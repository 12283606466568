import { events, track } from 'src/analytics';
import {
  CalibrateSuccessFoodPlanFragment,
  CalibrateSuccessOrderFragment,
  CalibrateSuccessPetFragment,
  CalibrateSuccessSubscriptionFragment,
  StockStatus,
} from 'src/graphql/types';

function getCalibrationCompleteInput({
  didPacksChange,
  didPortionsChange,
  didPlansChange,
  didReschedule,
  foodPlans,
  subscription,
  currentOrder,
  nextOrder,
  pets,
  email,
}: GetCalibrationCompleteInputArgs): CalibrationCompletePayload {
  const properties: CalibrationCompletePayload = {
    email,
    pets: pets.map(pet => ({
      name: pet.name,
      gender: pet.gender ?? '',
      dailyCalories:
        foodPlans.find(plan => plan.petId === pet.id)?.dailyCalories ?? 0,
    })),
    subscription: {
      frequency: subscription.frequency ?? 0,
      canRescheduleNextOrder: subscription.canRescheduleNextOrder ?? false,
      stockStatusAtNextDelivery:
        subscription.stockStatusAtNextDelivery ?? StockStatus.Ok,
      currentStockAsPacks:
        foodPlans
          .filter(plan => plan.active)
          .map(plan => plan.stockAsPacks)
          .reduce((a, b) => (a as number) + (b as number)) ?? 0,
      stockAsPacksAtNextDelivery:
        foodPlans
          .filter(plan => plan.active)
          .map(plan => plan.projectedStockAtNextDelivery)
          .reduce((a, b) => a + b) ?? 0,
    },
    currentOrder: {
      deliveryWindowStart: currentOrder.earliestDesiredArrivalDate,
      deliveryWindowEnd: currentOrder.latestDesiredArrivalDate,
      rescheduleCutoffDate: currentOrder.rescheduleCutOffDate,
    },
    firstFutureOrder: {
      deliveryWindowStart: nextOrder.earliestDesiredArrivalDate,
      deliveryWindowEnd: nextOrder.latestDesiredArrivalDate,
    },
    didPortionsChange,
    didPacksChange,
    didReschedule,
    didPlansChange,
  };
  return properties;
}

export function trackCommsCalibrationComplete(
  props: TrackCommsCalibrationCompleteArgs
) {
  track<CalibrationCompletePayload>(
    events.comms_calibration_complete,
    getCalibrationCompleteInput(props)
  );
}

type CalibrationCompleteContext = {
  didPacksChange: boolean;
  didPortionsChange: boolean;
  didPlansChange: boolean;
  didReschedule: boolean;
};
type GetCalibrationCompleteInputArgs = CalibrationCompleteContext & {
  currentOrder: CalibrateSuccessOrderFragment;
  nextOrder: CalibrateSuccessOrderFragment;
  subscription: CalibrateSuccessSubscriptionFragment;
  pets: CalibrateSuccessPetFragment[];
  foodPlans: CalibrateSuccessFoodPlanFragment[];
  email: string;
};

/** Properties of the "comms_calibration_complete" event. */
export interface CalibrationCompletePayload extends Record<string, unknown> {
  email: string;
  pets: {
    name: string;
    dailyCalories: number;
    gender: string;
  }[];
  subscription: {
    frequency: number;
    canRescheduleNextOrder: boolean;
    stockStatusAtNextDelivery: StockStatus;
    currentStockAsPacks: number;
    stockAsPacksAtNextDelivery: number;
  };
  currentOrder: {
    deliveryWindowStart: string | null;
    deliveryWindowEnd: string | null;
    rescheduleCutoffDate: string;
  };
  firstFutureOrder: {
    deliveryWindowStart: string | null;
    deliveryWindowEnd: string | null;
  };
  didPortionsChange: boolean;
  didPacksChange: boolean;
  didReschedule: boolean;
  didPlansChange: boolean;
}

interface TrackCommsCalibrationCompleteArgs {
  email: string;
  pets: CalibrateSuccessPetFragment[];
  subscription: CalibrateSuccessSubscriptionFragment;
  currentOrder: CalibrateSuccessOrderFragment;
  nextOrder: CalibrateSuccessOrderFragment;
  foodPlans: CalibrateSuccessFoodPlanFragment[];
  didPortionsChange: boolean;
  didPacksChange: boolean;
  didReschedule: boolean;
  didPlansChange: boolean;
}
