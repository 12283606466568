import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { Text, PageWidth } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

import { Content } from '../Content';
import { Title } from '../Title';

import { sections } from '../../data';

import styles from './ConveyorContent.module.scss';

export function ConveyorContent() {
  const { title, content, boldContent } = sections.conveyorSection;
  const rootClassName = useCorgiViewport({ xs: styles.xs, lg: styles.lg });

  return (
    <PageWidth className={classNames(styles.container, rootClassName)}>
      <ContentWidth fr={1} alignSelf="start" withGutter withPadding>
        <Title>{title}</Title>
      </ContentWidth>
      <ContentWidth fr={2} alignSelf="end" withPadding>
        <Content className={styles.content}>{content}</Content>
        <Text variant="article-20" bold>
          {boldContent}
        </Text>
      </ContentWidth>
    </PageWidth>
  );
}
