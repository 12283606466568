import Picture, { LoadingOption } from 'src/components/Picture';

import { Slideshow } from '@farmersdog/corgi-x';

import { RecipeType } from './types';

import { useCorgiV2Viewport } from 'src/screen';

import classNames from 'classnames';
import styles from './RecipeSlideShow.module.scss';

import { staticContent } from './static-content';

interface RecipeSlideShowProps {
  currentRecipeType: RecipeType;
}

const images = Object.entries(staticContent).map(content => ({
  type: content[0],
  sources: content[1].sources,
}));

export function RecipeSlideShow({ currentRecipeType }: RecipeSlideShowProps) {
  const responsiveClassName = useCorgiV2Viewport<string>({
    lg: styles.lg,
  });

  const activeIndex = images.findIndex(
    image => image.type === currentRecipeType
  );

  return (
    <div className={classNames(responsiveClassName, styles.image)}>
      <Slideshow label="DIY Nutrient Mix recipes" activeIndex={activeIndex}>
        {images.map(image => (
          <Picture
            key={image.type}
            sources={image.sources}
            alt={`${image.type} recipe`}
            className={classNames(responsiveClassName, styles.image)}
            loading={LoadingOption.lazy}
          />
        ))}
      </Slideshow>
    </div>
  );
}
