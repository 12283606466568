import events from '../eventNames';
import track from '../track';

/**
 * Send a tracking event for when a user changes views the after your trial
 * section on the checkout page
 */
export function trackCheckoutAfterYourTrialViewed(): void {
  return track(events.checkout_after_your_trial_viewed);
}
