import { ContentLoader, Text } from '@farmersdog/corgi-x';
import { JourneyStatus } from '../../../graphql/types.cgs';
import { formatNames, pluralizeString } from '../../../utils';
import { HomepagePetFieldsFragment } from '../../network';
import {
  WelcomeMessageCopyInputs,
  beforeBoxTwoSubHeading,
  afterBoxTwoSubHeading,
  beforeBoxTwoSubHeadingExperimentPicky,
} from './WelcomeMessage.copy';

interface WelcomeMessageProps {
  firstName: string | undefined;
  pets: HomepagePetFieldsFragment[] | undefined;
  journeyStatus: JourneyStatus | undefined;
  showExperimentPickyCopy: boolean;
  loading: boolean;
}

export function WelcomeMessage({
  firstName = '',
  pets = [],
  journeyStatus,
  showExperimentPickyCopy,
  loading,
}: WelcomeMessageProps) {
  const copyInputs: WelcomeMessageCopyInputs = {
    petNames: formatNames(pets.map(pet => pet.name)) || 'your dog',
    friendOrFriends: pluralizeString(pets.length, 'friend', 'friends'),
  };

  const heading = firstName.length > 0 ? `Hi, ${firstName}!` : `Welcome`;
  const subheading =
    journeyStatus === JourneyStatus.ReceivedSecondBox
      ? afterBoxTwoSubHeading(copyInputs)
      : showExperimentPickyCopy
        ? beforeBoxTwoSubHeadingExperimentPicky(copyInputs)
        : beforeBoxTwoSubHeading(copyInputs);

  return (
    <ContentLoader loading={loading} mode="dark" height={150}>
      <Text variant="heading-40" as="h1" color="kale-3" bold vSpacing="none">
        {heading}
      </Text>
      <Text variant="article-20" as="p" topSpacing="xs" bottomSpacing="lg">
        {subheading}
      </Text>
    </ContentLoader>
  );
}
