import { Button, Page, Picture, Text } from '@farmersdog/corgi-x';

import { trackClickAdjustDailyCalories } from '../../../../../../analytics';
import styles from './MixingPlanEducation.module.css';
import image from './assets/dog-wagging-tail-eating.png';

interface MixingPlanEducationProps {
  onClose: () => void;
  onBack: () => void;
  onContinue: () => void;
}

export function MixingPlanEducation(props: MixingPlanEducationProps) {
  const handleClickAdjustDailyCalories = () => {
    trackClickAdjustDailyCalories();
    props.onContinue();
  };
  return (
    <Page
      layout="base"
      onCloseClick={props.onClose}
      onBackClick={props.onBack}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Start a Mixing Plan
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        {`If feeding a fully fresh diet isn't right for you right now, let's build
        a plan that works! Even a little fresh food can have big benefits, and
        mixing some with another food source is an easy way to lower your daily
        price.`}
      </Text>
      <div className={styles.imageContainer}>
        <Picture sources={[image]} alt="dog wagging tail and eating" />
      </div>
      <div className={styles.ctas}>
        <Button
          type="button"
          onClick={handleClickAdjustDailyCalories}
          className={styles.continueButton}
        >
          Adjust Daily Calories
        </Button>
        <Button
          type="button"
          variant="plain-text"
          onClick={props.onBack}
          className={styles.backButton}
        >
          Back
        </Button>
      </div>
    </Page>
  );
}
