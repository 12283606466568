export const VET_CLINIC_FEATURE_DESC =
  "To complete your dog's profile, add your vet clinic.";
export const CLINIC_SELECTOR_DEFAULT_PLACEHOLDER = 'Search For Your Clinic';
export const CLINIC_SEARCH_DELAY = 1000;
export const MAX_RESULTS_FOR_INITIAL_LOADING = 5;
export const CLINIC_VALIDATION_ERROR =
  'We were unable to retrieve your clinic. Please try again.';
export const CLINIC_ADD_GENERIC_ERROR =
  'We were unable to add your clinic. Please try again.';
export const CLINIC_ADD_DUPLICATE_ERROR_CODE =
  'PET_HEALTH_CUSTOMER_CLINIC_ALREADY_ASSOCIATED';
export const CLINIC_ADD_DUPLICATE_ERROR =
  'This vet clinic is already added to your profile';
export const CLINIC_COUNTRY = 'USA';
export const CLINIC_REMOVAL_ERROR =
  'We were unable to remove your clinic. Please try again.';
