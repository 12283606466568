import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '@farmersdog/corgi';

import {
  PATH_CUSTOMER_ACCOUNT,
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_LOGIN,
} from '@farmersdog/constants/paths';

import { selectTokenExpiration } from 'src/reducers/auth';
import { selectUserEmail } from 'src/reducers/signup/user';
import { refreshToken, refreshingToken, logout } from 'src/actions/auth';

import browserOnly from 'src/utils/browserOnly';
import useAsyncFunction from 'src/utils/useAsyncFunction';
import { QueryParameter } from '@farmersdog/constants';

function useTokenExpiration() {
  const dispatch = useDispatch();
  const expiration = useSelector(selectTokenExpiration);
  const history = useHistory();
  const location = useLocation();
  const isApp = useRouteMatch(PATH_CUSTOMER_ACCOUNT);
  const isSignup = useRouteMatch(PATH_SIGNUP);
  const signupEmail = useSelector(selectUserEmail);
  const [hasFocus, setHasFocus] = useState(true);
  const dispatchToast = useToast();
  const [callRefreshToken, { loading }] = useAsyncFunction(
    email => dispatch(refreshToken(email)),
    {
      onError: () => {
        dispatch(logout());
        history.push(PATH_SIGNUP_ME);
        dispatchToast({
          variant: 'neutral',
          children: 'Session has expired.',
        });
      },
    }
  );

  useEffect(() => {
    return browserOnly((window, document) => {
      const handleVisibilityChange = () => {
        setHasFocus(!document.hidden);
      };

      const handleWindowBlur = () => {
        setHasFocus(false);
      };

      const handleWindowFocus = () => {
        setHasFocus(true);
      };

      // Document event for when the tab losses focus
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Window events for when browser losses focus
      window.addEventListener('blur', handleWindowBlur);

      window.addEventListener('focus', handleWindowFocus);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
        window.removeEventListener('blur', handleWindowBlur);
        window.removeEventListener('focus', handleWindowFocus);
      };
    });
  }, []);

  useEffect(() => {
    dispatch(refreshingToken(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (!hasFocus || !expiration || loading) return;
    const hasExpired = moment(expiration).isSameOrBefore(new Date());
    if (!hasExpired) return;

    if (isApp) {
      history.push(
        `${PATH_LOGIN}?${QueryParameter.NextPath}=${location.pathname}${location.search}`
      );
      dispatchToast({
        variant: 'neutral',
        children: 'Session has expired.',
      });
    }

    if (isSignup) {
      callRefreshToken(signupEmail);
    }
  }, [
    callRefreshToken,
    dispatchToast,
    expiration,
    hasFocus,
    history,
    isApp,
    isSignup,
    loading,
    location,
    signupEmail,
  ]);
}

export default useTokenExpiration;
