import { Pixel } from '../../Pixel';

export const ISPOT_PIXEL_ID = 'TC-4097-1';
export const PREMION_PIXEL_ID = 'TC-5137-2';

export const APP_TYPE = 'web';

export const PREMION_REF_ID = '60210';

/* 
  NOTE: This is a unique implementation where we have two nearly identical pixels that we fire for two vendors
  at the exact same times with different url configurations, thus we have two separate pixels using a similar setup.
*/

export enum ISpotEventName {
  PAGE_VIEW = 'visit',
  LEADS = 'lead',
  PROMO_CODE = 'promo_code',
  ADD_TO_CART = 'add_to_cart',
  CHECKOUT = 'checkout',
}

export enum PremionEventName {
  PAGE_VIEW = 'visit',
  LEADS = 'LEAD',
  ADD_TO_CART = 'ADDTOCART',
  CHECKOUT = 'CHECKOUT',
}

export interface ISpotPixelAttributes extends Record<string, unknown> {
  ord: number;
  app: string;
  type: ISpotEventName;
  customdata?: string;
  channel?: string;
}

// The Premion integration is nearly identical, but we send the following additional properties with different event names.
export interface PremionPixelAttributes
  extends Omit<ISpotPixelAttributes, 'type'> {
  type: PremionEventName;
  refid: string;
  url: string;
}

export const iSpot = new Pixel<ISpotPixelAttributes>({
  vendorName: 'iSpot',
  baseUrl: `https://pt.ispot.tv/v2/${ISPOT_PIXEL_ID}.gif`,
});

export const Premion = new Pixel<PremionPixelAttributes>({
  vendorName: 'Premion',
  baseUrl: `https://pt.ispot.tv/v2/${PREMION_PIXEL_ID}.gif`,
});
