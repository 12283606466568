import {
  Button,
  Grid,
  GridItem,
  Page,
  Section,
  Text,
} from '@farmersdog/corgi-x';

import styles from './IntroMixingPlan.module.css';

import {
  trackCancelSubscriptionClickContinueCanceling,
  trackClickStartMixingPlan,
} from '../../../../../../analytics';

export interface IntroMixingPlanProps {
  onStartMixingPlan?: () => void;
  onContinueCancelling: () => void;
  onPrevious: () => void;
  onClose: () => void;
}

export function IntroMixingPlan({
  onPrevious,
  onClose,
  onStartMixingPlan,
  onContinueCancelling,
}: IntroMixingPlanProps) {
  const handleStartMixingPlan = () => {
    if (onStartMixingPlan) {
      trackClickStartMixingPlan();
      onStartMixingPlan();
    }
  };
  const handleContinueCancelling = () => {
    if (onContinueCancelling) {
      trackCancelSubscriptionClickContinueCanceling({
        savePathway: 'mixing plan',
      });
      onContinueCancelling();
    }
  };
  return (
    <Page
      layout="base"
      onBackClick={onPrevious}
      onCloseClick={onClose}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        We Can Help With Cost
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        Good news! We have an option for you that should help.
      </Text>
      <Grid justifyContent="center">
        <GridItem md={6} sm={10} className={styles.gridItem}>
          <Text
            as="h2"
            bold
            color="kale-3"
            fontStyle="normal"
            variant="heading-28"
          >
            Mixing Plan
          </Text>
          <Text as="p" variant="article-20" vSpacing="sm">
            Lower your average daily price by mixing in our fresh food with
            another food source.
          </Text>
        </GridItem>
      </Grid>
      <Section
        as="div"
        vSpacing="md"
        widthConstraint="md"
        className={styles.cta}
      >
        <Button onClick={handleStartMixingPlan} type="button" variant="primary">
          Start Mixing Plan
        </Button>
        <Button
          onClick={handleContinueCancelling}
          type="button"
          variant="plain-text"
        >
          <Text variant="heading-16">No Thanks, Continue Canceling</Text>
        </Button>
      </Section>
    </Page>
  );
}
