import { formatCurrency } from '../../utils';
import styles from './PriceBreakdown.module.css';
import type { PaymentRetryOrderDataQuery } from '../network/PaymentRetryOrderData.cgs.generated-types';
import { formatNames } from '../../utils/formatNames';
import { PriceBreakdownItem } from './PriceBreakdownItem';
import { PriceBreakdownGreyedItem } from './PriceBreakdownGreyedItem';

export type PriceBreakdownProps = Pick<
  PaymentRetryOrderDataQuery['customer']['nextOrderToBeDelivered'],
  | 'pets'
  | 'applicableCredits'
  | 'discountTotal'
  | 'addOnsTotal'
  | 'mealsTotal'
  | 'pricePerDay'
  | 'taxTotal'
  | 'totalOrderPriceWithCreditsApplied'
>;

export function PriceBreakdown(props: PriceBreakdownProps) {
  return (
    <>
      <PriceBreakdownItem
        dataTestBase="meals_total"
        fieldName="Meals Total"
        value={props.mealsTotal ? formatCurrency(props.mealsTotal) : ''}
      />
      <div className={styles.orderBreakdownSectionGreyed}>
        <PriceBreakdownGreyedItem
          value={formatNames(props.pets) ?? ''}
          dataTestId="pet_name"
        />
        <PriceBreakdownGreyedItem
          value={`Daily Price ${formatCurrency(props.pricePerDay)}/day`}
          dataTestId="daily_price"
        />
      </div>
      {props.addOnsTotal !== null && props.addOnsTotal > 0 && (
        <PriceBreakdownItem
          dataTestBase="treats_total"
          fieldName="Treats Total"
          value={formatCurrency(props.addOnsTotal)}
        />
      )}
      {props.discountTotal !== null && props.discountTotal > 0 && (
        <PriceBreakdownItem
          dataTestBase="discount_amount"
          fieldName="Discount Amount"
          kale
          value={`-${formatCurrency(props.discountTotal)}`}
        />
      )}
      <PriceBreakdownItem
        dataTestBase="shipping_total"
        fieldName="Shipping"
        value="FREE"
      />
      <PriceBreakdownItem
        dataTestBase="tax_total"
        fieldName="Tax"
        value={formatCurrency(props.taxTotal)}
      />
      {props.applicableCredits > 0 && (
        <PriceBreakdownItem
          dataTestBase="credits"
          fieldName="Credits"
          kale
          value={`-${formatCurrency(props.applicableCredits)}`}
        />
      )}
      <PriceBreakdownItem
        dataTestBase="total_order_price"
        fieldName="Total Order Price"
        value={formatCurrency(props.totalOrderPriceWithCreditsApplied)}
        bold
      />
    </>
  );
}
