import { ReactNode } from 'react';
import styles from './ProgressContainer.module.css';

interface ProgressContainerProps {
  children: ReactNode;
  ariaLabel: string;
}

export function ProgressContainer(props: ProgressContainerProps) {
  return (
    <div
      className={styles.root}
      role="progressbar"
      aria-valuenow={1}
      aria-valuemin={1}
      aria-valuemax={3}
      tabIndex={0}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </div>
  );
}
