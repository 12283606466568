import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Text } from '@farmersdog/corgi-x';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useLabel } from '../../../hooks/useLabel';
import { useLeadState } from '../../../hooks/useLeadState';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { SegmentedControlInput } from '../base/SegmentedControlInput';

import { getMedicalConditionMessage } from './getMedicalConditionMessage';
import { MedicalConditionMessage } from './MedicalConditionMessage';
import styles from './PetHealthCheckboxes.module.css';
import { VALID_NODE_NAMES, ValidNodeName } from './types';

export const PET_HEALTH_CHECKBOXES_TEST_ID = 'pet-health-checkboxes';

function isNodeNameValid(nodeName: unknown): nodeName is ValidNodeName {
  return VALID_NODE_NAMES.includes(nodeName as ValidNodeName);
}

export function PetHealthCheckboxes(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, formValidationSchema } = props;

  const { register, getValues } = formMethods;

  const { name: nonIndexedNodeName, position } = getNodeNameAndPosition(
    node.name
  );
  if (!isNodeNameValid(nonIndexedNodeName)) {
    throw new Error(
      'PetHealthCheckboxes requires non-indexed node name of Issues or PrescriptionDiets'
    );
  }

  const { convertFormStateToLeadInput, writeLead } = useLeadState();
  const [
    shouldScrollToMessageIfImportant,
    setShouldScrollToMessageIfImportant,
  ] = useState(false);

  const writeLeadWithSelectedValues = async () => {
    const formData = formMethods.getValues();
    const leadInput = convertFormStateToLeadInput({
      formValidationSchema,
      formData,
    });
    await writeLead({ lead: leadInput });

    if (!isMessageImportant) {
      setShouldScrollToMessageIfImportant(true);
    }
  };

  const selectedValues =
    formMethods.getValues(`${nonIndexedNodeName}-${position}`) || [];
  const messageRef = useRef<HTMLSpanElement>(null);

  const {
    message,
    ariaLabel,
    isImportant: isMessageImportant,
  } = getMedicalConditionMessage(nonIndexedNodeName, selectedValues);

  useEffect(() => {
    if (shouldScrollToMessageIfImportant && isMessageImportant) {
      messageRef.current?.scrollIntoView({
        block: 'center',
      });
    }
    setShouldScrollToMessageIfImportant(false);
  }, [shouldScrollToMessageIfImportant, isMessageImportant]);

  const options = node.input?.options;
  const { frontLabel } = useLabel({ node, getValues });
  if (!options) {
    throw new Error('PetHealthCheckboxes requires options');
  }

  const registerProps = register(node.name, {
    onChange: writeLeadWithSelectedValues,
    setValueAs: (value: unknown) => value || [],
  });

  return (
    <>
      <Text
        className={styles.label}
        variant="article-16"
        fontStyle="italic"
        color="charcoal-3"
      >
        {frontLabel}
      </Text>

      <div
        className={classNames(styles.container, styles[nonIndexedNodeName])}
        data-testid={PET_HEALTH_CHECKBOXES_TEST_ID}
      >
        {options.map(option => {
          return (
            <SegmentedControlInput
              id={option.name}
              value={option.value}
              {...registerProps}
              key={option.value}
            >
              {option.name}
            </SegmentedControlInput>
          );
        })}

        <MedicalConditionMessage
          message={message}
          ariaLabel={ariaLabel}
          ref={messageRef}
        />
      </div>
    </>
  );
}
