import { NodeNames } from '@farmersdog/constants';

import { BranchNode } from '../../../../blueprint/types';
import { getNodeNameAndPosition } from '../../../../blueprint/utils';
import { makeIndexedName } from '../../../../utils';

import { SubmitButtonProps } from './SubmitButton';

interface HasPrescriptionsArgs {
  node: BranchNode;
  formMethods: SubmitButtonProps['formMethods'];
}

export function hasPrescriptions({
  node,
  formMethods,
}: HasPrescriptionsArgs): boolean {
  const formValues = formMethods.getValues();
  const { position } = getNodeNameAndPosition(node.name);

  if (!position) {
    return false;
  }

  const prescriptionDietsLabel = makeIndexedName(
    NodeNames.PrescriptionDiets,
    position
  );
  const prescriptionDietLabel = makeIndexedName(
    NodeNames.PrescriptionDiet,
    position
  );
  const doesHavePrescriptions = formValues[prescriptionDietLabel];
  const prescriptions = formValues[prescriptionDietsLabel];
  const numPrescriptions = Array.isArray(prescriptions)
    ? prescriptions.length
    : 0;

  return !!(doesHavePrescriptions && numPrescriptions > 0);
}
