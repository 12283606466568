import { local } from '@farmersdog/utils';

export const DEBUG_MODE_KEY = 'ga4-debug-mode';

function get(): string | null {
  return local.getItem(DEBUG_MODE_KEY);
}

function set(token: string): void {
  local.setItem(DEBUG_MODE_KEY, token);
}

function remove(): void {
  local.removeItem(DEBUG_MODE_KEY);
}

export const ga4DebugMode = {
  get,
  set,
  remove,
};
