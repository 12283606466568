import { PATH_ADJUST_DAILY_CALORIES } from '@farmersdog/constants/paths';
import { Text } from '@farmersdog/corgi-x';
import {
  formatDecimalToFraction,
  pluralizeString,
  setPathQueries,
} from '../../../utils';
import { ActionHeader } from '../ActionHeader';
import { FeedingGuide } from '../FeedingGuide';

interface DailyPortionSizeProps {
  dailyFreshCalories: number;
  freshFoodRatio: number;
  name: string;
  dailyPacksConsumed: number;
}

export function DailyPortion(props: DailyPortionSizeProps) {
  return (
    <section>
      <ActionHeader
        headerText="Daily Portions"
        actionText="Edit"
        actionAriaLabel={`Edit ${props.name} meal plan size`}
        actionTo={setPathQueries(PATH_ADJUST_DAILY_CALORIES, {
          pet: props.name,
        })}
      />
      <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="xxs">
        {props.name} eats{' '}
        <Text as="span" bold variant="heading-16">
          {props.dailyFreshCalories} calories
        </Text>
      </Text>
      <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="xxs">
        {`(${formatDecimalToFraction(
          props.dailyPacksConsumed
        )} ${pluralizeString(
          props.dailyPacksConsumed,
          'pack',
          'packs'
        )}/day);`}{' '}
        {Math.floor(props.freshFoodRatio * 100)}% fresh food plan
      </Text>
      <FeedingGuide dailyPacksConsumed={props.dailyPacksConsumed} />
    </section>
  );
}
