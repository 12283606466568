import get from 'lodash/get';

import { Logger } from '@farmersdog/logger';
import config from 'src/config';
import { datadog } from 'src/vendors/datadog';
import { clearInvalidUserId } from './clearInvalidUserId';
import { setGoogleTagManagerVar } from 'src/vendors/googleTagManager';

import getTraits from './getTraits';
import { identify as analyticsPackageIdentify } from '@farmersdog/analytics';
import sharedTraits from './sharedAttributes';
import { setIsOnDoNotSellShareList } from '@farmersdog/lead-browser-storage';

const log = new Logger('app:analytics');

function logUndefinedIdentifyFunction() {
  log.debug('Could not identify user: `analytics.identify` is not defined.');
}

/**
 * Identify a user for analytics purposes using id and traits.
 *
 * ### Usage
 *
 * - Calling this function will identify the user in analytics and other
 *   third-party services.
 * - Some traits have a reserved name. See
 *   https://segment.com/docs/spec/identify/#traits
 *
 * @param user - As user object (even without id).
 * @param additionalTraits - Free-form dictionary of traits of the user,
 *                                  like `quote`.
 */
function identify(
  user: Nullable<Partial<Reducer.User>>,
  additionalTraits?: Record<string, unknown>
) {
  if (typeof user.consent?.dnss === 'boolean') {
    setIsOnDoNotSellShareList(user.consent.dnss);
  }

  const userTraits = getTraits(user) as {
    email: string;
    firstName: string;
    address: Record<string, unknown>;
  };
  const traits = {
    ...additionalTraits,
    ...userTraits,
    ...sharedTraits,
    /**
     * Sending quote in `identify` payloads breaks our DB. This is a targeted way to clear it
     * from the cache. More details here:
     * https://app.clubhouse.io/farmersdog/story/51729/pass-null-as-a-value-for-the-quote-key-on-identify
     */
    quote: null,
  };

  const userId = !user.id || Number.isFinite(user.id) ? user.id : undefined;

  clearInvalidUserId();
  analyticsPackageIdentify(
    userId ? String(userId) : undefined,
    traits,
    logUndefinedIdentifyFunction
  );

  /**
   * These variables are used by google tag manager as a data layer for a number
   * of services. Modification of these variables should include manual testing
   * to ensure that the data layer in google tag manager still works correctly.
   * This is because we are defining an interface on the window object that GTM
   * expects to exist.
   */
  const { totalAmount, taxAmount } =
    get(user, 'checkoutQuote.price.trial') ||
    get(user, 'checkoutQuote.price.regular') ||
    ({} as Record<string, unknown>);

  setGoogleTagManagerVar('user', {
    email: traits.email,
    firstName: traits.firstName,
    shippingAddress: traits.address,
    // values not included in traits
    id: user.id,
    lastName: user.lastName,
  });
  setGoogleTagManagerVar('checkoutQuote', {
    totalAmount,
    taxAmount,
  });

  if (config('datadogRum.enabled')) {
    datadog.identify(user);
    log.debug('User has been set in DataDog RUM');
  } else {
    log.debug('Will not identify user in DataDog RUM since it is disabled.');
  }
}

export default identify;
