import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextV2 } from '@farmersdog/corgi';
import { Button } from '@farmersdog/corgi-x';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { selectPetNames } from 'src/reducers/signup/user';
import petNameOrYourDogs from 'src/utils/petNameOrYourDogs';

import ActionButtons from './ActionButtons';
import PreferencesSelector from './PreferenceSelector';

import { additionalIngredientOptions } from './additionalIngredientOptions';
import { TITLE } from './copy';
import { SLIDE_NAME as SUBMIT } from './Submit';

import styles from './AdditionalIngredients.module.scss';

export const SLIDE_NAME = 'ADDITIONAL_INGREDIENTS';

function AdditionalIngredients({
  goToPrevSlide,
  setCurrentSlide,
  onAnswerChange,
  setFinalMessage,
  additionalIngredients,
}) {
  const petNames = useSelector(selectPetNames);

  const customFinalMessage = `Thank you so much for your feedback — we’re actively working on expanding our selection of recipes, and your input is important to our development process! We hope ${petNameOrYourDogs(
    petNames
  )} can enjoy our fresh food soon :)`;

  const handleOptionChange = e => {
    const targetIngredient = e.target.name;
    const newIngredientPreference = e.target.value;

    onAnswerChange('additionalIngredients', {
      ...additionalIngredients,
      [targetIngredient]: newIngredientPreference,
    });
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE}>
        <TextV2 as="div" variant="heading-21">
          Which additional ingredients can {petNameOrYourDogs(petNames)} eat?
        </TextV2>
        <TextV2
          as="p"
          variant="body-16"
          color="charcoal-1"
          className={styles.supportText}
        >
          Select your requirements for whether to include each of these
          additional ingredients.
        </TextV2>
        <div className={styles.questions}>
          {additionalIngredientOptions.map(ingredient => (
            <PreferencesSelector
              key={ingredient.value}
              item={ingredient}
              value={additionalIngredients}
              onOptionChange={handleOptionChange}
            />
          ))}
        </div>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setFinalMessage(customFinalMessage);
              setCurrentSlide(SUBMIT);
            }}
          >
            Continue
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

AdditionalIngredients.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  setFinalMessage: PropTypes.func.isRequired,
  additionalIngredients: PropTypes.object.isRequired,
};

export default AdditionalIngredients;
