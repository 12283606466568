// Criteria definitions
const MINIMUM_NUDGE_ALWAYS_ON_FREQUENCY = 49;
const REGULAR_ORDERS_DELIVERED_COUNT_REQUIRED = 1;
const ORDER_UNLOCK_STATUS = true;

export interface EligibleForOrderSizeCtaByCriteriaProps {
  criteria: {
    frequency: number;
    regularOrdersDeliveredCount: number;
    nextOrderIsUnlocked: boolean;
  };
}

/**
 * Determines whether a user should see the order size nudge based on provided criteria.
 */
export function isEligibleForOrderSizeCtaByCriteria({
  criteria,
}: EligibleForOrderSizeCtaByCriteriaProps) {
  // ALL of these conditions must be met for the criteria to be met
  const criteriaChecks = [
    // User should have a frequency of 49 days or more
    criteria.frequency >= MINIMUM_NUDGE_ALWAYS_ON_FREQUENCY,
    // User should have exactly 1 regular order delivered
    criteria.regularOrdersDeliveredCount ===
      REGULAR_ORDERS_DELIVERED_COUNT_REQUIRED,
    // User should have an unlocked next order
    criteria.nextOrderIsUnlocked === ORDER_UNLOCK_STATUS,
  ];

  const isEligible = criteriaChecks.every(Boolean);

  return isEligible;
}
