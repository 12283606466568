import { Button, Grid, GridItem, Input, Page, Text } from '@farmersdog/corgi-x';
import { useState, ChangeEvent } from 'react';
import { formatCurrency } from '../../../../../utils';
import { trackCancelSubscriptionCompiledDesiredPrice } from '../../../../analytics';
import styles from './CostAssessment.module.css';

interface CostAssessmentProps {
  /** The customer's current quote weekly price, in cents */
  currentWeeklyPrice: number;
  onClose: () => void;
  onPrevious: () => void;
  onContinue: () => void;
}

export function CostAssessment(props: CostAssessmentProps) {
  const [desiredWeeklyPrice, setDesiredWeeklyPrice] = useState<
    number | undefined
  >();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.replace('$', '');
    if (/\D/.exec(value)) return;
    setDesiredWeeklyPrice(Number(value));
  }

  function handleContinue() {
    void trackCancelSubscriptionCompiledDesiredPrice({
      desiredWeeklyPrice: Number(desiredWeeklyPrice),
      currentWeeklyPrice: props.currentWeeklyPrice / 100,
    });
    props.onContinue();
  }

  return (
    <Page
      layout="base"
      onBackClick={props.onPrevious}
      onCloseClick={props.onClose}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        We Can Help With Cost
      </Text>
      <Text as="p" variant="article-20" vSpacing="sm">
        Your subscription is {formatCurrency(props.currentWeeklyPrice)} per
        week. What is the most you would pay per week for a fresh diet?
      </Text>
      <Grid>
        <GridItem md={5} className={styles.gridItem}>
          <Input
            type="text"
            label="Desired weekly price"
            name="desired weekly price"
            onChange={handleChange}
            value={`$${desiredWeeklyPrice ?? ''}`}
            className={styles.input}
            pattern="[0-9]*"
            inputMode="numeric"
          />
          <Button
            type="button"
            onClick={handleContinue}
            disabled={desiredWeeklyPrice === undefined}
            className={styles.button}
          >
            Continue
          </Button>
        </GridItem>
      </Grid>
    </Page>
  );
}
