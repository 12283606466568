/* CH#49502: Used by CVR */
import { PageWidth } from '@farmersdog/corgi-x';

import { useCorgiV2Viewport, useCorgiViewport } from 'src/screen';
import {
  CBSNewsLogo,
  FastCompanyLogo,
  TodayShowLogo,
  VogueLogo,
  FoxLogo,
  WSJLogo,
} from 'src/components/Logos';

import LegalDetails from './LegalDetails';

import styles from './SmallFooter.module.scss';
import { isDiySignup } from '@farmersdog/lead-browser-storage';
import classNames from 'classnames';

function SmallFooter(isRecipesPage = false) {
  const showExtraLogos = useCorgiViewport({ xs: false, lg: true });
  const isMobile = useCorgiV2Viewport({ xs: true, lg: false });
  const addExtraPadding = isRecipesPage && isDiySignup() && isMobile;
  return (
    <footer
      className={classNames(styles.container, {
        [styles.extraPadding]: addExtraPadding,
      })}
    >
      <div className={styles.logos}>
        <PageWidth>
          <TodayShowLogo height={30} />
          <FastCompanyLogo height={30} />
          {showExtraLogos && (
            <>
              <FoxLogo height={30} />
              <WSJLogo height={30} />
            </>
          )}

          <VogueLogo height={30} />
          <CBSNewsLogo height={30} />
        </PageWidth>
      </div>
      <LegalDetails />
    </footer>
  );
}

export default SmallFooter;
