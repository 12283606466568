import {
  MutationFunctionOptions,
  MutationResult,
  MutationHookOptions,
  useMutation as useApolloMutation,
  ApolloError,
  FetchResult,
} from '@apollo/client';

import { DocumentNode } from 'graphql';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'src/actions/user';
import useAsyncFunction from 'src/utils/useAsyncFunction';
import { UseAsyncFunctionState } from 'src/utils/useAsyncFunction/useAsyncFunction.types';

export interface UseMutationOptions<TData, TVariables>
  extends Omit<
    MutationHookOptions<TData, TVariables>,
    'mutation' | 'onCompleted' | 'onError'
  > {
  onCompleted?: (data?: TData | null) => void;
  onError?: (data: ApolloError) => void;
}

export type MutationState<TData> =
  | MutationResult<TData>
  | UseAsyncFunctionState<TData | null>;

export type UseMutation<TData, TVariables> = [
  (
    options?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData> | undefined>,
  MutationState<TData>,
];

/**
 * Wrapper on Apollo's `useMutation`: it fetches the user after the mutation to
 * keep the redux store in sync.  This is a temporary solution until we are off of
 * REST 100%.
 *
 * @param mutation - A GraphQL mutation document parsed into an AST by
 * graphql-tag.
 * @param options - Extends `MutationHookOptions` with `useAsyncFunction`
 * compatibles `onCompleted` and `onError`.
 */
function useMutation<TData, TVariables>(
  mutation: DocumentNode,
  {
    onCompleted,
    onError,
    ...options
  }: UseMutationOptions<TData, TVariables> = {}
): UseMutation<TData, TVariables> {
  const dispatch = useDispatch();
  const [callApolloMutation, apolloMutationState] = useApolloMutation<
    TData,
    TVariables
  >(mutation, options);

  const [callMutation, mutationState] = useAsyncFunction<
    FetchResult<TData> | undefined,
    (MutationFunctionOptions<TData, TVariables> | undefined)[],
    ApolloError
  >(
    async callOptions => {
      // Make graphql mutation call
      const result = await callApolloMutation(callOptions);
      // Update redux by fetching user data
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(fetchUser());
      return result;
    },
    {
      onCompleted: result => {
        if (onCompleted) {
          const data = result?.data;
          onCompleted(data);
        }
      },
      onError,
    }
  );

  return [
    callMutation,
    {
      ...apolloMutationState,
      loading: apolloMutationState.loading || mutationState.loading,
      error: apolloMutationState.error || mutationState.error,
    },
  ];
}

export default useMutation;
