import { ContentWidth } from '@farmersdog/corgi';
import { PageWidth } from '@farmersdog/corgi-x';
import { ScrollVideo } from '../ScrollVideo';
import { videos } from '../../data';

export interface BeefMealVideoProps {
  /** Whether to enable full width */
  fullWidth: boolean;
  /** Optional class name */
  className?: string;
}

export function BeefMealVideo({ className, fullWidth }: BeefMealVideoProps) {
  return (
    <PageWidth enabled={fullWidth} className={className}>
      <ContentWidth fr={3} withPadding>
        <ScrollVideo videoIds={videos.bagVideoIds} />
      </ContentWidth>
    </PageWidth>
  );
}
