import { useEffect } from 'react';

import { browserOnly } from '@farmersdog/utils';

import { Script } from '../../Script';

import { BASE_URL, COMPANY_ID } from './constants';

export interface PowerInboxAttributes {
  company_id?: string;
}

export function PowerInboxScript() {
  useEffect(() => {
    browserOnly((window: Window) => {
      window.pi_params = window.pi_params ?? {
        company_id: COMPANY_ID,
      };
    });
  });

  return <Script src={BASE_URL} type="text/javascript" async />;
}
