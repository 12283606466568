import { isEligibleForDiy } from '@farmersdog/lead-browser-storage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { MixingPlanTreatments, useFeature } from '../../abTesting';
import { CVR_MIXING_PLAN_EXPERIMENT } from '../../abTesting/features';
import {
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';
import { selectMixingPlanHasSeenError } from '../../reducers/signup/mixingPlanExperiment';

import {
  selectFoodPlanByPetId,
  selectPets,
  selectUser,
} from '../../reducers/signup/user';
import { useAppSelector } from 'src/store/useAppSelector';

export const showMixingPlanQueryParam = 'showMixingPlan=true';
const mixingPlanRoute = `${PATH_SIGNUP_RECIPES}?${showMixingPlanQueryParam}`;

export function useMixingPlanTreatment() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const hasSeenMixingPlanError = useSelector(selectMixingPlanHasSeenError);
  const user = useSelector(selectUser);
  const pets = useSelector(selectPets);
  const foodPlan = useAppSelector(state =>
    selectFoodPlanByPetId(state, pets[0]?.id)
  );

  // assumes a ratio of 1 (full plan) for experiment qualification: dailiyCalories = calories * portion * ratio
  const fullPlanDailyCalories = foodPlan
    ? Math.round(foodPlan.calories * foodPlan.portion)
    : undefined;

  const history = useHistory();
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);

  const { treatment } = useFeature<MixingPlanTreatments>(
    CVR_MIXING_PLAN_EXPERIMENT,
    {
      attributes: {
        numPets: pets.length,
        freshFoodConfidence: user.freshFoodConfidence,
        petDailyCalories: fullPlanDailyCalories,
        hasSeenDiy: isEligibleForDiy(),
      },
    }
  );

  useEffect(() => {
    if (
      !hasSeenMixingPlanError &&
      isPlansPage &&
      (treatment === MixingPlanTreatments.treatmentB ||
        treatment === MixingPlanTreatments.treatmentA)
    ) {
      history.push(mixingPlanRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment, isPlansPage]);

  return hasSeenMixingPlanError ? MixingPlanTreatments.off : treatment;
}
