import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '@farmersdog/corgi-x';

import { useCorgiViewport } from 'src/screen';

import styles from './BenefitsNav.module.scss';
import NavSelect from './NavSelect';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function BenefitsNav(props) {
  const handleNavigation = benefit => {
    props[benefit].current.scrollIntoView();
  };

  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    // TODO: To remove eslint-disable, move NavSelect into this component.
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classNames(styles.container, useCorgiViewport(styles), {
        [styles.accessibilityThemeExtraPadding]:
          isAccessibilityThemeOn && !isMobile,
      })}
    >
      <Text
        variant="article-20"
        fontStyle="italic"
        color="charcoal-3"
        className={styles.title}
      >
        See the benefits of feeding fresh
      </Text>
      <NavSelect onChange={handleNavigation}>
        <option value="health">Boosting Health & Wellness</option>
        <option value="picky">Pleasing Picky Eaters</option>
        <option value="weight">Attaining Optimal Weight</option>
      </NavSelect>
    </label>
  );
}

BenefitsNav.propTypes = {
  health: PropTypes.shape({ current: PropTypes.element }).isRequired,
  picky: PropTypes.shape({ current: PropTypes.element }).isRequired,
  weight: PropTypes.shape({ current: PropTypes.element }).isRequired,
};

export default BenefitsNav;
