import { events, track } from 'src/analytics';
interface trackTreatsFinalSkuRemovedProps {
  path: string;
}

export function trackTreatsFinalSkuRemoved({
  path,
}: trackTreatsFinalSkuRemovedProps) {
  return track(events.treats_final_sku_removed, {
    path,
  });
}
