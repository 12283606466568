import classNames from 'classnames';
import { ActivityIndicator, Button, Text } from '@farmersdog/corgi-x';
import { trackViewOrderLinkClicked } from '../../../analytics';
import styles from './PaymentFailedInfoCard.module.css';
import { PaymentWarningBadge } from '../../PaymentWarningBadge';
import { useGetPaymentFailedStatus } from '../hooks';
import { OrderPaymentStatus } from '../../../../graphql/types.core-api';
import { RedoBadge } from '../../RedoBadge';
import { PATH_ORDERS } from '@farmersdog/constants/paths';

export interface PaymentFailedInfoCardProps {
  dateRange: string;
  orderId: number;
}

export function PaymentFailedInfoCard(props: PaymentFailedInfoCardProps) {
  const { paymentMethodUpdateNeeded, paymentMethodChangedForOrderLoading } =
    useGetPaymentFailedStatus({
      orderId: props.orderId,
      // this component should always render when the payment has failed
      orderPaymentStatus: OrderPaymentStatus.Failed,
    });

  const showPaymentWarningBadge =
    !paymentMethodChangedForOrderLoading && paymentMethodUpdateNeeded;
  const showRedoBadge =
    !paymentMethodChangedForOrderLoading && !paymentMethodUpdateNeeded;

  if (paymentMethodChangedForOrderLoading) {
    return (
      <section className={styles.loading}>
        <ActivityIndicator color="carrot" />
      </section>
    );
  }

  return (
    <section>
      <div className={styles.paymentFailedInfo}>
        <div>
          {showPaymentWarningBadge && (
            <div
              className={classNames(styles.badge, styles.paymentNeededBadge)}
            >
              <PaymentWarningBadge />
            </div>
          )}
          <Text
            as="h3"
            bold
            color="kale-3"
            variant="heading-28"
            className={styles.paymentFailedHeading}
          >
            Scheduled for <br className={styles.scheduledBreakpoint} />
            <span className={styles.dateRange}>{props.dateRange}</span>
          </Text>
          {showRedoBadge && (
            <div className={classNames(styles.badge, styles.redoBadge)}>
              <RedoBadge />
            </div>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            to={PATH_ORDERS}
            type="link"
            className={styles.viewOrderButton}
            onClick={trackViewOrderLinkClicked}
          >
            View Order
          </Button>
        </div>
      </div>
    </section>
  );
}
