import { RetryLink } from '@apollo/client/link/retry';

import config from '../../../config';

export function createRetryMiddleware() {
  return new RetryLink({
    delay: {
      initial: config.get('tosa.apollo.retry').delay,
      max: config.get('tosa.apollo.retry').delay,
    },
    attempts: {
      max: config.get('tosa.apollo.retry').maxAttempts,
      retryIf: error => Boolean(error),
    },
  });
}
