import { LeadView } from '../../../../graphql/types';
import { experimentCodesWhitelist } from '../constants';

interface GetShouldOverwriteCookieDiscountWithLeadDiscount {
  cookieReferrerCode: string | undefined;
  cookieReferrerAssociatedAt: Date | undefined;
  leadDiscount: LeadView['discount'] | undefined;
}

export function getShouldOverwriteCookieDiscountWithLeadDiscount({
  cookieReferrerCode,
  cookieReferrerAssociatedAt,
  leadDiscount,
}: GetShouldOverwriteCookieDiscountWithLeadDiscount): boolean {
  if (!leadDiscount) {
    return false;
  }

  if (leadDiscount && !cookieReferrerCode) {
    return true;
  }

  const cookieDiscountIsDefaultDiscount =
    !!cookieReferrerCode && experimentCodesWhitelist.has(cookieReferrerCode);

  const leadDiscountIsDefaultDiscount = experimentCodesWhitelist.has(
    leadDiscount?.code
  );

  const leadDiscountIsNewerThanCookieDiscount =
    !!cookieReferrerAssociatedAt &&
    new Date(leadDiscount.associatedAt) > cookieReferrerAssociatedAt;

  return (
    (cookieDiscountIsDefaultDiscount && !leadDiscountIsDefaultDiscount) ||
    (!cookieDiscountIsDefaultDiscount &&
      !leadDiscountIsDefaultDiscount &&
      leadDiscountIsNewerThanCookieDiscount)
  );
}
