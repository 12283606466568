import omit from 'lodash/omit';
import { Logger } from '@farmersdog/logger';
import config from 'src/config';

import a2b from 'src/utils/atob';
import {
  SIGNUP_REGISTER,
  SIGNUP_REGISTER_EMAIL,
  SIGNUP_FETCH_QUOTE,
  SIGNUP_FETCH_DIY_QUOTE,
  SIGNUP_REPLACE_USER,
  SIGNUP_FETCH_PLANS,
  SIGNUP_FETCH_PREVIOUS_USER,
  SIGNUP_SET_USER_REFERRER,
  SIGNUP_SET_CHECK_EXISTING,
  SIGNUP_SET_CARD_HOLDER,
  SIGNUP_SET_BILLING_AS_SHIPPING,
  SIGNUP_RESET,
  SIGNUP_SET_VALIDATION_ERROR,
  SIGNUP_SET_RECIPES_SUBMITTING,
  SIGNUP_SET_CHECKOUT_SUBMITTING,
  SIGNUP_SET_CHANGED_START_DATE,
  SIGNUP_SET_DIY_SUBSCRIPTION,
  SIGNUP_PROGRESS_RESET,
} from 'src/constants/actionTypes';
import { fetchPlans } from 'src/actions/pet';

import { post, get } from 'src/actions/actionCreators';
import { fetchQuote, fetchDiyQuote } from 'src/actions/subscription';
import { fetchUser } from 'src/actions/user';

const log = new Logger('app:actions:signup');

/**
 * Prepare userData for `/auth/register`
 *
 * @param {Object} userData the user's data object
 * @param {Object} subscription the user's subscription object
 */
const prepareUserData = (userData, subscription) => {
  const user = structuredClone(userData);

  // Cleanup stuff our API doesn't like
  // See https://app.clubhouse.io/farmersdog/story/296
  user.subscription = {
    ...subscription,
    startDate: subscription.startDate,
    // There's no need to send `nextDate`, it may contain an old value which
    // could cause a validation error.
    nextDate: undefined,
    trialEnabled: config('features.trial'),
    frequency: subscription.frequency,
  };
  user.activatedAt = undefined;
  user.createdAt = undefined;
  user.notes = undefined;
  user.referralCode = undefined;
  user.role = undefined;
  user.shippingOption = undefined;
  user.status = undefined;
  user.stripeCard = undefined;
  user.stripeCustomerURL = undefined;
  user.stripeId = undefined;
  user.token = undefined;
  user.updatedAt = undefined;
  if (user.pets) {
    user.pets.forEach((pet, i) => {
      if (pet.currentFood) {
        delete user.pets[i].currentFood.id;
      }

      // TODO: remove this when ch14767 is ready
      // https://app.clubhouse.io/farmersdog/story/14767
      if (!user.pets[i].birthday) {
        delete user.pets[i].birthday;
        delete user.pets[i].birthdayAccuracy;
      }

      // TODO: remove this when ch10278 is ready
      // https://app.clubhouse.io/farmersdog/story/10278/
      delete user.pets[i].age;

      delete user.pets[i].calculatedCalories;

      if (pet.plan && pet.plan.recipes && pet.plan.recipes.length === 0) {
        delete user.pets[i].plan;
      }

      if (pet.plan) {
        delete user.pets[i].plan.calories;
        delete user.pets[i].plan.portion;
        delete user.pets[i].plan.stock;
        delete user.pets[i].plan.transition;
        delete user.pets[i].plan.quantity;
      }

      // Cleanup extraneous data added during signup
      delete user.pets[i].hasHealthIssues;
      if (pet.issues) {
        user.pets[i].issues = pet.issues
          // Filter duplicated issues
          .filter(
            (issue, j, self) =>
              self.findIndex(item => item.name === issue.name) === j
          );
      }

      if (pet.breeds) {
        user.pets[i].breeds = pet.breeds.map(b => b.id);
      }
      user.pets[i].requiredCalories = undefined;
    });
  }
  if (user.shippingAddress) {
    user.shippingAddress = { ...user.shippingAddress };
    user.shippingAddress.id = undefined;
    if (user.shippingAddress.state && user.shippingAddress.state.id) {
      user.shippingAddress.state = user.shippingAddress.state.id;
    }
    if (user.shippingAddress.country && user.shippingAddress.country.id) {
      user.shippingAddress.country = user.shippingAddress.country.id;
    }
  }

  if (user.billingAddress) {
    user.billingAddress = { ...user.billingAddress };
    user.billingAddress.id = undefined;
    if (user.billingAddress.state && user.billingAddress.state.id) {
      user.billingAddress.state = user.billingAddress.state.id;
    }
    if (user.billingAddress.country && user.billingAddress.country.id) {
      user.billingAddress.country = user.billingAddress.country.id;
    }
  }

  return user;
};

/**
 * Register the user via API
 *
 * @param {Object} userData user data to register
 * @param {Object} [subscription] subscription to override current subscription
 *
 * @returns {Promise<Api.Response<Reducer.User>>}
 */
export const register = (userData, subscription = {}) => {
  const user = prepareUserData(userData, subscription);
  return post(SIGNUP_REGISTER, '/auth/register', user);
};

/**
 * Register the user via API but don't update the store
 *
 * @param {Object} userData user data to register
 * @param {Object} [subscription] subscription to override current subscription
 */
export const registerEmail = (userData, subscription = {}) => {
  const user = prepareUserData(userData, subscription);
  return post(SIGNUP_REGISTER_EMAIL, '/auth/register', user);
};

/**
 * Replace the user data (those coming from signup, except the checkout)
 * currently in the store.
 *
 * @param {Object} payload
 */
export const replaceUser = payload => ({
  type: SIGNUP_REPLACE_USER,
  payload,
});

/**
 * Fetch the DIY subscription quote for signup.
 *
 * @param {Object} user the user object.
 * @param {Object} [user.shippingAddress] The shipping address of the user.
 * @param {Object} [user.subscription] The subscription associated with the user.
 * @param {Object} [user.pets] The pets associated with the user.
 *
 *
 * @return {Promise<APIResponse>} A promise that resolves to the API response
 */
export const fetchDiySignupQuote = ({
  shippingAddress,
  subscription,
  pets,
}) => {
  const action = fetchDiyQuote({
    shippingAddress,
    subscription,
    pets,
  });
  if (action.error) {
    action.type = `${SIGNUP_FETCH_DIY_QUOTE}_FAILURE`;
  } else {
    action.type = SIGNUP_FETCH_DIY_QUOTE;
  }
  return action;
};

/**
 * Fetch the subscription quote for signup in suggested mode. Suggested mode
 * means that the frequency will be inferred by the API.
 *
 * @param {Object} user the user object.
 * @param {Object} [user.shippingAddress] The shipping address of the user.
 * @param {Object} [user.subscription] The subscription associated with the user.
 * @param {Object} [user.pets] The pets associated with the user.
 * @param {Number} [user.referrerId] The userId of the referrer.
 * @param {String} [user.referrerCode] The special url code of the referrer.
 * @param {String} [user.referrerType] The enumerated type of referrer.
 * @param {String} [user.referrerAssociatedAt] An ISO date string.
 *
 * @param {Object} options the options associated with this quote request
 *
 * @return {Promise<APIResponse>} A promise that resolves to the API response
 */
export const fetchSignupQuote = ({
  id,
  shippingAddress,
  subscription,
  pets,
  referrerId,
  referrerCode,
  referrerType,
  referrerAssociatedAt,
}) => {
  const action = fetchQuote(
    {
      id,
      shippingAddress,
      // These may be stale fields on the client. If we submit them then we will
      // get an inaccurate quote value. It is better to submit the subscription
      // "as we have it" and remove these fields so that the API is in charge of
      // the discount. We can remove this when we are sure that the referral
      // code and these values are 100% correct in redux 100% of the time.
      subscription: omit(subscription, [
        'trialPeriodDiscountPercentage',
        'discountPercentage',
      ]),
      pets,
      referrerId,
      referrerCode,
      referrerType,
      referrerAssociatedAt,
    },
    {
      mode: 'suggested',
    }
  );

  action.meta ||= {};
  // Sometimes quotes can be fetched in rapid succession and received out of
  // order; we compare state.fetchTimestamp against
  // action.meta.fetchTimestamp.current to see if the incoming quote is more
  // recently-requested than the one in state.
  action.meta.fetchTimestamp = Date.now();

  if (action.error) {
    action.type = `${SIGNUP_FETCH_QUOTE}_FAILURE`;
  } else {
    action.type = SIGNUP_FETCH_QUOTE;
  }
  return action;
};

/*
 * This function is used to decode the email param in the url.
 * It returns the decoded email if it is valid, otherwise it returns null.
 *
 *
 */
export const getDecodedEmail = email => {
  if (email && email.indexOf('@') === -1) {
    try {
      return a2b(email);
    } catch (err) {
      return null;
    }
  }
  return email;
};

export const fetchPreviousUser = email => {
  if (email) {
    let decodedEmail = email;
    if (email.indexOf('@') === -1) {
      log.debug('Decoding param', { email });
      try {
        decodedEmail = a2b(email);
      } catch (err) {
        log.debug('Error decoding params');
        return fetchUser();
      }
    }
    log.debug('Fetching user from email param', { decodedEmail });
    const action = register({ email: decodedEmail });
    return action;
  }
  log.debug('Fetching previous user from email', { email });
  const action = get(SIGNUP_FETCH_PREVIOUS_USER, '/user');
  action.meta = {
    ignoreFailure: true,
  };
  return action;
};

/**
 * Fetch plans for signup
 *
 * @returns {Api.Response<PetsPlanOptions>}
 */
export const fetchSignupPlans = pets => {
  const action = fetchPlans(pets);
  action.type = SIGNUP_FETCH_PLANS;
  return action;
};

export const setReferrer = payload => ({
  type: SIGNUP_SET_USER_REFERRER,
  payload,
});

/**
 * This action is used in the "Me" page: we want to check if the user already
 * exists and offer to restore the answers. We use this state to keep the
 * behavior consistent between the signup pages.
 *
 * @param {Boolean} payload
 * @returns {Object}
 */
export const setCheckExistingUser = payload => ({
  type: SIGNUP_SET_CHECK_EXISTING,
  payload,
});

export const setCardHolder = payload => ({
  type: SIGNUP_SET_CARD_HOLDER,
  payload,
});

/**
 * This is used to determine if user has manually changed the start date.
 * If user hasn't changed the date, this field is null.
 *
 * @param {string | null} payload
 * @returns {Object}
 */
export const setChangedStartDate = payload => ({
  type: SIGNUP_SET_CHANGED_START_DATE,
  payload,
});

export const setDiySubscription = payload => ({
  type: SIGNUP_SET_DIY_SUBSCRIPTION,
  payload,
});

export const setBillingAsShipping = payload => ({
  type: SIGNUP_SET_BILLING_AS_SHIPPING,
  payload,
});

export const setValidationError = payload => ({
  type: SIGNUP_SET_VALIDATION_ERROR,
  payload,
});

export const setRecipesSubmitting = payload => ({
  type: SIGNUP_SET_RECIPES_SUBMITTING,
  payload,
});

export const setCheckoutSubmitting = payload => ({
  type: SIGNUP_SET_CHECKOUT_SUBMITTING,
  payload,
});

export const resetSignup = () => ({ type: SIGNUP_RESET });

export const resetProgress = () => ({
  type: SIGNUP_PROGRESS_RESET,
});
