import { Pixel } from '../Pixel';

/**
 * The base attributes for the Radio.com vendor
 */
export const vendorAttributes = {
  baseUrl: 'https://trkn.us/pixel/conv/',
  vendorName: 'Radio',
  stringifyOptions: {
    delimiter: ';',
  },
};

/**
 * An interface representing the freeform attributes that can be submitted in a
 * Radio.com pixel event
 */
interface RadioEventAttributes extends Record<string, unknown> {
  ppt: string;
  g: string;
  gid: string;
  cv1?: string;
}

export const radio = new Pixel<RadioEventAttributes>(vendorAttributes);
