import { useCallback, useState } from 'react';

/**
 * Kind of hackish approach recommended by react team. This is because error
 * boundary does not catch errors from asynchronous code
 *
 * https://github.com/facebook/react/issues/14981
 */
export function useThrowToErrorBoundary() {
  const [, setError] = useState();

  return useCallback((error: unknown) => {
    setError(() => {
      throw error;
    });
  }, []);
}
