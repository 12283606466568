/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Logger } from '@farmersdog/logger';
import { session } from 'src/utils/browserStorage';
import { anonymousId as tfdAnonymousId } from '@farmersdog/lead-browser-storage';
import { ENGAGEMENT_TRACKER_VERSION } from '@farmersdog/utils';
import { getSessionProperties } from '@farmersdog/sessions';
import {
  UTM_CAMPAIGN,
  UTM_CONTENT,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_TERM,
} from 'src/constants/sessionStorage';

/**
 * Prepare and return request header object. These header params should be used
 * with all requests.
 */
export function prepareGlobalRequestHeaders() {
  const headers = {} as Record<string, string | number>;
  const utmCampaign = session.getItem(UTM_CAMPAIGN);
  const utmMedium = session.getItem(UTM_MEDIUM);
  const utmSource = session.getItem(UTM_SOURCE);
  const utmTerm = session.getItem(UTM_TERM);
  const utmContent = session.getItem(UTM_CONTENT);
  const anonymousId = tfdAnonymousId.get();
  const sessionData = getSessionProperties(ENGAGEMENT_TRACKER_VERSION);

  if (anonymousId) {
    headers['Anonymous-ID'] = anonymousId;
  }
  if (utmCampaign) {
    headers['UTM-Campaign'] = utmCampaign;
  }
  if (utmMedium) {
    headers['UTM-Medium'] = utmMedium;
  }
  if (utmSource) {
    headers['UTM-Source'] = utmSource;
  }
  if (utmTerm) {
    headers['UTM-Term'] = utmTerm;
  }
  if (utmContent) {
    headers['UTM-Content'] = utmContent;
  }
  if (sessionData) {
    headers['Session-ID'] = sessionData.sessionId;
    headers['Session-Version'] = sessionData.sessionVersion;
  }

  const safeHeaders = Object.fromEntries(
    Object.entries(headers).map(([key, val]) => {
      if (key.toLowerCase().startsWith('utm')) {
        try {
          const encodedVal = encodeURIComponent(val);
          return [key, encodedVal];
        } catch (error) {
          const logger = new Logger(
            'website:frontend:prepareGlobalRequestHeaders'
          );
          logger.error('failed to encode utm value', { error, key, val });
        }
      }
      return [key, val];
    })
  );

  return safeHeaders;
}
