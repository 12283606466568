import { useState, ChangeEvent, FormEvent } from 'react';
import { Check } from '@farmersdog/corgi';
import { Input, FormControl, Button, Text } from '@farmersdog/corgi-x';
import { identify } from '@farmersdog/analytics';
import { isValidEmail } from '@farmersdog/utils';

import styles from './NewsletterForm.module.css';

export function NewsletterForm() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    if (!isValidEmail(email)) {
      console.error('Invalid e-mail: %s', email);
      return;
    }
    setIsSubmitting(true);
    // We subscribe emails just by identifying the user via e-mail
    identify(email);
    // Add visual feedback that the form has been submitted
    setIsSubmitting(false);
    setSubmitted(true);
  }

  return (
    <form name="NewsletterForm" onSubmit={handleSubmit}>
      <FormControl
        aria-live="polite"
        message={
          submitted && (
            <Text color="white" variant="heading-16" aria-live="assertive">
              <Check aria-hidden="true" fill="white" size={12} /> Subscribed!
            </Text>
          )
        }
      >
        <div className={styles.elements}>
          <Input
            label="Email"
            name="email"
            aria-label="Email address"
            value={email}
            type="email"
            onChange={handleChange}
            autoComplete="email"
          />
          <Button
            variant="primary"
            aria-disabled={isSubmitting || !isValidEmail(email)}
            type="submit"
            className={styles.button}
          >
            Submit
          </Button>
        </div>
      </FormControl>
    </form>
  );
}
