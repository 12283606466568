import { CgsBodyCondition } from '../../graphql/types.cgs';
import chunkyPNG from './chunky.png';
import justRightPNG from './just-right.png';
import roundedPNG from './rounded.png';
import tooSkinnyPNG from './too-skinny.png';

import chunkyExpandedPNG from './chunky-expanded.png';
import justRightExpandedPNG from './just-right-expanded.png';
import roundedExpandedPNG from './rounded-expanded.png';
import tooSkinnyExpandedPNG from './too-skinny-expanded.png';

export const bodyConditionResourcesSmall = {
  [CgsBodyCondition.Chunky]: [chunkyPNG],
  [CgsBodyCondition.JustRight]: [justRightPNG],
  [CgsBodyCondition.Rounded]: [roundedPNG],
  [CgsBodyCondition.TooSkinny]: [tooSkinnyPNG],
};

export const bodyConditionResourcesExpanded = {
  [CgsBodyCondition.Chunky]: [chunkyExpandedPNG],
  [CgsBodyCondition.JustRight]: [justRightExpandedPNG],
  [CgsBodyCondition.Rounded]: [roundedExpandedPNG],
  [CgsBodyCondition.TooSkinny]: [tooSkinnyExpandedPNG],
};
