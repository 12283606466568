import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';
import * as Path from '@farmersdog/constants/paths';

import { trackNextDoorPageView } from './trackNextDoorPageView';
import { shouldRunPageViewFromWebsite } from '../utils';
export const nextDoorPageViewRoutes = [
  Path.PATH_HOME,
  Path.PATH_SIGNUP_ME,
  Path.PATH_SIGNUP_RECIPES,
  Path.PATH_SIGNUP_CHECKOUT,
];

interface UseTrackNextDoorPageViewArgs {
  isRecipesPage: boolean;
  showTosaRecipesPage: boolean;
}
/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a next door page view event.
 */
export function useTrackNextDoorPageView({
  isRecipesPage,
  showTosaRecipesPage,
}: UseTrackNextDoorPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  const { previous } = useSignupRouter();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isFirstPetsPage = pathname === Path.PATH_SIGNUP_PETS && previous?.me;

  const shouldRunFromWebsite = shouldRunPageViewFromWebsite({
    pathname,
    isRecipesPage,
    showTosaRecipesPage,
  });

  useEffect(() => {
    if (shouldRunFromWebsite) {
      if (nextDoorPageViewRoutes.includes(pathname) || isFirstPetsPage) {
        trackNextDoorPageView();
      }
    }
  }, [pathname, isFirstPetsPage, shouldRunFromWebsite]);
}
