import { v4 as uuidv4 } from 'uuid';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
} from '@farmersdog/constants/paths';

import { trackTikTokEvent } from './trackTikTokEvent';
import { useSelector } from 'react-redux';

import { selectUser as selectSignupUser } from 'src/reducers/signup/user';
import {
  TIKTOK_PAGE_VIEW_EVENT,
  TIKTOK_INITIATE_CHECKOUT_EVENT,
} from './events';
import { shouldRunPageViewFromWebsite } from '../utils';
import { track } from 'src/analytics';

export const tikTokPageViewRoutes = [
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
];
import eventNames from '../../analytics/eventNames';

interface UseTrackTikTokPageViewArgs {
  isRecipesPage: boolean;
  showTosaRecipesPage: boolean;
}

export function useTrackTikTokPageView({
  isRecipesPage,
  showTosaRecipesPage,
}: UseTrackTikTokPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;
  const user = useSelector(selectSignupUser);

  const shouldRunFromWebsite = shouldRunPageViewFromWebsite({
    pathname,
    isRecipesPage,
    showTosaRecipesPage,
  });

  useEffect(() => {
    if (shouldRunFromWebsite) {
      if (tikTokPageViewRoutes.includes(pathname)) {
        const pageViewEventUuid = uuidv4(); // ensure there's a different UUID for each event
        void trackTikTokEvent(TIKTOK_PAGE_VIEW_EVENT, {
          email: user?.email,
          phone: user?.phone,
          event_id: pageViewEventUuid,
        });
        // call segement track with a special event to deduplicate tiktok events in S2S integration
        track(eventNames.tiktok_page_view, {
          email: user?.email,
          phone: user?.phone,
          eventId: pageViewEventUuid,
        });
      }
      if (pathname === PATH_SIGNUP_CHECKOUT) {
        const initiateCheckoutUuid = uuidv4(); // ensure there's a different UUID for each event
        void trackTikTokEvent(TIKTOK_INITIATE_CHECKOUT_EVENT, {
          email: user?.email,
          phone: user?.phone,
          event_id: initiateCheckoutUuid,
        });
        track(eventNames.tiktok_initiate_checkout, {
          email: user?.email,
          phone: user?.phone,
          eventId: initiateCheckoutUuid,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, shouldRunFromWebsite]);
}
