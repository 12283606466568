import { EngagementTrackerVersion } from '@farmersdog/utils';
import { SessionData } from './utils';

import { refreshSession } from './refresh-session';

export interface SessionPropertiesData {
  sessionId: SessionData['id'];
  sessionVersion: SessionData['version'];
}

export type GetSessionPropertiesReturn = SessionPropertiesData | undefined;

export function getSessionProperties(
  currentTrackerVersion: EngagementTrackerVersion
): GetSessionPropertiesReturn {
  // always make sure a session exists and it has the latest expiration date before doing a read
  const currentSession = refreshSession(currentTrackerVersion);
  return {
    sessionId: currentSession.id,
    sessionVersion: currentSession.version,
  };
}
