import { configureGa4 } from './configureGa4';

interface SendGa4UserIdArgs {
  userId: number | undefined;
}

export function sendGa4UserId({ userId }: SendGa4UserIdArgs) {
  if (userId) {
    configureGa4({ properties: { userId } });
  }
}
