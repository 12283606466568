import { Maybe } from 'graphql/jsutils/Maybe';
import { UseFeature } from '../../../../experiments/ExperimentsContext';

// Experiment: Box Progression in Account (Non-Picky)
export const SplitKey = 'onb_homepage_plan_progression';

export enum Treatment {
  /* Variant 1 */
  On = 'on',
  /* Control */
  Off = 'off',
}

export const Feature: FeatureType = {
  treatments: Object.values(Treatment),
  track: true,
  config: null,
  mock: {
    treatment: Treatment.Off,
    config: null,
  },
};

export interface Attributes extends SplitIO.Attributes {
  isEligible: boolean;
  activatedAt: number;
}

export type Config = object;

export interface ExperimentAttributes {
  hasPickyEater: boolean;
  regularOrdersDeliveredCount: number;
}

export interface FeatureType {
  treatments: Treatment[];
  config?: Maybe<Config>;
  track: boolean;
  mock?: {
    treatment: Treatment;
    config?: Maybe<Config>;
  };
}

export type UseFeatureType = UseFeature<Treatment, Config, Attributes>;
