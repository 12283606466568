import PropTypes from 'prop-types';
import { TextV2, Link } from '@farmersdog/corgi';
import { Button } from '@farmersdog/corgi-x';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';

import { TITLE } from './copy';
import { PATH_FAQ } from '@farmersdog/constants/paths';
import { EmailSupportButton } from 'src/components/EmailSupportButton';
import { isDiySignup } from '@farmersdog/lead-browser-storage';
import { EMAIL_DIY_HELP, EMAIL_HELP } from '@farmersdog/constants/emails';
import ActionButtons from './ActionButtons';

function MoreInfo({ goToPrevSlide }) {
  const email = isDiySignup() ? EMAIL_DIY_HELP : EMAIL_HELP;
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          Here to help! Please reach out to our customer service team by{' '}
          <EmailSupportButton subject="More Information" asLink email={email}>
            sending us a message
          </EmailSupportButton>
          ! You can also visit our{' '}
          <Link to={PATH_FAQ} target="_blank" rel="noopener noreferrer">
            FAQ
          </Link>{' '}
          in the meantime.
        </TextV2>
        <TextV2 as="p" variant="body-12" color="charcoal-1">
          Contact us at {email}
        </TextV2>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <EmailSupportButton subject="More Information" email={email}>
            Email Us
          </EmailSupportButton>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

MoreInfo.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  ticketMessage: PropTypes.string.isRequired,
  onTicketMessageChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export const SLIDE_NAME = 'MORE_INFO';

export default MoreInfo;
