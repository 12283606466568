import { useId } from 'react';
import {
  ContentLoader,
  Link,
  PaddedCard,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { PATH_EDIT_BUFFER } from '@farmersdog/constants/paths';
import { reporter } from '../errors/services';
import { pluralizeString } from '../utils';
import { useFetchBufferSummaryData } from './network';
import styles from './BufferSummary.module.css';

export function BufferSummary() {
  const request = useFetchBufferSummaryData({
    onError: (error: unknown) => {
      const errorMessage = 'Error retrieving BufferSummary data';
      reporter.warn(errorMessage, { sourceError: error });
    },
  });
  const id = useId();
  const { medium } = useViewport();

  const loadingHeight = medium ? 120 : 241;

  if (request.error) return null;

  if (!request.loading && !request.data) {
    return null;
  }

  return (
    <section aria-labelledby={id} className={styles.parentSection}>
      <PaddedCard className={styles.bufferSummaryCard}>
        <ContentLoader loading={request.loading} height={loadingHeight}>
          {request.data && (
            <>
              <Text id={id} variant="heading-28" color="kale-3" as="h2" bold>
                Safety Stock
              </Text>
              <Text variant="article-16">
                We&apos;ll time your orders to arrive when you have{' '}
                <Text variant="article-16" bold>
                  {pluralizeString(request.data.bufferDays, 'day', 'days', {
                    prependQuantity: true,
                  })}{' '}
                  of food left.
                </Text>
              </Text>
              <Link to={PATH_EDIT_BUFFER}>
                <Text
                  as="p"
                  variant="heading-16"
                  color="carrot-2"
                  bold
                  bottomSpacing="none"
                >
                  Edit
                </Text>
              </Link>
            </>
          )}
        </ContentLoader>
      </PaddedCard>
    </section>
  );
}
