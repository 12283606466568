import { Button, Text } from '@farmersdog/corgi-x';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import styles from './CallToAction.module.scss';
import type { ReactNode } from 'react';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

interface Props {
  children: ReactNode;
}

function CallToAction({ children }: Props) {
  const { isAccessibilityThemeOn, accessibilityThemeStyles } =
    useAccessibilityThemeContext();

  return (
    <div
      className={
        isAccessibilityThemeOn
          ? styles.accessibilityThemeContainer
          : styles.container
      }
    >
      <Button
        type="link"
        to={PATH_SIGNUP_ME}
        className={accessibilityThemeStyles.responsiveCta}
      >
        <Text bold variant="heading-16">
          {children}
        </Text>
      </Button>
    </div>
  );
}

export default CallToAction;
