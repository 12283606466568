import { reporter } from '../../../../../../errors/services';
import { useFetchHomepageData } from '../../../../../network';
import { isEligibleForOrderSizeCtaByCriteria } from '../utils/isEligibleForOrderSizeCta';
import { useOrderSizeNudgeStorage } from './useOrderSizeNudgeStorage';

/**
 * A wrapper to format data for the Order Size Dismissible CTA.
 *
 * This data is exported so that logic will be shared when checking for
 * the useEligibility slot hook and the Treats Nudge slot hook, as well
 * as the temporary usage in HomepageHero.
 */
export function useOrderSizeCtaData() {
  const { loading, data, error } = useFetchHomepageData({
    onError: (err: unknown) => {
      reporter.warn('Error retrieving data for the Order Size CTA', {
        sourceError: err,
      });
    },
  });
  const { getOrderSizeNudgeDismissed } = useOrderSizeNudgeStorage(
    window.localStorage
  );

  const frequency = data?.customer.freshSubscription.frequency ?? 0;
  const regularOrdersDeliveredCount =
    data?.customer.freshSubscription.regularOrdersDeliveredCount ?? 0;
  const nextOrderIsUnlocked =
    data?.customer.nextOrderToBeDelivered?.isUnlocked ?? false;

  const criteria = {
    frequency,
    regularOrdersDeliveredCount,
    nextOrderIsUnlocked,
  };

  const eligibleByCriteria = isEligibleForOrderSizeCtaByCriteria({
    criteria,
  });
  const noData = !data;
  const isReady = !loading && !error && !noData;

  const isDismissed = getOrderSizeNudgeDismissed();

  return {
    isReady,
    eligibleByCriteria,
    isDismissed,
  };
}
