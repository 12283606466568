import {
  getUniqueBreedNamesFromPets,
  getUniqueHealthIssueNamesFromPets,
} from 'src/utils/pet';

import btoa from 'src/utils/btoa';

/**
 * Parse a user object and return the traits useful for identifying the user in
 * analytics.
 *
 * @param {Object} user
 * @return {Object}
 */
function getTraits(user) {
  const {
    email,
    phone,
    firstName,
    lastName,
    fullName,
    shippingAddress = {},
    pets,
  } = user;

  const parsedTraits = {
    email,
    phone,
    firstName,
    // This isn't a foolproof way to arrive at the last name if it's not
    // explicitly provided, but it's sufficient for analytics purposes.
    // It gives us everything after the first word in the full name to act
    // as the last name.
    lastName:
      lastName || (fullName && fullName.replace(/ {0,}(\w*) ([\w ]*)/gm, '$2')),
    address: {
      postalCode: shippingAddress.zip,
      city: shippingAddress.city,
      state: shippingAddress.state && shippingAddress.state.abbr,
      addressLine1: shippingAddress.addressLine1,
      addressLine2: shippingAddress.addressLine2,
    },
    emailBase64: email ? btoa(unescape(encodeURIComponent(email))) : undefined,
    zip: shippingAddress.zip,
    petsCount: pets ? pets.length : 0,
    pets: pets ? pets.map(pet => pet.name) : undefined,
    breeds: getUniqueBreedNamesFromPets(pets),
    issues: getUniqueHealthIssueNamesFromPets(pets),
  };

  return parsedTraits;
}

export default getTraits;
