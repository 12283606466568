import classNames from 'classnames';
import { FocusEvent } from 'react';

import { withRef, WithRefProps, heading } from '@farmersdog/corgi';
import { interceptDOMEvent, handleControlledCursor } from '@farmersdog/utils';

import { ExtendedTextInputChangeEvent } from '../../../../types';

import styles from './InlineInput.module.css';

const typography = heading.create({ size: 28 });

type InputAttributesType = JSX.IntrinsicElements['input'];
type RefProps = WithRefProps<HTMLInputElement>;

interface InlineInputProps extends RefProps, InputAttributesType {
  accessibleLabel: string;
  onChange: (e: ExtendedTextInputChangeEvent) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

export const InlineInput = withRef<HTMLInputElement, InlineInputProps>(
  ({ type, className, forwardedRef, accessibleLabel, ...props }) => {
    return (
      <>
        <label htmlFor={props.name} className={styles.label}>
          {accessibleLabel}
        </label>
        <input
          className={classNames(
            styles.InlineInput,
            typography.className,
            className
          )}
          type={type === 'number' ? 'text' : type}
          lang="en-150"
          {...props}
          ref={forwardedRef}
          onChange={interceptDOMEvent(handleControlledCursor, props.onChange)}
          id={props.name}
        />
      </>
    );
  }
);
