export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
};

export type AddClinicInput = {
  clinic: ClinicInput;
  clinicEmail?: InputMaybe<Scalars['String']['input']>;
  clinicPhone?: InputMaybe<Scalars['String']['input']>;
  vetName?: InputMaybe<Scalars['String']['input']>;
};

export type AddCreditCardInput = {
  token: Scalars['String']['input'];
};

export type AddPetInput = {
  activityLevel: Scalars['String']['input'];
  ageNumber: Scalars['Float']['input'];
  ageUnit: Scalars['String']['input'];
  bodyCondition: Scalars['String']['input'];
  breed: Array<Scalars['String']['input']>;
  currentWeight: Scalars['Float']['input'];
  eatingStyle: Scalars['String']['input'];
  healthIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  petDietIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipes: Array<Scalars['String']['input']>;
  sex: Scalars['String']['input'];
  sterilization: Scalars['Boolean']['input'];
  targetWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type BillNowInput = {
  orderId: Scalars['Int']['input'];
};

export type CgsAddressInput = {
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['input'];
  /** The second line of the customer's address, if present */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The state abbreviation */
  stateAbbreviation: Scalars['String']['input'];
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export type CgsBillingInformationInput = {
  billingAddress: CgsAddressInput;
  stripeToken: Scalars['String']['input'];
};

export type CgsUpdateCustomerWithSuspendedSubscriptionInput = {
  billingInformation?: InputMaybe<CgsBillingInformationInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shippingAddress: CgsAddressInput;
};

export enum CgsActivityLevel {
  Active = 'Active',
  NotVeryActive = 'NotVeryActive',
  ProAthlete = 'ProAthlete',
  VeryActive = 'VeryActive',
}

export enum CgsBodyCondition {
  Chunky = 'Chunky',
  JustRight = 'JustRight',
  Rounded = 'Rounded',
  TooSkinny = 'TooSkinny',
}

export enum CgsOrderDiscountReason {
  SecondBoxDiscount = 'SecondBoxDiscount',
  SubscriberFreeFirstTreats = 'SubscriberFreeFirstTreats',
  SubscriptionDiscount = 'SubscriptionDiscount',
  SurpriseFreeTreats = 'SurpriseFreeTreats',
  TreatsInCheckout = 'TreatsInCheckout',
  TrialDiscount = 'TrialDiscount',
}

export enum CgsOrderDiscountType {
  /** A discount applied to the entire order */
  Order = 'order',
  /** A discount applied to a specific SKU */
  Sku = 'sku',
}

export enum CgsOrderState {
  Cancelled = 'cancelled',
  Current = 'current',
  Delivered = 'delivered',
  Future = 'future',
  Invalid = 'invalid',
  Next = 'next',
}

export enum CgsOrderType {
  Regular = 'regular',
  Trial = 'trial',
}

export enum CgsPaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Free = 'free',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Processing = 'processing',
  Queued = 'queued',
  Refunded = 'refunded',
  Transferred = 'transferred',
  Unpaid = 'unpaid',
}

export type CgsPetsInput = {
  /** Pet that is labeled 'no contact' for marketing or other reasons */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the requested pet */
  petName?: InputMaybe<Scalars['String']['input']>;
};

export type CgsRecipeDetailsInput = {
  /** Product Name to fetch */
  productName: Scalars['String']['input'];
};

export enum CgsShipmentStatus {
  AcquisitionFailed = 'acquisition_failed',
  Cancelled = 'cancelled',
  /** No longer in use */
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  /** No longer in use */
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Scheduled = 'scheduled',
  /** No longer in use */
  Shipped = 'shipped',
  Transit = 'transit',
  Unscheduled = 'unscheduled',
}

export enum CgsVariantType {
  Diy = 'diy',
  Food = 'food',
  FoodContainer = 'food_container',
  Leaflet = 'leaflet',
  Packaging = 'packaging',
  Supplement = 'supplement',
  Treat = 'treat',
}

export enum Cgs_SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
}

export type ChangeFreshOrderSizeInput = {
  orderSize: Scalars['Int']['input'];
};

export type ChangeFreshRecipesPlanQuoteInput = {
  petId: Scalars['Int']['input'];
  /** Updated recipes to use as quote input */
  recipes: Array<ChangeFreshRecipesPlanQuoteRecipeInput>;
};

export type ChangeFreshRecipesPlanQuoteRecipeInput = {
  /** Recipe display name */
  displayName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  /** Recipe machine name */
  name: Scalars['String']['input'];
};

export enum ClinicDataSource {
  Google = 'Google',
  User = 'User',
}

export type ClinicInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  source: ClinicDataSource;
  state: Scalars['String']['input'];
  streetAddress1: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type DiyDownsellInput = {
  cadence: Scalars['Int']['input'];
  nextOrderShipDate: Scalars['Date']['input'];
  numberOfBoxes: Scalars['Int']['input'];
};

export enum FreshFoodConfidence {
  /** Answered 'I already believe' that fresh food is the healthiest during signup */
  AlreadyBelieve = 'AlreadyBelieve',
  /** Answered 'I do not believe' that fresh food is the healthiest during signup */
  DoNotBelieve = 'DoNotBelieve',
  /** Answered 'I am not yet sure' that fresh food is the healthiest during signup */
  NotYetSure = 'NotYetSure',
  /** User did not answer the question */
  ValueNotAvailable = 'ValueNotAvailable',
}

export type HasTreatsInOrdersInput = {
  /** Whether to exclude the trial order when determining if user has treats in any orders */
  excludeTrialOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum JourneyStatus {
  /** The customer has completed checkout and is awaiting their first box */
  AwaitingFirstBox = 'AwaitingFirstBox',
  /** The customer has received their first box and is awaiting their second box */
  ReceivedFirstBox = 'ReceivedFirstBox',
  /** The customer has received their second box */
  ReceivedSecondBox = 'ReceivedSecondBox',
}

export enum OrderDeliveryStatus {
  /** When an order has reached its destination */
  Delivered = 'Delivered',
  /** When an order has failed shipment */
  Failed = 'Failed',
  /** When an order has failed payment */
  PaymentFailed = 'PaymentFailed',
  /** When an order is in production (shipment scheduled, billed, cooked, packed, etc) prior to leaving the facility */
  Preparing = 'Preparing',
  /** When no action has been taken on an order but is available in our system */
  Scheduled = 'Scheduled',
  /** When an order is out for delivery */
  Transit = 'Transit',
}

export type PauseFreshSubscriptionInput = {
  resumeDate: Scalars['String']['input'];
};

export type ReactivateDiySubscriptionInput = {
  nextDate: Scalars['Date']['input'];
};

export type ReactivateFreshSubscriptionInput = {
  nextDate: Scalars['Date']['input'];
};

export type RemoveClinicInput = {
  clinicId: Scalars['String']['input'];
};

export type SuggestOrderSizeQuoteInput = {
  /** Order size for which we want to generate a quote */
  orderSize: Scalars['Int']['input'];
};

export type SuspendFreshSubscriptionInput = {
  cancellationReasons: Array<Scalars['String']['input']>;
  cookAtHomeReplacementRecipe?: InputMaybe<Scalars['String']['input']>;
  freeResponse?: InputMaybe<Scalars['String']['input']>;
  prescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  replacementFoodBrand?: InputMaybe<Scalars['String']['input']>;
  replacementFoodType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreditCardInput = {
  /** The first line of the billing address */
  addressLine1: Scalars['String']['input'];
  /** The second line of the billing address, if present */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The country of the address */
  country: Scalars['String']['input'];
  /** id of the credit card to update */
  id: Scalars['String']['input'];
  /** The name on the credit card */
  name: Scalars['String']['input'];
  /** The state abbreviation */
  state: Scalars['String']['input'];
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export type UpdateCustomerNotificationPreferencesInput = {
  marketingNotificationsSms: Scalars['Boolean']['input'];
  orderRemindersSms: Scalars['Boolean']['input'];
  orderUpdatesSms: Scalars['Boolean']['input'];
};

export type UpdateFoodPlanRecipesInput = {
  /** The plan id associated with this this update */
  id: Scalars['Int']['input'];
  selectedRecipes: Array<UpdateRecipeInput>;
};

export type UpdateFoodPlansRecipesInput = {
  foodPlans: Array<UpdateFoodPlanRecipesInput>;
  freeFormFeedback?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRecipeInput = {
  /** The recipe id associated with this update */
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type ValidateVetInput = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
};
