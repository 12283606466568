import track from '../track';
import events from '../eventNames';

interface TrackGladlySidekickClickArgs {
  path: string;
  initiator: string;
}

export function trackGladlySidekickClick({
  path,
  initiator,
}: TrackGladlySidekickClickArgs) {
  track(events.gladly_sidekick_clicked, {
    path,
    initiator,
  });
}
