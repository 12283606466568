import { FISH, FISH_MEAT_BLEND, MEAT_MEAT_BLEND } from '../newProteinOptions';

export function getProteinBlendQuestionsToShow(proteins: string[]) {
  const selectedFishMeatBlend = proteins.includes(FISH_MEAT_BLEND.value);
  const selectedFish = proteins.includes(FISH.value);
  const selectedMeatMeatBlend = proteins.includes(MEAT_MEAT_BLEND.value);

  return {
    fishMeatBlend: selectedFishMeatBlend,
    fish: selectedFish && !selectedFishMeatBlend,
    meatMeatBlend: selectedMeatMeatBlend,
  };
}
