import { StripeElementsProvider, StripeProvider } from '@farmersdog/corgi-x';
import { PaymentRequestContextProvider } from 'src/stripe';
import { ExpressPayAttemptProvider } from '../SignupCheckoutPage/hooks/useExpressPayAttempt';
import { StatelessFreshQuoteProvider } from '../SignupCheckoutPage/hooks/useStatelessFreshQuote';

import config from 'src/config';

export const SignupProviders = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const stripeKey = config('stripe.publicKey') as string;

  return (
    <StripeProvider publishableKey={stripeKey}>
      <StripeElementsProvider>
        <PaymentRequestContextProvider>
          <ExpressPayAttemptProvider>
            <StatelessFreshQuoteProvider>
              {children}
            </StatelessFreshQuoteProvider>
          </ExpressPayAttemptProvider>
        </PaymentRequestContextProvider>
      </StripeElementsProvider>
    </StripeProvider>
  );
};
