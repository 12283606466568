import classNames from 'classnames';

import { Picture, Text } from '@farmersdog/corgi-x';

import styles from './TestimonialImage.css';

interface TestimonialImageProps {
  sources: string[];
  description: string;
  className?: string;
  alt: string;
}

export function TestimonialImage({
  sources,
  description,
  className,
  alt,
}: TestimonialImageProps) {
  return (
    <div className={classNames(styles.container, className)}>
      <Picture className={styles.image} sources={sources} alt={alt} />
      <div className={styles.descriptionContainer}>
        <Text
          className={styles.description}
          variant="heading-12"
          color="white"
          bold
        >
          {description}
        </Text>
      </div>
    </div>
  );
}
