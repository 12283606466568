import { DiyRecipe, PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const chickenCouscousAndApplesForPuppies: DiyRecipe = {
  name: 'Chicken, Couscous, and Apples for Puppies',
  isPuppy: true,
  primaryProtein: PrimaryProtein.Chicken,
  ingredients: [
    {
      name: 'ground chicken (93% lean)',
      quantity: 600,
      unit: 'g',
      alternativeQuantity: 1.3,
      alternativeUnit: 'lb',
    },
    {
      name: 'spinach',
      quantity: 260,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 8.75, unit: 'cup' },
        { name: 'frozen', quantity: 1.5, unit: 'cup' },
      ],
    },
    {
      name: 'cooked couscous',
      quantity: 200,
      unit: 'g',
      alternativeQuantity: 1.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'apples, cut into 1/4 inch dice',
      quantity: 120,
      unit: 'g',
      alternativeQuantity: 1,
      alternativeUnit: 'cup',
    },
    {
      name: 'cooked chickpeas',
      quantity: 80,
      unit: 'g',
      alternativeQuantity: 0.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
      alternativeQuantity: 22.5,
      alternativeUnit: 'g',
    },
    {
      name: 'sunflower oil',
      quantity: 8,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 6,
      unit: 'g',
      alternativeQuantity: 1.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salt, non iodized',
      quantity: 1,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientSummary:
    'Ground Chicken, Spinach, Couscous, Apples, Chickpeas, DIY Nutrient Mix, Sunflower Oil, Salmon Oil, Salt',
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  id: 'chicken-couscous-and-apples-for-puppies',
  yieldWeight: 2.9,
  cookTime: '40 mins',
  calories: 1537,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook the couscous according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 3',
      description:
        'Add the ground chicken to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 4',
      description:
        'Add the cooked couscous, apples, spinach, and cooked chickpeas. Cook until all ingredients are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the salmon oil and salt and stir to evenly distribute. If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
};
