import {
  Grid,
  GridItem,
  IconButton,
  Link,
  Text,
  Tooltip,
} from '@farmersdog/corgi-x';
import { useId } from 'react';

import { PAW_PATH_MY_DOGS } from '@farmersdog/constants/paths';
import { Information } from '@farmersdog/corgi-x/icons';

export function FrequencyHelp() {
  const id = useId();
  const helpPrompt = 'Adjust how often you receive deliveries.';

  return (
    <Grid topSpacing="sm" bottomSpacing="md">
      <GridItem xs={12}>
        <Text variant="article-16" color="kale-3" id={id}>
          {helpPrompt}
        </Text>
        <Tooltip
          position="bottom"
          content={
            <Text variant="article-16" color="kale-3">
              Opt for less food at a time to save freezer space, or more food at
              a time to save money.
              <br />
              If you would like to feed less food per meal, or mix our food with
              another food,{' '}
              <Link to={PAW_PATH_MY_DOGS}>please change your meal size</Link>.
            </Text>
          }
        >
          <IconButton
            aria-label={helpPrompt}
            icon={<Information baseColor="Kale3" />}
          />
        </Tooltip>
      </GridItem>
    </Grid>
  );
}
