import { useLazyQuery } from '@apollo/client';
import {
  ValidateClinicQuery,
  ValidateClinicQueryVariables,
} from './ValidateClinic.cgs.generated-types';
import ValidateClinic from './ValidateClinic.cgs.graphql';

export function useValidateClinic() {
  const [validateClinic, { loading }] = useLazyQuery<
    ValidateClinicQuery,
    ValidateClinicQueryVariables
  >(ValidateClinic, {
    context: { endpoint: 'cgs' },
  });

  return {
    validateClinic,
    loading,
  };
}
