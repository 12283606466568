import { Redo } from '@farmersdog/corgi-x/icons';
import { Text } from '@farmersdog/corgi-x';

import styles from './RedoBadge.module.css';

export const ORDER_RETRY_PAYMENT_TEXT =
  'We’ll automatically retry your payment soon';

export function RedoBadge() {
  return (
    <div className={styles.root}>
      <Redo />
      <Text color="blueberry-3" variant="heading-16">
        {ORDER_RETRY_PAYMENT_TEXT}
      </Text>
    </div>
  );
}
