import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { cookie } from '@farmersdog/lead-browser-storage';

type ChannelGroupingAttributes = {
  [key: string]: string | boolean;
};

export function useGetChannelGroupingAttributesInQueryParams() {
  const { search } = useLocation();
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

  if (!queryParams['channelGrouping']) {
    return;
  }

  const attributes = queryParams[
    'channelGrouping'
  ] as ChannelGroupingAttributes;

  Object.entries(attributes).forEach(([key, value]) => {
    cookie.setChannelGroupingAttributes({ [key]: value });
  });
}
