import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from '@farmersdog/corgi-x';

import styles from './CompanyLogo.module.scss';
function CompanyLogo({
  image,
  children,
  className,
  withQuotes = true,
  ...props
}) {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      {image}
      {children && (
        <Text
          as={withQuotes ? 'blockquote' : 'p'}
          variant="article-16"
          color="charcoal-1"
          className={styles.quote}
        >
          {withQuotes && '“'}
          {children}
          {withQuotes && '”'}
        </Text>
      )}
    </div>
  );
}

CompanyLogo.propTypes = {
  image: PropTypes.element.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.object,
  withQuotes: PropTypes.bool,
};

export default CompanyLogo;
