import { combineReducers } from 'redux';

import auth from './auth';
import configuration from './configuration';
import referrer from './referrer';
import signup from './signup';
import subscription from './subscription';
import ui from './ui';
import customerAccount from './customerAccount';
import splitio from './splitio';

export default combineReducers({
  auth,
  configuration,
  customerAccount,
  referrer,
  signup,
  splitio,
  subscription,
  ui,
});
