import { NodeRegistry } from '../blueprint/types';
import { blueprintTraverseApply } from '../blueprint/utils';
import { FormFieldsType, UseForm } from '../types';

interface RemoveNodeValueFromStateArgs {
  registry: NodeRegistry;
  unregister: UseForm['unregister'];
  nodeName: string;
}
export function removeNodeValueFromState({
  registry,
  unregister,
  nodeName,
}: RemoveNodeValueFromStateArgs) {
  const nodeToRemove = Object.values(registry).find(
    registryNode => registryNode.name === nodeName
  );
  if (!nodeToRemove) {
    return;
  }

  blueprintTraverseApply(nodeToRemove, node => {
    unregister(node.name as keyof FormFieldsType);
  });
}
