import { pixelRevenue } from '../constants';
import {
  mountPostieEvent,
  PostieEvents,
  PostieSchemas,
} from './mountPostieEvent';

interface TrackPostieCheckoutArgs {
  user?: Reducer.User;
  storeName?: string;
  totalAmount?: number;
  taxAmount?: number;
  shippingAmount?: number;
  currency?: string;
}

export const trackPostieCheckout = ({
  user,
  storeName = "The Farmer's Dog",
  totalAmount = pixelRevenue,
  taxAmount = pixelRevenue,
  shippingAmount = 0,
  currency = 'USD',
}: TrackPostieCheckoutArgs) => {
  mountPostieEvent({
    eventName: PostieEvents.AddTransaction,
    shouldSpreadArguments: true,
    properties: {
      userId: user?.id,
      storeName,
      totalAmount,
      taxAmount,
      shippingAmount,
      city: user?.shippingAddress?.city,
      state: user?.shippingAddress?.state?.name,
      country: user?.shippingAddress?.country?.name,
      currency,
      contextData: [
        {
          schema: PostieSchemas.Address,
          data: {
            name: user?.fullName,
            street: user?.shippingAddress?.addressLine1,
            street_2: user?.shippingAddress?.addressLine2,
            zip: user?.shippingAddress?.zip,
            city: user?.shippingAddress?.city,
            state: user?.shippingAddress?.state.name,
            country: user?.shippingAddress?.country?.name,
            email: user?.email,
          },
        },
      ],
    },
  });

  mountPostieEvent({
    eventName: PostieEvents.TrackTransaction,
  });
};
