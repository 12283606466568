import eventNames from '../eventNames';
import track from '../track';

interface FrequencyPrice {
  frequency: number;
  dailyConsumptionPrice: number;
}
export interface TrackChangeOrderSizeSuccessProps {
  min: FrequencyPrice | null;
  max: FrequencyPrice;
  current: FrequencyPrice;
}

export function trackChangeOrderSizeSuccess(
  suggestedFrequencies?: TrackChangeOrderSizeSuccessProps
) {
  track(eventNames.change_order_size_success, {
    minFrequency: suggestedFrequencies?.min?.frequency,
    minFrequencyDailyConsumptionPrice:
      suggestedFrequencies?.min?.dailyConsumptionPrice,
    maxFrequency: suggestedFrequencies?.max.frequency,
    maxFrequencyDailyConsumptionPrice:
      suggestedFrequencies?.max.dailyConsumptionPrice,
    currentFrequency: suggestedFrequencies?.current.frequency,
    currentDailyConsumptionPrice:
      suggestedFrequencies?.current.dailyConsumptionPrice,
  });
}
