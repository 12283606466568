import { Logger } from '@farmersdog/logger/src/Logger';

import { FormMetadata } from '../hooks';

const logger = new Logger('tosa:frontend:emptyformstep');

interface CheckForEmptyFormStep {
  formSteps: FormMetadata['steps'];
  current: string | undefined;
}

export function checkForEmptyCurrentFormStep({
  formSteps,
  current,
}: CheckForEmptyFormStep) {
  if (formSteps && formSteps.length > 0 && current) {
    const currentBranchIndex = formSteps.findIndex(
      branch => branch.__self__ === current
    );
    const currentBranch = formSteps[currentBranchIndex];
    const currentBranchChildren = formSteps[currentBranchIndex]?.children;

    if (currentBranchChildren && currentBranchChildren.length === 0) {
      logger.error(`Empty form step found at ${currentBranch.name}`);
      // TODO - remove after confirming logger error appears in DD
      console.error(`Empty form step found at ${currentBranch.name}`);
    }
  }
}
