import { Fragment, useEffect, useState, ReactNode } from 'react';

import { ContentReveal, useHtmlElement } from '@farmersdog/corgi-x';
import { browserOnly } from '@farmersdog/utils';

import { LayoutType, Node } from '../../blueprint/types';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import { useSignupSpacingExperiment } from '../../hooks';
import { TOSAComponentInput } from '../../types';
import {
  scroller,
  fastdom,
  getExperimentalLayoutElementKey,
} from '../../utils';

import { exclusionList } from './constants/leafNodeAnimationExclusions';
import { getLayoutClass } from './getLayoutClass';
import { getLayoutElement } from './getLayoutElement';

export interface LeafNodeAnimationProps extends TOSAComponentInput<Node> {
  children: ReactNode;
}

// The minimum ratio of invisible screen before triggering an automatic scroll
const MINIMUM_SCREEN_SCROLL_RATIO = 2 / 3;

export function LeafNodeAnimation({
  progress,
  node,
  children,
  useFeature,
}: LeafNodeAnimationProps) {
  const [htmlEl, setHtmlEl] = useHtmlElement();
  const isPreviousComplete = progress.getPreviousLeafComplete(node.__self__);

  const { name } = getNodeNameAndPosition(node.name);
  const nodeExcludedFromAnimations = exclusionList.has(name);

  const [hasShown, setHasShown] = useState(
    isPreviousComplete || nodeExcludedFromAnimations
  );

  const show = isPreviousComplete || hasShown || nodeExcludedFromAnimations;

  const shouldExcludeFromProgress = Boolean(node.input?.excludeFromProgress);

  useEffect(() => {
    if (hasShown) {
      return;
    }

    if (isPreviousComplete || nodeExcludedFromAnimations) {
      setHasShown(true);
    }
  }, [isPreviousComplete, nodeExcludedFromAnimations, hasShown]);

  useEffect(() => {
    if (!(isPreviousComplete && htmlEl)) {
      return;
    }

    // isPreviousComplete and show are always true for nodes that should be excluded from progress
    // However we don't want them to trigger scrolling
    if (shouldExcludeFromProgress) {
      return;
    }

    fastdom.measure(() => {
      browserOnly(window => {
        const scrollY = getScrollAmountY(htmlEl);
        if (scrollY > window.innerHeight * MINIMUM_SCREEN_SCROLL_RATIO) {
          scroller.scrollTo({
            top: scrollY,
            behavior: 'smooth',
          });
        }
      });
    });
  }, [isPreviousComplete, htmlEl, shouldExcludeFromProgress]);

  const { isTreatmentOn: isSignupSpacingExperimentOn } =
    useSignupSpacingExperiment({ useFeature });

  const layoutElementKey = isSignupSpacingExperimentOn
    ? getExperimentalLayoutElementKey(node.layout ?? LayoutType.none)
    : node.layout;

  const HTMLElement = getLayoutElement(node.layout);

  if (HTMLElement === Fragment) {
    return <HTMLElement>{children}</HTMLElement>;
  }

  return (
    <ContentReveal inAnimation="fade" in={show}>
      <HTMLElement
        className={getLayoutClass({
          layout: layoutElementKey,
          show,
        })}
        ref={setHtmlEl}
      >
        {children}
      </HTMLElement>
    </ContentReveal>
  );
}

// The ratio of screen to keep in view below the element being scrolled to
const SCREEN_SCROLL_AMOUNT_RATIO = 2 / 3;

function getScrollAmountY(el: HTMLElement): number {
  const value = browserOnly(
    window => {
      const { innerHeight } = window;
      const { top } = el.getBoundingClientRect();

      return top + innerHeight * SCREEN_SCROLL_AMOUNT_RATIO - innerHeight;
    },
    () => 0
  );

  return value ?? 0;
}
