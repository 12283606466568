import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_SUCCESS,
} from '@farmersdog/constants/paths';

import { mountPinterestPageView } from './mountPinterestPageView';

export const pinterestPageviewRoutes = [
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_SUCCESS,
];

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a Pinterest page view event.
 */
export function useMountPinterestPageView() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pinterestPageviewRoutes.includes(pathname)) {
      mountPinterestPageView();
    }
  }, [pathname]);
}
