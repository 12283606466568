import { CancellationResponsesEnum } from './cancellationResponses';

/** When any of these reasons are made the "current" state (by the customer
selecting and clicking "Continue") we should display the "Mixing Plan Save" UI */
const mixingPlanSaveStates = [
  CancellationResponsesEnum.FinancialSituationChanged,
  CancellationResponsesEnum.HadntRealizedTotalCost,
  CancellationResponsesEnum.OtherPriceReason,
] as const;

export function getMixingPlanSaveStates() {
  return mixingPlanSaveStates;
}
