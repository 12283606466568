import { Modal, ModalProps } from '@farmersdog/corgi';
import { Text, Divider, Logo, Link } from '@farmersdog/corgi-x';

import { PATH_PRIVACY, PATH_HOME, PATH_FAQ } from '@farmersdog/constants/paths';

import styles from './TermsOfUseModal.module.css';

export function TermsOfUseModal(props: ModalProps) {
  return (
    <Modal {...props}>
      <div>
        <section className={styles.header}>
          <Logo variant="light" size={100} />
          <Text
            variant="heading-40"
            as="h1"
            className={styles.termsOfUseHeading}
            bold
          >
            The Farmer’s Dog Terms of Use
          </Text>
          <Text variant="article-16" as="p" className={styles.lastUpdated}>
            Last Updated January 18, 2023.
          </Text>
          <Divider color="Oyster0" width={1} />
        </section>

        <Text variant="article-16" as="p">
          These Terms of Use (
          <Text variant="article-16" bold>
            “Terms”
          </Text>
          ) are a legal agreement between you and The Farmer’s Dog, Inc. (“
          <Text variant="article-16" bold>
            The Farmer’s Dog
          </Text>
          ,” “
          <Text variant="article-16" bold>
            we
          </Text>
          ,” “
          <Text variant="article-16" bold>
            us
          </Text>
          ,” or “
          <Text variant="article-16" bold>
            our
          </Text>
          ”) and the user of The Farmer’s Dog’s Services or Products (as
          hereinafter defined) (“
          <Text variant="article-16" bold>
            you
          </Text>
          ” or “
          <Text variant="article-16" bold>
            your
          </Text>
          ”). We make our website{' '}
          <Link to={PATH_HOME}>https://www.thefarmersdog.com</Link> (“
          <Text variant="article-16" bold>
            Website
          </Text>
          ”) available in order to provide you with information about our
          company, products and services, and also so that you can order our
          products. Your use of the Website, any related mobile applications,
          our owned and operated social media channels, and our dog food
          delivery service and any services we provided in connection therewith,
          including the Farmer’s Dog Referral Program (as defined below)
          (collectively, the “
          <Text variant="article-16" bold>
            Services
          </Text>
          ”), as well as your purchase of The Farmer’s Dog products through the
          Services (“
          <Text variant="article-16" bold>
            Products
          </Text>
          ”), are subject to these Terms. BY USING THE SERVICES AND/OR
          PURCHASING PRODUCTS, YOU AGREE TO AND WILL BE DEEMED TO BE BOUND BY
          THESE TERMS AND OUR PRIVACY POLICY FOUND AT{' '}
          <Link to={PATH_PRIVACY}>
            HTTPS://WWW.THEFARMERSDOG.COM/CONTENT/PRIVACY
          </Link>
          , INCORPORATED HEREIN BY REFERENCE. If you do not want to be bound by
          these Terms or the Privacy Policy, do not use the Services or
          Products.
        </Text>

        <Text variant="article-16" as="p">
          These Terms include: (1) AN ARBITRATION PROVISION; AND (2) A WAIVER OF
          RIGHTS TO BRING A CLASS ACTION AGAINST US.
        </Text>
        <Text variant="article-16" as="p">
          We may occasionally update these Terms. When we do update these Terms,
          we will also revise the “Last Updated” date at the top of these Terms.
          If we make changes to these Terms that, in our discretion, we consider
          signiﬁcant, we will post the updated Terms on the Website and we may
          also send emails to our subscribers containing a link to the revised
          Terms or provide alternative notice of changes to the Terms in our
          discretion. It is your responsibility to check these Terms for changes
          prior to use of the Services or Products. Your continued use of the
          Services or Products after we post an update to these Terms indicates
          your acceptance of the updated Terms.
        </Text>
        <Text variant="article-16" as="p">
          The owner of the Services and Products is based in the United States.
          We provide this Website, Services, and Products for use only by
          persons located in the United States. We make no claims that the
          Website or any of its content is accessible or appropriate outside of
          the United States. Access to the Website may not be legal by certain
          persons or in certain countries. If you access the Website from
          outside the United States, you do so on your own initiative and are
          responsible for compliance with local laws.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          1. Membership and account security.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            1.1
          </Text>{' '}
          This Website is offered and available to users who are 13 years of age
          or older, and reside in the United States or any of its territories or
          possessions. An individual or household may only create one (1)
          customer account on the Site. By using this Website, you represent and
          warrant that you meet all of the foregoing eligibility requirements.
          If you do not meet all of these requirements, you must not access or
          use the Website. While you may browse the Website and order some
          Products as a guest user, to purchase our dog food Products or access
          certain Services, you must submit an application to become a
          subscriber to our dog food delivery Services. You must be 18 years of
          age or older and legally capable of entering into binding contracts in
          order to apply, and we may accept or reject your application in our
          sole discretion. If approved, when you create an account, you agree
          you are responsible for maintaining the conﬁdentiality and security of
          your account by, among other things: (i) ensuring that your
          registration details are true, up to date, and accurate; (ii) not
          sharing your password, letting anyone else access your account,
          transferring your account to any other person, or taking other actions
          that might jeopardize the security of your account. An individual or
          household may only create one (1) customer account on the Site.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            1.2
          </Text>{' '}
          You agree to comply with these Terms and all applicable laws,
          statutes, ordinances, and regulations regarding your use of our
          Services and your purchase of Products through them. We reserve the
          right in our sole discretion to disable or terminate your account in
          our sole discretion for any and no reason, including if, in our
          opinion, you violate these Terms.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            1.3
          </Text>{' '}
          When you visit the Website, send SMS text messages, e-mails to us, or
          online chat with us, you are communicating with us electronically. By
          doing so, you consent to receive communications from us
          electronically. All communications are governed by our Privacy Policy
          found at{' '}
          <Link to={PATH_PRIVACY}>
            https://www.thefarmersdog.com/content/privacy
          </Link>
          . You agree that all agreements, notices, disclosures, and other
          communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing. In addition, by
          using our Services or Products, you consent to receive SMS text
          messages sent through an automatic telephone dialing system on the
          phone number associated with your account. You understand that you are
          not required to provide this consent as a condition of purchasing any
          Products from us. You also understand that you may opt out of
          receiving text messages from us at any time, by contacting{' '}
          <Link href="mailto:help@thefarmersdog.com">
            help@thefarmersdog.com
          </Link>{' '}
          or by replying “STOP” to any text messages received from us. You can
          also manage your communication preferences in your account.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          2. Content and links.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            2.1
          </Text>{' '}
          The text, images, photographs, graphics, logos, illustrations,
          descriptions, data, and other material provided by or otherwise
          through the Services, as well as the selection, assembly, and
          arrangement thereof, are referred to collectively as the “
          <Text variant="article-16" bold>
            Content
          </Text>
          ” The Content may contain errors, omissions, or typographical errors
          or may be out of date. We may change, delete, or update any Content at
          any time and without prior notice. The Content is provided for
          informational purposes only and is not binding on us in any way except
          to the extent it is speciﬁcally indicated to be so.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            2.2
          </Text>{' '}
          Unless otherwise noted through the Services, The Farmer’s Dog name,
          trademark, service mark and logo and all related names, logos, Product
          and Service names, designs and slogans (“
          <Text variant="article-16" bold>
            Marks
          </Text>
          ”), the Services, and all Content are protected under copyright,
          trademark, and other intellectual property laws. You agree that The
          Farmer’s Dog and/or its licensors own all right, title and interest in
          and to the Marks, Content and Services (including any and all
          intellectual property rights therein) and you agree not to take any
          action(s) inconsistent with such ownership interests. We and our
          licensors reserve all rights in connection with the Marks, Services
          and Content including, without limitation, the exclusive right to
          create derivative works. You may view and use the Content only for
          your personal information and for using the Services, and for no other
          purpose, including but not limited to, any commercial purpose, and you
          shall retain intact all copyright and other proprietary notices. Any
          use of the Content, except as speciﬁcally permitted in these Terms or
          as otherwise expressly permitted in the Content or in a writing signed
          by us, is strictly prohibited. If you print, copy, modify, download,
          or otherwise use or provide any other person with access to any part
          of the Services in breach of these Terms, your right to use the
          Services will stop immediately and you must, at our option, return or
          destroy any copies of the materials you have made.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            2.3
          </Text>{' '}
          Links to other Internet sites operated by third parties, including
          Website vendors, do not constitute sponsorship, endorsement, or
          approval by The Farmer’s Dog of the content, policies, or practices of
          such linked sites. Linked sites are not operated, controlled, or
          maintained by the Services or by us, and we are not responsible for
          the availability, content, security, policies, or practices of linked
          sites, including, without limitation, privacy policies and practices.
          Links to other sites are provided for your convenience only, and you
          access them at your own risk.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          3. Services access.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            3.1
          </Text>{' '}
          The Farmer’s Dog hereby grants you a limited right to access and make
          personal use of the Services. However you may not download (other than
          page caching) or modify the Services, or any portion of them, except
          with our express prior written consent. This access right does not
          include: any resale or commercial use of the Services, including the
          Website or its contents; any collection and use of any Product
          listings, descriptions, or prices; any derivative use of the Services,
          including the Website or its contents; any downloading or copying of
          account information for the beneﬁt of another merchant; or any use of
          data mining, robots, or similar data gathering and extraction tools.
          The Services or any portion of the Services may not be reproduced,
          duplicated, copied, sold, resold, visited, or otherwise exploited for
          any commercial or non-profit purpose without our express written
          consent.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            3.2
          </Text>{' '}
          You may not frame or utilize framing techniques to enclose any
          Content, trademark, logo, or other proprietary information (including
          images, text, page layout, or form) of the Services without our
          express prior written consent. You may not use any meta tags or any
          other “hidden text” utilizing the Website’s name or any Marks without
          our express prior written consent. Any unauthorized use terminates the
          permission granted herein. Any violation of these Terms may result in
          a copyright, trademark or other intellectual property right
          infringement that may subject the offender to civil and/or criminal
          penalties.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            3.3
          </Text>{' '}
          You may use the Services only for lawful purposes and in accordance
          with these Terms.
        </Text>
        <Text variant="article-16" as="p">
          You agree not to use the Website, (i) in any way that violates any
          applicable federal, state, local, or international law or regulation
          (including, without limitation, any laws regarding the export of data
          or software to and from the US or other countries), (ii) for the
          purpose of exploiting, harming, or attempting to exploit or harm
          minors in any way by exposing them to inappropriate content, asking
          for personally identifiable information, or otherwise, (iii) to
          transmit, or procure the sending of, any advertising or promotional
          material, except as specifically provided for herein or without our
          prior written consent, including any “junk mail,” “chain letter,”
          “spam,” or any other similar solicitation, (iv) to impersonate or
          attempt to impersonate The Farmer’s Dog, a The Farmer’s Dog employee,
          another user, or any other person or entity (including, without
          limitation, by using email addresses or user names associated with any
          of the foregoing), (v) to engage in any other conduct that restricts
          or inhibits anyone’s use or enjoyment of the Website, or which, as
          determined by us, may harm the The Farmer’s Dog or users of the
          Website, or expose them to liability, (vi) in any manner that could
          disable, overburden, damage, or impair the site or interfere with any
          other party’s use of the Website, including their ability to engage in
          real time activities through the Website, (vii) with any device,
          software, or routine that interferes with the proper working of the
          Website, (viii) to introduce any viruses, Trojan horses, worms, logic
          bombs, or other material that is malicious or technologically harmful,
          (ix) to attempt to gain unauthorized access to, interfere with,
          damage, or disrupt any parts of the Website, the server on which the
          Website is stored, or any server, computer, or database connected to
          the Website, or (x) in any way that otherwise attempts to interfere
          with the proper working of the Website.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          4. Subscriptions, Renewals and Cancellations.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            4.1{' '}
          </Text>{' '}
          <Text variant="article-16" bold>
            Recurring Subscription Payments
          </Text>
          . When you sign up to one of our subscription plans, you will be
          subscribed to recurring orders of Products from us. Our subscription
          plan consists of an initial charge followed by recurring periodic
          charges at the intervals set forth in your subscription page.{' '}
          <Text variant="article-16" bold>
            You acknowledge that your subscription has an initial and recurring
            payment feature and you accept responsibility for all recurring
            charges prior to cancellation of your subscription. We may submit
            periodic charges for fees due under your subscription without
            further authorization from you until you cancel your subscription.
            You agree that (a) we may store and continue billing your payment
            method (e.g. credit card) to avoid interruption of your
            subscription, and (b) we may calculate taxes payable by you based on
            the billing information that you provide us at the time of purchase.
          </Text>{' '}
          Please refer to our <Link to={PATH_FAQ}>The Farmer’s Dog FAQ</Link>{' '}
          for further instructions.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            4.2{' '}
          </Text>
          <Text variant="article-16" bold>
            Automatic Renewal and Cancellation. You agree that if you purchase a
            subscription through the Services, your subscription will
            automatically renew at the subscription period frequency referenced
            in your account, and your payment method will automatically be
            charged at the start of each new subscription period for the fees
            and taxes applicable to that period. To avoid future subscription
            charges, you must cancel your subscription at least six (6) days
            prior to the start day of the delivery window indicated in your
            account by doing the following: in your account under my profile
            page, subscription section at the bottom of page, provided that any
            subscription fees charged prior to the effective date of
            cancellation will not be refunded, in whole or in part. If you
            cancel your subscription, you will continue to enjoy your
            subscription benefits until the expiration of the then-current
            subscription term for which you have paid, and your subscription
            benefits will expire at the end of the then-current subscription
            term.
          </Text>
        </Text>
        <Text variant="heading-22" as="h3" bold>
          5. Products, Orders, Shipment, Pricing and Payment.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.1{' '}
          </Text>
          <Text variant="article-16" bold>
            Pricing Changes.
          </Text>{' '}
          Product pricing and our delivery charges, if any, will be as quoted
          through the Services from time to time, and may not include applicable
          taxes. We reserve the right to change our subscription plans or adjust
          pricing for our Products in any manner and at any time as we may
          determine in our sole and absolute discretion. Except as otherwise
          provided in these Terms, any price changes or changes to your
          subscription plan will take effect following reasonable notice to you.
          If you have not cancelled your subscription within the specified time
          after receiving notice of a price change, your subscription will
          auto-renew at the price indicated in your notice.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.2{' '}
          </Text>
          <Text variant="article-16" bold>
            Payments.
          </Text>{' '}
          If you purchase a subscription to our Products, you agree to pay us
          the applicable fees and taxes in U.S. Dollars. Failure to pay these
          fees and taxes will result in the termination of your subscription.
          Payment for all Products must be by credit or debit card. We accept
          payment with American Express, Visa and Mastercard. We reserve the
          right to change the payment methods we accept at any time. You
          acknowledge and agree that all information you provide with regards to
          a purchase of our Products, including, without limitation, credit card
          or other payment information, is accurate, current and complete. You
          represent and warrant that you have the legal right to use the payment
          method you provide to us or our payment processor, including, without
          limitation, any credit card you provide when completing a transaction.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.3{' '}
          </Text>
          <Text variant="article-16" bold>
            Credit Card Authorization.
          </Text>{' '}
          You may see credit card authorization holds when you (i) add or update
          your method of payment, (ii) purchase a paid, recurring subscription,
          or (iii) sign up for a free trial subscription. Authorization holds,
          pre-authorization holds, and pending charges are common anti-fraud
          practices that do not represent actual charges. The amount and
          duration of the hold varies depending on your credit card company’s
          policies, but generally speaking, this activity appears as a $1 (USD)
          charge that is released once your credit card is validated. Feel free
          to contact your credit card company with any questions.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.4{' '}
          </Text>
          <Text variant="article-16" bold>
            Order Acceptance; Shipment.
          </Text>{' '}
          Once we receive your order for Products, we will provide you with an
          order confirmation. Your receipt of an order confirmation, however,
          does not signify our acceptance of your order, nor does it constitute
          confirmation of our offer to sell; we are simply confirming that we
          received your order. The Farmer’s Dog reserves the right, in its sole
          discretion to accept or decline your order, cancel your subscription
          or order, or limit Product quantities for any or no reason. If we
          cancel an order after you have already been billed, then we will
          refund the billed amount. [For most orders, you will be charged at the
          time your order is placed. In the event of cancellation, The Farmer’s
          Dog will refund your payment.] Title and risk of loss for any
          purchases of Products pass to you upon our delivery to our carrier. We
          reserve the right to ship partial orders (at no additional cost to
          you), and the portion of any order that is partially shipped may be
          charged at the time of shipment. All orders are shipped using one of
          our third party couriers. Online tracking may be available at our
          courier’s website (for example, FedEx), though we make no warranties
          regarding its availability because it is not under our control. While
          deliveries may be scheduled for a specified arrival, we cannot
          guarantee delivery by any specific date or time.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.5{' '}
          </Text>
          All Products available through the Services are for personal use only.
          You may not resell any of the Products that you purchase or receive
          from us. If you are a wholesaler or retailer interested in purchasing
          items in bulk, please email{' '}
          <Link href="mailto:partners@thefarmersdog.com">
            partners@thefarmersdog.com.
          </Link>
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.6{' '}
          </Text>
          All offers of Products as they are displayed via the Services are
          subject to availability. The Farmer’s Dog reserves the right to modify
          the items offered via the Services at any time. If a Product is not
          available for shipping promptly after you place your order, or upon
          the next delivery date under your subscription, you will be notiﬁed,
          and you may choose to order a different item that is then-currently
          available, to wait until the product is available (if it will be
          available again in a timely manner) or to cancel your order. The
          Farmer’s Dog shall not be liable if Products are not in stock or
          otherwise not available. If a Product is listed at an incorrect price
          or with incorrect information due to typographical error or otherwise,
          we reserve the right to not process or to cancel any orders placed for
          such Product.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            5.7{' '}
          </Text>
          We may change our Products, in our sole discretion, to reflect changes
          in relevant laws and regulatory requirements which impact either the
          pet food market or the natural products markets in the US or other
          relevant countries, or to implement minor technical adjustments and
          improvements. Pursuant to applicable law and regulatory requirements,
          we will advise you if there is likely to be any significant difference
          in the Products.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          6. The Farmer’s Dog Referral Program Terms And Conditions.
        </Text>
        <Text variant="article-16" as="p">
          We offer a referral program (“
          <Text variant="article-16" bold>
            The Farmer’s Dog Referral Program
          </Text>
          ”) that provides eligible The Farmer’s Dog customers (each, a “
          <Text variant="article-16" bold>
            Referrer
          </Text>
          ”) with the opportunity to refer eligible individuals (each, a “
          <Text variant="article-16" bold>
            Referred Customer
          </Text>
          ”) to purchase Products from The Farmer’s Dog. Occasionally, The
          Farmer’s Dog Referral Program may offer temporary promotions,
          discounts, incentives, or store credit (collectively, “
          <Text variant="article-16" bold>
            TFD Credit
          </Text>
          ”), which may be adjusted or discontinued by The Farmer’s Dog, at any
          time in The Farmer’s Dog’s sole discretion. This Section 6 governs
          your participation in The Farmer’s Dog Referral Program. To the extent
          this Section 6 contains any terms or conditions inconsistent with the
          remainder of these Terms, this Section 6 will control with respect to
          The Farmer’s Dog Referral Program only.
        </Text>
        <Text variant="article-16" as="p">
          The Farmer’s Dog will send Referrers a notice (an “
          <Text variant="article-16" bold>
            Invitation
          </Text>
          ”) with a unique referral link (“
          <Text variant="article-16" bold>
            Referral Link
          </Text>
          ”), which can be shared with the Referrer’s friends and family.
          Invitations are provided by The Farmer’s Dog in its sole discretion
          and customers are not guaranteed to receive an Invitation. The
          Invitation will specify the TFD Credit, incentive or discount (as may
          be applicable) the Referrer will receive for each Referred Customer,
          if any (the “
          <Text variant="article-16" bold>
            Referrer Credit
          </Text>
          ”) and the TFD Credit, incentive or discount each Referred Customer
          will receive, if any (the “
          <Text variant="article-16" bold>
            Referred Credit
          </Text>
          ”). Referrers must follow any and all terms, conditions and guidance
          set forth in the Invitation to qualify for Referrer Credit, including
          with respect to the manner in which the Referrer can share the
          Referral Link and the date when the Referral Link will expire. By
          participating in The Farmer’s Dog Referral Program, you represent and
          warrant that (i) you are 18 years of age or older; (ii) you are a
          legal resident of the United States; (iii) you have the necessary
          consent from each such potential customer to whom you send a message
          regarding The Farmer’s Dog Referral Program; and (iv) you will at all
          times remain compliant with CAN-SPAM, the TCPA and any other
          applicable laws.
        </Text>
        <Text variant="article-16" as="p">
          You understand and agree that any communications you send with respect
          to The Farmer’s Dog Referral Program are being sent by you as an
          individual, on your own behalf, and not on behalf of The Farmer’s Dog.
          You are not acting as an agent of The Farmer’s Dog or acting in any
          representative capacity, nor will you be deemed an employee of The
          Farmer’s Dog. As such, while, as a Referrer, you can and should
          include your Referral Link in communications about The Farmer’s Dog
          Referral Program, you must not, without The Farmer’s Dog’s written
          permission, include any of our Marks or other The Farmer’s Dog
          materials in such communications. The content of all of your
          communications about The Farmer’s Dog Referral Program must be yours
          alone. You will not misrepresent or embellish your relationship with
          The Farmer’s Dog or write, say or post anything you know is untrue or
          may be misleading. And, if someone has asked you to stop sending them
          messages about The Farmer’s Dog, you will respect that wish.
        </Text>
        <Text variant="article-16" as="p">
          A “
          <Text variant="article-16" bold>
            Referred Customer
          </Text>
          ” means an individual who:
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" as="p">
            (a) clicks on a Referral Link to place an order on the Website or
            through the Services before The Farmer’s Dog Referral Program or
            campaign end date;
          </Text>
          <Text variant="article-16" as="p">
            (b) does not cancel such order prior to shipment and does not return
            or seek a refund for such order within ninety (90) calendar days
            (the “
            <Text variant="article-16" bold>
              Return Period
            </Text>
            ”); and
          </Text>
          <Text variant="article-16" as="p">
            (c) is a new (and not a returning or existing) The Farmer’s Dog
            customer who is a legal resident of the United States and at least
            eighteen (18) years of age. For the avoidance of doubt, any
            individual or household that has previously created a customer
            account through the Services cannot be a Referred Customer.
          </Text>
        </Text>
        <Text variant="article-16" as="p">
          In order to be eligible for a Referral Reward, a Referrer must refer a
          minimum of one (1) Referred Customer to The Farmer’s Dog, or the
          minimum number as may be designated in the rules specific to each
          campaign, which may be adjusted at any time in The Farmer’s Dog’s sole
          discretion. When a Referred Customer has used a Referrer’s Referral
          Link to satisfy the above conditions, the applicable Referrer is
          eligible to receive the Referrer Credit, which IF AVAILABLE will be
          speciﬁed in the Invitation. Referrer Credit and Referred Credit will
          be sent to the applicable Referrer or Referred Customer within thirty
          (30) days after the above conditions have been met. Unless otherwise
          set forth in an Invitation or other communication from The Farmer’s
          Dog, each Referrer is only eligible to receive up to Six Hundred US
          Dollars ($600) in TFD Credit per calendar year (i.e., after such
          Referrer has earned Six Hundred US Dollars ($600) of TFD Credit in a
          single calendar year, such Referrer will not be eligible for any
          additional Referral Credits even if additional Referred Customers
          satisfy the above conditions using such Referrer’s Referral Link). The
          amount and availability of the Referral Reward are subject to change
          at any time in The Farmer’s Dogs sole discretion. The Farmer’s Dog
          may, in its sole discretion, send Referrers who have reached their
          Referral Credit limit another Invitation, which may or may not contain
          the same terms and conditions.
        </Text>
        <Text variant="article-16" as="p">
          Each Referred Customer will also receive the Referred Credit or
          promotion specified in the relevant Referral Link. An individual may
          only create one (1) customer account through the Services and may only
          receive one (1) Referred Reward for becoming a Referred Customer, even
          if the individual receives and attempts to click through multiple
          Referral Links.
        </Text>
        <Text variant="article-16" as="p">
          The Farmer’s Dog will apply any TFD Credit to the next purchase made
          by the Referrer or Referred Customer, as applicable. TFD Credit is not
          transferable, redeemable or exchangeable. TFD Credit can only be used
          on the Website to purchase Products from The Farmer’s Dog. TFD Credits
          do not reflect a balance of US dollars or any other currency.
          Unauthorized or fraudulent use, resale, acquisition, or distribution
          of TFD Credit is prohibited. The Farmer’s Dog may cancel, revoke, or
          otherwise prevent the issuance of TFD Credit if we suspect any
          unauthorized or fraudulent use of TFD Credit and/or if The Farmer’s
          Dog mistakenly credits a Referrer or Referred Customer’s account with
          TFD Credits. TFD CREDIT MUST BE REDEEMED WITHIN TWELVE (12) MONTHS OF
          RECEIPT BY THE REFERRER OR REFERRED CUSTOMER. Referral Rewards and
          Referred Rewards may contain additional limitations, as set forth in
          the applicable Invitation.
        </Text>
        <Text variant="article-16" as="p">
          In addition to the conditions set forth in Section 11.1, we may
          terminate your eligibility to earn a Referral Reward or Referred
          Reward or terminate your account for the Services if you take any of
          the following actions:
        </Text>

        <Text variant="article-16" as="p">
          (a) Open multiple accounts, including with different e-mail addresses,
          in order to generate additional Referral Rewards or Referred Rewards;
        </Text>
        <Text variant="article-16" as="p">
          (b) Refer customers using spam, display advertising, sponsored links,
          unsolicited e-mails, automated systems or bots, scripts or automatic
          dialers or links on message boards or forums.
        </Text>
        <Text variant="article-16" as="p">
          (c) Use false names, refer yourself, impersonate other people, or
          otherwise provide false or misleading information to us;
        </Text>
        <Text variant="article-16" as="p">
          (d) Offer incentives beyond those specified in the relevant Invitation
          to others who register new accounts;
        </Text>
        <Text variant="article-16" as="p">
          (e) Use The Farmer’s Dog brand in connection with disparaging or
          defamatory content, content that promotes racism, bigotry, hatred,
          discrimination or physical harm against any group or individual,
          offensive, abusive, intimidating or harassing content, content that is
          sexually explicit, obscene and/or contains nudity, any political
          and/or religious statement, any content containing images or reference
          to illegal drugs, alcohol, tobacco, weapons or ﬁrearms or content that
          violates someone else’s privacy;
        </Text>
        <Text variant="article-16" as="p">
          (f) Sell or otherwise attempt to transfer your Referral Link;
        </Text>
        <Text variant="article-16" as="p">
          (g) Violate or infringe the rights of any third party; or
        </Text>
        <Text variant="article-16" as="p">
          (h) Pay to advertise your Referral Link or The Farmer’s Dog Referral
          Program, including via Google, Facebook, Twitter, Bing and Craigslist.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          7. Promotional discount links.
        </Text>
        <Text variant="article-16" as="p">
          The following terms and conditions, together with the Terms, govern
          the use of The Farmer’s Dogs promotional links that may be redeemed
          for discounts on Products or other features or benefits related to the
          Services (“
          <Text variant="article-16" bold>
            Promotion
          </Text>
          ”):
        </Text>

        <Text variant="article-16" as="p">
          (a) Promotions: (a) must be used in a lawful manner; (b) must be used
          for the intended audience and purpose; (c) may not be duplicated, sold
          or transferred in any manner, or made available by you to the general
          public (whether posted to a public forum, coupon collecting service,
          or otherwise), unless expressly permitted by The Farmer’s Dog; (d) may
          be disabled or have additional conditions applied to them by The
          Farmer’s Dog at any time for any reason without liability to you; (e)
          may only be used pursuant to the specific terms that The Farmer’s Dog
          establishes for such Promotion; (f) are not valid for cash or other
          credits or points; and (g) may expire prior to your use.
        </Text>
        <Text variant="article-16" as="p">
          (b) Promotions cannot be combined.
        </Text>
        <Text variant="article-16" as="p">
          (c) Promotions may have an expiration date, as indicated at the time
          of issuance. The Farmer’s Dog may adjust expiration and offer amount
          at any time in their sole discretion.
        </Text>
        <Text variant="article-16" as="p">
          (d) Promotions can only be redeemed through the Services toward the
          purchase of Products available thereon. They cannot be applied to any
          orders previously placed.
        </Text>
        <Text variant="article-16" as="p">
          (e) Promotions are non-transferable and may not be returned or
          redeemed for cash (except as required by law).
        </Text>
        <Text variant="article-16" as="p">
          (f) To redeem a Promotion you must click the Promotional link before
          purchase. Additional restrictions may apply, as indicated at the time
          of issuance.
        </Text>
        <Text variant="article-16" as="p">
          (g) All remaining balances exceeding the value of your Promotion must
          be paid by another acceptable form of payment.
        </Text>
        <Text variant="article-16" as="p">
          (h) Delivery of an electronic Promotion to an incorrect or
          non-existent email address is the sole responsibility of the
          purchaser. Delivery of a physical Promotion to an incorrect or
          non-existent shipping address is the sole responsibility of the
          purchaser. We are not responsible if a Promotion is lost, stolen,
          destroyed or used without permission. Promotions will not be replaced
          if lost or stolen.
        </Text>
        <Text variant="article-16" as="p">
          (i) Sales tax and shipping charges are applicable to any items
          purchased with a Promotion.
        </Text>
        <Text variant="article-16" as="p">
          (j) In addition to the conditions set forth in Section XYZ we may
          terminate your eligibility to redeem a promotion or terminate your
          account for the Services if you take any of the following actions:
        </Text>
        <ol type="a">
          <Text variant="article-16" as="li">
            Open multiple accounts, including with different e-mail addresses or
            other information
          </Text>
          <Text variant="article-16" as="li">
            Use false names, impersonate other people, or otherwise provide
            false or misleading information about you or the dog(s) to us;
          </Text>
        </ol>
        <Text variant="article-16" as="p">
          (k) We reserve the right, in our sole discretion, to refuse, modify,
          cancel or hold for review any Promotion and orders for suspected
          fraud, for Promotions mistakenly issued in an incorrect denomination,
          or for other violations of the Promotion or Services policies, in
          whole or in part for any reason or no reason to the extent permitted
          by law. If we suspect misuse of a Promotion, the promotion and the
          account may be suspended or terminated.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          8. User Contributions.
        </Text>
        <Text variant="article-16" as="p">
          The Services may contain message boards, personal web pages or
          profiles, forums, bulletin boards, review sections, and other
          interactive features (collectively, “Interactive Services”) that allow
          users to post, submit, publish, display, or transmit to other users or
          other persons (hereinafter, “post”) content or materials
          (collectively, “User Contributions”) on or through the Services.
        </Text>
        <Text variant="article-16" as="p">
          All User Contributions must comply with the Content Standards set out
          in these Terms.
        </Text>
        <Text variant="article-16" as="p">
          Any User Contribution you post to the Services will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Services, you grant us and our affiliates, and
          each of our respective licensees, successors, and assigns the right to
          use, reproduce, modify, perform, display, distribute, and otherwise
          disclose to third parties any such material for any purpose.
        </Text>
        <Text variant="article-16" as="p">
          You represent and warrant that (i) you own or control all rights in
          and to the User Contributions and have the right to grant the license
          granted above, and (ii) all of your User Contributions do and will
          comply with these Terms.
        </Text>
        <Text variant="article-16" as="p">
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not The Farmer’s Dog,
          have full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </Text>
        <Text variant="article-16" as="p">
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Website.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          9. Monitoring and Enforcement; Termination.
        </Text>
        <Text variant="article-16" as="p">
          We have the right to (i) remove or refuse to post any User
          Contributions for any or no reason in our sole discretion, (ii) take
          any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates the Terms, including the
          Content Standards, infringes any intellectual property right or other
          right of any person or entity, threatens the personal safety of users
          of the Website or the public, or could create liability for The
          Farmer’s Dog, (iii) disclose your identity or other information about
          you to any third party who claims that material posted by you violates
          their rights, including their intellectual property rights or their
          right to privacy, and (iv) take appropriate legal action, including
          without limitation, referral to law enforcement, for any illegal or
          unauthorized use of the Website.
        </Text>
        <Text variant="article-16" as="p">
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Services. YOU WAIVE AND HOLD
          HARMLESS THE FARMER’S DOG AND ITS AFFILIATES, AND LICENSEES, FROM ANY
          CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE THE FOREGOING
          PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
          SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </Text>
        <Text variant="article-16" as="p">
          We do not review all material before it is posted on the Services, and
          cannot ensure prompt removal of objectionable material after it has
          been posted. Accordingly, we assume no liability for any action or
          inaction regarding transmissions, communications, or content provided
          by any user or third party. We have no liability or responsibility to
          anyone for performance or nonperformance of the activities described
          in this section.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          10. Content Standards.
        </Text>
        <Text variant="article-16" as="p">
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must in their entirety
          comply with all applicable federal, state, local, and international
          laws and regulations. Without limiting the foregoing, User
          Contributions must not (i) contain any material that is defamatory,
          obscene, indecent, abusive, offensive, harassing, violent, hateful,
          inflammatory, or otherwise objectionable, (ii) promote sexually
          explicit or pornographic material, violence, or discrimination based
          on race, sex, religion, nationality, disability, sexual orientation,
          or age, (iii) infringe any patent, trademark, trade secret, copyright,
          or other intellectual property or other rights of any other person,
          (iv) violate the legal rights (including the rights of publicity and
          privacy) of others or contain any material that could give rise to any
          civil or criminal liability under applicable laws or regulations or
          that otherwise may be in conflict with these Terms of Use and our{' '}
          <Link to={PATH_PRIVACY}>Privacy Policy</Link>, (v) be likely to
          deceive any person, (vi) promote any illegal activity, or advocate,
          promote, or assist any unlawful act, (vii) cause annoyance,
          inconvenience, or needless anxiety or be likely to upset, embarrass,
          alarm, or annoy any other person, (viii) impersonate any person, or
          misrepresent your identity or affiliation with any person or
          organization, (ix) involve commercial activities or sales (except as
          specifically provided for herein), such as contests, sweepstakes, and
          other sales promotions, barter, or advertising, or (x) give the
          impression that they emanate from or are endorsed by us or any other
          person or entity, if this is not the case.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          11. Disclaimer and limitation of liability.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            11.1{' '}
          </Text>
          <Text variant="article-16" bold>
            GENERAL DISCLAIMER.
          </Text>{' '}
          THE FARMER’S DOG MAKES NO WARRANTIES OR REPRESENTATIONS WHATSOEVER
          WITH RESPECT TO THE PRODUCTS OR THE SERVICES, INCLUDING THE
          AVAILABILITY OF THE WEBSITE COMPLETENESS, OR TIMELINESS OF THAT
          CONTENT, INFORMATION, AND MATERIALS. THE FARMER’S DOG ALSO DOES NOT
          WARRANT OR REPRESENT THAT YOUR ACCESS TO OR USE OF THE SERVICES WILL
          BE UNINTERRUPTED OR FREE OF ERRORS OR OMISSIONS, THAT DEFECTS WILL BE
          CORRECTED, OR THAT THE SERVICES AND ANY REFERRAL LINKS ARE FREE OF
          COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS. WITHOUT LIMITING THE
          FOREGOING, THE PRODUCTS AND THE SERVICES ARE PROVIDED TO USERS “AS
          IS,” WITH NO WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY,
          ACCURACY, OR THE AVAILABILITY OR QUALITY OF ANY PRODUCTS AVAILABLE
          THROUGH THE SERVICES. THE FOREGOING DISCLAIMERS ARE EXPRESSLY MADE A
          CONDITION OF ANY TRANSACTION ARISING THROUGH OR AS A RESULT OF THE
          SERVICES. Please note that some jurisdictions may not allow the
          exclusion of implied warranties, so some of the above exclusions may
          not apply to you. Check your local laws for any restrictions or
          limitations regarding the exclusion of implied warranties.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            11.2{' '}
          </Text>
          <Text variant="article-16" bold>
            NO MEDICAL ADVICE.
          </Text>{' '}
          We may suggest a certain type of meal plan for your pet based on
          criteria specified by you through your account for the Services. YOU
          ACKNOWLEDGE THAT OUR RECOMMENDED MEAL PLAN IS INTENDED AS A GUIDE
          ONLY. IT IS YOUR RESPONSIBILITY AS A PET OWNER TO MONITOR YOUR PET’S
          WEIGHT AND CONDITION, AND TO LET US KNOW ANY CHANGES IN EITHER WEIGHT
          OR CONDITION AS THESE MAY IMPACT YOUR MEAL PLAN. IF YOU HAVE ANY
          CONCERNS ABOUT YOUR PET’S WEIGHT AND CONDITION, PLEASE CONSULT YOUR
          VETERINARIAN. ANY INFORMATION GIVEN BY ANY FARMER’S DOG TEAM MEMBER IS
          GENERAL IN NATURE, AND NOT SPECIFIC TO YOUR PET AND WE ALWAYS
          RECOMMEND TO SPEAK TO YOUR LOCAL VETERINARIAN WHO KNOWS YOUR PET’S
          MEDICAL HISTORY AND CAN EXAMINE THEM IN PERSON. NOTHING IN THE
          SERVICES OR COMMUNICATED TO YOU BY US OR ANY OF OUR REPRESENTATIVES IS
          INTENDED TO BE, NOR SHOULD BE, TAKEN AS MEDICAL OR VETERINARY ADVICE
          AND WE ARE NOT RESPONSIBLE TO YOU OR ANYONE ELSE FOR ANY LOSS, DAMAGE,
          LIABILITY, COST OR EXPENSE SUFFERED IN CONNECTION WITH YOUR RELIANCE
          ON SUCH INFORMATION.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            11.3{' '}
          </Text>
          <Text variant="article-16" bold>
            ALLERGENS.
          </Text>{' '}
          THE PRODUCTS WE SUPPLY MAY CONTAIN ALLERGENS. WE USE REASONABLE
          EFFORTS TO IDENTIFY COMMON ALLERGENS CONTAINED IN THE PRODUCTS, BUT IT
          IS YOUR RESPONSIBILITY TO ENSURE THAT ANY FOOD YOU FEED TO YOUR PET
          DOES NOT CAUSE AN ADVERSE REACTION AND WE WILL NOT HAVE ANY LIABILITY
          FOR, ANY ALLERGIC REACTION YOUR PET MAY HAVE OR ANY ADVERSE
          CONSEQUENCES RESULTING FROM AN ALLERGY.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            11.4{' '}
          </Text>
          <Text variant="article-16" bold>
            PRODUCT IMAGES AND DESCRIPTIONS.
          </Text>{' '}
          The images and descriptions of our Products are for illustrative
          purposes only. We try to be as accurate as possible when describing
          and showing our Products, but we do not warrant that these Product
          descriptions or depictions, or other content on or provided through
          the Services, are accurate, reliable, current, or complete. The
          packaging of our Products may vary from that shown in images on the
          Website or otherwise made available through the Services.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            11.5{' '}
          </Text>
          <Text variant="article-16" bold>
            LIMITATION OF LIABILITY.
          </Text>{' '}
          TO THE EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL THE
          FARMER’S DOG, ITS SUPPLIERS, OR ITS OR THEIR RESPECTIVE AFFILIATES,
          DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR TO ANY
          THIRD PARTY FOR ANY LOST PROFITS, LOST REVENUE OR LOSS OF BUSINESS, OR
          ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
          WHETHER IN CONTRACT OR IN TORT, INCLUDING NEGLIGENCE, ARISING IN ANY
          WAY OUT OF THE SERVICES, ACCESS TO OR USE OF OR INABILITY TO ACCESS OR
          USE THE SERVICES, OR THE PRODUCTS OR YOUR PURCHASE OR USE OF PRODUCTS
          FROM THE FARMER’S DOG, EVEN IF THE FARMER’S DOG IS EXPRESSLY ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE AGGREGATE
          LIABILITY OF THE FARMER’S DOG, ITS SUPPLIERS, OR ITS OR THEIR
          RESPECTIVE AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS TO YOU
          FOR ANY DAMAGES, LOSSES AND CAUSES OF ACTION ARISING OUT OF OR
          RELATING TO THE SERVICES, YOUR USE OF THE SERVICES, THE PRODUCTS, YOUR
          PURCHASE OR USE OF PRODUCTS OR THESE TERMS, EXCEED THE FEES ACTUALLY
          PAID BY YOU TO THE FARMERS DOG IN THE THIRTY DAYS IMMEDIATELY
          PRECEDING THE CLAIM GIVING RISE TO LIABILITY. IF ANY JURISDICTION
          PROHIBITS THE FOREGOING DISCLAIMERS, LIMITATIONS, AND EXCLUSIONS, IN
          SUCH JURISDICTIONS, THE FOREGOING DISCLAIMERS, LIMITATIONS, AND
          EXCLUSIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY THE
          JURISDICTION.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          12. Indemnification.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            12.1{' '}
          </Text>
          You agree to defend, indemnify, and hold harmless The Farmer’s Dog,
          its suppliers and its and their affiliates, directors, officers,
          employees, and agents from and against all claims, losses, damages,
          liabilities, and costs (including, without limitation, reasonable
          attorneys’ fees and court costs), arising out of or relating to your
          breach of these Terms, your access to or use of the Services, your
          negligence or willful misconduct or your violation of any applicable
          law or third-party right. You are solely responsible for interactions
          with other users of the Services.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          13. Arbitration.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.1{' '}
          </Text>
          <Text variant="article-16" bold>
            Binding Arbitration.
          </Text>{' '}
          Any dispute or claim arising in any way from your use of the Services,
          except for disputes relating to the infringement of our intellectual
          property rights or the access or use of the Services in violation of
          these Terms, will be resolved by binding arbitration, rather than in
          court, but you may assert claims in small claims court if your claims
          qualify.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.2{' '}
          </Text>
          <Text variant="article-16" bold>
            No Judge or Jury.
          </Text>{' '}
          There is no judge or jury in arbitration, and court review of an
          arbitration award is limited. However, an arbitrator can award on an
          individual basis the same damages and relief as a court (including
          injunctive and declaratory relief or statutory damages), and must
          follow the terms of these Terms as a court would.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.3{' '}
          </Text>
          <Text variant="article-16" bold>
            Arbitrator and Rules.
          </Text>{' '}
          The arbitration will be conducted before a neutral single arbitrator,
          whose decision will be ﬁnal and binding, and the arbitral proceedings
          shall be governed by the AAA Commercial Arbitration Rules, Consumer
          Due Process Protocol, and Supplementary Procedures for Resolution of
          Consumer Related Disputes. These rules can be found on the AAA website
          at <Link href="http://www.adr.org/">www.adr.org.</Link>
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.4{' '}
          </Text>
          <Text variant="article-16" bold>
            Starting an Arbitration.
          </Text>{' '}
          To begin an arbitration proceeding, you must send us a notice of
          dispute, in writing, setting forth your name, address and contact
          information, the facts of the dispute and relief requested. You must
          send your notice of legal dispute to us at the following address:{' '}
          <Link href="mailto:info@thefarmersdog.com">
            info@thefarmersdog.com
          </Link>
          . We will send any notice of dispute to you at the contact information
          we have for you.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.5{' '}
          </Text>
          <Text variant="article-16" bold>
            Format of Proceedings.
          </Text>{' '}
          The arbitration shall be conducted, at the option of the party seeking
          relief, by telephone, online, or based solely on written submissions.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.6{' '}
          </Text>
          <Text variant="article-16" bold>
            Limitation Period.
          </Text>{' '}
          In no event shall any claim, action or proceeding by you or The
          Farmer’s Dog be instituted more than one (1) year after the cause of
          action arose.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.7{' '}
          </Text>
          <Text variant="article-16" bold>
            Enforcement.
          </Text>{' '}
          Any judgment on the award rendered by the arbitrator may be entered in
          any court of competent jurisdiction. The United Nations Conventions on
          Contracts for the International Sale of Goods shall have no
          applicability.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            13.8{' '}
          </Text>
          <Text variant="article-16" bold>
            Invalidity.
          </Text>{' '}
          If a court of competent jurisdiction ﬁnds the foregoing arbitration
          provisions invalid or inapplicable, you and The Farmer’s Dog each
          agree to the exclusive jurisdiction of the federal and state courts
          located in New York County, New York, and you and The Farmer’s Dog
          each agree to submit to the exercise of personal jurisdiction of such
          courts for the purposes of litigating any applicable dispute or claim.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          14. Class Action Waiver.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            14.1{' '}
          </Text>
          <Text variant="article-16" bold>
            Individual Basis.
          </Text>{' '}
          To the fullest extent permitted by applicable law, you and the
          Farmer’s Dog each agree that any dispute resolution proceeding will be
          conducted only on an individual basis and not in a class, consolidated
          or representative action. If for any reason a claim proceeds in court
          rather than in arbitration, you and The Farmer’s Dog each waive any
          right to a jury trial. As a result, PROCEEDINGS TO RESOLVE OR LITIGATE
          A DISPUTE IN ANY FORUM WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS.
        </Text>
        <Text variant="heading-22" as="h3" bold>
          15. General provisions.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.1{' '}
          </Text>
          <Text variant="article-16" bold>
            Termination.
          </Text>{' '}
          If you breach any of the terms of these Terms, all rights granted to
          you by us, including permission to use the Services, will terminate
          automatically. Additionally, we may suspend, disable, or cancel your
          subscription and/or account with or without notice, for any or no
          reason. If we cancel your subscription or account for any suspected
          breach of these Terms by you, you are prohibited from re-registering
          for the Service under a different name. All sections which by their
          nature should survive the termination of these Terms shall continue in
          full force and effect subsequent to and notwithstanding any
          termination of your subscription, the Services or these Terms by The
          Farmer’s Dog or you, including without limitation the provisions set
          forth in Sections 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, and 15 of these
          Terms. Termination will not limit any of our other rights or remedies
          at law or in equity.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.2{' '}
          </Text>
          <Text variant="article-16" bold>
            Injunctive Relief.
          </Text>{' '}
          You agree that your breach of these Terms will cause irreparable
          injury to The Farmer’s Dog for which monetary damages would not be an
          adequate remedy and The Farmer’s Dog shall be entitled to equitable
          relief in addition to any remedies it may have hereunder or at law
          without a bond, other security or proof of damages.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.3{' '}
          </Text>
          <Text variant="article-16" bold>
            Notices.
          </Text>{' '}
          We may give notice to you by means of a general notice on the Website,
          electronic mail, through your account, or by written communication
          sent by ﬁrst class mail or pre-paid post. Such notice shall be deemed
          to have been given upon the expiration of 48 hours after mailing or
          posting (if sent by ﬁrst class mail or pre-paid post) or 12 hours
          after sending (if sent by email or through your account). You may give
          notice to us (such notice shall be deemed given when received by us)
          at any time by sending an email to{' '}
          <Link href="mailto:info@thefarmersdog.com">
            info@thefarmersdog.com
          </Link>
          . Please specify the reason for the email in the subject line so it
          can be forwarded to the proper department.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.4{' '}
          </Text>
          <Text variant="article-16" bold>
            Miscellaneous.
          </Text>{' '}
          No other act, document, usage or custom will be deemed to modify or
          amend these Terms. These Terms will inure to the beneﬁt of and will be
          binding upon each party’s successors and assigns. These Terms and the
          rights granted hereunder may be assigned by The Farmer’s Dog but may
          not be assigned by you without the prior express written consent of
          The Farmer’s Dog. Any attempt by you to assign these Terms without the
          written consent of The Farmer’s Dog shall be null and void. If any
          provision hereof is or becomes, at any time or for any reason,
          unenforceable or invalid, no other provision hereof will be affected
          thereby and the remaining provisions will continue with the same
          effect as if such unenforceable or invalid provisions will not have
          been inserted herein; provided that the ability of either party to
          obtain substantially the bargained-for performance of the other will
          not have thereby been impaired. It is expressly understood that in the
          event either party on any occasion fails to perform any term hereof
          and the other party does not enforce that term, the failure to enforce
          on any occasion will not constitute a waiver of any term and will not
          prevent enforcement on any other occasion. Nothing contained in these
          Terms will be deemed to constitute either party as the agent or
          representative of the other party or both parties as joint venturers
          or partners for any purpose. Except for any payment obligations
          hereunder, in the event that either party is prevented from
          performing, or is unable to perform, any of its obligations under
          these Terms due to any cause beyond the reasonable control of the
          party invoking this provision, the affected party’s performance will
          be extended for the period of delay or inability to perform due to
          such occurrence. The headings and captions contained herein will not
          be considered to be part of the Terms but are for convenience only.
          You and The Farmer’s Dog agree that the United Nations Convention on
          Contracts for the International Sale of Goods will not apply to the
          interpretation or construction of these Terms. The laws of the State
          of New York, excluding its conﬂicts of law rules, govern these Terms
          and your use of the Services. Your use of the Services may also be
          subject to other local, state, national, or international laws.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.5{' '}
          </Text>
          <Text variant="article-16" bold>
            California Residents.
          </Text>{' '}
          If you are a California resident, in accordance with Cal. Civ. Code
          &sect; 1789.3, you may report complaints to the Complaint Assistance
          Unit of the Division of Consumer Services of the California Department
          of Consumer Affairs by contacting them in writing at 1625 North Market
          Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
          952-5210.
        </Text>
        <Text variant="article-16" as="p">
          <Text variant="article-16" bold>
            15.6{' '}
          </Text>
          <Text variant="article-16" bold>
            Contact Us.
          </Text>{' '}
          If you have any questions regarding these Terms or the Services please
          contact us at 201 Varick St. PO Box 984, New York, NY 10014 or{' '}
          <Link href="mailto:info@thefarmersdog.com">
            info@thefarmersdog.com
          </Link>
          , or by phone at (646) 780-7957.
        </Text>
      </div>
    </Modal>
  );
}
