import * as Apollo from '@apollo/client';

import { useQuery } from '../../services/apollo';
import { PetRecipesQuery, PetRecipesQueryVariables } from '../types';

import query from './petRecipes.graphql';

type Options = Apollo.QueryHookOptions<
  PetRecipesQuery,
  PetRecipesQueryVariables
>;

export function usePetRecipes(options?: Options) {
  return useQuery<PetRecipesQuery, PetRecipesQueryVariables>(query, options);
}
