import { getImpactRadiusSegmentContext } from 'src/utils/referrer';
import { reporter } from 'src/services/reporter';

import events from '../eventNames';
import track from '../track';

/**
 * A magic string that is interpreted by impact radius to use the timestamp from
 * the segment event.
 */
export const NOW_STRING = 'NOW';

/**
 * A segment tracking event that is used to mark a customer giving us their
 * email. This event is used solely to pass data to impact radius for conversion
 * tracking.
 *
 * @param {Object} SegmentProperties A freeform object of segment properties
 * @param {Number} SegmentProperties.id The unique id of the customer
 */
export function trackImpactRadiusEmailConversion({ id } = {}) {
  if (!id) {
    reporter.warn(
      `Segment event "${events.impact_radius_email_conversion}" has missing parameter "id"`
    );
  }

  const impactRadiusAttributes = {
    customerId: id,
    orderId: id,
    eventDate: NOW_STRING,
  };

  track(
    events.impact_radius_email_conversion,
    impactRadiusAttributes,
    getImpactRadiusSegmentContext()
  );
}
