import { Link, LinkProps } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import {
  signupState,
  SubscriptionType,
} from '@farmersdog/lead-browser-storage';

interface GetEmailByProductLine {
  (productLine?: SubscriptionType): string;
}
export interface ContactSupportLinkProps extends LinkProps {
  getEmailByProductLine: GetEmailByProductLine;
}

export function ContactSupportLink({
  onClick,
  getEmailByProductLine,
}: ContactSupportLinkProps) {
  const { subscriptionTypes } = signupState.get();
  const productLine = subscriptionTypes.current;
  const email = getEmailByProductLine(productLine);
  const href = `mailto:${email}`;

  return (
    <Text as="div" variant="heading-16" bold>
      <Link variant="primary" href={href} onClick={onClick}>
        Contact Support
      </Link>
    </Text>
  );
}
