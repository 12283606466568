import config from 'src/config';
import { reporter } from 'src/services/reporter';
import { mountVendorWindowEvent } from '../utils';

export interface ConfigureGa4Args {
  properties: Record<string, unknown>;
}

function _configureGa4(w: Window, { properties = {} }: ConfigureGa4Args) {
  const enabled = config('ga4.enabled') as boolean | null;
  const measurementId = config('ga4.measurementId') as string | null;

  if (!enabled || typeof measurementId !== 'string' || measurementId === '') {
    return;
  }

  if (!w.gtag) {
    reporter.error(
      'Cannot run gtag command because "window.gtag" is not defined'
    );
    return;
  }

  w.gtag('config', measurementId, properties);
}

export const configureGa4 = mountVendorWindowEvent(
  'Ga4 Configure',
  _configureGa4
);
