import { sharedPinterestAttributes } from './constants';
import { pinterest, PinterestEventAttributes } from './pinterest';

/** The attributes required for a Pinterest page view event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'pagevisit',
};

/**
 * Mount a Pinterest page view event.
 */

export function mountPinterestPageView() {
  pinterest.mount({
    ...attributes,
  });
}
