import moment from 'moment';

import { BodyConditionName } from 'src/constants/BodyConditionName';
import { VERY_PICKY, CAN_BE_PICKY } from 'src/constants/eatingStyles';

import { PICKY, PUPPY, OVERWEIGHT, OTHER } from './constants';

/**
 * Get the facebook content id associated with a user.
 *
 * @param {Object} user A user object.
 * @param {Object} configuration The configuration object stored in redux.
 *
 * @return {PixelAttributes}
 * @typedef {String} PixelAttributes.content_type The string literal `'product'`.
 * @typedef {Array<PICKY | PUPPY | OVERWEIGHT | OTHER>} PixelAttributes.content_ids An array containing a single valid content id strings
 */
export function getFacebookPixelAttributes(user, configuration) {
  const bodyConditions = configuration?.bodyConditions ?? [];
  const eatingStyles = configuration?.eatingStyles ?? [];
  const pets = user?.pets ?? [];
  // We only want to return a single content ID. That is why we reassign this
  // variable in order to give preference to some IDs.
  let contentId = OTHER;

  const fourteenMonthsAgo = moment().subtract(14, 'months');
  if (
    pets.some(
      pet =>
        pet.birthday && moment(pet.birthday).isAfter(fourteenMonthsAgo, 'day')
    )
  ) {
    contentId = PUPPY;
  }

  const overweightBodyConditionIds = bodyConditions
    .filter(condition =>
      [BodyConditionName.Rounded, BodyConditionName.Chunky].includes(
        condition.name
      )
    )
    .map(condition => condition.id);

  if (pets.some(pet => overweightBodyConditionIds.includes(pet.condition))) {
    contentId = OVERWEIGHT;
  }

  const pickyEatingStyleIds = eatingStyles
    .filter(style => [VERY_PICKY, CAN_BE_PICKY].includes(style.name))
    .map(style => style.id);

  if (pets.some(pet => pickyEatingStyleIds.includes(pet.eatingStyle))) {
    contentId = PICKY;
  }

  return {
    content_ids: [contentId],
    content_type: 'product',
  };
}
