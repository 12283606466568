import { Picture } from '@farmersdog/corgi-x';

import { bowlPictureSources } from './assets';

interface RecipeBowlPictureProps {
  recipeName: string;
  className?: string;
}

export function RecipeBowlPicture({
  recipeName,
  className,
}: RecipeBowlPictureProps): JSX.Element | null {
  return bowlPictureSources[recipeName] ? (
    <Picture
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      sources={bowlPictureSources[recipeName]}
      alt={recipeName}
      className={className}
    />
  ) : null;
}
