import type { SlotMap, SlotPropsMap } from '../slots';
import {
  OrderSizeDownDismissibleCta,
  PickyEaterCta,
  TreatsDismissibleCta,
  FoundersVideo,
  PaymentFailedHero,
} from './features/HomepageSlot/Hero';
import { OrderSizeUpDismissibleCta } from './features/HomepageSlot/Hero/OrderSizeUpDismissibleCta/OrderSizeUpDismissibleCta';

export enum HomepageSlotId {
  Hero = 'homepage-hero',
  Tips = 'homepage-tips',
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface NoProps {}

/**
 * Assigns the props allowed for each set of candidates
 */
interface HomepagePropsMap extends SlotPropsMap<HomepageSlotId> {
  [HomepageSlotId.Hero]: NoProps;
  [HomepageSlotId.Tips]: NoProps;
}

/**
 * Maps the candidates to their slots
 */
export const homepageSlotMap: SlotMap<HomepagePropsMap> = {
  [HomepageSlotId.Hero]: [
    [PaymentFailedHero, 10],
    // users should only be eligible for one order size cta
    [OrderSizeUpDismissibleCta, 5],
    [OrderSizeDownDismissibleCta, 5],
    [TreatsDismissibleCta, 4],
    [PickyEaterCta, 3],
    [FoundersVideo, 1],
  ],
  // TODO: convert PickyEaterTips to slottable component
  // https://app.shortcut.com/farmersdog/story/127590
  [HomepageSlotId.Tips]: [
    // [PickyEaterTips, 1],
  ],
};
