import { useState } from 'react';
import { Button, Input, Text, FormControl } from '@farmersdog/corgi-x';
import { CheckCircle } from '@farmersdog/corgi-x/icons';

import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import isValidEmail from 'src/utils/isValidEmail';
import { identify } from 'src/analytics';

import styles from './NewsletterForm.module.scss';
import classNames from 'classnames';

const NewsletterForm = () => {
  const [email, setEmail] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isDisabled = isSubmitting || !isValidEmail(email);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    if (!isValidEmail(email)) {
      console.error('Invalid e-mail: %s', email);
      return;
    }
    setIsSubmitting(true);
    // We subscribe emails just by identifying the user via e-mail
    identify({ email });
    // Add visual feedback that the form has been submitted
    setIsSubmitting(false);
    setSubmitted(true);
  };

  return (
    <form name="NewsletterForm" onSubmit={handleOnSubmit}>
      <FormControl aria-live="polite">
        <div className={styles.elements}>
          <Input
            label="Email"
            name="email"
            aria-label="Email address"
            value={email}
            type="email"
            onChange={handleOnChange}
            autoComplete="email"
          />
          <Button
            variant="primary"
            aria-disabled={isDisabled}
            type="submit"
            className={classNames([
              styles.button,
              accessibilityThemeStyles.primaryCta,
            ])}
          >
            <Text variant="heading-16" bold>
              Submit
            </Text>
          </Button>
        </div>
        {submitted && (
          <div className={styles.submittedMessageContainer}>
            <Text color="white" variant="heading-16" aria-live="assertive" bold>
              <CheckCircle
                aria-hidden="true"
                className={styles.checkIcon}
                fill="white"
                size={24}
              />
              Subscribed!
            </Text>
          </div>
        )}
      </FormControl>
    </form>
  );
};

export default NewsletterForm;
