import BodyConditionChunkyExpandedNewPng from './body-condition-chunky-expanded-new.png';
import BodyConditionChunkyExpandedNewWebp from './body-condition-chunky-expanded-new.webp';
import BodyConditionChunkyExpandedPng from './body-condition-chunky-expanded.png';
import BodyConditionChunkyNew from './body-condition-chunky-new.png';
import BodyConditionChunkyNewWebp from './body-condition-chunky-new.webp';
import BodyConditionChunkyPng from './body-condition-chunky.png';
import BodyConditionJustRightExpandedNewPng from './body-condition-just-right-expanded-new.png';
import BodyConditionJustRightExpandedNewWebp from './body-condition-just-right-expanded-new.webp';
import BodyConditionJustRightExpandedPng from './body-condition-just-right-expanded.png';
import BodyConditionJustRightNewPng from './body-condition-just-right-new.png';
import BodyConditionJustRightNewWebp from './body-condition-just-right-new.webp';
import BodyConditionJustRightPng from './body-condition-just-right.png';
import BodyConditionRoundedExpandedNewPng from './body-condition-rounded-expanded-new.png';
import BodyConditionRoundedExpandedNewWebp from './body-condition-rounded-expanded-new.webp';
import BodyConditionRoundedExpandedPng from './body-condition-rounded-expanded.png';
import BodyConditionRoundedNewPng from './body-condition-rounded-new.png';
import BodyConditionRoundedNewWebp from './body-condition-rounded-new.webp';
import BodyConditionRoundedPng from './body-condition-rounded.png';
import BodyConditionTooSkinnyExpandedNew from './body-condition-too-skinny-expanded-new.png';
import BodyConditionTooSkinnyExpandedNewWebp from './body-condition-too-skinny-expanded-new.webp';
import BodyConditionTooSkinnyExpandedPng from './body-condition-too-skinny-expanded.png';
import BodyConditionTooSkinnyNewPng from './body-condition-too-skinny-new.png';
import BodyConditionTooSkinnyNewWebp from './body-condition-too-skinny-new.webp';
import BodyConditionTooSkinnyPng from './body-condition-too-skinny.png';

export const bodyConditionAssets = {
  small: [
    [BodyConditionTooSkinnyPng],
    [BodyConditionJustRightPng],
    [BodyConditionRoundedPng],
    [BodyConditionChunkyPng],
  ],
  large: [
    [BodyConditionTooSkinnyExpandedPng],
    [BodyConditionJustRightExpandedPng],
    [BodyConditionRoundedExpandedPng],
    [BodyConditionChunkyExpandedPng],
  ],
};

export const bodyConditionNewAssets = {
  small: [
    [BodyConditionTooSkinnyNewWebp, BodyConditionTooSkinnyNewPng],
    [BodyConditionJustRightNewWebp, BodyConditionJustRightNewPng],
    [BodyConditionRoundedNewWebp, BodyConditionRoundedNewPng],
    [BodyConditionChunkyNewWebp, BodyConditionChunkyNew],
  ],
  large: [
    [BodyConditionTooSkinnyExpandedNewWebp, BodyConditionTooSkinnyExpandedNew],
    [
      BodyConditionJustRightExpandedNewWebp,
      BodyConditionJustRightExpandedNewPng,
    ],
    [BodyConditionRoundedExpandedNewWebp, BodyConditionRoundedExpandedNewPng],
    [BodyConditionChunkyExpandedNewWebp, BodyConditionChunkyExpandedNewPng],
  ],
};
