/* CH#49502: Used by CVR */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Heart } from '@farmersdog/corgi';
import { Mask, Text } from '@farmersdog/corgi-x';
import { PALE_RED } from '@farmersdog/corgi/constants';

import { useViewport } from 'src/screen';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import Picture from 'src/components/Picture';

import styles from './InstagramCard.module.scss';

function InstagramCard({ icon, screenName, sources, quote, className }) {
  const roundedCard = useViewport({
    xs: false,
    lg: true,
  });
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <Card
      elevation={1}
      className={classNames(
        styles.card,
        className,
        {
          [styles.roundedCard]: roundedCard,
        },
        accessibilityThemeStyles.card
      )}
    >
      <figcaption className={styles.user}>
        <Mask shape="circle" size="sm">
          <Picture sources={[icon]} alt={`${screenName} profile`} />
        </Mask>
        <Text
          className={styles.screenName}
          variant="heading-16"
          color="kale-3"
          bold
        >
          {screenName}
        </Text>
      </figcaption>
      <Picture
        className={styles.image}
        sources={sources}
        loading="lazy"
        alt={`${screenName} post`}
      />
      <div className={styles.content}>
        <Heart width={24} fill={PALE_RED} />
        <Text
          as="blockquote"
          variant="heading-16"
          color="charcoal-3"
          className={styles.quote}
        >
          {quote}
        </Text>
      </div>
    </Card>
  );
}

InstagramCard.propTypes = {
  icon: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  quote: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default InstagramCard;
