import { createMachine } from 'xstate';
import { ReactivateEvent, ReactivateState } from '../constants';

export const reactivateMachine = createMachine({
  id: 'reactivation',
  initial: ReactivateState.ReviewSubscription,
  states: {
    [ReactivateState.ReviewSubscription]: {
      on: {
        [ReactivateEvent.Edit]: {
          target: ReactivateState.EditAccount,
        },
        [ReactivateEvent.Next]: {
          target: ReactivateState.ReactivateSuccess,
        },
      },
    },
    [ReactivateState.EditAccount]: {
      on: {
        [ReactivateEvent.Previous]: {
          target: ReactivateState.ReviewSubscription,
        },
        [ReactivateEvent.Next]: {
          target: ReactivateState.ReviewSubscription,
        },
      },
    },
    [ReactivateState.ReactivateSuccess]: {
      type: 'final',
    },
  },
});
