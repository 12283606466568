import { addOrRemoveFromArray } from './addOrRemoveFromArray';

export function getUpdatedValue(
  prevValue: string | string[],
  newValue: string
) {
  return Array.isArray(prevValue) && !Array.isArray(newValue)
    ? addOrRemoveFromArray(prevValue, newValue)
    : newValue;
}
