import { Hero as CorgiHero } from '@farmersdog/corgi';
import { Nowrap } from '@farmersdog/corgi-x';

import { Orientation, useCorgiV2Viewport } from 'src/screen';

import { Background } from './Background';

import styles from './Hero.module.scss';

import landscapeDefaultJpg from '../../assets/homepage-hero.jpg';
import landscapeDefaultWebP from '../../assets/homepage-hero.webp';
import homepageHeroPortraitJpg from '../../assets/homepage-hero-portrait.jpg';
import homepageHeroPortraitWebP from '../../assets/homepage-hero-portrait.webp';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToDiySignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';
import { ReactNode } from 'react';

const SOURCES = {
  landscape: [landscapeDefaultWebP, landscapeDefaultJpg],
  portrait: [homepageHeroPortraitWebP, homepageHeroPortraitJpg],
};

export function Hero() {
  const titleCopy = 'Homemade dog food, simplified';

  const titleContent = useCorgiV2Viewport<string | ReactNode>({
    lg: <Nowrap>{titleCopy}</Nowrap>,
    xs: titleCopy,
  });

  return (
    <CorgiHero
      // @ts-expect-error this type needs to be updated to string | ReactNode in CORGI
      title={titleContent}
      titleProps={{ color: 'kale-3', variant: 'heading-52' }}
      subtitle="An easy-to-add supplement to make your homemade dog food complete and balanced."
      subtitleProps={{ color: 'kale-3', variant: 'heading-22' }}
      ctaLabel="Get Started"
      ctaProps={{ to: getLinkToDiySignup(PATH_SIGNUP_ME) }}
      withCta
      background={
        <Orientation
          render={({ type }) => (
            <Background
              alt="DIY Nutrient Mix, an easy-to-add supplement for homemade dog food"
              sources={SOURCES[type]}
            />
          )}
        />
      }
      className={styles.container}
    />
  );
}
