import { Button, Page, Picture, Text } from '@farmersdog/corgi-x';

import styles from './MixingPlanEducation.module.css';
import image from './assets/dog-wagging-tail-eating.png';
import { trackClickAdjustDailyCalories } from '../../../../../../analytics';

interface MixingPlanEducationProps {
  onClose: () => void;
  onBack: () => void;
  onContinue: () => void;
}

export function MixingPlanEducation(props: MixingPlanEducationProps) {
  const handleClickAdjustDailyCalories = () => {
    trackClickAdjustDailyCalories();
    props.onContinue();
  };
  return (
    <Page
      layout="base"
      onCloseClick={props.onClose}
      onBackClick={props.onBack}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Start a Mixing Plan
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        We know fresh food isn’t the cheapest option, so we have a plan to suit
        everybody and every buddy. Mix smaller fresh portions (even a little can
        do wonders!) with another food to lower your weekly price.
      </Text>
      <div className={styles.imageContainer}>
        <Picture sources={[image]} alt="dog wagging tail and eating" />
      </div>
      <div className={styles.ctas}>
        <Button
          type="button"
          onClick={handleClickAdjustDailyCalories}
          className={styles.continueButton}
        >
          Adjust Daily Calories
        </Button>
        <Button
          type="button"
          variant="plain-text"
          onClick={props.onBack}
          className={styles.backButton}
        >
          <Text variant="heading-16">Back</Text>
        </Button>
      </div>
    </Page>
  );
}
