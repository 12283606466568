import {
  REFERRER_FETCH,
  REFERRER_IS_FETCHING,
  REFERRER_HAS_CALLED_FETCH,
  USER_UPDATE_REFERRER,
} from 'src/constants/actionTypes';
import { QueryParameter } from '@farmersdog/constants';
import { get, put } from 'src/actions/actionCreators';

import {
  REFERRER_TYPE_PARTNER,
  REFERRER_TYPE_USER,
} from 'src/constants/referrer';

const VALID_TYPES = new Set([REFERRER_TYPE_PARTNER, REFERRER_TYPE_USER]);

/**
 * @typedef {Object} ReferrerKey
 * @property {string} [id] - The referrer id. Required if 'code' is unspecified.
 * @property {string} [code] - The referrer's code. Required if 'id' is unspecified.
 */

/**
 * Fetches a referrer by type and id OR code.
 *
 * @param {REFERRER_TYPE_PARTNER|REFERRER_TYPE_USER} type Whether to fetch a partner or user referrer
 * @param {ReferrerKey} referrerKey
 */
export function fetchReferrer(type, { id, code }) {
  if (!VALID_TYPES.has(type)) {
    throw new Error(`'type' must be in ${VALID_TYPES}, but received '${type}'`);
  }
  if (!id && !code) {
    throw new Error('One of `id` or `code` must be specified');
  }
  const queryTokens = [`type=${type}`];
  if (id) {
    queryTokens.push(`id=${id}`);
  } else {
    queryTokens.push(`${QueryParameter.ReferrerCode}=${code}`);
  }
  return get(REFERRER_FETCH, `/referrer?${queryTokens.join('&')}`);
}

export const setIsFetchingReferrer = isFetching => ({
  type: REFERRER_IS_FETCHING,
  payload: isFetching,
});

export const hasCalledFetchReferrer = () => ({
  type: REFERRER_HAS_CALLED_FETCH,
});

export const updateUserReferrer = data =>
  put(USER_UPDATE_REFERRER, '/user', data);
