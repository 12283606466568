import { useLeadState, useTosaUserFeature } from '../../../hooks';
import {
  CvrWeightlossPersonalizationTreatments,
  FeatureName,
} from '../../../utils';

import type { UseFeatureHook } from '../../../types';

const ACTIVE_TREATMENTS = [
  CvrWeightlossPersonalizationTreatments.generic,
  CvrWeightlossPersonalizationTreatments.weight_loss,
] as const;

export type WeightLossPersonalizationActiveTreatment =
  (typeof ACTIVE_TREATMENTS)[number];

const isOnExperiment = (
  treatment: CvrWeightlossPersonalizationTreatments
): treatment is WeightLossPersonalizationActiveTreatment =>
  ACTIVE_TREATMENTS.includes(
    treatment as WeightLossPersonalizationActiveTreatment
  );

type UseWeightLossPersonalizationExperimentReturn =
  | {
      showBodyConditionPersonalization: true;
      isWeightLossTreatment: boolean;
      treatment: WeightLossPersonalizationActiveTreatment;
    }
  | {
      showBodyConditionPersonalization: false;
      isWeightLossTreatment: false;
      treatment: CvrWeightlossPersonalizationTreatments.off;
    };

interface UseWeightLossPersonalizationExperimentProps {
  useFeature: UseFeatureHook;
  bodyCondition: string | undefined | null;
}

export function useWeightLossPersonalizationExperiment({
  useFeature,
  bodyCondition,
}: UseWeightLossPersonalizationExperimentProps): UseWeightLossPersonalizationExperimentReturn {
  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const weightlossPersonalizationExperiment = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_WEIGHTLOSS_PERSONALIZATION,
    attributes: {
      numPets: lead?.pets?.length,
      discountCode: lead?.discount?.code || '',
      bodyCondition,
    },
  });

  const treatment =
    (weightlossPersonalizationExperiment.treatment as CvrWeightlossPersonalizationTreatments) ||
    CvrWeightlossPersonalizationTreatments.off;

  const showBodyConditionPersonalization =
    isOnExperiment(treatment) && !!bodyCondition;

  const isWeightLossTreatment =
    treatment === CvrWeightlossPersonalizationTreatments.weight_loss;

  return showBodyConditionPersonalization
    ? {
        showBodyConditionPersonalization,
        isWeightLossTreatment,
        treatment,
      }
    : {
        showBodyConditionPersonalization: false,
        isWeightLossTreatment: false,
        treatment: CvrWeightlossPersonalizationTreatments.off,
      };
}
