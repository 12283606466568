import moment from 'moment';
import { DateFormat } from '../constants';

export function formatDateRange(
  start: moment.MomentInput,
  end: moment.MomentInput
): string {
  const mStart = moment(start);
  const mEnd = moment(end);

  if (mStart.isSame(mEnd)) return mStart.format(DateFormat.DateRange);

  const sameMonth = mStart.month() === mEnd.month();
  const firstDate = mStart.format(DateFormat.DateRange);
  const lastDate = `${mEnd.format(sameMonth ? 'D' : DateFormat.DateRange)}`;
  return `${firstDate}—${lastDate}`;
}
