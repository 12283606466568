import { useEffect } from 'react';

import { Modal } from '@farmersdog/corgi';
import { Button, Text, Divider } from '@farmersdog/corgi-x';

import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { SignupSupportLink } from '../ContactSupportLinks';
import { Picture } from '../Picture';

import { errorDogSources } from './assets';
import styles from './ErrorModal.module.css';

interface ErrorModalProps {
  modalTitle?: string;
  setErrorModalMessage: (errorMessage: string | null) => void;
  errorMessage: string | null;
  reloadApp: () => void;
}

/**
 * ErrorModal display `error` to the user. Usually you won't need to use this.
 * This is currently used for auth/register errors at the recipes - checkout boundary for fresh
 * subscriptions and additionally at the pets - recipes boundary for DIY subscriptions.
 */
export function ErrorModal({
  errorMessage,
  setErrorModalMessage,
  modalTitle = 'Our website is being a bad boy',
  reloadApp,
}: ErrorModalProps) {
  const errorModalControl = useGlobalLightbox({
    id: LightboxId.ErrorModal,
    onClose: () => {
      handleClose();
    },
  });

  const handleClose = () => {
    errorModalControl.close();
    setErrorModalMessage(null);
    reloadApp();
  };

  useEffect(() => {
    // as the error modal is not used frequently, we can console.error these and observe in Fullstory
    if (errorMessage) {
      console.error(new Error(errorMessage));
    }
  }, [errorMessage]);

  return (
    <Modal {...errorModalControl}>
      <div className={styles.container}>
        <Text
          as="h2"
          variant="heading-40"
          color="kale-3"
          bold
          className={styles.title}
          {...errorModalControl.labelProps}
        >
          {modalTitle}
        </Text>
        <Picture
          sources={errorDogSources}
          alt="Bad Boy"
          className={styles.badBoyImage}
        />
        <Text
          variant="heading-16"
          as="p"
          {...errorModalControl.descriptionProps}
          className={styles.errorMessage}
        >
          {errorMessage}
        </Text>
      </div>

      <Divider color="Charcoal0" />
      <div className={styles.buttons}>
        <SignupSupportLink />
        <Text variant="heading-16" bold>
          <Button type="submit" onClick={handleClose}>
            Close
          </Button>
        </Text>
      </div>
    </Modal>
  );
}
