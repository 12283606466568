import { CompiledBlueprint, NodeRegistry, Node } from '../types';

import { blueprintTraverseApply } from './blueprintTraverseApply';

export function compileNodeRegistry(schema: CompiledBlueprint): NodeRegistry {
  const registry: NodeRegistry = {};

  blueprintTraverseApply<Node>(schema.root, node => {
    registry[node.__self__] = node;
  });

  return registry;
}
