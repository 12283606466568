export const BOK_CHOY = {
  label: 'Bok choy',
  value: 'bokChoy',
};

export const BRUSSEL_SPROUTS = {
  label: 'Brussel sprouts',
  value: 'brusselSprouts',
};

export const BROCCOLI = {
  label: 'Broccoli',
  value: 'broccoli',
};

export const CABBAGE = {
  label: 'Cabbage',
  value: 'cabbage',
};

export const CAULIFLOWER = {
  label: 'Cauliflower',
  value: 'cauliflower',
};

export const ZUCCHINI = {
  label: 'Zucchini',
  value: 'zucchini',
};

export const PUMPKIN = {
  label: 'Pumpkin',
  value: 'pumpkin',
};

export const BUTTERNUT_SQUASH = {
  label: 'Butternut squash',
  value: 'butternutSquash',
};

export const CHARD = {
  label: 'Chard',
  value: 'chard',
};

export const SPINACH = {
  label: 'Spinach',
  value: 'spinach',
};

export const KALE = {
  label: 'Kale',
  value: 'kale',
};

export const PEAS = {
  label: 'Peas',
  value: 'peas',
};

export const CHICKPEAS = {
  label: 'Chickpeas',
  value: 'chickpeas',
};

export const SWEET_POTATO = {
  label: 'Sweet potato',
  value: 'sweetPotato',
};

export const POTATO = {
  label: 'Potato',
  value: 'potato',
};

export const GREEN_BEANS = {
  label: 'Green beans',
  value: 'greenBeans',
};

export const LENTILS = {
  label: 'Lentils',
  value: 'lentils',
};

export const BEETS = {
  label: 'Beets',
  value: 'beets',
};

export const PARSNIPS = {
  label: 'Parsnips',
  value: 'parsnips',
};

export const CARROT = {
  label: 'Carrot',
  value: 'carrot',
};

export const vegetableOptions = [
  BOK_CHOY,
  BRUSSEL_SPROUTS,
  BROCCOLI,
  CABBAGE,
  CAULIFLOWER,
  ZUCCHINI,
  PUMPKIN,
  BUTTERNUT_SQUASH,
  CHARD,
  SPINACH,
  KALE,
  PEAS,
  CHICKPEAS,
  SWEET_POTATO,
  POTATO,
  GREEN_BEANS,
  LENTILS,
  BEETS,
  PARSNIPS,
  CARROT,
];
