import { tosaRouteWhitelist } from '@farmersdog/constants';

interface ShouldRunPageViewFromWebsiteArgs {
  pathname: string;
  showTosaRecipesPage: boolean;
  isRecipesPage: boolean;
}

export function shouldRunPageViewFromWebsite({
  pathname,
  showTosaRecipesPage,
  isRecipesPage,
}: ShouldRunPageViewFromWebsiteArgs) {
  return (
    !tosaRouteWhitelist.includes(pathname) &&
    !(isRecipesPage && showTosaRecipesPage)
  );
}
