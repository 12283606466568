import { reporter } from 'src/services/reporter';
import { trackPinterestLead } from 'src/vendors/pinterest';
import {
  TIKTOK_REGISTRATION_EVENT,
  trackTikTokEvent,
} from 'src/vendors/tiktok';

import track from 'src/analytics/track';
import events from 'src/analytics/eventNames';
import {
  getCurrentSignupSubscriptionType,
  cookie,
} from '@farmersdog/lead-browser-storage';
import { v4 as uuidv4 } from 'uuid';
import { PEACOCK_EVENTS, trackPeacockEvent } from 'src/vendors/peacock';

interface AddressData {
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

interface TrackMeSuccessArgs {
  /** RequestAttributes from the redux store, includes things like IP address and utm_source */
  requestAttributes: Record<string, unknown>;
  /** The registered user object (as opposed to from Redux) */
  user: Reducer.User;
  /** The user's human id */
  humanId: string | null;
  /** The discount override amount when cvr_globaldiscount_override is on */
  discountOverride: number | null;
  /** selected address data for track events */
  addressData?: AddressData;
}

export function trackMeSuccess({
  requestAttributes,
  humanId,
  discountOverride,
  user,
  addressData,
}: TrackMeSuccessArgs) {
  if (!user) {
    reporter.error(
      `Segment event "${events.me_success}" has missing parameter "user"`
    );
  }

  const additionalUserAttributes = {
    petsCount: user?.pets?.length,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    freshFoodConfidence: user?.freshFoodConfidence,
    email: user?.email,
    firstName: user?.firstName,
    fbp: cookie.getFacebookPixelCookie(),
    fcp: cookie.getFacebookClickIdCookie(),
    humanId,
    city: addressData?.city?.toLowerCase(),
    state: addressData?.state?.toLowerCase(),
    zip: addressData?.zip,
  };

  const currentSignupSubscriptionType = getCurrentSignupSubscriptionType();
  const eventUuid = uuidv4();

  track(events.me_success, {
    ...requestAttributes,
    ...additionalUserAttributes,
    product_line: currentSignupSubscriptionType,
    label: currentSignupSubscriptionType,
    discountOverride,
    eventId: eventUuid,
  });

  void trackPinterestLead(user?.email);

  void trackTikTokEvent(TIKTOK_REGISTRATION_EVENT, {
    email: user?.email,
    event_id: eventUuid,
  });

  trackPeacockEvent({ eventName: PEACOCK_EVENTS.SIGN_UP });
}
