import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client';

export function createInMemoryCache(options?: InMemoryCacheConfig) {
  return new InMemoryCache({
    ...options,
    typePolicies: {
      LeadView: {
        keyFields: ['email'],
      },
      PetView: {
        keyFields: ['name'],
      },
      RegisterLeadResponse: {
        keyFields: ['lead', ['email']],
      },
    },
  });
}
