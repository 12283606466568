import { ApolloError, useMutation } from '@apollo/client';
import ChangeFreshOrderSizeQuotesQuery from '../fetchChangeFreshOrderSizeQuotes/fetchChangeFreshOrderSizeQuotes.cgs.graphql';
import {
  ChangeFreshOrderSizeMutationVariables,
  ChangeFreshOrderSizeMutation,
} from './changeFreshOrderSize.cgs.generated-types';
import changeFreshOrderSize from './changeFreshOrderSize.cgs.graphql';

interface UseChangeFreshOrderSize {
  onCompleted: (response: ChangeFreshOrderSizeMutation) => void;
  onError: (error: ApolloError) => void;
}

export const useChangeFreshOrderSize = (args: UseChangeFreshOrderSize) => {
  const [submit, { loading }] = useMutation(changeFreshOrderSize, {
    refetchQueries: [ChangeFreshOrderSizeQuotesQuery],
    onCompleted: response => {
      args.onCompleted(response as ChangeFreshOrderSizeMutation);
    },
    onError: args.onError,
  });

  const onSubmit = (variables: ChangeFreshOrderSizeMutationVariables) => {
    void submit({ variables: { ...variables }, context: { endpoint: 'cgs' } });
  };

  return {
    onSubmit,
    loading,
  };
};
