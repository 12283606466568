import { useRef } from 'react';

import {
  useGlobalLightbox,
  GlobalLightboxReturn,
  LightboxId,
} from '../../../hooks';

export interface FinishRef {
  promise?: Promise<void>;
  resolve?: () => void;
}

export interface UseCalculatingScreenReturn extends GlobalLightboxReturn {
  waitForClose(): Promise<void>;
}

export function useCalculatingScreen(): UseCalculatingScreenReturn {
  const onFinishRef = useRef<FinishRef>({});
  const control = useGlobalLightbox({
    id: LightboxId.CalculatingScreen,
    onClose() {
      if (!onFinishRef.current.resolve) {
        throw new Error('Could not resolve calculatingScreen promise');
      }

      onFinishRef.current.resolve();
    },
  });

  const open = () => {
    onFinishRef.current = {};
    onFinishRef.current.promise = new Promise<void>(resolve => {
      onFinishRef.current.resolve = resolve;
    });

    control.open();
  };

  const waitForClose = async () => {
    const calculatingScreenPromise = onFinishRef.current.promise;
    if (calculatingScreenPromise) {
      await calculatingScreenPromise;
    }
  };

  return {
    ...control,
    open,
    waitForClose,
  };
}
