import { datadogRum } from './datadogRum';

interface DatadogService {
  error(error: unknown, context?: object): void;
  identify(user?: Nullable<Partial<Reducer.User>>): void;
}

export const datadog: DatadogService = {
  /**
   * Used to identify a user by id, email and name in the datadog RUM traces.
   *
   * @param user - The user to identify
   */
  identify(user?: Nullable<Partial<Reducer.User>>) {
    if (!user) {
      return;
    }

    datadogRum.setUser({
      id: String(user.id),
      email: user.email ?? undefined,
      name: user.firstName ?? undefined,
    });
  },

  /**
   * Used to submit an error to datadog
   *
   * @param error - The error to submit to datadog
   */
  error(error: unknown, context?: object) {
    datadogRum.addError(error, context);
  },
};
