import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { PageWidth, Text } from '@farmersdog/corgi-x';

import { useCorgiViewport } from 'src/screen';

import styles from './Instagram.module.scss';
import DATA from './data';
import InstagramCard from 'src/components/InstagramCard';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Instagram() {
  const headingVariant = useCorgiViewport({
    xs: 'heading-40',
    lg: 'heading-52',
  });

  const withGutter = useCorgiViewport({
    xs: false,
    lg: true,
  });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div
      className={classNames(styles.container, useCorgiViewport(styles), {
        [styles.accessibilityThemeContainer]: isAccessibilityThemeOn,
      })}
    >
      <PageWidth>
        <Text
          className={styles.heading}
          variant={headingVariant}
          color="kale-3"
          as="h2"
          bold
        >
          Farmer’s Dogs are social animals
        </Text>
      </PageWidth>
      <div className={styles.instagram}>
        {DATA.map((props, index) => (
          <ContentWidth key={index} withGutter={withGutter}>
            <InstagramCard {...props} />
          </ContentWidth>
        ))}
      </div>
    </div>
  );
}

export default Instagram;
