import { CancellationResponsesEnum } from './cancellationResponses';

/** When any of these responses are made the "current" state (by the customer
selecting and clicking "Continue") we should display the "Free Response" UI with an alternative form description text */
const changeFreeResponseTextStates = [
  CancellationResponsesEnum.ConcernedAboutIngredients,
  CancellationResponsesEnum.DogIsAllergic,
  CancellationResponsesEnum.VetHasRecipeConcerns,
  CancellationResponsesEnum.AllergicReaction,
  CancellationResponsesEnum.OtherPhysicalReaction,
  CancellationResponsesEnum.ServingTooMessy,
  CancellationResponsesEnum.PortioningTooDifficult,
  CancellationResponsesEnum.FoodArrivedWarm,
  CancellationResponsesEnum.ItemsDamagedIncorrect,
  CancellationResponsesEnum.CustomerServiceIssue,
  CancellationResponsesEnum.FoodQuality,
  CancellationResponsesEnum.VomitingDigestiveStool,
  CancellationResponsesEnum.DogNeverTouchedFood,
  CancellationResponsesEnum.DogUsedToLikeIt,
  CancellationResponsesEnum.DogAteSome,
] as const;

export function getChangeFreeResponseTextStates() {
  return changeFreeResponseTextStates;
}
