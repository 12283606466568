import { useClient } from '../../services/apollo';
import fullRegisterResponse from '../fragments/fullRegisterResponse.graphql';
import {
  FullLeadRegisterResponseFragment,
  RegisterLeadResponse,
} from '../types';
import { getEmailFromCache } from '../utils/getEmailFromCache';

type Fragment = FullLeadRegisterResponseFragment;
type TypeName = NonNullable<RegisterLeadResponse['__typename']>;
const RegisterLeadResponseTypeName: TypeName = 'RegisterLeadResponse';

export function useLazyRegisterLeadResponse() {
  const client = useClient();

  return (): Fragment | null => {
    return client.readFragment<Fragment>({
      id: getRegisterResponseCacheKey(),
      fragment: fullRegisterResponse,
      fragmentName: 'FullLeadRegisterResponse',
    });
  };
}

export function getRegisterResponseCacheKey() {
  return `${RegisterLeadResponseTypeName}:${JSON.stringify({
    lead: { email: getEmailFromCache() },
  })}`;
}
