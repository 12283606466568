import { SubscriptionType } from '../graphql/types';

import signupState, { SignupState, SIGNUP_STATE_VERSION } from './signupState';

export const DIY_SESSION_LENGTH = 60 * 60 * 1000;

function getSubscriptionTypeState(): SignupState['subscriptionTypes'] {
  const { subscriptionTypes } = signupState.get();
  return subscriptionTypes;
}

function getNextExpiration() {
  return Date.now() + DIY_SESSION_LENGTH;
}

export function isSubscriptionTypeExpired() {
  const { current, expiration } = getSubscriptionTypeState();
  return Boolean(
    current === SubscriptionType.Diy && expiration && Date.now() > expiration
  );
}

export function formatEligibleSubscriptionTypes(
  eligibleTypes: SubscriptionType[]
) {
  return JSON.stringify(eligibleTypes);
}

export function getEligibleSubscriptionTypes() {
  const currentSubscriptionTypes = getSubscriptionTypeState();
  return currentSubscriptionTypes.eligible;
}

export function isEligibleSubscriptionType(
  subscriptionType: SubscriptionType
): boolean {
  const { eligible } = getSubscriptionTypeState();
  return eligible.includes(subscriptionType);
}

export function addEligibleSubscriptionType(
  subscriptionType: SubscriptionType
): void {
  if (!isEligibleSubscriptionType(subscriptionType)) {
    const currentSubscriptionTypes = getSubscriptionTypeState();
    const nextSubscriptionTypes = {
      version: SIGNUP_STATE_VERSION,
      subscriptionTypes: {
        ...currentSubscriptionTypes,
        eligible: [...currentSubscriptionTypes.eligible, subscriptionType],
      },
    };
    signupState.set(nextSubscriptionTypes);
  }
}

export function getCurrentSignupSubscriptionType(): SubscriptionType {
  const { current } = getSubscriptionTypeState();
  return current;
}

export function setCurrentSignupSubscriptionType(
  subscriptionType: SubscriptionType
): void {
  if (subscriptionType !== getCurrentSignupSubscriptionType()) {
    const currentSubscriptionTypes = getSubscriptionTypeState();
    const expiration =
      subscriptionType === SubscriptionType.Diy ? getNextExpiration() : null;
    const nextSubscriptionTypes = {
      version: SIGNUP_STATE_VERSION,
      subscriptionTypes: {
        ...currentSubscriptionTypes,
        current: subscriptionType,
        expiration,
      },
    };
    signupState.set(nextSubscriptionTypes);
  }
}

export function isDiySignup() {
  return getCurrentSignupSubscriptionType() === SubscriptionType.Diy;
}

export function isFreshSignup() {
  return getCurrentSignupSubscriptionType() === SubscriptionType.Fresh;
}

export function isEligibleForDiy() {
  return isEligibleSubscriptionType(SubscriptionType.Diy);
}

export function isEligibleForFresh() {
  return isEligibleSubscriptionType(SubscriptionType.Fresh);
}

export function isSubscriptionType(value: unknown): value is SubscriptionType {
  return Object.values(SubscriptionType).includes(value as SubscriptionType);
}
