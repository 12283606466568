import { AddDogContent } from '../AddDogContent';

import { threeHappyDogs } from '../../../../../assets/add-dog';

interface SuccessSceneProps {
  name: string | undefined;
  onCtaClick: () => void;
}

export const SuccessScene = (props: SuccessSceneProps) => {
  const { onCtaClick, name } = props;
  return (
    <AddDogContent
      heading={`We can’t wait to welcome ${name} to the growing family`}
      subheading="We're so excited to feed another dog fresh, healthy food. Our team will email you as soon as we can to finalize your dog's plan!"
      onCtaClick={onCtaClick}
      cta="Back to Account"
      ctaButtonVariant="plain-text"
      isSuccessScene
      image={{
        src: threeHappyDogs,
        alt: 'Three happy dogs sitting together',
      }}
    />
  );
};
