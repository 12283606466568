import {
  barometric,
  BarometricEventId,
  BarometricEventName,
  PIXEL_ID,
} from './barometric';

/**
 * Track a barometric conversion event
 */
export function mountBarometricConversionEvent(userId: number): void {
  barometric.mount({
    ppt: PIXEL_ID,
    g: BarometricEventName.CONVERSION,
    gid: BarometricEventId.CONVERSION,
    cid: userId,
  });
}
