import {
  ExperimentAttributes,
  SplitKey,
  Treatment,
  UseFeatureType,
} from '../../../../../../app/lib/Split/features/OnbHomepagePersonalizationPicky';
import { useExperimentContext } from '../../../../../../experiments';
import { isEligibleForExperiment } from '../../../../HomepagePersonalization/ExperimentPicky';

type PickyEaterSplitTreatmentParams = {
  activatedAt: string | null;
  attributes: ExperimentAttributes;
  shouldBlockSplit: boolean;
};

export function usePickyEaterSplitTreatment({
  activatedAt,
  attributes,
  shouldBlockSplit,
}: PickyEaterSplitTreatmentParams) {
  const { useFeature } = useExperimentContext() as {
    useFeature: UseFeatureType;
  };

  // Check eligibility
  const isEligible = !shouldBlockSplit && isEligibleForExperiment(attributes);
  const activatedAtDate = new Date(activatedAt ?? 0).getTime();

  // Retrieve treatment (as string)
  const { treatment: featureTreatment } = useFeature?.(SplitKey, {
    attributes: { isEligible, activatedAt: activatedAtDate },
  }) ?? { treatment: Treatment.Off };

  return featureTreatment;
}
