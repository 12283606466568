import { HomepageSlotId, homepageSlotMap } from '../../Homepage.slots';
import { Slot } from '../../../slots';

interface HomepageSlotProps {
  slotId: HomepageSlotId;
}

/**
 * A local wrapper for the Slot component, to
 * provide Homepage Slot components by Slot ID.
 *
 * @param {HomepageSlotId} slotId A valid {@link HomepageSlotId | Homepage Slot ID}
 */
export function HomepageSlot({ slotId }: HomepageSlotProps) {
  return <Slot slotId={slotId} candidates={homepageSlotMap[slotId] ?? []} />;
}
