import { getNodeNameAndPosition } from '../blueprint/utils';
import { TOSALeafNode, UseForm } from '../types';

export interface GetIsLastPetArgs {
  node: TOSALeafNode;
  formMethods: UseForm;
}

export function getIsLastPet({ node, formMethods }: GetIsLastPetArgs) {
  const { position } = getNodeNameAndPosition(node.name);
  const numPets = formMethods.getValues('numPets');
  return numPets === parseInt(position);
}
