import PropTypes from 'prop-types';
import { TextV2 } from '@farmersdog/corgi';
import { Button } from '@farmersdog/corgi-x';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import ActionButtons from './ActionButtons';

import { TITLE } from './copy';

function PetInfo({ goToPrevSlide, submit }) {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          That’s ok! You can reach out to our{' '}
          <Button asLink variant="primary" onClick={submit}>
            customer service
          </Button>{' '}
          team to get help with any questions.
        </TextV2>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button variant="primary" type="submit">
            Close
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

PetInfo.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export const SLIDE_NAME = 'PET_INFO';

export default PetInfo;
