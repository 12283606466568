import { useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { paths } from '@farmersdog/constants';
import { useHistory, useLocation } from 'react-router';
import {
  CancellationResponse,
  CancellationResponsesEnum,
  CancellationResponsesEnumType,
} from './data/cancellationResponses';
import { InitialStatesEnumType, StatesUnion, TerminalStatesEnum } from './data';
import {
  getQuestionForState,
  getReasonsForState as getResponsesForState,
  randomizeResponses,
} from './utils';
import {
  getReplacementFoodStates,
  REPLACEMENT_FOOD,
} from './data/replacementFoodStates';
import { trackCancelSubscriptionFlowExit } from '../analytics';
import { SubscriptionType } from '../../graphql/types.core-api';

export interface UseCancellationProgress {
  /** Event handler to take the user to the next stage of the flow */
  onNext: () => void;
  /** Event handler to take the user to the previous stage of the flow */
  onPrevious: () => void;
  /** Event handler for when the user selects a different reason option */
  onReasonChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Event handler for when the workflow is closed */
  onClose: () => void;
  /** Reason options that the user can select for this question */
  reasons: CancellationResponse[];
  /** Human-readable question text for this state */
  question: string | undefined;
  /** User's current "stage" in the flow */
  currentState: StatesUnion;
  /** User's current selected reason in the flow */
  selectedReason: undefined | CancellationResponsesEnumType;
  /** History of cancellation reasons that the user has selected in the flow */
  reasonsHistory: CancellationResponsesEnumType[];
  /** Boolean for consumers of the flow hook to verify the current state of the machine */
  isState: (state: StatesUnion) => boolean;
  onCancellationSuccess: () => void;
  /** The user's response (if any) to "please share feedback" free-text area */
  freeResponse: string | undefined;
  /** Callback to be fired when user submits "please share feedback" form */
  setFreeResponse: (response: string) => void;
  /** Change current state to REPLACEMENT_FOOD */
  transitionToReplacementFoodState: () => void;
  /** The food brand the customer will be replacing TFD with */
  selectedFoodBrand: string | undefined;
  /** Callback to be fired when the user selects a food brand */
  onFoodBrandChange: (brand: string) => void;
  /** The source of the cook-at-home recipe the customer will be replacing TFD with */
  selectedCookAtHomeReplacementRecipe: string | undefined;
  /** Callback to be fired when the user selects a recipe source */
  onCookAtHomeReplacementRecipeChange: (source: string) => void;
}

export interface UseCancellationProgressArgs {
  initialState: InitialStatesEnumType;
}

type CancellationProgressHistoryState = {
  flowState?: StatesUnion;
};

const INITIAL_REASON: undefined | CancellationResponsesEnumType = undefined;
const INITIAL_REASON_HISTORY: CancellationResponsesEnumType[] = [];
const INITIAL_FOOD_BRAND: undefined | string = undefined;
const INITIAL_RECIPE_SOURCE: undefined | string = undefined;
const INITIAL_FREE_RESPONSE: undefined | string = undefined;

/**
 * Manages state in the cancellation reasons flow.
 *
 * @returns an object that exposes
 * - the current state in the flow
 * - the current list of cancellation reasons
 * - associated human-readable question for the current state
 * - associated reasons (AKA options) for the current question
 * - a callback that sends the user to the next state
 * - a callback that sends the user to the previous state
 * - a callback that transitions state to "ReplacementFood"
 */
export function useCancellationProgress({
  initialState,
}: UseCancellationProgressArgs): UseCancellationProgress {
  const history = useHistory<CancellationProgressHistoryState>();
  const location = useLocation<CancellationProgressHistoryState>();

  const [currentStateVal, setCurrentStateVal] =
    useState<StatesUnion>(initialState);
  const [selectedReason, setSelectedReason] = useState(INITIAL_REASON);
  const [cancellationReasonsHistory, setCancellationReasonsHistory] = useState(
    INITIAL_REASON_HISTORY
  );
  const [selectedFoodBrand, setSelectedFoodBrand] =
    useState(INITIAL_FOOD_BRAND);

  function onFoodBrandChange(brand: string) {
    setSelectedFoodBrand(brand);
  }

  const [
    selectedCookAtHomeReplacementRecipe,
    setSelectedCookAtHomeReplacementRecipe,
  ] = useState(INITIAL_RECIPE_SOURCE);

  function onCookAtHomeReplacementRecipeChange(brand: string) {
    setSelectedCookAtHomeReplacementRecipe(brand);
  }

  const [freeResponse, setFreeResponse] = useState(INITIAL_FREE_RESPONSE);

  const reasons = useMemo(() => {
    const responses = getResponsesForState(currentStateVal);
    const randomizedResponses = randomizeResponses(responses);
    return randomizedResponses;
  }, [currentStateVal]);
  const question = getQuestionForState(currentStateVal);
  const replacementFoodStates = getReplacementFoodStates();

  /** Transitions the customer to the "What are you replacing TFD with" state */
  const transitionToReplacementFoodState = () => {
    setCurrentStateVal(REPLACEMENT_FOOD);
  };

  /** Controls the flow of transition depending on the current state / selected response */
  const handleTransition = (response: CancellationResponsesEnumType) => {
    const isPrescriptionDietState = isState(
      CancellationResponsesEnum.VetRecommendedPrescriptionDiet
    );
    const shouldTransitionToReplacementFoodState =
      replacementFoodStates.includes(response) || isPrescriptionDietState;

    if (shouldTransitionToReplacementFoodState) {
      transitionToReplacementFoodState();
    } else {
      setCurrentStateVal(response);
    }
  };

  /**
   * - Captures the customer’s response to a question
   * - Handles transition based on selected response
   * - Resets selected response
   * - Appends the current state to browser history
   */
  const onNext = () => {
    if (!selectedReason) return;

    setCancellationReasonsHistory(existing => {
      return [...existing, selectedReason];
    });
    handleTransition(selectedReason);
    setSelectedReason(undefined);

    history.push(paths.PAW_PATH_CANCEL_REASONS, {
      flowState: currentStateVal,
    });
  };
  const onClose = () => {
    trackCancelSubscriptionFlowExit({
      exitReason: 'flow-closed',
      subscriptionType: SubscriptionType.Fresh,
      cancellationReasonsHistory,
    });
    history.push(paths.PATH_CUSTOMER_ACCOUNT);
  };
  const goToPreviousState = useCallback(() => {
    if (location.state?.flowState) {
      setSelectedReason(undefined);
      setCurrentStateVal(location.state.flowState);
      setCancellationReasonsHistory(existing => existing.slice(0, -1));
    }
  }, [location.state]);
  const onPrevious = () => {
    history.goBack();
  };
  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      if (action === 'POP') {
        goToPreviousState();
      }
    });
    return unlisten;
  }, [history, goToPreviousState]);
  const onReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(event.target.value as CancellationResponsesEnumType);
  };
  const isState = (state: StatesUnion): boolean => state === currentStateVal;
  const onCancellationSuccess = () => {
    setCurrentStateVal(TerminalStatesEnum.CancelSuccess);
  };
  return {
    currentState: currentStateVal,
    selectedReason,
    onNext,
    onPrevious,
    onReasonChange,
    onClose,
    reasons,
    question,
    reasonsHistory: cancellationReasonsHistory,
    isState,
    setFreeResponse,
    freeResponse,
    onCancellationSuccess,
    transitionToReplacementFoodState,
    selectedFoodBrand,
    onFoodBrandChange,
    selectedCookAtHomeReplacementRecipe,
    onCookAtHomeReplacementRecipeChange,
  };
}
