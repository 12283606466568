import { createReducer } from 'redux-create-reducer';
import * as actionTypes from 'src/constants/actionTypes';

const initialUiState = {
  error: undefined,
  // TODO: is this `modal` property used? Remove if is not
  modal: undefined,
  options: undefined,
  // TODO: lightboxes do not belong here, _or_ the lines above should be put in
  // an object and the selector below updated
  lightboxes: {},
};

/**
 * Return if there is an open global lightbox.
 *
 * @param {Object} uiState The current ui state object.
 * @return {Boolean} Whether there is an open lightbox.
 */
function getHasOpenLightbox(uiState) {
  return Object.values(uiState.lightboxes).some(lightbox => lightbox.isOpen);
}

/**
 * Return the should show state of a lightbox.
 *
 * @param {Object} uiState The current ui state object.
 * @param {String} id The string ID of the lightbox
 */
function getShouldShowLightbox(uiState, id) {
  const lightbox = uiState.lightboxes[id];
  const shouldShow = lightbox?.shouldShow;

  return typeof shouldShow === 'undefined' ? true : shouldShow;
}

/**
 * Return a new state of with all lightboxes closed.
 *
 * @param {Object} uiState The UI State
 * @return {Object} The lightboxes state
 */
function getClosedLightboxes(uiState) {
  const lightboxes = {};

  Object.entries(uiState.lightboxes).forEach(([id, lightboxState]) => {
    lightboxes[id] = {
      ...lightboxState,
      isOpen: false,
    };
  });

  return lightboxes;
}

export default createReducer(initialUiState, {
  [actionTypes.UI_ERROR_MODAL_SHOW](uiState, action) {
    return Object.assign({}, uiState, {
      error: action.payload.error,
      options: action.payload.options,
    });
  },
  [actionTypes.UI_ERROR_MODAL_HIDE](uiState) {
    return Object.assign({}, uiState, {
      error: undefined,
      options: undefined,
    });
  },
  [actionTypes.UI_LIGHTBOX_SET](uiState, action) {
    const { id, changes } = action.payload;

    return {
      ...uiState,
      lightboxes: {
        ...uiState.lightboxes,
        [id]: {
          ...uiState.lightboxes[id],
          ...changes,
        },
      },
    };
  },
  [actionTypes.UI_LIGHTBOX_SHOW](uiState, action) {
    const { id, override } = action.payload;
    const hasOpenLightbox = getHasOpenLightbox(uiState);
    const shouldShow = getShouldShowLightbox(uiState, id);

    if (override && shouldShow) {
      return {
        ...uiState,
        lightboxes: {
          ...getClosedLightboxes(uiState),
          [id]: {
            ...uiState.lightboxes[id],
            isOpen: true,
          },
        },
      };
    }

    return {
      ...uiState,
      lightboxes: {
        ...uiState.lightboxes,
        [id]: {
          ...uiState.lightboxes[id],
          isOpen: Boolean(shouldShow && !hasOpenLightbox),
        },
      },
    };
  },
  [actionTypes.UI_LIGHTBOX_HIDE](uiState, action) {
    const { id } = action.payload;

    return {
      ...uiState,
      lightboxes: {
        ...uiState.lightboxes,
        [id]: {
          ...uiState.lightboxes[id],
          isOpen: false,
        },
      },
    };
  },
  [actionTypes.UI_LIGHTBOX_HIDE_ALL](uiState) {
    return {
      ...uiState,
      lightboxes: getClosedLightboxes(uiState),
    };
  },
});

export const selectConfirm = state => state.ui.confirm;
export const selectError = state => state.ui.error;
export const selectOptions = state => state.ui.options;

/**
 * Return whether a lightbox is open
 *
 * @param {Object} state
 * @param {string} id
 *
 * @return {boolean}
 */
export const selectLightboxIsOpen = (state, id) => {
  const lightbox = state.ui.lightboxes[id];
  return lightbox?.isOpen;
};

export const selectLightboxShouldShow = (state, id) => {
  return getShouldShowLightbox(state.ui, id);
};

export const selectHasOpenLightbox = state => {
  return getHasOpenLightbox(state.ui);
};
