import { isSubscriptionTypeExpired } from '@farmersdog/lead-browser-storage';

import { useSetSignupFlow } from 'src/pages/Signup/DIY/hooks/useSetSignupFlow';

export function useExpireSubscriptionType() {
  const { enableFreshSignupFlow } = useSetSignupFlow();

  if (isSubscriptionTypeExpired()) {
    enableFreshSignupFlow();
  }
}
