import { Button, Grid, GridItem, Section, Text } from '@farmersdog/corgi-x';
import { useEffect, useId, useState } from 'react';
import styles from './OrderSizes.module.css';
import { Badge, OrderSizeDescription } from './components';
import { useOrderSizeNudgeStorage } from '../../../homepage/features/HomepageSlot/Hero/OrderSizeDismissibleCta/hooks';
import { trackOrderSizeUpNudgeDisplayedPlanOptions } from '../../analytics/trackSuggestOrderSizeUp';
export type OrderSizeSubscriptionDetails = {
  daysOfFood: number;
  dailyConsumptionPrice: number;
  orderTotal: number;
};

export interface OrderSizesProps {
  callSubmit: () => void;
  onReject: () => void;
  currentSubscription: OrderSizeSubscriptionDetails;
  suggestedSubscription: OrderSizeSubscriptionDetails;
}

/**
 * Render current and suggested order sizes and prices
 */
export function OrderSizes(props: OrderSizesProps) {
  const { setOrderSizeNudgeDismissed } = useOrderSizeNudgeStorage(
    window.localStorage
  );
  const headingId = useId();
  const [
    didCallTrackOrderSizeNudgeDisplayedPlanOptions,
    setDidCallTrackOrderSizeNudgeDisplayedPlanOptions,
  ] = useState(false);
  const onAcceptSuggestion = () => {
    setOrderSizeNudgeDismissed();
    props.callSubmit();
  };
  const onRejectSuggestion = () => {
    setOrderSizeNudgeDismissed();
    props.onReject();
  };
  useEffect(() => {
    if (
      !didCallTrackOrderSizeNudgeDisplayedPlanOptions &&
      props.currentSubscription &&
      props.suggestedSubscription
    ) {
      trackOrderSizeUpNudgeDisplayedPlanOptions({
        currentSubscription: props.currentSubscription,
        suggestedSubscription: props.suggestedSubscription,
      });
      setDidCallTrackOrderSizeNudgeDisplayedPlanOptions(true);
    }
  }, [
    props.currentSubscription,
    props.suggestedSubscription,
    didCallTrackOrderSizeNudgeDisplayedPlanOptions,
  ]);

  return (
    <Section
      backgroundColor="Chickpea1"
      className={styles.contentSection}
      aria-labelledby={headingId}
    >
      <Grid className={styles.content} flexDirection={'column'}>
        <Text variant="heading-40" color="kale-3" as="h1" bold id={headingId}>
          Order Sizes
        </Text>

        <Text variant="article-20" topSpacing="xs">
          Choose how much food you want in each order and how regularly you’ll
          receive fresh boxes.
        </Text>

        <Grid topSpacing={{ xs: 'md', md: 'lg' }} gap="lg">
          <GridItem md={6}>
            <Section
              variant="card"
              className={styles.card}
              aria-label="Suggested Plan"
            >
              <OrderSizeDescription
                {...props.suggestedSubscription}
                subCopy={['Best Value', 'Fewest Deliveries']}
              />
              <Grid topSpacing="md">
                <Button
                  onClick={onAcceptSuggestion}
                  type="button"
                  className={styles.cta}
                >
                  Switch to This Plan
                </Button>
              </Grid>
            </Section>
          </GridItem>

          <GridItem md={6}>
            <Section
              variant="card"
              className={styles.card}
              aria-label="Current Plan"
            >
              <Badge>Current Plan</Badge>

              <OrderSizeDescription
                {...props.currentSubscription}
                subCopy={['Pay Less Per Box', 'Best for Small Freezers']}
              />
              <Grid topSpacing="md">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={onRejectSuggestion}
                  className={styles.cta}
                >
                  Keep Current Plan
                </Button>
              </Grid>
            </Section>
          </GridItem>
        </Grid>

        <Text as="p" topSpacing="md" variant="heading-12" color="charcoal-3">
          * Prices do not include treats or taxes (if applicable)
        </Text>
      </Grid>
    </Section>
  );
}
