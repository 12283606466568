import { Input, CreditCardInput } from '@farmersdog/corgi-x';

import styles from './CreditCardDetails.module.css';
import { UseCreditCardForm } from '../hooks/useCreditCardForm';
import classNames from 'classnames';

export interface CreditCardExpirationProps {
  className: string;
  expirationMonth: number;
  expirationYear: number;
  isEditing: boolean;
  onCreditCardExpirationFocus: UseCreditCardForm['handleCreditCardDetailsFocus'];
  onCreditCardExpirationChange: UseCreditCardForm['handleCreditCardDetailsChange'];
}

/**
 * Shows placeholder for credit card expiration date until `props.isEditing` is
 * `true`, which exposes the Corgi/Stripe-integrated component and hides the
 * placeholder.
 */
export function CreditCardExpiration(props: CreditCardExpirationProps) {
  return (
    <div className={classNames(styles.parent, props.className)}>
      {props.isEditing ? (
        <CreditCardInput
          type="cardExpiry"
          className={styles.cardExpiration}
          label="Expiration"
          onChange={e =>
            props.onCreditCardExpirationChange({ expiration: e.complete })
          }
        />
      ) : (
        <Input
          className={styles.placeholderElement}
          label="Expiration"
          value={`${props.expirationMonth}/${props.expirationYear}`}
          onFocus={props.onCreditCardExpirationFocus}
        />
      )}
    </div>
  );
}
