export function getPreferencesFormCopy() {
  return {
    orderUpdates: {
      title: 'Delivery Updates',
      copy: 'Keep up-to-date on your order’s journey, from departing our kitchen to arriving on your doorstep.',
    },
    orderReminders: {
      title: 'Order Reminders',
      copy: 'Been a while between boxes? We’ll tell you before the next one ships so you can make any desired updates!',
    },
    marketingNotifications: {
      title: 'Offers and Promotions',
      copy: 'Be one of the first to know about products, offers, and promotions for your dogs.',
    },
    updateSuccess: 'Notification settings updated',
    updateFailure: 'There was an error updating your communication preferences',
    updateIncomplete:
      'You have unsaved changes to notification preferences. Are you sure you want to leave?',
    optInLegalDisclaimer:
      'By checking here and clicking "Save Changes", I agree to receive marketing and customer service communications by automatic telephone dialing system or other technology, including calls, pre-recorded messages, and text messages from The Farmer’s Dog at the telephone number provided. This agreement is not entered into as a condition or requirement of purchase. Reply STOP to cancel texts. Msg & data rates may apply.',
  };
}
