import PropTypes from 'prop-types';
import { Text, Divider, Mask } from '@farmersdog/corgi-x';
import styles from './VetsWide.module.scss';
import Picture from '../../../../components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Vet({ name, quote, iconSources }) {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div className={styles.vet}>
      <Mask shape="circle" size="md" className={styles.icon}>
        <Picture sources={iconSources} alt={name} />
      </Mask>
      <Text
        variant="heading-22"
        color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
        as="figcaption"
        bold
        className={styles.name}
      >
        {name}
      </Text>
      <Text
        as="blockquote"
        color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
        variant="article-20"
        className={styles.quote}
      >
        “{quote}”
      </Text>
    </div>
  );
}

Vet.propTypes = {
  name: PropTypes.string.isRequired,
  quote: PropTypes.node.isRequired,
  iconSources: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function VetsWide({ vetsData }) {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div className={styles.container}>
      <Vet {...vetsData[0]} />
      <Divider
        orientation="vertical"
        margin={13}
        color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
        width={1}
      />
      <Vet {...vetsData[1]} />
      <Divider
        orientation="vertical"
        margin={13}
        color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
        width={1}
      />
      <Vet {...vetsData[2]} />
    </div>
  );
}

VetsWide.propTypes = {
  vetsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VetsWide;
