import { PetNodes } from '@farmersdog/constants';

import { IndexedPetFields } from '../blueprint/types';
import { MAX_NUMBER_OF_PETS } from '../validation/constants';

import { makeIndexedName } from './makeIndexedName';

interface GetPossiblePetFieldsArgs {
  petFields?: PetNodes[];
  maxPets?: number;
}
export function getPossiblePetFields({
  petFields,
  maxPets = MAX_NUMBER_OF_PETS,
}: GetPossiblePetFieldsArgs): Array<keyof IndexedPetFields> {
  const possibleFields: Array<keyof IndexedPetFields> = [];
  const source = petFields ?? Object.values(PetNodes);
  for (let i = 1; i <= maxPets; i++) {
    const fields = source.map(
      (nodeName: PetNodes): keyof IndexedPetFields =>
        makeIndexedName(nodeName, i) as keyof IndexedPetFields
    );

    possibleFields.push(...fields);
  }

  return possibleFields;
}
