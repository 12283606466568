import {
  SIGNUP_MIXING_PLAN_FETCH_FULL_PLAN_QUOTE,
  SIGNUP_MIXING_PLAN_FETCH_MIXING_PLAN_QUOTE,
  SIGNUP_MIXING_PLAN_FETCH_BOOSTER_PLAN_QUOTE,
  SIGNUP_MIXING_PLAN_SET_HAS_SEEN_ERROR,
  SIGNUP_MIXING_PLAN_SET_MIXING_PLAN_OPTIONS_ARE_LOADING,
} from 'src/constants/actionTypes';

const initialState = {
  quotes: {
    fullPlan: null,
    mixingPlan: null,
    boosterPlan: null,
  },
  hasSeenError: false,
  mixingPlanOptionsAreLoading: false,
};

export default function signupMixingPlanReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_MIXING_PLAN_FETCH_FULL_PLAN_QUOTE:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          fullPlan: action.payload.data,
        },
      };
    case `${SIGNUP_MIXING_PLAN_FETCH_FULL_PLAN_QUOTE}_FAILURE`:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          fullPlan: initialState.fullPlan,
        },
      };

    case SIGNUP_MIXING_PLAN_FETCH_MIXING_PLAN_QUOTE:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          mixingPlan: action.payload.data,
        },
      };
    case `${SIGNUP_MIXING_PLAN_FETCH_MIXING_PLAN_QUOTE}_FAILURE`:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          mixingPlan: initialState.mixingPlan,
        },
      };

    case SIGNUP_MIXING_PLAN_FETCH_BOOSTER_PLAN_QUOTE:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          boosterPlan: action.payload.data,
        },
      };
    case `${SIGNUP_MIXING_PLAN_FETCH_BOOSTER_PLAN_QUOTE}_FAILURE`:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          boosterPlan: initialState.boosterPlan,
        },
      };

    case SIGNUP_MIXING_PLAN_SET_HAS_SEEN_ERROR:
      return {
        ...state,
        hasSeenError: action.payload.hasSeenError,
      };

    case SIGNUP_MIXING_PLAN_SET_MIXING_PLAN_OPTIONS_ARE_LOADING:
      return {
        ...state,
        mixingPlanOptionsAreLoading: action.payload.mixingPlanOptionsAreLoading,
      };

    default:
      return state;
  }
}

/**
 * Check the user's mixing plan experiment quotes for full, half, and booster.
 *
 * @param {Object} state - the redux state object
 * @return {Reducer.mixingPlanExperiment.quotes}
 */
export function selectMixingPlanExperimentQuotes(state) {
  return state.signup.mixingPlanExperiment.quotes;
}

/**
 * Check whether the user has seen a mixing plan experiment error state.
 *
 * @param {Object} state - the redux state object
 * @return {Reducer.mixingPlanExperiment.hasSeenError}
 */
export function selectMixingPlanHasSeenError(state) {
  return state.signup.mixingPlanExperiment.hasSeenError;
}

/**
 * Check whether the the mixing plan options are loading.
 *
 * @param {Object} state - the redux state object
 * @return {Reducer.mixingPlanExperiment.mixingPlanOptionsAreLoading}
 */
export function selectMixingPlanOptionsAreLoading(state) {
  return state.signup.mixingPlanExperiment.mixingPlanOptionsAreLoading;
}
