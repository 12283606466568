import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as Paths from '@farmersdog/constants/paths';

import { trackRadioHomePageView } from './trackRadioHomePageView';

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a Radio page view event.
 */
export function useTrackRadioPageView() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === Paths.PATH_HOME) {
      trackRadioHomePageView();
    }
  }, [pathname]);
}
