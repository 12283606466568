enum TimeAccuracy {
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

const WEEKS_IN_YEAR = 52;
const WEEKS_IN_MONTH = 4.345;

const TWELVE_WEEKS = 12;
const TWO_YEARS_IN_WEEKS = WEEKS_IN_YEAR * 2;

/**
 * Format from age in weeks to years
 */
export function formatAge(ageInWeeks: number) {
  let accuracy = TimeAccuracy.Year;
  let age = Math.floor(ageInWeeks / WEEKS_IN_YEAR);

  if (ageInWeeks < TWELVE_WEEKS) {
    accuracy = TimeAccuracy.Week;
    age = ageInWeeks;
  } else if (ageInWeeks < TWO_YEARS_IN_WEEKS) {
    accuracy = TimeAccuracy.Month;
    age = Math.floor(ageInWeeks / WEEKS_IN_MONTH);
  }

  return `${age} ${accuracy} old`;
}
