import { TextProps } from '@farmersdog/corgi-x';

import styles from '../HigherDiscountBanner.module.css';

export function getSurpriseHigherDiscountBannerProps({
  isRecipesPage,
  discountAmount,
}: {
  isRecipesPage: boolean;
  discountAmount: number;
}) {
  const shouldUseSurpriseHigherDiscountRecipePageBannerStyles = isRecipesPage;

  const surpriseHigherDiscountClassName =
    shouldUseSurpriseHigherDiscountRecipePageBannerStyles
      ? styles.surpriseDiscountBanner
      : '';

  const surpriseHigherDiscountBannerTextColor: TextProps['color'] =
    shouldUseSurpriseHigherDiscountRecipePageBannerStyles ? 'Kale3' : 'White';

  const originalDiscount = discountAmount - 10;
  const surpriseHigherDiscountBannerCopy = `Things just got even fresher. Here's 10% off on top of your ${originalDiscount}% first box discount!`;

  const surpriseHigherDiscountLinkCopy = ' Offer Terms';

  return {
    surpriseHigherDiscountClassName,
    surpriseHigherDiscountBannerTextColor,
    surpriseHigherDiscountBannerCopy,
    surpriseHigherDiscountLinkCopy,
  };
}
