import { Script } from 'src/components/Script';

export function LiveIntentScript() {
  return (
    <Script
      type="text/javascript"
      src="//b-code.liadm.com/a-037l.min.js"
      async
    />
  );
}
