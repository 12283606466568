import { useCxContact } from 'src/abTesting/useCxContact';

import { useRouteMatch } from 'react-router-dom';
import { PATH_DIY } from '@farmersdog/constants/paths';

import { EMAIL_HELP, EMAIL_DIY_HELP } from 'src/constants/emails';

export function useFullFooterContact() {
  const { phone, email } = useCxContact('prospective', 'footer');
  const showDiyCxContact = useRouteMatch(PATH_DIY);

  return showDiyCxContact
    ? { showPhone: false, showEmail: email, supportEmail: EMAIL_DIY_HELP }
    : { showPhone: phone, showEmail: email, supportEmail: EMAIL_HELP };
}
