import { Prompt } from 'react-router';
import { useToast } from '@farmersdog/corgi';
import { Button, Form, Text } from '@farmersdog/corgi-x';
import {
  MarketingNotificationFieldsFragment,
  OrderNotificationFieldsFragment,
  useUpdateNotificationPreferences,
} from '../../network';
import { SmsCheckbox } from '../SmsCheckbox';
import { EmailSmsCheckbox } from '../EmailSmsCheckbox';
import { getPreferencesFormCopy } from './getPreferencesFormCopy';
import {
  mapDataToFormShape,
  PreferenceFormFieldName,
  usePreferencesForm,
} from './usePreferencesForm';
import styles from './PreferencesForm.module.css';
import { trackNotificationPreferencesSaved } from '../../analytics/trackNotificationPreferencesSaved';

interface PreferencesFormProps {
  marketingNotifications: MarketingNotificationFieldsFragment;
  orderReminders: OrderNotificationFieldsFragment;
  orderUpdates: OrderNotificationFieldsFragment;
}

export function PreferencesForm(props: PreferencesFormProps) {
  const content = getPreferencesFormCopy();
  const dispatchToast = useToast();
  const form = usePreferencesForm(mapDataToFormShape({ ...props }));
  const submit = useUpdateNotificationPreferences({
    onCompleted: responseValues => {
      // We need to reset the form behavior after we have updated the system
      // We are using the mutation response to set the new initial form values
      form.resetForm({
        values: mapDataToFormShape({ ...responseValues }),
      });

      dispatchToast({
        variant: 'positive',
        children: content.updateSuccess,
      });
    },
    onError: () => {
      dispatchToast({
        variant: 'negative',
        children: content.updateFailure,
      });
    },
  });

  async function handleSubmit() {
    await submit.callSubmit({
      marketingNotificationsSms: form.values.MarketingNotificationsSms,
      orderRemindersSms: form.values.OrderRemindersSms,
      orderUpdatesSms: form.values.OrderUpdatesSms,
    });
    trackNotificationPreferencesSaved({
      new: {
        orderReminders: {
          email: props.orderReminders.email,
          sms: form.values.OrderRemindersSms,
        },
        orderUpdates: {
          email: props.orderUpdates.email,
          sms: form.values.OrderUpdatesSms,
        },
        marketingNotifications: {
          sms: form.values.MarketingNotificationsSms,
        },
      },
      old: {
        orderReminders: {
          email: props.orderReminders.email,
          sms: props.orderReminders.sms,
        },
        orderUpdates: {
          email: props.orderUpdates.email,
          sms: props.orderUpdates.sms,
        },
        marketingNotifications: {
          sms: props.marketingNotifications.sms,
        },
      },
    });
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className={styles.checkboxContainer}>
          <EmailSmsCheckbox
            content={content.orderUpdates}
            emailCheck={props.orderUpdates.email}
            smsCheck={form.values.OrderUpdatesSms}
            onChange={form.handleChange}
            fieldName={PreferenceFormFieldName.OrderUpdatesSms}
          />
          <EmailSmsCheckbox
            content={content.orderReminders}
            emailCheck={props.orderReminders.email}
            smsCheck={form.values.OrderRemindersSms}
            onChange={form.handleChange}
            fieldName={PreferenceFormFieldName.OrderRemindersSms}
          />
          <SmsCheckbox
            content={content.marketingNotifications}
            smsCheck={form.values.MarketingNotificationsSms}
            onChange={form.handleChange}
            fieldName={PreferenceFormFieldName.MarketingNotificationsSms}
          />
        </div>
        {/* We only want to render if a customer is opting into receiving marketing comms */}
        {form.values.MarketingNotificationsSms &&
          !form.initialValues.MarketingNotificationsSms && (
            <Text
              variant="heading-12"
              className={styles.legalTextContainer}
              as="p"
              vSpacing="md"
            >
              {content.optInLegalDisclaimer}
            </Text>
          )}
        <div className={styles.buttonContainer}>
          {form.dirty && (
            <Button
              type="button"
              variant="plain-text"
              onClick={form.handleReset}
              disabled={submit.isSubmitting}
              className={styles.cancelButton}
            >
              <Text variant="heading-16">Cancel</Text>
            </Button>
          )}
          <Button
            type="submit"
            variant="primary"
            disabled={submit.isSubmitting || !form.dirty}
            className={styles.submitButton}
          >
            <Text variant="heading-16" as="span" bold>
              Save Changes
            </Text>
          </Button>
        </div>
      </Form>
      <Prompt when={form.dirty} message={content.updateIncomplete} />
    </>
  );
}
