import { useQuery } from '@apollo/client';
import GetStates from './GetStates.cgs.graphql';
import { GetStatesQuery } from './GetStates.cgs.generated-types';

interface useGetStatesProps {
  onCompleted: (data: GetStatesQuery) => void;
}

export const useGetStates = ({ onCompleted }: useGetStatesProps) =>
  useQuery<GetStatesQuery>(GetStates, {
    context: {
      endpoint: 'cgs',
      fetchPolicy: 'cache-first',
    },
    onCompleted,
  });
