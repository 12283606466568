import classNames from 'classnames';
import React, { ReactNode, ChangeEventHandler, FocusEventHandler } from 'react';

import { Text } from '@farmersdog/corgi-x';

import styles from './PetHealthCheckbox.module.css';

/**
 * Render a checkbox or a radio button similarly to a iOS [segmented
 * control](https://developer.apple.com/design/human-interface-guidelines/ios/controls/segmented-controls/).
 * As default, it works like a `<input type="checkbox">`.
 */
type InputAttributesType = JSX.IntrinsicElements['input'];

interface PetHealthCheckboxProps extends InputAttributesType {
  children: ReactNode;
  id: string;
  name: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  hasWarning?: boolean;
}

export const PetHealthCheckbox = React.forwardRef<
  HTMLInputElement,
  PetHealthCheckboxProps
>(({ children, hasWarning, ...props }, ref) => {
  const id = props.id || `${props.name}-${props.value}`;

  return (
    <label
      className={classNames(styles.root, {
        [styles.hasWarning]: hasWarning,
      })}
      id={id}
    >
      <input {...props} type="checkbox" ref={ref} />{' '}
      <Text as="span" variant="heading-16" color="charcoal-3">
        {children}
      </Text>
    </label>
  );
});
