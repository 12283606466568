import { JourneyStatus } from '../../../../../graphql/types.cgs';

interface useGetHomepageTreatmentProps {
  journeyStatus?: JourneyStatus;
  hasPickyEater?: boolean;
}

export enum HomepageTreatment {
  AwaitingFirstBox = 'awaiting_first_box',
  ReceivedFirstBoxPicky = 'received_first_box_picky',
  ReceivedFirstBoxNotPicky = 'received_first_box_not_picky',
  Default = 'default',
}

export function useGetHomepageTreatment({
  journeyStatus,
  hasPickyEater,
}: useGetHomepageTreatmentProps): string {
  if (journeyStatus === JourneyStatus.AwaitingFirstBox) {
    return HomepageTreatment.AwaitingFirstBox;
  } else if (
    journeyStatus === JourneyStatus.ReceivedFirstBox &&
    hasPickyEater
  ) {
    return HomepageTreatment.ReceivedFirstBoxPicky;
  } else if (
    journeyStatus === JourneyStatus.ReceivedFirstBox &&
    !hasPickyEater
  ) {
    return HomepageTreatment.ReceivedFirstBoxNotPicky;
  }

  return HomepageTreatment.Default;
}
