import { formatPossessive } from './formatPossessive';

type Name = string | null | undefined;

interface ObjectWithName {
  name: Name;
}

interface Options {
  /** First array element starts with uppercase */
  startWithUppercase?: boolean;
  /** All array elements start with uppercase */
  allStartWithUppercase?: boolean;
  /** Add possessive */
  possessive?: boolean;
  /** Word before last name if more than 1 name */
  last?: string;
  /** Format each name */
  formatter?: (name: string) => string;
}

function isObjectWithName(n: Name | ObjectWithName): n is ObjectWithName {
  return Boolean(n && typeof n === 'object' && 'name' in n);
}

/**
 * A filter input function that filters out non-unique values
 */
export function onlyUniqueValues(
  value: Name,
  index: number,
  self: Array<Name>
): value is string {
  return self.indexOf(value) === index && Boolean(value);
}

/**
 * Format a list of names to readable english, e.g.
 *
 * @example
 * ```ts
 * formatNames(`['Alice', 'Bob', 'Eva']`) // `Alice, Bob and Eva`
 * ```
 */
export function formatNames(
  /** Names to format */
  names: (Name | ObjectWithName)[],
  options: Options = {}
): string | null {
  const {
    startWithUppercase = false,
    allStartWithUppercase = false,
    possessive = false,
    last = 'and',
    formatter = value => value,
  } = options;
  const uniqueNames = names
    .map(nameOrObj => {
      if (isObjectWithName(nameOrObj)) {
        return nameOrObj.name;
      } else {
        return nameOrObj;
      }
    })
    .filter(onlyUniqueValues);

  const lastIndex = uniqueNames.length - 1;
  let str;

  if (uniqueNames.length === 0) {
    return null;
  }

  if (startWithUppercase) {
    uniqueNames[0] =
      uniqueNames[0].charAt(0).toUpperCase() + uniqueNames[0].slice(1);
  }

  if (allStartWithUppercase) {
    uniqueNames.forEach((uniqueName, index) => {
      uniqueNames[index] =
        uniqueName.charAt(0).toUpperCase() + uniqueName.slice(1);
    });
  }

  if (possessive) {
    uniqueNames[lastIndex] = formatPossessive(uniqueNames[lastIndex]);
  }

  if (uniqueNames.length === 1) {
    str = formatter(uniqueNames[0]);
  } else {
    const includeOxfordComma = uniqueNames.length > 2;
    str = `${uniqueNames.splice(0, lastIndex).map(formatter).join(', ')}${
      includeOxfordComma ? ',' : ''
    } ${last} ${formatter(uniqueNames[uniqueNames.length - 1])}`;
  }

  return str;
}
