import { NodeNames } from '@farmersdog/constants';

import { Placeholder } from '../blueprint/Placeholder';
import { getNodeNameAndPosition } from '../blueprint/utils';
import { FormFieldsType, UseForm } from '../types';

interface UseErrorMessageProps {
  nodeName: keyof FormFieldsType;
  getValues: UseForm['getValues'];
  isPlural?: boolean;
  errorCode: string | undefined;
  errorMap: Record<string, string>;
}

interface parseErrorMessageProps {
  errorMessage: string;
  bodyCondition: string;
}

function parseErrorMessage({
  errorMessage,
  bodyCondition,
}: parseErrorMessageProps): string {
  const placeholder = new Placeholder(errorMessage).handleBodyCondition({
    bodyCondition,
    applyStartCasing: true,
  });

  return placeholder.modified;
}

/**
 * Gets a formatted error message with any relevant placeholder text dynamically replaced with form values
 */
export function useErrorMessage({
  nodeName,
  getValues,
  errorCode,
  errorMap,
}: UseErrorMessageProps): string {
  const errorMessage = errorCode ? errorMap[errorCode] : '';

  if (!errorMessage) {
    return '';
  }

  const { position } = getNodeNameAndPosition(nodeName);

  const bodyConditionField = `${NodeNames.BodyCondition}-${position}` as const;
  const formValues = getValues();
  const bodyCondition = formValues[bodyConditionField];

  return parseErrorMessage({
    errorMessage,
    bodyCondition: bodyCondition ?? 'just right',
  });
}
