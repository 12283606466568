import { Logger } from '@farmersdog/logger';

import {
  desktopLovedByHumans,
  mobileLovedByHumans,
} from '../../pages/Home/components/AssetPreloader/assets/loved-by-humans';
import {
  desktopLifestyleBowl,
  mobileLifestyleBowl,
} from '../../pages/Home/components/AssetPreloader/assets/lifestyle-bowl';
import {
  useSSRAnonymousExperiments,
  getHasSSRAnonymousExperiment,
} from '../../server/context/experimentsContext';
import type { MobileAndDesktopSources } from 'src/pages/Home/components/AssetPreloader/assets/shared';
import {
  desktopMeatAndVeggies,
  mobileMeatAndVeggies,
} from '../../pages/Home/components/AssetPreloader/assets/meat-and-veggies';

export enum HomepageHeroTreatments {
  LovedByHumans = 'loved_by_humans',
  LifestyleBowl = 'lifestyle_bowl',
  MeatAndVeggies = 'meat_and_veggies',
}

const assets: Record<string, MobileAndDesktopSources> = {
  [HomepageHeroTreatments.LovedByHumans]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroTreatments.LifestyleBowl]: {
    mobileSources: mobileLifestyleBowl,
    desktopSources: desktopLifestyleBowl,
  },
  [HomepageHeroTreatments.MeatAndVeggies]: {
    mobileSources: mobileMeatAndVeggies,
    desktopSources: desktopMeatAndVeggies,
  },
};

type UseHomepageHeroExperimentReturn = MobileAndDesktopSources & {
  treatment: HomepageHeroTreatments;
};

export function useHomepageHeroExperiment(): UseHomepageHeroExperimentReturn {
  const experiments = useSSRAnonymousExperiments();

  const hasHomepageHeroExperiment = getHasSSRAnonymousExperiment(
    experiments,
    'cvr_homepage_hero_image'
  );

  if (!hasHomepageHeroExperiment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error(
      'No SSR Anonymous Experiments found in useHomepageHeroExperiment',
      {
        experiments,
      }
    );

    return {
      ...assets[HomepageHeroTreatments.LovedByHumans],
      treatment: HomepageHeroTreatments.LovedByHumans,
    };
  }

  const homepageHeroExperiment = experiments.cvr_homepage_hero_image;
  if (!homepageHeroExperiment?.treatment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error('No treatment found for homepage hero experiment', {
      experiment: homepageHeroExperiment,
    });

    return {
      ...assets[HomepageHeroTreatments.LovedByHumans],
      treatment: HomepageHeroTreatments.LovedByHumans,
    };
  }

  const treatment = homepageHeroExperiment.treatment as string;

  if (treatment === HomepageHeroTreatments.LifestyleBowl) {
    return {
      ...assets[HomepageHeroTreatments.LifestyleBowl],
      treatment: HomepageHeroTreatments.LifestyleBowl,
    };
  }

  if (treatment === HomepageHeroTreatments.MeatAndVeggies) {
    return {
      ...assets[HomepageHeroTreatments.MeatAndVeggies],
      treatment: HomepageHeroTreatments.MeatAndVeggies,
    };
  }

  // Return assets for future experimental treatments here...
  // See https://app.shortcut.com/farmersdog/story/131374

  // Return the default treatment if we don't actively recognize the treatment
  return {
    ...assets[HomepageHeroTreatments.LovedByHumans],
    treatment: HomepageHeroTreatments.LovedByHumans,
  };
}
