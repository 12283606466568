import { useQuery } from '@apollo/client';
import { FetchNotificationPreferencesQuery } from './fetchNotificationPreferences.cgs.generated-types';
import fetchNotificationPreferencesQuery from './fetchNotificationPreferences.cgs.graphql';

export function useFetchNotificationPreferences() {
  return useQuery<FetchNotificationPreferencesQuery>(
    fetchNotificationPreferencesQuery,
    { context: { endpoint: 'cgs' } }
  );
}
