import { validateBirthday } from 'src/utils/birthday';

import { FOOD_TYPE_NO_BRAND } from 'src/constants/pet';

import { isFreshSignup } from '@farmersdog/lead-browser-storage';
import { getSubscriptionByType } from 'src/pages/Signup/DIY/utils/getSubscriptionByType';
import { SubscriptionType } from 'src/graphql/types';

const getMeProgress = (user: Reducer.User, numberOfPets: number) => {
  const { pets } = user;
  return {
    email: Boolean(user.email),
    phone: Boolean(user.phone),
    firstName: Boolean(user.firstName),
    petsCount: pets.length > 0 && numberOfPets > 0,
    petsNames:
      pets.length > 0 &&
      pets.slice(0, numberOfPets).every(p => Boolean(p.name)),
    zip: Boolean(user.shippingAddress && user.shippingAddress.zip),
    freshFoodConfidence: Number.isFinite(user.freshFoodConfidence),
  };
  /*
    The commented code below indicates what we believe are the bare minimum required fields (presently) to checkout.
    If we use an alternate blueprint in TOSA that does not contain ALL signup fields, this progress tracker will
    cause the user to be kicked back to TOSA once they hit /recipes or /checkout unless we implement something similar
    to below, which would conditionally only consider a subset of the fields to consider the progress "complete"

    const isMinimumSignupFlow = true; // value based on some conditional property, not statically set to "true"
    const minimumRequiredFields = {
      email: Boolean(user.email),
      petsCount: pets.length > 0 && numberOfPets > 0,
      petsNames:
        pets.length > 0 &&
        pets.slice(0, numberOfPets).every(p => Boolean(p.name)),
      zip: Boolean(user.shippingAddress && user.shippingAddress.zip),
    };

    if (isMinimumSignupFlow) {
      return minimumRequiredFields;
    } else {
      return {
        ...minimumRequiredFields,
        phone: Boolean(user.phone),
        firstName: Boolean(user.firstName),
        freshFoodConfidence: Number.isFinite(user.freshFoodConfidence),
      };
    }
  */
};

const getPetProgress = (
  pet: Reducer.Pet,
  options = { hasHealthIssues: false }
) => {
  const genderAndAge =
    !!pet.gender &&
    !!pet.birthday &&
    !!pet.birthdayAccuracy &&
    !validateBirthday(pet.birthday);

  const neuteredAndWeight = pet.neutered !== null && !!pet.weight;
  const personalityTypeAndBreeds =
    !!pet.nature && !!pet.breeds && pet.breeds.length > 0;
  const breedWeight = !!pet.breeds && pet.breeds.length > 0 && !!pet.weight;
  const bodyCondition = pet.condition !== null;
  const activityLevel = pet.activity !== null;
  const eatingStyle = pet.eatingStyle !== null;

  const healthIssues =
    !options.hasHealthIssues ||
    (options.hasHealthIssues && pet.issues && pet.issues.length > 0);

  const currentFood =
    (!!pet.currentFood &&
      !!pet.currentFood.type &&
      pet.currentFood.type === FOOD_TYPE_NO_BRAND) ||
    (!!pet.currentFood &&
      !!pet.currentFood.type &&
      pet.currentFood.type !== FOOD_TYPE_NO_BRAND &&
      !!pet.currentFood.brand);

  const treatUsageBucket = !!pet.treatsQuantity;
  const specialDiet =
    !pet.specialDiet || (pet.specialDiet && pet.prescriptionDiets.length > 0);

  return {
    genderAndAge,
    neuteredAndWeight,
    bodyCondition,
    personalityTypeAndBreeds,
    breedWeight,
    activityLevel,
    eatingStyle,
    healthIssues,
    currentFood,
    treatUsageBucket,
    specialDiet,
  };
  /*
    See `getMeProgress` for more details, but if we wanted to run a minimum required fields version of signup flow,
    the following fields could be used (again, would require some further validation to run in production)

    const genderAndAge =
      !!pet.gender &&
      !!pet.birthday &&
      !!pet.birthdayAccuracy &&
      !validateBirthday(pet.birthday);
    const neuteredAndWeight = pet.neutered !== null && !!pet.weight;
    const personalityTypeAndBreeds = !!pet.breeds && pet.breeds.length > 0;
    const breedWeight = !!pet.breeds && pet.breeds.length > 0 && !!pet.weight;
    const bodyConditionAndTargetWeight = pet.condition !== null;
    const activityLevel = pet.activity !== null;
    const eatingStyle = pet.eatingStyle !== null;
    const specialDiet =
      !pet.specialDiet || (pet.specialDiet && pet.prescriptionDiets.length > 0);

    return {
      genderAndAge,
      neuteredAndWeight,
      bodyConditionAndTargetWeight,
      personalityTypeAndBreeds,
      breedWeight,
      activityLevel,
      eatingStyle,
      specialDiet,
    };
  */
};

const getPlanProgress = (plans: Reducer.FoodPlan[]) =>
  plans.map(plan => ({
    recipeSelected:
      (plan && (plan.recipes ? plan.recipes.length > 0 : false)) ||
      plan.active === false,
  }));

const getDiyPlanProgress = (
  subscriptionProducts: Reducer.SubscriptionProduct[]
) => [
  {
    productSelected: Boolean(
      subscriptionProducts.length > 0 &&
        subscriptionProducts.every(
          subscriptionProduct =>
            subscriptionProduct.quantity > 0 &&
            Number.isFinite(subscriptionProduct.productId) &&
            subscriptionProduct.productId > 0
        )
    ),
  },
];

/**
 * Calculate the percentage of a step that has been completed
 *
 * @param innerSteps - an object containing each of the inner steps (me, pets, plans)
 *
 */
const getStepPercentage = (innerSteps = {}) => {
  const stepValues = Object.values(innerSteps);
  const completedInnerSteps = stepValues.filter(Boolean);
  const progress = completedInnerSteps.length / stepValues.length;

  // isFinite check to work when progress === 0 / 0
  return Number.isFinite(progress) ? progress : 1;
};

export type OverallProgress = ReturnType<typeof getOverallProgress>;

export const getOverallProgress = (progress: SignupProgress) => {
  // Keep track of a single me step (sum must be 1)
  const me = getStepPercentage(progress.me);
  const singlePets = progress.pets.map(getStepPercentage);
  const pets = getStepPercentage(
    singlePets.map((petProgress: number) => petProgress === 1)
  );
  const singlePlans = progress.plans.map(getStepPercentage);
  const plans = getStepPercentage(
    singlePlans.map(planProgress => planProgress === 1)
  );

  return { me, singlePets, pets, singlePlans, plans };
};

type SignupProgress = ReturnType<typeof calculateProgress>;

interface SignupState {
  pets: {
    withHealthIssues: boolean[];
    numberOfPets: number;
  };
  user: Reducer.User;
}

/**
 * Calculate the progress for the given user data for all the signup pages.
 *
 * @param signupState - The signup store state
 */
export default function calculateProgress(signupState: SignupState) {
  const { withHealthIssues } = signupState.pets;
  const { pets, subscription, subscriptions } = signupState.user;
  const diySubscription = getSubscriptionByType({
    subscriptions,
    type: SubscriptionType.Diy,
  });

  const foodPlans = (subscription && subscription.foodPlans) || [];
  const subscriptionProducts =
    (diySubscription && diySubscription.subscriptionProducts) || [];

  const progress = {
    me: getMeProgress(signupState.user, signupState.pets.numberOfPets),
    pets: pets.map((pet: Reducer.Pet, i: number) =>
      getPetProgress(pet, {
        hasHealthIssues: withHealthIssues[i],
      })
    ),
    plans: isFreshSignup()
      ? getPlanProgress(foodPlans)
      : getDiyPlanProgress(subscriptionProducts),
  };

  return progress;
}
