import { ApolloError, useMutation } from '@apollo/client';
import loginCustomer from '../../graphql/mutations/loginCustomer.auth.graphql';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';
import {
  LoginCustomerMutation,
  LoginCustomerMutationVariables,
} from 'src/graphql/auth.types';

interface UseAuthGraphqlLoginSubmitArgs {
  onCompleted: (data: LoginCustomerMutation) => void;
  onError: (error: ApolloError) => void;
}

export function useAuthGraphqlLoginSubmit({
  onCompleted,
  onError,
}: UseAuthGraphqlLoginSubmitArgs) {
  const [login] = useMutation<
    LoginCustomerMutation,
    LoginCustomerMutationVariables
  >(loginCustomer, {
    context: {
      endpoint: GraphQLEndpoints.AUTH,
    },
    onError,
    onCompleted,
  });
  const callLogin = (
    email: string,
    password: string,
    challengeToken: string | null = null
  ) => login({ variables: { input: { email, password, challengeToken } } });
  return callLogin;
}
