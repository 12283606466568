import { SIGNUP_FETCH_QUOTE } from 'src/constants/actionTypes';

const initialState = null;

export default function signupQuoteReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FETCH_QUOTE:
      // Sometimes quotes can be fetched in rapid succession and received out
      // of order; we compare state.fetchTimestamp against
      // action.meta.fetchTimestamp.current to see if the incoming quote is
      // more recently-requested than the one in state.
      return !state?.fetchTimestamp ||
        action.meta.fetchTimestamp > state.fetchTimestamp
        ? {
            ...action.payload.data,
            fetchTimestamp: action.meta.fetchTimestamp,
          }
        : state;
    case `${SIGNUP_FETCH_QUOTE}_FAILURE`:
      return initialState;
    default:
      return state;
  }
}

export function selectQuote(state) {
  return state.signup.quote;
}

export const selectPetQuote = (state, petId) => {
  const quote = selectQuote(state);
  if (!quote || !quote.pets) {
    return undefined;
  }
  return quote.pets.find(p => p.id === petId);
};
