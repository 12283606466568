/**
 * The body condition's id for which is not required to ask the pet's target
 * weight.
 */
export const CONDITION_NO_TARGET_WEIGHT = 3;

/**
 * The food type for which no brand is required.
 */
export const FOOD_TYPE_NO_BRAND = 'homemade';

/**
 * The prescription diets that could work with our food.
 */
export const ALLOWED_PRESCRIPTION_DIETS = [
  'Low Fat',
  'Joint Support',
  'Weight Management',
  'Weight Loss',
  'GI diet',
  'Skin Support',
];

export const HEALTH_ISSUES_WITH_DISCLAIMER = [
  {
    name: 'History of Pancreatitis',
    disclaimer:
      'Dogs with a History of Pancreatitis often require a specific recipe which we currently don’t formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.',
  },
  {
    name: 'Hyperlipidemia / Hypertriglyceridemia',
    disclaimer:
      'Dogs with Hyperlipidemia / Hypertriglyceridemia often require a specific recipe which we currently don’t formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.',
  },
];

export const BOTH_HEALTH_ISSUES_WITH_DISCLAIMER_SELECTED =
  'Dogs with a History of Pancreatitis and/or Hyperlipidemia / Hypertriglyceridemia often require a specific recipe which we currently don’t formulate. If the issues are chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.';

/**
 * Minimum and maximum age (in weeks) for subscribing a pet.
 */
export const MIN_AGE_WEEKS = 8;
export const MAX_AGE_WEEKS = 30 * 52;

/**
 * Minimum and maximum weight (in grams) for subscribing a pet.
 */
export const PET_MAX_WEIGHT = 158757; // 350 lbs
export const PET_MIN_WEIGHT = 44.0564; // 0.99 lbs

/** Maximum number of pets allowed by our system */
export const MAX_NUMBER_OF_PETS = 8;
