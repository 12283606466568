import { Text } from '@farmersdog/corgi-x';
import styles from './PriceBreakdown.module.css';

interface PriceBreakdownGreyedItemProps {
  dataTestId: string;
  value: string;
}

export const PriceBreakdownGreyedItem = ({
  dataTestId,
  value,
}: PriceBreakdownGreyedItemProps) => {
  return (
    <>
      <Text
        variant="heading-16"
        className={styles.orderBreakdownFieldGreyed}
        data-testid={dataTestId}
      >
        {value}
      </Text>
      <span />
    </>
  );
};
