import { UseAddDogFormFields } from '../hooks';
import eventNames from './eventNames';
import { track } from '@farmersdog/analytics';

interface TrackOnSubmitArgs {
  formValue: UseAddDogFormFields;
  isRecommendedPlan: boolean;
}

export function trackOnSubmit(args: TrackOnSubmitArgs) {
  track(eventNames.onFormSubmit, {
    formValue: args.formValue,
    isRecommendedPlan: args.isRecommendedPlan,
  });
}
