import { Maybe } from 'src/graphql/types';

interface Options {
  /** Whether to round to whole dollars or not  */
  round?: boolean;
}

/**
 * Format a currency value
 */
export function formatCurrency(
  value: number | undefined,
  { round }: Options = { round: false }
): Maybe<string> {
  if (!value && value !== 0) {
    return null;
  }
  const absValue = Math.abs(value) / 100;
  const absFormattedValue = round ? Math.round(absValue) : absValue.toFixed(2);
  return `$${value < 0 ? '–' : ''}${absFormattedValue}`;
}
