import { useId } from 'react';

import { Picture, Button, Text, Card } from '@farmersdog/corgi-x';

import { PATH_TREAT_PRODUCTS } from '@farmersdog/constants/paths';
import dogSources from './assets/treatsDog';

import classNames from 'classnames';
import styles from './EmptyTreats.module.css';

export function EmptyTreats({ className }: { className?: string }) {
  const id = useId();
  return (
    <Card className={classNames(className, styles.container)}>
      <Picture alt="treats dog" sources={dogSources} className={styles.image} />
      <section aria-labelledby={id} className={styles.content}>
        <Text variant="heading-22" color="kale-3" id={id}>
          Add our{' '}
          <Text variant="heading-22" bold>
            healthier, tastier treats
          </Text>{' '}
          to your plan!
        </Text>
        <div>
          <Button type="link" to={PATH_TREAT_PRODUCTS}>
            See Treats
          </Button>
        </div>
      </section>
    </Card>
  );
}
