import { Picture } from '@farmersdog/corgi-x';

import DogDiggingImage from 'src/assets/images/loadingDog/dog-digging.gif';

import styles from './LoadingIndicator.module.scss';

export function LoadingIndicator() {
  return (
    <div className={styles.loading}>
      <Picture
        sources={[DogDiggingImage]}
        alt="Dog digging"
        className={styles.picture}
      />
    </div>
  );
}
