import { Fragment, ReactNode } from 'react';

import { LayoutType, Node } from '../../blueprint/types';
import { useSignupSpacingExperiment } from '../../hooks';
import { TOSAComponentInput } from '../../types';
import { getExperimentalLayoutElementKey } from '../../utils';

import { getLayoutClass } from './getLayoutClass';
import { getLayoutElement } from './getLayoutElement';

export interface BranchNodeAnimationProps extends TOSAComponentInput<Node> {
  children: ReactNode;
}

export function BranchNodeAnimation({
  node,
  children,
  useFeature,
}: BranchNodeAnimationProps) {
  const HTMLElement = getLayoutElement(node.layout);
  const { isTreatmentOn: isSignupSpacingExperimentOn } =
    useSignupSpacingExperiment({ useFeature });

  if (HTMLElement === Fragment) {
    return <HTMLElement>{children}</HTMLElement>;
  }

  const layoutElementKey = isSignupSpacingExperimentOn
    ? getExperimentalLayoutElementKey(node.layout ?? LayoutType.none)
    : node.layout;

  return (
    <HTMLElement
      className={getLayoutClass({
        layout: layoutElementKey,
      })}
    >
      {children}
    </HTMLElement>
  );
}
