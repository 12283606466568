import { Card, Link, Mask, Text } from '@farmersdog/corgi-x';

import FiveStars from '../FiveStars';
import styles from './ReviewCard.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import classNames from 'classnames';

/**
 * Card containing google review content
 */
function ReviewCard({
  initials,
  name,
  comment,
  href,
}: {
  initials?: string;
  name?: string;
  href: string;
  comment: string;
}) {
  const { isAccessibilityThemeOn, accessibilityThemeStyles } =
    useAccessibilityThemeContext();
  return (
    <Card className={classNames(styles.card, accessibilityThemeStyles.card)}>
      <div className={styles.container}>
        <Mask shape="circle" size="md" className={styles.initialsMask}>
          <Text
            variant="heading-22"
            color="white"
            as="div"
            className={styles.initials}
            bold
          >
            {initials}
          </Text>
        </Mask>
        <div className={styles.content}>
          <Text
            variant="heading-22"
            as="h3"
            color="charcoal-3"
            className={styles.name}
            bold
          >
            {name}
          </Text>
          <Text
            as="blockquote"
            variant="article-20"
            color="charcoal-3"
            className={classNames(styles.comment, {
              [styles.accessiblilityThemeComment]: isAccessibilityThemeOn,
            })}
          >
            “{comment}”
          </Text>
        </div>
        <Link
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <Text
            variant="heading-16"
            className={
              isAccessibilityThemeOn
                ? styles.accessibilityThemeReadMore
                : undefined
            }
          >
            Read more
          </Text>
        </Link>
        <FiveStars color="carrot-2" />
      </div>
    </Card>
  );
}

export default ReviewCard;
