/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Email: any;
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  Phone: any;
  /** A string or object representing a payment source */
  StripeSource: any;
};

/** Used to describe the amount of activity for a pet. */
export type ActivityLevel = {
  __typename?: 'ActivityLevel';
  /** The activity level ID */
  id: Scalars['Int'];
  /** The activity level name */
  name: Scalars['String'];
  /** Further description of the activity level */
  description: Scalars['String'];
};

/** A subscription add-on */
export type AddOn = {
  __typename?: 'AddOn';
  /** The add-on's ID */
  id: Scalars['Int'];
  /** ID of the subscription */
  subscriptionId: Scalars['Int'];
  /** ID of the product */
  productId: Scalars['Int'];
  /** The product */
  product: Product;
  /** The quantity of the product */
  quantity: Scalars['Int'];
  /** Is this item a one-off (AKA not recurring via the subscription)? */
  isOneOff: Scalars['Boolean'];
};

export type AddOnInput = {
  /** Product ID */
  productId: Scalars['Int'];
  /** Product quantity */
  quantity: Scalars['Int'];
  /** Is this a one-time purchase (as opposed to a recurring subscription)? */
  isOneOff?: Maybe<Scalars['Boolean']>;
};

export type AddOptOutUserInput = {
  /** Full name of user opting out of tracking */
  name: Scalars['String'];
  /** Email address of user opting out of tracking */
  email: Scalars['Email'];
  /** Phone number of user opting out of tracking */
  phone: Scalars['Phone'];
  /** Segment ID of users opting out of tracking */
  anonymousId: Scalars['String'];
};

/** The result of adding a user to the opt out list */
export type AddOptOutUserResultType = {
  __typename?: 'AddOptOutUserResultType';
  /** Whether or not the request to add the user to the opt out list was successful */
  success: Scalars['Boolean'];
};

export type AddressInput = {
  /** The ID of the address */
  id?: Maybe<Scalars['Int']>;
  /** The street address */
  addressLine1: Scalars['String'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Scalars['String'];
  /** The ID of the state */
  stateId: Scalars['Int'];
  /** The ID of the country */
  countryId: Scalars['Int'];
  /** The zipcode of the address */
  zip: Scalars['String'];
  /** Is the address validated */
  validated?: Maybe<Scalars['Boolean']>;
};

export enum AddressType {
  /** Address is a shipping address */
  Shipping = 'shipping',
  /** Address is a billing address */
  Billing = 'billing',
}

/** A body condition for a pet. */
export type BodyCondition = {
  __typename?: 'BodyCondition';
  /** The body condition's ID */
  id: Scalars['Int'];
  /** The body condition's name */
  name: Scalars['String'];
  /** The body condition's description */
  description: Scalars['String'];
};

/** A breed for a pet. */
export type Breed = {
  __typename?: 'Breed';
  /** The breed ID */
  id: Scalars['Int'];
  /** The breed's name */
  name: Scalars['String'];
  /** A URL to information about this breed (often to a kennel club website) */
  url: Maybe<Scalars['String']>;
  /** The unit for the breed's weight */
  weightUnit: Maybe<Scalars['String']>;
  /** The maximum weight for this breed in the unit specified by 'weightUnit' */
  weightMax: Maybe<Scalars['Int']>;
  /** The minimum weight for this breed in the unit specified by 'weightUnit' */
  weightMin: Maybe<Scalars['Int']>;
  /** The size definition of a particular breed */
  size: BreedSize;
};

export enum BreedSize {
  XSmall = 'x_small',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'x_large',
}

export type BufferOption = {
  __typename?: 'BufferOption';
  /** Number of days of food this buffer would provide */
  days: Scalars['Int'];
  /** Next rescheduled order date if customer accepted this buffer */
  rescheduleDate: Scalars['Date'];
  /** Whether this reschedule date will result in two billings in one week */
  willDoubleBill: Scalars['Boolean'];
};

/** Range of buffer days for this subscription */
export type BufferRange = {
  __typename?: 'BufferRange';
  /** Minimum days of buffer for this subscription */
  minDays: Scalars['Int'];
  /** Maximum days of buffer for this subscription */
  maxDays: Scalars['Int'];
  /** Recommended days of buffer for this subscription */
  recommendedDays: Scalars['Int'];
};

/** The user's current and new passwords. */
export type ChangePasswordInput = {
  /** The user's current password */
  currentPassword: Scalars['String'];
  /** The user's new password */
  newPassword: Scalars['String'];
};

export type CheckoutInput = {
  /** The email address of the User */
  email: Scalars['Email'];
  /** The password of the User */
  password?: Maybe<Scalars['String']>;
  /** The phone number of the User */
  phone: Scalars['Phone'];
  /** The first name of the User */
  firstName: Scalars['String'];
  /** The last name of the User */
  lastName?: Maybe<Scalars['String']>;
  /** The full name of the User */
  fullName: Scalars['String'];
  /** The shipping address of the User */
  shippingAddress: AddressInput;
  /** The billing address of the User */
  billingAddress: AddressInput;
  /** User Stripe token or full payload */
  stripeToken: Scalars['StripeSource'];
  /** Subscription details about the User */
  subscription: PendingSubscriptionInputType;
  /** Acquisition channel related metadata needed at checkout */
  attributionMetadata?: Maybe<Scalars['JSONObject']>;
};

/** A customer's preferences about whether and how to receive communications from TFD (e.g. email or SMS) on different subjects (order reminders, order updates, etc.) */
export type CommunicationPreferenceType = {
  __typename?: 'CommunicationPreferenceType';
  /** Should customer receive order updates by email? */
  orderUpdatesEmail: Scalars['Boolean'];
  /** Should customer receive order updates by SMS? */
  orderUpdatesSms: Scalars['Boolean'];
  /** Should customer receive order reminders by email? */
  orderRemindersEmail: Scalars['Boolean'];
  /** Should customer receive order reminders by SMS? */
  orderRemindersSms: Scalars['Boolean'];
};

export type CommunicationPreferencesInput = {
  /** Should customer receive order updates by SMS? */
  orderUpdatesSms: Scalars['Boolean'];
  /** Should customer receive order reminders by SMS? */
  orderRemindersSms: Scalars['Boolean'];
};

/** Application configuration information for client use */
export type Configuration = {
  __typename?: 'Configuration';
  /** Maximum number of days the next order can be delayed */
  maxDelayDurationInDays: Scalars['Int'];
};

/** A country in the world */
export type Country = {
  __typename?: 'Country';
  /** The country's The Farmer's Dog ID */
  id: Scalars['Int'];
  /** The country's display name */
  name: Scalars['String'];
  /** The country's ISO-3166 name */
  isoName: Maybe<Scalars['String']>;
  /** The country's ISO-3166 alpha-2 code */
  iso: Maybe<Scalars['String']>;
  /** The country's ISO-3166 alpha-3 code */
  iso3: Maybe<Scalars['String']>;
  /** The country's ISO-3166 numeric code */
  numcode: Maybe<Scalars['Int']>;
  /** Does this country have states? */
  statesRequired: Maybe<Scalars['Boolean']>;
  /** The list of states within this country in alphabetical order */
  states: Maybe<Array<State>>;
};

export type CreateMyPetInput = {
  /** The pet's name */
  name: Scalars['String'];
  /** The activity level of the pet */
  activity?: Maybe<Scalars['Int']>;
  /** The pet's birthday */
  birthday?: Maybe<Scalars['Date']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: Maybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: Maybe<Array<Scalars['Int']>>;
  /** The body condition of the pet */
  condition?: Maybe<Scalars['Int']>;
  /** The pet's current food */
  currentFood?: Maybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: Maybe<Scalars['Int']>;
  /** The pet's gender */
  gender?: Maybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: Maybe<Array<Scalars['String']>>;
  /** The nature of the pet */
  nature?: Maybe<Scalars['Int']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: Maybe<Scalars['Boolean']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: Maybe<Scalars['String']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: Maybe<Array<Scalars['Int']>>;
  /** A list of restrictions related to the pet */
  restrictionNames?: Maybe<Array<Scalars['String']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: Maybe<Scalars['Int']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: Maybe<Scalars['Int']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: Maybe<Scalars['Int']>;
  /** The pet's current weight */
  weight?: Maybe<Scalars['Int']>;
};

export type CreatePetInput = {
  /** The pet's name */
  name: Scalars['String'];
  /** The activity level of the pet */
  activity?: Maybe<Scalars['Int']>;
  /** The pet's birthday */
  birthday?: Maybe<Scalars['Date']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: Maybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: Maybe<Array<Scalars['Int']>>;
  /** The body condition of the pet */
  condition?: Maybe<Scalars['Int']>;
  /** The pet's current food */
  currentFood?: Maybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: Maybe<Scalars['Int']>;
  /** The pet's gender */
  gender?: Maybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: Maybe<Array<Scalars['String']>>;
  /** The nature of the pet */
  nature?: Maybe<Scalars['Int']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: Maybe<Scalars['Boolean']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: Maybe<Scalars['String']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: Maybe<Array<Scalars['Int']>>;
  /** A list of restrictions related to the pet */
  restrictionNames?: Maybe<Array<Scalars['String']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: Maybe<Scalars['Int']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: Maybe<Scalars['Int']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: Maybe<Scalars['Int']>;
  /** The pet's current weight */
  weight?: Maybe<Scalars['Int']>;
  /** When true remove pet from customer account and all communications.  Typically this is done when the pet has passed. */
  noContact?: Maybe<Scalars['Boolean']>;
  /** Does the pet require a special diet */
  specialDiet?: Maybe<Scalars['Boolean']>;
};

/** Input fields for a support ticket submitted from the front-end */
export type CreateSupportTicketInput = {
  /** User's description of the support problem */
  body: Scalars['String'];
  /** Support ticket category */
  issue?: Maybe<SupportTicketIssueType>;
  /** Tags on customer support issue */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Custom key: value data attached to support ticket */
  additionalData?: Maybe<Array<SupportTicketAdditionalDataInput>>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  /** The ID of the credit card */
  id: Scalars['String'];
  /** The name associated with the credit card */
  nameOnCard: Maybe<Scalars['String']>;
  /** The last four digits of the credit card */
  last4Digits: Scalars['String'];
  /** The credit card's expiration month */
  expirationMonth: Scalars['Int'];
  /** The credit card's expiration month */
  expirationYear: Scalars['Int'];
};

/** A view of a customer's available credit. */
export type CreditView = {
  __typename?: 'CreditView';
  /** The amount of available credit in cents */
  amount: Scalars['Int'];
  /** The expiration of the available credit */
  expirationDate: Maybe<Scalars['Date']>;
  /** Whether the customer has ever had any credits */
  hasCreditHistory: Scalars['Boolean'];
};

/** The pet's current food */
export type CurrentFood = {
  __typename?: 'CurrentFood';
  /** The id of the pet's current food */
  id: Scalars['Int'];
  /** The brand of the current food */
  brand: Maybe<Scalars['String']>;
  /** The specific product for the current food */
  product: Maybe<Scalars['String']>;
  /** The type of current food */
  type: Maybe<CurrentFoodType>;
  /** The measurement units of the amount of current food */
  unit: Maybe<CurrentFoodUnit>;
  /** Amount of the current food being consumed on a daily basis */
  amount: Maybe<Scalars['String']>;
};

/** The pet's current food */
export type CurrentFoodInput = {
  /** The id of the pet's current food */
  id?: Maybe<Scalars['Int']>;
  /** The brand of the current food */
  brand?: Maybe<Scalars['String']>;
  /** The specific product for the current food */
  product?: Maybe<Scalars['String']>;
  /** The type of current food */
  type?: Maybe<CurrentFoodType>;
  /** The measurement units of the amount of current food */
  unit?: Maybe<CurrentFoodUnit>;
  /** Amount of the current food being consumed on a daily basis */
  amount?: Maybe<Scalars['String']>;
};

export enum CurrentFoodType {
  /** Dry food */
  Dry = 'dry',
  /** Wet food */
  Wet = 'wet',
  /** Raw food */
  Raw = 'raw',
  /** Dehydrated food */
  Dehydrated = 'dehydrated',
  /** Fresh food */
  Fresh = 'fresh',
  /** Homemade food */
  Homemade = 'homemade',
}

export enum CurrentFoodUnit {
  /** Food amount measurement in cups */
  Cup = 'cup',
  /** Food amount measurement in grams */
  Gram = 'gram',
}

export type DiyRecommendationInput = {
  _?: Maybe<Scalars['Boolean']>;
};

/** Address district */
export type District = {
  __typename?: 'District';
  /** Id of the State */
  stateId: Scalars['Int'];
  /** Id of the Country */
  countryId: Scalars['Int'];
  /** Postal code of the District */
  postalCode: Scalars['String'];
  /** City of the District */
  city: Scalars['String'];
  /** State of the District */
  state: State;
  /** Country of the District */
  country: Country;
};

export type DiyQuotePet = {
  __typename?: 'DiyQuotePet';
  id: Scalars['Int'];
  suggestedCalories: Maybe<Scalars['Int']>;
};

export type DiyQuotePrice = {
  __typename?: 'DiyQuotePrice';
  regular: DiyQuotePriceInfo;
  trial: Maybe<DiyQuotePriceInfo>;
};

export type DiyQuotePriceInfo = {
  __typename?: 'DiyQuotePriceInfo';
  netAmount: Scalars['Int'];
  baseAmount: Scalars['Int'];
  discountAmount: Scalars['Int'];
  taxAmount: Scalars['Int'];
  itemsTotalAmount: Scalars['Int'];
  shippingAmount: Scalars['Int'];
  totalAmount: Scalars['Int'];
  weeklyAmount: Scalars['Int'];
};

export type DiyQuoteProductQuantity = {
  __typename?: 'DiyQuoteProductQuantity';
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type DiyQuoteRecommendation = {
  __typename?: 'DiyQuoteRecommendation';
  cadence: Scalars['Int'];
  products: Array<Maybe<DiyQuoteProductQuantity>>;
  dailyCalories: Scalars['Int'];
};

export type DiyQuoteShipment = {
  __typename?: 'DiyQuoteShipment';
  regular: DiyQuoteShipmentInfo;
  /** @deprecated This will always be `null` in practice, but we are keeping this as a queryable field for compatibility with fresh quotes. */
  trial: Maybe<DiyQuoteShipmentInfo>;
};

export type DiyQuoteShipmentInfo = {
  __typename?: 'DiyQuoteShipmentInfo';
  pricePerWeek: Scalars['Int'];
  defaultFrequency: Scalars['Int'];
  shipmentFrequencyInDays: Maybe<Array<Scalars['Int']>>;
  frequency: Scalars['Int'];
};

export type DiyQuoteSubscription = {
  __typename?: 'DiyQuoteSubscription';
  recommended: Maybe<DiyQuoteRecommendation>;
  accepted: Maybe<DiyQuoteRecommendation>;
};

/** DIY subscription quote data */
export type DiySubscriptionQuote = {
  __typename?: 'DiySubscriptionQuote';
  subscription: DiyQuoteSubscription;
  pets: Array<Maybe<DiyQuotePet>>;
  price: DiyQuotePrice;
  shipment: DiyQuoteShipment;
};

/** Describes a pet's eating style */
export type EatingStyle = {
  __typename?: 'EatingStyle';
  /** The eating style ID */
  id: Scalars['Int'];
  /** The eating style name */
  name: Scalars['String'];
};

/** A pet food allergy. */
export type FoodAllergy = {
  __typename?: 'FoodAllergy';
  /** The food allergy's ID */
  id: Scalars['Int'];
  /** A food allergy's name */
  name: Scalars['String'];
};

/** A pet food brand. */
export type FoodBrand = {
  __typename?: 'FoodBrand';
  /** The brand's ID */
  id: Scalars['Int'];
  /** The brand's name */
  name: Scalars['String'];
};

export type FoodPlanMutationsInput = {
  /** A list of food plans to be set on the subscription */
  update?: Maybe<Array<UpdateMyFoodPlanInput>>;
};

/** Describes a pet food type */
export type FoodType = {
  __typename?: 'FoodType';
  /** The food type ID */
  id: Scalars['String'];
  /** The food type name */
  name: Scalars['String'];
};

export enum FreshFoodConfidenceEnum {
  AlreadyBelieve = 'ALREADY_BELIEVE',
  AmNotSureYet = 'AM_NOT_SURE_YET',
  DontBelieve = 'DONT_BELIEVE',
}

export type FreshRecommendationInput = {
  _?: Maybe<Scalars['Boolean']>;
};

/** A pet health issue. */
export type HealthIssue = {
  __typename?: 'HealthIssue';
  /** The health issue's ID */
  id: Scalars['Int'];
  /** A health issue's name */
  name: Scalars['String'];
  /** The type of health issue */
  type: HealthIssueType;
  /** Is the issue enabled? */
  enabled: Scalars['Boolean'];
};

/** The type of health issue */
export enum HealthIssueType {
  /** System default health issue */
  Default = 'default',
  /** User created health issue */
  User = 'user',
}

/** A line item on an order */
export type LineItem = {
  __typename?: 'LineItem';
  /** UUID for the line item */
  uuid: Scalars['String'];
  /** ID of the product */
  productId: Scalars['Int'];
  /** The product for this line item */
  product: Product;
  /** Quantity of this product included in the order */
  quantity: Scalars['Int'];
  /** ID of the order shipping box this line item is in */
  orderShippingBoxId: Scalars['Int'];
  /** ID of the order this line item belongs to */
  orderId: Scalars['Int'];
  /** ID of the subscription the order belongs to */
  subscriptionId: Scalars['Int'];
  /** Metadata for this line item */
  metadata: LineItemMetadata;
};

/** Pet breed line item metadata */
export type LineItemBreedMetadata = {
  __typename?: 'LineItemBreedMetadata';
  /** The breed ID */
  id: Scalars['Int'];
  /** The breed's name */
  name: Scalars['String'];
  /** A URL to information about this breed (often to a kennel club website) */
  url: Maybe<Scalars['String']>;
  /** The unit for the breed's weight */
  weightUnit: Maybe<Scalars['String']>;
  /** The maximum weight for this breed in the unit specified by 'weightUnit' */
  weightMax: Maybe<Scalars['Int']>;
  /** The minimum weight for this breed in the unit specified by 'weightUnit' */
  weightMin: Maybe<Scalars['Int']>;
};

/** Pet food allergy line item metadata */
export type LineItemFoodAllergyMetadata = {
  __typename?: 'LineItemFoodAllergyMetadata';
  /** The food allergy's ID */
  id: Scalars['Int'];
  /** A food allergy's name */
  name: Scalars['String'];
};

/** Food plan metadata for a line item */
export type LineItemFoodPlanMetadata = {
  __typename?: 'LineItemFoodPlanMetadata';
  /** The plan's ID */
  id: Scalars['Int'];
  /** ID of the pet the plan is tied to */
  petId: Maybe<Scalars['Int']>;
  /** ID of the subscription this plan belongs to */
  subscriptionId: Scalars['Int'];
  /** Is this plan active? */
  active: Scalars['Boolean'];
  /** The plan's type */
  type: PlanType;
  /** The plan's calories */
  calories: Maybe<Scalars['Float']>;
  /** How much of a full pack should the pet be feed each day? */
  portion: Maybe<Scalars['Float']>;
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Maybe<Scalars['Float']>;
  /** The number of calories a pet gets from The Farmer's Dog food each day */
  dailyCalories: Maybe<Scalars['Int']>;
  /** How many packs of food a customer has at their home for this plan */
  stock: Scalars['Float'];
  /** How many days a full pack lasts for this plan */
  lastingDays: Scalars['Float'];
  /** Is this a transition plan? */
  transition: Scalars['Boolean'];
  /** The plan's products */
  planProducts: Maybe<Array<LineItemPlanProductMetadata>>;
};

/** Pet health issue line item metadata */
export type LineItemHealthIssueMetadata = {
  __typename?: 'LineItemHealthIssueMetadata';
  /** The health issue's ID */
  id: Scalars['Int'];
  /** A health issue's name */
  name: Maybe<Scalars['String']>;
};

/** Metadata related to the order */
export type LineItemMetadata = {
  __typename?: 'LineItemMetadata';
  /** The product metadata for this line item */
  product: LineItemProductMetadata;
  /** The pet metadata for this line item if it's a food line item for fresh subscriptions */
  pet: Maybe<LineItemPetMetadata>;
  /** The food plan metadata for this line item if it's a food line item */
  plan: Maybe<LineItemFoodPlanMetadata>;
  /** The add-on metadata for this line item if it's a subscription add-on product line item */
  subscriptionAddOn: Maybe<LineItemSubscriptionAddOnMetadata>;
  /** The add-on metadata for this line item if it's a one-off add-on product line item */
  oneOffAddOn: Maybe<LineItemOneOffAddOnMetadata>;
  /** Food plan statistics describing the plan across the entire order */
  stats: Maybe<LineItemStatsMetadata>;
};

/** One-off add-on metadata for a line item */
export type LineItemOneOffAddOnMetadata = {
  __typename?: 'LineItemOneOffAddOnMetadata';
  /** ID of the product */
  productId: Scalars['Int'];
  /** The quantity of the product */
  quantity: Scalars['Int'];
};

/** Pet metadata for a line item */
export type LineItemPetMetadata = {
  __typename?: 'LineItemPetMetadata';
  /** The pet's ID */
  id: Scalars['Int'];
  /** The user's ID */
  userId: Scalars['Int'];
  /** The pet's name */
  name: Scalars['String'];
  /** The pet's weight in grams */
  weight: Maybe<Scalars['Int']>;
  /** The pet's target weight in grams */
  targetWeight: Maybe<Scalars['Int']>;
  /** The pet's gender */
  gender: Maybe<PetGender>;
  /** The pet's estimated birthdate */
  birthday: Maybe<Scalars['Date']>;
  /** How accurate is the pet's birthday we have recorded? */
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  activity: Maybe<Scalars['Int']>;
  condition: Maybe<Scalars['Int']>;
  treatsQuantity: Maybe<Scalars['Int']>;
  eatingStyle: Maybe<Scalars['Int']>;
  nature: Maybe<Scalars['Int']>;
  /** Is the pet neutered? */
  neutered: Maybe<Scalars['Boolean']>;
  /** The pet's suggested daily calories based on their weight and other factors */
  suggestedCalories: Maybe<Scalars['Float']>;
  /** Other prescription diet */
  otherPrescriptionDiet: Maybe<LineItemPetPrescriptionDietMetadata>;
  /** The pet's suggested daily calories based on factors excluding their activity level */
  calculatedCalories: Maybe<Scalars['Float']>;
  /** The pet's required calores as prescribed by a vet */
  requiredCalories: Maybe<Scalars['Float']>;
  /** Is the pet on a special diet? */
  specialDiet: Maybe<Scalars['Boolean']>;
  /** Can we contact the customer about this pet? */
  noContact: Scalars['Boolean'];
  /**
   * Does the pet have an active food plan?
   * @deprecated Instead use pet.plan.active
   */
  subscribed: Maybe<Scalars['Boolean']>;
  /** Pet creation date */
  createdAt: Scalars['DateTime'];
  /** Pet health issue metadata */
  issues: Maybe<Array<LineItemHealthIssueMetadata>>;
  /** Pet food allergy metadata */
  restrictions: Maybe<Array<LineItemFoodAllergyMetadata>>;
  /** Pet breed metadata */
  breeds: Maybe<Array<LineItemBreedMetadata>>;
};

/** Prescription diet metadata */
export type LineItemPetPrescriptionDietMetadata = {
  __typename?: 'LineItemPetPrescriptionDietMetadata';
  /** The prescription diet's ID */
  id: Scalars['Int'];
  /** The prescription diet's name */
  name: Scalars['String'];
};

/** Food plan product metadata for a line item */
export type LineItemPlanProductMetadata = {
  __typename?: 'LineItemPlanProductMetadata';
  /** The plan product's ID */
  id: Scalars['Int'];
  /** ID of the product */
  productId: Scalars['Int'];
  /** Name of the product */
  productName: Scalars['String'];
  /** Product SKU */
  productSKU: Scalars['String'];
  /** ID of the recipe */
  recipeId: Scalars['Int'];
  /** Name of the recipe */
  recipeName: Scalars['String'];
  /** What portion of the plan's products is this product? */
  ratio: Scalars['Float'];
};

/** Product metadata for a line item */
export type LineItemProductMetadata = {
  __typename?: 'LineItemProductMetadata';
  /** The product's ID */
  id: Scalars['Int'];
  /** The product's name */
  name: Scalars['String'];
  /** The product's unique SKU */
  sku: Scalars['String'];
  /** What type of product this is */
  type: ProductType;
  /** Is this product enabled? */
  status: ProductStatus;
  /** If this product is 'food', the recipe ID for it */
  recipeId: Maybe<Scalars['Int']>;
  /** If this product is 'food', the recipe for it */
  recipe: Maybe<LineItemRecipeMetadata>;
  /** If this product is 'food', the number of calories it includes */
  calories: Maybe<Scalars['Float']>;
  /** The cost of the product in cents */
  price: Scalars['Int'];
  /** The weight of the product in grams */
  weight: Scalars['Float'];
  /** The height of the product in centimeters */
  height: Scalars['Float'];
  /** The length of the product in centimeters */
  length: Scalars['Float'];
  /** The width of the product in centimeters */
  width: Scalars['Float'];
  /** The volume of the product in cubic centimeters */
  volume: Scalars['Float'];
  /** Products pack code */
  packCode: Maybe<Scalars['String']>;
};

/** Recipe metadata for a line item */
export type LineItemRecipeMetadata = {
  __typename?: 'LineItemRecipeMetadata';
  /** The recipe's ID */
  id: Scalars['Int'];
  /** The recipe's name */
  name: Scalars['String'];
  /** The display name for the recipe */
  displayName: Maybe<Scalars['String']>;
  /** A summary of the recipe's main ingredients */
  mainIngredients: Maybe<Scalars['String']>;
  /** A summary of the recipe's supplements */
  supplements: Maybe<Scalars['String']>;
  /** A summary of the recipe's full ingredients */
  fullIngredients: Maybe<Scalars['String']>;
  /**
   * A summary of the recipe's ingredients
   * @deprecated Replaced by fullIngredients
   */
  ingredients: Maybe<Scalars['String']>;
  /** The number of carbs in the recipe */
  carbs: Scalars['Int'];
  /** The grams of protein in the recipe */
  protein: Scalars['Int'];
  /** The grams of fat in the recipe */
  fat: Scalars['Int'];
  /** The number of cents a gram of the recipe costs */
  pricePerGram: Scalars['Float'];
  /** The number of calories per gram in the recipe */
  kcalPerGram: Scalars['Float'];
};

/** Food plan statistics describing the plan across the entire order */
export type LineItemStatsMetadata = {
  __typename?: 'LineItemStatsMetadata';
  /** Days of food included for the plan in the order */
  days: Scalars['Int'];
  /** Number of food packs included for the plan in the order */
  quantity: Scalars['Int'];
  /** Total calories (sum of all the pack calories) included for the plan in the order */
  calories: Scalars['Int'];
  /** Total volume (cm^3) of all the food packs included for the plan in the order */
  volume: Scalars['Int'];
  /** Total weight (g) of all the food packs included for the plan in the order */
  weight: Scalars['Int'];
  /** Plan recipe metadata */
  recipes: Array<LineItemStatsRecipeMetadata>;
  /** Total carryover days from this plan and order into the next order */
  carryover: Scalars['Int'];
};

/** Food plan recipe statistics */
export type LineItemStatsRecipeMetadata = {
  __typename?: 'LineItemStatsRecipeMetadata';
  /** ID of the recipe */
  id: Scalars['Int'];
  /** Days of food included for this specific recipe in the order */
  days: Scalars['Int'];
  /** Number of food packs included for this specific recipe in the order */
  quantity: Scalars['Int'];
  /** Total calories included for this specific recipe in the order */
  calories: Scalars['Int'];
  /** Total volume (cm^3) of food packs included for this specific recipe in the order */
  volume: Scalars['Int'];
  /** Total weight (g) of food packs included for this specific recipe in the order */
  weight: Scalars['Int'];
};

/** Subscription add-on metadata for a line item */
export type LineItemSubscriptionAddOnMetadata = {
  __typename?: 'LineItemSubscriptionAddOnMetadata';
  /** The add-on's ID */
  id: Scalars['Int'];
  /** ID of the subscription */
  subscriptionId: Scalars['Int'];
  /** ID of the product */
  productId: Scalars['Int'];
  /** The quantity of the product */
  quantity: Scalars['Int'];
};

/** The user's email address and password. */
export type LoginInput = {
  /** The user's email address */
  email: Scalars['Email'];
  /** The user's password */
  password: Scalars['String'];
};

/** A customer's view of their order. */
export type MyOrderView = {
  __typename?: 'MyOrderView';
  /** The order's ID */
  id: Scalars['Int'];
  /** Metadata about the order including subscription and plan snapshots */
  metadata: OrderMetadata;
  /** The order number */
  number: Maybe<Scalars['String']>;
  /** The order type */
  type: OrderType;
  /** Does the order have a shipping label purchased? */
  hasShippingLabel: Scalars['Boolean'];
  /** Can the order be modified (or is it locked in)? */
  isEditable: Scalars['Boolean'];
  /** Has the order been delivered? */
  isCompleted: Scalars['Boolean'];
  /** Has the order been cancelled? */
  isCancelled: Scalars['Boolean'];
  /** The order's payment status */
  paymentStatus: OrderPaymentStatus;
  /** The order's shipment status */
  shipmentStatus: OrderShipmentStatus;
  /** A custom status for the order */
  customStatus: Maybe<Scalars['String']>;
  /** Order line items */
  lineItems: Maybe<Array<LineItem>>;
  /** The estimated date the order will be shipped */
  shipDate: Scalars['Date'];
  /** Earliest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead. */
  estimatedEarliestArrivalDate: Scalars['Date'];
  /** Latest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead. */
  estimatedLatestArrivalDate: Scalars['Date'];
  /** The date the order was delivered */
  arrivalDate: Maybe<Scalars['DateTime']>;
  /** The time the order was last exported */
  exportedAt: Maybe<Scalars['DateTime']>;
  /** The order's billing address ID */
  billingAddressId: Scalars['Int'];
  /** The order's billing address */
  billingAddress: UserAddressView;
  /** The order's shipping address ID */
  shippingAddressId: Scalars['Int'];
  /** The order's shipping address */
  shippingAddress: UserAddressView;
  /** The order's total cost in cents */
  total: Scalars['Int'];
  /** The order's shipping cost in cents */
  shippingTotal: Scalars['Int'];
  /** The cost of the order's line items in cents */
  itemTotal: Scalars['Int'];
  /** The order's tax amount in cents */
  taxTotal: Scalars['Int'];
  /** Total cost in cents for add ons on this order */
  addOnsTotal: Maybe<Scalars['Int']>;
  /** The order's discount amount in cents */
  discountTotal: Scalars['Int'];
  /** The order's base cost in cents */
  baseFee: Scalars['Int'];
  /** The order's meals cost in cents */
  mealsTotal: Scalars['Int'];
  /** The name of the fulfillment batch */
  batch: Maybe<Scalars['String']>;
  /** Were the shipping labels purchased outside the standard process? */
  external: Scalars['Boolean'];
  /** Number of times we've attempted to bill the order */
  paymentRetryCount: Scalars['Int'];
  /** Is the order currently being fulfilled? */
  inFulfillment: Scalars['Boolean'];
  /** The name of the carrier delivering the order */
  shippingCarrier: Maybe<Scalars['String']>;
  /** How many days we expect the order to be in transit */
  shipmentDays: Maybe<Scalars['Int']>;
  /** States this order is in */
  states: Maybe<Array<OrderState>>;
  /** Days since previous shipment */
  daysSinceShipment: Scalars['Int'];
  /** The order's payment details */
  payment: Maybe<OrderPayment>;
  /** Credits that can be used for this order payment */
  applicableCredits: Scalars['Int'];
  /** The order total minus applicable credits */
  cashTotal: Scalars['Int'];
  /** Whether this order is a step up (transition) order */
  isStepUp: Maybe<Scalars['Boolean']>;
  /** The relative position of the order in the queue of all of the orders for that subscription */
  queuePosition: OrderQueuePosition;
  /** The number of packs in this order */
  quantity: Maybe<Scalars['Int']>;
  /** The tracking number from the carrier */
  trackingNumber: Maybe<Scalars['String']>;
  /** Link to the tracking information from the carrier */
  trackingURL: Maybe<Scalars['String']>;
  /** Can the order be rescheduled */
  isReschedulable: Scalars['Boolean'];
  /** The communicated cut off date and time for a user to reschedule the order. Time should be displayed as provided, ignore local timezone */
  rescheduleCutOffDate: Scalars['DateTime'];
};

/** A GraphQL connection for MyOrderView. Used to paginate through long lists. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewConnection = {
  __typename?: 'MyOrderViewConnection';
  /** Total number of items */
  totalCount: Scalars['Int'];
  /** Information about the returned page of data */
  pageInfo: PageInfo;
  /** The actual list of items returned in this page */
  edges: Maybe<Array<MyOrderViewEdge>>;
  /** ID of the next order to be processed (the first future order) */
  nextOrderToBeProcessedId: Maybe<Scalars['Int']>;
};

/** A GraphQL connection for MyOrderView. Used to paginate through long lists. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewConnectionEdgesArgs = {
  states: Maybe<Array<Maybe<OrderState>>>;
  sort: Maybe<OrderSortBy>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** A GraphQL connection edge for MyOrderView. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewEdge = {
  __typename?: 'MyOrderViewEdge';
  /** Unique edge identifier within the connection */
  cursor: Scalars['String'];
  /** A MyOrderView */
  node: MyOrderView;
};

/** A customer's view of their pet. */
export type MyPetView = {
  __typename?: 'MyPetView';
  /** The pet's ID */
  id: Scalars['Int'];
  /** The pet's name */
  name: Scalars['String'];
  /** The pet's weight in grams */
  weight: Maybe<Scalars['Int']>;
  /** The breed(s) of the pet */
  breeds: Maybe<Array<Breed>>;
  /** The pet's target weight in grams */
  targetWeight: Maybe<Scalars['Int']>;
  /** The pet's gender */
  gender: Maybe<PetGender>;
  /** The pet's estimated birthdate */
  birthday: Maybe<Scalars['Date']>;
  /** How accurate is the pet's birthday we have recorded? */
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  activity: Maybe<Scalars['Int']>;
  condition: Maybe<Scalars['Int']>;
  treatsQuantity: Maybe<Scalars['Int']>;
  eatingStyle: Maybe<Scalars['Int']>;
  nature: Maybe<Scalars['Int']>;
  /** Is the pet neutered? */
  neutered: Maybe<Scalars['Boolean']>;
  /** The pet's suggested daily calories based on their weight and other factors */
  suggestedCalories: Maybe<Scalars['Float']>;
  /** A pet's suggested calories adjusted to the nearest available DIY calorie bucket */
  suggestedDiyCalories: Maybe<Scalars['Float']>;
  /** The pet's suggested daily calories based on factors excluding their activity level */
  calculatedCalories: Maybe<Scalars['Float']>;
  /** The pet's required calores as prescribed by a vet */
  requiredCalories: Maybe<Scalars['Float']>;
  /** The pet's health issues */
  issues: Maybe<Array<HealthIssue>>;
  /** The pet's prescription diets */
  prescriptionDiets: Maybe<Array<PrescriptionDiet>>;
  /** The pet's food restrictions */
  restrictions: Maybe<Array<Restriction>>;
  /** The pet's current food */
  currentFood: Maybe<CurrentFood>;
  /** Is the pet on a special diet? */
  specialDiet: Maybe<Scalars['Boolean']>;
  /** Can we contact the customer about this pet? */
  noContact: Scalars['Boolean'];
  /** The pet's prescription diet if it is different from what we provide */
  otherPrescriptionDiet: Maybe<Scalars['String']>;
  /**
   * Does the pet have an active food plan?
   * @deprecated Instead use pet.plan.active
   */
  subscribed: Maybe<Scalars['Boolean']>;
  /** Pet creation date */
  createdAt: Scalars['DateTime'];
  /** A puppy starts it's life gaining weight and growing at an accelerated rate. During this time it's crucial to ensure the calorie intake meets the current weight of the dog. When true, the pet is in this growth phase. */
  isRapidlyGrowing: Scalars['Boolean'];
  /**
   * The pet's plan
   * @deprecated Instead use subscription.foodPlans.
   */
  plan: MyPlan;
};

/** A customer's view of their plan. */
export type MyPlan = {
  __typename?: 'MyPlan';
  /** The plan's ID */
  id: Scalars['Int'];
  /** ID of the pet the plan is tied to */
  petId: Scalars['Int'];
  /** ID of the subscription this plan belongs to */
  subscriptionId: Scalars['Int'];
  /** Is this plan active? */
  active: Scalars['Boolean'];
  /** The plan's type */
  type: PlanType;
  /**
   * The plan's products
   * @deprecated Deprecated, should use 'planProducts' field instead
   */
  products: Maybe<Array<Product>>;
  /** The plan's calories. Will be null for incomplete pets during a multi-pet signup. */
  calories: Maybe<Scalars['Float']>;
  /** How much of a full pack should the pet be feed each day? Will be null for incomplete pets during a multi-pet signup. */
  portion: Maybe<Scalars['Float']>;
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float'];
  /** The number of calories a pet gets from The Farmer's Dog food each day */
  dailyCalories: Scalars['Int'];
  /**
   * How many packs of food a customer has at their home for this plan
   * @deprecated Deprecated, should use 'stockAsDays'/'stockAsPacks' fields instead
   */
  stock: Scalars['Float'];
  /** How many days a full pack lasts for this plan */
  lastingDays: Scalars['Float'];
  /** Is this plan a transition plan? */
  transition: Scalars['Boolean'];
  /** Available food products based on serving combination for this plan */
  availableFoodProducts: Maybe<Array<Product>>;
  /** The plan's products */
  planProducts: Maybe<Array<PlanProduct>>;
  /** The plan's recipes */
  recipes: Maybe<Array<PlanRecipe>>;
  /** The stock records for the plan */
  planStock: Maybe<Array<PlanStock>>;
  /** The predicted future stock at next delivery date */
  projectedStockAtNextDelivery: Scalars['Float'];
  /** The mixing plan ratios for currently selected serving combination */
  availableMixingRatios: Maybe<Array<Scalars['Float']>>;
  /** Remaining stock for a pet counted in remaining packs */
  stockAsPacks: Maybe<Scalars['Float']>;
  /** Remaining stock for a pet counted in days left */
  stockAsDays: Maybe<Scalars['Float']>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionView = {
  __typename?: 'MySubscriptionView';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  nextDate: Maybe<Scalars['Date']>;
  startDate: Maybe<Scalars['Date']>;
  frequency: Maybe<Scalars['Int']>;
  numberOfBoxes: Maybe<Scalars['Int']>;
  fillBox: Maybe<Scalars['Boolean']>;
  multiBox: Maybe<Scalars['Boolean']>;
  trialEnabled: Maybe<Scalars['Boolean']>;
  price: Maybe<Scalars['Int']>;
  trialPeriodDiscountPercentage: Maybe<Scalars['Float']>;
  discountPercentage: Maybe<Scalars['Float']>;
  priceVersionId: Maybe<Scalars['Int']>;
  includeTraysAndLids: Maybe<Scalars['Boolean']>;
  /** Whether the subscription has just in time enabled */
  justInTimeEnabled: Maybe<Scalars['Boolean']>;
  /** The quote generated when the customer checked out */
  checkoutQuote: Maybe<Scalars['JSON']>;
  /** The current quote */
  currentQuote: Maybe<SubscriptionQuote>;
  /** The add-ons attached to this subscription */
  addOns: Maybe<Array<AddOn>>;
  /** The feeding guides for each pet with a food plan */
  feedingGuides: Maybe<Array<SubscriptionFeedingGuide>>;
  /** Is there space in the box for add-ons after adding the food? */
  hasSpaceForAddOns: Scalars['Boolean'];
  /** How many treats do we recommend the customer add to their order? */
  recommendedTreatQuantity: Scalars['Int'];
  /** Maximum number of addOns allowed for this subscription. (0 can also mean AddOns are disabled) */
  maxNumberOfAddOns: Scalars['Int'];
  recommendedNextDate: Maybe<Scalars['Date']>;
  /** Stock status immediately before the next delivery, aggregated for all plans on this subscription */
  stockStatusAtNextDelivery: Maybe<StockStatus>;
  /** products and quantities in a non-fresh food subscription */
  subscriptionProducts: Maybe<Array<Maybe<SubscriptionProduct>>>;
  /** Desired buffer stock for this subscription (as days of food) */
  bufferDays: Maybe<Scalars['Int']>;
  /** Range of buffer days for a fresh subscription. Not applicable for DIY */
  bufferRange: Maybe<BufferRange>;
  /** An array of buffer options with associated number of days and reschedule date */
  bufferOptions: Maybe<Array<BufferOption>>;
  /**
   * Dates that can be selected for the next order's shipDate (used for rush or delay)
   * @deprecated Instead use user.availableNextDates
   */
  availableNextDates: Array<Scalars['Date']>;
  /** Calculates a nextDate based solely on your reported plan stock info */
  stockAdjustedNextDate: Scalars['Date'];
  /** Whether the next order can be rescheduled */
  canRescheduleNextOrder: Maybe<Scalars['Boolean']>;
  /** The food plans attached to this subscription */
  foodPlans: Maybe<Array<MyPlan>>;
  /**
   * The orders for this subscription ascending chronologically
   * @deprecated Instead use ordersConnection
   */
  orders: Maybe<Array<MyOrderView>>;
  /** A connection of orders */
  ordersConnection: MyOrderViewConnection;
  /** The most recently delivered order */
  mostRecentlyDeliveredOrder: Maybe<MyOrderView>;
  /** Frequencies available for a users subscription */
  suggestedFrequencies: SuggestedFrequenciesType;
  /** In this subscription's queue of orders, the next order expected to arrive at the customer's door */
  nextOrderToBeDelivered: Maybe<MyOrderView>;
  /** In this subscription's queue of orders, the soonest future order that can be edited or rescheduled */
  firstUnlockedOrder: Maybe<MyOrderView>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewAddOnsArgs = {
  types: Maybe<Array<Maybe<ProductType>>>;
  productIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewStockAdjustedNextDateArgs = {
  planStockStates: Array<PlanStockStateInput>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewOrdersConnectionArgs = {
  first: Maybe<Scalars['Int']>;
  shipmentStatuses: Maybe<Array<Maybe<OrderShipmentStatus>>>;
  queuePositions: Maybe<Array<Maybe<OrderQueuePosition>>>;
  sort: Maybe<OrderSortBy>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** A customer's view of their own user account. */
export type MyUserView = {
  __typename?: 'MyUserView';
  id: Scalars['Int'];
  role: UserRole;
  status: UserStatus;
  email: Scalars['String'];
  phone: Maybe<Scalars['Phone']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  /** First name and last name combined */
  fullName: Maybe<Scalars['String']>;
  /** Code customers can use to get credit for referring other customers */
  referralCode: Scalars['String'];
  /** The customer's data used to make referrals */
  referralStats: Maybe<ReferralStatsType>;
  /** Card ID for Stripe */
  stripeCard: Maybe<Scalars['ID']>;
  /** Confidence level in the value of fresh food */
  freshFoodConfidence: Maybe<Scalars['Int']>;
  /** User creation date */
  createdAt: Scalars['DateTime'];
  /** Dates that can be selected for the next order's shipDate (used for rush or delay) */
  availableNextDates: Array<Scalars['Date']>;
  /** List of credit card details associated with the user */
  creditCards: Maybe<Array<CreditCard>>;
  /** Credit available for the user to spend on products */
  credit: CreditView;
  /** User's preferences on receiving various TFD communications (updates, reminders) via email, SMS, etc. */
  communicationPreferences: CommunicationPreferenceType;
  /** Manual confirmation that a user has not committed fraud */
  fraudOverrideEnabled: Scalars['Boolean'];
  /** A flag to determine if this user can be contacted */
  noContact: Maybe<Scalars['Boolean']>;
  /** The Users current subscription type during signup */
  currentSignupSubscriptionType: Maybe<SubscriptionType>;
  /** A stringified JSON array of subscription types that the user is eligible to sign up for */
  eligibleSignupSubscriptionTypes: Maybe<Scalars['String']>;
  /** The customer's subscription which specify the details of what the customer will receive and how often they should receive it */
  subscription: MySubscriptionView;
  /** The list of the customer's subscriptions differentiated by type */
  subscriptions: Array<MySubscriptionView>;
  /** List of the customer's pets */
  pets: Maybe<Array<MyPetView>>;
  /** The customer's billing address */
  billingAddress: Maybe<UserAddressView>;
  /** The customer's shipping address */
  shippingAddress: Maybe<UserAddressView>;
  /** User's default subscription frequency at the time of checkout */
  frequenciesAtCheckoutFrequency: Maybe<Scalars['Int']>;
  /** The trial start weeks options available to a user */
  trialStartWeeks: Maybe<TrialStartWeeks>;
};

/** A customer's view of their own user account. */
export type MyUserViewSubscriptionsArgs = {
  subscriptionsInput: Maybe<SubscriptionsArgumentsInput>;
};

/** A customer's view of their own user account. */
export type MyUserViewPetsArgs = {
  petsInput: Maybe<PetsArgumentsInput>;
};

/** A customer's view of their own user account. */
export type MyUserViewTrialStartWeeksArgs = {
  trialStartWeeksInput: Maybe<TrialStartWeeksInput>;
};

/** Metadata related to the order */
export type OrderMetadata = {
  __typename?: 'OrderMetadata';
  /** Pets that are associated to the order */
  pets: Maybe<Array<MyPetView>>;
  stats: OrderMetadataStats;
};

/** A pet's plan details at the time this order was shipped */
export type OrderMetadataPlanStats = {
  __typename?: 'OrderMetadataPlanStats';
  /** The plan ID */
  id: Scalars['Int'];
  /** The total calories for the plan in this order (sum of pack calories) */
  calories: Scalars['Int'];
  /** The number of packs of in this order for the plan */
  quantity: Scalars['Int'];
  /** The number of days worth of food in this order for the plan */
  days: Scalars['Float'];
  /** The plan's daily calories at the time this order was shipped */
  dailyCalories: Scalars['Int'];
};

/** A snapshot of how the subscription looked when this order was shipped */
export type OrderMetadataStats = {
  __typename?: 'OrderMetadataStats';
  /** The amount of food in this order, expressed in days */
  avgDays: Scalars['Float'];
  /** The amount of food in this order, expressed in weeks (rounded down to the nearest week) */
  weeksOfFoodInShipment: Scalars['Int'];
  /** Snapshots of plan data */
  plans: Maybe<Array<OrderMetadataPlanStats>>;
  /** The frequency (fresh)/cadence (diy) that is associated with this order */
  frequency: Scalars['Int'];
};

/** The order payment details */
export type OrderPayment = {
  __typename?: 'OrderPayment';
  /** The amount of credits used for payment */
  credits: Scalars['Int'];
  /** The amount of cash used for payment */
  cash: Scalars['Int'];
};

export enum OrderPaymentStatus {
  Scheduled = 'scheduled',
  Authorized = 'authorized',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid',
  Refunded = 'refunded',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Unpaid = 'unpaid',
  Free = 'free',
  Transferred = 'transferred',
}

export enum OrderQueuePosition {
  Past = 'past',
  NextToBeDelivered = 'next_to_be_delivered',
  Future = 'future',
}

export enum OrderShipmentStatus {
  AcquisitionFailed = 'acquisition_failed',
  Cancelled = 'cancelled',
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Scheduled = 'scheduled',
  Shipped = 'shipped',
  Transit = 'transit',
  Unscheduled = 'unscheduled',
}

export type OrderSortBy = {
  /** The field on which to sort by */
  field: OrderSortFieldEnum;
  /** Sorting direction */
  dir: SortDirectionEnum;
};

export enum OrderSortFieldEnum {
  ArrivalDate = 'arrivalDate',
  ShipDate = 'shipDate',
}

export enum OrderState {
  Current = 'current',
  Future = 'future',
  Next = 'next',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  Invalid = 'invalid',
}

export enum OrderType {
  Regular = 'regular',
  Trial = 'trial',
}

/** Standard page info for the GraphQL connection model. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Used to fetch the previous page of results */
  startCursor: Maybe<Scalars['String']>;
  /** Used to fetch the next page of results */
  endCursor: Maybe<Scalars['String']>;
  /** Is there another page of data available before this one? */
  hasPreviousPage: Maybe<Scalars['Boolean']>;
  /** Is there another page of data available after this one? */
  hasNextPage: Maybe<Scalars['Boolean']>;
};

/** Partner Referrer information */
export type PartnerReferrer = {
  __typename?: 'PartnerReferrer';
  id: Scalars['Int'];
  code: Scalars['String'];
  bannerCopy: Maybe<Scalars['String']>;
  discountPercentage: Scalars['Int'];
  invalid: Scalars['Boolean'];
};

export type PendingSubscriptionInputType = {
  /** The start date for a subscription */
  startDate?: Maybe<Scalars['Date']>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: Maybe<Scalars['Int']>;
  /** The type of the subscription. */
  type: SubscriptionType;
};

/** Describes a pet's personality */
export type PersonalityType = {
  __typename?: 'PersonalityType';
  /** The personality type ID */
  id: Scalars['Int'];
  /** The personality type display name */
  name: Scalars['String'];
};

/** Paired with birthday, provides info on how accurate that info is */
export enum PetBirthdayAccuracy {
  /** Birthday is the exact date */
  Date = 'date',
  /** Birthday is accurate to the week */
  Weeks = 'weeks',
  /** Birthday is accurate to the month */
  Months = 'months',
  /** Birthday is accurate to the year */
  Years = 'years',
}

export enum PetGender {
  Male = 'male',
  Female = 'female',
}

export type PetMutationsInput = {
  /** List of pets to create */
  create?: Maybe<Array<CreatePetInput>>;
  /** List of pets to update */
  update?: Maybe<Array<UpdatePetInput>>;
  /** List of pet IDs to delete */
  delete?: Maybe<Array<Scalars['Int']>>;
};

export type PetsArgumentsInput = {
  /** Flag to grab pets based on whether they have an active of inactive food plan */
  active?: Maybe<Scalars['Boolean']>;
  /** List of pet ids */
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** An association between a plan and product. */
export type PlanProduct = {
  __typename?: 'PlanProduct';
  /** The plan product's ID */
  id: Scalars['Int'];
  /** ID of the plan */
  planId: Scalars['Int'];
  /** ID of the product */
  productId: Scalars['Int'];
  /** The product */
  product: Product;
  /** What portion of the plan's products is this product? */
  ratio: Scalars['Float'];
};

/** A The Farmer's Dog recipe for a plan. */
export type PlanRecipe = {
  __typename?: 'PlanRecipe';
  /** The recipe's ID */
  id: Scalars['Int'];
  /** The recipe's name */
  name: Scalars['String'];
  /** What portion of this plan's food is this recipe? */
  ratio: Scalars['Float'];
};

/** A record of a change to planStock or 're-base' of planStock for a plan. */
export type PlanStock = {
  __typename?: 'PlanStock';
  /** The unique planStock record ID (auto-incremented) */
  id: Scalars['Int'];
  /** The ID for the plan associated with this record. Refers to Plans table. */
  planId: Scalars['Int'];
  /** The ID of the base stock record that begins this set of stock changes. Is null for records of type "base". */
  baseStockId: Maybe<Scalars['Int']>;
  /** All of the associated records of type "change" that share this same base stock ID */
  changeStocks: Maybe<Array<PlanStock>>;
  /** The subscription ID associated with this change record. Refers to Subscriptions table. */
  subscriptionId: Scalars['Int'];
  /** The order ID associated with this change record. Refers to Orders table. May be null. */
  orderId: Scalars['Int'];
  /** The burn down rate of packs by day. Matches the daily pack quantity recommended to customers. */
  portion: Scalars['Float'];
  /** The starting amount of packs for a base record, or the delta of packs for a change record. */
  value: Scalars['Float'];
  /** The type of record ("base" or "change") */
  type: PlanStockType;
  /** The moment in time when this record is effective. May differ from created_at because of delays in downstream dependencies (deliveries). */
  addedAt: Scalars['DateTime'];
  /** Auto-generated timestamp of when this record was created */
  createdAt: Scalars['DateTime'];
  /** Auto-generated timestamp of when this record was updated. Note: planStock records are currently never updated, so this field is redundant. */
  updatedAt: Scalars['DateTime'];
};

export type PlanStockStateInput = {
  /** A pet's plan ID */
  planId: Scalars['Int'];
  /** How many total food packs the pet has at home */
  value: Scalars['Float'];
  /** Number of days a single food pack from the most recently delivered order is lasting */
  lastingDays?: Maybe<Scalars['Float']>;
};

/** The type of plan stock record */
export enum PlanStockType {
  /** The record resets the stock to 0 to begin recording changes again */
  Base = 'base',
  /** The record is recording a change to the running stock data */
  Change = 'change',
}

/** What type of plan a plan is */
export enum PlanType {
  /** A food plan */
  Food = 'food',
  /** An add-on plan */
  AddOn = 'add_on',
}

/** A prescription diet for a pet. */
export type PrescriptionDiet = {
  __typename?: 'PrescriptionDiet';
  /** The prescription diet's ID */
  id: Scalars['Int'];
  /** The prescription diet's name */
  name: Scalars['String'];
  /** Can we serve pets that require this diet? */
  enabled: Scalars['Boolean'];
};

/** A The Farmer's Dog Product. */
export type Product = {
  __typename?: 'Product';
  /** The product's ID */
  id: Scalars['Int'];
  /** The product's name */
  name: Scalars['String'];
  /** The product's unique SKU */
  sku: Scalars['String'];
  /** What type of product this is */
  type: ProductType;
  /** Is this product enabled? */
  status: ProductStatus;
  /** If this product is 'food', the recipe ID for it */
  recipeId: Maybe<Scalars['Int']>;
  /** If this product is 'food', the recipe for it */
  recipe: Maybe<Recipe>;
  /** If this product is 'food', the number of calories it includes */
  calories: Maybe<Scalars['Float']>;
  /** The cost of the product in cents */
  price: Scalars['Int'];
  /** The weight of the product in grams */
  weight: Scalars['Float'];
  /** The volume of the product in cubic centimeters */
  volume: Scalars['Float'];
};

/** Serving combination for product */
export type ProductCombination = {
  __typename?: 'ProductCombination';
  /** Calories in package */
  calories: Scalars['Int'];
  /** Previous combination size increment in % */
  decrement: Maybe<Scalars['Float']>;
  /** Next combination size increment in % */
  increment: Maybe<Scalars['Float']>;
  /** Serving portion */
  portion: Scalars['Float'];
  /** Combination size identifier (package calories * portion) */
  size: Scalars['Int'];
};

/** A quantity of a specific product */
export type ProductQuantity = {
  __typename?: 'ProductQuantity';
  /** The quantity */
  quantity: Scalars['Int'];
  /** The productId */
  productId: Scalars['Int'];
};

/** Whether a product is enabled or not */
export enum ProductStatus {
  /** This product is enabled */
  Enabled = 'enabled',
  /** This product is disabled */
  Disabled = 'disabled',
}

/** What type of product a product is */
export enum ProductType {
  /** Food product (a The Farmer's Dog recipe) */
  Food = 'food',
  /** Packaging material */
  Packaging = 'packaging',
  /** Printed inserts like feeding guides */
  Leaflet = 'leaflet',
  /** Ice for keeping the box cool during shipping */
  Ice = 'ice',
  /** Treat product like snacks or bones */
  Treat = 'treat',
  /** Supplement product like vitamins or oils */
  Supplement = 'supplement',
  /** DIY product (nutrition powder) */
  Diy = 'diy',
}

/** As view of a customer's order. */
export type ProjectedOrderView = {
  __typename?: 'ProjectedOrderView';
  /** The order's ID */
  id: Scalars['Int'];
  /** The order number */
  number: Maybe<Scalars['String']>;
  /** The order type */
  type: OrderType;
  /** Metadata about the order including subscription and plan snapshots */
  metadata: OrderMetadata;
  /** The order's payment status */
  paymentStatus: OrderPaymentStatus;
  /** The order's shipment status */
  shipmentStatus: OrderShipmentStatus;
  /** The estimated date the order will be shipped */
  shipDate: Scalars['Date'];
  /** Earliest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead. */
  estimatedEarliestArrivalDate: Scalars['Date'];
  /** The order's billing address ID */
  billingAddressId: Scalars['Int'];
  /** The order's billing address */
  billingAddress: UserAddressView;
  /** The order's shipping address ID */
  shippingAddressId: Scalars['Int'];
  /** The order's shipping address */
  shippingAddress: UserAddressView;
  /** The order's total cost in cents */
  total: Scalars['Int'];
  /** The order's shipping cost in cents */
  shippingTotal: Scalars['Int'];
  /** The cost of the order's line items in cents */
  itemTotal: Scalars['Int'];
  /** The order's tax amount in cents */
  taxTotal: Scalars['Int'];
  /** The order's discount amount in cents */
  discountTotal: Scalars['Int'];
  /** The order's base cost in cents */
  baseFee: Scalars['Int'];
  /** States this order is in */
  states: Maybe<Array<OrderState>>;
  /** Credits that can be used for this order payment */
  applicableCredits: Scalars['Int'];
};

export type ProjectedOrdersInput = {
  /** The id of the subscription to project orders for */
  subscriptionId: Scalars['Int'];
  /** The number of orders to project */
  first?: Maybe<Scalars['Int']>;
  /** Subscription overrides to use for projecting orders */
  subscriptionOverrides?: Maybe<PendingSubscriptionInputType>;
};

export type RecalculatePlansFromFeedingInfoInput = {
  /** The pets to calculate plan data for */
  pets: Array<RecalculatePlansFromFeedingInfoPetInput>;
};

export type RecalculatePlansFromFeedingInfoNewInput = {
  /** Plan Id */
  planId: Scalars['Int'];
  /** How many pack(s) the pet eats daily */
  portion: Scalars['Float'];
  /** Does the user plan on mixing in the new plan? */
  isMixing?: Maybe<Scalars['Boolean']>;
};

export type RecalculatePlansFromFeedingInfoPetInput = {
  /** A pet ID */
  id: Scalars['Int'];
  /** Is our food being mixed with other food */
  isMixingWithOtherFood: Scalars['Boolean'];
  /** How many days a pack of food is lasting */
  packLastingDays: Scalars['Float'];
};

export type RecalculatePlansInput = {
  /** Plan Id */
  id: Scalars['Int'];
  /** Calories in new plan */
  calories: Scalars['Int'];
  /** Mixing ratio of new plan */
  ratio: Scalars['Float'];
  /** Active state of the new plan */
  active?: Maybe<Scalars['Boolean']>;
};

/** A The Farmer's Dog recipe. */
export type Recipe = {
  __typename?: 'Recipe';
  /** The recipe's ID */
  id: Scalars['Int'];
  /** Which category of products is this a recipe for? */
  type: RecipeType;
  /** A human-readable unique identifer for the recipe (like a slug) */
  name: Scalars['String'];
  /** A UI displayable name for the recipe */
  displayName: Scalars['String'];
  /** A summary of the recipe's main ingredients */
  mainIngredients: Scalars['String'];
  /** A summary of the recipe's supplements */
  supplements: Scalars['String'];
  /** A summary of the recipe's full ingredients */
  fullIngredients: Scalars['String'];
  /**
   * A summary of the recipe's ingredients
   * @deprecated Replaced by fullIngredients
   */
  ingredients: Scalars['String'];
  /** The number of carbs in the recipe */
  carbs: Scalars['Int'];
  /** The grams of protein in the recipe */
  protein: Scalars['Int'];
  /** The grams of fat in the recipe */
  fat: Scalars['Int'];
  /** The number of cents a gram of the recipe costs */
  pricePerGram: Scalars['Float'];
  /** The number of calories per gram in the recipe */
  kcalPerGram: Scalars['Float'];
  /** The calorie content of the recipe */
  calorieContent: Maybe<Scalars['String']>;
  /** The guaranteed analysis of the recipe */
  guaranteedAnalysis: Maybe<Scalars['String']>;
  /** The dry matter typical analysis of the recipe */
  typicalAnalysis: Maybe<Scalars['String']>;
};

/** The kind of recipe */
export enum RecipeType {
  /** For our main food recipes */
  Food = 'food',
  /** For treat recipes */
  Treat = 'treat',
}

export type RecommendFoodPlanInput = {
  /** Weight of the pet in grams */
  weight: Scalars['Int'];
  /** Ideal weight of the pet in grams */
  targetWeight?: Maybe<Scalars['Int']>;
  /** The pet's birthdate */
  birthday: Scalars['Date'];
  /** List of the pet's breed IDs */
  breedIds: Array<Scalars['Int']>;
  /** The pet's activity level between 1 (low activity) and 4 (high activity) */
  activityLevel: Scalars['Int'];
  /** The pet's body condition between 1 (underweight) and 4 (overweight) */
  bodyCondition: Scalars['Int'];
  /** Is the pet neutered? */
  neutered: Scalars['Boolean'];
  /** The percentage of this pet's calories that will come from The Farmer's Dog food */
  mixingPlanRatio: Scalars['Float'];
};

/** A recommendation for a subscription */
export type Recommendation = {
  __typename?: 'Recommendation';
  /** A cadence (in days) */
  cadence: Scalars['Int'];
  /** A list of products and associated quantities */
  products: Array<ProductQuantity>;
};

/** Parameters for a recommendation request */
export type RecommendationsInput = {
  fresh?: Maybe<FreshRecommendationInput>;
  diy?: Maybe<DiyRecommendationInput>;
  treats?: Maybe<TreatsRecommendationInput>;
};

/** A collection of requested recommendations */
export type RecommendationsResult = {
  __typename?: 'RecommendationsResult';
  /** A fresh food recommendation */
  fresh: Maybe<Recommendation>;
  /** A DIY recommendation */
  diy: Maybe<Recommendation>;
  /** A treats recommendation */
  treats: Maybe<Recommendation>;
};

/** A recommendation of what a food plan should look like for a pet that can be used to update the plan for that pet. */
export type RecommendedFoodPlan = {
  __typename?: 'RecommendedFoodPlan';
  /** The plan's calories */
  calories: Scalars['Float'];
  /** How much of a full pack should the pet be feed each day? */
  portion: Scalars['Float'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float'];
  /** The plan's recipes */
  recipes: Maybe<Array<PlanRecipe>>;
};

export type ReferralStatsType = {
  __typename?: 'ReferralStatsType';
  /** The user's referral link */
  referralLink: Scalars['String'];
  /** The user's referral code */
  referralCode: Scalars['String'];
  /** Referrals made by the user */
  referralsMade: Maybe<Array<Maybe<ReferralMade>>>;
  /** The discount percentage that the user can currently grant */
  discountPercentage: Scalars['Int'];
  /** Whether the CRM has set that the user is unable to give free referrals */
  freeReferralEnabled: Scalars['Boolean'];
  /** The user's state which determines their discountPercentage */
  referralState: ReferralState;
};

export type RequestPasswordResetInput = {
  /** The e-mail where the reset link will be sent */
  email: Scalars['Email'];
};

/** The result of requesting a password reset */
export type RequestPasswordResetResult = {
  __typename?: 'RequestPasswordResetResult';
  /** Whether or not the reset request was successful */
  success: Scalars['Boolean'];
};

export type RescheduleNextOrderInput = {
  /** Whether the reschedule is a rush or a delay */
  type?: Maybe<RescheduleNextOrderTypes>;
  /** Date to schedule the next order for */
  shipDate: Scalars['Date'];
  /** Reason for reschedule */
  reasonId: RescheduleNextOrderReason;
  /** More detailed reason info */
  reasonDescription: Scalars['String'];
  /** The subscription type to be updated */
  subscriptionType?: Maybe<SubscriptionType>;
};

export enum RescheduleNextOrderReason {
  DiyExtraMixRush = 'diy_extra_mix_rush',
  DiyFeedingMoreRush = 'diy_feeding_more_rush',
  DiyLostPouchRush = 'diy_lost_pouch_rush',
  DiyDamagedPouchRush = 'diy_damaged_pouch_rush',
  DiyAnotherDogRush = 'diy_another_dog_rush',
  DiyFeedingLessDelay = 'diy_feeding_less_delay',
  DiyMixingDelay = 'diy_mixing_delay',
  DiyTransitioningDelay = 'diy_transitioning_delay',
  DiyMoreTimeDelay = 'diy_more_time_delay',
  DiyNotEnoughMixRush = 'diy_not_enough_mix_rush',
  DiyTooMuchMixDelay = 'diy_too_much_mix_delay',
  DiyTravelingRush = 'diy_traveling_rush',
  DiyTravelingDelay = 'diy_traveling_delay',
  DiyOtherRush = 'diy_other_rush',
  DiyOtherDelay = 'diy_other_delay',
  FeedingMoreRush = 'feeding_more_rush',
  TravelingRush = 'traveling_rush',
  LostPackRush = 'lost_pack_rush',
  DamagedPackRush = 'damaged_pack_rush',
  FeedingAnotherRush = 'feeding_another_rush',
  RunningLowRush = 'running_low_rush',
  ExtraPacksRush = 'extra_packs_rush',
  CalibrateRush = 'calibrate_rush',
  OtherRush = 'other_rush',
  FeedingLessDelay = 'feeding_less_delay',
  TrialDelay = 'trial_delay',
  TooMuchFoodDelay = 'too_much_food_delay',
  TravelingDelay = 'traveling_delay',
  TransitioningDelay = 'transitioning_delay',
  MixingDelay = 'mixing_delay',
  CalibrateDelay = 'calibrate_delay',
  OtherDelay = 'other_delay',
  InternalRush = 'internal_rush',
  InternalDelay = 'internal_delay',
  BufferRush = 'buffer_rush',
  BufferDelay = 'buffer_delay',
}

export enum RescheduleNextOrderTypes {
  Rush = 'rush',
  Delay = 'delay',
}

/** The reset token and the user's new password. */
export type ResetPasswordInput = {
  /** The reset token */
  resetToken: Scalars['String'];
  /** The user's new password */
  newPassword: Scalars['String'];
};

/** A pet food restriction/allergy */
export type Restriction = {
  __typename?: 'Restriction';
  /** Restriction ID */
  id: Scalars['Int'];
  /** Restriction name */
  name: Scalars['String'];
  /** Restriction type */
  type: RestrictionType;
};

/** The type of restriction */
export enum RestrictionType {
  /** System default restriction */
  Default = 'default',
  /** User created restriction */
  User = 'user',
}

export type RootMutation = {
  __typename?: 'RootMutation';
  addOptOutUser: AddOptOutUserResultType;
  /** Change the user's password by providing their current password. */
  changePassword: MyUserView;
  /** A singular endpoint allowing a User to checkout. */
  checkout: MyUserView;
  /** Create a support request submitted from the front-end */
  createSupportTicket: Scalars['Boolean'];
  /** Create a new pet with a default fresh plan */
  createMyPet: MyPetView;
  /**
   * Use the user's email address and password to login
   * @deprecated Deprecated in favor of loginNew.  Please switch to using that mutation.
   */
  login: Session;
  /** Use the user's email address and password to login */
  loginNew: Session;
  rescheduleNextOrder: MyUserView;
  /** Request a password reset link */
  requestPasswordReset: RequestPasswordResetResult;
  /** Change the user's password by providing a reset token. */
  resetPassword: MyUserView;
  setMyAddOns: MyUserView;
  suspendMySubscription: MyUserView;
  /**
   *
   *     This mutation can be used to update logged in user fields and user-associated objects.
   *     It allows for partial updates: included fields will be updated, while omitted
   *     fields will be ignored.
   *
   */
  updateMe: MyUserView;
  updateMyBuffer: Maybe<MyUserView>;
  updateMyCommunicationPreferences: MyUserView;
  updateMyFoodPlan: MyUserView;
  /** @deprecated use updateMe instead */
  updateMyPet: MyUserView;
  updateMySubscription: MyUserView;
  updateStock: MyUserView;
  verifyMyAddress: VerifyAddressResult;
};

export type RootMutationAddOptOutUserArgs = {
  input: AddOptOutUserInput;
};

export type RootMutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type RootMutationCheckoutArgs = {
  input: CheckoutInput;
};

export type RootMutationCreateSupportTicketArgs = {
  input: CreateSupportTicketInput;
};

export type RootMutationCreateMyPetArgs = {
  input: CreateMyPetInput;
};

export type RootMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RootMutationLoginNewArgs = {
  input: LoginInput;
};

export type RootMutationRescheduleNextOrderArgs = {
  input: RescheduleNextOrderInput;
};

export type RootMutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type RootMutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type RootMutationSetMyAddOnsArgs = {
  input: SetMyAddOnsInput;
};

export type RootMutationSuspendMySubscriptionArgs = {
  input: SuspendMySubscriptionInput;
};

export type RootMutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type RootMutationUpdateMyBufferArgs = {
  input: UpdateMyBufferInput;
};

export type RootMutationUpdateMyCommunicationPreferencesArgs = {
  input: CommunicationPreferencesInput;
};

export type RootMutationUpdateMyFoodPlanArgs = {
  input: UpdateMyFoodPlanInput;
};

export type RootMutationUpdateMyPetArgs = {
  petId: Scalars['Int'];
  input: UpdatePetInput;
};

export type RootMutationUpdateMySubscriptionArgs = {
  input: UpdateMySubscriptionInput;
};

export type RootMutationUpdateStockArgs = {
  input: UpdateStockInput;
};

export type RootMutationVerifyMyAddressArgs = {
  input: VerifyAddressInput;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  /** A list of the active feature IDs/keys */
  activeFeatures: Array<Scalars['ID']>;
  /** A list of the different activity levels that can describe a pet's amount of activity */
  activityLevels: Array<ActivityLevel>;
  /** A list of add-ons products */
  addOnsProducts: Array<Product>;
  /** A list of the pet body conditions we support */
  bodyConditions: Array<BodyCondition>;
  /**
   * A list of the pet food brands we have data for sorted alphabetically
   * @deprecated Replaced by 'foodBrands'
   */
  brands: Array<FoodBrand>;
  /** A list of the pet breeds we have data for sorted alphabetically */
  breeds: Array<Breed>;
  /** Application configuration information for client use */
  configuration: Configuration;
  /** A list of the countries we currently support */
  countries: Array<Country>;
  districtByPostalCode: District;
  /** Returns a DIY subscription quote. */
  diySubscriptionQuote: Maybe<DiySubscriptionQuote>;
  /** A list of the different eating styles that can describe how a pet eats */
  eatingStyles: Array<EatingStyle>;
  /** A list of the pet food allergies we have data for */
  foodAllergies: Array<FoodAllergy>;
  /** A list of the pet food brands we have data for sorted alphabetically */
  foodBrands: Array<FoodBrand>;
  /** A list of the different types of food that a pet can eat */
  foodTypes: Array<FoodType>;
  /** Returns referral stats for the specified user */
  getReferralStats: Maybe<ReferralStatsType>;
  /** A list of the pet health issues we have data for sorted alphabetically */
  healthIssues: Array<HealthIssue>;
  /** Your user account */
  me: MyUserView;
  /** The cutoff date for scheduling a user's first shipment. This is dynamic and changes weekly once the current date hits. */
  orderChangeCutoffDate: Scalars['DateTime'];
  /** Fetch a Partner Referrer */
  partnerReferrer: Maybe<PartnerReferrer>;
  /** A list of different pet personality types */
  personalityTypes: Array<PersonalityType>;
  /** A list of prescription diets we have data for sorted alphabetically */
  prescriptionDiets: Array<PrescriptionDiet>;
  /** Possible product and package combinations */
  productCombinations: Array<ProductCombination>;
  /** A list of projected orders */
  projectedOrders: Maybe<Array<Maybe<ProjectedOrderView>>>;
  /** Predict new plan information based on current feeding data */
  recalculatePlansFromFeedingInfo: MyUserView;
  /** Predicts a new plan based on current feeding data */
  recalculatePlansFromFeedingInfoNew: MyUserView;
  /** A list of our recipes */
  recipes: Array<Recipe>;
  /** A requested set of recommendations */
  recommendations: RecommendationsResult;
  /** Recommend a food plan given pet data */
  recommendFoodPlan: RecommendedFoodPlan;
  /** A list of different treat usage buckets */
  treatUsageBuckets: Array<TreatUsageBucket>;
  /** Returns the Unknown mix-breed dog breed */
  unknownBreed: Breed;
  /** Fetches a User Referrer by code */
  userReferrer: Maybe<UserReferrer>;
  /** An endpoint for validating whether US shipping addresses are deliverable */
  validateShippingAddress: ValidateShippingAddressView;
  /** The API version number/identifier */
  version: Scalars['ID'];
  /** Possible calories options for DIY */
  availableDiyCalories: Array<Scalars['Int']>;
  /** Returns recalculated plans based on the input but does not store the data in the database. */
  recalculatePlans: Maybe<MyUserView>;
};

export type RootQueryAddOnsProductsArgs = {
  types: Maybe<Array<Maybe<ProductType>>>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RootQueryDistrictByPostalCodeArgs = {
  postalCode: Scalars['String'];
};

export type RootQueryGetReferralStatsArgs = {
  id: Scalars['Int'];
};

export type RootQueryPartnerReferrerArgs = {
  code: Scalars['String'];
};

export type RootQueryProjectedOrdersArgs = {
  input: ProjectedOrdersInput;
};

export type RootQueryRecalculatePlansFromFeedingInfoArgs = {
  input: RecalculatePlansFromFeedingInfoInput;
};

export type RootQueryRecalculatePlansFromFeedingInfoNewArgs = {
  input: Array<RecalculatePlansFromFeedingInfoNewInput>;
};

export type RootQueryRecommendationsArgs = {
  input: RecommendationsInput;
};

export type RootQueryRecommendFoodPlanArgs = {
  input: RecommendFoodPlanInput;
};

export type RootQueryUserReferrerArgs = {
  code: Scalars['String'];
};

export type RootQueryValidateShippingAddressArgs = {
  input: ValidateShippingAddressInput;
};

export type RootQueryRecalculatePlansArgs = {
  input: Array<Maybe<RecalculatePlansInput>>;
};

/** Successful login response. */
export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
};

export type SetMyAddOnsInput = {
  /** Replaces the add-ons for your subscription */
  addOns: Array<AddOnInput>;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** A state (like for countries) */
export type State = {
  __typename?: 'State';
  /** The state's The Farmer's Dog ID */
  id: Scalars['Int'];
  /** The state's display name */
  name: Scalars['String'];
  /** The state's country ID */
  countryId: Scalars['Int'];
  /** The state's abbreviation */
  abbr: Maybe<Scalars['String']>;
};

export enum StockStatus {
  /** Too much food in stock */
  Overage = 'overage',
  /** Too little food in stock */
  Shortage = 'shortage',
  /** Will run out of food within next delivery window */
  Warning = 'warning',
  /** Just the right amount of food in stock */
  Ok = 'ok',
}

/** An overview of a pets current calories and portion */
export type SubscriptionFeedingGuide = {
  __typename?: 'SubscriptionFeedingGuide';
  /** ID of the food plan */
  planId: Scalars['Int'];
  /** ID of the pet the feeding guide is tied to */
  petId: Scalars['Int'];
  /** The pet's current portion being fed */
  portion: Scalars['Float'];
  /**
   * The pets' daily amount of calories being fed
   * @deprecated Use "dailyCalories" instead
   */
  calories: Scalars['Float'];
  /** The pets' daily amount of calories being fed */
  dailyCalories: Scalars['Float'];
  /** Whether the pet is on a mixing plan */
  isMixing: Scalars['Boolean'];
  /** Current number of packs in stock for this plan */
  stockAsPacks: Maybe<Scalars['Float']>;
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  /** SubscriptionProduct id */
  id: Scalars['Int'];
  /** Product id */
  productId: Scalars['Int'];
  /** Quantity of the product in this subscription */
  quantity: Scalars['Int'];
};

export type SubscriptionProductInput = {
  /** SubscriptionProduct id */
  id?: Maybe<Scalars['Int']>;
  /** Product id */
  productId: Scalars['Int'];
  /** Quantity of the product in this subscription */
  quantity: Scalars['Int'];
};

export type SubscriptionQuote = {
  __typename?: 'SubscriptionQuote';
  price: Maybe<SubscriptionQuotePricing>;
  shipment: Maybe<SubscriptionQuoteShipmentDetails>;
  pets: Maybe<Array<SubscriptionQuotePet>>;
  /** Information about the user's subscription */
  subscription: Maybe<SubscriptionQuoteSubscription>;
};

export type SubscriptionQuotePet = {
  __typename?: 'SubscriptionQuotePet';
  id: Scalars['Int'];
  shipment: Maybe<SubscriptionQuotePetShipment>;
  price: Maybe<SubscriptionQuotePetPrice>;
};

export type SubscriptionQuotePetPrice = {
  __typename?: 'SubscriptionQuotePetPrice';
  totalNetAmount: Maybe<Scalars['Int']>;
  totalBaseAmount: Maybe<Scalars['Int']>;
  totalDiscountAmount: Maybe<Scalars['Int']>;
  totalTaxAmount: Maybe<Scalars['Int']>;
  totalItemsTotalAmount: Maybe<Scalars['Int']>;
  totalShippingAmount: Maybe<Scalars['Int']>;
  totalTotalAmount: Maybe<Scalars['Int']>;
  weeklyNetAmount: Maybe<Scalars['Int']>;
  weeklyBaseAmount: Maybe<Scalars['Int']>;
  weeklyDiscountAmount: Maybe<Scalars['Int']>;
  weeklyTaxAmount: Maybe<Scalars['Int']>;
  weeklyItemsTotalAmount: Maybe<Scalars['Int']>;
  weeklyShippingAmount: Maybe<Scalars['Int']>;
  weeklyTotalAmount: Maybe<Scalars['Int']>;
  dailyNetAmount: Maybe<Scalars['Int']>;
  dailyBaseAmount: Maybe<Scalars['Int']>;
  dailyDiscountAmount: Maybe<Scalars['Int']>;
  dailyTaxAmount: Maybe<Scalars['Int']>;
  dailyItemsTotalAmount: Maybe<Scalars['Int']>;
  dailyShippingAmount: Maybe<Scalars['Int']>;
  dailyTotalAmount: Maybe<Scalars['Int']>;
};

export type SubscriptionQuotePetShipment = {
  __typename?: 'SubscriptionQuotePetShipment';
  totalCalories: Maybe<Scalars['Int']>;
  totalVolume: Maybe<Scalars['Int']>;
  totalWeight: Maybe<Scalars['Int']>;
  weeklyCalories: Maybe<Scalars['Int']>;
  weeklyVolume: Maybe<Scalars['Int']>;
  weeklyWeight: Maybe<Scalars['Int']>;
  dailyCalories: Maybe<Scalars['Int']>;
  dailyVolume: Maybe<Scalars['Int']>;
  dailyWeight: Maybe<Scalars['Int']>;
};

export type SubscriptionQuotePrice = {
  __typename?: 'SubscriptionQuotePrice';
  addOnsTotalAmount: Maybe<Scalars['Int']>;
  netAmount: Maybe<Scalars['Int']>;
  baseAmount: Maybe<Scalars['Int']>;
  discountAmount: Maybe<Scalars['Int']>;
  taxAmount: Maybe<Scalars['Int']>;
  itemsTotalAmount: Maybe<Scalars['Int']>;
  shippingAmount: Maybe<Scalars['Int']>;
  totalAmount: Maybe<Scalars['Int']>;
  discountPercentage: Maybe<Scalars['Int']>;
};

export type SubscriptionQuotePricing = {
  __typename?: 'SubscriptionQuotePricing';
  trial: Maybe<SubscriptionQuotePrice>;
  regular: Maybe<SubscriptionQuotePrice>;
};

export type SubscriptionQuoteProductType = {
  __typename?: 'SubscriptionQuoteProductType';
  /** The product's quantity */
  quantity: Scalars['Int'];
  /** The product's id */
  productId: Scalars['Int'];
};

export type SubscriptionQuoteShipment = {
  __typename?: 'SubscriptionQuoteShipment';
  totalDays: Maybe<Scalars['Int']>;
  avgDays: Maybe<Scalars['Int']>;
  fillBox: Maybe<Scalars['Boolean']>;
  multiBox: Maybe<Scalars['Boolean']>;
  defaultFrequency: Maybe<Scalars['Int']>;
  frequency: Maybe<Scalars['Int']>;
  packagingWeight: Maybe<Scalars['Int']>;
  totalWeight: Maybe<Scalars['Int']>;
  totalVolume: Maybe<Scalars['Int']>;
  freeVolume: Maybe<Scalars['Int']>;
  packages: Maybe<Array<SubscriptionQuoteShipmentPackage>>;
  boxes: Maybe<Array<SubscriptionQuoteShipmentBox>>;
  plans: Maybe<Scalars['JSON']>;
  frequencies: Maybe<Array<Scalars['Int']>>;
  shipmentFrequencyInDays: Maybe<Array<Scalars['Int']>>;
  shipmentFrequencyInWeeks: Maybe<Array<Scalars['Int']>>;
  pricePerWeek: Maybe<Scalars['Int']>;
};

export type SubscriptionQuoteShipmentBox = {
  __typename?: 'SubscriptionQuoteShipmentBox';
  ref: Maybe<Scalars['String']>;
  days: Maybe<Scalars['Int']>;
  totalVolume: Maybe<Scalars['Int']>;
  totalWeight: Maybe<Scalars['Int']>;
  packagingWeight: Maybe<Scalars['Int']>;
};

export type SubscriptionQuoteShipmentDetails = {
  __typename?: 'SubscriptionQuoteShipmentDetails';
  regular: Maybe<SubscriptionQuoteShipment>;
  trial: Maybe<SubscriptionQuoteShipment>;
};

export type SubscriptionQuoteShipmentPackage = {
  __typename?: 'SubscriptionQuoteShipmentPackage';
  planId: Maybe<Scalars['Int']>;
  productId: Maybe<Scalars['Int']>;
  days: Maybe<Scalars['Int']>;
  totalVolume: Maybe<Scalars['Int']>;
  totalWeight: Maybe<Scalars['Int']>;
};

export type SubscriptionQuoteSubscription = {
  __typename?: 'SubscriptionQuoteSubscription';
  /** The quote the user has accepted */
  accepted: Maybe<SubscriptionQuoteSubscriptionDetails>;
  /** The quote from the recommendation engine */
  recommended: Maybe<SubscriptionQuoteSubscriptionDetails>;
};

export type SubscriptionQuoteSubscriptionDetails = {
  __typename?: 'SubscriptionQuoteSubscriptionDetails';
  /** The number of days between deliveries */
  cadence: Maybe<Scalars['Int']>;
  /** The subscription's products */
  products: Maybe<Array<Maybe<SubscriptionQuoteProductType>>>;
  /** A pet's daily calorie intake */
  dailyCalories: Maybe<Scalars['Int']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
}

export enum SubscriptionSuspensionReason {
  /** Internal/Influencer */
  Internal = 'internal',
  /** Customer has too much food */
  TooMuchFood = 'too_much_food',
  /** The customer's pet is not eating */
  DogNotEating = 'dog_not_eating',
  /** The customer's pet experienced stomach or digestive issues */
  StomachDigestiveIssues = 'stomach_digestive_issues',
  /** The subscription is too expensive */
  TooExpensive = 'too_expensive',
  /** A temporary pause with plans to continue at a later date */
  TemporaryPause = 'temporary_pause',
  /** The customer needs more time on the trial food */
  NeedMoreTimeOnTrial = 'need_more_time_on_trial',
  /** The customer had an issue with my box or delivery */
  DeliveryIssues = 'delivery_issues',
  /** The food packs are inconvenient or messy */
  PacksInconvenientMessy = 'packs_inconvenient_messy',
  /** The customer doesn't like recurring shipments */
  DontLikeRecurring = 'dont_like_recurring',
  /** The customer's pet(s) require a prescription diet */
  PrescriptionDietRequired = 'prescription_diet_required',
  /** The customer's pet(s) are not eating the home cooked food */
  DogNotEatingHomeCooked = 'dog_not_eating_home_cooked',
  /** The customer is no longer using our nutrient mix */
  NutrientMixNoLongerUsed = 'nutrient_mix_no_longer_used',
  /** Cooking home cooked meals is too inconvenient for the customer */
  CookingInconvenient = 'cooking_inconvenient',
  /** The recipe options are non-sufficient */
  RecipeOptions = 'recipe_options',
  /** Customer has too much nutrient mix */
  TooMuchNutrientMix = 'too_much_nutrient_mix',
  /** The customer's pet passed away */
  PetPassed = 'pet_passed',
  /** Other suspension reason */
  Other = 'other',
}

export enum SubscriptionType {
  Fresh = 'fresh',
  Diy = 'diy',
}

export type SubscriptionsArgumentsInput = {
  /** List of statuses of which to determine which subscriptions to retrieve */
  statuses?: Maybe<Array<Maybe<SubscriptionStatus>>>;
  /** List of types of subscriptions to retrieve */
  types?: Maybe<Array<Maybe<SubscriptionType>>>;
};

/** Information pertaining to a specific suggested frequency */
export type SuggestedFrequenciesInfoType = {
  __typename?: 'SuggestedFrequenciesInfoType';
  /** The suggested frequencies' frequency amount */
  frequency: Scalars['Int'];
  /** The suggested frequencies' quote */
  quote: SubscriptionQuote;
};

export type SuggestedFrequenciesType = {
  __typename?: 'SuggestedFrequenciesType';
  /** The minimum possible suggested frequency for the customer */
  min: Maybe<SuggestedFrequenciesInfoType>;
  /** The regular possible suggested frequency for the customer */
  reg: SuggestedFrequenciesInfoType;
  /** The maximum possible suggested frequency for the customer */
  max: Maybe<SuggestedFrequenciesInfoType>;
};

/** Format for additional data submitted with support tickets (key/value pairs) */
export type SupportTicketAdditionalDataInput = {
  /** Additional data category */
  name: Scalars['String'];
  /** Additional data value */
  value: Scalars['String'];
};

/** List of the issue types for support tickets submitted from the front-end */
export enum SupportTicketIssueType {
  /** User wants to add a pet */
  AddPet = 'add_pet',
  /** Adjust shipping schedule */
  ShippingSchedule = 'shipping_schedule',
  /** Dog isn’t eating */
  NotEating = 'not_eating',
  /** Feeding guideline questions */
  FeedingQuestion = 'feeding_question',
  /** Missing delivery */
  MissingDelivery = 'missing_delivery',
  /** Damaged delivery */
  DamagedShipment = 'damaged_shipment',
  /** Change recipes and ratios */
  UpdateRecipeRatio = 'update_recipe_ratio',
  /** My dog requires a special diet */
  SpecialDiet = 'special_diet',
  /** Technical support */
  Support = 'support',
  /** Other */
  Other = 'other',
}

export type SuspendMySubscriptionInput = {
  /** Reason for suspension */
  reasonType: SubscriptionSuspensionReason;
  /** If reason is "other", the custom suspension reason */
  otherReason?: Maybe<Scalars['String']>;
  /** Freeform contextual suspension information */
  questionnaire?: Maybe<Scalars['JSON']>;
  /** The subscription type to be suspended */
  subscriptionType?: Maybe<SubscriptionType>;
};

/** Loosely describes the amount of treats a pet gets */
export type TreatUsageBucket = {
  __typename?: 'TreatUsageBucket';
  /** The treat usage bucket ID */
  id: Scalars['Int'];
  /** The treat usage bucket display name */
  name: Scalars['String'];
};

export type TreatsRecommendationInput = {
  productId?: Maybe<Scalars['Int']>;
};

/** The trial start week options available to a user */
export type TrialStartWeeks = {
  __typename?: 'TrialStartWeeks';
  /** The time at which these options are no longer valid */
  validUntil: Scalars['DateTime'];
  /** The available trial start week options */
  options: Array<Maybe<Scalars['Date']>>;
};

export type TrialStartWeeksInput = {
  /** The type of the subscription */
  subscriptionType?: Maybe<SubscriptionType>;
};

export type UpdateMeInput = {
  /** The user's email */
  email?: Maybe<Scalars['String']>;
  /** The user's phone */
  phone?: Maybe<Scalars['Phone']>;
  /** The user's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The user's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The user's full name */
  fullName?: Maybe<Scalars['String']>;
  /** User confidence in the benefits of fresh food */
  freshFoodConfidence?: Maybe<FreshFoodConfidenceEnum>;
  /** The user's billing address */
  billingAddress?: Maybe<AddressInput>;
  /** The user's shipping address */
  shippingAddress?: Maybe<AddressInput>;
  /** The user's communication preferences */
  communicationPreferences?: Maybe<CommunicationPreferencesInput>;
  /** The user's subscription */
  subscription?: Maybe<UpdateMySubscriptionInput>;
  /** A flag to determine if this user can be contacted */
  noContact?: Maybe<Scalars['Boolean']>;
  /** Set to true if the account is believed to be fraudulent */
  fraud?: Maybe<Scalars['Boolean']>;
  /** The Users current subscription type during signup */
  currentSignupSubscriptionType?: Maybe<SubscriptionType>;
  /** A stringified JSON array of subscription types that the user is eligible to sign up for */
  eligibleSignupSubscriptionTypes?: Maybe<Scalars['String']>;
  /** User pet mutation instructions */
  petMutations?: Maybe<PetMutationsInput>;
};

export type UpdateMyBufferInput = {
  /** Buffer for this subscription (as number of days of food) */
  bufferDays: Scalars['Int'];
};

export type UpdateMyFoodPlanInput = {
  /** ID of the plan to update */
  id: Scalars['Int'];
  /** Is this plan active when the subscription is active? */
  active: Scalars['Boolean'];
  /** The plan's calories */
  calories: Scalars['Int'];
  /** How much of a full pack should the pet be feed each day? */
  portion: Scalars['Float'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float'];
  /** The recipes to update */
  recipes: Array<UpdateMyFoodPlanRecipeInput>;
};

export type UpdateMyFoodPlanRecipeInput = {
  /** ID of the recipe */
  id: Scalars['Int'];
  /** What portion of this plan's food is this recipe? */
  ratio: Scalars['Float'];
};

export type UpdateMySubscriptionInput = {
  /** A list of food plans to be set on the subscription */
  foodPlans?: Maybe<Array<UpdateMyFoodPlanInput>>;
  /** Optional details for mutating food plans */
  foodPlanMutations?: Maybe<FoodPlanMutationsInput>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: Maybe<Scalars['Int']>;
  /** The date of when the next order will go out */
  nextDate?: Maybe<Scalars['Date']>;
  /** A flag that represents if the next order should ignore treats */
  skipTreatsFromNextOrder?: Maybe<Scalars['Boolean']>;
  /** The products and quantities specified in the subscription */
  subscriptionProducts?: Maybe<Array<SubscriptionProductInput>>;
  /** If present, this field will be used to determine which subscription to update */
  type?: Maybe<SubscriptionType>;
  /** New status for the subscription */
  status?: Maybe<SubscriptionStatus>;
};

export type UpdatePetInput = {
  /** The ID the pet */
  id?: Maybe<Scalars['Int']>;
  /** The activity level of the pet */
  activity?: Maybe<Scalars['Int']>;
  /** The pet's birthday */
  birthday?: Maybe<Scalars['Date']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: Maybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: Maybe<Array<Scalars['Int']>>;
  /** The body condition of the pet */
  condition?: Maybe<Scalars['Int']>;
  /** The pet's current food */
  currentFood?: Maybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: Maybe<Scalars['Int']>;
  /** The pet's gender */
  gender?: Maybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: Maybe<Array<Scalars['String']>>;
  /** The pet's name */
  name?: Maybe<Scalars['String']>;
  /** The nature of the pet */
  nature?: Maybe<Scalars['Int']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: Maybe<Scalars['Boolean']>;
  /** Determines whether or not we should contact the owner of the pet */
  noContact?: Maybe<Scalars['Boolean']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: Maybe<Scalars['String']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: Maybe<Array<Scalars['Int']>>;
  /** A list of restrictions related to the pet */
  restrictionNames?: Maybe<Array<Scalars['String']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: Maybe<Scalars['Int']>;
  /** Does the pet require a special diet */
  specialDiet?: Maybe<Scalars['Boolean']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: Maybe<Scalars['Int']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: Maybe<Scalars['Int']>;
  /** The pet's current weight */
  weight?: Maybe<Scalars['Int']>;
};

export type UpdateStockInput = {
  /** Current stock for plans */
  planStocks: Array<UpdateStockPlanInput>;
};

export type UpdateStockPlanInput = {
  /** A pet's plan ID */
  planId: Scalars['Int'];
  /** How much total stock the user has at home */
  stock: Scalars['Float'];
  /** How much of stock is being used daily */
  portion?: Maybe<Scalars['Float']>;
};

export type UserAddressView = {
  __typename?: 'UserAddressView';
  /** The ID of the address */
  id: Scalars['Int'];
  /** The street address */
  addressLine1: Maybe<Scalars['String']>;
  /** Apartment number, unit number, etc of the address */
  addressLine2: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Maybe<Scalars['String']>;
  /** The state of the address */
  state: Maybe<State>;
  /** The zip of the address */
  zip: Maybe<Scalars['String']>;
  /** The country of the address */
  country: Maybe<Country>;
  /** The type of the address */
  type: AddressType;
  /** Is the address a residential address */
  isResidential: Scalars['Boolean'];
  /** The tax rate associated to the address */
  taxRate: Maybe<Scalars['Float']>;
};

/** User Referrer information */
export type UserReferrer = {
  __typename?: 'UserReferrer';
  id: Scalars['Int'];
  referralCode: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  invalid: Scalars['Boolean'];
};

export enum UserRole {
  Admin = 'admin',
  User = 'user',
  CrmAdmin = 'crm_admin',
  CrmAgent = 'crm_agent',
  CrmViewOnly = 'crm_view_only',
  Disabled = 'disabled',
}

export enum UserStatus {
  /** Awaiting checkout */
  Pending = 'pending',
  /** Checked out */
  Active = 'active',
  Disabled = 'disabled',
}

export type ValidateShippingAddressInput = {
  /** The first line of the address, commonly used for street number */
  addressLine1: Scalars['String'];
  /** The second line of the address, commonly used for building division */
  addressLine2?: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Scalars['String'];
  /** The 2-letter state abbreviation of the address */
  stateAbbreviation: Scalars['String'];
  /** The 5 or 9 digit zip code of the address */
  zip: Scalars['String'];
};

export type ValidateShippingAddressView = {
  __typename?: 'ValidateShippingAddressView';
  /** The first line of the address, commonly used for street number */
  addressLine1: Scalars['String'];
  /** The second line of the address, commonly used for building division */
  addressLine2: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Scalars['String'];
  /** The 2-letter state abbreviation of the address */
  stateAbbreviation: Scalars['String'];
  /** The 5 or 9 digit zip code of the address */
  zip: Scalars['String'];
};

export type VerifyAddressInput = {
  /** The street address */
  addressLine1: Scalars['String'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Scalars['String'];
  /** The state abbreviation of the address */
  stateAbbreviation: Scalars['String'];
  /** The zip of the address */
  zip: Scalars['String'];
  /** The type of address */
  type: AddressType;
};

export enum VerifyAddressInvalidReason {
  /** When address has no shipping rates */
  CannotShipToAddress = 'CANNOT_SHIP_TO_ADDRESS',
  /** When address belongs to state we do not ship to */
  CannotShipToState = 'CANNOT_SHIP_TO_STATE',
  /** When address does not pass Shippo's USPS address validator */
  ShippoUspsInvalid = 'SHIPPO_USPS_INVALID',
  /** When address is already used by another user (looks like fraud) */
  DuplicateAddress = 'DUPLICATE_ADDRESS',
}

export type VerifyAddressResult = {
  __typename?: 'VerifyAddressResult';
  /** The street address */
  addressLine1: Scalars['String'];
  /** Apartment number, unit number, etc of the address */
  addressLine2: Maybe<Scalars['String']>;
  /** The city of the address */
  city: Scalars['String'];
  /** The state of the address */
  state: State;
  /** The zip of the address */
  zip: Scalars['String'];
  /** The type of the address, shipping or billing */
  type: AddressType;
  /** Is the address a verfified address */
  verified: Scalars['Boolean'];
  /** Reason why an address is invalid */
  invalidReason: Maybe<VerifyAddressInvalidReason>;
  /** Message explanation of the invalidity of the address */
  invalidMessage: Maybe<Scalars['String']>;
};

/** Information on a referral that was given */
export type ReferralMade = {
  __typename?: 'referralMade';
  /** The user's ID */
  userId: Scalars['Int'];
  /** The user's first name */
  firstName: Scalars['String'];
  /** The discount given */
  discountGiven: Scalars['Int'];
};

export enum ReferralState {
  DefaultDiscount = 'default_discount',
  Free = 'free',
  DiscountAfterLimit = 'discount_after_limit',
  FreeUnlimited = 'free_unlimited',
}

export type FetchMyAddOnsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchMyAddOnsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      addOns: Maybe<
        Array<{ __typename?: 'AddOn'; productId: number; quantity: number }>
      >;
      orders: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              arrivalDate: Maybe<string>;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    oneOffAddOn: Maybe<{
                      __typename?: 'LineItemOneOffAddOnMetadata';
                      productId: number;
                      quantity: number;
                    }>;
                  };
                }>
              >;
            };
          }>
        >;
      };
    };
  };
};

export type HasSpaceForAddOnsQueryVariables = Exact<{ [key: string]: never }>;

export type HasSpaceForAddOnsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      hasSpaceForAddOns: boolean;
    };
  };
};

export type SetMyAddOnsMutationVariables = Exact<{
  input: SetMyAddOnsInput;
}>;

export type SetMyAddOnsMutation = {
  __typename?: 'RootMutation';
  setMyAddOns: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      addOns: Maybe<
        Array<{ __typename?: 'AddOn'; productId: number; quantity: number }>
      >;
      orders: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              arrivalDate: Maybe<string>;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    oneOffAddOn: Maybe<{
                      __typename?: 'LineItemOneOffAddOnMetadata';
                      productId: number;
                      quantity: number;
                    }>;
                  };
                }>
              >;
            };
          }>
        >;
      };
    };
  };
};

export type AddOnsProductsQueryVariables = Exact<{ [key: string]: never }>;

export type AddOnsProductsQuery = {
  __typename?: 'RootQuery';
  addOnsProducts: Array<{
    __typename?: 'Product';
    id: number;
    sku: string;
    name: string;
    price: number;
    weight: number;
    status: ProductStatus;
  }>;
};

export type FetchPrescriptionDietsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchPrescriptionDietsQuery = {
  __typename?: 'RootQuery';
  prescriptionDiets: Array<{
    __typename?: 'PrescriptionDiet';
    id: number;
    name: string;
  }>;
};

export type RecommendFoodPlanQueryVariables = Exact<{
  input: RecommendFoodPlanInput;
}>;

export type RecommendFoodPlanQuery = {
  __typename?: 'RootQuery';
  recommendFoodPlan: {
    __typename?: 'RecommendedFoodPlan';
    calories: number;
    portion: number;
    ratio: number;
    recipes: Maybe<
      Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
    >;
  };
};

export type UpdateMyFoodPlanSignupActionMutationVariables = Exact<{
  input: UpdateMyFoodPlanInput;
}>;

export type UpdateMyFoodPlanSignupActionMutation = {
  __typename?: 'RootMutation';
  updateMyFoodPlan: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          subscriptionId: number;
          active: boolean;
          type: PlanType;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          stock: number;
          transition: boolean;
          recipes: Maybe<
            Array<{
              __typename?: 'PlanRecipe';
              id: number;
              name: string;
              ratio: number;
            }>
          >;
        }>
      >;
    };
  };
};

export type FetchAvailableNextDatesSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchAvailableNextDatesSubscriptionQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
  };
  configuration: {
    __typename?: 'Configuration';
    maxDelayDurationInDays: number;
  };
};

export type FetchAvailableNextDatesPlanStockQueryVariables = Exact<{
  planStockStates: Array<PlanStockStateInput> | PlanStockStateInput;
}>;

export type FetchAvailableNextDatesPlanStockQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      stockAdjustedNextDate: string;
    };
  };
  configuration: {
    __typename?: 'Configuration';
    maxDelayDurationInDays: number;
  };
};

export type UpdateMyFoodPlanActionMutationVariables = Exact<{
  input: UpdateMyFoodPlanInput;
}>;

export type UpdateMyFoodPlanActionMutation = {
  __typename?: 'RootMutation';
  updateMyFoodPlan: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          subscriptionId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          transition: boolean;
          recipes: Maybe<
            Array<{
              __typename?: 'PlanRecipe';
              id: number;
              name: string;
              ratio: number;
            }>
          >;
        }>
      >;
    };
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'RootMutation';
  changePassword: {
    __typename?: 'MyUserView';
    id: number;
    role: UserRole;
    status: UserStatus;
    email: string;
    phone: Maybe<any>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    fullName: Maybe<string>;
  };
};

export type FetchUserStatusQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserStatusQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    status: UserStatus;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
    };
  };
};

export type BodyConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type BodyConditionsQuery = {
  __typename?: 'RootQuery';
  bodyConditions: Array<{
    __typename?: 'BodyCondition';
    id: number;
    name: string;
    description: string;
  }>;
};

export type OrderDetailsAddressFragment = {
  __typename?: 'UserAddressView';
  addressLine1: Maybe<string>;
  addressLine2: Maybe<string>;
  city: Maybe<string>;
  zip: Maybe<string>;
  state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
};

export type OrderDetailsOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  isReschedulable: boolean;
  rescheduleCutOffDate: string;
  number: Maybe<string>;
  arrivalDate: Maybe<string>;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  trackingNumber: Maybe<string>;
  trackingURL: Maybe<string>;
  paymentStatus: OrderPaymentStatus;
  shipDate: string;
  shipmentStatus: OrderShipmentStatus;
  states: Maybe<Array<OrderState>>;
  total: number;
  type: OrderType;
  cashTotal: number;
  lineItems: Maybe<
    Array<{
      __typename?: 'LineItem';
      quantity: number;
      product: {
        __typename?: 'Product';
        id: number;
        type: ProductType;
        sku: string;
      };
      metadata: {
        __typename?: 'LineItemMetadata';
        pet: Maybe<{
          __typename?: 'LineItemPetMetadata';
          id: number;
          name: string;
        }>;
        plan: Maybe<{ __typename?: 'LineItemFoodPlanMetadata'; id: number }>;
        product: {
          __typename?: 'LineItemProductMetadata';
          type: ProductType;
          recipe: Maybe<{
            __typename?: 'LineItemRecipeMetadata';
            displayName: Maybe<string>;
          }>;
        };
      };
    }>
  >;
  metadata: {
    __typename?: 'OrderMetadata';
    stats: {
      __typename?: 'OrderMetadataStats';
      plans: Maybe<
        Array<{
          __typename?: 'OrderMetadataPlanStats';
          id: number;
          days: number;
        }>
      >;
    };
  };
  shippingAddress: {
    __typename?: 'UserAddressView';
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    city: Maybe<string>;
    zip: Maybe<string>;
    state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
  };
  billingAddress: {
    __typename?: 'UserAddressView';
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    city: Maybe<string>;
    zip: Maybe<string>;
    state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
  };
};

export type OrderDetailsOrderLineItemFragment = {
  __typename?: 'LineItem';
  quantity: number;
  product: {
    __typename?: 'Product';
    id: number;
    type: ProductType;
    sku: string;
  };
  metadata: {
    __typename?: 'LineItemMetadata';
    pet: Maybe<{
      __typename?: 'LineItemPetMetadata';
      id: number;
      name: string;
    }>;
    plan: Maybe<{ __typename?: 'LineItemFoodPlanMetadata'; id: number }>;
    product: {
      __typename?: 'LineItemProductMetadata';
      type: ProductType;
      recipe: Maybe<{
        __typename?: 'LineItemRecipeMetadata';
        displayName: Maybe<string>;
      }>;
    };
  };
};

export type OrderDetailsOrderMetadataFragment = {
  __typename?: 'OrderMetadata';
  stats: {
    __typename?: 'OrderMetadataStats';
    plans: Maybe<
      Array<{ __typename?: 'OrderMetadataPlanStats'; id: number; days: number }>
    >;
  };
};

export type OrderDetailsSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
};

export type OrderDetailsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
};

export type OrderDetailsModalQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type OrderDetailsModalQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              isReschedulable: boolean;
              rescheduleCutOffDate: string;
              number: Maybe<string>;
              arrivalDate: Maybe<string>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              trackingNumber: Maybe<string>;
              trackingURL: Maybe<string>;
              paymentStatus: OrderPaymentStatus;
              shipDate: string;
              shipmentStatus: OrderShipmentStatus;
              states: Maybe<Array<OrderState>>;
              total: number;
              type: OrderType;
              cashTotal: number;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  quantity: number;
                  product: {
                    __typename?: 'Product';
                    id: number;
                    type: ProductType;
                    sku: string;
                  };
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    pet: Maybe<{
                      __typename?: 'LineItemPetMetadata';
                      id: number;
                      name: string;
                    }>;
                    plan: Maybe<{
                      __typename?: 'LineItemFoodPlanMetadata';
                      id: number;
                    }>;
                    product: {
                      __typename?: 'LineItemProductMetadata';
                      type: ProductType;
                      recipe: Maybe<{
                        __typename?: 'LineItemRecipeMetadata';
                        displayName: Maybe<string>;
                      }>;
                    };
                  };
                }>
              >;
              metadata: {
                __typename?: 'OrderMetadata';
                stats: {
                  __typename?: 'OrderMetadataStats';
                  plans: Maybe<
                    Array<{
                      __typename?: 'OrderMetadataPlanStats';
                      id: number;
                      days: number;
                    }>
                  >;
                };
              };
              shippingAddress: {
                __typename?: 'UserAddressView';
                addressLine1: Maybe<string>;
                addressLine2: Maybe<string>;
                city: Maybe<string>;
                zip: Maybe<string>;
                state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
              };
              billingAddress: {
                __typename?: 'UserAddressView';
                addressLine1: Maybe<string>;
                addressLine2: Maybe<string>;
                city: Maybe<string>;
                zip: Maybe<string>;
                state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
              };
            };
          }>
        >;
      };
    }>;
  };
};

export type MyPetEntireFragment = {
  __typename?: 'MyPetView';
  id: number;
  activity: Maybe<number>;
  birthday: Maybe<string>;
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  condition: Maybe<number>;
  eatingStyle: Maybe<number>;
  gender: Maybe<PetGender>;
  name: string;
  nature: Maybe<number>;
  neutered: Maybe<boolean>;
  noContact: boolean;
  otherPrescriptionDiet: Maybe<string>;
  requiredCalories: Maybe<number>;
  specialDiet: Maybe<boolean>;
  targetWeight: Maybe<number>;
  treatsQuantity: Maybe<number>;
  weight: Maybe<number>;
  breeds: Maybe<
    Array<{
      __typename?: 'Breed';
      id: number;
      name: string;
      url: Maybe<string>;
      weightUnit: Maybe<string>;
      weightMax: Maybe<number>;
      weightMin: Maybe<number>;
    }>
  >;
  issues: Maybe<
    Array<{
      __typename?: 'HealthIssue';
      id: number;
      name: string;
      type: HealthIssueType;
      enabled: boolean;
    }>
  >;
  prescriptionDiets: Maybe<
    Array<{
      __typename?: 'PrescriptionDiet';
      id: number;
      name: string;
      enabled: boolean;
    }>
  >;
  restrictions: Maybe<
    Array<{
      __typename?: 'Restriction';
      id: number;
      name: string;
      type: RestrictionType;
    }>
  >;
  currentFood: Maybe<{
    __typename?: 'CurrentFood';
    id: number;
    brand: Maybe<string>;
    product: Maybe<string>;
    type: Maybe<CurrentFoodType>;
    unit: Maybe<CurrentFoodUnit>;
    amount: Maybe<string>;
  }>;
};

export type MySubscriptionOrdersFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  ordersConnection: {
    __typename?: 'MyOrderViewConnection';
    nextOrderToBeProcessedId: Maybe<number>;
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        cursor: string;
        node: {
          __typename?: 'MyOrderView';
          id: number;
          number: Maybe<string>;
          shipDate: string;
          shipmentStatus: OrderShipmentStatus;
          paymentStatus: OrderPaymentStatus;
          states: Maybe<Array<OrderState>>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          arrivalDate: Maybe<string>;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              metadata: {
                __typename?: 'LineItemMetadata';
                oneOffAddOn: Maybe<{
                  __typename?: 'LineItemOneOffAddOnMetadata';
                  productId: number;
                  quantity: number;
                }>;
              };
            }>
          >;
        };
      }>
    >;
  };
};

export type VerifyAddressEntireFragment = {
  __typename?: 'VerifyAddressResult';
  addressLine1: string;
  addressLine2: Maybe<string>;
  city: string;
  zip: string;
  invalidReason: Maybe<VerifyAddressInvalidReason>;
  verified: boolean;
  state: {
    __typename?: 'State';
    name: string;
    id: number;
    abbr: Maybe<string>;
  };
};

export type CreateSupportTicketMutationVariables = Exact<{
  input: CreateSupportTicketInput;
}>;

export type CreateSupportTicketMutation = {
  __typename?: 'RootMutation';
  createSupportTicket: boolean;
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'RootMutation';
  requestPasswordReset: {
    __typename?: 'RequestPasswordResetResult';
    success: boolean;
  };
};

export type MyFoodPlanQueryVariables = Exact<{ [key: string]: never }>;

export type MyFoodPlanQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          type: PlanType;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          stock: number;
          lastingDays: number;
          transition: boolean;
          recipes: Maybe<
            Array<{
              __typename?: 'PlanRecipe';
              id: number;
              name: string;
              ratio: number;
            }>
          >;
        }>
      >;
    };
  };
};

export type UpdateMyFoodPlanFoodPlansFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  active: boolean;
  type: PlanType;
  calories: Maybe<number>;
  portion: Maybe<number>;
  ratio: number;
  dailyCalories: number;
  stock: number;
  lastingDays: number;
  transition: boolean;
  recipes: Maybe<
    Array<{
      __typename?: 'PlanRecipe';
      id: number;
      name: string;
      ratio: number;
    }>
  >;
};

export type UpdateMyFoodPlanMutationVariables = Exact<{
  input: UpdateMyFoodPlanInput;
}>;

export type UpdateMyFoodPlanMutation = {
  __typename?: 'RootMutation';
  updateMyFoodPlan: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      userId: number;
      status: SubscriptionStatus;
      nextDate: Maybe<string>;
      startDate: Maybe<string>;
      frequency: Maybe<number>;
      numberOfBoxes: Maybe<number>;
      fillBox: Maybe<boolean>;
      multiBox: Maybe<boolean>;
      trialEnabled: Maybe<boolean>;
      price: Maybe<number>;
      trialPeriodDiscountPercentage: Maybe<number>;
      discountPercentage: Maybe<number>;
      priceVersionId: Maybe<number>;
      checkoutQuote: Maybe<any>;
      recommendedTreatQuantity: number;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            cursor: string;
            node: {
              __typename?: 'MyOrderView';
              id: number;
              number: Maybe<string>;
              shipDate: string;
              shipmentStatus: OrderShipmentStatus;
              paymentStatus: OrderPaymentStatus;
              states: Maybe<Array<OrderState>>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              arrivalDate: Maybe<string>;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    oneOffAddOn: Maybe<{
                      __typename?: 'LineItemOneOffAddOnMetadata';
                      productId: number;
                      quantity: number;
                    }>;
                  };
                }>
              >;
            };
          }>
        >;
      };
    };
  };
};

export type MyPetsQueryVariables = Exact<{ [key: string]: never }>;

export type MyPetsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        activity: Maybe<number>;
        birthday: Maybe<string>;
        birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
        condition: Maybe<number>;
        eatingStyle: Maybe<number>;
        gender: Maybe<PetGender>;
        name: string;
        nature: Maybe<number>;
        neutered: Maybe<boolean>;
        noContact: boolean;
        otherPrescriptionDiet: Maybe<string>;
        requiredCalories: Maybe<number>;
        specialDiet: Maybe<boolean>;
        targetWeight: Maybe<number>;
        treatsQuantity: Maybe<number>;
        weight: Maybe<number>;
        breeds: Maybe<
          Array<{
            __typename?: 'Breed';
            id: number;
            name: string;
            url: Maybe<string>;
            weightUnit: Maybe<string>;
            weightMax: Maybe<number>;
            weightMin: Maybe<number>;
          }>
        >;
        issues: Maybe<
          Array<{
            __typename?: 'HealthIssue';
            id: number;
            name: string;
            type: HealthIssueType;
            enabled: boolean;
          }>
        >;
        prescriptionDiets: Maybe<
          Array<{
            __typename?: 'PrescriptionDiet';
            id: number;
            name: string;
            enabled: boolean;
          }>
        >;
        restrictions: Maybe<
          Array<{
            __typename?: 'Restriction';
            id: number;
            name: string;
            type: RestrictionType;
          }>
        >;
        currentFood: Maybe<{
          __typename?: 'CurrentFood';
          id: number;
          brand: Maybe<string>;
          product: Maybe<string>;
          type: Maybe<CurrentFoodType>;
          unit: Maybe<CurrentFoodUnit>;
          amount: Maybe<string>;
        }>;
      }>
    >;
  };
};

export type UpdateMyPetMutationVariables = Exact<{
  petId: Scalars['Int'];
  input: UpdatePetInput;
}>;

export type UpdateMyPetMutation = {
  __typename?: 'RootMutation';
  updateMyPet: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        activity: Maybe<number>;
        birthday: Maybe<string>;
        birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
        condition: Maybe<number>;
        eatingStyle: Maybe<number>;
        gender: Maybe<PetGender>;
        name: string;
        nature: Maybe<number>;
        neutered: Maybe<boolean>;
        noContact: boolean;
        otherPrescriptionDiet: Maybe<string>;
        requiredCalories: Maybe<number>;
        specialDiet: Maybe<boolean>;
        targetWeight: Maybe<number>;
        treatsQuantity: Maybe<number>;
        weight: Maybe<number>;
        breeds: Maybe<
          Array<{
            __typename?: 'Breed';
            id: number;
            name: string;
            url: Maybe<string>;
            weightUnit: Maybe<string>;
            weightMax: Maybe<number>;
            weightMin: Maybe<number>;
          }>
        >;
        issues: Maybe<
          Array<{
            __typename?: 'HealthIssue';
            id: number;
            name: string;
            type: HealthIssueType;
            enabled: boolean;
          }>
        >;
        prescriptionDiets: Maybe<
          Array<{
            __typename?: 'PrescriptionDiet';
            id: number;
            name: string;
            enabled: boolean;
          }>
        >;
        restrictions: Maybe<
          Array<{
            __typename?: 'Restriction';
            id: number;
            name: string;
            type: RestrictionType;
          }>
        >;
        currentFood: Maybe<{
          __typename?: 'CurrentFood';
          id: number;
          brand: Maybe<string>;
          product: Maybe<string>;
          type: Maybe<CurrentFoodType>;
          unit: Maybe<CurrentFoodUnit>;
          amount: Maybe<string>;
        }>;
      }>
    >;
  };
};

export type UpdateMySignupSubscriptionTypeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMySignupSubscriptionTypeMutation = {
  __typename?: 'RootMutation';
  updateMe: {
    __typename?: 'MyUserView';
    id: number;
    currentSignupSubscriptionType: Maybe<SubscriptionType>;
    eligibleSignupSubscriptionTypes: Maybe<string>;
  };
};

export type MySubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type MySubscriptionQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      userId: number;
      status: SubscriptionStatus;
      nextDate: Maybe<string>;
      startDate: Maybe<string>;
      frequency: Maybe<number>;
      numberOfBoxes: Maybe<number>;
      fillBox: Maybe<boolean>;
      multiBox: Maybe<boolean>;
      trialEnabled: Maybe<boolean>;
      price: Maybe<number>;
      trialPeriodDiscountPercentage: Maybe<number>;
      discountPercentage: Maybe<number>;
      priceVersionId: Maybe<number>;
      checkoutQuote: Maybe<any>;
      recommendedTreatQuantity: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          type: PlanType;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          stock: number;
          lastingDays: number;
          transition: boolean;
          recipes: Maybe<
            Array<{
              __typename?: 'PlanRecipe';
              id: number;
              name: string;
              ratio: number;
            }>
          >;
        }>
      >;
    };
  };
};

export type UpdateMySubscriptionMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type UpdateMySubscriptionMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      userId: number;
      status: SubscriptionStatus;
      nextDate: Maybe<string>;
      startDate: Maybe<string>;
      frequency: Maybe<number>;
      numberOfBoxes: Maybe<number>;
      fillBox: Maybe<boolean>;
      multiBox: Maybe<boolean>;
      trialEnabled: Maybe<boolean>;
      price: Maybe<number>;
      trialPeriodDiscountPercentage: Maybe<number>;
      discountPercentage: Maybe<number>;
      priceVersionId: Maybe<number>;
      checkoutQuote: Maybe<any>;
      recommendedTreatQuantity: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          type: PlanType;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          stock: number;
          lastingDays: number;
          transition: boolean;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        pets: Maybe<
          Array<{
            __typename?: 'SubscriptionQuotePet';
            id: number;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePetPrice';
              weeklyTotalAmount: Maybe<number>;
              weeklyTaxAmount: Maybe<number>;
            }>;
          }>
        >;
      }>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            cursor: string;
            node: {
              __typename?: 'MyOrderView';
              id: number;
              number: Maybe<string>;
              shipDate: string;
              shipmentStatus: OrderShipmentStatus;
              paymentStatus: OrderPaymentStatus;
              states: Maybe<Array<OrderState>>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              arrivalDate: Maybe<string>;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    oneOffAddOn: Maybe<{
                      __typename?: 'LineItemOneOffAddOnMetadata';
                      productId: number;
                      quantity: number;
                    }>;
                  };
                }>
              >;
            };
          }>
        >;
      };
    };
  };
};

export type VerifyAddressesMutationVariables = Exact<{
  input: VerifyAddressInput;
}>;

export type VerifyAddressesMutation = {
  __typename?: 'RootMutation';
  verifyMyAddress: {
    __typename?: 'VerifyAddressResult';
    addressLine1: string;
    addressLine2: Maybe<string>;
    city: string;
    zip: string;
    invalidReason: Maybe<VerifyAddressInvalidReason>;
    verified: boolean;
    state: {
      __typename?: 'State';
      name: string;
      id: number;
      abbr: Maybe<string>;
    };
  };
};

export type FetchCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCountriesQuery = {
  __typename?: 'RootQuery';
  countries: Array<{
    __typename?: 'Country';
    id: number;
    name: string;
    iso: Maybe<string>;
    states: Maybe<
      Array<{
        __typename?: 'State';
        id: number;
        name: string;
        abbr: Maybe<string>;
      }>
    >;
  }>;
};

export type FetchCountriesStateFragment = {
  __typename?: 'State';
  id: number;
  name: string;
  abbr: Maybe<string>;
};

export type FetchFoodBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchFoodBrandsQuery = {
  __typename?: 'RootQuery';
  foodBrands: Array<{ __typename?: 'FoodBrand'; id: number; name: string }>;
};

export type FetchAvailableNextDatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchAvailableNextDatesQuery = {
  __typename?: 'RootQuery';
  orderChangeCutoffDate: string;
  me: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
  };
};

export type FetchPetConfigurationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchPetConfigurationQuery = {
  __typename?: 'RootQuery';
  activityLevels: Array<{
    __typename?: 'ActivityLevel';
    id: number;
    name: string;
    description: string;
  }>;
  bodyConditions: Array<{
    __typename?: 'BodyCondition';
    id: number;
    name: string;
    description: string;
  }>;
  breeds: Array<{
    __typename?: 'Breed';
    id: number;
    name: string;
    weightMax: Maybe<number>;
  }>;
  eatingStyles: Array<{ __typename?: 'EatingStyle'; id: number; name: string }>;
  foodTypes: Array<{ __typename?: 'FoodType'; id: string; name: string }>;
  healthIssues: Array<{
    __typename?: 'HealthIssue';
    id: number;
    name: string;
    type: HealthIssueType;
  }>;
  personalityTypes: Array<{
    __typename?: 'PersonalityType';
    id: number;
    name: string;
  }>;
  prescriptionDiets: Array<{
    __typename?: 'PrescriptionDiet';
    id: number;
    name: string;
  }>;
  treatUsageBuckets: Array<{
    __typename?: 'TreatUsageBucket';
    id: number;
    name: string;
  }>;
};

export type FetchRecipesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRecipesQuery = {
  __typename?: 'RootQuery';
  recipes: Array<{
    __typename?: 'Recipe';
    id: number;
    type: RecipeType;
    name: string;
    displayName: string;
    mainIngredients: string;
    fullIngredients: string;
    supplements: string;
    calorieContent: Maybe<string>;
    guaranteedAnalysis: Maybe<string>;
    typicalAnalysis: Maybe<string>;
  }>;
};

export type SubscriptionStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionStatusQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
    }>;
  };
};

export type FetchTrialStartWeeksQueryVariables = Exact<{
  subscriptionType: SubscriptionType;
}>;

export type FetchTrialStartWeeksQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    trialStartWeeks: Maybe<{
      __typename?: 'TrialStartWeeks';
      validUntil: string;
      options: Array<Maybe<string>>;
    }>;
  };
};

export type ValidateShippingAddressQueryQueryVariables = Exact<{
  input: ValidateShippingAddressInput;
}>;

export type ValidateShippingAddressQueryQuery = {
  __typename?: 'RootQuery';
  validateShippingAddress: {
    __typename?: 'ValidateShippingAddressView';
    addressLine1: string;
    addressLine2: Maybe<string>;
    city: string;
    stateAbbreviation: string;
    zip: string;
  };
};

export type ActivityLevelDetailsFragment = {
  __typename?: 'ActivityLevel';
  id: number;
  name: string;
};

export type BreedDetailsFragment = {
  __typename?: 'Breed';
  id: number;
  name: string;
  url: Maybe<string>;
  weightUnit: Maybe<string>;
  weightMax: Maybe<number>;
  weightMin: Maybe<number>;
};

export type CommunicationPreferencesDetailsFragment = {
  __typename?: 'MyUserView';
  id: number;
  communicationPreferences: {
    __typename?: 'CommunicationPreferenceType';
    orderUpdatesEmail: boolean;
    orderUpdatesSms: boolean;
    orderRemindersEmail: boolean;
    orderRemindersSms: boolean;
  };
};

export type CreditCardDetailsFragment = {
  __typename?: 'CreditCard';
  id: string;
  nameOnCard: Maybe<string>;
  last4Digits: string;
  expirationMonth: number;
  expirationYear: number;
};

export type CurrentFoodDetailsFragment = {
  __typename?: 'CurrentFood';
  id: number;
  brand: Maybe<string>;
  product: Maybe<string>;
  type: Maybe<CurrentFoodType>;
  unit: Maybe<CurrentFoodUnit>;
  amount: Maybe<string>;
};

export type EatingStyleDetailsFragment = {
  __typename?: 'EatingStyle';
  id: number;
  name: string;
};

export type HealthIssueDetailsFragment = {
  __typename?: 'HealthIssue';
  id: number;
  name: string;
  type: HealthIssueType;
  enabled: boolean;
};

export type LineItemOneOffAddOnMetadataDetailsFragment = {
  __typename?: 'LineItemOneOffAddOnMetadata';
  productId: number;
  quantity: number;
};

export type MyOrderViewConnectionDetailsFragment = {
  __typename?: 'MyOrderViewConnection';
  nextOrderToBeProcessedId: Maybe<number>;
};

export type MyOrderViewDetailsFragment = {
  __typename?: 'MyOrderView';
  id: number;
  number: Maybe<string>;
  shipDate: string;
  shipmentStatus: OrderShipmentStatus;
  paymentStatus: OrderPaymentStatus;
  states: Maybe<Array<OrderState>>;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  arrivalDate: Maybe<string>;
};

export type MyOrderViewEdgeDetailsFragment = {
  __typename?: 'MyOrderViewEdge';
  cursor: string;
};

export type MyPetDetailsFragment = {
  __typename?: 'MyPetView';
  id: number;
  activity: Maybe<number>;
  birthday: Maybe<string>;
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  condition: Maybe<number>;
  eatingStyle: Maybe<number>;
  gender: Maybe<PetGender>;
  name: string;
  nature: Maybe<number>;
  neutered: Maybe<boolean>;
  noContact: boolean;
  otherPrescriptionDiet: Maybe<string>;
  requiredCalories: Maybe<number>;
  specialDiet: Maybe<boolean>;
  targetWeight: Maybe<number>;
  treatsQuantity: Maybe<number>;
  weight: Maybe<number>;
};

export type MyPlanDetailsFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  active: boolean;
  type: PlanType;
  calories: Maybe<number>;
  portion: Maybe<number>;
  ratio: number;
  dailyCalories: number;
  stock: number;
  lastingDays: number;
  transition: boolean;
};

export type MySubscriptionDetailsFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  userId: number;
  status: SubscriptionStatus;
  nextDate: Maybe<string>;
  startDate: Maybe<string>;
  frequency: Maybe<number>;
  numberOfBoxes: Maybe<number>;
  fillBox: Maybe<boolean>;
  multiBox: Maybe<boolean>;
  trialEnabled: Maybe<boolean>;
  price: Maybe<number>;
  trialPeriodDiscountPercentage: Maybe<number>;
  discountPercentage: Maybe<number>;
  priceVersionId: Maybe<number>;
  checkoutQuote: Maybe<any>;
  recommendedTreatQuantity: number;
};

export type MyUserDetailsFragment = {
  __typename?: 'MyUserView';
  id: number;
  role: UserRole;
  status: UserStatus;
  email: string;
  phone: Maybe<any>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  fullName: Maybe<string>;
  referralCode: string;
  stripeCard: Maybe<string>;
  freshFoodConfidence: Maybe<number>;
  createdAt: string;
  availableNextDates: Array<string>;
};

export type PersonalityTypeDetailsFragment = {
  __typename?: 'PersonalityType';
  id: number;
  name: string;
};

export type PlanRecipeDetailsFragment = {
  __typename?: 'PlanRecipe';
  id: number;
  name: string;
  ratio: number;
};

export type PrescriptionDietDetailsFragment = {
  __typename?: 'PrescriptionDiet';
  id: number;
  name: string;
  enabled: boolean;
};

export type RecipeDetailsFragment = {
  __typename?: 'Recipe';
  id: number;
  name: string;
  type: RecipeType;
  displayName: string;
};

export type RestrictionDetailsFragment = {
  __typename?: 'Restriction';
  id: number;
  name: string;
  type: RestrictionType;
};

export type StateDetailsFragment = {
  __typename?: 'State';
  name: string;
  id: number;
  abbr: Maybe<string>;
};

export type SubscriptionQuotePetDetailsFragment = {
  __typename?: 'SubscriptionQuotePet';
  id: number;
};

export type SubscriptionQuotePetPriceDetailsFragment = {
  __typename?: 'SubscriptionQuotePetPrice';
  weeklyTotalAmount: Maybe<number>;
  weeklyTaxAmount: Maybe<number>;
};

export type TreatUsageBucketDetailsFragment = {
  __typename?: 'TreatUsageBucket';
  id: number;
  name: string;
};

export type VerifyAddressDetailsFragment = {
  __typename?: 'VerifyAddressResult';
  addressLine1: string;
  addressLine2: Maybe<string>;
  city: string;
  zip: string;
  invalidReason: Maybe<VerifyAddressInvalidReason>;
  verified: boolean;
};

export type ApiVersionQueryVariables = Exact<{ [key: string]: never }>;

export type ApiVersionQuery = { __typename?: 'RootQuery'; version: string };

export type UseExpiringDataNextDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UseExpiringDataNextDateQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    firstName: Maybe<string>;
    availableNextDates: Array<string>;
  };
};

export type AccountSummaryFeedingGuideFragment = {
  __typename?: 'SubscriptionFeedingGuide';
  petId: number;
  planId: number;
  portion: number;
  dailyCalories: number;
};

export type AccountSummaryFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  active: boolean;
  projectedStockAtNextDelivery: number;
  stockAsPacks: Maybe<number>;
  stockAsDays: Maybe<number>;
};

export type AccountSummaryOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  number: Maybe<string>;
  quantity: Maybe<number>;
  shipDate: string;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  arrivalDate: Maybe<string>;
};

export type AccountSummaryPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type AccountSummarySubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  nextDate: Maybe<string>;
  recommendedNextDate: Maybe<string>;
  stockStatusAtNextDelivery: Maybe<StockStatus>;
  canRescheduleNextOrder: Maybe<boolean>;
  bufferDays: Maybe<number>;
  justInTimeEnabled: Maybe<boolean>;
  mostRecentlyDeliveredOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    number: Maybe<string>;
    quantity: Maybe<number>;
    shipDate: string;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
  }>;
  nextOrderToBeDelivered: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    number: Maybe<string>;
    quantity: Maybe<number>;
    shipDate: string;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
  }>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      active: boolean;
      projectedStockAtNextDelivery: number;
      stockAsPacks: Maybe<number>;
      stockAsDays: Maybe<number>;
    }>
  >;
  feedingGuides: Maybe<
    Array<{
      __typename?: 'SubscriptionFeedingGuide';
      petId: number;
      planId: number;
      portion: number;
      dailyCalories: number;
    }>
  >;
  bufferRange: Maybe<{ __typename?: 'BufferRange'; recommendedDays: number }>;
};

export type AccountSummaryUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    nextDate: Maybe<string>;
    recommendedNextDate: Maybe<string>;
    stockStatusAtNextDelivery: Maybe<StockStatus>;
    canRescheduleNextOrder: Maybe<boolean>;
    bufferDays: Maybe<number>;
    justInTimeEnabled: Maybe<boolean>;
    mostRecentlyDeliveredOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      number: Maybe<string>;
      quantity: Maybe<number>;
      shipDate: string;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
    }>;
    nextOrderToBeDelivered: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      number: Maybe<string>;
      quantity: Maybe<number>;
      shipDate: string;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
    }>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        active: boolean;
        projectedStockAtNextDelivery: number;
        stockAsPacks: Maybe<number>;
        stockAsDays: Maybe<number>;
      }>
    >;
    feedingGuides: Maybe<
      Array<{
        __typename?: 'SubscriptionFeedingGuide';
        petId: number;
        planId: number;
        portion: number;
        dailyCalories: number;
      }>
    >;
    bufferRange: Maybe<{ __typename?: 'BufferRange'; recommendedDays: number }>;
  };
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
};

export type AccountSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountSummaryQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      nextDate: Maybe<string>;
      recommendedNextDate: Maybe<string>;
      stockStatusAtNextDelivery: Maybe<StockStatus>;
      canRescheduleNextOrder: Maybe<boolean>;
      bufferDays: Maybe<number>;
      justInTimeEnabled: Maybe<boolean>;
      mostRecentlyDeliveredOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        number: Maybe<string>;
        quantity: Maybe<number>;
        shipDate: string;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        number: Maybe<string>;
        quantity: Maybe<number>;
        shipDate: string;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          active: boolean;
          projectedStockAtNextDelivery: number;
          stockAsPacks: Maybe<number>;
          stockAsDays: Maybe<number>;
        }>
      >;
      feedingGuides: Maybe<
        Array<{
          __typename?: 'SubscriptionFeedingGuide';
          petId: number;
          planId: number;
          portion: number;
          dailyCalories: number;
        }>
      >;
      bufferRange: Maybe<{
        __typename?: 'BufferRange';
        recommendedDays: number;
      }>;
    };
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
};

export type AddPetSubmitMutationVariables = Exact<{
  input: CreateMyPetInput;
}>;

export type AddPetSubmitMutation = {
  __typename?: 'RootMutation';
  createMyPet: { __typename?: 'MyPetView'; id: number };
};

export type AddPetQueryVariables = Exact<{ [key: string]: never }>;

export type AddPetQuery = {
  __typename?: 'RootQuery';
  activityLevels: Array<{
    __typename?: 'ActivityLevel';
    id: number;
    name: string;
  }>;
  breeds: Array<{ __typename?: 'Breed'; id: number; name: string }>;
  bodyConditions: Array<{
    __typename?: 'BodyCondition';
    id: number;
    name: string;
    description: string;
  }>;
  eatingStyles: Array<{ __typename?: 'EatingStyle'; id: number; name: string }>;
  healthIssues: Array<{
    __typename?: 'HealthIssue';
    id: number;
    name: string;
    enabled: boolean;
    type: HealthIssueType;
  }>;
  personalityTypes: Array<{
    __typename?: 'PersonalityType';
    id: number;
    name: string;
  }>;
  prescriptionDiets: Array<{
    __typename?: 'PrescriptionDiet';
    id: number;
    name: string;
    enabled: boolean;
  }>;
  treatUsageBuckets: Array<{
    __typename?: 'TreatUsageBucket';
    id: number;
    name: string;
  }>;
};

export type CalibrateCaloriesFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  availableMixingRatios: Maybe<Array<number>>;
  dailyCalories: number;
  petId: number;
  ratio: number;
};

export type CalibrateCaloriesPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type CalibrateCaloriesProductCombinationFragment = {
  __typename?: 'ProductCombination';
  size: number;
};

export type CalibrateCaloriesSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      availableMixingRatios: Maybe<Array<number>>;
      dailyCalories: number;
      petId: number;
      ratio: number;
    }>
  >;
};

export type CalibrateCaloriesUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        availableMixingRatios: Maybe<Array<number>>;
        dailyCalories: number;
        petId: number;
        ratio: number;
      }>
    >;
  }>;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
};

export type CalibrateCaloriesQueryVariables = Exact<{ [key: string]: never }>;

export type CalibrateCaloriesQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          availableMixingRatios: Maybe<Array<number>>;
          dailyCalories: number;
          petId: number;
          ratio: number;
        }>
      >;
    }>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
  productCombinations: Array<{
    __typename?: 'ProductCombination';
    size: number;
  }>;
};

export type CalibratePacksFeedingGuideFragment = {
  __typename?: 'SubscriptionFeedingGuide';
  planId: number;
  petId: number;
  portion: number;
  isMixing: boolean;
  dailyCalories: number;
};

export type CalibratePacksFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  stockAsPacks: Maybe<number>;
  calories: Maybe<number>;
};

export type CalibratePacksOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  shipmentStatus: OrderShipmentStatus;
};

export type CalibratePacksPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type CalibratePacksSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  frequency: Maybe<number>;
  nextOrderToBeDelivered: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    shipmentStatus: OrderShipmentStatus;
  }>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      stockAsPacks: Maybe<number>;
      calories: Maybe<number>;
    }>
  >;
  feedingGuides: Maybe<
    Array<{
      __typename?: 'SubscriptionFeedingGuide';
      planId: number;
      petId: number;
      portion: number;
      isMixing: boolean;
      dailyCalories: number;
    }>
  >;
};

export type CalibratePacksUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    frequency: Maybe<number>;
    nextOrderToBeDelivered: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      shipmentStatus: OrderShipmentStatus;
    }>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        stockAsPacks: Maybe<number>;
        calories: Maybe<number>;
      }>
    >;
    feedingGuides: Maybe<
      Array<{
        __typename?: 'SubscriptionFeedingGuide';
        planId: number;
        petId: number;
        portion: number;
        isMixing: boolean;
        dailyCalories: number;
      }>
    >;
  };
};

export type CalibratePacksUpdateStockMutationVariables = Exact<{
  input: UpdateStockInput;
}>;

export type CalibratePacksUpdateStockMutation = {
  __typename?: 'RootMutation';
  updateStock: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        shipmentStatus: OrderShipmentStatus;
      }>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          stockAsPacks: Maybe<number>;
          calories: Maybe<number>;
        }>
      >;
      feedingGuides: Maybe<
        Array<{
          __typename?: 'SubscriptionFeedingGuide';
          planId: number;
          petId: number;
          portion: number;
          isMixing: boolean;
          dailyCalories: number;
        }>
      >;
    };
  };
};

export type CalibratePacksQueryVariables = Exact<{ [key: string]: never }>;

export type CalibratePacksQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        shipmentStatus: OrderShipmentStatus;
      }>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          stockAsPacks: Maybe<number>;
          calories: Maybe<number>;
        }>
      >;
      feedingGuides: Maybe<
        Array<{
          __typename?: 'SubscriptionFeedingGuide';
          planId: number;
          petId: number;
          portion: number;
          isMixing: boolean;
          dailyCalories: number;
        }>
      >;
    };
  };
};

export type CalibratePlansFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  active: boolean;
  calories: Maybe<number>;
  portion: Maybe<number>;
  ratio: number;
  dailyCalories: number;
  recipes: Maybe<
    Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
  >;
};

export type CalibratePlansPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type CalibratePlansQuoteFragment = {
  __typename?: 'SubscriptionQuote';
  price: Maybe<{
    __typename?: 'SubscriptionQuotePricing';
    regular: Maybe<{
      __typename?: 'SubscriptionQuotePrice';
      taxAmount: Maybe<number>;
      totalAmount: Maybe<number>;
    }>;
  }>;
  shipment: Maybe<{
    __typename?: 'SubscriptionQuoteShipmentDetails';
    regular: Maybe<{
      __typename?: 'SubscriptionQuoteShipment';
      pricePerWeek: Maybe<number>;
    }>;
  }>;
};

export type CalibratePlansSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  frequency: Maybe<number>;
  suggestedFrequencies: {
    __typename?: 'SuggestedFrequenciesType';
    min: Maybe<{
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      };
    }>;
    reg: {
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      };
    };
    max: Maybe<{
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      };
    }>;
  };
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      active: boolean;
      calories: Maybe<number>;
      portion: Maybe<number>;
      ratio: number;
      dailyCalories: number;
      recipes: Maybe<
        Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
      >;
    }>
  >;
  currentQuote: Maybe<{
    __typename?: 'SubscriptionQuote';
    price: Maybe<{
      __typename?: 'SubscriptionQuotePricing';
      regular: Maybe<{
        __typename?: 'SubscriptionQuotePrice';
        taxAmount: Maybe<number>;
        totalAmount: Maybe<number>;
      }>;
    }>;
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
      }>;
    }>;
  }>;
};

export type CalibratePlansSuggestedFrequenciesFragment = {
  __typename?: 'SuggestedFrequenciesType';
  min: Maybe<{
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          taxAmount: Maybe<number>;
          totalAmount: Maybe<number>;
        }>;
      }>;
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
    };
  }>;
  reg: {
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          taxAmount: Maybe<number>;
          totalAmount: Maybe<number>;
        }>;
      }>;
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
    };
  };
  max: Maybe<{
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          taxAmount: Maybe<number>;
          totalAmount: Maybe<number>;
        }>;
      }>;
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
    };
  }>;
};

export type CalibratePlansSuggestedFrequencyInfoFragment = {
  __typename?: 'SuggestedFrequenciesInfoType';
  frequency: number;
  quote: {
    __typename?: 'SubscriptionQuote';
    price: Maybe<{
      __typename?: 'SubscriptionQuotePricing';
      regular: Maybe<{
        __typename?: 'SubscriptionQuotePrice';
        taxAmount: Maybe<number>;
        totalAmount: Maybe<number>;
      }>;
    }>;
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
      }>;
    }>;
  };
};

export type CalibratePlansUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    frequency: Maybe<number>;
    suggestedFrequencies: {
      __typename?: 'SuggestedFrequenciesType';
      min: Maybe<{
        __typename?: 'SuggestedFrequenciesInfoType';
        frequency: number;
        quote: {
          __typename?: 'SubscriptionQuote';
          price: Maybe<{
            __typename?: 'SubscriptionQuotePricing';
            regular: Maybe<{
              __typename?: 'SubscriptionQuotePrice';
              taxAmount: Maybe<number>;
              totalAmount: Maybe<number>;
            }>;
          }>;
          shipment: Maybe<{
            __typename?: 'SubscriptionQuoteShipmentDetails';
            regular: Maybe<{
              __typename?: 'SubscriptionQuoteShipment';
              pricePerWeek: Maybe<number>;
            }>;
          }>;
        };
      }>;
      reg: {
        __typename?: 'SuggestedFrequenciesInfoType';
        frequency: number;
        quote: {
          __typename?: 'SubscriptionQuote';
          price: Maybe<{
            __typename?: 'SubscriptionQuotePricing';
            regular: Maybe<{
              __typename?: 'SubscriptionQuotePrice';
              taxAmount: Maybe<number>;
              totalAmount: Maybe<number>;
            }>;
          }>;
          shipment: Maybe<{
            __typename?: 'SubscriptionQuoteShipmentDetails';
            regular: Maybe<{
              __typename?: 'SubscriptionQuoteShipment';
              pricePerWeek: Maybe<number>;
            }>;
          }>;
        };
      };
      max: Maybe<{
        __typename?: 'SuggestedFrequenciesInfoType';
        frequency: number;
        quote: {
          __typename?: 'SubscriptionQuote';
          price: Maybe<{
            __typename?: 'SubscriptionQuotePricing';
            regular: Maybe<{
              __typename?: 'SubscriptionQuotePrice';
              taxAmount: Maybe<number>;
              totalAmount: Maybe<number>;
            }>;
          }>;
          shipment: Maybe<{
            __typename?: 'SubscriptionQuoteShipmentDetails';
            regular: Maybe<{
              __typename?: 'SubscriptionQuoteShipment';
              pricePerWeek: Maybe<number>;
            }>;
          }>;
        };
      }>;
    };
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        active: boolean;
        calories: Maybe<number>;
        portion: Maybe<number>;
        ratio: number;
        dailyCalories: number;
        recipes: Maybe<
          Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
        >;
      }>
    >;
    currentQuote: Maybe<{
      __typename?: 'SubscriptionQuote';
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          taxAmount: Maybe<number>;
          totalAmount: Maybe<number>;
        }>;
      }>;
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
    }>;
  };
};

export type CalibratePlansUpdateStockMutationVariables = Exact<{
  input: UpdateStockInput;
}>;

export type CalibratePlansUpdateStockMutation = {
  __typename?: 'RootMutation';
  updateStock: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type CalibratePlansUpdateSubscriptionMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type CalibratePlansUpdateSubscriptionMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type CalibratePlansRecalculateQueryVariables = Exact<{
  input: RecalculatePlansFromFeedingInfoInput;
}>;

export type CalibratePlansRecalculateQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
  recalculatePlansFromFeedingInfo: {
    __typename?: 'MyUserView';
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type CalibratePlansRecalculateCaloriesQueryVariables = Exact<{
  input: Array<Maybe<RecalculatePlansInput>> | Maybe<RecalculatePlansInput>;
}>;

export type CalibratePlansRecalculateCaloriesQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
  recalculatePlans: Maybe<{
    __typename?: 'MyUserView';
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  }>;
};

export type CalibratePlansUserQueryVariables = Exact<{ [key: string]: never }>;

export type CalibratePlansUserQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                taxAmount: Maybe<number>;
                totalAmount: Maybe<number>;
              }>;
            }>;
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          dailyCalories: number;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
          }>;
        }>;
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type CalibrateRescheduleSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  nextDate: Maybe<string>;
  recommendedNextDate: Maybe<string>;
  canRescheduleNextOrder: Maybe<boolean>;
};

export type CalibrateRescheduleUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    nextDate: Maybe<string>;
    recommendedNextDate: Maybe<string>;
    canRescheduleNextOrder: Maybe<boolean>;
  };
};

export type CalibrateRescheduleNextOrderMutationVariables = Exact<{
  input: RescheduleNextOrderInput;
}>;

export type CalibrateRescheduleNextOrderMutation = {
  __typename?: 'RootMutation';
  rescheduleNextOrder: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      nextDate: Maybe<string>;
      recommendedNextDate: Maybe<string>;
      canRescheduleNextOrder: Maybe<boolean>;
    };
  };
};

export type CalibrateRescheduleQueryVariables = Exact<{ [key: string]: never }>;

export type CalibrateRescheduleQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      nextDate: Maybe<string>;
      recommendedNextDate: Maybe<string>;
      canRescheduleNextOrder: Maybe<boolean>;
    };
  };
};

export type CalibrateSuccessFeedingGuideFragment = {
  __typename?: 'SubscriptionFeedingGuide';
  petId: number;
  planId: number;
  portion: number;
  dailyCalories: number;
  stockAsPacks: Maybe<number>;
};

export type CalibrateSuccessFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  portion: Maybe<number>;
  dailyCalories: number;
  stockAsPacks: Maybe<number>;
  projectedStockAtNextDelivery: number;
  active: boolean;
};

export type CalibrateSuccessOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  isReschedulable: boolean;
  rescheduleCutOffDate: string;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  total: number;
  lineItems: Maybe<
    Array<{
      __typename?: 'LineItem';
      quantity: number;
      metadata: {
        __typename?: 'LineItemMetadata';
        pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
        product: { __typename?: 'LineItemProductMetadata'; type: ProductType };
      };
    }>
  >;
  metadata: {
    __typename?: 'OrderMetadata';
    stats: {
      __typename?: 'OrderMetadataStats';
      plans: Maybe<
        Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
      >;
    };
  };
};

export type CalibrateSuccessPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  gender: Maybe<PetGender>;
};

export type CalibrateSuccessSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  frequency: Maybe<number>;
  canRescheduleNextOrder: Maybe<boolean>;
  stockStatusAtNextDelivery: Maybe<StockStatus>;
  feedingGuides: Maybe<
    Array<{
      __typename?: 'SubscriptionFeedingGuide';
      petId: number;
      planId: number;
      portion: number;
      dailyCalories: number;
      stockAsPacks: Maybe<number>;
    }>
  >;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      portion: Maybe<number>;
      dailyCalories: number;
      stockAsPacks: Maybe<number>;
      projectedStockAtNextDelivery: number;
      active: boolean;
    }>
  >;
  nextOrderToBeDelivered: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    isReschedulable: boolean;
    rescheduleCutOffDate: string;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    total: number;
    lineItems: Maybe<
      Array<{
        __typename?: 'LineItem';
        quantity: number;
        metadata: {
          __typename?: 'LineItemMetadata';
          pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
          product: {
            __typename?: 'LineItemProductMetadata';
            type: ProductType;
          };
        };
      }>
    >;
    metadata: {
      __typename?: 'OrderMetadata';
      stats: {
        __typename?: 'OrderMetadataStats';
        plans: Maybe<
          Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
        >;
      };
    };
  }>;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    isReschedulable: boolean;
    rescheduleCutOffDate: string;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    total: number;
    lineItems: Maybe<
      Array<{
        __typename?: 'LineItem';
        quantity: number;
        metadata: {
          __typename?: 'LineItemMetadata';
          pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
          product: {
            __typename?: 'LineItemProductMetadata';
            type: ProductType;
          };
        };
      }>
    >;
    metadata: {
      __typename?: 'OrderMetadata';
      stats: {
        __typename?: 'OrderMetadataStats';
        plans: Maybe<
          Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
        >;
      };
    };
  }>;
};

export type CalibrateSuccessUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  email: string;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      name: string;
      gender: Maybe<PetGender>;
    }>
  >;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    frequency: Maybe<number>;
    canRescheduleNextOrder: Maybe<boolean>;
    stockStatusAtNextDelivery: Maybe<StockStatus>;
    feedingGuides: Maybe<
      Array<{
        __typename?: 'SubscriptionFeedingGuide';
        petId: number;
        planId: number;
        portion: number;
        dailyCalories: number;
        stockAsPacks: Maybe<number>;
      }>
    >;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        portion: Maybe<number>;
        dailyCalories: number;
        stockAsPacks: Maybe<number>;
        projectedStockAtNextDelivery: number;
        active: boolean;
      }>
    >;
    nextOrderToBeDelivered: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      isReschedulable: boolean;
      rescheduleCutOffDate: string;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      total: number;
      lineItems: Maybe<
        Array<{
          __typename?: 'LineItem';
          quantity: number;
          metadata: {
            __typename?: 'LineItemMetadata';
            pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
            product: {
              __typename?: 'LineItemProductMetadata';
              type: ProductType;
            };
          };
        }>
      >;
      metadata: {
        __typename?: 'OrderMetadata';
        stats: {
          __typename?: 'OrderMetadataStats';
          plans: Maybe<
            Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
          >;
        };
      };
    }>;
    firstUnlockedOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      isReschedulable: boolean;
      rescheduleCutOffDate: string;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      total: number;
      lineItems: Maybe<
        Array<{
          __typename?: 'LineItem';
          quantity: number;
          metadata: {
            __typename?: 'LineItemMetadata';
            pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
            product: {
              __typename?: 'LineItemProductMetadata';
              type: ProductType;
            };
          };
        }>
      >;
      metadata: {
        __typename?: 'OrderMetadata';
        stats: {
          __typename?: 'OrderMetadataStats';
          plans: Maybe<
            Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
          >;
        };
      };
    }>;
  };
};

export type CalibrateSuccessQueryVariables = Exact<{ [key: string]: never }>;

export type CalibrateSuccessQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    email: string;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        gender: Maybe<PetGender>;
      }>
    >;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      canRescheduleNextOrder: Maybe<boolean>;
      stockStatusAtNextDelivery: Maybe<StockStatus>;
      feedingGuides: Maybe<
        Array<{
          __typename?: 'SubscriptionFeedingGuide';
          petId: number;
          planId: number;
          portion: number;
          dailyCalories: number;
          stockAsPacks: Maybe<number>;
        }>
      >;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          portion: Maybe<number>;
          dailyCalories: number;
          stockAsPacks: Maybe<number>;
          projectedStockAtNextDelivery: number;
          active: boolean;
        }>
      >;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        rescheduleCutOffDate: string;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        total: number;
        lineItems: Maybe<
          Array<{
            __typename?: 'LineItem';
            quantity: number;
            metadata: {
              __typename?: 'LineItemMetadata';
              pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
              product: {
                __typename?: 'LineItemProductMetadata';
                type: ProductType;
              };
            };
          }>
        >;
        metadata: {
          __typename?: 'OrderMetadata';
          stats: {
            __typename?: 'OrderMetadataStats';
            plans: Maybe<
              Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
            >;
          };
        };
      }>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        rescheduleCutOffDate: string;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        total: number;
        lineItems: Maybe<
          Array<{
            __typename?: 'LineItem';
            quantity: number;
            metadata: {
              __typename?: 'LineItemMetadata';
              pet: Maybe<{ __typename?: 'LineItemPetMetadata'; id: number }>;
              product: {
                __typename?: 'LineItemProductMetadata';
                type: ProductType;
              };
            };
          }>
        >;
        metadata: {
          __typename?: 'OrderMetadata';
          stats: {
            __typename?: 'OrderMetadataStats';
            plans: Maybe<
              Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
            >;
          };
        };
      }>;
    };
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'RootMutation';
  resetPassword: { __typename?: 'MyUserView'; id: number; email: string };
};

export type CustomerAccountOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  type: OrderType;
};

export type CustomerAccountOrderConnectionFragment = {
  __typename?: 'MyOrderViewConnection';
  edges: Maybe<
    Array<{
      __typename?: 'MyOrderViewEdge';
      node: { __typename?: 'MyOrderView'; id: number; type: OrderType };
    }>
  >;
};

export type CustomerAccountPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type CustomerAccountReferralStatsFragment = {
  __typename?: 'ReferralStatsType';
  discountPercentage: number;
};

export type CustomerAccountSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  justInTimeEnabled: Maybe<boolean>;
  ordersConnection: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: { __typename?: 'MyOrderView'; id: number; type: OrderType };
      }>
    >;
  };
};

export type CustomerAccountUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    justInTimeEnabled: Maybe<boolean>;
    ordersConnection: {
      __typename?: 'MyOrderViewConnection';
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: { __typename?: 'MyOrderView'; id: number; type: OrderType };
        }>
      >;
    };
  }>;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  referralStats: Maybe<{
    __typename?: 'ReferralStatsType';
    discountPercentage: number;
  }>;
};

export type CustomerAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAccountQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      justInTimeEnabled: Maybe<boolean>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: { __typename?: 'MyOrderView'; id: number; type: OrderType };
          }>
        >;
      };
    }>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    referralStats: Maybe<{
      __typename?: 'ReferralStatsType';
      discountPercentage: number;
    }>;
  };
};

export type CustomerAccountFaqUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
  }>;
};

export type CustomerAccountFaqQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAccountFaqQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
    }>;
  };
};

export type DiyMealsPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type DiyMealsSubscriptionsFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  frequency: Maybe<number>;
};

export type DiyMealsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    type: Maybe<SubscriptionType>;
    frequency: Maybe<number>;
  }>;
};

export type DiyMealsUpdateMySubscriptionMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type DiyMealsUpdateMySubscriptionMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
    }>;
  };
};

export type DiyMealsQueryVariables = Exact<{ [key: string]: never }>;

export type DiyMealsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
    }>;
  };
};

export type DiyRecipeDetailsPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  suggestedDiyCalories: Maybe<number>;
};

export type DiyRecipeDetailsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      name: string;
      suggestedDiyCalories: Maybe<number>;
    }>
  >;
};

export type DiyRecipeDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type DiyRecipeDetailsQuery = {
  __typename?: 'RootQuery';
  availableDiyCalories: Array<number>;
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        suggestedDiyCalories: Maybe<number>;
      }>
    >;
  };
};

export type DiyRecipesUserFragment = { __typename?: 'MyUserView'; id: number };

export type DiyRecipesQueryVariables = Exact<{ [key: string]: never }>;

export type DiyRecipesQuery = {
  __typename?: 'RootQuery';
  me: { __typename?: 'MyUserView'; id: number };
};

export type DiyDownsellAddressFragment = {
  __typename?: 'UserAddressView';
  id: number;
  addressLine1: Maybe<string>;
};

export type DiyDownsellOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
};

export type DiyDownsellQuoteFragment = {
  __typename?: 'DiySubscriptionQuote';
  price: {
    __typename?: 'DiyQuotePrice';
    regular: {
      __typename?: 'DiyQuotePriceInfo';
      totalAmount: number;
      taxAmount: number;
    };
  };
  subscription: {
    __typename?: 'DiyQuoteSubscription';
    recommended: Maybe<{
      __typename?: 'DiyQuoteRecommendation';
      cadence: number;
      products: Array<
        Maybe<{
          __typename?: 'DiyQuoteProductQuantity';
          quantity: number;
          productId: number;
        }>
      >;
    }>;
    accepted: Maybe<{
      __typename?: 'DiyQuoteRecommendation';
      cadence: number;
      products: Array<
        Maybe<{
          __typename?: 'DiyQuoteProductQuantity';
          quantity: number;
          productId: number;
        }>
      >;
    }>;
  };
};

export type DiyDownsellSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  frequency: Maybe<number>;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  ordersConnection: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
        };
      }>
    >;
  };
};

export type DiyDownsellUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  availableNextDates: Array<string>;
  shippingAddress: Maybe<{
    __typename?: 'UserAddressView';
    id: number;
    addressLine1: Maybe<string>;
  }>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    frequency: Maybe<number>;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    ordersConnection: {
      __typename?: 'MyOrderViewConnection';
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: {
            __typename?: 'MyOrderView';
            id: number;
            estimatedEarliestArrivalDate: string;
            estimatedLatestArrivalDate: string;
          };
        }>
      >;
    };
  }>;
};

export type DiyDownsellSignupMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type DiyDownsellSignupMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
    shippingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
    }>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
            };
          }>
        >;
      };
    }>;
  };
};

export type DiyDownsellQueryVariables = Exact<{ [key: string]: never }>;

export type DiyDownsellQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
    shippingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
    }>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
            };
          }>
        >;
      };
    }>;
  };
  diySubscriptionQuote: Maybe<{
    __typename?: 'DiySubscriptionQuote';
    price: {
      __typename?: 'DiyQuotePrice';
      regular: {
        __typename?: 'DiyQuotePriceInfo';
        totalAmount: number;
        taxAmount: number;
      };
    };
    subscription: {
      __typename?: 'DiyQuoteSubscription';
      recommended: Maybe<{
        __typename?: 'DiyQuoteRecommendation';
        cadence: number;
        products: Array<
          Maybe<{
            __typename?: 'DiyQuoteProductQuantity';
            quantity: number;
            productId: number;
          }>
        >;
      }>;
      accepted: Maybe<{
        __typename?: 'DiyQuoteRecommendation';
        cadence: number;
        products: Array<
          Maybe<{
            __typename?: 'DiyQuoteProductQuantity';
            quantity: number;
            productId: number;
          }>
        >;
      }>;
    };
  }>;
};

export type EditBufferBufferOptionFragment = {
  __typename?: 'BufferOption';
  days: number;
  rescheduleDate: string;
  willDoubleBill: boolean;
};

export type EditBufferBufferRangeFragment = {
  __typename?: 'BufferRange';
  minDays: number;
  maxDays: number;
  recommendedDays: number;
};

export type EditBufferOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
};

export type EditBufferPetsFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type EditBufferSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  status: SubscriptionStatus;
  nextDate: Maybe<string>;
  bufferDays: Maybe<number>;
  nextOrderToBeDelivered: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
  }>;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
  }>;
  bufferOptions: Maybe<
    Array<{
      __typename?: 'BufferOption';
      days: number;
      rescheduleDate: string;
      willDoubleBill: boolean;
    }>
  >;
  bufferRange: Maybe<{
    __typename?: 'BufferRange';
    minDays: number;
    maxDays: number;
    recommendedDays: number;
  }>;
};

export type UpdateMyBufferMutationVariables = Exact<{
  input: UpdateMyBufferInput;
}>;

export type UpdateMyBufferMutation = {
  __typename?: 'RootMutation';
  updateMyBuffer: Maybe<{
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      bufferDays: Maybe<number>;
      nextDate: Maybe<string>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
    }>;
  }>;
};

export type EditBufferDataQueryVariables = Exact<{ [key: string]: never }>;

export type EditBufferDataQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      nextDate: Maybe<string>;
      bufferDays: Maybe<number>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
      bufferOptions: Maybe<
        Array<{
          __typename?: 'BufferOption';
          days: number;
          rescheduleDate: string;
          willDoubleBill: boolean;
        }>
      >;
      bufferRange: Maybe<{
        __typename?: 'BufferRange';
        minDays: number;
        maxDays: number;
        recommendedDays: number;
      }>;
    }>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
};

export type EditPetBreedFragment = {
  __typename?: 'Breed';
  id: number;
  name: string;
};

export type EditPetHealthIssueFragment = {
  __typename?: 'HealthIssue';
  id: number;
  name: string;
  type: HealthIssueType;
  enabled: boolean;
};

export type EditPetPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  activity: Maybe<number>;
  birthday: Maybe<string>;
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  condition: Maybe<number>;
  eatingStyle: Maybe<number>;
  gender: Maybe<PetGender>;
  name: string;
  nature: Maybe<number>;
  neutered: Maybe<boolean>;
  targetWeight: Maybe<number>;
  treatsQuantity: Maybe<number>;
  weight: Maybe<number>;
  breeds: Maybe<Array<{ __typename?: 'Breed'; id: number; name: string }>>;
  prescriptionDiets: Maybe<
    Array<{
      __typename?: 'PrescriptionDiet';
      id: number;
      name: string;
      enabled: boolean;
    }>
  >;
  issues: Maybe<
    Array<{
      __typename?: 'HealthIssue';
      id: number;
      name: string;
      type: HealthIssueType;
      enabled: boolean;
    }>
  >;
};

export type EditPetPrescriptionDietFragment = {
  __typename?: 'PrescriptionDiet';
  id: number;
  name: string;
  enabled: boolean;
};

export type EditPetUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      activity: Maybe<number>;
      birthday: Maybe<string>;
      birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
      condition: Maybe<number>;
      eatingStyle: Maybe<number>;
      gender: Maybe<PetGender>;
      name: string;
      nature: Maybe<number>;
      neutered: Maybe<boolean>;
      targetWeight: Maybe<number>;
      treatsQuantity: Maybe<number>;
      weight: Maybe<number>;
      breeds: Maybe<Array<{ __typename?: 'Breed'; id: number; name: string }>>;
      prescriptionDiets: Maybe<
        Array<{
          __typename?: 'PrescriptionDiet';
          id: number;
          name: string;
          enabled: boolean;
        }>
      >;
      issues: Maybe<
        Array<{
          __typename?: 'HealthIssue';
          id: number;
          name: string;
          type: HealthIssueType;
          enabled: boolean;
        }>
      >;
    }>
  >;
};

export type EditPetSubmitMutationVariables = Exact<{
  petId: Scalars['Int'];
  input: UpdatePetInput;
}>;

export type EditPetSubmitMutation = {
  __typename?: 'RootMutation';
  updateMyPet: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        activity: Maybe<number>;
        birthday: Maybe<string>;
        birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
        condition: Maybe<number>;
        eatingStyle: Maybe<number>;
        gender: Maybe<PetGender>;
        name: string;
        nature: Maybe<number>;
        neutered: Maybe<boolean>;
        noContact: boolean;
        otherPrescriptionDiet: Maybe<string>;
        requiredCalories: Maybe<number>;
        specialDiet: Maybe<boolean>;
        targetWeight: Maybe<number>;
        treatsQuantity: Maybe<number>;
        weight: Maybe<number>;
        breeds: Maybe<
          Array<{
            __typename?: 'Breed';
            id: number;
            name: string;
            url: Maybe<string>;
            weightUnit: Maybe<string>;
            weightMax: Maybe<number>;
            weightMin: Maybe<number>;
          }>
        >;
        issues: Maybe<
          Array<{
            __typename?: 'HealthIssue';
            id: number;
            name: string;
            type: HealthIssueType;
            enabled: boolean;
          }>
        >;
        prescriptionDiets: Maybe<
          Array<{
            __typename?: 'PrescriptionDiet';
            id: number;
            name: string;
            enabled: boolean;
          }>
        >;
        restrictions: Maybe<
          Array<{
            __typename?: 'Restriction';
            id: number;
            name: string;
            type: RestrictionType;
          }>
        >;
        currentFood: Maybe<{
          __typename?: 'CurrentFood';
          id: number;
          brand: Maybe<string>;
          product: Maybe<string>;
          type: Maybe<CurrentFoodType>;
          unit: Maybe<CurrentFoodUnit>;
          amount: Maybe<string>;
        }>;
      }>
    >;
  };
};

export type EditPetQueryVariables = Exact<{ [key: string]: never }>;

export type EditPetQuery = {
  __typename?: 'RootQuery';
  activityLevels: Array<{
    __typename?: 'ActivityLevel';
    id: number;
    name: string;
  }>;
  breeds: Array<{ __typename?: 'Breed'; id: number; name: string }>;
  bodyConditions: Array<{
    __typename?: 'BodyCondition';
    id: number;
    name: string;
    description: string;
  }>;
  eatingStyles: Array<{ __typename?: 'EatingStyle'; id: number; name: string }>;
  healthIssues: Array<{
    __typename?: 'HealthIssue';
    id: number;
    name: string;
    enabled: boolean;
    type: HealthIssueType;
  }>;
  personalityTypes: Array<{
    __typename?: 'PersonalityType';
    id: number;
    name: string;
  }>;
  prescriptionDiets: Array<{
    __typename?: 'PrescriptionDiet';
    id: number;
    name: string;
    enabled: boolean;
  }>;
  treatUsageBuckets: Array<{
    __typename?: 'TreatUsageBucket';
    id: number;
    name: string;
  }>;
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        activity: Maybe<number>;
        birthday: Maybe<string>;
        birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
        condition: Maybe<number>;
        eatingStyle: Maybe<number>;
        gender: Maybe<PetGender>;
        name: string;
        nature: Maybe<number>;
        neutered: Maybe<boolean>;
        targetWeight: Maybe<number>;
        treatsQuantity: Maybe<number>;
        weight: Maybe<number>;
        breeds: Maybe<
          Array<{ __typename?: 'Breed'; id: number; name: string }>
        >;
        prescriptionDiets: Maybe<
          Array<{
            __typename?: 'PrescriptionDiet';
            id: number;
            name: string;
            enabled: boolean;
          }>
        >;
        issues: Maybe<
          Array<{
            __typename?: 'HealthIssue';
            id: number;
            name: string;
            type: HealthIssueType;
            enabled: boolean;
          }>
        >;
      }>
    >;
  };
};

export type FreeMealsPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type FreeMealsReferralStatsFragment = {
  __typename?: 'ReferralStatsType';
  referralLink: string;
  referralCode: string;
  discountPercentage: number;
  freeReferralEnabled: boolean;
  referralState: ReferralState;
  referralsMade: Maybe<
    Array<Maybe<{ __typename?: 'referralMade'; firstName: string }>>
  >;
};

export type FreeMealsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type FreeMealsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
  getReferralStats: Maybe<{
    __typename?: 'ReferralStatsType';
    referralLink: string;
    referralCode: string;
    discountPercentage: number;
    freeReferralEnabled: boolean;
    referralState: ReferralState;
    referralsMade: Maybe<
      Array<Maybe<{ __typename?: 'referralMade'; firstName: string }>>
    >;
  }>;
};

export type InventoryPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type InventoryPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  active: boolean;
  calories: Maybe<number>;
  dailyCalories: number;
  lastingDays: number;
  portion: Maybe<number>;
  ratio: number;
  stock: number;
  transition: boolean;
  type: PlanType;
  recipes: Maybe<
    Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
  >;
};

export type InventoryQuoteFragment = {
  __typename?: 'SubscriptionQuote';
  shipment: Maybe<{
    __typename?: 'SubscriptionQuoteShipmentDetails';
    regular: Maybe<{
      __typename?: 'SubscriptionQuoteShipment';
      pricePerWeek: Maybe<number>;
      totalDays: Maybe<number>;
      defaultFrequency: Maybe<number>;
    }>;
  }>;
  price: Maybe<{
    __typename?: 'SubscriptionQuotePricing';
    regular: Maybe<{
      __typename?: 'SubscriptionQuotePrice';
      shippingAmount: Maybe<number>;
      taxAmount: Maybe<number>;
      totalAmount: Maybe<number>;
      discountAmount: Maybe<number>;
    }>;
  }>;
};

export type InventoryQuotePriceFragment = {
  __typename?: 'SubscriptionQuotePrice';
  shippingAmount: Maybe<number>;
  taxAmount: Maybe<number>;
  totalAmount: Maybe<number>;
  discountAmount: Maybe<number>;
};

export type InventoryQuoteShipmentFragment = {
  __typename?: 'SubscriptionQuoteShipment';
  pricePerWeek: Maybe<number>;
  totalDays: Maybe<number>;
  defaultFrequency: Maybe<number>;
};

export type InventorySubscriptionsFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  frequency: Maybe<number>;
  nextDate: Maybe<string>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      active: boolean;
      calories: Maybe<number>;
      dailyCalories: number;
      lastingDays: number;
      portion: Maybe<number>;
      ratio: number;
      stock: number;
      transition: boolean;
      type: PlanType;
      recipes: Maybe<
        Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
      >;
    }>
  >;
  currentQuote: Maybe<{
    __typename?: 'SubscriptionQuote';
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
        totalDays: Maybe<number>;
        defaultFrequency: Maybe<number>;
      }>;
    }>;
    price: Maybe<{
      __typename?: 'SubscriptionQuotePricing';
      regular: Maybe<{
        __typename?: 'SubscriptionQuotePrice';
        shippingAmount: Maybe<number>;
        taxAmount: Maybe<number>;
        totalAmount: Maybe<number>;
        discountAmount: Maybe<number>;
      }>;
    }>;
  }>;
};

export type InventoryUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    frequency: Maybe<number>;
    nextDate: Maybe<string>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        active: boolean;
        calories: Maybe<number>;
        dailyCalories: number;
        lastingDays: number;
        portion: Maybe<number>;
        ratio: number;
        stock: number;
        transition: boolean;
        type: PlanType;
        recipes: Maybe<
          Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
        >;
      }>
    >;
    currentQuote: Maybe<{
      __typename?: 'SubscriptionQuote';
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
          totalDays: Maybe<number>;
          defaultFrequency: Maybe<number>;
        }>;
      }>;
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          shippingAmount: Maybe<number>;
          taxAmount: Maybe<number>;
          totalAmount: Maybe<number>;
          discountAmount: Maybe<number>;
        }>;
      }>;
    }>;
  }>;
};

export type DiyCadenceUpdateMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type DiyCadenceUpdateMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
    }>;
  };
};

export type InventoryUpdateMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type InventoryUpdateMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
      nextDate: Maybe<string>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          dailyCalories: number;
          lastingDays: number;
          portion: Maybe<number>;
          ratio: number;
          stock: number;
          transition: boolean;
          type: PlanType;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
            totalDays: Maybe<number>;
            defaultFrequency: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            shippingAmount: Maybe<number>;
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
            discountAmount: Maybe<number>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type InventoryRecalculatePlansQueryVariables = Exact<{
  input: RecalculatePlansFromFeedingInfoInput;
}>;

export type InventoryRecalculatePlansQuery = {
  __typename?: 'RootQuery';
  recalculatePlansFromFeedingInfo: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
      nextDate: Maybe<string>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          dailyCalories: number;
          lastingDays: number;
          portion: Maybe<number>;
          ratio: number;
          stock: number;
          transition: boolean;
          type: PlanType;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
            totalDays: Maybe<number>;
            defaultFrequency: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            shippingAmount: Maybe<number>;
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
            discountAmount: Maybe<number>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type InventoryUserQueryVariables = Exact<{ [key: string]: never }>;

export type InventoryUserQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
      nextDate: Maybe<string>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          active: boolean;
          calories: Maybe<number>;
          dailyCalories: number;
          lastingDays: number;
          portion: Maybe<number>;
          ratio: number;
          stock: number;
          transition: boolean;
          type: PlanType;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
            totalDays: Maybe<number>;
            defaultFrequency: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            shippingAmount: Maybe<number>;
            taxAmount: Maybe<number>;
            totalAmount: Maybe<number>;
            discountAmount: Maybe<number>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'RootMutation';
  loginNew: { __typename?: 'Session'; token: string };
};

export type OrderAddressFragment = {
  __typename?: 'UserAddressView';
  addressLine1: Maybe<string>;
  addressLine2: Maybe<string>;
  city: Maybe<string>;
  zip: Maybe<string>;
  state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
};

export type OrdersConnectionEdgeFragment = {
  __typename?: 'MyOrderViewEdge';
  node: {
    __typename?: 'MyOrderView';
    id: number;
    isReschedulable: boolean;
    rescheduleCutOffDate: string;
    number: Maybe<string>;
    arrivalDate: Maybe<string>;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    trackingURL: Maybe<string>;
    paymentStatus: OrderPaymentStatus;
    shipmentStatus: OrderShipmentStatus;
    total: number;
    type: OrderType;
    applicableCredits: number;
    cashTotal: number;
    lineItems: Maybe<
      Array<{
        __typename?: 'LineItem';
        quantity: number;
        metadata: {
          __typename?: 'LineItemMetadata';
          pet: Maybe<{
            __typename?: 'LineItemPetMetadata';
            id: number;
            name: string;
          }>;
          product: {
            __typename?: 'LineItemProductMetadata';
            type: ProductType;
            recipe: Maybe<{
              __typename?: 'LineItemRecipeMetadata';
              displayName: Maybe<string>;
            }>;
          };
        };
      }>
    >;
    metadata: {
      __typename?: 'OrderMetadata';
      pets: Maybe<
        Array<{ __typename?: 'MyPetView'; id: number; name: string }>
      >;
      stats: {
        __typename?: 'OrderMetadataStats';
        frequency: number;
        plans: Maybe<
          Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
        >;
      };
    };
    shippingAddress: {
      __typename?: 'UserAddressView';
      addressLine1: Maybe<string>;
      addressLine2: Maybe<string>;
      city: Maybe<string>;
      zip: Maybe<string>;
      state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
    };
    payment: Maybe<{
      __typename?: 'OrderPayment';
      credits: number;
      cash: number;
    }>;
  };
};

export type OrdersCurrentFragment = {
  __typename?: 'MySubscriptionView';
  currentConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
};

export type OrdersFutureFragment = {
  __typename?: 'MySubscriptionView';
  futureConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
};

export type OrdersOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  isReschedulable: boolean;
  rescheduleCutOffDate: string;
  number: Maybe<string>;
  arrivalDate: Maybe<string>;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  trackingURL: Maybe<string>;
  paymentStatus: OrderPaymentStatus;
  shipmentStatus: OrderShipmentStatus;
  total: number;
  type: OrderType;
  applicableCredits: number;
  cashTotal: number;
  lineItems: Maybe<
    Array<{
      __typename?: 'LineItem';
      quantity: number;
      metadata: {
        __typename?: 'LineItemMetadata';
        pet: Maybe<{
          __typename?: 'LineItemPetMetadata';
          id: number;
          name: string;
        }>;
        product: {
          __typename?: 'LineItemProductMetadata';
          type: ProductType;
          recipe: Maybe<{
            __typename?: 'LineItemRecipeMetadata';
            displayName: Maybe<string>;
          }>;
        };
      };
    }>
  >;
  metadata: {
    __typename?: 'OrderMetadata';
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    stats: {
      __typename?: 'OrderMetadataStats';
      frequency: number;
      plans: Maybe<
        Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
      >;
    };
  };
  shippingAddress: {
    __typename?: 'UserAddressView';
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    city: Maybe<string>;
    zip: Maybe<string>;
    state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
  };
  payment: Maybe<{
    __typename?: 'OrderPayment';
    credits: number;
    cash: number;
  }>;
};

export type OrdersOrderLineItemFragment = {
  __typename?: 'LineItem';
  quantity: number;
  metadata: {
    __typename?: 'LineItemMetadata';
    pet: Maybe<{
      __typename?: 'LineItemPetMetadata';
      id: number;
      name: string;
    }>;
    product: {
      __typename?: 'LineItemProductMetadata';
      type: ProductType;
      recipe: Maybe<{
        __typename?: 'LineItemRecipeMetadata';
        displayName: Maybe<string>;
      }>;
    };
  };
};

export type OrdersOrderMetadataFragment = {
  __typename?: 'OrderMetadata';
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  stats: {
    __typename?: 'OrderMetadataStats';
    frequency: number;
    plans: Maybe<
      Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
    >;
  };
};

export type OrdersOrderPaymentFragment = {
  __typename?: 'OrderPayment';
  credits: number;
  cash: number;
};

export type OrdersPastFragment = {
  __typename?: 'MySubscriptionView';
  pastConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
};

export type OrdersPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type OrdersSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  status: SubscriptionStatus;
  currentConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
  futureConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
  pastConnections: {
    __typename?: 'MyOrderViewConnection';
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          isReschedulable: boolean;
          rescheduleCutOffDate: string;
          number: Maybe<string>;
          arrivalDate: Maybe<string>;
          estimatedEarliestArrivalDate: string;
          estimatedLatestArrivalDate: string;
          trackingURL: Maybe<string>;
          paymentStatus: OrderPaymentStatus;
          shipmentStatus: OrderShipmentStatus;
          total: number;
          type: OrderType;
          applicableCredits: number;
          cashTotal: number;
          lineItems: Maybe<
            Array<{
              __typename?: 'LineItem';
              quantity: number;
              metadata: {
                __typename?: 'LineItemMetadata';
                pet: Maybe<{
                  __typename?: 'LineItemPetMetadata';
                  id: number;
                  name: string;
                }>;
                product: {
                  __typename?: 'LineItemProductMetadata';
                  type: ProductType;
                  recipe: Maybe<{
                    __typename?: 'LineItemRecipeMetadata';
                    displayName: Maybe<string>;
                  }>;
                };
              };
            }>
          >;
          metadata: {
            __typename?: 'OrderMetadata';
            pets: Maybe<
              Array<{ __typename?: 'MyPetView'; id: number; name: string }>
            >;
            stats: {
              __typename?: 'OrderMetadataStats';
              frequency: number;
              plans: Maybe<
                Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
              >;
            };
          };
          shippingAddress: {
            __typename?: 'UserAddressView';
            addressLine1: Maybe<string>;
            addressLine2: Maybe<string>;
            city: Maybe<string>;
            zip: Maybe<string>;
            state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
          };
          payment: Maybe<{
            __typename?: 'OrderPayment';
            credits: number;
            cash: number;
          }>;
        };
      }>
    >;
  };
};

export type OrdersUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    type: Maybe<SubscriptionType>;
    status: SubscriptionStatus;
    currentConnections: {
      __typename?: 'MyOrderViewConnection';
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: {
            __typename?: 'MyOrderView';
            id: number;
            isReschedulable: boolean;
            rescheduleCutOffDate: string;
            number: Maybe<string>;
            arrivalDate: Maybe<string>;
            estimatedEarliestArrivalDate: string;
            estimatedLatestArrivalDate: string;
            trackingURL: Maybe<string>;
            paymentStatus: OrderPaymentStatus;
            shipmentStatus: OrderShipmentStatus;
            total: number;
            type: OrderType;
            applicableCredits: number;
            cashTotal: number;
            lineItems: Maybe<
              Array<{
                __typename?: 'LineItem';
                quantity: number;
                metadata: {
                  __typename?: 'LineItemMetadata';
                  pet: Maybe<{
                    __typename?: 'LineItemPetMetadata';
                    id: number;
                    name: string;
                  }>;
                  product: {
                    __typename?: 'LineItemProductMetadata';
                    type: ProductType;
                    recipe: Maybe<{
                      __typename?: 'LineItemRecipeMetadata';
                      displayName: Maybe<string>;
                    }>;
                  };
                };
              }>
            >;
            metadata: {
              __typename?: 'OrderMetadata';
              pets: Maybe<
                Array<{ __typename?: 'MyPetView'; id: number; name: string }>
              >;
              stats: {
                __typename?: 'OrderMetadataStats';
                frequency: number;
                plans: Maybe<
                  Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
                >;
              };
            };
            shippingAddress: {
              __typename?: 'UserAddressView';
              addressLine1: Maybe<string>;
              addressLine2: Maybe<string>;
              city: Maybe<string>;
              zip: Maybe<string>;
              state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
            };
            payment: Maybe<{
              __typename?: 'OrderPayment';
              credits: number;
              cash: number;
            }>;
          };
        }>
      >;
    };
    futureConnections: {
      __typename?: 'MyOrderViewConnection';
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: {
            __typename?: 'MyOrderView';
            id: number;
            isReschedulable: boolean;
            rescheduleCutOffDate: string;
            number: Maybe<string>;
            arrivalDate: Maybe<string>;
            estimatedEarliestArrivalDate: string;
            estimatedLatestArrivalDate: string;
            trackingURL: Maybe<string>;
            paymentStatus: OrderPaymentStatus;
            shipmentStatus: OrderShipmentStatus;
            total: number;
            type: OrderType;
            applicableCredits: number;
            cashTotal: number;
            lineItems: Maybe<
              Array<{
                __typename?: 'LineItem';
                quantity: number;
                metadata: {
                  __typename?: 'LineItemMetadata';
                  pet: Maybe<{
                    __typename?: 'LineItemPetMetadata';
                    id: number;
                    name: string;
                  }>;
                  product: {
                    __typename?: 'LineItemProductMetadata';
                    type: ProductType;
                    recipe: Maybe<{
                      __typename?: 'LineItemRecipeMetadata';
                      displayName: Maybe<string>;
                    }>;
                  };
                };
              }>
            >;
            metadata: {
              __typename?: 'OrderMetadata';
              pets: Maybe<
                Array<{ __typename?: 'MyPetView'; id: number; name: string }>
              >;
              stats: {
                __typename?: 'OrderMetadataStats';
                frequency: number;
                plans: Maybe<
                  Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
                >;
              };
            };
            shippingAddress: {
              __typename?: 'UserAddressView';
              addressLine1: Maybe<string>;
              addressLine2: Maybe<string>;
              city: Maybe<string>;
              zip: Maybe<string>;
              state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
            };
            payment: Maybe<{
              __typename?: 'OrderPayment';
              credits: number;
              cash: number;
            }>;
          };
        }>
      >;
    };
    pastConnections: {
      __typename?: 'MyOrderViewConnection';
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: {
            __typename?: 'MyOrderView';
            id: number;
            isReschedulable: boolean;
            rescheduleCutOffDate: string;
            number: Maybe<string>;
            arrivalDate: Maybe<string>;
            estimatedEarliestArrivalDate: string;
            estimatedLatestArrivalDate: string;
            trackingURL: Maybe<string>;
            paymentStatus: OrderPaymentStatus;
            shipmentStatus: OrderShipmentStatus;
            total: number;
            type: OrderType;
            applicableCredits: number;
            cashTotal: number;
            lineItems: Maybe<
              Array<{
                __typename?: 'LineItem';
                quantity: number;
                metadata: {
                  __typename?: 'LineItemMetadata';
                  pet: Maybe<{
                    __typename?: 'LineItemPetMetadata';
                    id: number;
                    name: string;
                  }>;
                  product: {
                    __typename?: 'LineItemProductMetadata';
                    type: ProductType;
                    recipe: Maybe<{
                      __typename?: 'LineItemRecipeMetadata';
                      displayName: Maybe<string>;
                    }>;
                  };
                };
              }>
            >;
            metadata: {
              __typename?: 'OrderMetadata';
              pets: Maybe<
                Array<{ __typename?: 'MyPetView'; id: number; name: string }>
              >;
              stats: {
                __typename?: 'OrderMetadataStats';
                frequency: number;
                plans: Maybe<
                  Array<{ __typename?: 'OrderMetadataPlanStats'; days: number }>
                >;
              };
            };
            shippingAddress: {
              __typename?: 'UserAddressView';
              addressLine1: Maybe<string>;
              addressLine2: Maybe<string>;
              city: Maybe<string>;
              zip: Maybe<string>;
              state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
            };
            payment: Maybe<{
              __typename?: 'OrderPayment';
              credits: number;
              cash: number;
            }>;
          };
        }>
      >;
    };
  }>;
};

export type OrdersQueryVariables = Exact<{ [key: string]: never }>;

export type OrdersQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      currentConnections: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              isReschedulable: boolean;
              rescheduleCutOffDate: string;
              number: Maybe<string>;
              arrivalDate: Maybe<string>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              trackingURL: Maybe<string>;
              paymentStatus: OrderPaymentStatus;
              shipmentStatus: OrderShipmentStatus;
              total: number;
              type: OrderType;
              applicableCredits: number;
              cashTotal: number;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  quantity: number;
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    pet: Maybe<{
                      __typename?: 'LineItemPetMetadata';
                      id: number;
                      name: string;
                    }>;
                    product: {
                      __typename?: 'LineItemProductMetadata';
                      type: ProductType;
                      recipe: Maybe<{
                        __typename?: 'LineItemRecipeMetadata';
                        displayName: Maybe<string>;
                      }>;
                    };
                  };
                }>
              >;
              metadata: {
                __typename?: 'OrderMetadata';
                pets: Maybe<
                  Array<{ __typename?: 'MyPetView'; id: number; name: string }>
                >;
                stats: {
                  __typename?: 'OrderMetadataStats';
                  frequency: number;
                  plans: Maybe<
                    Array<{
                      __typename?: 'OrderMetadataPlanStats';
                      days: number;
                    }>
                  >;
                };
              };
              shippingAddress: {
                __typename?: 'UserAddressView';
                addressLine1: Maybe<string>;
                addressLine2: Maybe<string>;
                city: Maybe<string>;
                zip: Maybe<string>;
                state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
              };
              payment: Maybe<{
                __typename?: 'OrderPayment';
                credits: number;
                cash: number;
              }>;
            };
          }>
        >;
      };
      futureConnections: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              isReschedulable: boolean;
              rescheduleCutOffDate: string;
              number: Maybe<string>;
              arrivalDate: Maybe<string>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              trackingURL: Maybe<string>;
              paymentStatus: OrderPaymentStatus;
              shipmentStatus: OrderShipmentStatus;
              total: number;
              type: OrderType;
              applicableCredits: number;
              cashTotal: number;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  quantity: number;
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    pet: Maybe<{
                      __typename?: 'LineItemPetMetadata';
                      id: number;
                      name: string;
                    }>;
                    product: {
                      __typename?: 'LineItemProductMetadata';
                      type: ProductType;
                      recipe: Maybe<{
                        __typename?: 'LineItemRecipeMetadata';
                        displayName: Maybe<string>;
                      }>;
                    };
                  };
                }>
              >;
              metadata: {
                __typename?: 'OrderMetadata';
                pets: Maybe<
                  Array<{ __typename?: 'MyPetView'; id: number; name: string }>
                >;
                stats: {
                  __typename?: 'OrderMetadataStats';
                  frequency: number;
                  plans: Maybe<
                    Array<{
                      __typename?: 'OrderMetadataPlanStats';
                      days: number;
                    }>
                  >;
                };
              };
              shippingAddress: {
                __typename?: 'UserAddressView';
                addressLine1: Maybe<string>;
                addressLine2: Maybe<string>;
                city: Maybe<string>;
                zip: Maybe<string>;
                state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
              };
              payment: Maybe<{
                __typename?: 'OrderPayment';
                credits: number;
                cash: number;
              }>;
            };
          }>
        >;
      };
      pastConnections: {
        __typename?: 'MyOrderViewConnection';
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              isReschedulable: boolean;
              rescheduleCutOffDate: string;
              number: Maybe<string>;
              arrivalDate: Maybe<string>;
              estimatedEarliestArrivalDate: string;
              estimatedLatestArrivalDate: string;
              trackingURL: Maybe<string>;
              paymentStatus: OrderPaymentStatus;
              shipmentStatus: OrderShipmentStatus;
              total: number;
              type: OrderType;
              applicableCredits: number;
              cashTotal: number;
              lineItems: Maybe<
                Array<{
                  __typename?: 'LineItem';
                  quantity: number;
                  metadata: {
                    __typename?: 'LineItemMetadata';
                    pet: Maybe<{
                      __typename?: 'LineItemPetMetadata';
                      id: number;
                      name: string;
                    }>;
                    product: {
                      __typename?: 'LineItemProductMetadata';
                      type: ProductType;
                      recipe: Maybe<{
                        __typename?: 'LineItemRecipeMetadata';
                        displayName: Maybe<string>;
                      }>;
                    };
                  };
                }>
              >;
              metadata: {
                __typename?: 'OrderMetadata';
                pets: Maybe<
                  Array<{ __typename?: 'MyPetView'; id: number; name: string }>
                >;
                stats: {
                  __typename?: 'OrderMetadataStats';
                  frequency: number;
                  plans: Maybe<
                    Array<{
                      __typename?: 'OrderMetadataPlanStats';
                      days: number;
                    }>
                  >;
                };
              };
              shippingAddress: {
                __typename?: 'UserAddressView';
                addressLine1: Maybe<string>;
                addressLine2: Maybe<string>;
                city: Maybe<string>;
                zip: Maybe<string>;
                state: Maybe<{ __typename?: 'State'; abbr: Maybe<string> }>;
              };
              payment: Maybe<{
                __typename?: 'OrderPayment';
                credits: number;
                cash: number;
              }>;
            };
          }>
        >;
      };
    }>;
  };
};

export type PetMealAddOnFragment = {
  __typename?: 'AddOn';
  id: number;
  quantity: number;
  productId: number;
  product: { __typename?: 'Product'; price: number; sku: string };
};

export type PetMealFirstUnlockedOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
};

export type PetMealPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  weight: Maybe<number>;
  birthday: Maybe<string>;
  condition: Maybe<number>;
  breeds: Maybe<Array<{ __typename?: 'Breed'; name: string }>>;
};

export type PetMealPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  calories: Maybe<number>;
  portion: Maybe<number>;
  ratio: number;
  active: boolean;
  recipes: Maybe<
    Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
  >;
};

export type PetMealPlanRecipeFragment = {
  __typename?: 'PlanRecipe';
  id: number;
  ratio: number;
};

export type PetMealRecipeFragment = {
  __typename?: 'Recipe';
  id: number;
  name: string;
  type: RecipeType;
  displayName: string;
};

export type PetMealSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  maxNumberOfAddOns: number;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
  }>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      calories: Maybe<number>;
      portion: Maybe<number>;
      ratio: number;
      active: boolean;
      recipes: Maybe<
        Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
      >;
    }>
  >;
  currentQuote: Maybe<{
    __typename?: 'SubscriptionQuote';
    pets: Maybe<
      Array<{
        __typename?: 'SubscriptionQuotePet';
        id: number;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePetPrice';
          weeklyTotalAmount: Maybe<number>;
          weeklyTaxAmount: Maybe<number>;
        }>;
      }>
    >;
  }>;
  treatAddOns: Maybe<
    Array<{
      __typename?: 'AddOn';
      id: number;
      quantity: number;
      productId: number;
      product: { __typename?: 'Product'; price: number; sku: string };
    }>
  >;
};

export type PetMealSubscriptionQuotePetFragment = {
  __typename?: 'SubscriptionQuotePet';
  id: number;
  price: Maybe<{
    __typename?: 'SubscriptionQuotePetPrice';
    weeklyTotalAmount: Maybe<number>;
    weeklyTaxAmount: Maybe<number>;
  }>;
};

export type TreatsAddOnFragment = {
  __typename?: 'AddOn';
  id: number;
  productId: number;
  quantity: number;
};

export type TreatsSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  addOns: Maybe<
    Array<{
      __typename?: 'AddOn';
      id: number;
      productId: number;
      quantity: number;
    }>
  >;
};

export type TreatsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    addOns: Maybe<
      Array<{
        __typename?: 'AddOn';
        id: number;
        productId: number;
        quantity: number;
      }>
    >;
  };
};

export type InTheBoxUpdateMyTreatsMutationVariables = Exact<{
  input: SetMyAddOnsInput;
}>;

export type InTheBoxUpdateMyTreatsMutation = {
  __typename?: 'RootMutation';
  setMyAddOns: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      addOns: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
        }>
      >;
    };
  };
};

export type PetMealUpdateMyFoodPlanMutationVariables = Exact<{
  input: UpdateMyFoodPlanInput;
}>;

export type PetMealUpdateMyFoodPlanMutation = {
  __typename?: 'RootMutation';
  updateMyFoodPlan: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      maxNumberOfAddOns: number;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          active: boolean;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        pets: Maybe<
          Array<{
            __typename?: 'SubscriptionQuotePet';
            id: number;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePetPrice';
              weeklyTotalAmount: Maybe<number>;
              weeklyTaxAmount: Maybe<number>;
            }>;
          }>
        >;
      }>;
      treatAddOns: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          quantity: number;
          productId: number;
          product: { __typename?: 'Product'; price: number; sku: string };
        }>
      >;
    };
  };
};

export type PetMealQueryVariables = Exact<{ [key: string]: never }>;

export type PetMealQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        weight: Maybe<number>;
        birthday: Maybe<string>;
        condition: Maybe<number>;
        breeds: Maybe<Array<{ __typename?: 'Breed'; name: string }>>;
      }>
    >;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      maxNumberOfAddOns: number;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          calories: Maybe<number>;
          portion: Maybe<number>;
          ratio: number;
          active: boolean;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        pets: Maybe<
          Array<{
            __typename?: 'SubscriptionQuotePet';
            id: number;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePetPrice';
              weeklyTotalAmount: Maybe<number>;
              weeklyTaxAmount: Maybe<number>;
            }>;
          }>
        >;
      }>;
      treatAddOns: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          quantity: number;
          productId: number;
          product: { __typename?: 'Product'; price: number; sku: string };
        }>
      >;
    }>;
  };
  recipes: Array<{
    __typename?: 'Recipe';
    id: number;
    name: string;
    type: RecipeType;
    displayName: string;
  }>;
};

export type AddOptOutUserMutationVariables = Exact<{
  input: AddOptOutUserInput;
}>;

export type AddOptOutUserMutation = {
  __typename?: 'RootMutation';
  addOptOutUser: { __typename?: 'AddOptOutUserResultType'; success: boolean };
};

export type NotificationPreferencesDetailsFragment = {
  __typename?: 'MyUserView';
  communicationPreferences: {
    __typename?: 'CommunicationPreferenceType';
    orderUpdatesEmail: boolean;
    orderUpdatesSms: boolean;
    orderRemindersEmail: boolean;
    orderRemindersSms: boolean;
  };
};

export type ProfileAddressFragment = {
  __typename?: 'UserAddressView';
  id: number;
  addressLine1: Maybe<string>;
  addressLine2: Maybe<string>;
  city: Maybe<string>;
  zip: Maybe<string>;
  state: Maybe<{
    __typename?: 'State';
    id: number;
    abbr: Maybe<string>;
    name: string;
  }>;
  country: Maybe<{ __typename?: 'Country'; id: number; iso: Maybe<string> }>;
};

export type ProfileCreditFragment = {
  __typename?: 'CreditView';
  amount: number;
  expirationDate: Maybe<string>;
  hasCreditHistory: boolean;
};

export type ProfileCreditCardFragment = {
  __typename?: 'CreditCard';
  id: string;
  nameOnCard: Maybe<string>;
  last4Digits: string;
  expirationMonth: number;
  expirationYear: number;
};

export type ProfileFirstUnlockedOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  arrivalDate: Maybe<string>;
};

export type ProfilePetFragment = { __typename?: 'MyPetView'; id: number };

export type ProfileSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
  }>;
};

export type ProfileUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  email: string;
  phone: Maybe<any>;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number }>>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    firstUnlockedOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
    }>;
  }>;
  billingAddress: Maybe<{
    __typename?: 'UserAddressView';
    id: number;
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    city: Maybe<string>;
    zip: Maybe<string>;
    state: Maybe<{
      __typename?: 'State';
      id: number;
      abbr: Maybe<string>;
      name: string;
    }>;
    country: Maybe<{ __typename?: 'Country'; id: number; iso: Maybe<string> }>;
  }>;
  shippingAddress: Maybe<{
    __typename?: 'UserAddressView';
    id: number;
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    city: Maybe<string>;
    zip: Maybe<string>;
    state: Maybe<{
      __typename?: 'State';
      id: number;
      abbr: Maybe<string>;
      name: string;
    }>;
    country: Maybe<{ __typename?: 'Country'; id: number; iso: Maybe<string> }>;
  }>;
  credit: {
    __typename?: 'CreditView';
    amount: number;
    expirationDate: Maybe<string>;
    hasCreditHistory: boolean;
  };
  creditCards: Maybe<
    Array<{
      __typename?: 'CreditCard';
      id: string;
      nameOnCard: Maybe<string>;
      last4Digits: string;
      expirationMonth: number;
      expirationYear: number;
    }>
  >;
  communicationPreferences: {
    __typename?: 'CommunicationPreferenceType';
    orderUpdatesEmail: boolean;
    orderUpdatesSms: boolean;
    orderRemindersEmail: boolean;
    orderRemindersSms: boolean;
  };
};

export type ProfileUpdateUserMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type ProfileUpdateUserMutation = {
  __typename?: 'RootMutation';
  updateMe: {
    __typename?: 'MyUserView';
    id: number;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    email: string;
    phone: Maybe<any>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
    }>;
    billingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
      addressLine2: Maybe<string>;
      city: Maybe<string>;
      zip: Maybe<string>;
      state: Maybe<{
        __typename?: 'State';
        id: number;
        abbr: Maybe<string>;
        name: string;
      }>;
      country: Maybe<{
        __typename?: 'Country';
        id: number;
        iso: Maybe<string>;
      }>;
    }>;
    shippingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
      addressLine2: Maybe<string>;
      city: Maybe<string>;
      zip: Maybe<string>;
      state: Maybe<{
        __typename?: 'State';
        id: number;
        abbr: Maybe<string>;
        name: string;
      }>;
      country: Maybe<{
        __typename?: 'Country';
        id: number;
        iso: Maybe<string>;
      }>;
    }>;
    credit: {
      __typename?: 'CreditView';
      amount: number;
      expirationDate: Maybe<string>;
      hasCreditHistory: boolean;
    };
    creditCards: Maybe<
      Array<{
        __typename?: 'CreditCard';
        id: string;
        nameOnCard: Maybe<string>;
        last4Digits: string;
        expirationMonth: number;
        expirationYear: number;
      }>
    >;
    communicationPreferences: {
      __typename?: 'CommunicationPreferenceType';
      orderUpdatesEmail: boolean;
      orderUpdatesSms: boolean;
      orderRemindersEmail: boolean;
      orderRemindersSms: boolean;
    };
  };
};

export type ProfileUserQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileUserQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    email: string;
    phone: Maybe<any>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number }>>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
    }>;
    billingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
      addressLine2: Maybe<string>;
      city: Maybe<string>;
      zip: Maybe<string>;
      state: Maybe<{
        __typename?: 'State';
        id: number;
        abbr: Maybe<string>;
        name: string;
      }>;
      country: Maybe<{
        __typename?: 'Country';
        id: number;
        iso: Maybe<string>;
      }>;
    }>;
    shippingAddress: Maybe<{
      __typename?: 'UserAddressView';
      id: number;
      addressLine1: Maybe<string>;
      addressLine2: Maybe<string>;
      city: Maybe<string>;
      zip: Maybe<string>;
      state: Maybe<{
        __typename?: 'State';
        id: number;
        abbr: Maybe<string>;
        name: string;
      }>;
      country: Maybe<{
        __typename?: 'Country';
        id: number;
        iso: Maybe<string>;
      }>;
    }>;
    credit: {
      __typename?: 'CreditView';
      amount: number;
      expirationDate: Maybe<string>;
      hasCreditHistory: boolean;
    };
    creditCards: Maybe<
      Array<{
        __typename?: 'CreditCard';
        id: string;
        nameOnCard: Maybe<string>;
        last4Digits: string;
        expirationMonth: number;
        expirationYear: number;
      }>
    >;
    communicationPreferences: {
      __typename?: 'CommunicationPreferenceType';
      orderUpdatesEmail: boolean;
      orderUpdatesSms: boolean;
      orderRemindersEmail: boolean;
      orderRemindersSms: boolean;
    };
  };
};

export type RescheduleSubscriptionSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  nextDate: Maybe<string>;
  recommendedNextDate: Maybe<string>;
};

export type RescheduleSubscriptionUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  availableNextDates: Array<string>;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    nextDate: Maybe<string>;
    recommendedNextDate: Maybe<string>;
  }>;
};

export type RescheduleNextOrderMutationVariables = Exact<{
  input: RescheduleNextOrderInput;
}>;

export type RescheduleNextOrderMutation = {
  __typename?: 'RootMutation';
  rescheduleNextOrder: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      nextDate: Maybe<string>;
      recommendedNextDate: Maybe<string>;
    }>;
  };
};

export type RescheduleSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RescheduleSubscriptionQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    availableNextDates: Array<string>;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      nextDate: Maybe<string>;
      recommendedNextDate: Maybe<string>;
    }>;
  };
};

export type UpdateDiySignupSubscriptionSubscriptionsFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  frequency: Maybe<number>;
  subscriptionProducts: Maybe<
    Array<
      Maybe<{
        __typename?: 'SubscriptionProduct';
        id: number;
        quantity: number;
        productId: number;
      }>
    >
  >;
};

export type UpdateDiySignupSubscriptionMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type UpdateDiySignupSubscriptionMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      frequency: Maybe<number>;
      subscriptionProducts: Maybe<
        Array<
          Maybe<{
            __typename?: 'SubscriptionProduct';
            id: number;
            quantity: number;
            productId: number;
          }>
        >
      >;
    }>;
  };
};

export type CheckoutMutationVariables = Exact<{
  input: CheckoutInput;
}>;

export type CheckoutMutation = {
  __typename?: 'RootMutation';
  checkout: {
    __typename?: 'MyUserView';
    id: number;
    firstName: Maybe<string>;
    status: UserStatus;
    frequenciesAtCheckoutFrequency: Maybe<number>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      startDate: Maybe<string>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              discountTotal: number;
              total: number;
              taxTotal: number;
              metadata: {
                __typename?: 'OrderMetadata';
                stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
              };
            };
          }>
        >;
      };
    };
  };
};

export type ProjectedOrdersProjectedOrderFragment = {
  __typename?: 'ProjectedOrderView';
  id: number;
  discountTotal: number;
  total: number;
  taxTotal: number;
  shipDate: string;
  metadata: {
    __typename?: 'OrderMetadata';
    stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
  };
};

export type ProjectedOrdersQueryVariables = Exact<{
  input: ProjectedOrdersInput;
}>;

export type ProjectedOrdersQuery = {
  __typename?: 'RootQuery';
  projectedOrders: Maybe<
    Array<
      Maybe<{
        __typename?: 'ProjectedOrderView';
        id: number;
        discountTotal: number;
        total: number;
        taxTotal: number;
        shipDate: string;
        metadata: {
          __typename?: 'OrderMetadata';
          stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
        };
      }>
    >
  >;
};

export type SuccessOrdersFragment = {
  __typename?: 'MyOrderView';
  id: number;
  discountTotal: number;
  total: number;
  taxTotal: number;
  metadata: {
    __typename?: 'OrderMetadata';
    stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
  };
};

export type SuccessSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  startDate: Maybe<string>;
  ordersConnection: {
    __typename?: 'MyOrderViewConnection';
    nextOrderToBeProcessedId: Maybe<number>;
    edges: Maybe<
      Array<{
        __typename?: 'MyOrderViewEdge';
        node: {
          __typename?: 'MyOrderView';
          id: number;
          discountTotal: number;
          total: number;
          taxTotal: number;
          metadata: {
            __typename?: 'OrderMetadata';
            stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
          };
        };
      }>
    >;
  };
};

export type SuccessUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  firstName: Maybe<string>;
  status: UserStatus;
  frequenciesAtCheckoutFrequency: Maybe<number>;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    startDate: Maybe<string>;
    ordersConnection: {
      __typename?: 'MyOrderViewConnection';
      nextOrderToBeProcessedId: Maybe<number>;
      edges: Maybe<
        Array<{
          __typename?: 'MyOrderViewEdge';
          node: {
            __typename?: 'MyOrderView';
            id: number;
            discountTotal: number;
            total: number;
            taxTotal: number;
            metadata: {
              __typename?: 'OrderMetadata';
              stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
            };
          };
        }>
      >;
    };
  };
};

export type SuccessUserQueryVariables = Exact<{ [key: string]: never }>;

export type SuccessUserQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    firstName: Maybe<string>;
    status: UserStatus;
    frequenciesAtCheckoutFrequency: Maybe<number>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      startDate: Maybe<string>;
      ordersConnection: {
        __typename?: 'MyOrderViewConnection';
        nextOrderToBeProcessedId: Maybe<number>;
        edges: Maybe<
          Array<{
            __typename?: 'MyOrderViewEdge';
            node: {
              __typename?: 'MyOrderView';
              id: number;
              discountTotal: number;
              total: number;
              taxTotal: number;
              metadata: {
                __typename?: 'OrderMetadata';
                stats: { __typename?: 'OrderMetadataStats'; avgDays: number };
              };
            };
          }>
        >;
      };
    };
  };
};

export type TreatDetailsAddOnFragment = {
  __typename?: 'AddOn';
  id: number;
  productId: number;
  quantity: number;
  product: { __typename?: 'Product'; sku: string };
};

export type TreatDetailsOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
};

export type TreatDetailsProductFragment = {
  __typename?: 'Product';
  id: number;
  sku: string;
  price: number;
};

export type TreatDetailsSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  status: SubscriptionStatus;
  maxNumberOfAddOns: number;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
  }>;
};

export type TreatDetailsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    type: Maybe<SubscriptionType>;
    status: SubscriptionStatus;
    maxNumberOfAddOns: number;
    firstUnlockedOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
    }>;
  }>;
};

export type TreatDetailsUpdateMyTreatsMutationVariables = Exact<{
  input: SetMyAddOnsInput;
  productId: Scalars['Int'];
}>;

export type TreatDetailsUpdateMyTreatsMutation = {
  __typename?: 'RootMutation';
  setMyAddOns: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      maxNumberOfAddOns: number;
      requestedTreat: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
      subscribedTreats: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
    }>;
  };
};

export type TreatDetailsQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;

export type TreatDetailsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      maxNumberOfAddOns: number;
      requestedTreat: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
      subscribedTreats: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
      }>;
    }>;
  };
  addOnsProducts: Array<{
    __typename?: 'Product';
    id: number;
    sku: string;
    price: number;
  }>;
};

export type TreatsLandingAddOnFragment = {
  __typename?: 'AddOn';
  id: number;
  productId: number;
  quantity: number;
  product: { __typename?: 'Product'; sku: string };
};

export type TreatsLandingProductFragment = {
  __typename?: 'Product';
  id: number;
  sku: string;
  price: number;
};

export type TreatsLandingSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  status: SubscriptionStatus;
  maxNumberOfAddOns: number;
  addOns: Maybe<
    Array<{
      __typename?: 'AddOn';
      id: number;
      productId: number;
      quantity: number;
      product: { __typename?: 'Product'; sku: string };
    }>
  >;
};

export type TreatsLandingUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    type: Maybe<SubscriptionType>;
    status: SubscriptionStatus;
    maxNumberOfAddOns: number;
    addOns: Maybe<
      Array<{
        __typename?: 'AddOn';
        id: number;
        productId: number;
        quantity: number;
        product: { __typename?: 'Product'; sku: string };
      }>
    >;
  }>;
};

export type TreatsLandingUpdateMutationVariables = Exact<{
  input: SetMyAddOnsInput;
}>;

export type TreatsLandingUpdateMutation = {
  __typename?: 'RootMutation';
  setMyAddOns: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      maxNumberOfAddOns: number;
      addOns: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
    }>;
  };
};

export type TreatsLandingQueryVariables = Exact<{ [key: string]: never }>;

export type TreatsLandingQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      maxNumberOfAddOns: number;
      addOns: Maybe<
        Array<{
          __typename?: 'AddOn';
          id: number;
          productId: number;
          quantity: number;
          product: { __typename?: 'Product'; sku: string };
        }>
      >;
    }>;
  };
  addOnsProducts: Array<{
    __typename?: 'Product';
    id: number;
    sku: string;
    price: number;
  }>;
};

export type CancelSubscriptionFoodBrandFragment = {
  __typename?: 'FoodBrand';
  id: number;
  name: string;
};

export type CancelSubscriptionOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  isReschedulable: boolean;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  arrivalDate: Maybe<string>;
};

export type CancelSubscriptionOrderLineItemFragment = {
  __typename?: 'LineItem';
  quantity: number;
  product: { __typename?: 'Product'; type: ProductType };
};

export type CancelSubscriptionPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
};

export type CancelSubscriptionSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  type: Maybe<SubscriptionType>;
  status: SubscriptionStatus;
  frequency: Maybe<number>;
  currentQuote: Maybe<{
    __typename?: 'SubscriptionQuote';
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
      }>;
    }>;
  }>;
  nextOrderToBeDelivered: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    isReschedulable: boolean;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
  }>;
  mostRecentlyDeliveredOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    isReschedulable: boolean;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
    lineItems: Maybe<
      Array<{
        __typename?: 'LineItem';
        quantity: number;
        product: { __typename?: 'Product'; type: ProductType };
      }>
    >;
  }>;
};

export type CancelSubscriptionUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    type: Maybe<SubscriptionType>;
    status: SubscriptionStatus;
    frequency: Maybe<number>;
    currentQuote: Maybe<{
      __typename?: 'SubscriptionQuote';
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
    }>;
    nextOrderToBeDelivered: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      isReschedulable: boolean;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
    }>;
    mostRecentlyDeliveredOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      isReschedulable: boolean;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
      lineItems: Maybe<
        Array<{
          __typename?: 'LineItem';
          quantity: number;
          product: { __typename?: 'Product'; type: ProductType };
        }>
      >;
    }>;
  }>;
  pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
};

export type CancelSubscriptionSuspendSubscriptionMutationVariables = Exact<{
  input: SuspendMySubscriptionInput;
}>;

export type CancelSubscriptionSuspendSubscriptionMutation = {
  __typename?: 'RootMutation';
  suspendMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      frequency: Maybe<number>;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
      mostRecentlyDeliveredOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
        lineItems: Maybe<
          Array<{
            __typename?: 'LineItem';
            quantity: number;
            product: { __typename?: 'Product'; type: ProductType };
          }>
        >;
      }>;
    }>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
};

export type CancelSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type CancelSubscriptionQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      type: Maybe<SubscriptionType>;
      status: SubscriptionStatus;
      frequency: Maybe<number>;
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
      }>;
      nextOrderToBeDelivered: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
      mostRecentlyDeliveredOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        isReschedulable: boolean;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
        lineItems: Maybe<
          Array<{
            __typename?: 'LineItem';
            quantity: number;
            product: { __typename?: 'Product'; type: ProductType };
          }>
        >;
      }>;
    }>;
    pets: Maybe<Array<{ __typename?: 'MyPetView'; id: number; name: string }>>;
  };
  foodBrands: Array<{ __typename?: 'FoodBrand'; id: number; name: string }>;
};

export type ChangeCaloriesFoodPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  subscriptionId: number;
  ratio: number;
  portion: Maybe<number>;
  calories: Maybe<number>;
  active: boolean;
  availableMixingRatios: Maybe<Array<number>>;
  recipes: Maybe<
    Array<{
      __typename?: 'PlanRecipe';
      id: number;
      name: string;
      ratio: number;
    }>
  >;
};

export type ChangeCaloriesPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  suggestedCalories: Maybe<number>;
};

export type ChangeCaloriesPlanRecipeFragment = {
  __typename?: 'PlanRecipe';
  id: number;
  name: string;
  ratio: number;
};

export type ChangeCaloriesProductCombinationFragment = {
  __typename?: 'ProductCombination';
  calories: number;
  portion: number;
  size: number;
};

export type ChangeCaloriesSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  userId: number;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      subscriptionId: number;
      ratio: number;
      portion: Maybe<number>;
      calories: Maybe<number>;
      active: boolean;
      availableMixingRatios: Maybe<Array<number>>;
      recipes: Maybe<
        Array<{
          __typename?: 'PlanRecipe';
          id: number;
          name: string;
          ratio: number;
        }>
      >;
    }>
  >;
};

export type ChangeCaloriesUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      name: string;
      suggestedCalories: Maybe<number>;
    }>
  >;
  subscription: {
    __typename?: 'MySubscriptionView';
    id: number;
    userId: number;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        subscriptionId: number;
        ratio: number;
        portion: Maybe<number>;
        calories: Maybe<number>;
        active: boolean;
        availableMixingRatios: Maybe<Array<number>>;
        recipes: Maybe<
          Array<{
            __typename?: 'PlanRecipe';
            id: number;
            name: string;
            ratio: number;
          }>
        >;
      }>
    >;
  };
};

export type ChangeCaloriesDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ChangeCaloriesDetailsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        suggestedCalories: Maybe<number>;
      }>
    >;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      userId: number;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          subscriptionId: number;
          ratio: number;
          portion: Maybe<number>;
          calories: Maybe<number>;
          active: boolean;
          availableMixingRatios: Maybe<Array<number>>;
          recipes: Maybe<
            Array<{
              __typename?: 'PlanRecipe';
              id: number;
              name: string;
              ratio: number;
            }>
          >;
        }>
      >;
    };
  };
  productCombinations: Array<{
    __typename?: 'ProductCombination';
    calories: number;
    portion: number;
    size: number;
  }>;
};

export type ChangeFrequencySubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  frequency: Maybe<number>;
  suggestedFrequencies: {
    __typename?: 'SuggestedFrequenciesType';
    min: Maybe<{
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            totalAmount: Maybe<number>;
          }>;
        }>;
      };
    }>;
    reg: {
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            totalAmount: Maybe<number>;
          }>;
        }>;
      };
    };
    max: Maybe<{
      __typename?: 'SuggestedFrequenciesInfoType';
      frequency: number;
      quote: {
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            totalAmount: Maybe<number>;
          }>;
        }>;
      };
    }>;
  };
  currentQuote: Maybe<{
    __typename?: 'SubscriptionQuote';
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
      }>;
    }>;
    price: Maybe<{
      __typename?: 'SubscriptionQuotePricing';
      regular: Maybe<{
        __typename?: 'SubscriptionQuotePrice';
        totalAmount: Maybe<number>;
      }>;
    }>;
  }>;
};

export type ChangeFrequencySubscriptionQuoteFragment = {
  __typename?: 'SubscriptionQuote';
  shipment: Maybe<{
    __typename?: 'SubscriptionQuoteShipmentDetails';
    regular: Maybe<{
      __typename?: 'SubscriptionQuoteShipment';
      pricePerWeek: Maybe<number>;
    }>;
  }>;
  price: Maybe<{
    __typename?: 'SubscriptionQuotePricing';
    regular: Maybe<{
      __typename?: 'SubscriptionQuotePrice';
      totalAmount: Maybe<number>;
    }>;
  }>;
};

export type ChangeFrequencySuggestedFrequenciesFragment = {
  __typename?: 'SuggestedFrequenciesType';
  min: Maybe<{
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          totalAmount: Maybe<number>;
        }>;
      }>;
    };
  }>;
  reg: {
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          totalAmount: Maybe<number>;
        }>;
      }>;
    };
  };
  max: Maybe<{
    __typename?: 'SuggestedFrequenciesInfoType';
    frequency: number;
    quote: {
      __typename?: 'SubscriptionQuote';
      shipment: Maybe<{
        __typename?: 'SubscriptionQuoteShipmentDetails';
        regular: Maybe<{
          __typename?: 'SubscriptionQuoteShipment';
          pricePerWeek: Maybe<number>;
        }>;
      }>;
      price: Maybe<{
        __typename?: 'SubscriptionQuotePricing';
        regular: Maybe<{
          __typename?: 'SubscriptionQuotePrice';
          totalAmount: Maybe<number>;
        }>;
      }>;
    };
  }>;
};

export type ChangeFrequencySuggestedFrequencyInfoFragment = {
  __typename?: 'SuggestedFrequenciesInfoType';
  frequency: number;
  quote: {
    __typename?: 'SubscriptionQuote';
    shipment: Maybe<{
      __typename?: 'SubscriptionQuoteShipmentDetails';
      regular: Maybe<{
        __typename?: 'SubscriptionQuoteShipment';
        pricePerWeek: Maybe<number>;
      }>;
    }>;
    price: Maybe<{
      __typename?: 'SubscriptionQuotePricing';
      regular: Maybe<{
        __typename?: 'SubscriptionQuotePrice';
        totalAmount: Maybe<number>;
      }>;
    }>;
  };
};

export type ChangeFrequencyUpdateSubscriptionMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type ChangeFrequencyUpdateSubscriptionMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            totalAmount: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type ChangeFrequencyQueryVariables = Exact<{ [key: string]: never }>;

export type ChangeFrequencyQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      frequency: Maybe<number>;
      suggestedFrequencies: {
        __typename?: 'SuggestedFrequenciesType';
        min: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        }>;
        reg: {
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        };
        max: Maybe<{
          __typename?: 'SuggestedFrequenciesInfoType';
          frequency: number;
          quote: {
            __typename?: 'SubscriptionQuote';
            shipment: Maybe<{
              __typename?: 'SubscriptionQuoteShipmentDetails';
              regular: Maybe<{
                __typename?: 'SubscriptionQuoteShipment';
                pricePerWeek: Maybe<number>;
              }>;
            }>;
            price: Maybe<{
              __typename?: 'SubscriptionQuotePricing';
              regular: Maybe<{
                __typename?: 'SubscriptionQuotePrice';
                totalAmount: Maybe<number>;
              }>;
            }>;
          };
        }>;
      };
      currentQuote: Maybe<{
        __typename?: 'SubscriptionQuote';
        shipment: Maybe<{
          __typename?: 'SubscriptionQuoteShipmentDetails';
          regular: Maybe<{
            __typename?: 'SubscriptionQuoteShipment';
            pricePerWeek: Maybe<number>;
          }>;
        }>;
        price: Maybe<{
          __typename?: 'SubscriptionQuotePricing';
          regular: Maybe<{
            __typename?: 'SubscriptionQuotePrice';
            totalAmount: Maybe<number>;
          }>;
        }>;
      }>;
    };
  };
};

export type ReactivateSubscriptionOrderFragment = {
  __typename?: 'MyOrderView';
  id: number;
  estimatedEarliestArrivalDate: string;
  estimatedLatestArrivalDate: string;
  arrivalDate: Maybe<string>;
};

export type ReactivateSubscriptionSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  availableNextDates: Array<string>;
  firstUnlockedOrder: Maybe<{
    __typename?: 'MyOrderView';
    id: number;
    estimatedEarliestArrivalDate: string;
    estimatedLatestArrivalDate: string;
    arrivalDate: Maybe<string>;
  }>;
};

export type ReactivateSubscriptionUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    availableNextDates: Array<string>;
    firstUnlockedOrder: Maybe<{
      __typename?: 'MyOrderView';
      id: number;
      estimatedEarliestArrivalDate: string;
      estimatedLatestArrivalDate: string;
      arrivalDate: Maybe<string>;
    }>;
  }>;
};

export type ReactivateSubscriptionUpdateMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type ReactivateSubscriptionUpdateMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      availableNextDates: Array<string>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
    }>;
  };
};

export type ReactivateSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReactivateSubscriptionQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      availableNextDates: Array<string>;
      firstUnlockedOrder: Maybe<{
        __typename?: 'MyOrderView';
        id: number;
        estimatedEarliestArrivalDate: string;
        estimatedLatestArrivalDate: string;
        arrivalDate: Maybe<string>;
      }>;
    }>;
  };
};

export type SuggestChangeCaloriesPetFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  suggestedCalories: Maybe<number>;
  isRapidlyGrowing: boolean;
  weight: Maybe<number>;
  condition: Maybe<number>;
  treatsQuantity: Maybe<number>;
  neutered: Maybe<boolean>;
  birthday: Maybe<string>;
  activity: Maybe<number>;
  breeds: Maybe<Array<{ __typename?: 'Breed'; id: number }>>;
};

export type SuggestChangeCaloriesPlanFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  calories: Maybe<number>;
  portion: Maybe<number>;
  dailyCalories: number;
};

export type SuggestChangeCaloriesProductCombinationFragment = {
  __typename?: 'ProductCombination';
  calories: number;
  portion: number;
  size: number;
};

export type SuggestChangeCaloriesSubscriptionFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      calories: Maybe<number>;
      portion: Maybe<number>;
      dailyCalories: number;
    }>
  >;
};

export type SuggestChangeCaloriesUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      name: string;
      suggestedCalories: Maybe<number>;
      isRapidlyGrowing: boolean;
      weight: Maybe<number>;
      condition: Maybe<number>;
      treatsQuantity: Maybe<number>;
      neutered: Maybe<boolean>;
      birthday: Maybe<string>;
      activity: Maybe<number>;
      breeds: Maybe<Array<{ __typename?: 'Breed'; id: number }>>;
    }>
  >;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        calories: Maybe<number>;
        portion: Maybe<number>;
        dailyCalories: number;
      }>
    >;
  }>;
};

export type SuggestChangeCaloriesSubmitConditionMutationVariables = Exact<{
  petId: Scalars['Int'];
  bodyCondition: Scalars['Int'];
}>;

export type SuggestChangeCaloriesSubmitConditionMutation = {
  __typename?: 'RootMutation';
  updateMyPet: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        suggestedCalories: Maybe<number>;
        isRapidlyGrowing: boolean;
        weight: Maybe<number>;
        condition: Maybe<number>;
        treatsQuantity: Maybe<number>;
        neutered: Maybe<boolean>;
        birthday: Maybe<string>;
        activity: Maybe<number>;
        breeds: Maybe<Array<{ __typename?: 'Breed'; id: number }>>;
      }>
    >;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          calories: Maybe<number>;
          portion: Maybe<number>;
          dailyCalories: number;
        }>
      >;
    }>;
  };
};

export type SuggestChangeCaloriesQueryVariables = Exact<{
  petId: Scalars['Int'];
}>;

export type SuggestChangeCaloriesQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        suggestedCalories: Maybe<number>;
        isRapidlyGrowing: boolean;
        weight: Maybe<number>;
        condition: Maybe<number>;
        treatsQuantity: Maybe<number>;
        neutered: Maybe<boolean>;
        birthday: Maybe<string>;
        activity: Maybe<number>;
        breeds: Maybe<Array<{ __typename?: 'Breed'; id: number }>>;
      }>
    >;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          calories: Maybe<number>;
          portion: Maybe<number>;
          dailyCalories: number;
        }>
      >;
    }>;
  };
  bodyConditions: Array<{
    __typename?: 'BodyCondition';
    id: number;
    name: string;
    description: string;
  }>;
  productCombinations: Array<{
    __typename?: 'ProductCombination';
    calories: number;
    portion: number;
    size: number;
  }>;
};

export type ToggleSubscriptionsPetsFragment = {
  __typename?: 'MyPetView';
  id: number;
  name: string;
  noContact: boolean;
};

export type ToggleSubscriptionsSubscriptionsFragment = {
  __typename?: 'MySubscriptionView';
  id: number;
  status: SubscriptionStatus;
  type: Maybe<SubscriptionType>;
  foodPlans: Maybe<
    Array<{
      __typename?: 'MyPlan';
      id: number;
      petId: number;
      subscriptionId: number;
      active: boolean;
      type: PlanType;
      ratio: number;
      portion: Maybe<number>;
      calories: Maybe<number>;
      recipes: Maybe<
        Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
      >;
    }>
  >;
};

export type ToggleSubscriptionsSubscriptionsFoodPlansFragment = {
  __typename?: 'MyPlan';
  id: number;
  petId: number;
  subscriptionId: number;
  active: boolean;
  type: PlanType;
  ratio: number;
  portion: Maybe<number>;
  calories: Maybe<number>;
  recipes: Maybe<
    Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
  >;
};

export type ToggleSubscriptionsUserFragment = {
  __typename?: 'MyUserView';
  id: number;
  subscriptions: Array<{
    __typename?: 'MySubscriptionView';
    id: number;
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
    foodPlans: Maybe<
      Array<{
        __typename?: 'MyPlan';
        id: number;
        petId: number;
        subscriptionId: number;
        active: boolean;
        type: PlanType;
        ratio: number;
        portion: Maybe<number>;
        calories: Maybe<number>;
        recipes: Maybe<
          Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
        >;
      }>
    >;
  }>;
  pets: Maybe<
    Array<{
      __typename?: 'MyPetView';
      id: number;
      name: string;
      noContact: boolean;
    }>
  >;
};

export type ToggleSubscriptionsSubmitMutationVariables = Exact<{
  input: UpdateMySubscriptionInput;
}>;

export type ToggleSubscriptionsSubmitMutation = {
  __typename?: 'RootMutation';
  updateMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          subscriptionId: number;
          active: boolean;
          type: PlanType;
          ratio: number;
          portion: Maybe<number>;
          calories: Maybe<number>;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
    }>;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        noContact: boolean;
      }>
    >;
  };
};

export type ToggleSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ToggleSubscriptionsQuery = {
  __typename?: 'RootQuery';
  me: {
    __typename?: 'MyUserView';
    id: number;
    subscriptions: Array<{
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
      type: Maybe<SubscriptionType>;
      foodPlans: Maybe<
        Array<{
          __typename?: 'MyPlan';
          id: number;
          petId: number;
          subscriptionId: number;
          active: boolean;
          type: PlanType;
          ratio: number;
          portion: Maybe<number>;
          calories: Maybe<number>;
          recipes: Maybe<
            Array<{ __typename?: 'PlanRecipe'; id: number; ratio: number }>
          >;
        }>
      >;
    }>;
    pets: Maybe<
      Array<{
        __typename?: 'MyPetView';
        id: number;
        name: string;
        noContact: boolean;
      }>
    >;
  };
};

export type CancelMySubscriptionMutationVariables = Exact<{
  input: SuspendMySubscriptionInput;
}>;

export type CancelMySubscriptionMutation = {
  __typename?: 'RootMutation';
  suspendMySubscription: {
    __typename?: 'MyUserView';
    id: number;
    subscription: {
      __typename?: 'MySubscriptionView';
      id: number;
      status: SubscriptionStatus;
    };
  };
};
