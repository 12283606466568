import { useEffect, useState, LinkHTMLAttributes } from 'react';
import { Helmet } from 'react-helmet-async';

import { fastdom } from '../../../utils/fastdom';

/**
 * Load a critical resource in a non-blocking way.
 * https://web.dev/preload-critical-assets/
 *
 * @example
 *
 * // load css async
 * <PreloadLink as="style" href="/some.css" rel="stylesheet" type="text/css" />
 *
 * // preload image asset
 * <PreloadLink as="image" href="/image.jpg" type="" />
 *
 * // preload font
 * <PreloadLink as="font" href="/resource.woff" type="font/woff" crossOrigin="anonymous" />
 *
 * // preload javascript
 * <PreloadLink as="script" href="/my-cool-script.js" type="text/javascript" />
 *
 */

export enum PreloadLinkAs {
  Style = 'style',
  Script = 'script',
  Video = 'video',
  Audio = 'audio',
  Font = 'font',
  Image = 'image',
}

export enum PreloadLinkType {
  Css = 'text/css',
  Jpg = 'image/jpeg',
  Png = 'image/png',
  Gif = 'image/gif',
  Webp = 'image/webp',
  Svg = 'image/svg+xml',
  Js = 'text/javascript',
  Woff = 'font/woff',
  Woff2 = 'font/woff2',
}

export type PreloadLinkBaseProps = LinkHTMLAttributes<HTMLLinkElement>;

export interface PreloadLinkWithHrefProps extends PreloadLinkBaseProps {
  href: string;
  as: PreloadLinkAs;
  type: PreloadLinkType;
}

export interface PreloadLinkPropsWithSrcSet extends PreloadLinkBaseProps {
  imageSrcSet: string;
  media: string;
}

export type PreloadLinkProps =
  | PreloadLinkWithHrefProps
  | PreloadLinkPropsWithSrcSet;

function isPreloadLinkWithHref(
  props: PreloadLinkProps
): props is PreloadLinkWithHrefProps {
  return (props as PreloadLinkWithHrefProps).href !== undefined;
}

export function PreloadLink(props: PreloadLinkProps) {
  if (isPreloadLinkWithHref(props)) {
    return <PreloadLinkWithHref_ {...props} />;
  }

  return <PreloadLinkWithSrcSet_ {...props} />;
}

function PreloadLinkWithHref_({
  href,
  rel,
  as,
  type,
  ...props
}: PreloadLinkWithHrefProps) {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => fastdom.mutate(() => setLoaded(true), [loaded]));

  return (
    <Helmet>
      <link rel="preload" as={as} href={href} type={type} {...props} />
      {loaded && rel && <link rel={rel} href={href} type={type} {...props} />}
    </Helmet>
  );
}

function PreloadLinkWithSrcSet_({
  imageSrcSet,
  media,
  ...props
}: PreloadLinkPropsWithSrcSet) {
  return (
    <Helmet>
      <link
        rel="preload"
        as={PreloadLinkAs.Image}
        imageSrcSet={imageSrcSet}
        media={media}
        {...props}
      />
    </Helmet>
  );
}
