import type { ReviewData } from '../data';

export function generateStructuredReviewData(reviews: ReviewData[]): string {
  const structuredData = reviews.map(review => ({
    '@context': 'https://schema.org/',
    '@type': 'Review',
    reviewBody: review.comment,
    author: {
      '@type': 'Person',
      name: review.name,
    },
    itemReviewed: {
      '@type': 'Product',
      name: "The Farmer's Dog Fresh Dog Food",
      description:
        "Fresh food has a transformative impact on dogs' health — improving everything from digestion and allergies, to energy levels and life span.",
      image: 'https://www.thefarmersdog.com/static/logo_1.png',
      brand: {
        '@type': 'Brand',
        name: "The Farmer's Dog",
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: 5,
        ratingValue: 5,
        reviewCount: reviews.length,
      },
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Google',
    },
    url: review.href,
  }));

  return JSON.stringify(structuredData, null);
}
