import { useMutation } from '@apollo/client';

import { UpdateFoodPlansRecipesInput } from '../../../graphql/types.cgs';
import updateFoodPlansRecipeMutation from './updateFoodPlansRecipes.cgs.graphql';

interface UseUpdateFoodPlansRecipeArgs {
  onCompleted: () => void;
  onError: (error: Error) => void;
}

export const useUpdateFoodPlansRecipe = (
  args: UseUpdateFoodPlansRecipeArgs
) => {
  const [submit, state] = useMutation(updateFoodPlansRecipeMutation, {
    context: {
      endpoint: 'cgs',
    },
    onCompleted: args.onCompleted,
    onError: args.onError,
  });

  // NOTE: evaluate if some formatting should be done here
  const onSubmit = (variables: UpdateFoodPlansRecipesInput) => {
    void submit({ variables: { input: { ...variables } } });
  };

  return {
    onSubmit,
    isLoading: state.loading,
  };
};
