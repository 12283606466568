import { useEffect, useState, ChangeEvent } from 'react';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import { Modal } from '@farmersdog/corgi';
import {
  Text,
  Input,
  Button,
  Divider,
  Accordion,
  Checkbox,
} from '@farmersdog/corgi-x';
import { setDevToolsSkipCalculatingScreen } from '@farmersdog/lead-browser-storage/src/cookie/cookie';

import { CompiledBlueprint, NodeRegistry } from '../../blueprint/types';
import { useGlobalLightbox, LightboxId, useLeadState } from '../../hooks';
import {
  Experiments,
  UseForm,
  UseFormNavigateReturn,
  UseSchemaReturn,
} from '../../types';

import { useHotKeys } from './hooks';
import styles from './TosaDevTools.module.css';
import { autofillSignup, goToFinalMeStep } from './utils';

export interface TosaDevToolsArgs {
  formMethods: UseForm;
  schema: CompiledBlueprint;
  registry: NodeRegistry;
  formSteps: UseSchemaReturn['formSteps'];
  setFormSteps: UseSchemaReturn['setFormSteps'];
  experiments: Experiments;
  goToStep: UseFormNavigateReturn['goToStep'];
}

export function TosaDevTools({
  formMethods,
  schema,
  registry,
  formSteps,
  setFormSteps,
  experiments,
  goToStep,
}: TosaDevToolsArgs) {
  const lightboxControl = useGlobalLightbox({
    id: LightboxId.TosaDevTools,
  });
  const { hasBeenOpened } = useHotKeys();

  const [leadState, setLeadState] = useState<string | undefined>(undefined);
  const [userInput, setUserInput] = useState<number>(2);
  const [shouldAdvanceToLastMeStep, setShouldAdvanceToLastMeStep] =
    useState<boolean>(true);
  const [shouldSkipCalculatingScreen, setShouldSkipCalculatingScreen] =
    useState<boolean>(true);
  const [isSignupRecoveryLinkCopied, setIsSignupRecoveryLinkCopied] =
    useState<boolean>(false);
  const [isEmailCopied, setIsEmailCopied] = useState<boolean>(false);
  const leadEmail = formMethods.getValues('email');

  useEffect(() => {
    // only set the cookie if the modal has intentionally been opened
    if (hasBeenOpened) {
      setDevToolsSkipCalculatingScreen(shouldSkipCalculatingScreen);
    }
  }, [hasBeenOpened, shouldSkipCalculatingScreen]);

  useEffect(() => {
    const buttonDisableTimeout = 3_000;
    if (isSignupRecoveryLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsSignupRecoveryLinkCopied(false);
      }, buttonDisableTimeout);
      return () => clearTimeout(timeoutId);
    }
    if (isEmailCopied) {
      const timeoutId = setTimeout(() => {
        setIsEmailCopied(false);
      }, buttonDisableTimeout);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [isSignupRecoveryLinkCopied, isEmailCopied]);

  const { getCachedLead } = useLeadState();

  const handleSinglePetAutofill = async () => {
    await autofillSignup({
      formMethods,
      schema,
      registry,
      setFormSteps,
      experiments,
      numPets: 1,
    });
    lightboxControl.close();
    if (shouldAdvanceToLastMeStep) goToFinalMeStep({ formSteps, goToStep });
  };

  const handleMultiPetAutofill = async () => {
    await autofillSignup({
      formMethods,
      schema,
      registry,
      setFormSteps,
      experiments,
      numPets: userInput,
    });
    lightboxControl.close();
    if (shouldAdvanceToLastMeStep) goToFinalMeStep({ formSteps, goToStep });
  };

  const handleToggleSkipCalculatingScreen = () => {
    setShouldSkipCalculatingScreen(!shouldSkipCalculatingScreen);
  };
  const handleToggleshouldAdvanceToLastMeStep = () => {
    setShouldAdvanceToLastMeStep(!shouldAdvanceToLastMeStep);
  };

  const handleMultiPetInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserInput(Number(value));
  };

  const handleFetchLead = () => {
    const lead = getCachedLead();
    setLeadState(JSON.stringify(lead, null, 2));
  };

  const handleCopySignupLink = async () => {
    if ('clipboard' in navigator && leadEmail) {
      setIsSignupRecoveryLinkCopied(true);
      return await navigator.clipboard.writeText(
        `${window.location.origin}${PATH_SIGNUP}/${leadEmail}`
      );
    }
  };

  const handleCopyEmail = async () => {
    if ('clipboard' in navigator && leadEmail) {
      setIsEmailCopied(true);
      return await navigator.clipboard.writeText(leadEmail);
    }
  };

  return (
    <Modal {...lightboxControl}>
      <Text variant="heading-28" bold color="kale-3">
        TOSA Developer Tools 🐶
      </Text>
      <Divider color="Charcoal0" className={styles.divider} />
      <Button type="button" onClick={handleSinglePetAutofill}>
        Autofill Single Pet Signup
      </Button>
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          className={styles.multiPetButton}
          onClick={handleMultiPetAutofill}
          disabled={!(userInput <= 8 && userInput >= 2)}
        >
          Autofill Multi Pet Signup
        </Button>
        <Input
          className={styles.multiPetNumInput}
          value={userInput}
          onChange={handleMultiPetInput}
          label={'Number of Pets (2-8)'}
          maxLength={1}
        ></Input>
      </div>
      <div className={styles.checkboxContainer}>
        <Checkbox
          label="Advance to Me Registration"
          onChange={handleToggleshouldAdvanceToLastMeStep}
          checked={shouldAdvanceToLastMeStep}
          className={styles.checkbox}
        />
        <Checkbox
          label="Skip Calculating Screen"
          onChange={handleToggleSkipCalculatingScreen}
          checked={shouldSkipCalculatingScreen}
          className={styles.checkbox}
        />
      </div>
      <Divider color="Charcoal0" className={styles.divider} />
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          onClick={handleCopySignupLink}
          disabled={!leadEmail || isSignupRecoveryLinkCopied}
          className={styles.copyButton}
        >
          {isSignupRecoveryLinkCopied
            ? 'Copied Recovery Link!'
            : 'Copy Recovery Link'}
        </Button>
        <Button
          type="button"
          onClick={handleCopyEmail}
          disabled={!leadEmail || isEmailCopied}
          className={styles.copyButton}
        >
          {isEmailCopied ? 'Copied Email!' : 'Copy Email Address'}
        </Button>
      </div>
      <Accordion
        rows={[
          {
            title: 'Form State',
            content: (
              <Text variant="heading-16">
                <pre>{JSON.stringify(formMethods.getValues(), null, 2)}</pre>
              </Text>
            ),
          },
          {
            title: 'LeadView',
            content: (
              <>
                <Button type="button" onClick={handleFetchLead}>
                  {leadState ? 'Refetch Lead' : 'Fetch Lead'}
                </Button>
                <Text variant="heading-16">
                  <pre>{leadState}</pre>
                </Text>
              </>
            ),
          },
          {
            title: 'Current Form Steps',
            content: (
              <>
                {formSteps.map((step, index) => {
                  return (
                    <Text variant="heading-16" as="p" key={`formStep-${index}`}>
                      {index + 1}. &quot;{step.name}&quot;
                    </Text>
                  );
                })}
              </>
            ),
          },
        ]}
      />
    </Modal>
  );
}
