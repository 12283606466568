import { useQuery } from '@apollo/client';
import { SuggestOrderSizeQuoteQuery } from './SuggestOrderSizeQuote.cgs.generated-types';
import SuggestOrderSizeQuote from './SuggestOrderSizeQuote.cgs.graphql';

/**
 * Retrieve initial subscription data for SuggestOrderSize
 */
export function useSuggestOrderSizeUpData(experimentalOrderSize: number) {
  return useQuery<SuggestOrderSizeQuoteQuery>(SuggestOrderSizeQuote, {
    context: { endpoint: 'cgs' },
    variables: {
      input: {
        orderSize: experimentalOrderSize,
      },
    },
  });
}
