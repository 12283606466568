import { convenientDeliveries } from '../assets/benefits/convenientDeliveries';
import { freshIngredientsBowl } from '../assets/benefits/freshIngredientsBowl';
import { readyToServe } from '../assets/benefits/readyToServe';

export interface Benefit {
  headline: string;
  imageSources: string[];
  firstLine: string;
  secondLine: string;
  noWrap: boolean;
}

export const BenefitsList = [
  {
    headline: 'Real food',
    imageSources: freshIngredientsBowl,
    firstLine: 'Human-grade',
    secondLine: 'made fresh.',
    noWrap: true,
  },
  {
    headline: 'Ready-to-serve',
    imageSources: readyToServe,
    firstLine: 'Pre-portioned packs.',
    secondLine: 'Just open and pour.',
    noWrap: false,
  },
  {
    headline: 'Convenient deliveries',
    imageSources: convenientDeliveries,
    firstLine: 'Skip the stores',
    secondLine: 'and preservatives.',
    noWrap: true,
  },
];

const activeExperimentBenefitsList = [
  {
    headline: 'Real food, made fresh',
    imageSources: freshIngredientsBowl,
    firstLine: 'Human-grade',
    secondLine: 'made fresh.',
    noWrap: true,
  },
  {
    headline: 'Pre-portioned packs',
    imageSources: readyToServe,
    firstLine: 'Pre-portioned packs.',
    secondLine: 'Just open and pour.',
    noWrap: false,
  },
  {
    headline: 'No more store runs, ever',
    imageSources: convenientDeliveries,
    firstLine: 'Skip the stores',
    secondLine: 'and preservatives.',
    noWrap: true,
  },
];

export const getBenefitsList = (
  isMobileGetStartedCopyOn: boolean
): Benefit[] => {
  return isMobileGetStartedCopyOn ? activeExperimentBenefitsList : BenefitsList;
};
