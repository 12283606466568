import { Text, type TextProps, Accordion } from '@farmersdog/corgi-x';

import { useCorgiV2Viewport } from 'src/screen';
import styles from './SingleRecipe.module.scss';
import { DiyRecipe } from 'src/data/diy';
import { RecipeType } from './types';
import { staticContent } from './static-content';
interface InstructionsProps {
  instructions: DiyRecipe['instructions'];
}

function Instructions({ instructions }: InstructionsProps) {
  return (
    <ul className={styles.instructions}>
      {instructions.map(({ description, name }) => (
        <li key={name}>
          <Text variant="heading-22" as="h4" bold>
            {name}
          </Text>
          <Text variant="article-16" as="p">
            {description.replace('{numberOfPackets}', '1 packet')}
          </Text>
        </li>
      ))}
    </ul>
  );
}

interface GetRecipeNameArgs {
  petName: string;
  primaryProtein: DiyRecipe['primaryProtein'];
}

function getRecipeName({ petName, primaryProtein }: GetRecipeNameArgs) {
  return `${petName}’s ${primaryProtein} Recipe`;
}

type SingleRecipeProps = Pick<
  DiyRecipe,
  | 'cookTime'
  | 'ingredientSummary'
  | 'instructions'
  | 'yieldWeight'
  | 'primaryProtein'
> & { currentRecipeType: RecipeType };

export function SingleRecipe({
  cookTime,
  ingredientSummary,
  instructions,
  yieldWeight,
  currentRecipeType,
  primaryProtein,
}: SingleRecipeProps) {
  const textVariants = useCorgiV2Viewport<TextProps['variant']>({
    xs: 'heading-22',
    lg: 'heading-28',
  });

  const rows = [
    {
      title: 'Ingredients',
      content: (
        <Text variant="article-16" as="p">
          {ingredientSummary}
        </Text>
      ),
    },
    {
      title: 'Instructions',
      content: <Instructions instructions={instructions} />,
    },
  ];

  const petName = staticContent[currentRecipeType].petName;

  return (
    <div className={styles.description}>
      <Text
        variant={textVariants}
        color="kale-3"
        as="h3"
        bold
        className={styles.title}
      >
        {getRecipeName({ petName, primaryProtein })}
      </Text>
      <Text
        variant="heading-16"
        color="charcoal-3"
        as="p"
        className={styles.stats}
      >
        Time: {cookTime}
      </Text>
      <Text
        variant="heading-16"
        color="charcoal-3"
        as="p"
        className={styles.stats}
      >
        Yield: {yieldWeight} lbs
      </Text>
      <Accordion rows={rows} />
    </div>
  );
}
