import { CustomerVetSectionTreatments, useFeature } from 'src/abTesting';
import { HEALTH_HOMEPAGE_CUSTOMER_VET_SECTION } from 'src/abTesting/features';

export const useVetSection = () => {
  const { treatment } = useFeature<CustomerVetSectionTreatments>(
    HEALTH_HOMEPAGE_CUSTOMER_VET_SECTION
  );
  const shouldShowVetSection =
    treatment === CustomerVetSectionTreatments.checkmarks ||
    treatment === CustomerVetSectionTreatments.icons;
  const showCheckmarksVersion =
    treatment === CustomerVetSectionTreatments.checkmarks;

  return {
    shouldShowVetSection,
    showCheckmarksVersion,
  };
};
