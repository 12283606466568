import eventNames from '../eventNames';
import track from '../track';

export function trackAccountStandalonePopupAppear({
  times,
}: {
  times: number;
}) {
  track(eventNames.account_standalone_popup_appear, {
    times,
  });
}
