import { PRODUCT_COMBINATIONS_FETCH } from 'src/constants/actionTypes';

import { createReducer } from 'redux-create-reducer';

const initialState = {
  productCombinations: [],
  isPetConfigurationFetched: false,
  isRecipeConfigurationFetched: false,
};

export default createReducer(initialState, {
  [PRODUCT_COMBINATIONS_FETCH](state, action) {
    return {
      ...state,
      productCombinations: action.payload.data,
    };
  },
});

/**
 * Select the available product combinations
 *
 * @param {Object} state
 * @returns {Configuration.ProductCombination[]}
 */
export function selectProductCombinations(state) {
  return state.configuration.productCombinations;
}

/**
 * Select the smallest product combination
 *
 * @param {Object} state
 * @returns {Configuration.ProductCombination}
 */
export function selectSmallestProductCombination(state) {
  return selectProductCombinations(state)?.[0] ?? {};
}

export function selectIsConfigurationFetched(state) {
  return state.configuration.isPetConfigurationFetched;
}
