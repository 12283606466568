import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_HOME } from '@farmersdog/constants/paths';
import { trackArtsAiPageView } from './trackArtsAiPageView';

export const trackArtsAiPageViewRoutes = [PATH_HOME];

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for an ArtsAi page view event.
 */
export function useTrackArtsAiPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (trackArtsAiPageViewRoutes.includes(pathname)) {
      trackArtsAiPageView();
    }
  }, [pathname]);
}
