import { ApplicationError, ApplicationErrorOptions } from './ApplicationError';

export const VALIDATION_ERROR_NAME = 'ValidationError';

export interface Validation {
  [field: string]: string;
}

export interface ValidationErrorOptions extends ApplicationErrorOptions {
  param?: string;
}

/**
 * A validation error. This extends our ApplicationError class.
 *
 * @param message - An error message
 * @param options.title - An optional, non-technical, user-facing title
 * @param options.displayMessage - An optional, non-technical, user-facing message
 * @param options.param -  An optional property to tell which parameter was not valid.
 *
 * @example
 *
 *```ts
 * new ValidationError('Something went wrong with an unknown param');
 * new ValidationError('First name is not valid', { param: 'firstName' });
 * new ValidationError(
 *   'Address does not exists',
 *   {
 *     title: 'Please check your information'
 *     displayMessage: <p>Address does <strong>not!</strong> exist</p>,
 *     param: 'shippingAddress.addressLine1',
 *   });
 *```
 */
export class ValidationError extends ApplicationError {
  validation: Validation | undefined;

  constructor(message: string, options?: ValidationErrorOptions) {
    const { param, ...superOptions } = options ?? { param: undefined };
    super(message, superOptions);
    if (param) {
      this.validation = { [param]: message };
    }
    this.name = VALIDATION_ERROR_NAME;
  }
}
