import classNames from 'classnames';
import { useState, FC } from 'react';
import { useSwipeable } from 'react-swipeable';

import { CHARCOAL_1, CHARCOAL_0 } from '@farmersdog/corgi/constants';
import { MatchingViewports, Slider, useViewport } from '@farmersdog/corgi-x';

import ArrowIcon from './assets/icons/arrow-down-open.svg';
import CircleButton from './CircleButton/CircleButton';
import styles from './ReviewsSlider.module.css';
import { DOT_GROUP_LENGTH, getDotGroup, getSlides } from './util';

interface ReviewsSliderProps {
  className?: string;
}

export const ReviewsSlider: FC<ReviewsSliderProps> = ({ className = '' }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const viewport = useViewport();
  const visibleSlides = getVisibleSlides(viewport);

  let scrollBy = 1;
  if (viewport.large) {
    scrollBy = 2;
  }

  const slides = getSlides();

  const handleNextSlide = () =>
    setCurrentSlide(
      Math.min(currentSlide + scrollBy, slides.length - visibleSlides)
    );

  const handlePreviousSlide = () =>
    setCurrentSlide(Math.max(0, currentSlide - scrollBy));

  const handleSwipes = useSwipeable({
    onSwipedRight: handlePreviousSlide,
    onSwipedLeft: handleNextSlide,
    preventScrollOnSwipe: true,
  });

  if (!Array.isArray(slides) || slides.length < 1) {
    return null;
  }

  const dotGroup = getDotGroup(
    slides.length,
    currentSlide,
    scrollBy,
    visibleSlides
  );

  const showTouchInterface = window?.navigator?.maxTouchPoints > 0;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const dotGroupArray = [...new Array(Math.round(dotGroup))];
  return (
    <section
      className={classNames(styles.reviewSlider, className)}
      {...handleSwipes}
    >
      <Slider
        fixedHeight
        currentSlide={currentSlide}
        visibleSlides={visibleSlides}
      >
        {slides.map((slide, i) => {
          const { component: SlideContent, data } = slide;

          return (
            <div className={styles.slide} key={`reviews-${i}`}>
              <SlideContent {...data} />
            </div>
          );
        })}
      </Slider>
      {!showTouchInterface && (
        <div
          className={styles.reviewSliderControls}
          style={{ display: 'flex' }}
        >
          {currentSlide > 0 && (
            <div
              className={styles.buttonLanding}
              style={{
                marginRight: 'auto',
              }}
              onClick={handlePreviousSlide}
            >
              <CircleButton
                onClick={handlePreviousSlide}
                style={{
                  marginRight: 'auto',
                }}
                aria-label="Previous"
                type="button"
              >
                <ArrowIcon
                  style={{
                    transform: 'rotate(90deg) translateY(1px)',
                    width: 25,
                    height: 25,
                    display: 'flex',
                  }}
                />
              </CircleButton>
            </div>
          )}
          {currentSlide < slides.length - visibleSlides && (
            <div
              className={styles.buttonLanding}
              style={{
                marginLeft: 'auto',
              }}
              onClick={handleNextSlide}
            >
              <CircleButton
                onClick={handleNextSlide}
                style={{
                  marginRight: 'auto',
                }}
                aria-label="Next"
                type="button"
              >
                <ArrowIcon
                  style={{
                    transform: 'rotate(-90deg) translateY(1px)',
                    width: 25,
                    height: 25,
                    display: 'flex',
                  }}
                />
              </CircleButton>
            </div>
          )}
        </div>
      )}
      {showTouchInterface && (
        <div className={styles.touchInterface}>
          {dotGroupArray.map((_, i) => (
            <div
              key={`index-dot-${i}`}
              style={{
                width: 6,
                height: 6,
                margin: '0 3px',
                background:
                  (currentSlide / scrollBy) % DOT_GROUP_LENGTH === i
                    ? CHARCOAL_1
                    : CHARCOAL_0,
                borderRadius: '100%',
              }}
            />
          ))}
        </div>
      )}
    </section>
  );
};

const getVisibleSlides = (viewport: MatchingViewports): number => {
  let visibleSlides = 1;
  if (viewport.large) {
    visibleSlides = 4;
  } else if (viewport.medium) {
    visibleSlides = 2;
  } else if (viewport.extraSmall) {
    visibleSlides = 1;
  }

  return visibleSlides;
};
