import { CvrZipEmailCopyTreatments, FeatureName } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface UseZipEmailCopyExperimentProps {
  useFeature: UseFeatureHook;
}

export function useZipEmailCopyExperiment({
  useFeature,
}: UseZipEmailCopyExperimentProps) {
  const { treatment } = useFeature(FeatureName.CVR_ZIP_EMAIL_COPY);

  const showZipEmailCopy = treatment === CvrZipEmailCopyTreatments.show_context;

  return {
    showZipEmailCopy,
  };
}
