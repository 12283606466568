import { track } from '@farmersdog/analytics';
import eventNames from './eventNames';

export function trackNotificationPreferencesSaved(params: {
  new: {
    orderReminders: { email: boolean; sms: boolean };
    orderUpdates: { email: boolean; sms: boolean };
    marketingNotifications: { sms: boolean };
  };
  old: {
    orderReminders: { email: boolean; sms: boolean };
    orderUpdates: { email: boolean; sms: boolean };
    marketingNotifications: { sms: boolean };
  };
}) {
  track(eventNames.saved, params);
}
