import { getCacheBuster } from '../../utils';
import {
  claritas,
  IHeartRadioEventId,
  IHeartRadioEventName,
  I_HEART_RADIO_PIXEL_ID,
} from '../claritas';

/**
 * Track an iHeartRadio page view
 */
export function trackIHeartRadioPageView(): void {
  const ord = getCacheBuster();
  claritas.track({
    ord,
    ppt: I_HEART_RADIO_PIXEL_ID,
    g: IHeartRadioEventName.PAGE_VIEW,
    gid: IHeartRadioEventId.PAGE_VIEW,
  });
}
