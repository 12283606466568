import { CancellationResponsesEnum } from './cancellationResponses';

/** When any of these reasons are made the "current" state (by the customer
selecting and clicking "Continue") we should display the "Delay Save Prompt" UI */
const delaySaveStates = [
  CancellationResponsesEnum.TravelingMoving,
  CancellationResponsesEnum.TooMuchFood,
  CancellationResponsesEnum.NeedMoreTimeOnTrial,
] as const;

export function getDelaySaveStates() {
  return delaySaveStates;
}
