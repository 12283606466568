import * as Apollo from '@apollo/client';
import reactivateDataQuery from './reactivateData.cgs.graphql';

import { FetchReactivateDataQuery } from './reactivateData.cgs.generated-types';

interface UseReactivateDataProps {
  onCompleted: () => void;
}

export function useReactivateData(props: UseReactivateDataProps) {
  return Apollo.useQuery<FetchReactivateDataQuery>(reactivateDataQuery, {
    context: {
      endpoint: 'cgs',
    },
    onCompleted: props.onCompleted,
  });
}
