import { Link } from '@farmersdog/corgi';
import { Nowrap } from '@farmersdog/corgi-x';

import { PATH_REVIEWS } from '@farmersdog/constants/paths';

import { shouldBeFoodSources, dentalDiseaseSources } from './assets';
import {
  LINK_DIGEST_DENTAL,
  LINK_DIGEST_OVERWEIGHT,
  LINK_DIGEST_LABEL_TRICKS,
  LINK_TODAY_COM_STUDIES,
} from '../../constants/links';

export const pageTitle = 'Why Fresh?';

export const metaData = {
  title: 'Why Fresh Dog Food Is Healthier & Safer Than Dry & Canned',
  description:
    'Research shows traditional pet food and highly processed diets are correlated with long-term health risks. Learn more about the industry’s low safety and quality standards, and start feeding fresh, healthy food today.',
};

export const hero = {
  head: (
    <>
      The Dog Years <Nowrap>Are Over</Nowrap>
    </>
  ),
  body: (
    <>
      For as long as any of us can remember, we’ve been trained to feed
      commercial “dog food.” What do we have to show for{' '}
      <Nowrap>it today?</Nowrap>
    </>
  ),
};

export const videos = {
  calendarVideoIds: {
    xs: 'lsi5p14jkt',
    sm: '4q2d6steq4',
    md: 'lsi82se6q5',
  },
  bagVideoIds: {
    xs: 'vte7f86ftn',
    lg: 'ejwowgxigd',
  },
  conveyorVideoIds: {
    xs: '3i4jr086zs',
    lg: '4y1fpov4pd',
  },
};

export const images = {
  dentalDiseaseGraph: {
    sources: dentalDiseaseSources,
    alt: "This graph shows the amount of dogs eating dry food, about 80%, compared to the amount of dogs suffering from dental disease: Also about 80%. We don't believe in coincidences — just fresh food.",
  },
};

export const sections = {
  dentalDisease: {
    content: (
      <>
        <p>
          Over 80% of dogs will get some form of{' '}
          <Link href={LINK_DIGEST_DENTAL} target="_blank">
            dental disease
          </Link>{' '}
          in their lifetime — roughly the same percentage who eat dry food.
          Nearly 60% are{' '}
          <Link href={LINK_DIGEST_OVERWEIGHT} target="_blank">
            overweight
          </Link>{' '}
          or obese. Half over the age of 10 will develop cancer — but most won’t
          make it past that age to begin with.
        </p>
        <p>
          It’s as though they invented ‘dog years’ to hide a shorter lifespan.
          But we want more actual years with our pets. And that starts with what
          we feed them.
        </p>
      </>
    ),
    cta: 'Start Feeding Fresh',
  },
  kibbleBullSection: {
    content: (
      <>
        <p>
          We’ve come to rely on pet food labels and what’s pictured on the bag
          or can, because the “food” itself - both in smell and appearance - has
          never been a very useful guide.
        </p>
        <p>
          Neither is the bag, it turns out. A host of{' '}
          <Link href={LINK_DIGEST_LABEL_TRICKS} target="_blank">
            ingredient label tricks
          </Link>{' '}
          allow companies to mislead consumers, and{' '}
          <Link
            href={LINK_TODAY_COM_STUDIES}
            target="_blank"
            rel="nofollow noreferrer noopener"
          >
            numerous studies
          </Link>{' '}
          have found pet foods to contain ingredients not listed on their label,
          or missing those they claim to use.
        </p>
      </>
    ),
    responsiveVideoIds: {
      xs: '',
      lg: '',
    },
  },
  conveyorSection: {
    title: <>But what about the “good” brands?</>,
    content: (
      <>
        <p>
          Pet food production in the U.S. is held to alarmingly low safety and
          quality standards, which are hidden behind loose labeling and
          marketing regulations.
        </p>
        <p>
          While the picture on the bag suggests that the brown pellet inside was
          made with a gorgeous roast chicken breast, the reality can instead be
          diseased chicken parts, mixed with starchy fillers, and processed at
          temperatures known to produce carcinogens.
        </p>
      </>
    ),
    boldContent: (
      <p>
        And this can all be labeled “premium,”“natural,” and even “organic.”
      </p>
    ),
  },
  bowlSection: {
    title: (
      <>
        Pet food should <Nowrap>be…food.</Nowrap> Not burnt brown balls.
      </>
    ),
    content: (
      <>
        <p>
          We discovered that the key to our dogs’ well being was real food in
          their bowls — not simply pictures of it on a bag. And our{' '}
          <Link to={PATH_REVIEWS} target="_blank" rel="noopener noreferrer">
            customers
          </Link>{' '}
          constantly remind us about the wonders fresh food can do for dogs’
          short- and long-term health.
        </p>
        <p>
          Most of these dogs weren’t visibly “sick” or grappling with specific
          issues. But in some cases, a dog given weeks or months to live
          suddenly had a new lease on life.
        </p>
        <p>
          The effects of this simple switch may seem like magic, but there’s no
          trick. It’s just the power of real food. Made fresh. Quickly frozen.
          Delivered to your door.
        </p>
      </>
    ),
    cta: 'Try It Today',
    media: {
      sources: shouldBeFoodSources,
      alt: 'Dog with food bowl',
    },
  },
};
