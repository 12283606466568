import { Children, Fragment, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Bowser from 'bowser';

import browserOnly from 'src/utils/browserOnly';

/**
 * Device type information
 *
 * @property {String} osname The name of the operating system.
 * @property {String} browser The name of the browser.
 * @property {String} platform The name of the platform.
 * @property {String} engine The name of the browser rendering engine.
 * @property {Boolean} touchable Whether the device supports touch events.
 * @property {Boolean} passiveSupported Whether the device supports passive event listeners.
 *
 * @property {Function} initialize Initialize the deviceType object.
 * @property {Function} setUserAgent Set the userAgent string.
 * @property {Function} setPassiveSupported Set the passiveSupported property.
 * @property {Function} setTouchSupported Set up the subscription for touch event notifier.
 *
 * This code is run both in node and in the browser. Please update carefully!
 */
export class DeviceType {
  constructor() {
    this.osname = '';
    this.browser = '';
    this.platform = '';
    this.engine = '';
    this.touchable = false;
    this.passiveSupported = false;
  }

  /**
   * Initialize the deviceType object in both node and browser environments.
   * Some attributes of deviceType will not be accessible in browser
   * environments. You must call this method before rendering the react tree.
   *
   * @param {String} userAgent The user agent of the device.
   */
  initialize(userAgent) {
    this.setUserAgent(userAgent);
    this.setPassiveSupported();
  }

  /**
   * Set the deviceType attributes that are determined by the user agent.
   *
   * @param {String} userAgent The user agent of the device.
   */
  setUserAgent(userAgent) {
    if (userAgent) {
      const deviceInfo = Bowser.getParser(userAgent);

      this.osname = deviceInfo.getOSName().toLowerCase();
      this.browser = deviceInfo.getBrowserName().toLowerCase();
      this.platform = deviceInfo.getPlatformType().toLowerCase();
      this.engine = deviceInfo.getEngineName().toLowerCase();
    }
  }

  /**
   * Method for detecting support for third options object argument when
   * setting up event listeners. Recommended by mozilla :)
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
   */
  setPassiveSupported() {
    browserOnly(window => {
      let passiveSupported = this.passiveSupported;

      try {
        const options = {
          get passive() {
            // This function will be called when the browser attempts to access
            // the passive property.
            passiveSupported = true;
            return undefined;
          },
        };

        window.addEventListener('test', options, options);
        window.removeEventListener('test', options, options);
      } catch (err) {
        passiveSupported = false;
      }

      this.passiveSupported = passiveSupported;
    });
  }

  /**
   * Method for detecting touch support on devices. There are a few methods for
   * detection however we cannot be absolutely sure that we wil get this
   * synchronously.
   *
   * @param {Function} callback Function to call when touch support is detected.
   * @return {Function} A function that will cancel the event listener.
   */
  setTouchSupported(callback) {
    return browserOnly(
      window => {
        if (window.PointerEvent && window.navigator.maxTouchPoints) {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }

          return noop;
        }

        if (
          window.matchMedia &&
          window.matchMedia('(any-pointer:coarse)').matches
        ) {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }

          return noop;
        }

        // last resort
        const handleTouchStart = () => {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }
        };

        window.addEventListener(
          'touchstart',
          handleTouchStart,
          this.passiveSupported ? { passive: true } : false
        );

        return () => window.removeEventListener('touchstart', handleTouchStart);
      },
      () => noop
    );
  }
}

const deviceType = Object.seal(new DeviceType());

export default deviceType;

/**
 * Unfortunately this component is quite dumb and also quite needed. Due to how
 * touchevents are detected we have to use a keyed fragment to force a render
 * cycle when touch is detected.
 */
export function DeviceTypeInjector({ children }) {
  const [hasTouchable, setHasTouchable] = useState(false);

  useLayoutEffect(() => {
    return deviceType.setTouchSupported(() => setHasTouchable(true));
  }, []);

  return (
    <Fragment key={`has-touchable-${hasTouchable}`}>
      {Children.only(children)}
    </Fragment>
  );
}

DeviceTypeInjector.propTypes = {
  children: PropTypes.node,
};
