import { Text } from '@farmersdog/corgi-x';
import { CircleAlert } from '@farmersdog/corgi-x/icons';
import styles from './PendingPetsDisclaimer.module.css';

export const PendingPetsDisclaimer = () => {
  return (
    <section
      className={styles.root}
      role="note"
      aria-label="Request for new pet already recieved"
    >
      <div className={styles.icon}>
        <CircleAlert baseColor="Blueberry3" size="25px" />
      </div>

      <Text as="p" vSpacing="none" align="left" color="Blueberry3">
        We&apos;ve received your request to add a new dog, and we&apos;ll email
        to confirm everything soon! To add a different new dog to your plan,
        please continue.
      </Text>
    </section>
  );
};
