import { createSlottableComponent } from '../../../../../slots';
import { useFetchHomepageData } from '../../../../network/fetchHomepageData';
import { reporter } from '../../../../../errors/services/reporter/reporter';
import { formatNames } from '../../../../../utils';
import { Nudge } from '../../../Nudge';
import { bodyTemplate, ctaLabel, subheadTemplate } from './PickyEaterCta.copy';
import { usePickyEaterNudgeEligibility } from './hooks/usePickyEaterNudgeEligibility';
import { trackPickyEaterNudge } from './analytics';
import {
  assets as pickyDogImages,
  altText as pickyDogImagesAlt,
} from './assets';

function PickyEaterCtaComponent() {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving homepage data', { sourceError: error });
    },
  });

  const pets = request.data?.customer.pets;
  if (!pets) {
    return;
  }

  const petNames = formatNames(pets.map(pet => pet.name)) || 'your dog';

  function handleCtaClick() {
    const tipsNode = document.querySelector('#picky-eater-tips');
    if (tipsNode) {
      tipsNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    trackPickyEaterNudge();
  }

  const subheadText = subheadTemplate.replace('{petNames}', petNames);
  const bodyText = bodyTemplate.replace('{petNames}', petNames);

  return (
    <Nudge
      onMaybeLaterClick={() => {}}
      setNudgeDismissed={() => {}}
      imageSources={pickyDogImages}
      imageAltText={pickyDogImagesAlt}
      headlineText=""
      subheadText={subheadText}
      body={bodyText}
      ctaText={ctaLabel}
      ctaLinkDestination="#"
      ctaOnClick={handleCtaClick}
      componentTitle="Picky Eater"
      trackDismissCta={() => {}}
      bodyColor="white"
    />
  );
}

export const PickyEaterCta = createSlottableComponent(
  PickyEaterCtaComponent,
  usePickyEaterNudgeEligibility
);
