import browserOnly from 'src/utils/browserOnly';
import { Logger } from '@farmersdog/logger';

const log = new Logger('app:vendors:googleTagManager');

/**
 * Safely set a variable in the `googleTagManagerVariables` global.
 */
export function setGoogleTagManagerVar(name, value) {
  return browserOnly(window => {
    if (typeof window.googleTagManagerVariables !== 'object') {
      log.debug(
        'Cannot set the variable because "window.googleTagManagerVariables" is not defined'
      );
      return;
    }
    window.googleTagManagerVariables[name] = value;
    log.debug('Set variable', { name, value });
  });
}
