import { GetTreatmentConfigType } from '@farmersdog/ab-testing';
import { local } from '@farmersdog/utils';
import { FeatureKeys, FeaturesMap } from '../app/lib/Split';
import { userFeatures, anonymousFeatures } from '../app/lib/Split/features';

// @ts-expect-error we know these are keys!
const allKeys: FeatureKeys[] = [
  ...Object.keys(userFeatures),
  ...Object.keys(anonymousFeatures),
];

export type Result = GetTreatmentConfigType<FeaturesMap, FeatureKeys> & {
  locked: boolean;
};

export type Results = Record<FeatureKeys, Result | undefined>;

// @ts-expect-error TS doesn't want to believe that key is type FeatureKeys
const results: Results = Object.fromEntries(
  allKeys.map((key: FeatureKeys) => {
    try {
      const result = JSON.parse(local.getItem(key) ?? '') as Result;
      return [key, result];
    } catch {
      return [key, undefined];
    }
  })
);

function lock(key: FeatureKeys) {
  const result = results[key];
  if (!result) return;
  result.locked = true;
  local.setItem(key, JSON.stringify(result));
}

function unlock(key: FeatureKeys) {
  const result = results[key];
  if (result) result.locked = false;
  local.removeItem(key);
}

function set(key: FeatureKeys, result: Result) {
  const current = results[key];
  if (current?.locked) return;

  const newResult = { ...result, locked: Boolean(current?.locked) };
  results[key] = newResult;
}

function get(key: FeatureKeys): Result | undefined {
  const memoryResult = results[key];
  try {
    const localResult = JSON.parse(local.getItem(key) ?? '') as
      | Result
      | undefined;
    if (localResult) {
      return localResult;
    }
    return memoryResult;
  } catch {
    return memoryResult;
  }
}

export const splitDebug = { results, set, get, lock, unlock };
