import { FetchQuoteQuery } from '../../graphql/types';

export function getTrialDurationInWeeks(
  quote: FetchQuoteQuery['fetchQuote'] | null
): string {
  if (!quote) {
    return '';
  }

  const trialDays = quote.trial.daysOfFood;
  const trialWeeks = Math.floor(trialDays / 7);

  return `${trialWeeks} week${trialWeeks === 1 ? '' : 's'}`;
}
