import classNames from 'classnames';

import { ExperimentalLayoutType, LayoutType } from '../../blueprint/types';

import styles from './getLayoutClass.css';

interface GetLayoutClassArgs {
  layout?: LayoutType | ExperimentalLayoutType;
  show?: boolean;
}

export function getLayoutClass({ layout, show }: GetLayoutClassArgs) {
  return classNames(
    layout ? styles[layout] : '',
    show === false ? styles['hiddenNode'] : ''
  );
}
