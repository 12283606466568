import { DiyRecipe, PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const turkeyAndChickpeas: DiyRecipe = {
  name: 'Turkey and Chickpeas',
  id: 'turkey-and-chickpeas',
  primaryProtein: PrimaryProtein.Turkey,
  ingredients: [
    {
      name: '93% lean ground turkey',
      quantity: 990,
      unit: 'g',
      alternativeQuantity: 2.2,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked chickpeas',
      quantity: 300,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, cut into 1/4 inch dice',
      quantity: 280,
      unit: 'g',
      alternativeQuantity: 3.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'sweet potato',
      quantity: 260,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, shredded or cut into 1/4 inch dice',
      quantity: 220,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'eggs (yolk and white)',
      quantity: 150,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'large',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 8,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  yieldWeight: 4.9,
  cookTime: '35 mins',
  ingredientSummary:
    'Ground Turkey, Chickpeas, Broccoli, Sweet Potato, Carrots, Eggs, DIY Nutrient Mix, Sunflower Oil, Salmon Oil ',

  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 2',
      description:
        'Add the ground turkey to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 3',
      description:
        'Add the chickpeas, broccoli, sweet potatoes, and carrots. Cook until the chickpeas and vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the eggs and salmon oil to the pot. Break the yolks and stir to evenly distribute. Cook until the eggs are firm and no longer runny.',
    },
    {
      name: 'Step 5',
      description:
        'If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
  popularity: 2,
};
