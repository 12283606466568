import get from 'lodash/get';
import set from 'lodash/set';

import { isNotNullUndefinedOrEmptyString } from '../isNotNullUndefinedOrEmptyString';

interface FilterLeadByFieldsArgs {
  object: Record<string, unknown>;
  fields: string[];
}

export function filterObjectByFields({
  object,
  fields,
}: FilterLeadByFieldsArgs): Record<string, unknown> {
  return fields.reduce((accum, field) => {
    const value = get(object, field);

    if (isNotNullUndefinedOrEmptyString(value)) {
      set(accum, field, value);
    }

    return accum;
  }, {});
}
