interface HandlePetNamesWhitespaceProps {
  label: string;
  petNames: string[];
}

/**
 * Returns a label with whitespaces removed from pet names so that the pet names can be indexed and have classname applied to its wrapping jsx element.
 * Returns a pet names map to look up the original value to display in the UI.
 */
export function handlePetNamesWhitespace({
  label,
  petNames,
}: HandlePetNamesWhitespaceProps) {
  let petNamesWithoutWhitespaceLabel = label;
  const petNamesMap: Record<string, string> = {};

  petNames.forEach(petName => {
    const petNameWithoutWhitespace = petName.replace(/\s/g, '');
    petNamesWithoutWhitespaceLabel = petNamesWithoutWhitespaceLabel.replace(
      petName,
      petNameWithoutWhitespace
    );
    petNamesMap[petNameWithoutWhitespace] = petName;
  });

  return { petNamesWithoutWhitespaceLabel, petNamesMap };
}
