import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { PATH_HOME } from '@farmersdog/constants/paths';

import { trackPeacockEvent } from './trackPeacockEvent';
import { PEACOCK_EVENTS } from './PeacockScript';

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a Pixel page view event.
 */
export function useTrackPeacockPageView() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === PATH_HOME) {
      trackPeacockEvent({ eventName: PEACOCK_EVENTS.SESSION_START });
    }
  }, [pathname]);
}
