import PropTypes from 'prop-types';
import { TextV2 } from '@farmersdog/corgi';
import { Button, Nowrap } from '@farmersdog/corgi-x';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { TITLE } from './copy';
import ActionButtons from './ActionButtons';

function SeeRecipes({ goToPrevSlide }) {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          Our recipes are comprised of real, human-grade meat, veggies, vitamins
          and minerals. That’s it!
        </TextV2>
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          After the dog profile questionnaire, you will see a detailed list of{' '}
          <Nowrap>recommended recipes</Nowrap>.
        </TextV2>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button variant="primary" type="submit">
            Close
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

SeeRecipes.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
};

export const SLIDE_NAME = 'SEE_RECIPES';

export default SeeRecipes;
