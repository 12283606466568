import { RecipeNames } from '@farmersdog/constants';

import type { RecommendedRecipe } from '../../types';

interface SortRecipesArgs {
  recipes: RecommendedRecipe[];
  sortOrder: RecipeNames[];
}

export function sortRecipes({ recipes, sortOrder }: SortRecipesArgs) {
  return [...recipes].sort((a, b) =>
    sortOrder.indexOf(a.name) > sortOrder.indexOf(b.name) ? 1 : -1
  );
}
