import classNames from 'classnames';

import { Modal } from '@farmersdog/corgi';

import { FreshRecipeRecommendation } from '../../graphql/types';
import { LightboxId, useGlobalLightbox } from '../../hooks';

import { RecipeDetails } from './RecipeDetails';
import styles from './RecipeModal.module.css';

export interface RecipeModalProps {
  recipe: FreshRecipeRecommendation;
  petName?: string;
}

function RecipeModal({ recipe, petName }: RecipeModalProps) {
  const { rootProps } = useGlobalLightbox({
    id: LightboxId.RecipeDetailModal,
  });

  return (
    // @ts-expect-error Types are incorrect
    <Modal className={classNames(styles.main)} {...rootProps}>
      <RecipeDetails recipe={recipe} petName={petName} key={recipe.name} />
    </Modal>
  );
}

export default RecipeModal;
