import { ContentWidth } from '@farmersdog/corgi';
import { Text, type TextProps } from '@farmersdog/corgi-x';
import { ReactNode } from 'react';

import { Hero, PageWidth } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

import { ScrollVideo, ScrollVideoProps } from '../ScrollVideo';
import styles from './ContentHero.module.scss';

export interface ContentHeroProps {
  /** The head content */
  head: ReactNode;
  /** The body content */
  body: ReactNode;
  /** Responsive viewport object containing video IDs */
  videoIds: ScrollVideoProps['videoIds'];
}

/**
 * Render a hero video with a head and body content above.
 */
export function ContentHero({ videoIds, head, body }: ContentHeroProps) {
  const variant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-40',
    lg: 'heading-52',
  });

  const videoOptions = useCorgiViewport<ScrollVideoProps['options']>({
    xs: {
      videoFoam: {
        minHeight: 558,
      },
    },
    md: {
      videoFoam: {
        minHeight: 572,
      },
    },
  });

  return (
    <div
      className={useCorgiViewport({
        xs: styles.xs,
        lg: styles.lg,
        xl: styles.xl,
      })}
    >
      <PageWidth>
        <ContentWidth fr={2} alignSelf="center">
          <Text
            as="h1"
            variant={variant}
            color="kale-3"
            className={styles.head}
            bold
          >
            {head}
          </Text>
          <Text
            as="h2"
            variant="article-20"
            color="charcoal-3"
            className={styles.body}
          >
            {body}
          </Text>
        </ContentWidth>
      </PageWidth>
      <Hero
        aria-label="Video section"
        className={styles.hero}
        background={
          <div className={styles.videoWrapper}>
            <ScrollVideo options={videoOptions} videoIds={videoIds} />
          </div>
        }
      />
    </div>
  );
}
