import { events, track } from 'src/analytics';

/**
 * This function tracks the customer’s referral code
 * @param referalCode - The customer’s referral code
 */

export function trackReferralCode({ referralCode }: { referralCode: string }) {
  track(events.signup_referral_code, {
    referralCode,
  });
}
