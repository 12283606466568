import { DiyRecipe, PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const tilapiaAndRiceForPuppies: DiyRecipe = {
  name: 'Tilapia and Rice for Puppies',
  id: 'tilapia-and-rice-for-puppies',
  primaryProtein: PrimaryProtein.Tilapia,
  isPuppy: true,
  ingredients: [
    {
      name: 'tilapia fillets (skinless)',
      quantity: 750,
      unit: 'g',
      alternativeQuantity: 1.7,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 380,
      unit: 'g',
      alternativeQuantity: 2.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 160,
      unit: 'g',
      alternativeIngredients: [
        {
          name: 'fresh',
          quantity: 5.5,
          unit: 'cup',
        },
        {
          name: 'frozen',
          quantity: 1,
          unit: 'cup',
        },
      ],
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, shredded or cut into 1⁄4 inch dice',
      quantity: 160,
      unit: 'g',
      alternativeQuantity: 1.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'sunflower oil',
      quantity: 25,
      unit: 'g',
      alternativeQuantity: 5.5,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'salmon oil',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salt, non-iodized',
      quantity: 1.5,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  yieldWeight: 4.5,
  cookTime: '40 mins',
  ingredientSummary:
    'Tilapia Fillets, White Rice, Spinach, Carrots, Sunflower Oil, DIY Nutrient Mix, Salmon Oil, Salt',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Place the tilapia fillets in the pot and cook, flipping periodically. Break into smaller pieces and cook until the tilapia is totally opaque. Remove tilapia from the pot, double check and remove any remaining pin bones, and set aside.',
    },
    {
      name: 'Step 3',
      description:
        'Add the carrots. Cook until the carrots are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the tilapia, cooked rice, and spinach into the pot and stir to evenly distribute. Cook until the spinach has wilted.',
    },
    {
      name: 'Step 5',
      description:
        'Add the sunflower oil and salmon oil, then stir to evenly distribute. If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        "Remove the pot from the heat and sprinkle the {numberOfPackets} packet of The Farmer's Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.",
    },
  ],
  calories: 1537,
};
