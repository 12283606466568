interface Options {
  /** Whether to round to whole dollars or not  */
  round?: boolean;
}

export function formatCurrency(
  value: number | undefined | null,
  { round }: Options = { round: false }
) {
  if (!value && value !== 0) {
    return '';
  }
  const absValue = Math.abs(value) / 100;
  const absFormattedValue = round ? Math.round(absValue) : absValue.toFixed(2);
  return `$${value < 0 ? '–' : ''}${absFormattedValue}`;
}
