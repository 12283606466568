import { useQuery } from '@apollo/client';
import {
  FetchEditFreshRecipesDataQuery,
  FetchEditFreshRecipesDataQueryVariables,
} from './fetchEditFreshRecipesData.cgs.generated-types';
import fetchEditFreshRecipesData from './fetchEditFreshRecipesData.cgs.graphql';

interface UseFetchEditFreshRecipesDataProps {
  petName: string;
  onCompleted: () => void;
}

export function useFetchEditFreshRecipesData(
  props: UseFetchEditFreshRecipesDataProps
) {
  return useQuery<
    FetchEditFreshRecipesDataQuery,
    FetchEditFreshRecipesDataQueryVariables
  >(fetchEditFreshRecipesData, {
    context: { endpoint: 'cgs' },
    variables: { input: { petName: props.petName } },
    onCompleted: props.onCompleted,
  });
}
