import { capitalize } from '@farmersdog/utils';

// TODO - to be imported from tosa-npm
const PRONOUN_PLACEHOLDER = '%pronoun';
export const PRONOUN_SUBJECT_PLACEHOLDER = `${PRONOUN_PLACEHOLDER}-subject%`;
export const PRONOUN_OBJECT_PLACEHOLDER = `${PRONOUN_PLACEHOLDER}-object%`;
export const PRONOUN_POSSESSIVE_PLACEHOLDER = `${PRONOUN_PLACEHOLDER}-possessive%`;
const PRONOUN_PLACEHOLDER_REGEXP = new RegExp(
  `(${PRONOUN_SUBJECT_PLACEHOLDER}|${PRONOUN_POSSESSIVE_PLACEHOLDER}|${PRONOUN_OBJECT_PLACEHOLDER})`,
  'g'
);

interface HandlePronounPlaceholdersProps {
  input: string;
  gender: string;
}

export function handlePronounPlaceholders({
  input,
  gender,
}: HandlePronounPlaceholdersProps) {
  const pronounPlaceholders = [...input.matchAll(PRONOUN_PLACEHOLDER_REGEXP)];
  const pronounReplacements = pronounPlaceholders.map(([placeholder]) => {
    switch (placeholder) {
      case PRONOUN_SUBJECT_PLACEHOLDER: {
        return gender === 'female' ? 'she' : 'he';
      }
      case PRONOUN_OBJECT_PLACEHOLDER: {
        return gender === 'female' ? 'her' : 'him';
      }
      case PRONOUN_POSSESSIVE_PLACEHOLDER: {
        return gender === 'female' ? 'her' : 'his';
      }

      default: {
        return 'she';
      }
    }
  });

  return input.replace(PRONOUN_PLACEHOLDER_REGEXP, (_, __, offset) => {
    const pronounReplacement = pronounReplacements.shift() ?? 'she';

    if (offset === 0) {
      return capitalize(pronounReplacement);
    }

    return pronounReplacement;
  });
}
