import { BranchNode } from '../../blueprint/types';
import { TOSAComponentInput } from '../../types';
import { Node } from '../schema/Node';

export function FormWrapper(props: TOSAComponentInput<BranchNode>) {
  const { formNavigation, node } = props;
  return (
    <>
      {node.children.map(childNode => {
        return childNode.__self__ === formNavigation.current ? (
          <Node {...props} node={childNode} key={childNode.__self__} />
        ) : null;
      })}
    </>
  );
}
