import { addMethod, object, string } from 'yup';

import { emailRegex } from '@farmersdog/constants/regexPatterns';

addMethod(string, 'email', function (message: string) {
  return this.matches(emailRegex, {
    name: 'email',
    message,
    excludeEmptyString: true,
  });
});

export const LoginFormSchema = object().shape({
  email: string().email('Invalid email address').required('Email is required'),
  password: string().required('Password is required'),
});
