import { useState, ReactElement } from 'react';
import classNames from 'classnames';
import { GridContainer, Nowrap } from '@farmersdog/corgi-x';
import { Text, type TextProps } from '@farmersdog/corgi-x';

import { NavigationTab } from './NavigationTab';
import { SingleRecipe } from './SingleRecipe';
import { Testimonial } from './Testimonial';
import { RecipeSlideShow } from './RecipeSlideShow';
import { CTA } from './CTA';

import { RecipeType } from './types';

import { useCorgiV2Viewport } from 'src/screen';
import styles from './Recipes.module.scss';
import { DiyRecipe, getRecipesById } from 'src/data/diy';

interface ViewportProps {
  responsiveClassName: string;
  RecipesComponent: (props: CurrentRecipe) => ReactElement;
}

export interface CurrentRecipe {
  currentRecipeType: RecipeType;
  setCurrentRecipeType: (recipe: RecipeType) => void;
  currentRecipeData: DiyRecipe;
}

function Heading() {
  return (
    <Text variant="heading-40" color="kale-3" as="h2" bold>
      Homemade dog food recipes for all, <Nowrap>big and small.</Nowrap>
    </Text>
  );
}

function Description() {
  const textVariants = useCorgiV2Viewport<TextProps['variant']>({
    xs: 'article-16',
    lg: 'article-20',
  });

  return (
    <Text variant={textVariants} color="charcoal-3" as="p">
      When you sign up for a DIY plan, we’ll recommend the best fresh recipes
      for your pup. There’s something ideal for every dog — and easy for every
      owner — with our mix-and-match recipe options. Check out a small taste
      below.
    </Text>
  );
}

function MobileRecipes({
  currentRecipeType,
  setCurrentRecipeType,
  currentRecipeData,
}: CurrentRecipe) {
  return (
    <>
      <GridContainer>
        <div className={styles.content}>
          <Heading />
          <Description />
          <NavigationTab
            currentRecipeType={currentRecipeType}
            setCurrentRecipeType={setCurrentRecipeType}
          />
        </div>
      </GridContainer>
      <RecipeSlideShow currentRecipeType={currentRecipeType} />
      <GridContainer>
        <div className={styles.content}>
          <SingleRecipe
            {...currentRecipeData}
            currentRecipeType={currentRecipeType}
          />
          <Testimonial />
          <CTA />
        </div>
      </GridContainer>
    </>
  );
}

function DesktopRecipes({
  currentRecipeType,
  setCurrentRecipeType,
  currentRecipeData,
}: CurrentRecipe) {
  return (
    <GridContainer>
      <div className={styles.content}>
        <Heading />
        <Description />
        <NavigationTab
          currentRecipeType={currentRecipeType}
          setCurrentRecipeType={setCurrentRecipeType}
        />
        <SingleRecipe
          {...currentRecipeData}
          currentRecipeType={currentRecipeType}
        />
        <Testimonial />
      </div>
      <div className={styles.image}>
        <RecipeSlideShow currentRecipeType={currentRecipeType} />
      </div>
      <div className={styles.cta}>
        <CTA />
      </div>
    </GridContainer>
  );
}

export function Recipes() {
  const [currentRecipeType, setCurrentRecipeType] = useState<RecipeType>(
    RecipeType.Meat
  );

  const recipeData = getRecipesById(['beef-and-carrots', 'chicken-and-rice']);

  const [meat, poultry, fish] = recipeData;

  const recipes = {
    meat,
    poultry,
    fish,
  };

  const { responsiveClassName, RecipesComponent } =
    useCorgiV2Viewport<ViewportProps>({
      xs: {
        responsiveClassName: styles.xs,
        RecipesComponent: MobileRecipes,
      },
      lg: {
        responsiveClassName: styles.lg,
        RecipesComponent: DesktopRecipes,
      },
    });

  const currentRecipeData = recipes[currentRecipeType];

  return (
    <section className={classNames(responsiveClassName, styles.container)}>
      <RecipesComponent
        currentRecipeType={currentRecipeType}
        setCurrentRecipeType={setCurrentRecipeType}
        currentRecipeData={currentRecipeData}
      />
    </section>
  );
}
