import isEqual from 'lodash/isEqual';
import { useState, useEffect } from 'react';

interface UseShouldLandOnCustomizationArgs {
  availableSelectedRecipesNames: string[] | undefined;
  recommendedRecipesNames: string[];
  isCustomizingRecipes: boolean | null;
  setIsCustomizingRecipes: (arg: boolean | null) => void;
}

export function useShouldLandOnCustomization({
  availableSelectedRecipesNames,
  recommendedRecipesNames,
  isCustomizingRecipes,
  setIsCustomizingRecipes,
}: UseShouldLandOnCustomizationArgs) {
  const [defaultToCustomization, setDefaultToCustomization] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (isCustomizingRecipes !== null) {
      return;
    }

    if (recommendedRecipesNames.length === 0) {
      return;
    }

    // recommendation is fetched async when the recipe page renders
    // while current selection is the form state that is already available when the recipe page renders
    // if we have recommendation but no defined selections, it is the first time the user lands on the recipes page
    if (recommendedRecipesNames.length > 0 && !availableSelectedRecipesNames) {
      setIsCustomizingRecipes(false);
      setDefaultToCustomization(false);
      return;
    }

    const currentSelectionMatchesRecommendation = isEqual(
      [...recommendedRecipesNames].sort().map(name => name.toLowerCase()),
      [...(availableSelectedRecipesNames ?? [])]
        .sort()
        .map(name => name.toLowerCase())
    );

    const shouldShowCustomization = Boolean(
      availableSelectedRecipesNames &&
        availableSelectedRecipesNames.length > 0 &&
        !currentSelectionMatchesRecommendation
    );

    setIsCustomizingRecipes(shouldShowCustomization);
    setDefaultToCustomization(shouldShowCustomization);
  }, [
    isCustomizingRecipes,
    recommendedRecipesNames,
    availableSelectedRecipesNames,
    setIsCustomizingRecipes,
  ]);

  return { defaultToCustomization };
}
