import { useMachine } from '@xstate/react';
import { SuggestOrderSizeEvent } from './SuggestOrderSizeEvent';
import {
  suggestOrderSizeMachine,
  SuggestOrderSizeMachineContext,
} from './suggestOrderSizeMachine';
import { SuggestOrderSizeState } from './SuggestOrderSizeState';

export type UseSuggestOrderSizeMachine = {
  /* Current state context */
  context: SuggestOrderSizeMachineContext;
  /* Returns true if current state matches input state */
  isState: (state: SuggestOrderSizeState) => boolean;
  /* Fires when user accepts new plan */
  onAccept: (
    updatedNextOrderToBeDelivered: SuggestOrderSizeMachineContext
  ) => void;
  /* Fires when user rejects new plan */
  onReject: (
    originalNextOrderToBeDelivered: SuggestOrderSizeMachineContext
  ) => void;
  /* Fires on click of X or Back buttons */
  onClose: () => void;
  /* Fires on click of See My Plan button */
  onContinueToPlan: () => void;
};

/**
 * Wrapper for useMachine. Defines xState event transitions.
 * Returns wrapped transition handlers and machine utils.
 */
export function useSuggestOrderSizeMachine(): UseSuggestOrderSizeMachine {
  const [state, send] = useMachine(suggestOrderSizeMachine);

  const isState = (stateInput: SuggestOrderSizeState) => {
    return state.matches(stateInput);
  };

  const onAccept = (
    updatedNextOrderToBeDelivered: SuggestOrderSizeMachineContext
  ) => {
    send({
      type: SuggestOrderSizeEvent.Accept,
      ...updatedNextOrderToBeDelivered,
    });
  };

  const onReject = (
    originalNextOrderToBeDelivered: SuggestOrderSizeMachineContext
  ) => {
    send({
      type: SuggestOrderSizeEvent.Reject,
      ...originalNextOrderToBeDelivered,
    });
  };

  const onClose = () => {
    send({ type: SuggestOrderSizeEvent.Close });
  };

  const onContinueToPlan = () => {
    send({ type: SuggestOrderSizeEvent.ContinueToPlan });
  };

  return {
    context: state.context,
    isState,
    onAccept,
    onReject,
    onClose,
    onContinueToPlan,
  };
}
