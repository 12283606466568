import {
  ActionType,
  CheckoutState,
  SetBillingAsShippingAction,
  SetCardHolderAction,
  SetChangedStartDateAction,
  SetValidationErrorAction,
} from './checkout.types';

const initialState: CheckoutState = {
  billingAsShipping: true,
  cardHolder: '',
  changedStartDate: null,

  validationErrors: {
    userAccount: null,
    shippingAddress: null,
    billingAddress: null,
    card: null,
    startDate: null,
  },
};

export default function signupCheckoutReducer(
  state = initialState,
  action:
    | SetCardHolderAction
    | SetBillingAsShippingAction
    | SetValidationErrorAction
    | SetChangedStartDateAction
) {
  switch (action.type) {
    case ActionType.SetChangedStartDate:
      return { ...state, changedStartDate: action.payload };

    case ActionType.SetCardHolder:
      return { ...state, cardHolder: action.payload };

    case ActionType.SetBillingAsShipping:
      return { ...state, billingAsShipping: action.payload };

    case ActionType.SetValidationError:
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export function selectChangedStartDate(state: {
  signup: { checkout: CheckoutState };
}) {
  return state.signup.checkout.changedStartDate;
}

export function selectCardHolder(state: {
  signup: { checkout: CheckoutState };
}) {
  return state.signup.checkout.cardHolder;
}

export function selectBillingAsShipping(state: {
  signup: { checkout: CheckoutState };
}) {
  return state.signup.checkout.billingAsShipping;
}

export function selectValidationErrors(state: {
  signup: { checkout: CheckoutState };
}) {
  return state.signup.checkout.validationErrors;
}
