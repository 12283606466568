export enum LightboxId {
  CalculatingScreen = 'petsCalculatingScreenId',
  HasAccountModal = 'hasAccountModal',
  RecoveryModal = 'recoveryModalId',
  RecipeDetailModal = 'recipeDetailModal',
  MultiRecipeDetailModal = 'multiRecipeDetailModal',
  ErrorModal = 'errorModalId',
  TosaDevTools = 'tosaDevToolsId',
  PriceValueModal = 'priceValueModalId',
}
