import {
  ShippingAddressFieldsFragment,
  BillingAddressFieldsFragment,
} from '../network';

type Address = ShippingAddressFieldsFragment | BillingAddressFieldsFragment;

export function isSameAddress(address1: Address, address2: Address): boolean {
  return (
    address1.addressLine1 === address2.addressLine1 &&
    address1.addressLine2 === address2.addressLine2 &&
    address1.city === address2.city &&
    address1.stateAbbreviation === address2.stateAbbreviation &&
    address1.zip === address2.zip
  );
}
