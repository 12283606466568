import { useRouteMatch } from 'react-router-dom';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';

export enum DynamicPageTitles {
  SignupPageTitle = 'Sign Up',
}

export function useRouteMatchedTitle() {
  if (useRouteMatch(PATH_SIGNUP)) {
    return DynamicPageTitles.SignupPageTitle;
  }

  return;
}
