import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ActivityIndicator from 'src/components/_depreciated/ActivityIndicator';

import './Button.scss';

/**
 * The `<Button>` component renders a `button` element with the different styles
 * (kinds) in use in our UI.
 *
 * @param {string} [kind] The kind of the button
 * @param {boolean?} [alternate] Render the button with an alternative color
 * @param {boolean?} [loading] Render the `ActivityIndicator` component instead of the
 * button, keeping the same width and height.
 * @param {string} [to] Renders a react-router's `Link` instead of a `button`
 * element.
 * @param {string} [href] Renders an anchor link instead of a `button` element.
 * @param {object?} [style] Inline styles of button.
 * @param {boolean?} [inline] Render the button as an inline element.
 * @param {boolean?} [block] Render the button as a block element.
 */
export default function Button({
  children,
  to,
  href,
  className,
  kind = 'default',
  disabled = false,
  loading = false,
  inline = false,
  block = false,
  type = 'button',
  ...props
}) {
  let Component = 'button';
  if (to) {
    Component = Link;
  } else if (href) {
    Component = 'a';
  }
  const cssClass = classNames('Button', className, kind, {
    loading,
    inline,
    block,
  });
  return (
    <Component
      {...props}
      className={cssClass}
      disabled={disabled || loading}
      to={to}
      href={href}
      type={type}
    >
      <span className="Button-label">{children}</span>
      {loading && <ActivityIndicator />}
    </Component>
  );
}

Button.propTypes = {
  /** The button's label */
  children: PropTypes.node,
  /** Renders a react-router's `Link` instead of a `button` element. */
  to: PropTypes.string,
  /** Renders an anchor link instead of a `button` element. */
  href: PropTypes.string,
  /** The kind (style) of the Button */
  kind: PropTypes.oneOf([
    'bordered',
    'bordered-alternate',
    'default',
    'default-alternate',
    'default-disabled',
    'facebook',
    'inverted',
    'link',
    'link-alternate',
    'plain-text',
    'none',
    'plain',
    'plain-alternate',
    'rounded',
  ]),
  /** @deprecated Use `kind` instead */
  alternate: PropTypes.bool,
  /** @ignore */
  disabled: PropTypes.bool,
  /** Render the `ActivityIndicator` component instead of the button content */
  loading: PropTypes.bool,
  inline: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  /** @ignore */
  type: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};
