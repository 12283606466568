import events from '../eventNames';
import track from '../track';

type TrackShippingAddressErrorArgs = {
  errorCode: string;
};

/**
 * A segment tracking event that is used to send the error code
 *  when a user enters an invalid shipping address.
 */
export function trackShippingAddressError({
  errorCode,
}: TrackShippingAddressErrorArgs) {
  track(events.checkout_address_validation_error, {
    errorCode,
  });
}
