import { QueryParameter } from '@farmersdog/constants';

type Queries = {
  [key in QueryParameter]?: unknown;
};

function hasValue(queryValue: unknown) {
  return queryValue !== null && typeof queryValue !== 'undefined';
}

/**
 * Appends a safe query string to the end of a path
 *
 * @example
 * ```ts
 * setPathParams(
 *  "/signup",
 *  {userId: 123, previous: "/example"}
 * )
 *
 * Outputs: "/signup?userId=123&previous=%2Fexample"
 * ```
 *
 * @param path - Path to append query string to
 * @param params - Object with keys that match query values
 */
export function setPathQueries(path: string, queries: Queries) {
  const searchString = Object.entries(queries)
    .filter(([, queryValue]) => hasValue(queryValue))
    .map(([queryKey, queryValue]) => {
      const encodedValue = encodeURIComponent(String(queryValue));
      return `${queryKey}=${encodedValue}`;
    })
    .join('&');

  return searchString ? `${path}?${searchString}` : path;
}
