import qs from 'qs';

/**
 * Parse a query string and ev
 *
 * @param {string} search
 * @param {string} name The name to get
 * @return {string|undefined}
 */
export default function parseQueryString(search, name) {
  const parsed = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return parsed[name];
}
