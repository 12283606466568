import { useFormik } from 'formik';
import noop from 'lodash/noop';
import {
  MarketingNotificationFieldsFragment,
  OrderNotificationFieldsFragment,
} from '../../network';

interface UsePreferencesFormProps {
  [PreferenceFormFieldName.MarketingNotificationsSms]: boolean;
  [PreferenceFormFieldName.OrderRemindersSms]: boolean;
  [PreferenceFormFieldName.OrderUpdatesSms]: boolean;
}

export enum PreferenceFormFieldName {
  OrderUpdatesSms = 'OrderUpdatesSms',
  OrderRemindersSms = 'OrderRemindersSms',
  MarketingNotificationsSms = 'MarketingNotificationsSms',
}

export function mapDataToFormShape(values: {
  marketingNotifications: MarketingNotificationFieldsFragment;
  orderReminders: OrderNotificationFieldsFragment;
  orderUpdates: OrderNotificationFieldsFragment;
}): UsePreferencesFormProps {
  return {
    [PreferenceFormFieldName.MarketingNotificationsSms]:
      values.marketingNotifications.sms,
    [PreferenceFormFieldName.OrderRemindersSms]: values.orderReminders.sms,
    [PreferenceFormFieldName.OrderUpdatesSms]: values.orderUpdates.sms,
  };
}

export function usePreferencesForm(props: UsePreferencesFormProps) {
  return useFormik<UsePreferencesFormProps>({
    initialValues: props,
    onSubmit: noop,
  });
}
