import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectProgress } from 'src/reducers/signup/progress';
import { getOverallProgress } from 'src/reducers/signup/utils/calculateProgress';
import omit from 'lodash/omit';
import { useLocation } from 'react-router';
import {
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

export default function useProgress() {
  const { pathname } = useLocation();
  const initialProgress = useSelector(selectProgress);

  return useMemo(() => {
    // on checkout page user can clear firstName and zip which will incorrectly recalc progress
    const meProgress =
      pathname.includes(PATH_SIGNUP_CHECKOUT) ||
      pathname.includes(PATH_SIGNUP_RECIPES)
        ? omit(initialProgress.me, 'phone', 'firstName', 'zip')
        : omit(initialProgress.me, 'phone');

    let newProgress = {
      ...initialProgress,
      me: meProgress,
    };
    return {
      ...newProgress,
      overall: getOverallProgress(newProgress),
    };
  }, [initialProgress, pathname]);
}
