import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { ReactNode } from 'react';

interface DetailsSectionProps {
  heading: string;
  children?: ReactNode;
}

export function DetailsSection({ heading, children }: DetailsSectionProps) {
  return (
    <Grid
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      vSpacing="sm"
      rowGap="sm"
    >
      <GridItem>
        <Text variant={'heading-22'} color={'kale-3'} as="h2" bold>
          {heading}
        </Text>
      </GridItem>
      {children && <GridItem>{children}</GridItem>}
    </Grid>
  );
}
