import { Modal, Divider } from '@farmersdog/corgi';
import { MARGIN_MD, OYSTER_0 } from '@farmersdog/corgi/build/constants';
import { Button, Text } from '@farmersdog/corgi-x';

import { useGlobalLightbox, LightboxId } from '../../../hooks';

import styles from './RecoveryModal.module.css';

interface RecoveryModalProps {
  petNames: string[];
  handleRecover: () => void;
  handleStartOver: () => void;
  loading: boolean;
}
export function RecoveryModal({
  handleRecover,
  handleStartOver,
  loading,
}: RecoveryModalProps) {
  const lightboxControl = useGlobalLightbox({
    id: LightboxId.RecoveryModal,
  });

  return (
    <Modal {...lightboxControl} disableInteractions={true}>
      <Text
        as="h2"
        variant="heading-40"
        color="kale-3"
        bold
        className={styles.title}
        {...lightboxControl.labelProps}
      >
        Hi again!
      </Text>
      <Text variant="article-16" as="p" {...lightboxControl.descriptionProps}>
        Looks like you’ve already started to answer the questionnaire for your
        pet(s). Would you like to retrieve your previous answers?
      </Text>
      <Divider margin={MARGIN_MD} color={OYSTER_0} orientation="horizontal" />
      <div className={styles.buttons}>
        <div className={styles.recover}>
          <Button disabled={loading} loading={loading} onClick={handleRecover}>
            Yes, recover answers
          </Button>
        </div>
        <div className={styles.startOver}>
          <Button
            variant="plain-text"
            aria-disabled={loading}
            loading={loading}
            onClick={handleStartOver}
          >
            No, start over
          </Button>
        </div>
      </div>
    </Modal>
  );
}
