import { PATH_ORDERS } from '@farmersdog/constants/paths';

import { getHomepagePath } from './getHomepagePath';

import * as Types from '../../graphql/types.core-api';

interface GetDefaultAccountPathArgs {
  withRegularOrder: boolean;
  isSubscriptionActive: boolean;
  subscriptionType?: Types.SubscriptionType | null;
}

export function getDefaultAccountPath(args: GetDefaultAccountPathArgs) {
  const homepagePath = getHomepagePath(args);

  if (!homepagePath) {
    return PATH_ORDERS;
  }

  return homepagePath;
}
