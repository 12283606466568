/**
 * Get the pre tax total of a trial quote price. This is a commonly used value
 * that is submitted to our analytics events.
 *
 * @param subtotalAmount - The subtotal amount of the quote.
 */
function getPreTaxTotal(subtotalAmount: number) {
  return (subtotalAmount / 100).toFixed(2);
}

export default getPreTaxTotal;
