import { useCallback } from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import requestPasswordResetMutation from './requestPasswordReset.graphql';
import {
  RequestPasswordResetInput,
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from 'src/graphql/types';

interface UseResetPasswordArgs {
  onCompleted?: (data: RequestPasswordResetMutation) => void;
  onError?: (error: ApolloError) => void;
}

type UseRequestPasswordReset = [
  (input: RequestPasswordResetInput) => Promise<void>,
  {
    loading: boolean;
    error?: ApolloError;
  },
];

export function useRequestPasswordReset({
  onCompleted,
  onError,
}: UseResetPasswordArgs = {}): UseRequestPasswordReset {
  const [requestPasswordReset, requestPasswordResetState] = useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(requestPasswordResetMutation, {
    onCompleted,
    onError,
  });

  const submit = useCallback(
    async (input: RequestPasswordResetInput) => {
      await requestPasswordReset({
        variables: { input },
      });
    },
    [requestPasswordReset]
  );

  return [submit, requestPasswordResetState];
}
