const initialState = {
  defaultTreatments: undefined,
};

export default function splitioReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function selectDefaultTreatments(state) {
  return state.splitio.defaultTreatments;
}
