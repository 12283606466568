import { ApolloError, useQuery } from '@apollo/client';
import { ApplicationError } from '../../../errors';
import { FetchBufferSummaryDataQuery } from './FetchBufferSummaryData.core-api.generated-types';
import fetchBufferSummaryData from './FetchBufferSummaryData.core-api.graphql';
import { reporter } from '../../../errors/services';

interface BufferSummaryData {
  justInTimeEnabled: boolean;
  bufferDays: number;
  recommendedDays: number;
}

export interface UseFetchBufferSummaryData {
  loading: boolean;
  error?: ApolloError | ApplicationError;
  data?: BufferSummaryData;
}

interface UseFetchBufferSummaryDataProps {
  onError: (error: unknown) => void;
}

export function useFetchBufferSummaryData(
  props: UseFetchBufferSummaryDataProps
): UseFetchBufferSummaryData {
  const request = useQuery<FetchBufferSummaryDataQuery>(
    fetchBufferSummaryData,
    { context: { endpoint: 'core-api' }, onError: props.onError }
  );

  if (request.loading) {
    return { loading: request.loading };
  }

  if (request.error) {
    return { loading: false, error: request.error };
  }

  if (
    !request.data ||
    request.data.me.subscriptions[0].justInTimeEnabled === null ||
    request.data.me.subscriptions[0].bufferRange === null ||
    request.data.me.subscriptions[0].bufferDays === null
  ) {
    reporter.warn('Invalid BufferSummary data');
    return {
      loading: false,
      error: new ApplicationError(
        'Unknown exception occured while loading data'
      ),
    };
  }

  return {
    loading: false,
    data: {
      justInTimeEnabled: request.data.me.subscriptions[0].justInTimeEnabled,
      bufferDays: request.data.me.subscriptions[0].bufferDays,
      recommendedDays:
        request.data.me.subscriptions[0].bufferRange.recommendedDays,
    },
  };
}
