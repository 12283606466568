import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { browserOnly } from '@farmersdog/utils';

import { ga4Mounter } from './Ga4Mounter';

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a Ga4 page view event.
 */
export function useMountGa4PageView() {
  const location = useLocation();
  const { pathname, search, hash } = location;

  const origin = browserOnly(
    (window: Window) => window.location.origin,
    () => ''
  );

  useEffect(() => {
    ga4Mounter.pageView({ location: { pathname, search, hash, origin } });
  }, [pathname, hash, search, origin]);
}
