import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { TextV2 } from '@farmersdog/corgi';
import { Button, Divider } from '@farmersdog/corgi-x';

import { MARGIN_MD } from '@farmersdog/corgi/build/constants';

import { selectPetNames } from 'src/reducers/signup/user';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { TITLE } from './copy';

import { formatNames } from 'src/utils/formatters';

function Submit({ finalMessage }) {
  const petNames = useSelector(selectPetNames);

  const defaultMessage = `Thanks for your feedback! We’ll be hard at work to help accommodate ${formatNames(
    petNames,
    { possessive: true }
  )} needs.`;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy align="center">
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          {finalMessage || defaultMessage}
        </TextV2>
        <Divider margin={MARGIN_MD} color="oyster-0" orientation="horizontal" />
        <Button variant="primary" type="submit">
          Continue Signup
        </Button>
      </TitleWithCopy>
    </>
  );
}

Submit.propTypes = {
  finalMessage: PropTypes.node,
};

export const SLIDE_NAME = 'SUBMIT';

export default Submit;
