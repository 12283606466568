import classNames from 'classnames';
import { Text, Nowrap } from '@farmersdog/corgi-x';

import { useCorgiViewport } from 'src/screen';
import UnderlineDecorator from 'src/components/UnderlineDecorator';

import { HomepageTitle1 } from '../HomepageTitle';
import HomepageText from '../HomepageText';

import styles from './SectionDogContent.module.scss';

function PlanFeatures() {
  const responsiveClassName = useCorgiViewport({
    sm: styles.sm,
    md: styles.md,
    lg: styles.lg,
  });

  const align = useCorgiViewport({ xs: 'center', sm: 'left' });

  return (
    <div className={classNames(styles.container, responsiveClassName)}>
      <HomepageTitle1 className={styles.heading}>
        Better for them.
        <br />
        Easier for you.
      </HomepageTitle1>
      <HomepageText className={styles.subheading}>
        Skip the stores, preservatives, and retail markups. Our vet-developed
        plans guide you to the best diet, while perfectly-timed frozen
        deliveries make sure you <Nowrap>never run out</Nowrap>.
      </HomepageText>
      <UnderlineDecorator center={align === 'center'}>
        <Text as="h3" variant="heading-22" color="kale-3" bold>
          Plan Features
        </Text>
      </UnderlineDecorator>
      <div
        className={classNames(
          styles.headlineListContainer,
          responsiveClassName
        )}
      >
        <Text
          as="h4"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.text}
        >
          Free & Flexible
          <br />
          Deliveries
        </Text>
        <Text
          as="h4"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.text}
        >
          Nationwide
          <br />
          Shipping
        </Text>
        <Text
          as="h4"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.text}
        >
          Eco Friendly
          <br />
          Packaging
        </Text>
        <Text
          as="h4"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.text}
        >
          24-Hour
          <br />
          Customer Service
        </Text>
      </div>
    </div>
  );
}

export default PlanFeatures;
