import { comparePetUri } from 'src/utils/petUri';

/**
 * Get the petId given a location
 *
 * @param {Location} location
 * @param {Array} pets
 * @param {Array} activePets
 * @returns {Number}
 */
function getPetId(petName, pets = [], activePetIndex = 0) {
  const locationPetIndex = pets.findIndex(pet =>
    comparePetUri(pet.name, petName)
  );

  const index = locationPetIndex < 0 ? activePetIndex : locationPetIndex;
  const id = pets[index] ? pets[index].id : undefined;

  return id;
}

export default getPetId;
