import {
  Button,
  Divider,
  Grid,
  GridItem,
  Section,
  Text,
  Toast,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import { PetSexOptions, RecipeOptions, UseAddDogFormFields } from '../../hooks';
import { AddDogContent } from '../AddDogContent';

import {
  bodyConditionResourcesSmall,
  activityLevelResource,
  freshFoodPictureSources,
  eatingStyleResources,
  EatingStyle,
  RecipeNames,
} from '../../../../../assets';
import {
  CgsActivityLevel,
  CgsBodyCondition,
} from '../../../../../graphql/types.cgs';
import { ProfileItem } from './components/ProfileItem';

import { PictureWithHeadingItem } from './components/PictureWithHeadingItem';

import styles from './ReviewScene.module.css';
import { pluralizeString } from '../../../../../utils';
import { reporter } from '../../../../../errors/services';

export const PRIMARY_CTA_COPY = 'Request My New Daily Price';
export const DEFAULT_RECIPE_CTA = 'Or Select Your Own Recipes';
export const USER_SELECTED_RECIPE_CTA = 'Edit Selected Recipes';

interface ReviewSceneProps {
  formValue: UseAddDogFormFields;
  availableRecipes: RecipeOptions | undefined;
  isRecommendedPlan: boolean;
  onCtaClick: () => void;
  onSelectedRecipesClick: () => void;
  isSubmitting: boolean;
  showSubmissionErrorToast: boolean;
  onSubmissionErrorToastClose: () => void;
}

export const ReviewScene = (props: ReviewSceneProps) => {
  const {
    onCtaClick,
    formValue,
    availableRecipes,
    onSelectedRecipesClick,
    isSubmitting,
    isRecommendedPlan,
    showSubmissionErrorToast,
    onSubmissionErrorToastClose,
  } = props;
  const {
    name,
    breed,
    bodyCondition,
    ageNumber,
    ageUnit,
    currentWeight,
    targetWeight,
    sterilization,
    sex,
    activityLevel,
    eatingStyle,
    healthIssues,
    petDietIssues,
    recipes,
  } = formValue;

  if (!availableRecipes) {
    reporter.error('Available recipes not defined');
    throw new Error('Available recipes are required for Add Pet form');
  }

  const isAgeNumberSingular = ageNumber === 1;
  const petAgeUnit = isAgeNumberSingular ? ageUnit.slice(0, -1) : ageUnit;
  const age = ageNumber + ' ' + petAgeUnit.toLowerCase() + ' old';
  const capializedSex = sex.charAt(0).toUpperCase() + sex.slice(1);

  // Remove spaces from the strings to match the keys in the image resources
  const bodyConditionSource = bodyCondition.replace(/\s+/g, '');
  const activityLevelSource = activityLevel.replace(/\s+/g, '');
  const petEatingStyleSource = eatingStyle.replace(/\s+/g, '');

  const petWeight = pluralizeString(currentWeight, 'lb', 'lbs', {
    prependQuantity: true,
  });

  const petTargetWeight =
    targetWeight &&
    pluralizeString(targetWeight, 'lb', 'lbs', {
      prependQuantity: true,
    });

  const hasHealthIssues = healthIssues && healthIssues.length > 0;
  const haspetDietIssues = petDietIssues && petDietIssues.length > 0;

  const recipePluralization = pluralizeString(
    recipes.length,
    'Recipe',
    'Recipes'
  );

  const recommendedRecipeHeading = (
    <>
      Our Recommended {recipePluralization}
      <br />
      for {name}
    </>
  );

  const userSelectedRecipeHeading = (
    <>
      Your Selected {recipePluralization}
      <br />
      for {name}
    </>
  );

  return (
    <AddDogContent
      heading={`Review ${name}'s Information`}
      onCtaClick={onCtaClick}
      sectionWidthConstraint="sm"
      cta={PRIMARY_CTA_COPY}
      ctaLoading={isSubmitting}
    >
      <div className={styles.sectionContainer}>
        <Section aria-labelledby="profile">
          <Text
            as="h2"
            variant="heading-22"
            id="profile"
            color="kale-3"
            bold
            bottomSpacing="sm"
            topSpacing="lg"
          >
            Profile
          </Text>
          <ProfileItem name="Age" value={age} />
          <ProfileItem name="Breed" value={breed} />
          <ProfileItem name="Sex" value={capializedSex} />
          <ProfileItem
            name={sex === PetSexOptions.Male ? 'Neutered' : 'Spayed'}
            value={sterilization === true ? 'Yes' : 'No'}
          />
          <ProfileItem name="Current Weight" value={petWeight} />
          {targetWeight && (
            <ProfileItem name="Ideal Weight" value={petTargetWeight} />
          )}

          <Grid topSpacing="md" justifyContent="flex-start">
            <PictureWithHeadingItem
              alt={`Dog with ${bodyCondition} body condition`}
              sources={
                bodyConditionResourcesSmall[
                  bodyConditionSource as CgsBodyCondition
                ]
              }
              value={bodyCondition}
            />
            <PictureWithHeadingItem
              alt={`Dog with ${activityLevel} activity level`}
              sources={
                activityLevelResource[activityLevelSource as CgsActivityLevel]
              }
              value={activityLevel}
            />
            <PictureWithHeadingItem
              alt="Dog with bowl of food"
              sources={
                eatingStyleResources[petEatingStyleSource as EatingStyle]
              }
              value={eatingStyle}
            />
          </Grid>
        </Section>
        <Divider color="Charcoal0" spacing={24} />
        {hasHealthIssues && (
          <Section
            aria-labelledby="health-issues"
            bottomSpacing={haspetDietIssues ? 'md' : 'none'}
          >
            <Text
              as="h2"
              variant="heading-22"
              color="kale-3"
              bold
              bottomSpacing="xs"
              id="health-issues"
            >
              Health Issues
            </Text>
            <Text variant="heading-16" as="p" vSpacing="none">
              {healthIssues &&
                healthIssues.length > 0 &&
                healthIssues.join(', ')}
            </Text>
          </Section>
        )}
        {haspetDietIssues && (
          <Section aria-labelledby="therapeutic-diets">
            <Text
              as="h2"
              variant="heading-22"
              color="kale-3"
              bold
              bottomSpacing="xs"
              id="therapeutic-diets"
            >
              Therapeutic Diets
            </Text>
            <Text
              variant="heading-16"
              as="p"
              bottomSpacing="md"
              topSpacing="none"
            >
              {petDietIssues &&
                petDietIssues.length > 0 &&
                petDietIssues.join(', ')}
            </Text>
          </Section>
        )}
        {(hasHealthIssues || haspetDietIssues) && (
          <Divider color="Charcoal0" spacing={24} />
        )}

        <Section aria-labelledby="recipes" bottomSpacing="md">
          <Text
            as="h2"
            variant="heading-22"
            color="kale-3"
            bold
            bottomSpacing="none"
            id="recipes"
            aria-label={`Current ${recipePluralization} For ${name}`}
          >
            {isRecommendedPlan
              ? recommendedRecipeHeading
              : userSelectedRecipeHeading}
          </Text>
          <Grid topSpacing="md" justifyContent="center">
            {recipes.map((recipe, index) => {
              // For recipes, we are storing the recipe name (ex: CHICKEN_CHIA, CHICKEN_OATS`,etc) for the value
              // but we also need to display the recipe display name.
              const currentRecipe = availableRecipes.find(
                recipeOption => recipeOption.name === recipe
              );
              if (!currentRecipe) return null;
              return (
                <PictureWithHeadingItem
                  variant="recipe"
                  key={index}
                  alt={`${currentRecipe.displayName} recipe`}
                  sources={
                    freshFoodPictureSources[currentRecipe.name as RecipeNames]
                  }
                  value={currentRecipe.displayName}
                />
              );
            })}
          </Grid>
          <Grid topSpacing="md">
            <GridItem xs={12} justifyContent="center">
              <Button
                variant="plain-text"
                type="button"
                onClick={onSelectedRecipesClick}
                underline
              >
                {isRecommendedPlan
                  ? DEFAULT_RECIPE_CTA
                  : USER_SELECTED_RECIPE_CTA}
              </Button>
            </GridItem>
          </Grid>
        </Section>
      </div>
      <Toast
        isOpen={showSubmissionErrorToast}
        sentiment={ToastSentiment.Negative}
        onClose={onSubmissionErrorToastClose}
        message="There was an error submitting your request. Please try again."
      />
    </AddDogContent>
  );
};
