import classNames from 'classnames';
import { Text } from '@farmersdog/corgi-x';

import { useCorgiViewport } from 'src/screen';
import Picture from 'src/components/Picture';

import styles from './Hero.module.scss';
import { background, backgroundMobile } from './assets/backgrounds';

export default function Hero() {
  const sources = useCorgiViewport({ xs: backgroundMobile, md: background });
  return (
    <>
      <section
        className={classNames([styles.container, useCorgiViewport(styles)])}
      >
        <Picture sources={sources} alt="" className={styles.background} />
        <div className={styles.gradient} />
        <Text
          as="h1"
          variant="heading-52"
          color="white"
          bold
          className={styles.header}
        >
          Better for them, easier for you.
        </Text>
      </section>
    </>
  );
}
