import { useRef, useEffect } from 'react';

import { loadGoogleApi } from './loadGoogleApi';

export interface GoogleMapsScriptProps {
  onLoad?: (google: typeof window.google) => void;
  onError?: (error: Error) => void;
  googleMapsApiKey: string;
}

/*
 * api key must be passed as an arg from the config value
 */

function GoogleMapsScript({
  onLoad,
  onError,
  googleMapsApiKey,
}: GoogleMapsScriptProps) {
  const onLoadRef = useRef(onLoad);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onLoadRef.current = onLoad;
    onErrorRef.current = onError;
  }, [onLoad, onLoadRef, onErrorRef, onError]);

  useEffect(() => {
    void loadGoogleApi({ googleMapsApiKey })
      .then(google => {
        if (google && onLoadRef.current) {
          onLoadRef.current(google);
        }
      })
      .catch(error => {
        if (onErrorRef.current) {
          onErrorRef.current(error as Error);
        }
      });
  }, [onLoadRef, onErrorRef, googleMapsApiKey]);

  return null;
}

export default GoogleMapsScript;
