import { BodyConditionName } from '@farmersdog/constants';

import { getGenderPossessivePronoun } from '../utils/getGenderPossessivePronoun';
import { getGenderSubjectPronoun } from '../utils/getGenderSubjectPronoun';

import {
  checkBoardPlaceholderSrc,
  checkBoardSrc,
  foodBowlPlaceholderSrc,
  foodBowlSrc,
  foodPacksPlaceholderSrc,
  foodPacksSrc,
} from './assets';
import styles from './WeightLossItem.module.css';

interface WeightLossItemsArgs {
  bodyCondition: string;
  petGender: string;
}

export const weightLossItems = ({
  bodyCondition,
  petGender,
}: WeightLossItemsArgs) => {
  const subjectPronoun = getGenderSubjectPronoun(petGender);
  const possessivePronoun = getGenderPossessivePronoun(petGender);

  return [
    {
      title: 'Guess-Free Portioning',
      description: (
        <>
          We’ll deliver {possessivePronoun} food in portions personalized to{' '}
          {possessivePronoun} weight loss goals.
        </>
      ),
      src: {
        placeholder: foodPacksPlaceholderSrc,
        animation: foodPacksSrc,
      },
    },
    {
      title: 'Helpful Support',
      description: (
        <>
          <span className={styles.largeWrap}>
            We’ll check in on {possessivePronoun} progress as{' '}
            {possessivePronoun} body
          </span>{' '}
          <span className={styles.largeWrap}>
            condition changes from <span>{bodyCondition}</span> to{' '}
            {BodyConditionName.JustRight.toLowerCase()}.
          </span>
        </>
      ),
      src: {
        placeholder: checkBoardPlaceholderSrc,
        animation: checkBoardSrc,
      },
    },
    {
      title: 'Easy Adjustments',
      description: (
        <>
          We’ll suggest updates so {subjectPronoun}’s always getting the right
          amount of food.
        </>
      ),
      src: {
        placeholder: foodBowlPlaceholderSrc,
        animation: foodBowlSrc,
      },
    },
  ];
};
