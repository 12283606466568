import { ReactNode } from 'react';

import Animation from './Animation';
import { Behavior } from './types';

interface FadeProps {
  children: ReactNode;
  behavior: Behavior;
  in: boolean;
}

export const Fade = ({ children, ...props }: FadeProps) => (
  <Animation name="Fade" {...props}>
    {children}
  </Animation>
);
