import { FC } from 'react';

import { Text } from '@farmersdog/corgi-x';

import StarCheckIcon from '../assets/icons/star-check.svg';
import StarIcon from '../assets/icons/star.svg';

import styles from './Testimonial.css';

const NUM_STARS = 5;

export interface TestimonialProps {
  fullName: string;
  quote: string;
  pets: {
    name: string;
    age: number;
    breed: string;
    issues: string[];
    pickyEater: boolean;
  }[];
}

export const Testimonial: FC<TestimonialProps> = ({
  fullName,
  quote,
  pets,
}) => {
  //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const stars = [...new Array(NUM_STARS)];
  return (
    <div className={styles.testimonial}>
      <div
        style={{ display: 'flex' }}
        role="img"
        aria-label={`Rating ${NUM_STARS} out of 5`}
      >
        {stars.map((_, i) => (
          <StarIcon
            aria-hidden
            key={`${fullName}-star-${i}`}
            className={styles.starIcon}
          />
        ))}
      </div>
      <cite style={{ margin: 0, padding: 0, fontStyle: 'normal' }}>
        <Text
          as="h3"
          variant="heading-22"
          color="kale-3"
          className={styles.testimonialText}
          bold
        >
          <small>{fullName}</small>
        </Text>
        <StarCheckIcon className={styles.starcheck} aria-hidden />
      </cite>
      <Text
        as="p"
        variant="article-16"
        color="charcoal-3"
        style={{ marginTop: 4, marginBottom: 0 }}
      >
        {pets
          .slice(0, 2)
          .map(pet => `${pet.name} (${pet.age} year old ${pet.breed})`)
          .join(', ')}
      </Text>
      <Text
        variant="article-16"
        as="blockquote"
        color="charcoal-3"
        style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
      >
        {quote}
      </Text>
    </div>
  );
};
