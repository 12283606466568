import { useClient } from '@splitsoftware/splitio-react';
import { useEffect, useState } from 'react';

interface UseSplitClientReturn {
  /** Has the split client finished loading */
  isReady: boolean;
  /** Split client returned from useClient */
  splitClient: SplitIO.IClient | null;
}

/**
 * A cache to enable future calls to `useSplitClient` to be initially ready.
 * This will avoid a moment of the control treatment being provided if the
 * client is already loaded.
 */
export const cachedSplitClients: Record<string, boolean> = {};

/**
 * Wraps Split's useClient in a custom hook to manage the isReady state. This
 * allows hooks to not have to await a promise and instead react to a state
 * change. Attaches to a cache variable to allow future calls to set isReady to
 * true to avoid a brief control treatment.
 *
 * @param splitKey - The key of the new client. Commonly the user or anonymous id.
 */
export const useSplitClient = (splitKey: string): UseSplitClientReturn => {
  const splitClient = useClient(splitKey);
  const [readyState, setReadyState] = useState(cachedSplitClients);

  useEffect(() => {
    if (!splitClient || readyState[splitKey]) {
      return;
    }

    void (async () => {
      try {
        await splitClient?.ready();
      } catch {
        // Nothing to do split failed to load
      } finally {
        // Always set to ready, this will skip the loading state to consumers.
        // If Split failed to load will return control.
        setReadyState({ ...readyState, [splitKey]: true });
        cachedSplitClients[splitKey] = true;
      }
    })();
  }, [readyState, splitClient, splitKey]);

  return {
    splitClient,
    isReady: readyState[splitKey] ?? false,
  };
};
