type InterceptDOMEventProps<E> = (undefined | ((event: E) => void))[];

/**
 * Return an event handler that will run multiple events in the order that they
 * are passed as arguments.
 */
export const interceptDOMEvent = <E>(
  ...handlers: InterceptDOMEventProps<E>
) => {
  return (e: E) => {
    handlers.forEach(handler => {
      if (handler) {
        handler(e);
      }
    });
  };
};
