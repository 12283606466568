import { Fragment } from 'react';

import {
  FraudSupportLink,
  SignupSupportLink,
} from '../../../shared/ContactSupportLinks';

import { ErrorTemplateToken, errorTemplateTokenSet } from './tokens';

export function getComponentByToken(token: string) {
  switch (token) {
    case ErrorTemplateToken.SignupSupportButton: {
      return SignupSupportLink;
    }

    case ErrorTemplateToken.FraudSupportButton: {
      return FraudSupportLink;
    }

    default: {
      return Fragment;
    }
  }
}

export function isErrorTemplateToken(
  token: string
): token is ErrorTemplateToken {
  return errorTemplateTokenSet.has(token as ErrorTemplateToken);
}
