import { usePaymentRetryOrderData } from './network/usePaymentRetryOrderData';
import {
  ActivityIndicator,
  Divider,
  Grid,
  GridItem,
  Page,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import styles from './features/PaymentRetry.module.css';
import {
  PAW_PATH_PAYMENT_RETRY_SUCCESS,
  PAW_PATH_PAYMENT_RETRY_FAILURE,
  PATH_PROFILE,
} from '@farmersdog/constants/paths';
import { useHistory } from 'react-router';
import { reporter } from '../errors/services';
import { formatDateRange } from '../utils/formatting/formatDateRange';
import { PriceBreakdown } from './features/PriceBreakdown';
import { formatCurrency, WeekdayNumber } from '../utils';
import { useBillNowMutation } from './network';
import { PaymentRetryWrapper } from './features/PaymentRetryWrapper';
import { StickyButton } from './features/StickyButton';
import {
  trackPaymentRetrySuccess,
  trackPaymentRetryFailure,
  trackPaymentRetryExited,
} from './analytics';
import { OrderPaymentStatus } from '../graphql/types.core-api';
import { useExperimentContext } from '../experiments';

export function PaymentRetry() {
  const history = useHistory();
  const { useFeature } = useExperimentContext();
  const { data, loading, error } = usePaymentRetryOrderData();
  const { submit, isSubmitting } = useBillNowMutation({
    onCompleted: response => {
      if (response.billNow.paymentStatus === OrderPaymentStatus.Failed) {
        trackPaymentRetryFailure();
        history.push(PAW_PATH_PAYMENT_RETRY_FAILURE);
      } else if (
        response.billNow.paymentStatus === OrderPaymentStatus.Processing
      ) {
        trackPaymentRetrySuccess();
        history.push(PAW_PATH_PAYMENT_RETRY_SUCCESS);
      }
    },
    onError: (e: Error) => {
      trackPaymentRetryFailure();
      reporter.error(new Error(e.message), {
        sourceError: e,
      });
      history.push(PAW_PATH_PAYMENT_RETRY_FAILURE);
    },
  });

  const gracePeriodFeature = useFeature?.('adj_grace_period_experiment_on', {});

  if (loading || isSubmitting) {
    return (
      <Page layout="base-centered" className={styles.loadingContainer}>
        <ActivityIndicator mode="dark" />
      </Page>
    );
  }
  if (error) {
    reporter.error(new Error('Error loading PaymentRetry Data'), {
      sourceError: error,
    });

    return null;
  }

  if (!data) {
    reporter.error(new Error('Unknown exception occurred while loading data'), {
      sourceError: error,
    });

    return null;
  }

  const nextOrderToBeDelivered = data.customer.nextOrderToBeDelivered;
  const lastQuotedPrice = data.customer.freshSubscription.lastQuotedPrice;

  const handleOnClose = () => {
    trackPaymentRetryExited();
    history.push(PATH_PROFILE);
  };
  const handleOnCTAClick = () => {
    submit({ orderId: nextOrderToBeDelivered.id });
  };
  const heading = 'Complete Your Order';
  const subHeading = `Your billing info has been saved. Try charging your card now to ensure your next order arrives on time.`;
  const cardHeadlineText = 'Your Next Order';
  const arrivalWindow = formatDateRange(
    nextOrderToBeDelivered.deliveryWindow.earliestDesiredArrivalDate,
    nextOrderToBeDelivered.deliveryWindow.latestDesiredArrivalDate,
    { abbreviatedMonth: true }
  );
  const cardSublineText = `Estimated Arrival: ${arrivalWindow}`;
  const ctaText = `Retry Payment for ${formatCurrency(nextOrderToBeDelivered.totalOrderPriceWithCreditsApplied)}`;
  const dayOfWeek = new Date().getDay();

  let ctaSublineText =
    'If you would like to wait, we will automatically attempt billing again within 24 hours.';

  if (gracePeriodFeature?.treatment === 'on') {
    switch (dayOfWeek) {
      case WeekdayNumber.Monday:
        ctaSublineText =
          'If you would like to wait, we will automatically attempt billing again later this week.';
        break;
      case WeekdayNumber.Tuesday:
      case WeekdayNumber.Saturday:
        ctaSublineText =
          'If you would like to wait, we will automatically attempt billing again within 48 hours.';
        break;
      case WeekdayNumber.Wednesday:
      case WeekdayNumber.Thursday:
      case WeekdayNumber.Friday:
      case WeekdayNumber.Sunday:
      default:
        ctaSublineText =
          'If you would like to wait, we will automatically attempt billing again within 24 hours.';
        break;
    }
  }

  const ctaButtonDataTestId = 'payment_retry_cta';

  return (
    <PaymentRetryWrapper>
      <Page layout="base" onCloseClick={handleOnClose}>
        <Grid
          className={styles.grid}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem>
            <Text
              as="h1"
              variant="heading-40"
              color="kale-3"
              bold
              data-testid="headline_text"
            >
              {heading}
            </Text>
            <Divider borderStyle="none" />
            <Text
              variant="article-16"
              color="charcoal-3"
              data-testid="subline_text"
              className={styles.subHeading}
            >
              {subHeading}
            </Text>
          </GridItem>
          <GridItem className={styles.cardContainer}>
            <Section
              aria-label={cardHeadlineText}
              variant="card"
              className={styles.card}
            >
              <div className={styles.cardHeadline}>
                <Text
                  as="h1"
                  variant="heading-28"
                  color="kale-3"
                  bold
                  data-testid="card_headline_text"
                >
                  {cardHeadlineText}
                </Text>
                <Text
                  as="h1"
                  vSpacing="md"
                  variant="heading-16"
                  color="charcoal-3"
                  bold
                  data-testid="card_subline_text"
                >
                  {cardSublineText}
                </Text>
              </div>
              {/* TODO: Review and replace pricing data points with accurate ones */}
              <PriceBreakdown
                mealsTotal={nextOrderToBeDelivered.mealsTotal}
                addOnsTotal={nextOrderToBeDelivered.addOnsTotal}
                taxTotal={nextOrderToBeDelivered.taxTotal}
                pets={nextOrderToBeDelivered.pets}
                pricePerDay={lastQuotedPrice.dailyConsumptionPrice}
                discountTotal={nextOrderToBeDelivered.discountTotal}
                applicableCredits={nextOrderToBeDelivered.applicableCredits}
                totalOrderPriceWithCreditsApplied={
                  nextOrderToBeDelivered.totalOrderPriceWithCreditsApplied
                }
              />
            </Section>
          </GridItem>
          {gracePeriodFeature?.isReady ? (
            <StickyButton
              handleOnCTAClick={handleOnCTAClick}
              buttonVariant="primary"
              buttonDataTestId={ctaButtonDataTestId}
              ctaText={ctaText}
              ctaSublineText={ctaSublineText}
              ctaSublineTextTestId="payment_retry_cta_subline_text"
            />
          ) : (
            <ActivityIndicator mode="dark" />
          )}
        </Grid>
      </Page>
    </PaymentRetryWrapper>
  );
}
