import get from 'lodash/get';
import set from 'lodash/set';

import { NodeProgress } from '../hooks/useProgress';
import { FormFieldsType } from '../types';

interface GetCompletedDataArgs {
  data: FormFieldsType;
  completedLeafNodes: NodeProgress[];
}

export function getCompletedData({
  data,
  completedLeafNodes,
}: GetCompletedDataArgs): FormFieldsType {
  const completedFields = completedLeafNodes.map(node => node.name);

  return completedFields.reduce(
    (accum: FormFieldsType, key: string): FormFieldsType => {
      const value = get(data, key) as unknown;

      if (value !== undefined) {
        set(accum, key, value);
      }

      return accum;
    },
    {} as FormFieldsType
  );
}
