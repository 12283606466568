/* CH#49502: Used by RET and CVR */
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';

/**
 * Wrapper of React Router Redirect to maintain a search string on redirect
 */
export function RedirectWithSearch({ to, ...props }: RedirectProps) {
  const location = useLocation();
  const search = location.search;
  const redirectTo =
    typeof to === 'object' ? { ...to, search } : { pathname: to, search };

  return <Redirect {...props} to={redirectTo} />;
}
