import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { FooterSocialLinks } from './FooterSocialLinks';

import { EMAIL_PRESS } from 'src/constants/emails';
import { LINK_PARTNERSHIP_INQUIRIES } from 'src/constants/links';
import styles from './FooterLinks.module.scss';

export const FooterContactLinks = () => {
  return (
    <>
      <Text as="h4" variant="heading-16" color="white" bold topSpacing="sm">
        Connect
      </Text>
      <ul className={styles.list} aria-label="Contact">
        <li>
          <Link variant="secondary" href={`mailto:${EMAIL_PRESS}`}>
            <Text variant="heading-16">Media Inquiries</Text>
          </Link>
        </li>
        <li>
          <Link
            id="partnership-inquiries"
            variant="secondary"
            href={LINK_PARTNERSHIP_INQUIRIES}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text variant="heading-16">Partnership Inquiries</Text>
          </Link>
        </li>
      </ul>
      <FooterSocialLinks />
    </>
  );
};

export default FooterContactLinks;
