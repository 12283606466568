import classNames from 'classnames';
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  useIntersection,
  HorizontalNavigationItem,
  HorizontalNavigation,
} from '@farmersdog/corgi';
import { ContentReveal, PageWidth } from '@farmersdog/corgi-x';
import { isDiySignup } from '@farmersdog/lead-browser-storage';

import useProgress from 'src/pages/Signup/hooks/useProgress';
import { useCorgiV2Viewport } from 'src/screen';
import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';
import {
  PlanSummary,
  PlanSummaryLayout,
} from 'src/pages/SignupRecipesPageDiy/components/PlanSummary';
import { useMixingPlanTreatment } from '../../../../hooks/useMixingPlanTreatment';
import { MixingPlanTreatments } from '../../../../abTesting';
import { selectActivePets } from '../../../../reducers/signup/user';

import styles from './PetSelectorV4.module.scss';
import { PATH_SIGNUP_RECIPES } from '@farmersdog/constants/paths';

interface PetSelectorV4Prop {
  fadeEl: Element;
}

function PetSelectorV4({ fadeEl }: PetSelectorV4Prop) {
  const { routesData, current } = useSignupRouter();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { overall: overallProgress } = useProgress();

  const intersection = useIntersection(fadeEl);

  const reveal = !intersection?.isIntersecting;
  const isOnDiySignup = isDiySignup();
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const mixingPlanTreatment = useMixingPlanTreatment();

  const headerSummaryComponent = isOnDiySignup ? (
    <PlanSummary layout={PlanSummaryLayout.Header} />
  ) : null;

  const {
    responsiveClassName,
    stickyHeaderSummary,
    showCarouselButtons,
    showComponentOnScroll,
  } = useCorgiV2Viewport({
    xs: {
      responsiveClassName: '',
      stickyHeaderSummary: null,
      showCarouselButtons: false,
      showComponentOnScroll: false,
    },
    lg: {
      responsiveClassName: styles.lg,
      stickyHeaderSummary: (
        <ContentReveal in={reveal} inAnimation="fade">
          {headerSummaryComponent}
        </ContentReveal>
      ),
      showCarouselButtons: true,
      showComponentOnScroll: true,
    },
  });

  const activePetNames = useSelector(selectActivePets).map(p => p.name);

  // Only show this for DIY recipes and topper plans segments
  if (!current || (!current.recipes && !current.plans)) {
    return null;
  }

  const petRoutes = routesData.filter(
    data => data.recipes && data.title && activePetNames.includes(data.title)
  );

  const currentIndex = petRoutes.findIndex(data => data.path === current.path);
  const isPlanRoute = current.recipes || current.plans;
  const links = petRoutes.map((data, index) => {
    const active = data.path === current.path;
    const disabled = !!current.pets && index > currentIndex;

    return (
      <HorizontalNavigationItem
        to={data.path}
        key={data.path}
        active={active}
        disabled={disabled}
        onClick={(e: MouseEvent) => {
          if (disabled) {
            e.preventDefault();
            return;
          }
        }}
        className={!active ? styles.inactiveLink : undefined}
      >
        {data.title}
      </HorizontalNavigationItem>
    );
  });

  const planRoute = routesData.find(data => data.plans);
  const isInMPMTreatments =
    mixingPlanTreatment === MixingPlanTreatments.treatmentA ||
    mixingPlanTreatment === MixingPlanTreatments.treatmentB;
  if (isPlanRoute && planRoute && !planRoute.skip && !isInMPMTreatments) {
    const active = current.plans;
    links.push(
      <HorizontalNavigationItem
        to={planRoute.path}
        key={planRoute.path}
        active={Boolean(active)}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        disabled={overallProgress.plans < 1}
        onClick={(e: MouseEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (overallProgress.plans < 1) {
            e.preventDefault();
            return;
          }
        }}
        className={!active ? styles.inactiveLink : undefined}
      >
        Meal Plan
      </HorizontalNavigationItem>
    );
  }

  const intersecting = showComponentOnScroll && reveal;
  const moreThanOnePet = links.length > 1;

  const shouldComponentShow = isOnDiySignup
    ? intersecting
    : moreThanOnePet || intersecting;

  return (
    <ContentReveal in={shouldComponentShow} inAnimation="fade">
      <div className={classNames(styles.container, responsiveClassName)}>
        <PageWidth className={styles.contentRow}>
          <HorizontalNavigation
            withButtons={showCarouselButtons && links.length > 5}
            className={styles.carousel}
          >
            {moreThanOnePet && !isOnDiySignup ? links : null}
          </HorizontalNavigation>
          {isRecipesPage && stickyHeaderSummary}
        </PageWidth>
      </div>
    </ContentReveal>
  );
}

export default PetSelectorV4;
