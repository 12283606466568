import { track } from '@farmersdog/analytics';
import { SubscriptionType } from '../../graphql/types.core-api';
import { eventNames } from './eventNames';

export interface CancelSubscriptionFlowStartPayload {
  subscriptionType: SubscriptionType;
}

export function trackCancelSubscriptionFlowStart({
  subscriptionType,
}: CancelSubscriptionFlowStartPayload) {
  track(eventNames.cancel_subscription_flow_start, {
    cancellationVersion: 2,
    subscriptionType,
  });
}
