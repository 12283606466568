import browserOnly from '../../utils/browserOnly';

interface AspireIqApi {
  convert: () => void;
}

interface Window {
  tdl?: AspireIqApi;
}

export function trackAspireIqCheckoutConversion() {
  browserOnly((window: Window) => {
    window.tdl?.convert();
  });
}
