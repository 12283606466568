import { cookie, ga4DebugMode } from '@farmersdog/lead-browser-storage';
import isNumber from 'lodash/isNumber';

import config from 'src/config';
import { reporter } from 'src/services/reporter';

export enum Ga4StandardEvent {
  PageView = 'page_view',
  Purchase = 'purchase',
  AddToCart = 'add_to_cart',
}

export enum Ga4CustomEvent {
  MeSuccess = 'me_success',
  PetsSuccess = 'pets_success',
  RecipesSuccess = 'recipes_success',
  CheckoutSuccess = 'checkout_success',
}

export type Ga4Event = Ga4StandardEvent | Ga4CustomEvent;

import { mountVendorWindowEvent } from '../utils';

export interface TrackGa4EventArgs {
  name: Ga4Event;
  properties?: Record<string, unknown>;
}

function _trackGa4Event(
  w: Window,
  { name, properties = {} }: TrackGa4EventArgs
) {
  if (!config('ga4.enabled')) {
    return;
  }

  if (!w.gtag) {
    reporter.error(
      'Cannot run gtag command because "window.gtag" is not defined'
    );
    return;
  }

  w.gtag('event', name, buildProperties(properties));
}

export const trackGa4Event = mountVendorWindowEvent(
  'Ga4 Track',
  _trackGa4Event
);

function buildProperties(eventProperties: TrackGa4EventArgs['properties']) {
  const debugProperty =
    config('ga4.debugView') || ga4DebugMode.get()
      ? { debug_mode: true }
      : undefined;

  const userIdProperty = isNumber(eventProperties?.user_id)
    ? String(eventProperties?.user_id)
    : cookie.getCorePostGresUserId();

  return {
    ...eventProperties,
    ...debugProperty,
    user_id: userIdProperty,
    send_to: config('ga4.measurementId') as string,
  };
}
