import classNames from 'classnames';
import { useViewport } from 'src/screen';
import { Text } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';

import styles from './Hero.module.scss';
import heroImages from './assets/images';

function Hero() {
  const isMobile = useViewport({ xs: true, lg: false });
  return (
    <>
      <section
        className={classNames(styles.container, { [styles.lg]: !isMobile })}
      >
        <Picture
          sources={heroImages}
          alt="about-us-hero"
          className={styles.background}
        />
        <Text
          as="h1"
          variant="heading-52"
          color="white"
          bold
          className={classNames(styles.header, { [styles.lg]: !isMobile })}
        >
          For the love
          <br />
          of dogs.
        </Text>
      </section>
    </>
  );
}

export default Hero;
