import { events, track } from 'src/analytics';
import { ReferralStatsType } from 'src/graphql/types';
import { SharingChannel } from 'src/pages/SignupSuccessPage/types';

export function trackSuccessReferralShareClick(
  channel: SharingChannel,
  referralStats: ReferralStatsType
) {
  return track(events.success_referral_share_click, {
    channel,
    discount: referralStats.discountPercentage,
  });
}
