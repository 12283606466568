import { Text } from '@farmersdog/corgi-x';
import styles from './ProfileItem.module.css';

interface ProfileItemProps {
  name: string;
  value: string | number | string[] | undefined;
}

export const ProfileItem = (props: ProfileItemProps) => {
  const { name, value } = props;

  if (value === undefined) return null;

  let ariaLabel;

  if (Array.isArray(value)) {
    ariaLabel = `${name} is ${value.join(', ')}`;
  } else {
    ariaLabel = `${name} is ${value}`;
  }

  return (
    <Text
      as="p"
      variant="heading-16"
      vSpacing="none"
      className={styles.root}
      aria-label={ariaLabel}
    >
      <Text as="span" variant="heading-16" bold className={styles.title}>
        {name}:
      </Text>
      {Array.isArray(value) ? (
        <Text as="span" variant="heading-16">
          {value.join(', ')}
        </Text>
      ) : (
        <Text as="span" variant="heading-16">
          {value}
        </Text>
      )}
    </Text>
  );
};
