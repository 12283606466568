import { EngagementTrackerVersion } from '@farmersdog/utils';
import {
  isSessionValid,
  updateSession,
  createSession,
  SessionData,
} from './utils';

import { SessionDataStorage } from './storage';

export function refreshSession(
  currentTrackerVersion: EngagementTrackerVersion
): SessionData {
  const sessionDataStorage = new SessionDataStorage();

  const currentSession = sessionDataStorage.read();

  const isCurrentSessionValid = isSessionValid(
    currentSession,
    currentTrackerVersion
  );
  const nextSession = isCurrentSessionValid
    ? updateSession(currentSession)
    : createSession(currentTrackerVersion);

  sessionDataStorage.write(nextSession);
  return nextSession;
}
