import { formatDateRange } from './formatDateRange';

import { getDeliveryWindow } from './getDeliveryWindow';

/**
 * Format the delivery week given a delivery day. In its current implementation,
 * the delivery window is *between Tuesday and Saturday*:
 *
 * ```js
 * > formatWeekOf('2019-10-09'))
 * < "October 8–12"
 * ```
 *
 * When the next Saturday spans over the next month, this will be added too:
 *
 * ```js
 * > formatWeekOf('2019-10-30'))
 * < "October 29–November 2"
 * ```
 *
 * @param deliveryDay - The day in YYYY-MM-DD format
 * @param format - The format string, defaults to 'MMMM D'
 */
export function formatDeliveryWindow(deliveryDay: string): string {
  const [from, to] = getDeliveryWindow(deliveryDay);
  return formatDateRange(from, to);
}
