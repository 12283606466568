import { combineReducers } from 'redux';

import user from './user';
import card from './card';

const customerAccountReducer = combineReducers({
  user,
  card,
});

export default customerAccountReducer;
