import { useLeadState } from '../../useLeadState';
import { UseFormSubmitArgs } from '../useFormSubmit';

import { handleAuthRegisterError } from './handleAuthRegisterError';

import type { FormFieldsType } from '../../../types';

interface HandleFinalFormStepArgs {
  formValidationSchema: UseFormSubmitArgs['formValidationSchema'];
  data: FormFieldsType;
  stepName: string;
  updateLeadAndAdvanceStep: (args: {
    stepName: string;
    data: FormFieldsType;
  }) => Promise<void>;
  onManagedError: (message: string) => void;
  throwToErrorBoundary: (error: Error) => void;
}

export function useHandleFinalFormStep() {
  const { convertFormStateToLeadInput, registerLead } = useLeadState();

  const handleFinalFormStep = async ({
    formValidationSchema,
    data,
    updateLeadAndAdvanceStep,
    stepName,
    onManagedError,
    throwToErrorBoundary,
  }: HandleFinalFormStepArgs) => {
    try {
      await registerLead({
        lead: convertFormStateToLeadInput({
          formValidationSchema,
          formData: data,
        }),
        disableRecipesUpdate: true,
      });
    } catch (error) {
      handleAuthRegisterError({
        error: error as Error,
        onManagedError,
        throwToErrorBoundary,
      });
      // need to return here because managed errors show a modal and we should not continue
      return;
    }
    await updateLeadAndAdvanceStep({ stepName, data });
  };

  return handleFinalFormStep;
}
