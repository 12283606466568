import { useMutation } from '@apollo/client';

import UpdatePaymentMethodMutation from './UpdatePaymentMethodMutation.anon.graphql';
import {
  UpdatePaymentMethodMutation as Data,
  UpdatePaymentMethodInput,
  UpdatePaymentMethodMutationVariables as Variables,
} from 'src/graphql/anon.types';
import { getGraphqlSpecErrorHeader } from 'src/graphql/utils/getGraphqlSpecErrorHeader';

export function useUpdatePaymentMethodMutation() {
  const [callMutation, { data, loading, error }] = useMutation<Data, Variables>(
    UpdatePaymentMethodMutation
  );

  const updatePaymentMethodMutation = async (
    input: UpdatePaymentMethodInput
  ) => {
    await callMutation({
      variables: { input },
      context: {
        headers: getGraphqlSpecErrorHeader(),
      },
    });
  };

  return {
    updatePaymentMethodMutation,
    success: Boolean(data?.updatePaymentMethod),
    loading,
    error,
  };
}
