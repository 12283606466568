import browserOnly from 'src/utils/browserOnly';

/**
 * Blur the current document's active element.
 */
export function blurActiveElement() {
  browserOnly(window => {
    const document = window.document;
    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur();
    }
  });
}

/**
 * Scroll an element to its top position.
 */
export function scrollToTop(optionalElement) {
  browserOnly(window => {
    const el = optionalElement || window;
    el.scrollTo({ top: 0, left: 0 });
  });
}

/**
 * Reload the current location.
 */
export function reloadLocation() {
  browserOnly(window => {
    window.location.reload();
  });
}

/**
 * Listen to location changes by setting an interval to check the current
 * window.location.href.
 *
 * @param {Function} callback
 * @return {Number} The parameter for clearInterval()
 */
export function onLocationChange(callback) {
  return browserOnly(window => {
    const currentPage = window.location.href;
    const interval = setInterval(() => {
      if (currentPage !== window.location.href) {
        clearInterval(interval);
        callback();
      }
    }, 500);
    return interval;
  });
}

/**
 * Get scrollY with support for IE11
 *
 * @return {Number} the number of pixels scrolled
 */
export function getScrollY() {
  return browserOnly(window =>
    typeof window.scrollY === 'number' ? window.scrollY : window.pageYOffset
  );
}

/**
 * Add a new Element with the specified id. If the element already exists, it
 * returns it.
 *
 * @param {string} id
 * @param {string?} tagName='div'
 *
 * @return {Element}
 */
export function addDOMElementById(id, tagName = 'div') {
  return browserOnly(window => {
    const document = window.document;
    let el = document.getElementById(id);
    if (!el) {
      el = document.createElement(tagName);
      el.id = id;
      document.body.appendChild(el);
    }
    return el;
  });
}
