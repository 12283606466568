import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

interface TrackEnterDownsellFlowProps {
  /** The reason for entering the downsell flow */
  reason: 'price'; // can be expanded to other reasons
}
/** Track when user reaches the entry point for any Downsell within fresh Cancellation flow */
export function trackEnterDownsellFlow(props: TrackEnterDownsellFlowProps) {
  track(eventNames.cancel_subscription_enter_downsell_flow, {
    reason: props.reason,
  });
}
