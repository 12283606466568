import { Button, ButtonVariant, Divider, Text } from '@farmersdog/corgi-x';
import styles from './StickyButton.module.css';
export interface StickyButtonProps {
  handleOnCTAClick: () => void;
  buttonVariant: ButtonVariant;
  ctaText: string;
  ctaSublineText?: string;
  ctaSublineTextTestId?: string;
  buttonDataTestId?: string;
}

/**
 * A Button that stays at the bottom of screen
 */
export function StickyButton(props: StickyButtonProps) {
  return (
    <div className={styles.buttonContainer}>
      <Divider borderStyle="none" />
      <Button
        variant={props.buttonVariant}
        type="submit"
        onClick={props.handleOnCTAClick}
        className={styles.buttonCTA}
        data-testid={props.buttonDataTestId}
      >
        {props.ctaText}
      </Button>
      <Divider borderStyle="none" />
      {props.ctaSublineText && (
        <Text
          variant="article-12"
          fontStyle="italic"
          as="p"
          color="charcoal-3"
          data-testid={props.ctaSublineTextTestId}
        >
          {props.ctaSublineText}
        </Text>
      )}
    </div>
  );
}
