import { Picture } from '../../../Picture';
import { HealthierPoopSources } from '../assets';
import { DidYouKnowBox } from '../DidYouKnowBox';
import responsiveStyles from '../PlanCalculationWithLoader.module.css';

import styles from './HealthierPoopAnimated.module.css';

interface ResponsiveHealthierPoopAnimated {
  isMobile?: boolean;
}

function ResponsiveHealthierPoopAnimated({
  isMobile,
}: ResponsiveHealthierPoopAnimated) {
  return (
    <div
      className={isMobile ? responsiveStyles.mobile : responsiveStyles.desktop}
    >
      <div className={styles.container}>
        <DidYouKnowBox textColor="green" className={styles.textsContainer}>
          Our fresh recipes are more digestible than highly processed kibble,
          resulting in smaller, healthier poops.
        </DidYouKnowBox>
        <div className={styles.animationContainer}>
          <Picture
            data-testid="healthier-poop-animation"
            aria-hidden="true"
            className={styles.animation}
            alt=""
            sources={
              isMobile
                ? HealthierPoopSources.mobile
                : HealthierPoopSources.desktop
            }
          />
        </div>
      </div>
    </div>
  );
}

export const HealthierPoopAnimated = () => {
  return (
    <>
      <ResponsiveHealthierPoopAnimated />
      <ResponsiveHealthierPoopAnimated isMobile />
    </>
  );
};
