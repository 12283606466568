import { useCallback, useMemo, useState } from 'react';
import styles from './ClinicSearch.module.css';
import { ClinicSelector, FoundClinic } from '../ClinicSelector';
import { useAddClinic, useValidateClinic } from '../../network';
import { SelectedClinic } from '../SelectedClinic';
import { ValidateClinicQuery } from '../../network/ValidateClinic.cgs.generated-types';
import { ActivityIndicator, Button, Text } from '@farmersdog/corgi-x';
import {
  AddClinic,
  AddClinicFormFieldName,
  AddClinicFormProps,
} from '../AddClinic';
import { Chevron } from '@farmersdog/corgi-x/icons';
import { useToast } from '@farmersdog/corgi';
import {
  CLINIC_ADD_DUPLICATE_ERROR_CODE,
  CLINIC_ADD_DUPLICATE_ERROR,
  CLINIC_ADD_GENERIC_ERROR,
  CLINIC_COUNTRY,
  CLINIC_VALIDATION_ERROR,
} from '../../constants';
import {
  ClinicFormFieldName,
  ClinicFormProps,
} from '../ClinicForm/validationForm';
import { AddClinicInput, ClinicDataSource } from '../../../graphql/types.cgs';
import omit from 'lodash/omit';
import { GetClinicsQuery } from '../../network/GetClinics.cgs.generated-types';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import {
  trackClickedAddVetClinic,
  trackSelectedSearchResult,
} from '../../analytics';
import { customScroll } from '../../utils';

enum ClinicSearchStep {
  Search = 'search',
  Selected = 'selected',
  Add = 'Add',
}

interface ClinicSearchProps {
  refetchSavedClinics: () => Promise<ApolloQueryResult<GetClinicsQuery>>;
  setDisplayVetClinicSearchIfSaved: (arg: boolean) => void;
  setRecentlySavedClinicId: (id: string) => void;
  showBackButton: boolean;
  parentId: string;
}

export const ClinicSearch = ({
  refetchSavedClinics,
  setDisplayVetClinicSearchIfSaved,
  setRecentlySavedClinicId,
  showBackButton,
  parentId,
}: ClinicSearchProps) => {
  const [step, setStep] = useState<ClinicSearchStep>(ClinicSearchStep.Search);
  const [selectedClinic, setSelectedClinic] = useState<FoundClinic>();
  const [validatedClinic, setValidatedClinic] =
    useState<ValidateClinicQuery['petHealth']['validateClinic']>();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FoundClinic[]>([]);
  const dispatchToast = useToast();

  const { validateClinic, loading: validateClinicLoading } =
    useValidateClinic();
  const [addClinicMutation, { loading: addClinicLoading }] = useAddClinic();

  const onSelectedClinicChange = useCallback(
    async (practice: FoundClinic, searchString: string) => {
      customScroll({ id: parentId, offset: -200 });
      try {
        const response = await validateClinic({
          variables: {
            input: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        setSelectedClinic(practice);
        setSearchTerm(searchString);
        setValidatedClinic(response.data?.petHealth.validateClinic);
        setStep(ClinicSearchStep.Selected);
        trackSelectedSearchResult({ clinicId: practice.id });
      } catch {
        dispatchToast({
          variant: 'negative',
          children: CLINIC_VALIDATION_ERROR,
        });
      }
    },
    [dispatchToast, validateClinic, parentId]
  );

  const resetSelector = () => {
    setSelectedClinic(undefined);
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleAddClinicError = useCallback(
    (error: unknown) => {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.extensions?.code ===
          CLINIC_ADD_DUPLICATE_ERROR_CODE
      ) {
        dispatchToast({
          variant: 'negative',
          children: CLINIC_ADD_DUPLICATE_ERROR,
        });
        return;
      }
      dispatchToast({
        variant: 'negative',
        children: CLINIC_ADD_GENERIC_ERROR,
      });
    },
    [dispatchToast]
  );

  const addClinic = useCallback(
    async (clinicInput: AddClinicInput) => {
      try {
        const response = await addClinicMutation({
          variables: {
            input: {
              ...clinicInput,
              clinic: {
                ...clinicInput.clinic,
                zip: clinicInput.clinic.zip?.replaceAll('-', ''),
              },
            },
          },
        });
        const clinicId = response?.data?.addClinic?.clinic?.id;
        if (clinicId) {
          setRecentlySavedClinicId(clinicId);
        }
        resetSelector();
        setDisplayVetClinicSearchIfSaved(false);
        customScroll({ id: parentId, offset: -200 });
        await refetchSavedClinics();
      } catch (error) {
        handleAddClinicError(error);
      }
    },
    [
      addClinicMutation,
      refetchSavedClinics,
      setDisplayVetClinicSearchIfSaved,
      setRecentlySavedClinicId,
      handleAddClinicError,
      parentId,
    ]
  );

  const onClickAddVetClinic = useCallback(() => {
    setStep(ClinicSearchStep.Add);
    trackClickedAddVetClinic();
    customScroll({ id: parentId, offset: -200 });
  }, [parentId]);

  const onAddManualClinic = useCallback(
    async (values: AddClinicFormProps) => {
      await addClinic({
        clinic: {
          city: values[AddClinicFormFieldName.City],
          country: CLINIC_COUNTRY,
          name: values[AddClinicFormFieldName.Name],
          source: ClinicDataSource.User,
          state: values[AddClinicFormFieldName.State],
          streetAddress1: values[AddClinicFormFieldName.StreetAddress1],
          type: 'clinic',
          zip: values[AddClinicFormFieldName.ZipCode],
          streetAddress2: values[AddClinicFormFieldName.StreetAddress2],
        },
        vetName: values[AddClinicFormFieldName.VeterinarianName],
      });
    },
    [addClinic]
  );

  const onAddSearchClinic = useCallback(
    async (values: ClinicFormProps) => {
      await addClinic({
        clinic: {
          ...omit(validatedClinic?.addressComponents, '__typename'),
          source: ClinicDataSource.Google,
          id: selectedClinic?.id,
          type: 'clinic',
        },
        vetName: values[ClinicFormFieldName.VetName],
      });
    },
    [addClinic, validatedClinic?.addressComponents, selectedClinic?.id]
  );

  const body = useMemo(() => {
    if (validateClinicLoading)
      return (
        <div className={styles.loadingClinic}>
          <ActivityIndicator mode="dark" />
        </div>
      );
    if (step === ClinicSearchStep.Search) {
      return (
        <>
          {showBackButton && (
            <Action
              onClick={() => setDisplayVetClinicSearchIfSaved(false)}
              label="Back"
            />
          )}
          <ClinicSelector
            selectedClinic={selectedClinic}
            onClinicSelected={onSelectedClinicChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            addVetClinic={onClickAddVetClinic}
          />
        </>
      );
    }

    if (step === ClinicSearchStep.Selected) {
      return (
        <>
          <Action
            onClick={() => setStep(ClinicSearchStep.Search)}
            label="Search Results"
          />
          <SelectedClinic
            onSaveChanges={onAddSearchClinic}
            address={validatedClinic?.formattedAddress || ''}
            name={validatedClinic?.addressComponents?.name || ''}
            loading={addClinicLoading}
          />
        </>
      );
    }
    if (step === ClinicSearchStep.Add) {
      return (
        <>
          <Action
            onClick={() => setStep(ClinicSearchStep.Search)}
            label="Search Results"
          />
          <AddClinic
            onSaveChanges={onAddManualClinic}
            loading={addClinicLoading}
          />
        </>
      );
    }
    return <></>;
  }, [
    addClinicLoading,
    onClickAddVetClinic,
    onAddManualClinic,
    onAddSearchClinic,
    onSelectedClinicChange,
    searchResults,
    searchTerm,
    selectedClinic,
    setDisplayVetClinicSearchIfSaved,
    showBackButton,
    step,
    validateClinicLoading,
    validatedClinic?.addressComponents?.name,
    validatedClinic?.formattedAddress,
  ]);

  return (
    <>
      <div className={styles.clinicContainer}>{body}</div>
    </>
  );
};

interface ActionProps {
  onClick: () => void;
  label: string;
}
const Action = ({ onClick, label }: ActionProps) => {
  return (
    <Button
      variant="plain-text"
      onClick={onClick}
      className={styles.actionButton}
      aria-label={label}
    >
      <Chevron orientation="left" height={25} baseColor="Carrot2" />
      <Text variant="heading-16" color="carrot-2" as="span" bold>
        {label}
      </Text>
    </Button>
  );
};
