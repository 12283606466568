import { useEffect } from 'react';
import qs from 'qs';
import { useRouteMatch, useLocation, useHistory } from 'react-router';
import omit from 'lodash/omit';

import * as couponCookie from './utils/couponCookie';

import {
  PATH_COUPON_CODE_MATCH,
  USER_REFERRER_QUERY_PARAM,
  COUPON_CODE_QUERY_PARAM,
  COUPON_COOKIE_VERSION,
} from './constants';

import { ReferrerType } from './types';

interface UserReferrerQuery {
  [USER_REFERRER_QUERY_PARAM]: string;
}

interface CouponCodeQuery {
  [COUPON_CODE_QUERY_PARAM]: string;
}

interface CouponCodeMatch {
  couponCode: string;
}

export function useSetCouponCookie() {
  const match = useRouteMatch(PATH_COUPON_CODE_MATCH);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isValidCouponMatch(match?.params)) {
      couponCookie.set({
        referrerAssociatedAt: new Date(),
        referrerCode: sanitizeCouponCode(match?.params.couponCode),
        referrerType: ReferrerType.Partner,
        version: COUPON_COOKIE_VERSION,
      });

      history.replace({
        ...location,
        pathname: '/',
      });
    }
  }, [match, history, location]);

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (isValidCouponQuery(query)) {
      couponCookie.set({
        referrerAssociatedAt: new Date(),
        referrerCode: sanitizeCouponCode(query.c),
        referrerType: ReferrerType.Partner,
        version: COUPON_COOKIE_VERSION,
      });

      history.replace({
        ...location,
        search: qs.stringify(omit(query, COUPON_CODE_QUERY_PARAM), {
          addQueryPrefix: true,
        }),
      });

      return;
    }

    if (isValidReferrerQuery(query)) {
      couponCookie.set({
        referrerAssociatedAt: new Date(),
        referrerCode: sanitizeCouponCode(query.ref),
        referrerType: ReferrerType.User,
        version: COUPON_COOKIE_VERSION,
      });

      history.replace({
        ...location,
        search: qs.stringify(omit(query, USER_REFERRER_QUERY_PARAM), {
          addQueryPrefix: true,
        }),
      });

      return;
    }
  }, [match, history, location]);
}

function isValidCouponMatch(search: unknown): search is CouponCodeMatch {
  return Boolean(
    search && Object.prototype.hasOwnProperty.call(search, 'couponCode')
  );
}

function isValidCouponQuery(search: unknown): search is CouponCodeQuery {
  return Boolean(
    search &&
      Object.prototype.hasOwnProperty.call(search, COUPON_CODE_QUERY_PARAM)
  );
}

function isValidReferrerQuery(search: unknown): search is UserReferrerQuery {
  return Boolean(
    search &&
      Object.prototype.hasOwnProperty.call(search, USER_REFERRER_QUERY_PARAM)
  );
}

function sanitizeCouponCode(couponCode?: string) {
  if (!couponCode) {
    return '';
  }

  const match = /\w+/.exec(couponCode.substr(0, 100));
  return match ? match[0] : '';
}
