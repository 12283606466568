export function GladlyChatIcon() {
  return (
    <svg
      viewBox="0 0 23 24"
      fill="#ffffff"
      stroke="#385A9D"
      role="img"
      aria-labelledby="minimizedChatTitleId"
      width={24}
      height={24}
    >
      <title id="minimizedChatTitleId">Open Chat</title>
      <path
        fillRule="evenodd"
        fill="#ffffff"
        stroke="#ffffff"
        clipRule="evenodd"
        d="M0 2C0 .9.9 0 2 0h19a2 2 0 012 2v15a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
      />
      <path
        fillRule="evenodd"
        fill="#ffffff"
        clipRule="evenodd"
        stroke="#ffffff"
        d="M13 17.9L5 24l.03-10L13 17.9z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4 5h10.2M6.4 9h10.2M6.38 13h5.25"
      />
    </svg>
  );
}
