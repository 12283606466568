import { useFeature } from 'src/abTesting';
import { CVR_ANONYMOUS_ID_PERFORMANCE_LOGS } from 'src/abTesting/features';

interface useAnonymousIdPerformanceLogsReturn {
  isLoggingEnabled: boolean;
  cachedSplitClients: Record<string, boolean>;
}

export function useAnonymousIdPerformanceLogs(): useAnonymousIdPerformanceLogsReturn {
  const { treatment, cachedSplitClients } = useFeature(
    CVR_ANONYMOUS_ID_PERFORMANCE_LOGS
  );

  return {
    isLoggingEnabled: treatment === 'on',
    cachedSplitClients: cachedSplitClients as Record<string, boolean>,
  };
}
