import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
  ApolloClient,
  from,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { selectToken } from 'src/reducers/auth';
import config from 'src/config';

import { handleRequestError } from '../utils/handleRequestError';
import browserOnly from 'src/utils/browserOnly';
import { cacheConfig } from '../cacheConfig';
import { createTosaAuthMiddleware } from './createTosaAuthMiddleware';
import { getApolloHttpLink } from './getApolloHttpLink';
import { createTosaErrorMiddleware } from './createTosaErrorMiddleware';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
function Provider({ children }: PropsWithChildren<{}>) {
  const token = useSelector(selectToken) as string | null;
  const GraphQLEndpointHttpLink = getApolloHttpLink(token);

  // Links are evaluated in reverse order
  const link = from([
    onError(handleRequestError),
    createTosaErrorMiddleware(),
    createTosaAuthMiddleware(),
    GraphQLEndpointHttpLink,
  ]);

  const client = new ApolloClient({
    name: 'website',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    version: config('app.version'),
    link,
    cache: new InMemoryCache(cacheConfig),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    connectToDevTools: browserOnly(
      () => config('app.env') !== 'production',
      () => false
    ),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Provider;
