import { useEffect } from 'react';

import { ga4DebugMode } from '@farmersdog/lead-browser-storage';
import { ga4Mounter } from '@farmersdog/pixels';

import config from '../config';

export function useConfigureGa4() {
  const { enabled, loadSdk, measurementId, debugView } = config.get('ga4');

  const debugViewSetting = Boolean(debugView || ga4DebugMode.get());

  useEffect(() => {
    ga4Mounter.configure({
      enabled,
      loadSdk,
      measurementId,
      debugView: debugViewSetting,
    });
  }, [enabled, loadSdk, measurementId, debugViewSetting]);
}
