import { sharedPinterestAttributes } from './constants';
import { pinterest, PinterestEventAttributes } from './pinterest';

/** The attributes required for a Pinterest lead event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'lead',
};

/**
 * Mount a Pinterest lead event.
 */

export async function mountPinterestLead(email: string | undefined) {
  await pinterest.setEnhancedMatchAttributes(email);
  pinterest.mount(attributes);
}
