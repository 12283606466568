import { useEffect } from 'react';
import { Script } from 'src/components/Script';
import browserOnly from 'src/utils/browserOnly';
import { BASE_URL, COMPANY_ID } from './constants';

/**
 * An interface representing the data that should be attached to the global
 * window object when loading the Power Inbox tracking script.
 */
export interface PowerInboxAttributes {
  company_id?: string;
}
declare global {
  interface Window {
    pi_params?: PowerInboxAttributes;
  }
}

export function PowerInboxScript() {
  useEffect(() => {
    browserOnly((window: Window) => {
      window.pi_params = window.pi_params ?? {
        company_id: COMPANY_ID,
      };
    });
  });

  return <Script src={BASE_URL} type="text/javascript" async />;
}
