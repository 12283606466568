import type { OnMeStepCompleted } from '@farmersdog/tosa';
import { reporter } from 'src/services/reporter';
import { useSelector } from 'react-redux';
import {
  trackMeSuccess,
  identifyMePage,
  trackImpactRadiusEmailConversion,
} from 'src/analytics';
import { freshFoodOptions } from 'src/constants/freshFoodOptions';
import { selectRequestAttributes } from 'src/reducers/referrer';

const requiredFields = ['email', 'anonymousId', 'pets', 'zip'] as const;

export function useHandleTosaMeCompleted(): OnMeStepCompleted {
  const requestAttributes = useSelector(selectRequestAttributes);

  const handleTosaMeCompleted: OnMeStepCompleted = (_, data) => {
    const {
      lead,
      couponData,
      requireCorePostgresId = false,
      discountOverride,
    } = data;

    try {
      if (!lead) {
        throw new Error('Cannot find lead data for trackMeSuccess event');
      }

      const freshFoodConfidence = freshFoodOptions.find(confidence => {
        return confidence.label === lead.freshFoodConfidence;
      });

      if (!freshFoodConfidence) {
        throw new Error(
          'Cannot find freshFoodConfidence for trackMeSuccess event'
        );
      }

      if (requireCorePostgresId && !lead.corePostgresUserId) {
        throw new Error('Cannot find corePostgresId for trackMeSuccess event');
      }

      requiredFields.forEach(requiredField => {
        if (!lead[requiredField]) {
          throw new Error(
            `Cannot find ${requiredField} for trackMeSuccess event`
          );
        }
      });

      identifyMePage({
        referrer: {
          discountType: couponData.discountType,
          discountAmount: couponData.discountAmount,
          type: couponData.referrerType,
        },
        couponCode: couponData.couponCode,
        user: {
          id: lead.corePostgresUserId as number,
          email: lead.email,
          firstName: lead.firstName,
          pets: lead.pets,
          shippingAddress: { zip: lead.zip },
        },
      });

      const freshFoodConfidenceId = freshFoodConfidence.value;

      trackMeSuccess({
        discountOverride,
        humanId: lead.humanId,
        requestAttributes,
        user: {
          id: lead.corePostgresUserId as number,
          // @ts-expect-error LeadView does not line up with Reducer.User
          firstName: lead.firstName,
          // @ts-expect-error PetsView does not line up with Reducer.User['pets']
          pets: lead.pets,
          freshFoodConfidence: freshFoodConfidenceId,
          email: lead.email,
        },
        addressData: {
          city: lead.city,
          state: lead.state,
          zip: lead.zip,
        },
      });

      trackImpactRadiusEmailConversion({
        id: lead.corePostgresUserId as number,
      });
    } catch (error: unknown) {
      if (!isErrorWithMessage(error)) {
        throw error;
      }

      reporter.error(error.message);
    }
  };

  return handleTosaMeCompleted;
}

type ErrorWithMessage = {
  message: string;
};

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return Boolean(
    error && Object.prototype.hasOwnProperty.call(error, 'message')
  );
}
