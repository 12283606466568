import { emailRegex } from '@farmersdog/constants/regexPatterns';

export function redactEmailsFromPath(path: string) {
  const pathComponents = path.split('/');

  const nonEmailPathComponents = pathComponents.filter(
    partialPath => !emailRegex.test(partialPath)
  );

  const pathWithRedactedEmail = nonEmailPathComponents.join('/');

  return pathWithRedactedEmail;
}
