import { CostAssessment as CostAssessmentLayout } from '../CostAssessment';
import {
  IntroMixingPlan as IntroMixingPlanLayout,
  MixingPlanEducation as MixingPlanEducationLayout,
} from './components';

import { MixingPlanState } from './useMixingPlanDownsellProgress';

interface MixingPlanNavigation {
  onClose: () => void;
  onPrevious: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
}

interface MixingPlanDownsellProps extends MixingPlanNavigation {
  currentState: MixingPlanState;
  currentWeeklyPrice: number;
}

export function MixingPlanDownsell(props: MixingPlanDownsellProps) {
  const { currentState, ...navigation } = props;

  const Component =
    currentState === MixingPlanState.CostAssessment
      ? CostAssessment
      : currentState === MixingPlanState.IntroMixingPlan
        ? IntroMixingPlan
        : MixingPlanEducation;

  return <Component {...navigation} />;
}

function CostAssessment(
  props: MixingPlanNavigation & { currentWeeklyPrice: number }
) {
  return (
    <CostAssessmentLayout
      currentWeeklyPrice={props.currentWeeklyPrice}
      onClose={props.onClose}
      onContinue={props.onPrimaryClick}
      onPrevious={props.onPrevious}
    />
  );
}

function IntroMixingPlan(props: MixingPlanNavigation) {
  return (
    <IntroMixingPlanLayout
      onClose={props.onClose}
      onPrevious={props.onPrevious}
      onStartMixingPlan={props.onSecondaryClick}
      onContinueCancelling={props.onPrimaryClick}
    />
  );
}

function MixingPlanEducation(props: MixingPlanNavigation) {
  return (
    <MixingPlanEducationLayout
      onClose={props.onClose}
      onBack={props.onPrevious}
      onContinue={props.onPrimaryClick}
    />
  );
}
