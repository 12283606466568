import { track } from '@farmersdog/analytics';
import eventNames from '../eventNames';
import {
  RescheduleSceneName,
  TooMuchFoodSceneName,
} from 'src/pages/Reschedule/workflows';

export type RescheduleExitMethods = 'X Button' | 'Secondary CTA';

interface TrackRescheduleExitedProps {
  step: RescheduleSceneName | TooMuchFoodSceneName;
  method: RescheduleExitMethods;
}

export function trackRescheduleExited(props: TrackRescheduleExitedProps) {
  return track(eventNames.reschedule_exited, {
    step: props.step,
    method: props.method,
  });
}
