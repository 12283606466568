import PropTypes from 'prop-types';
import {
  Button,
  Input,
  SelectorGroup,
  LabeledSelector,
} from '@farmersdog/corgi';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';

import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';
import useAnalytics from './useAnalytics';

import { SLIDE_NAME as SUBMIT } from './Submit';
import { TITLE, ROOT_DECISION_COPY, ROOT_DECISION_LEGEND } from './copy';

import useRootDecisions from './useRootDecisions';
import { OTHER } from './useRootDecisions.reasons';

import styles from './RootDecision.module.scss';

function RootDecision({
  onAnswerEvent,
  setCurrentSlide,
  rootDecision,
  otherRootDecision,
  isTOSA,
}) {
  const { current } = useSignupRouter({ isTOSA });

  const { rootDecisions } = useRootDecisions({
    current,
  });

  const { trackAnswerSelected } = useAnalytics();

  const getNextSlide = value => {
    return rootDecisions.find(decision => decision.value === value)?.nextSlide;
  };

  const handleRootDecisionChange = e => {
    const { value } = e.target;
    onAnswerEvent(e);
    if (value === OTHER) {
      return;
    }
    setCurrentSlide(getNextSlide(value));
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} copy={ROOT_DECISION_COPY} align="center">
        <SelectorGroup
          type="radio"
          name="rootDecision"
          legend={ROOT_DECISION_LEGEND}
          value={rootDecision}
          onChange={handleRootDecisionChange}
          inputsClassName={styles.optionsContainer}
        >
          {rootDecisions.map(decision => (
            <LabeledSelector
              key={decision.value}
              value={decision.value}
              className={styles.option}
            >
              {decision.label}
            </LabeledSelector>
          ))}
        </SelectorGroup>
        {rootDecision === OTHER && (
          <div className={styles.otherField}>
            <Input
              label="What can we improve?"
              name="otherRootDecision"
              type="text"
              onChange={e => onAnswerEvent(e, { track: false })}
              value={otherRootDecision}
            />
          </div>
        )}
        {rootDecision && (
          <Button
            type="button"
            name="submitFreeText"
            className={styles.submitButton}
            disabled={
              (rootDecision === OTHER && !otherRootDecision) ||
              (rootDecision !== OTHER && !rootDecision)
            }
            onClick={() => {
              trackAnswerSelected('otherRootDecision', otherRootDecision);
              setCurrentSlide(getNextSlide(rootDecision));
            }}
          >
            {getNextSlide(rootDecision) === SUBMIT ? 'Submit' : 'Continue'}
          </Button>
        )}
      </TitleWithCopy>
    </>
  );
}

RootDecision.propTypes = {
  onAnswerEvent: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  rootDecision: PropTypes.string.isRequired,
  otherRootDecision: PropTypes.string.isRequired,
  isTOSA: PropTypes.bool,
};

export const SLIDE_NAME = 'ROOT_DECISION';

export default RootDecision;
