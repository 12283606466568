export enum HomepageHeroImageTreatments {
  Control = 'control',
  Off = 'off',
  On = 'on',
  LifestyleBowl = 'lifestyle_bowl',
  MeatAndVeggies = 'meat_and_veggies',
}

export enum HomepageVetOptimizationTreatments {
  Control = 'control',
  Off = 'off',
  PillAndCard = 'pill_and_card',
  PillOnly = 'pill_only',
}

export const HEALTH_VET_OPTIMIZATION_HOMEPAGE =
  'HEALTH_VET_OPTIMIZATION_HOMEPAGE';

export interface AnonymousExperimentFlags {
  cvr_homepage_hero_image: {
    config: void;
    treatment: HomepageHeroImageTreatments;
  };
  [HEALTH_VET_OPTIMIZATION_HOMEPAGE]: {
    config: void;
    treatment: HomepageVetOptimizationTreatments;
  };
}

export type AnonymousExperimentFlag = keyof AnonymousExperimentFlags;
