import { tosaRouteWhitelist } from '@farmersdog/constants';

import { PixelOption, PixelSettings } from '../pixelMigrator';

interface ShouldRunPageViewFromTosa {
  vendorName: PixelOption;
  pixelSettings: PixelSettings;
  pathname: string;
}

export function shouldRunPageViewFromTosa({
  vendorName,
  pixelSettings,
  pathname,
}: ShouldRunPageViewFromTosa) {
  return pixelSettings[vendorName] && tosaRouteWhitelist.includes(pathname);
}
