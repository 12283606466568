import { AddDogContent } from '../AddDogContent';
import {
  Text,
  Grid,
  GridItem,
  RadioButton,
  AnimateHeight,
  StatusMessage,
  Section,
  visuallyHidden,
  useIntersection,
} from '@farmersdog/corgi-x';
import styles from './HealthIssuesScene.module.css';
import { AddDogFormChangeHandler, UseAddDogFormFields } from '../../hooks';
import {
  HEALTH_ISSUE_WARNING_MESSAGE,
  HealthIssue,
  CTA_BUTTON,
  CTA_BUTTON_WITH_WARNING,
} from './constants';
import { PetHealthCheckbox } from './components/PetHealthCheckbox/';
import React, { useEffect, useRef, useState } from 'react';

export interface HealthIssueSceneProps {
  name: string | undefined;
  value: UseAddDogFormFields['healthIssues'];
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

function isHealthIssueWithWarning(healthIssue: HealthIssue): boolean {
  return (
    healthIssue === HealthIssue.HyperlipidemiaOrHypertriglyceridemia ||
    healthIssue === HealthIssue.PancreatitisHistory
  );
}

export const HealthIssueScene = (props: HealthIssueSceneProps) => {
  const { onCtaClick, onChange, name, value } = props;

  // Internal state for radio buttons.
  const [hasHealthIssues, setHasHealthIssues] = useState<boolean | null>();

  // Internal state for warning message.
  const [warningMessage, setWarningMessage] = useState(false);

  const checkBoxContainerRef = useRef<HTMLDivElement>(null);
  const statusMessageRef = useRef<HTMLDivElement>(null);

  const isStatusMessageVisible = useIntersection(statusMessageRef?.current, {
    threshold: 1,
  })?.isIntersecting;

  const [allowScrollIntoView, setAllowScrollIntoView] = useState(false);

  const healthIssues: string[] = Object.values(HealthIssue);

  const ctaDisabled =
    hasHealthIssues === undefined ||
    (hasHealthIssues === true && value && value?.length === 0);

  // Event handler for radio buttons.
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasHealthIssue = e.target.value === 'yes';

    if (!hasHealthIssue) {
      setWarningMessage(false);
    }
    onChange('healthIssues', []);
    setHasHealthIssues(hasHealthIssue);
  };

  // Event handler for checkboxes.
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const checkboxValue = e.target.value as HealthIssue;

    const currentHealthIssueNames: HealthIssue[] =
      value && value.length > 0 ? value : [];

    const newHealthIssueNames: HealthIssue[] = isChecked
      ? [...currentHealthIssueNames, checkboxValue]
      : currentHealthIssueNames.filter(issue => issue !== checkboxValue);

    const hasWarning = newHealthIssueNames.some(issue => {
      return isHealthIssueWithWarning(issue);
    });

    if (hasWarning) {
      setWarningMessage(true);
    } else {
      setWarningMessage(false);
    }
    // We only want to allow scrolling as a result of an issue
    //having a warning message and the status message not being visible.
    setAllowScrollIntoView(hasWarning && !isStatusMessageVisible);

    onChange('healthIssues', newHealthIssueNames);
  };

  // If user is going back and forth in the form, we lose our internal state
  // for hasHealthIssues and need to check if they already answered the question
  // in order to show/hide the checkboxes accordingly.
  useEffect(() => {
    if (value && value.length > 0) {
      setHasHealthIssues(true);
      if (value.some(issue => isHealthIssueWithWarning(issue))) {
        setWarningMessage(true);
      }
    } else if (hasHealthIssues === undefined && value && value.length === 0) {
      setHasHealthIssues(false);
    }

    if (warningMessage && !isStatusMessageVisible && allowScrollIntoView) {
      setTimeout(() => {
        statusMessageRef.current?.scrollIntoView();
      }, 150);
      // After scrolling, set to false so it doesn't scroll again.
      setAllowScrollIntoView(false);
    }
  }, [
    value,
    hasHealthIssues,
    warningMessage,
    isStatusMessageVisible,
    allowScrollIntoView,
  ]);

  return (
    <AddDogContent
      heading={`Does ${name} have any health issues?`}
      cta={warningMessage ? CTA_BUTTON_WITH_WARNING : CTA_BUTTON}
      onCtaClick={onCtaClick}
      ctaDisabled={ctaDisabled}
      sectionWidthConstraint="lg"
    >
      <fieldset className={styles.radiosContainer}>
        <legend
          className={visuallyHidden}
        >{`Does ${name} have any health issues?`}</legend>
        <RadioButton
          className={styles.radio}
          name="yes"
          label="Yes"
          value="yes"
          onChange={e => handleChange(e)}
          checked={hasHealthIssues === true}
        />

        <RadioButton
          className={styles.radio}
          name="no"
          label="No"
          value="no"
          onChange={e => handleChange(e)}
          checked={hasHealthIssues === false}
        />
      </fieldset>

      <AnimateHeight
        nodeRef={checkBoxContainerRef}
        animateIn={hasHealthIssues === true}
      >
        <div ref={checkBoxContainerRef}>
          <Grid rowGap="md">
            <GridItem>
              <Text
                as="span"
                variant="article-16"
                fontStyle="italic"
                aria-hidden
                color="charcoal-2"
              >
                Tap All That Apply
              </Text>
            </GridItem>
            <GridItem>
              {healthIssues?.map((healthIssue, i) => {
                const isChecked = value?.includes(healthIssue as HealthIssue);

                return (
                  <PetHealthCheckbox
                    key={`health-issue-${i}`}
                    id={`health-issue-${i}`}
                    name={healthIssue}
                    value={healthIssue}
                    onChange={handleCheckboxChange}
                    checked={isChecked}
                    hasWarning={isHealthIssueWithWarning(
                      healthIssue as HealthIssue
                    )}
                  >
                    {healthIssue}
                  </PetHealthCheckbox>
                );
              })}
            </GridItem>
          </Grid>
        </div>
      </AnimateHeight>
      <AnimateHeight nodeRef={statusMessageRef} animateIn={warningMessage}>
        <div ref={statusMessageRef}>
          <Section as="div" widthConstraint="sm" topSpacing="md">
            <StatusMessage politeness="polite">
              <Text variant="article-16" color="error-red" fontStyle="italic">
                {HEALTH_ISSUE_WARNING_MESSAGE}
              </Text>
            </StatusMessage>
          </Section>
        </div>
      </AnimateHeight>
    </AddDogContent>
  );
};
