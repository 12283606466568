import { useMachine } from '@xstate/react';
import { AddDogEvent } from './AddDogEvent';
import { addDogMachine } from './addDogMachine';
import { AddDogState } from './AddDogState';

export interface UseAddDogMachineProps {
  isState: (state: AddDogState) => boolean;
  onNext: () => void;
  onPrevious: () => void;
  onClose: () => void;
  onSelectRecipes: () => void;
}

export function useAddDogMachine() {
  const [state, send] = useMachine(addDogMachine);

  const isState = (stateInput: AddDogState) => {
    return state.matches(stateInput);
  };

  const onNext = () => {
    send({ type: AddDogEvent.Next });
  };

  const onPrevious = () => {
    send({ type: AddDogEvent.Previous });
  };

  const onClose = () => {
    send({ type: AddDogEvent.Close });
  };

  const onSelectRecipes = () => {
    send({ type: AddDogEvent.SelectRecipes });
  };

  return {
    isState,
    onNext,
    onPrevious,
    onClose,
    state,
    onSelectRecipes,
  };
}
