import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

interface Options {
  /** If true, will truncate month to 3 letters. */
  abbreviatedMonth?: boolean;
}

/**
 *
 * @param dateString - the date string to format
 * @param options - optional arguments
 * @returns dateString formatted to month and day.
 * @throws if parsed date string produces an "Invalid Date"
 *
 * @example
 * ```ts
 * formatDateToMonthAndDay('2000-01-01') // January 1
 * formatDateToMonthAndDay('2000-01-01', { abbreivatedMonth: true }) // Jan 1
 * ```
 */
export function formatDateToMonthAndDay(dateString: string, options?: Options) {
  const parsed = parseISO(dateString);

  if (parsed.toString() === 'Invalid Date') {
    throw new Error('invalid date');
  }

  return format(parsed, options?.abbreviatedMonth ? 'MMM d' : 'MMMM d');
}
