import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { trackPricesSeen } from './trackPricesSeen';
import type { PriceLoggingMetadata } from './trackPricesSeen';

export const useTrackPricesSeen = (priceMetadata: PriceLoggingMetadata) => {
  const {
    discountPercentage,
    dailyPrice,
    preTaxTotal,
    postTaxTotal,
    taxTotal,
    orderId,
    petId,
    frequency,
  } = priceMetadata;

  useEffect(() => {
    const metadataObject = {
      discountPercentage,
      dailyPrice,
      preTaxTotal,
      postTaxTotal,
      taxTotal,
      orderId,
      petId,
      frequency,
    };
    if (!Object.values(metadataObject).every(value => isNil(value))) {
      trackPricesSeen(metadataObject);
    }
  }, [
    discountPercentage,
    dailyPrice,
    preTaxTotal,
    postTaxTotal,
    taxTotal,
    orderId,
    petId,
    frequency,
  ]);
};
