import { Component } from 'react';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import browserOnly from 'src/utils/browserOnly';

import './Sticky.scss';

const ANIMATION_DURATION = 250;
const VENDOR_NAMES = ['sticky', '-webkit-sticky'];

/**
 * Creates a sticky container. Internally, it will use `position: sticky` when
 * supported.
 */
/**
 * @deprecated See CH#49502
 */
class Sticky extends Component {
  static propTypes = {
    /** HTML element to render */
    as: PropTypes.string,
    /** Switch between `sticky` and `fixed` positioning */
    behavior: PropTypes.oneOf(['sticky', 'fixed']),
    /** @ignore React node */
    children: PropTypes.node.isRequired,
    /** @ignore CSS class name */
    className: PropTypes.string,
  };

  static defaultProps = {
    as: 'aside',
    behavior: 'sticky',
  };

  constructor(props) {
    super(props);

    this.el = null;

    this.setNodeHeight = this.setNodeHeight.bind(this);

    this.state = {
      supportsSticky: true,
      height: 0,
      cachedHeight: undefined,
    };
  }

  componentDidMount() {
    this.setSupports();
    this.setNodeHeight();
  }

  shouldComponentUpdate(prevProps, prevState) {
    return prevProps !== this.props || prevState !== this.state;
  }

  componentWillUnmount() {
    const { supportsSticky } = this.state;
    if (supportsSticky) {
      return;
    }
  }

  setSupports() {
    browserOnly(window => {
      this.setState({
        supportsSticky:
          typeof window.CSS !== 'undefined' &&
          VENDOR_NAMES.some(name =>
            window?.CSS?.supports?.(`position: ${name}`)
          ),
      });
    });
  }

  setNodeHeight() {
    const { height, cachedHeight } = this.state;
    if (this.el && height !== cachedHeight) {
      this.setState(state => ({
        height: this.el.getBoundingClientRect().height,
        cachedHeight: state.height,
      }));
    }
  }

  get stickyStyles() {
    const { height } = this.state;
    return {
      entering: {
        transition: `margin ${ANIMATION_DURATION}ms ease`,
        marginTop: height,
      },
      entered: {
        transition: `margin 0ms ease`,
        marginTop: 0,
      },
      exiting: {
        transition: `margin ${ANIMATION_DURATION}ms ease`,
        marginTop: -height,
      },
      exited: {
        transition: `margin 0ms ease`,
        marginTop: 0,
      },
    };
  }

  render() {
    const {
      as: HTMLElement,
      behavior,
      className,
      children,
      ...props
    } = this.props;
    return (
      <Transition
        in={behavior === 'sticky'}
        timeout={ANIMATION_DURATION}
        onEnter={this.setNodeHeight}
      >
        {state => (
          <>
            <HTMLElement
              ref={el => (this.el = el)}
              className={classNames(
                'Sticky',
                `Sticky-${state}`,
                {
                  'Sticky-no-polyfill':
                    behavior === 'sticky' && !this.state.supportsSticky,
                },
                className
              )}
              {...props}
            >
              {children}
            </HTMLElement>
            <div style={this.stickyStyles[state]} />
          </>
        )}
      </Transition>
    );
  }
}

export default Sticky;
