import { Button, ContentReveal } from '@farmersdog/corgi-x';

interface SkipPetButtonProps {
  label: string;
  className: string;
  shouldShow: boolean;
  onClick: () => Promise<void>;
}

export function SkipPetButton({
  label,
  className,
  shouldShow,
  onClick,
}: SkipPetButtonProps) {
  if (!shouldShow) {
    return null;
  }

  return (
    <ContentReveal in={shouldShow} inAnimation="fade">
      <Button variant="secondary" onClick={onClick} className={className}>
        {label}
      </Button>
    </ContentReveal>
  );
}
