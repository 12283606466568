import { useState } from 'react';

import { Link, ContentWidth } from '@farmersdog/corgi';
import { Text, Gallery, PageWidth, Wave } from '@farmersdog/corgi-x';
import { MARGIN_MD } from '@farmersdog/corgi/constants';

import { useCorgiViewport } from 'src/screen';

import { Title } from '../Title';
import { GallerySlide } from './GallerySlide';

import slides from './slides';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import classNames from 'classnames';
import styles from './GallerySection.module.scss';

const INITIAL_INDEX = 0;
const title = 'What is legally allowed in pet food?';

/**
 * Render a gallery of slides inside a decorative wave.
 */
export function GallerySection() {
  const [activeIndex, setActiveIndex] = useState(INITIAL_INDEX);
  const isWide = useCorgiViewport({
    xs: false,
    lg: true,
  });

  const { isAccessibilityThemeOn, KaleBackgroundWrapper } =
    useAccessibilityThemeContext();

  const fullWidth = useCorgiViewport({
    xs: false,
    xl: true,
  });

  const activeSlide = slides[activeIndex];

  const sectionContent = (
    <>
      <ContentWidth fr={2} alignSelf="center">
        <Title
          color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
          className={classNames(styles.title, {
            [styles.accessibilityTheme]: isAccessibilityThemeOn,
          })}
        >
          {title}
        </Title>
      </ContentWidth>
      <Gallery
        mode={isAccessibilityThemeOn ? 'darker' : 'light'}
        label={title}
        isWide={isWide}
        onSlideChange={setActiveIndex}
        className={classNames(styles.gallery, {
          [styles.accessibilityTheme]: isAccessibilityThemeOn,
        })}
        withDots
        footnote={
          <ContentWidth fr={2} alignSelf="center">
            <Text
              variant="heading-16"
              color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
            >
              <p>{activeSlide.caption}</p>
              <p>
                Source:{' '}
                <Text
                  variant="heading-16"
                  color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                  bold
                >
                  <Link
                    href={activeSlide.citation}
                    mode="light"
                    className={
                      isAccessibilityThemeOn
                        ? styles.accessibilityThemeLink
                        : ''
                    }
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {activeSlide.source}
                  </Link>
                </Text>
              </p>
            </Text>
          </ContentWidth>
        }
      >
        {slides.map((slide, index) => (
          <GallerySlide key={index} {...slide} />
        ))}
      </Gallery>
    </>
  );

  const className = useCorgiViewport({ xs: styles.xs, lg: styles.lg });

  return isAccessibilityThemeOn ? (
    <KaleBackgroundWrapper>{sectionContent}</KaleBackgroundWrapper>
  ) : (
    <Wave
      withTop
      withBottom
      waveHeight={MARGIN_MD}
      topTransform="scaleX(-1) scaleY(-1) rotate(180deg)"
      bottomTransform="scaleX(-1) scaleY(-1)"
      fill="Kale3"
      padding="margin-xxl"
      className={className}
    >
      <PageWidth enabled={fullWidth}>{sectionContent}</PageWidth>
    </Wave>
  );
}
