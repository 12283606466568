export enum RatioBuckets {
  Topper = 'topper',
  Half = 'half',
  Full = 'full',
}

export function getPlanRatio(ratio: number): RatioBuckets {
  if (ratio < 0.45) {
    return RatioBuckets.Topper;
  } else if (ratio < 1) {
    return RatioBuckets.Half;
  }

  return RatioBuckets.Full;
}
