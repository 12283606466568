/**
 * A list of unique identifiers for each response (option) in the
 * cancellation flow.
 */
export const CancellationResponsesEnum = {
  Price: 'Price',
  TempPause: 'TempPause',
  DontLikeSubscriptions: 'DontLikeSubscriptions',
  OrderIssue: 'OrderIssue',
  FeedingFreshTooDifficult: 'FeedingFreshTooDifficult',
  FoodArrivedWarm: 'FoodArrivedWarm',
  CustomerServiceIssue: 'CustomerServiceIssue',
  HadntRealizedTotalCost: 'HadntRealizedTotalCost',
  FoodDidntWork: 'FoodDidntWork',
  NoLongerWithDog: 'NoLongerWithDog',
  NoLongerFostering: 'NoLongerFostering',
  SomeoneElseCaringForDog: 'SomeoneElseCaringForDog',
  DogPassed: 'DogPassed',
  TravelingMoving: 'TravelingMoving',
  TooMuchFood: 'TooMuchFood',
  NeedMoreTimeOnTrial: 'NeedMoreTimeOnTrial',
  PreferOrderingWhenNeeded: 'PreferOrderingWhenNeeded',
  SubscriptionDoesntWorkForMe: 'SubscriptionDoesntWorkForMe',
  OtherPriceReason: 'OtherPriceReason',
  FinancialSituationChanged: 'FinancialSituationChanged',
  PortionsCalsIncorrect: 'PortionsCalsIncorrect',
  DidNotSeeAChange: 'DidNotSeeAChange',
  ConcernedWithNutrition: 'ConcernedWithNutrition',
  PhysicalReaction: 'PhysicalReaction',
  VetRecommendedDifferentFood: 'VetRecommendedDifferentFood',
  ConcernedAboutIngredients: 'ConcernedAboutIngredients',
  DogIsAllergic: 'DogIsAllergic',
  VetHasRecipeConcerns: 'VetHasRecipeConcerns',
  VetHasFreshFoodConcerns: 'VetHasFreshFoodConcerns',
  VetRecommendedPrescriptionDiet: 'VetRecommendedPrescriptionDiet',
  VomitingDigestiveStool: 'VomitingDigestiveStool',
  OtherPhysicalReaction: 'OtherPhysicalReaction',
  AllergicReaction: 'AllergicReaction',
  ServingTooMessy: 'ServingTooMessy',
  PortioningTooDifficult: 'PortioningTooDifficult',
  ReceivingDeliveriesInconvient: 'ReceivingDeliveriesInconvient',
  StoringThawingInconvenient: 'StoringThawingInconvenient',
  DeliveryCarrierIssue: 'DeliveryCarrierIssue',
  RanOutOfFood: 'RanOutOfFood',
  ItemsDamagedIncorrect: 'ItemsDamagedIncorrect',
  FoodQuality: 'FoodQuality',
  ScheduleUnpredictable: 'ScheduleUnpredictable',
  BoxesNotProperlyHandled: 'BoxesNotProperlyHandled',
  BoxesDelayed: 'BoxesDelayed',
  OtherDeliveryIssue: 'OtherDeliveryIssue',
  DontHaveStorageSpace: 'DontHaveStorageSpace',
  TransferringToFridgeDifficult: 'TransferringToFridgeDifficult',
  PreferShelfStable: 'PreferShelfStable',
  WantToFeedOneBoxAtATime: 'WantToFeedOneBoxAtATime',
  Diabetes: 'Diabetes',
  HydrolizedProtein: 'HydrolizedProtein',
  JointSupport: 'JointSupport',
  LiverSupport: 'LiverSupport',
  LowFat: 'LowFat',
  NeoplasiaDiet: 'NeoplasiaDiet',
  RenalDisease: 'RenalDisease',
  SkinSupport: 'SkinSupport',
  WeightLoss: 'WeightLoss',
  WeightManagement: 'WeightManagement',
  DryFood: 'DryFood',
  IllCookAtHome: 'IllCookAtHome',
  OtherFreshFood: 'OtherFreshFood',
  RawFood: 'RawFood',
  WetFood: 'WetFood',
  DehydratedFood: 'DehydratedFood',
  StillTheFarmersDog: 'StillTheFarmersDog',
  DogDidntLikeIt: 'DogDidntLikeIt',
  DogNeverTouchedFood: 'DogNeverTouchedFood',
  DogAteSome: 'DogAteSome',
  DogUsedToLikeIt: 'DogUsedToLikeIt',
  NeedMoreOrOtherRecipes: 'NeedMoreOrOtherRecipes',
  NeedVegetarianRecipe: 'NeedVegetarianRecipe',
  NeedRecipeWithGrain: 'NeedRecipeWithGrain',
  NeedFishRecipe: 'NeedFishRecipe',
  NeedLowerFatRecipe: 'NeedLowerFatRecipe',
  NeedOtherRecipe: 'NeedOtherRecipe',
  NeedLifeStageSpecificFood: 'NeedLifeStageSpecificFood',
  VetRecommendedDifferentFoodNonPrescription:
    'VetRecommendedDifferentFoodNonPrescription',
  SubscriptionServicesDontWorkForMe: 'SubscriptionServicesDontWorkForMe',
  UnawareItWasASubscription: 'UnawareItWasASubscription',
} as const;

export type CancellationResponsesEnumType =
  keyof typeof CancellationResponsesEnum;

/**
 * Each unique cancellation reason has associated text to be displayed
 * to the user as an option.
 */
export const ReasonLabelEnum: Record<CancellationResponsesEnumType, string> = {
  [CancellationResponsesEnum.Price]: 'Price',
  [CancellationResponsesEnum.TempPause]: `I would like to pause temporarily`,
  [CancellationResponsesEnum.DontLikeSubscriptions]:
    'I do not like subscription services',

  [CancellationResponsesEnum.FeedingFreshTooDifficult]:
    'Feeding fresh is too difficult or inconvenient',
  [CancellationResponsesEnum.OrderIssue]: `There was an issue with my order(s)`,
  [CancellationResponsesEnum.FoodArrivedWarm]: `My dog's food arrived warm or spoiled`,
  [CancellationResponsesEnum.HadntRealizedTotalCost]: `I hadn't realized the total cost of my orders when I signed up`,
  [CancellationResponsesEnum.FoodDidntWork]:
    'This food doesn’t work for my dog',
  [CancellationResponsesEnum.NoLongerWithDog]: 'My dog is no longer with me',
  [CancellationResponsesEnum.NoLongerFostering]:
    'I was fostering until they found a permanent home',
  [CancellationResponsesEnum.SomeoneElseCaringForDog]:
    'Someone else is caring for my dog now',
  [CancellationResponsesEnum.DogPassed]: 'My dog passed away',
  [CancellationResponsesEnum.TravelingMoving]: 'I am traveling or moving',
  [CancellationResponsesEnum.TooMuchFood]: 'I have too much food on hand',
  [CancellationResponsesEnum.NeedMoreTimeOnTrial]:
    'I need more time on my trial',
  [CancellationResponsesEnum.PreferOrderingWhenNeeded]:
    'I prefer to place orders as I need them',
  [CancellationResponsesEnum.SubscriptionDoesntWorkForMe]:
    'Subscription service doesn’t work for my needs',
  [CancellationResponsesEnum.OtherPriceReason]: 'Other price related reason',
  [CancellationResponsesEnum.FinancialSituationChanged]:
    'My financial situation has changed, and the cost of the food is now above my current budget',
  [CancellationResponsesEnum.PortionsCalsIncorrect]:
    'My dog’s portions or calories were incorrect',
  [CancellationResponsesEnum.DidNotSeeAChange]:
    'I did not see a change in my dog that I was expecting from improving their diet',
  [CancellationResponsesEnum.ConcernedWithNutrition]:
    'I am concerned/dissatisfied with a nutritional aspect of the food',
  [CancellationResponsesEnum.PhysicalReaction]:
    'My dog had a physical reaction I didn’t like',
  [CancellationResponsesEnum.VetRecommendedDifferentFood]:
    'My vet recommended a different food',
  [CancellationResponsesEnum.ConcernedAboutIngredients]:
    'I am concerned about ingredients in or not in your recipes',
  [CancellationResponsesEnum.DogIsAllergic]:
    'My dog is allergic to ingredients in a recipe',
  [CancellationResponsesEnum.VetHasRecipeConcerns]:
    'My vet has concerns about recipes from The Farmer’s Dog',
  [CancellationResponsesEnum.VetRecommendedPrescriptionDiet]:
    'My vet recommended a prescription diet',
  [CancellationResponsesEnum.VetHasFreshFoodConcerns]:
    'My vet has concerns about feeding dogs fresh food',
  [CancellationResponsesEnum.VomitingDigestiveStool]:
    'My dog experienced vomiting, digestive, or stool issues',
  [CancellationResponsesEnum.AllergicReaction]:
    'My dog had an allergic reaction',
  [CancellationResponsesEnum.OtherPhysicalReaction]:
    'My dog had a different physical reaction',
  [CancellationResponsesEnum.ServingTooMessy]: 'Serving the food is too messy',
  [CancellationResponsesEnum.PortioningTooDifficult]:
    'Portioning the food correctly is too difficult',
  [CancellationResponsesEnum.StoringThawingInconvenient]:
    'Storing the food in the freezer or thawing it is too inconvenient',
  [CancellationResponsesEnum.ReceivingDeliveriesInconvient]:
    'Being home soon enough after deliveries is inconvenient',
  [CancellationResponsesEnum.DeliveryCarrierIssue]:
    'I had delivery or carrier issues',
  [CancellationResponsesEnum.RanOutOfFood]: 'I ran out of food',
  [CancellationResponsesEnum.ItemsDamagedIncorrect]:
    'Items in my box were incorrect or damaged',
  [CancellationResponsesEnum.CustomerServiceIssue]:
    'I had an issue with customer service',
  [CancellationResponsesEnum.FoodQuality]:
    'I had an issue with the quality of the food',
  [CancellationResponsesEnum.ScheduleUnpredictable]:
    'My delivery schedule is unpredictable',
  [CancellationResponsesEnum.BoxesNotProperlyHandled]:
    'The carrier did not properly handle my box(es)',
  [CancellationResponsesEnum.BoxesDelayed]:
    'My boxes were consistently delayed',
  [CancellationResponsesEnum.OtherDeliveryIssue]: 'Other',
  [CancellationResponsesEnum.DontHaveStorageSpace]:
    'I don’t have the storage space in my freezer/fridge',
  [CancellationResponsesEnum.TransferringToFridgeDifficult]:
    'The process of transferring from freezer to fridge is too difficult',
  [CancellationResponsesEnum.PreferShelfStable]: 'I prefer shelf stable foods',
  [CancellationResponsesEnum.WantToFeedOneBoxAtATime]:
    'I would like to continue to feed The Farmer’s Dog but one box at a time',
  [CancellationResponsesEnum.Diabetes]: 'Diabetes',
  [CancellationResponsesEnum.HydrolizedProtein]: 'Hydrolized protein',
  [CancellationResponsesEnum.JointSupport]: 'Joint support',
  [CancellationResponsesEnum.LiverSupport]: 'Liver support',
  [CancellationResponsesEnum.LowFat]: 'Low fat',
  [CancellationResponsesEnum.NeoplasiaDiet]: 'Neoplasia diet',
  [CancellationResponsesEnum.RenalDisease]: 'Renal disease',
  [CancellationResponsesEnum.SkinSupport]: 'Skin support',
  [CancellationResponsesEnum.WeightLoss]: 'Weight loss',
  [CancellationResponsesEnum.WeightManagement]: 'Weight management',
  [CancellationResponsesEnum.DryFood]: 'Dry food',
  [CancellationResponsesEnum.IllCookAtHome]: `I'll cook at home`,
  [CancellationResponsesEnum.OtherFreshFood]: 'Other fresh food',
  [CancellationResponsesEnum.RawFood]: 'Raw food',
  [CancellationResponsesEnum.WetFood]: 'Wet food',
  [CancellationResponsesEnum.DehydratedFood]: 'Dehydrated food',
  [CancellationResponsesEnum.StillTheFarmersDog]: `Still feeding The Farmer's Dog`,
  [CancellationResponsesEnum.DogDidntLikeIt]: `My dog didn't like it`,
  [CancellationResponsesEnum.DogNeverTouchedFood]: `My dog never even touched the food`,
  [CancellationResponsesEnum.DogAteSome]: `My dog ate some but doesn't seem to love it`,
  [CancellationResponsesEnum.DogUsedToLikeIt]: `My dog used to like it, but now is not eating it`,
  [CancellationResponsesEnum.NeedMoreOrOtherRecipes]:
    'I need more or other recipes',
  [CancellationResponsesEnum.NeedVegetarianRecipe]: 'Vegetarian',
  [CancellationResponsesEnum.NeedRecipeWithGrain]: 'With grain',
  [CancellationResponsesEnum.NeedFishRecipe]: 'Fish',
  [CancellationResponsesEnum.NeedLowerFatRecipe]: 'Lower fat',
  [CancellationResponsesEnum.NeedOtherRecipe]: 'Other',
  [CancellationResponsesEnum.NeedLifeStageSpecificFood]:
    'I need food specific to my dog’s lifestage',
  [CancellationResponsesEnum.VetRecommendedDifferentFoodNonPrescription]:
    'My vet recommended another type of food that is not a prescription diet',
  [CancellationResponsesEnum.SubscriptionServicesDontWorkForMe]:
    'Subscription services do not work for me in general',
  [CancellationResponsesEnum.UnawareItWasASubscription]:
    'I was unaware this was a subscription when I checked out',
};

/**
 * This data structure is used to display reasons in the UI (each
 * option/value is associated with a label).
 */
export interface CancellationResponse {
  value: CancellationResponsesEnumType;
  label: string;
}
