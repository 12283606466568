export const eventNames = {
  cancel_subscription_diy_downsell_cta: `Cancel Subscription - DIY Downsell CTA`,
  cancel_subscription_compiled_desired_price:
    'Cancel Subscription - Compiled desired price',
  cancel_subscription_flow_start: 'Cancel Subscription - Flow start',
  cancel_subscription_flow_exit: 'Cancel Subscription - Flow exit',
  cancel_subscription_flow_save: 'Cancel Subscription - Flow save',
  cancel_subscription_enter_downsell_flow:
    'Cancel Subscription - Enter Downsell Flow',
  cancel_subscription_click_start_mixing_plan:
    'Cancel Subscription - Click Start Mixing Plan',
  cancel_subscription_click_accept_lpf_downsell:
    'Cancel Subscription - Click Accept LPF Downsell',
  cancel_subscription_mixing_plan_downsell_click_adjust_daily_calories:
    'Cancel Subscription - Mixing Plan Downsell - Click Adjust Daily Calories',
  cancel_subscription_continue_canceling:
    'Cancel Subscription - Continue Canceling',
};
