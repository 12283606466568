import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

export function trackTreatsNudgeShopNow() {
  track(eventNames.clicked_shop_treats_now);
}

export function trackTreatsNudgeMaybeLater() {
  track(eventNames.clicked_maybe_later);
}
