import { PreloadLinkType } from '@farmersdog/tosa/src/components/shared/PreloadLink';
import { LG_VALUE, MD_VALUE } from 'src/screen/config/corgiV2.constants';

export const mobileMediaQuery = `(max-width: ${LG_VALUE - 1}px)`;
export const desktopMediaQuery = `(min-width: ${LG_VALUE}px)`;

export function generateSrcSetString(src: string, size: number): string {
  return `${src} ${size}w`;
}

interface PreloaderSources {
  webP: string;
  jpg: string;
}

export interface MobileAndDesktopSources {
  mobileSources: PreloaderSources;
  desktopSources: PreloaderSources;
}

export function generateSourcesForPreloader({
  mobileSources,
  desktopSources,
}: MobileAndDesktopSources) {
  const mobileSrcSetStrings = [
    generateSrcSetString(mobileSources.webP, MD_VALUE),
    generateSrcSetString(mobileSources.jpg, MD_VALUE),
  ];

  const desktopSrcSetStrings = [
    generateSrcSetString(desktopSources.webP, LG_VALUE),
    generateSrcSetString(desktopSources.jpg, LG_VALUE),
  ];

  const mobileSourcesForPreloader = {
    imageSrcSet: mobileSrcSetStrings.join(', '),
    media: mobileMediaQuery,
  };

  const desktopSourcesForPreloader = {
    imageSrcSet: desktopSrcSetStrings.join(', '),
    media: desktopMediaQuery,
  };

  return [mobileSourcesForPreloader, desktopSourcesForPreloader];
}

export function generateSourcesForPicture({
  mobileSources,
  desktopSources,
}: MobileAndDesktopSources) {
  const mobileSourcesForPreloader = [
    {
      src: mobileSources.webP,
      type: PreloadLinkType.Webp,
      media: mobileMediaQuery,
    },
    {
      src: mobileSources.jpg,
      type: PreloadLinkType.Jpg,
      media: mobileMediaQuery,
    },
  ];

  const desktopSourcesForPreloader = [
    {
      src: desktopSources.webP,
      type: PreloadLinkType.Webp,
      media: desktopMediaQuery,
    },
    {
      src: desktopSources.jpg,
      type: PreloadLinkType.Jpg,
      media: desktopMediaQuery,
    },
  ];

  return [...mobileSourcesForPreloader, ...desktopSourcesForPreloader];
}
