import { SuggestOrderSizeUpMachineContext } from './suggestOrderSizeUpMachine';

/**
 * Events in the Suggest Order Size Up workflow
 */
export enum SuggestOrderSizeUpEvent {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  Close = 'CLOSE',
  ContinueToPlan = 'CONTINUE_TO_PLAN',
}

export type SuggestOrderSizeUpEventMachineEvent = {
  type: SuggestOrderSizeUpEvent;
} & SuggestOrderSizeUpMachineContext;
