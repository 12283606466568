import { Script } from '../../components/Script';
import { useIsCustomerPortalPath } from '../../utils';
import config from '../../config/config';

export function PrismicPreviewToolbarScript() {
  const isCustomerPortalPath = useIsCustomerPortalPath();

  if (!isCustomerPortalPath) {
    return null;
  }

  if (!config.get('prismic.toolbarEnabled')) {
    return null;
  }

  return (
    <Script
      async
      defer
      src="https://static.cdn.prismic.io/prismic.js?new=true&repo=thefarmersdog"
    />
  );
}
