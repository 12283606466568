import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

import { mountAudacyPageView } from './mountAudacyPageView';

export const audacyPageViewPaths = [
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
];

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for an Audacy page view event.
 */
export function useMountAudacyPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    const shouldTrackAudacyPageView = audacyPageViewPaths.includes(pathname);

    if (shouldTrackAudacyPageView) {
      mountAudacyPageView();
    }
  }, [pathname]);
}
