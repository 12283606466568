import { Pixel } from '../../Pixel';

export const PIXEL_ID = 12607;

export enum BarometricEventName {
  HOMEPAGE_VIEW = 'landingpage_homepage',
  CONVERSION = 'purchase_confirmation',
}
export enum BarometricEventId {
  HOMEPAGE_VIEW = 34766,
  CONVERSION = 34767,
}

export interface BarometricAttributes extends Record<string, unknown> {
  ppt: number;
  g: BarometricEventName;
  gid: BarometricEventId;
  cid?: number;
}

export const barometric = new Pixel<BarometricAttributes>({
  vendorName: 'Barometric',
  baseUrl: 'https://trkn.us/pixel/conv/',
  stringifyOptions: {
    delimiter: ';',
  },
});
