import { getSessionProperties } from '@farmersdog/sessions';
import {
  redactEmailsFromPath,
  ENGAGEMENT_TRACKER_VERSION,
} from '@farmersdog/utils';

import analytics from './analytics';
import { getSegmentOpts } from './helpers/getSegmentOpts';
export interface PageProperties extends Record<string, unknown> {
  title?: string;
}

export const titleTemplate = '%s | The Farmer’s Dog';
export interface PageArgs<T> {
  path: string;
  baseUrl?: string;
  properties?: T;
  callbackIfMethodIsUndefined?: () => void;
}

function page<T extends PageProperties>({
  path,
  baseUrl,
  properties,
  callbackIfMethodIsUndefined,
}: PageArgs<T>) {
  const sessionProperties = getSessionProperties(ENGAGEMENT_TRACKER_VERSION);
  const cleanedPath = redactEmailsFromPath(path);

  const formattedPropertiesWithSessions = {
    ...properties,
    ...sessionProperties,
    title: properties?.title
      ? titleTemplate.replace('%s', String(properties.title))
      : undefined,
    path: cleanedPath,
    url: `${baseUrl ?? ''}${cleanedPath}`,
  };

  const opts = getSegmentOpts({ eventType: 'page' });

  analytics(callbackIfMethodIsUndefined).page(
    cleanedPath,
    formattedPropertiesWithSessions,
    opts
  );
}

export default page;
