import type { OnStepCompleted } from '@farmersdog/tosa';
import { getTosaFacebookPixelAttributes } from 'src/vendors/facebook';
import { setGoogleTagManagerVar } from 'src/vendors/googleTagManager';

export function useHandleTosaStepCompleted(): OnStepCompleted {
  const handleStepCompleted: OnStepCompleted = (_, lead) => {
    if (lead) {
      const pixelAttributes = getTosaFacebookPixelAttributes({ lead });

      Object.entries(pixelAttributes).forEach(([attribute, value]) => {
        setGoogleTagManagerVar(attribute, value);
      });
    }
  };

  return handleStepCompleted;
}
