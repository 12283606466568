export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Email: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Phone: { input: any; output: any };
  StripeSource: { input: any; output: any };
};

export type AddOnInput = {
  /** Is this a one-time purchase (as opposed to a recurring subscription)? */
  isOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
  /** Product quantity */
  quantity: Scalars['Int']['input'];
};

export type AddOptOutUserInput = {
  /** Segment ID of users opting out of tracking */
  anonymousId: Scalars['String']['input'];
  /** Email address of user opting out of tracking */
  email: Scalars['Email']['input'];
  /** Full name of user opting out of tracking */
  name: Scalars['String']['input'];
  /** Phone number of user opting out of tracking */
  phone: Scalars['Phone']['input'];
};

export type AddressInput = {
  /** The street address */
  addressLine1: Scalars['String']['input'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The ID of the country */
  countryId: Scalars['Int']['input'];
  /** Delivery instructions for the address (FedEx and LaserShip only) */
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the address */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the state */
  stateId: Scalars['Int']['input'];
  /** Is the address validated */
  validated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The zipcode of the address */
  zip: Scalars['String']['input'];
};

export enum AddressType {
  /** Address is a billing address */
  Billing = 'billing',
  /** Address is a shipping address */
  Shipping = 'shipping',
}

export enum BreedSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XLarge = 'x_large',
  XSmall = 'x_small',
}

/** The user's current and new passwords. */
export type ChangePasswordInput = {
  /** The user's current password */
  currentPassword: Scalars['String']['input'];
  /** The user's new password */
  newPassword: Scalars['String']['input'];
};

export type CheckoutInput = {
  /** Acquisition channel related metadata needed at checkout */
  attributionMetadata?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The billing address of the User */
  billingAddress: AddressInput;
  /** The communication preferences of the User */
  communicationPreferences?: InputMaybe<CommunicationPreferencesInput>;
  /** The referrer in the coupon cookie at checkout */
  cookieReferrer?: InputMaybe<CookieReferrer>;
  /** The email address of the User */
  email: Scalars['Email']['input'];
  /** The first name of the User */
  firstName: Scalars['String']['input'];
  /** The full name of the User */
  fullName: Scalars['String']['input'];
  /** The last name of the User */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The UTC time at which lead was created */
  leadCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The password of the User */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the User */
  phone: Scalars['Phone']['input'];
  /** The quote the user viewed before checking out */
  proposedQuote?: InputMaybe<ProposedQuote>;
  /** The shipping address of the User */
  shippingAddress: AddressInput;
  /** User Stripe token or full payload */
  stripeToken: Scalars['StripeSource']['input'];
  /** Subscription details about the User */
  subscription: PendingSubscriptionInputType;
};

export type CommunicationPreferencesInput = {
  /** Should customer receive marketing by SMS? */
  marketingSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should customer receive order reminders by SMS? */
  orderRemindersSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should customer receive order updates by SMS? */
  orderUpdatesSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CookieReferrer = {
  /** The time the referrer associated with the coupon cookie */
  referrerAssociatedAt: Scalars['String']['input'];
  /** The referrer code in the coupon cookie at checkout */
  referrerCode: Scalars['String']['input'];
  /** The referrer type in the coupon cookie at checkout */
  referrerType: ReferrerType;
};

export type CreateMyPetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name: Scalars['String']['input'];
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name: Scalars['String']['input'];
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** When true remove pet from customer account and all communications.  Typically this is done when the pet has passed. */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does the pet require a special diet */
  specialDiet?: InputMaybe<Scalars['Boolean']['input']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** The pet's current food */
export type CurrentFoodInput = {
  /** Amount of the current food being consumed on a daily basis */
  amount?: InputMaybe<Scalars['String']['input']>;
  /** The brand of the current food */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** The id of the pet's current food */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The specific product for the current food */
  product?: InputMaybe<Scalars['String']['input']>;
  /** The type of current food */
  type?: InputMaybe<CurrentFoodType>;
  /** The measurement units of the amount of current food */
  unit?: InputMaybe<CurrentFoodUnit>;
};

export enum CurrentFoodType {
  /** Dehydrated food */
  Dehydrated = 'dehydrated',
  /** Dry food */
  Dry = 'dry',
  /** Fresh food */
  Fresh = 'fresh',
  /** Homemade food */
  Homemade = 'homemade',
  /** Raw food */
  Raw = 'raw',
  /** Wet food */
  Wet = 'wet',
}

export enum CurrentFoodUnit {
  /** Food amount measurement in cups */
  Cup = 'cup',
  /** Food amount measurement in grams */
  Gram = 'gram',
}

export type DiyRecommendationInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FreshFoodConfidenceEnum {
  AlreadyBelieve = 'ALREADY_BELIEVE',
  AmNotSureYet = 'AM_NOT_SURE_YET',
  DontBelieve = 'DONT_BELIEVE',
}

export type FreshRecommendationInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The type of health issue */
export enum HealthIssueType {
  /** System default health issue */
  Default = 'default',
  /** User created health issue */
  User = 'user',
}

/** The user's email address and password. */
export type LoginInput = {
  /** The user's email address */
  email: Scalars['Email']['input'];
  /** The user's password */
  password: Scalars['String']['input'];
};

export enum NoRecommendationReasonsType {
  AdultDogWithExtremeIncreasedWeight = 'ADULT_DOG_WITH_EXTREME_INCREASED_WEIGHT',
  PuppyWithAbnormalWeightChange = 'PUPPY_WITH_ABNORMAL_WEIGHT_CHANGE',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export enum OrderDiscountReason {
  SecondBoxDiscount = 'SecondBoxDiscount',
  SubscriberFreeFirstTreats = 'SubscriberFreeFirstTreats',
  SubscriptionDiscount = 'SubscriptionDiscount',
  SurpriseFreeTreats = 'SurpriseFreeTreats',
  TreatsInCheckout = 'TreatsInCheckout',
  TrialDiscount = 'TrialDiscount',
}

export enum OrderDiscountType {
  /** A discount applied to the entire order */
  Order = 'order',
  /** A discount applied to a specific SKU */
  Sku = 'sku',
}

export enum OrderPaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Free = 'free',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Processing = 'processing',
  Queued = 'queued',
  Refunded = 'refunded',
  Transferred = 'transferred',
  Unpaid = 'unpaid',
}

export enum OrderQueuePosition {
  Future = 'future',
  NextToBeDelivered = 'next_to_be_delivered',
  Past = 'past',
}

export enum OrderShipmentStatus {
  AcquisitionFailed = 'acquisition_failed',
  Cancelled = 'cancelled',
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Scheduled = 'scheduled',
  Shipped = 'shipped',
  Transit = 'transit',
  Unscheduled = 'unscheduled',
}

export type OrderSortBy = {
  /** Sorting direction */
  dir: SortDirectionEnum;
  /** The field on which to sort by */
  field: OrderSortFieldEnum;
};

export enum OrderSortFieldEnum {
  ArrivalDate = 'arrivalDate',
  ShipDate = 'shipDate',
}

export enum OrderState {
  Cancelled = 'cancelled',
  Current = 'current',
  Delivered = 'delivered',
  Future = 'future',
  Invalid = 'invalid',
  Next = 'next',
}

export enum OrderType {
  Regular = 'regular',
  Trial = 'trial',
}

export type PendingSubscriptionAddOns = {
  /** The treats that the user has selected to add to their subscription */
  treats?: InputMaybe<Array<PendingSubscriptionAddOnsTreat>>;
};

export type PendingSubscriptionAddOnsTreat = {
  /** The name of the treats to be added to the subscription */
  name: Scalars['String']['input'];
  /** The number of packages of treats to add to the subscription */
  quantity: Scalars['Int']['input'];
  /** The size of the treats to add to the subscription */
  size: TreatSize;
};

export type PendingSubscriptionInputType = {
  /** The addons that the user has selected to add to their subscription */
  addOns?: InputMaybe<PendingSubscriptionAddOns>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: InputMaybe<Scalars['Int']['input']>;
  /** Whether to include trays and lids in the order */
  includeTraysAndLids?: InputMaybe<Scalars['Boolean']['input']>;
  /** The start date for a subscription */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** The type of the subscription. */
  type: SubscriptionType;
};

/** Paired with birthday, provides info on how accurate that info is */
export enum PetBirthdayAccuracy {
  /** Birthday is the exact date */
  Date = 'date',
  /** Birthday is accurate to the month */
  Months = 'months',
  /** Birthday is accurate to the week */
  Weeks = 'weeks',
  /** Birthday is accurate to the year */
  Years = 'years',
}

export enum PetGender {
  Female = 'female',
  Male = 'male',
}

export type PetMutationsInput = {
  /** List of pets to create */
  create?: InputMaybe<Array<CreatePetInput>>;
  /** List of pet IDs to delete */
  delete?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of pets to update */
  update?: InputMaybe<Array<UpdatePetInput>>;
};

export type PetsArgumentsInput = {
  /** Flag to grab pets based on whether they have an active of inactive food plan */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of pet ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type PlanStockStateInput = {
  /** Number of days a single food pack from the most recently delivered order is lasting */
  lastingDays?: InputMaybe<Scalars['Float']['input']>;
  /** A pet's plan ID */
  planId: Scalars['Int']['input'];
  /** How many total food packs the pet has at home */
  value: Scalars['Float']['input'];
};

/** The type of plan stock record */
export enum PlanStockType {
  /** The record resets the stock to 0 to begin recording changes again */
  Base = 'base',
  /** The record is recording a change to the running stock data */
  Change = 'change',
}

/** What type of plan a plan is */
export enum PlanType {
  /** A food plan */
  Food = 'food',
}

/** Whether a product is enabled or not */
export enum ProductStatus {
  /** This product is disabled */
  Disabled = 'disabled',
  /** This product is enabled */
  Enabled = 'enabled',
}

/** What type of product a product is */
export enum ProductType {
  /** DIY product (nutrition powder) */
  Diy = 'diy',
  /** Food product (a The Farmer's Dog recipe) */
  Food = 'food',
  /** Containers of Food */
  FoodContainer = 'food_container',
  /** Printed inserts like feeding guides */
  Leaflet = 'leaflet',
  /** Packaging material */
  Packaging = 'packaging',
  /** Supplement product like vitamins or oils */
  Supplement = 'supplement',
  /** Treat product like snacks or bones */
  Treat = 'treat',
  /** Ice for keeping the box cool during shipping */
  Undefined = 'undefined',
}

export type ProjectedOrdersInput = {
  /** The number of orders to project */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the subscription to project orders for */
  subscriptionId: Scalars['Int']['input'];
  /** Subscription overrides to use for projecting orders */
  subscriptionOverrides?: InputMaybe<PendingSubscriptionInputType>;
};

export type ProposedQuote = {
  /** The total on the add ons of the bill */
  addOnsTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the user submitted an updated address via the address modal */
  didUpdateAddress?: InputMaybe<Scalars['Boolean']['input']>;
  /** The discount amount of the bill */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** The discount percentage of the bill */
  discountPercentage: Scalars['Float']['input'];
  /** The grand total of the bill */
  grandTotal: Scalars['Float']['input'];
  /** Whether the user checked out with express pay */
  isExpressPay?: InputMaybe<Scalars['Boolean']['input']>;
  /** The referrer of the user */
  referrer?: InputMaybe<Referrer>;
  /** The tax amount of the bill */
  taxAmount: Scalars['Float']['input'];
};

export type RecalculatePlansFromFeedingInfoInput = {
  /** The pets to calculate plan data for */
  pets: Array<RecalculatePlansFromFeedingInfoPetInput>;
};

export type RecalculatePlansFromFeedingInfoPetInput = {
  /** A pet ID */
  id: Scalars['Int']['input'];
  /** Is our food being mixed with other food */
  isMixingWithOtherFood: Scalars['Boolean']['input'];
  /** How many days a pack of food is lasting */
  packLastingDays: Scalars['Float']['input'];
};

export type RecalculatePlansInput = {
  /** Active state of the new plan */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Calories in new plan */
  calories: Scalars['Int']['input'];
  /** Plan Id */
  id: Scalars['Int']['input'];
  /** Mixing ratio of new plan */
  ratio: Scalars['Float']['input'];
};

/** The product line of the recipe */
export enum RecipeProductLine {
  Core = 'core',
  Lpf = 'lpf',
}

/** The kind of recipe */
export enum RecipeType {
  /** For our main food recipes */
  Food = 'food',
  /** For treat recipes */
  Treat = 'treat',
}

export type RecommendFoodPlanInput = {
  /** The pet's activity level between 1 (low activity) and 4 (high activity) */
  activityLevel: Scalars['Int']['input'];
  /** The pet's birthdate */
  birthday: Scalars['Date']['input'];
  /** The pet's body condition between 1 (underweight) and 4 (overweight) */
  bodyCondition: Scalars['Int']['input'];
  /** List of the pet's breed IDs */
  breedIds: Array<Scalars['Int']['input']>;
  /** The percentage of this pet's calories that will come from The Farmer's Dog food */
  mixingPlanRatio: Scalars['Float']['input'];
  /** Is the pet neutered? */
  neutered: Scalars['Boolean']['input'];
  /** Ideal weight of the pet in grams */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** Weight of the pet in grams */
  weight: Scalars['Int']['input'];
};

/** Parameters for a recommendation request */
export type RecommendationsInput = {
  diy?: InputMaybe<DiyRecommendationInput>;
  fresh?: InputMaybe<FreshRecommendationInput>;
  treats?: InputMaybe<TreatsRecommendationInput>;
};

export type Referrer = {
  /** The time the referrer was associated */
  referrerAssociatedAt: Scalars['String']['input'];
  /** The id of the referrer */
  referrerId: Scalars['Int']['input'];
};

export enum ReferrerType {
  Partner = 'partner',
  User = 'user',
}

export type RequestPasswordResetInput = {
  /** The e-mail where the reset link will be sent */
  email: Scalars['Email']['input'];
};

export type RescheduleNextOrderInput = {
  /** More detailed reason info */
  reasonDescription: Scalars['String']['input'];
  /** Reason for reschedule */
  reasonId: RescheduleNextOrderReason;
  /** Date to schedule the next order for */
  shipDate: Scalars['Date']['input'];
  /** The subscription type to be updated */
  subscriptionType?: InputMaybe<SubscriptionType>;
  /** Whether the reschedule is a rush or a delay */
  type: RescheduleNextOrderTypes;
};

export enum RescheduleNextOrderReason {
  BillingDelay = 'billing_delay',
  BufferDelay = 'buffer_delay',
  BufferRush = 'buffer_rush',
  CalibrateDelay = 'calibrate_delay',
  CalibrateRush = 'calibrate_rush',
  DamagedPackRush = 'damaged_pack_rush',
  DiyAnotherDogRush = 'diy_another_dog_rush',
  DiyBillingDelay = 'diy_billing_delay',
  DiyDamagedPouchRush = 'diy_damaged_pouch_rush',
  DiyExtraMixRush = 'diy_extra_mix_rush',
  DiyFeedingLessDelay = 'diy_feeding_less_delay',
  DiyFeedingMoreRush = 'diy_feeding_more_rush',
  DiyLostPouchRush = 'diy_lost_pouch_rush',
  DiyMixingDelay = 'diy_mixing_delay',
  DiyMoreTimeDelay = 'diy_more_time_delay',
  DiyNotEnoughMixRush = 'diy_not_enough_mix_rush',
  DiyOtherDelay = 'diy_other_delay',
  DiyOtherRush = 'diy_other_rush',
  DiyTooMuchMixDelay = 'diy_too_much_mix_delay',
  DiyTransitioningDelay = 'diy_transitioning_delay',
  DiyTravelingDelay = 'diy_traveling_delay',
  DiyTravelingRush = 'diy_traveling_rush',
  ExtraPacksRush = 'extra_packs_rush',
  FeedingAnotherRush = 'feeding_another_rush',
  FeedingLessDelay = 'feeding_less_delay',
  FeedingMoreRush = 'feeding_more_rush',
  GracePeriod = 'grace_period',
  InternalDelay = 'internal_delay',
  InternalRush = 'internal_rush',
  LostPackRush = 'lost_pack_rush',
  MixingDelay = 'mixing_delay',
  OtherDelay = 'other_delay',
  OtherRush = 'other_rush',
  RunningLowRush = 'running_low_rush',
  TemporaryPause = 'temporary_pause',
  TooMuchFoodDelay = 'too_much_food_delay',
  TransitioningDelay = 'transitioning_delay',
  TravelingDelay = 'traveling_delay',
  TravelingRush = 'traveling_rush',
  TrialDelay = 'trial_delay',
}

export enum RescheduleNextOrderTypes {
  Delay = 'delay',
  Rush = 'rush',
}

/** The reset token and the user's new password. */
export type ResetPasswordInput = {
  /** The user's new password */
  newPassword: Scalars['String']['input'];
  /** The reset token */
  resetToken: Scalars['String']['input'];
};

/** The type of restriction */
export enum RestrictionType {
  /** System default restriction */
  Default = 'default',
  /** User created restriction */
  User = 'user',
}

export type SetMyAddOnsInput = {
  /** Replaces the add-ons for your subscription */
  addOns: Array<AddOnInput>;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum StockStatus {
  /** Just the right amount of food in stock */
  Ok = 'ok',
  /** Too much food in stock */
  Overage = 'overage',
  /** Too little food in stock */
  Shortage = 'shortage',
  /** Will run out of food within next delivery window */
  Warning = 'warning',
}

export type SubscriptionProductInput = {
  /** SubscriptionProduct id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Product id */
  productId: Scalars['Int']['input'];
  /** Quantity of the product in this subscription */
  quantity: Scalars['Int']['input'];
  /** Variant sku, this should replace Product id (ask PLX team) */
  variantSku?: InputMaybe<Scalars['String']['input']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
}

export enum SubscriptionSuspensionReason {
  /** Cooking home cooked meals is too inconvenient for the customer */
  CookingInconvenient = 'cooking_inconvenient',
  /** The customer had an issue with my box or delivery */
  DeliveryIssues = 'delivery_issues',
  /** The customer's pet is not eating */
  DogNotEating = 'dog_not_eating',
  /** The customer's pet(s) are not eating the home cooked food */
  DogNotEatingHomeCooked = 'dog_not_eating_home_cooked',
  /** The customer doesn't like recurring shipments */
  DontLikeRecurring = 'dont_like_recurring',
  /** Internal/Influencer */
  Internal = 'internal',
  /** The customer needs more time on the trial food */
  NeedMoreTimeOnTrial = 'need_more_time_on_trial',
  /** The customer is no longer using our nutrient mix */
  NutrientMixNoLongerUsed = 'nutrient_mix_no_longer_used',
  /** Other suspension reason */
  Other = 'other',
  /** The food packs are inconvenient or messy */
  PacksInconvenientMessy = 'packs_inconvenient_messy',
  /** The customer's pet passed away */
  PetPassed = 'pet_passed',
  /** The customer's pet(s) require a prescription diet */
  PrescriptionDietRequired = 'prescription_diet_required',
  /** The recipe options are non-sufficient */
  RecipeOptions = 'recipe_options',
  /** The customer's pet experienced stomach or digestive issues */
  StomachDigestiveIssues = 'stomach_digestive_issues',
  /** Switching to a Fresh subscription */
  SwitchingToFresh = 'switching_to_fresh',
  /** A temporary pause with plans to continue at a later date */
  TemporaryPause = 'temporary_pause',
  /** The subscription is too expensive */
  TooExpensive = 'too_expensive',
  /** Customer has too much food */
  TooMuchFood = 'too_much_food',
  /** Customer has too much nutrient mix */
  TooMuchNutrientMix = 'too_much_nutrient_mix',
}

export enum SubscriptionType {
  Diy = 'diy',
  Fresh = 'fresh',
}

export type SubscriptionsArgumentsInput = {
  /** List of statuses of which to determine which subscriptions to retrieve */
  statuses?: InputMaybe<Array<InputMaybe<SubscriptionStatus>>>;
  /** List of types of subscriptions to retrieve */
  types?: InputMaybe<Array<InputMaybe<SubscriptionType>>>;
};

export type SuspendMySubscriptionInput = {
  /** If reason is "other", the custom suspension reason */
  otherReason?: InputMaybe<Scalars['String']['input']>;
  /** Freeform contextual suspension information */
  questionnaire?: InputMaybe<Scalars['JSON']['input']>;
  /** Reason for suspension */
  reasonType: SubscriptionSuspensionReason;
  /** The subscription type to be suspended */
  subscriptionType?: InputMaybe<SubscriptionType>;
};

/** The size of the treat, if this product is a treat */
export enum TreatSize {
  Large = 'large',
  Regular = 'regular',
}

export type TreatsRecommendationInput = {
  productId?: InputMaybe<Scalars['Int']['input']>;
};

export type TrialStartWeeksInput = {
  /** The type of the subscription */
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export type UpdateMeInput = {
  /** The user's billing address */
  billingAddress?: InputMaybe<AddressInput>;
  /** The user's communication preferences */
  communicationPreferences?: InputMaybe<CommunicationPreferencesInput>;
  /** The Users current subscription type during signup */
  currentSignupSubscriptionType?: InputMaybe<SubscriptionType>;
  /** A stringified JSON array of subscription types that the user is eligible to sign up for */
  eligibleSignupSubscriptionTypes?: InputMaybe<Scalars['String']['input']>;
  /** The user's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Set to true if the account is believed to be fraudulent */
  fraud?: InputMaybe<Scalars['Boolean']['input']>;
  /** User confidence in the benefits of fresh food */
  freshFoodConfidence?: InputMaybe<FreshFoodConfidenceEnum>;
  /** The user's full name */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** A flag to determine if this user can be contacted */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** User pet mutation instructions */
  petMutations?: InputMaybe<PetMutationsInput>;
  /** The user's phone */
  phone?: InputMaybe<Scalars['Phone']['input']>;
  /** The user's shipping address */
  shippingAddress?: InputMaybe<AddressInput>;
  /** The user's subscription */
  subscription?: InputMaybe<UpdateMySubscriptionInput>;
};

export type UpdateMyBufferInput = {
  /** Buffer for this subscription (as number of days of food) */
  bufferDays: Scalars['Int']['input'];
};

export type UpdateMyFoodPlanInput = {
  /** Is this plan active when the subscription is active? */
  active: Scalars['Boolean']['input'];
  /** The plan's calories */
  calories: Scalars['Int']['input'];
  /** ID of the plan to update */
  id: Scalars['Int']['input'];
  /** How much of a full pack should the pet be feed each day? */
  portion: Scalars['Float']['input'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float']['input'];
  /** The recipes to update */
  recipes: Array<UpdateMyFoodPlanRecipeInput>;
};

export type UpdateMyFoodPlanRecipeInput = {
  /** ID of the recipe */
  id: Scalars['Int']['input'];
  /** What portion of this plan's food is this recipe? */
  ratio: Scalars['Float']['input'];
};

export type UpdateMySubscriptionInput = {
  /** A list of food plans to be set or update on the subscription */
  foodPlans?: InputMaybe<Array<UpdateMyFoodPlanInput>>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: InputMaybe<Scalars['Int']['input']>;
  /** The date of when the next order will go out */
  nextDate?: InputMaybe<Scalars['Date']['input']>;
  /** A flag that represents if the next order should ignore treats */
  skipTreatsFromNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** New status for the subscription */
  status?: InputMaybe<SubscriptionStatus>;
  /** The products and quantities specified in the subscription */
  subscriptionProducts?: InputMaybe<Array<SubscriptionProductInput>>;
  /** If present, this field will be used to determine which subscription to update */
  type?: InputMaybe<SubscriptionType>;
};

/** The input for the updatePaymentMethod mutation. */
export type UpdatePaymentMethodInput = {
  /** The new Stripe payment method token. */
  stripeToken: Scalars['String']['input'];
  /** The authentication token to authorize the request. */
  token: Scalars['String']['input'];
};

export type UpdatePetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** The ID the pet */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines whether or not we should contact the owner of the pet */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does the pet require a special diet */
  specialDiet?: InputMaybe<Scalars['Boolean']['input']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStockInput = {
  /** Current stock for plans */
  planStocks: Array<UpdateStockPlanInput>;
};

export type UpdateStockPlanInput = {
  /** A pet's plan ID */
  planId: Scalars['Int']['input'];
  /** How much of stock is being used daily */
  portion?: InputMaybe<Scalars['Float']['input']>;
  /** How much total stock the user has at home */
  stock: Scalars['Float']['input'];
};

export enum UserRole {
  Admin = 'admin',
  CrmAdmin = 'crm_admin',
  CrmAgent = 'crm_agent',
  CrmPending = 'crm_pending',
  CrmViewOnly = 'crm_view_only',
  Disabled = 'disabled',
  User = 'user',
}

export enum UserStatus {
  /** Checked out */
  Active = 'active',
  Disabled = 'disabled',
  /** Awaiting checkout */
  Pending = 'pending',
}

export type ValidateShippingAddressInput = {
  /** The first line of the address, commonly used for street number */
  addressLine1: Scalars['String']['input'];
  /** The second line of the address, commonly used for building division */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The 2-letter state abbreviation of the address */
  stateAbbreviation: Scalars['String']['input'];
  /** The 5 or 9 digit zip code of the address */
  zip: Scalars['String']['input'];
};

export type VerifyAddressInput = {
  /** The street address */
  addressLine1: Scalars['String']['input'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The state abbreviation of the address */
  stateAbbreviation: Scalars['String']['input'];
  /** The type of address */
  type: AddressType;
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export enum VerifyAddressInvalidReason {
  /** When address has no shipping rates */
  CannotShipToAddress = 'CANNOT_SHIP_TO_ADDRESS',
  /** When address belongs to state we do not ship to */
  CannotShipToState = 'CANNOT_SHIP_TO_STATE',
  /** When address is already used by another user (looks like fraud) */
  DuplicateAddress = 'DUPLICATE_ADDRESS',
  /** When address does not pass Shippo's USPS address validator */
  ShippoUspsInvalid = 'SHIPPO_USPS_INVALID',
}

export enum ReferralState {
  DefaultDiscount = 'default_discount',
  DiscountAfterLimit = 'discount_after_limit',
  Free = 'free',
  FreeUnlimited = 'free_unlimited',
}
