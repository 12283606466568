export const PASSWORD_MATCH_VALIDATION_MESSAGE =
  'Make sure the two passwords are the same.';

export const PAGE_SUBHEADER = 'Just like your dog, we’ll never forget you.';

export const PAGE_HEADER_CREATE = 'Create password';
export const PAGE_HEADER_CHANGE = 'Change password';

export const PAGE_TITLE_SUCCESS = 'Password has been changed';
export const PAGE_TITLE_DEFAULT = 'Change password';
