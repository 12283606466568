import { useMemo } from 'react';

import { cookie } from '@farmersdog/lead-browser-storage';

import { UseFeatureHook } from '../../types';
import { FeatureName } from '../../utils';

export const DIRECT_TRAFFIC_CODE = 'no_pcode';

export function useGlobalDiscountOverride(useFeature: UseFeatureHook) {
  const referrer = cookie.getReferrerCookie();

  const useFeatureOpts = useMemo(
    () => ({
      attributes: {
        referrerCode: referrer?.referrerCode ?? DIRECT_TRAFFIC_CODE,
        referrerType: referrer?.referrerType ?? '',
      },
    }),
    [referrer?.referrerCode, referrer?.referrerType]
  );

  const globalDiscountOverride = useFeature(
    FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE,
    useFeatureOpts
  );

  const discountPercentage = (globalDiscountOverride?.config
    ?.discountPercentage ?? null) as number | null;

  const treatment = globalDiscountOverride?.treatment;
  const isTreatmentOn = treatment !== 'off' && treatment !== 'control';

  return {
    globalDiscountOverride,
    discountPercentage,
    isTreatmentOn,
  };
}
