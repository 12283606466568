import {
  PATH_CUSTOMER_ACCOUNT,
  PAW_PATH_CANCEL_REASONS,
} from '@farmersdog/constants/paths';
import { Button, Page, Text } from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import { formatDateToMonthAndDay } from '../../utils';
import { formatDateRange } from '../../utils/formatting/formatDateRange';
import { OrderDeliveryWindowFragment } from '../network/preCancelData.cgs.generated-types';
import styles from './PreCancel.module.css';

export interface PreCancelLockedProps {
  deliveryWindow: OrderDeliveryWindowFragment;
  onClose: () => void;
  onExit: () => void;
}

export function PreCancelLocked(props: PreCancelLockedProps) {
  const history = useHistory();

  function handleContinue() {
    history.push(PAW_PATH_CANCEL_REASONS);
  }

  function handleClose() {
    props.onClose();
    history.push(PATH_CUSTOMER_ACCOUNT);
  }

  function handleExit() {
    props.onExit();
    history.push(PATH_CUSTOMER_ACCOUNT);
  }

  let formattedCopy = '';

  if (props.deliveryWindow.estimatedDeliveryDate) {
    formattedCopy = `Your next box is out for delivery and is estimated to arrive on ${formatDateToMonthAndDay(
      props.deliveryWindow.estimatedDeliveryDate
    )}. Do you still want to cancel all shipments after this?`;
  } else {
    formattedCopy = `Your next box has already been billed and will be delivered between ${formatDateRange(
      props.deliveryWindow.earliestDesiredArrivalDate,
      props.deliveryWindow.latestDesiredArrivalDate
    )}. Do you still want to cancel all shipments after this?`;
  }

  return (
    <Page className={styles.page} layout="base" onCloseClick={handleClose}>
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        {formattedCopy}
      </Text>
      <div className={styles.ctas}>
        <Button
          type="button"
          className={styles.button}
          onClick={handleContinue}
        >
          Continue to Cancellation
        </Button>
        <Button type="button" variant="plain-text" onClick={handleExit}>
          <Text variant="heading-16">Never Mind, I’ll Stay</Text>
        </Button>
      </div>
    </Page>
  );
}
