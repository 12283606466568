import { useEffect, useState } from 'react';
import { PATH_SNAP_STICKS_DETAILS } from '@farmersdog/constants/paths';
import { Text } from '@farmersdog/corgi-x';
import { reporter } from '../../../../../errors/services';
import { createSlottableComponent } from '../../../../../slots';
import { useMaybeLaterModal } from '../../../MaybeLaterModal';
import { Nudge } from '../../../Nudge';
import { TreatsDismissibleCtaMaybeLaterModal } from './TreatsDismissibleCtaMaybeLaterModal';
import { useFetchHomepageData } from '../../../../network';
import {
  trackTreatsNudgeMaybeLater,
  trackTreatsNudgeShopNow,
} from './analytics/trackTreatsNudge';
import { dogWithTreatsSideViewSources } from './assets/dogWithTreatsSideViewSources';
import {
  useEligibility,
  useTreatsNudgeStorage,
  useFreeFirstTreatEnabled,
} from './hooks';
import { getNudgeCopy } from './TreatsDismissibleCta.copy';
import { trackTreatsNudgeDisplayed } from '../../../../analytics/trackTreatsNudgeDisplayed';

export type NudgeCopy = {
  body: string;
  headline: string;
  subhead: string;
  ctaText: string;
  title: string;
  dismiss: string;
  alt: string;
};

function TreatsDismissibleCtaComponent() {
  const [petsName, setPetsName] = useState('your dog’s');
  const { isOpen, openModal, closeModal } = useMaybeLaterModal();
  const { isTreatsNudgeDismissed, setTreatsNudgeDismissed } =
    useTreatsNudgeStorage(window.localStorage);
  const isFreeFirstTreatEnabled = useFreeFirstTreatEnabled();

  const { data } = useFetchHomepageData({
    onError: (err: unknown) => {
      reporter.warn('Error retrieving data for the Order Size CTA', {
        sourceError: err,
      });
    },
  });

  useEffect(() => {
    if (!data) return;

    const pets = data?.customer.pets;

    if (pets?.length === 1) {
      setPetsName(`${pets[0].name}’s`);
    }

    trackTreatsNudgeDisplayed();
  }, [data]);

  const copy = getNudgeCopy(isFreeFirstTreatEnabled, petsName);

  const body = (
    <Text variant="article-16" color="white">
      {copy.body}
    </Text>
  );

  const handleMaybeLaterClick = () => {
    if (!isFreeFirstTreatEnabled) {
      openModal();
    }
  };

  return (
    <>
      <TreatsDismissibleCtaMaybeLaterModal
        isOpen={isOpen}
        onClose={closeModal}
      />
      {!isTreatsNudgeDismissed && (
        <Nudge
          onMaybeLaterClick={handleMaybeLaterClick}
          setNudgeDismissed={setTreatsNudgeDismissed}
          imageSources={dogWithTreatsSideViewSources}
          imageAltText={copy.alt}
          headlineText={copy.headline}
          subheadText={copy.subhead}
          body={body}
          ctaText={copy.ctaText}
          ctaLinkDestination={PATH_SNAP_STICKS_DETAILS}
          ctaOnClick={trackTreatsNudgeShopNow}
          componentTitle={copy.title}
          dismissCtaText={copy.dismiss}
          trackDismissCta={trackTreatsNudgeMaybeLater}
        />
      )}
    </>
  );
}

export const TreatsDismissibleCta = createSlottableComponent(
  TreatsDismissibleCtaComponent,
  useEligibility
);
