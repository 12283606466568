interface SessionHasCorrectVersionArgs {
  version: number;
  currentTrackerVersion: number;
}

export function sessionHasCorrectVersion({
  version,
  currentTrackerVersion,
}: SessionHasCorrectVersionArgs): boolean {
  return version === currentTrackerVersion;
}
