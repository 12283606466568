import * as constants from '@farmersdog/corgi/constants';

import { Viewport, Config } from 'src/screen/types';
import ORIENTATIONS from './orientations';
import DEFAULT_VIEWPORTS from './defaultViewports';

/**
 * Definitions for our breakpoints
 */
export const VIEWPORTS: Viewport[] = [
  {
    name: 'xs',
    minWidth: Number(constants.XS.replace('px', '')),
  },
  {
    name: 'sm',
    minWidth: Number(constants.SM.replace('px', '')),
  },
  {
    name: 'md',
    minWidth: Number(constants.MD.replace('px', '')),
  },
  {
    name: 'lg',
    minWidth: Number(constants.LG.replace('px', '')),
  },
  {
    name: 'xl',
    minWidth: Number(constants.XL.replace('px', '')),
  },
];

export const corgiConfig: Config = {
  viewports: VIEWPORTS,
  defaultViewports: DEFAULT_VIEWPORTS,
  orientations: ORIENTATIONS,
};
