import { useId } from 'react';
import { Section, Text, Link } from '@farmersdog/corgi-x';
import { PAW_PATH_ADD_DOG } from '@farmersdog/constants/paths';

import { trackAddDogClicked } from '../../analytics';

import styles from './AddDog.module.css';

export function AddDog() {
  const onAddDogClick = () => {
    trackAddDogClicked();
  };
  const headingId = useId();
  return (
    <div className={styles.container}>
      <Section aria-labelledby={headingId} vSpacing="sm" variant="card">
        <Text as="h2" variant="heading-28" bold color="kale-3">
          Add a New Dog
        </Text>
        <Text variant="article-16" as="p">
          Build a fresh food plan for the freshest member of the family!
        </Text>
        <Link
          aria-label="Add a New Dog"
          to={PAW_PATH_ADD_DOG}
          onClick={onAddDogClick}
        >
          <Text variant="heading-16" bold as="span">
            Add a New Dog
          </Text>
        </Link>
      </Section>
    </div>
  );
}
