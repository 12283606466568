function decimalToFraction(decimal: number) {
  if (decimal <= 0.125) {
    return '0';
  }

  if (decimal > 0.125 && decimal <= 0.285) {
    return '1/4';
  }

  if (decimal > 0.285 && decimal <= 0.415) {
    return '1/3';
  }

  if (decimal > 0.415 && decimal <= 0.585) {
    return '1/2';
  }

  if (decimal > 0.585 && decimal <= 0.705) {
    return '2/3';
  }

  if (decimal > 0.705 && decimal <= 0.875) {
    return '3/4';
  }

  if (decimal > 0.875 && decimal < 1) {
    return '1';
  } else {
    return '0';
  }
}

export function formatDecimalToFraction(
  portion: number | undefined = 0
): string {
  if (portion <= 0) {
    return '0';
  }

  const whole = Math.floor(portion);
  const decimal = portion % 1;
  const fraction = decimalToFraction(decimal);

  if (whole && decimal && fraction !== '0' && fraction !== '1') {
    return `${whole} ${fraction}`;
  }

  if (whole) {
    if (fraction === '1') {
      return (whole + 1).toString();
    } else {
      return whole.toString();
    }
  }

  if (decimal) {
    return fraction;
  }

  return '0';
}
