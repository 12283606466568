import { paths } from '@farmersdog/constants';
import { Button } from '@farmersdog/corgi-x';

import { trackToggleProductLineClick } from '../../../../../analytics';
import { ProductLineType } from '../../../../../graphql/types';
import { getEmailFromCache } from '../../../../../graphql/utils/getEmailFromCache';
import { getLinkToDiySignup } from '../../../../../utils/getLinkToSignupFlow';

import styles from './FreshAndDiyCta.module.css';

import type { RecommendedPlanCtaProps } from './RecommendedPlanCta';

const FreshAndDiyCta = ({
  recommendationDisabled,
  recommendationLoading,
  formSubmitting,
  onClick,
}: RecommendedPlanCtaProps) => {
  const email = getEmailFromCache();
  const handleDiyClick = () => trackToggleProductLineClick(ProductLineType.Diy);

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        type="link"
        variant="secondary"
        onClick={handleDiyClick}
        to={`${getLinkToDiySignup(paths.PATH_SIGNUP_RECIPES, email)}`}
      >
        Switch to DIY
      </Button>
      <Button
        disabled={recommendationDisabled || formSubmitting}
        aria-disabled={formSubmitting}
        loading={recommendationLoading || formSubmitting}
        className={styles.button}
        type="submit"
        onClick={onClick}
      >
        Continue
      </Button>
    </div>
  );
};

export { FreshAndDiyCta };
