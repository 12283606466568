import { Link, Text } from '@farmersdog/corgi-x';
import { useId } from 'react';
import {
  formatDecimalToFraction,
  formatFeedingTipsURL,
  pluralizeString,
} from '../../../utils';
import { trackViewFeedingGuideClicked } from '../../analytics';
import styles from './FeedingGuide.module.css';

interface FeedingGuideProps {
  dailyPacksConsumed: number;
}

export function FeedingGuide({ dailyPacksConsumed }: FeedingGuideProps) {
  const titleId = useId();
  const handleViewClick = () => {
    trackViewFeedingGuideClicked();
  };

  const feedingTipsUrl = formatFeedingTipsURL(dailyPacksConsumed);

  if (!feedingTipsUrl) {
    return null;
  }

  return (
    <section aria-labelledby={titleId} className={styles.feedingGuide}>
      <Text
        as="h4"
        bold
        id={titleId}
        color="kale-3"
        variant="heading-16"
        vSpacing="xxs"
      >
        Feeding Tips
      </Text>
      <Text variant="heading-16" as="p" vSpacing="xxs">
        {`Instructions for feeding ${formatDecimalToFraction(
          dailyPacksConsumed
        )} ${pluralizeString(dailyPacksConsumed, 'pack', 'packs')} per day`}
      </Text>
      <Link
        target="_blank"
        rel="noreferrer"
        to={feedingTipsUrl}
        onClick={handleViewClick}
      >
        <Text variant="heading-16">View</Text>
      </Link>
    </section>
  );
}
