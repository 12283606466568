/**
 * Returns the log level severity for an error (currently used when capturing
 * exceptions in the reporter)
 *
 * @param {Error} error
 * @return {string}
 */
function getDefaultLevel(error) {
  switch (error.name) {
    // Error we want to be notified about
    case 'NetworkError':
      return 'info';
    case 'UserError':
      return 'info';
    case 'ValidationError':
      return 'info';

    // Errors that should never happen
    case 'EvalError':
    case 'RangeError':
    case 'ReferenceError':
    case 'SyntaxError':
    case 'TypeError':
    case 'URIError':
      return 'fatal';

    // Other errors
    case 'RequestError':
    case 'Error':
    case 'ApplicationError':
    default:
      return 'error';
  }
}

export default getDefaultLevel;
