import { local } from 'src/utils/browserStorage';
import { userFeatures, anonymousFeatures } from 'src/abTesting/features';

const allKeys = [
  ...Object.keys(userFeatures),
  ...Object.keys(anonymousFeatures),
];

export interface Result {
  treatment: string;
  config: object;
  locked: boolean;
}

export interface Results {
  [key: string]: Result | undefined;
}

const results: Results = allKeys.reduce((initResults, feature) => {
  let initResult = undefined;

  try {
    // @ts-expect-error TODO: Update to use correct local storage return types
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    initResult = JSON.parse(local.getItem(feature));
  } catch {
    // Do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  initResults[feature] = initResult;
  return initResults;
}, {} as Results);

function lock(feature: string) {
  const result = results[feature];
  if (!result) return;
  result.locked = true;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  local.setItem(feature, JSON.stringify(result));
}

function unlock(feature: string) {
  const result = results[feature];
  if (result) result.locked = false;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  local.removeItem(feature);
}

function set(feature: string, result: Result) {
  const current = results[feature];
  if (current?.locked) return;

  const newResult = { ...result, locked: Boolean(current?.locked) };
  results[feature] = newResult;
}

function get(feature: string): Result {
  // @ts-expect-error TODO: Update to use correct local storage return types
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return JSON.parse(local.getItem(feature)) ?? results[feature];
}

export const splitDebug = { results, set, get, lock, unlock };
