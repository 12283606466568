/**
 * Given an error object, returns a string that can be used as generic friendly
 * title for our users.
 *
 * @param error - The error object.
 * @returns string
 */

export default function getDisplayTitle(error: Error) {
  switch (error.name) {
    case 'RequestError':
      return 'Our server is being a bad boy';
    case 'NetworkError':
      return 'Network error';
    case 'UserError':
      return 'Something did not work';
    case 'ValidationError':
      return 'Data validation failed';
    case 'ApplicationError':
    default:
      return 'Our website is being a bad boy';
  }
}
