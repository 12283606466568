import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Picture from 'src/components/Picture';
import { Logger } from '@farmersdog/logger';

import { MARGIN_LG } from '@farmersdog/corgi/build/constants';

import { track, events } from 'src/analytics';
import { reporter } from 'src/services/reporter';

import getDisplayMessage from './getDisplayMessage';
import { errorDogSources } from './assets';

/**
 * Use `ErrorMessage` to display an error to the user.
 */
export default function ErrorMessage({
  error,
  hasRetryableError = false,
  captureException = true,
  onCaptureException,
  children,
}) {
  useEffect(() => {
    console.error(error);
    track(events.show_error_message, {
      name: error.name,
      message: error.message,
    });
    if (captureException) {
      reporter.error(error, onCaptureException);
    }
  }, [captureException, error, onCaptureException]);

  const displayMessage = children || getDisplayMessage(error);

  const showDog =
    (!children &&
      !error.displayMessage &&
      error.name !== 'ValidationError' &&
      error.name !== 'UserError' &&
      error.name !== 'NetworkError') ||
    hasRetryableError;

  const logger = new Logger('website:frontend:errorBoundary');

  const checkoutSuccessErrorMessage =
    "TypeError: undefined is not an object (evaluating 'e.filter')";

  const logTitle = 'checkout success error - ';

  if (error.message.includes(checkoutSuccessErrorMessage)) {
    logger.error(logTitle, {
      ...error,
    });
    console.error(logTitle, error.message);
  }

  return (
    <div>
      {showDog && (
        <div style={{ textAlign: 'center', marginTop: MARGIN_LG }}>
          <Picture
            sources={errorDogSources}
            alt="Bad Boy"
            style={{ maxWidth: 200 }}
          />
        </div>
      )}
      {displayMessage}
    </div>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.instanceOf(Error),
  /**
   * Captures the exception in the reporter. Default is `true`. Set it to `false` if
   * you really know we do not need to be notified about the error.
   *
   * TODO: remove this prop (and that from `ErrorModal`). Find another solution.
   * - For some reason this feature ended to be a prop of `ErrorMessage`, but it
   *   has nothing to do with error message itself.
   */
  captureException: PropTypes.bool,
  hasRetryableError: PropTypes.bool,
  /**
   * Called when exception has been captured. The event handler receive's the
   * reporter's event id as argument
   */
  onCaptureException: PropTypes.func,
  children: PropTypes.element,
};
