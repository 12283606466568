import { Divider as CorgiDivider, PageWidth } from '@farmersdog/corgi-x';
import styles from './Divider.module.scss';

function Divider() {
  return (
    <div className={styles.container}>
      <PageWidth>
        <CorgiDivider
          style="dotted"
          color="chickpea-2"
          orientation="horizontal"
          margin={0}
        />
      </PageWidth>
    </div>
  );
}

export default Divider;
