/* CH#49502: Used by CVR */
import PropTypes from 'prop-types';
import Flex from 'src/components/_depreciated/Flex';

import { CARROT_2 } from '@farmersdog/corgi/constants';

import './UnderlineDecorator.scss';

/**
 * Decorate the wrapped text rendering an orange line at its bottom.
 * The line width can be adjusted using the `style` prop.
 *
 */
function UnderlineDecorator({ children, center = false, style }) {
  return (
    <Flex
      direction="column"
      alignItems={center ? 'center' : 'flex-start'}
      className="UnderlineDecorator"
    >
      {children}
      <div
        style={{
          width: 121,
          height: 3,
          marginTop: 8,
          backgroundColor: CARROT_2,
          ...style,
        }}
      />
    </Flex>
  );
}

UnderlineDecorator.propTypes = {
  style: PropTypes.object,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};

export default UnderlineDecorator;
