import { Link, Text } from '@farmersdog/corgi-x';
import {
  formatDecimalToFraction,
  formatFeedingTipsURL,
  pluralizeString,
} from '../../../utils';
import { trackViewFeedingTipsClicked } from '../../analytics/trackViewFeedingTipsClicked';
import {
  PacksAndPortionsFeedingGuideFragment,
  PacksAndPortionsFoodPlanFragment,
} from '../../network/fragments/';
import { floor } from '../../utils/numbers';
import styles from './Content.module.css';

export interface PacksAndPortionContentProps {
  petName: string;
  feedingGuide: PacksAndPortionsFeedingGuideFragment | null | undefined;
  foodPlan: PacksAndPortionsFoodPlanFragment | undefined;
}

export function Content({
  petName,
  feedingGuide,
  foodPlan,
}: PacksAndPortionContentProps) {
  const portion = feedingGuide?.portion ?? 0;
  const portionPacksString = pluralizeString(portion, 'pack', 'packs');

  const stockAsPacks = floor(foodPlan?.stockAsPacks ?? 0, { fractional: true });
  const stockAsPacksString = pluralizeString(stockAsPacks, 'pack', 'packs');

  const stockAsDays = floor(foodPlan?.stockAsDays ?? 0);
  const stockAsDaysString = pluralizeString(stockAsDays, 'day', 'days');
  const feedingTipsUrl = formatFeedingTipsURL(portion);

  const handleViewClick = () => {
    trackViewFeedingTipsClicked();
  };

  return (
    <div className={styles.container}>
      <div>
        <Text variant="heading-16" bold as="div" color="kale-3">
          Feeding per Day:
        </Text>
        <Text variant="heading-16" className={styles.textContent} as="div">
          {`${formatDecimalToFraction(portion)} ${portionPacksString} (${
            feedingGuide?.dailyCalories ?? 0
          } calories) a day`}
        </Text>
      </div>
      <div>
        <Text variant="heading-16" bold as="div" color="kale-3">
          Remaining Packs at Home:
        </Text>
        <Text variant="heading-16" className={styles.textContent} as="div">
          {`${formatDecimalToFraction(
            stockAsPacks
          )} ${stockAsPacksString} (${stockAsDays} ${stockAsDaysString} of food)`}
        </Text>
      </div>
      {feedingTipsUrl && (
        <Link
          target="_blank"
          rel="noreferrer"
          to={feedingTipsUrl}
          onClick={handleViewClick}
        >
          <Text bold variant="heading-16">
            View {petName}&apos;s Feeding Tips
          </Text>
        </Link>
      )}
    </div>
  );
}
