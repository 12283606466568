import * as Apollo from '@apollo/client';

export interface RequestContext {
  headers?: Record<string, string>;
}

export interface SetOperationHeadArg {
  operation: Apollo.Operation;
  headers: Record<string, string | number>;
}

export function setOperationHeaders({
  operation,
  headers,
}: SetOperationHeadArg) {
  operation.setContext(
    ({ headers: existingHeaders, ...context }: RequestContext) => ({
      ...context,
      headers: {
        ...existingHeaders,
        ...headers,
      },
    })
  );
}
