import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../blueprint/utils';
import { FormFieldsType } from '../types';
import { MAX_NUMBER_OF_PETS } from '../validation/constants';

interface GetPetPositionByPetNameArgs {
  formValues: FormFieldsType;
  petName: string;
}
export function getPetPositionByPetName({
  formValues,
  petName,
}: GetPetPositionByPetNameArgs) {
  const petNameRegExp = new RegExp(`${NodeNames.Name}-[0-9]*`);

  for (const [key, value] of Object.entries(formValues)) {
    if (petNameRegExp.test(key)) {
      if (String(value) === petName) {
        const { position } = getNodeNameAndPosition(key);
        return parseInt(position) <= MAX_NUMBER_OF_PETS ? position : undefined;
      }
    }
  }
  return;
}
