import { ReactNode } from 'react';
import classNames from 'classnames';
import { Text } from '@farmersdog/corgi-x';

import styles from './Content.module.scss';

export interface ContentProps {
  children: ReactNode;
  /** Optional class name */
  className?: string;
}

export function Content({ children, className }: ContentProps) {
  return (
    <Text
      as="div"
      variant="article-20"
      color="charcoal-3"
      className={classNames(styles.content, className)}
    >
      {children}
    </Text>
  );
}
