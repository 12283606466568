import { filter } from '@prismicio/client';
import { usePrismicContext } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { reporter } from '../errors/services';
import { JourneyStatus } from '../graphql/types.cgs';
import { ContentDocumentType } from './types';

export interface UseContentDocumentByJourneyStatusArgs {
  customTypeUid: ContentDocumentType;
  journeyStatus?: JourneyStatus;
}

export interface UseContentDocumentByJourneyStatus<TDocument> {
  content?: TDocument;
  loading: boolean;
  error?: Error;
}

/**
 * Fetches a document from Prismic by Custom Type and Journey Status.
 * The type of `content` will match the generic passed in as `TDocument`.
 */
export function useContentDocumentByJourneyStatus<TDocument>({
  customTypeUid,
  journeyStatus,
}: UseContentDocumentByJourneyStatusArgs): UseContentDocumentByJourneyStatus<TDocument> {
  const prismicContext = usePrismicContext();
  const [content, setContent] = useState<TDocument>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!journeyStatus) {
      return;
    }

    const fetchDocument = async () => {
      const filters = [
        filter.at(
          `my.${customTypeUid}.journey_status`,
          journeyStatus as string
        ),
      ];

      try {
        const document = await prismicContext.client?.getFirst({
          filters,
        });
        setContent(document?.data as TDocument);
      } catch (e) {
        reporter.warn('Error loading prismic content', {
          customTypeUid,
          filters,
          sourceError: e,
        });
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    };

    void fetchDocument();
  }, [customTypeUid, journeyStatus, prismicContext.client, setContent]);

  return {
    content,
    loading,
    error,
  };
}
