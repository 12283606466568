import { ActivityIndicator, Page } from '@farmersdog/corgi-x';

import styles from './Loading.module.css';

export function Loading() {
  return (
    <Page layout="base" className={styles.container}>
      <div role="status" aria-live="polite" aria-label="loading" />
      <ActivityIndicator mode="dark" />
    </Page>
  );
}
