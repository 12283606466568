export const YES = {
  label: 'Yes',
  value: 'yes',
};

export const NO = {
  label: 'No',
  value: 'no',
};

export const NO_PREFERENCE = {
  label: 'No preference',
  value: 'noPreference',
};

export const preferenceOptions = [YES, NO, NO_PREFERENCE];
