import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, Mask } from '@farmersdog/corgi-x';
import Picture from 'src/components/Picture';

import styles from './LabeledPicture.module.scss';

function LabeledPicture({ sources, label, alt, className }) {
  return (
    <div className={classNames(styles.container, className)}>
      <Mask shape="circle" size="xl">
        <Picture sources={sources} alt={alt} />
      </Mask>
      <Text className={styles.label} variant="heading-22" color="white" bold>
        {label}
      </Text>
    </div>
  );
}

LabeledPicture.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LabeledPicture;
