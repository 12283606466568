// TODO - to be imported from tosa-npm
export const PET_NAME_PLACEHOLDER = '%petName%';

interface HandlePetNamePlaceholders {
  input: string;
  petName: string;
}

export function handlePetNamePlaceholders({
  input,
  petName,
}: HandlePetNamePlaceholders) {
  return input.includes(PET_NAME_PLACEHOLDER)
    ? input.split(PET_NAME_PLACEHOLDER).join(petName)
    : input;
}
