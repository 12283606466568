import { reporter } from 'src/services/reporter';
import { getFacebookPixelAttributes } from 'src/vendors/facebook';
import { trackTikTokEvent, TIKTOK_ADDTOCART_EVENT } from 'src/vendors/tiktok';

import events from '../eventNames';
import track from '../track';
import getPreTaxTotal from '../getPreTaxTotal';
import { trackISpotRecipesConversion } from 'src/vendors/iSpot';
import { trackAmazonTagAddToShoppingCart } from 'src/vendors/amazon';
import {
  trackGa4RecipesSuccess,
  getGa4ItemsFromRecipes,
  getRecipeNamesFromPlans,
} from 'src/vendors/ga4';
import { SubscriptionType } from 'src/graphql/types';
import { cookie } from '@farmersdog/lead-browser-storage';

interface AddressData {
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

interface TrackRecipesSuccessArgs {
  isLastPetSubmit?: boolean;
  user?: Reducer.User;
  subtotalAmount?: number;
  configuration?: Record<string, unknown>;
  requestAttributes?: Record<string, unknown>;
  anonymousId?: string;
  addressData?: AddressData;
}

/**
 * A tracking event that is used to mark a customer successfully
 * clicking the continue button on the recipes screen.
 *
 * @param isLastPetSubmit - some of these events should only fire when clicking submit on the final pet, rather than navigating between multi-pets
 * @param user - A TFD user object
 * @param subtotalAmount - The subtotal amount of the Fresh quote.
 * @param configuration - The configuration object stored in redux.
 * @param requestAttributes - Freeform attributes to submit to segment.
 */
export function trackRecipesSuccess({
  isLastPetSubmit,
  user,
  subtotalAmount,
  configuration,
  requestAttributes,
  addressData,
}: TrackRecipesSuccessArgs) {
  if (!user) {
    reporter.warn(
      `Segment event "${events.recipes_success}" has missing parameter "user"`
    );
  }

  const totalAmount =
    typeof subtotalAmount === 'number'
      ? getPreTaxTotal(subtotalAmount)
      : undefined;

  const ga4Items = getGa4ItemsFromRecipes(getRecipeNamesFromPlans(user));

  track(events.recipes_success, {
    totalAmount,
    // @ts-expect-error TODO: Update this consumer to use typescript
    ...getFacebookPixelAttributes(user, configuration),
    ...requestAttributes,
    firstName: user?.firstName,
    email: user?.email,
    phone: user?.phone,
    fbp: cookie.getFacebookPixelCookie(),
    fcp: cookie.getFacebookClickIdCookie(),
    product_line: SubscriptionType.Fresh,
    label: SubscriptionType.Fresh,
    city: addressData?.city,
    state: addressData?.state,
    zip: addressData?.zip,
  });

  trackGa4RecipesSuccess({
    data: {
      items: ga4Items,
    },
    isLastPet: isLastPetSubmit ?? false,
    productLine: SubscriptionType.Fresh,
  });

  if (isLastPetSubmit) {
    void trackTikTokEvent(TIKTOK_ADDTOCART_EVENT);
    trackISpotRecipesConversion({
      utmSource: requestAttributes?.utm_source,
    });
    trackAmazonTagAddToShoppingCart();
  }
}
