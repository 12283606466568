import {
  PreloadLink,
  PreloadLinkAs,
} from '@farmersdog/tosa/src/components/shared/PreloadLink';

import { useHomepageHeroExperiment } from '../../../../hooks/useHomepageHeroExperiment';

import { generateSourcesForPreloader } from './assets/shared';

export const AssetPreloader = () => {
  const { mobileSources, desktopSources } = useHomepageHeroExperiment();
  const assetsForPreloader = generateSourcesForPreloader({
    mobileSources,
    desktopSources,
  });

  return (
    <>
      {assetsForPreloader.map(({ imageSrcSet, media }) => (
        <PreloadLink
          key={imageSrcSet}
          rel="preload"
          as={PreloadLinkAs.Image}
          imageSrcSet={imageSrcSet}
          media={media}
        />
      ))}
    </>
  );
};
