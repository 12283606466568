import { iSpot, ISpotEventName } from './iSpot';
import { getSharedISpotParams } from './getSharedISpotParams';

/**
 * Track the iSpot recipes conversion event
 */

export function trackISpotRecipesConversion({
  utmSource,
}: {
  utmSource: unknown;
}) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { customData, channel, app } = getSharedISpotParams({
    utmSource,
  });

  iSpot.track({
    app,
    type: ISpotEventName.ADD_TO_CART,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
