import { useState } from 'react';

import { Logger } from '@farmersdog/logger';

import { useTosaUserFeature } from '../../../hooks';
import {
  CvrSurpriseHigherDiscountTreatments,
  FeatureName,
} from '../../../utils';

import type { UseFeatureHook } from '../../../types';

const logger = new Logger('useSurpriseHigherDiscountExperiment:tosa');
const NON_ACTIVE_TREATMENTS = [
  CvrSurpriseHigherDiscountTreatments.off,
  'control',
];

interface UseSurpriseHigherDiscountExperimentArgs {
  useFeature: UseFeatureHook;
}

type SurpriseHigherDiscountConfig = { code: string };

type UseSurpriseHigherDiscountExperimentReturn =
  | {
      isSurpriseHigherDiscountEnabled: false;
      isRecipesPageEnabled: false;
      isCheckoutPageEnabled: false;
      code: SurpriseHigherDiscountConfig['code'];
    }
  | {
      isSurpriseHigherDiscountEnabled: true;
      isRecipesPageEnabled: true;
      isCheckoutPageEnabled: false;
      code: SurpriseHigherDiscountConfig['code'];
    }
  | {
      isSurpriseHigherDiscountEnabled: true;
      isRecipesPageEnabled: false;
      isCheckoutPageEnabled: true;
      code: SurpriseHigherDiscountConfig['code'];
    };

export function useSurpriseHigherDiscountExperiment({
  useFeature,
}: UseSurpriseHigherDiscountExperimentArgs): UseSurpriseHigherDiscountExperimentReturn {
  const [didLog, setDidLog] = useState(false);

  const { treatment, config } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_SURPRISE_HIGHER_DISCOUNT,
  });

  const doesConfigHaveCode = isValidConfig(config);
  const code = doesConfigHaveCode ? config.code : '';
  const shouldLogError =
    !doesConfigHaveCode && !NON_ACTIVE_TREATMENTS.includes(treatment);

  if (shouldLogError && !didLog) {
    logger.error('useSurpriseHigherDiscountExperiment: config missing code', {
      config,
      treatment,
    });

    setDidLog(true);
  }

  switch (treatment) {
    case CvrSurpriseHigherDiscountTreatments.recipes:
      return {
        isSurpriseHigherDiscountEnabled: true,
        isRecipesPageEnabled: true,
        isCheckoutPageEnabled: false,
        code,
      };
    case CvrSurpriseHigherDiscountTreatments.checkout:
      return {
        isSurpriseHigherDiscountEnabled: true,
        isRecipesPageEnabled: false,
        isCheckoutPageEnabled: true,
        code,
      };
    default:
      return {
        isSurpriseHigherDiscountEnabled: false,
        isRecipesPageEnabled: false,
        isCheckoutPageEnabled: false,
        code,
      };
  }
}

function isValidConfig(val: unknown): val is SurpriseHigherDiscountConfig {
  return Object.prototype.hasOwnProperty.call(val, 'code');
}
