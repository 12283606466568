import { Button, Text } from '@farmersdog/corgi-x';
import { FetchRecipeDetailsQuery } from '../../network';
import { AnalysisRow, AnalysisTable } from '../AnalysisTable';
import { RecipeDiagram } from '../RecipeDiagram';
import { DetailsContainer } from './DetailsContainer';
import { DetailsSection } from './DetailsSection';
import styles from './RecipeDetails.module.css';

export interface RecipeDetailsProps {
  recipe: FetchRecipeDetailsQuery['recipeDetails'];
  petName?: string;
  onClose: () => void;
}

export function RecipeDetails({
  recipe,
  petName,
  onClose,
}: RecipeDetailsProps) {
  const asFedAnalysisParts = recipe.guaranteedAnalysis
    ?.split('\n')
    .map(part => part.trim().split(': ')) ?? [[]];

  return (
    <>
      <section className={styles.diagramSection} aria-label="Recipe Diagram">
        <div className={styles.diagramContainer}>
          <RecipeDiagram
            recipeName={recipe.name}
            ingredients={recipe.mainIngredients.split(', ')}
          />
        </div>
      </section>

      <div className={styles.ingredientSection}>
        <DetailsContainer>
          <Text
            variant={'heading-28'}
            color={'kale-3'}
            as={'h1'}
            bold
            id={recipe.displayName}
          >
            {petName
              ? `${petName}'s ${recipe.displayName}`
              : recipe.displayName}
          </Text>

          <DetailsSection heading="Ingredients">
            <Text variant={'article-16'} color={'charcoal-3'} bold>
              {recipe.mainIngredients}
            </Text>
            <Text variant={'article-16'} color={'charcoal-3'}>
              {` [${recipe.supplements}]`}
            </Text>
          </DetailsSection>

          <DetailsSection heading="Nutritional Info">
            <AnalysisTable heading="As-Fed Guaranteed Analysis">
              {asFedAnalysisParts.map(([name, percentage]) => (
                <AnalysisRow key={name} name={name} percentage={percentage} />
              ))}
            </AnalysisTable>
          </DetailsSection>

          <DetailsSection heading="AAFCO statement">
            <Text variant="article-16">
              {`The Farmer’s Dog ${recipe.displayName} Recipe for Dogs is formulated to meet the nutritional levels
          established by the AAFCO Dog Food Nutrient Profiles for all life
          stages, including growth of large sized dogs (70 lbs. or more as an
          adult).`}
            </Text>
          </DetailsSection>

          <DetailsSection heading="Calorie Content">
            <Text variant="article-16">{recipe.calorieContent}</Text>
          </DetailsSection>
        </DetailsContainer>
        <Button
          type="button"
          variant="plain-text"
          onClick={onClose}
          className={styles.close}
        >
          Close
        </Button>
      </div>
    </>
  );
}
