import { useToastDialog } from '@farmersdog/corgi';
import { BeforeAfter, Button, Page, Section, Text } from '@farmersdog/corgi-x';
import noop from 'lodash/noop';
import { useId, useState } from 'react';
import { Loading } from '../../../../../../../components';
import { useUpdateFoodPlansRecipe } from '../../../../../../../edit-fresh-recipes/network';
import { reporter } from '../../../../../../../errors/services';
import { UpdateFoodPlansRecipesInput } from '../../../../../../../graphql/types.cgs';
import {
  RecipeDetailsModal,
  RecipeDetailsModalProps,
} from '../../../../../../../recipe-details-modal';
import { formatCurrency } from '../../../../../../../utils';
import {
  trackCancelSubscriptionClickContinueCanceling,
  trackClickAcceptLpfDownsell,
} from '../../../../../../analytics';
import { PetFieldsFragment } from '../../../../../../network';
import { useFetchLpfEducationData } from '../../../../../../network/fetchLpfEducationData/useFetchLpfEducationData';
import { LpfRecipe } from '../../LpfDownsell';
import styles from './LpfEducation.module.css';
import { RecipeCard } from './components';

interface LpfEducationProps {
  onPrevious: () => void;
  onClose: () => void;
  onAccept: () => void;
  onReject: () => void;
  pet: PetFieldsFragment;
  recipe: LpfRecipe;
}
interface ValidRecipe {
  id: number;
  displayName: string;
  mainIngredients: string;
}
function isValidRecipe(recipe: unknown): recipe is ValidRecipe {
  return (
    typeof recipe === 'object' &&
    recipe !== null &&
    typeof (recipe as ValidRecipe).id === 'number' &&
    typeof (recipe as ValidRecipe).displayName === 'string' &&
    typeof (recipe as ValidRecipe).mainIngredients === 'string'
  );
}
/**
 * LPF Education page informing a customer of the cost savings of switching to the new recipe
 */
export function LpfEducation(props: LpfEducationProps) {
  const dispatchToast = useToastDialog();
  const headingId = useId();

  const request = useFetchLpfEducationData({
    recipeId: props.recipe.id,
    recipeName: props.recipe.name,
    recipeDisplayName: props.recipe.displayName,
    pet: props.pet,
  });
  const petPlanId: number = request.data?.customer.pets[0].plan.id ?? -1;
  const updateRecipeInput: UpdateFoodPlansRecipesInput = {
    foodPlans: [
      {
        id: petPlanId,
        selectedRecipes: [{ id: props.recipe.id, name: props.recipe.name }],
      },
    ],
    freeFormFeedback: undefined,
  };
  const { onSubmit, isLoading } = useUpdateFoodPlansRecipe({
    onCompleted: () => {
      props.onAccept();
    },
    onError: (error: Error) => {
      reporter.error(new Error('Error executing useUpdateFoodPlansRecipe'), {
        sourceError: error,
      });
      dispatchToast({
        variant: 'negative',
        message: 'There was an error submitting your request.',
        buttonLabel: 'Close',
        close: noop,
      });
    },
  });

  const handleAcceptClick = () => {
    if (petPlanId === -1) {
      reporter.error(new Error('Error loading LpfEducation'), {
        sourceError: new Error('Error retrieving pet plan id'),
      });
      throw new Error('Error retrieving pet plan id');
    }
    trackClickAcceptLpfDownsell();
    onSubmit(updateRecipeInput);
  };

  const handleCloseRecipeDetailsModal = () => {
    setModalState({ ...modalState, isOpen: false, productName: '' });
  };

  const [modalState, setModalState] = useState<RecipeDetailsModalProps>({
    isOpen: false,
    productName: '',
    onClose: handleCloseRecipeDetailsModal,
  });
  if (request.loading || isLoading) {
    return <Loading />;
  }
  if (request.error) {
    reporter.error(new Error('Error loading LpfEducation'), {
      sourceError: request.error,
    });
    return null;
  }
  if (!request.data || !request.data.customer) {
    reporter.error(new Error('Error retrieving LpfEducation data'));
    return null;
  }
  const downsellRecipe =
    request.data.customer.pets[0].availableFreshFoodProducts.find(
      recipe => recipe.name === props.recipe.name
    );
  const { dailyConsumptionPrice, regularOrderTotalConsumptionPrice } =
    request.data.customer.changeFreshRecipesPlanQuote;
  const pricesBefore = `${formatCurrency(
    regularOrderTotalConsumptionPrice.original
  )} (${formatCurrency(dailyConsumptionPrice.original)}/day)*`;
  const pricesAfter = `${formatCurrency(
    regularOrderTotalConsumptionPrice.updated
  )} (${formatCurrency(dailyConsumptionPrice.updated)}/day)*`;
  const orderPriceDifferenceFormatted = formatCurrency(
    Math.abs(regularOrderTotalConsumptionPrice.difference),
    {
      round: true,
    }
  );
  if (!isValidRecipe(downsellRecipe)) {
    throw new Error('Invalid recipe data');
  }
  const handleSeeDetailsClick = () => {
    setModalState({
      ...modalState,
      isOpen: true,
      productName: downsellRecipe.name,
    });
  };
  const handleRejectClick = () => {
    trackCancelSubscriptionClickContinueCanceling({
      savePathway: 'lpf',
    });
    props.onReject();
  };
  return (
    <Page onBackClick={props.onPrevious} onCloseClick={props.onClose}>
      <Section
        aria-labelledby={headingId}
        variant="wave"
        widthConstraint="md"
        hSpacing="md"
        className={styles.content}
      >
        <Text as="h2" variant="heading-40" bold color="kale-3" id={headingId}>
          We Can Help With Cost
        </Text>
        <Section as="div" topSpacing="sm">
          <Text className={styles.introText} as="span" variant="article-20">
            Switch to our {downsellRecipe.displayName} recipe and{' '}
          </Text>
          <Text as="span" variant="article-20" bold aria-label="Savings">
            save {orderPriceDifferenceFormatted} per order!
          </Text>
        </Section>
        <RecipeCard
          title={downsellRecipe.displayName}
          description={downsellRecipe.mainIngredients}
          callToAction={handleSeeDetailsClick}
        />
        <Section as="div">
          <Text
            as="h4"
            variant="heading-22"
            bold
            color="kale-3"
            topSpacing="none"
            bottomSpacing="xs"
          >
            Order Price
          </Text>
          <div className={styles.beforeAfterContainer}>
            <BeforeAfter
              before={pricesBefore}
              after={pricesAfter}
              accessibleText={`New box price of ${pricesAfter}"`}
            />
          </div>

          <Text
            as="p"
            topSpacing="lg"
            bottomSpacing="none"
            variant="heading-12"
          >
            *Prices do not include treats or tax (if applicable)
          </Text>
        </Section>
      </Section>
      <Section
        as="div"
        vSpacing="md"
        widthConstraint="md"
        hSpacing="md"
        className={styles.cta}
      >
        <Button variant="primary" type="button" onClick={handleAcceptClick}>
          Switch to this Recipe
        </Button>

        <Button variant="plain-text" type="button" onClick={handleRejectClick}>
          <Text variant="heading-16">No Thanks, Continue Canceling</Text>
        </Button>
        {/* We want the modal to gracefully close but not submit a false recipe id request on initial render */}
        {modalState.productName !== '' && (
          <RecipeDetailsModal
            isOpen={modalState.isOpen}
            productName={modalState.productName}
            onClose={handleCloseRecipeDetailsModal}
            petName={props.pet.name}
          />
        )}
      </Section>
    </Page>
  );
}
