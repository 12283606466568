import { ContentReveal } from '@farmersdog/corgi-x';

import { usePriorFoodTypeProgression } from '../../../hooks';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { BasicInput } from '../base';

export function FoodBrandInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, useFeature, progress } = props;

  const { showSingleQuestionProgression, isReady } =
    usePriorFoodTypeProgression({
      useFeature,
    });

  const shouldRevealInput = progress.getPreviousLeafComplete(node.__self__);

  if (!isReady) return null;

  return showSingleQuestionProgression ? (
    <ContentReveal in={shouldRevealInput} inAnimation="fade">
      <div>
        <BasicInput {...props} />
      </div>
    </ContentReveal>
  ) : (
    <BasicInput {...props} />
  );
}
