import { useMemo, useState } from 'react';
import {
  ScoredCandidate,
  CandidateMap,
  SlottableComponent,
  CandidateEntry,
  SlotIdentifier,
} from '../types';
import isEqual from 'lodash/isEqual';
import { reporter } from '../../errors/services';

export function useScoredCandidates<P extends NonNullable<unknown>>(
  slotId: SlotIdentifier,
  candidates: ScoredCandidate<P>[]
): SlottableComponent<P> | undefined {
  /**
   * Sort to both optimize and preserve hook order
   */
  const sortedCandidates = useMemo(
    () =>
      candidates
        .filter(scoredCandidate => {
          const [component] = scoredCandidate;
          const hasEligibilityHook = !!component?.useEligibility;
          if (!component) {
            reporter.error('Scored candidate found without component', {
              slotId,
              candidates,
            });
          }
          if (!hasEligibilityHook) {
            reporter.error('Scored candidate found without eligibility hook', {
              slotId,
              candidates,
            });
          }
          return !!component && hasEligibilityHook;
        })
        .sort(([, scoreA], [, scoreB]) => scoreB - scoreA),
    [candidates, slotId]
  );

  /**
   * Map to a named structure for clarity
   */
  const candidateMap: CandidateMap<P> = useMemo<CandidateMap<P>>(
    () =>
      sortedCandidates.reduce(
        (map, [component, score], id) => ({
          ...map,
          [id]: {
            component,
            score,
            hook: component.useEligibility,
            done: false,
          },
        }),
        {}
      ),
    [sortedCandidates]
  );

  /**
   * Create initial ready state (all false)
   */
  const initialReadyState = useMemo<{ [id: string]: boolean }>(
    () =>
      sortedCandidates.reduce(
        (map, _, id) => ({
          ...map,
          [id]: false,
        }),
        {}
      ),
    [sortedCandidates]
  );

  /**
   * Use a hook for the setter to ensure a re-render
   * when ready state changes
   */
  const [readyState, setReadyState] = useState(initialReadyState);

  /** Flattened flag for easier checks */
  const allReady = Object.values(readyState).every(Boolean);

  /**
   * Clone the ready state to remove re-renders until
   * all components have been checked or re-checked
   */
  const batchedReadyUpdate = { ...readyState };

  const winningCandidate = Object.entries(candidateMap).reduce(
    (currentWinner, [id, candidate]) => {
      if (candidate.score < 0) {
        throw new Error('Scored candidates must have a positive score');
      }

      const currentWinningScore = currentWinner?.score ?? -1;
      const higherScoring = candidate.score > currentWinningScore;

      const { isReady, isCandidate } = candidate.hook({
        constraints: {
          blockSplitEligibility: !allReady || !higherScoring,
        },
        slotId,
      });

      batchedReadyUpdate[id] = isReady;

      if (isCandidate && higherScoring) {
        return candidate;
      }

      return currentWinner;
    },
    {} as CandidateEntry<P>
  );

  if (!isEqual(readyState, batchedReadyUpdate)) {
    setReadyState(batchedReadyUpdate);
  }

  const Winner = useMemo(
    () => winningCandidate?.component,
    [winningCandidate?.component]
  );

  return allReady ? Winner : undefined;
}
