import { SIGNUP_FETCH_PLANS } from 'src/constants/actionTypes';

const initialState = null;

export default function signupPlansReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FETCH_PLANS:
      return action.payload.data;
    default:
      return state;
  }
}

export function selectPlans(state) {
  return state.signup.plans;
}
