import { Nowrap } from '@farmersdog/corgi-x';

import leo from './assets/leo';
import myah from './assets/myah';
import ravi from './assets/ravi';

export default [
  {
    name: 'Leo’s mom',
    quote: (
      <span>
        With TFD, my dog’s fur is shinier, he has more energy, he licks the bowl
        clean, his digestion has greatly improved, and no{' '}
        <Nowrap>more farts!</Nowrap>
      </span>
    ),
    sources: leo,
  },
  {
    name: 'Myah’s dad',
    quote: (
      <span>
        Our 14 y.o. is now running again after eating TFD! I wasn’t expecting
        such a big improvement. You truly are what <Nowrap>you eat.</Nowrap>
      </span>
    ),
    sources: myah,
  },
  {
    name: 'Ravi’s mom',
    quote: (
      <span>
        I love this company. They truly care about their customers and have a
        great product. My dog has lost weight and is{' '}
        <Nowrap>more energetic.</Nowrap>
      </span>
    ),
    sources: ravi,
  },
];
