import { couponCookie, ReferrerType } from '@farmersdog/coupons';
import { COUPON_COOKIE_VERSION } from '@farmersdog/coupons/src/constants';
import { Logger } from '@farmersdog/logger';

import { isSplitDiscountConfigValid } from './isSplitDiscountConfigValid';

interface SetCouponCookieWithDefaultExperimentCodeArgs {
  splitConfig: Record<string, unknown> | undefined;
}

export function setCouponCookieWithDefaultExperimentCode({
  splitConfig,
}: SetCouponCookieWithDefaultExperimentCodeArgs) {
  const logger = new Logger('website:frontend:defaultDiscountExperiment');

  if (isSplitDiscountConfigValid(splitConfig)) {
    couponCookie.set({
      version: COUPON_COOKIE_VERSION,
      referrerCode: splitConfig?.code as string,
      referrerType: ReferrerType.Partner,
      referrerAssociatedAt: new Date(),
    });
  } else {
    // TODO - add additional logging
    logger.error(
      'cvr_default_discount - could not set default discount cookie',
      { splitConfig }
    );
  }
}
