/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { session } from 'src/utils/browserStorage';
import { useLocation } from 'react-router';
import parseQueryString from 'src/utils/parseQueryString';
import {
  UTM_CAMPAIGN,
  UTM_CONTENT,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_TERM,
} from 'src/constants/sessionStorage';
import { useEffect } from 'react';

export function useSessionSetUtmTags(): void {
  const location = useLocation();
  const search = location.search;

  useEffect(() => {
    const locationUtmCampaign = parseQueryString(search, 'utm_campaign');
    const locationUtmMedium = parseQueryString(search, 'utm_medium');
    const locationUtmSource = parseQueryString(search, 'utm_source');
    const locationUtmTerm = parseQueryString(search, 'utm_term');
    const locationUtmContent = parseQueryString(search, 'utm_content');

    if (locationUtmCampaign) {
      session.setItem(UTM_CAMPAIGN, locationUtmCampaign);
    }

    if (locationUtmMedium) {
      session.setItem(UTM_MEDIUM, locationUtmMedium);
    }

    if (locationUtmSource) {
      session.setItem(UTM_SOURCE, locationUtmSource);
    }

    if (locationUtmTerm) {
      session.setItem(UTM_TERM, locationUtmTerm);
    }

    if (locationUtmContent) {
      session.setItem(UTM_CONTENT, locationUtmContent);
    }
  }, [search]);
}
