import HealthierPoopMobile from './healthier-poop-mobile.gif';
import HealthierPoopDesktop from './healthier-poop.gif';
import IdealWeightMobile from './weight-mobile.gif';
import IdealWeightDesktop from './weight.gif';

export const IdealWeightSources = {
  desktop: [IdealWeightDesktop],
  mobile: [IdealWeightMobile],
};

export const HealthierPoopSources = {
  desktop: [HealthierPoopDesktop],
  mobile: [HealthierPoopMobile],
};
