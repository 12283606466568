import { Redirect, useRouteMatch } from 'react-router';
import { StripeProvider, StripeElementsProvider } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';

import { PartialTosa } from '@farmersdog/tosa';
import Signup from '../Signup';
import { PaymentRequestContextProvider } from 'src/stripe';

import { useFeature } from 'src/abTesting';
import { useHandleTosaCompleted } from './useHandleTosaCompleted';
import { useHandleTosaMeCompleted } from './useHandleTosaMeCompleted';
import { useHandleTosaStepCompleted } from './useHandleTosaStepCompleted';

import { isFreshSignup } from '@farmersdog/lead-browser-storage';

import {
  useSetSignupFlowByUrl,
  useExpireSubscriptionType,
} from '../Signup/DIY/hooks';
import config from 'src/config';
import Exit from '../Signup/components/Exit';
import type { UseFeatureHook } from '@farmersdog/tosa/src/types';
import { CVR_SIGNUP_FLOW_DOWN } from 'src/abTesting/features';
import { isSignupShutoffCooldownComplete } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { useDispatch } from 'react-redux';
import { clearUserToken } from 'src/actions/auth';

const WrappedPartialTosa = () => {
  const handleTosaCompleted = useHandleTosaCompleted();
  const handleMeCompleted = useHandleTosaMeCompleted();
  const handleStepCompleted = useHandleTosaStepCompleted();
  const dispatch = useDispatch();

  const signupSiteShutoffIsOn =
    useFeature(CVR_SIGNUP_FLOW_DOWN).treatment === 'on' ||
    !isSignupShutoffCooldownComplete();
  if (signupSiteShutoffIsOn) {
    return <Redirect to={paths.PATH_SIGNUP_SITE_UNAVAILABLE} />;
  }

  const stripeKey = config('stripe.publicKey') as string;

  const clearSignupUser = () => {
    dispatch(clearUserToken());
  };

  return (
    // We include StripeProvider here to utilize Stripe's fraud protection
    // features
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <StripeProvider publishableKey={stripeKey}>
      <StripeElementsProvider>
        <PaymentRequestContextProvider>
          <Exit isTOSA={true} />
          <PartialTosa
            onTosaCompleted={handleTosaCompleted}
            onMeCompleted={handleMeCompleted}
            onStepCompleted={handleStepCompleted}
            useFeature={useFeature as UseFeatureHook}
            clearSignupUser={clearSignupUser}
          />
        </PaymentRequestContextProvider>
      </StripeElementsProvider>
    </StripeProvider>
  );
};

export function SignupTosa() {
  const isCheckoutPage = useRouteMatch(paths.PATH_SIGNUP_CHECKOUT);
  const isRecipesPage = useRouteMatch(paths.PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(paths.PATH_SIGNUP_PLANS);
  const isSuccessPage = useRouteMatch(paths.PATH_SIGNUP_SUCCESS);
  const showTosaRecipesPage = isFreshSignup();

  const isTosaManagedPage =
    (!isCheckoutPage && !isRecipesPage && !isPlansPage && !isSuccessPage) ||
    (showTosaRecipesPage && isRecipesPage);

  useSetSignupFlowByUrl();
  useExpireSubscriptionType();

  if (!isTosaManagedPage) {
    return <Signup />;
  }

  return <WrappedPartialTosa />;
}
