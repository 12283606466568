import { EmailSupportButton } from 'src/components/EmailSupportButton';
import { reloadLocation } from 'src/utils/dom';

import { Text, Button, Logo, PageWidth } from '@farmersdog/corgi-x';
import { MARGIN_LG } from '@farmersdog/corgi/build/constants';

import ErrorMessage from './ErrorMessage';
import getDisplayTitle from './getDisplayTitle';

interface ErrorPageProps {
  error: Error;
}

function ErrorPage({ error }: ErrorPageProps) {
  return (
    <PageWidth style={{ maxWidth: 630, marginTop: 40 }}>
      <div style={{ textAlign: 'center' }}>
        <Logo />
      </div>
      <Text
        variant="heading-40"
        as="h1"
        color="kale-3"
        style={{ textAlign: 'center' }}
        bold
      >
        {getDisplayTitle(error)}
      </Text>
      <ErrorMessage error={error} />
      <div style={{ textAlign: 'center' }}>
        <EmailSupportButton asLink subject="Support">
          <Text variant="heading-16" bold style={{ marginRight: MARGIN_LG }}>
            Contact Support
          </Text>
        </EmailSupportButton>
        <Button variant="primary" onClick={reloadLocation}>
          Try Again
        </Button>
      </div>
    </PageWidth>
  );
}

export default ErrorPage;
