import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

interface UseClearCustomizedSelectionArgs {
  availableSelectedRecipesNames: string[] | undefined;
  currentSelectedRecipeNames: string[] | undefined;
  setCustomizedSelection: (recipes: string[]) => void;
}

/**
 * Determines if lead's previous selection should be filtered from form state to only include available recipes.
 */
export function useRemoveUnavailableRecipes({
  availableSelectedRecipesNames,
  currentSelectedRecipeNames,
  setCustomizedSelection,
}: UseClearCustomizedSelectionArgs) {
  const hasSelectedUnavailableRecipes =
    currentSelectedRecipeNames &&
    availableSelectedRecipesNames &&
    !isEqual(
      [...currentSelectedRecipeNames].sort().map(name => name.toLowerCase()),
      [...availableSelectedRecipesNames].sort().map(name => name.toLowerCase())
    );

  // Check if existing recipes selection is unavailable and set only available recipes
  useEffect(() => {
    if (hasSelectedUnavailableRecipes) {
      setCustomizedSelection(availableSelectedRecipesNames);
    }
  }, [
    hasSelectedUnavailableRecipes,
    setCustomizedSelection,
    availableSelectedRecipesNames,
  ]);
}
