import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import parseQueryString from 'src/utils/parseQueryString';

import {
  selectPets,
  selectIndexOfFirstActivePet,
} from 'src/reducers/signup/user';

import getPetId from './getPetId';

/**
 * Get the petId based on the current url and pets in the signup reducer
 */
function useSignupPetLocation() {
  const location = useLocation();
  const activeIndex = useSelector(selectIndexOfFirstActivePet);
  const pets = useSelector(selectPets);
  const petName = parseQueryString(location.search, 'pet');
  return getPetId(petName, pets, activeIndex);
}

export default useSignupPetLocation;
