import { FetchResult } from '@apollo/client';
import { useSelector } from 'react-redux';
import useMutation, {
  MutationState,
  UseMutationOptions,
} from 'src/graphql/mutations/useMutation';
import * as GraphQLSchema from 'src/graphql/types';
import { selectIsLoggedIn } from 'src/reducers/auth';
import mutation from './updateMySignupSubscriptionTypeMutation.graphql';

import { useThrowToErrorBoundary } from 'src/hooks/useThrowToErrorBoundary';

interface UpdateMySignupSubscriptionTypeInput {
  currentSignupSubscriptionType?: GraphQLSchema.SubscriptionType;
  eligibleSignupSubscriptionTypes?: GraphQLSchema.Scalars['String']['input'];
}
type Response = GraphQLSchema.ProfileUpdateUserMutation;
type Variables = {
  input: UpdateMySignupSubscriptionTypeInput;
};
export type UseUpdateMySignupSubscriptionTypeReturn = [
  (
    input: UpdateMySignupSubscriptionTypeInput
  ) => Promise<FetchResult<Response> | undefined>,
  MutationState<Response>,
];

/**
 * Return a mutation tuple used for checking out a user.
 */
export function useUpdateMySignupSubscriptionType(
  options?: UseMutationOptions<Response, Variables>
): UseUpdateMySignupSubscriptionTypeReturn {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const throwToErrorBoundary = useThrowToErrorBoundary();
  const [submit, state] = useMutation<Response, Variables>(mutation, {
    onError: e => {
      throwToErrorBoundary(e);
    },
    ...options,
  });

  const updateMySignupSubscriptionType = (
    input: UpdateMySignupSubscriptionTypeInput
  ) => {
    if (isLoggedIn) {
      return submit({
        variables: { input },
      });
    }
    return new Promise<undefined>(resolve => {
      resolve(undefined);
    });
  };

  return [updateMySignupSubscriptionType, state];
}
