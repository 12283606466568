import { Picture } from '../../../Picture';
import { IdealWeightSources } from '../assets';
import { DidYouKnowBox } from '../DidYouKnowBox';
import responsiveStyles from '../PlanCalculationWithLoader.module.css';

import styles from './IdealWeightAnimation.module.css';

interface ResponsiveIdealWeightAnimation {
  isMobile?: boolean;
}

function ResponsiveIdealWeightAnimation({
  isMobile,
}: ResponsiveIdealWeightAnimation) {
  return (
    <div
      className={isMobile ? responsiveStyles.mobile : responsiveStyles.desktop}
    >
      <div className={styles.container}>
        <DidYouKnowBox textColor="green" className={styles.textsContainer}>
          Our precise, personalized portions make it simple to meet and maintain
          your dog’s ideal weight.
        </DidYouKnowBox>
        <div className={styles.animationContainer}>
          <Picture
            data-testid="ideal-weight-animation"
            className={styles.animation}
            alt=""
            aria-hidden="true"
            sources={
              isMobile ? IdealWeightSources.mobile : IdealWeightSources.desktop
            }
          />
        </div>
      </div>
    </div>
  );
}

export function IdealWeightAnimation() {
  return (
    <>
      <ResponsiveIdealWeightAnimation isMobile />
      <ResponsiveIdealWeightAnimation />
    </>
  );
}
