import { pinterest, PinterestEventAttributes } from './pinterest';
import { sharedPinterestAttributes } from './constants';

/** The attributes required for a Pinterest page view event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'pagevisit',
};

/**
 * Track a Pinterest page view event.
 */

export function trackPinterestPageView() {
  pinterest.track({
    ...attributes,
  });
}
