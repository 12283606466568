import { FormEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  Divider,
  Text,
  Input,
  FormControl,
  Select,
  Checkbox,
} from '@farmersdog/corgi-x';
import { LightboxControlReturn } from '@farmersdog/corgi';

import {
  AccountInformationFieldsFragment,
  ShippingAddressFieldsFragment,
  BillingAddressFieldsFragment,
  CgsCreditCardFieldsFragment,
  StateFieldsFragment,
  AddressFragment,
  SubmitOptions,
  AddressToUse,
} from '../../network';
import { useEditAccountForm, useCreditCardForm } from './hooks';
import { CreditCard } from './CreditCard';
import { SuggestedAddressModal } from './SuggestedAddressModal';
import styles from './EditAccount.module.css';
import { ModifyAccountMessage } from '../ModifyAccountMessage';

export type EditAccountFormValues = {
  accountInformation: Omit<
    AccountInformationFieldsFragment,
    '__typename' | 'id'
  >;
  shippingAddress: Omit<ShippingAddressFieldsFragment, '__typename' | 'id'>;
  billingAddress: Omit<BillingAddressFieldsFragment, '__typename' | 'id'>;
  isResidential: boolean;
  cardholderName: string;
  useShippingAsBillingAddress: boolean;
};

export function EditAccount(props: {
  initialValues: EditAccountFormValues;
  creditCardDetails: CgsCreditCardFieldsFragment;
  onSubmit: (options: SubmitOptions) => void;
  isSubmitting: boolean;
  states: StateFieldsFragment[];
  onCancel: () => void;
  suggestedAddressModalProps: LightboxControlReturn;
  submittedAddress: AddressFragment | undefined;
  suggestedAddress: AddressFragment | undefined | null;
  onSuggestedAddressConfirm: (addressToUse: AddressToUse) => void;
  onSuggestedAddressCancel: () => void;
}) {
  const form = useEditAccountForm(props);
  const creditCardForm = useCreditCardForm({
    hasBillingAddressChanged: form.hasBillingAddressChanged,
  });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    // If the non-credit fields haven't been touched/modified
    // We want to run a form validation prior to submission
    if (!form.isDirty) {
      const validationResult = await form.validateForm();

      if (Object.values(validationResult).length >= 1) return;
    }

    props.onSubmit({
      values: form.values,
      hasEditedBilling: creditCardForm.isEditing,
    });
  }

  const isFormDirty = form.isDirty || creditCardForm.isEditing;

  const isSubmitDisabled =
    !isEmpty(form.errors) || !isFormDirty || props.isSubmitting;

  return (
    <>
      {props.suggestedAddressModalProps.isOpen &&
        props.submittedAddress &&
        props.suggestedAddress && (
          <SuggestedAddressModal
            submittedAddress={props.submittedAddress}
            suggestedAddress={props.suggestedAddress}
            onConfirm={props.onSuggestedAddressConfirm}
            onCancel={props.onSuggestedAddressCancel}
            modalProps={props.suggestedAddressModalProps}
          />
        )}
      <div className={styles.editAccountContainer}>
        <ModifyAccountMessage />
        <form onSubmit={handleSubmit} className={styles.editAccountForm}>
          <fieldset className={styles.fieldsetItem}>
            <legend>
              <Text color="charcoal-3" variant="heading-22" as="h2" bold>
                User Account
              </Text>
            </legend>
            <div className={styles.formSection}>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.accountInformation?.firstName}
                invalid={Boolean(form.errors.accountInformation?.firstName)}
              >
                <Input
                  id="firstName"
                  name="accountInformation.firstName"
                  onChange={form.handleChange}
                  value={form.values.accountInformation.firstName}
                  label="First Name"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.accountInformation?.lastName}
                invalid={Boolean(form.errors.accountInformation?.lastName)}
              >
                <Input
                  id="lastName"
                  name="accountInformation.lastName"
                  onChange={form.handleChange}
                  value={form.values.accountInformation.lastName}
                  label="Last Name"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.accountInformation?.email}
                invalid={Boolean(form.errors.accountInformation?.email)}
              >
                <Input
                  id="email"
                  name="accountInformation.email"
                  onChange={form.handleChange}
                  value={form.values.accountInformation.email}
                  label="Email"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.accountInformation?.phone}
                invalid={Boolean(form.errors.accountInformation?.phone)}
              >
                <Input
                  id="phone"
                  name="accountInformation.phone"
                  onChange={form.handleChange}
                  value={form.values.accountInformation.phone}
                  autoComplete="tel"
                  inputMode="tel"
                  mask="phone"
                  label="Phone Number"
                />
              </FormControl>
            </div>
          </fieldset>
          <Divider color="Charcoal0" width={1} spacing={24} />
          <fieldset className={styles.fieldsetItem}>
            <legend>
              <Text color="charcoal-3" variant="heading-22" as="h2" bold>
                Shipping Address
              </Text>
            </legend>
            <div className={styles.formSection}>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.shippingAddress?.addressLine1}
                invalid={Boolean(form.errors.shippingAddress?.addressLine1)}
              >
                <Input
                  id="shippingAddress-addressLine1"
                  name="shippingAddress.addressLine1"
                  onChange={form.handleChange}
                  onBlur={form.handleShippingAddressOnBlur}
                  value={form.values.shippingAddress.addressLine1}
                  label="Street Address"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.shippingAddress?.addressLine2}
                invalid={Boolean(form.errors.shippingAddress?.addressLine2)}
              >
                <Input
                  id="shippingAddress-addressLine2"
                  name="shippingAddress.addressLine2"
                  onChange={form.handleChange}
                  onBlur={form.handleShippingAddressOnBlur}
                  value={form.values.shippingAddress.addressLine2 ?? ''}
                  label="Apartment Number"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.shippingAddress?.city}
                invalid={Boolean(form.errors.shippingAddress?.city)}
              >
                <Input
                  id="shippingAddress-city"
                  name="shippingAddress.city"
                  onChange={form.handleChange}
                  onBlur={form.handleShippingAddressOnBlur}
                  value={form.values.shippingAddress.city}
                  label="City"
                />
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.shippingAddress?.stateAbbreviation}
                invalid={Boolean(
                  form.errors.shippingAddress?.stateAbbreviation
                )}
              >
                <Select
                  id="shippingAddress-stateAbbreviation"
                  label="State"
                  value={form.values.shippingAddress.stateAbbreviation}
                  name="shippingAddress.stateAbbreviation"
                  onChange={form.handleChange}
                  onBlur={form.handleShippingAddressOnBlur}
                >
                  {props.states.map(state => {
                    return (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                className={styles.input}
                aria-live="polite"
                message={form.errors.shippingAddress?.zip}
                invalid={Boolean(form.errors.shippingAddress?.zip)}
              >
                <Input
                  id="shippingAddress-zip"
                  name="shippingAddress.zip"
                  onChange={form.handleChange}
                  onBlur={form.handleShippingAddressOnBlur}
                  value={form.values.shippingAddress.zip}
                  autoComplete="postal-code"
                  inputMode="numeric"
                  mask="zip"
                  label="Zip Code"
                />
              </FormControl>
            </div>
          </fieldset>
          <Divider color="Charcoal0" width={0} spacing={24} />
          <fieldset className={styles.fieldsetItem}>
            <div className={styles.checkboxes}>
              <FormControl
                aria-live="polite"
                message={form.errors.isResidential}
                invalid={Boolean(form.errors.isResidential)}
              >
                <Checkbox
                  id="isResidential"
                  name="isResidential"
                  onChange={form.handleChange}
                  checked={form.values.isResidential}
                  label="Is Residential"
                  type="checkbox"
                />
              </FormControl>
              <FormControl
                aria-live="polite"
                message={form.errors.useShippingAsBillingAddress}
                invalid={Boolean(form.errors.useShippingAsBillingAddress)}
              >
                <Checkbox
                  id="useShippingAsBillingAddress"
                  name="useShippingAsBillingAddress"
                  onChange={form.handleUseShippingAsBillingAddressChange}
                  checked={form.values.useShippingAsBillingAddress}
                  label="Use as Billing Address"
                  type="checkbox"
                />
              </FormControl>
            </div>
          </fieldset>
          <Divider color="Charcoal0" width={0} spacing={24} />
          {!form.values.useShippingAsBillingAddress && (
            <>
              <fieldset className={styles.fieldsetItem}>
                <legend>
                  <Text color="charcoal-3" variant="heading-22" as="h2" bold>
                    Billing Address
                  </Text>
                </legend>
                <div className={styles.formSection}>
                  <FormControl
                    className={styles.input}
                    aria-live="polite"
                    message={form.errors.billingAddress?.addressLine1}
                    invalid={Boolean(form.errors.billingAddress?.addressLine1)}
                  >
                    <Input
                      id="billingAddress-addressLine1"
                      name="billingAddress.addressLine1"
                      onChange={form.handleChange}
                      value={form.values.billingAddress.addressLine1}
                      label="Street Address"
                    />
                  </FormControl>
                  <FormControl
                    className={styles.input}
                    aria-live="polite"
                    message={form.errors.billingAddress?.addressLine2}
                    invalid={Boolean(form.errors.billingAddress?.addressLine2)}
                  >
                    <Input
                      id="billingAddress-addressLine2"
                      name="billingAddress.addressLine2"
                      onChange={form.handleChange}
                      value={form.values.billingAddress.addressLine2 ?? ''}
                      label="Apartment Number"
                    />
                  </FormControl>
                  <FormControl
                    className={styles.input}
                    aria-live="polite"
                    message={form.errors.billingAddress?.city}
                    invalid={Boolean(form.errors.billingAddress?.city)}
                  >
                    <Input
                      id="billingAddress-city"
                      name="billingAddress.city"
                      onChange={form.handleChange}
                      value={form.values.billingAddress.city}
                      label="City"
                    />
                  </FormControl>
                  <FormControl
                    className={styles.input}
                    aria-live="polite"
                    message={form.errors.billingAddress?.stateAbbreviation}
                    invalid={Boolean(
                      form.errors.billingAddress?.stateAbbreviation
                    )}
                  >
                    <Select
                      id="billingAddress-stateAbbreviation"
                      label="State"
                      value={form.values.billingAddress.stateAbbreviation}
                      name="billingAddress.stateAbbreviation"
                      onChange={form.handleChange}
                    >
                      {props.states.map(state => {
                        return (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={styles.input}
                    aria-live="polite"
                    message={form.errors.billingAddress?.zip}
                    invalid={Boolean(form.errors.billingAddress?.zip)}
                  >
                    <Input
                      id="billingAddress-zip"
                      name="billingAddress.zip"
                      onChange={form.handleChange}
                      value={form.values.billingAddress.zip}
                      autoComplete="postal-code"
                      inputMode="numeric"
                      mask="zip"
                      label="Zip Code"
                    />
                  </FormControl>
                </div>
              </fieldset>
            </>
          )}
          <Divider color="Charcoal0" width={1} spacing={24} />
          <CreditCard
            {...props.creditCardDetails}
            {...creditCardForm}
            cardholderName={form.values.cardholderName}
            setCardholderName={form.handleCardholderNameChange}
          />
          <Divider color="Charcoal0" width={1} spacing={24} />
          <fieldset className={styles.cta}>
            <Button
              type="submit"
              loading={props.isSubmitting}
              aria-disabled={isSubmitDisabled}
            >
              Submit
            </Button>
            <Button variant="plain-text" onClick={props.onCancel}>
              <Text variant="heading-16" bold>
                Cancel
              </Text>
            </Button>
          </fieldset>
        </form>
      </div>
    </>
  );
}
