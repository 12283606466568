export enum TurnstileErrorFamily {
  CHALLENGE_FAILED = 'challenge_failed',
  CRASHED = 'crashed',
  EXECUTION_ERROR = 'generic_client_execution_error',
  INITIALIZATION_PROBLEMS = 'initialization_problems',
  INTERNAL_ERROR = 'internal_cloudflare_error',
  INVALID_ACTION = 'unsupported_action',
  INVALID_API_VERSION = 'incompatible_api_version',
  INVALID_BROWSER = 'unsupported_browser',
  INVALID_CACHE = 'accidental_cache',
  INVALID_CLIENT_TIMING = 'time_problem',
  INVALID_CUSTOM_DATA = 'unsupported_cdata',
  INVALID_DOMAIN = 'domain_not_allowed',
  INVALID_PARAMETERS = 'invalid_parameters',
  INVALID_SITEKEY = 'invalid_sitekey',
  INCONSISTENT_USER_AGENT = 'inconsistent_user_agent',
  TIMEOUT = 'timeout',
  TIMEOUT_INTERACTIVE = 'timeout_interactive',
  UNDEFINED = 'undefined',
  UNKNOWN = 'unknown_error_type',
}

const RELOAD_PAGE_TEXT =
  'Please try refreshing the page.  If this error continues, please contact customer support.';
export const GENERIC_ERROR_MSG = `We were unable to verify your device. ${RELOAD_PAGE_TEXT}`;
export const INTERNAL_ERROR = 'Internal error. Please contact customer support';

type TurnstileError = {
  match: RegExp;
  type: TurnstileErrorFamily;
  reset?: boolean;
  retry?: boolean;
  message?: string;
};

const errorResponses: TurnstileError[] = [
  {
    match: /crashed/,
    type: TurnstileErrorFamily.CRASHED,
    retry: true,
  },
  {
    match: /undefined/,
    type: TurnstileErrorFamily.UNDEFINED,
    retry: true,
  },
  {
    match: /100\d{3}/,
    type: TurnstileErrorFamily.INITIALIZATION_PROBLEMS,
  },
  {
    match: /10[2-4,6]\d{3}/,
    type: TurnstileErrorFamily.INVALID_PARAMETERS,
    retry: true,
  },
  {
    match: /105\d{3}/,
    type: TurnstileErrorFamily.INVALID_API_VERSION,
  },
  {
    match: /1101[0-1]0/,
    type: TurnstileErrorFamily.INVALID_SITEKEY,
    message: INTERNAL_ERROR,
  },
  {
    match: /110200/,
    type: TurnstileErrorFamily.INVALID_DOMAIN,
    message: INTERNAL_ERROR,
  },
  {
    match: /110420/,
    type: TurnstileErrorFamily.INVALID_ACTION,
    message: INTERNAL_ERROR,
  },
  {
    match: /110430/,
    type: TurnstileErrorFamily.INVALID_CUSTOM_DATA,
    message: INTERNAL_ERROR,
  },
  {
    match: /110500/,
    type: TurnstileErrorFamily.INVALID_BROWSER,
    message: 'Please upgrade your browser',
  },
  {
    match: /110510/,
    type: TurnstileErrorFamily.INCONSISTENT_USER_AGENT,
    message: 'Please disable user agent spoofing',
  },
  {
    match: /11060\d{1}/,
    type: TurnstileErrorFamily.TIMEOUT,
    retry: true,
  },
  {
    match: /11062\d{1}/,
    type: TurnstileErrorFamily.TIMEOUT_INTERACTIVE,
    retry: true,
    reset: true,
  },
  {
    match: /200010/,
    type: TurnstileErrorFamily.INVALID_CACHE,
    message: 'Please clear the browser cache and try again',
  },
  {
    match: /200100/,
    type: TurnstileErrorFamily.INVALID_CLIENT_TIMING,
    message: `Please check your device's system time and try again`,
  },
  {
    match: /300\d{3}/,
    type: TurnstileErrorFamily.EXECUTION_ERROR,
    retry: true,
  },
  {
    match: /600\d{3}/,
    type: TurnstileErrorFamily.CHALLENGE_FAILED,
    retry: true,
  },
];
/**
 * Maps a Cloudflare Turnstile error code to the appropriate response information.
 *
 * @see https://developers.cloudflare.com/turnstile/reference/client-side-errors
 */

export function getCaptchaResponseFromErrorCode(code: string) {
  const {
    type = TurnstileErrorFamily.UNKNOWN,
    reset = false,
    retry = false,
    message = GENERIC_ERROR_MSG,
  } = errorResponses.find(({ match }) => match.test(code)) ?? {};

  return {
    code,
    type,
    reset,
    retry,
    message,
  };
}
