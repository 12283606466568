import styles from './ClinicInformation.module.css';
import { Text, Grid, GridItem } from '@farmersdog/corgi-x';

export type ClinicInformationProps = {
  name?: string;
  address?: string;
};
export function ClinicInformation({ address, name }: ClinicInformationProps) {
  return (
    <Grid className={styles.container}>
      <GridItem>
        <Text variant="heading-16">{name}</Text>
      </GridItem>
      <GridItem>
        <Text
          className={styles.informationItem}
          variant="heading-12"
          color="charcoal-2"
        >
          {address}
        </Text>
      </GridItem>
    </Grid>
  );
}
