import { DiyQuote } from 'src/pages/Signup/DIY/hooks/useFetchDiyQuote';

import { selectDiyQuote } from 'src/reducers/signup/diy';
import { selectDiySubscriptionCadence } from 'src/reducers/signup/user';
import { useSelector } from 'react-redux';
import { calculatePretaxWeeklyAmount } from 'src/pages/SignupRecipesPageDiy/utils/calculatePretaxWeeklyAmount';
import { SubscriptionType } from 'src/graphql/types';

export interface DiyCheckoutQuoteReturn {
  hasQuote: boolean;
  hasSalesTaxApplied: boolean;
  recommendedSubscription: DiyQuote['subscription']['recommended'];
  totalAmount: number;
  hasDiscount: boolean;
  trialDiscountPercentage: number | undefined;
  taxAmount: number;
  subtotalAmount: number;
  subtotalWeeklyAmount: number | undefined;
  regularOrderDaysOfFood: number;
  type: SubscriptionType.Diy;
  recommendationStrategy: undefined;
}

export function useDiyCheckoutQuote(): DiyCheckoutQuoteReturn {
  const quote = useSelector(selectDiyQuote) as DiyQuote | null;
  const regularOrderDaysOfFood = useSelector(selectDiySubscriptionCadence) ?? 0;

  const discountAmount = quote?.price.regular.discountAmount ?? 0;
  const taxAmount = quote?.price.regular.taxAmount ?? 0;
  const totalAmount = quote?.price.regular.totalAmount ?? 0;
  const subtotalAmount = totalAmount - taxAmount;

  const subtotalWeeklyAmount =
    regularOrderDaysOfFood && subtotalAmount
      ? calculatePretaxWeeklyAmount({
          pretaxAmount: subtotalAmount,
          currentCadence: regularOrderDaysOfFood,
        })
      : undefined;

  const hasSalesTaxApplied =
    typeof taxAmount === 'number' &&
    Number.isFinite(taxAmount) &&
    taxAmount > 0;

  const recommendedSubscription = quote?.subscription.recommended ?? {
    products: [],
    cadence: 0,
  };

  return {
    // Quote info
    hasQuote: !!quote,
    hasSalesTaxApplied,
    recommendedSubscription,
    // Pricing
    totalAmount,
    hasDiscount: discountAmount > 0,
    trialDiscountPercentage: undefined, // For parity with fresh quote
    taxAmount,
    subtotalAmount,
    subtotalWeeklyAmount,
    // Shipment
    regularOrderDaysOfFood,
    type: SubscriptionType.Diy,
    recommendationStrategy: undefined, // For parity with fresh quote
  };
}
