import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

export function trackOrderSizeUpNudgeSeePlanOptions() {
  track(eventNames.order_size_up_nudge_clicked_compare_plans);
}

export function trackOrderSizeUpNudgeMaybeLater() {
  track(eventNames.order_size_up_nudge_clicked_maybe_later);
}

export function trackOrderSizeNudgeUpViewedNudgeHero() {
  track(eventNames.order_size_up_nudge_viewed_nudge_hero);
}
