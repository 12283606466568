import EatingStyleCanBePickyExpandedNewPng from './eating-style-can-be-picky-expanded-new.png';
import EatingStyleCanBePickyExpandedNewWebp from './eating-style-can-be-picky-expanded-new.webp';
import EatingStyleCanBePickyExpandedPng from './eating-style-can-be-picky-expanded.png';
import EatingStyleGoodEaterExpandedNewPng from './eating-style-good-eater-expanded-new.png';
import EatingStyleGoodEaterExpandedNewWebp from './eating-style-good-eater-expanded-new.webp';
import EatingStyleGoodEaterExpandedPng from './eating-style-good-eater-expanded.png';
import EatingStyleVeryPickyExpandedNewPng from './eating-style-very-picky-expanded-new.png';
import EatingStyleVeryPickyExpandedNewWebp from './eating-style-very-picky-expanded-new.webp';
import EatingStyleVeryPickyExpandedPng from './eating-style-very-picky-expanded.png';
import EatingStyleWillEatAnythingExpandedNewPng from './eating-style-will-eat-anything-expanded-new.png';
import EatingStyleWillEatAnythingExpandedNewWebp from './eating-style-will-eat-anything-expanded-new.webp';
import EatingStyleWillEatAnythingExpandedPng from './eating-style-will-eat-anything-expanded.png';

export enum EatingStyleAssets {
  VeryPicky,
  CanBePicky,
  GoodEater,
  WillEatAnything,
}

export const eatingStyleAssets = {
  large: [
    [EatingStyleVeryPickyExpandedPng],
    [EatingStyleCanBePickyExpandedPng],
    [EatingStyleGoodEaterExpandedPng],
    [EatingStyleWillEatAnythingExpandedPng],
  ],
};

export const eatingStyleNewAssets = {
  large: [
    [EatingStyleVeryPickyExpandedNewWebp, EatingStyleVeryPickyExpandedNewPng],
    [EatingStyleCanBePickyExpandedNewWebp, EatingStyleCanBePickyExpandedNewPng],
    [EatingStyleGoodEaterExpandedNewWebp, EatingStyleGoodEaterExpandedNewPng],
    [
      EatingStyleWillEatAnythingExpandedNewWebp,
      EatingStyleWillEatAnythingExpandedNewPng,
    ],
  ],
};
