import { FetchQuoteQuery } from '../../../graphql/types';
import { formatCurrency } from '../../../utils/formatters/formatCurrency';
import { getPetDailySubtotal } from '../../../utils/quote/getPetDailySubtotal';
import { getTrialDurationInWeeks } from '../../../utils/quote/getTrialDurationInWeeks';

interface GetQuoteDataArgs {
  quote: FetchQuoteQuery['fetchQuote'] | null;
  currentPetName: string;
}

export function getQuoteData({ quote, currentPetName }: GetQuoteDataArgs) {
  const { dailySubtotal, dailySubtotalWithDiscount } = getPetDailySubtotal({
    quote,
    petName: currentPetName,
  });

  return {
    discountPercentage: quote?.trial.discount.percentage ?? null,
    weeksOfFood: getTrialDurationInWeeks(quote),
    petDailySubtotal: formatCurrency(dailySubtotal),
    petDailyDiscountedSubtotal: formatCurrency(dailySubtotalWithDiscount),
  };
}
