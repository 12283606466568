import { Link } from '@farmersdog/corgi';

import { EMAIL_CAREERS } from '../../constants/emails';
import { EQUAL_EMPLOYMENT_PDF } from '../../constants/pdfUrls';

import { Nowrap } from '@farmersdog/corgi-x';

export default {
  pageTitle: 'Dog People Wanted',
  pageTitleDescription: 'Make a living helping dogs live longer.',
  pageSubtitle: 'For the love of dogs, join our team!',
  pageText: (
    <>
      <p>
        The Farmer’s Dog was founded by two dog dads who were fed up with the
        ever-growing gap between how pet food was marketed (Wolves! Nature!
        T-bone steak!) and how it was actually made (Inedible meats! Rendered
        powders! Extreme processing!). They wanted to make it easier for owners
        to care for their beloved dogs — starting with truly healthy pet{' '}
        <Nowrap>food options.</Nowrap>
      </p>
      <p>
        Our mission resonates with dog owners of all types across the country,
        but just as importantly, it continues to draw to our team some of the
        most brilliant, passionate, and caring dog people on{' '}
        <Nowrap>the planet.</Nowrap>
      </p>
      <p>
        We come from a wide array of personal and professional backgrounds. Your
        role could involve developing systems to safely and swiftly deliver
        fresh food to doorsteps around the country, or creating products and
        experiences that inspire dog people to rethink a century-old habit. But
        wherever you find your fit, we guarantee you’ll come to work every day
        and immerse yourself in an unmatched, values-driven company culture of
        curiosity, fun, and <Nowrap>dog hair.</Nowrap>
      </p>
      <p>
        If this sounds like an environment in which you’d thrive, we’d love to
        hear from you. Roles we’re actively seeking to fill in both our NYC and
        Nashville offices are listed below, but if there isn’t anything that
        sounds like a great fit, we’d still love you to introduce yourself to us
        at <Link href={`mailto:${EMAIL_CAREERS}`}>{EMAIL_CAREERS}</Link>.
      </p>
      <p>
        Our one requirement? <Nowrap>You #mustlovedogs.</Nowrap>
      </p>
    </>
  ),
  deiHeading: (
    <>
      Our Commitment to Diversity, Equity, <br /> and Inclusion
    </>
  ),
  deiCopy: (
    <p>
      We want everyone who works at The Farmer’s Dog to be their authentic
      selves. For dogs, authenticity is the default. For people, we need to be
      more intentional about creating an environment where authenticity is the
      norm. That’s why we’re committed to hiring and supporting a diverse
      workforce, and investing in policies, hiring processes, and training that
      create and sustain an inclusive team.
    </p>
  ),
  equalEmploymentHeading: 'Equal Employment Opportunity Statement',
  equalEmploymentCopy: (
    <p>
      The Farmer’s Dog, Inc. is an equal employment opportunity employer and
      does not discriminate in hiring on the basis of sex, gender identity,
      sexual orientation, race, color, religion, creed, national origin,
      physical or mental disability, protected veteran status or any other
      legally protected characteristic or status. For more information, please{' '}
      <Nowrap>
        visit{' '}
        <Link target="_blank" href={EQUAL_EMPLOYMENT_PDF}>
          Know Your Rights
        </Link>
        .
      </Nowrap>
    </p>
  ),
};
