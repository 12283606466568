import classNames from 'classnames';

import { Grid, GridItem, Nowrap, Text } from '@farmersdog/corgi-x';

import { CvrWeightlossPersonalizationTreatments } from '../../../utils';
import { Section } from '../Section';

import { GenericStories } from './GenericStories';
import styles from './SuccessStories.module.css';
import { WeightLossStories } from './WeightLossStories';

interface SuccessStoriesProps {
  className?: string;
  petName: string;
  treatment: CvrWeightlossPersonalizationTreatments;
}

export function SuccessStories({
  className,
  petName,
  treatment,
}: SuccessStoriesProps) {
  const isWeightLoss =
    treatment === CvrWeightlossPersonalizationTreatments.weight_loss;

  const title = isWeightLoss ? (
    <>Healthy Weight Transformations</>
  ) : (
    <>
      The Proof <Nowrap>Is in the Pup</Nowrap>
    </>
  );

  const description = isWeightLoss
    ? `Here are just some of the countless dogs like ${petName} we’ve helped reach a healthy weight!`
    : 'See what real dog people are saying about how real, fresh food has impacted their pup for the better.';

  return (
    <Section backgroundColor="green">
      <Grid
        className={className}
        rowGap={{ xs: 'xl', lg: isWeightLoss ? 'md' : 'xl' }}
      >
        <GridItem
          flexDirection="column"
          alignItems={{
            xs: isWeightLoss ? 'flex-start' : 'center',
            lg: 'flex-start',
          }}
        >
          <Text
            className={styles.title}
            as="h3"
            variant="heading-12"
            color="charcoal-3"
            bold
          >
            Success Stories
          </Text>
          <Text
            className={classNames(styles.subtitle, {
              [styles.centered]: !isWeightLoss,
            })}
            as="h2"
            variant="heading-40"
            color="kale-3"
            topSpacing="xs"
            bold
          >
            {title}
          </Text>
          <Text
            className={classNames(styles.description, {
              [styles.centered]: !isWeightLoss,
            })}
            topSpacing="sm"
            bottomSpacing="none"
            as="p"
            variant="article-16"
          >
            {description}
          </Text>
        </GridItem>
        <GridItem>
          {isWeightLoss ? <WeightLossStories /> : <GenericStories />}
        </GridItem>
      </Grid>
    </Section>
  );
}
