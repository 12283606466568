export interface TestimonialSlideData {
  quote: string;
  fullName: string;
  pets: {
    name: string;
    age: number;
    breed: string;
    issues: string[];
    pickyEater: boolean;
  }[];
}

const TESTIMONIAL_DATA: TestimonialSlideData[] = [
  {
    quote:
      'I wanted to pass along this photo of my dog, Piper. I figured maybe it could help show the real difference a good food can make! She used to eat “high quality” kibble, and had awful tear staining. She hasn’t had a tear staining issue since switching to your food, and now is all lean muscle!',
    fullName: 'Juliana C.',
    pets: [
      {
        name: 'Piper',
        age: 3,
        breed: 'Italian Grey Hound and Chihuahua mix',
        issues: ['Tear stains'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Our 14 year old could hardly get up and walk due to so much pain in her hips, after eating your food she’s now running again! I was hoping it would help her but I wasn’t expecting that big of an improvement. Good food reduced her inflammation. You truly are what you eat.',
    fullName: 'Robert F.',
    pets: [
      {
        name: 'Myah',
        age: 14,
        breed: 'Unknown Mix Breed',
        issues: ['Joints', 'Lethargy'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      "I cannot believe how fast my dog devoured his first meal! He is notorious for being a picky eater, but wow, what a turnaround. Thank you so much for helping me maintain my dog's newfound clean eating routine. Absolutely remarkable!",
    fullName: 'Alison G.',
    pets: [
      {
        name: 'Max',
        age: 3,
        breed: 'Soft Coated Wheaten Terrier and Labrador Retriever mix',
        issues: [],
        pickyEater: true,
      },
    ],
  },
  {
    quote:
      'My dog was beginning a downward slope. We decided to make a change to her food...and we chose Farmer’s Dog...The difference is ASTOUNDING. She eats with gusto, her energy levels are back up, her teeth and coat are even better quality...she’s like a whole different dog. I honestly feel that Farmer’s Dog saved her.',
    fullName: 'Nicole R.',
    pets: [
      {
        name: 'Ginger',
        age: 6,
        breed: 'Jack Russel Terrier mix',
        issues: ['Skin and Coat', 'Lethargy'],
        pickyEater: true,
      },
    ],
  },
  {
    quote:
      "Dani is the perfect lap dog, but her gas was so bad I could barely hold her. Not to mention her loose stools and bouts of diarrhea. We tried all kinds of dog food..Nothing really worked. Until The Farmers Dog! What a difference. I can't thank you guys enough! And I am sure Dani would love to thank you too!",
    fullName: 'Eileen H.',
    pets: [
      {
        name: 'Dani',
        age: 2,
        breed: 'French Bulldog',
        issues: ['Diarrhea', 'Passes Gas'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Maggie loves the food and acts like a puppy! She had a lot of GI issues...which have greatly improved. She looks forward to the food and is excited where ever she sees the box! I would like to express my gratitude to you. The change in Maggie is unbelievable and makes my family, myself and her so happy.',
    fullName: 'David G.',
    pets: [
      {
        name: 'Maggie',
        age: 15,
        breed: 'Chihuahua',
        issues: ['Lethargy', 'Diarrhea'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      "You all make it so easy to be one of your greatest fans. I've said it before and I'll say it again, I've watched Jingle's health return 100% in a matter of days, after I was desperately afraid I would lose her. I attribute that solely to The Farmer’s Dog and you will never know how grateful I am!",
    fullName: 'Mary G.',
    pets: [
      {
        name: 'Jingle',
        age: 12,
        breed: 'Jack Russel Terrior and Rat Terrior mix',
        issues: [],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Sasha is just thriving on her food. For the last two years, she has been slowing down quite a lot. After two weeks of being on her new food, she has more energy, looks a lot more interested in what is going around her and literally hops. I am so pleased with this food, it makes her happy!',
    fullName: 'Ann G.',
    pets: [
      {
        name: 'Sasha',
        age: 12,
        breed: 'Samoyed',
        issues: ['Lethargy'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Lola has put on a lot of weight in her old age, despite a strict diet and exercise. The weight never came off. Our vet ran every test imaginable on her. Since switching to Farmers Dog, she’s lost 10lbs and is playing again! It’s been nothing short of miraculous. I’m so happy that we found you guys!',
    fullName: 'Sarah C.',
    pets: [
      {
        name: 'Lola',
        age: 12,
        breed: 'Labrador Retriever',
        issues: ['Lethargy'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      "I believe changing to your food has helped some dry patches Izzie's had on her ears. They seem softer. She already had a shiny coat, but wow!—Her coat is feeling silkier. People think that she’s much younger than she is. She’s a forever puppy, we like to call her. So, let me be clear: WE LOVE YOU!",
    fullName: 'Tisa M.',
    pets: [
      {
        name: 'Lizzie',
        age: 10,
        breed: 'Dachshund',
        issues: ['Skin and Coat'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      "I now spend (more) on food but vet bills have dropped. All their vets attribute the pups' improved health to your food!",
    fullName: 'Lisa K.',
    pets: [
      {
        name: 'Annabelle',
        age: 12,
        breed: 'Coon Hound',
        issues: [],
        pickyEater: false,
      },
      {
        name: 'Casper',
        age: 8,
        breed: 'Coonhound and Bloodhound mix',
        issues: [],
        pickyEater: false,
      },
      {
        name: 'Farnsworth',
        age: 5,
        breed: 'Bloodhound',
        issues: [],
        pickyEater: false,
      },
      {
        name: 'Jane',
        age: 17,
        breed: 'Coonhound',
        issues: [],
        pickyEater: false,
      },
      {
        name: 'CheyAnne',
        age: 12,
        breed: 'Chihuahua',
        issues: [],
        pickyEater: false,
      },
      {
        name: 'Balto',
        age: 15,
        breed: 'Coonhound',
        issues: [],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'The Farmer’s Dog food has helped COMPLETELY RID MY DOG OF FOOD ALLERGIES. I cannot express how critical that has been in enhancing my dog’s quality of life, and easing my wallet from all the critical vet visits. Thanks to Farmers Dog.',
    fullName: 'Jennie M.',
    pets: [
      {
        name: 'Oliver',
        age: 6,
        breed: 'French Bulldog',
        issues: ['Allergies'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Benjamin has worried us for years as he won’t eat. I have bought every soft and kibble dog food available. I put your food in the bowl and he gobbled it. We are now half through the third packet and he still loves it. He will sit at his bowl and beg. I am amazed. Thank you for whatever magic happens in your kitchen.',
    fullName: 'Gwen B.',
    pets: [
      {
        name: 'Ben',
        age: 15,
        breed: 'Chihuahua',
        issues: [],
        pickyEater: true,
      },
    ],
  },
  {
    quote:
      "Liam (was) 73lbs. He had no energy to do anything. I knew I needed to change up his food...it's been about 4.5 months (on The Farmer’s Dog), and look at the difference! He's 8 years old and has his puppy energy back which is good and bad!..Thank you so much for helping me whip this fat boy back in shape!",
    fullName: 'Natalie B.',
    pets: [
      {
        name: 'Liam',
        age: 7,
        breed: 'Unknown mixed-breed',
        issues: ['Lethargy'],
        pickyEater: false,
      },
    ],
  },
  {
    quote:
      'Lucy has cancer and we almost put her down. As a last ditch effort, we tried your food and she has come back to life...we know she lost weight (too). She is jumping playing and acting like a puppy. Thank you for giving me more time with my precious dog.',
    fullName: 'Michael D.',
    pets: [
      {
        name: 'Lucy',
        age: 10,
        breed: 'Cockapoo',
        issues: ['Lethargy'],
        pickyEater: false,
      },
    ],
  },
];

export default TESTIMONIAL_DATA;
