import {
  Text,
  ActivityIndicator,
  Page,
  Grid,
  GridItem,
  Divider,
} from '@farmersdog/corgi-x';
import { usePaymentRetryOrderData } from '../network/usePaymentRetryOrderData';
import { useHistory } from 'react-router';
import { reporter } from '../../errors/services';
import { formatDateRange } from '../../utils';
import { PATH_PROFILE } from '@farmersdog/constants/paths';
import styles from './PaymentRetry.module.css';
import PaymentSuccessIconSVG from '../assets/paymentSuccessIcon.svg';
import { StickyButton } from './StickyButton';

export function PaymentRetrySuccess() {
  const { data, loading, error } = usePaymentRetryOrderData();
  const history = useHistory();
  const handleOnClose = () => {
    history.push(PATH_PROFILE);
  };
  const handleOnCTAClick = handleOnClose;
  const heading = `We'll begin prepping this order soon. You can expect it to arrive`;
  const ctaText = 'Back To Account';

  if (loading) {
    return (
      <Page layout="base-centered" className={styles.root}>
        <ActivityIndicator mode="dark" />
      </Page>
    );
  }
  if (error) {
    reporter.error(new Error('Error loading PaymentRetry Data'), {
      sourceError: error,
    });
    return null;
  }

  if (!data) {
    reporter.error(new Error('Unknown exception occurred while loading data'), {
      sourceError: error,
    });
    return null;
  }

  const arrivalWindow = formatDateRange(
    data.customer.nextOrderToBeDelivered.deliveryWindow
      .earliestDesiredArrivalDate,
    data.customer.nextOrderToBeDelivered.deliveryWindow
      .latestDesiredArrivalDate,
    { abbreviatedMonth: false }
  );
  return (
    <Page layout="base-centered" onCloseClick={handleOnClose}>
      <Grid
        className={styles.grid}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <GridItem>
          <PaymentSuccessIconSVG />
        </GridItem>
        <Divider borderStyle="none" />
        <GridItem>
          <Text
            as="h1"
            variant="heading-40"
            color="kale-3"
            data-testid="headline_text"
            bold
          >
            Success!
          </Text>
        </GridItem>

        <GridItem>
          <Text
            variant="article-20"
            color="charcoal-3"
            className={styles.headingSection}
          >
            {heading}
          </Text>
          <Text as="h1" variant="heading-22" color="kale-3" bold>
            {arrivalWindow}
          </Text>
        </GridItem>
        <Divider borderStyle="none" />

        <StickyButton
          buttonVariant="secondary"
          ctaText={ctaText}
          handleOnCTAClick={handleOnCTAClick}
        />
      </Grid>
    </Page>
  );
}
