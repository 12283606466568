import { LINK_PRIVACY_OPTOUT } from '@farmersdog/constants/links';
import { PATH_TERMS, PATH_PRIVACY } from '@farmersdog/constants/paths';

interface Link {
  label: string;
  to: string;
}

export const footerLinks: Link[] = [
  {
    label: 'Privacy',
    to: PATH_PRIVACY,
  },
  {
    label: 'Terms',
    to: PATH_TERMS,
  },
  {
    label: 'Do Not Sell My Personal Information',
    to: LINK_PRIVACY_OPTOUT,
  },
];
