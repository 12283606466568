import { Modal } from '@farmersdog/corgi';
import { Text, type TextProps } from '@farmersdog/corgi-x';

import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import { useCorgiViewport } from 'src/screen';

export const NO_PASSWORD_SET_MODAL_ID = 'NoPasswordSetModalId';

interface ResponsiveVariants {
  heading: TextProps['variant'];
  body: TextProps['variant'];
}

export function NoPasswordSetModal() {
  const { rootProps, labelProps } = useGlobalLightbox({
    id: NO_PASSWORD_SET_MODAL_ID,
  });

  const textVariants = useCorgiViewport<ResponsiveVariants>({
    xs: {
      heading: 'heading-28',
      body: 'heading-16',
    },
    md: {
      heading: 'heading-40',
      body: 'heading-22',
    },
  });

  return (
    // @ts-expect-error TODO: Fix these types in corgi
    <Modal {...rootProps}>
      <Text as="h2" variant={textVariants.heading} bold {...labelProps}>
        Please check your email
      </Text>
      <Text as="p" variant={textVariants.body}>
        We couldn’t find a password for your account, so we have sent you a link
        to create a new one.
      </Text>
      <Text as="p" variant={textVariants.body}>
        Please follow the instructions we have sent to create a password and try
        to login again.
      </Text>
    </Modal>
  );
}
