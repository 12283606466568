import { Link } from '@farmersdog/corgi-x';
import { AdditionalTerms } from 'src/components/AdditionalTerms';

const EMAIL = 'care@thefarmersdog.com';
const MAIL_TO = `mailto:${EMAIL}`;

export function ReferralTerms() {
  return (
    <AdditionalTerms title="Referral Terms">
      <p>
        Limited time offer with limited quantities available only online at
        thefarmersdog.com. Offer applicable to new customers only with an active
        automatic recurring subscription service. Offer applies to The Farmer’s
        Dog fresh food products only, not to applicable taxes, shipping and
        handling charges. Offer cannot be combined with any other offers,
        credits or discounts. Offer is non-transferable and not valid for cash
        or cash equivalent. No adjustments on previous purchases. The Farmer’s
        Dog reserves the right to modify, terminate or cancel the offer at any
        time. For inquiries, contact <Link to={MAIL_TO}>{EMAIL}</Link>.
      </p>
    </AdditionalTerms>
  );
}
