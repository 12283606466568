import { jwtDecode } from 'jwt-decode';

export interface TokenPayload {
  email: string;
  exp: number;
  iat: number;
}

function decodeToken(token: string) {
  return jwtDecode<TokenPayload>(token);
}

interface DoesEmailMatchTokenArgs {
  email: string;
  token: string | null;
}
export function doesEmailMatchToken({ email, token }: DoesEmailMatchTokenArgs) {
  if (!token) {
    return false;
  }
  const decodedToken = tokenUtils.decodeToken(token);

  return decodedToken.email === email;
}

export const tokenUtils = {
  decodeToken,
  doesEmailMatchToken,
};
