export enum Application {
  Website = 'website',
  Tosa = 'tosa',
}

export const pixelOptions = [
  'nextdoor',
  'impact',
  'pinterest',
  'tiktok',
  'ispot',
] as const;
export type PixelOption = (typeof pixelOptions)[number];
export type PixelConfig = PixelOption[];

export type PixelSettings = Record<PixelOption, boolean>;
