import { Card, Text } from '@farmersdog/corgi-x';

import styles from './ModifyAccountMessage.module.css';

export function ModifyAccountMessage() {
  return (
    <Card variant="plain" className={styles.card}>
      <Text variant="heading-16" as="p" vSpacing="none">
        You can make edits to your recipes, order size, or other plan details
        right after reactivating!
      </Text>
    </Card>
  );
}
