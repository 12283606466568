import { Nowrap, Slideshow } from '@farmersdog/corgi-x';

import { Viewport } from 'src/screen';
import Flex from 'src/components/_depreciated/Flex';
import { useFeature } from 'src/abTesting';
import { trackCondensedPressBarClicked } from 'src/analytics/events/trackCondensedPressBarClicked';

import {
  CBSNewsLogo,
  FastCompanyLogo,
  TodayShowLogo,
  VogueLogo,
  GoogleReviewsLogo,
} from 'src/components/Logos';

import { OYSTER_0 } from '@farmersdog/corgi/build/constants';

import CompanyLogo from './CompanyLogo';

const Google = props => (
  <CompanyLogo
    withQuotes={false}
    image={<GoogleReviewsLogo height={78} {...props} />}
  >
    Customer reviews
  </CompanyLogo>
);

const TodayShow = props => (
  <CompanyLogo image={<TodayShowLogo height={30} {...props} />}>
    An online pet food company that makes feeding real food to{' '}
    <Nowrap>dogs really easy</Nowrap>.
  </CompanyLogo>
);
const Vogue = props => (
  <CompanyLogo image={<VogueLogo height={30} {...props} />}>
    It never sits on a shelf. All you do is open the{' '}
    <Nowrap>pack and pour</Nowrap>.
  </CompanyLogo>
);
const FastCompany = props => (
  <CompanyLogo image={<FastCompanyLogo height={30} {...props} />}>
    Researchers concluded that fresh diets do demonstrate a number of{' '}
    <Nowrap>pet health benefits</Nowrap>.
  </CompanyLogo>
);
const CBSNews = props => (
  <CompanyLogo image={<CBSNewsLogo height={30} {...props} />}>
    Owners can get healthy dog food shipped right to{' '}
    <Nowrap>their door.</Nowrap>
  </CompanyLogo>
);

const CondensedMobilePressBar = () => {
  return (
    <Slideshow
      withDots
      withButtons
      withLoop
      label="Mobile press bar"
      controlsPosition="outside"
      scrollBehavior="smooth"
      onSlideChange={trackCondensedPressBarClicked}
    >
      <Flex justifyContent="center">{<TodayShow loading="lazy" />}</Flex>
      <Flex justifyContent="center">{<Google loading="lazy" />}</Flex>
      <Flex justifyContent="center">{<Vogue loading="lazy" />}</Flex>
      <Flex justifyContent="center">{<FastCompany loading="lazy" />}</Flex>
      <Flex justifyContent="center">{<CBSNews loading="lazy" />}</Flex>
    </Slideshow>
  );
};

function SectionLogos() {
  const condensedMobilePressBarEnabled =
    useFeature('cvr_condensed_press_bar').treatment === 'on';
  return (
    <section style={{ background: OYSTER_0, padding: '1rem 0' }}>
      <Viewport
        xs={
          condensedMobilePressBarEnabled ? (
            CondensedMobilePressBar
          ) : (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Google />
              <TodayShow />
              <Vogue />
              <FastCompany />
              <CBSNews />
            </Flex>
          )
        }
        md={
          <>
            <Flex justifyContent="center" alignItems="start">
              <Google />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="start"
              style={{ margin: '1.5rem 0' }}
            >
              <TodayShow />
              <Vogue />
            </Flex>
            <Flex justifyContent="center" alignItems="start">
              <FastCompany />
              <CBSNews />
            </Flex>
          </>
        }
        lg={
          <>
            <Flex justifyContent="center" alignItems="start">
              <TodayShow />
              <Google />
              <FastCompany />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="start"
              style={{ marginTop: '1.5rem' }}
            >
              <Vogue />
              <CBSNews />
            </Flex>
          </>
        }
        xl={
          <Flex justifyContent="center" alignItems="start">
            <TodayShow />
            <FastCompany />
            <Google />
            <Vogue />
            <CBSNews />
          </Flex>
        }
      />
    </section>
  );
}

export default SectionLogos;
