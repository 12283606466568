import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import { AmazonTagMethod } from './AmazonTagScript';

export enum AmazonTagEvent {
  PageView = 'PageView',
  Lead = 'Lead',
  AddToShoppingCart = 'AddToShoppingCart',
  Checkout = 'Checkout',
}

export interface TrackAmazonTagEventArgs {
  name: AmazonTagEvent;
  properties?: Record<string, unknown>;
}

function _mountAmazonTagEvent(
  w: Window,
  { name, properties = {} }: TrackAmazonTagEventArgs
) {
  w.amzn?.(AmazonTagMethod.TrackEvent, name, properties);
}

export const mountAmazonTagEvent = mountVendorWindowEvent(
  'Amazon',
  _mountAmazonTagEvent
);
