import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

/**
 * This function tracks the customer’s desired weekly price, current quote
 * price, and which version of the cancellation flow they are using
 * @param desiredWeeklyPrice - The customer's input for their desired weekly price in dollars
 * @param currentWeeklyPrice - The customer's current quote weekly price in dollars
 */
export function trackCancelSubscriptionCompiledDesiredPrice(params: {
  desiredWeeklyPrice: number;
  currentWeeklyPrice: number;
}) {
  track(eventNames.cancel_subscription_compiled_desired_price, {
    desired_weekly_price: String(params.desiredWeeklyPrice),
    current_weekly_price: String(params.currentWeeklyPrice),
    cancellationVersion: 2,
  });
}
