import { local } from '@farmersdog/utils';

export const TOKEN_KEY = 'tosa-token';

function get(): string | null {
  return local.getItem(TOKEN_KEY);
}

function set(token: string): void {
  local.setItem(TOKEN_KEY, token);
}

function remove(): void {
  local.removeItem(TOKEN_KEY);
}

export const tosaToken = {
  get,
  set,
  remove,
};
