/* global window */

let isRegistered = false;

module.exports = function ({ evaluate = false } = {}) {
  // For server usage
  if (!process.env.TOSA_IS_LIBRARY) {
    if (!isRegistered) {
      const { register } = require('@swc-node/register/register');
      register();

      isRegistered = true;
    }

    const config = require('./server').default;

    // For TOSA development mode
    if (evaluate) {
      const serverProperties = JSON.stringify(config.getProperties());

      return {
        code: `module.exports = () => { return ${serverProperties} };`,
        cacheable: false,
      };
    }

    return config.getProperties();
  }

  if (process.env.TOSA_IS_LIBRARY) {
    // When bundled as website server
    if (!process.env.BROWSER) {
      const convictSchema =
        // @ts-expect-error we're reaching out of this package to the website but the tsconfig doesn't like that
        require('../../../website/src/config/config').default;
      const convict = require('convict');
      const convictConfig = convict(convictSchema);

      convictConfig.validate({ allowed: 'strict' });

      return convictConfig.getProperties();
    }

    // When bundled with website
    return window.__APP_CONFIG__;
  }
};
