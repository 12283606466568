import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useAnonymousExperimentsContext } from '../../../hooks/useAnonymousExperimentsContext';
import { cvr_tosa_high_traffic_mode } from '../../../utils';

import { handleRequestTimeout, HandleRequestTimeoutArgs } from './utils';
export function useHandleRequestTimeout() {
  const { experiments } = useAnonymousExperimentsContext();

  const history = useHistory();
  const isrequestTimeoutSignupShutoffEnabled =
    experiments[cvr_tosa_high_traffic_mode]?.treatment === 'on';
  const configCooldownDuration =
    experiments[cvr_tosa_high_traffic_mode]?.config?.cooldownDurationInMinutes;
  // if we didn't get a valid config for the cooldown duration, we'll run the default
  const timeoutDurationInMinutes =
    typeof configCooldownDuration === 'number'
      ? configCooldownDuration
      : undefined;

  const safelyHandleRequestTimeout = useCallback(
    (error: HandleRequestTimeoutArgs['error']) => {
      if (isrequestTimeoutSignupShutoffEnabled) {
        return handleRequestTimeout({
          error,
          timeoutDurationInMinutes,
          history,
        });
      }
    },
    [history, timeoutDurationInMinutes, isrequestTimeoutSignupShutoffEnabled]
  );

  return safelyHandleRequestTimeout;
}
