interface FloorOptions {
  /** If the value is a float, the decimal precision to round too */
  precision?: number;
  fractional?: boolean;
}

function getFractionalPrecisionOfDecimal(decimal: number) {
  if (decimal < 0.25) return 0;
  if (decimal < 0.33) return 0.25;
  if (decimal < 0.5) return 0.33;
  if (decimal < 0.66) return 0.5;
  if (decimal < 0.75) return 0.66;
  return 0.75;
}

function getFractionalPrecision(value: number) {
  const whole = Math.floor(value);
  const decimal = value % 1;
  const fractionalPrecision = getFractionalPrecisionOfDecimal(decimal);
  return whole + fractionalPrecision;
}

export function floor(value: number, options: FloorOptions = {}) {
  const precision = options.precision ?? 0;
  const precisionInfluence = Math.pow(10, options.fractional ? 2 : precision);
  const floored =
    Math.floor((value + Number.EPSILON) * precisionInfluence) /
    precisionInfluence;

  if (!options.fractional) return floored;

  return getFractionalPrecision(floored);
}
