import fetch from 'cross-fetch';
import { HttpLink } from '@apollo/client';
import { getUriForHttpLink } from '../utils/getUriForHttpLink';
import { prepareGlobalRequestHeaders } from '../utils/prepareGlobalRequestHeaders';

export const getApolloHttpLink = (token: string | null): HttpLink => {
  return new HttpLink({
    uri: getUriForHttpLink,
    headers: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      ...(token ? { authorization: `Bearer ${token}` } : undefined),
      ...prepareGlobalRequestHeaders(),
    },
    fetch,
  });
};
