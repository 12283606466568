import { useId, ReactNode } from 'react';
import classNames from 'classnames';

import { type TextProps, Text } from '@farmersdog/corgi-x';
import { useCorgiV2Viewport } from 'src/screen';

import styles from './TitleWithCopy.module.scss';

export type ContentProps = Omit<TextProps, 'variant'> & {
  variant?: TextProps['variant'];
  style?: React.CSSProperties;
};

interface TitleWithCopyProps {
  title?: ReactNode;
  titleProps?: ContentProps;
  align?: 'start' | 'end' | 'center';
  copy?: ReactNode;
  copyProps?: ContentProps;
  children?: ReactNode;
}

/**
 * Title with copy to describe on contents purpose.
 * Includes padding to keep content safe in a Slide component.
 *
 * @deprecated Use `Section` and the `Text` components from `@farmersdog/corgi-x`.
 */
export function TitleWithCopy({
  title,
  titleProps,
  align = 'start',
  copy,
  copyProps,
  children,
}: TitleWithCopyProps) {
  const reactId = useId();
  const labelId = titleProps?.id || reactId;

  const variant = useCorgiV2Viewport<'heading-28' | 'heading-40'>({
    xs: 'heading-28',
    lg: 'heading-40',
  });

  return (
    <section
      aria-labelledby={labelId}
      className={classNames(
        styles.container,
        styles[align],
        useCorgiV2Viewport({ xs: styles.xs, lg: styles.lg })
      )}
    >
      <Text
        as="h2"
        color="kale-3"
        bold
        id={labelId}
        variant={variant}
        {...titleProps}
      >
        {title}
      </Text>
      <Text
        as="p"
        color="charcoal-3"
        className={styles.copy}
        variant="heading-16"
        {...copyProps}
      >
        {copy}
      </Text>
      <div className={styles.content}>{children}</div>
    </section>
  );
}
