import { Pixel } from '../Pixel';

export const PIXEL_ID = 'eb39db8a-05d1-449a-95e0-f0e17dfb38e9';

export enum ArtsAiEventName {
  PAGE_VIEW = 'content',
  CHECKOUT_SUCCESS = 'signup',
}

export interface ArtsAiAttributes extends Record<string, unknown> {
  pixid: string;
  action: ArtsAiEventName;
}

export const artsAi = new Pixel<ArtsAiAttributes>({
  vendorName: 'ArtsAI',
  baseUrl: 'https://arttrk.com/pixel/?',
  stringifyOptions: {
    delimiter: '&',
  },
});
