import { Button } from '@farmersdog/corgi-x';

import styles from './FreshOnlyCta.module.css';

import type { RecommendedPlanCtaProps } from './RecommendedPlanCta';

const FreshOnlyCta = ({
  recommendationDisabled,
  recommendationLoading,
  formSubmitting,
  onClick,
}: RecommendedPlanCtaProps) => {
  return (
    <Button
      disabled={recommendationDisabled || formSubmitting}
      aria-disabled={formSubmitting}
      loading={recommendationLoading || formSubmitting}
      className={styles.submitButton}
      type="submit"
      onClick={onClick}
    >
      Continue with Recommendation
    </Button>
  );
};

export { FreshOnlyCta };
