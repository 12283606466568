import { ReactNode } from 'react';

export const APPLICATION_ERROR_NAME = 'ApplicationError';

// TODO: extend ErrorOptions to add `cause` in TS 4.6
export interface ApplicationErrorOptions {
  title?: string;
  displayMessage?: ReactNode;
}

/**
 * A system error. This extends the base Error class.
 *
 * @param message - An error message
 * @param options.title - An optional, non-technical, user-facing title
 * @param options.displayMessage - An optional, non-technical, user-facing message
 *
 * @example
 *
 *```ts
 * new ApplicationError('subscription type is undefined');
 * new ApplicationError(
 *   "subscription type is undefined",
 *   {
 *     title: "Uh oh! Something went wrong...",
 *     displayMessage: <p>We're having a hard time finding your subscription. Please contact <a href="">support</a></p>
 *   }
 * );
 *```
 */
export class ApplicationError extends Error {
  title: string | undefined;
  displayMessage: ReactNode;

  constructor(message: string, options?: ApplicationErrorOptions) {
    super(message);
    this.name = APPLICATION_ERROR_NAME;
    this.title = options?.title;
    this.displayMessage = options?.displayMessage;
  }
}
