import { stringifyUserId } from '../../utils';

import { sharedPinterestAttributes } from './constants';
import {
  pinterest,
  PinterestEventAttributes,
  PinterestCheckoutEventData,
} from './pinterest';

/** The attributes required for an Pinterest checkout conversion event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'checkout',
};

export interface MountPinterestConversionProps {
  userId: number;
  email: string;
}

/**
 * Mount a Pinterest checkout conversion event.
 */

export async function mountPinterestCheckoutEvent({
  userId,
  email,
}: MountPinterestConversionProps) {
  const eventData: PinterestCheckoutEventData = {
    value: '100',
    currency: 'USD',
    order_id: stringifyUserId({ userId }),
  };
  await pinterest.setEnhancedMatchAttributes(email);
  pinterest.mount({
    ...attributes,
    ed: eventData,
  });
}
