import { PATH_HOME } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi';
import { Progress, Logo } from '@farmersdog/corgi-x';

import {
  PreviousLinkNavigationLabel,
  UsePreviousLinkNavigationReturn,
} from '../../../../hooks/usePreviousLinkNavigation';
import { BackButton } from '../BackButton';
import { StepData } from '../hooks/useSteps';

import styles from './SmallNavigation.module.css';
import { Steps } from './Steps';

interface SmallNavigationProps extends UsePreviousLinkNavigationReturn {
  stepData: StepData;
}

export function SmallNavigation(props: SmallNavigationProps) {
  const { stepData } = props;
  const { steps, activeStep } = stepData;

  return (
    <nav className={styles.container} data-testid="header-nav-mobile">
      <div className={styles.navigation}>
        <BackButton {...props} />
        <Link
          to={PATH_HOME}
          className={styles.homeLink}
          aria-label={PreviousLinkNavigationLabel.homePage}
          data-testid="header-logo-mobile"
        >
          <Logo variant="product" size={50} />
        </Link>
        <Steps stepData={stepData} />
      </div>
      <Progress
        className={styles.progress}
        role="status"
        title="Sign up progress"
        aria-label={`Step ${activeStep?.number ?? 0} of ${steps.length}`}
        steps={steps.length}
        activeStep={activeStep?.number}
        stepProgress={activeStep?.percentageComplete}
      />
    </nav>
  );
}
