import classNames from 'classnames';

import { Text, Nowrap } from '@farmersdog/corgi-x';

import { Picture } from '../../../shared/Picture';
import {
  BenefitsList as desktopBenefitsList,
  getBenefitsList,
} from '../constants/BenefitsMap';

import styles from './Benefits.module.css';

interface BenefitsProps {
  isMobileGetStartedCopyOn: boolean;
}

export function Benefits({ isMobileGetStartedCopyOn }: BenefitsProps) {
  const benefitClassnames = classNames(styles.benefitSmall, {
    [styles.benefitSmallMobile]: isMobileGetStartedCopyOn,
  });

  const mobileBenefitsList = getBenefitsList(isMobileGetStartedCopyOn);

  return (
    <div className={styles.benefitLayout}>
      <div className={styles.benefitLargeWrapper}>
        {desktopBenefitsList.map((benefit, i) => (
          <div key={`benefit-large-${i}`} className={styles.benefitLarge}>
            <Picture
              sources={benefit.imageSources}
              alt=""
              className={styles.benefitIcon}
            />
            <div className={styles.benefitBox}>
              <Text
                as="h3"
                variant="heading-16"
                className={styles.headline}
                color="charcoal-1"
                bold
              >
                {benefit.noWrap ? (
                  <Nowrap>{benefit.headline}</Nowrap>
                ) : (
                  benefit.headline
                )}
              </Text>
              <div className={styles.benefitLargeDescription}>
                <Text as="span" variant="article-16" color="charcoal-1">
                  <>
                    {benefit.firstLine}
                    <br />
                    {benefit.secondLine}
                  </>
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.benefitSmallWrapper}>
        {mobileBenefitsList.map(benefit => (
          <div key={benefit.headline} className={benefitClassnames}>
            <Picture
              sources={benefit.imageSources}
              className={styles.benefitIcon}
              alt=""
            />
            <Text
              className={styles.benefitBody}
              as="span"
              variant="heading-16"
              color="charcoal-1"
              bold
            >
              {benefit.noWrap ? (
                <Nowrap>{benefit.headline}</Nowrap>
              ) : (
                benefit.headline
              )}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}
