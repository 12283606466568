import { Path } from '../config';
import qs from 'qs';

interface BuildUtmsArgs {
  path: Path;
}

interface Utms {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

type PathToUtms = Record<Path, Utms>;

const pathToUtms: PathToUtms = {
  [Path.Mets]: {
    utm_source: 'mets',
    utm_medium: 'partnerships',
    utm_content: 'freshfocus',
    utm_term: 'mets',
    utm_campaign: 'mets',
  },
  [Path.Dogpeople]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'streaming',
    utm_term: 'radio',
    utm_campaign: 'dogpeople',
  },
  [Path.Healthyhabits]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'streaming',
    utm_term: 'radio',
    utm_campaign: 'healthyhabits',
  },
  [Path.Secrets]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'secrets',
  },
  [Path.Fitish]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'fitish',
  },
  [Path.Daddies]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'daddies',
  },
  [Path.Spout]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'spout',
  },
  [Path.Fueled]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'fueled',
  },
  [Path.Irrational]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'irrational',
  },
  [Path.Weeklydose]: {
    utm_source: 'audacy',
    utm_medium: 'audio',
    utm_content: 'podcast',
    utm_term: 'liveread',
    utm_campaign: 'weeklydose',
  },
  [Path.News]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'news',
  },
  [Path.Lol]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'lol',
  },
  [Path.Cnn]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'cnn',
  },
  [Path.Bloomberg]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'bloomberg',
  },
  [Path.Comedy]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'comedy',
  },
  [Path.Games]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'games',
  },
  [Path.Hln]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'hln',
  },
  [Path.Kiss]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'kiss',
  },
  [Path.Msnbc]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'msnbc',
  },
  [Path.Radioandy]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'radioandy',
  },
  [Path.Sirius]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'sirius',
  },
  [Path.Sxm]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'sxm',
  },
  [Path.Todaysxm]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'todaysxm',
  },
  [Path.Stars]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'stars',
  },
  [Path.Debatable]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'debatable',
  },
  [Path.Faction]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: 'faction',
  },
  [Path.Disney]: {
    utm_source: 'tv',
    utm_medium: 'direct',
    utm_content: 'strangeworld',
    utm_term: 'disney',
    utm_campaign: 'strangeworld',
  },
  [Path.Realfood]: {
    utm_source: 'linear',
    utm_medium: 'tv',
    utm_content: 'testimonials_founder',
    utm_term: 'realfood',
    utm_campaign: 'testimonials_founder',
  },
  [Path.Rangers]: {
    utm_source: 'partnerships',
    utm_medium: 'baseball',
    utm_content: 'rangers',
    utm_campaign: 'rangers2023',
    utm_term: 'rangers',
  },
  [Path.Mariners]: {
    utm_source: 'partnerships',
    utm_medium: 'baseball',
    utm_content: 'seattle',
    utm_campaign: 'seattle2023',
    utm_term: 'seattle',
  },
  [Path.Newheights]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_healthier_homepage',
    utm_campaign: 'newheights',
    utm_term: 'newheights',
  },
  [Path.Pmt]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_healthier_homepage',
    utm_campaign: 'pmt',
    utm_term: 'pmt',
  },
  [Path.Football]: {
    utm_source: 'nfl',
    utm_medium: 'tv',
    utm_content: 'forevernfl',
    utm_term: '',
    utm_campaign: '2023nfl',
  },
  [Path.Toast]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'toast',
    utm_content: 'healthier_homepage',
    utm_term: 'toast',
  },
  [Path.Skinny]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'skinnyconfidential',
    utm_content: 'healthier_homepage',
    utm_term: 'skinny',
  },
  [Path.Mel]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'mel',
    utm_content: 'healthier_homepage',
    utm_term: 'mel',
  },
  [Path.Elvis]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'elvisduran',
    utm_content: 'healthier_homepage',
    utm_term: 'elvis',
  },
  [Path.Elvisduran]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'elvisduran',
    utm_content: 'healthier_homepage',
    utm_term: 'elvis',
  },
  [Path.Jomboy]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'jomboy',
    utm_content: 'healthier_homepage',
    utm_term: 'jomboy',
  },
  [Path.Bible]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'bbible',
    utm_content: 'healthier_homepage',
    utm_term: 'bible',
  },
  [Path.SXM50]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_campaign: '2023',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
  },
  [Path.Bobby]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_campaign: 'bobbybones',
    utm_content: 'healthier_homepage',
    utm_term: 'bobby',
  },
  [Path.Jetblue]: {
    utm_source: 'jetblue',
    utm_medium: 'tfdpartnerships',
    utm_campaign: 'in-flight-pre-roll',
    utm_content: 'nomagic',
    utm_term: 'jetblue',
  },
  [Path.Time]: {
    utm_source: 'linear',
    utm_medium: 'tv',
    utm_content: 'itstime',
    utm_term: '',
    utm_campaign: 'itstimeq523',
  },
  [Path.Timeqr30]: {
    utm_source: 'timeqr30',
    utm_medium: 'tv',
    utm_content: 'itstimeqr',
    utm_term: '',
    utm_campaign: 'itstime',
  },
  [Path.Yanks]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_jomboy_healthier_homepage',
    utm_term: 'yanks',
    utm_campaign: 'talkinyanks',
  },
  [Path.Talkin]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_jomboy_healthier_homepage',
    utm_term: 'today',
    utm_campaign: 'baseballtoday',
  },
  [Path.Today]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_jomboy_healthier_homepage',
    utm_term: 'talkin',
    utm_campaign: 'talkinbaseball',
  },
  [Path.MorningMeeting]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_jomboy_healthier_homepage',
    utm_term: 'morningmeeting',
    utm_campaign: 'morningmeeting',
  },
  [Path.Rose]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_jomboy_healthier_homepage',
    utm_term: 'rose',
    utm_campaign: 'roserotation',
  },
  [Path.Rogan]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'healthier_homepage',
    utm_term: 'rogan',
    utm_campaign: 'joerogan',
  },
  [Path.MSNBC50]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: '2024',
  },
  [Path.CNN50]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: '2024',
  },
  [Path.COCO]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: '2024',
  },
  [Path.Sirius50]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: '2024',
  },
  [Path.MLB]: {
    utm_source: 'veritone',
    utm_medium: 'audio',
    utm_content: 'siriusxm',
    utm_term: 'prerecord',
    utm_campaign: '2024',
  },
  [Path.AvmaTechs2024]: {
    utm_source: 'vet_tech_lounge_free_trial',
    utm_medium: 'AVMA',
    utm_content: 'vet_tech_lounge',
    utm_term: 'Sign',
    utm_campaign: 'vet',
  },
  [Path.VetKnowsBest]: {
    utm_source: 'in_practice',
    utm_medium: 'direct_mail',
    utm_content: 'trifold',
    utm_term: 'vet',
    utm_campaign: 'vet',
  },
  [Path.AvmaGuest2024]: {
    utm_source: 'avma_booth',
    utm_medium: 'AVMA',
    utm_content: 'verbal',
    utm_term: 'vet',
    utm_campaign: 'vet',
  },
  [Path.Nightcap]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_healthier_homepage',
    utm_term: 'nightcap',
    utm_campaign: 'nightcap',
  },
  [Path.Shayshay]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'sports_healthier_homepage',
    utm_term: 'shayshay',
    utm_campaign: 'clubshayshay',
  },
  [Path.CallHerDaddy]: {
    utm_source: 'tfdinfluencer-podcast',
    utm_medium: 'influencer_podcast',
    utm_content: 'healthier_homepage',
    utm_term: 'daddy',
    utm_campaign: 'callherdaddy',
  },
  [Path.Han]: {
    utm_source: 'han',
    utm_medium: 'tv',
    utm_content: 'han',
    utm_term: '',
    utm_campaign: 'testimonials24',
  },
  [Path.Leo]: {
    utm_source: 'leo',
    utm_medium: 'tv',
    utm_content: 'leo',
    utm_term: '',
    utm_campaign: 'testimonials24',
  },
  [Path.Bogart]: {
    utm_source: 'bogart',
    utm_medium: 'tv',
    utm_content: 'bogart',
    utm_term: '',
    utm_campaign: 'testimonials24',
  },
  [Path.Love]: {
    utm_source: 'brandads24',
    utm_medium: 'tv',
    utm_content: 'love',
    utm_term: '',
    utm_campaign: 'brandads24',
  },
  [Path.Try]: {
    utm_source: 'streaming',
    utm_medium: 'tv',
    utm_content: '',
    utm_term: '',
    utm_campaign: 'amazon_streaming',
  },
};

export function buildUtms({ path }: BuildUtmsArgs) {
  const utms: Utms = {
    ...pathToUtms[path],
  };

  return qs.stringify(utms);
}
