export type HigherDiscountBannerEligibilityProps = {
  discountAmount?: number;
  couponCode?: string;
};

enum OnbFrequencyCapExperimentalDiscountCode {
  ONBFREQUENCYCAP60 = 'onbfrequencycap60',
  ONBFREQUENCYCAP70 = 'onbfrequencycap70',
}
const higherDiscountCodeMap: Record<
  OnbFrequencyCapExperimentalDiscountCode,
  number
> = {
  [OnbFrequencyCapExperimentalDiscountCode.ONBFREQUENCYCAP60]: 60,
  [OnbFrequencyCapExperimentalDiscountCode.ONBFREQUENCYCAP70]: 70,
};

export function isEligibleForHigherDiscountBanner({
  discountAmount,
  couponCode,
}: HigherDiscountBannerEligibilityProps): boolean {
  return (
    !!couponCode &&
    couponCode in higherDiscountCodeMap &&
    discountAmount ===
      higherDiscountCodeMap[
        couponCode as OnbFrequencyCapExperimentalDiscountCode
      ]
  );
}
