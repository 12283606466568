import { Text, useViewport } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import styles from './KeyIngredients.module.css';
import { LineAndDot } from './LineAndDot';

interface KeyIngredientsProps {
  ingredients: string[];
}

export function KeyIngredients({ ingredients }: KeyIngredientsProps) {
  const { medium, large, extraLarge } = useViewport();
  const split = medium || large || extraLarge;

  return (
    <div className={styles.outerContainer}>
      <div className={classNames(styles.container, { [styles.split]: split })}>
        {split && (
          <>
            <div>
              {ingredients.slice(0, 2).map((ingredient, i) => (
                <div
                  className={classNames(styles.item, styles.leftItem)}
                  key={i}
                >
                  <Text bold variant="heading-16" color="kale-3">
                    {ingredient}
                  </Text>
                  <LineAndDot />
                </div>
              ))}
            </div>
            <div>
              {ingredients.slice(2, 4).map((ingredient, i) => (
                <div
                  className={classNames(styles.item, styles.rightItem)}
                  key={i}
                >
                  <LineAndDot flip />
                  <Text bold variant="heading-16" color="kale-3">
                    {ingredient}
                  </Text>
                </div>
              ))}
            </div>
          </>
        )}
        {!split && (
          <>
            <div>
              {ingredients.slice(0, 4).map((ingredient, i) => (
                <div
                  className={classNames(
                    styles.item,
                    styles.rightItem,
                    styles.split
                  )}
                  key={i}
                >
                  <LineAndDot flip />
                  <Text bold variant="heading-16" color="kale-3">
                    {ingredient}
                  </Text>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
