import { ChangeEvent, useCallback, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { Position } from '../../../blueprint/types';
import {
  handleCustomPlaceholders,
  getNodeNameAndPosition,
} from '../../../blueprint/utils';
import { INPUT_PLACEHOLDER } from '../../../hooks/useLabel';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { determineSkipGetStarted } from '../../../utils';
import { getInputAttributes } from '../../../utils/getInputAttributes';
import { InlineInput } from '../base/InlineInput';

import styles from './PetNameInput.module.css';

export function PetNameInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, experiments } = props;
  const { setValue, formState, register, getValues } = formMethods;
  const registerProps = register(node.name);
  const { position } = getNodeNameAndPosition(node.name);

  const prevPosition = `${parseInt(position) - 1}` as Position;
  const prevPetNameField = `${NodeNames.Name}-${prevPosition}` as const;

  const inputAttributes = getInputAttributes(node);
  const isFirstPet = parseInt(position) === 1;

  const labelConnector = isFirstPet ? 'named ' : ' &\u00A0';
  const parsedLabel = handleCustomPlaceholders({
    input: node.input?.label ?? '',
    replacement: labelConnector,
  });
  const [frontLabel, backLabel] = parsedLabel.split(INPUT_PLACEHOLDER);

  const spanRef = useRef<HTMLSpanElement | null>(null);
  const { shouldSkipGetStarted } = determineSkipGetStarted({ experiments });

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const value = event.target.value;
      await registerProps.onChange(event);

      if (shouldSkipGetStarted) {
        requestAnimationFrame(() => {
          spanRef.current?.scrollIntoView({
            block: 'start',
          });
        });
      }

      setValue(node.name, value.replace(/[^\w -]/gi, ''), {
        shouldValidate: true,
      });
    },
    [node.name, registerProps, setValue, shouldSkipGetStarted]
  );

  const handleBlur = async (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const value = event.target.value;
    await registerProps.onBlur(event);

    setValue(node.name, value.trim(), {
      shouldValidate: true,
    });
  };

  const inputWrapperClassName = isFirstPet ? styles.first : undefined;
  const labelInputWrapperClassName = !isFirstPet ? styles.notFirst : undefined;

  const renderInput =
    isFirstPet ||
    formState.dirtyFields[prevPetNameField] ||
    getValues(prevPetNameField);
  return (
    <>
      {renderInput && (
        <>
          <span ref={spanRef} className={labelInputWrapperClassName}>
            {frontLabel}
            <span className={inputWrapperClassName}>
              <InlineInput
                className={styles.input}
                {...inputAttributes}
                {...registerProps}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </span>
          </span>
          {backLabel}
        </>
      )}
    </>
  );
}
