import { useMutation } from '@apollo/client';
import { ReactivatedNextOrderToBeDeliveredFragment } from '.';
import {
  ReactivateFreshSubscriptionMutation,
  ReactivateFreshSubscriptionMutationVariables,
} from './reactivateFreshSubscription.cgs.generated-types';
import reactivateFreshSubscriptionMutation from './reactivateFreshSubscription.cgs.graphql';

interface Props {
  onCompleted: () => void;
  onError: () => void;
}

interface UseReactivateFreshSubscription {
  submit: (nextDate: string) => void;
  isSubmitting: boolean;
  nextOrderToBeDelivered: ReactivatedNextOrderToBeDeliveredFragment | undefined;
}

export function useReactivateFreshSubscription(
  props: Props
): UseReactivateFreshSubscription {
  const [callReactivate, reactivateState] = useMutation<
    ReactivateFreshSubscriptionMutation,
    ReactivateFreshSubscriptionMutationVariables
  >(reactivateFreshSubscriptionMutation, {
    context: {
      endpoint: 'cgs',
    },
    onCompleted: props.onCompleted,
    onError: props.onError,
  });

  function submit(nextDate: string) {
    void callReactivate({ variables: { input: { nextDate } } });
  }

  return {
    submit,
    isSubmitting: reactivateState.loading,
    nextOrderToBeDelivered:
      reactivateState.data?.reactivateFreshSubscription?.customer
        .nextOrderToBeDelivered,
  };
}
