import { Nowrap } from '@farmersdog/corgi-x';

import benjamin from './assets/benjamin';
import nick from './assets/nick';
import prince from './assets/prince';

export default [
  {
    name: 'Nick’s mom',
    quote: (
      <span>
        Nick would seldom eat my homemade food, but gobbled TFD down in seconds!
        The vet can’t believe <Nowrap>the improvement.</Nowrap>
      </span>
    ),
    sources: nick,
  },
  {
    name: 'Prince’s mom',
    quote: (
      <span>
        Prince is an extremely picky eater, but from his first TFD meal, he‘s
        gobbled up every bite! Y’all have done what no other dog food{' '}
        <Nowrap>company can.</Nowrap>
      </span>
    ),
    sources: prince,
  },
  {
    name: 'Benjamin’s mom',
    quote: (
      <span>
        I have bought every dog food available, but Ben wouldn’t eat. I put your
        food in the bowl and he gobbled it. He will sit at his bowl{' '}
        <Nowrap>and beg.</Nowrap>
      </span>
    ),
    sources: benjamin,
  },
];
