import lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import { WeekdayNumber } from 'src/constants/time';

/**
 * -----------------------------------------------------------------
 * THIS FILE IS DEPRECIATED MIGRATE ALL FUNCTIONS TO THEIR OWN FILES
 * -----------------------------------------------------------------
 */

/**
 * Format two ship dates into a human-readable string
 * TODO: provide an example of what's doing
 *
 * @param {Moment} [date1]
 * @param {Moment} [date2]
 * @returns {String}
 */
export function formatShipDates(date1, date2) {
  let dateString;

  if (!date1 || !date2) {
    if (!date1 && !date2) {
      // both dates ares null
      dateString = '';
    } else {
      // one date is null
      dateString = (date1 || date2).format('MMMM Do');
    }
  } else if (date1.format('YYYYMM') === date2.format('YYYYMM')) {
    // same month
    dateString = `${date1.format('MMMM Do')}-${date2.format('Do')}`;
  } else {
    // different month
    dateString = `${date1.format('MMMM Do')} and ${date2.format('MMMM Do')}`;
  }

  return dateString;
}

/**
 * Return the monday of the week the given day belongs to.
 *
 * @example
 *
 *  console.log(formatWeekOf('2019-09-17')) // September, 16th
 *
 * @param {String} day The day in YYYY-MM-DD format
 */
export function formatWeekOf(day) {
  return moment(day, 'YYYY-MM-DD').day(WeekdayNumber.Monday).format('MMMM Do');
}

/**
 * Returns the string in the case style for website labels
 *
 * @example
 *
 * labelCase(myLabel) // My label
 *
 * @param {String} label The string to format to "Label case"
 */
export function labelCase(label) {
  const lower = lowerCase(label);
  return lower.charAt(0).toUpperCase() + lower.slice(1);
}
