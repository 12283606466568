import { DiyRecipe, PrimaryProtein } from '../types';
import beefAndRiceForPuppiesWebp from '../assets/beef-and-rice-for-puppies.webp';

export const beefAndRiceForPuppies: DiyRecipe = {
  name: 'Beef and Rice for Puppies',
  isPuppy: true,
  primaryProtein: PrimaryProtein.Beef,
  ingredients: [
    {
      name: '90% lean ground beef',
      quantity: 706,
      unit: 'g',
      alternativeQuantity: 1.6,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 194,
      unit: 'g',
      alternativeQuantity: 1.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots',
      quantity: 102,
      unit: 'g',
      alternativeQuantity: 0.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 88,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 3, unit: 'cup' },
        { name: 'frozen', quantity: 0.5, unit: 'cup' },
      ],
    },
    {
      name: 'broccoli, cut into 1/4 inch dice',
      quantity: 88,
      unit: 'g',
      alternativeQuantity: 1,
      alternativeUnit: 'cup',
    },
    {
      name: 'eggs (yolk and white)',
      quantity: 85,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'large',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 3.6,
      unit: 'g',
      alternativeQuantity: 0.75,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 0.65,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salt, non iodized',
      quantity: 0.65,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  ingredientSummary:
    'Ground Beef, White Rice, Carrots, Spinach, Broccoli, Eggs, DIY Nutrient Mix, Sunflower Oil, Salmon Oil, Salt',
  id: 'beef-and-rice-for-puppies',
  yieldWeight: 2.8,
  cookTime: '35 mins',
  calories: 1537,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: beefAndRiceForPuppiesWebp,
  instructions: [
    {
      name: 'Step 1',
      description: `Cook rice according to the instructions on the packaging and set aside.`,
    },
    {
      name: 'Step 2',
      description: `Place pot/skillet/dutch oven oven over medium to medium-high heat on the stovetop. 
      Pour the sunflower oil in and spread it to evenly coat the bottom.`,
    },
    {
      name: 'Step 3',
      description: `Add the ground beef. Break apart and stir until the meat is no longer pink and is broken into small pieces.`,
    },
    {
      name: 'Step 4',
      description: `Add the carrots and broccoli. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.`,
      note: `Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.`,
    },
    {
      name: 'Step 5',
      description: `Add the white rice and spinach. Cook until the spinach is wilted and stir to evenly distribute throughout the mixture.`,
    },
    {
      name: 'Step 6',
      description: `Add the egg, salt, and salmon oil to the pot. Break the yolks and stir to evenly distribute. 
      Cook until the eggs are firm and no longer runny.`,
    },
    {
      name: 'Step 7',
      description: `If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.`,
      note: `In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.`,
    },
    {
      name: 'Step 8',
      description: `Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer's Dog DIY Nutrient Mix evenly across the surface. 
      Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.`,
    },
  ],
};
