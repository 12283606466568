import {
  PET_CONFIGURATION_FETCH,
  PRESCRIPTION_DIETS_FETCH,
} from 'src/constants/actionTypes';
import { graphql } from 'src/actions/actionCreators';

import fetchPetConfigurationQuery from 'src/graphql/queries/usePetConfiguration/petConfiguration.graphql';
import fetchPrescriptionDietsQuery from './fetchPrescriptionDiets.graphql';

export const fetchPetConfiguration = () =>
  graphql(PET_CONFIGURATION_FETCH, fetchPetConfigurationQuery);

export const fetchPrescriptionDiets = () =>
  graphql(PRESCRIPTION_DIETS_FETCH, fetchPrescriptionDietsQuery);
