import { artsAi, ArtsAiEventName, PIXEL_ID } from './artsAi';

/**
 * Mount an ArtsAI lead conversion pixel
 */
export function mountArtsAiLead(): void {
  artsAi.mount({
    pixid: PIXEL_ID,
    action: ArtsAiEventName.LEAD,
    ad_log: 'referer',
  });
}
