import { useCallback, useState } from 'react';

const TREATS_NUDGE_DISMISSED_KEY = 'treats-nudge-dismissed';
const TREATS_NUDGE_DISMISSED_TRUE_VALUE = 'true';

/**
 * A hook that provides functions for interacting with a storage mechanism.
 * Specific to the Treats Nudge module.
 *
 * @param {Storage} store - The storage mechanism to use.
 */
export function useTreatsNudgeStorage(store: Storage) {
  const [isTreatsNudgeDismissed, setIsTreatsNudgeDismissed] = useState<boolean>(
    () =>
      store.getItem(TREATS_NUDGE_DISMISSED_KEY) ===
      TREATS_NUDGE_DISMISSED_TRUE_VALUE
  );

  const setTreatsNudgeDismissed = useCallback(() => {
    store.setItem(
      TREATS_NUDGE_DISMISSED_KEY,
      TREATS_NUDGE_DISMISSED_TRUE_VALUE
    );
    setIsTreatsNudgeDismissed(true);
  }, [store]);

  return {
    setTreatsNudgeDismissed,
    isTreatsNudgeDismissed,
  };
}
