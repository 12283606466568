import howtoCookIconPng from '../../assets/howto-cook-icon.png';
import howtoIngredientsIconPng from '../../assets/howto-ingredients-icon.png';
import howtoMixIconPng from '../../assets/howto-mix-icon.png';
import howtoRecipeIconPng from '../../assets/howto-recipe-icon.png';

export interface HowToContent {
  icon: string;
  title: string;
  description: string;
}

export const content: HowToContent[] = [
  {
    icon: howtoMixIconPng,
    title: 'Sign up',
    description:
      'Tell us a bit about your dog and we’ll show you your custom DIY delivery plan.',
  },
  {
    icon: howtoRecipeIconPng,
    title: 'Get your recipes',
    description:
      'Select from the personalized, vet-approved recipes in your customer account.',
  },
  {
    icon: howtoIngredientsIconPng,
    title: 'Shop for your ingredients',
    description: 'Buy the food ingredients at your store of choice.',
  },
  {
    icon: howtoCookIconPng,
    title: 'Cook and add The DIY Nutrient Mix',
    description:
      'Follow our easy cooking instructions and add in the DIY Nutrient Mix to make meals complete and balanced.',
  },
];
