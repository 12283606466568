import {
  HealthIssue,
  PrescriptionDietIssues,
} from '../../../../validation/constants';

export interface MedicalConditionConfig {
  [key: string]: {
    name: string;
    displaySkipOption?: boolean;
    warningMessage?: string;
  };
}

export const PrescriptionDietsConfig: MedicalConditionConfig = {
  [PrescriptionDietIssues.LowFat.toLowerCase()]: {
    name: 'Low Fat',
    displaySkipOption: true,
    warningMessage: `Dogs on prescription foods often require a specific recipe which we don’t currently formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.`,
  },
  [PrescriptionDietIssues.JointSupport.toLowerCase()]: {
    name: 'Joint Support',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.WeightManagement.toLowerCase()]: {
    name: 'Weight Management',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.WeightLoss.toLowerCase()]: {
    name: 'Weight Loss',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.RenalDisease.toLowerCase()]: {
    name: 'Renal Disease',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.Diabetes.toLowerCase()]: {
    name: 'Diabetes',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.LiverSupport.toLowerCase()]: {
    name: 'Liver Support',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.NeoplasiaDiet.toLowerCase()]: {
    name: 'Neoplasia Diet',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.SkinSupport.toLowerCase()]: {
    name: 'Skin Support',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.HydrolyzedProtein.toLowerCase()]: {
    name: 'Hydrolyzed Protein',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.GiPancreatitisDiet.toLowerCase()]: {
    name: 'GI/Pancreatitis Diet',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.Other.toLowerCase()]: {
    name: 'Other',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.GiDiet.toLowerCase()]: {
    name: 'GI diet',
    displaySkipOption: true,
  },
  [PrescriptionDietIssues.PancreatitisDiet.toLowerCase()]: {
    name: 'Pancreatitis diet',
    displaySkipOption: true,
  },
};

export const HealthIssuesConfig: MedicalConditionConfig = {
  [HealthIssue.Anxiety.toLowerCase()]: { name: 'Anxiety' },
  [HealthIssue.BadBreath.toLowerCase()]: { name: 'Bad breath' },
  [HealthIssue.Cancer.toLowerCase()]: { name: 'Cancer' },
  [HealthIssue.ChewsPaws.toLowerCase()]: { name: 'Chews paws' },
  [HealthIssue.Constipation.toLowerCase()]: { name: 'Constipation' },
  [HealthIssue.Diarrhea.toLowerCase()]: { name: 'Diarrhea' },
  [HealthIssue.EarInfections.toLowerCase()]: { name: 'Ear infections' },
  [HealthIssue.EnvironmentalAllergies.toLowerCase()]: {
    name: 'Environmental allergies',
  },
  [HealthIssue.FoodAllergies.toLowerCase()]: { name: 'Food allergies' },
  [HealthIssue.GlutenSensitive.toLowerCase()]: { name: 'Gluten sensitive' },
  [HealthIssue.GrainSensitive.toLowerCase()]: { name: 'Grain sensitive' },
  [HealthIssue.Hyperactive.toLowerCase()]: { name: 'Hyperactive' },
  [HealthIssue.HyperlipidemiaOrHypertriglyceridemia.toLowerCase()]: {
    name: 'Hyperlipidemia / Hypertriglyceridemia',
    warningMessage: `Dogs with this issue often require a specific recipe which we don’t currently formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.`,
    displaySkipOption: true,
  },
  [HealthIssue.PancreatitisHistory.toLowerCase()]: {
    name: 'History of Pancreatitis',
    warningMessage: `Dogs with this issue often require a specific recipe which we don’t currently formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.`,
    displaySkipOption: true,
  },
  [HealthIssue.Itchiness.toLowerCase()]: { name: 'Itchiness' },
  [HealthIssue.Joints.toLowerCase()]: { name: 'Joints' },
  [HealthIssue.Lethargy.toLowerCase()]: { name: 'Lethargy' },
  [HealthIssue.PassesGas.toLowerCase()]: { name: 'Passes Gas' },
  [HealthIssue.Seizures.toLowerCase()]: { name: 'Seizures' },
  [HealthIssue.SkinAndCoat.toLowerCase()]: { name: 'Skin and Coat' },
  [HealthIssue.TearStains.toLowerCase()]: { name: 'Tear stains' },
  [HealthIssue.UrinaryTract.toLowerCase()]: { name: 'Urinary tract' },
  [HealthIssue.Vomiting.toLowerCase()]: { name: 'Vomiting' },
};
