import { MouseEventHandler } from 'react';

import { Text, Button } from '@farmersdog/corgi-x';

import { BreedType } from '../../../../types';
import { Fade } from '../animation/Fade';

import styles from './AddBreedButtons.module.css';

interface AddBreedButtonsProps {
  petName: string;
  unknownBreed: BreedType;
  selectedBreeds: BreedType[];
  handleAddAnotherBreed: MouseEventHandler;
  handleAddUnknownBreed: MouseEventHandler;
}

export function AddBreedButtons({
  petName,
  selectedBreeds,
  unknownBreed,
  handleAddAnotherBreed,
  handleAddUnknownBreed,
}: AddBreedButtonsProps) {
  return (
    <>
      <Fade
        behavior="hidden"
        in={
          selectedBreeds &&
          selectedBreeds.length >= 1 &&
          selectedBreeds.length < 5
        }
      >
        <Text
          as="div"
          variant="article-16"
          fontStyle="italic"
          className={styles.breedText}
        >
          <Button
            variant="plain-text"
            onClick={handleAddAnotherBreed}
            className={styles.breedButton}
          >
            Add another breed
          </Button>
        </Text>
      </Fade>
      <Fade
        behavior="hidden"
        in={Boolean(
          unknownBreed && (!selectedBreeds || selectedBreeds.length === 0)
        )}
      >
        <Text
          as="div"
          variant="article-16"
          fontStyle="italic"
          color="charcoal-3"
          className={styles.breedText}
        >
          If {petName ?? 'your pet'} is a mix, you can select multiple breeds,
          or{' '}
          <Button
            variant="plain-text"
            onClick={handleAddUnknownBreed}
            className={styles.breedButton}
          >
            {unknownBreed.name.toLowerCase()}
          </Button>
          .
        </Text>
      </Fade>
    </>
  );
}
