import { UserFeatureKeys } from '../../../../../../../app/lib/Split';
import { useExperimentContext } from '../../../../../../../experiments';
import { useGetEligibleDiscounts } from '../../../../../../network';

/**
 * Determines whether a user is eligible for free first treats.
 */
export function useFreeFirstTreatEnabled(): boolean {
  const { useFeature } = useExperimentContext();

  const request = useGetEligibleDiscounts();
  const isEligibleForFreeFirstTreats =
    request?.data?.customer?.getEligibleDiscounts?.freeFirstTreats?.eligible;

  const isFreeFirstTreatDiscountAvailable =
    useFeature?.(
      UserFeatureKeys.ssp_free_first_treat_enabled,
      isEligibleForFreeFirstTreats === null ||
        isEligibleForFreeFirstTreats === undefined
        ? {}
        : {
            attributes: {
              isEligible: isEligibleForFreeFirstTreats,
            },
          }
    )?.treatment === 'on';

  if (request.loading || request.error || !isEligibleForFreeFirstTreats) {
    return false;
  }

  return isFreeFirstTreatDiscountAvailable && isEligibleForFreeFirstTreats;
}
