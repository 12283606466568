// Criteria definitions
const FREQUENCY_CAP = 24;
const REGULAR_ORDERS_DELIVERED_COUNT_REQUIRED = 1;

export interface EligibleForOrderSizeUpCtaByCriteriaProps {
  criteria: {
    frequency: number;
    regularOrdersDeliveredCount: number;
    nextOrderIsUnlocked: boolean;
    signedUpWithFrequencyCapDiscountCode: boolean;
  };
}

/**
 * Determines whether a user should see the order size up nudge based on provided criteria.
 */
export function isEligibleForOrderSizeUpCtaByCriteria({
  criteria,
}: EligibleForOrderSizeUpCtaByCriteriaProps): boolean {
  const criteriaChecks = [
    criteria.signedUpWithFrequencyCapDiscountCode,
    criteria.frequency <= FREQUENCY_CAP,
    criteria.regularOrdersDeliveredCount ===
      REGULAR_ORDERS_DELIVERED_COUNT_REQUIRED,
    criteria.nextOrderIsUnlocked,
  ];

  const isEligible = criteriaChecks.every(Boolean);

  return isEligible;
}
