import { useEffect, useState } from 'react';

export type UseScenes<SceneName = string> = {
  /** Change the active scene. */
  setActiveScene: (scene: SceneName) => void;
  /** The list of the used scenes. */
  scenes: SceneName[];
  /** The current active scene. */
  activeScene: SceneName;
  /** The index of the current active scene. */
  activeIndex: number;
  /** The name of the next scene in the workflow. */
  nextScene?: SceneName;
  /** The name of the previous scene in the workflow. */
  previousScene?: SceneName;
  /** Whether the active scene is the first scene. */
  isFirstScene: boolean;
  /** Whether the active scene is the last scene. */
  isLastScene: boolean;
};

/**
 * Use this hook to manage a scene-based workflow.
 *
 * A scene based workflow consists in multiple scenes that should appear
 * progressively in the user interface.
 */
export function useScenes<SceneName = string>(
  scenes: SceneName[]
): UseScenes<SceneName> {
  const [activeScene, setActive] = useState(scenes[0]);
  const activeIndex = scenes.indexOf(activeScene);
  const setActiveScene = (scene: SceneName) => setActive(scene);
  const isFirstScene = activeIndex === 0;
  const isLastScene = activeIndex === scenes.length - 1;
  const nextScene = scenes[activeIndex + 1];
  const previousScene = scenes[activeIndex - 1];

  useEffect(() => {
    setActive(scenes[0]);
  }, [scenes]);

  return {
    scenes,
    setActiveScene,
    activeScene,
    activeIndex,
    previousScene,
    nextScene,
    isFirstScene,
    isLastScene,
  };
}
