import { getCacheBuster } from '../../utils';
import {
  claritas,
  IHeartRadioEventId,
  IHeartRadioEventName,
  I_HEART_RADIO_PIXEL_ID,
} from '../claritas';

/**
 * Track an iHeartRadio checkout conversion
 */
export function trackIHeartRadioCheckoutConversion(): void {
  const ord = getCacheBuster();
  claritas.track({
    ord,
    ppt: I_HEART_RADIO_PIXEL_ID,
    g: IHeartRadioEventName.CHECKOUT_SUCCESS,
    gid: IHeartRadioEventId.CHECKOUT_SUCCESS,
  });
}
