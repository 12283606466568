import PropTypes from 'prop-types';

import { Text } from '@farmersdog/corgi-x';
import Flex from 'src/components/_depreciated/Flex';

import StarIcon from 'src/assets/icons/star.svg';
import StarCheckIcon from 'src/assets/icons/star-check.svg';

import './Testimonial.scss';

const NUM_STARS = 5;

function Testimonial({ fullName, quote, pets }) {
  return (
    <div className="Testimonial">
      <Flex aria-label={`Rating ${NUM_STARS} out of 5`} role="img">
        {[...new Array(NUM_STARS)].map((_, i) => (
          <StarIcon
            aria-hidden
            key={`${fullName}-star-${i}`}
            className="Testimonial-star"
          />
        ))}
      </Flex>
      <cite style={{ margin: 0, padding: 0, fontStyle: 'normal' }}>
        <Text
          as="h3"
          variant="heading-22"
          color="kale-3"
          className="Testimonial-text"
          bold
        >
          <small>{fullName}</small>
        </Text>
        <StarCheckIcon className="Testimonial-starcheck" aria-hidden />
      </cite>
      <Text
        as="p"
        variant="heading-16"
        color="charcoal-3"
        style={{ marginTop: 4, marginBottom: 0 }}
      >
        {pets
          .slice(0, 2)
          .map(pet => `${pet.name} (${pet.age} year old ${pet.breed})`)
          .join(', ')}
      </Text>
      <Text
        variant="heading-16"
        as="blockquote"
        color="charcoal-3"
        style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
      >
        {quote}
      </Text>
    </div>
  );
}

Testimonial.propTypes = {
  quote: PropTypes.string,
  fullName: PropTypes.string,
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      age: PropTypes.number,
      breed: PropTypes.string,
      issues: PropTypes.arrayOf(PropTypes.string),
      pickyEater: PropTypes.bool,
    })
  ),
};

export default Testimonial;
