import { Grid, GridItem, Nowrap, Text } from '@farmersdog/corgi-x';

import { Section } from '../Section';

import styles from './WeightLoss.module.css';
import { WeightLossItem } from './WeightLossItem';
import { weightLossItems } from './weightLossItems';

interface WeightLossProps {
  className?: string;
  petName: string;
  bodyCondition: string;
  petGender: string;
}

export function WeightLoss({
  className,
  petName,
  bodyCondition,
  petGender,
}: WeightLossProps) {
  const items = weightLossItems({ bodyCondition, petGender });

  return (
    <Section backgroundColor="gray">
      <Grid className={className} rowGap={{ xs: 'md', lg: 'xl' }}>
        <GridItem flexDirection="column" alignItems="flex-start">
          <Text
            className={styles.title}
            as="h3"
            variant="heading-12"
            color="charcoal-3"
            bold
          >
            Weight loss
          </Text>
          <Text
            className={styles.subtitle}
            as="h2"
            variant="heading-40"
            color="kale-3"
            topSpacing="xs"
            bold
          >
            Ideal Weight, <Nowrap>Made Easy</Nowrap>
          </Text>
          <Text
            className={styles.description}
            topSpacing="sm"
            bottomSpacing="none"
            as="p"
            variant="article-16"
          >
            Here’s how our weight loss plan will work for {petName}:
          </Text>
        </GridItem>
        <GridItem
          className={styles.itemsContainer}
          flexDirection="column"
          alignItems="flex-start"
        >
          {items.map(({ title, description, src }, i) => (
            <WeightLossItem
              key={title}
              title={title}
              description={description}
              src={src}
              isReverse={i % 2 !== 0}
            />
          ))}
        </GridItem>
      </Grid>
    </Section>
  );
}
