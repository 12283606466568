import { useEffect, useState } from 'react';
import { ContentLoader, Form, Page, Progress } from '@farmersdog/corgi-x';
import {
  AddDogFormSteps,
  AddDogState,
  useAddDogMachine,
} from './state-machine';

import {
  NameScene,
  EssentialScene,
  BodyConditionScene,
  WeightScene,
  ActivityLevelScene,
  RecipesScene,
  ReviewScene,
  SuccessScene,
  HealthIssueScene,
  EatingStyleScene,
} from './components';
import { useHistory } from 'react-router';
import { useFetchAddDogFormData, useAddPetMutation } from './network';
import { useAddDogForm, useAddDogStepProgress } from './hooks';
import { PetDietIssueScene } from './components/PetDietIssueScene';
import { reporter } from '../../../errors/services';
import { PAW_PATH_MY_DOGS } from '@farmersdog/constants/paths';
import {
  trackFormSuccess,
  trackOnClose,
  trackOnSubmit,
  trackPetDetailsComplete,
  trackRecipeComplete,
  trackOnSelectRecipes,
} from './analytics';
import { arraysContainSameValues } from './utils/arraysContainSameValues';
import styles from './AddDogForm.module.css';
import classNames from 'classnames';
import { isWithinLast24Hours } from '../../../my-dogs';

export const AddDogForm = () => {
  const history = useHistory();
  const machine = useAddDogMachine();

  const currentState = machine.state.value as AddDogState;

  const [showSubmissionErrorToast, setShowSubmissionErrorToast] =
    useState(false);

  const [isRecommendedPlan, setIsRecommendedPlan] = useState(false);

  const {
    loading,
    error,
    breeds,
    availableRecipes,
    preSelectedRecipes,
    pendingPet,
  } = useFetchAddDogFormData();

  const { currentStep, stepProgress, totalSteps } = useAddDogStepProgress({
    formSteps: AddDogFormSteps,
    currentState,
  });

  const { formValue, setPetField } = useAddDogForm({ preSelectedRecipes });

  const { submit, isSubmitting } = useAddPetMutation({
    onCompleted: () => {
      trackFormSuccess();
      machine.onNext();
    },
    onError: (err: Error) => {
      reporter.error('Error submitting add pet mutation', { sourceError: err });
      setShowSubmissionErrorToast(true);
    },
  });

  const hideBackButton = Boolean(
    machine.isState(AddDogState.Name) || machine.isState(AddDogState.Success)
  );

  const hideCloseButton = machine.isState(AddDogState.Recipes);

  const hideProgressBar = machine.isState(AddDogState.Success);

  useEffect(() => {
    const currentPath = history.location.pathname;
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.get('step') !== currentState) {
      searchParams.set('step', currentState);
      history.replace(`${currentPath}?${searchParams.toString()}`);
    }

    const selectedRecipesMatchRecommendation = arraysContainSameValues({
      arr1: preSelectedRecipes ?? [],
      arr2: formValue.recipes,
    });

    setIsRecommendedPlan(selectedRecipesMatchRecommendation);
  }, [
    currentState,
    history,
    isRecommendedPlan,
    preSelectedRecipes,
    formValue.recipes,
  ]);

  if (error) {
    throw error;
  }

  const handleBackClick = () => {
    // Handle edge case where user can navigate back from the recipes screen with no recipes selected.
    if (
      machine.isState(AddDogState.Recipes) &&
      formValue.recipes.length === 0
    ) {
      return;
    }
    machine.onPrevious();
  };

  const handleOnClose = () => {
    trackOnClose({ currentStep: currentState });
    machine.onClose();
    history.push(PAW_PATH_MY_DOGS);
  };

  const handleContinueClick = () => {
    if (currentState === AddDogState.PetDietIssues) {
      trackPetDetailsComplete({
        name: formValue.name,
        ageNumber: formValue.ageNumber,
        ageUnit: formValue.ageUnit,
        sex: formValue.sex,
        breed: formValue.breed,
        sterilization: formValue.sterilization,
        bodyCondition: formValue.bodyCondition,
        currentWeight: formValue.currentWeight,
        targetWeight: formValue.targetWeight,
        activityLevel: formValue.activityLevel,
        eatingStyle: formValue.eatingStyle,
        healthIssues: formValue.healthIssues ?? undefined,
        petDietIssues: formValue.petDietIssues ?? undefined,
      });
    }

    if (currentState === AddDogState.Recipes) {
      trackRecipeComplete({ recipes: formValue.recipes });
    }

    machine.onNext();
  };

  const handleSelectRecipes = () => {
    trackOnSelectRecipes();
    machine.onSelectRecipes();
  };

  const handleOnSubmit = () => {
    submit(formValue);
    trackOnSubmit({ formValue, isRecommendedPlan });
  };

  if (machine.isState(AddDogState.CloseAddDog)) {
    return null;
  }

  //todo: Move 24hr logic to backend. https://app.shortcut.com/farmersdog/story/128591/add-24-hour-logic-to-pendingpet-resolver
  const hasPendingPet =
    pendingPet && isWithinLast24Hours(pendingPet?.createdAt);

  return (
    <ContentLoader loading={loading} height="100vh" color="carrot">
      <Page
        layout="full-width"
        onCloseClick={hideCloseButton ? undefined : handleOnClose}
        onBackClick={hideBackButton ? undefined : handleBackClick}
        className={classNames({
          [styles.success]: machine.isState(AddDogState.Success),
        })}
      >
        {!hideProgressBar && (
          <Progress
            id="add-dog"
            activeStep={currentStep}
            role="progressbar"
            steps={totalSteps}
            stepProgress={stepProgress}
            title="add-dog"
          />
        )}
        <Form data-testid="add-dog-form">
          {machine.isState(AddDogState.Name) && (
            <NameScene
              onCtaClick={handleContinueClick}
              name={formValue.name}
              onChange={setPetField}
              hasPendingPet={hasPendingPet}
            />
          )}
          {machine.isState(AddDogState.Essential) && (
            <EssentialScene
              name={formValue.name}
              ageNumber={formValue.ageNumber}
              ageUnit={formValue.ageUnit}
              sex={formValue.sex}
              breed={formValue.breed}
              sterilization={formValue.sterilization}
              onCtaClick={handleContinueClick}
              breedFormOptions={breeds}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.BodyCondition) && (
            <BodyConditionScene
              name={formValue.name}
              bodyCondition={formValue.bodyCondition}
              onCtaClick={handleContinueClick}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.Weight) && (
            <WeightScene
              onCtaClick={handleContinueClick}
              onChange={setPetField}
              name={formValue.name}
              currentWeight={formValue.currentWeight}
              targetWeight={formValue.targetWeight}
              bodyCondition={formValue.bodyCondition}
            />
          )}
          {machine.isState(AddDogState.ActivityLevel) && (
            <ActivityLevelScene
              name={formValue?.name}
              activityLevel={formValue?.activityLevel}
              onCtaClick={handleContinueClick}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.EatingStyle) && (
            <EatingStyleScene
              name={formValue.name}
              eatingStyle={formValue.eatingStyle}
              onCtaClick={handleContinueClick}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.HealthIssues) && (
            <HealthIssueScene
              name={formValue.name}
              value={formValue.healthIssues}
              onCtaClick={handleContinueClick}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.PetDietIssues) && (
            <PetDietIssueScene
              name={formValue.name}
              value={formValue.petDietIssues}
              onCtaClick={handleContinueClick}
              onChange={setPetField}
            />
          )}
          {machine.isState(AddDogState.Recipes) && (
            <RecipesScene
              name={formValue.name}
              availableRecipes={availableRecipes}
              selectedRecipes={formValue.recipes}
              preSelectedRecipes={preSelectedRecipes}
              onChange={setPetField}
              onCtaClick={handleContinueClick}
            />
          )}
          {machine.isState(AddDogState.Review) && (
            <ReviewScene
              onCtaClick={handleOnSubmit}
              isSubmitting={isSubmitting}
              formValue={formValue}
              availableRecipes={availableRecipes}
              onSelectedRecipesClick={handleSelectRecipes}
              isRecommendedPlan={isRecommendedPlan}
              showSubmissionErrorToast={showSubmissionErrorToast}
              onSubmissionErrorToastClose={() => {
                setShowSubmissionErrorToast(false);
              }}
            />
          )}
          {machine.isState(AddDogState.Success) && (
            <SuccessScene name={formValue.name} onCtaClick={handleOnClose} />
          )}
        </Form>
      </Page>
    </ContentLoader>
  );
};
