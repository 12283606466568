/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import { LogoProps } from './Logos.types';

import FastCompanyPng from 'src/assets/images/logos/fast-company.png';
import FastCompanyWebP from 'src/assets/images/logos/fast-company.webp';

export function FastCompanyLogo(props: LogoProps) {
  return (
    <Picture
      sources={[FastCompanyWebP, FastCompanyPng]}
      alt="Fast Company Logo"
      {...props}
    />
  );
}
