import { LightboxAction, LightboxActionType } from './actions';
import { GlobalLightboxState } from './context';
import {
  getIsAnotherLightboxOpen,
  closeAllLightboxes,
  getIsLightboxCurrentlySuppressed,
} from './helpers';

export function lightboxReducer(
  state: GlobalLightboxState,
  action: LightboxAction
) {
  switch (action.type) {
    case LightboxActionType.Show: {
      const { id, forceOpen } = action.payload;
      const isAnotherLightboxOpen = getIsAnotherLightboxOpen(state);
      const isLightboxCurrentlySuppressed = getIsLightboxCurrentlySuppressed(
        state,
        id
      );

      if (forceOpen && !isLightboxCurrentlySuppressed) {
        return {
          ...state,
          lightboxes: {
            ...closeAllLightboxes(state),
            [id]: {
              ...state.lightboxes[id],
              isOpen: true,
            },
          },
        };
      }

      return {
        ...state,
        lightboxes: {
          ...state.lightboxes,
          [id]: {
            ...state.lightboxes[id],
            isOpen: Boolean(
              isAnotherLightboxOpen && !isLightboxCurrentlySuppressed
            ),
          },
        },
      };
    }

    case LightboxActionType.Hide: {
      const { id } = action.payload;

      return {
        ...state,
        lightboxes: {
          ...state.lightboxes,
          [id]: {
            ...state.lightboxes[id],
            isOpen: false,
          },
        },
      };
    }

    case LightboxActionType.HideAll: {
      return {
        ...state,
        lightboxes: closeAllLightboxes(state),
      };
    }

    case LightboxActionType.Suppress: {
      const { id, suppressed } = action.payload;

      return {
        ...state,
        lightboxes: {
          [id]: {
            ...state.lightboxes[id],
            suppressed,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
