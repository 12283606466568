import { BranchNode } from '../blueprint/types';

export const getFinalFormStepByRoute = (
  formSteps: BranchNode[],
  route: string
): string | undefined => {
  // Spread the array to avoid mutating the original
  const deepCopiedFormSteps = [...formSteps];
  return deepCopiedFormSteps.reverse().find(formStep => {
    return formStep.route === route;
  })?.name;
};
