import styles from './ProgressContainer.module.css';
import { ProgressItem } from './ProgressItem';
import { progressItems } from './progressItems';

export function ProgressContainer() {
  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {progressItems.map(({ title, subtitle, descriptionItems, number }) => (
          <ProgressItem
            key={title}
            title={title}
            subtitle={subtitle}
            descriptionItems={descriptionItems}
            number={number}
          />
        ))}
      </div>
    </div>
  );
}
