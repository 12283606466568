import { Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import { useCorgiViewport, CorgiViewport } from 'src/screen';

import FiveStars from '../FiveStars';
import CallToAction from '../CallToAction';

import VetsSlider from './VetsSlider';
import VetsWide from './VetsWide';
import styles from './Veterinarians.module.scss';
import VETS_DATA from './vetsData';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Veterinarians() {
  const { isAccessibilityThemeOn, KaleBackgroundWrapper } =
    useAccessibilityThemeContext();

  const dyanmicClassNames = useCorgiViewport(styles);
  const sectionContent = (
    <>
      <FiveStars color={isAccessibilityThemeOn ? 'kale-3' : 'white'} />
      <Text
        as="h2"
        variant="heading-40"
        color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
        className={classNames(styles.heading, dyanmicClassNames)}
        bold
      >
        Veterinarian Testimonials
      </Text>
      <CorgiViewport vetsData={VETS_DATA} xs={VetsSlider} md={VetsWide} />
      <CallToAction>Get Started</CallToAction>
    </>
  );

  return isAccessibilityThemeOn ? (
    <KaleBackgroundWrapper addBackgroundPadding={false}>
      <div
        className={classNames(
          styles.accessibilityThemeContainer,
          dyanmicClassNames
        )}
      >
        {sectionContent}
      </div>
    </KaleBackgroundWrapper>
  ) : (
    <div className={classNames(styles.container, dyanmicClassNames)}>
      {sectionContent}
    </div>
  );
}

export default Veterinarians;
