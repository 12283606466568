import { Grid, GridItem } from '@farmersdog/corgi-x';
import { ClinicInformation } from '../ClinicInformation';
import styles from './SelectedClinic.module.css';
import { ClinicForm } from '../ClinicForm';
import { ClinicFormProps } from '../ClinicForm/validationForm';

export type SelectedClinicProps = {
  address: string;
  name: string;
  onSaveChanges: (values: ClinicFormProps) => void;
  loading: boolean;
};

export const SelectedClinic = ({
  address,
  name,
  onSaveChanges,
  loading,
}: SelectedClinicProps) => {
  return (
    <Grid className={styles.container}>
      <GridItem>
        <ClinicInformation address={address} name={name} />
      </GridItem>
      <GridItem className={styles.form}>
        <ClinicForm onSaveChanges={onSaveChanges} loading={loading} />
      </GridItem>
    </Grid>
  );
};
