import { useChangeFrequencyData } from '../../../../../../change-frequency/network';
import { reporter } from '../../../../../../errors/services';
import { formatCurrency } from '../../../../../../utils';

export const NO_SAVINGS_WARNING =
  'Current price is less than or the same was max frequency price';

export function useYearlySavings() {
  const { suggestedFrequencies, loading } = useChangeFrequencyData();
  if (loading) return null;

  const currentPrice =
    suggestedFrequencies?.current?.yearlyConsumptionPrice ?? 0;
  const maxFrequencyPrice =
    suggestedFrequencies?.max?.yearlyConsumptionPrice ?? 0;

  const areThereSavings = currentPrice > maxFrequencyPrice;
  if (!areThereSavings) {
    reporter.warn(NO_SAVINGS_WARNING);
    return null;
  }

  const yearlySavings = currentPrice - maxFrequencyPrice;

  return formatCurrency(yearlySavings, { displayCents: false });
}
