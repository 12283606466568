import { emails } from '@farmersdog/constants';
import { SubscriptionType } from '@farmersdog/lead-browser-storage';

import { ContactSupportLink } from './ContactSupportLink';

export function SignupSupportLink() {
  return <ContactSupportLink getEmailByProductLine={getEmailByProductLine} />;
}

function getEmailByProductLine(productLine?: SubscriptionType) {
  switch (productLine) {
    case SubscriptionType.Diy: {
      return emails.EMAIL_DIY_HELP;
    }

    default:
    case SubscriptionType.Fresh: {
      return emails.EMAIL_HELP;
    }
  }
}
