/**
 * Encode a pet name to a valid URI string
 *
 * @example
 * this.props.history.push(`/signup/pets?pet=${encodePetUri(pet.name)}`)
 *
 * @param {String?} name
 * @return {String}
 */
export const encodePetUri = (name = '') =>
  encodeURIComponent(name.toLowerCase());

/**
 * Decode a pet name from a URI to a human readable lower case string
 *
 * @example
 * const petName = decodePetUri(
 *   parseQueryString(this.props.location.search),
 *   'pet'
 * );
 *
 * @param {String} petURI
 * @return {String}
 */
export const decodePetUri = uri =>
  typeof uri === 'string' ? decodeURIComponent(uri).toLowerCase() : uri;

/**
 * Compare if two pet names are the same.
 * Account for uri encoding and ignore case sensitivity.
 * Does not account for double encoding.
 *
 * @example
 * const isSame = comparePetUri(
 *   currentPet.name,
 *   parseQueryString(this.props.location.search),
 *   'pet'
 * );
 *
 * @param {String} name
 * @param {String} uri
 * @return {Boolean}
 */
export const comparePetUri = (name, uri) =>
  decodePetUri(name) === decodePetUri(uri);
