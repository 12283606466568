import { Logger } from '@farmersdog/logger';
import type { TurnstileProps as TurnstileLibraryProps } from '@marsidev/react-turnstile';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import classNames from 'classnames';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  trackLoginChallengeSuccess,
  trackLoginEnterInteractiveChallengeMode,
  trackLoginLeaveInteractiveChallengeMode,
} from 'src/analytics/events/';
import styles from './TurnstileWidget.module.css';
import { getCaptchaResponseFromErrorCode, getTurnstileSiteKey } from './utils/';

export type CaptchaResponse = {
  message?: string;
  pass: boolean;
};

enum ActionType {
  LOGIN = 'login',
}

enum AppearanceType {
  INTERACTION_ONLY = 'interaction-only',
}

enum WidgetTheme {
  LIGHT = 'light',
}

export enum TurnstileState {
  EXPIRED = 'expired',
  FAILED = 'failed',
  PASSED = 'passed',
  UNKNOWN = 'unknown',
}

export type TurnstileProps = {
  setCaptchaError: ((error?: string) => boolean) | (() => void);
  setCaptchaState: (state: TurnstileState) => void;
  setCaptchaToken: (token: string) => void;
  handleExpiredToken: () => void;
};

/**
 * Render the Turnstile library component
 */
export const TurnstileWidget = forwardRef<TurnstileInstance, TurnstileProps>(
  (
    { setCaptchaError, setCaptchaState, setCaptchaToken, handleExpiredToken },
    ref
  ) => {
    const turnstileInstanceRef = ref as MutableRefObject<TurnstileInstance>;
    const logger = useMemo(
      () => new Logger('website:login:TurnstileWidget'),
      []
    );
    const SITE_KEY = getTurnstileSiteKey();
    const MAX_ATTEMPTS = 3;
    const DEFAULT_RENDER_OPTIONS: TurnstileLibraryProps['options'] = {
      appearance: AppearanceType.INTERACTION_ONLY,
      action: ActionType.LOGIN,
      theme: WidgetTheme.LIGHT,
    };
    const [isInteractiveChallenge, setIsInteractiveChallenge] = useState(false);
    const attemptCount = useRef(1);

    const handleEnterInteractiveChallengeMode = () => {
      trackLoginEnterInteractiveChallengeMode();
      setIsInteractiveChallenge(true);
    };
    const handleLeaveInteractiveChallengeMode = () => {
      trackLoginLeaveInteractiveChallengeMode();
    };

    const handleSuccess = (token: string) => {
      setCaptchaToken(token);
      setCaptchaState(TurnstileState.PASSED);
      setCaptchaError();
      trackLoginChallengeSuccess();
    };

    const handleError = (code: string = '') => {
      const { message, retry, reset } = getCaptchaResponseFromErrorCode(code);
      if (reset) {
        turnstileInstanceRef?.current?.reset();
        return;
      }

      if (retry && attemptCount.current < MAX_ATTEMPTS) {
        attemptCount.current = (attemptCount.current ?? 1) + 1;
        turnstileInstanceRef?.current?.reset();
        return;
      }

      setCaptchaState(TurnstileState.FAILED);
      setCaptchaError(message);
      logger.error('Failed captcha challenge', {
        code,
        message,
      });
    };
    useEffect(() => {
      if (!SITE_KEY || SITE_KEY.length === 0) {
        setCaptchaToken('');
        setCaptchaState(TurnstileState.UNKNOWN);
        setCaptchaError();
        logger.error('Invalid turnstile site key');
        return;
      }
    }, [setCaptchaToken, setCaptchaState, setCaptchaError, SITE_KEY, logger]);
    return (
      <Turnstile
        className={
          isInteractiveChallenge
            ? classNames(styles.turnstileWidget, styles.interactive)
            : styles.turnstileWidget
        }
        data-testid="turnstile-library-component"
        options={DEFAULT_RENDER_OPTIONS}
        ref={turnstileInstanceRef}
        siteKey={SITE_KEY}
        onAfterInteractive={handleLeaveInteractiveChallengeMode}
        onBeforeInteractive={handleEnterInteractiveChallengeMode}
        onSuccess={handleSuccess}
        onError={handleError}
        onExpire={handleExpiredToken}
      />
    );
  }
);
