export const CUSTOM_PLACEHOLDER = '%custom%';

interface HandleCustomPlaceHolderProps {
  input: string;
  replacement: string | string[];
}

/** if replacement is a string, replace all placeholders with the same string;
 * if replacement is an array of string, replace each placeholder with the replacement in the corresponding position
 */
export function handleCustomPlaceholders({
  input,
  replacement,
}: HandleCustomPlaceHolderProps) {
  const regex = new RegExp(CUSTOM_PLACEHOLDER, 'g');

  if (!Array.isArray(replacement)) {
    return input.replace(regex, replacement);
  }

  const replacements = [...replacement];
  return input.replace(regex, () => {
    return replacements.shift() ?? CUSTOM_PLACEHOLDER;
  });
}
