import { formatAge } from './formatAge';
import { formatBreeds } from './formatBreeds';

export interface PetDescriptionInput {
  weightInPounds?: number;
  breeds?: string[];
  ageInWeeks?: number;
}

/**
 * Formatter to format a pet's profile description
 * based on the values that are passed in
 */
export function formatPetDescription({
  weightInPounds,
  breeds,
  ageInWeeks,
}: PetDescriptionInput) {
  const descriptors = [];

  if (ageInWeeks) {
    descriptors.push(formatAge(ageInWeeks) + ',');
  }

  if (weightInPounds) {
    descriptors.push(`${String(weightInPounds)}lb,`);
  }

  if (breeds) {
    descriptors.push(formatBreeds(breeds));
  } else {
    descriptors.push('dog');
  }

  return descriptors.join(' ');
}
