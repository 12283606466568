import { mountVendorWindowEvent } from '../utils';

export enum PostieEvents {
  TrackPageView = 'trackPageView',
  AddTransaction = 'addTrans',
  TrackTransaction = 'trackTrans',
  TrackUnstructEvent = 'trackUnstructEvent',
}

export enum PostieSchemas {
  Address = 'iglu:com.getletterpress/address/jsonschema/1-0-0',
  Email = 'iglu:com.getletterpress/email/jsonschema/1-0-0',
}

export type PostieSdk = (
  eventName: PostieEvents,
  ...properties: unknown[]
) => void;

interface MountPostieEventParams {
  eventName: PostieEvents;
  properties?: Record<string, unknown>;
  shouldSpreadArguments?: boolean;
}

export function _mountPostieEvents(
  window: Window,
  {
    eventName,
    properties = {},
    shouldSpreadArguments = false,
  }: MountPostieEventParams
) {
  const sdk = window.letterpress;
  if (shouldSpreadArguments) {
    sdk?.(eventName, ...Object.values(properties));
  } else {
    sdk?.(eventName, properties);
  }
}

export const mountPostieEvent = mountVendorWindowEvent(
  'Postie',
  _mountPostieEvents
);
