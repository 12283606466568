import { ForwardedRef } from 'react';

import { Text } from '@farmersdog/corgi-x';

import type { BreedType } from '../../../types';
import type { UseComboboxPropGetters } from 'downshift';

interface SearchableListProps {
  items: BreedType[];
  firstItemRef: ForwardedRef<HTMLDivElement>;
  getItemProps: UseComboboxPropGetters<BreedType>['getItemProps'];
}

export function SearchableList({
  items,
  getItemProps,
  firstItemRef,
}: SearchableListProps) {
  return (
    <>
      {items.length > 0 &&
        items.map((item, index) => {
          return (
            <div
              key={itemToString(item)}
              {...(index === 0 && { ref: firstItemRef })}
            >
              <Text
                as="li"
                variant="heading-22"
                {...getItemProps({
                  key: itemToString(item),
                  item,
                })}
              >
                {itemToString(item)}
              </Text>
            </div>
          );
        })}
    </>
  );
}

function itemToString(item: BreedType | null): string {
  if (!item) {
    return '';
  }

  return item.label;
}
