import { FC } from 'react';
import { Script } from 'src/components/Script';

export const PostieScript: FC = () => {
  return (
    <Script>
      {`(function(z,i,p,c,o,d,e){z[c]||(z[c]=function(){(z[c].q=z[c].q||[]).
        push(arguments)},z[c].q=z[c].q||[],d=i.createElement(p),d.async=1,d.
        src=o,e=i.getElementsByTagName(p)[0],e.parentNode.insertBefore(d,e))
        })(window,document,"script","letterpress","//scripts.postie.com/sprakbzd/lp.1.js");`}
    </Script>
  );
};
