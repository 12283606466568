import { log } from '.';

export function logSplitClientMismatches(
  cachedSplitClients: Record<string, boolean>
) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const digitsOnlyRegex = /^\d+$/;

  const anonymousIdSplitClients = Object.keys(cachedSplitClients).filter(key =>
    uuidRegex.test(key)
  );

  const userIdSplitClients = Object.keys(cachedSplitClients).filter(key =>
    digitsOnlyRegex.test(key)
  );

  if (anonymousIdSplitClients.length > 1) {
    log.warn('Found multiple anonymous id split clients', {
      anonymousIdSplitClients,
      userIdSplitClients,
    });

    return;
  }

  if (userIdSplitClients.length > 1) {
    log.warn('Found multiple user id split clients', {
      anonymousIdSplitClients,
      userIdSplitClients,
    });
  }
}
