import { UserFeatureKeys } from '../../../../../../app/lib/Split';
import { reporter } from '../../../../../../errors/services';
import { useExperimentContext } from '../../../../../../experiments';
import {
  useFetchHomepageData,
  usePaymentMethodChangedForOrder,
} from '../../../../../../homepage/network';
import { SlotEligibility } from '../../../../../../slots';

/**
 * Checks if the user is eligible to see the Payment Failed Hero
 */
export function useEligibility(): SlotEligibility {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving homepage data', { sourceError: error });
    },
  });

  const { useFeature } = useExperimentContext();
  const isPaymentFailed =
    request.data?.customer.nextOrderToBeDelivered?.paymentStatus === 'failed';

  const order = request.data?.customer.nextOrderToBeDelivered;

  if (!order) {
    if (!request.loading) {
      reporter.warn('Next order to be delivered not found');
    }
  }

  const {
    paymentMethodChangedForOrder,
    loading: paymentMethodChangedForOrderLoading,
    error: paymentMethodChangedForOrderError,
  } = usePaymentMethodChangedForOrder({
    orderId: order?.id,
  });

  const isPreGracePeriodEnabled =
    useFeature?.(UserFeatureKeys.adj_pre_grace_period, {}).treatment === 'on';

  const isReady =
    !request.loading && !request.error && !paymentMethodChangedForOrderLoading;

  if (!isReady) {
    return {
      isReady,
      isCandidate: false,
    };
  }

  const isCandidate =
    isPreGracePeriodEnabled &&
    isReady &&
    isPaymentFailed &&
    !paymentMethodChangedForOrder &&
    !paymentMethodChangedForOrderError;

  return {
    isReady,
    isCandidate,
  };
}
