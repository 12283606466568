export enum ReferrerType {
  Partner = 'partner',
  User = 'user',
}

export enum DiscountType {
  fixed = 'fixed',
  percentage = 'percentage',
}

export interface DiscountTypeAndAmount {
  discountAmount: number;
  discountType: DiscountType;
}
