import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserShippingAddress } from 'src/reducers/signup/user';

export interface UseShippingAddressReturn {
  country?: Reducer.AddressCountry['iso'];
  addressLine1?: Reducer.Address['addressLine1'];
  city?: Reducer.Address['city'];
  stateAbbr?: Reducer.Address['state']['abbr'];
  zipCode?: Reducer.Address['zip'];
}

export function useShippingAddress(): UseShippingAddressReturn {
  const shippingAddress = useSelector(selectUserShippingAddress);
  return useMemo(() => {
    return {
      country: shippingAddress.country?.iso,
      addressLine1: shippingAddress.addressLine1,
      city: shippingAddress.city,
      stateAbbr: shippingAddress.state?.abbr,
      zipCode: shippingAddress.zip,
    };
  }, [shippingAddress]);
}
