import classNames from 'classnames';
import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { useCorgiV2Viewport } from 'src/screen';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import styles from './CTA.module.scss';
import { getLinkToDiySignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';

export function CTA() {
  const responsiveClassName = useCorgiV2Viewport<string>({
    lg: styles.lg,
  });

  return (
    <div className={classNames(responsiveClassName, styles.container)}>
      <Text variant="heading-22" as="h4" color="kale-3" bold>
        See more homemade dog food recipe options just right for your dog
      </Text>
      <Link to={getLinkToDiySignup(PATH_SIGNUP_ME)} asButton>
        Build Your DIY Plan
      </Link>
    </div>
  );
}
