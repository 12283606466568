import {
  SubscriptionType,
  UpdateDiySignupSubscriptionSubscriptionsFragment,
} from 'src/graphql/types';

type InputSubscription =
  | UpdateDiySignupSubscriptionSubscriptionsFragment
  | Reducer.Subscription;
export interface GetSubscriptionByTypeArgs {
  subscriptions: InputSubscription[] | undefined;
  type: SubscriptionType;
}

export function getSubscriptionByType({
  subscriptions,
  type,
}: GetSubscriptionByTypeArgs) {
  if (!subscriptions) {
    return;
  }

  return subscriptions.find(subscription => subscription.type === type);
}
