import { Helmet } from 'react-helmet-async';

import { useRouteMatchedTitle } from './hooks/useRouteMatchedTitle';

export function DynamicPageTitle() {
  const routeMatchedTitle = useRouteMatchedTitle();

  return routeMatchedTitle ? (
    <Helmet>
      <title>{routeMatchedTitle}</title>
    </Helmet>
  ) : null;
}
