import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './HeadlineList.module.scss';

export default function HeadlineList({
  children,
  align = 'center',
  columns = 2,
}) {
  const rows = Math.ceil(Children.count(children) / columns);

  return (
    <div className={styles.container}>
      {[...new Array(rows)].map((_, rowIndex) => {
        const startIndex = rowIndex * columns;

        return (
          <div
            key={`row-${rowIndex}`}
            className={classNames(styles.row, styles[align])}
          >
            {Children.toArray(children)
              .slice(startIndex, startIndex + columns)
              .map(child =>
                cloneElement(child, {
                  className: classNames(styles.item, child.props.className),
                })
              )}
          </div>
        );
      })}
    </div>
  );
}

HeadlineList.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'center']),
  columns: PropTypes.number,
};
