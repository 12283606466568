import { LG_VALUE } from '@farmersdog/corgi/constants';

import { PreloadLink, PreloadLinkAs, PreloadLinkType } from '../PreloadLink';

import { turkeyBowls, kibbleBowls, diggingDogs } from './assets';

export function AssetPreloader() {
  const [, turkeyBowlJp2, turkeyBowlPng] = turkeyBowls;
  const [, kibbleBowlJp2, kibbleBowlPng] = kibbleBowls;
  const [diggingDogSm, diggingDogMd] = diggingDogs;

  return (
    <>
      <PreloadLink
        href={turkeyBowlJp2}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Jpg}
      />
      <PreloadLink
        href={turkeyBowlPng}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Png}
      />
      <PreloadLink
        href={kibbleBowlJp2}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Jpg}
      />
      <PreloadLink
        href={kibbleBowlPng}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Png}
      />
      <PreloadLink
        href={diggingDogSm}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Gif}
        media={`max-width: (${LG_VALUE - 1}px)`}
      />
      <PreloadLink
        href={diggingDogMd}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Gif}
        media={`min-width: (${LG_VALUE}px)`}
      />
    </>
  );
}
