import { local } from '@farmersdog/utils';

export const TREATS_STATE_KEY = 'TREATS_SIGN_UP_STATE';

export interface SignupTreatsState {
  id: number | null;
  sku: string;
}

function get(): string | null {
  return local.getItem(TREATS_STATE_KEY);
}

function set(treats: SignupTreatsState): void {
  local.setItem(TREATS_STATE_KEY, JSON.stringify(treats));
}

function remove(): void {
  local.removeItem(TREATS_STATE_KEY);
}

export const signupTreatsState = {
  get,
  set,
  remove,
};
