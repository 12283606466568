import { getSharedISpotParams } from './getSharedISpotParams';
import {
  iSpot,
  ISpotEventName,
  PremionEventName,
  Premion,
  PREMION_REF_ID,
} from './iSpot';

/**
 * Mount the iSpot leads conversion event
 */

export function mountISpotMeConversion({ utmSource }: { utmSource: unknown }) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { customData, channel, app } = getSharedISpotParams({
    utmSource,
  });

  iSpot.mount({
    app,
    type: ISpotEventName.LEADS,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });

  Premion.mount({
    app,
    type: PremionEventName.LEADS,
    refid: PREMION_REF_ID,
    url: window.location.pathname,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
