import events from '../eventNames';
import track from '../track';

export type RecipeDetailsPetCalories = {
  name: string;
  calories: number | null;
};

export type TrackDiyRecipeDetailsUpdateInput = {
  userId: number;
  initialCalories?: RecipeDetailsPetCalories[];
  newCalories?: RecipeDetailsPetCalories[];
  numberOfBatches?: number;
};

export function trackDiyRecipeDetailsUpdate({
  userId,
  initialCalories,
  newCalories,
  numberOfBatches,
}: TrackDiyRecipeDetailsUpdateInput) {
  track(events.diy_recipe_details_update, {
    userId,
    initialCalories,
    newCalories,
    numberOfBatches,
  });
}
