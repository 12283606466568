import { MedicalConditionConfig } from './MedicalConditionMessage/constants';

interface GetFirstMedicalConditionMessageArgs {
  medicalConditions: string[];
  medicalConditionsConfig: MedicalConditionConfig;
}

export function getMedicalConditionMessageFromConfig({
  medicalConditions,
  medicalConditionsConfig,
}: GetFirstMedicalConditionMessageArgs): string | undefined {
  for (let i = 0; i < medicalConditions.length; i++) {
    const medicalCondition = medicalConditions[i];
    const medicalConditionConfig =
      medicalConditionsConfig[medicalCondition.toLowerCase()];
    const warningMessage = medicalConditionConfig?.warningMessage;

    if (warningMessage) {
      return warningMessage;
    }
  }

  return undefined;
}
