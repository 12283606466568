import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

interface TrackCancelSubscriptionClickContinueCancelingProps {
  savePathway: 'mixing plan' | 'lpf';
}
/** Track clicks to continue cancellation (reject saves) within Cancel Subcription flow */
export function trackCancelSubscriptionClickContinueCanceling(
  props: TrackCancelSubscriptionClickContinueCancelingProps
) {
  track(eventNames.cancel_subscription_continue_canceling, {
    savePathway: props.savePathway,
  });
}
