import catrionaJpg from 'src/assets/images/quotes-catriona.jpg';
import catrionaWebP from 'src/assets/images/quotes-catriona.webp';
import blockJpg from 'src/assets/images/quotes-block.jpg';
import blockWebP from 'src/assets/images/quotes-block.webp';
import schechterJpg from 'src/assets/images/quotes-schechter.jpg';
import schechterWebP from 'src/assets/images/quotes-schechter.webp';

const VETS_DATA = [
  {
    name: 'Dr. Catriona Love',
    quote:
      'One of the most exciting innovations in pet food, The Farmer’s Dog is a safe alternative to conventional brands.',
    sources: [catrionaWebP, catrionaJpg],
  },
  {
    name: 'Dr. Alex Schechter',
    quote:
      'No animal’s digestive system is designed to eat highly-processed food every meal of their lives. The Farmer’s Dog makes it easy to feed our pets what they were meant to eat.',
    sources: [schechterWebP, schechterJpg],
  },
  {
    name: 'Dr. Jonathan Block',
    quote:
      'I recommend Farmer’s Dog to our patients looking for a more healthful pet food. My own dogs love it too!',
    sources: [blockWebP, blockJpg],
  },
];

export default VETS_DATA;
