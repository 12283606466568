import classNames from 'classnames';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import {
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import { withRef, WithRefProps, heading } from '@farmersdog/corgi';

import { useBreedDropdownImprovementsExperiment } from '../../../hooks/experiments/useBreedDropdownImprovementsExperiment';
import { BreedType, UseFeatureHook } from '../../../types';

import RemoveIcon from './assets/remove.svg';
import { ToggleButton } from './buttons/ToggleButton';
import { fuzzySearch } from './search';
import { SearchableList } from './SearchableList';
import selectComboBoxStyles from './SelectComboBox.module.css';

type RefProps = WithRefProps<HTMLInputElement>;
export type SelectComboBoxProps = RefProps & SelectBaseProps;

interface SelectBaseProps {
  selectedBreed?: BreedType;
  availableBreedsList: BreedType[];
  accessibleLabel: string;
  shouldFocusInput?: boolean;
  setShouldFocusInput: Dispatch<SetStateAction<boolean>>;
  selectedBreedPosition?: number;
  useFeature: UseFeatureHook;
  unknownBreed: BreedType | undefined;
  shouldShowClearButton: boolean;
  handleBreedSelected: (breed: BreedType) => void;
  handleBreedRemoved: () => void;
  className?: string;
}

const typography = heading.create({ size: 28 });

export const SelectComboBox = withRef<HTMLInputElement, SelectComboBoxProps>(
  (selectProps: SelectComboBoxProps) => {
    const {
      selectedBreed,
      shouldShowClearButton,
      availableBreedsList,
      shouldFocusInput,
      setShouldFocusInput,
      selectedBreedPosition,
      useFeature,
      handleBreedSelected,
      handleBreedRemoved,
      unknownBreed,
      className: inheritedClassnames,
    } = selectProps;

    const { shouldShowBreedDropdownImprovements } =
      useBreedDropdownImprovementsExperiment({
        useFeature,
      });

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const [userInput, setUserInput] = useState<string>(
      selectedBreed?.name ?? ''
    );

    const fuzzySearchResults = useMemo(() => {
      return shouldShowBreedDropdownImprovements
        ? fuzzySearch({
            items: availableBreedsList,
            inputValue: userInput,
          })
        : [];
    }, [shouldShowBreedDropdownImprovements, availableBreedsList, userInput]);

    const dropdownItems = useMemo(() => {
      if (userInput.trim().length === 0 || userInput === selectedBreed?.name) {
        return availableBreedsList;
      }

      return shouldShowBreedDropdownImprovements
        ? [
            ...fuzzySearchResults.map(({ item }) => item),
            ...(unknownBreed ? [unknownBreed] : []),
          ]
        : availableBreedsList.filter(getBreedsFilter(userInput ?? ''));
    }, [
      availableBreedsList,
      fuzzySearchResults,
      selectedBreed,
      shouldShowBreedDropdownImprovements,
      unknownBreed,
      userInput,
    ]);

    const firstListItemRef = useRef<HTMLDivElement>(null);

    const scrollToTopOfBreeds = () => {
      firstListItemRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
    };
    const onInputValueChange = ({
      inputValue,
    }: UseComboboxStateChange<BreedType>) => {
      if (shouldShowBreedDropdownImprovements) {
        scrollToTopOfBreeds();
      }
      setUserInput(inputValue ?? '');
    };

    const toggleFocus = () => {
      setIsFocused(!isFocused);
      if (shouldFocusInput) {
        setShouldFocusInput(false);
      }
    };

    const handleBlur = () => {
      toggleFocus();

      if (!selectedBreed) {
        if (shouldShowBreedDropdownImprovements) {
          setUserInput('');
        }
        return;
      }

      const lowerCasedSelectedBreed = selectedBreed.name.toLowerCase();
      if (lowerCasedSelectedBreed !== userInput.toLowerCase()) {
        onInputValueChange({
          inputValue: selectedBreed.name,
        } as UseComboboxStateChange<BreedType>);
      }
    };

    const onBreedSelect = ({
      selectedItem,
    }: UseComboboxStateChange<BreedType>) => {
      if (selectedItem) {
        handleBreedSelected(selectedItem);
      }
    };

    useEffect(() => {
      setUserInput(selectedBreed ? selectedBreed.name : '');
    }, [selectedBreed]);

    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getItemProps,
      setHighlightedIndex,
    } = useCombobox<BreedType>({
      items: dropdownItems,
      onInputValueChange,
      onSelectedItemChange: onBreedSelect,
    });

    useEffect(() => {
      if (shouldShowBreedDropdownImprovements) {
        const indexOfExactMatch = fuzzySearchResults.findIndex(
          ({ isExactMatch }) => isExactMatch
        );

        if (indexOfExactMatch !== -1) {
          setHighlightedIndex(indexOfExactMatch);
        }
      }
    }, [
      shouldShowBreedDropdownImprovements,
      fuzzySearchResults,
      setHighlightedIndex,
    ]);

    const handleClickToggleButton = (e: MouseEvent) => {
      e.preventDefault();
    };

    const getUpdatedToggleButtonProps = () =>
      getToggleButtonProps({ onClick: handleClickToggleButton });

    const shouldShowBreedsList = shouldShowBreedDropdownImprovements
      ? userInput !== '' && (isOpen || !selectedBreed)
      : isOpen;

    const inputRef = useRef<HTMLInputElement>(null);
    const inputProps = getInputProps({
      ref: inputRef,
      onFocus: toggleFocus,
      onBlur: handleBlur,
      value: userInput,
    });

    // This is necessary to deal with iOS Chrome's lack of firing a React
    // synthetic 'blur' event on the focused input when the user taps the
    // 'Done' button on the keyboard.
    useEffect(() => {
      const inputElement = inputRef.current;
      inputElement?.addEventListener('blur', inputProps.onBlur);
      return () => inputElement?.removeEventListener('blur', inputProps.onBlur);
    }, [inputProps.onBlur]);

    return (
      <div
        className={classNames(
          selectComboBoxStyles.ComboBoxContainer,
          inheritedClassnames,
          {
            [selectComboBoxStyles.focused]: isFocused,
          }
        )}
        id={`selectedBreed-${(selectedBreedPosition ?? 0) + 1}`}
      >
        <label className={selectComboBoxStyles.label} {...getLabelProps()}>
          {selectProps.accessibleLabel}
        </label>
        {shouldShowClearButton && (
          <button
            aria-label="clear selection"
            type="button"
            role="button"
            onClick={handleBreedRemoved}
            className={classNames(selectComboBoxStyles['ComboBox-delete-btn'], {
              [selectComboBoxStyles['ComboBox-delete-btn-placeholder']]:
                !selectedBreed,
            })}
            title={`Remove ${selectedBreed?.name ?? 'input'}`}
          >
            <RemoveIcon viewBox="0 0 44 44" />
          </button>
        )}
        <input
          placeholder={
            shouldShowBreedDropdownImprovements
              ? 'type and select breed'
              : 'type breed'
          }
          className={classNames(
            selectComboBoxStyles.input,
            typography.className
          )}
          {...inputProps}
          autoFocus={shouldFocusInput}
        />
        {!shouldShowBreedDropdownImprovements && (
          <ToggleButton
            isOpen={isOpen}
            getToggleButtonProps={getUpdatedToggleButtonProps}
            getInputProps={getInputProps}
          />
        )}
        {/* Dropdown Menu */}
        <ul
          {...getMenuProps()}
          className={classNames(
            selectComboBoxStyles['Select-dropDown'],
            !shouldShowBreedsList && selectComboBoxStyles.hidden
          )}
        >
          {shouldShowBreedsList && (
            <SearchableList
              items={dropdownItems}
              getItemProps={getItemProps}
              firstItemRef={firstListItemRef}
            />
          )}
        </ul>
      </div>
    );
  }
);

function getBreedsFilter(inputValue: string) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return function breedsFilter(breed: BreedType) {
    return (
      !inputValue ||
      breed.name.toLowerCase().includes(lowerCasedInputValue) ||
      breed.label.toLowerCase().includes(lowerCasedInputValue) ||
      breed.value.toLowerCase().includes('unknown')
    );
  };
}
