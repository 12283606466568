import { ApolloClient } from '@apollo/client';
import { setupApolloLink } from './setupApolloLink';
import { createCache } from './createCache';

export function createClient() {
  return new ApolloClient({
    name: 'paw',
    link: setupApolloLink(),
    cache: createCache(),
  });
}
