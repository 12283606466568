import { Modal } from '@farmersdog/corgi';
import { Nowrap, Text } from '@farmersdog/corgi-x';

import { useGlobalLightbox, LightboxId } from '../../hooks';

import styles from './PriceValueModal.module.css';

export interface PriceValueModalProps {
  shouldShowKibbleBashContent?: boolean;
  shouldShowProFreshContent?: boolean;
}

export const PriceValueModal = ({
  shouldShowKibbleBashContent,
  shouldShowProFreshContent,
}: PriceValueModalProps) => {
  const { rootProps } = useGlobalLightbox({
    id: LightboxId.PriceValueModal,
  });

  return (
    // @ts-expect-error Types are incorrect
    <Modal className={styles.modal} {...rootProps}>
      <div className={styles.content}>
        <Text variant="heading-22" as="h3" bold color="white">
          {shouldShowKibbleBashContent && <>A Better Question:</>}
          {shouldShowProFreshContent && (
            <>
              Because it’s what <Nowrap>real food costs.</Nowrap>
            </>
          )}
        </Text>
        <div className={styles.description}>
          {shouldShowKibbleBashContent && (
            <Text variant="article-16" as="p" bold color="white">
              <>How can kibble cost as little as $0.10 a day?</>
            </Text>
          )}
          <Text variant="article-16" as="p" color="white">
            {shouldShowKibbleBashContent && (
              <>
                It sounds great—until you wonder how it can be possible. What
                other healthy meal could you get for under a dollar?{' '}
                <b>
                  Pet food companies can legally use ingredients that are
                  considered too low quality
                </b>{' '}
                for use in human food (including diseased meats).{' '}
                <b>We hold ourselves to a higher standard</b>, and put real,
                healthy food in your dog’s bowl—not just in the pictures on the
                bag.
              </>
            )}
            {shouldShowProFreshContent && (
              <>
                Feeding fresh may not always be the cheapest option, but{' '}
                <b>
                  it is the best way to ensure real, healthy food is going in
                  your dog’s bowl
                </b>
                —not just pictures on the bag.{' '}
                <b>
                  Pet food companies can legally use ingredients considered too
                  low quality
                </b>{' '}
                for use in human food (including diseased meats), but we deliver
                healthy recipes made to human food safety standards, so it’s
                easy to feed your dog <Nowrap>something better</Nowrap>.
              </>
            )}
          </Text>
        </div>
      </div>
    </Modal>
  );
};
