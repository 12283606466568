import { Branch, Fragment, Node, NodeType } from '../types';

import { isBranchNode } from './isBranchNode';

export interface BlueprintTraverseApplyOptions {
  types?: NodeType[];
  after?: boolean;
}

export function blueprintTraverseApply<T extends Node | Fragment>(
  root: T,
  fn: (node: T, parent: T | null) => void,
  options?: BlueprintTraverseApplyOptions
) {
  const defaultOptions = { types: Object.values(NodeType), after: false };
  const { types, after } = {
    ...defaultOptions,
    ...options,
  };

  const targetTypes = new Set(types);

  const op = (node: T, parent: Branch<T> | null) => {
    if (targetTypes.has(node.type)) {
      fn(node, parent);
    }
  };

  const traverseStep = (node: T, parent: Branch<T> | null): void => {
    if (!after) {
      op(node, parent);
    }

    if (isBranchNode<T>(node)) {
      node.children.forEach(n => traverseStep(n, node));
    }

    if (after) {
      op(node, parent);
    }
  };

  traverseStep(root, null);
}
