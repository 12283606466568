/** Use this email for redirecting sign-up support e-mails. */
export const EMAIL_HELP_SIGNUP = 'signup@thefarmersdog.com';
/** Use this email for redirecting support e-mails. */
export const EMAIL_HELP = 'help@thefarmersdog.com';
/** Use this email for redirecting diy support e-mails. */
export const EMAIL_DIY_HELP = 'diysupport@thefarmersdog.com';
/** Use this email to redirect press enquiries. */
export const EMAIL_PRESS = 'press@thefarmersdog.com';
/** Use this email for careers enquiries. */
export const EMAIL_CAREERS = 'careers@thefarmersdog.com';
/** Use this email for recruiting enquiries. */
export const EMAIL_RECRUITING = 'recruiting@thefarmersdog.com';
/** Use this email for redirecting generic emails. */
export const EMAIL_INFO = 'info@thefarmersdog.com';
