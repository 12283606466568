import classNames from 'classnames';

import { Text, PageWidth, useViewport, Link } from '@farmersdog/corgi-x';
import {
  Facebook,
  Instagram,
  Mail,
  Press,
  Smartphone,
} from '@farmersdog/corgi-x/icons';

import config from '../../config/config';

import { paths, emails, phoneNumbers, links } from '@farmersdog/constants';
import { EMAIL_HELP } from '@farmersdog/constants/emails';

import { LegalDetails } from './LegalDetails';
import { NewsletterForm } from './NewsletterForm';

import styles from './FullFooter.module.css';

export interface FullFooterProps {
  shouldShowPhoneLink: boolean;
  shouldShowEmailLink: boolean;
}

export function FullFooter(props: FullFooterProps) {
  const { large: isLarge, extraSmall: isExtraSmall } = useViewport();

  return (
    <footer
      className={classNames(
        styles.container,
        isLarge ? styles.lg : '',
        isExtraSmall ? styles.xs : ''
      )}
    >
      <PageWidth>
        <div className={styles.content}>
          <span className={styles.navigation}>
            <Text as="h4" variant="heading-16" bold>
              Site Map
            </Text>
            <ul>
              <li>
                <Link baseColor="white" to={paths.PATH_REVIEWS}>
                  <Text variant="heading-16">Reviews</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" to={paths.PATH_ABOUT_US}>
                  <Text variant="heading-16">About Us</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" to={paths.PATH_FAQ}>
                  <Text variant="heading-16">FAQ</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" to={paths.PATH_LOGIN}>
                  <Text variant="heading-16">Log In</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" to={paths.PATH_SIGNUP}>
                  <Text variant="heading-16">Sign Up</Text>
                </Link>
              </li>
              <li>
                <Link
                  baseColor="white"
                  href={links.LINK_DIGEST}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">Digest</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" to={paths.PATH_DIY}>
                  <Text variant="heading-16">DIY</Text>
                </Link>
              </li>
              <li>
                <Link
                  baseColor="white"
                  href={paths.PATH_CAREERS}
                  target="_blank"
                >
                  <Text variant="heading-16">Careers</Text>
                </Link>
              </li>
              <li>
                <Link
                  baseColor="white"
                  href={links.LINK_AFFILIATES}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">Affiliates</Text>
                </Link>
              </li>
              <li>
                <Link
                  baseColor="white"
                  href={config.get('app.vetPortalUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">Vet Portal</Text>
                </Link>
              </li>
              <li>
                <Link baseColor="white" href={paths.PATH_BREED}>
                  <Text variant="heading-16">Breeds</Text>
                </Link>
              </li>
            </ul>
          </span>
          <span className={styles.contact}>
            <Text as="h4" variant="heading-16" bold>
              Contact
            </Text>
            {props.shouldShowEmailLink && (
              <Link baseColor="white" href={`mailto:${EMAIL_HELP}`}>
                <Text variant="heading-16">
                  <Mail aria-hidden className={styles.icon} /> {EMAIL_HELP}
                </Text>
              </Link>
            )}
            {props.shouldShowPhoneLink && (
              <>
                <Link
                  baseColor="white"
                  href={`tel:${phoneNumbers.FRESH_CX_PHONE.link}`}
                >
                  <Text variant="heading-16">
                    <Smartphone aria-hidden className={styles.icon} />{' '}
                    {phoneNumbers.FRESH_CX_PHONE.display}
                  </Text>
                </Link>
              </>
            )}
            <Link
              baseColor="white"
              href={links.LINK_IG}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text variant="heading-16">
                <Instagram aria-hidden className={styles.icon} /> Instagram
              </Text>
            </Link>
            <Link
              baseColor="white"
              href={links.LINK_FB}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text variant="heading-16">
                <Facebook aria-hidden className={styles.icon} /> Facebook
              </Text>
            </Link>
            <Link baseColor="white" href={`mailto:${emails.EMAIL_PRESS}`}>
              <Text variant="heading-16">
                <Press aria-hidden className={styles.icon} /> Media Inquiries
              </Text>
            </Link>
          </span>
          <span className={styles.newsletter}>
            <Text as="h4" variant="heading-16" bold>
              Free Health and Nutrition Tips
            </Text>
            <NewsletterForm />
          </span>
        </div>
        <div className={styles.ancillary}>
          <Text variant="heading-16">
            Our food is made while playing doo-wop tunes, all with ❤ in the US
          </Text>
        </div>
      </PageWidth>
      <LegalDetails mode="light" />
    </footer>
  );
}
