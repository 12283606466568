import { Modal } from '@farmersdog/corgi';
import { Accordion, Text } from '@farmersdog/corgi-x';

import { useDebugModal } from './useDebugModal';
import { SegmentEventLog } from '../segmentDebug';
import { SplitDebugForm } from '../splitDebug';
import { useHotKeys } from './useHotKey';

export function DebugModal() {
  const { rootProps } = useDebugModal();
  useHotKeys();

  return (
    // @ts-expect-error TODO: Fix these types in corgi
    <Modal {...rootProps}>
      <Text variant="heading-28" bold color="kale-3">
        Debug
      </Text>
      <Accordion
        rows={[
          { title: 'Split', content: <SplitDebugForm /> },
          { title: 'Segment', content: <SegmentEventLog /> },
        ]}
      />
    </Modal>
  );
}
