import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { mountPostieEvent, PostieEvents } from './mountPostieEvent';

export const useMountPostiePageView = () => {
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    mountPostieEvent({
      eventName: PostieEvents.TrackPageView,
    });
  }, [pathname, search]);
};
