import { Text, Picture, Button } from '@farmersdog/corgi-x';
import { freshFoodPictureSources } from '../../../assets';
import { RecipeDetailsModalProps } from '../../../recipe-details-modal';
import { MyDogsFoodRecipeFieldsFragment } from '../../network';

import { trackRecipesSeeDetailsClicked } from '../../analytics';

import styles from './SelectedFoodRecipes.module.css';

type HandleOpenRecipeDetailsModalProps = Pick<
  RecipeDetailsModalProps,
  'productName' | 'petName'
>;

interface SelectedFoodRecipesProps {
  petName: string;
  recipes: MyDogsFoodRecipeFieldsFragment[];
  handleOpenRecipeDetailsModal: (
    props: HandleOpenRecipeDetailsModalProps
  ) => void;
}

export function SelectedFoodRecipes(props: SelectedFoodRecipesProps) {
  return (
    <>
      {props.recipes.map(recipe => (
        <div key={recipe.id} className={styles.recipeItemContainer}>
          <Picture
            sources={freshFoodPictureSources[recipe.name]}
            alt=""
            className={styles.recipeItemFoodImage}
          />
          <div className={styles.recipeInfo}>
            <Text variant="heading-16" color="charcoal-3" as="div" bold>
              {recipe.displayName}
            </Text>
            <SeeDetailsButton
              productName={recipe.name}
              petName={props.petName}
              recipeDisplayName={recipe.displayName}
              handleOpenRecipeDetailsModal={props.handleOpenRecipeDetailsModal}
            />
          </div>
        </div>
      ))}
    </>
  );
}

function SeeDetailsButton(props: {
  productName: string;
  petName: string;
  recipeDisplayName: string;
  handleOpenRecipeDetailsModal: (
    props: HandleOpenRecipeDetailsModalProps
  ) => void;
}) {
  function handleButtonClick() {
    props.handleOpenRecipeDetailsModal({
      petName: props.petName,
      productName: props.productName,
    });

    trackRecipesSeeDetailsClicked({
      recipe: props.recipeDisplayName,
    });
  }

  return (
    <Button
      type="button"
      variant="plain-text"
      aria-label={`See ${props.petName} ${props.recipeDisplayName} recipe details`}
      onClick={handleButtonClick}
    >
      <Text variant="heading-16">See details</Text>
    </Button>
  );
}
