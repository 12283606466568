import { tosaErrorCodes as errorCodes } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useLabel, useRemovePet } from '../../../hooks';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { getErrorMessage } from '../../../validation';
import { SkipPetButton } from '../../shared';

import styles from './PetAgeSkipButton.module.css';

const ageErrors = new Set([errorCodes.PET_TOO_OLD, errorCodes.PET_TOO_YOUNG]);

export function PetAgeSkipButton(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const {
    getValues,
    formState: { errors },
  } = formMethods;

  const { removePet } = useRemovePet();

  const formValues = getValues();

  const { parsedLabel } = useLabel({ node, getValues });

  const hasAgeError = Object.values(errors)
    .map(error => getErrorMessage(error))
    .some(errorMessage => ageErrors.has(errorMessage as errorCodes));

  const shouldShow = formValues.numPets
    ? formValues.numPets > 1 && hasAgeError
    : false;

  const { position } = getNodeNameAndPosition(node.name);

  return (
    <SkipPetButton
      label={parsedLabel}
      className={styles.skipButton}
      shouldShow={shouldShow}
      onClick={() =>
        removePet({
          position,
          componentProps: props,
        })
      }
    />
  );
}
