import classNames from 'classnames';

import Picture, { LoadingOption } from 'src/components/Picture';
import { useCorgiViewport } from 'src/screen';

import sources from './assets';
import styles from './Bowl.module.scss';

export interface BowlProps {
  /** Optional class name */
  className?: string;
}

/**
 * Render a bowl graphic.
 */
export function Bowl({ className }: BowlProps) {
  return (
    <div
      className={classNames(
        styles.visible,
        className,
        useCorgiViewport({ xs: styles.xs, lg: styles.lg })
      )}
    >
      <div className={styles.aspect}>
        <Picture
          sources={sources}
          alt="Bowl of food"
          loading={LoadingOption.lazy}
          className={styles.image}
        />
      </div>
    </div>
  );
}
