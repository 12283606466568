import classNames from 'classnames';

import styles from './FullScreenImageBackground.module.css';
import {
  generateSourcesForPicture,
  MobileAndDesktopSources,
} from 'src/pages/Home/components/AssetPreloader/assets/shared';

interface PictureComponentProps {
  moveBackgroundLower?: boolean;
  sources: MobileAndDesktopSources;
  alt: string;
  className?: string;
}

export const FullScreenImageBackground = ({
  sources,
  moveBackgroundLower,
  className,
  alt,
}: PictureComponentProps) => {
  const imageClassNames = classNames(className, styles.backgroundImage, {
    [styles.moveBackgroundLower]: moveBackgroundLower,
  });

  const sourcesForPicture = generateSourcesForPicture(sources);

  return (
    <picture>
      {sourcesForPicture.map(({ src, type, media }) => {
        return (
          <source
            key={src}
            type={type}
            srcSet={src}
            media={media}
            sizes="100vw"
          />
        );
      })}
      <img alt={alt} className={imageClassNames} />
    </picture>
  );
};
