import { BaseTreatment } from '../../..';
import eventNames from '../../analytics/eventNames';
import { track } from '@farmersdog/analytics';

export interface OrderDeliveryWindowDisplayedPayload {
  /* whether no past deliveries feature flag is on */
  noPastDeliveriesTreatment: BaseTreatment;
  /* The trial order ID */
  orderId?: number;
  /* The date the order was created */
  orderCreatedAt?: string;
  /* The start of the range displayed */
  fromDate?: string;
  /* The end of the range displayed */
  toDate?: string;
  /* Where the information was displayed */
  displayLocation: 'checkout-success' | 'account-homepage' | 'account-orders';
}

export function trackOrderDeliveryWindowDisplayed(
  payload: OrderDeliveryWindowDisplayedPayload
) {
  track(eventNames.order_delivery_window_displayed, { ...payload });
}
