import { useMediaQueryMatch } from 'src/hooks/useMediaQueryMatch';
import { MIN_HEIGHT_FOR_DESKTOP_NAV } from 'src/components/AppPage/constants';

export function useDisplayDesktopNav(): boolean {
  return useMediaQueryMatch<boolean>([
    {
      mediaQuery: `(min-height: ${MIN_HEIGHT_FOR_DESKTOP_NAV}px)`,
      value: true,
    },
  ]);
}
