/**
 * Create a HTML element with the specified id. If the element already exists,
 * it returns it. This is used for the portal in the Lightbox.
 */
export function createElementWithId(
  id: string,
  tagName = 'div',
  className?: string
) {
  let el = window.document.getElementById(id);
  if (!el) {
    el = window.document.createElement(tagName);
    el.id = id;

    if (className) {
      el.classList.add(className);
    }

    window.document.body.appendChild(el);
  }
  return el;
}
