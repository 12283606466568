import '@farmersdog/corgi-x/build/styles.css';
import './App.module.css';
import 'react-day-picker/style.css';

import { ErrorBoundary } from '../errors';
import { LibProviders } from './lib';
import { StateProviders } from './state';
import { RootRoutes } from '../routes';
import { Head } from './Head';
import { AppProps } from './App.types';

export function App(props?: AppProps) {
  return (
    <ErrorBoundary>
      {props?.withHead !== false && <Head />}

      <StateProviders websiteAuth={props?.websiteAuth}>
        <LibProviders
          apolloClient={props?.apolloClient}
          withBrowserRouter={props?.withBrowserRouter}
        >
          <RootRoutes />
        </LibProviders>
      </StateProviders>
    </ErrorBoundary>
  );
}
