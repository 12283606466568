import { Banner, Nowrap, Link, Text, TextProps } from '@farmersdog/corgi-x';

import styles from './DiscountBanner.module.css';

interface DiscountBannerInnerProps {
  copy?: string;
  linkPath?: string;
  linkCopy?: string;
  className?: string;
  color?: TextProps['color'];
}

export function DiscountBannerInner({
  copy,
  linkPath,
  linkCopy,
  className,
  color = 'white',
}: DiscountBannerInnerProps) {
  return (
    <Banner className={className}>
      {copy && (
        <Text id="discount-banner-inner" variant="heading-16" color={color}>
          {copy}
        </Text>
      )}

      {linkPath && linkCopy && (
        <>
          {' '}
          <Nowrap>
            <Link href={linkPath} target="_blank" className={styles.cta}>
              {linkCopy}
            </Link>
          </Nowrap>
        </>
      )}
    </Banner>
  );
}
