import ContentPage from 'src/components/ContentPage';

import { paths } from '@farmersdog/constants';

interface AccessibilityStatementProps {
  page?: boolean;
  className?: string;
}

export default function AccessibilityStatement({
  page = true,
  className,
}: AccessibilityStatementProps) {
  const Container = page ? ContentPage : 'div';

  return (
    <Container title="Accessibility Statement" className={className}>
      <h1>Accessibility Statement</h1>
      <p>Last Updated February 9, 2023.</p>

      <h2>Our Policy & Commitment</h2>
      <p>
        At The Farmers Dog we are committed to accessibility, diversity, and
        inclusion. As part of that commitment, we are making efforts to ensure
        that The Farmers Dog’s digital property is accessible to all
        individuals, regardless of ability. In doing so, The Farmers Dog has a
        goal to comply with applicable accessibility standards included in the{' '}
        <a
          href="https://www.w3.org/WAI/standards-guidelines/wcag/"
          target="_blank"
          rel="noreferrer"
        >
          W3C’s WCAG
        </a>{' '}
        <span style={{ color: '#f2674b' }}>2.1 AA</span> guidelines and
        regulations including the{' '}
        <a
          href="https://www.ada.gov/pubs/adastatute08.htm"
          target="_blank"
          rel="noreferrer"
        >
          Americans with Disabilities Act (ADA)
        </a>
        .
      </p>

      <h2>Digital Accessibility Statement</h2>
      <p>
        We are working to ensure our website and online services are accessible
        for all and are committed to following the W3C{' '}
        <a
          href="https://www.levelaccess.com/blog/web-content-accessibility-guidelines-wcag/"
          target="_blank"
          rel="noreferrer"
        >
          Web Content Accessibility Guidelines
        </a>
        . We are also firmly dedicated to complying with regulations such as the{' '}
        <a
          href="https://www.levelaccess.com/blog/ada-guidelines-compliance/"
          target="_blank"
          rel="noreferrer"
        >
          Americans with Disabilities Act (ADA)
        </a>{' '}
        and other applicable web accessibility laws.
      </p>
      <p>
        To accomplish this, we have{' '}
        <a href="https://www.levelaccess.com/" target="_blank" rel="noreferrer">
          partnered with Level Access
        </a>{' '}
        to leverage their technology and 25 year’s experience to achieve and
        sustain conformance to accessibility standards. Their web accessibility
        platform is used to evaluate our digital properties in accordance with
        best practices and is supported by a diverse team of accessibility
        professionals, including users with disabilities. The platform,
        moreover, goes beyond minimum compliance requirements by making an{' '}
        <a
          href="https://www.levelaccess.com/thefarmersdog"
          target="_blank"
          rel="noreferrer"
        >
          assistive CX technology application
        </a>{' '}
        available to customers who have trouble typing, gesturing, moving a
        mouse, or reading. The application is free to download, and it
        incorporates tools such as mouse and keyboard replacements, voice
        recognition, speech enablement, hands-free/touch-free navigation, and
        more.{' '}
      </p>

      <h2>Contact</h2>
      <p>
        We want to hear from you - If you would like further information about
        The Farmers Dog’s accessibility efforts, have other accessibility
        questions or feedback, or encounter accessibility barriers on our
        digital properties, please email:
      </p>
      <p>
        <a href="mailto:accessibility@thefarmersdog.com">
          accessibility@thefarmersdog.com
        </a>
      </p>
      <p>
        For all other account or customer related questions or feedback, please
        refer to our{' '}
        <a href={`https://www.thefarmersdog.com/${paths.PATH_FAQ}`}>FAQ</a>{' '}
        page, or email:
      </p>
      <p>
        <a href="mailto:help@thefarmersdog.com">help@thefarmersdog.com</a>
      </p>
    </Container>
  );
}
