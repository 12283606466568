import { Page } from '@farmersdog/corgi-x';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Loading } from '../components';
import {
  doesGraphqlErrorPathIncludeFields,
  getCoreApiDataValidationError,
} from '../errors';
import {
  ChangeFreshRecipesPlanQuoteInput,
  UpdateFoodPlansRecipesInput,
} from '../graphql/types.cgs';
import {
  RecipeDetailsModal,
  RecipeDetailsModalProps,
} from '../recipe-details-modal';
import styles from './EditFreshRecipes.module.css';
import {
  trackClickedContinueButton,
  trackEnteredFlow,
  trackExitedFlow,
} from './analytics';
import { ConfirmRecipes, SelectRecipes } from './features';
import {
  useFetchEditFreshRecipesData,
  useLazyFetchChangeFreshRecipesQuote,
  useUpdateFoodPlansRecipe,
} from './network';
import {
  EditFreshRecipeState,
  useEditFreshRecipeMachine,
} from './state-machine';
import {
  RecipeChange,
  trackSavedSelections,
} from './analytics/trackSavedSelections';

export type RecipeDetailsModalHandlerArgs = Pick<
  RecipeDetailsModalProps,
  'productName' | 'petName'
>;

interface EditFreshRecipesProps {
  petName: string;
}

export function EditFreshRecipes(props: EditFreshRecipesProps) {
  const history = useHistory();
  const machine = useEditFreshRecipeMachine();
  const request = useFetchEditFreshRecipesData({
    petName: props.petName,
    onCompleted: trackEnteredFlow,
  });
  const submit = useUpdateFoodPlansRecipe({
    onCompleted: () => {
      handleOnClose();
    },
    onError: () => '', // TODO: show message
  });
  // The query is associated with the SelectRecipes continue button
  const [lazyQuery, lazyQueryResult] = useLazyFetchChangeFreshRecipesQuote({
    onCompleted: () => {
      // We only want to track successful button clicks
      trackClickedContinueButton();
      machine.onNext();
    },
  });
  const [modalState, setModalState] = useState<
    { isOpen: boolean } & RecipeDetailsModalHandlerArgs
  >({
    isOpen: false,
    productName: '',
  });

  function handleOnClose() {
    machine.onClose();
    trackExitedFlow();
    history.goBack();
  }

  function handleOpenRecipeDetailsModal(
    petAndRecipe: RecipeDetailsModalHandlerArgs
  ) {
    setModalState({ ...petAndRecipe, isOpen: true });
  }

  function handleCloseRecipeDetailsModal() {
    setModalState({ ...modalState, isOpen: false });
  }

  function handleContinueClick(args: ChangeFreshRecipesPlanQuoteInput): void {
    void lazyQuery({ variables: { input: args } });
  }

  if (request.loading || lazyQueryResult.loading) {
    return <Loading />;
  }

  if (request.error) {
    throw request.error;
  }

  if (!request.data) {
    throw new Error('Unknown exception occurred while loading data');
  }

  // This error check should expand to the entire query once the quote and order size feature flags are removed
  if (
    doesGraphqlErrorPathIncludeFields(
      ['firstUnlockedOrder', 'selectedRecipes'],
      getCoreApiDataValidationError(lazyQueryResult.error)
    )
  ) {
    throw new Error(
      'Unknown exception occured while calling for recipe quote data'
    );
  }

  const pet = request.data.customer.pets[0];

  function handleSubmit(variables: UpdateFoodPlansRecipesInput) {
    submit.onSubmit(variables);

    const price =
      lazyQueryResult.data?.customer.changeFreshRecipesPlanQuote
        .dailyConsumptionPrice;

    const frequency =
      lazyQueryResult.data?.customer.changeFreshRecipesPlanQuote
        .subscriptionFrequency;

    const recipeChange: RecipeChange = {
      originalPrice: price?.original,
      originalFrequency: frequency?.original,
      originalRecipes: pet.foodRecipes.map(recipe => recipe.name),
      updatedPrice: price?.updated,
      updatedFrequency: frequency?.updated,
      updatedRecipes: variables.foodPlans[0].selectedRecipes.map(
        recipe => recipe.name
      ),
    };

    trackSavedSelections(recipeChange);
  }

  if (machine.isState(EditFreshRecipeState.CloseRecipes)) {
    return null;
  }

  if (machine.isState(EditFreshRecipeState.ConfirmRecipes)) {
    return (
      <Page
        onBackClick={machine.onPrevious}
        onCloseClick={handleOnClose}
        className={styles.confirmPage}
      >
        <ConfirmRecipes
          petPlanId={pet.plan.id}
          petName={pet.name}
          deliveryWindow={
            lazyQueryResult.data?.customer.firstUnlockedOrder.deliveryWindow
          }
          newRecipes={
            lazyQueryResult.data?.customer.changeFreshRecipesPlanQuote
              .selectedRecipes
          }
          prices={
            lazyQueryResult.data?.customer.changeFreshRecipesPlanQuote
              .dailyConsumptionPrice
          }
          orderSizes={
            lazyQueryResult.data?.customer.changeFreshRecipesPlanQuote
              .subscriptionFrequency
          }
          onClose={handleOnClose}
          onSubmit={handleSubmit}
          isSubmitting={submit.isLoading}
        />
      </Page>
    );
  }

  return (
    <Page onCloseClick={handleOnClose} className={styles.selectPage}>
      <SelectRecipes
        pet={pet}
        onClose={handleOnClose}
        onContinue={handleContinueClick}
        handleOpenRecipeDetailsModal={handleOpenRecipeDetailsModal}
      />
      {/* We want the modal to gracefully close but not submit a false recipe id request on initial render */}
      {modalState.productName !== '' && (
        <RecipeDetailsModal
          isOpen={modalState.isOpen}
          productName={modalState.productName}
          petName={modalState.petName}
          onClose={handleCloseRecipeDetailsModal}
        />
      )}
    </Page>
  );
}
