import identify from '../identify';

/**
 * Wrapper function that calls Segment identify when a user views the mixing modal
 *
 * @param user - a Signup user reducer object
 */
export function identifyViewedMixingModal(user: Reducer.User) {
  identify(user, { viewedMixingModal: true });
}
