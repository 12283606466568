import PropTypes from 'prop-types';
import classNames from 'classnames';
import { headingStyles, articleStyles } from '@farmersdog/corgi';

const headingClassName = classNames(
  headingStyles.base.family,
  headingStyles.colors.primary1
);

const typographyPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const SectionHeading = ({ children, className }) => (
  <h2
    className={classNames(
      headingClassName,
      headingStyles.weights.bold,
      headingStyles.sizes.size28,
      className
    )}
  >
    {children}
  </h2>
);

SectionHeading.propTypes = typographyPropTypes;

export const AnswerText = ({ children, className }) => {
  return (
    <p
      className={classNames(
        articleStyles.base.family,
        articleStyles.colors.secondary1,
        articleStyles.sizes.size16,
        className
      )}
    >
      {children}
    </p>
  );
};

AnswerText.propTypes = typographyPropTypes;
