import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

interface ClickedRemoveClinicPayload {
  clinicId: string;
}

export function trackClickedRemoveClinic({
  clinicId,
}: ClickedRemoveClinicPayload) {
  track(eventNames.clicked_remove_clinic, {
    id: clinicId,
  });
}
