import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import {
  AnonymousExperimentFlag,
  AnonymousExperimentFlags,
} from 'src/server/middlewares/experimentsMiddleware';

export function getHasSSRAnonymousExperiment(
  experiments: unknown,
  experimentFlag: AnonymousExperimentFlag
): experiments is AnonymousExperimentFlags {
  return (
    isObject(experiments) &&
    !isEmpty(experiments) &&
    Object.prototype.hasOwnProperty.call(experiments, experimentFlag)
  );
}
