import { QueryParameter, paths } from '@farmersdog/constants';

import { ProductLineType } from '../graphql/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const allowedPaths = [
  paths.PATH_SIGNUP,
  paths.PATH_SIGNUP_ME,
  paths.PATH_SIGNUP_PETS,
  paths.PATH_SIGNUP_RECIPES,
  paths.PATH_SIGNUP_PLANS,
  paths.PATH_SIGNUP_CHECKOUT,
] as const;

type AllowedPaths = (typeof allowedPaths)[number];

export interface GetLinkToSignupFlowArgs {
  path: AllowedPaths;
  flow: ProductLineType;
  email?: string;
}

function getLinkToSignupFlow({ path, flow, email }: GetLinkToSignupFlowArgs) {
  const emailPathComponent = email ? `/${email}/` : '';
  return `${path}${emailPathComponent}?${String(
    QueryParameter.ProductLine
  )}=${flow}`;
}

export function getLinkToFreshSignup(path: AllowedPaths, email?: string) {
  return getLinkToSignupFlow({ path, flow: ProductLineType.Fresh, email });
}

export function getLinkToDiySignup(path: AllowedPaths, email?: string) {
  return getLinkToSignupFlow({ path, flow: ProductLineType.Diy, email });
}
