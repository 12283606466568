interface GetFirstBoxAccessibleTextArgs {
  petDailySubtotal: string;
  petDailyDiscountedSubtotal: string;
}

interface getRecipesStartingAtAccessibleTextArgs {
  cheapestLpfPetDailySubtotal: string;
  cheapestPetDailyDiscountedSubtotal: string;
}

const getFirstBoxAccessibleText = ({
  petDailySubtotal,
  petDailyDiscountedSubtotal,
}: GetFirstBoxAccessibleTextArgs) => {
  return `Subtotal was ${petDailySubtotal} per day, it’s now ${petDailyDiscountedSubtotal} per day.`;
};

const getRecipesStartingAtAccessibleText = ({
  cheapestPetDailyDiscountedSubtotal,
  cheapestLpfPetDailySubtotal,
}: getRecipesStartingAtAccessibleTextArgs) => {
  return `Recipes were starting at ${cheapestLpfPetDailySubtotal} per day; now starting at ${cheapestPetDailyDiscountedSubtotal} per day.`;
};

export { getFirstBoxAccessibleText, getRecipesStartingAtAccessibleText };
