import { CalendarEvent } from './events';
import { HomepageOrdersSectionProps } from '../HomepageOrdersSection';
import { addDays, parseISO } from 'date-fns';
export function convertNextOrderToCalendarEvent(
  nextOrderToBeDelivered: HomepageOrdersSectionProps['nextOrderToBeDelivered']
): CalendarEvent {
  // Add timestamp participle
  const from = parseISO(
    nextOrderToBeDelivered?.deliveryWindow.earliestDesiredArrivalDate ?? ''
  );
  const to = parseISO(
    nextOrderToBeDelivered?.deliveryWindow.latestDesiredArrivalDate ?? ''
  );
  return {
    from: from,
    to: to,
  };
}

// Add frequency to simulate future order
export function generateFakeFutureOrderCalendarEvent(
  nextOrderToBeDeliveredCalendarEvent: CalendarEvent,
  subscriptionFrequency?: number
): CalendarEvent {
  const futureEvent = {
    from: addDays(
      nextOrderToBeDeliveredCalendarEvent.from,
      subscriptionFrequency ?? 0
    ),
    to: addDays(
      nextOrderToBeDeliveredCalendarEvent.to,
      subscriptionFrequency ?? 0
    ),
  };
  return futureEvent;
}
