import { AxiosResponse } from 'axios';

/**
 * Prepare the attachment if the response has the `content-disposition` header set.
 *
 * @param {Object} response
 */
export default function getAttachmentFromResponse(
  response: AxiosResponse
): string | undefined {
  if (!response.headers || !response.headers['content-disposition']) {
    return undefined;
  }

  const value = response.headers['content-disposition'] as string;
  return value.split(';')[1].trim().split('=')[1].replace(/"/g, '');
}
