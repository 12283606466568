export interface hashArgs {
  /** string to hash */
  stringToHash: string;
  /** hashing algorithm to use. Note: SHA1 is also an option but is unsupported in Edge*/
  algorithm?: 'SHA-256' | 'SHA-384 ' | 'SHA-512';
}

/* https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest */

export async function hashSha({
  stringToHash,
  algorithm = 'SHA-256',
}: hashArgs) {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(stringToHash);

    const hashBuffer = await crypto.subtle.digest(algorithm, data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  } catch {
    return stringToHash;
  }
}
