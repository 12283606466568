import { Picture } from '../../../shared';

interface ActiveImageProps {
  icon: string[] | null | undefined;
  alt: string;
}

export function ActiveImage({ icon, alt }: ActiveImageProps) {
  if (!icon) {
    return null;
  }
  return <Picture sources={icon} alt={alt} />;
}
