import { useContext, ReactNode, createContext } from 'react';

import {
  PetNamesAndRequestedCalories,
  useGetPetNamesAndRecommendedCalories,
} from './useGetPetNamesAndRecommendedCalories';

interface ContextProviderProps {
  children: ReactNode;
}

interface PetNamesAndRequestedCaloriesInterface {
  petNamesAndRequestedCalories: PetNamesAndRequestedCalories[] | undefined;
}

const PetNamesAndRequestedCaloriesContext =
  createContext<PetNamesAndRequestedCaloriesInterface>({
    petNamesAndRequestedCalories: undefined,
  });

export const PetNamesAndRequestedCaloriesProvider = ({
  children,
}: ContextProviderProps) => {
  const petNamesAndRequestedCalories = useGetPetNamesAndRecommendedCalories();
  return (
    <PetNamesAndRequestedCaloriesContext.Provider
      value={{ petNamesAndRequestedCalories }}
    >
      {children}
    </PetNamesAndRequestedCaloriesContext.Provider>
  );
};

export function usePetNamesAndRequestedCalories() {
  return useContext(PetNamesAndRequestedCaloriesContext);
}
