import moment from 'moment';

/**
 * The week days number according to the moment week-date system.
 */
export enum WeekdayNumber {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

/**
 * The offset hours from the UTC in Eastern Standard Time (EST).
 */
export const EST_UTC_OFFSET = moment().isDST() ? -4 : -5;
