/**
 * Return an event handler that will run multiple events in the order that they
 * are passed as arguments.
 */
const interceptDOMEvent = <E>(
  ...handlers: (undefined | ((event: E) => void))[]
) => {
  return (e: E) => {
    handlers.forEach(handler => {
      if (handler) {
        handler(e);
      }
    });
  };
};

export default interceptDOMEvent;
