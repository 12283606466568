import { post } from '../actionCreators';

import {
  AUTH_CLEAR_USER_TOKEN,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_REFRESHING_TOKEN,
} from 'src/constants/actionTypes';

export const loginWithToken = token => ({
  type: AUTH_LOGIN,
  payload: { data: { login: { token } } },
});

export const logout = () => ({ type: AUTH_LOGOUT });

export const clearUserToken = () => ({ type: AUTH_CLEAR_USER_TOKEN });

/**
 * Refresh the auth token by registering the user again.
 *
 * Since this endpoint is only for new users, this won't work for those already
 * registered: the request will fail silently. so they will need to login again.
 */
export const refreshToken = email =>
  post(AUTH_REFRESH_TOKEN, '/auth/register', { email });

export const refreshingToken = isRefreshing => ({
  type: AUTH_REFRESHING_TOKEN,
  payload: isRefreshing,
});
