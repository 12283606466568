import { useState, useCallback } from 'react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { anonymousId, cookie } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';

import { useFeature } from 'src/abTesting';
import { CVR_DEFAULT_DISCOUNT } from 'src/abTesting/features';
import { REFERRER_TYPE_PARTNER } from 'src/constants/referrer';
import {
  isSplitDiscountConfigValid,
  setCouponCookieWithDefaultExperimentCode,
} from './utils';
import { experimentCodesWhitelist } from './constants';

const defaultDiscountExperimentErrorMessage = 'Error fetching default discount';

export function useDefaultDiscountExperiment() {
  const [discountChecked, setDiscountChecked] = useState<boolean>(false);
  const tfdAnonymousId = anonymousId.get();
  const { treatment, config } = useFeature<string, Record<string, unknown>>(
    CVR_DEFAULT_DISCOUNT,
    {},
    tfdAnonymousId
  );
  const logger = new Logger('website:frontend:defaultDiscountExperiment');

  const isDefaultDiscountExperimentOn =
    treatment !== 'off' && treatment !== 'control';

  if (isDefaultDiscountExperimentOn && !discountChecked) {
    const referrerCookie = cookie.getReferrerCookie();
    const currentCouponCookieCode = referrerCookie?.referrerCode;

    /*
      If user is in the experiment and ANY NON-DEFAULT coupon cookie value exists, we will not overwrite it.
      If user is in the experiment and a DEFAULT coupon cookie value exists,
      we will overwrite it with the latest code returned from split.
    */
    const currentCouponCookieIsDefaultDiscount =
      currentCouponCookieCode &&
      experimentCodesWhitelist.has(currentCouponCookieCode);

    if (
      isNil(currentCouponCookieCode) ||
      currentCouponCookieIsDefaultDiscount
    ) {
      setCouponCookieWithDefaultExperimentCode({
        splitConfig: config,
      });
    }
    setDiscountChecked(true);
  }

  function getDefaultDiscount() {
    if (isDefaultDiscountExperimentOn && isSplitDiscountConfigValid(config)) {
      return {
        referrerCode: config?.code as string,
        referrerType: REFERRER_TYPE_PARTNER,
      };
    } else if (isDefaultDiscountExperimentOn) {
      logger.error(
        'cvr_default_discount - could not get valid default discount config',
        { config }
      );
      return {
        referrerCode: undefined,
        referrerType: undefined,
        referrerAssociatedAt: undefined,
      };
    }
    return {
      referrerCode: undefined,
      referrerType: undefined,
      referrerAssociatedAt: undefined,
    };
  }

  const safelySetCouponCookieWithDefaultExperimentCode = useCallback(() => {
    if (isDefaultDiscountExperimentOn && isSplitDiscountConfigValid(config)) {
      return setCouponCookieWithDefaultExperimentCode({
        splitConfig: config,
      });
    } else {
      return noop();
    }
  }, [config, isDefaultDiscountExperimentOn]);

  return {
    isDefaultDiscountExperimentOn,
    getDefaultDiscount,
    setCouponCookieWithDefaultExperimentCode:
      safelySetCouponCookieWithDefaultExperimentCode,
    defaultDiscountExperimentErrorMessage,
  };
}
