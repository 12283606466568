import { ContentReveal } from '@farmersdog/corgi-x';

import {
  useGrainsQuestionExperiment,
  grainsQuestionLabel,
  usePriorFoodTypeProgression,
} from '../../../hooks';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { DropdownInput } from '../base/DropdownInput';

export function TreatsQuantityInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, useFeature, progress } = props;

  const { showSingleQuestionProgression, isReady } =
    usePriorFoodTypeProgression({
      useFeature,
    });

  const shouldRevealInput = progress.getPreviousLeafComplete(node.__self__);

  const { shouldShowGrainsQuestion } = useGrainsQuestionExperiment({
    useFeature,
  });

  const overrideLabel = shouldShowGrainsQuestion
    ? grainsQuestionLabel
    : node.input?.label;

  if (!isReady) return null;

  return showSingleQuestionProgression ? (
    <ContentReveal in={shouldRevealInput} inAnimation="fade">
      <div>
        <DropdownInput {...props} overrideLabel={overrideLabel} />
      </div>
    </ContentReveal>
  ) : (
    <DropdownInput {...props} overrideLabel={overrideLabel} />
  );
}
