import { Modal, Page } from '@farmersdog/corgi-x';
import { Loading } from '../components';
import { RecipeDetails } from './features';
import { useFetchRecipeDetails } from './network';
import styles from './RecipeDetailsModal.module.css';

export interface RecipeDetailsModalProps {
  productName: string;
  petName?: string;
  isOpen: boolean;
  onClose: () => void;
}

export function RecipeDetailsModal({
  productName,
  petName,
  isOpen,
  onClose,
}: RecipeDetailsModalProps) {
  const { data, loading, error } = useFetchRecipeDetails(productName);
  const recipe = data?.recipeDetails;

  return (
    <Modal
      contentClassName={styles.modal}
      isOpen={isOpen}
      withCloseButton
      aria-label="Recipe Details"
      onClose={onClose}
    >
      {loading && <Loading />}
      {recipe && (
        <RecipeDetails recipe={recipe} petName={petName} onClose={onClose} />
      )}
      {error && <Page layout="base-centered" heading="Recipe not found" />}
    </Modal>
  );
}
