import { useEffect } from 'react';

import {
  trackCancelSubscriptionFlowExit,
  trackCancelSubscriptionFlowStart,
} from '../cancellation-reasons/analytics';
import { SubscriptionType } from '../graphql/types.core-api';
import { PreCancelLocked, PreCancelUnlocked } from './features';
import { usePreCancelData } from './network';
import { Loading } from '../components';

export function PreCancel() {
  const preCancelData = usePreCancelData();

  useEffect(() => {
    if (preCancelData?.data?.customer.id) {
      trackCancelSubscriptionFlowStart({
        subscriptionType: SubscriptionType.Fresh,
      });
    }
  }, [preCancelData?.data?.customer.id]);

  if (preCancelData.loading) {
    return <Loading />;
  }

  if (preCancelData.error) {
    throw preCancelData.error;
  }

  if (!preCancelData.data) {
    throw new Error('Unknown exception occurred while loading data');
  }

  const { isUnlocked, deliveryWindow } =
    preCancelData.data.customer.nextOrderToBeDelivered;

  /** when the customer clicks the page's "x" to leave the flow */
  function onClose() {
    trackCancelSubscriptionFlowExit({
      exitReason: 'flow-closed',
      subscriptionType: SubscriptionType.Fresh,
    });
  }

  /** when the customer clicks the negative CTA to leave the flow */
  function onExit() {
    trackCancelSubscriptionFlowExit({
      exitReason: 'cancellation-aborted',
      subscriptionType: SubscriptionType.Fresh,
    });
  }

  return isUnlocked ? (
    <PreCancelUnlocked onClose={onClose} />
  ) : (
    <PreCancelLocked
      deliveryWindow={deliveryWindow}
      onClose={onClose}
      onExit={onExit}
    />
  );
}
