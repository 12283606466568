import classNames from 'classnames';
import { ReactNode, ChangeEventHandler, FocusEventHandler } from 'react';

import { withRef, WithRefProps } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import styles from './SegmentedControlInput.module.css';

/**
 * Render a checkbox or a radio button similarly to a iOS [segmented
 * control](https://developer.apple.com/design/human-interface-guidelines/ios/controls/segmented-controls/).
 * As default, it works like a `<input type="checkbox">`.
 *
 * ---
 * **🔧 Maintenance required**
 * - Replace `multi` and `stacked` props with a `behavior` prop that can accept
 *   `radio`, `checkbox` or `stacked` as values.
 */
type InputAttributesType = JSX.IntrinsicElements['input'];
type RefProps = WithRefProps<HTMLInputElement>;

interface SegmentedControlInputProps extends RefProps, InputAttributesType {
  children: ReactNode;
  id: string;
  name: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  multi?: boolean;
  stacked?: boolean;
}

export const SegmentedControlInput = withRef<
  HTMLInputElement,
  SegmentedControlInputProps
>(({ children, forwardedRef, multi = true, stacked = false, ...props }) => {
  const id = props.id || `${props.name}-${props.value}`;

  return (
    <>
      <label
        className={classNames(styles.SegmentedControl, {
          stacked,
        })}
        id={id}
      >
        <input
          {...props}
          type={multi && !stacked ? 'checkbox' : 'radio'}
          ref={forwardedRef}
        />{' '}
        <Text as="span" variant="heading-16" color="charcoal-3">
          {children}
        </Text>
      </label>
    </>
  );
});
