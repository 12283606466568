import { useId } from 'react';
import {
  ActivityIndicator,
  Divider,
  Grid,
  GridItem,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import { useFetchPetFreshFoodData } from './network';
import { reporter } from '../errors/services';
import { Pet } from './features';
import styles from './PetFreshFood.module.css';

export function PetFreshFood() {
  const request = useFetchPetFreshFoodData();
  const headingId = useId();

  if (request.loading) {
    return <ActivityIndicator mode="dark" />;
  }
  if (request.error) {
    reporter.error(new Error('Error loading PetFreshFood'), {
      sourceError: request.error,
    });

    return null;
  }

  if (!request.data) {
    reporter.error(new Error('Unknown exception occurred while loading data'), {
      sourceError: request.error,
    });

    return null;
  }

  const pets = request.data.customer.pets;

  return (
    <Section aria-labelledby={headingId} vSpacing="sm" variant="card">
      <Text variant="heading-28" bold color="kale-3" as="h2" id={headingId}>
        Fresh Food
      </Text>
      <Divider color="Charcoal0" width={1} spacing={24} />
      <Grid>
        {pets.map((pet, index) => (
          <GridItem key={pet.id} md={12} lg={6}>
            <Pet
              petId={pet.id}
              name={pet.name}
              plan={pet.plan}
              recipes={pet.foodRecipes}
              dailyConsumptionPrice={pet.plan.dailyConsumptionPrice}
            />
            {index !== pets.length - 1 && (
              <Divider
                color="Charcoal0"
                width={1}
                spacing={24}
                className={styles.divider}
              />
            )}
          </GridItem>
        ))}
      </Grid>
    </Section>
  );
}
