import { NodeNames } from '@farmersdog/constants';

import { CompiledBlueprint } from '../../blueprint/types';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import { Experiments, FormFieldsType } from '../../types';
import { sortObjectPropertiesByKey } from '../../utils';
import { FeatureName } from '../../utils/features';
import { experimentFragmentConfig } from '../experimentFragmentConfig';
import { fragmentConfig } from '../fragmentConfig';

import { updateSchemaWithFragments } from './updateSchemaWithFragments';

/* 
  This function takes form input values from a returning user and rebuilds the compiled blueprint based on their answers.
  Since fragments can dynamically change the shape of the blueprint, this function rebuilds the blueprint with any
  necessary fragments included based on their form entries.
*/

interface ReconstructBlueprintFromLeadStateProps {
  baseBlueprint: CompiledBlueprint;
  recoveredLeadFormValues: FormFieldsType;
  experiments: Experiments;
}

export function reconstructBlueprintFromLeadState({
  baseBlueprint,
  recoveredLeadFormValues,
  experiments,
}: ReconstructBlueprintFromLeadStateProps) {
  let reconstructedBlueprint = baseBlueprint;
  const sortedLeadFormValues = sortObjectPropertiesByKey({
    object: recoveredLeadFormValues,
    map: { [NodeNames.NumPets]: 1 },
  });
  Object.entries(sortedLeadFormValues).forEach(([key, val]) => {
    const { name, position } = getNodeNameAndPosition(key);
    const index = parseInt(position);
    if (fragmentConfig[name]) {
      const { fragments, getFragmentCount } = fragmentConfig[name];
      if (fragments && fragments.length > 0) {
        reconstructedBlueprint = updateSchemaWithFragments({
          fragmentCount: getFragmentCount(val),
          fragments,
          schema: reconstructedBlueprint,
          index: Number.isFinite(index) ? index : undefined,
        });
      }
    }

    // look at every experiment defined in config
    for (const [experimentName, experimentConfig] of Object.entries(
      experimentFragmentConfig
    )) {
      // look at every node that is relevant with the experiment
      for (const [nodeName, nodeConfig] of Object.entries(experimentConfig)) {
        // if the node defined in config is present in the returning lead's state
        if (nodeName === key) {
          const { fragments, getFragmentCount } = nodeConfig;
          // get the relevant experiment's returned values from a/b testing provider
          const experimentReturn = experiments[experimentName as FeatureName];
          if (fragments && fragments.length > 0 && experimentReturn) {
            reconstructedBlueprint = updateSchemaWithFragments({
              // use the relevant returning lead's state value and experiment returned values to determine fragment operation
              fragmentCount: getFragmentCount(val, experimentReturn),
              fragments,
              schema: reconstructedBlueprint,
              index: Number.isFinite(index) ? index : undefined,
            });
          }
        }
      }
    }
  });

  return reconstructedBlueprint;
}
