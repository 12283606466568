import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

interface TrackPetManageClickedArgs {
  petId: number;
  petName: string;
}

export function trackPetManageClicked(args: TrackPetManageClickedArgs) {
  track(eventNames.pet_fresh_food_manage_clicked, {
    petId: args.petId,
    petName: args.petName,
  });
}
