import { ForwardedRef, forwardRef, useEffect, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { BranchNode as BranchNodeType } from '../../../blueprint/types';
import {
  getNodeNameAndPosition,
  getPositionedProperty,
} from '../../../blueprint/utils';
import { useWeightLossPersonalizationExperiment } from '../../../hooks';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';
import { TOSAComponentInput } from '../../../types';
import {
  getBodyConditionEducationDetails,
  getCompletedData,
  scrollOncePerSession,
} from '../../../utils';
import { isNotNullUndefinedOrEmptyString } from '../../../utils/isNotNullUndefinedOrEmptyString';
import { PersonalizedEducation } from '../../education';
import { BranchNode } from '../../schema/BranchNode';
import { SubmitButton } from '../../shared';

import { usePetWeightValidation } from './hooks/usePetWeightValidation';
import styles from './PetWeightForm.module.css';

export function PetWeightForm(props: TOSAComponentInput<BranchNodeType>) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const educationRef = useRef<HTMLDivElement>(null);

  const { formMethods, formSubmitRequest, progress, useFeature, node } = props;
  const formValues = formMethods.getValues();
  const { position } = getNodeNameAndPosition(props.node.name);
  const weightField = `${NodeNames.Weight}-${position}` as const;
  const targetWeightField = `${NodeNames.TargetWeight}-${position}` as const;
  const breedsField = `${NodeNames.Breeds}-${position}` as const;
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const bodyCondition = getPositionedProperty({
    formValues,
    nodeName: node.name,
    property: 'bodyCondition',
  });

  const targetWeightFormValue = getPositionedProperty({
    formValues,
    nodeName: node.name,
    property: 'targetWeight',
  });

  const isBranchCompleted = progress.getBranchComplete(node.__self__);

  const { showBodyConditionPersonalization, isWeightLossTreatment } =
    useWeightLossPersonalizationExperiment({
      useFeature,
      bodyCondition: bodyCondition as string,
    });

  const { validateWeight, handleWeightValidationError } =
    usePetWeightValidation({
      weightField,
      targetWeightField,
      setError: formMethods.setError,
      useFeature,
    });

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });
    const weight = data[weightField];
    const targetWeight = data[targetWeightField];
    const breeds = data[breedsField];
    if (!breeds || !isNotNullUndefinedOrEmptyString(weight)) {
      return throwToErrorBoundary(new Error('Breeds and weight are required'));
    }

    const { isValid, weightError, targetWeightError } = await validateWeight({
      breeds,
      weight,
      targetWeight,
    });

    if (!isValid) {
      return handleWeightValidationError({
        weightError,
        targetWeightError,
      });
    }

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  const isTargetWeightFilled =
    targetWeightFormValue !== undefined && targetWeightFormValue !== '';

  const shouldScrollToEducationBox =
    isBranchCompleted &&
    showBodyConditionPersonalization &&
    isWeightLossTreatment &&
    isTargetWeightFilled;

  useEffect(() => {
    if (shouldScrollToEducationBox) {
      scrollOncePerSession({ ref: educationRef, id: node.name });
    }
  }, [shouldScrollToEducationBox, node.name]);

  return (
    <>
      <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
        <BranchNode {...props} />
        <SubmitButton
          smallWhiteSpace={showBodyConditionPersonalization}
          loading={formSubmitRequest.loading}
          {...props}
        />
      </form>
      {shouldScrollToEducationBox && (
        <PetWeightFormBodyConditionEducation
          ref={educationRef}
          isWeightLoss={isWeightLossTreatment}
        />
      )}
    </>
  );
}

const PetWeightFormBodyConditionEducation = forwardRef(function (
  props: { isWeightLoss: boolean },
  ref: ForwardedRef<HTMLDivElement>
) {
  const { isWeightLoss } = props;
  const { heading, information } = getBodyConditionEducationDetails({
    isWeightLoss,
  });

  return (
    <div className={styles.bodyConditionEducationBox} ref={ref}>
      <PersonalizedEducation heading={heading} information={information} />
    </div>
  );
});
