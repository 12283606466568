import { Logger } from '@farmersdog/logger';
import { page as analyticsPackagePage } from '@farmersdog/analytics';
import { isStandaloneDisplayMode } from '@farmersdog/utils/src/isStandaloneDisplayMode';

import config from 'src/config';

import { getGeolocationData } from './getGeolocationData';

const log = new Logger('app:analytics');

export interface Properties extends Record<string, unknown> {
  title: string;
  standalone?: boolean;
}

function logUndefinedPageFunction() {
  log.debug(
    'Could not track page view because `analytics.page` is not defined'
  );
}

/**
 * Track a page view. This function is a wrapper of Segment's `page()`. See
 * https://segment.com/docs/spec/page/ for more details).
 *
 * @param path - The _relative_ page path. Defaults to the current page.
 * @param properties - Free-form dictionary of properties of the page. `url` and `path` are added by default.
 */
function page(path: string, properties: Properties): void | undefined {
  const geolocationData = getGeolocationData();
  const props = {
    ...properties,
    ...geolocationData,
    path, // must include path or google analytics won't see this
    url: `${String(config('app.publicUrl'))}${path}`,
  };

  if (isStandaloneDisplayMode()) {
    props.standalone = true;
  }

  log.debug('Tracking page view', { path, props });

  analyticsPackagePage({
    path,
    properties: props,
    callbackIfMethodIsUndefined: logUndefinedPageFunction,
  });
}

export default page;
