import { track } from '@farmersdog/analytics';

export const TEMPORARY_PAUSE_FLOW_SUCCESS = 'Temporary Pause - Flow Success';

interface TrackTemporaryPauseSuccessPayload extends Record<string, unknown> {
  previousShipDate: string;
  rescheduledShipDate: string;
}

export function trackTemporaryPauseSuccess(
  payload: TrackTemporaryPauseSuccessPayload
) {
  track<TrackTemporaryPauseSuccessPayload>(
    TEMPORARY_PAUSE_FLOW_SUCCESS,
    payload
  );
}
