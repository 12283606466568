// TODO - to be imported from tosa-npm
export const PLURAL_PLACEHOLDER = '%plural%';

interface HandlePluralPlaceholdersProps {
  input: string;
  isPlural: boolean;
}

export function handlePluralPlaceholders({
  input,
  isPlural,
}: HandlePluralPlaceholdersProps) {
  return input.includes(PLURAL_PLACEHOLDER)
    ? input.split(PLURAL_PLACEHOLDER).join(isPlural ? 's' : '')
    : input;
}
