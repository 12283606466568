import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { couponCookie } from '@farmersdog/coupons';

import { trackRecipesSurpriseHigherDiscount } from '../../../../analytics';

export interface UseSurpriseHigherDiscountArgs {
  shouldReplaceDiscount: boolean;
  code: string;
}

export function useSurpriseHigherDiscountRecipesPage({
  shouldReplaceDiscount,
  code,
}: UseSurpriseHigherDiscountArgs) {
  const [isReplacingHistory, setIsReplacingHistory] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const addSurpriseDiscountReferrerCoupon = useCallback(
    ({ currentDiscountCode }: { currentDiscountCode: string }) => {
      setIsReplacingHistory(true);
      const urlParams = new URLSearchParams(location.search);

      // make sure someone isn't actively trying to apply a pcode via URL
      if (urlParams.has('c')) {
        setIsReplacingHistory(false);
        return;
      }

      trackRecipesSurpriseHigherDiscount({
        oldCode: currentDiscountCode,
        newCode: code,
      });

      urlParams.set('c', code);
      history.replace({ ...location, search: urlParams.toString() });
      setIsReplacingHistory(false);
    },
    [history, location, code]
  );

  useEffect(() => {
    if (!shouldReplaceDiscount || isReplacingHistory || !code) {
      return;
    }

    const currentDiscountCode = couponCookie.get()?.referrerCode ?? '';

    if (currentDiscountCode === code) {
      return;
    }

    addSurpriseDiscountReferrerCoupon({ currentDiscountCode });
  }, [
    shouldReplaceDiscount,
    isReplacingHistory,
    code,
    addSurpriseDiscountReferrerCoupon,
  ]);
}
