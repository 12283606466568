import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { PageWidth } from '@farmersdog/corgi-x';
import Picture, { LoadingOption } from 'src/components/Picture';
import { useCorgiViewport } from 'src/screen';

import styles from './DentalDiseaseSection.module.scss';

import { Content } from '../Content';
import { CTA } from '../CTA';

import { sections, images } from '../../data';

export interface DentalDiseaseProps {
  /** Optional class name */
  className?: string;
}

export function DentalDiseaseSection({ className }: DentalDiseaseProps) {
  return (
    <PageWidth
      className={classNames(
        className,
        styles.container,
        useCorgiViewport({
          xs: styles.xs,
          lg: styles.lg,
        })
      )}
    >
      <ContentWidth fr={2} alignSelf="start" withPadding>
        <Content className={styles.content}>
          {sections.dentalDisease.content}
        </Content>
        <CTA>{sections.dentalDisease.cta}</CTA>
      </ContentWidth>
      <ContentWidth fr={1} alignSelf="end">
        <Picture
          className={styles.image}
          loading={LoadingOption.lazy}
          sources={images.dentalDiseaseGraph.sources}
          alt={images.dentalDiseaseGraph.alt}
        />
      </ContentWidth>
    </PageWidth>
  );
}
