import {
  brunoSrc,
  diegoAfterSrc,
  diegoBeforeSrc,
  obieAfterSrc,
  obieBeforeSrc,
  tobyAfterSrc,
  tobyBeforeSrc,
  maxieSueAndFrenchieSrc,
} from './assets';

export const weightLossTestimonials = [
  {
    petName: 'Obie',
    before: {
      source: obieBeforeSrc,
      alt: 'Obie, a cheerful pet, before starting the weight loss journey',
    },
    after: {
      source: obieAfterSrc,
      alt: 'Obie, looking fit and happy, after losing 22lbs and reaching his ideal weight',
    },
    description: 'Lost 22lbs, now at his ideal weight',
  },
  {
    petName: 'Diego',
    before: {
      source: diegoBeforeSrc,
      alt: 'Diego, a lively pet, before starting the weight loss journey',
    },
    after: {
      source: diegoAfterSrc,
      alt: 'Diego, looking energetic and healthy, after losing 11lbs within weeks',
    },
    description: 'Lost 11lbs in a few months',
  },
  {
    petName: 'Toby',
    before: {
      source: tobyBeforeSrc,
      alt: 'Toby, a playful pet, before starting the weight loss journey',
    },
    after: {
      source: tobyAfterSrc,
      alt: 'Toby, looking active and content, after after losing 20lb and reaching his ideal weight',
    },
    description: '20lbs down & thriving as a senior',
  },
];

export const genericTestimonials = [
  {
    petName: 'Bruno',
    source: brunoSrc,
    alt: 'Bruno, a happy pet, looking healthy and fit',
    description:
      'Bruno has been dining on TFD for two months now and he looks like a two year old. His coat absolutely gleams with health. His eyes are bright and his energy level is through the roof!',
  },
  {
    petName: 'Toby',
    source: tobyAfterSrc,
    alt: 'Toby, a cheerful pet, looking healthy and fit',
    description:
      'In just 4 months of his fresh food plan,Toby has lost 20 pounds! No change in lifestyle, just a better diet. Thank you!',
  },
  {
    petName: 'Maxie Sue and Frenchie',
    source: maxieSueAndFrenchieSrc,
    alt: 'Maxie Sue and Frenchie, two cheerful pets, looking healthy and fit',
    description:
      'They’re both senior dogs and since we started feeding them The Farmer’s Dog, they run and run on the beach daily with more energy and zest than we have seen in years!',
  },
];
