import {
  anonymousId,
  ANONYMOUS_ID_MISSING_VALUE,
} from '@farmersdog/lead-browser-storage';

export function getAnonymousIdInput(): string | undefined {
  const tfdAnonymousId = anonymousId.get();
  return tfdAnonymousId !== ANONYMOUS_ID_MISSING_VALUE
    ? tfdAnonymousId
    : undefined;
}
