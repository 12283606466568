import { formatNames } from '../../utils';

// TODO - to be imported from tosa-npm
export const PET_NAMES_PLACEHOLDER = '%petNames%';

interface HandlePetNamesPlaceholders {
  input: string;
  petNames: string[];
}

export function handlePetNamesPlaceholders({
  input,
  petNames,
}: HandlePetNamesPlaceholders) {
  return input.includes(PET_NAMES_PLACEHOLDER)
    ? input.split(PET_NAMES_PLACEHOLDER).join(formatNames(petNames) ?? '')
    : input;
}
