import times from 'lodash/times';

import { NodeNames } from '@farmersdog/constants';

import { Position } from '../../blueprint/types';
import { PetInputForQuote, ProductLineType } from '../../graphql/types';
import { FormFieldsType, PetRecipes } from '../../types';
interface ConvertToPetInputsForQuoteArgs {
  data: FormFieldsType;
  isCustomizingRecipes: boolean | null;
  currentPetName: string;
  petRecipes: PetRecipes;
}

export function convertToPetInputsForQuote({
  data,
  isCustomizingRecipes,
  currentPetName,
  petRecipes,
}: ConvertToPetInputsForQuoteArgs): Omit<
  PetInputForQuote,
  'requestedCalories'
>[] {
  const numPets = Number(data.numPets);

  return times(numPets, i => {
    const indexKey = String(i + 1) as Position;

    const name = data[`${NodeNames.Name}-${indexKey}`];

    if (!name) {
      throw new Error(
        `required pet attribute for quote is undefined or null - attribute name: "name", pet index: "${indexKey}"`
      );
    }

    const recommendation =
      petRecipes[name]
        ?.filter(recipe => recipe.recommended)
        .map(recipe => recipe.name) ?? [];

    let selection = data[`${NodeNames.FreshSelection}-${indexKey}`];

    const hasSelection = selection && selection.length > 0;
    const isCurrentPet = name === currentPetName;

    if (isCurrentPet) {
      // use recommendation as selection to fetch quote for current pet
      if (!isCustomizingRecipes) {
        selection = recommendation;
      }
    } else {
      // since selection state would be cleared if recommendation was chosen,
      // the recommendation state is the other pet's selection if it is present but the selection state isn't
      if (!hasSelection) {
        selection = recommendation;
      }
    }

    if (!selection) {
      throw new Error(
        `required pet attribute for quote is undefined or null - attribute name: "selection", pet name: "${name}"`
      );
    }

    return {
      name,
      selection: {
        diy: null,
        fresh: {
          productLine: ProductLineType.Fresh,
          options: { recipes: selection },
        },
      },
    };
  });
}
