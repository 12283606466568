import { Banner, IconButton, Link, Text } from '@farmersdog/corgi-x';
import { Close } from '@farmersdog/corgi-x/icons';
import styles from './TermsAndConditionsChangeBanner.module.css';
import { paths } from '@farmersdog/constants';
import { useState } from 'react';
import { track } from '@farmersdog/analytics';
import { events } from 'src/analytics';
import { TERMS_CHANGES_NOTICE_DISMISSED_COOKIE } from 'src/constants/cookie';
import Cookie from 'js-cookie';
import { getCookieDomain } from '@farmersdog/utils';

export enum TermsAndConditionsChangeBannerVariant {
  Home = 'home',
  AccountLogin = 'account_login',
}

interface TermsAndConditionsChangeBannerProps {
  variant: TermsAndConditionsChangeBannerVariant;
}

export function TermsAndConditionsChangeBanner({
  variant,
}: TermsAndConditionsChangeBannerProps) {
  const haveTermsBeenPreviouslyDismissed =
    Cookie.get(TERMS_CHANGES_NOTICE_DISMISSED_COOKIE) === 'true';

  const [isBannerVisible, setIsBannerVisible] = useState(
    !haveTermsBeenPreviouslyDismissed
  );
  const handleClose = () => {
    setIsBannerVisible(false);
    Cookie.set(TERMS_CHANGES_NOTICE_DISMISSED_COOKIE, 'true', {
      expires: 30,
      domain: getCookieDomain(),
    });
    track(events.terms_changes_notice_dismissed);
  };

  const textVariant =
    variant === TermsAndConditionsChangeBannerVariant.Home
      ? 'article-12'
      : 'article-16';

  return (
    isBannerVisible && (
      <Banner
        variant={
          variant === TermsAndConditionsChangeBannerVariant.Home
            ? 'white'
            : 'kale-2'
        }
        className={
          variant === TermsAndConditionsChangeBannerVariant.Home
            ? styles.homeBanner
            : styles.accountBanner
        }
      >
        <div
          className={styles.bannerContentContainer}
          data-testid="terms-change-notice-banner"
        >
          <Text
            variant={textVariant}
            className={
              variant === TermsAndConditionsChangeBannerVariant.Home
                ? styles.homeBannerText
                : styles.bannerText
            }
            color={variant === 'home' ? 'Kale3' : 'white'}
          >
            Please see our updated{' '}
            <Link href={paths.PATH_PRIVACY} target="_blank">
              <Text variant={textVariant} underline>
                Privacy Policy
              </Text>{' '}
            </Link>
            and{' '}
            <Link href={paths.PATH_TERMS} target="_blank">
              <Text variant={textVariant} underline>
                Terms of Use
              </Text>
            </Link>
            .
          </Text>
          <IconButton
            icon={
              <Close
                baseColor={
                  variant === TermsAndConditionsChangeBannerVariant.Home
                    ? 'Kale2'
                    : 'Kale1'
                }
                size={25}
              />
            }
            aria-label="Close"
            onClick={handleClose}
            className={styles.closeButton}
          />
        </div>
      </Banner>
    )
  );
}
