import { UseAddDogFormFields } from '../hooks';
import eventNames from './eventNames';
import { track } from '@farmersdog/analytics';

interface TrackRecipeCompleteArgs {
  recipes: UseAddDogFormFields['recipes'];
}
export function trackRecipeComplete(args: TrackRecipeCompleteArgs) {
  track(eventNames.onRecipesComplete, {
    recipes: args.recipes,
  });
}
