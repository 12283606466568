import { GraphQLFormattedError } from 'graphql';

import { tosaErrorCodes } from '@farmersdog/constants';

interface GetKnownErrorMessageArgs {
  error: GraphQLFormattedError;
  isTargetWeight?: boolean;
}

export function getKnownErrorMessage({
  error,
  isTargetWeight = false,
}: GetKnownErrorMessageArgs) {
  const errorMessage = error.extensions?.code as string;
  switch (errorMessage) {
    case tosaErrorCodes.WEIGHT_TOO_LIGHT: {
      return isTargetWeight
        ? tosaErrorCodes.TARGET_WEIGHT_TOO_LIGHT
        : errorMessage;
    }
    case tosaErrorCodes.BREED_WEIGHT_TOO_HEAVY:
    case tosaErrorCodes.WEIGHT_TOO_HEAVY: {
      return isTargetWeight
        ? tosaErrorCodes.TARGET_WEIGHT_TOO_HEAVY
        : errorMessage;
    }
    default: {
      return undefined;
    }
  }
}
