import { Link } from '@farmersdog/corgi';
import { GridContainer, Wave, Text } from '@farmersdog/corgi-x';

import { content, HowToContent } from './content';

import Picture from 'src/components/Picture';

import { useCorgiV2Viewport } from 'src/screen';

import styles from './HowTo.module.scss';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToDiySignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';

import { ScrollVideo } from 'src/pages/WhyFresh/components';

interface CheckListItemArgs extends HowToContent {
  index: number;
}

function ChecklistItem({ index, icon, title, description }: CheckListItemArgs) {
  return (
    <li className={styles.checklistItem}>
      <Picture sources={[icon]} alt={`How to mix step ${index + 1}`} />
      <Text variant="heading-22" color="kale-3" bold as="h3">
        {index + 1}. {title}
      </Text>
      <Text variant="article-20">{description}</Text>
    </li>
  );
}

function Checklist() {
  return (
    <GridContainer className={styles.checklist} as="ul">
      {content.map((step, index) => {
        return <ChecklistItem {...step} key={index} index={index} />;
      })}
    </GridContainer>
  );
}

const videoIds = {
  xs: 'vuqrixfeql',
  lg: '1j3u49ilwa',
};

export function HowTo() {
  const responsiveClassName = useCorgiV2Viewport<string>({
    xs: styles.xs,
    lg: '',
  });

  return (
    <section>
      <Wave padding="margin-xxl" withBottom className={responsiveClassName}>
        <GridContainer className={styles.videoPreview}>
          <div>
            <ScrollVideo videoIds={videoIds} />
          </div>
        </GridContainer>
        <Text
          variant="heading-40"
          color="kale-3"
          bold
          as="h2"
          className={styles.heading}
        >
          How to make homemade dog food
        </Text>
        <Checklist />
        <div className={styles.cta}>
          <Link to={getLinkToDiySignup(PATH_SIGNUP_ME)} asButton>
            Get Started
          </Link>
        </div>
      </Wave>
    </section>
  );
}
