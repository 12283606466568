import { Script } from './Script';
import { Helmet } from 'react-helmet-async';

interface SdkLoaderScriptArgs {
  fallbackScript: string;
  sdkLoader: string;
}

/*
  This is a tool that can be used in the case that we have a third party script that needs to initialize 
  quickly and may otherwise miss important events on our site when we defer third party script execution 
  until after the browser reaches a certain point in the readiness lifecycle.

  This component accepts a fallback script that would contain all of the usual content provided by the
  third party for their script tag, but with the sdk loader portion stripped out separately (this would
  often be labeled `vendorObject.load`, etc.). This enables the primary script content to be loaded on the
  SSR side so it's available and then the sdk loader is called in our usual `Script` component.
*/

export const SdkLoaderWithFallbackScript = ({
  fallbackScript,
  sdkLoader,
}: SdkLoaderScriptArgs) => {
  return (
    <>
      <Helmet>
        <script>{fallbackScript}</script>
      </Helmet>
      <Script>{sdkLoader}</Script>
    </>
  );
};
