import checkBoardJp2 from './check-board-placeholder.jp2';
import checkBoardPng from './check-board-placeholder.png';
import checkBoardWebp from './check-board-placeholder.webp';
import checkBoardGif from './check-board.gif';
import foodBowlJp2 from './food-bowl-placeholder.jp2';
import foodBowlPng from './food-bowl-placeholder.png';
import foodBowlWebp from './food-bowl-placeholder.webp';
import foodBowlGif from './food-bowl.gif';
import foodPacksJp2 from './food-packs-placeholder.jp2';
import foodPacksPng from './food-packs-placeholder.png';
import foodPacksWebp from './food-packs-placeholder.webp';
import foodPacksGif from './food-packs.gif';

export const foodPacksPlaceholderSrc = [
  foodPacksWebp,
  foodPacksPng,
  foodPacksJp2,
];
export const foodPacksSrc = [foodPacksGif];

export const foodBowlPlaceholderSrc = [foodBowlWebp, foodBowlPng, foodBowlJp2];
export const foodBowlSrc = [foodBowlGif];

export const checkBoardPlaceholderSrc = [
  checkBoardWebp,
  checkBoardPng,
  checkBoardJp2,
];
export const checkBoardSrc = [checkBoardGif];
