export const COUPON_COOKIE_V1_NAME = 'referral';
export const COUPON_COOKIE_VERSION = 1;

export const PATH_COUPON_CODE = '/p';
export const PATH_COUPON_CODE_MATCH = `${PATH_COUPON_CODE}/:couponCode`;

export const USER_REFERRER_QUERY_PARAM = 'ref';
export const COUPON_CODE_QUERY_PARAM = 'c';

export const ONE_DAY = 1000 * 60 * 60 * 24;
export const MAX_COOKIE_EXPIRATION = new Date('Tue, 19 Jan 2038 04:14:07 GMT');
export const COUPON_COOKIE_EXPIRE_DAYS = ONE_DAY * 28;
