import { events, track } from 'src/analytics';

/**
 * This function tracks the customer’s desired treat
 * @param treatText - The customer’s desired treat
 */
export function trackRequestATreat(params: { treatText: string }) {
  track(events.treats_request_a_treat, {
    treatText: params.treatText,
  });
}
