import { useEffect } from 'react';
import { useLocation } from 'react-router';
import dogWithBone from '../../assets/dog-with-bone.png';
import styles from './NotFound.module.css';
import { PATH_HOME } from '@farmersdog/constants/paths';
import { Helmet } from 'react-helmet-async';
import { Button, ContentWidth, PageWidth, Text } from '@farmersdog/corgi-x';
import { reporter } from '../../errors/services/reporter';

export default function NotFound() {
  const title = 'Page Not Found';
  const location = useLocation();
  useEffect(() => {
    reporter.error('Page not found', {
      path: location.pathname,
    });
  }, [location.pathname]);
  return (
    <main aria-label={title}>
      <Helmet title={title} />
      <PageWidth>
        <ContentWidth alignSelf="center" className={styles.container}>
          <Text variant="heading-28" color="kale-3" as="h4" bold>
            Page not found
          </Text>
          <Text variant="article-16" as="p">
            Woof! This page doesn’t exist!
          </Text>
          <Text variant="heading-12" as="p" color="charcoal-1">
            Please double-check the URL is valid.
          </Text>
          <img src={dogWithBone} className={styles.dogWithBone} alt="Poo" />
          <div>
            <Button type="link" to={PATH_HOME}>
              <Text variant="heading-16" bold>
                To home page
              </Text>
            </Button>
          </div>
        </ContentWidth>
      </PageWidth>
    </main>
  );
}
