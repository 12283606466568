import { Button, Grid, GridItem, Picture, Text } from '@farmersdog/corgi-x';
import { dogInBoxSources } from '../../../assets/suggest-order-size';
import { formatDateToMonthAndDay } from '../../../utils';
import styles from './Confirmation.module.css';

interface ConfirmationProps {
  acceptedSuggestion: boolean;
  daysOfFood: number;
  earliestDesiredArrivalDate: string;
  latestDesiredArrivalDate: string;
  onContinueToPlan: () => void;
}

/**
 * Displays next order details after user accepts or rejects plan suggestion
 */
export function Confirmation(props: ConfirmationProps) {
  const headingCopy = props.acceptedSuggestion ? 'Done!' : 'You’re all set!';
  const planCopy = props.acceptedSuggestion
    ? `We’ve updated your plan.`
    : `We’ll keep you on the same plan.`;
  const dateRangeCopy = `${formatDateToMonthAndDay(
    props.earliestDesiredArrivalDate
  )} - ${formatDateToMonthAndDay(props.latestDesiredArrivalDate)}`;

  return (
    <Grid
      className={styles.grid}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      topSpacing={'md'}
    >
      <Picture
        sources={dogInBoxSources}
        alt="dog in box"
        className={styles.image}
      />
      <GridItem>
        <Grid
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          rowGap={'xs'}
          topSpacing={'lg'}
        >
          <Text as="h1" color="kale-3" variant="heading-40" bold>
            {headingCopy}
          </Text>
          <Text variant="article-20" color="charcoal-3">
            {`${planCopy} Your next order will contain`}
            <Text variant="article-20" bold>
              {` ${props.daysOfFood} days of food `}
            </Text>
            and be delivered between
          </Text>

          <Text as="div" variant="heading-22" color="kale-3" bold>
            {dateRangeCopy}
          </Text>
        </Grid>
      </GridItem>
      <GridItem className={styles.buttonContainer}>
        <Button
          className={styles.button}
          variant="secondary"
          type="button"
          onClick={props.onContinueToPlan}
        >
          See My Plan
        </Button>
      </GridItem>
    </Grid>
  );
}
