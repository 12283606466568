import pick from 'lodash/pick';
import { identify } from 'src/analytics';
import {
  SIGNUP_FETCH_PREVIOUS_USER,
  SIGNUP_REGISTER,
  SIGNUP_REPLACE_USER,
  SIGNUP_SET_USER_REFERRER,
  SIGNUP_UPDATE_FOOD_PLAN,
  SIGNUP_SET_DIY_SUBSCRIPTION,
  USER_UPDATE_REFERRER,
} from 'src/constants/actionTypes';
import * as SubscriptionStatus from 'src/constants/subscriptionStatus';
import localStorageUser from 'src/utils/localStorageUser';
import merge from 'src/utils/mergeObjects';
import { getSubscriptionByType } from 'src/pages/Signup/DIY/utils/getSubscriptionByType';
import { SubscriptionType } from 'src/graphql/types';

/*
 * Handle user data. For privacy reasons, we separate checkout from user data,
 * so that sensitive data such as shipping address are not visibile to the user
 * except when checking out.
 *
 * */

const initialState = {
  email: null,
  firstName: null,
  shippingAddress: {
    zip: null,
  },
  billingAddress: {
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    zip: null,
  },

  freshFoodConfidence: null,

  referrerId: null,
  referralCode: null,
  referrerType: null,
  referrerAssociatedAt: null,

  pets: [],
};

export default function signupUserReducer(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_REFERRER: {
      return {
        ...state,
        ...pick(action.payload.data, [
          'referrerId',
          'referralCode',
          'referrerType',
          'referrerAssociatedAt',
        ]),
      };
    }
    case SIGNUP_REGISTER:
    case SIGNUP_FETCH_PREVIOUS_USER: {
      const user = action.payload.data;
      try {
        identify(user);
      } catch {
        // ignore identify errors
      }

      // Moving forward plan is now attached to the subscription, but the API is
      // maintaining backwards capabilities for the CRM thus certain endpoints
      // will respect pets.plan.  Since the website has moved forward with the
      // API we should no longer send or use the pet.plan data.
      if (user.pets) {
        user.pets.forEach(pet => delete pet.plan);
      }

      if (user.subscription.status === SubscriptionStatus.active) {
        return state;
      }

      return merge({}, state, user);
    }
    case SIGNUP_SET_USER_REFERRER:
    case SIGNUP_REPLACE_USER: {
      const { email, pets } = action.payload;
      let newPets = pets;

      // Remove ids if the email changes
      if (email && email !== state.email && state.pets.some(p => p.id)) {
        newPets = state.pets.map(p => ({ ...p, id: undefined }));
      }
      const newState = merge({}, state, action.payload, { pets: newPets });
      localStorageUser.set(newState);
      return newState;
    }

    case SIGNUP_UPDATE_FOOD_PLAN: {
      const { petId, data } = action.payload;

      const lastPlans =
        (state.subscription && state.subscription.foodPlans) || [];

      const nextPlans = lastPlans.map(foodPlan => {
        if (foodPlan.petId === petId) {
          return {
            ...foodPlan,
            ...data,
          };
        }

        return foodPlan;
      });

      return merge({}, state, {
        subscription: { foodPlans: nextPlans },
      });
    }

    case SIGNUP_SET_DIY_SUBSCRIPTION: {
      const diySubscription = getSubscriptionByType({
        subscriptions: state.subscriptions,
        type: SubscriptionType.Diy,
      });

      const nextSubscriptions = diySubscription
        ? state.subscriptions.map(subscription => {
            if (subscription.id === diySubscription.id) {
              return {
                ...subscription,
                ...action.payload,
              };
            }

            return subscription;
          })
        : [
            ...state.subscriptions,
            { ...action.payload, type: SubscriptionType.Diy },
          ];

      return merge({}, state, {
        subscriptions: nextSubscriptions,
      });
    }

    default:
      return state;
  }
}

/**
 * Get the entire user object
 *
 * @param {Object} state - the redux state object
 * @return {Reducer.User}
 */
export function selectUser(state) {
  return state.signup.user;
}

export function selectUserEmail(state) {
  return selectUser(state).email;
}

export function selectUserShippingAddress(state) {
  return selectUser(state).shippingAddress;
}

export function selectUserShippingZip(state) {
  const shippingAddress = selectUserShippingAddress(state);
  return shippingAddress?.zip;
}

/**
 * Get all pets for user
 *
 * @param {Object} state - the redux state object
 * @return {Reducer.Pet[]}
 */
export function selectPets(state) {
  return selectUser(state).pets;
}

export function selectSubscription(state) {
  return selectUser(state).subscription;
}

export function selectSubscriptionStartDate(state) {
  return selectSubscription(state).startDate;
}

export function selectSubscriptions(state) {
  return selectUser(state).subscriptions;
}

export function selectDiySubscription(state) {
  const subscriptions = selectSubscriptions(state);
  return getSubscriptionByType({
    subscriptions,
    type: SubscriptionType.Diy,
  });
}

export function selectDiySubscriptionCadence(state) {
  const diySubscription = selectDiySubscription(state);
  return diySubscription?.frequency;
}

export function selectReferrer(state) {
  return pick(selectUser(state), [
    'referrerId',
    'referrerCode',
    'referrerType',
    'referrerAssociatedAt',
  ]);
}

/**
 * Finds a pet by the pet id
 *
 * @param {Object} state - the redux state object
 * @param {number} id - Id of the pet
 * @return {Reducer.Pet | undefined}
 */
export function selectPetById(state, id) {
  return selectPets(state).find(pet => pet.id === id);
}

/**
 * Select the food plans associated with all of the pets from the user object.
 *
 * @param {Object} state the redux state object
 * @return {Array<Reducer.FoodPlan>} an array of plan objects
 */
export function selectFoodPlans(state) {
  const subscription = selectSubscription(state);

  return (subscription && subscription.foodPlans) || [];
}

/**
 * Select the active food plans associated with all of the pets from the user
 * object object.
 *
 * @param {Object} state the redux state object
 *
 * @return {Array<Reducer.FoodPlan>} an array of plan objects
 */
export function selectActiveFoodPlans(state) {
  const subscription = selectSubscription(state);
  const foodPlans = (subscription && subscription.foodPlans) || [];

  return foodPlans.filter(foodPlan => foodPlan && foodPlan.active);
}

/**
 * Select all pets that currently have a foodPlan with the boolean field active
 * set to true.
 *
 * @param {Object} state The global redux state.
 *
 * @return {Array<Reducer.Pet>} An array of pets with active food plans
 */
export function selectActivePets(state) {
  return selectPets(state).filter(pet => {
    const foodPlan = selectFoodPlanByPetId(state, pet.id);
    return foodPlan && foodPlan.active;
  });
}

export function selectLastActivePet(state) {
  const activePets = selectActivePets(state);
  return activePets.length ? activePets[activePets.length - 1] : undefined;
}

/**
 * Select the index of the first active pet in the pets array
 *
 * @param {Object} state The global redux state.
 *
 * @returns {Number} Index of first active pet in pets array
 */
export function selectIndexOfFirstActivePet(state) {
  const activePets = selectActivePets(state);
  const pets = selectPets(state);

  const firstActivePet = activePets[0];

  return Math.max(
    pets.findIndex(
      pet => (pet && pet.id) === (firstActivePet && firstActivePet.id)
    ),
    0
  );
}

export function selectIndexOfPet(state, id) {
  const pets = selectPets(state);
  return pets.findIndex(pet => (pet && pet.id) === id);
}

/**
 * Select the plan associated with the pet from the user object.
 *
 * @param {Object} state the redux state object
 * @param {Number} id the id of the pet
 *
 * @return {Reducer.FoodPlan | null} the plan object
 */
export function selectFoodPlanByPetId(state, id) {
  const foodPlans = selectFoodPlans(state);

  return foodPlans.find(foodPlan => foodPlan.petId === id) || null;
}

/**
 * Select the next pet in the flow based on the current pet index.
 *
 * @param {Object} state The global redux state.
 * @param {Number} currentPetIndex The index of the current active pet
 *
 * @return  The next pet in the flow
 *
 */
export function selectNextPet(state, currentPetId) {
  const currentPetIndex = selectIndexOfPet(state, currentPetId);
  return selectPets(state).slice(currentPetIndex + 1)[0];
}

/**
 * Select the next pet in the flow that has a currently active food plan. Some
 * pets may be ignored at certain points in the signup flow if their food plan
 * is set to `active = false`. This allows us to select the next pet that
 * matches that logic.
 *
 * @param {Object} state The global redux state.
 * @param {Number} currentPetIndex The index of the current active pet
 *
 * @return {Reducer.Pet | undefined} The next pet that has an active plan
 *
 */
export function selectNextActivePet(state, currentPetId) {
  const currentPetIndex = selectIndexOfPet(state, currentPetId);
  return selectPets(state)
    .slice(currentPetIndex + 1)
    .filter(pet => {
      const foodPlan = selectFoodPlanByPetId(state, pet.id);
      if (foodPlan) {
        return foodPlan.active;
      }

      return true;
    })[0];
}

/**
 * Select the names of all pets whether they are active or not.
 *
 * @param {Object} state The global redux state.
 *
 * @return {Array<String>} An array of the names of all pets
 */
export function selectPetNames(state) {
  return selectPets(state).map(pet => pet.name);
}

/**
 * Select a boolean value indicating whether the mixing plan option can be
 * shown. The food plans associated with the pet must meet certain business
 * criteria in order to qualify for a mixing plan.
 *
 * @param {Object} state the redux state object
 *
 * @return {Boolean} the boolean value indicating if mixing plans can be used
 */
export function selectAllowChangeMealSize(state) {
  return selectFoodPlans(state).some(
    foodPlan =>
      foodPlan.active &&
      foodPlan.calories * foodPlan.portion * foodPlan.ratio > 201
  );
}

/**
 * Return the boolean value indicating whether the user has been confirmed not
 * to be suspected of fraud.
 *
 * @param {Object} state the redux state object
 *
 * @return {Boolean}
 */
export function selectFraudOverrideEnabled(state) {
  const user = selectUser(state);

  if (!user) {
    return undefined;
  }

  return user.fraudOverrideEnabled;
}
