import { QueryResult, useQuery } from '@apollo/client';
import { FetchAddDogFormDataQuery } from './fetchAddDogFormData.cgs.generated-types';
import FetchAddDogFormData from './fetchAddDogFormData.cgs.graphql';

type PartialQueryResult = Pick<
  QueryResult<FetchAddDogFormDataQuery>,
  'loading' | 'error'
>;

interface UseFetchAddDogFormData extends PartialQueryResult {
  availableRecipes:
    | FetchAddDogFormDataQuery['customer']['pets'][number]['availableFreshFoodProducts']
    | undefined;
  preSelectedRecipes: string[] | undefined;
  breeds: FetchAddDogFormDataQuery['petCharacteristics']['breeds'] | undefined;
  pendingPet: FetchAddDogFormDataQuery['customer']['pendingPet'] | undefined;
}

export function useFetchAddDogFormData(): UseFetchAddDogFormData {
  const { loading, data, error } = useQuery<FetchAddDogFormDataQuery>(
    FetchAddDogFormData,
    {
      context: { endpoint: 'cgs' },
    }
  );

  const breeds = data?.petCharacteristics?.breeds;
  const pets = data?.customer?.pets ?? [];
  const petsInAscendingOrder = [...pets].sort((a, b) => a.id - b.id);

  const recentlyAddedPet =
    petsInAscendingOrder.length > 0
      ? petsInAscendingOrder[petsInAscendingOrder.length - 1]
      : undefined;

  const pendingPet = data?.customer?.pendingPet;

  return {
    loading,
    error,
    breeds,
    preSelectedRecipes: recentlyAddedPet?.foodRecipes.map(
      recipe => recipe.name
    ),
    availableRecipes: recentlyAddedPet?.availableFreshFoodProducts,
    pendingPet,
  };
}
