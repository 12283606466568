import { useRef } from 'react';
import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { PageWidth } from '@farmersdog/corgi-x';
import { FullFooter } from 'src/components/Footer';
import { Navigation } from 'src/components/Navigation';
import Page from 'src/components/Page';

import { useCorgiViewport } from 'src/screen';

import {
  TitledContent,
  Bowl,
  AlignedMediaContent,
  KibbleBull,
  GallerySection,
  ContentHero,
  BeefMealVideo,
  ConveyorVideo,
  DentalDiseaseSection,
  ConveyorContent,
} from './components';

import { hero, sections, videos, metaData } from './data';
import styles from './WhyFresh.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

export function WhyFresh() {
  const ctaReveal = useRef(null);
  const rootClassName = useCorgiViewport({ xs: styles.xs, lg: styles.lg });
  const enablePageWidth = useCorgiViewport({
    xs: false,
    lg: true,
  });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <>
      <Navigation ctaTrigger={ctaReveal} />
      <Page
        className={rootClassName}
        title={metaData.title}
        description={metaData.description}
      >
        <ContentHero
          videoIds={videos.calendarVideoIds}
          // TODO: Fix types in data.js
          head={hero.head as JSX.Element}
          body={hero.body as JSX.Element}
        />
        <div ref={ctaReveal}>
          <DentalDiseaseSection className={styles.section} />
          <ConveyorVideo
            fullWidth={enablePageWidth}
            className={styles.section}
          />
          <PageWidth className={classNames(styles.kibbleBull, styles.section)}>
            <ContentWidth fr={1} alignSelf="start">
              <KibbleBull />
            </ContentWidth>
            <ContentWidth
              fr={2}
              alignSelf="end"
              className={styles.kibbleBullContent}
            >
              <TitledContent {...sections.kibbleBullSection} />
            </ContentWidth>
          </PageWidth>
          <BeefMealVideo
            fullWidth={enablePageWidth}
            className={styles.section}
          />
          <ConveyorContent />
          <div
            className={classNames(styles.section, {
              [styles.accessibilityTheme]: isAccessibilityThemeOn,
            })}
          >
            <GallerySection />
          </div>
          <AlignedMediaContent
            alignMedia="start"
            className={styles.section}
            {...sections.bowlSection}
          />
        </div>
      </Page>
      <Bowl />
      <FullFooter />
    </>
  );
}
