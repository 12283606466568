import { Text } from '@farmersdog/corgi-x';
import styles from './AnalysisTable.module.css';

export interface AnalysisRowProps {
  percentage: string;
  name: string;
}
export function AnalysisRow({ percentage, name }: AnalysisRowProps) {
  return (
    <Text as="tr" variant="article-16" className={styles.tr}>
      <td className={styles.name}>{name}</td>
      <td className={styles.percentage}>{percentage}</td>
    </Text>
  );
}
