import { AdditionalTerms } from 'src/components/AdditionalTerms';

export function OfferTerms() {
  return (
    <AdditionalTerms title="Offer Terms">
      <p>
        Offer valid from September 18, 2024 12:00am ET while supplies last
        online at thefarmersdog.com in the contiguous 48 United States only.
        Offer applicable to new customers only with an active automatic
        recurring subscription service. Offer applies to The Farmer’s Dog fresh
        food products only, not to applicable taxes, shipping & handling
        charges. Offer cannot be combined with any other offers, credits or
        discounts. Offer is non-transferable and not valid for cash or cash
        equivalent. No adjustments on previous purchases. The Farmer’s Dog
        reserves the right to modify, terminate or cancel the offer at any time.
        For inquiries, contact care@thefarmersdog.com.
      </p>
    </AdditionalTerms>
  );
}
