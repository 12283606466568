import { useParams } from 'react-router-dom';

import { EnablementId } from '../components/Enable';

export interface GetSignupParamsArgs {
  pageOrEmail?: string;
  email?: string;
  enablementId?: EnablementId;
}

export function getSignupParams({
  pageOrEmail,
  enablementId,
  email,
}: GetSignupParamsArgs) {
  // /signup/product/enablementId/email
  if (enablementId) {
    return { email: email ?? '', page: 'enable', enablementId };
  }

  // /signup
  if (!pageOrEmail) {
    return { email: '', page: '', enablementId: '' };
  }

  // /signup/page/email
  if (email) {
    return { email, page: pageOrEmail, enablementId: '' };
  }

  // /signup/email
  if (pageOrEmail.indexOf('@') > -1) {
    return { email: pageOrEmail, page: '', enablementId: '' };
  }

  // /signup/page
  return { email: '', page: pageOrEmail, enablementId: '' };
}

export function useSignupParams() {
  const params = useParams<GetSignupParamsArgs>();
  return getSignupParams(params);
}
