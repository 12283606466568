import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import promise from 'redux-promise';

import rootReducer from 'src/reducers/rootReducer';
import { selectToken, selectEmail } from 'src/reducers/auth';
import { logout, refreshToken } from 'src/actions/auth';
import { middleware as requestMiddleware } from '@farmersdog/request-middleware';

import config from 'src/config';

export default function configureStore({ initialState = {} }) {
  let middleware = [thunk, promise];

  middleware.push(
    requestMiddleware({
      selectToken,
      selectEmail,
      refreshToken,
      logout,
      apiUrl: config('app.apiUrl'),
    })
  );
  middleware = applyMiddleware(...middleware);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('@redux-devtools/extension');
    middleware = composeWithDevTools(middleware);
  }

  const store = middleware(createStore)(rootReducer, initialState);

  if (module.hot) {
    module.hot.accept('../reducers/rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../reducers/rootReducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
