import { Script } from 'src/components/Script';
import { ACCESS_TOKEN, PRODUCT_ID } from './trackPeacockEvent';

export enum PEACOCK_EVENTS {
  SESSION_START = 'session_start',
  SIGN_UP = 'sign_up',
  PURCHASE = 'purchase',
}

export interface PeacockApi {
  sendConversion: (eventName: PEACOCK_EVENTS) => void;
}

declare global {
  interface Window {
    pubSuite?: PeacockApi;
  }
}

//Peacock / Kochava
export function PeacockScript() {
  return (
    <Script type="text/javascript">
      {`function loadScript() {
        if (window.pubSuite) {
            console.log("pubSuite snippet already included");
            return;
        }
        const pubSuiteScript = document.createElement("script");
        pubSuiteScript.type = "text/javascript";
        pubSuiteScript.src = "https://storage.googleapis.com/kochava-web-assets/ps/v1.3.0/pubsuite.js";
        pubSuiteScript.async = true;

        pubSuiteScript.onload = () => {
            const product_id = '${PRODUCT_ID}';
            const access_token = '${ACCESS_TOKEN}';
            window.pubSuite.startWithProductId(product_id, access_token);
        };
        document.head.appendChild(pubSuiteScript);
        }
        loadScript();`}
    </Script>
  );
}
