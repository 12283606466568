import { useRef } from 'react';

import Page from 'src/components/Page';
import { Navigation } from 'src/components/Navigation';
import { FullFooter } from 'src/components/Footer';

import Summary from './components/Summary';
import GoogleReviews, {
  generateStructuredReviewData,
  reviewData,
} from './components/GoogleReviews';
import Hero from './components/Hero';
import Veterinarians from './components/Veterinarians';
import Instagram from './components/Instagram';
import BenefitsNav from './components/BenefitsNav';
import BenefitHealth from './components/BenefitHealth';
import BenefitPicky from './components/BenefitPicky';
import BenefitWeight from './components/BenefitWeight';
import CompanyReviews from './components/CompanyReviews';
import VideoBanner from './components/VideoBanner';
import Divider from './components/Divider';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { Helmet } from 'react-helmet-async';

export function Reviews() {
  const health = useRef();
  const picky = useRef();
  const weight = useRef();
  const ctaReveal = useRef(null);

  const structuredReviewData = generateStructuredReviewData(reviewData);

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{structuredReviewData}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <Hero />
      <Page
        title="Fresh Dog Food Reviews & Ratings"
        description="Fresh food has a transformative impact on dogs' health — improving everything from digestion and allergies, to energy levels and life span. Read real customer reviews and see why dogs and their humans love The Farmer’s Dog."
      >
        <div ref={ctaReveal}>
          <Summary />
          <GoogleReviews />
          <VideoBanner />
          <Veterinarians />
          <BenefitsNav health={health} picky={picky} weight={weight} />
          <BenefitHealth ref={health} />
          {!isAccessibilityThemeOn && <Divider />}
          <BenefitPicky ref={picky} />
          <CompanyReviews />
          <BenefitWeight ref={weight} />
          <Instagram />
          <FullFooter />
        </div>
      </Page>
    </>
  );
}
