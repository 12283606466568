export interface SessionData {
  /** session identifier - UUID RFC v4 */
  id: string;
  /** TFD user activity tracker version */
  version: number;
  /** timestamp of session expiration */
  expiration: number;
}

export function isValidSessionData(val: unknown): val is SessionData {
  const safeVal = val ?? {};

  return (
    Object.hasOwn(safeVal, 'id') &&
    Object.hasOwn(safeVal, 'expiration') &&
    Object.hasOwn(safeVal, 'version')
  );
}
