import { getSharedISpotParams } from './getSharedISpotParams';
import { iSpot, ISpotEventName } from './iSpot';

/**
 * Mount the iSpot checkout conversion event
 */

export function mountISpotCheckoutConversion({
  utmSource,
}: {
  utmSource: unknown;
}) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { app, channel, customData } = getSharedISpotParams({
    utmSource,
  });

  iSpot.mount({
    app,
    type: ISpotEventName.CHECKOUT,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
