import {
  signupState,
  SubscriptionType,
} from '@farmersdog/lead-browser-storage';

import { Experiments } from '../../../../types';

import { DiscountBannerInner } from './DiscountBannerInner';
import { FreshDiscountBanner } from './FreshDiscountBanner';

export interface BannerOverride {
  bannerCopy?: string;
  bannerLinkCopy?: string;
  bannerLinkPath?: string;
}

export const discountAmountToken = '~~discountAmount~~';

interface DiscountBannerProps {
  experiments: Experiments;
}

export function DiscountBanner({ experiments }: DiscountBannerProps) {
  const { subscriptionTypes } = signupState.get();
  const productLine = subscriptionTypes.current;

  switch (productLine) {
    case SubscriptionType.Diy: {
      return (
        <DiscountBannerInner copy="Free shipping on DIY orders. Because dogs." />
      );
    }

    case SubscriptionType.Fresh: {
      return <FreshDiscountBanner experiments={experiments} />;
    }
  }
}
