export const eventNames = {
  order_size_up_nudge_success_updated_plan:
    'Order Size Up Nudge - Success Updated Plan',
  order_size_up_nudge_displayed_plan_options:
    'Order Size Up Nudge - Displayed Plan Options',
  order_size_up_nudge_clicked_keep_plan:
    'Order Size Up Nudge - Clicked Keep Current Plan',
  order_size_up_nudge_clicked_see_plan_page:
    'Order Size Up Nudge - Clicked See My Plan',
};
