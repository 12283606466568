import {
  SIGNUP_SET_RECIPES_SUBMITTING,
  SIGNUP_SET_CHECKOUT_SUBMITTING,
} from 'src/constants/actionTypes';

export const initialState = {
  me: false,
  pets: false,
  recipes: false,
  plans: false,
  checkout: false,
};

export default function signupSubmittingReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_SET_RECIPES_SUBMITTING:
      return { ...state, recipes: action.payload };

    case SIGNUP_SET_CHECKOUT_SUBMITTING:
      return { ...state, checkout: action.payload };

    default:
      return state;
  }
}

export function selectMeSubmitting(state) {
  return state.signup.submitting.me;
}
export function selectPetsSubmitting(state) {
  return state.signup.submitting.pets;
}
export function selectRecipesSubmitting(state) {
  return state.signup.submitting.recipes;
}
export function selectPlansSubmitting(state) {
  return state.signup.submitting.plans;
}
export function selectCheckoutSubmitting(state) {
  return state.signup.submitting.checkout;
}
