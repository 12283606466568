import { useRef } from 'react';

import Page from 'src/components/Page';
import Picture, { LoadingOption } from 'src/components/Picture';
import { Navigation } from 'src/components/Navigation';
import {
  Hero,
  OurMission,
  GuidedByScience,
  OurTale,
  Story,
  Today,
  Quotes,
} from './components';
import { FullFooter } from 'src/components/Footer';

import { useScrollRestore } from 'src/hooks/useScrollRestore';

import brettAndJonathanSources from './assets/brett-and-jonathan';
import styles from './AboutUs.module.scss';

function AboutUs() {
  useScrollRestore();
  const ctaReveal = useRef(null);

  return (
    <>
      <Navigation ctaTrigger={ctaReveal} />
      <Hero />
      <div ref={ctaReveal}>
        <Page
          title="Our Story and Mission"
          description="Learn more about how we’re turning our unconditional love for dogs into uncomplicated care."
        >
          <OurMission />
          <GuidedByScience />
          <OurTale />
          <Story />
          <Today />
          <Picture
            className={styles.brettAndJonathan}
            sources={brettAndJonathanSources}
            loading={LoadingOption.lazy}
            alt="Founders of The Farmer's Dog"
          />
          <Quotes />
          <FullFooter />
        </Page>
      </div>
    </>
  );
}

export default AboutUs;
