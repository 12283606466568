type Empty = Record<string, unknown>;

/**
 * Converts a json string into JSON.  If an error occurs
 * default to object as split can not send an array.
 *
 * @param json - JSON string to parse
 */
export function safeJsonParse<T>(json: string | null | undefined): T | Empty {
  if (!json) {
    return {};
  }

  try {
    return JSON.parse(json) as T;
  } catch {
    return {};
  }
}
