const ORDER_SIZE_NUDGE_DISMISSED_KEY = 'order-size-nudge-dismissed';
const ORDER_SIZE_NUDGE_DISMISSED_TRUE_VALUE = 'true';

/**
 * A hook that provides functions for interacting with a storage mechanism (e.g. localStorage),
 * specific to the Order Size Nudge module.
 *
 * @param {Storage} store - The storage mechanism to use.
 */
export function useOrderSizeNudgeStorage(store: Storage) {
  function setOrderSizeNudgeDismissed() {
    store.setItem(
      ORDER_SIZE_NUDGE_DISMISSED_KEY,
      ORDER_SIZE_NUDGE_DISMISSED_TRUE_VALUE
    );
  }

  function getOrderSizeNudgeDismissed() {
    return (
      store.getItem(ORDER_SIZE_NUDGE_DISMISSED_KEY) ===
      ORDER_SIZE_NUDGE_DISMISSED_TRUE_VALUE
    );
  }

  return {
    setOrderSizeNudgeDismissed,
    getOrderSizeNudgeDismissed,
  };
}
