export enum NextDoorEvent {
  PAGEVIEW = 'PAGE_VIEW',
  CONVERSION = 'CONVERSION',
}

export type NextDoorTracker = (
  eventType: string,
  eventName: NextDoorEvent,
  options?: { user_email_hash?: string }
) => void;

export interface Window {
  ndp?: NextDoorTracker;
}

export enum NEXTDOOR_EVENT_TYPES {
  init = 'init',
  track = 'track',
}
