import * as Apollo from '@apollo/client';

import { useCountries } from './useCountries';
import { DEFAULT_ISO_CODE } from './constants';

/**
 * Return a list of states that are associated with a country's iso code
 *
 * @param isoCode - A valid ISO-3166 alpha-2 code
 */
export function useStatesByIsoCode(
  isoCode: string = DEFAULT_ISO_CODE,
  options?: Partial<Apollo.QueryDataOptions>
) {
  const query = useCountries(options);

  if (!query.data) {
    return [];
  }

  const foundCountry = query.data.countries.find(
    country => country.iso === isoCode
  );

  return foundCountry?.states ?? [];
}
