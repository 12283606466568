type Params = {
  [key: string]: unknown;
};

/**
 * Replaces a react-router path params with matched values
 *
 * @example
 * ```ts
 * setPathParams(
 *  "/signup/:userId/pets/:petName",
 *  {userId: 123, petName: "example"}
 * )
 * ```
 *
 * Outputs: "/signup/123/pets/example"
 *
 * @param path - Path with param to replace
 * @param params - Object with keys that match param name
 */
export function setPathParams(path: string, params: Params) {
  if (!path.includes(':')) return path;

  let withParams = path;

  Object.entries(params).forEach(([paramKey, paramValue]) => {
    const encodedValue = encodeURIComponent(String(paramValue));
    const paramRegex = new RegExp(`:${paramKey}\\??`);
    withParams = withParams.replace(paramRegex, encodedValue);
  });

  return withParams;
}
