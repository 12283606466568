import classNames from 'classnames';
import { useViewport } from 'src/screen';
import { Link } from '@farmersdog/corgi';
import { Nowrap, Text } from '@farmersdog/corgi-x';

import styles from './Today.module.scss';
import { PATH_CAREERS } from '@farmersdog/constants/paths';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Today() {
  const isMobile = useViewport({ xs: true, lg: false });

  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <>
      <div className={classNames(styles.container, { [styles.lg]: !isMobile })}>
        <Text
          variant={isMobile ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Today
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          Our team has grown to hundreds of employees (
          <Link to={PATH_CAREERS}>we’re hiring!</Link>) and we’re delivering
          millions of meals monthly. In many ways, we’re just getting started,
          but what drives us hasn’t changed: Making it easy to keep dogs
          healthy.
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          We’re not sure if Jada realizes how many dogs her story has ultimately
          impacted, but we think she’d be <Nowrap>pretty pleased.</Nowrap>
        </Text>
        <Link
          asButton
          variant="primary"
          to={PATH_CAREERS}
          className={accessibilityThemeStyles.responsiveCta}
        >
          Start Your Fresh Journey
        </Link>
      </div>
    </>
  );
}

export default Today;
