import { datadog } from 'src/vendors/datadog';
import { Logger, LogContext } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

class Reporter {
  private logger = new Logger('reporter');

  /**
   * Send an error to the reporting service
   *
   * @param error - The error object to send to the service
   */
  error(error: unknown, context?: object) {
    browserOnly(() => this.handleError(error, context));
  }

  /**
   * Send a warning to the reporting service
   *
   * @param warning - The warning string to send to the service
   */
  warn(warning: string, context?: LogContext) {
    browserOnly(() => this.handleWarning(warning, context));
  }

  info(message: string, context?: LogContext) {
    browserOnly(() => this.handleInfoMessage(message, context));
  }

  /**
   * Send an error to the reporting service
   *
   * @param error - The error object to send to the service
   */
  private handleError(error: unknown, context?: object) {
    datadog.error(error, context);
  }

  /**
   * Send a warning to the reporting service
   *
   * @param warning - The warning string to send to the service
   */
  private handleWarning(warning: string, context?: LogContext) {
    this.logger.warn(warning, context);
  }

  private handleInfoMessage(message: string, context?: LogContext) {
    this.logger.info(message, context);
  }
}

export const reporter = new Reporter();
