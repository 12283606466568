import { ReferrerType, couponCookie } from '@farmersdog/coupons';
import { COUPON_COOKIE_VERSION } from '@farmersdog/coupons/src/constants';

import { FetchLeadQuery } from '../graphql/types';
import { getShouldOverwriteCookieDiscountWithLeadDiscount } from '../hooks/experiments/useDefaultDiscountExperiment/utils';

interface ReconcileCouponCookieAndLeadDiscountArgs {
  leadDiscount: FetchLeadQuery['lead']['discount'] | undefined;
  temporaryGrainsDiscount:
    | FetchLeadQuery['lead']['temporaryGrainsDiscount']
    | undefined;
}

export const lpfOverrideDiscountCoupon = 'zsllcwu32doz';

export function reconcileCouponCookieAndLeadDiscount({
  leadDiscount,
  temporaryGrainsDiscount,
}: ReconcileCouponCookieAndLeadDiscountArgs): void {
  const cookie = couponCookie.get();
  const cookieReferrerCode = cookie?.referrerCode;
  const cookieReferrerAssociatedAt = cookie?.referrerAssociatedAt
    ? new Date(cookie.referrerAssociatedAt)
    : undefined;

  if (
    temporaryGrainsDiscount &&
    cookieReferrerCode !== lpfOverrideDiscountCoupon
  ) {
    couponCookie.set({
      version: COUPON_COOKIE_VERSION,
      referrerCode: lpfOverrideDiscountCoupon,
      referrerType: ReferrerType.Partner,
      referrerAssociatedAt: new Date(),
    });

    return;
  }

  const shouldOverwriteCookieDiscountWithLeadDiscount =
    getShouldOverwriteCookieDiscountWithLeadDiscount({
      cookieReferrerCode,
      cookieReferrerAssociatedAt,
      leadDiscount,
    });

  if (leadDiscount && shouldOverwriteCookieDiscountWithLeadDiscount) {
    couponCookie.set({
      version: COUPON_COOKIE_VERSION,
      referrerCode: leadDiscount.code,
      referrerType: leadDiscount.type as unknown as ReferrerType, // TS doesn't know these enums match
      referrerAssociatedAt: new Date(leadDiscount.associatedAt),
    });
  }
}
