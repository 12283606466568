import { DiyRecipe, PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const tilapiaRiceAndVegetables: DiyRecipe = {
  name: 'Tilapia, Rice, and Vegetables',
  id: 'tilapia-rice-and-vegetables',
  primaryProtein: PrimaryProtein.Tilapia,
  ingredients: [
    {
      name: 'tilapia fillets (skinless)',
      quantity: 1350,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 490,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, riced or finely chopped',
      quantity: 220,
      unit: 'g',
      alternativeQuantity: 2.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, shredded or cut into 1⁄4 inch dice',
      quantity: 220,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'sunflower oil',
      quantity: 55,
      unit: 'g',
      alternativeQuantity: 12,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'salmon oil',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  yieldWeight: 4.5,
  cookTime: '40 mins',
  ingredientSummary:
    'Tilapia Fillets, White rice, Broccoli, Carrots, Sunflower oil, DIY Nutrient Mix, Salmon oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Place the tilapia fillets in the pot and cook, flipping periodically. Break into smaller pieces and cook until the tilapia is totally opaque. Remove tilapia from the pot, double check and remove any remaining pin bones, and set aside.',
    },
    {
      name: 'Step 3',
      description:
        'Add the carrots and broccoli. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the tilapia, cooked rice, sunflower oil, and salmon oil into the pot and stir to evenly distribute. If using a food-safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines.',
    },
    {
      name: 'Step 5',
      description:
        "Remove the pot from the heat and sprinkle the {numberOfPackets} packet of The Farmer's Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.",
    },
  ],
  calories: 2430,
};
