import { FeatureName, ToggleTreatments } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface useSignupSpacingExperimentProps {
  useFeature: UseFeatureHook;
}

export function useSignupSpacingExperiment({
  useFeature,
}: useSignupSpacingExperimentProps) {
  const { treatment } = useFeature(FeatureName.CVR_SIGNUP_SPACING);

  const isTreatmentOn = treatment === ToggleTreatments.on;

  return {
    isTreatmentOn,
  };
}
