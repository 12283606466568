import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH_HOME } from '@farmersdog/constants/paths';

import { mountBarometricPageView } from './mountBarometricPageView';

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a barometric page view event.
 */
export function useMountBarometricPageView() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === PATH_HOME) {
      mountBarometricPageView();
    }
  }, [pathname]);
}
