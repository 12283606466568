import { Wave } from '@farmersdog/corgi-x';

import { Footer } from '../../../components/creative/GetStarted/Footer';
import { RecipesBenefits } from '../RecipesBenefits';
import { ReviewsSection } from '../Reviews/ReviewsSection';

import styles from './RecommendedPlanWrapper.module.css';

interface RecommendedPlanWrapperProps {
  children: React.ReactNode;
  petName: string;
}

export function RecommendedPlanWrapper({
  children,
  petName,
}: RecommendedPlanWrapperProps) {
  return (
    <>
      {children}
      <Wave
        data-testid="wave-container"
        withTop
        waveHeight={38}
        className={styles.wave}
      >
        <RecipesBenefits petName={petName} />
        <ReviewsSection />
        <Footer className={styles.footer} />
      </Wave>
    </>
  );
}
