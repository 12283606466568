import {
  Button,
  Grid,
  GridItem,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import { BowlSteaming, CircleCross, Clock } from '@farmersdog/corgi-x/icons';
import { trackPickyEaterSectionArticleClicked } from '../../analytics/trackPickEaterArticleLinkClicked';
import styles from './PickyEaterTips.module.css';
import { altText, pickyDogSources } from './assets';

const PICKY_EATER_ARTICLE_LINK: string =
  'https://www.thefarmersdog.com/digest/how-to-help-a-dog-whos-a-picky-eater/';

const ARTICLE_BUTTON_TEXT: string = 'Read Our Full Guide';

function handleCtaClick() {
  trackPickyEaterSectionArticleClicked(
    ARTICLE_BUTTON_TEXT,
    PICKY_EATER_ARTICLE_LINK
  );
}
/**
 * A card that displays tips geared towards customers with picky dogs.
 */
export function PickyEaterTips() {
  return (
    <>
      <Section
        variant="card"
        aria-label="Picky Eater Tips"
        bottomSpacing="lg"
        className={styles.section}
      >
        <Grid
          rowGap={{ xs: 'md' }}
          columnGap={{ md: 'md', lg: 'lg' }}
          topSpacing="sm"
        >
          <GridItem md={4} className={styles.head}>
            <Picture
              sources={pickyDogSources}
              alt={altText}
              className={styles.image}
            />
          </GridItem>
          <GridItem md={8}>
            <Grid rowGap="sm">
              <GridItem>
                <Text
                  variant="heading-28"
                  color="kale-3"
                  bold
                  as="h1"
                  id="picky-eater-tips"
                  bottomSpacing="none"
                  className={styles.heading}
                >
                  Picky Eater Tips
                </Text>
              </GridItem>
              <GridItem>
                <Text variant="article-16">
                  {`Leaving food can just mean your dog isn't hungry. But these expert tips can help their appetite:`}
                </Text>
              </GridItem>
              <GridItem className={styles.body}>
                <Grid rowGap="sm" bottomSpacing="sm">
                  <GridItem flexDirection="row">
                    <div className={styles.icon}>
                      <Clock width={22} baseColor="Carrot2" />
                    </div>
                    <div>
                      <Text variant="article-16" bold>
                        {`Don’t leave the food out `}
                        <Text variant="article-16">{`for longer than 30 minutes`}</Text>
                      </Text>
                    </div>
                  </GridItem>
                  <GridItem flexDirection="row">
                    <div className={styles.icon}>
                      <CircleCross width={22} baseColor="Carrot2" />
                    </div>
                    <div>
                      <Text variant="article-16" bold>
                        {`Cut back `}
                        <Text variant="article-16">{`on treats and scraps`}</Text>
                      </Text>
                    </div>
                  </GridItem>
                  <GridItem flexDirection="row">
                    <div className={styles.icon}>
                      <BowlSteaming width={22} baseColor="Carrot2" />
                    </div>
                    <div>
                      <Text variant="article-16" bold>
                        {`Add some warm water `}
                      </Text>
                      <Text variant="article-16">{`and fluff the food with a fork`}</Text>
                    </div>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem className={styles.buttonContainer}>
                <Button
                  type="anchor"
                  href={PICKY_EATER_ARTICLE_LINK}
                  target="_blank"
                  className={styles.button}
                  onClick={handleCtaClick}
                >
                  {ARTICLE_BUTTON_TEXT}
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Section>
    </>
  );
}
