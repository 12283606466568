import {
  type ArticleColors,
  type HeadingColors,
  Text,
} from '@farmersdog/corgi-x';

export interface OrderSizeDismissibleCTACopyProps {
  subscriptionFrequency: number;
}

export const headline = 'A TIMELY TIP';
export const subhead = 'Your Plan is Flexible';
export const cta = 'See 28 Day Plan';
export const componentTitle = 'Order Size Nudge';
export const dismissCta = 'Maybe Later';

export const maybeLaterBody =
  'If you’d like to adjust your order size in the future you can do so on the ‘plan’ page of your account.';
export const maybeLaterHeadline = 'Okay, Maybe Later';
export const maybeLaterCloseCta = 'Got it';

export const colors: {
  maybeLaterHeadline: HeadingColors;
  maybeLaterBodyText: ArticleColors;
} = {
  maybeLaterHeadline: 'kale-3',
  maybeLaterBodyText: 'kale-3',
};

export const templates = {
  body: function ({ subscriptionFrequency }: OrderSizeDismissibleCTACopyProps) {
    return (
      <Text variant="article-16" color="white">
        You’re on our {subscriptionFrequency}-day plan, our best value option.
        But you can{' '}
        <Text as="span" variant="article-16" bold color="white">
          lower your box price{' '}
        </Text>
        by getting smaller deliveries!
      </Text>
    );
  },
} satisfies Record<
  string,
  (props: OrderSizeDismissibleCTACopyProps) => JSX.Element
>;
