import events from '../eventNames';
import track from '../track';

type TrackPortalReferralShareClickInput = {
  channel: string;
  discountPercentage: number;
};

/**
 * A segment tracking event that is used to mark a customer using the referral
 * share features of the customer account.
 */
export function trackPortalReferralShareClick({
  channel,
  discountPercentage,
}: TrackPortalReferralShareClickInput) {
  track(events.portal_referral_share_click, {
    channel,
    discount: discountPercentage,
  });
}
