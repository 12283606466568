import { Application, PixelConfig, PixelSettings, pixelOptions } from './types';

interface PixelMigratorArgs {
  application: Application;
  config: PixelConfig;
}

/* Note: this is a copy of the pixel migrator from the @farmersdog/pixels package
 * we duplicate the logic here to avoid making the above package a dependency of the website
 */

export function pixelMigrator({ application, config }: PixelMigratorArgs) {
  const defaultSetting = Boolean(application === Application.Website);

  return pixelOptions.reduce((pixelConfigObject, pixelName) => {
    const currentSetting = config.includes(pixelName)
      ? !defaultSetting
      : defaultSetting;
    return { ...pixelConfigObject, [pixelName]: currentSetting };
  }, {} as PixelSettings);
}
