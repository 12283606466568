import classNames from 'classnames';

import { HomepageHeroTreatments } from 'src/hooks/useHomepageHeroExperiment';
import { useHomepageCtaHeaderCopy } from 'src/hooks/useHomepageCtaHeaderCopy';

import { MetaHeroGrid } from '../MetaHeroGrid';
import { FullScreenImageBackground } from '../FullScreenImageBackground';
import {
  LovedByHumansConfig,
  LifestyleBowlConfig,
  MeatAndVeggiesConfig,
} from './HeroContent.config';
import styles from './HeroContent.module.css';

interface HeroContentProps {
  variant: HomepageHeroTreatments;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
}

const HERO_CONFIG = {
  [HomepageHeroTreatments.LovedByHumans]: LovedByHumansConfig,
  [HomepageHeroTreatments.LifestyleBowl]: LifestyleBowlConfig,
  [HomepageHeroTreatments.MeatAndVeggies]: MeatAndVeggiesConfig,
};

export const HeroContent = ({
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  variant,
}: HeroContentProps) => {
  const isHomepageCtaHeaderCopyEnabled = useHomepageCtaHeaderCopy();
  const heroConfig = HERO_CONFIG[variant](isHomepageCtaHeaderCopyEnabled);

  const isLifestyleBowl = variant === HomepageHeroTreatments.LifestyleBowl;
  const isMeatAndVeggies = variant === HomepageHeroTreatments.MeatAndVeggies;

  return (
    <MetaHeroGrid
      isTextOnBottom
      withRightOverlay
      isHeadlineLink={isHeadlineLink}
      linkToFreshSignup={linkToFreshSignup}
      isAccessibilityThemeOn={isAccessibilityThemeOn}
      gridItemClassname={classNames(styles.cta, {
        [styles.lifestyleBowlGridItem]: isLifestyleBowl,
        [styles.meatAndVeggiesGridItem]: isMeatAndVeggies,
      })}
      headlineText={heroConfig.title}
      subtitle={heroConfig.subtitle}
      ctaString={heroConfig.cta}
      titleClassname={classNames(styles.title, {
        [styles.lifestyleBowlTitle]: isLifestyleBowl,
      })}
      subtitleClassname={styles.subtitle}
      buttonClassname={classNames(styles.button, {
        [styles.lifestyleBowlButton]: isLifestyleBowl,
      })}
      containerClassname={classNames(styles.container, {
        [styles.lifestyleBowlContainer]: isLifestyleBowl,
        [styles.meatAndVeggiesContainer]: isMeatAndVeggies,
      })}
      desktopColumnWidth={8}
      copyContainerClassname={classNames({
        [styles.lifestyleBowlCopyContainer]: isLifestyleBowl,
      })}
    >
      <FullScreenImageBackground
        moveBackgroundLower={heroConfig.lowerBackground}
        sources={heroConfig.assets}
        alt={heroConfig.alt}
      />
    </MetaHeroGrid>
  );
};
