export function getRecipeNamesFromPlans(user: Reducer.User | undefined) {
  const allRecipes: Set<string> = new Set();

  user?.subscription?.foodPlans?.forEach(foodPlan =>
    foodPlan?.recipes?.forEach(recipe => {
      if (recipe && recipe.name) {
        allRecipes.add(recipe.name);
      }
    })
  );

  return Array.from(allRecipes);
}
