import {
  iSpot,
  Premion,
  ISpotEventName,
  PremionEventName,
  PREMION_REF_ID,
} from './iSpot';
import { getSharedISpotParams } from './getSharedISpotParams';

interface TrackISpotPageView {
  pathname: string;
  utmSource: string | undefined;
}

/**
 * Track a iSpot page view
 */
export function trackISpotPageView({
  pathname,
  utmSource,
}: TrackISpotPageView): void {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { app, channel, customData } = getSharedISpotParams({ utmSource });

  iSpot.track({
    app,
    channel,
    customData,
    type: ISpotEventName.PAGE_VIEW,
    ord: cacheBuster,
  });

  Premion.track({
    app,
    refid: PREMION_REF_ID,
    url: pathname,
    type: PremionEventName.PAGE_VIEW,
    ord: cacheBuster,
  });
}
