import { ApolloError } from '@apollo/client';
import { CORE_API_VALIDATION_ERROR, CGS_VALIDATION_ERROR } from '../errorCodes';
import { ApplicationError } from '../ApplicationError';
import { ValidationError } from '../ValidationError';

export function transformGraphqlError(
  error: ApolloError
): ApplicationError | ValidationError {
  return error.graphQLErrors.map(graphQLError => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (
      graphQLError.extensions?.code === CGS_VALIDATION_ERROR ||
      graphQLError.extensions?.code === CORE_API_VALIDATION_ERROR
    ) {
      return new ValidationError(graphQLError.message);
    } else {
      return new ApplicationError(graphQLError.message);
    }
  })[0];
}
