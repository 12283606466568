import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as Path from '@farmersdog/constants/paths';

import { mountNextDoorPageView } from './mountNextDoorPageView';

export const nextDoorPageViewRoutes = [
  Path.PATH_HOME,
  Path.PATH_SIGNUP_ME,
  Path.PATH_SIGNUP_RECIPES,
  Path.PATH_SIGNUP_CHECKOUT,
];

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a next door page view event.
 */
export interface UseMountNextDoorPageViewArgs {
  isFirstPetsPage: boolean;
}

export function useMountNextDoorPageView({
  isFirstPetsPage,
}: UseMountNextDoorPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (nextDoorPageViewRoutes.includes(pathname) || isFirstPetsPage) {
      mountNextDoorPageView();
    }
  }, [pathname, isFirstPetsPage]);
}
