export const PET_STERILIZED_PLACEHOLDER = '%sterilized%';

interface HandlePetSterilizedPlaceholdersProps {
  input: string;
  gender: string;
}

export function handlePetSterilizedPlaceholders({
  input,
  gender,
}: HandlePetSterilizedPlaceholdersProps) {
  const sterilized = gender === 'male' ? 'neutered' : 'spayed';

  return input.replace(new RegExp(PET_STERILIZED_PLACEHOLDER, 'g'), sterilized);
}
