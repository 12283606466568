import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

export function trackOrderSizeNudgeSeePlanOptions() {
  track(eventNames.clicked_see_plan_options);
}

export function trackOrderSizeNudgeMaybeLater() {
  track(eventNames.clicked_maybe_later);
}
