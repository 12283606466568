import { NodeNames } from '@farmersdog/constants';

import { useSignupUpdatedAssets } from '../../..//hooks';
import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useLabel } from '../../../hooks/useLabel';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { RadioSelectorGroup } from '../base';

import {
  eatingStyleAssets,
  EatingStyleAssets,
  eatingStyleNewAssets,
} from './assets';

export function EatingStyleInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature } = props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const eatingStyleField = `${NodeNames.EatingStyle}-${position}` as const;
  const currentValue = getValues(eatingStyleField) ?? null;
  const { frontLabel } = useLabel({ node, getValues });

  const options =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  return (
    <>
      <span>{frontLabel}</span>
      <RadioSelectorGroup
        registerProps={registerProps}
        options={options}
        icons={
          isSignupUpdatedAssetsOn ? eatingStyleNewAssets : eatingStyleAssets
        }
        defaultMobileIconIndex={EatingStyleAssets.GoodEater}
        legend="Eating Style"
        currentValue={currentValue}
        defaultDescription={
          currentValue ? '' : "Select the eating style closest to your dog's."
        }
      />
    </>
  );
}
