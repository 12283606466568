import { Button, Link, Progress, Text } from '@farmersdog/corgi-x';
import { PATH_ORDERS } from '@farmersdog/constants/paths';
import { orderDeliveryGifs } from '../../../assets/order-delivery';
import { OrderDeliveryStatus } from '../../../graphql/types.cgs';
import {
  trackTrackOrderLinkClicked,
  trackViewOrderLinkClicked,
} from '../../analytics';

import styles from './InfoCard.module.css';
import { OrderProgressCopy } from './HomepageOrdersSection';

const STEPS_AMOUNT = 3;

const TRACKING_STATES = new Set([
  OrderDeliveryStatus.Transit,
  OrderDeliveryStatus.Delivered,
]);

export interface InfoCardProps {
  deliveryStatus: OrderDeliveryStatus;
  dateRange: string;
  orderProgressCopy: OrderProgressCopy;
  trackingUrls: string[] | null;
}

export function InfoCard(props: InfoCardProps) {
  const deliveryStatusForDisplay =
    props.deliveryStatus === OrderDeliveryStatus.Transit
      ? 'In-transit'
      : props.deliveryStatus;

  const deliveryGif = orderDeliveryGifs[props.deliveryStatus];

  const showTrackingLink =
    props.trackingUrls &&
    props.trackingUrls.length > 0 &&
    TRACKING_STATES.has(props.deliveryStatus);

  return (
    <section className={styles.container}>
      <div className={styles.info}>
        <Text
          color="kale-3"
          variant="heading-16"
          as="p"
          className={styles.statusText}
        >
          {deliveryStatusForDisplay}
        </Text>
        <Progress
          role="status"
          aria-label={`Order delivery progress: ${
            props.deliveryStatus === OrderDeliveryStatus.Preparing ? 1 : 2
          } of ${STEPS_AMOUNT}`}
          steps={STEPS_AMOUNT}
          activeStep={
            props.deliveryStatus === OrderDeliveryStatus.Preparing ? 1 : 2
          }
          stepProgress={50}
          variant="bold"
        />
        <Text
          as="h3"
          bold
          color="kale-3"
          variant="heading-28"
          className={styles['info-heading']}
        >
          {props.orderProgressCopy}
          <br />
          {props.dateRange}
        </Text>
      </div>

      <div className={styles.actions}>
        <Button
          to={PATH_ORDERS}
          type="link"
          className={styles['view-order-button']}
          onClick={trackViewOrderLinkClicked}
        >
          View Order
        </Button>
        {showTrackingLink ? (
          <Text bold variant="heading-16">
            <Link
              to={props.trackingUrls?.[0]}
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackTrackOrderLinkClicked}
            >
              Track here
            </Link>
          </Text>
        ) : (
          props.deliveryStatus === OrderDeliveryStatus.Preparing && (
            <Text bold variant="heading-16" color="charcoal-1">
              Track here when shipped
            </Text>
          )
        )}
      </div>
      {deliveryGif && (
        <div className={styles.image}>
          <img alt="" src={deliveryGif[0]} className={styles.image} />
        </div>
      )}
    </section>
  );
}
