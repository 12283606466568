import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import { setShouldShowLightboxById } from 'src/actions/ui';
import { setExitIntentCookie, showExitIntentCookie } from 'src/utils/cookies';

import config from 'src/config';

import { selectCheckoutSubmitting } from 'src/reducers/signup/submitting';
import { selectLightboxIsOpen } from 'src/reducers/ui';

import ExitIntentModal, { EXIT_MODAL_ID } from '../ExitIntentModal';
import ExitIntentTracker from '../ExitIntentTracker';
import useSignupParams from 'src/pages/Signup/hooks/useSignupParams';
import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';
import { useIsOnGetStartedCard } from 'src/pages/Signup/hooks/useIsOnGetStartedCard';
import { isDiySignup } from '@farmersdog/lead-browser-storage';

const INTERSTITIAL_ID = 'petsInterstitialId';

function Exit({ isTOSA }) {
  const dispatch = useDispatch();
  const checkoutSubmitting = useSelector(selectCheckoutSubmitting);
  const isInterstitialOpen = useSelector(state =>
    selectLightboxIsOpen(state, INTERSTITIAL_ID)
  );
  const { page } = useSignupParams();
  const { current } = useSignupRouter({ isTOSA });
  const onTosaGetStartedCard = useIsOnGetStartedCard({ isTOSA });

  const { open } = useGlobalLightbox({
    id: EXIT_MODAL_ID,
  });
  const isOnDiySignup = isDiySignup();

  useEffect(() => {
    const enabledInConfig = config('features.exitIntent');
    const cookieAllowsExitIntent = showExitIntentCookie(page);
    const notOnSuccessPage = current && !current.success;

    const shouldShow =
      enabledInConfig &&
      cookieAllowsExitIntent &&
      notOnSuccessPage &&
      !onTosaGetStartedCard &&
      !checkoutSubmitting &&
      !isInterstitialOpen &&
      !isOnDiySignup;

    dispatch(setShouldShowLightboxById(EXIT_MODAL_ID, shouldShow));
  }, [
    checkoutSubmitting,
    current,
    dispatch,
    page,
    isInterstitialOpen,
    isOnDiySignup,
    onTosaGetStartedCard,
  ]);

  const handleSubmit = () => {
    setExitIntentCookie(page);
    dispatch(setShouldShowLightboxById(EXIT_MODAL_ID, false));
  };

  return (
    <>
      <ExitIntentTracker onExit={() => open({ override: true })} />
      <ExitIntentModal onSubmit={handleSubmit} isTOSA={isTOSA} />
    </>
  );
}

Exit.propTypes = {
  isTOSA: PropTypes.bool,
};

export default Exit;
