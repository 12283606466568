import { RecipeNames } from '@farmersdog/constants';

import { useTosaUserFeature } from '../../../../hooks';
import { UseFeatureHook } from '../../../../types';
import {
  FeatureName,
  RecipePagePackOrderConfig,
} from '../../../../utils/features';

// The recipes you want to show up in the front should be at the end of this list, the recipes you want to stay towards the back should be at the beginning
export const defaultSortOrder = [
  RecipeNames.PORK,
  RecipeNames.TURKEY,
  RecipeNames.CHICKEN_CHIA,
  RecipeNames.CHICKENL,
  RecipeNames.BEEF,
];

export function useRecipePackSortOrder(useFeature: UseFeatureHook) {
  const validRecipeNames = Object.values(RecipeNames);

  const { config } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_RECIPE_PAGE_PACK_ORDER,
  });

  return isValidConfig(config) &&
    config.recipeOrder.length &&
    config.recipeOrder.every(recipe => validRecipeNames.includes(recipe))
    ? config.recipeOrder
    : defaultSortOrder;
}

function isValidConfig(val: unknown): val is RecipePagePackOrderConfig {
  return Object.prototype.hasOwnProperty.call(val, 'recipeOrder');
}
