import {
  Button,
  Form,
  IconButton,
  Picture,
  Text,
  TextArea,
  Tooltip,
  Wave,
} from '@farmersdog/corgi-x';
import { Caret, Close, Information } from '@farmersdog/corgi-x/icons';
import classNames from 'classnames';
import { ChangeEvent, useId, useState } from 'react';
import whitePuppyEatingPng from '../../../assets/white-puppy-eating.png';
import { UpdateFoodPlansRecipesInput } from '../../../graphql/types.cgs';
import { formatCurrency, formatDateRange } from '../../../utils';
import { FetchChangeFreshRecipesQuoteQuery } from '../../network';
import styles from './ConfirmRecipes.module.css';

import { useTrackPricesSeen } from '../../../analytics/useTrackPricesSeen';

export interface ConfirmRecipesProps {
  petPlanId: number;
  petName: string;
  newRecipes?: FetchChangeFreshRecipesQuoteQuery['customer']['changeFreshRecipesPlanQuote']['selectedRecipes'];
  deliveryWindow?: FetchChangeFreshRecipesQuoteQuery['customer']['firstUnlockedOrder']['deliveryWindow'];
  prices?: FetchChangeFreshRecipesQuoteQuery['customer']['changeFreshRecipesPlanQuote']['dailyConsumptionPrice'];
  orderSizes?: FetchChangeFreshRecipesQuoteQuery['customer']['changeFreshRecipesPlanQuote']['subscriptionFrequency'];
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (input: UpdateFoodPlansRecipesInput) => void;
}

export function ConfirmRecipes(props: ConfirmRecipesProps) {
  const headingId = useId();
  const messageCharLimitId = useId();

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const updatedPrice = props.prices?.updated;
  const originalPrice = props.prices?.original;

  useTrackPricesSeen({
    dailyPrice: updatedPrice,
    petId: props.petPlanId,
  });

  if (!props.newRecipes) {
    throw new Error(
      'The fetch edit recipes quote failed to return new recipes data'
    );
  }

  if (!props.deliveryWindow) {
    throw new Error(
      'The fetch edit recipes quote failed to return delivery window data'
    );
  }

  const charLimit = 500;
  const isFormInvalid = feedbackMessage.length > charLimit;
  const recipes = props.newRecipes;
  const deliveryWindow = props.deliveryWindow;

  const formattedRecipes = props.newRecipes
    .map(recipe => recipe.displayName)
    .join(', ');

  const desiredDateRange = formatDateRange(
    deliveryWindow.earliestDesiredArrivalDate,
    deliveryWindow.latestDesiredArrivalDate
  );

  function handleOnMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setFeedbackMessage(event.target.value);
  }

  function handleOnFormClose() {
    setFeedbackMessage('');
    setIsFormVisible(false);
  }

  function handleConfirmRecipesSubmit() {
    void props.onSubmit({
      foodPlans: [
        {
          id: props.petPlanId,
          selectedRecipes: recipes.map(recipe => ({
            id: recipe.id,
            name: recipe.name,
          })),
        },
      ],
      freeFormFeedback: feedbackMessage,
    });
  }

  return (
    <section aria-labelledby={headingId} className={styles.section}>
      <Wave withBottom withTop>
        <div className={styles.container}>
          <Picture
            className={styles.illustration}
            sources={[whitePuppyEatingPng]}
            alt=""
          />
          <Text
            variant="heading-40"
            color="kale-3"
            as="h2"
            id={headingId}
            bold
            className={styles.heading}
          >
            Confirm Changes
          </Text>
          <Text variant="heading-22" as="p" className={styles.paragraph}>
            Here’s how {props.petName}&apos;s plan will look with this update,
            beginning with your order set to arrive between{' '}
            <Text as="span" bold variant="heading-22">
              {desiredDateRange}
            </Text>
          </Text>
          <Text variant="heading-22" color="kale-3" bold>
            New Recipes Selection
          </Text>
          <Text variant="heading-22" as="p" className={styles.paragraph}>
            {formattedRecipes}
          </Text>

          {originalPrice && updatedPrice && (
            <>
              <Text variant="heading-22" color="kale-3" bold>
                Daily Price
              </Text>

              <Text
                variant="heading-22"
                className={classNames(styles['daily-price'], styles.paragraph)}
                as="p"
              >
                {originalPrice !== updatedPrice ? (
                  <>
                    <Text
                      variant="heading-22"
                      color="charcoal-1"
                      className={styles['current-price']}
                      data-testid="original-price"
                    >
                      {`${formatCurrency(originalPrice)}/day`}
                    </Text>{' '}
                    <Caret aria-hidden className={styles['caret-icon']} />
                    <span data-testid="updated-price">
                      {`${formatCurrency(updatedPrice)}/day`}
                    </span>
                  </>
                ) : (
                  <span data-testid="original-price">
                    {`${formatCurrency(originalPrice)}/day`}
                  </span>
                )}
              </Text>
            </>
          )}

          {isFormVisible && (
            <section className={styles.formContainer}>
              <div className={styles['form-close-button']}>
                <IconButton
                  aria-label="Close Feedback"
                  icon={<Close size={32} aria-hidden baseColor="Kale3" />}
                  onClick={handleOnFormClose}
                />
              </div>
              <Form>
                <div className={styles['form-heading']}>
                  <Text variant="heading-16" color="kale-3">
                    <Text variant="heading-16" bold>
                      To help us improve,
                    </Text>{' '}
                    please tell us why you changed recipes. (Optional)
                  </Text>
                </div>
                <div className={styles.actions}>
                  <div className={styles['input-container']}>
                    <TextArea
                      aria-describedby={messageCharLimitId}
                      label="Message"
                      invalid={isFormInvalid}
                      value={feedbackMessage}
                      onChange={handleOnMessageChange}
                    />
                    <Text
                      role="status"
                      id={messageCharLimitId}
                      variant="heading-16"
                      color="charcoal-1"
                      as="p"
                      className={classNames(styles['limit-disclaimer'], {
                        [styles['limit-disclaimer-error']]: isFormInvalid,
                      })}
                    >
                      {feedbackMessage.length} of {charLimit} max characters
                    </Text>
                  </div>
                </div>
              </Form>
            </section>
          )}
          {props.orderSizes &&
            props.orderSizes.original !== props.orderSizes.updated && (
              <>
                <div className={styles['order-size-tile-container']}>
                  <Text variant="heading-22" color="kale-3" bold>
                    New Order Size
                  </Text>
                  <Tooltip
                    position="right-bottom"
                    content={
                      <Text variant="heading-16" color="kale-3">
                        While each recipe contains the same amount of calories,
                        some recipes are higher in volume than others. Since you
                        changed recipes, we updated your order size to make sure
                        your recipes can still fit in the box! You can always
                        make changes later.
                      </Text>
                    }
                  >
                    <Information />
                  </Tooltip>
                </div>
                <Text variant="heading-22" as="p" className={styles.paragraph}>
                  {props.orderSizes?.updated} days
                </Text>
              </>
            )}
        </div>
      </Wave>
      <div className={styles['cta-container']}>
        <Button
          type="button"
          onClick={handleConfirmRecipesSubmit}
          loading={props.isSubmitting}
          disabled={props.isSubmitting || isFormInvalid}
        >
          Save Selections
        </Button>
        <Button type="button" variant="plain-text" onClick={props.onClose}>
          Keep Current Recipes
        </Button>
      </div>
    </section>
  );
}
