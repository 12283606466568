import { UserFeatureKeys } from '../../../../../app/lib/Split';
import { useExperimentContext } from '../../../../../experiments';
import { OrderPaymentStatus } from '../../../../../graphql/types.core-api';
import { usePaymentMethodChangedForOrder } from '../usePaymentMethodChangedForOrder';

export const useGetPaymentFailedStatus = ({
  orderId,
  orderPaymentStatus,
}: {
  orderPaymentStatus?: OrderPaymentStatus;
  orderId?: number;
}) => {
  const { useFeature } = useExperimentContext();
  const isPreGracePeriodExperimentEnabled =
    useFeature?.(UserFeatureKeys.adj_pre_grace_period, {}).treatment === 'on';

  const {
    paymentMethodChangedForOrder,
    error: paymentMethodChangedForOrderError,
    loading: paymentMethodChangedForOrderLoading,
  } = usePaymentMethodChangedForOrder({
    orderId,
  });

  const isFailedOrder = orderPaymentStatus === OrderPaymentStatus.Failed;

  const paymentMethodUpdateNeeded =
    isFailedOrder &&
    !paymentMethodChangedForOrderLoading &&
    !paymentMethodChangedForOrderError &&
    paymentMethodChangedForOrder === false;

  return {
    isFailedOrder,
    paymentMethodUpdateNeeded,
    isPreGracePeriodExperimentEnabled,
    paymentMethodChangedForOrderLoading,
  };
};
