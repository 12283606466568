import startCase from 'lodash/startCase';

export const BODY_CONDITION_PLACEHOLDER = '%bodyCondition%';

interface HandleBodyConditionPlaceholders {
  input: string;
  bodyCondition: string;
  applyStartCasing?: boolean;
}

export function handleBodyConditionPlaceholders({
  input,
  bodyCondition,
  applyStartCasing,
}: HandleBodyConditionPlaceholders) {
  let formattedBodyCondition = bodyCondition;
  if (applyStartCasing) {
    formattedBodyCondition = startCase(bodyCondition);
  }

  return input.includes(BODY_CONDITION_PLACEHOLDER)
    ? input.split(BODY_CONDITION_PLACEHOLDER).join(formattedBodyCondition)
    : input;
}
