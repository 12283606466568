import { Select, useLightboxControl } from '@farmersdog/corgi';
import { Button, Text } from '@farmersdog/corgi-x';
import { useId, useState, ChangeEvent, FormEvent } from 'react';
import { SecurePaymentMessage } from '../../../components';
import { formatDateToWeekOf } from '../../../utils';
import {
  FreshSubscriptionFieldsFragment,
  ReactivationFreshQuoteFieldsFragment,
} from '../../network';
import { ModifyAccountMessage } from '../ModifyAccountMessage';
import { DailyPriceQuote } from './QuoteDisplay';
import styles from './ShippingAndQuoteDisplay.module.css';
import { TermsOfUseModal } from './TermsOfUseModal';

export interface ShippingAndQuoteDisplayProps {
  availableNextDates: string[];
  quote: ReactivationFreshQuoteFieldsFragment;
  addOns: FreshSubscriptionFieldsFragment['addOns'];
  submit: (selectedAvailableNextDate: string) => void;
  isSubmitting: boolean;
}

export function ShippingAndQuoteDisplay(props: ShippingAndQuoteDisplayProps) {
  const [selectedAvailableNextDate, setSelectedAvailableNextDate] = useState(
    props.availableNextDates[0]
  );
  const termsOfUseModalControl = useLightboxControl();

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedAvailableNextDate(e.target.value);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    props.submit(selectedAvailableNextDate);
  }

  const orderSummaryId = useId();

  return (
    <section aria-labelledby={orderSummaryId} className={styles.root}>
      <form onSubmit={handleSubmit}>
        <Text
          id={orderSummaryId}
          as="h2"
          variant="heading-28"
          bold
          color="charcoal-3"
        >
          Order Summary
        </Text>
        <Text
          as="p"
          variant="article-16"
          color="charcoal-3"
          topSpacing="md"
          bottomSpacing="sm"
        >
          Start your orders again ASAP or select a later week that you’d prefer.
        </Text>
        <Select
          name="selectedAvailableNextDate"
          label="Delivery Week"
          onChange={handleChange}
          value={selectedAvailableNextDate}
          className={styles.selectAvailableNextDate}
        >
          {props.availableNextDates.map(date => {
            return (
              <option key={date} value={date}>
                {formatDateToWeekOf(date)}
              </option>
            );
          })}
        </Select>
        <DailyPriceQuote quote={props.quote} addOns={props.addOns} />
        <ModifyAccountMessage />
        <Button
          type="submit"
          disabled={props.isSubmitting}
          loading={props.isSubmitting}
          className={styles.submit}
        >
          Reactivate
        </Button>
        <div className={styles.subText}>
          <div className={styles.subTextItem}>
            <Text variant="heading-12" color="charcoal-3">
              Pause or cancel anytime.
            </Text>
          </div>
          <div className={styles.subTextItem}>
            <Text variant="heading-12" color="charcoal-3">
              By reactivating, you agree to our{' '}
            </Text>
            <Button
              type="button"
              variant="plain-text"
              onClick={termsOfUseModalControl.open}
            >
              <Text variant="heading-12" color="kale-3" bold>
                Terms of Use.
              </Text>
            </Button>{' '}
            <Text variant="heading-12" color="charcoal-3">
              The Farmer’s Dog is an auto-renewing subscription with no
              long-term commitment. Prices shown above do not include tax. You
              can cancel unprocessed orders anytime.
            </Text>
          </div>
          <SecurePaymentMessage />
        </div>
      </form>
      {termsOfUseModalControl.isOpen && (
        <TermsOfUseModal {...termsOfUseModalControl} />
      )}
    </section>
  );
}
