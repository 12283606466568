import { DiscountType, DiscountTypeEnum } from '../../graphql/types';

/*
 * This is a temporary function that converts the Core API discount type to the target TOSA discount type
 * It will no longer be necessary once TOSA no longer reaches out directly to the Core API
 * and the client only uses the discount types expected by TOSA
 * TODO: https://app.shortcut.com/farmersdog/story/108390/update-tosa-cookie-to-use-tosa-discount-code-type
 */
export function getQuoteDiscountType(
  discountType: DiscountType
): DiscountTypeEnum | null {
  switch (discountType) {
    case DiscountType.Partner: {
      return DiscountTypeEnum.Coupon;
    }
    case DiscountType.User: {
      return DiscountTypeEnum.Referral;
    }
    default: {
      return null;
    }
  }
}
