import moment from 'moment';
import { WeekdayNumber } from '../constants';
import { isTodayBetweenThuFri } from './isTodayBetweenThuFri';

/**
 * Add the today's date to the available dates if necessary.
 */
export function addThisWeek(dates: string[]): string[] {
  // This function will check the first available date in the list
  if (dates.length === 0) return [];
  if (!isTodayBetweenThuFri()) return [...dates];

  // Always use the first day of the week (Sunday) to work with weeks.
  const mStartOfTodayWeek = moment()
    .day(WeekdayNumber.Sunday)
    // Ensures the time is 12:00 am
    .startOf('day');

  // Do not add the today date if is already available
  const isTodayIncluded = dates.includes(
    mStartOfTodayWeek.format('YYYY-MM-DD')
  );
  if (isTodayIncluded) return [...dates];

  // Make sure we compare the first days of the week
  const mStartOfWeek = moment(dates[0]).day(WeekdayNumber.Sunday);

  // Today's date is in the previous week of the first available one: add it!
  if (mStartOfWeek.diff(mStartOfTodayWeek, 'week') === 1) {
    return [mStartOfTodayWeek.format('YYYY-MM-DD'), ...dates];
  }
  return [...dates];
}
