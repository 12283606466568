import Picture from 'src/components/Picture';
import { Card } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import styles from './Testimonial.module.scss';
import testimonalIcon from '../../assets/recipes-testimonial.webp';

export function Testimonial() {
  return (
    <Card className={styles.card}>
      <div className={styles.petInfo}>
        <Picture
          sources={[testimonalIcon]}
          alt="Redd, Certified Salmon Lover"
        />
        <div className={styles.petName}>
          <Text variant="heading-22" as="h3" color="kale-3" bold>
            Redd
          </Text>
          <Text variant="heading-16" as="h3" color="kale-3">
            Certified Beef Lover
          </Text>
        </div>
      </div>
      <Text variant="article-16" color="charcoal-3" as="blockquote">
        “I was so nervous to cook for Redd before, but now that I use the DIY
        Nutrient Mix I have peace of mind that he is getting all of the
        nutrients he needs.”
      </Text>
      <Text variant="heading-16" color="charcoal-3" as="figcaption">
        – Dylan (Redd’s human)
      </Text>
    </Card>
  );
}
