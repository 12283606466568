import RootDecision, { SLIDE_NAME as ROOT_DECISION } from './RootDecision';
import SeeRecipes, { SLIDE_NAME as SEE_RECIPES } from './SeeRecipes';
import SeePricing, { SLIDE_NAME as SEE_PRICING } from './SeePricing';
import MoreInfo, { SLIDE_NAME as MORE_INFO } from './MoreInfo';
import MoreTime, { SLIDE_NAME as MORE_TIME } from './MoreTime';
import PetInfo, { SLIDE_NAME as PET_INFO } from './PetInfo';
import MaxPrice, { SLIDE_NAME as MAX_PRICE } from './MaxPrice';
import PricingOptions, {
  SLIDE_NAME as PRICING_OPTIONS,
} from './PricingOptions';

import NewProteins, { SLIDE_NAME as NEW_PROTEINS } from './NewProteins';
import ProteinBlends, { SLIDE_NAME as PROTEIN_BLENDS } from './ProteinBlends';
import Vegetables, { SLIDE_NAME as VEGETABLES } from './Vegetables';
import AdditionalIngredients, {
  SLIDE_NAME as ADDITIONAL_INGREDIENTS,
} from './AdditionalIngredients';

import Submit, { SLIDE_NAME as SUBMIT } from './Submit';

export default {
  [ROOT_DECISION]: RootDecision,
  [SEE_RECIPES]: SeeRecipes,
  [SEE_PRICING]: SeePricing,
  [MORE_INFO]: MoreInfo,
  [MORE_TIME]: MoreTime,
  [PET_INFO]: PetInfo,
  [MAX_PRICE]: MaxPrice,
  [PRICING_OPTIONS]: PricingOptions,
  [NEW_PROTEINS]: NewProteins,
  [PROTEIN_BLENDS]: ProteinBlends,
  [VEGETABLES]: Vegetables,
  [ADDITIONAL_INGREDIENTS]: AdditionalIngredients,
  [SUBMIT]: Submit,
};
