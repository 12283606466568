import planBoxIllustration1 from './Plan_Box_Illustration_1x.png';
import planBoxIllustration2 from './Plan_Box_Illustration_2x.png';
import maybeLaterBuddyJadaIllustration from './maybe-later-buddy-jada.png';

export const planBox = {
  sources: [planBoxIllustration2, planBoxIllustration1],
  altText: 'Smaller Order Size',
};

export const maybeLaterBuddyJada = {
  sources: [maybeLaterBuddyJadaIllustration],
  altText: 'buddy and jada dogs',
};
