import merge from 'src/utils/mergeObjects';

import {
  SIGNUP_FETCH_PREVIOUS_USER,
  SIGNUP_REGISTER,
  SIGNUP_SET_RECOMMENDED_FOOD_PLAN,
} from 'src/constants/actionTypes';

const initialState = {
  numberOfPets: 0,
  hasSeenInterstitial: false,
  withHealthIssues: {}, // booleans for each pet by id
};

const getIssues = pets => {
  const withHealthIssues = {};
  pets.forEach(pet => {
    withHealthIssues[pet.id] = pet.issues ? pet.issues.length > 0 : false;
  });
  return withHealthIssues;
};

export default function signupPetsReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_REGISTER:
    case SIGNUP_FETCH_PREVIOUS_USER: {
      // We are beginning the signup or receiving a response from /register:
      // we can replace all the values with the last from API.
      const { pets, subscription } = action.payload.data;
      if (subscription.status === 'active') {
        return state;
      }
      return merge({}, state, {
        numberOfPets: pets.length,
        withHealthIssues: getIssues(pets),
      });
    }
    case SIGNUP_SET_RECOMMENDED_FOOD_PLAN: {
      const { recommendedFoodPlan, petId } = action.payload;
      return {
        ...state,
        recommendedFoodPlan: {
          ...state.recommendedFoodPlan,
          [petId]: recommendedFoodPlan,
        },
      };
    }
    default:
      break;
  }
  return state;
}

export function selectNumberOfPets(state) {
  return state.signup.pets.numberOfPets;
}

export function selectPetsWithIssues(state) {
  return state.signup.pets.withHealthIssues;
}
