import { track } from '@farmersdog/analytics';

import { FreshRecipeRecommendation } from '../../graphql/types';
import { segmentTrackEvents } from '../segmentTrackEventNames';

interface TrackRecipesClickContinueWithRecommendationArgs {
  recommendedRecipes: FreshRecipeRecommendation[];
}

export function trackRecipesClickContinueWithRecommendation({
  recommendedRecipes,
}: TrackRecipesClickContinueWithRecommendationArgs) {
  const recipeNames = recommendedRecipes
    .map(recipe => recipe.name)
    .sort()
    .join(',');

  track(segmentTrackEvents.recipes_click_continue_with_recommendation, {
    recipeNames,
  });
}
