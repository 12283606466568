/* eslint-disable @typescript-eslint/no-unsafe-return */
import { local } from '@farmersdog/utils';

import { SubscriptionType } from '../graphql/types';

export const STORAGE_KEY = 'TFD_SIGNUP_STATE';
export const SIGNUP_STATE_VERSION = 1;

export interface SignupState {
  version: number;
  subscriptionTypes: {
    eligible: SubscriptionType[];
    current: SubscriptionType;
    expiration: number | null;
  };
}

function init(): SignupState {
  return {
    version: SIGNUP_STATE_VERSION,
    subscriptionTypes: {
      eligible: [SubscriptionType.Fresh],
      current: SubscriptionType.Fresh,
      expiration: null,
    },
  };
}

function get(): SignupState {
  const signupStateString = local.getItem(STORAGE_KEY);

  if (signupStateString === null) {
    return init();
  }

  try {
    return JSON.parse(signupStateString);
  } catch {
    return init();
  }
}

function set(signupState: SignupState): void {
  const signupStateString = JSON.stringify(signupState);
  local.setItem(STORAGE_KEY, signupStateString);
}

export { SubscriptionType };

export default {
  get,
  init,
  set,
};
