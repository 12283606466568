import * as Yup from 'yup';
import { isInputNumberLength } from '../../../utils';

import { emailRegex } from '@farmersdog/constants/regexPatterns';

Yup.addMethod(Yup.string, 'email', function (message: string) {
  return this.matches(emailRegex, {
    name: 'email',
    message,
    excludeEmptyString: true,
  });
});

const accountInformation = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .test(
      'Phone number length',
      'Phone number format is invalid',
      isInputNumberLength([10])
    ),
});

const commonAddress = Yup.object({
  addressLine1: Yup.string().required('Address is required'),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().required('City is required'),
  stateAbbreviation: Yup.string().required('State is required'),
  zip: Yup.string()
    .required('Zip code is required')
    .test(
      'Zip code length',
      'Zip code must be exactly 5 or 9 digits',
      isInputNumberLength([5, 9])
    ),
});

/** Field-level validation for Edit Account form */
export default Yup.object({
  accountInformation,
  shippingAddress: commonAddress,
  billingAddress: commonAddress,
  isResidential: Yup.boolean(),
  isBillingSameAsShipping: Yup.boolean(),
});
