import { paths } from '@farmersdog/constants';
import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { SearchParams } from '../../../utils/getSignupRoute';

import styles from './PetNameNavbar.module.css';

interface PerPetNavbarData {
  name: string;
  disabled: boolean;
}

export interface PetNameNavbarProps {
  petNavbarData: PerPetNavbarData[];
  currentPetName: string;
  firstPetCard: string;
}

export function PetNameNavbar(props: PetNameNavbarProps) {
  const { petNavbarData, currentPetName } = props;
  const currentIndex = petNavbarData.findIndex(navbarData => {
    return navbarData.name.toLowerCase() === currentPetName.toLowerCase();
  });

  return (
    <div className={styles.container}>
      {petNavbarData.map((navbarData, index) => {
        const params = new URLSearchParams({
          [SearchParams.Pet]: navbarData.name,
          [SearchParams.Card]: props.firstPetCard,
        });

        const to = `${paths.PATH_SIGNUP_PETS}?${params.toString()}`;
        const key = `${navbarData.name}-${index}`;

        const isCurrentPet = index === currentIndex;

        return (
          <Text key={key} className={styles.petName} variant="heading-16" bold>
            <Link
              variant="secondary"
              mode="dark"
              active={isCurrentPet}
              to={to}
              disabled={navbarData.disabled}
              tabIndex={navbarData.disabled ? -1 : undefined}
              className={
                isCurrentPet ? styles.activePetName : styles.inactivePetName
              }
            >
              {navbarData.name}
            </Link>
          </Text>
        );
      })}
    </div>
  );
}
