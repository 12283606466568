import { Grid, Link, Picture, Text } from '@farmersdog/corgi-x';
import GentleCooking from './assets/GentleCooking.svg';
import Stethescope from './assets/Stethescope.svg';
import SteakAndCarrot from './assets/SteakAndCarrot.svg';
import MagnifyingGlass from './assets/MagnifyingGlass.svg';
import { Testimonials } from './Testimonials/Testimonials';
import { vet } from './assets';
import { InformationIcon } from './InformationIcon/InformationIcon';
import { HomepageTitle1 } from '../../HomepageTitle';

import styles from './IconsSection.module.scss';
import config from 'src/config';
import { trackHomeVetClickedVetPortal } from 'src/analytics/events/trackHomeVetClickedVetPortal';
import { useRef } from 'react';
import { useOnEnterView } from 'src/hooks/useOnEnterView';
import { trackHomeVetSeen } from 'src/analytics';

export const iconsSection = [
  {
    icon: GentleCooking,
    label: 'On-Staff, Board-Certified Nutritionists',
  },
  {
    icon: Stethescope,
    label: 'On-Staff, Veterinarians',
  },
  {
    icon: SteakAndCarrot,
    label: 'AAFCO Complete & Balanced',
  },
  {
    icon: MagnifyingGlass,
    label: 'Clinically Proven Nutrition',
  },
];

interface IconsSectionProps {
  isMobile: boolean;
}

export const IconsSection = ({ isMobile }: IconsSectionProps) => {
  const ref = useRef(null);
  useOnEnterView({
    onEnter: trackHomeVetSeen,
    ref,
  });
  return (
    <section aria-label="vet section" className={styles.container}>
      <Grid
        flexDirection="column"
        alignItems={isMobile ? 'flex-start' : 'center'}
      >
        <HomepageTitle1 center={!isMobile} bottomSpacing="md">
          Formulated by nutritionists. Driven by science.
        </HomepageTitle1>
        <div ref={ref}>
          <Text
            variant="article-20"
            bottomSpacing="xl"
            className={styles.subtitle}
          >
            We combine decades of dog nutrition research with the clinical
            insights of experienced vet professionals.
          </Text>
        </div>
        <Grid
          className={styles.iconsContainer}
          flexDirection="row"
          bottomSpacing="xxl"
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap="md"
        >
          {iconsSection.map(({ icon, label }) => (
            <InformationIcon
              key={label}
              icon={icon}
              label={label}
              isMobile={isMobile}
            />
          ))}
        </Grid>
        <Grid className={styles.secondSection}>
          <Testimonials isMobile={isMobile} />
          <div>
            <Picture
              sources={vet}
              alt="vet"
              loading="lazy"
              className={styles.vetImage}
            />
          </div>
        </Grid>
        <Text topSpacing="xl" variant="article-20">
          Are you a veterinary professional? Check out our{' '}
          <Link
            href={config('app.vetPortalUrl') as string}
            className={styles.link}
            role="link"
            onClick={trackHomeVetClickedVetPortal}
            target="_blank"
          >
            Vet Team Portal.
          </Link>
        </Text>
      </Grid>
    </section>
  );
};
