import turkeyPng from './turkey.png';
import turkeyWebP from './turkey.webp';

import beefPng from './beef.png';
import beefWebP from './beef.webp';

import porkPng from './pork.png';
import porkWebP from './pork.webp';

import chickenRicePng from './chicken-rice.png';
import chickenRiceWebP from './chicken-rice.webp';

import chickenGreensPng from './chicken-greens.png';
import chickenGreensWebP from './chicken-greens.webp';

import chickenOatsPng from './chicken-oats.png';
import chickenOatsWebP from './chicken-oats.webp';

import porkOatsPng from './pork-oats.png';
import porkOatsWebP from './pork-oats.webp';

export enum RecipeNames {
  TURKEY = 'TURKEY',
  BEEF = 'BEEF',
  PORK = 'PORK',
  CHICKEN_AND_RICE = 'CHICKEN_AND_RICE',
  CHICKEN_AND_GREENS = 'CHICKEN_AND_GREENS',
  CHICKEN_CHIA = 'CHICKEN_CHIA',
  CHICKENL = 'CHICKENL',
  CHICKEN_OATS = 'CHICKEN_OATS',
  CHICKEN_OATS_COLLARDS = 'CHICKEN_OATS_COLLARDS',
  BEEF_MLENTILS = 'BEEF_MLENTILS',
  TURKEYK = 'TURKEYK',
  TURKEYLL = 'TURKEYLL',
  PORK_OATS = 'PORK_OATS',
}

export const freshFoodPictureSources: Record<string, string[]> = {
  [RecipeNames.TURKEY]: [turkeyWebP, turkeyPng],
  [RecipeNames.BEEF]: [beefWebP, beefPng],
  [RecipeNames.PORK]: [porkWebP, porkPng],
  [RecipeNames.CHICKEN_AND_RICE]: [chickenRiceWebP, chickenRicePng],
  [RecipeNames.CHICKEN_AND_GREENS]: [chickenGreensWebP, chickenGreensPng],
  [RecipeNames.CHICKEN_CHIA]: [chickenGreensWebP, chickenGreensPng],
  [RecipeNames.CHICKENL]: [chickenGreensWebP, chickenGreensPng],
  [RecipeNames.CHICKEN_OATS]: [chickenOatsPng, chickenOatsWebP],
  [RecipeNames.CHICKEN_OATS_COLLARDS]: [chickenOatsPng, chickenOatsWebP],
  [RecipeNames.BEEF_MLENTILS]: [beefWebP, beefPng],
  [RecipeNames.TURKEYK]: [turkeyWebP, turkeyPng],
  [RecipeNames.TURKEYLL]: [turkeyWebP, turkeyPng],
  [RecipeNames.PORK_OATS]: [porkOatsWebP, porkOatsPng],
};
