import { useEffect, useState } from 'react';

import { useCorgiViewport } from 'src/screen';

import BezierEasing from 'bezier-easing';
import { useIntersection, useHtmlElement } from '@farmersdog/corgi';

import { HomepageTitle1 } from '../HomepageTitle';

const ANIMATION_LENGTH = 1500;
const FRAME_DURATION = 10;

const COUNTER_MIN = 0;
const COUNTER_MAX_HUNDRED_MILLION = 100_000_000;

const easing = BezierEasing(0, 0.01, 0.1, 1);

const OFFSET = '-40px';

function CounterTitle() {
  const [state, setState] = useState({ start: 0, counter: COUNTER_MIN });
  const [imageEl, imageElRef] = useHtmlElement();
  const intersection = useIntersection(imageEl, {
    rootMargin: OFFSET,
  });

  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  useEffect(() => {
    const handleCounter = () => {
      setState(prevState => {
        const { counter, start } = prevState;

        if (counter < COUNTER_MAX_HUNDRED_MILLION) {
          setTimeout(handleCounter, FRAME_DURATION);
          const progressRatio = (Date.now() - start) / ANIMATION_LENGTH;

          return {
            ...prevState,
            counter:
              progressRatio < 1
                ? Math.round(
                    easing(progressRatio) * COUNTER_MAX_HUNDRED_MILLION
                  )
                : COUNTER_MAX_HUNDRED_MILLION,
          };
        }

        return prevState;
      });
    };

    if (intersection && intersection.isIntersecting && state.start === 0) {
      setState(prevState => ({ ...prevState, start: Date.now() }));
      handleCounter();
    }
  }, [intersection, state]);

  return (
    <div ref={imageElRef}>
      <HomepageTitle1 center style={{ marginBottom: '1.5rem' }}>
        Over
        {isMobile ? <br /> : ' '}
        {state.counter.toLocaleString()}
        {isMobile ? <br /> : ' '} meals delivered.
      </HomepageTitle1>
    </div>
  );
}

export default CounterTitle;
