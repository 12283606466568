import { useQuery } from '@apollo/client';
import { RetryPaymentIsUnlockedQuery } from './RetryPaymentIsUnlocked.cgs.generated-types';
import RetryPaymentIsUnlocked from './RetryPaymentIsUnlocked.cgs.graphql';

/**
 * Retrieve if Payment Retry is unlocked.
 */
export function useRetryPaymentIsUnlocked() {
  return useQuery<RetryPaymentIsUnlockedQuery>(RetryPaymentIsUnlocked, {
    context: { endpoint: 'cgs' },
  });
}
