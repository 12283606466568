import classNames from 'classnames';
import { useEffect } from 'react';

import { Text } from '@farmersdog/corgi-x';

import { trackRecipesViewedRecipeDetails } from '../../analytics';
import { FreshRecipeRecommendation } from '../../graphql/types';
import { LightboxId, useGlobalLightbox } from '../../hooks';
import { RecipeBowlPicture } from '../images/RecipeBowlPicture';

import {
  AnalysisRow,
  AnalysisTable,
  AnalysisBody,
  AnalysisHeader,
} from './AnalysisTable';
import { KeyIngredients } from './KeyIngredients';
import styles from './RecipeModal.module.css';
import { SubHeader } from './SubHeader';

export interface RecipeModalProps {
  recipe: FreshRecipeRecommendation;
  petName?: string;
  withNav?: boolean;
}

const defaultAnalysisParts: string[][] = [];

export function RecipeDetails({ recipe, petName, withNav }: RecipeModalProps) {
  const { labelProps } = useGlobalLightbox({
    id: LightboxId.RecipeDetailModal,
  });

  const asFedAnalysisParts =
    recipe.content.guaranteedAnalysis
      ?.split('\n')
      .map(macro => macro.trim().split(': ')) ?? defaultAnalysisParts;

  useEffect(() => {
    trackRecipesViewedRecipeDetails({ recipeName: recipe.name });
  }, [recipe.name]);

  return (
    <div className={classNames(styles.main)}>
      <div
        className={classNames([
          styles.diagramBackground,
          withNav && styles.withNav,
        ])}
      >
        <RecipeBowlPicture
          recipeName={recipe.name}
          className={styles.diagramImage}
        />
        <KeyIngredients mainIngredients={recipe.content.mainIngredients} />
      </div>
      <div className={styles.content}>
        <Text variant="heading-28" bold color="kale-3" as="h2" {...labelProps}>
          {petName && `${petName}’s `}
          {recipe.content.displayName}
        </Text>
        <SubHeader>Ingredients</SubHeader>
        <Text className={styles.xsTop} variant="article-16" as="div">
          <strong>{recipe.content.mainIngredients}</strong>
        </Text>
        <Text
          className={styles.mdTop}
          variant="heading-16"
          color="kale-3"
          bold
          as="div"
        >
          TFD Nutrient Blend
        </Text>
        <Text
          className={classNames([styles.xsTop, styles.supplements])}
          variant="article-16"
          as="div"
        >
          {recipe.content.supplements}
        </Text>
        <SubHeader>Nutritional Info</SubHeader>
        <AnalysisTable className={styles.smTop}>
          <AnalysisHeader heading="As-Fed Guaranteed Analysis" />
          <AnalysisBody>
            {asFedAnalysisParts.map(([name, percentage]) => {
              return (
                <AnalysisRow key={name} name={name} percentage={percentage} />
              );
            })}
          </AnalysisBody>
        </AnalysisTable>
        <SubHeader>AAFCO statement</SubHeader>
        <Text className={styles.xsTop} variant="article-16" as="div">
          The Farmer’s Dog {recipe.content.displayName} Recipe for Dogs is
          formulated to meet the nutritional levels established by the AAFCO Dog
          Food Nutrient Profiles for all life stages, including growth of large
          sized dogs (70 lbs. or more as an adult).
        </Text>
        <SubHeader>Calorie content</SubHeader>
        <Text variant="article-16" as="div">
          <p className={styles.smTop}>{recipe.content.calorieContent}</p>
        </Text>
      </div>
    </div>
  );
}
