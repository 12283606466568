/**
 * Safely execute browser only code.
 *
 * @param fn - The function to execute.
 * @param fallback - The default return value.
 *
 */

function browserOnly<T>(
  fn: (window: Window, document: Document) => T,
  fallback: () => T
): T;

function browserOnly<T>(
  fn: (window: Window, document: Document) => T
): T | undefined;

function browserOnly<T>(
  fn: (window: Window, document: Document) => T,
  fallback?: () => T
): T | undefined {
  if (typeof window !== 'undefined') {
    return fn(window, document);
  }

  if (fallback) return fallback();
  return;
}

export { browserOnly };
