import backgroundPng from './background.png';
import backgroundJpg from './background.jpg';
import backgroundWebp from './background.webp';

import backgroundMobilePng from './background-mobile.png';
import backgroundMobileJpg from './background-mobile.jpg';
import backgroundMobileWebp from './background-mobile.webp';

export const background = [backgroundWebp, backgroundJpg, backgroundPng];
export const backgroundMobile = [
  backgroundMobileWebp,
  backgroundMobileJpg,
  backgroundMobilePng,
];
