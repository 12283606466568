import { getCacheBuster } from '../../../utils';
import {
  claritas,
  IHeartRadioEventId,
  IHeartRadioEventName,
  I_HEART_RADIO_PIXEL_ID,
} from '../claritas';

/**
 * Mount an iHeartRadio lead conversion pixel
 */
export function mountIHeartRadioMeConversion(): void {
  const ord = getCacheBuster();
  claritas.mount({
    ord,
    ppt: I_HEART_RADIO_PIXEL_ID,
    g: IHeartRadioEventName.LEAD_CONVERSION,
    gid: IHeartRadioEventId.LEAD_CONVERSION,
  });
}
