import { iSpot, ISpotEventName } from './iSpot';
import { getSharedISpotParams } from './getSharedISpotParams';

/**
 * Track the iSpot leads conversion event
 */

export function trackISpotMeConversion({ utmSource }: { utmSource: unknown }) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { customData, channel, app } = getSharedISpotParams({
    utmSource,
  });

  iSpot.track({
    app,
    type: ISpotEventName.LEADS,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
