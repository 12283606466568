import { Text, PageWidth } from '@farmersdog/corgi-x';

import styles from './ReviewsSection.module.css';
import { ReviewsSlider } from './ReviewsSlider/ReviewsSlider';

export const ReviewsSection = () => {
  return (
    <div data-testid="reviews-section" className={styles.container}>
      <PageWidth>
        <Text
          bold
          as="h2"
          className={styles.title}
          variant="heading-40"
          color="kale-3"
        >
          Healthy dogs and happy dog people.
        </Text>
        <Text
          as="p"
          className={styles.subtitle}
          variant="heading-16"
          color="charcoal-3"
        >
          Because dog food should be food — not burnt{' '}
          <span className={styles.mobileBreak}>brown balls</span>.
        </Text>
        <ReviewsSlider />
      </PageWidth>
    </div>
  );
};
