import {
  useFeature,
  UseFeatureOptions,
  getTreatmentFromQueryString,
} from '@farmersdog/ab-testing';
import { useLocation } from 'react-router';
import { splitDebug } from '../../../debug';
import {
  AnonymousFeatures,
  FeatureKeys,
  FeaturesMap,
  UserFeatures,
} from './types';

export function usePawFeature<K extends FeatureKeys>(
  name: K,
  options?: UseFeatureOptions
) {
  const fromSplit = useFeature<AnonymousFeatures, UserFeatures, K>(
    name,
    options
  );

  const { search } = useLocation();
  const fromQueryParam = getTreatmentFromQueryString<FeaturesMap, FeatureKeys>({
    queryString: search,
    treatmentName: name,
  });

  const fromDebug = splitDebug.get(name);

  if (fromQueryParam) {
    return { ...fromQueryParam, isReady: true };
  } else if (fromDebug) {
    return {
      treatment: fromDebug.treatment,
      config: fromDebug.config,
      isReady: true,
    };
  } else {
    return fromSplit;
  }
}
