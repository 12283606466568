import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

export enum X_Events {
  Lead = 'tw-o28pe-odnpi',
  Add_To_Cart = 'tw-o28pe-odnpn',
  Purchase = 'tw-o28pe-odnog',
}

export type XSdk = (
  command: 'event',
  eventName: X_Events,
  properties?: Record<string, unknown>
) => void;

declare global {
  interface Window {
    twq?: XSdk;
  }
}

interface MountXEventParams {
  eventName: X_Events;
}
export function _mountXEvents(
  window: Window,
  { eventName }: MountXEventParams
) {
  const sdk = window.twq;
  sdk?.('event', eventName);
}

export const mountXEvent = mountVendorWindowEvent('X', _mountXEvents);
