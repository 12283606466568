import PropTypes from 'prop-types';
import config from 'src/config';
import { Mask, Text } from '@farmersdog/corgi-x';

import { KALE_3, WHITE } from '@farmersdog/corgi/constants';

import { Viewport } from 'src/screen';
import Flex from 'src/components/_depreciated/Flex';

import { useReferrer } from 'src/hooks/useReferrer';
import { getDiscountAmountCopy } from 'src/utils/referral';
import { DiscountType } from 'src/graphql/enums';
import Present from 'src/assets/icons/present.svg';

import './DiscountBadge.scss';

function XSView({ discountAmount, discountType, ...props }) {
  const shouldReduceFontSize =
    discountAmount === 100 && discountType === DiscountType.percentage;

  return (
    <Flex
      {...props}
      style={{ marginBottom: 8, marginLeft: 5 }}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Mask shape="circle" size="xs">
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ background: KALE_3 }}
        >
          <Present fill={WHITE} width={24} height={24} alt="Present Icon" />
        </Flex>
      </Mask>
      <Text
        variant="heading-12"
        color="kale-3"
        bold
        style={{ textTransform: 'uppercase', marginTop: 8 }}
        className={shouldReduceFontSize ? 'mobileFreeTrialText' : null}
        id="checkout-discount-badge-discount-copy"
      >
        {getDiscountAmountCopy({ discountAmount, discountType })}
      </Text>
    </Flex>
  );
}

XSView.propTypes = {
  discountAmount: PropTypes.number,
  discountType: DiscountType,
  style: PropTypes.object,
};

function LGView({ discountAmount, discountType, ...props }) {
  return (
    <Flex {...props} style={{ marginTop: -9 }} alignItems="center">
      <Mask shape="circle" size="sm">
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ background: KALE_3 }}
        >
          <Present fill={WHITE} width={32} height={32} alt="Present Icon" />
        </Flex>
      </Mask>
      <span style={{ marginTop: 4, marginLeft: 10 }}>
        <Text variant="heading-16" color="kale-3" bold as="h2">
          {discountAmount === 100 && discountType === DiscountType.percentage
            ? 'Free trial'
            : 'Special offer'}
        </Text>
        <Text
          variant="heading-16"
          color="kale-3"
          bold
          as="p"
          id="checkout-discount-badge-discount-copy"
        >
          {getDiscountAmountCopy({ discountAmount, discountType })}
        </Text>
      </span>
    </Flex>
  );
}

LGView.propTypes = {
  discountAmount: PropTypes.number,
  discountType: DiscountType,
  style: PropTypes.object,
};

function DiscountBadge({ style }) {
  const { hasCalledFetch, discountAmount, discountType } = useReferrer();

  return hasCalledFetch && discountAmount > 0 && config('features.trial') ? (
    <Viewport
      discountAmount={discountAmount}
      discountType={discountType}
      className="DiscountBadge"
      style={style}
      xs={XSView}
      lg={LGView}
    />
  ) : null;
}

DiscountBadge.propTypes = {
  style: PropTypes.object,
};

export default DiscountBadge;
