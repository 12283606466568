import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { SubscriptionType } from 'src/graphql/types';
import { useSetSignupFlow } from 'src/pages/Signup/DIY/hooks/useSetSignupFlow';
import { QueryParameter } from '@farmersdog/constants';

import { getCurrentSignupSubscriptionType } from '@farmersdog/lead-browser-storage';

function isValidSubscriptionType(value: unknown): value is SubscriptionType {
  return Object.values(SubscriptionType).includes(value as SubscriptionType);
}

export function useSetSignupFlowByUrl() {
  const { search } = useLocation();

  const params = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const activeSubscriptionType = params?.[QueryParameter.ProductLine];
  const { setActiveSignupFlow, unlockDiySignupFlow } = useSetSignupFlow();

  const currentSubscriptionType = getCurrentSignupSubscriptionType();

  useEffect(() => {
    const subscriptionTypeHasChanged =
      activeSubscriptionType !== currentSubscriptionType;

    if (
      isValidSubscriptionType(activeSubscriptionType) &&
      subscriptionTypeHasChanged
    ) {
      setActiveSignupFlow(activeSubscriptionType);
      if (activeSubscriptionType === SubscriptionType.Diy) {
        unlockDiySignupFlow();
      }
    }
  }, [
    activeSubscriptionType,
    currentSubscriptionType,
    setActiveSignupFlow,
    unlockDiySignupFlow,
  ]);
}
