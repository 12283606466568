import classNames from 'classnames';

import { Text, Picture } from '@farmersdog/corgi-x';

import styles from './Benefit.module.css';

interface BenefitProps {
  sources: string[];
  title: string;
  description: string;
  textPositionLeft?: boolean;
  alt?: string;
}

export function Benefit({
  description,
  sources,
  title,
  textPositionLeft,
  alt = '', // Empty alt text indicates an image is decorative
}: BenefitProps) {
  const containerStyles = classNames(styles.container, {
    [styles.reverseRow]: textPositionLeft,
    [styles.row]: !textPositionLeft,
  });

  const benefitTextStyles = classNames(styles.benefitText, {
    [styles.benefitTextLeft]: textPositionLeft,
  });

  return (
    <div className={containerStyles}>
      <Picture sources={sources} alt={alt} className={styles.benefitImage} />
      <div className={benefitTextStyles}>
        <Text
          variant="heading-28"
          color="kale-3"
          className={styles.title}
          bold
          as="h3"
        >
          {title}
        </Text>
        <Text
          variant="article-16"
          color="charcoal-3"
          className={styles.description}
        >
          {description}
        </Text>
      </div>
    </div>
  );
}
