import { Select } from '@farmersdog/corgi-x';
import { AddDogFormChangeHandler, AgeUnitOptions } from '../../../hooks';

interface AgeUnitInputProps {
  ageUnit: AgeUnitOptions;
  onChange: AddDogFormChangeHandler;
}

export const AgeUnitInput = (props: AgeUnitInputProps) => {
  const { ageUnit, onChange } = props;

  const handleAgeUnitChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = e => {
    onChange('ageUnit', e.target.value as AgeUnitOptions);
  };
  return (
    <Select
      name="age-unit"
      onChange={handleAgeUnitChange}
      value={ageUnit}
      aria-required
    >
      <option value={AgeUnitOptions.Weeks}>Weeks</option>
      <option value={AgeUnitOptions.Months}>Months</option>
      <option value={AgeUnitOptions.Years}>Years</option>
    </Select>
  );
};
