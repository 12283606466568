import noop from 'lodash/noop';
import { useContext, useCallback, useEffect } from 'react';

import {
  useLightboxControl,
  ANIMATION_DURATION,
  UseLightboxControlProps,
  LightboxControlReturn,
} from '@farmersdog/corgi';

import {
  showLightboxById,
  hideLightboxById,
  setSuppressLightboxById,
} from './actions';
import { LightboxContext } from './context';
import { getIsLightboxCurrentlyOpen, wait } from './helpers';
import { LightboxId } from './lightboxIds';

interface GlobalLightboxProps extends Omit<UseLightboxControlProps, 'id'> {
  id: LightboxId;
}

export interface GlobalLightboxReturn extends LightboxControlReturn {
  setSuppressed: (suppressed: boolean) => void;
  open: (opts?: { forceOpen?: boolean }) => void;
}

/**
 * Create a controller for a lightbox or modal with a global context based on an
 * id attribute. Use it the same way you would use the hook `useLightboxControl`
 * with the exception that the the id attribute will map your controller to any
 * lightbox with the same id.
 *
 * @example
 * ```ts
 * function MyModal() {
 *   const { rootProps } = useGlobalLightbox({ id: 'unique-id' })
 *
 *   return <Lightbox {...rootProps}><p>Yay!</p></Lightbox>
 * }
 *
 * function INeedToOpenTheModal() {
 *   const { open } = useGlobalLightbox({ id: 'unique-id' })
 *
 *   return <button onClick={open}>Open your Modal!</button>
 * }
 * ```
 *
 */
export function useGlobalLightbox({
  id,
  onClose,
  onOpen,
  initialIsOpen,
  ...props
}: GlobalLightboxProps): GlobalLightboxReturn {
  const { state, dispatch } = useContext(LightboxContext);
  const isLightboxCurrentlyOpen = getIsLightboxCurrentlyOpen(state, id);

  const globalOpen = useCallback(
    ({ forceOpen = true }: { forceOpen?: boolean } = {}) => {
      dispatch(showLightboxById({ id, forceOpen }));
    },
    [dispatch, id]
  );

  const globalClose = useCallback(() => {
    dispatch(hideLightboxById(id));
    return wait(ANIMATION_DURATION, noop);
  }, [dispatch, id]);

  const globalSuppress = useCallback(
    (suppressed: boolean) => {
      dispatch(setSuppressLightboxById({ id, suppressed }));
    },
    [dispatch, id]
  );

  const handleOpen = () => {
    if (!isLightboxCurrentlyOpen) {
      globalOpen();
    }

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    if (isLightboxCurrentlyOpen) {
      void globalClose();
    }

    if (onClose) {
      onClose();
    }
  };

  const { isOpen, open, close, ...controlProps } = useLightboxControl({
    onOpen: handleOpen,
    onClose: handleClose,
    initialIsOpen: Boolean(isLightboxCurrentlyOpen),
    id,
    ...props,
  });

  useEffect(() => {
    if (isLightboxCurrentlyOpen && !isOpen) {
      open();
    }

    if (!isLightboxCurrentlyOpen && isOpen) {
      close();
    }
  }, [isLightboxCurrentlyOpen, isOpen, open, close]);

  // On initialization if the consumer sets initialIsOpen to true we need to set
  // open on the global state.
  useEffect(() => {
    if (!isLightboxCurrentlyOpen && initialIsOpen) {
      globalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...controlProps,
    open: globalOpen,
    close: globalClose,
    setSuppressed: globalSuppress,
    isOpen: isLightboxCurrentlyOpen,
  };
}

export default useGlobalLightbox;
