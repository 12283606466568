/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import { LogoProps } from './Logos.types';

import VoguePng from 'src/assets/images/logos/vogue.png';
import VogueWebP from 'src/assets/images/logos/vogue.webp';

export function VogueLogo(props: LogoProps) {
  return (
    <Picture sources={[VogueWebP, VoguePng]} alt="Vogue Logo" {...props} />
  );
}
