export const OTHER_MEAT = {
  label: 'Other meat',
  value: 'other-meat',
};

export const OTHER_FISH = {
  label: 'Other fish',
  value: 'other-fish',
};

export const MEAT_OPTIONS = [
  {
    label: 'Beef',
    value: 'beef',
  },
  {
    label: 'Bison',
    value: 'bison',
  },
  {
    label: 'Chicken',
    value: 'chicken',
  },
  {
    label: 'Duck',
    value: 'duck',
  },
  {
    label: 'Lamb, sheep, or goat',
    value: 'lamb-sheep-goat',
  },
  {
    label: 'Pork',
    value: 'pork',
  },
  {
    label: 'Turkey',
    value: 'turkey',
  },
  OTHER_MEAT,
];

export const FISH_OPTIONS = [
  {
    label: 'Salmon',
    value: 'salmon',
  },
  {
    label: 'Cod (whitefish)',
    value: 'cod',
  },
  {
    label: 'Pollock (whitefish)',
    value: 'pollock',
  },
  {
    label: 'Trout (whitefish)',
    value: 'trout',
  },
  {
    label: 'Any whitefish',
    value: 'any-whitefish',
  },
  OTHER_FISH,
];
