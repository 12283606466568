import {
  PropsWithChildren,
  ReactNode,
  useContext,
  FC,
  ComponentType,
} from 'react';
import has from 'lodash/has';
import omit from 'lodash/omit';

import isRenderable from './isRenderable';
import isNamedComponent from './isNamedComponent';
import { ScreenContext } from './createScreenContext';
import { Config, ViewportProps, ViewportName } from 'src/screen/types';

export type ViewportComponentProps = ViewportProps<ReactNode> & {
  /** Whether to include raw screen information as props */
  screenInfo?: boolean;
};

interface RenderableProps {
  matchedViewports?: ViewportName[];
  matchedViewport?: ViewportName;
}

const createViewport = (Context: ScreenContext, config: Config) => {
  const viewportNames = config.viewports.map(viewport => viewport.name);

  /**
   * Use the `Viewport` component to render different elements according to the
   * viewport width (`xs`, `sm`, `md`, `lg` and `xl`).
   *
   * This component requires to be wrapped by `ScreenProvider` in one of the
   * parent components, which usually happens in the root component.
   *
   * @example
   * ```jsx
   *  import { Viewport } from 'src/screen';
   *  <Viewport xs={<p>Hello mobile user</p>} md={<p>Hello desktop user</p>} />;
   * ```
   */
  function Viewport({
    screenInfo = false,
    ...props
  }: PropsWithChildren<ViewportComponentProps>) {
    const { matchedViewports, matchedViewport } = useContext(Context);
    const matchedProp = matchedViewports.find(screenName =>
      has(props, screenName)
    );

    const Renderable = matchedProp
      ? props[matchedProp as keyof typeof props]
      : null;

    if (isRenderable(Renderable)) {
      return <>{Renderable}</>;
    }

    const componentProps = omit(props, viewportNames);
    const renderableProps = screenInfo
      ? { ...componentProps, matchedViewports, matchedViewport }
      : componentProps;

    if (isNamedComponent(Renderable, viewportNames)) {
      const NamedComponent = Renderable as unknown as ComponentType<
        PropsWithChildren<RenderableProps>
      >;
      return NamedComponent && <NamedComponent {...renderableProps} />;
    }

    const AsFunction = Renderable as unknown as FC<
      PropsWithChildren<RenderableProps>
    >;
    return AsFunction && AsFunction({ ...renderableProps });
  }

  return Viewport;
};

export default createViewport;
