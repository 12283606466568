import { Text } from '@farmersdog/corgi-x';
import { formatNames } from '../../../utils/formatNames';

export interface SummaryTitleProps {
  petNames: string[];
}

export function SummaryTitle(props: SummaryTitleProps) {
  const formattedPetNames =
    formatNames(props.petNames, { possessive: true }) || 'your dog’s';

  return (
    <section>
      <Text variant="heading-40" as="h2" color="kale-3" bold>
        {props.petNames.length === 1 ? 'My Dog' : 'My Dogs'}
      </Text>
      <Text variant="article-16" as="p" color="charcoal-3" topSpacing="xs">
        {`View and edit ${formattedPetNames} information, portions, and recipes.`}
      </Text>
    </section>
  );
}
