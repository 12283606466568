import { hashSha } from '@farmersdog/utils';
import { TikTokHashedData, TikTokCheckoutData } from './TikTokScript';
import { mountVendorWindowEvent } from '../utils/mountVendorWindowEvent/mountVendorWindowEvent';

export interface TikTokData {
  email?: string;
  phone?: string;
  // NOTE: event_id used for deduplicating web pixel events with segment events
  event_id?: string;
  value?: string;
  currency?: TikTokCheckoutData.currency;
}

const US_COUNTRY_CODE = '1';

async function _trackTikTokEvent(
  window: Window,
  event: string,
  data: TikTokData = {}
) {
  if (typeof window.ttq !== 'undefined') {
    try {
      const hashedData = await hashTikTokData(data);
      window.ttq.identify(hashedData);
      window.ttq.track(event, {
        event_id: data?.event_id,
        // hardcoded event data for every event type
        content_type: 'product',
        content_id: 'fresh_food',
        value: data?.value,
        currency: data?.currency,
      });
    } catch {
      window.ttq.track(event, {
        event_id: data?.event_id,
        content_type: 'product',
        content_id: 'fresh_food',
        value: data?.value,
        currency: data?.currency,
      });
    }
  }
}

export const trackTikTokEvent = mountVendorWindowEvent(
  'TikTok',
  _trackTikTokEvent
);

async function hashTikTokData(data: TikTokData): Promise<TikTokHashedData> {
  const hashedData: TikTokHashedData = {};

  if (data.email) {
    hashedData.sha256_email = await hashSha({
      stringToHash: data.email,
      algorithm: 'SHA-256',
    });
  }

  if (data.phone) {
    hashedData.sha256_phone_number = await hashSha({
      stringToHash: `+${US_COUNTRY_CODE}${data.phone}`,
      algorithm: 'SHA-256',
    });
  }

  return hashedData;
}
