import { Input, CreditCardInput } from '@farmersdog/corgi-x';
import styles from './CreditCardDetails.module.css';
import { UseCreditCardForm } from '../hooks/useCreditCardForm';
import classNames from 'classnames';

export interface CreditCardCvcProps {
  className: string;
  isEditing: boolean;
  onCreditCardCvcFocus: UseCreditCardForm['handleCreditCardDetailsFocus'];
  onCreditCardCvcChange: UseCreditCardForm['handleCreditCardDetailsChange'];
}

/**
 * Shows placeholder for credit card cvc (***) until `props.isEditing` is
 * `true`, which exposes the Corgi/Stripe-integrated component and hides the
 * placeholder.
 */
export function CreditCardCvc(props: CreditCardCvcProps) {
  return (
    <div className={classNames(styles.parent, props.className)}>
      {props.isEditing ? (
        <CreditCardInput
          type="cardCvc"
          className={styles.cardCvc}
          label="Security Code"
          onChange={e => props.onCreditCardCvcChange({ cvc: e.complete })}
        />
      ) : (
        <Input
          className={styles.placeholderElement}
          label="Security Code"
          value="***"
          onFocus={props.onCreditCardCvcFocus}
        />
      )}
    </div>
  );
}
