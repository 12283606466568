import { createClient } from '@prismicio/client';
import crossFetch from 'cross-fetch';
import { useMemo } from 'react';
import config from '../../config/config';

/**
 * This returns a new Prismic client at runtime. We have to use this because the
 * config values are not ready at build time. This can also be called as needed
 * for a new client in test environment.
 */
export function getPrismicClient() {
  return createClient(config.get('prismic.apiUrl'), {
    accessToken: config.get('prismic.token'),
    fetch: crossFetch,
    //  Identifies the version of the content to use as input for the build
    //  ref: config.get('prismic.ref'),
  });
}

/**
 * Use this to get a single instance of the Prismic client for each root render
 * of the React application.
 */
export function usePrismicClient() {
  return useMemo(() => {
    return getPrismicClient();
  }, []);
}
