import get from 'lodash/get';

import getProperties, { Properties } from './get-properties';

import type { Path, PathValue } from 'convict';

type PathParam = Path<Properties>;
type GetReturn<K extends PathParam> = K extends null | undefined
  ? Properties
  : K extends Path<Properties>
    ? PathValue<Properties, K>
    : never;

export default {
  get<K extends PathParam>(path: K): GetReturn<K> {
    const properties = getProperties();
    return get(properties, path) as GetReturn<K>;
  },
};
