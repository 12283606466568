import classNames from 'classnames';

import styles from './Loader.module.css';

interface LoaderProps {
  isStatic?: boolean;
  variant?: 'blue' | 'green';
}

export function Loader({ isStatic, variant = 'blue' }: LoaderProps) {
  return (
    <div
      className={classNames(styles.dots, {
        [styles.static]: isStatic,
        [styles.green]: variant === 'green',
      })}
    />
  );
}
