import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
} from '@farmersdog/constants/paths';
import { Text } from '@farmersdog/corgi-x';
import { useSetCouponCookie } from '@farmersdog/coupons';
import { signupState } from '@farmersdog/lead-browser-storage';

import { TosaLayout } from './components/layout';
import { Node } from './components/schema/Node';
import { SignupHeader, DynamicPageTitle } from './components/shared';
import { CalculatingScreen } from './components/shared/CalculatingScreen/CalculatingScreen';
import { ErrorModal } from './components/shared/ErrorModal';
import { TosaDevTools } from './components/TosaDevTools';
import config from './config';
import {
  LightboxProvider,
  useRecoverSignupByUrl,
  ReloadApp,
  RequestAttributesProvider,
  useLeadState,
} from './hooks';
import {
  useFeaturePlaceholder,
  useAnonymousExperiments,
} from './hooks/experiments';
import { useDefaultDiscountExperiment } from './hooks/experiments/useDefaultDiscountExperiment';
import { useConfigureGa4 } from './hooks/useConfigureGa4';
import { useDebugTosa } from './hooks/useDebugTosa';
import { useFormNavigation } from './hooks/useFormNavigation';
import { useFormQueryUrl } from './hooks/useFormQueryUrl';
import { useFormSubmit } from './hooks/useFormSubmit';
import { useFormValidation } from './hooks/useFormValidation';
import {
  useHandleFormCompleted,
  OnTosaCompleted,
  OnStepCompleted,
  OnMeStepCompleted,
} from './hooks/useHandleFormCompleted';
import { useHandleMeCompleted } from './hooks/useHandleMeCompleted';
import { useHandlePageView } from './hooks/useHandlePageView';
import { useHandlePetsCompleted } from './hooks/useHandlePetsCompleted';
import { useHandleStepCompleted } from './hooks/useHandleStepCompleted';
import { useOverrideRoute } from './hooks/useOverrideRoute';
import { useProgress } from './hooks/useProgress';
import { useRestoreLeadProgress } from './hooks/useRestoreLeadProgress';
import { useRoute } from './hooks/useRoute';
import { useSchema, GetBlueprint } from './hooks/useSchema';
import { useScrollToTop } from './hooks/useScrollToTop';
import styles from './TOSA.css';
import { FormFieldsType, PetRecipes, UseFeatureHook } from './types';
import { getBlueprintVersion } from './utils/getBlueprintVersion';
import { redirectIfNodeRemoved } from './utils/redirectIfNodeRemoved';

export interface TOSAProps {
  getBlueprint: GetBlueprint;
  onTosaCompleted?: OnTosaCompleted;
  onMeCompleted?: OnMeStepCompleted;
  onStepCompleted?: OnStepCompleted;
  useFeature?: UseFeatureHook;
  reloadApp: ReloadApp;
  clearSignupUser: () => void;
}

const CALCULATING_SCREEN_PATHS: string[] = [
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
];

export function TOSAComponent({
  getBlueprint,
  onMeCompleted: websiteOnMeCompleted,
  onTosaCompleted,
  onStepCompleted: websiteOnStepCompleted,
  reloadApp,
  useFeature: websiteUseFeature,
  clearSignupUser,
}: TOSAProps) {
  const enableDevTools = config.get('features.tosaDevTools');
  const useFeature = websiteUseFeature ?? useFeaturePlaceholder;
  const [restoreComplete, setRestoreComplete] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<null | string>(
    null
  );

  useConfigureGa4();
  const location = useLocation();

  const shouldRenderCalculatingScreen = !CALCULATING_SCREEN_PATHS.includes(
    location.pathname
  );

  const experiments = useAnonymousExperiments(useFeature);
  const blueprintVersion = getBlueprintVersion();

  const { schema, registry, formSteps, setFormSteps } = useSchema({
    getBlueprint,
    blueprintVersion,
    experiments,
  });

  const [availablePetRecipesCache, setAvailablePetRecipesCache] =
    useState<PetRecipes>({});
  const clearAvailablePetRecipesCache = () => setAvailablePetRecipesCache({});

  const onMeCompleted = useHandleMeCompleted(websiteOnMeCompleted);
  const onPetsCompleted = useHandlePetsCompleted();
  const onStepCompleted = useHandleStepCompleted(websiteOnStepCompleted);

  const urlEmail = useRecoverSignupByUrl();

  useSetCouponCookie();

  const formNavigation = useFormNavigation();
  const { current } = formNavigation;

  const { branchValidationSchema, formValidationSchema } = useFormValidation({
    current,
    root: schema?.root,
    registry,
  });

  const formMethods = useForm<FormFieldsType>({
    resolver: yupResolver(branchValidationSchema),
    defaultValues: {},
    mode: 'onChange',
  });

  useFormQueryUrl({
    formMethods,
    loaded: Boolean(schema && current && restoreComplete),
    schema,
    registry,
    setFormSteps,
    experiments,
  });

  useRoute({
    registry,
    current,
    blueprint: schema,
    setFormSteps,
    formNavigation,
    formMethods,
    formSteps,
    experiments,
  });

  const { getValues, reset } = formMethods;

  const { subscriptionTypes } = signupState.get();
  const productLine = subscriptionTypes.current;

  const progress = useProgress({
    schema,
    formSteps,
    formMethods,
    formNavigation,
    useFeature,
  });

  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();
  redirectIfNodeRemoved({
    current,
    formSteps,
    formNavigation,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    experiments,
    lead,
  });

  useScrollToTop({ formNavigation });
  useDebugTosa({ formMethods });

  useRestoreLeadProgress({
    formNavigation,
    urlEmail,
    formSteps,
    setFormSteps,
    blueprint: schema,
    reset,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    getLastCompletedBranch: progress.getLastCompletedBranch,
    getFirstBlockedBranch: progress.getFirstBlockedBranch,
    handleRestoreComplete: () => setRestoreComplete(true),
    experiments,
  });

  useOverrideRoute({
    registry,
    getValues,
    formSteps,
    getBranchComplete: progress.getBranchComplete,
    formNavigation,
  });

  const handleFormCompleted = useHandleFormCompleted({
    formSteps,
    onMeCompleted,
    onPetsCompleted,
    onTosaCompleted,
    onStepCompleted,
    experiments,
  });

  useHandlePageView({
    current,
    formSteps,
  });

  const [handleSubmit, formSubmitRequest] = useFormSubmit({
    formNavigation,
    formValidationSchema,
    formSteps,
    progress,
    onFormCompleted: handleFormCompleted,
    experiments,
    setErrorModalMessage,
    clearAvailablePetRecipesCache,
  });

  useDefaultDiscountExperiment({ experiments });

  if (!schema || !registry || !current) {
    return null;
  }

  return (
    <div className={styles.root}>
      <DynamicPageTitle />
      <SignupHeader
        formNavigation={formNavigation}
        formSteps={formSteps}
        registry={registry}
        getValues={getValues}
        blueprint={schema}
        setFormSteps={setFormSteps}
        reset={reset}
        useFeature={useFeature}
        experiments={experiments}
      />
      <ErrorModal
        errorMessage={errorModalMessage}
        setErrorModalMessage={setErrorModalMessage}
        reloadApp={reloadApp}
      />
      {shouldRenderCalculatingScreen && (
        <CalculatingScreen productLine={productLine} useFeature={useFeature} />
      )}
      <TosaLayout useFeature={useFeature}>
        <Text variant="heading-28" color="charcoal-3" as="div">
          <Node
            onSubmit={handleSubmit}
            onFormCompleted={handleFormCompleted}
            formMethods={formMethods}
            formSubmitRequest={formSubmitRequest}
            schema={schema}
            registry={registry}
            setFormSteps={setFormSteps}
            formNavigation={formNavigation}
            node={schema.root}
            formSteps={formSteps}
            progress={progress}
            formValidationSchema={formValidationSchema}
            reloadApp={reloadApp}
            useFeature={useFeature}
            experiments={experiments}
            petRecipes={availablePetRecipesCache}
            setPetRecipes={setAvailablePetRecipesCache}
            setErrorModalMessage={setErrorModalMessage}
            clearAvailablePetRecipesCache={clearAvailablePetRecipesCache}
            clearSignupUser={clearSignupUser}
          />
        </Text>
        {enableDevTools && (
          <TosaDevTools
            formMethods={formMethods}
            schema={schema}
            registry={registry}
            formSteps={formSteps}
            setFormSteps={setFormSteps}
            experiments={experiments}
            goToStep={formNavigation.goToStep}
          />
        )}
      </TosaLayout>
    </div>
  );
}

export function TOSA(props: TOSAProps) {
  return (
    <RequestAttributesProvider>
      <LightboxProvider>
        <TOSAComponent {...props} />
      </LightboxProvider>
    </RequestAttributesProvider>
  );
}
