export const getBodyConditionEducationDetails = ({
  isWeightLoss,
}: {
  isWeightLoss?: boolean;
}) => {
  if (isWeightLoss) {
    return {
      heading: <>Weight management, made easy</>,
      information: (
        <>
          We make it simple to meet and maintain your dog’s ideal weight with
          personalized, vet-recommended portions.
        </>
      ),
    };
  }
  return {
    heading: <>Goodbye guesstimated scoops</>,
    information: (
      <>
        Your dog’s food arrives pre-portioned just for them, so you can easily
        feed the right amount.
      </>
    ),
  };
};
