import { Config } from 'src/screen/types';
import createScreenContext from './createScreenContext';
import createUseViewport from './createUseViewport';
import createViewport from './createViewport';
import createOrientation from './createOrientation';
import createViewportDebug from './createViewportDebug';

function createScreen(config: Config) {
  const { ScreenContext, ScreenProvider } = createScreenContext(config);

  const useViewport = createUseViewport(ScreenContext);
  const Orientation = createOrientation(ScreenContext);
  const Viewport = createViewport(ScreenContext, config);
  const ViewportDebug = createViewportDebug(Viewport, config);

  return { ScreenProvider, Viewport, ViewportDebug, Orientation, useViewport };
}

export default createScreen;
