import { useDispatch } from 'react-redux';

import { logout } from 'src/actions/auth';
import { trackLogoutFrontendSuccess } from 'src/analytics';
import { useDisplayDesktopNav } from 'src/hooks/useDisplayDesktopNav';
import { useCorgiViewport } from 'src/screen';

import { UseCustomerAccountData } from '@/account/app/hooks/useCustomerAccountData';
import { getNavigation } from '@/account/app/utils/getNavigation/getNavigation';

export function useCustomerAccountNavigation(data: UseCustomerAccountData) {
  const dispatch = useDispatch();
  const showDesktopNav = useDisplayDesktopNav();

  const isMobile = useCorgiViewport({
    xs: true,
    lg: !showDesktopNav,
  });

  const callLogout = () => {
    dispatch(logout());
    trackLogoutFrontendSuccess();
  };

  return getNavigation(data, callLogout, isMobile);
}
