export const TerminalStatesEnum = {
  CancelSuccess: 'CancelSuccess',
} as const;

export type TerminalStatesEnumType = keyof typeof TerminalStatesEnum;

/**
 * A terminal state is found at the end of the cancellation reasons
 * flow. There is no further path forward or back, and there is no
 * association with any reason options.
 *
 * An example of this state is when the user has successfully completed
 * cancellation.
 */
export interface TerminalState {
  /** A unique identifier for the state, corresponding to a reason enum */
  value: TerminalStatesEnumType;
}

const cancelSuccessState = {
  value: TerminalStatesEnum.CancelSuccess,
};

const terminalStatesEnum: Record<string, TerminalState> = {
  [TerminalStatesEnum.CancelSuccess]: cancelSuccessState,
};

export const getTerminalStates = () => terminalStatesEnum;
