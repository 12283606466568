import axios from 'axios';

export default (options = {}) =>
  axios.create({
    ...options,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Expose-Headers':
        'Content-disposition, Content-Length, Content-Type',
    },
  });
