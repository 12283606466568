import { Text, PageWidth } from '@farmersdog/corgi-x';

import { delivery } from '../assets/delivery';
import { feed } from '../assets/feed';
import { formulated } from '../assets/formulated';
import { healthy } from '../assets/healthy';
import { Benefit } from '../Benefit/Benefit';

import styles from './RecipesBenefits.module.css';

interface RecipesBenefitsProps {
  petName: string;
}

export const RecipesBenefits = (props: RecipesBenefitsProps) => {
  return (
    <PageWidth data-testid="recipes-benefits-section">
      <div className={styles.title}>
        <Text variant="heading-40" bold color="kale-3" as="h2">
          Better for them.
          <span className={styles.subtitle}> Easier for you.</span>
        </Text>
      </div>
      <Benefit
        title="Lasting Health Benefits"
        sources={healthy}
        description={`Here are a handful of the improvements you can expect when you start to
        add fresh, real food to your dog’s diet: better digestion, stronger
        immune systems, healthier skin and coats, and increased vitality.`}
      />
      <Benefit
        title="Vet Formulated"
        sources={formulated}
        textPositionLeft
        description={`Why should pets eat highly processed food for every meal of their lives?
        We partnered with veterinary nutritionists to create fresh, healthy
        recipes that contain all of the vitamins and nutrients that dogs need to
        thrive.`}
      />
      <Benefit
        title="Easy to Feed"
        sources={feed}
        description={`We portion to ${props.petName}’s unique calorie needs, and the food is
        pre-made and ready-to-feed. Just take it out of the fridge, open, and
        pour. Proclaiming your love for ${props.petName} while serving is
        optional but highly recommended.`}
      />
      <Benefit
        title="Convenient Deliveries"
        sources={delivery}
        textPositionLeft
        description={`Meals are delivered within days of cooking in refrigerated boxes and
        eco-friendly packaging. Plus our deliveries are perfectly timed so you
        never run out of food. It’s a win-win-win.`}
      />
    </PageWidth>
  );
};
