import {
  ValidAcceptFrequencyUpdateSubscriptionMutationResponse,
  useSuggestOrderSizeUpMutation,
} from './useSuggestOrderSizeUpMutation';
import { useToast, ToastSentiment } from '@farmersdog/corgi-x';
import { reporter } from '../../errors/services';

interface UseSuggestOrderSizeSubmitProps {
  onCompleted: (
    response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
  ) => void;
}
/**
 * Wraps mutation that updates subscription frequency
 */

export function useSuggestOrderSizeUpSubmit(
  props: UseSuggestOrderSizeSubmitProps
) {
  const { onCompleted } = props;
  const dispatchToast = useToast();
  const { onSubmit } = useSuggestOrderSizeUpMutation({
    onCompleted,
    onError: () => {
      dispatchToast({
        sentiment: ToastSentiment.Negative,
        message: 'Something went wrong. Your subscription was not updated.',
      });
      reporter.error(
        new Error('Error updating subscription in SuggestOrderSizeUp')
      );
    },
  });
  return {
    callSubmit: onSubmit,
  };
}
