import { getExpirationTimestamp } from './get-expiration-timestamp';

import { SetOptional } from 'type-fest';
import { SessionData } from './is-valid-session-data';

export function updateSession(
  sessionData: SetOptional<SessionData, 'expiration'>
): SessionData {
  const updatedSession = {
    ...sessionData,
    expiration: getExpirationTimestamp(),
  };

  return updatedSession;
}
