import { Text } from '@farmersdog/corgi-x';
import styles from './PriceBreakdown.module.css';

interface PriceBreakdownItemProps {
  dataTestBase: string;
  fieldName: string;
  value: string;
  bold?: boolean;
  kale?: boolean;
}

export const PriceBreakdownItem = ({
  dataTestBase,
  fieldName,
  value,
  bold,
  kale,
}: PriceBreakdownItemProps) => {
  return (
    <span className={styles.orderBreakdownItem}>
      <Text
        variant="heading-16"
        color={kale ? 'kale-2' : 'charcoal-3'}
        data-testid={`${dataTestBase}_field`}
        bold={bold}
      >
        {fieldName}
      </Text>
      <Text
        variant="heading-16"
        color={kale ? 'kale-2' : 'charcoal-3'}
        data-testid={`${dataTestBase}_value`}
        bold={bold}
      >
        {value}
      </Text>
    </span>
  );
};
