import ContentPage from 'src/components/ContentPage';
import styles from './AdditionalTerms.module.css';

interface AdditionalTermsProps {
  title: string;
  children: React.ReactNode;
}

export function AdditionalTerms({ title, children }: AdditionalTermsProps) {
  return (
    <ContentPage title={title}>
      <h1>{title}</h1>
      <div className={styles.divider}></div>
      {children}
    </ContentPage>
  );
}
