import { useRef, useImperativeHandle } from 'react';

function useImperativeScroll(ref) {
  const innerRef = useRef();
  useImperativeHandle(
    ref,
    () => ({
      scrollIntoView: () => {
        if (!ref.current) {
          return;
        }

        innerRef.current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
        });
      },
    }),
    // We don't want to rebuild this handle on every re-render, block
    // the effect with the exhaustive dep
    [ref]
  );

  return innerRef;
}

export default useImperativeScroll;
