import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel, Card } from '@farmersdog/corgi';
import { Mask, PageWidth, Text } from '@farmersdog/corgi-x';
import Picture from 'src/components/Picture';
import { useCorgiViewport } from 'src/screen';

import styles from './BenefitCarousel.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function BenefitCarousel({ quotes }) {
  const centerFirstAndLast = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const maxWidth = useCorgiViewport({
    xs: false,
    lg: true,
  });

  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <PageWidth enabled={maxWidth}>
      <Carousel
        className={classNames(styles.carousel, useCorgiViewport(styles))}
        centerFirstAndLast={centerFirstAndLast}
      >
        {quotes.map((quoteData, index) => (
          <Card
            key={index}
            className={classNames(styles.card, accessibilityThemeStyles.card)}
          >
            <Mask shape="circle" size="md" className={styles.mask}>
              <Picture sources={quoteData.sources} alt={quoteData.name} />
            </Mask>
            <Text
              as="blockquote"
              variant="article-20"
              color="charcoal-3"
              className={styles.quote}
            >
              “{quoteData.quote}”
            </Text>
            <Text as="figcaption" variant="heading-16" color="charcoal-3">
              — {quoteData.name}
            </Text>
          </Card>
        ))}
      </Carousel>
    </PageWidth>
  );
}

BenefitCarousel.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quote: PropTypes.node.isRequired,
      sources: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

export default BenefitCarousel;
