import { FreshRecipeRecommendation } from '../../../graphql/types';

interface LpfRecipeStatus {
  lpfRecipeEligible: boolean;
  lpfRecipeRecommended: boolean;
  onlyLpfRecipeRecommended: boolean;
}

export function checkLpfRecipeStatus(
  recipes: FreshRecipeRecommendation[]
): LpfRecipeStatus {
  let lpfRecipeEligible = false;
  let lpfRecipeRecommended = false;
  let otherRecipesThanLpfRecommended = false;

  recipes.forEach(recipe => {
    if (recipe.content.productLine === 'lpf') {
      lpfRecipeEligible = true;
      if (recipe.recommended) {
        lpfRecipeRecommended = true;
      }
    } else if (recipe.recommended) {
      otherRecipesThanLpfRecommended = true;
    }
  });

  return {
    lpfRecipeEligible,
    lpfRecipeRecommended,
    onlyLpfRecipeRecommended:
      lpfRecipeRecommended && !otherRecipesThanLpfRecommended,
  };
}
