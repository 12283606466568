import { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import {
  PATH_SIGNUP,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_TOSA_ME_OVERRIDE,
  PATH_SIGNUP_TOSA_PETS_OVERRIDE,
  PATH_SIGNUP_TOSA_LAST_PET_OVERRIDE,
} from '@farmersdog/constants/paths';
import { cookie } from '@farmersdog/lead-browser-storage';

import { NodeRegistry, BranchNode } from '../blueprint/types';
import { UseForm, UseFormNavigateReturn } from '../types';
import { getFirstMeFormStep, getFirstPetFormStep } from '../utils';
import { getFormStepFromSearchParams } from '../utils/getFormStepFromSearchParams';
import { getSignupRoute, SearchParams } from '../utils/getSignupRoute';

import { Progress } from './useProgress';

interface UseOverrideRouteArgs {
  registry: NodeRegistry | undefined;
  getValues: UseForm['getValues'];
  formSteps: BranchNode[];
  getBranchComplete: Progress['getBranchComplete'];
  formNavigation: UseFormNavigateReturn;
}
export function useOverrideRoute({
  registry,
  getValues,
  formSteps,
  getBranchComplete,
  formNavigation,
}: UseOverrideRouteArgs) {
  const location = useLocation();
  const [hasRun, setHasRun] = useState(false);
  const isSignup = useRouteMatch(PATH_SIGNUP);
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const cardName = searchParams.get(SearchParams.Card);
  const petName = searchParams.get(SearchParams.Pet);

  const isReturnToMeFromOutsideTosa = useRouteMatch(
    PATH_SIGNUP_TOSA_ME_OVERRIDE
  );
  const isReturnToPetsFromOutsideTosa = useRouteMatch(
    PATH_SIGNUP_TOSA_PETS_OVERRIDE
  );
  const isReturnToLastPetsFromOutsideTosa = useRouteMatch(
    PATH_SIGNUP_TOSA_LAST_PET_OVERRIDE
  );
  const isPhoneSaleAgent = cookie.isPhoneSalesAgent();

  useEffect(() => {
    if (hasRun) {
      return;
    }

    // phone sales agent should always go to first contentful 'me' card upon signup landing
    if (isPhoneSaleAgent) {
      const firstMeCardId = getFirstMeFormStep({ formSteps })?.__self__;

      if (firstMeCardId) {
        formNavigation.goToStep(firstMeCardId);
        setHasRun(true);
      }
      return;
    }

    if (registry && isSignup) {
      const externalLinkMatchedFormStep = getNonTosaLinkFormStep();
      const routeMatchedFormStep = cardName
        ? getFormStepFromSearchParams({
            formSteps,
            cardName,
            petName,
            getValues,
          })
        : undefined;

      const routeMatchedFormStepId =
        externalLinkMatchedFormStep?.__self__ || routeMatchedFormStep?.__self__;
      /*
        NOTE: very important that we check that branch is complete here. Otherwise routes will switch in a render loop
        due to the nextIncompleteBranch logic that sets the current form step.
      */
      if (routeMatchedFormStepId && getBranchComplete(routeMatchedFormStepId)) {
        const overrideSignupRoute = getSignupRoute({
          registry,
          current: routeMatchedFormStepId,
          getValues,
        });

        if (overrideSignupRoute) {
          formNavigation.goToStep(routeMatchedFormStepId, {
            routingAction: 'replace',
          });
          setHasRun(true);
        }
      }
      return;
    }

    // if user has left tosa and clicks a link to return to me or pets, we can send the user to the associated form step
    function getNonTosaLinkFormStep() {
      if (isReturnToLastPetsFromOutsideTosa) {
        return getLastPetFormStep(formSteps);
      }
      if (isReturnToMeFromOutsideTosa || isReturnToPetsFromOutsideTosa) {
        return isReturnToMeFromOutsideTosa
          ? getFirstMeFormStep({ formSteps })
          : getFirstPetFormStep({ formSteps });
      }
      return;
    }
  }, [
    registry,
    formSteps,
    cardName,
    petName,
    hasRun,
    isSignup,
    getValues,
    getBranchComplete,
    formNavigation,
    isReturnToMeFromOutsideTosa,
    isReturnToPetsFromOutsideTosa,
    isReturnToLastPetsFromOutsideTosa,
    isPhoneSaleAgent,
  ]);
}

function getLastPetFormStep(formSteps: BranchNode[]) {
  return [...formSteps].reverse().find(formStep => {
    return formStep.route === PATH_SIGNUP_PETS;
  });
}
