import { SlotEligibility } from '../../../../../../slots';
import { useOrderSizeUpCtaData } from './useOrderSizeUpCtaData';

export function useEligibility(): SlotEligibility {
  // Use data gatherer so logic will not separate between hooks
  const { isReady, eligibleByCriteria, isDismissed } = useOrderSizeUpCtaData();

  if (!isReady) {
    return {
      isReady,
      isCandidate: false,
    };
  }

  return {
    isReady,
    isCandidate: !isDismissed && eligibleByCriteria,
  };
}
