import { useState, useEffect } from 'react';

interface UseClearCustomizedSelectionArgs {
  defaultToCustomization: boolean | null;
  clearCustomizedSelection: () => void;
}

/**
 * Determines if lead's previous selection should be cleared from form state.
 * If the recommendation screen is the default landing screen, and user has not touched any selection,
 * clears the previous selection from form state
 */

export function useClearCustomizedSelection({
  defaultToCustomization,
  clearCustomizedSelection,
}: UseClearCustomizedSelectionArgs) {
  const [hasToggledSelection, setHasToggledSelection] =
    useState<boolean>(false);

  const shouldKeepCustomizedSelection =
    defaultToCustomization || (!defaultToCustomization && hasToggledSelection);

  useEffect(() => {
    if (defaultToCustomization === null || shouldKeepCustomizedSelection) {
      return;
    }
    clearCustomizedSelection();
  }, [
    defaultToCustomization,
    shouldKeepCustomizedSelection,
    clearCustomizedSelection,
  ]);

  return { setHasToggledSelection };
}
