import { Node as NodeType } from '../../blueprint/types';
import { isLeafNode, isBranchNode } from '../../blueprint/utils';
import { TOSAComponentInput } from '../../types';
import { BranchNodeAnimation, LeafNodeAnimation } from '../animation';

import { BranchNode } from './BranchNode';

export function Node(props: TOSAComponentInput<NodeType>) {
  const { node } = props;
  const NodeComponent = node.component;

  if (isBranchNode(node)) {
    return NodeComponent ? (
      <BranchNodeAnimation {...props}>
        <NodeComponent {...props} />
      </BranchNodeAnimation>
    ) : (
      <BranchNodeAnimation {...props}>
        <BranchNode {...props} node={node} />
      </BranchNodeAnimation>
    );
  }
  if (isLeafNode(node)) {
    return NodeComponent ? (
      <LeafNodeAnimation {...props}>
        <NodeComponent {...props} />
      </LeafNodeAnimation>
    ) : null;
  }

  throw new Error('Invalid node');
}
