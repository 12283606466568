import { useState, ChangeEvent, useCallback } from 'react';
import classNames from 'classnames';
import {
  Grid,
  GridItem,
  Page,
  Picture,
  Text,
  Button,
  Input,
  Logo,
  Form,
  Nowrap,
} from '@farmersdog/corgi-x';
import events from 'src/analytics/eventNames';
import track from 'src/analytics/track';
import { useCorgiV2Viewport } from 'src/screen';
import isValidEmail from '../../utils/isValidEmail';
import { HighTrafficFooter } from './components/HighTrafficFooter';
import sleepingDog from './assets/sleeping-dog.png';
import styles from './SignupSiteUnavailablePage.module.css';

export function SignupSiteUnavailablePage() {
  const [emailAddress, setEmailAddress] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChangeEmailAddress = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmailAddress(e.target.value);
    },
    []
  );

  const handleSubmitForm = useCallback(() => {
    track(events.signup_site_shutoff_email_form_submitted, {
      emailAddress,
    });

    setIsFormSubmitted(true);
  }, [emailAddress]);

  const responsiveClassName = useCorgiV2Viewport({
    xs: styles.xs,
    md: styles.md,
  });
  const isMediumScreen = responsiveClassName === styles.md;

  const dogPicture = (
    <div
      className={classNames(
        responsiveClassName,
        styles.dogPictureAbsoluteContainer
      )}
    >
      <Picture
        className={classNames(responsiveClassName, styles.dogPicture)}
        sources={[sleepingDog]}
        alt="Dog sleeping next to flowers"
      />
    </div>
  );

  return (
    <>
      <Page layout="full-width">
        <Grid
          flexDirection="column"
          justifyContent="space-between"
          gap={isMediumScreen ? undefined : 'md'}
          className={styles.container}
        >
          <Grid justifyContent="center">
            <GridItem
              xs={12}
              className={classNames(responsiveClassName, styles.logoContainer)}
            >
              <Logo variant="product" size={74} />
            </GridItem>
          </Grid>

          <Grid
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            gap={isMediumScreen ? 'lg' : 'md'}
            className={styles.contentContainer}
          >
            <Grid
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={isMediumScreen ? 'md' : 'sm'}
            >
              <GridItem xs={10} md={8} xl={8}>
                <Text
                  variant={isMediumScreen ? 'heading-52' : 'heading-40'}
                  as="h1"
                  color="kale-3"
                  bold
                >
                  Our servers are a little full <Nowrap>right now</Nowrap>
                </Text>
              </GridItem>

              <GridItem
                xs={10}
                md={6}
                xl={6}
                className={styles.subheadingContainer}
              >
                <Text
                  variant="article-20"
                  as="h2"
                  color="charcoal-3"
                  className={classNames({
                    // On form submission, this element is hidden rather than
                    // removed from the DOM so that other elements on the page
                    // do not move around
                    [styles.hidden]: isFormSubmitted,
                  })}
                >
                  Enter your information below and we’ll email you when we{' '}
                  <Nowrap>are ready!</Nowrap>
                </Text>

                {isFormSubmitted && (
                  <Text
                    color="kale-2"
                    variant="heading-16"
                    as="span"
                    className={styles.thanksText}
                  >
                    Thank you!
                  </Text>
                )}
              </GridItem>
            </Grid>

            <GridItem className={styles.formContainer}>
              <Form
                onSubmit={handleSubmitForm}
                className={classNames({
                  // On form submission, this element is hidden rather than
                  // removed from the DOM so that other elements on the page do
                  // not move around
                  [styles.hidden]: isFormSubmitted,
                })}
              >
                <Grid
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={isMediumScreen ? 'md' : 'sm'}
                >
                  <GridItem>
                    <Input
                      label="Email"
                      name="email"
                      aria-label="email address"
                      value={emailAddress}
                      type="email"
                      onChange={handleChangeEmailAddress}
                      autoComplete="email"
                    />
                  </GridItem>

                  <GridItem>
                    <Button
                      variant="primary"
                      disabled={!isValidEmail(emailAddress)}
                      type="submit"
                      className={styles.button}
                    >
                      Submit
                    </Button>
                  </GridItem>
                </Grid>
              </Form>

              {isMediumScreen && dogPicture}
            </GridItem>
          </Grid>

          {/* This Grid needs to render regardless of whether or not it
          contains content so that .contentContainer will always be in the
          center of the screen, no matter its height */}
          <Grid
            className={classNames(responsiveClassName, styles.bottomContainer)}
            flex-direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <GridItem>{!isMediumScreen && dogPicture}</GridItem>
          </Grid>
        </Grid>
      </Page>

      <HighTrafficFooter />
    </>
  );
}
