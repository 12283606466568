import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  TextV2,
  Input,
  SelectorGroup,
  LabeledSelector,
  Button,
} from '@farmersdog/corgi';

import { selectPetNames } from 'src/reducers/signup/user';
import petNameOrYourDogs from 'src/utils/petNameOrYourDogs';

import { useViewport } from 'src/screen';

import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import ActionButtons from './ActionButtons';
import useAnalytics from './useAnalytics';

import {
  MEAT_OPTIONS,
  FISH_OPTIONS,
  OTHER_MEAT,
  OTHER_FISH,
} from './proteinBlendOptions';
import { SLIDE_NAME as VEGETABLES } from './Vegetables';

import { TITLE } from './copy';
import { getProteinBlendQuestionsToShow } from './utils';

import styles from './ProteinBlends.module.scss';

export const SLIDE_NAME = 'PROTEIN_BLENDS';

function ProteinBlends({
  goToPrevSlide,
  setCurrentSlide,
  onAnswerEvent,
  newProteins,
  avoidFishMeatBlendProteins,
  avoidOtherFishMeatBlendMeatProtein,
  avoidOtherFishMeatBlendFishProtein,
  avoidFishProteins,
  avoidOtherFishProtein,
  avoidMeatBlendProteins,
  avoidOtherMeatBlendProtein,
}) {
  const { trackAnswerSelected } = useAnalytics();

  const petNames = useSelector(selectPetNames);

  const questionsToShow = getProteinBlendQuestionsToShow(newProteins);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} />
      <div className={classNames(styles.container, useViewport(styles))}>
        {questionsToShow.fishMeatBlend && (
          <div className={styles.question}>
            <TextV2
              as="div"
              variant="heading-21"
              className={styles.questionText}
            >
              Which fish and meats would you AVOID in a blended protein recipe
              for {petNameOrYourDogs(petNames)}?
            </TextV2>
            <div className={styles.row}>
              <div className={styles.col}>
                <SelectorGroup
                  type="checkbox"
                  name="avoidFishMeatBlendProteins"
                  legend="Which meat options for a fish-meat blend?"
                  onChange={onAnswerEvent}
                  value={avoidFishMeatBlendProteins}
                  inputsClassName={styles.selectorGroup}
                >
                  {MEAT_OPTIONS.map(meat => (
                    <LabeledSelector
                      key={meat.value}
                      value={meat.value}
                      className={styles.selector}
                    >
                      {meat.label}
                    </LabeledSelector>
                  ))}
                </SelectorGroup>
                {avoidFishMeatBlendProteins.includes(OTHER_MEAT.value) && (
                  <div className={styles.otherProteinField}>
                    <Input
                      label="Other meat"
                      name="avoidOtherFishMeatBlendMeatProtein"
                      type="text"
                      onChange={e => onAnswerEvent(e, { track: false })}
                      value={avoidOtherFishMeatBlendMeatProtein}
                    />
                  </div>
                )}
              </div>
              <div className={styles.col}>
                <SelectorGroup
                  type="checkbox"
                  name="avoidFishMeatBlendProteins"
                  legend="Which fish options for a fish-meat blend?"
                  onChange={onAnswerEvent}
                  value={avoidFishMeatBlendProteins}
                  inputsClassName={styles.selectorGroup}
                >
                  {FISH_OPTIONS.map(fish => (
                    <LabeledSelector
                      key={fish.value}
                      value={fish.value}
                      className={styles.selector}
                    >
                      {fish.label}
                    </LabeledSelector>
                  ))}
                </SelectorGroup>
                {avoidFishMeatBlendProteins.includes(OTHER_FISH.value) && (
                  <div className={styles.otherProteinField}>
                    <Input
                      label="Other fish"
                      name="avoidOtherFishMeatBlendFishProtein"
                      type="text"
                      onChange={e => onAnswerEvent(e, { track: false })}
                      value={avoidOtherFishMeatBlendFishProtein}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {questionsToShow.fish && (
          <div className={styles.question}>
            <TextV2
              as="div"
              variant="heading-21"
              className={styles.questionText}
            >
              Which types of fish would you AVOID in a recipe for{' '}
              {petNameOrYourDogs(petNames)}?
            </TextV2>
            <SelectorGroup
              type="checkbox"
              name="avoidFishProteins"
              legend="Which fish options for a fish-meat blend?"
              onChange={onAnswerEvent}
              value={avoidFishProteins}
              inputsClassName={styles.selectorGroup}
            >
              {FISH_OPTIONS.map(fish => (
                <LabeledSelector
                  key={fish.value}
                  value={fish.value}
                  className={styles.selector}
                >
                  {fish.label}
                </LabeledSelector>
              ))}
            </SelectorGroup>
            {avoidFishProteins.includes(OTHER_FISH.value) && (
              <div className={styles.otherProteinField}>
                <Input
                  label="Other fish"
                  name="avoidOtherFishProtein"
                  type="text"
                  onChange={e => onAnswerEvent(e, { track: false })}
                  value={avoidOtherFishProtein}
                />
              </div>
            )}
          </div>
        )}
        {questionsToShow.meatMeatBlend && (
          <div className={styles.question}>
            <TextV2
              as="div"
              variant="heading-21"
              className={styles.questionText}
            >
              Which types of meat would you AVOID in a recipe for{' '}
              {petNameOrYourDogs(petNames)}?
            </TextV2>
            <SelectorGroup
              type="checkbox"
              name="avoidMeatBlendProteins"
              legend="Which fish options for a fish-meat blend?"
              onChange={onAnswerEvent}
              value={avoidMeatBlendProteins}
              inputsClassName={styles.selectorGroup}
            >
              {MEAT_OPTIONS.map(meat => (
                <LabeledSelector
                  key={meat.value}
                  value={meat.value}
                  className={styles.selector}
                >
                  {meat.label}
                </LabeledSelector>
              ))}
            </SelectorGroup>
            {avoidMeatBlendProteins.includes(OTHER_MEAT.value) && (
              <div className={styles.otherProteinField}>
                <Input
                  label="Other meat"
                  name="avoidOtherMeatBlendProtein"
                  type="text"
                  onChange={e => onAnswerEvent(e, { track: false })}
                  value={avoidOtherMeatBlendProtein}
                />
              </div>
            )}
          </div>
        )}
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              trackAnswerSelected(
                'avoidOtherFishMeatBlendMeatProtein',
                avoidOtherFishMeatBlendMeatProtein
              );
              trackAnswerSelected(
                'avoidOtherFishMeatBlendFishProtein',
                avoidOtherFishMeatBlendFishProtein
              );
              trackAnswerSelected(
                'avoidOtherFishProtein',
                avoidOtherFishProtein
              );
              trackAnswerSelected(
                'avoidOtherMeatBlendProtein',
                avoidOtherMeatBlendProtein
              );
              setCurrentSlide(VEGETABLES);
            }}
          >
            Continue
          </Button>
        </ActionButtons>
      </div>
    </>
  );
}

ProteinBlends.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  onAnswerEvent: PropTypes.func.isRequired,
  newProteins: PropTypes.arrayOf(PropTypes.string).isRequired,
  avoidFishMeatBlendProteins: PropTypes.arrayOf(PropTypes.string).isRequired,
  avoidOtherFishMeatBlendMeatProtein: PropTypes.string,
  avoidOtherFishMeatBlendFishProtein: PropTypes.string,
  avoidFishProteins: PropTypes.arrayOf(PropTypes.string).isRequired,
  avoidOtherFishProtein: PropTypes.string,
  avoidMeatBlendProteins: PropTypes.arrayOf(PropTypes.string).isRequired,
  avoidOtherMeatBlendProtein: PropTypes.string,
};

export default ProteinBlends;
