import { getCacheBuster } from '../../../utils';
import {
  claritas,
  AudacyEventId,
  AudacyEventName,
  AUDACY_PIXEL_ID,
} from '../claritas';

/**
 * Track an audacy page view
 */
export function mountAudacyPageView(): void {
  const ord = getCacheBuster();
  claritas.mount({
    ord,
    ppt: AUDACY_PIXEL_ID,
    g: AudacyEventName.PAGE_VIEW,
    gid: AudacyEventId.PAGE_VIEW,
  });
}
