import { JourneyStatus } from '../../../../graphql/types.cgs';
import {
  checkIconSrc,
  onePackIconSrc,
  threePackIconSrc,
  twoPackIconSrc,
} from '../assets';

/**
 * Creates a selector function that determines the appropriate icon for a given box
 * based on the journey status and the number of regular orders delivered.
 * This higher-order function captures the initial state of the journey and uses it
 * to return a function that selects the correct icon source for each box.
 *
 * @param {JourneyStatus} journeyStatus - The current status of the journey.
 * @param {number} regularOrdersDeliveredCount - The count of regular orders that have been delivered.
 * @returns {Function} A function that takes a box number and returns the corresponding icon source.
 **/
export function createBoxIconSelector({
  journeyStatus,
  regularOrdersDeliveredCount,
}: {
  journeyStatus: JourneyStatus;
  regularOrdersDeliveredCount: number;
}) {
  return function (boxNumber: number) {
    const boxIconMap: Record<number, string[]> = {
      1: onePackIconSrc,
      2: twoPackIconSrc,
      3: threePackIconSrc,
    };

    if (journeyStatus === JourneyStatus.AwaitingFirstBox) {
      return boxIconMap[boxNumber];
    }

    if (journeyStatus === JourneyStatus.ReceivedFirstBox && boxNumber === 1) {
      return checkIconSrc;
    }

    if (regularOrdersDeliveredCount >= boxNumber - 1) {
      return checkIconSrc;
    }

    return boxIconMap[boxNumber] ?? threePackIconSrc;
  };
}
