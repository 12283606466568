import { createReducer } from 'redux-create-reducer';
import moment from 'moment';

import {
  SUBSCRIPTION_FETCH_START_DATES,
  SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES,
  SUBSCRIPTION_FETCH_USER_QUOTE,
  SUBSCRIPTION_SET_CURRENT_NEXT_DATE,
} from 'src/constants/actionTypes';

const initialState = {
  startDates: [],
  quote: undefined,
};

export default createReducer(initialState, {
  [SUBSCRIPTION_FETCH_START_DATES](state, action) {
    // Join received start dates and add a soldOut flag
    const startDates = [];
    action.payload.data.soldOut.forEach(day => {
      startDates.push({ day, soldOut: true });
    });
    action.payload.data.valid.forEach(day => {
      startDates.push({ day, soldOut: false });
    });
    startDates.sort((a, b) => {
      if (moment(a) > moment(b)) {
        return -1;
      }
      if (moment(a) < moment(b)) {
        return 1;
      }
      return 0;
    });
    return Object.assign({}, state, { startDates });
  },
  [SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES](state, action) {
    const stockAdjustedNextDate = action.payload.data.me.subscription
      ? action.payload.data.me.subscription.stockAdjustedNextDate
      : null;

    const { availableNextDates } = action.payload.data.me;
    return { ...state, availableNextDates, stockAdjustedNextDate };
  },
  [SUBSCRIPTION_FETCH_USER_QUOTE](state, action) {
    return Object.assign({}, state, {
      quote: action.payload.data,
    });
  },
  [SUBSCRIPTION_SET_CURRENT_NEXT_DATE](state, action) {
    return Object.assign({}, state, {
      currentNextDate: action.payload,
    });
  },
});

export const selectSubscription = state => state.subscription;
export const selectStartDates = state =>
  state.subscription ? state.subscription.startDates : [];
export const selectAvailableNextDates = state =>
  state.subscription?.availableNextDates ?? [];

/**
 * Gets the current logged in user's subscription quote
 *
 * @param {Object} state - the redux state
 *
 * @returns {Reducer.Quote}
 */
export const selectQuote = state => state.subscription.quote;

/**
 * Gets the subscription next date before it was rescheduled
 *
 * @param {Object} state - the redux state
 * @returns {String | undefined}
 */
export const selectCurrentNextDate = state =>
  state.subscription.currentNextDate;
