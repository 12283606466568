import moment from 'moment';
import { WeekdayNumber } from '../constants';

/**
 * Return the monday of the week the given day belongs to.
 *
 * @example
 *
 *  console.log(formatWeekOf('2019-09-17')) // September, 16th
 */
export function formatWeekOf(day: string) {
  return moment(day, 'YYYY-MM-DD').day(WeekdayNumber.Monday).format('MMMM Do');
}
