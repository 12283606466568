import { Blueprint, CompiledBlueprint, NodeRegistry } from '../types';

import { compileBlueprint } from './compileBlueprint';
import { compileNodeRegistry } from './compileNodeRegistry';

export interface LoadBlueprintReturn {
  blueprint: CompiledBlueprint;
  registry: NodeRegistry;
}

export function loadBlueprint(
  blueprint: Blueprint | CompiledBlueprint
): LoadBlueprintReturn {
  const compiledBlueprint = compileBlueprint(blueprint);
  const registry = compileNodeRegistry(compiledBlueprint);
  return { blueprint: compiledBlueprint, registry };
}
