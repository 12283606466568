import { Logger } from '@farmersdog/logger';

import {
  useSSRAnonymousExperiments,
  getHasSSRAnonymousExperiment,
} from 'src/server/context/experimentsContext';
import {
  HomepageVetOptimizationTreatments,
  HEALTH_VET_OPTIMIZATION_HOMEPAGE,
} from 'src/server/middlewares/experimentsMiddleware/flags';

export const useVetOptimizationLinks = () => {
  const experiments = useSSRAnonymousExperiments();

  const hasHomepageVetOptimizationExperiment = getHasSSRAnonymousExperiment(
    experiments,
    HEALTH_VET_OPTIMIZATION_HOMEPAGE
  );

  if (!hasHomepageVetOptimizationExperiment) {
    const log = new Logger('website:useVetOptimizationLinks');
    log.error(
      'No SSR Anonymous Experiments found for HEALTH_VET_OPTIMIZATION_HOMEPAGE experiment',
      {
        experiments,
      }
    );

    return {
      showPillOnly: false,
      showPillAndCard: false,
      showDefault: true,
    };
  }

  const vetOptimizationLinksExperiment =
    experiments[HEALTH_VET_OPTIMIZATION_HOMEPAGE];

  if (!vetOptimizationLinksExperiment?.treatment) {
    const log = new Logger('website:useVetOptimizationLinks');
    log.error(
      'No treatment found for HEALTH_VET_OPTIMIZATION_HOMEPAGE experiment',
      {
        experiment: vetOptimizationLinksExperiment,
      }
    );

    return {
      showPillOnly: false,
      showPillAndCard: false,
      showDefault: true,
    };
  }

  const showPillOnly =
    vetOptimizationLinksExperiment.treatment ===
    HomepageVetOptimizationTreatments.PillOnly;
  const showPillAndCard =
    vetOptimizationLinksExperiment.treatment ===
    HomepageVetOptimizationTreatments.PillAndCard;

  const showDefault = !showPillOnly && !showPillAndCard;

  return {
    showPillOnly,
    showPillAndCard,
    showDefault,
  };
};
