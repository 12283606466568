import analytics from './analytics';

/* 
  returns the cookied value of the user id from segment
  this is set by the analytics.identify method
*/
function getUserId() {
  return analytics().user().id();
}

export default getUserId;
