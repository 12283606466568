import React from 'react';

import { Button, Link, Text, TextVariants } from '@farmersdog/corgi-x';
import { useCorgiV2Viewport, useCorgiViewport } from 'src/screen';

import styles from './Heading.module.css';
import classNames from 'classnames';

interface ViewportProps {
  titleVariant: TextVariants;
  subTitleVariant: TextVariants;
}

interface HeadingProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
  isSmallSubtitle?: boolean;
  isTextOnBottom?: boolean;
  greenFont?: boolean;
  ctaString: string;
  buttonClassname?: string;
  subtitleClassname?: string;
  titleClassname?: string;
  ctaClassname?: string;
  copyContainerClassname?: string;
}

export const Heading = ({
  title,
  subtitle,
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  isTextOnBottom,
  greenFont,
  ctaString,
  buttonClassname,
  subtitleClassname,
  titleClassname,
  ctaClassname,
  copyContainerClassname,
}: HeadingProps) => {
  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const ctaClassNames = classNames(buttonClassname, ctaClassname, {
    [styles.accessibilityThemeCta]: isAccessibilityThemeOn,
    [styles.ctaButton]: !isAccessibilityThemeOn,
    [styles.bottomTextButton]: isTextOnBottom,
  });

  const subtitleClassNames = classNames(styles.subtitle, subtitleClassname);
  const titleClassNames = classNames(styles.title, titleClassname);
  const wrapperClassNames = classNames(
    styles.baseCopyContainerClassname,
    copyContainerClassname
  );

  const { titleVariant, subTitleVariant } = useCorgiV2Viewport<ViewportProps>({
    xs: {
      titleVariant: 'heading-40',
      subTitleVariant: 'heading-16',
    },
    lg: {
      titleVariant: 'heading-52',
      subTitleVariant: 'heading-22',
    },
  });

  const titleColor = isHeadlineLink
    ? undefined
    : greenFont
      ? 'kale-3'
      : 'white';

  return (
    <>
      <div className={wrapperClassNames}>
        <Text
          bold
          as="h1"
          topSpacing="none"
          bottomSpacing={isMobile ? 'sm' : 'md'}
          variant={titleVariant}
          className={titleClassNames}
          data-testid="static-hero-title"
          color={titleColor}
        >
          {isHeadlineLink ? (
            <Link baseColor="white" to={linkToFreshSignup}>
              {title}
            </Link>
          ) : (
            title
          )}
        </Text>
        <Text
          bold
          as="h2"
          color={greenFont ? 'kale-3' : 'white'}
          topSpacing="none"
          variant={subTitleVariant}
          className={subtitleClassNames}
          bottomSpacing={{
            lg: 'xl',
          }}
        >
          {subtitle}
        </Text>
      </div>
      <Button className={ctaClassNames} type="link" to={linkToFreshSignup}>
        {ctaString}
      </Button>
    </>
  );
};
