import { useMemo } from 'react';
import { useDeepMemo } from '@farmersdog/utils';

import { useSplitContext } from './useSplitContext';

interface StaticAttributes {
  current_date: number;
}

export type UndefinedSplitAttributes = {
  [K in keyof SplitIO.Attributes]: SplitIO.Attributes[K] | undefined;
};

interface UseAttributes<
  T extends UndefinedSplitAttributes,
  G extends SplitIO.Attributes,
> {
  /** Merged object of both global attributes and provided feature attributes */
  attributes: T & G & StaticAttributes;
  /** True if all feature attributes have a value */
  attributesReady: boolean;
}

/**
 * Merges custom feature attributes with global feature attributes.  Will
 * validate if the feature attributes are ready.
 *
 * @param featureAttributes - Custom attributes to send to split
 */
export const useAttributes = <
  T extends UndefinedSplitAttributes,
  G extends SplitIO.Attributes,
>(
  featureAttributes?: T
): UseAttributes<T, G> => {
  const { globalAttributes } = useSplitContext<T, G>();
  const safeFeatureAttributes = useDeepMemo(featureAttributes);

  return useMemo((): UseAttributes<T, G> => {
    const attributesReady = safeFeatureAttributes
      ? Object.values(safeFeatureAttributes).every(value => value !== undefined)
      : true;

    return {
      attributesReady,
      // @ts-expect-error TODO: Update this type to not use ignore
      attributes: {
        ...globalAttributes,
        ...safeFeatureAttributes,
        current_date: Date.now(),
      },
    };
  }, [safeFeatureAttributes, globalAttributes]);
};
