import type { ErrorResponse } from '@apollo/client/link/error';
import type { ServerError } from '@apollo/client';

import * as errorCodes from 'src/errors/errorCodes';

export interface BackendError {
  /* The parameter that is invalid */
  params: string;
  /* The reason the parameter is invalid */
  reason: 'required' | 'invalid' | 'unique';
  /* A human readable message */
  message: string;
  /*  A human readable description */
  description: string;
  /* An error code that signifies the purpose of the error */
  code: (typeof errorCodes)[keyof typeof errorCodes];
}

export type ApolloError = ErrorResponse['networkError'];

/**
 * Return the errors that are present on a request error in the deprecated
 * format.
 *
 * @param error - An error object that is returned by apollo client.
 *
 * This is helpful as our API currently doesn't adhere to GraphQL error
 * specifications. In the future this utility won't be needed.
 */
export function getDeprecatedErrors(
  error: ApolloError
): undefined | BackendError[] {
  if (!isServerError(error) || typeof error.result === 'string') {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return error.result?.data?.errors;
}

/**
 * Return whether an apollo error is an instance of a server error
 *
 * @param error - An error object that is returned by apollo client.
 */
function isServerError(error: ApolloError | null): error is ServerError {
  return Boolean(
    error && Object.prototype.hasOwnProperty.call(error, 'result')
  );
}
