import { BodyConditionName, eatingStyles } from '@farmersdog/constants';

/**
 * A magic string passed to facebook pixel via segment that indicates a user has
 * one or more pets that fall into the `CAN_BE_PICKY` or `VERY_PICKY` eating
 * styles.
 */
export const PICKY = 'picky';

/**
 * A magic string passed to facebook pixel via segment that indicates a user has
 * one or more pets younger then 14 months old.
 */
export const PUPPY = 'puppy';

/**
 * A magic string passed to facebook pixel via segment that indicates a user has
 * one or more pets younger that fall into the `Rounded` or `Chunky` body types.
 */
export const OVERWEIGHT = 'overweight';

/**
 * A magic string passed to facebook pixel via segment that indicates a user has
 * no pets that fall into one or more of the categories above.
 */
export const OTHER = 'other';

/**
 * A set of body conditions that are grouped under the attribute `overweight`
 */

export const overweightBodyConditions = new Set([
  BodyConditionName.Rounded.toLowerCase(),
  BodyConditionName.Chunky.toLowerCase(),
]);

/**
 * A set of eating styles that are grouped under the attribute `picky`
 */
export const pickyEatingStyles = new Set([
  eatingStyles.VERY_PICKY.toLowerCase(),
  eatingStyles.CAN_BE_PICKY.toLowerCase(),
]);
