import desktopMeatJpg from './assets/carousel-meat-desktop.jpg';
import mobileMeatJpg from './assets/carousel-meat-mobile.jpg';
import desktopMeatWebP from './assets/carousel-meat-desktop.webp';
import mobileMeatWebP from './assets/carousel-meat-mobile.webp';

import desktopMealJpg from './assets/carousel-meal-desktop.jpg';
import mobileMealJpg from './assets/carousel-meal-mobile.jpg';
import desktopMealWebP from './assets/carousel-meal-desktop.webp';
import mobileMealWebP from './assets/carousel-meal-mobile.webp';

import desktop3PercentJpg from './assets/carousel-3-percent-desktop.jpg';
import mobile3PercentJpg from './assets/carousel-3-percent-mobile.jpg';
import desktop3PercentWebP from './assets/carousel-3-percent-desktop.webp';
import mobile3PercentWebP from './assets/carousel-3-percent-mobile.webp';

import desktopNaturalJpg from './assets/carousel-natural-desktop.jpg';
import mobileNaturalJpg from './assets/carousel-natural-mobile.jpg';
import desktopNaturalWebP from './assets/carousel-natural-desktop.webp';
import mobileNaturalWebP from './assets/carousel-natural-mobile.webp';

import desktopCdcJpg from './assets/carousel-cdc-desktop.jpg';
import mobileCdcJpg from './assets/carousel-cdc-mobile.jpg';
import desktopCdcWebP from './assets/carousel-cdc-desktop.webp';
import mobileCdcWebP from './assets/carousel-cdc-mobile.webp';

export type GallerySectionSlide = {
  source: string;
  responsiveSources: {
    xs: [string, string];
    lg: [string, string];
  };
  caption: string;
  citation: string;
  /** The alternate text for the image. */
  alt: string;
};

const slides: GallerySectionSlide[] = [
  {
    responsiveSources: {
      xs: [mobileMeatWebP, mobileMeatJpg],
      lg: [desktopMeatWebP, desktopMeatJpg],
    },
    caption: 'Diseased meats are allowed in dog food if rendered at high heat.',
    citation: 'https://bit.ly/3gBb7My',
    source: 'AAFCO',
    alt: 'A quote from AAFCO saying: Yes, certain carcasses or parts may be rejected from use for humans and processed into animal feed. Meat and meat byproducts not directly suitable for animal food that are designated as 4-D (dead, dying, diseased or disabled) are considered adulterated—unless processed in a manner that rids them of disease-causing microorganisms prior to becoming animal feed. This is most often done by rendering, which subjects the materials to heat and pressure to eliminate harmful bacteria.',
  },
  {
    responsiveSources: {
      xs: [mobileMealWebP, mobileMealJpg],
      lg: [desktopMealWebP, desktopMealJpg],
    },
    caption:
      'Common rendered ingredients are “meal” powders (e.g., “chicken meal”).',
    citation: ' https://bit.ly/2YdUWxL',
    source: 'AAFCO',
    alt: `A quote from AAFCO defining Meat Meal, such as Chicken Meal, Beef Meal, etc, as "rendered product from mammal tissues, exclusive of any added blood, hair, hoof, horn, hide trimmings, manure, stomach and rumen contents except in such amounts as may occur unavoidably in good processing practices. Unlike meat and 'meat by-products', this ingredient may be from mammals other than cattle, pigs, sheep or goats without further description. However, a manufacturer may designate a species if appropriate (e.g., "beef meal" if only from cattle)."`,
  },
  {
    responsiveSources: {
      xs: [mobile3PercentWebP, mobile3PercentJpg],
      lg: [desktop3PercentWebP, desktop3PercentJpg],
    },
    caption:
      'A product needs only 3% of an ingredient to be labeled as made "with" it.',
    citation:
      'https://www.aafco.org/consumers/understanding-pet-food/reading-labels',
    alt: `A quote from AAFCO saying: "According to AAFCO model regulations, including the words "with" or "similar" allows an ingredient to be included in the product name or anywhere else on the label at an inclusion rate of at least 3% of each named ingredient. "Honest Jack's Dog Food With Chicken" should contain at least 3% chicken, while "Cynthia's Super Cat Food with Tuna and Rice" should contain at least 3% tuna and 3% rice.`,
    source: 'AAFCO',
  },
  {
    responsiveSources: {
      xs: [mobileNaturalWebP, mobileNaturalJpg],
      lg: [desktopNaturalWebP, desktopNaturalJpg],
    },
    caption:
      'Terms like “natural” and “premium” are not expressions of safety.',
    citation: 'https://bit.ly/32COYbZ',
    source: 'AAFCO',
    alt: `A quote from AAFCO saying: "Does AAFCO have any warnings or recommendations regarding products labeled natural, all-natural or 100% natural? Other than concerns for potential misbranding and misunderstanding amongst consumers, no. Natural and like terms are not expressions of safety, but simply fit a loose definition. Are 'premium' pet food products better? 'Premium' is a totally unregulated term in feed law. Whereas premium gasoline must meet higher octane standards than regular gasoline, premium pet food doesn't have special requirements that differ from any other pet food."`,
  },
  {
    responsiveSources: {
      xs: [mobileCdcWebP, mobileCdcJpg],
      lg: [desktopCdcWebP, desktopCdcJpg],
    },
    caption: 'Pet food isn’t as safe as the bag leads us to believe.',
    citation: 'https://bit.ly/2zzyYuJ',
    source: 'CDC',
    alt: `A quote from the CDC saying: "Always wash your hands with soap and water right after handling pet food or treats; this is the most important step to prevent illness. When possible, store pet food and treats away from where human food is stored or prepared and away from reach of young children. Don't use your pet's feeding bowl to scoop food. Use a clean, dedicated scoop, spoon, or cup. Always follow any storage instructions on pet food bags or containers."`,
  },
];

export default slides;
