import { experimentCodesWhitelist } from '../useDefaultDiscountExperiment/constants';

interface ReferrerSource {
  referrerAssociatedAt: string | null;
  referrerType?: string | null;
  referrerCode?: string | null;
  referrerId?: string | null;
}

/**
 * Compares two objects by their referrerAssociatedAt property and
 * returns the most recent.
 *
 */
function getMostRecentReferrerSource(
  referrerSource1: ReferrerSource,
  referrerSource2: ReferrerSource
): ReferrerSource {
  // Compare association dates.
  // Use 0 if association date is falsy to prevent invalid dates which
  // always results in a false comparison.
  const referrerSource1Date = new Date(
    referrerSource1.referrerAssociatedAt || 0
  );
  const referrerSource2Date = new Date(
    referrerSource2.referrerAssociatedAt || 0
  );
  return referrerSource1Date > referrerSource2Date
    ? referrerSource1
    : referrerSource2;
}

function getPreferredReferrerSource(
  referrerSource1: ReferrerSource,
  referrerSource2: ReferrerSource
): ReferrerSource {
  // Check if sources have default discount
  const source1HasDefaultDiscount =
    referrerSource1.referrerCode &&
    experimentCodesWhitelist.has(referrerSource1.referrerCode);
  const source2HasDefaultDiscount =
    referrerSource2.referrerCode &&
    experimentCodesWhitelist.has(referrerSource2.referrerCode);

  // Prefer non-default discounts
  if (source1HasDefaultDiscount && !source2HasDefaultDiscount) {
    return referrerSource2;
  } else if (!source1HasDefaultDiscount && source2HasDefaultDiscount) {
    return referrerSource1;
  } else {
    // If both have non-default discounts or both have default discounts, return the most recent one
    return getMostRecentReferrerSource(referrerSource1, referrerSource2);
  }
}

export function getReferrerProperties(
  user: ReferrerSource | undefined,
  referrerCookie: ReferrerSource | undefined,
  defaultDiscount: ReferrerSource,
  isDefaultDiscountExperimentOn: boolean
) {
  let referrerType, referrerId, referrerCode;

  if (!user && !referrerCookie) {
    if (isDefaultDiscountExperimentOn) {
      referrerType = defaultDiscount.referrerType;
      referrerCode = defaultDiscount.referrerCode;
      return { referrerType, referrerId, referrerCode };
    } else {
      return { referrerType, referrerId, referrerCode };
    }
  } else {
    let referrerSource = user || referrerCookie;
    if (user && referrerCookie) {
      referrerSource = getPreferredReferrerSource(user, referrerCookie);
    }
    referrerType = referrerSource?.referrerType;
    referrerCode = referrerSource?.referrerCode;
    referrerId = referrerSource?.referrerId;

    if (isDefaultDiscountExperimentOn) {
      // If there is no referrer code or id, use the default discount
      if (!referrerCode && !referrerId) {
        referrerType = defaultDiscount.referrerType;
        referrerCode = defaultDiscount.referrerCode;
      }
    }
  }

  return { referrerType, referrerId, referrerCode };
}
