import { ChangeEvent } from 'react';
import { useFormik, FormikErrors, FormikTouched } from 'formik';
import { EditAccountFormValues } from '../../EditAccount';
import editAccountFormValidationSchema from '../../EditAccountFormValidation';
import { isSameAddress } from '../../../../utils/isSameAddress';
import { SubmitOptions } from '../../../../network';
import noop from 'lodash/noop';

/** Shape returned by the form hook */
export type UseEditAccountForm = {
  values: EditAccountFormValues;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSubmit: () => void;
  errors: FormikErrors<EditAccountFormValues>;
  touched: FormikTouched<EditAccountFormValues>;
  handleUseShippingAsBillingAddressChange: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  handleCardholderNameChange: (newName: string) => void;
  handleShippingAddressOnBlur: () => void;
  hasBillingAddressChanged: boolean;
  isDirty: boolean;
  validateForm: () => Promise<FormikErrors<EditAccountFormValues>>;
};

/** Arguments accepted by the form hook */
type UseEditAccountFormProps = {
  initialValues: EditAccountFormValues;
  onSubmit: (options: SubmitOptions) => void;
};

/** Manages state for Edit Account form */
export function useEditAccountForm(
  props: UseEditAccountFormProps
): UseEditAccountForm {
  const formik = useFormik<EditAccountFormValues>({
    initialValues: props.initialValues,
    validationSchema: editAccountFormValidationSchema,
    // We are using a submit handler in EditAccount, but useFormik requires this
    onSubmit: noop,
  });

  function handleUseShippingAsBillingAddressChange(
    e: ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.checked === true) {
      void formik.setFieldValue('billingAddress', {
        addressLine1: formik.values.shippingAddress.addressLine1,
        addressLine2: formik.values.shippingAddress.addressLine2,
        city: formik.values.shippingAddress.city,
        stateAbbreviation: formik.values.shippingAddress.stateAbbreviation,
        zip: formik.values.shippingAddress.zip,
      });
    }
    formik.handleChange(e);
  }

  function handleCardholderNameChange(newName: string) {
    void formik.setFieldValue('cardholderName', newName);
  }

  function handleShippingAddressOnBlur() {
    if (
      formik.values.useShippingAsBillingAddress &&
      !isSameAddress(
        formik.values.billingAddress,
        formik.values.shippingAddress
      )
    ) {
      void formik.setFieldValue('useShippingAsBillingAddress', false);
    }
  }

  const hasBillingAddressChanged = !isSameAddress(
    props.initialValues.billingAddress,
    formik.values.billingAddress
  );

  return {
    values: formik.values,
    handleChange: formik.handleChange,
    handleSubmit: formik.handleSubmit,
    errors: formik.errors,
    touched: formik.touched,
    handleUseShippingAsBillingAddressChange,
    handleCardholderNameChange,
    handleShippingAddressOnBlur,
    hasBillingAddressChanged,
    isDirty: formik.dirty,
    validateForm: formik.validateForm,
  };
}
