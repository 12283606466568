import moment from 'moment';
import {
  ACCURACY_DATE,
  ACCURACY_MONTHS,
  ACCURACY_WEEKS,
  ACCURACY_YEARS,
} from 'src/constants/birthdayAccuracy';
import { MAX_AGE_WEEKS, MIN_AGE_WEEKS } from 'src/constants/pet';
import { ValidationError } from 'src/errors';

export const ValidationMessages = {
  minSignup: `Come back when your puppy is ${MIN_AGE_WEEKS} weeks old!`,
  minEdit: `Are you sure? That seems very young!`,
  max: `Are you sure? That seems quite old!`,
};

/**
 * Return a pet birthday string (YYYY-MM-DD) given age and accuracy. E.g.
 *
 * @example
 * convertAgeToBirthday(5, 'years', '2019-05-21') // `2014-05-21`.
 *
 * @param {number} age
 * @param {string} accuracy The birthday accuracy ('years', 'months', or
 * 'weeks')
 * @param {string?} createdAt (ISO Date) The date to calculate the birthday
 * from, e.g. when the pet has been created. Defaults to today.
 *
 * @return {string} The birthday date in YYYY-MM-DD format.
 */
export function convertAgeToBirthday(age, accuracy, createdAt) {
  const birthday = moment(createdAt)
    .subtract(age, accuracy === ACCURACY_DATE ? ACCURACY_YEARS : accuracy)
    .format('YYYY-MM-DD');
  return birthday;
}

/**
 * Guess the birthday accuracy from a birthday date.
 *
 * @param {string} birthday
 * @return {string} Either `years`, `weeks` or `months`.
 */
export function guessBirthdayAccuracy(birthday) {
  const TWELVE_WEEKS = 12;
  const TWO_YEARS_IN_WEEKS = 52 * 2;

  const ageInWeeks = Math.round(moment().diff(moment(birthday), 'weeks', true));
  let birthdayAccuracy = ACCURACY_YEARS;
  if (ageInWeeks) {
    if (ageInWeeks < TWELVE_WEEKS) {
      birthdayAccuracy = ACCURACY_WEEKS;
    } else if (ageInWeeks < TWO_YEARS_IN_WEEKS) {
      birthdayAccuracy = ACCURACY_MONTHS;
    }
  }
  return birthdayAccuracy;
}

/**
 @typedef AgeObject
 @type {Object}
 @property {number} age The numeric age for the detected accuracy
 @property {string} birthdayAccuracy The accuracy (`years`, `months`, `weeks`)
 */

/**
 * Return the age with accuracy from a birthday as `{ age, birthdayAccuracy }`.
 * where `age` is to the value the user type in the pet form.
 *
 * @param {string} birthday
 * @param {import('src/graphql/types').PetBirthdayAccuracy | null} [birthdayAccuracy]
 * @return {AgeObject}
 */
export function convertBirthdayToAge(birthday, birthdayAccuracy) {
  const accuracy =
    birthdayAccuracy && birthdayAccuracy !== ACCURACY_DATE
      ? birthdayAccuracy
      : guessBirthdayAccuracy(birthday);
  const age = Math.floor(moment().diff(moment(birthday), accuracy, true));
  return { age, birthdayAccuracy: accuracy };
}

/**
 * Returns a `ValidationError` object if the birthday is not valid. `undefined`
 * otherwise.
 *
 * @example
 * validateBirthday('1800-10-12') // { birthday: 'Are you sure? That seems quite old!'}
 *
 * @param {string} birthday
 * @return {?ValidationError} The validation error if birthday is not valid, otherwise
 * `undefined`.
 */
export function validateBirthday(birthday) {
  if (validateBirthdayMin(birthday)) {
    return new ValidationError(ValidationMessages.minSignup, 'birthday');
  }
  if (validateBirthdayMax(birthday)) {
    return new ValidationError(ValidationMessages.max, 'birthday');
  }
}

/**
 * Determines if a given birthday results in a pet that is too young for The Farmer's Dog
 *
 * @param {string} birthday Date as string
 * @returns {boolean} True if invalid, false if valid
 */
export function validateBirthdayMin(birthday) {
  const diffInWeeks = moment().diff(moment(birthday), 'week', true);
  return diffInWeeks < MIN_AGE_WEEKS;
}

/**
 * Determines if a given birthday results in a pet that is too old for The Farmer's Dog
 *
 * @param {string} birthday Date as string
 * @returns {boolean} True if invalid, false if valid
 */
export function validateBirthdayMax(birthday) {
  const diffInWeeks = moment().diff(moment(birthday), 'week', true);
  return diffInWeeks > MAX_AGE_WEEKS;
}

/**
 * Return a human-readable birthday in age, e.g.:
 *
 *  5 year old
 *
 * @param {string|undefined|null} birthday
 * @param options Optional parameters
 * @param userInfo.plural Leaves the s at the end of the accuracy, 5 years old
 * @return {string}
 */
export function formatBirthday(birthday, { plural = false } = {}) {
  if (!birthday) {
    return '';
  }
  const { age, birthdayAccuracy } = convertBirthdayToAge(birthday);
  const formattedAccuracy =
    !plural || age === 1
      ? birthdayAccuracy.replace(/s$/, '')
      : birthdayAccuracy;

  return `${age} ${formattedAccuracy} old`;
}
