import {
  useExperimentPickyFeature,
  ExperimentPickyTreatment,
} from './ExperimentPicky';
import {
  ExperimentPlanProgressionTreatment,
  useExperimentPlanProgressionFeature,
} from './ExperimentPlanProgression';

export interface HomepagePersonalizations {
  // Founder Video: Hidden by Picky and Plan Progression experiment
  hideFounderVideo: boolean;
  // Picky Nudge: Shown by Picky experiment
  showPickyNudge: boolean;
  // Picky Tips: Shown by Picky experiment
  showPickyTips: boolean;
  // Plan Progression: Shown by Picky and Plan Progression experiment
  showPlanProgression: boolean;
  // Welcome Message Copy: Updated by Picky experiment
  updateWelcomeMessageCopy: boolean;
}

type Criteria = Record<keyof HomepagePersonalizations, boolean>;

/**
 * Retrieves the treatment of homepage personalization experiment
 * treatments, and checks appearance criteria to determine which
 * updates need to be made to the homepage, returned as flat boolean
 * flags.
 */
export function useHomepagePersonalization() {
  // === Visible Change Criteria Readability ===
  // Hook for usage determines treatment eligibility (ineligible = "Off" variant)
  // Attributes returned used for temporal logic to determine visibility of components

  // <> Experiment: New Picky Day 1 Experience
  const {
    // Treatments: Off, Variant 1 [PickyNudge], Variant 2 [PickyNudgeWithProgression]
    treatment: experimentPickyTreatment,
    // Populated Experiment Attributes
    attributes: experimentPickyAttributes,
  } = useExperimentPickyFeature();

  // Criteria Checks
  const experimentPickyLogic: Partial<Criteria> = {
    // \\ Founder Video
    hideFounderVideo:
      // Variants: Off, 1, 2
      // Box 3 is delivered
      experimentPickyAttributes.regularOrdersDeliveredCount > 1,

    // \\ Picky Nudge
    showPickyNudge: [
      // Variants: 1, 2
      [
        ExperimentPickyTreatment.PickyNudge,
        ExperimentPickyTreatment.PickyNudgeWithProgression,
      ].some(treatment => treatment === experimentPickyTreatment),
      // Box 4 is not delivered
      experimentPickyAttributes.regularOrdersDeliveredCount < 3,
    ].every(Boolean),

    // \\ Picky Tips
    showPickyTips: [
      // Variants: 1, 2
      [
        ExperimentPickyTreatment.PickyNudge,
        ExperimentPickyTreatment.PickyNudgeWithProgression,
      ].some(treatment => treatment === experimentPickyTreatment),
      // Box 4 is not delivered
      experimentPickyAttributes.regularOrdersDeliveredCount < 3,
    ].every(Boolean),

    // \\ Order Progression
    showPlanProgression: [
      // Variants: 2
      experimentPickyTreatment ===
        ExperimentPickyTreatment.PickyNudgeWithProgression,
      // Box 4 is not delivered
      experimentPickyAttributes.regularOrdersDeliveredCount < 3,
    ].every(Boolean),

    // \\ Welcome Message
    updateWelcomeMessageCopy: [
      // Variants: 1, 2
      [
        ExperimentPickyTreatment.PickyNudge,
        ExperimentPickyTreatment.PickyNudgeWithProgression,
      ].some(treatment => treatment === experimentPickyTreatment),
      // Box 2 is not delivered
      experimentPickyAttributes.regularOrdersDeliveredCount < 1,
    ].every(Boolean),
  };

  // <> Experiment: Box Progression in Account (Non-Picky)
  const {
    // Treatments: Off, Variant 1 [On]
    treatment: experimentPlanProgressionTreatment,
    // Populated Experiment Attributes
    attributes: experimentPlanProgressionAttributes,
  } = useExperimentPlanProgressionFeature();

  // Criteria Checks
  const experimentPlanProgressionLogic: Partial<Criteria> = {
    // \\ Founder Video
    hideFounderVideo:
      // Variants: Off, 1
      // Box 3 is delivered
      experimentPlanProgressionAttributes.regularOrdersDeliveredCount > 1,

    // \\ Order Progression
    showPlanProgression: [
      // Variants: 1
      experimentPlanProgressionTreatment ===
        ExperimentPlanProgressionTreatment.On,
      // Box 4 is not delivered
      experimentPlanProgressionAttributes.regularOrdersDeliveredCount < 3,
    ].every(Boolean),
  };

  const criteria = [experimentPickyLogic, experimentPlanProgressionLogic];

  // Output Flags
  const hideFounderVideo = criteria.some(l => l.hideFounderVideo);
  const showPickyNudge = criteria.some(l => l.showPickyNudge);
  const showPickyTips = criteria.some(l => l.showPickyTips);
  const showPlanProgression = criteria.some(l => l.showPlanProgression);
  const updateWelcomeMessageCopy = criteria.some(
    l => l.updateWelcomeMessageCopy
  );

  return {
    hideFounderVideo,
    showPickyNudge,
    showPickyTips,
    showPlanProgression,
    updateWelcomeMessageCopy,
  };
}
