import { useStripeCreateToken } from '@farmersdog/corgi-x';
import { ValidationError, errorCopy } from '../../../errors';
import { ApplicationError } from '../../../errors/ApplicationError';

export interface TokenData {
  name: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
}

export function useCreateStripeToken() {
  const createToken = useStripeCreateToken();
  /**
   * Submits a request to Stripe for a new credit card token, combining current
   * values from Stripe elements in the UI with cardholder name and address data
   * provided as an argument.
   */
  const createStripeToken = async (tokenData: TokenData) => {
    const response = await createToken(tokenData);

    if (!response) {
      throw new ApplicationError('No response from Stripe', {
        title: errorCopy.CREDIT_CARD_TITLE,
        displayMessage: errorCopy.UNKNOWN_STRIPE,
      });
    }

    const { error, token } = response;

    if (error?.type === 'validation_error' && error?.message) {
      throw new ValidationError(error.message, {
        title: errorCopy.CREDIT_CARD_TITLE,
      });
    }

    if (error) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw error;
    }

    if (!token) {
      throw new ApplicationError('No token returned from Stripe', {
        title: errorCopy.CREDIT_CARD_TITLE,
        displayMessage: errorCopy.UNKNOWN_STRIPE,
      });
    }

    if (token?.card?.funding === 'prepaid') {
      throw new ValidationError(errorCopy.PREPAID_CARD, {
        title: errorCopy.CREDIT_CARD_TITLE,
        param: 'card',
      });
    }

    return token.id;
  };

  return createStripeToken;
}
