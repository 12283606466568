import { paths, PetNodes } from '@farmersdog/constants';

import { BranchNode, NodeRegistry } from '../../../blueprint/types';
import { usePreviousLinkNavigation } from '../../../hooks/usePreviousLinkNavigation';
import {
  UseFormNavigateReturn,
  UseForm,
  RestoreCachedLeadArgs,
  UseFeatureHook,
} from '../../../types';
import { getFullSignupRoute } from '../../../utils';

import { DiscountBanner } from './DiscountBanner';
import { useSteps } from './hooks/useSteps';
import { LargeNavigation } from './LargeNavigation';
import styles from './SignupHeader.module.css';
import { SmallNavigation } from './SmallNavigation';

interface SignupHeaderProps extends RestoreCachedLeadArgs {
  formNavigation: UseFormNavigateReturn;
  formSteps: BranchNode[];
  registry: NodeRegistry;
  getValues: UseForm['getValues'];
  useFeature: UseFeatureHook;
}

export function SignupHeader(props: SignupHeaderProps) {
  const navigationProps = usePreviousLinkNavigation({ ...props });

  const signupMeUrl = getFullSignupRoute({
    formSteps: props.formSteps,
    route: paths.PATH_SIGNUP_ME,
  });

  const firstPetKey = `${PetNodes.Name}-1`;
  const firstPetName = props.getValues(firstPetKey);

  const signupPetsUrl = getFullSignupRoute({
    formSteps: props.formSteps,
    route: paths.PATH_SIGNUP_PETS,
    petName: firstPetName,
  });

  const stepData = useSteps({
    signupMeUrl,
    signupPetsUrl,
    formSteps: props.formSteps,
    current: props.formNavigation.current,
  });

  return (
    <div>
      <div className={styles.container}>
        <DiscountBanner experiments={props.experiments} />
        <div className={styles.navigation}>
          <div className={styles.large}>
            <LargeNavigation {...navigationProps} stepData={stepData} />
          </div>
          <div className={styles.small}>
            <SmallNavigation {...navigationProps} stepData={stepData} />
          </div>
        </div>
      </div>
    </div>
  );
}
