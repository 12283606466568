import isNil from 'lodash/isNil';
import { useState, useEffect } from 'react';

import { couponCookie, useCoupon, DiscountType } from '@farmersdog/coupons';
import { usePreviousEffect } from '@farmersdog/utils';

import config from '../../../config';
import { Experiments } from '../../../types';
import { cvr_default_discount } from '../../../utils';

import { experimentCodesWhitelist } from './constants';
import { setCouponCookieWithDefaultExperimentCode } from './utils/setCouponCookieWithDefaultExperimentCode';

interface UseDefaultDiscountExperiment {
  experiments: Experiments;
}

export function useDefaultDiscountExperiment({
  experiments,
}: UseDefaultDiscountExperiment) {
  const { url, referrerRoute } = config.get('tosa.app.coreApi');
  const { isValid, loading, couponCode } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  });

  const [discountChecked, setDiscountChecked] = useState<boolean>(false);

  /* 
    while we don't want to set the discount for the same code repeatedly, 
    if the cookied code changes, we must retest each changing code for validity
    so we don't restore lead state on an invalid/expired coupon, blocking resetting
    the default discount cookie 
  */
  usePreviousEffect((prevCoupon, nextCoupon) => {
    if (prevCoupon !== nextCoupon && discountChecked) {
      setDiscountChecked(false);
    }
  }, couponCode);

  const defaultDiscountExperiment = experiments[cvr_default_discount];
  const isDefaultDiscountExperimentOn = !(
    defaultDiscountExperiment?.treatment === 'off' ||
    defaultDiscountExperiment?.treatment === 'control'
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isDefaultDiscountExperimentOn && !discountChecked) {
      const currentCouponCookieCode = couponCookie.get().referrerCode;

      /*
        - If user is in the experiment and ANY NON-DEFAULT coupon cookie value exists, we will not overwrite it.
        - If user is in the experiment and a DEFAULT coupon cookie value exists, 
        we will overwrite it with the latest code returned from split.
        - Lastly, we check `isValid` to prevent the case where a user tries to overwrite a valid default discount
        experiment code while in the experiment with an invalid regular referrer code
      */
      const currentCouponCookieIsDefaultDiscount =
        currentCouponCookieCode &&
        experimentCodesWhitelist.has(currentCouponCookieCode);

      if (
        isNil(currentCouponCookieCode) ||
        currentCouponCookieIsDefaultDiscount ||
        !isValid
      ) {
        setCouponCookieWithDefaultExperimentCode({
          splitConfig: defaultDiscountExperiment?.config,
        });
      }
      setDiscountChecked(true);
    }
  }, [
    defaultDiscountExperiment,
    discountChecked,
    isDefaultDiscountExperimentOn,
    isValid,
    loading,
  ]);
}
