import { Fragment, ReactNode } from 'react';

import { isErrorTemplateToken, getComponentByToken } from './utils';

interface InlineErrorTemplateProps {
  children: ReactNode;
}

export function InlineErrorTemplate({ children }: InlineErrorTemplateProps) {
  if (typeof children !== 'string') {
    return <>{children}</>;
  }

  return (
    <>
      {children.split(' ').map((child, index) => {
        const cleanChild = child.trim();
        const key = `${cleanChild}-${index}`;

        if (!isErrorTemplateToken(cleanChild)) {
          return <Fragment key={key}>{child} </Fragment>;
        }

        const Component = getComponentByToken(cleanChild);

        return (
          <Fragment key={key}>
            <Component />{' '}
          </Fragment>
        );
      })}
    </>
  );
}
