import { HealthIssue } from '../components/HealthIssueScene/constants';
import { PetDietIssue } from '../components/PetDietIssueScene/constants';
import { FetchAddDogFormDataQuery } from '../network/fetchAddDogFormData.cgs.generated-types';
import { useEffect, useState } from 'react';

export enum AgeUnitOptions {
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}

export enum PetSexOptions {
  Male = 'male',
  Female = 'female',
}

export type RecipeOptions = NonNullable<
  FetchAddDogFormDataQuery['customer']['pets'][number]
>['availableFreshFoodProducts'];

export type BreedOptions = NonNullable<
  FetchAddDogFormDataQuery['petCharacteristics']['breeds'][number]
>['name'];

export interface UseAddDogFormFields {
  name: string;
  ageNumber: number;
  ageUnit: AgeUnitOptions;
  sex: PetSexOptions;
  breed: BreedOptions[];
  sterilization: boolean;
  bodyCondition: string;
  currentWeight: number;
  targetWeight?: number;
  activityLevel: string;
  eatingStyle: string;
  healthIssues?: HealthIssue[];
  petDietIssues?: PetDietIssue[];
  recipes: string[];
}

const DEFAULT_FORM_VALUES: UseAddDogFormFields = {
  name: '',
  ageNumber: 0,
  ageUnit: AgeUnitOptions.Years,
  sex: PetSexOptions.Male,
  breed: [],
  sterilization: false,
  currentWeight: 0,
  bodyCondition: '',
  activityLevel: '',
  eatingStyle: '',
  recipes: [],
};

export type AddDogFormChangeHandler = <T extends keyof UseAddDogFormFields>(
  key: T,
  value: UseAddDogFormFields[T]
) => void;

interface UseAddDogForm {
  formValue: UseAddDogFormFields;
  setPetField: AddDogFormChangeHandler;
}

interface UseAddDogFormProps {
  preSelectedRecipes: string[] | undefined;
}

export function useAddDogForm(props: UseAddDogFormProps): UseAddDogForm {
  const { preSelectedRecipes } = props;

  const [formValue, setFormValue] =
    useState<UseAddDogFormFields>(DEFAULT_FORM_VALUES);

  const [preSelectedRecipesAppliedToForm, setPreSelectedRecipesAppliedToForm] =
    useState(false);

  const setPetField = <T extends keyof UseAddDogFormFields>(
    key: T,
    value: UseAddDogFormFields[T]
  ): void => {
    setFormValue(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (!preSelectedRecipes || preSelectedRecipesAppliedToForm) {
      return;
    }

    if (preSelectedRecipes && !preSelectedRecipesAppliedToForm) {
      setPetField('recipes', preSelectedRecipes);
      setPreSelectedRecipesAppliedToForm(true);
    }
  }, [formValue, preSelectedRecipes, preSelectedRecipesAppliedToForm]);

  return {
    formValue,
    setPetField,
  };
}
