import { track } from '@farmersdog/analytics';
import { SubscriptionType } from '../../graphql/types.core-api';
import { eventNames } from './eventNames';

export interface CancelSubscriptionFlowExitPayload {
  exitReason: 'flow-closed' | 'cancellation-aborted';
  subscriptionType: SubscriptionType;
  cancellationReasonsHistory?: string[];
}

export function trackCancelSubscriptionFlowExit({
  exitReason,
  subscriptionType,
  cancellationReasonsHistory,
}: CancelSubscriptionFlowExitPayload) {
  track(eventNames.cancel_subscription_flow_exit, {
    cancellationVersion: 2,
    exitReason,
    subscriptionType,
    cancellationReasonsHistory,
  });
}
