import { useMemo } from 'react';
import { selectBodyConditions } from './selectors';
import { usePetConfiguration } from './usePetConfiguration';

/**
 * Fetch the prescription diet data that is required for modifying details about
 * a pet.
 */
export function useBodyConditions() {
  const query = usePetConfiguration();
  const bodyConditions = useMemo(
    () => selectBodyConditions(query.data),
    [query.data]
  );

  return {
    ...query,
    bodyConditions,
  };
}
