import { Link, Text } from '@farmersdog/corgi-x';

import { footerLinks } from '../constants/FooterLinks';

import styles from './LegalDetails.module.css';

export function LegalDetails() {
  return (
    <div className={styles.legalDetailsContainer}>
      <div className={styles.copyright}>
        <Text
          variant="heading-22"
          color="charcoal-3"
          className={styles.copyrightText}
        >
          © {new Date().getFullYear()} The Farmer’s Dog, Inc.
        </Text>
        <Text
          variant="heading-22"
          color="charcoal-3"
          className={styles.longLiveDogs}
        >
          Long Live Dogs™
        </Text>
      </div>
      <ul aria-label="Legal details" className={styles.legalDetails}>
        {footerLinks.map(link => (
          <Text
            key={link.label}
            className={styles.linkWrapper}
            variant="heading-22"
            color="charcoal-3"
            as="li"
          >
            <Link to={link.to} className={styles.link}>
              {link.label}
            </Link>
          </Text>
        ))}
      </ul>
    </div>
  );
}
