import { events, track } from 'src/analytics';

export enum TrackCalibratePlansAction {
  Accept = 'accept',
  Reject = 'reject',
}

export function trackCalibratePlans(action: TrackCalibratePlansAction) {
  track(events.calibration_plans, { action });
}
