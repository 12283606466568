import {
  barometric,
  BarometricEventId,
  BarometricEventName,
  PIXEL_ID,
} from './barometric';

/**
 * Track a barometric page view
 */
export function mountBarometricPageView(): void {
  barometric.mount({
    ppt: PIXEL_ID,
    g: BarometricEventName.HOMEPAGE_VIEW,
    gid: BarometricEventId.HOMEPAGE_VIEW,
  });
}
