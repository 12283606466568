import { TOSALeafNode } from '../types';
import {
  isValidationSchemaField,
  validationSchema,
} from '../validation/yupValidation';

interface ParseTextInputValueProps {
  value: string;
  node: TOSALeafNode;
}

export function parseTextInputValue({ value, node }: ParseTextInputValueProps) {
  const validation = node.validation;
  if (isValidationSchemaField(validationSchema, validation)) {
    const type = validationSchema[validation].type;
    if (type === 'number') {
      return value.replace(/[^0-9]/g, '');
    }
  }
  return value;
}
