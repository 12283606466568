import { Divider, Grid, GridItem, Section, Text } from '@farmersdog/corgi-x';
import { useId } from 'react';
import { JourneyStatus } from '../../../graphql/types.cgs';
import { formatNames } from '../../../utils';
import {
  HomepageOrderFieldsFragment,
  HomepagePetFieldsFragment,
} from '../../network';
import styles from './PlanProgression.module.css';
import { ProgressContainer } from './ProgressItem/ProgressContainer';
import { ProgressItem } from './ProgressItem/ProgressItem';
import { checkIconSrc } from './assets';
import { createBoxIconSelector } from './utils';

interface PlanProgressionProps {
  pets?: HomepagePetFieldsFragment[];
  journeyStatus?: JourneyStatus;
  regularOrdersDeliveredCount?: number;
  orders?: HomepageOrderFieldsFragment[];
  hasTransitionOrder?: boolean;
  frequency?: number;
}

/**
 * Displays a card that shows the box progression of the first few boxes
 * of the customer's fresh food plan.
 */
export function PlanProgression({
  pets = [],
  journeyStatus = JourneyStatus.AwaitingFirstBox,
  regularOrdersDeliveredCount = 0,
  orders = [],
  hasTransitionOrder,
  frequency,
}: PlanProgressionProps) {
  const petNames = formatNames(pets.map(pet => pet.name)) || 'your dog';

  /**
   * Frequency is used as a fallback in scenarios where the third box is not generated.
   * I.e. In Dev/Staging using created test users.  In Prod when there's a delay in billing the trial box.
   */
  const daysOfFood = {
    introBox: orders[0]?.avgDaysOfFood,
    secondBox: orders[1]?.avgDaysOfFood,
    fullBox: hasTransitionOrder
      ? (orders[2]?.avgDaysOfFood ?? frequency)
      : orders[1]?.avgDaysOfFood,
  };

  const getBoxIcon = createBoxIconSelector({
    journeyStatus,
    regularOrdersDeliveredCount,
  });

  const headingId = useId();

  return (
    <Section aria-labelledby={headingId} variant="card" bottomSpacing="lg">
      <Grid>
        <GridItem>
          <Text variant="heading-28" as="h1" bold id={headingId} color="kale-3">
            What to Expect
          </Text>
        </GridItem>
        <GridItem>
          <Divider variant="red-squiggly" spacing={18} align="start" />
        </GridItem>
        <GridItem className={styles.progress}>
          <ProgressContainer ariaLabel="Fresh food plan orders">
            <ProgressItem
              heading="Plan Created"
              content={`You just made a fresh food plan for ${petNames}.`}
              iconSrc={checkIconSrc}
            />
            <ProgressItem
              heading="Intro Box"
              content={`${daysOfFood.introBox} days of food for a taste of our fresh recipes. You can make edits before your second box.`}
              iconSrc={getBoxIcon(1)}
            />
            {hasTransitionOrder && (
              <>
                <ProgressItem
                  heading="Second Box"
                  content={`${daysOfFood.secondBox} days of food so you can find your dog’s favorite recipes and make other edits before full orders.`}
                  iconSrc={getBoxIcon(2)}
                />
                <ProgressItem
                  heading="Full Boxes"
                  content={`${daysOfFood.fullBox} days of your preferred recipes, on our best value order size—editable anytime.`}
                  iconSrc={getBoxIcon(3)}
                />
              </>
            )}
            {!hasTransitionOrder && (
              <>
                <ProgressItem
                  heading="Full Boxes"
                  content={`${daysOfFood.secondBox} days of your preferred recipes, on our best value order size—editable anytime.`}
                  iconSrc={getBoxIcon(2)}
                />
              </>
            )}
          </ProgressContainer>
        </GridItem>
      </Grid>
    </Section>
  );
}
