import styles from './LineAndDot.module.css';

interface LineAndDotProps {
  flip?: boolean;
}

export function LineAndDot({ flip }: LineAndDotProps) {
  const dot = <div className={styles.dot} />;

  return (
    <div className={styles.lineAndDot}>
      {flip && dot}
      <div className={styles.line} />
      {!flip && dot}
    </div>
  );
}
