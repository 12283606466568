import { CancellationResponsesEnum } from './cancellationResponses';

/** When any of these responses are made the "current" state (by the customer
selecting and clicking "Continue") we should display the "Free Response" UI */
const freeResponseStates = [
  CancellationResponsesEnum.PreferOrderingWhenNeeded,
  CancellationResponsesEnum.SubscriptionDoesntWorkForMe,
  CancellationResponsesEnum.PortionsCalsIncorrect,
  CancellationResponsesEnum.DidNotSeeAChange,
  CancellationResponsesEnum.ConcernedAboutIngredients,
  CancellationResponsesEnum.DogIsAllergic,
  CancellationResponsesEnum.VetHasRecipeConcerns,
  CancellationResponsesEnum.VetHasFreshFoodConcerns,
  CancellationResponsesEnum.VomitingDigestiveStool,
  CancellationResponsesEnum.AllergicReaction,
  CancellationResponsesEnum.OtherPhysicalReaction,
  CancellationResponsesEnum.ServingTooMessy,
  CancellationResponsesEnum.PortioningTooDifficult,
  CancellationResponsesEnum.ReceivingDeliveriesInconvient,
  CancellationResponsesEnum.ScheduleUnpredictable,
  CancellationResponsesEnum.BoxesNotProperlyHandled,
  CancellationResponsesEnum.BoxesDelayed,
  CancellationResponsesEnum.OtherDeliveryIssue,
  CancellationResponsesEnum.FoodArrivedWarm,
  CancellationResponsesEnum.RanOutOfFood,
  CancellationResponsesEnum.ItemsDamagedIncorrect,
  CancellationResponsesEnum.CustomerServiceIssue,
  CancellationResponsesEnum.FoodQuality,
  CancellationResponsesEnum.WantToFeedOneBoxAtATime,
  CancellationResponsesEnum.DontHaveStorageSpace,
  CancellationResponsesEnum.TransferringToFridgeDifficult,
  CancellationResponsesEnum.PreferShelfStable,
  CancellationResponsesEnum.DogNeverTouchedFood,
  CancellationResponsesEnum.DogUsedToLikeIt,
  CancellationResponsesEnum.DogAteSome,
] as const;

export function getFreeResponseStates() {
  return freeResponseStates;
}
