import { Banner, Link, Text } from '@farmersdog/corgi-x';
import { useFeature } from 'src/abTesting/useFeature';
import { ToggleTreatment } from 'src/abTesting/types';
import { CX_ALERT_BANNER } from 'src/abTesting/features';

interface CXAlertBannerConfig {
  bannerCopy: string;
  link?: string;
}

export function CXAlertBanner() {
  const { treatment: cxAlertBannerTreatment, config: cxAlertBannerConfig } =
    useFeature<ToggleTreatment, CXAlertBannerConfig>(CX_ALERT_BANNER);

  if (cxAlertBannerTreatment !== 'on') return null;
  return (
    <Banner>
      <Link to={cxAlertBannerConfig.link}>
        <Text variant="heading-16" color="white">
          {cxAlertBannerConfig.bannerCopy}
        </Text>
      </Link>
    </Banner>
  );
}
