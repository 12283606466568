import {
  Button,
  Grid,
  GridItem,
  Page,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import { useId } from 'react';

import styles from './AddDogEmail.module.css';
import addDogImg from './assets/two-dogs-sniffing.png';

import { trackRequestedAddADog } from '../../analytics';
import {
  ADD_DOG_CTA_TEXT,
  ADD_DOG_EMAIL_ADDRESS,
  ADD_DOG_EMAIL_BODY,
  ADD_DOG_EMAIL_SUBJECT,
  ADD_DOG_PAGE_HEADING,
  ADD_DOG_PAGE_SUBHEADING,
} from './constants';
import { PAW_PATH_MY_DOGS } from '@farmersdog/constants/paths';

export function AddDogEmail() {
  const history = useHistory();
  const headingId = useId();

  const handleOnClose = () => {
    history.push(PAW_PATH_MY_DOGS);
  };

  const handleCtaClick = () => {
    trackRequestedAddADog();
  };

  const ctaLink =
    'mailto:' +
    ADD_DOG_EMAIL_ADDRESS +
    '?subject=' +
    encodeURIComponent(ADD_DOG_EMAIL_SUBJECT) +
    '&body=' +
    encodeURIComponent(ADD_DOG_EMAIL_BODY);

  return (
    <Page layout="base" onCloseClick={handleOnClose}>
      <Section
        widthConstraint="md"
        bottomSpacing="md"
        aria-labelledby={headingId}
      >
        <Grid>
          <GridItem xs={12} className={styles.header}>
            <Text
              id={headingId}
              as="h1"
              variant="heading-40"
              color="kale-3"
              bold
              bottomSpacing="sm"
            >
              {ADD_DOG_PAGE_HEADING}
            </Text>
            <Text as="h2" variant="heading-22">
              {ADD_DOG_PAGE_SUBHEADING}
            </Text>
          </GridItem>
        </Grid>
      </Section>
      <Section widthConstraint="sm" hSpacing="md" as="div">
        <Grid rowGap="xxl" justifyContent="center">
          <GridItem xs={12} justifyContent="center">
            <Picture
              sources={[addDogImg]}
              alt="Two dogs sniffing each other while a third dog looks on."
              className={styles.picture}
            />
          </GridItem>
          <GridItem xs={12} growContent justifyContent="center">
            <Button
              type="anchor"
              href={ctaLink}
              target="_blank"
              variant="primary"
              onClick={handleCtaClick}
            >
              {ADD_DOG_CTA_TEXT}
            </Button>
          </GridItem>
        </Grid>
      </Section>
    </Page>
  );
}
