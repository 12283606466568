import {
  MaybeLaterModal,
  type MaybeLaterProps,
  type ModalProps,
} from '../../../MaybeLaterModal';
import { dogWithTreatsSources } from './assets/dogWithTreatsFrontViewSources';

/**
 * Renders MaybeLaterModal specific to Treats experiment
 */
export function TreatsDismissibleCtaMaybeLaterModal({
  isOpen,
  onClose,
}: ModalProps) {
  const TreatsNudgeProps: MaybeLaterProps = {
    bodyText: `If you’d ever like to add treats in the future you can do so on the ‘Treats’ page of your account.`,
    headlineText: 'Okay, Maybe Later',
    imageAltText: 'Dog with treats',
    imageSources: dogWithTreatsSources,
    closeCtaText: 'Got it',
    headlineColor: 'kale-3',
    bodyTextColor: 'kale-3',
  };
  return (
    <MaybeLaterModal
      isOpen={isOpen}
      onClose={onClose}
      headlineText={TreatsNudgeProps.headlineText}
      bodyText={TreatsNudgeProps.bodyText}
      closeCtaText={TreatsNudgeProps.closeCtaText}
      imageAltText={TreatsNudgeProps.imageAltText}
      imageSources={dogWithTreatsSources}
      headlineColor={TreatsNudgeProps.headlineColor}
      bodyTextColor={TreatsNudgeProps.bodyTextColor}
    />
  );
}
