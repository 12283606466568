import cbsWebP from './cbs-news-logo.webp';
import cbsPng from './cbs-news-logo.png';
import cbsKaleWebP from './cbs-news-logo-kale.webp';
import cbsKalePng from './cbs-news-logo-kale.png';

import vogueWebP from './vogue-logo.webp';
import voguePng from './vogue-logo.png';
import vogueKaleWebP from './vogue-logo-kale.webp';
import vogueKalePng from './vogue-logo-kale.png';

import fastCompanyWebP from './fast-company-logo.webp';
import fastCompanyPng from './fast-company-logo.png';
import fastCompanyKaleWebP from './fast-company-logo-kale.webp';
import fastCompanyKalePng from './fast-company-logo-kale.png';

import todayShowWebP from './today-show-logo.webp';
import todayShowPng from './today-show-logo.png';
import todayShowKaleWebP from './today-show-logo-kale.webp';
import todayShowKalePng from './today-show-logo-kale.png';

export const cbsSources = [cbsWebP, cbsPng];
export const cbsSourcesKale = [cbsKaleWebP, cbsKalePng];

export const vogueSources = [vogueWebP, voguePng];
export const vogueSourcesKale = [vogueKaleWebP, vogueKalePng];

export const fastCompanySources = [fastCompanyWebP, fastCompanyPng];
export const fastCompanySourcesKale = [fastCompanyKaleWebP, fastCompanyKalePng];

export const todayShowSources = [todayShowWebP, todayShowPng];
export const todayShowSourcesKale = [todayShowKaleWebP, todayShowKalePng];
