import { createMachine } from 'xstate';
import { EditFreshRecipeEvent } from './EditFreshRecipeEvent';
import { EditFreshRecipeState } from './EditFreshRecipeState';

export const editFreshRecipeMachine = createMachine({
  id: 'edit-recipes',
  initial: EditFreshRecipeState.SelectRecipes,
  states: {
    [EditFreshRecipeState.SelectRecipes]: {
      on: {
        [EditFreshRecipeEvent.Next]: {
          target: EditFreshRecipeState.ConfirmRecipes,
        },
        [EditFreshRecipeEvent.Close]: {
          target: EditFreshRecipeState.CloseRecipes,
        },
      },
    },
    [EditFreshRecipeState.ConfirmRecipes]: {
      on: {
        [EditFreshRecipeEvent.Previous]: {
          target: EditFreshRecipeState.SelectRecipes,
        },
        [EditFreshRecipeEvent.Close]: {
          target: EditFreshRecipeState.CloseRecipes,
        },
      },
    },
    /**
     * We want to properly close the machine when exiting the workflow
     * The current designs do not having a closing ui
     */
    [EditFreshRecipeState.CloseRecipes]: {
      type: 'final',
    },
  },
});
