import { events, track } from 'src/analytics';

interface trackTreatsSkipModalViewProps {
  path: string;
}

export function trackTreatsSkipModalView({
  path,
}: trackTreatsSkipModalViewProps) {
  return track(events.treats_skip_modal_view, {
    path,
  });
}
