import cloneDeep from 'lodash/cloneDeep';

import { NodeNames } from '@farmersdog/constants';

import { Position } from '../blueprint/types';
import { FRESH_RECOMMENDATION_STATE_NAME } from '../components/RecipesPage/constants';
import { FormFieldsType } from '../types';

export function validateRecipesSubmission(formData: FormFieldsType) {
  const data = cloneDeep(formData);

  const numPets = data[NodeNames.NumPets] as number;

  for (let i = 1; i <= numPets; i++) {
    const petPosition = String(i) as Position;

    const recommendation =
      data[`${FRESH_RECOMMENDATION_STATE_NAME}-${petPosition}`];
    const selection = data[`${NodeNames.FreshSelection}-${petPosition}`];

    const hasRecommendation = recommendation && recommendation.length > 0;
    const hasSelection = selection && selection?.length > 0;

    if (hasRecommendation && hasSelection) {
      const petName = data[`${NodeNames.Name}-${petPosition}`] as string;

      throw new Error(
        `Unable to determine recipes selection for ${petName} - recommendation: ${JSON.stringify(
          recommendation,
          null,
          2
        )}, selection: ${JSON.stringify(selection, null, 2)}`
      );
    }

    if (hasRecommendation) {
      data[`${NodeNames.FreshSelection}-${petPosition}`] = recommendation;
    }
  }

  return data;
}
