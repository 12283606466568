import * as Apollo from '@apollo/client';

import { useLazyQuery } from '../../services/apollo';
import { FetchLeadQuery, FetchLeadQueryVariables } from '../types';

import leadQuery from './lead.graphql';

type Options = Apollo.QueryHookOptions<FetchLeadQuery, FetchLeadQueryVariables>;

export function useLead(options?: Options) {
  return useLazyQuery<FetchLeadQuery, FetchLeadQueryVariables>(
    leadQuery,
    options
  );
}
