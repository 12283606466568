import { useState } from 'react';
import { Page, Text, Form, Button } from '@farmersdog/corgi-x';
import { RescheduleDates } from '../RescheduleDates';
import styles from './RescheduledDateScene.module.css';

export type RescheduledDateSceneProps = {
  currentNextDate: string;
  unavailableDate?: string;
  availablePauseResumeDates: string[];
  onSubmit: (resumeDate: string) => void;
  onClose: () => void;
  isSubmitting: boolean;
};
/**
 * Render a scene with a form to choose a new date to reschedule the next order.
 */
export function RescheduledDateScene(props: RescheduledDateSceneProps) {
  const {
    currentNextDate,
    unavailableDate,
    availablePauseResumeDates,
    onSubmit,
    onClose,
    isSubmitting,
  } = props;

  const [rescheduledDate, setRescheduledDate] = useState<string>();
  const [didUserSelectADate, setDidUserSelectADate] = useState(false);

  const handleChange = (date: string) => {
    setRescheduledDate(date);
    setDidUserSelectADate(true);
  };

  function handleSubmit() {
    // submit is disabled if this is undefined
    if (!rescheduledDate) return;
    onSubmit(rescheduledDate);
  }

  return (
    <Page layout="base" onCloseClick={onClose} className={styles.page}>
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Pause My Plan
      </Text>
      <Form onSubmit={handleSubmit} aria-label="Form with months">
        <div className={styles.content}>
          <Text
            variant="article-20"
            as="p"
            color="charcoal-3"
            topSpacing="sm"
            bottomSpacing="lg"
          >
            Tell us when you’d like to pause your plan until. We’ll notify you
            before we begin preparing your next order, and you can always adjust
            your restart date.
          </Text>
          <Text
            variant="heading-22"
            as="h4"
            bold
            vSpacing="md"
            className={styles.rescheduleQuestion}
          >
            When would you like to resume?
          </Text>
          <RescheduleDates
            dates={availablePauseResumeDates}
            rescheduledDate={rescheduledDate}
            unavailableDate={unavailableDate}
            currentNextDate={currentNextDate}
            onChange={handleChange}
            didUserSelectADate={didUserSelectADate}
          />
          <div className={styles.stickyButtonContainer}>
            <Button
              type="submit"
              variant="secondary"
              aria-disabled={
                !rescheduledDate ||
                rescheduledDate === currentNextDate ||
                isSubmitting
              }
              loading={isSubmitting}
              className={styles.stickyButton}
            >
              Continue
            </Button>
          </div>
        </div>
      </Form>
    </Page>
  );
}
