import {
  type GeolocationData,
  geolocationWindowProperty,
} from 'src/server/utils/getGeolocationDataFromHeaders';

import { browserOnly } from '@farmersdog/utils';

declare global {
  interface Window {
    [geolocationWindowProperty]?: GeolocationData;
  }
}

export function getGeolocationData() {
  return browserOnly(
    (window: Window) => {
      return window[geolocationWindowProperty] ?? {};
    },
    () => ({}) as GeolocationData
  );
}
