import { randomizeArray, swap } from '../../../utils';
import { CancellationResponse, CancellationResponsesEnum } from '../data';

const otherResponses = [
  CancellationResponsesEnum.OtherPriceReason,
  CancellationResponsesEnum.OtherPhysicalReaction,
  CancellationResponsesEnum.OtherDeliveryIssue,
  CancellationResponsesEnum.NeedMoreOrOtherRecipes,
  CancellationResponsesEnum.NeedOtherRecipe,
];

/**
 * Randomizes an array of cancellation responses.
 * If the array includes an "other" response, ensures it is last.
 * If the array includes "NoLongerWithDog", ensures it is last.
 * If the array includes "StillTheFarmersDog", ensures it is last.
 * If the array includes "TempPause", ensures it is second to last.
 * */
export function randomizeResponses(responses: CancellationResponse[]) {
  const randomizedArray = randomizeArray(responses);
  const lastIndex = randomizedArray.length - 1;

  const otherResponse = randomizedArray.find(r =>
    otherResponses.find(o => o === r.value)
  );

  if (otherResponse) {
    swapResponses(randomizedArray, otherResponse.value, lastIndex);
  }

  if (
    randomizedArray.find(
      r => r.value === CancellationResponsesEnum.NoLongerWithDog
    )
  ) {
    swapResponses(
      randomizedArray,
      CancellationResponsesEnum.NoLongerWithDog,
      lastIndex
    );
  }

  if (
    randomizedArray.find(
      r => r.value === CancellationResponsesEnum.StillTheFarmersDog
    )
  ) {
    swapResponses(
      randomizedArray,
      CancellationResponsesEnum.StillTheFarmersDog,
      lastIndex
    );
  }

  if (
    randomizedArray.find(r => r.value === CancellationResponsesEnum.TempPause)
  ) {
    swapResponses(
      randomizedArray,
      CancellationResponsesEnum.TempPause,
      lastIndex - 1
    );
  }

  return randomizedArray;
}

/** Finds `item` in `responses` and swaps it with the item at `swapIndex`.
 * If `item` is not in `responses`, returns early */
function swapResponses(
  responses: CancellationResponse[],
  item: string,
  swapIndex: number
) {
  const itemIndex = responses.findIndex(r => r.value === item);
  if (itemIndex === -1) return;
  if (itemIndex !== swapIndex) {
    swap(responses, itemIndex, swapIndex);
  }
}
