export interface ReviewData {
  initials: string;
  name: string;
  href: string;
  comment: string;
}

export const reviewData: ReviewData[] = [
  {
    initials: 'MK',
    name: 'Marina Kinross',
    href: 'https://www.google.com/url?q=https://www.google.com/maps/contrib/110815125907888638674/reviews?hl%3Den-US%26sa%3DX%26ved%3D2ahUKEwj03qz11e_kAhUCZN8KHaXZCCUQvvQBegQIARBM&sa=D&ust=1572014237496000&usg=AFQjCNEq68GQkDh62FPZ7PWvCUKBb3zEjQ',
    comment:
      'This healthier diet may cost a little more than the dried or canned food but it has saved me hundreds of dollars on vet bills.',
  },
  {
    initials: 'PS',
    name: 'Pamela Stewart',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0001352,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s115469324638109689721!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'My dog LOVES the food and I love the convenience and nutritional value!',
  },
  {
    initials: 'CC',
    name: 'Catherine Clark',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0001352,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s118287799014949704710!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'We have noticed clearer eyes, shinier coat, and much more energy!',
  },
  {
    initials: 'SH',
    name: 'Shawna Heninger',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0023239,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s110380895156170684148!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'If you love your dog feed him food from The Farmers Dog. Your dog will love it and you will love knowing it is healthy and safe.',
  },
  {
    initials: 'TB',
    name: 'Tarun Bhatnagar',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0023239,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s102719700171747882865!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'Our Havanese puppy “Chutney” is a very picky eater and The Farmer’s Dog is the only thing he eats without fuss.',
  },
  {
    initials: 'CK',
    name: 'Cindy Kirkland',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0001352,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s105619360653572035429!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'The Farmers Dog has cleared up all of my dog`s allergies and Pip just absolutely loves his fresh food!',
  },
  {
    initials: 'HG',
    name: 'Heather Grassette ',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0001352,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s106345913108502087595!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment: 'The quality is amazing, as well as the customer service.',
  },
  {
    initials: 'SH',
    name: 'Sarajill Hartley',
    href: 'https://www.google.com/maps/reviews/@40.7294695,-74.0023239,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s102907240947200056710!2s0x0:0x1f3c21784965e32e?hl=en-US',
    comment:
      'We recently switched from kibble to the Farmer’s Dog and are totally thrilled!',
  },
];
