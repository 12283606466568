import { useEffect } from 'react';
import { Button, ContentWidth } from '@farmersdog/corgi';
import { PageWidth, Text } from '@farmersdog/corgi-x';

import Page from 'src/components/Page';

import dogWithBone from 'src/assets/images/dog-with-bone.png';
import { PATH_HOME } from '@farmersdog/constants/paths';
import styles from './NotFound.module.scss';
import { useHistory, useLocation } from 'react-router';
import { reporter } from 'src/services/reporter';

interface NotFoundProps {
  /** response status for SSR */
  staticContext?: { status?: number };
  /** Page / Modal title */
  title?: string;
}

export default function NotFound(props: NotFoundProps) {
  const { staticContext } = props;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (staticContext) {
      // Prepare to return 404 status from server-side rendering
      // https://reacttraining.com/react-router/web/guides/server-rendering
      staticContext.status = 404;
    }
  }, [staticContext]);

  useEffect(() => {
    reporter.error('Page not found', {
      path: location.pathname,
    });
  }, [location.pathname]);

  const redirectToHome = () => {
    history.push(PATH_HOME);
  };

  const body = (
    <>
      <Text variant="heading-28" color="kale-3" as="h4" bold>
        Page not found
      </Text>
      <Text variant="article-16" as="p">
        Woof! This page doesn’t exist!
      </Text>
      <Text variant="heading-12" as="p" color="charcoal-1">
        Please double-check the URL is valid.
      </Text>
      <img src={dogWithBone} className={styles.dogWithBone} alt="Poo" />
    </>
  );

  return (
    <Page title="Page Not Found">
      <PageWidth>
        <ContentWidth alignSelf="center" className={styles.container}>
          {body}
          <div>
            <Button asLink onClick={redirectToHome}>
              <Text variant="heading-16" bold>
                To home page
              </Text>
            </Button>
          </div>
        </ContentWidth>
      </PageWidth>
    </Page>
  );
}
