import { Card } from '@farmersdog/corgi-x';
import { useState } from 'react';
import { useFetchHomepageData } from '../../network';
import { reporter } from '../../../errors/services';
import { type DateRange, DayPicker } from 'react-day-picker';

import type { CalendarEvent } from './events';
import styles from './schedulecard.module.css';
import { generateFakeFutureOrderCalendarEvent } from './transformToEvents';

export interface ScheduleCardProps {
  event: CalendarEvent;
}

interface CalendarProps {
  event: CalendarEvent;
}

export const ScheduleCard = (props: ScheduleCardProps) => {
  const orderSizeNudgeRequest = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving Schedule Card data', {
        sourceError: error,
      });
    },
  });
  const events = [
    props.event,
    generateFakeFutureOrderCalendarEvent(
      props.event,
      orderSizeNudgeRequest.data?.customer.freshSubscription.frequency
    ),
  ];
  return (
    <>
      {events.map(event => (
        <Calendar key={event.from.toISOString()} event={event} />
      ))}
    </>
  );
};

export const Calendar = (props: CalendarProps) => {
  const [range, setRange] = useState<DateRange | undefined>({
    from: new Date(props.event.from),
    to: new Date(props.event.to),
  });

  const handleOnSelect = () => {
    setRange(range);
  };

  return (
    <Card>
      <DayPicker
        className={styles.root}
        classNames={{
          range_start: styles.rangeStart,
          range_end: styles.rangeEnd,
        }}
        mode="range"
        selected={range}
        onSelect={handleOnSelect}
        defaultMonth={range?.from}
      />
    </Card>
  );
};
