import { ChangeEvent } from 'react';
import { Checkbox, Fieldset, Text } from '@farmersdog/corgi-x';
import styles from './SmsCheckbox.module.css';

interface SmsCheckboxProps {
  content: { title: string; copy: string };
  smsCheck: boolean;
  onChange: (e: ChangeEvent) => void;
  fieldName: string;
}

export function SmsCheckbox(props: SmsCheckboxProps) {
  return (
    <Fieldset legend={props.content.title}>
      <Text variant="article-16">{props.content.copy}</Text>
      <Checkbox
        label="Text"
        checked={props.smsCheck}
        onChange={props.onChange}
        name={props.fieldName}
        className={styles.checkboxContainer}
        data-checked={props.smsCheck}
      />
    </Fieldset>
  );
}
