import { useEffect, useId } from 'react';

import { UseForm, FormFieldsType } from '../../../../types';

interface UseFormHintProps {
  formMethods: UseForm;
  nodeName: keyof FormFieldsType;
}

export function useFormHint({ formMethods, nodeName }: UseFormHintProps) {
  const hintId = useId();

  useEffect(() => {
    async function validateInitialState() {
      await formMethods.trigger(nodeName);
    }
    void validateInitialState();
  }, [formMethods, nodeName]);

  const { invalid } = formMethods.getFieldState(nodeName);

  return {
    hintId,
    invalid,
  };
}
