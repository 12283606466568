import { useCallback, useMemo, useState } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { MaybeNodeInput } from '../../../blueprint/types';
import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useBreedDropdownImprovementsExperiment } from '../../../hooks/experiments/useBreedDropdownImprovementsExperiment';
import { useLabel } from '../../../hooks/useLabel';
import {
  TOSAComponentInput,
  TOSALeafNode,
  BreedType,
  TOSANodeInput,
} from '../../../types';
import { getPetNamesFromFormEntries } from '../../../utils';

import styles from './BreedsInput.module.css';
import { AddBreedButtons } from './buttons/AddBreedButtons';
import { SelectComboBox } from './SelectComboBox';

export interface BreedNodeInput extends TOSANodeInput {
  options?: BreedType[];
}

export interface BreedLeafNode extends TOSALeafNode {
  input?: MaybeNodeInput<BreedNodeInput>;
}

export function BreedsInput(props: TOSAComponentInput<BreedLeafNode>) {
  const { node, formMethods, useFeature } = props;
  if (!node?.input?.options) {
    throw new Error('BreedsInput requires options');
  }
  const { shouldShowBreedDropdownImprovements } =
    useBreedDropdownImprovementsExperiment({
      useFeature,
    });

  const breeds = shouldShowBreedDropdownImprovements
    ? removeUnknownBreed(node.input.options)
    : node.input.options;

  const unknownBreed = shouldShowBreedDropdownImprovements
    ? findUnknownBreed(node.input.options)
    : findUnknownBreed(breeds);

  const { register, getValues } = formMethods;
  const registerProps = register(node.name);
  const { backLabel } = useLabel({ node, getValues });
  const formValues = getValues();
  const { position } = getNodeNameAndPosition(node.name);
  const breedsField = `${NodeNames.Breeds}-${position}` as const;
  const [shouldFocusInput, setShouldFocusInput] = useState(false);

  const [selectedBreeds, setSelectedBreeds] = useState<BreedType[]>(
    convertBreedStringsToItems(
      formValues[breedsField] || [],
      node.input.options
    )
  );

  const availableBreedsList = useMemo(() => {
    return breeds.filter(breed => !selectedBreeds.includes(breed));
  }, [breeds, selectedBreeds]);

  const { currentPetName } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName: node.name,
  });

  const [showPlaceholderInput, setShowPlaceholderInput] = useState(false);

  const handleAddAnotherBreed = useCallback(() => {
    setShowPlaceholderInput(true);
    setShouldFocusInput(true);
  }, []);

  const updateSelectedBreeds = useCallback(
    (updatedList: BreedType[]) => {
      setSelectedBreeds(updatedList);
      const event = {
        target: {
          name: node.name,
          value: updatedList.map(item => item.name),
        },
      };
      void registerProps.onChange(event);
    },
    [node.name, registerProps]
  );

  const handleBreedAdded = useCallback(
    (selectedBreed: BreedType) => {
      if (selectedBreeds.includes(selectedBreed)) {
        return;
      }
      setShowPlaceholderInput(false);
      updateSelectedBreeds([...selectedBreeds, selectedBreed]);
    },
    [selectedBreeds, updateSelectedBreeds]
  );

  const handleAddUnknownBreed = useCallback(
    () => handleBreedAdded(unknownBreed),
    [handleBreedAdded, unknownBreed]
  );

  const handleBreedRemovedFns = useMemo(
    () =>
      selectedBreeds.map(selectedBreed => () => {
        updateSelectedBreeds(
          selectedBreeds.filter(item => item !== selectedBreed)
        );
      }),
    [selectedBreeds, updateSelectedBreeds]
  );

  const handleBreedSelectedFns = useMemo(
    () =>
      selectedBreeds.map((_, indexToReplace) => (selectedBreed: BreedType) => {
        const updatedList = [...selectedBreeds];
        updatedList[indexToReplace] = selectedBreed;
        updateSelectedBreeds(updatedList);
      }),
    [selectedBreeds, updateSelectedBreeds]
  );

  const handlePlaceholderRemoved = useCallback(
    () => setShowPlaceholderInput(false),
    []
  );

  const accessibleLabel = node.input?.accessibleLabel || node.name;
  const shouldDisplayUnknownBreed = !selectedBreeds.includes(unknownBreed);
  return (
    <div className={styles.wrapper}>
      {selectedBreeds.map((breed, index) => {
        return (
          <SelectComboBox
            key={breed.name}
            availableBreedsList={availableBreedsList}
            selectedBreed={breed}
            unknownBreed={shouldDisplayUnknownBreed ? unknownBreed : undefined}
            accessibleLabel={accessibleLabel}
            setShouldFocusInput={setShouldFocusInput}
            selectedBreedPosition={index}
            useFeature={useFeature}
            shouldShowClearButton={selectedBreeds.length > 0}
            handleBreedRemoved={handleBreedRemovedFns[index]}
            handleBreedSelected={handleBreedSelectedFns[index]}
            className={styles.breedsInput}
          />
        );
      })}
      {(selectedBreeds.length === 0 || showPlaceholderInput) && (
        <SelectComboBox
          key="placeholderInput"
          availableBreedsList={availableBreedsList}
          selectedBreed={undefined}
          unknownBreed={shouldDisplayUnknownBreed ? unknownBreed : undefined}
          accessibleLabel={accessibleLabel}
          shouldFocusInput={shouldFocusInput}
          setShouldFocusInput={setShouldFocusInput}
          useFeature={useFeature}
          shouldShowClearButton={selectedBreeds.length > 0}
          handleBreedRemoved={handlePlaceholderRemoved}
          handleBreedSelected={handleBreedAdded}
          className={styles.placeholderInput}
        />
      )}
      <span>{backLabel}</span>
      <div className={styles.addBreedButtons}>
        <AddBreedButtons
          petName={currentPetName}
          unknownBreed={unknownBreed}
          selectedBreeds={selectedBreeds}
          handleAddAnotherBreed={handleAddAnotherBreed}
          handleAddUnknownBreed={handleAddUnknownBreed}
        />
      </div>
    </div>
  );
}

function convertBreedStringsToItems(
  names: string[],
  items: BreedType[]
): BreedType[] {
  return names.map(name => {
    const foundItem = items.find(
      item => item.name.toLowerCase() === name.toLowerCase()
    );

    if (!foundItem) {
      throw new Error(`Could not find breed with name ${name}`);
    }

    return foundItem;
  });
}

function removeUnknownBreed(items: BreedType[]) {
  return items.filter(breed => !/^unknown/i.exec(breed.name));
}

export function findUnknownBreed(items: BreedType[]): BreedType {
  const unknownBreed = items.find(item => /^unknown/i.exec(item.name));
  if (!unknownBreed) {
    throw new Error('Cannot find unknown breed');
  }

  return unknownBreed;
}
