import { useZipEmailCopyExperiment } from '../../../../hooks/experiments/useZipEmailCopyExperiment';
import { TOSAComponentInput, TOSALeafNode } from '../../../../types';
import { InlineError } from '../InlineError';
import { SpeechBubble } from '../SpeechBubble';

export function ZipEmailErrorWrapper(props: TOSAComponentInput<TOSALeafNode>) {
  const { useFeature, node } = props;
  const { showZipEmailCopy } = useZipEmailCopyExperiment({ useFeature });

  const isNotFocused =
    document.activeElement?.getAttribute('name') !== node.input?.label;

  return (
    <>
      {showZipEmailCopy ? (
        <>{isNotFocused && <SpeechBubble {...props} />}</>
      ) : (
        <InlineError {...props} />
      )}
    </>
  );
}
