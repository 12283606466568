import {
  trackGa4Event,
  Ga4CustomEvent,
  Ga4StandardEvent,
} from './trackGa4Event';

import { pixelRevenue } from 'src/vendors/constants';

import type { Ga4Item } from './utils';
import { SubscriptionType } from 'src/graphql/types';

interface RecipesSuccessData {
  items?: Ga4Item[];
}

interface RecipesSuccessArgs {
  data: RecipesSuccessData;
  productLine: SubscriptionType;
  isLastPet: boolean;
}

export function trackGa4RecipesSuccess({
  data,
  isLastPet,
  productLine,
}: RecipesSuccessArgs) {
  trackGa4Event({
    name: Ga4CustomEvent.RecipesSuccess,
    properties: { productLine },
  });
  if (isLastPet && data?.items?.length) {
    trackGa4Event({
      name: Ga4StandardEvent.AddToCart,
      properties: {
        currency: 'USD',
        value: pixelRevenue,
        items: data.items,
      },
    });
  }
}
