import { couponCookie, ReferrerType } from '@farmersdog/coupons';

import { useTosaUserFeature } from '../../../hooks/useTosaUserFeature';
import { UseFeatureHook, UseTreatsInCheckoutUiReturn } from '../../../types';
import { CvrTreatsInCheckoutUiTreatments, FeatureName } from '../../../utils';

interface UseTreatsInCheckoutUiProps {
  useFeature: UseFeatureHook;
}

export const useTreatsInCheckoutUi = ({
  useFeature,
}: UseTreatsInCheckoutUiProps): UseTreatsInCheckoutUiReturn => {
  const { referrerCode, referrerType } = couponCookie.get();
  const pCode =
    referrerCode && referrerType == ReferrerType.Partner ? referrerCode : '';

  const { treatment } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_TREATS_IN_CHECKOUT_UI,
    attributes: { pCode },
  }) as { treatment: CvrTreatsInCheckoutUiTreatments };

  // The treatment of the split flag is either 'off' or 'page'.
  // This hook returns the location where treats in checkout UI should be displayed,
  // which is either 'checkout' or 'page' as strings.
  return (
    treatment === CvrTreatsInCheckoutUiTreatments.off ? 'checkout' : treatment
  ) as UseTreatsInCheckoutUiReturn;
};
