import { PageWidth } from '@farmersdog/corgi-x';
import { useVetSection } from '../../hooks/useVetSection';
import { IconsSection } from './IconsSection/IconsSection';
import { CheckMarksSection } from './CheckMarksSection/CheckMarksSection';
import styles from './SectionVet.module.scss';
import { useCorgiViewport } from 'src/screen';

export const SectionVet = () => {
  const { shouldShowVetSection, showCheckmarksVersion } = useVetSection();

  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });
  if (!shouldShowVetSection) {
    return null;
  }

  return (
    <section className={styles.container}>
      <PageWidth>
        {showCheckmarksVersion ? (
          <CheckMarksSection isMobile={isMobile} />
        ) : (
          <IconsSection isMobile={isMobile} />
        )}
      </PageWidth>
    </section>
  );
};
