import { ApolloLink, FetchResult } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { tosaToken } from '@farmersdog/lead-browser-storage';
import { GraphQLFormattedError } from 'graphql';
import { jwtDecode } from 'jwt-decode';
import createLead from '../tosa/mutations/useCreateLead/createLead.graphql';
import { getApolloHttpLink } from './getApolloHttpLink';
import { GraphQLEndpoints } from './constants';
import { CreateLeadMutation } from '../tosa.types';

export interface TokenPayload {
  email: string;
  exp: number;
  iat: number;
}

const errorCodes = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

const httpLink = getApolloHttpLink(tosaToken.get());

export function createTosaErrorMiddleware(): ApolloLink {
  return onError(({ graphQLErrors, operation, forward }) => {
    if (
      !graphQLErrors ||
      operation.getContext().endpoint !== GraphQLEndpoints.TOSA
    ) {
      return;
    }

    const unauthenticatedError = graphQLErrors.find(isUnauthenticatedError);

    if (!unauthenticatedError) {
      return;
    }

    // Attempt to refresh the token
    const currentToken = tosaToken.get();
    if (!currentToken) {
      return;
    }

    const decodedToken = jwtDecode<TokenPayload>(currentToken);
    const observable = ApolloLink.execute(httpLink, {
      query: createLead,
      variables: {
        email: decodedToken.email,
      },
      context: {
        endpoint: GraphQLEndpoints.TOSA,
      },
    });

    return observable.flatMap((result: FetchResult) => {
      const { data } = result as FetchResult<CreateLeadMutation>;
      const refreshedToken = data?.createLead.token;

      if (!refreshedToken) {
        return forward(operation);
      }

      const currentContext = operation.getContext();
      const existingHeaders = currentContext.headers as Record<string, unknown>;
      tosaToken.set(refreshedToken);
      operation.setContext({
        ...currentContext,
        headers: {
          ...existingHeaders,
          Authorization: `Bearer ${refreshedToken}`,
        },
      });

      // Retry the failed operation with the new token
      return forward(operation);
    });
  });
}

function isUnauthenticatedError(error: GraphQLFormattedError) {
  return error.extensions?.code === errorCodes.UNAUTHENTICATED;
}
