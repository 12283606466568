import WeightSVG from '../assets/weight.svg';
import { DidYouKnowBox } from '../DidYouKnowBox';

export const IdealWeightStatic = () => {
  return (
    <DidYouKnowBox Svg={WeightSVG}>
      Our precise, personalized portions make it simple to meet and maintain
      your dog’s ideal weight.
    </DidYouKnowBox>
  );
};
