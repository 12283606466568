// Includes all enabled and disabled values from the db; needs to be updated when static data changes.
export enum HealthIssue {
  Anxiety = 'Anxiety',
  BadBreath = 'Bad breath',
  Cancer = 'Cancer',
  ChewsPaws = 'Chews paws',
  Constipation = 'Constipation',
  Diarrhea = 'Diarrhea',
  EarInfections = 'Ear infections',
  EnvironmentalAllergies = 'Environmental allergies',
  FoodAllergies = 'Food allergies',
  GlutenSensitive = 'Gluten sensitive',
  GrainSensitive = 'Grain sensitive',
  Hyperactive = 'Hyperactive',
  HyperlipidemiaOrHypertriglyceridemia = 'Hyperlipidemia / Hypertriglyceridemia',
  PancreatitisHistory = 'History of Pancreatitis',
  Itchiness = 'Itchiness',
  Joints = 'Joints',
  Lethargy = 'Lethargy',
  PassesGas = 'Passes Gas',
  Seizures = 'Seizures',
  SkinAndCoat = 'Skin and Coat',
  TearStains = 'Tear stains',
  UrinaryTract = 'Urinary tract',
  Vomiting = 'Vomiting',
}

export type HealthIssueWithWarning =
  | HealthIssue.HyperlipidemiaOrHypertriglyceridemia
  | HealthIssue.PancreatitisHistory;

export const HEALTH_ISSUE_WARNING_MESSAGE = `Dogs with this issue often require a specific recipe which we don’t currently formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.`;

export const CTA_BUTTON_WITH_WARNING = 'Continue Anyway';
export const CTA_BUTTON = 'Continue';
