import { Grid, GridItem, Picture, Section, Text } from '@farmersdog/corgi-x';
import { threeHappyDogsWhite } from '../../../assets/add-dog';
import styles from './PendingCard.module.css';

import { PENDING_PETS_HEADING, PENDING_PETS_COPY } from './constants';
export const PendingPetsCard = () => {
  return (
    <Section
      aria-labelledby="pending-pets-card-heading"
      variant="card"
      className={styles.root}
      bottomSpacing="sm"
    >
      <Grid
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
      >
        <GridItem md={8} xl={9}>
          <Text
            as="h2"
            variant="heading-28"
            color="white"
            bold
            topSpacing={{ xs: 'sm', md: 'none' }}
            id="pending-pets-card-heading"
          >
            {PENDING_PETS_HEADING}
          </Text>
          <Text as="p" color="white" variant="article-16">
            {PENDING_PETS_COPY}
          </Text>
        </GridItem>
        <GridItem md={3} justifyContent="center" alignItems="center">
          <Picture
            sources={[threeHappyDogsWhite]}
            alt="Three happy dogs sitting together"
            style={{ width: '100%', maxWidth: '275px' }}
          />
        </GridItem>
      </Grid>
    </Section>
  );
};
