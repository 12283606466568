import classNames from 'classnames';

import { Text, useViewport } from '@farmersdog/corgi-x';

import styles from './KeyIngredients.module.css';
import { LineAndDot } from './LineAndDot';

export interface KeyIngredientsProps {
  mainIngredients: string;
}

export function KeyIngredients({ mainIngredients }: KeyIngredientsProps) {
  const keyIngredients = mainIngredients.split(', ');
  const { medium, large, extraLarge } = useViewport();
  const split = medium || large || extraLarge;

  return (
    <div className={styles.outerContainer}>
      <div className={classNames(styles.container, { [styles.split]: split })}>
        {split && (
          <div>
            {keyIngredients.slice(0, 2).map((ingredient, i) => (
              <div className={classNames(styles.item, styles.leftItem)} key={i}>
                <Text bold variant="heading-16" color="kale-3">
                  {ingredient}
                </Text>
                <LineAndDot />
              </div>
            ))}
          </div>
        )}
        <div>
          {keyIngredients.slice(split ? 2 : 0, 4).map((ingredient, i) => (
            <div className={classNames(styles.item, styles.rightItem)} key={i}>
              <LineAndDot flip />
              <Text bold variant="heading-16" color="kale-3">
                {ingredient}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
