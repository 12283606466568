import classNames from 'classnames';

import {
  AccessibleContent,
  ContentReveal,
  Strikeout,
  Text,
  Button,
} from '@farmersdog/corgi-x';

import styles from './StickyCta.module.css';

interface StickyCtaArgs {
  shouldReveal: boolean;
  petDailySubtotal: string;
  petDailyDiscountedSubtotal: string;
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
  discountPercentage: number | null;
  accessibleText: string;
}
export function StickyCta({
  shouldReveal,
  petDailySubtotal,
  petDailyDiscountedSubtotal,
  onClick,
  loading,
  disabled,
  discountPercentage,
  accessibleText,
}: StickyCtaArgs) {
  return (
    <ContentReveal in={shouldReveal} inAnimation="fade" outAnimation="fade">
      <div
        className={classNames(styles.stickyContainer, {
          [styles.stickyContainerFront]: shouldReveal,
          [styles.stickyContainerBack]: !shouldReveal,
        })}
      >
        <div className={styles.ctaContainer}>
          {petDailyDiscountedSubtotal && (
            <AccessibleContent
              accessibleText={accessibleText}
              visualContent={
                <Text
                  variant="heading-16"
                  color="charcoal-3"
                  bold
                  className={styles.priceText}
                >
                  First Box:{' '}
                  <>
                    <Strikeout className={styles.pricePerDay}>
                      <Text variant="heading-16">{petDailySubtotal}/day</Text>
                    </Strikeout>{' '}
                  </>
                  <Text variant="heading-16" as="span" color="kale-2" bold>
                    {petDailyDiscountedSubtotal}
                    /day{' '}
                  </Text>
                  <Text
                    variant="heading-16"
                    as="span"
                    color="kale-2"
                    bold
                    className={styles.pricePerDay}
                    id="recipes-recommended-plan-stickycta-discount"
                  >
                    ({discountPercentage ?? ''}% OFF)
                  </Text>
                </Text>
              }
            />
          )}
          <Button
            variant="primary"
            onClick={onClick}
            loading={loading}
            disabled={disabled || !shouldReveal}
            aria-disabled={disabled || !shouldReveal}
            type="submit"
            className={styles.ctaButton}
          >
            <div className={styles.ctaButtonText}>
              Continue with Recommendation
            </div>
          </Button>
        </div>
      </div>
    </ContentReveal>
  );
}
