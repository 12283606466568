import { DefaultViewPorts } from 'src/screen/types';

/**
 * Definitions for common window heights based on platform type
 * Platform is provided by SSR safe `deviceType`
 */
const DEFAULT_VIEWPORTS: DefaultViewPorts = {
  // iPhone 7/8
  mobile: {
    innerWidth: 414,
    innerHeight: 736,
  },
  // iPad
  tablet: {
    innerWidth: 768,
    innerHeight: 1024,
  },
  // Macbook 12" Retina
  desktop: {
    innerWidth: 1400,
    innerHeight: 900,
  },
};

export default DEFAULT_VIEWPORTS;
