import { trackAmazonTagEvent, AmazonTagEvent } from './trackAmazonTagEvent';

interface trackAmazonTagCheckoutArgs {
  userId?: number;
  orderId?: number;
}
/**
 * track an Amazon checkout event
 */
export function trackAmazonTagCheckout({
  userId,
  orderId,
}: trackAmazonTagCheckoutArgs): void {
  trackAmazonTagEvent({
    name: AmazonTagEvent.Checkout,
    properties: { userId, orderId },
  });
}
