import {
  getManagedAuthRegisterErrorDisplayMessage,
  isManagedAuthRegisterError,
} from '../../../utils';
import { getErrorMessage } from '../../../validation';

interface HandleAuthRegisterErrorArgs {
  error: Error;
  throwToErrorBoundary: (error: Error) => void;
  onManagedError: (message: string) => void;
}

export const handleAuthRegisterError = ({
  error,
  throwToErrorBoundary,
  onManagedError,
}: HandleAuthRegisterErrorArgs): void => {
  const errorMessage = getErrorMessage(error);

  if (isManagedAuthRegisterError(errorMessage)) {
    const displayableErrorMessage =
      getManagedAuthRegisterErrorDisplayMessage(errorMessage);
    onManagedError(displayableErrorMessage);
  } else {
    throwToErrorBoundary(error);
  }
};
