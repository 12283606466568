import * as Apollo from '@apollo/client';

import config from '../../config';

import { client } from './client';

export function useClient() {
  const clientOverride = config.get('tosa.apollo.withNativeProvider')
    ? undefined
    : client;

  return Apollo.useApolloClient(clientOverride);
}
