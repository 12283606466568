import { mountVendorWindowEvent } from '../utils';

export interface LiQPushArgs {
  event: string;
  name: string;
  email: string;
  amount: string;
  conversionId: string | undefined;
  currency: string;
}

declare global {
  interface Window {
    liQ: {
      push: (args: LiQPushArgs) => void;
    };
  }
}

export interface TriggerLiveIntentArgs {
  conversionId: LiQPushArgs['conversionId'];
  email: LiQPushArgs['email'];
  amount: LiQPushArgs['amount'];
}

function _triggerLiveIntentEvent(
  window: Window,
  { conversionId, email, amount }: TriggerLiveIntentArgs
): void {
  window.liQ = window.liQ || [];
  if (typeof window.liQ?.push === 'function') {
    window.liQ.push({
      event: 'conversion',
      name: 'product_purchase',
      email,
      conversionId,
      amount,
      currency: 'USD',
    });
  }
}

export const triggerLiveIntentEvent = mountVendorWindowEvent(
  'LiveIntent',
  _triggerLiveIntentEvent
);
