import { Picture } from '@farmersdog/corgi-x';

import { useSignupUpdatedAssets } from '../../../hooks';
import { TOSAComponentInput, TOSALeafNode } from '../../../types';

import NumPetsPicturePng from './assets/numberOfPets.png';
import NumPetsPictureSvg from './assets/numberOfPets.svg';
import NumPetsPictureWebp from './assets/numberOfPets.webp';
import styles from './NumberOfPetsImage.module.css';

export function NumberOfPetsImage({
  useFeature,
}: TOSAComponentInput<TOSALeafNode>) {
  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });
  return (
    <div className={styles.imageContainer}>
      {isSignupUpdatedAssetsOn ? (
        <Picture
          className={styles.image}
          sources={[NumPetsPictureWebp, NumPetsPicturePng]}
          alt="Number of pets"
        />
      ) : (
        <NumPetsPictureSvg className={styles.image} />
      )}
    </div>
  );
}
