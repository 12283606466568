import { FetchQuoteQuery } from '../../graphql/types';

interface GetPetDailySubtotalArgs {
  quote: FetchQuoteQuery['fetchQuote'] | null;
  petName: string;
}

interface GetPetDaylySubtotalReturn {
  dailySubtotal: number | null;
  dailySubtotalWithDiscount: number | null;
}

export function getPetDailySubtotal({
  quote,
  petName,
}: GetPetDailySubtotalArgs): GetPetDaylySubtotalReturn {
  if (!quote) {
    return {
      dailySubtotal: null,
      dailySubtotalWithDiscount: null,
    };
  }
  const petDailySubtotal = quote.trial.breakdown.daily.find(
    item => item.petName === petName
  )?.fresh?.subtotal;
  const trialDiscountPercentage = quote.trial.discount.percentage;

  if (!petDailySubtotal) {
    return {
      dailySubtotal: null,
      dailySubtotalWithDiscount: null,
    };
  }

  return {
    dailySubtotal: petDailySubtotal,
    dailySubtotalWithDiscount: Math.round(
      petDailySubtotal * (1 - trialDiscountPercentage / 100)
    ),
  };
}
