import { Picture } from '@farmersdog/corgi-x';
import ProgressStep from '../ProgressStep';

import { useCorgiV2Viewport } from 'src/screen';

import { STEPS } from './constants';

import {
  progressStepsDesktopSources,
  progressStepsMobileSources,
} from './assets';

import styles from './HowItWorksProgress.module.scss';

function HowItWorksProgress() {
  const sourceProgressSteps = useCorgiV2Viewport({
    xs: progressStepsMobileSources,
    md: progressStepsDesktopSources,
  });

  return (
    <div className={styles.mainContainer}>
      <div className={styles.animationContainer}>
        <Picture
          sources={sourceProgressSteps}
          loading="lazy"
          alt="Progress Steps"
          className={styles.animation}
        />
      </div>
      <div className={styles.stepsContainer}>
        {STEPS.map(({ title, description, id }, index) => (
          <ProgressStep
            key={id}
            step={index + 1}
            title={title}
            description={description}
          />
        ))}
      </div>
    </div>
  );
}

export default HowItWorksProgress;
