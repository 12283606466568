import { PATH_BONUS_DISCOUNT_OFFER_TERMS } from '@farmersdog/constants/paths';

import { DiscountBannerInner } from '../DiscountBannerInner';

import { getSurpriseHigherDiscountBannerProps } from './hooks/getSurpriseHigherDiscountBannerProps';

export type HigherDiscountBannerProps = {
  isRecipesPage: boolean;
  discountAmount: number;
};

/**
 * Higher discount banner for frequency cap experiment,
 * to be used when isEligibleForHigherDiscountBanner returns true
 */
export function HigherDiscountBanner({
  isRecipesPage,
  discountAmount,
}: HigherDiscountBannerProps) {
  const {
    surpriseHigherDiscountClassName,
    surpriseHigherDiscountBannerTextColor,
    surpriseHigherDiscountBannerCopy,
    surpriseHigherDiscountLinkCopy,
  } = getSurpriseHigherDiscountBannerProps({
    isRecipesPage,
    discountAmount,
  });

  return (
    <DiscountBannerInner
      copy={surpriseHigherDiscountBannerCopy}
      linkPath={PATH_BONUS_DISCOUNT_OFFER_TERMS}
      linkCopy={surpriseHigherDiscountLinkCopy}
      className={surpriseHigherDiscountClassName}
      color={surpriseHigherDiscountBannerTextColor}
    />
  );
}
