import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

interface RecipesSeeDetailsClickedArgs {
  recipe: string;
}

export function trackRecipesSeeDetailsClicked({
  recipe,
}: RecipesSeeDetailsClickedArgs) {
  track(eventNames.my_dogs_recipes_see_details_clicked, {
    recipe,
  });
}
