import { Button, Link, Text } from '@farmersdog/corgi-x';
import { PATH_ORDERS, PATH_RESCHEDULE } from '@farmersdog/constants/paths';
import {
  trackEditOrderLinkClicked,
  trackRescheduleOrderLinkClicked,
} from '../../analytics';
import styles from './ScheduledInfoCard.module.css';
import { useLocation } from 'react-router';
import { setPathQueries } from '../../../utils';
import { QueryParameter } from '@farmersdog/constants';

export interface ScheduledInfoCardProps {
  dateRange: string;
  rescheduleCutoffDate: string;
}

export function ScheduledInfoCard(props: ScheduledInfoCardProps) {
  const location = useLocation();
  const RESCHEDULE_PATH = setPathQueries(PATH_RESCHEDULE, {
    [QueryParameter.PrevPath]: location.pathname,
    [QueryParameter.EntryPoint]: location.pathname,
  });
  return (
    <section>
      <div className={styles.scheduledInfo}>
        <Text
          as="h3"
          bold
          color="kale-3"
          variant="heading-28"
          className={styles.scheduledHeading}
        >
          Scheduled for <br className={styles.scheduledBreakpoint} />
          <span className={styles.dateRange}>{props.dateRange}</span>
        </Text>
        <Button
          to={RESCHEDULE_PATH}
          type="link"
          onClick={trackRescheduleOrderLinkClicked}
        >
          Reschedule
        </Button>
      </div>
      <Text
        variant="heading-16"
        color="charcoal-3"
        className={styles.scheduledChanges}
      >
        <Link to={PATH_ORDERS} onClick={trackEditOrderLinkClicked}>
          Make changes
        </Link>{' '}
        by {props.rescheduleCutoffDate}.
      </Text>
    </section>
  );
}
