/**
 * This file should export two objects, userFeatures and anonymousFeatures
 * userFeatures are features that will receive the user id where as
 * anonymousFeatures will receive the anonymous id.
 *
 * This separation is needed in split to ensure tracking is consistent for a user
 * from the home page all the way to checkout.
 *
 * All new features and feature updates should be documented here:
 * https://farmersdog.slab.com/posts/split-io-features-bxahk2oq
 */

import {
  CvrTreatsInCheckoutUiTreatments,
  FreshFoodConfidenceTreatmentEnum,
} from '@farmersdog/tosa/src/utils';
import {
  CheckoutPlanPriceClarityTreatments,
  CustomerClinicSearchTreatments,
  CustomerVetSectionTreatments,
  CvrSmsOptInTreatments,
  CvrSurpriseHigherDiscountTreatments,
  CvrWeightlossPersonalizationTreatments,
  CvrZipEmailCopyTreatments,
  DefaultDiscountTreatments,
  DynamicCalculatingScreenTreatments,
  OnbHomepagePersonalizationPickyTreatments,
  PriceValueModalTreatments,
  ReferralsGiftABoxVariant,
  SkipGetStartedTreatments,
  ToggleTreatments,
} from './types';

/**
 * Meta data around a feature used for various purposes throughout the website.
 */
interface FeatureMetaData {
  /** All expected treatment values */
  treatments: string[];
  /** All expected treatment values */
  track: boolean;
  /** Treatment data to use in development */
  mock: SplitIO.TreatmentWithConfig;
}

/**
 * Object with feature meta data. Specify the featureName as key and the meta data as value.
 */
interface Features {
  [featureName: string]: FeatureMetaData;
}

/**
 * Feature names
 */

export const CVR_MIXING_PLAN_EXPERIMENT = 'cvr_mixing_plan_experiment';
export const CVR_POST_CHECKOUT_REFERRAL_PERCENTAGE =
  'cvr_post_checkout_referral_percentage';
export const CX_ALERT_BANNER = 'cx_alert_banner';
export const TSX_SNAP_STICKS_SOLD_OUT = 'TSX_SNAP_STICKS_SOLD_OUT';
export const BEEF_MLENTILS_ENABLED = 'beef_mlentils_enabled';
export const BEEF_MLENTILS_UI_ROLLBACK_ENABLED =
  'beef_mlentils_ui_rollback_enabled';
export const CVR_DAISY_MODE_ENABLED = 'cvr_daisy_mode_enabled';
export const CVR_DYNAMIC_CALCULATING_SCREEN = 'cvr_dynamic_calculating_screen';
export const CVR_GRAINS_QUESTION = 'cvr_grains_question';
export const CVR_RECIPE_PAGE_PACK_ORDER = 'cvr_recipe_page_pack_order';
export const CVR_DEFAULT_DISCOUNT = 'cvr_default_discount';
export const CVR_DISABLE_REFERRER_LOOKUP = 'cvr_disable_referrer_lookup';
export const CVR_PRICE_VALUE_MODAL = 'cvr_price_value_modal';
export const CVR_GLOBALDISCOUNT_OVERRIDE = 'cvr_globaldiscount_override';
export const CVR_SIGNUP_FLOW_DOWN = 'cvr_signup_flow_down';
export const CVR_TOSA_HIGH_TRAFFIC_MODE = 'cvr_tosa_high_traffic_mode';
export const CVR_HOMEPAGE_VIDEO_HERO = 'cvr_homepage_video_hero';
export const CVR_CHECKOUT_PLAN_PRICE = 'cvr_checkout_plan_price';
export const CVR_CTA_ACCESSIBILITY_HOMEPAGE = 'cvr_cta_accessibility_homepage';
export const CVR_SMS_OPTIN_COPY = 'cvr_sms_optin_copy';
export const CVR_SKIP_GET_STARTED = 'cvr_skip_get_started';
export const CVR_LINK_HOMEPAGE_HEADLINE = 'cvr_link_homepage_headline';
export const CVR_WEIGHTLOSS_PERSONALIZATION = 'cvr_weightloss_personalization';
export const CVR_MOVE_NAV_LOGO_DOWN = 'cvr_move_nav_logo_down';
export const CVR_ZIP_EMAIL_COPY = 'cvr_zip_email_copy';
export const CVR_SURPRISE_HIGHER_DISCOUNT = 'cvr_surprise_higher_discount';
export const CVR_TREATS_IN_CHECKOUT_UI = 'cvr_treats_in_checkout_ui';
export const CVR_HOMEPAGE_TERMS_CHANGES_BANNER =
  'cvr_homepage_terms_changes_banner';
export const CVR_ANONYMOUS_ID_PERFORMANCE_LOGS =
  'cvr_anonymous_id_performance_logs';
export const CVR_HOMEPAGE_CTA_HEADER_COPY = 'cvr_homepage_cta_header_copy';
export const CVR_SIGNUP_SPACING = 'cvr_signup_spacing';
export const CVR_SIGNUP_ICON_UPDATE = 'cvr_signup_icon_update';
export const CVR_PRIOR_FOOD_TYPE_PROGRESSION =
  'cvr_prior_food_type_progression';
export const TERMS_CHANGES_BANNER = 'terms_changes_banner';
export const TSX_GLADLY_LIVE_CHAT = 'tsx_gladly_live_chat';
export const TSX_TREATS_PDP_PRICE_TEST = 'tsx_treats_pdp_price_test_enabled';
export const NARROW_DELIVERY_WINDOW = 'narrow_delivery_window';
export const TSX_SNAP_STICKS_HIDE = 'tsx_snap_sticks_hide';
export const TSX_TREATS_RECOMMEND_1_BAG = 'tsx_treats_recommend_1_bag';
export const ONB_USE_PAD_AT_CHECKOUT = 'onb_use_pad_at_checkout';
export const ONB_CHECKOUT_FREQUENCY_CAP = 'onb_checkout_frequency_cap';
export const INT_OPS_MEO_ENABLE_GENERATE_LABEL =
  'int_ops_meo_enable_generate_label';
export const SSP_SCHEDULE_CARD = 'ssp_schedule_card';
export const SSP_CALORIE_CHANGE_WEEKLY_PRICE_TO_DAILY_PRICE =
  'ssp_calorie_change_weekly_price_to_daily_price';
export const SSP_TREATS_LARGE_BAG_DISCOUNT = 'ssp_treats_large_bag_discount';
export const SSP_CALIBRATION_FLOW_DETAILED_PRICES =
  'ssp_calibration_flow_detailed_prices';
export const SSP_CALIBRATION_OPTION_FIX = 'ssp_calibration_option_fix';
export const SSP_PRE_TAX_ON_ORDERS_TOTAL = 'ssp_pre_tax_on_orders_total';
export const SSP_FREE_FIRST_TREAT_ENABLED = 'ssp_free_first_treat_enabled';
export const ADJ_REDIRECT_ADJUST_CALORIES_TO_CX =
  'adj_redirect_adjust_calories_to_cx';
const HEALTH_CUSTOMER_CLINIC_SEARCH = 'HEALTH_CUSTOMER_CLINIC_SEARCH';
export const HEALTH_HOMEPAGE_CUSTOMER_VET_SECTION =
  'HEALTH_HOMEPAGE_CUSTOMER_VET_SECTION';
export const ADJ_ADD_DOG_FORM = 'adj_add_dog_form';
export const ADJ_CX_CHAT_AB_TESTING = 'adj_cx_chat_ab_testing';
export const ONB_HOMEPAGE_PERSONALIZATION_PICKY =
  'onb_homepage_personalization_picky';
export const ONB_HOMEPAGE_PLAN_PROGRESSION = 'onb_homepage_plan_progression';
export const ONB_NO_PAST_DELIVERY_WINDOW = 'onb_no_past_delivery_window';
export const ADJ_BILLING_TIMELINE_SERVICE_ENABLE =
  'adj_billing_timeline_service_enable';
export const ADJ_MULTIPLE_PAYMENT_METHODS = 'adj_multiple_payment_methods';
export const ADJ_EDIT_PET_UI_IMPROVEMENTS = 'adj_edit_pet_ui_improvements';
export const ADJ_DIY_REACTIVATION_CGS_ENABLE =
  'adj_diy_reactivation_cgs_enable';
export const ADJ_PRE_GRACE_PERIOD = 'adj_pre_grace_period';
export const ADJ_GRACE_PERIOD_EXPERIMENT_ON = 'adj_grace_period_experiment_on';
export const ADJ_BILLING_RESCHEDULE_REASON = 'adj_billing_reschedule_reason';
export const ADJ_EDIT_PET_LOWEST_CALORIE_BUCKET =
  'adj_edit_pet_lowest_calorie_bucket';
export const ADJ_EDIT_PET_UPDATE_CONTACT_CX_MODAL =
  'adj_edit_pet_update_contact_cx_modal';
export const ADJ_MULTIDOG_SUBSCRIPTION_LINK_COPY =
  'adj_multidog_subscription_link_copy';
export const ADJ_REMOVE_MANAGE_BUTTON_FOR_FAILED_ORDERS =
  'adj_remove_manage_button_for_failed_orders';
export const ACQ_REFERRALS_GIFT_A_BOX = 'acq_referrals_gift_a_box';
export const ADJ_TMF_RESCHEDULE_UPDATE_PLAN = 'adj_tmf_reschedule_update_plan';

/**
 * Collection of expected split features that should be called with a user id
 * from segment.
 */
export const userFeatures: Features = {
  customer_cx_phone_number: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: JSON.stringify({
        footer: {
          email: true,
          phone: true,
        },
        faq: {
          email: true,
          phone: true,
        },
        diySupport: {
          phone: true,
          email: true,
        },
      }),
    },
  },
  cvr_breed_dropdown_improvements: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  show_holiday_announcement: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        headline: 'Order Scheduled for Holiday Week',
        underline:
          "We noticed your next order is scheduled during a holiday week. If you'll be traveling, check out our ${faq} to make sure you know how to change your address or ${reschedule} your order before ${rescheduleCutoff}.",
        endDate: '2020-12-22',
        cutoffStart: '2020-11-23',
        cutoffEnd: '2021-01-02',
      }),
    },
  },
  express_pay_signup: {
    track: false,
    treatments: ['on', 'off'],
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  frequencies_at_checkout: {
    treatments: ['trans_box', '28_cap', 'control'],
    track: true,
    mock: {
      treatment: 'trans_box',
      config: null,
    },
  },
  chicken_subscriber_web: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  chicken_funnel_v1: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  cancel_inflight_warning: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  recipe_names: {
    treatments: ['off', 'vegetables', 'ingredients'],
    track: true,
    mock: {
      treatment: 'ingredients',
      config: JSON.stringify({
        TURKEY: 'parsnip',
        BEEF: 'lentil',
        PORK: 'cauliflower',
        CHICKEN_AND_GREENS: 'brussel sprouts',
      }),
    },
  },
  ret_holidayfaqs_toggle: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  remove_password_signup: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        couponCode: '',
        couponType: '',
      }),
    },
  },
  cvr_quicker_trials: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },

  [CVR_RECIPE_PAGE_PACK_ORDER]: {
    treatments: ['off', 'chicken_first'],
    track: true,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        recipeOrder: ['PORK', 'CHICKEN_CHIA', 'TURKEY', 'BEEF'],
      }),
    },
  },
  [TSX_SNAP_STICKS_SOLD_OUT]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: JSON.stringify({}),
    },
  },
  [CVR_MIXING_PLAN_EXPERIMENT]: {
    treatments: [
      'off',
      'control',
      'treatmentControl',
      'treatmentA',
      'treatmentB',
      'treatmentC',
    ],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_POST_CHECKOUT_REFERRAL_PERCENTAGE]: {
    treatments: [
      'off',
      'control',
      '20_percent_off',
      '60_percent_off',
      '100_percent_off',
    ],
    track: true,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        couponCode: 'referral60',
      }),
    },
  },
  [BEEF_MLENTILS_ENABLED]: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [BEEF_MLENTILS_UI_ROLLBACK_ENABLED]: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_PRICE_VALUE_MODAL]: {
    treatments: Object.values(PriceValueModalTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_DYNAMIC_CALCULATING_SCREEN]: {
    treatments: Object.values(DynamicCalculatingScreenTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_GRAINS_QUESTION]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_CHECKOUT_PLAN_PRICE]: {
    treatments: Object.values(CheckoutPlanPriceClarityTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SMS_OPTIN_COPY]: {
    treatments: Object.values(CvrSmsOptInTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_WEIGHTLOSS_PERSONALIZATION]: {
    treatments: Object.values(CvrWeightlossPersonalizationTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SURPRISE_HIGHER_DISCOUNT]: {
    treatments: Object.values(CvrSurpriseHigherDiscountTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        code: 'surprisediscount100cxvso',
      }),
    },
  },
  [CVR_TREATS_IN_CHECKOUT_UI]: {
    treatments: Object.values(CvrTreatsInCheckoutUiTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_PRIOR_FOOD_TYPE_PROGRESSION]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [TSX_GLADLY_LIVE_CHAT]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [TSX_TREATS_PDP_PRICE_TEST]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [SSP_TREATS_LARGE_BAG_DISCOUNT]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [TSX_SNAP_STICKS_HIDE]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: JSON.stringify({}),
    },
  },
  [TSX_TREATS_RECOMMEND_1_BAG]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ONB_USE_PAD_AT_CHECKOUT]: {
    treatments: Object.values(ToggleTreatments),
    track: true,
    mock: {
      treatment: ToggleTreatments.off,
      config: null,
    },
  },
  [SSP_SCHEDULE_CARD]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [SSP_CALORIE_CHANGE_WEEKLY_PRICE_TO_DAILY_PRICE]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [SSP_CALIBRATION_FLOW_DETAILED_PRICES]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [SSP_CALIBRATION_OPTION_FIX]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_REDIRECT_ADJUST_CALORIES_TO_CX]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [HEALTH_CUSTOMER_CLINIC_SEARCH]: {
    treatments: Object.values(CustomerClinicSearchTreatments),
    track: true,
    mock: {
      treatment: CustomerClinicSearchTreatments.on,
      config: null,
    },
  },
  [ADJ_ADD_DOG_FORM]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_CX_CHAT_AB_TESTING]: {
    treatments: ['default', 'sidekickdemo'],
    track: true,
    mock: {
      treatment: 'default',
      config: JSON.stringify({
        appId: 'thefarmersdog.com',
      }),
    },
  },
  [ONB_HOMEPAGE_PERSONALIZATION_PICKY]: {
    treatments: Object.values(OnbHomepagePersonalizationPickyTreatments),
    track: true,
    mock: {
      treatment: OnbHomepagePersonalizationPickyTreatments.Off,
      config: null,
    },
  },
  [ONB_HOMEPAGE_PLAN_PROGRESSION]: {
    treatments: Object.values(ToggleTreatments),
    track: true,
    mock: {
      treatment: ToggleTreatments.off,
      config: null,
    },
  },
  [ONB_NO_PAST_DELIVERY_WINDOW]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_EDIT_PET_UI_IMPROVEMENTS]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_PRE_GRACE_PERIOD]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [ADJ_BILLING_RESCHEDULE_REASON]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [SSP_PRE_TAX_ON_ORDERS_TOTAL]: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_EDIT_PET_LOWEST_CALORIE_BUCKET]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [ADJ_EDIT_PET_UPDATE_CONTACT_CX_MODAL]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [ADJ_MULTIDOG_SUBSCRIPTION_LINK_COPY]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [SSP_FREE_FIRST_TREAT_ENABLED]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_TMF_RESCHEDULE_UPDATE_PLAN]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
};

/**
 * Collection of expected split features that should be called with an anonymous
 * id from segment.
 */
export const anonymousFeatures: Features = {
  cvr_citytakeover_redirects: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  cvr_condensed_press_bar: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  cvr_fresh_food_confidence_education: {
    treatments: Object.values(FreshFoodConfidenceTreatmentEnum),
    track: true,
    mock: {
      treatment: FreshFoodConfidenceTreatmentEnum.off,
      config: null,
    },
  },
  prospective_cx_phone_number: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: JSON.stringify({
        footer: {
          phone: true,
          email: true,
        },
        diySupport: {
          phone: false,
          email: true,
        },
      }),
    },
  },
  [CVR_GLOBALDISCOUNT_OVERRIDE]: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: `{ "discountPercentage": 100, "bannerCopy": "Surprise! We're offering a 100% off just today!" }`,
    },
  },
  [CX_ALERT_BANNER]: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: JSON.stringify({
        bannerCopy: 'We are experiencing high volume etc etc',
        link: '/faq',
      }),
    },
  },
  [CVR_TOSA_HIGH_TRAFFIC_MODE]: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_DAISY_MODE_ENABLED]: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_ANONYMOUS_ID_PERFORMANCE_LOGS]: {
    treatments: ['off', 'on'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_DEFAULT_DISCOUNT]: {
    treatments: Object.values(DefaultDiscountTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SIGNUP_FLOW_DOWN]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_DISABLE_REFERRER_LOOKUP]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_CTA_ACCESSIBILITY_HOMEPAGE]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SKIP_GET_STARTED]: {
    treatments: Object.values(SkipGetStartedTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_LINK_HOMEPAGE_HEADLINE]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_MOVE_NAV_LOGO_DOWN]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_HOMEPAGE_TERMS_CHANGES_BANNER]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_HOMEPAGE_CTA_HEADER_COPY]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_ZIP_EMAIL_COPY]: {
    treatments: Object.values(CvrZipEmailCopyTreatments),
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SIGNUP_SPACING]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [CVR_SIGNUP_ICON_UPDATE]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [INT_OPS_MEO_ENABLE_GENERATE_LABEL]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_BILLING_TIMELINE_SERVICE_ENABLE]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [ADJ_MULTIPLE_PAYMENT_METHODS]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [TERMS_CHANGES_BANNER]: {
    treatments: ['off', 'on'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [ADJ_GRACE_PERIOD_EXPERIMENT_ON]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [HEALTH_HOMEPAGE_CUSTOMER_VET_SECTION]: {
    treatments: Object.values(CustomerVetSectionTreatments),
    track: true,
    mock: {
      treatment: CustomerVetSectionTreatments.checkmarks,
      config: null,
    },
  },
  [ADJ_REMOVE_MANAGE_BUTTON_FOR_FAILED_ORDERS]: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [ACQ_REFERRALS_GIFT_A_BOX]: {
    treatments: Object.values(ReferralsGiftABoxVariant),
    track: true,
    mock: {
      treatment: ReferralsGiftABoxVariant.off,
      config: null,
    },
  },
};

/**
 * Convert a feature config into an object that can be
 * used by the split factory for local testing.
 *
 * @param features - Array of features to get mock treatment data from
 * @returns
 */
function getMockedFeatureMap(features: Features): SplitIO.MockedFeaturesMap {
  const asLocal: SplitIO.MockedFeaturesMap = {};

  Object.entries(features).forEach(([key, feature]) => {
    asLocal[key] = feature.mock;
  });

  return asLocal;
}

/**
 * Converted features for local testing
 */
export const mockedFeatures: SplitIO.MockedFeaturesMap = {
  ...getMockedFeatureMap(userFeatures),
  ...getMockedFeatureMap(anonymousFeatures),
};
