import { useEffect, useState } from 'react';

import { useIntersection } from '@farmersdog/corgi-x';

export interface UseOnEnterViewArgs {
  /** The callback function to run when the user has intersected with the element */
  onEnter: () => void;
  /** Ref to track */
  ref: React.MutableRefObject<HTMLElement | null>;
  /** Optional condition to check before calling the onEnter callback */
  shouldTrack?: boolean;
}

/**
 * A custom hook that runs a callback when a user views an HTML element
 * @returns a boolean indicating whether or not the onEnter callback function has fired
 */
export function useOnEnterView({
  onEnter,
  ref,
  shouldTrack = true,
}: UseOnEnterViewArgs) {
  const [hasEntered, setHasEntered] = useState(false);

  const intersection = useIntersection(ref.current);

  useEffect(() => {
    if (hasEntered || !intersection?.isIntersecting || !shouldTrack) {
      return;
    }

    setHasEntered(true);
    onEnter();
  }, [intersection?.isIntersecting, hasEntered, shouldTrack, onEnter]);

  return hasEntered;
}
