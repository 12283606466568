/**
 * These are expected to match with the `name` attribute from the bodyConditions
 * query. The is used to find the id associated with a condition.
 */
export enum BodyConditionName {
  Rounded = 'Rounded',
  Chunky = 'Chunky',
  JustRight = 'Just Right',
  TooSkinny = 'Too Skinny',
}
