import { NodeNames } from '@farmersdog/constants';

import {
  getNodeNameAndPosition,
  handlePluralPlaceholders,
} from '../../blueprint/utils';
import { TOSAComponentInput, TOSALeafNode } from '../../types';

import { DropdownInput, Item } from './base';

export function BirthdayUnitInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const { getValues } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);
  const birthdayAmountField =
    `${NodeNames.BirthdayAmount}-${position}` as const;
  const petAge = getValues(birthdayAmountField);

  // default plurality to true if input is nullish/empty
  const isPlural = petAge ? petAge > 1 : true;

  const itemToString = (option: Item | null) => {
    if (!option?.name) {
      return '';
    }

    return handlePluralPlaceholders({
      input: option.name,
      isPlural,
    });
  };

  const handleChange: TOSAComponentInput<TOSALeafNode>['onChange'] = () => {
    void formMethods.trigger(birthdayAmountField);
  };

  return (
    <DropdownInput
      {...props}
      itemToString={itemToString}
      onChange={handleChange}
    />
  );
}
