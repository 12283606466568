import { GraphQLFormattedError } from 'graphql';
import { Maybe } from '../../graphql/types.cgs';
import * as errorCodes from '../errorCodes';

type CorrectedAddress = {
  addressLine1: string;
  addressLine2: Maybe<string>;
  city: string;
  zip: string;
  stateAbbreviation: string;
};

export type ShippingAddressCorrections = {
  [P in keyof CorrectedAddress]?: NonNullable<CorrectedAddress[P]>;
};

const NON_SHIPPO_ZIPCODE_ERRORS = [
  errorCodes.ADDRESS_NONEXISTENT_ZIPCODE_ERROR,
  errorCodes.ADDRESS_INVALID_ZIP_ERROR,
];

export interface AddressError extends GraphQLFormattedError {
  extensions: {
    code: string;
    corrections: ShippingAddressCorrections;
    compositeKey: string;
  };
}

/**
 * This function determines if the GraphQL error matches the shape of a Shippo
 * error, or includes a non-Shippo but address-related error code.
 * @param error - an error returned by GraphQL
 * @returns boolean
 */
export function isAddressError(
  error: GraphQLFormattedError
): error is AddressError {
  return (
    Boolean(
      error.extensions?.code &&
        error.extensions?.corrections &&
        error.extensions?.compositeKey
    ) ||
    Boolean(
      typeof error.extensions?.code === 'string' &&
        NON_SHIPPO_ZIPCODE_ERRORS.includes(error.extensions.code)
    )
  );
}
