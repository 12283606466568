import { InMemoryCacheConfig } from '@apollo/client';

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    // example: to cache by field "id":
    // MyUserView: { keyFields: ['id'] },
    //
    // example: to cache by subfield "id" on field "node"
    // MyOrderViewEdge: { keyFields: ['node', ['id']] },
    //
    // example: to disable caching
    // PlanRecipe: { keyFields: false },
  },
};
