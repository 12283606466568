import { Fragment } from 'react';

import { LayoutType } from '../../blueprint/types';

export function getLayoutElement(layout?: LayoutType) {
  switch (layout) {
    case LayoutType.block: {
      return 'div';
    }

    case LayoutType.inline: {
      return 'span';
    }

    default:
    case LayoutType.none: {
      return Fragment;
    }
  }
}
