/*
  External links used within the app that we may want to test they are not dead
  in the future
*/

export const LINK_DIGEST = `https://www.thefarmersdog.com/digest`;
export const LINK_DIGEST_BENEFITS = `${LINK_DIGEST}/6-benefits-of-feeding-human-grade-dog-food`;
export const LINK_DIGEST_CARROTS = `${LINK_DIGEST}/superfoods-kitchen-carrots-dogs`;
export const LINK_DIGEST_DENTAL = `${LINK_DIGEST}/dog-service-announcement-no-kibble-isnt-good-for-cleaning-your-dogs-teeth`;
export const LINK_DIGEST_LABEL_TRICKS = `${LINK_DIGEST}/dog-food-label-tricks-every-owner-needs-know`;
export const LINK_DIGEST_OIL = `${LINK_DIGEST}/olive-oil-for-dogs`;
export const LINK_DIGEST_OVERWEIGHT = `${LINK_DIGEST}/obesity-in-dogs-a-massive-health-threat-thats-hiding-in-plain-sight`;
export const LINK_DIGEST_RECIPES_HOME = `${LINK_DIGEST}/cook-dog-run-food`;
export const LINK_DIGEST_SNACKS = `${LINK_DIGEST}/top-14-dog-friendly-snacks`;
export const LINK_DIGEST_RECIPES_INGREDIENTS = `${LINK_DIGEST}/farmers-dog-ingredients-recipes/`;
export const LINK_DIGEST_FOOD_FOR_PUPPIES = `${LINK_DIGEST}/digest/why-the-farmers-dog-food-is-great-for-puppies/`;
export const LINK_DIGEST_COST_AND_VALUE = `${LINK_DIGEST}/the-farmers-dog-cost-and-value/`;
export const LINK_DIGEST_VET_PORTAL = `${LINK_DIGEST}/vet-portal`;

export const LINK_AFFILIATES = 'https://affiliates.thefarmersdog.com';
export const LINK_FB = 'http://www.facebook.com/thefarmersdog';
export const LINK_IG = 'http://instagram.com/thefarmersdog';
export const LINK_NUTRIENT_PACKS = `https://thefarmersdog.typeform.com/to/yrK1vN`;
export const LINK_TODAY_COM_STUDIES = `https://www.today.com/style/dog-cat-food-products-are-mislabeled-study-finds-1D80249283`;
export const LINK_PARTNERSHIP_INQUIRIES = `https://thefarmersdog.typeform.com/workwithus`;
