import { useEffect, useRef } from 'react';

interface useIntervalArgs {
  durationSeconds: number;
  onTimeLapsed: () => void;
}
export const useInterval = ({
  durationSeconds,
  onTimeLapsed,
}: useIntervalArgs) => {
  const intervalRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      onTimeLapsed();
    }, durationSeconds * 1000);
    return () => clearInterval(intervalRef.current);
  });

  const reset = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      onTimeLapsed();
    }, durationSeconds * 1000);
  };
  return { reset };
};
