import { local } from '@farmersdog/utils';

export const PET_MIXING_PLAN_STATE_KEY = 'petMixingPlanRatios';

export type PetMixingPlanRatios = {
  [key: string]: number;
};

function isValidPetMixingPlanRatios(obj: unknown): obj is PetMixingPlanRatios {
  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj as Record<string, unknown>).every(
      value => typeof value === 'number'
    );
  }
  return false;
}

function get(): PetMixingPlanRatios | null {
  const localStorageValue = local.getItem(PET_MIXING_PLAN_STATE_KEY);

  if (localStorageValue === null) {
    return null;
  }

  const storedValue = JSON.parse(localStorageValue) as unknown;

  if (isValidPetMixingPlanRatios(storedValue)) {
    return storedValue;
  }

  return null;
}

function getIndividualPetRatio(petName: string): number {
  const ratios = get() ?? {};

  return ratios[petName] ?? 1;
}

/* sets the plan ratio for a single petName at a time */
function setIndividualPetRatio(petName: string, ratio: number): void {
  const ratios = get() ?? {};

  ratios[petName] = ratio;

  local.setItem(PET_MIXING_PLAN_STATE_KEY, JSON.stringify(ratios));
}

/* resets all pet ratios to 1 (full plan) */
function resetAllPetRatios(): void {
  const ratios = get();

  if (ratios === null) {
    return;
  }

  Object.keys(ratios).forEach(petName => {
    ratios[petName] = 1; // full plan ratio
  });

  local.setItem(PET_MIXING_PLAN_STATE_KEY, JSON.stringify(ratios));
}

function removeAll(): void {
  local.removeItem(PET_MIXING_PLAN_STATE_KEY);
}

export const petMixingPlanRatiosState = {
  get,
  getIndividualPetRatio,
  setIndividualPetRatio,
  resetAllPetRatios,
  removeAll,
};
