/* eslint-disable global-require */
const INSTAGRAM_DATA = [
  {
    url: 'https://www.instagram.com/p/BwciBvonxpI/',
    screenName: 'woof.im.otis',
    quote:
      '🔊Made dad film and edit my morning @thefarmersdog. Now watch me eat! 🔊',
    image: {
      sources: [require('src/assets/images/instagrams/woof.im.otis.gif')],
    },
  },
  {
    url: 'https://www.instagram.com/p/BgeZstah2vG/',
    screenName: 'ottozurich',
    quote:
      'Are you ready for it??? #imafarmersdog Otto is tired of looking cute and just wants to eat!!! Finally found a food he actually wants and tolerates!!! 🙌🙌🙌🙌 @thefarmersdog',
    image: {
      sources: [
        require('src/assets/images/instagrams/ottozurich.webp'),
        require('src/assets/images/instagrams/ottozurich.jp2'),
        require('src/assets/images/instagrams/ottozurich.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BfrTkB2l3F1/',
    screenName: 'pawsofsimba',
    quote:
      'It’s hard work supervising in the kitchen but someone has to do it! Can’t wait to dig into my @thefarmersdog meal 🍲 #imafarmersdog #realfood',
    image: {
      sources: [require('src/assets/images/instagrams/pawsofsimba.gif')],
    },
  },
  {
    url: 'https://www.instagram.com/p/Bk85i-CgJiQ/',
    screenName: 'zakkiya.miller',
    quote:
      'Anikan loved The Farmers Dog on first bite and I quickly fell in love with this healthy, easy and convenient brand.',
    image: {
      sources: [
        require('src/assets/images/instagrams/zakkiya.miller.webp'),
        require('src/assets/images/instagrams/zakkiya.miller.jp2'),
        require('src/assets/images/instagrams/zakkiya.miller.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BmRCRDaDgcw/',
    screenName: 'barkley_theboxer',
    quote:
      'I’m so excited to try @thefarmersdog ... now I can have real human-grade food! Thanks mom and dad for caring so much about my health! 🍖 #imafarmersdog',
    image: {
      sources: [
        require('src/assets/images/instagrams/barkley_theboxer.webp'),
        require('src/assets/images/instagrams/barkley_theboxer.jp2'),
        require('src/assets/images/instagrams/barkley_theboxer.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BseBXhUgCdl/',
    screenName: 'schurmansa',
    quote:
      'Best day of the month for these two!! Their food is real, fresh, healthy, & delivered right to our door...what’s not to love? ❤️🐶🐾',
    image: {
      sources: [
        require('src/assets/images/instagrams/schurmansa.webp'),
        require('src/assets/images/instagrams/schurmansa.jp2'),
        require('src/assets/images/instagrams/schurmansa.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/Bi5QLAThAok/',
    screenName: 'fit_kels',
    quote:
      "Flynn devoured his @thefarmersdog dinner!! He usually nibbles at his meals for hours before finishing it. I'd say this was a success!\n#iamafarmersdog",
    image: {
      sources: [
        require('src/assets/images/instagrams/fit_kels.webp'),
        require('src/assets/images/instagrams/fit_kels.jp2'),
        require('src/assets/images/instagrams/fit_kels.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BlEFUJglXv6/',
    screenName: 'chubbypubby',
    quote:
      'Pubby is SOOO picky and his stomach is a little sensitive. We made the switch to a fresh food diet...Pubby loves it and we do too! We’re all happy now. #imafarmersdog 👩🏻‍🌾🐶',
    image: {
      sources: [
        require('src/assets/images/instagrams/chubbypubby.webp'),
        require('src/assets/images/instagrams/chubbypubby.jp2'),
        require('src/assets/images/instagrams/chubbypubby.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BofW8a4HNeu/',
    screenName: 'kaitlinvilasuso',
    quote:
      '😜This right here is the face of a now healthy, happy, 10+ pounds lighter Maddy thanks to @thefarmersdog .. 🙌🏻',
    image: {
      sources: [
        require('src/assets/images/instagrams/kaitlinvilasuso.webp'),
        require('src/assets/images/instagrams/kaitlinvilasuso.jp2'),
        require('src/assets/images/instagrams/kaitlinvilasuso.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BRfSOuuB0e6/',
    screenName: 'picathepom',
    quote: 'GIVE IT TO ME! GIVE IT TO ME! #imafarmersdog',
    image: {
      sources: [require('src/assets/images/instagrams/picathepom.gif')],
    },
  },
  {
    url: 'https://www.instagram.com/p/BvJ3hSPgucf/',
    screenName: 'wearerockyandonyx',
    quote:
      '3 weeks into my @thefarmersdog fresh food and I am doing so great!! I have tons of energy, my fur is shiny and soft, and I’m maintaining my physique! It’s SUPER yummy!! 👅👅',
    image: {
      sources: [
        require('src/assets/images/instagrams/wearerockyandonyx.webp'),
        require('src/assets/images/instagrams/wearerockyandonyx.jp2'),
        require('src/assets/images/instagrams/wearerockyandonyx.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BlsvmEgBSaB/',
    screenName: 'smalldogsadie',
    quote:
      'Hi my name is Sadie, and I’m the worlds PICKIEST DOG. But today I got to try @thefarmersdog and completely emptied my bowl ;)',
    image: {
      sources: [
        require('src/assets/images/instagrams/smalldogsadie.webp'),
        require('src/assets/images/instagrams/smalldogsadie.jp2'),
        require('src/assets/images/instagrams/smalldogsadie.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BjcsRpNjr48/',
    screenName: 'safarimou',
    quote:
      'Do you guys have the same excitement as I do going to sleep thinking about what you’re going to eat in the morning?! Thank you @thefarmersdog for this delicious recipe!!!',
    image: {
      sources: [
        require('src/assets/images/instagrams/safarimou.webp'),
        require('src/assets/images/instagrams/safarimou.jp2'),
        require('src/assets/images/instagrams/safarimou.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/Bi7ggdDD7n1/',
    screenName: 'winston_and_heidi',
    quote:
      'More please!!! Nom nom nom 😋 why is it so long between meal times?? #imafarmersdog',
    image: {
      sources: [
        require('src/assets/images/instagrams/winston_and_heidi.webp'),
        require('src/assets/images/instagrams/winston_and_heidi.jp2'),
        require('src/assets/images/instagrams/winston_and_heidi.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BgtVRUDFhIA/',
    screenName: 'twodapperdoods',
    quote:
      'We used to beg our parents for their food, now they beg us for ours 🙏🏻 🥘',
    image: {
      sources: [
        require('src/assets/images/instagrams/twodapperdoods.webp'),
        require('src/assets/images/instagrams/twodapperdoods.jp2'),
        require('src/assets/images/instagrams/twodapperdoods.jpg'),
      ],
    },
  },
  {
    url: 'https://www.instagram.com/p/BmTWAWKHzx2/',
    screenName: 'lilyxlafleur',
    image: {
      sources: [
        require('src/assets/images/instagrams/lilyxlafleur.webp'),
        require('src/assets/images/instagrams/lilyxlafleur.jp2'),
        require('src/assets/images/instagrams/lilyxlafleur.jpg'),
      ],
    },
    quote:
      'I actually get excited at meal times now 😛 Can’t believe my human made me eat crap for 7 years 😣',
  },
];
/* eslint-disable global-require */

export default INSTAGRAM_DATA;
