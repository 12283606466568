import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ErrorPage } from './features';

interface ErrorBoundaryProps extends RouteComponentProps {
  error?: Error;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: undefined,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: props.error };
    this.unlisten = this.unlisten.bind(this);
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({
          error: undefined,
        });
      }
    });
  }

  unlisten() {
    // overwritten when component mounts;
  }

  componentWillUnmount() {
    this.unlisten();
  }

  public render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
