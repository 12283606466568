import { useLocation } from 'react-router-dom';

import {
  PATH_REVIEWS,
  PATH_ABOUT_US,
  PATH_FAQ,
  PATH_LOGIN,
  PATH_WHY_FRESH,
  PATH_DIY,
} from '@farmersdog/constants/paths';

export function useNavigationItems() {
  const location = useLocation();

  const leftItems = [
    {
      title: 'Reviews',
      to: PATH_REVIEWS,
    },
    {
      title: 'About Us',
      to: PATH_ABOUT_US,
    },
    {
      title: 'Why Fresh?',
      to: PATH_WHY_FRESH,
    },
    {
      title: 'FAQ',
      to: location.pathname === PATH_DIY ? `#diy-faq` : PATH_FAQ,
      // overrides react-router-dom NavLink default active class to consider complete match of path + hash as active
      isActive: () =>
        location.pathname === PATH_DIY && location.hash === `#diy-faq`,
    },
  ];

  return {
    leftItems,
    persistentItem: {
      title: 'Log In',
      to: PATH_LOGIN,
    },
  };
}
