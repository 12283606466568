import { useEffect, useState } from 'react';
import {
  anonymousId,
  SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY,
} from '@farmersdog/lead-browser-storage';
import { local } from '@farmersdog/utils';

import { log } from 'src/abTesting';
import { useAnonymousIdPerformanceLogs } from '../useAnonymousIdPerformanceLogs';

export function useObserveAnonymousIdStorage() {
  const { isLoggingEnabled } = useAnonymousIdPerformanceLogs();
  const [didLog, setDidLog] = useState(false);
  const cookieAnonymousId = anonymousId.get();
  const localAnonymousId = local.getItem(
    SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY
  );

  useEffect(() => {
    if (
      !isLoggingEnabled ||
      !cookieAnonymousId ||
      !localAnonymousId ||
      didLog
    ) {
      return;
    }

    const sanitizedCookieAnonymousId = stripQuotes(cookieAnonymousId);
    const sanitizedLocalAnonymousId = stripQuotes(localAnonymousId);

    if (sanitizedCookieAnonymousId !== sanitizedLocalAnonymousId) {
      log.warn('Mismatched cookie and local storage anonymous split ids', {
        localAnonymousId: sanitizedLocalAnonymousId,
        cookieAnonymousId: sanitizedCookieAnonymousId,
      });

      setDidLog(true);
    }
  }, [isLoggingEnabled, cookieAnonymousId, localAnonymousId, didLog]);
}

function stripQuotes(value: string): string {
  return value.replace(/^"(.*)"$/, '$1');
}
