import get from 'lodash/get';
import getProperties, { Properties } from './get-properties';

import type { Path, PathValue } from 'convict';

type PathParam = Path<Properties>;

type GetReturn<K extends PathParam> = PathValue<Properties, K>;

/**
 * @returns an object with get method
 * `get` returns a config value at properties.path cast to its respective type.
 */
export default {
  get<K extends PathParam>(path: K): GetReturn<K> {
    const properties = getProperties();
    return get(properties, path) as GetReturn<K>;
  },
};
