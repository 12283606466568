//This function is used to scroll to a specific element only once during the user's session.
//It uses the sessionStorage to store the scroll state and checks if the user has scrolled to the element before.
//If not, it scrolls to the element using the scrollIntoView method or scrolls to the bottom of the window.

interface ScrollOncePerSessionProps {
  // The ref of the element to scroll to, if not provided, it will scroll to the bottom of the window
  ref?: React.RefObject<HTMLElement>;
  id: string;
}

export const scrollOncePerSession = ({
  ref,
  id,
}: ScrollOncePerSessionProps) => {
  const sessionId = `hasScrolled-${id}`;

  const hasScrolled = sessionStorage.getItem(sessionId);

  if (hasScrolled !== 'true') {
    requestAnimationFrame(() => {
      if (ref?.current) {
        ref.current.scrollIntoView({ block: 'start' });
      } else {
        window.scrollTo({
          top: document.body.scrollHeight,
        });
      }
    });
    sessionStorage.setItem(sessionId, 'true');
  }
};
