import { getIsOnDoNotSellShareList } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';

import getUserId from '../getUserId';

export const DO_NOT_SELL_SHARE_INTEGRATIONS = {
  All: false,
  FullStory: true,
  Webhooks: true, // Simon
} as const;

export interface SegmentLoggingProperties {
  event?: string;
  eventType: 'track' | 'identify' | 'page';
}

export function getSegmentIntegrations({
  event,
  eventType,
}: SegmentLoggingProperties) {
  const logger = new Logger('analytics:getSegmentIntegrations');

  if (getIsOnDoNotSellShareList()) {
    logger.warn(
      'Blocking segment event because user is on do not sell share list',
      { event, eventType, userId: getUserId() }
    );
    return DO_NOT_SELL_SHARE_INTEGRATIONS;
  }

  return undefined;
}
