import { DropdownArrow } from '../../base/DropdownArrow';
import styles from '../SelectComboBox.module.css';

import type { BreedType } from '../../../../types';
import type { UseComboboxPropGetters } from 'downshift';

interface ToggleButtonProps {
  isOpen: boolean;
  getToggleButtonProps: () => ReturnType<
    UseComboboxPropGetters<BreedType>['getToggleButtonProps']
  >;
  getInputProps: UseComboboxPropGetters<BreedType>['getInputProps'];
}

export function ToggleButton({
  isOpen,
  getToggleButtonProps,
  getInputProps,
}: ToggleButtonProps) {
  const inputProps = getInputProps();

  const ariaLabelledBy = {
    ['aria-labelledby']: inputProps['aria-labelledby'],
  };

  return (
    <button
      {...getToggleButtonProps()}
      {...ariaLabelledBy}
      className={styles['Select-toggle']}
    >
      <DropdownArrow isOpen={isOpen} />
    </button>
  );
}
