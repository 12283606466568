import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { amazonTagPageViewRoutes } from './constants';
import { mountAmazonTagPageView } from './mountAmazonTagPageView';

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for an amazon tag page view event.
 */
export interface UseMountAmazonTagPageViewArgs {
  isFirstPetsPage: boolean;
}

export function useMountAmazonTagPageView({
  isFirstPetsPage,
}: UseMountAmazonTagPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (amazonTagPageViewRoutes.includes(pathname) || isFirstPetsPage) {
      mountAmazonTagPageView();
    }
  }, [pathname, isFirstPetsPage]);
}
