import { useState } from 'react';

export type ReloadApp = () => void;

export type UseReloadAppReturn = [appKey: number, reloadApp: ReloadApp];

export function useReloadApp(): UseReloadAppReturn {
  const [appKey, setAppKey] = useState<number>(0);

  const reloadApp = () => {
    setAppKey(currentKey => currentKey + 1);
  };

  return [appKey, reloadApp];
}
