export const eventNames = {
  homepage_brand_love_video_played: 'Homepage - Video played',
  homepage_brand_love_video_paused: 'Homepage - Video paused',
  homepage_brand_love_video_ended: 'Homepage - Video ended',
  homepage_orders_view_order_link_clicked:
    'homepage - orders section - view order link clicked',
  homepage_orders_edit_order_link_clicked:
    'homepage - orders section - edit order link clicked',
  homepage_essentials_link_clicked:
    'homepage - essentials section - link clicked',
  homepage_orders_reschedule_link_clicked:
    'homepage - orders section - reschedule link clicked',
  homepage_orders_track_order_link_clicked:
    'homepage - orders section - track order link clicked',
  homepage_picky_eater_section_clicked:
    'homepage - picky eater section clicked',
  treats_nudge_displayed: 'Treats Nudge — Displayed',
};
