import { FeatureName, ToggleTreatments } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface useSignupUpdatedAssetsProps {
  useFeature: UseFeatureHook;
}

export function useSignupUpdatedAssets({
  useFeature,
}: useSignupUpdatedAssetsProps) {
  const { treatment } = useFeature(FeatureName.CVR_SIGNUP_ICON_UPDATE);

  const isSignupUpdatedAssetsOn = treatment === ToggleTreatments.on;

  return {
    isSignupUpdatedAssetsOn,
  };
}
