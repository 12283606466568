import {
  CancellationResponsesEnum,
  CancellationResponsesEnumType,
} from './cancellationResponses';

export type ReplacementFoodType = 'ReplacementFood';

export const REPLACEMENT_FOOD: ReplacementFoodType = 'ReplacementFood';

/**
 * A "replacementFoodState" is an intermediate state with no accompanying
 * questions, responses, or UI. Its only purpose is to redirect the
 * user to the "What food will you be replacing TFD with" state.
 *
 * It is associated with a cancellation reason (i.e. the option the
 * user just selected).
 */

const replacementFoodStates: CancellationResponsesEnumType[] = [
  CancellationResponsesEnum.NeedFishRecipe,
  CancellationResponsesEnum.NeedLowerFatRecipe,
  CancellationResponsesEnum.NeedOtherRecipe,
  CancellationResponsesEnum.NeedVegetarianRecipe,
  CancellationResponsesEnum.NeedRecipeWithGrain,
  CancellationResponsesEnum.NeedLifeStageSpecificFood,
  CancellationResponsesEnum.VetRecommendedDifferentFoodNonPrescription,
  CancellationResponsesEnum.SubscriptionServicesDontWorkForMe,
  CancellationResponsesEnum.UnawareItWasASubscription,
  CancellationResponsesEnum.ItemsDamagedIncorrect,
  CancellationResponsesEnum.Diabetes,
  CancellationResponsesEnum.HydrolizedProtein,
  CancellationResponsesEnum.JointSupport,
  CancellationResponsesEnum.LiverSupport,
  CancellationResponsesEnum.LowFat,
  CancellationResponsesEnum.NeoplasiaDiet,
  CancellationResponsesEnum.RenalDisease,
  CancellationResponsesEnum.SkinSupport,
  CancellationResponsesEnum.WeightLoss,
  CancellationResponsesEnum.WeightManagement,
];

const replacementFoodTypeStates: CancellationResponsesEnumType[] = [
  CancellationResponsesEnum.DryFood,
  CancellationResponsesEnum.IllCookAtHome,
  CancellationResponsesEnum.OtherFreshFood,
  CancellationResponsesEnum.RawFood,
  CancellationResponsesEnum.WetFood,
  CancellationResponsesEnum.DehydratedFood,
  CancellationResponsesEnum.StillTheFarmersDog,
];

export const getReplacementFoodStates = () => replacementFoodStates;
export const getReplacementFoodTypeStates = () => replacementFoodTypeStates;
