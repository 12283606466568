/* CH#49502: Used by RET and CVR */
import { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Slider.scss';

/**
 * Render a set of slides that scrolls horizontally.
 *
 * ```jsx
 *  <Slider currentSlide="second">
 *    <Slider.Slide name="first">A first slide</Slider.Slide>
 *    <Slider.Slide name="second">A second slide (will be visible as default)</Slider.Slide>
 *  </Slider>
 * ```
 *
 * @param {import('./Slider.types').SliderProps} props - Slider props
 */
export default function Slider({
  children,
  currentSlide,
  fixedHeight,
  visibleSlides,
  animate,
  className,
  ...props
}) {
  const slides = Children.toArray(children);

  let slideIndex = currentSlide;
  if (typeof currentSlide === 'string') {
    slideIndex = slides.findIndex(slide => slide.props.name === currentSlide);
  }

  const slidesCount = slides.length;
  const slideWidth = 100 / visibleSlides / slidesCount;

  const style = {
    width: `${(100 / visibleSlides) * slidesCount}%`,
    transform: `translateX(${-slideIndex * slideWidth * visibleSlides}%)`,
  };

  return (
    <div className={classNames('Slider', { animate }, className)} {...props}>
      <div className="Slider-slides" style={style}>
        {slides.map((slide, i) => {
          const visible = i >= slideIndex && i < slideIndex + visibleSlides;
          let slideStyle = {
            visibility: !visible ? 'hidden' : 'visible',
            maxHeight: !fixedHeight && !visible ? 1 : 'none',
          };

          return (
            <div
              key={i}
              className={'Slider-slide'}
              data-slide-name={slide.props.name}
              style={slideStyle}
            >
              {slide}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Slider.propTypes = {
  /** Use `<Slider.Slide>` for each slide. */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Container height matches the heighest slide by default. Set to `false`
   * to match the height of the current slide. */
  fixedHeight: PropTypes.bool,
  /** The index or the name of the visible slide. */
  currentSlide: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** How many slide to display at a time */
  visibleSlides: PropTypes.number,
  /** Toggle horizontal animation */
  animate: PropTypes.bool,
  className: PropTypes.string,
};

Slider.defaultProps = {
  currentSlide: 0,
  fixedHeight: true,
  animate: true,
  visibleSlides: 1,
};

/**
 * Render a slide inside a slider.
 *
 * @deprecated - Just use any single html or react element
 * @param {HTMLAttributes<HTMLDivElement>} props - All the div props
 * @returns
 */
export function Slide({ children, ...props }) {
  return <div {...props}>{children}</div>;
}
Slide.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Slider.Slide = Slide;
