import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SLIDE_NAME as SEE_PRICING } from './SeePricing';
import { SLIDE_NAME as SEE_RECIPES } from './SeeRecipes';
import { SLIDE_NAME as MORE_INFO } from './MoreInfo';
import { SLIDE_NAME as MORE_TIME } from './MoreTime';
import { SLIDE_NAME as PET_INFO } from './PetInfo';
import { SLIDE_NAME as NEW_PROTEINS } from './NewProteins';
import { SLIDE_NAME as MAX_PRICE } from './MaxPrice';
import { SLIDE_NAME as SUBMIT } from './Submit';

import { RECIPES_DECISION, OTHER } from './useRootDecisions.reasons';

import {
  RECIPES_DECISION_LABEL,
  MAX_PRICE_LABEL,
  OTHER_LABEL,
  SEE_PRICING_LABEL,
  SEE_RECIPES_LABEL,
  MORE_INFO_LABEL,
  MORE_TIME_LABEL,
  PET_INFO_LABEL,
} from './copy';

import { selectAllowChangeMealSize } from 'src/reducers/signup/user';

function useRootDecisions({ current }) {
  const allowChangeMealSize = useSelector(selectAllowChangeMealSize);

  return useMemo(() => {
    let rootDecisions;

    if (current.recipes || current.checkout) {
      rootDecisions = [
        {
          label: RECIPES_DECISION_LABEL,
          value: RECIPES_DECISION,
          nextSlide: NEW_PROTEINS,
        },
        {
          label: MAX_PRICE_LABEL,
          value: MAX_PRICE,
          nextSlide: allowChangeMealSize ? MAX_PRICE : NEW_PROTEINS,
        },
        {
          label: OTHER_LABEL,
          value: OTHER,
          nextSlide: NEW_PROTEINS,
        },
      ];
    } else {
      const petInfo = current.pets;

      rootDecisions = [
        {
          label: SEE_PRICING_LABEL,
          value: SEE_PRICING,
          nextSlide: SEE_PRICING,
        },
        {
          label: SEE_RECIPES_LABEL,
          value: SEE_RECIPES,
          nextSlide: SEE_RECIPES,
        },
        {
          label: MORE_INFO_LABEL,
          value: MORE_INFO,
          nextSlide: MORE_INFO,
        },
        {
          label: MORE_TIME_LABEL,
          value: MORE_TIME,
          nextSlide: MORE_TIME,
        },
        petInfo
          ? {
              label: PET_INFO_LABEL,
              value: PET_INFO,
              nextSlide: PET_INFO,
            }
          : null,
        {
          label: OTHER_LABEL,
          value: OTHER,
          nextSlide: SUBMIT,
        },
      ].filter(slide => slide);
    }

    return {
      rootDecisions,
    };
  }, [current, allowChangeMealSize]);
}

export default useRootDecisions;
