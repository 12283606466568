import classNames from 'classnames';

import { Picture, Text } from '@farmersdog/corgi-x';

import styles from './GenericStories.module.css';
import { genericTestimonials } from './testimonials';

export function GenericStories() {
  return (
    <div className={styles.container}>
      {genericTestimonials.map(({ petName, source, description, alt }, i) => {
        return (
          <div
            key={petName}
            className={classNames(styles.caseContainer, {
              [styles.reverse]: i % 2 !== 0,
            })}
          >
            <Picture className={styles.image} sources={source} alt={alt} />
            <div className={styles.textsContainer}>
              <Text
                className={styles.description}
                variant="article-16"
                color="kale-3"
              >
                “{description}”
              </Text>
              <Text
                className={styles.description}
                variant="heading-16"
                color="kale-3"
                bold
              >
                — {petName}’s person
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
}
