import { selectPrescriptionDiets } from './selectors';
import { usePetConfiguration } from './usePetConfiguration';

/**
 * Fetch the prescription diet data that is required for modifying details about
 * a pet.
 */
export function usePrescriptionDiets() {
  const query = usePetConfiguration();

  return {
    ...query,
    prescriptionDiets: selectPrescriptionDiets(query.data),
  };
}
