import eventNames from '../eventNames';
import track from '../track';

export enum RequestedSupportDestinations {
  EditPet = 'Edit Pet',
  EditPetAdjustDailyCalories = 'Edit Pet - Adjust Daily Calories',
  MyOrdersOrderFailed = 'My Orders - Order Failed',
}

export enum RequestedSupportChannels {
  Email = 'email',
  Phone = 'phone',
  LiveChat = 'live-chat',
}

export interface RequestedSupportArgs {
  supportChannel: 'email' | 'phone' | 'live-chat';
  destination?: RequestedSupportDestinations;
}

export function trackRequestedSupport({
  supportChannel,
  destination,
}: RequestedSupportArgs) {
  track(eventNames.requested_support, { supportChannel, destination });
}
