import { getSessionProperties } from '@farmersdog/sessions';
import { ENGAGEMENT_TRACKER_VERSION } from '@farmersdog/utils';

import analytics from './analytics';
import { getSegmentOpts } from './helpers/getSegmentOpts';

function track<T extends Record<string, unknown>>(
  event: string,
  properties?: T,
  opts?: Record<string, unknown>,
  callbackIfMethodIsUndefined?: () => void
) {
  const sessionProperties = getSessionProperties(ENGAGEMENT_TRACKER_VERSION);

  const propertiesWithSessions = {
    ...properties,
    ...sessionProperties,
  };

  const optsWithOverrides = {
    ...getSegmentOpts({ eventType: 'track', event }),
    ...opts,
  };

  analytics(callbackIfMethodIsUndefined).track(
    event,
    propertiesWithSessions,
    optsWithOverrides
  );
}

export default track;
