import { radio } from './radio';

/** The attributes required for an Radio.com home page view event */
export const attributes = {
  ppt: '7767',
  g: 'web_visit',
  gid: '26798',
};

/**
 * Track a Radio.com home page view event.
 */
export function trackRadioHomePageView(): void {
  radio.track({
    ...attributes,
  });
}
