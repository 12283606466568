import { ApolloError, useQuery } from '@apollo/client';
import { ExperimentPickyCriteriaQuery } from './graphql/ExperimentPickyCriteriaQuery.cgs.generated-types';
import ExperimentPickyCriteria from './graphql/ExperimentPickyCriteriaQuery.cgs.graphql';

interface UseExperimentPickyDataProps {
  onError: (error: unknown) => void;
}

export function useExperimentPickyDataQuery(
  props: UseExperimentPickyDataProps
): {
  loading: boolean;
  error?: ApolloError;
  data?: ExperimentPickyCriteriaQuery;
} {
  return useQuery<ExperimentPickyCriteriaQuery>(ExperimentPickyCriteria, {
    context: { endpoint: 'cgs' },
    onError: props.onError,
  });
}
