import { addThisWeek } from './addThisWeek';

/**
 * Given the next dates, return the date that should appear as unavailable.
 */
export function getUnavailableDate(
  availableNextDates: string[] = []
): string | undefined {
  const datesWithToday = addThisWeek(availableNextDates);

  if (datesWithToday[0] !== availableNextDates[0]) return datesWithToday[0];
  return undefined;
}
