import { useTosaUserFeature } from '../../../hooks';
import { FeatureName } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface UseBreedImprovementsExperimentArgs {
  useFeature: UseFeatureHook;
}

type UseBreedImprovementsExperimentReturn = {
  shouldShowBreedDropdownImprovements: boolean;
};

export function useBreedDropdownImprovementsExperiment({
  useFeature,
}: UseBreedImprovementsExperimentArgs): UseBreedImprovementsExperimentReturn {
  const { treatment } = useTosaUserFeature({
    featureName: FeatureName.CVR_BREED_DROPDOWN_IMPROVEMENTS,
    useFeature,
  });

  return {
    shouldShowBreedDropdownImprovements: treatment === 'on',
  };
}
