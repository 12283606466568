import emailMisspelled, { top100 } from 'email-misspelled';
import { useState } from 'react';

import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../../../../analytics/segmentTrackEventNames';

export function useSuggestedEmail() {
  const [suggestedEmail, setSuggestedEmail] = useState('');

  const domainAllowlist = ['icloud.com'];

  const emailChecker = emailMisspelled({ maxMisspelled: 4, domains: top100 });

  const suggestEmail = (email: string) => {
    if (email === '') return;

    const emailDomain = email.split('@')[1];

    if (domainAllowlist.includes(emailDomain)) {
      return;
    }

    const suggestedEmailOptions = emailChecker(email);
    if (suggestedEmailOptions.length > 0) {
      // only return the closest match suggestion from the package
      setSuggestedEmail(suggestedEmailOptions[0].corrected || '');
      track(segmentTrackEvents.suggest_email_correction);
    }
  };

  const resetSuggestedEmail = () => {
    setSuggestedEmail('');
  };

  return {
    suggestedEmail,
    suggestEmail,
    resetSuggestedEmail,
  };
}
