import { Helmet } from 'react-helmet-async';

import config from 'src/config';

export interface GTag {
  (command: 'event', name: string, properties: Record<string, unknown>): void;
  (command: 'config', tagId: string, config: Record<string, unknown>): void;
}

declare global {
  interface Window {
    gtag?: GTag;
  }
}

export function Ga4Script() {
  const measurementId = config('ga4.measurementId') as string;

  if (!config('ga4.enabled')) {
    return null;
  }

  const configurationScript = ` 
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${measurementId}', { send_page_view: false });
  `;

  return (
    <Helmet>
      {config('ga4.loadSdk') && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
        ></script>
      )}
      <script>{configurationScript}</script>
    </Helmet>
  );
}
