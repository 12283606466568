import { tosaErrorCodes as errorCodes } from '@farmersdog/constants';

import { GENERIC_ERROR_MESSAGE } from '../validation/constants';

import { isManagedAuthRegisterError } from './isManagedAuthRegisterError';

// provides human-readable display errors for auth/register failures
export function getManagedAuthRegisterErrorDisplayMessage(
  errorMessage: string
) {
  if (isManagedAuthRegisterError(errorMessage)) {
    switch (errorMessage) {
      case errorCodes.EMAIL_ALREADY_IN_USE:
        return 'Looks like you’re already signed up with this email';
      case errorCodes.PHONE_NUMBER_ALREADY_IN_USE:
        return 'This phone number is used by another account';
      default:
        return GENERIC_ERROR_MESSAGE;
    }
  } else {
    return GENERIC_ERROR_MESSAGE;
  }
}
