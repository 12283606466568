import {
  iSpot,
  Premion,
  ISpotEventName,
  PremionEventName,
  PREMION_REF_ID,
} from './iSpot';
import { getSharedISpotParams } from './getSharedISpotParams';

/**
 * Track the iSpot checkout conversion event
 */

export function trackISpotCheckoutConversion({
  utmSource,
}: {
  utmSource: unknown;
}) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { app, channel, customData } = getSharedISpotParams({
    utmSource,
  });

  iSpot.track({
    app,
    type: ISpotEventName.CHECKOUT,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });

  Premion.track({
    app,
    type: PremionEventName.CHECKOUT,
    refid: PREMION_REF_ID,
    url: window.location.pathname,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
