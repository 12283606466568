import { useState, useEffect, useCallback } from 'react';

export type AreCardDetailsComplete = {
  cardNumber: boolean;
  cvc: boolean;
  expiration: boolean;
};

export interface UseCreditCardForm {
  /** Has the customer completed the Stripe input elements? (Can we submit?) */
  areCardDetailsComplete: AreCardDetailsComplete;
  /** Should we display input placeholder elements? (false) or Corgi/Stripe elements? (true) */
  isEditing: boolean;
  /** Focus handler for CardNumber, CardExpiration, and CardCvc */
  handleCreditCardDetailsFocus: () => void;
  /** Change handler for CardNumber, CardExpiration, and CardCvc */
  handleCreditCardDetailsChange: (
    isPropertyComplete: Partial<AreCardDetailsComplete>
  ) => void;
}

export interface UseCreditCardFormProps {
  hasBillingAddressChanged: boolean;
}

export function useCreditCardForm(
  props: UseCreditCardFormProps
): UseCreditCardForm {
  const [areCardDetailsComplete, setAreCardDetailsComplete] =
    /*
     * Since the customer already has credit card details, we can consider these
     * properties initially "complete" and not block the form’s submit, unless the
     * customer decides to edit.
     */
    useState<AreCardDetailsComplete>({
      cardNumber: true,
      cvc: true,
      expiration: true,
    });

  const [isEditing, setIsEditing] = useState(false);

  /*
   * If the customer wants to edit any of their credit card details, we have to
   * reset the "complete" property of each detail, so we can later flag when
   * we're ready for submit.
   */
  const initEditCreditCard = useCallback(() => {
    if (isEditing) {
      return;
    }
    setAreCardDetailsComplete({
      cardNumber: false,
      cvc: false,
      expiration: false,
    });
    setIsEditing(true);
  }, [isEditing]);

  useEffect(() => {
    /* Billing address is stored in Stripe. If the customer updates their billing
    address they will also need to enter their credit card details, since we will
    be requesting a new token from Stripe. */
    if (props.hasBillingAddressChanged) {
      initEditCreditCard();
    }
  }, [initEditCreditCard, props.hasBillingAddressChanged]);

  /*
   * On change, `e.complete` returns `false` until it satisfies Stripe’s
   * requirements. This function keeps track if all details are complete so we
   * can enable submit.
   */
  function handleCreditCardDetailsChange(
    isPropertyComplete: Partial<AreCardDetailsComplete>
  ) {
    setAreCardDetailsComplete(prevCardDetails => ({
      ...prevCardDetails,
      ...isPropertyComplete,
    }));
  }

  return {
    areCardDetailsComplete,
    isEditing,
    handleCreditCardDetailsChange,
    handleCreditCardDetailsFocus: initEditCreditCard,
  };
}
