import { track } from '@farmersdog/analytics';

import events from '../eventNames';

interface TrackCheckoutSurpriseHigherDiscountArgs {
  oldCode: string;
  newCode: string;
}

export function trackCheckoutSurpriseHigherDiscount({
  oldCode,
  newCode,
}: TrackCheckoutSurpriseHigherDiscountArgs) {
  track(events.signup_surprise_higher_discount, {
    oldCode,
    newCode,
    page: 'checkout',
  });
}
