import { useEffect } from 'react';
import { useLocation } from 'react-router';

import {
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_SUCCESS,
} from '@farmersdog/constants/paths';

import { trackPinterestPageView } from './trackPinterestPageView';
import { shouldRunPageViewFromWebsite } from '../utils';
export const pinterestPageviewRoutes = [
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_SUCCESS,
];

interface UseTrackPinterestPageViewArgs {
  isRecipesPage: boolean;
  showTosaRecipesPage: boolean;
}

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a Pinterest page view event.
 */
export function useTrackPinterestPageView({
  isRecipesPage,
  showTosaRecipesPage,
}: UseTrackPinterestPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  const shouldRunFromWebsite = shouldRunPageViewFromWebsite({
    pathname,
    isRecipesPage,
    showTosaRecipesPage,
  });

  useEffect(() => {
    if (shouldRunFromWebsite) {
      if (pinterestPageviewRoutes.includes(pathname)) {
        trackPinterestPageView();
      }
    }
  }, [pathname, shouldRunFromWebsite]);
}
