import { Text, Button } from '@farmersdog/corgi-x';

import styles from './PlanSummary.module.scss';
import { useCorgiV2Viewport } from 'src/screen';
import classNames from 'classnames';

import { formatCadenceInWeeks, formatCurrency } from 'src/utils/formatters';

import { useRecipeSubmit } from '../../hooks/useRecipeSubmit';

import { useDiyRecommendation } from '../../../Signup/DIY/hooks/useDiyRecommendation';
import { pluralizeString } from 'src/utils/pluralizeString';
import { useDiyCheckoutQuote } from 'src/pages/SignupCheckoutPage/hooks/useDiyCheckoutQuote';

export enum PlanSummaryLayout {
  Header = 'header',
  Page = 'page',
}

export interface PlanSummaryProps {
  layout: PlanSummaryLayout;
}

function PagePlanSummary() {
  const { loading, submit } = useRecipeSubmit();

  const {
    subtotalAmount,
    subtotalWeeklyAmount: pretaxWeeklyAmount,
    regularOrderDaysOfFood: currentCadence,
  } = useDiyCheckoutQuote();

  const responsiveClassName = useCorgiV2Viewport<string>({
    lg: styles.lg,
  });

  const { recommendedNumberOfProducts } = useDiyRecommendation();

  const recommendedNumberOfProductsWithBoxes = pluralizeString(
    recommendedNumberOfProducts,
    'box',
    'boxes',
    { prependQuantity: true }
  );

  if (!currentCadence) {
    return null;
  }

  return (
    <div className={classNames(styles.planSummary, responsiveClassName)}>
      <Text variant="heading-16" bold>
        DIY Plan:{' '}
      </Text>
      <Text variant="heading-16">
        {recommendedNumberOfProductsWithBoxes} every{' '}
        {formatCadenceInWeeks(currentCadence)} weeks{' '}
        <span className={styles.weeklyPrice}>
          ({formatCurrency(pretaxWeeklyAmount)}/week)
        </span>
      </Text>
      <Button
        onClick={submit}
        loading={loading}
        name="submit"
        variant="primary"
        className={styles.cta}
      >
        Checkout for {formatCurrency(subtotalAmount)}
      </Button>
    </div>
  );
}

function HeaderPlanSummary() {
  const { loading, submit } = useRecipeSubmit();

  return (
    <Button
      onClick={submit}
      loading={loading}
      name="submit"
      variant="special"
      className={styles.cta}
    >
      Checkout
    </Button>
  );
}

export function PlanSummary({ layout }: PlanSummaryProps) {
  const { hasQuote } = useDiyCheckoutQuote();
  if (!hasQuote) {
    return null;
  }

  return layout === PlanSummaryLayout.Page ? (
    <PagePlanSummary />
  ) : (
    <HeaderPlanSummary />
  );
}
