import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useTosaAuthToken } from '../../../../pages/SignupTosa/useTosaAuthToken';
import { paths } from '@farmersdog/constants';

const {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_TOSA_ME_OVERRIDE,
  PATH_SIGNUP_TOSA_PETS_OVERRIDE,
} = paths;

/**
 * Check if a path contains any of the paths
 *
 * @param {String} pathname the target pathname to check
 * @param  {...String} pathInputs the path arguments to check for
 *
 * @return {Boolean} does the pathname contains any of the paths
 */
function pathContains(pathname, ...pathInputs) {
  return pathInputs.some(path => pathname.search(path) > -1);
}

/**
 * Calculate the percentage of a step tthat has been completed
 *
 * @param {Array} innerSteps
 *
 * @return {Number} the total percentage completed from this step
 */
function getStepPercentage(innerSteps) {
  const completedInnerSteps = innerSteps.filter(Boolean);

  return 100 * (completedInnerSteps.length / innerSteps.length);
}

/**
 * Reusable step logic as a function of progress and pathname
 *
 * @typedef {Object} Step
 * @param {String} Step.path pathname of the step page
 * @param {String} Step.label label associated with the step
 * @param {Boolean} Step.completed whether or not the entire step has been completed
 * @param {Boolean} Step.active whether or not the step is in an active state
 * @param {Number} Step.percentage a percentage value representing the completion of the inner steps
 *
 * @return {Array<Step>} a memoized array of steps that will recalculate when progress and pathname change
 */
export default function useSteps(progress, pathname) {
  const tosaAuthToken = useTosaAuthToken();
  const isCheckoutPage = useRouteMatch(PATH_SIGNUP_CHECKOUT);
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);

  const linkShouldReturnToTosa =
    tosaAuthToken && (isCheckoutPage || isRecipesPage || isPlansPage);

  return useMemo(() => {
    const { overall, pets, plans, me } = progress;
    const safePets = Array.isArray(pets) ? pets : [{}];

    return [
      {
        path: linkShouldReturnToTosa
          ? PATH_SIGNUP_TOSA_ME_OVERRIDE
          : PATH_SIGNUP_ME,
        label: 'Me',
        completed: overall.me === 1 && !pathContains(pathname, PATH_SIGNUP_ME),
        active: pathContains(pathname, PATH_SIGNUP_ME),
        percentage: getStepPercentage(Object.values(me)),
      },
      {
        path: linkShouldReturnToTosa
          ? PATH_SIGNUP_TOSA_PETS_OVERRIDE
          : PATH_SIGNUP_PETS,
        label: 'My Dogs',
        completed:
          overall.pets === 1 &&
          !pathContains(pathname, PATH_SIGNUP_ME, PATH_SIGNUP_PETS),
        active: pathContains(pathname, PATH_SIGNUP_PETS),
        percentage: getStepPercentage(safePets.flatMap(Object.values)),
      },
      {
        path: PATH_SIGNUP_RECIPES,
        label: 'Your Plan',
        completed:
          overall.plans === 1 &&
          !pathContains(
            pathname,
            PATH_SIGNUP_ME,
            PATH_SIGNUP_PETS,
            PATH_SIGNUP_RECIPES,
            PATH_SIGNUP_PLANS
          ),
        active: pathContains(pathname, PATH_SIGNUP_RECIPES, PATH_SIGNUP_PLANS),
        percentage: getStepPercentage(plans.flatMap(Object.values)),
      },
      {
        path: PATH_SIGNUP_CHECKOUT,
        label: 'Checkout',
        completed:
          overall.plans === 1 &&
          !pathContains(
            pathname,
            PATH_SIGNUP_ME,
            PATH_SIGNUP_PETS,
            PATH_SIGNUP_RECIPES,
            PATH_SIGNUP_PLANS
          ),
        active: pathContains(pathname, PATH_SIGNUP_CHECKOUT),
        percentage: 0,
      },
    ];
  }, [progress, pathname, linkShouldReturnToTosa]);
}
