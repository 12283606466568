import brunoJp2 from './bruno.jp2';
import brunoPng from './bruno.png';
import brunoWebp from './bruno.webp';
import diegoAfterJp2 from './diego-after.jp2';
import diegoAfterPng from './diego-after.png';
import diegoAfterWebp from './diego-after.webp';
import diegoBeforeJp2 from './diego-before.jp2';
import diegoBeforePng from './diego-before.png';
import diegoBeforeWebp from './diego-before.webp';
import maxieSueAndFrenchieJp2 from './maxie-sue-and-frenchie.jp2';
import maxieSueAndFrenchiePng from './maxie-sue-and-frenchie.png';
import maxieSueAndFrenchieWebp from './maxie-sue-and-frenchie.webp';
import obieAfterJp2 from './obie-after.jp2';
import obieAfterPng from './obie-after.png';
import obieAfterWebp from './obie-after.webp';
import obieBeforeJp2 from './obie-before.jp2';
import obieBeforePng from './obie-before.png';
import obieBeforeWebp from './obie-before.webp';
import tobyAfterJp2 from './toby-after.jp2';
import tobyAfterPng from './toby-after.png';
import tobyAfterWebp from './toby-after.webp';
import tobyBeforeJp2 from './toby-before.jp2';
import tobyBeforePng from './toby-before.png';
import tobyBeforeWebp from './toby-before.webp';

export const obieBeforeSrc = [obieBeforePng, obieBeforeWebp, obieBeforeJp2];
export const obieAfterSrc = [obieAfterPng, obieAfterWebp, obieAfterJp2];

export const diegoBeforeSrc = [diegoBeforePng, diegoBeforeWebp, diegoBeforeJp2];
export const diegoAfterSrc = [diegoAfterPng, diegoAfterWebp, diegoAfterJp2];

export const tobyBeforeSrc = [tobyBeforePng, tobyBeforeWebp, tobyBeforeJp2];
export const tobyAfterSrc = [tobyAfterPng, tobyAfterWebp, tobyAfterJp2];

export const brunoSrc = [brunoPng, brunoWebp, brunoJp2];

export const maxieSueAndFrenchieSrc = [
  maxieSueAndFrenchiePng,
  maxieSueAndFrenchieWebp,
  maxieSueAndFrenchieJp2,
];
