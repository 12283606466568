import {
  Button,
  ContentReveal,
  Grid,
  GridItem,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import styles from './AddDogContent.module.css';
import React, { useEffect } from 'react';
import classNames from 'classnames';

export interface AddDogSectionProps {
  /**Image positioned on top of heading */
  image?: { src: string; alt: string };
  /** Main heading. Provide sectionAriaLabel prop if heading is a ReactNode */
  heading: string | React.ReactNode;
  /** Provide an aria label for the section */
  sectionAriaLabel?: string;
  /** Subheading */
  subheading?: string;
  /** Children components */
  children?: React.ReactNode;
  /** CTA button text. Default value is 'Continue' */
  cta?: string;
  /** CTA button variants */
  ctaButtonVariant?: 'default' | 'plain-text';
  /** Toggle disabled state of CTA button */
  ctaDisabled?: boolean;
  /** Loading state of CTA button */
  ctaLoading?: boolean;
  /** Primary CTA button event handler */
  onCtaClick: () => void;
  /** Optional width constraint */
  sectionWidthConstraint?: 'sm' | 'md' | 'lg' | 'auto';
  /** Whether user is on the Success Scene */
  isSuccessScene?: boolean;
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export const AddDogContent = (props: AddDogSectionProps) => {
  const {
    heading,
    subheading,
    children,
    onCtaClick,
    image,
    cta = 'Continue',
    ctaDisabled,
    sectionWidthConstraint = 'sm',
    ctaButtonVariant = 'default',
    ctaLoading = false,
    sectionAriaLabel,
    isSuccessScene,
  } = props;

  const [showContent, setShowContent] = React.useState(false);

  useEffect(() => {
    setShowContent(true);
    if (window.scrollY !== 0) {
      window.scroll({ top: 0, behavior: 'instant' });
    }
  }, []);

  const showPlainTextCta = ctaButtonVariant === 'plain-text';

  return (
    <Section
      aria-label={isString(heading) ? heading : (sectionAriaLabel ?? '')}
      topSpacing="lg"
      bottomSpacing={{
        xs: showPlainTextCta ? 'none' : '4xl',
        md: showPlainTextCta ? 'none' : 'lg',
      }}
      className={classNames(styles.root, {
        [styles.successScene]: isSuccessScene,
      })}
    >
      <ContentReveal in={showContent} inAnimation="fade">
        <div>
          <Section
            as="div"
            className={styles.header}
            hSpacing="md"
            widthConstraint={sectionWidthConstraint}
          >
            {image && (
              <Picture
                sources={[image.src]}
                alt={image.alt}
                style={{ maxWidth: '327px', marginBottom: '24px' }}
              />
            )}
            {isString(heading) ? (
              <Text as="h1" variant="heading-28" bold color="kale-3">
                {heading}
              </Text>
            ) : (
              heading
            )}
            {subheading && (
              <Text as="p" variant="article-16" bottomSpacing="md">
                {subheading}
              </Text>
            )}
            {children}
          </Section>

          <Grid
            flexDirection="column"
            alignItems="center"
            rowGap="lg"
            className={classNames(
              showPlainTextCta ? styles.plainTextCta : styles.stickyCta,
              {
                [styles.successSceneCta]: isSuccessScene,
              }
            )}
          >
            <GridItem justifyContent="center" sm={12} lg={12}>
              <Button
                type="button"
                variant={showPlainTextCta ? 'plain-text' : 'primary'}
                onClick={onCtaClick}
                className={styles.ctaButton}
                aria-disabled={ctaDisabled}
                color={isSuccessScene ? 'kale' : 'carrot'}
                underline={showPlainTextCta}
                loading={ctaLoading}
              >
                {cta}
              </Button>
            </GridItem>
          </Grid>
        </div>
      </ContentReveal>
    </Section>
  );
};
