import { useRef, useEffect } from 'react';

import { BranchNode as BranchNodeType } from '../../blueprint/types';
import { TOSAComponentInput } from '../../types';
import { determineSkipGetStarted, getCompletedData } from '../../utils';
import { BranchNode } from '../schema/BranchNode';
import { SubmitButton } from '../shared';

export function StandardForm(props: TOSAComponentInput<BranchNodeType>) {
  const { formMethods, formSubmitRequest, progress, node, experiments } = props;

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  const { shouldSkipGetStarted } = determineSkipGetStarted({ experiments });

  const shouldAutoScrollToBottom =
    shouldSkipGetStarted &&
    node.name === 'Me1' &&
    progress.getBranchComplete(node.__self__);

  useEffect(() => {
    if (shouldAutoScrollToBottom) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
        });
      }, 0);
    }
  }, [shouldAutoScrollToBottom]);

  return (
    <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
      <BranchNode {...props} />
      <SubmitButton loading={formSubmitRequest.loading} {...props} />
    </form>
  );
}
