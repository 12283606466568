import { useState, useMemo, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { NodeNames } from '@farmersdog/constants';

import { CompiledBlueprint, NodeRegistry } from '../blueprint/types';
import { updatePetCount } from '../schema/actions/updatePetCount';

import type {
  UseForm,
  SetFormStepsType,
  Experiments,
  FormFieldsType,
} from '../types';

interface UseFormQueryUrlArgs {
  formMethods: UseForm;
  loaded: boolean;
  schema?: CompiledBlueprint;
  registry: NodeRegistry | undefined;
  setFormSteps: SetFormStepsType;
  experiments: Experiments;
}

export function useFormQueryUrl({
  formMethods,
  schema,
  registry,
  setFormSteps,
  loaded,
  experiments,
}: UseFormQueryUrlArgs) {
  const [hasRun, setHasRun] = useState(false);
  const location = useLocation();
  const numPets = useMemo((): string | null => {
    const search = location.search;
    const searchParams = new URLSearchParams(search);

    return searchParams.get(NodeNames.NumPets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const numPetsDefaultValue = Number(numPets);

  //useController persists the default value state update regardless of whether the target input has been registered. (setValue, on the other hand, does not persist the state update if the input is not yet registered)
  useController<FormFieldsType>({
    name: NodeNames.NumPets,
    control: formMethods.control,
    defaultValue:
      numPetsDefaultValue && numPetsDefaultValue > 0 ? numPetsDefaultValue : '',
  });

  useEffect(() => {
    if (!loaded || hasRun || !schema || !registry) {
      return;
    }

    const handleNumPetsFromURL = async () => {
      setHasRun(true);

      const valid = await formMethods.trigger(NodeNames.NumPets);
      if (valid) {
        updatePetCount({
          value: numPetsDefaultValue,
          schema,
          setFormSteps,
          nodeName: NodeNames.NumPets,
          registry,
          unregister: formMethods.unregister,
          experiments,
        });
      }
    };

    if (numPets) {
      void handleNumPetsFromURL();
    }
  }, [
    numPets,
    loaded,
    formMethods,
    schema,
    registry,
    setFormSteps,
    hasRun,
    experiments,
    numPetsDefaultValue,
  ]);
}
