export interface Ga4Item {
  item_id: string;
  item_name: string;
}

export function getGa4ItemsFromRecipes(recipes: string[]): Ga4Item[] {
  return recipes.map(recipe => ({
    item_name: 'Fresh Food',
    item_id: recipe,
  }));
}
