import { useMemo } from 'react';

import { FeatureName } from '../../../utils/features';
import { useLeadState } from '../../useLeadState';
import { useTreatsInCheckoutUi } from '../useTreatsInCheckoutUi';

import type {
  UseFeatureHook,
  UseUserExperimentsHookReturn,
} from '../../../types';

interface UseUserExperimentsArgs {
  useFeature: UseFeatureHook;
}

export function useUserExperiments({
  useFeature,
}: UseUserExperimentsArgs): UseUserExperimentsHookReturn | undefined {
  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const treatsInCheckoutUi = useTreatsInCheckoutUi({
    useFeature,
  });

  const experiments = useMemo(
    () => ({
      [FeatureName.CVR_TREATS_IN_CHECKOUT_UI]: treatsInCheckoutUi,
    }),
    [treatsInCheckoutUi]
  );

  if (!lead?.corePostgresUserId) {
    return undefined;
  }

  return experiments as UseUserExperimentsHookReturn;
}
