import { Helmet } from 'react-helmet-async';

export interface Ga4Configuration {
  enabled: boolean;
  measurementId: string;
  loadSdk: boolean;
  debugView: boolean;
}

export interface GTagMounter {
  (command: 'event', name: string, properties: Record<string, unknown>): void;
  (command: 'config', tagId: string, config: Record<string, unknown>): void;
}

export function Ga4Script({
  enabled,
  measurementId,
  loadSdk,
}: Ga4Configuration) {
  if (!enabled) {
    return null;
  }

  const configurationScript = ` 
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${measurementId}', { send_page_view: false });
  `;

  return (
    <Helmet>
      {loadSdk && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
        ></script>
      )}
      <script>{configurationScript}</script>
    </Helmet>
  );
}
