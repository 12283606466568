import { standardBlueprintVersion, blueprintVersionMap } from '../../blueprint';
import { Blueprint } from '../../blueprint/types';
import { useClient } from '../../services/apollo';
import { StaticDataQuery, StaticDataQueryVariables } from '../types';
import { seedStaticData } from '../utils/staticData/seedStaticData';

import staticDataQuery from './staticData.graphql';

interface BlueprintVersion {
  version: string;
}

export interface FetchBlueprint {
  (variables?: BlueprintVersion): Promise<Blueprint>;
}

export function useBlueprint(): FetchBlueprint {
  const client = useClient();

  // TODO: right now the are not using the blueprint version, so we are just
  // using the standard 0.0.0 version. We will need to update this whenever we
  // start using the blueprint version as a parameter
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (variables?: BlueprintVersion): Promise<Blueprint> => {
    const staticData = await client.query<
      StaticDataQuery,
      StaticDataQueryVariables
    >({
      query: staticDataQuery,
    });

    const unseededBlueprint = blueprintVersionMap.get(standardBlueprintVersion);

    if (!unseededBlueprint) {
      throw new Error('Cannot find unseeded blueprint');
    }

    if (!staticData.data.staticData) {
      throw new Error('Cannot find static data');
    }

    const seededBlueprint = seedStaticData(
      unseededBlueprint,
      staticData.data.staticData
    );

    return seededBlueprint;
  };
}
