import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { HeroContent } from './MetaHero';

import { useFeature } from 'src/abTesting';
import { CVR_LINK_HOMEPAGE_HEADLINE } from 'src/abTesting/features';

import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { useHomepageHeroExperiment } from 'src/hooks/useHomepageHeroExperiment';
import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils';
import {
  TermsAndConditionsChangeBanner,
  TermsAndConditionsChangeBannerVariant,
} from 'src/components/TermsAndConditionsChangeBanner';

import styles from './StaticHero.module.css';
import classNames from 'classnames';
import { useHomepageTermsChangesBanner } from '../../../../../../hooks/useHomepageTermsChangesBanner';

export function StaticHero() {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();
  const { treatment } = useHomepageHeroExperiment();

  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  const isHeadlineLink =
    useFeature(CVR_LINK_HOMEPAGE_HEADLINE).treatment === 'on';

  const isTermsChangeNoticeEnabled = useHomepageTermsChangesBanner();

  return (
    <div
      className={classNames({
        [styles.heroContainer]: isTermsChangeNoticeEnabled,
      })}
    >
      {isTermsChangeNoticeEnabled && (
        <div className={styles.termsBanner}>
          <TermsAndConditionsChangeBanner
            variant={TermsAndConditionsChangeBannerVariant.Home}
          />
        </div>
      )}
      <HeroContent
        isHeadlineLink={isHeadlineLink}
        linkToFreshSignup={linkToFreshSignup}
        isAccessibilityThemeOn={isAccessibilityThemeOn}
        variant={treatment}
      />
    </div>
  );
}
