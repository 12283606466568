import { AxiosError } from 'axios';
import get from 'lodash/get';

/**
 * Returns `true` if a response error is a request error.
 *
 * @param {object} failure
 * @return {boolean}
 */
export function isRequestFailure(
  failure: Omit<AxiosError, 'status' | 'cause'>
) {
  return Boolean(
    failure.request && failure.response && failure.response.status !== 200
  );
}

/**
 * Returns `true` if a request error is a validation error.
 *
 * @param {object} failure
 * @return {boolean}
 */
export function isValidationFailure(
  failure: Omit<AxiosError, 'status' | 'cause'>
) {
  const isWellFormatted = Array.isArray(
    get(failure, 'response.data.data.errors')
  );
  return (
    isWellFormatted &&
    isRequestFailure(failure) &&
    failure.response?.status === 400
  );
}

/**
 * Returns `true` if a request error should be classified as `NetworkError`.
 *
 * @param {RequestError} error
 */
export function isNetworkError(error: Omit<AxiosError, 'status' | 'cause'>) {
  if (!error.response) {
    return true;
  }
  if (error.code === 'ECONNABORTED') {
    return true;
  }
  if (error.message && error.message.indexOf('Network Error') > -1) {
    return true;
  }
  return false;
}

/**
 * Use `RequestError` class for handling
 *
 * @example
 *
 *  const anApiError = new RequestError({
 *    message: 'Something went wrong', // Will be error message
 *    response: { status: 400 }
 *  });
 *
 * @extends Error
 */
export class RequestError
  extends Error
  implements Omit<AxiosError, 'status' | 'cause'>
{
  status?: number;
  statusText?: string;
  retryCount: number;
  extra?: Record<string, unknown>;

  /*
   * These are all "inherited" from AxiosError
   */
  code?: AxiosError['code'];
  config: AxiosError['config'];
  request?: AxiosError['request'];
  response?: AxiosError['response'];
  // @ts-expect-error it's assigned in the constructor
  isAxiosError: AxiosError['isAxiosError'];
  // @ts-expect-error it's assigned in the constructor
  toJSON: AxiosError['toJSON'];

  constructor(error: AxiosError) {
    super(error.message);

    // Copy error props
    (Object.keys(error) as (keyof typeof error)[]).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key as keyof typeof this] = error[key];
    });

    this.name = 'RequestError';
    if (isNetworkError(error)) {
      this.name = 'NetworkError';
    }

    if (error.response) {
      this.status = error.response.status;
      this.statusText = error.response.statusText;
      if (
        error.response.data &&
        (error.response.data as { message: string }).message
      ) {
        this.message = `${this.message}: ${
          (error.response.data as { message: string }).message
        }`;
      }
    }

    this.retryCount = 0;
  }

  setRetryCount(count: number) {
    this.retryCount = count;
  }
}

/**
 * Error class for validation errors
 */
export class ValidationError extends RequestError {
  validation: Record<string, string> = {};

  constructor(error: Omit<AxiosError, 'status' | 'cause'>) {
    super(error);

    const data = get(error, 'response.data.data') as unknown as {
      errors?: (Error & { params?: string[]; param?: string })[];
    };

    if (!data.errors || data.errors.length < 1) {
      return;
    }

    if (data.errors[0] && data.errors[0].message) {
      this.message = data.errors[0].message;
    }

    // Create a error.validation.paramName error structure
    data.errors.forEach(dataErr => {
      if (Array.isArray(dataErr.params)) {
        dataErr.params.forEach(param => {
          this.validation[param] = dataErr.message;
        });
      } else if (typeof dataErr.params === 'string') {
        this.validation[dataErr.params] = dataErr.message;
      } else if (!dataErr.param) {
        this.validation.unknown = dataErr.message;
      }
    });

    this.name = 'ValidationError';
  }
}
