import { addMinutes } from 'date-fns/addMinutes';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';

/* Formats a date time string as if it were UTC instead of using the local time */
export function formatUtcDateTime(
  dateTimeInput: string,
  formatString: string
): string {
  const dDate = new Date(dateTimeInput);

  if (!isValid(dDate)) {
    return '';
  }

  const formattedDate = format(
    addMinutes(dDate, dDate.getTimezoneOffset()),
    "yyyy-MM-dd'T'HH:00"
  );

  return format(new Date(formattedDate), formatString);
}
