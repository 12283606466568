// Includes all enabled and disabled values from the db; needs to be updated when static data changes.
export enum PetDietIssue {
  LowFat = 'Low Fat',
  JointSupport = 'Joint Support',
  WeightManagement = 'Weight Management',
  WeightLoss = 'Weight Loss',
  RenalDisease = 'Renal Disease',
  Diabetes = 'Diabetes',
  LiverSupport = 'Liver Support',
  NeoplasiaDiet = 'Neoplasia Diet',
  SkinSupport = 'Skin Support',
  HydrolyzedProtein = 'Hydrolyzed Protein',
  GiPancreatitisDiet = 'GI/Pancreatitis Diet',
  Other = 'Other',
  GiDiet = 'GI diet',
  PancreatitisDiet = 'Pancreatitis diet',
}

export type PrescriptionDietIssueWithWarning = PetDietIssue.LowFat;

export const CTA_BUTTON_WITH_WARNING = 'Continue Without RX';
export const CTA_BUTTON = 'Continue';

export const PET_DIET_ISSUE_WARNING_MESSAGE =
  'Dogs on prescription foods often require a specific recipe which we don’t currently formulate. If the issue is chronic or you’ve been prescribed a specific diet, we suggest talking with your veterinarian.';
