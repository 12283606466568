import { ChangeEvent } from 'react';

import {
  Form,
  Grid,
  GridItem,
  ToggleBox,
  ToggleBoxProps,
} from '@farmersdog/corgi-x';
import { formatCurrency, pluralizeString } from '../../../utils';
import { ChangeFreshOrderSizeQuotesQuery } from '../../network/fetchChangeFreshOrderSizeQuotes/fetchChangeFreshOrderSizeQuotes.cgs.generated-types';
import styles from './FrequencySelector.module.css';
import { FrequencySelectionProps, getFrequencySelections } from './utils';
import { useTrackPricesSeen } from '../../../analytics/useTrackPricesSeen';

export type FrequencyInfo = {
  frequency: number;
  dailyConsumptionPrice: number;
};
export interface FrequencySelectorProps {
  /** The selected frequency value */
  value?: number;
  suggestedFrequencies: ChangeFreshOrderSizeQuotesQuery['customer']['changeFreshOrderSizeQuotes'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function FrequencySelector({
  value,
  suggestedFrequencies,
  onChange,
}: FrequencySelectorProps) {
  const formName = 'frequencySelector';

  const frequencySelections = getFrequencySelections(suggestedFrequencies).map(
    ({ frequencyInfo, isMinFrequency, isMinPrice }, i) => (
      <FrequencyChoice
        key={`${formName}-${frequencyInfo.frequency}-${i}`}
        frequencyInfo={frequencyInfo}
        isMinFrequency={isMinFrequency}
        isMinPrice={isMinPrice}
        value={value}
        onChange={onChange}
      />
    )
  );

  return (
    <Form>
      <Grid rowGap={'sm'} columnGap={'md'} flexDirection={'row'}>
        {frequencySelections}
      </Grid>
    </Form>
  );
}

function FrequencyChoice({
  frequencyInfo,
  isMinFrequency,
  isMinPrice,
  value,
  onChange,
}: FrequencySelectionProps &
  Pick<FrequencySelectorProps, 'value' | 'onChange'>) {
  const formName = 'frequencySelector';

  const badgeProps = getBadgeProps({
    isMinFrequency,
    isMinPrice,
  });

  useTrackPricesSeen({
    dailyPrice: frequencyInfo.dailyConsumptionPrice,
    frequency: frequencyInfo.frequency,
  });

  return (
    <GridItem md={6}>
      <ToggleBox
        className={styles.toggleBox}
        type="radio"
        name={formName}
        value={frequencyInfo.frequency}
        checked={frequencyInfo.frequency === value}
        onChange={onChange}
        supportCopy={`${formatCurrency(
          frequencyInfo.dailyConsumptionPrice
        )}/day`}
        label={`${pluralizeString(frequencyInfo.frequency, 'Day', 'Days', {
          prependQuantity: true,
        })}`}
        {...badgeProps}
      />
    </GridItem>
  );
}

function getBadgeProps({
  isMinFrequency,
  isMinPrice,
}: {
  isMinFrequency: boolean;
  isMinPrice: boolean;
}): Pick<ToggleBoxProps, 'badge' | 'badgeVariant'> {
  if (isMinPrice) {
    return {
      badge: 'Best Value',
      badgeVariant: 'recommended',
    };
  }

  if (isMinFrequency) {
    return {
      badge: 'Save Space',
      badgeVariant: 'default',
    };
  }

  return {};
}
