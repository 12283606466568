import { Button, Color, Page, Text } from '@farmersdog/corgi-x';
import { CheckCircle } from '@farmersdog/corgi-x/icons';
import { PATH_ORDERS } from '@farmersdog/constants/paths';
import { formatWeekOf } from '../../utils';
import styles from './SuccessScene.module.css';

type SuccessSceneProps = {
  /** The ship date after the plans have been updated. */
  updatedNextDate: string | null | undefined;
  /** Callback to close the page */
  onClose: () => void;
};

/**
 * Render a success screen and (if the selected reason says so) an option to
 * open the `Inventory` workflow.
 */
export function SuccessScene({ updatedNextDate, onClose }: SuccessSceneProps) {
  return (
    <Page
      layout="base-centered"
      image={<CheckCircle size={64} fill={Color.Carrot2} />}
      onCloseClick={onClose}
      className={styles.page}
    >
      <div className={styles.content}>
        <Text variant="heading-40" bold as="h1" color="kale-3" topSpacing="sm">
          You’re All Set!
        </Text>
        <Text
          variant="article-20"
          as="p"
          color="charcoal-3"
          topSpacing="sm"
          bottomSpacing="lg"
        >
          Your orders are now paused.
          {updatedNextDate && (
            <span>
              {' '}
              Your next box will ship the week of{' '}
              {formatWeekOf(updatedNextDate)}.
            </span>
          )}
        </Text>

        <Button
          type="link"
          to={PATH_ORDERS}
          variant="secondary"
          className={styles.link}
        >
          Back to account
        </Button>
      </div>
    </Page>
  );
}
