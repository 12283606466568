import { Button, Text } from '@farmersdog/corgi-x';

import styles from './ActionButtons.module.css';

export interface ActionButtonsProps {
  loading: boolean;
  canSubmit: boolean;
  onCancel: () => void;
}

export function ActionButtons({
  loading,
  canSubmit,
  onCancel,
}: ActionButtonsProps) {
  return (
    <div className={styles.container}>
      {canSubmit && (
        <Button
          className={styles.cancelButton}
          type="button"
          variant="plain-text"
          onClick={onCancel}
          loading={loading}
        >
          <Text variant="heading-16">Cancel</Text>
        </Button>
      )}
      <Button
        variant="primary"
        type="submit"
        disabled={!canSubmit && !loading}
        loading={loading}
      >
        Save Changes
      </Button>
    </div>
  );
}
