import { UserFeatureKeys } from '../app/lib/Split';
import { useExperimentContext } from '../experiments';
import { AddDogForm, AddDogEmail } from './features';

export function AddDog() {
  const { useFeature } = useExperimentContext();

  const isAddDogFormEnabled =
    useFeature?.(UserFeatureKeys.adj_add_dog_form, {}).treatment === 'on';

  return isAddDogFormEnabled ? <AddDogForm /> : <AddDogEmail />;
}
