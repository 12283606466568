import { QueryParameter } from '@farmersdog/constants';
import {
  PAW_PATH_ACCOUNT,
  PAW_PATH_ADD_DOG,
  PAW_PATH_CANCEL_REASONS,
  PAW_PATH_PRE_CANCEL,
  PAW_PATH_REACTIVATE,
  PAW_PATH_RECIPES,
  PAW_PATH_TEMPORARY_PAUSE,
  PAW_SUGGEST_ORDER_SIZE,
  PAW_PATH_PAYMENT_RETRY_SUCCESS,
  PAW_PATH_PAYMENT_RETRY_FAILURE,
  PAW_PATH_PAYMENT_RETRY,
  PAW_SUGGEST_ORDER_SIZE_UP,
} from '@farmersdog/constants/paths';
import { StripeProvider, StripeElementsProvider } from '@farmersdog/corgi-x';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AddDog } from '../add-dog';
import { AppProps } from '../app/App.types';
import { CancellationFlow } from '../cancellation-reasons';
import NotFound from '../components/NotFound';
import config from '../config/config';
import { EditFreshRecipes } from '../edit-fresh-recipes';
import { PreCancel } from '../pre-cancel';
import { ReactivationFlow } from '../reactivation';
import { SuggestOrderSize } from '../suggest-order-size';
import { OrderSizeUpEligibilityWrapper } from '../suggest-order-size-up';
import { TemporaryPause } from '../temporary-pause';
import { getPathQueryValue } from '../utils';
import { CustomerAccountData } from './network';
import { getDefaultAccountPath } from './utils';
import {
  PaymentRetry,
  PaymentRetrySuccess,
  PaymentRetryFailure,
} from '../payment-retry';

interface AccountRouteProps {
  dispatchErrorModal?: AppProps['dispatchErrorModal'];
  customerAccountData: CustomerAccountData;
}

export function AccountRoutes(props: AccountRouteProps) {
  const stripeKey = config.get('stripe.publicKey');
  const location = useLocation();
  const { withRegularOrder, subscription, isSubscriptionActive } =
    props.customerAccountData;

  const defaultAccountPath = getDefaultAccountPath({
    isSubscriptionActive,
    withRegularOrder,
    subscriptionType: subscription?.type,
  });

  const petNameQuery = getPathQueryValue(
    location.search,
    QueryParameter.PetName
  );

  return (
    <Switch>
      <Redirect exact from={PAW_PATH_ACCOUNT} to={defaultAccountPath} />
      {props.customerAccountData.isSubscriptionFresh &&
        props.customerAccountData.isSubscriptionSuspended &&
        !props.customerAccountData.isCustomerFlaggedForFraud && (
          <Route path={PAW_PATH_REACTIVATE}>
            <StripeProvider publishableKey={stripeKey}>
              <StripeElementsProvider>
                <ReactivationFlow
                  dispatchErrorModal={props.dispatchErrorModal}
                />
              </StripeElementsProvider>
            </StripeProvider>
          </Route>
        )}
      {props.customerAccountData.isSubscriptionFresh &&
        props.customerAccountData.isSubscriptionActive && (
          <Route path={PAW_PATH_CANCEL_REASONS}>
            <CancellationFlow />
          </Route>
        )}
      {props.customerAccountData.isSubscriptionFresh &&
        props.customerAccountData.isSubscriptionActive && (
          <Route path={PAW_PATH_PRE_CANCEL}>
            <PreCancel />
          </Route>
        )}

      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route path={PAW_PATH_TEMPORARY_PAUSE}>
            <TemporaryPause />
          </Route>
        )}

      {petNameQuery &&
        props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route path={PAW_PATH_RECIPES}>
            <EditFreshRecipes petName={petNameQuery} />
          </Route>
        )}

      <Route exact path={PAW_PATH_ADD_DOG}>
        <AddDog />
      </Route>

      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route exact path={PAW_SUGGEST_ORDER_SIZE}>
            <SuggestOrderSize />
          </Route>
        )}
      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route exact path={PAW_SUGGEST_ORDER_SIZE_UP}>
            <OrderSizeUpEligibilityWrapper />
          </Route>
        )}
      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route exact path={PAW_PATH_PAYMENT_RETRY}>
            <PaymentRetry />
          </Route>
        )}
      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route exact path={PAW_PATH_PAYMENT_RETRY_SUCCESS}>
            <PaymentRetrySuccess />
          </Route>
        )}
      {props.customerAccountData.isSubscriptionActive &&
        props.customerAccountData.isSubscriptionFresh && (
          <Route exact path={PAW_PATH_PAYMENT_RETRY_FAILURE}>
            <PaymentRetryFailure />
          </Route>
        )}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
