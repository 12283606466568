import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

export interface SplitImpressionListener {
  (impressionData: SplitIO.ImpressionData): void;
}

export interface ImpressionLoggerConstructor {
  ignoreList?: string[];
}

/**
 * Stateful class that caches impressions by `keyName`, `feature`, and deep
 * equality of impression attributes.
 */
export class ImpressionLogger {
  cache = new Map<string, SplitIO.ImpressionData>();
  listeners = new Set<SplitImpressionListener>();
  ignoreList: Set<string>;

  constructor({ ignoreList }: ImpressionLoggerConstructor = {}) {
    this.ignoreList = new Set(ignoreList);
  }

  /**
   * Add a function to listen for distinct split impressions. Distinctness is by
   * `keyName`, `feature` and deep equality for impression data.
   *
   * @param fn - The impression listener
   */
  addListener(fn: SplitImpressionListener): () => void {
    this.listeners.add(fn);
    return () => this.listeners.delete(fn);
  }

  /**
   * Log an impression from split
   *
   * @param impressionData - The impression data to log
   */
  logImpression(impressionData: SplitIO.ImpressionData) {
    const attributes = impressionData.attributes;
    const { feature } = impressionData.impression;

    if (this.ignoreList.has(feature)) {
      return;
    }

    const cacheKey = this.getCacheKey(impressionData);
    const hasCacheHit = this.cache.has(cacheKey);
    const cachedImpressionData = this.cache.get(cacheKey);

    const comparableAttributes = omit(attributes, ['current_date']);
    const cachedAttributes = omit(cachedImpressionData?.attributes, [
      'current_date',
    ]);

    if (hasCacheHit && isEqual(comparableAttributes, cachedAttributes)) {
      return;
    }

    this.publish(impressionData);
    this.cache.set(cacheKey, impressionData);
  }

  /**
   * Return the cache key based on impression data
   *
   * @param impressionData - The impression data to use for computing the key
   */
  private getCacheKey(impressionData: SplitIO.ImpressionData) {
    const { keyName, feature, treatment } = impressionData.impression;

    return `${keyName}-${feature}-${treatment}`;
  }

  /**
   * Publish impression data to each listener
   *
   * @param impressionData - The impression data
   */
  private publish(impressionData: SplitIO.ImpressionData) {
    this.listeners.forEach(listener => listener(impressionData));
  }
}
