import { datadogRum } from './datadogRum';

interface IdentifyUser {
  id: number;
  email: string;
  firstName: string;
}

interface DatadogService {
  error(error: unknown, context?: object): void;
  identify(user?: Partial<IdentifyUser>): void;
}

export const datadog: DatadogService = {
  /**
   * Used to identify a user by id, email and name in the datadog RUM traces.
   *
   * @param user - The user to identify
   */
  identify(user?: Partial<IdentifyUser>) {
    if (!user) {
      return;
    }

    datadogRum.setUser({
      id: String(user.id),
      email: user.email,
      name: user.firstName,
    });
  },

  /**
   * Used to submit an error to datadog
   *
   * @param error - The error to submit to datadog
   */
  error(error: unknown, context?: object) {
    datadogRum.addError(error, context);
  },
};
