import { PAW_SUGGEST_ORDER_SIZE_UP } from '@farmersdog/constants/paths';
import { reporter } from '../../../../../errors/services';
import { createSlottableComponent } from '../../../../../slots';
import { useFetchHomepageData } from '../../../../network';
import { MaybeLaterModal, useMaybeLaterModal } from '../../../MaybeLaterModal';
import { Nudge } from '../../../Nudge';
import {
  trackOrderSizeNudgeUpViewedNudgeHero,
  trackOrderSizeUpNudgeMaybeLater,
  trackOrderSizeUpNudgeSeePlanOptions,
} from './analytics';

import { useEligibility } from './hooks';
import * as assets from './assets';
import * as copy from './OrderSizeUpDismissibleCta.copy';
import { useYearlySavings } from './hooks/useYearlySavings';
import { useOrderSizeNudgeStorage } from '../OrderSizeDismissibleCta/hooks';
import { useEffect } from 'react';

function OrderSizeUpDismissibleCtaComponent() {
  const { data } = useFetchHomepageData({
    onError: (err: unknown) => {
      reporter.warn('Error retrieving data for the Order Size Up CTA', {
        sourceError: err,
      });
    },
  });

  useEffect(() => {
    trackOrderSizeNudgeUpViewedNudgeHero();
  }, []);

  const { isOpen, openModal, closeModal } = useMaybeLaterModal();
  // 👇 Using the same storage as Order Size Down nudge so that we don't show Order Size Down nudge once this nudge is dismissed
  const { getOrderSizeNudgeDismissed, setOrderSizeNudgeDismissed } =
    useOrderSizeNudgeStorage(window.localStorage);

  const yearlySavings = useYearlySavings();

  if (!yearlySavings) return null;

  const isDismissed = getOrderSizeNudgeDismissed();

  const nudgeBody = copy.templates.body({
    subscriptionFrequency: data?.customer.freshSubscription.frequency ?? 0,
    yearlySavings,
  });

  return (
    <>
      {/* Dismiss Confirm */}
      <MaybeLaterModal
        // Functionality
        isOpen={isOpen}
        onClose={closeModal}
        // Images
        imageSources={assets.maybeLaterBuddyJada.sources}
        imageAltText={assets.maybeLaterBuddyJada.altText}
        // Copy
        headlineText={copy.maybeLaterHeadline}
        bodyText={copy.maybeLaterBody}
        closeCtaText={copy.maybeLaterCloseCta}
        headlineColor={copy.colors.maybeLaterHeadline}
        bodyTextColor={copy.colors.maybeLaterBodyText}
      />
      {/* Dismissible CTA */}
      {!isDismissed && (
        <Nudge
          // Functionality
          onMaybeLaterClick={openModal}
          setNudgeDismissed={setOrderSizeNudgeDismissed}
          ctaOnClick={trackOrderSizeUpNudgeSeePlanOptions}
          trackDismissCta={trackOrderSizeUpNudgeMaybeLater}
          ctaLinkDestination={PAW_SUGGEST_ORDER_SIZE_UP}
          // Images
          imageSources={assets.planBox.sources}
          imageAltText={assets.planBox.altText}
          // Copy
          body={nudgeBody}
          headlineText={copy.headline}
          subheadText={copy.subhead}
          ctaText={copy.cta}
          componentTitle={copy.componentTitle}
          dismissCtaText={copy.dismissCta}
        />
      )}
    </>
  );
}

export const OrderSizeUpDismissibleCta = createSlottableComponent(
  OrderSizeUpDismissibleCtaComponent,
  useEligibility
);
