import { useState, useEffect } from 'react';
import { useFeature } from '../../abTesting';
import { useIsAuthenticatedPath } from '@farmersdog/paw/src/utils';

import { useHistory } from 'react-router';

import { GladlySidekickScript } from '@farmersdog/paw';
import { GladlyButton } from './GladlyButton';
import { GladlyService } from '@farmersdog/paw/src/cx/';
import { showLiveChat } from './utils';

export type GladlySidekickConfig = { appId: string };

export function GladlySidekick() {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const history = useHistory();
  const shouldDisplayGladlySidekick =
    useFeature('tsx_gladly_live_chat').treatment === 'on';

  const { config } = useFeature<unknown, GladlySidekickConfig>(
    'adj_cx_chat_ab_testing'
  );

  const isAuthenticatedPath = useIsAuthenticatedPath();

  useEffect(() => {
    if (!shouldDisplayGladlySidekick || !isAuthenticatedPath) {
      setIsButtonVisible(false);
      return;
    }

    setIsButtonVisible(true);

    const unlisten = history.listen(() => {
      GladlyService.navigate();
    });
    return unlisten;
  }, [shouldDisplayGladlySidekick, isAuthenticatedPath, history]);

  return (
    <>
      <GladlyButton
        isVisible={isButtonVisible ? true : false}
        onClick={() => showLiveChat(history.location.pathname, 'gladly-button')}
      />
      <GladlySidekickScript splitGladlyAppId={config.appId} />
    </>
  );
}
