import { Link } from '@farmersdog/corgi';
import { ActivityIndicator, Text } from '@farmersdog/corgi-x';

import { useJobs } from './useJobs';

import styles from './JobsList.module.scss';

/**
 * Render a list of jobs from our greenhouse profile
 */
export function JobsList() {
  const { jobsByDepartment, loading } = useJobs();

  if (loading) {
    return (
      <div className={styles.loading}>
        <ActivityIndicator mode="dark" />
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {Object.entries(jobsByDepartment).map(([department, jobs]) => (
        <li className={styles.department} key={department}>
          <Text
            as="h3"
            variant="heading-22"
            bold
            color="kale-3"
            className={styles.departmentHeader}
          >
            {department}
          </Text>
          <ul className={styles.list}>
            {jobs.map((job, i) => (
              <Text
                as="li"
                key={`${department}-${i}`}
                variant="heading-16"
                color="white"
                className={styles.job}
              >
                <Link variant="primary" href={job.absolute_url}>
                  {job.title}
                </Link>
                {job.location && job.location.name && (
                  <Text as="span" variant="heading-16" color="charcoal-1">
                    <br />
                    {job.location.name}
                  </Text>
                )}
              </Text>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
