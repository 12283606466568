import { useId } from 'react';
import { Link, Meter, Text } from '@farmersdog/corgi-x';
import { PATH_CALIBRATE_PACKS } from '@farmersdog/constants/paths';
import styles from './StockMeter.module.css';
import { pluralizeString } from '../../../utils';

interface StockMeterProps {
  petNames: string[];
  currentPackCount: number;
  previousOrderQuantity: number;
}

export function StockMeter({
  petNames,
  currentPackCount,
  previousOrderQuantity,
}: StockMeterProps) {
  const currentPackCountString = pluralizeString(
    currentPackCount,
    'pack',
    'packs'
  );

  const packsRemainingText = petNames?.length > 1 ? 'all dogs' : petNames[0];

  const stockMeterId = useId();
  return (
    <section aria-labelledby={stockMeterId} className={styles.section}>
      <Meter
        className={styles.meter}
        current={currentPackCount}
        max={previousOrderQuantity}
        fullLabel="Full"
        emptyLabel="Out"
      >
        <div className={styles.stockDetailsContainer}>
          <Text id={stockMeterId} variant="heading-22" bold>
            {currentPackCount} full {currentPackCountString}
          </Text>
          <Text variant="heading-12">for {packsRemainingText}</Text>
        </div>
      </Meter>
      <Link to={PATH_CALIBRATE_PACKS} className={styles.editLink}>
        <Text variant="heading-16" bold color="carrot-2">
          Edit
        </Text>
      </Link>
    </section>
  );
}
