import { useEffect, useState, useMemo } from 'react';

import { Logger } from '@farmersdog/logger';

import { usePetsWithRecommendedCalories } from '../../../graphql/queries/usePetsWithRecommendedCalories';

export interface PetNamesAndRequestedCalories {
  petName: string;
  requestedCalories: number;
}

export function useGetPetNamesAndRecommendedCalories():
  | PetNamesAndRequestedCalories[]
  | undefined {
  const {
    refetch: getPetNamesAndRecommendedCalories,
    loading,
    called,
  } = usePetsWithRecommendedCalories({
    skip: true,
    fetchPolicy: 'network-only', // make sure most up-to-date rec is fetched
  });

  const [petNamesAndRequestedCalories, setPetNamesAndRequestedCalories] =
    useState<PetNamesAndRequestedCalories[] | undefined>(undefined);

  const logger = useMemo(() => {
    return new Logger('tosa:frontend:useGetRecommendedCalories');
  }, []);

  useEffect(() => {
    if (called || loading) {
      return;
    }

    async function fetchLeadDataWithRecommendedCalories() {
      try {
        const leadPetData = await getPetNamesAndRecommendedCalories();
        const mappedPetData = leadPetData.data.lead.pets.map(pet => ({
          petName: pet.name,
          requestedCalories: pet.recommendedCalories,
        }));

        const everyPetHasRecommendedCalories = mappedPetData?.every(
          (pet): pet is PetNamesAndRequestedCalories =>
            typeof pet.requestedCalories === 'number'
        );
        if (everyPetHasRecommendedCalories) {
          setPetNamesAndRequestedCalories(mappedPetData);
        } else {
          logger.error('Not all pets have recommended calories', {
            mappedPetData,
          });
        }
      } catch (error) {
        logger.error('Failed to fetch recommended calories', {
          error,
        });
      }
    }

    void fetchLeadDataWithRecommendedCalories();
  }, [getPetNamesAndRecommendedCalories, called, loading, logger]);

  return petNamesAndRequestedCalories;
}
