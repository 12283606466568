import { getQuestionStates } from '../data';

/**
 * A util that returns the question corresponding to a particular state
 * (if a question exists).
 *
 * @remarks This util allows easier mocking for tests.
 *
 * @param state - the current state in the flow
 * @returns a string (the question) or undefined if no question exists
 */

export function getQuestionForState(state: string): string | undefined {
  const questionStates = getQuestionStates();
  return questionStates[state]?.question;
}
