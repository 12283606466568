import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import browserOnly from 'src/utils/browserOnly';

/** These functions should be removed when we find a better way to handle modal routes */
let shouldRestoreScroll = true;

export function disableScrollRestoration() {
  shouldRestoreScroll = false;
}

export function enableScrollRestoration() {
  shouldRestoreScroll = true;
}

/**
 * On pathname change restore cache and scroll to initial state
 */
function usePathRestoration() {
  const { pathname, hash } = useLocation();
  const client = useApolloClient();

  const resetApolloCache = () => {
    try {
      client.cache.reset();
    } catch (e) {
      // No cache do nothing
    }
  };

  // Reset apollo cache on every new page
  // TODO: Remove this when we are completely on graphql
  useEffect(
    () => resetApolloCache,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  useEffect(() => {
    if (!shouldRestoreScroll || hash) return;

    browserOnly(window => {
      window.scrollTo(0, 0);
    });
  }, [pathname, hash]);
}

export default usePathRestoration;
