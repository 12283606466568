import { handlePetNamesWhitespace } from './handlePetNamesWhitespace';
import styles from './StyledLabel.module.css';

const PET_NAME_TRAILING_CHARS = [',', '’s', '’'];
const WHITESPACE = ' ';
const NON_BREAKING_SPACE = '\u00A0';

interface StyledLabelProps {
  label: string | undefined;
  petNames: string[];
  shouldApplyPetNameStyle: boolean;
}

const stringIsPetName = (str: string, petNamesMap: Record<string, string>) =>
  Object.keys(petNamesMap).includes(str);

export const StyledLabel = ({
  label,
  petNames,
  shouldApplyPetNameStyle,
}: StyledLabelProps) => {
  if (!label) {
    return null;
  }
  const [comma, possessive, apostrophe] = PET_NAME_TRAILING_CHARS;

  const { petNamesWithoutWhitespaceLabel, petNamesMap } =
    handlePetNamesWhitespace({ label, petNames });

  const renderWord = (word: string, options?: { nonBreaking: boolean }) => {
    const itemWithoutTrailingChars = word
      .replace(comma, '')
      .replace(possessive, '')
      .replace(apostrophe, '');

    if (!stringIsPetName(itemWithoutTrailingChars, petNamesMap)) {
      return (
        <>
          {word}
          {options?.nonBreaking ? NON_BREAKING_SPACE : WHITESPACE}
        </>
      );
    }

    return (
      <>
        <span
          className={shouldApplyPetNameStyle ? styles.petName : ''}
          data-bold={shouldApplyPetNameStyle} // allows us to test this component
        >
          {petNamesMap[itemWithoutTrailingChars]}
        </span>
        {word.endsWith(comma) && comma}
        {word.endsWith(possessive) && possessive}
        {WHITESPACE}
      </>
    );
  };

  const styled = petNamesWithoutWhitespaceLabel.split(WHITESPACE).map(item => {
    // capture non-breaking space and split it into separate words to render
    const nonBreakingText = item.trim().split(NON_BREAKING_SPACE);
    const itemIsSingleWord = nonBreakingText.length === 1;

    return nonBreakingText.map(word =>
      renderWord(word, { nonBreaking: !itemIsSingleWord })
    );
  });

  return <>{styled}</>;
};
