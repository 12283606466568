import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import { Section } from '../Section';
import { getGenderPossessivePronoun } from '../utils/getGenderPossessivePronoun';

import styles from './HowItWorks.module.css';
import { ProgressContainer } from './ProgressContainer';

interface HowItWorksProps {
  className?: string;
  petName: string;
  petGender: string;
  isGraySection?: boolean;
}

export function HowItWorks({
  petName,
  petGender,
  isGraySection,
  className,
}: HowItWorksProps) {
  const pronoun = getGenderPossessivePronoun(petGender);
  return (
    <Section backgroundColor={isGraySection ? 'gray' : 'white'}>
      <Grid className={className} rowGap="xl">
        <GridItem
          flexDirection="column"
          alignItems={{ xs: 'center', lg: 'flex-start' }}
        >
          <Text
            className={styles.title}
            as="h3"
            variant="heading-12"
            color="charcoal-3"
            bold
          >
            How it works
          </Text>
          <Text
            as="h2"
            variant="heading-40"
            color="kale-3"
            topSpacing="xs"
            bold
          >
            {petName}’s Plan
          </Text>
          <Text
            className={styles.description}
            topSpacing="sm"
            bottomSpacing="none"
            as="p"
            color="kale-3"
            variant="article-16"
          >
            <span>
              All you need to know to get {petName} started on {pronoun}
            </span>
            <span className={styles.freshFoodJourney}>
              {' '}
              fresh food journey:
            </span>
          </Text>
        </GridItem>
        <GridItem
          justifyContent={{
            xs: 'center',
            lg: 'flex-start',
          }}
        >
          <ProgressContainer />
        </GridItem>
      </Grid>
    </Section>
  );
}
