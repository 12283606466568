import classNames from 'classnames';
import { Text } from '@farmersdog/corgi-x';

import { Kibble } from './Kibble';
import styles from './KibbleBull.module.scss';

export interface KibbleBullProps {
  /** Optional class name */
  className?: string;
}

const MEATS = ['Chicken', 'Beef', 'Turkey'];

/**
 * Render a graphic with kibble and copy
 */
export function KibbleBull({ className }: KibbleBullProps) {
  return (
    <figure
      aria-label="Whether kibble companies claim their product is made with chicken, turkey, or beef, it's all the same: Complete bull."
      className={classNames(styles.container, className)}
    >
      <Text variant="heading-28" color="kale-3" as="div" bold>
        {MEATS.map(meat => (
          <div key={meat} className={styles.meat}>
            {meat}
            <Kibble />
          </div>
        ))}
        <div className={styles.bull}>Bull.</div>
      </Text>
    </figure>
  );
}
