import events from '../eventNames';
import track from '../track';

type TrackSignupFreeTreatsOpenNutritionFactsArgs = {
  name: string;
  size: string;
};

/** A Segment tracking event for when a user opens the nutrition facts modal for a treat in the checkout page. */

export function trackSignupFreeTreatsOpenNutritionFacts({
  name,
  size,
}: TrackSignupFreeTreatsOpenNutritionFactsArgs) {
  track(events.signup_free_treats_open_nutrition_facts, {
    name,
    size,
  });
}
