import { createElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scale } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';
import Benefit from '../Benefit';
import BenefitCarousel from '../BenefitCarousel';
import CallToAction from '../CallToAction';

import useImperativeScroll from 'src/hooks/useImperativeScroll';

import QUOTES from './quotes';
import before from './assets/before';
import after from './assets/after';
import LabeledPicture from './LabeledPicture';
import styles from './BenefitWeight.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function BenefitWeight({ innerRef }) {
  const containerRef = useImperativeScroll(innerRef);

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div
      id="benefit-weight"
      ref={containerRef}
      className={classNames(styles.container, useCorgiViewport(styles), {
        [styles.oysterBackground]: isAccessibilityThemeOn,
      })}
    >
      <div className={styles.content}>
        <Benefit
          icon={<Scale height={24} fill="kale-3" />}
          title="Attaining Optimal Weight"
          quote={
            <>
              Over the years, Maddy went from being a little overweight to a lot
              overweight. I felt like I’d tried everything to get it off. I
              ordered her every recipe... She loved them all! Soon, we started
              to notice the weight loss and she was jumping up on our bed again,
              which she hadn’t been able to do in years!
            </>
          }
          author={
            <Text variant="heading-16" color="charcoal-3">
              — Maddy‘s mom
            </Text>
          }
          picture={
            <div className={styles.picture}>
              <LabeledPicture
                className={styles.before}
                sources={before}
                label="Before"
                alt="attaining optimal weight before"
              />
              <LabeledPicture
                className={styles.after}
                sources={after}
                label="After"
                alt="attaining optimal weight after"
              />
            </div>
          }
        />
      </div>
      <BenefitCarousel quotes={QUOTES} />
      <div
        className={classNames(useCorgiViewport(styles), {
          [styles.ctaPadding]: isAccessibilityThemeOn,
        })}
      >
        <CallToAction>Create My Plan</CallToAction>
      </div>
    </div>
  );
}

BenefitWeight.propTypes = {
  innerRef: PropTypes.object,
};

// eslint-disable-next-line react/display-name
export default forwardRef((props, innerRef) =>
  createElement(BenefitWeight, { ...props, innerRef })
);
