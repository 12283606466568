import { Context, useContext } from 'react';
import { SplitContext, SplitContextValue } from '../context/SplitContext';

export function useSplitContext<T extends object, U extends object>() {
  const context = useContext(SplitContext as Context<SplitContextValue<T, U>>);

  if (!context) {
    throw Error('Cannot use split context without provider');
  }

  return context;
}
