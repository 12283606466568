import config from 'src/config';
import { reporter } from 'src/services/reporter';
import { mountVendorWindowEvent } from '../utils';

export enum GoogleAdsEvent {
  Conversion = 'conversion',
}

export interface TrackGoogleAdsEventArgs {
  actionId: string;
  properties?: Record<string, unknown>;
}

function _trackGoogleAdsEvent(
  w: Window,
  { actionId, properties = {} }: TrackGoogleAdsEventArgs
) {
  if (!config('googleAds.enabled') || config('googleAds.tagId') === '') {
    return;
  }

  if (!w.gtag) {
    reporter.error(
      'Cannot run gtag command because "window.gtag" is not defined'
    );
    return;
  }

  w.gtag(
    'event',
    GoogleAdsEvent.Conversion,
    buildProperties({ properties, actionId })
  );
}

export const trackGoogleAdsEvent = mountVendorWindowEvent(
  'Google Ads',
  _trackGoogleAdsEvent
);

function buildProperties({ properties, actionId }: TrackGoogleAdsEventArgs) {
  return {
    ...properties,
    send_to: `${String(config('googleAds.tagId'))}/${actionId}`,
  };
}
