/**
 * Removes all - in a phone number and leading '1' (the country code)
 */
export function denormalizePhone(value: string) {
  let onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums[0] === '1') {
    onlyNums = onlyNums.slice(1);
  }
  return onlyNums.replace(/-/g, '').slice(0, 10);
}
