import { jwtDecode } from 'jwt-decode';

export const DEFAULT_EXPIRATION_DAYS = 180;

export function getTokenExpiration(token: string) {
  const decoded = jwtDecode(token);

  let expires: Date | number = DEFAULT_EXPIRATION_DAYS;
  if (decoded.exp) {
    expires = new Date(decoded.exp * 1000);
  }

  return expires;
}
