import classNames from 'classnames';
import { useEffect, useState } from 'react';

import {
  Picture,
  Text,
  useHtmlElement,
  useIntersection,
} from '@farmersdog/corgi-x';

import styles from './WeightLossItem.module.css';

interface WeightLossItemProps {
  title: string;
  description: string | JSX.Element;
  src: {
    placeholder: string[];
    animation: string[];
  };
  isReverse?: boolean;
}

export function WeightLossItem({
  title,
  description,
  src,
  isReverse,
}: WeightLossItemProps) {
  const [animationEl, animationElRef] = useHtmlElement();

  const intersection = useIntersection(animationEl);

  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (animationEl && intersection && !showAnimation) {
      setShowAnimation(intersection.isIntersecting);
    }
  }, [animationEl, intersection, showAnimation]);

  return (
    <div>
      <div
        className={classNames(styles.container, {
          [styles.reverse]: isReverse,
        })}
      >
        <div className={styles.animation}>
          <Picture
            className={styles.animation}
            sources={showAnimation ? src.animation : src.placeholder}
            alt=""
            aria-hidden="true"
          />
        </div>

        <div className={styles.textsContainer}>
          <Text variant="heading-16" bold color="charcoal-3">
            {title}
          </Text>
          <Text variant="heading-16" color="charcoal-3">
            {description}
          </Text>
        </div>
      </div>
      <span ref={animationElRef} />
    </div>
  );
}
