import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { useLocation } from 'react-router-dom';

interface UseIsOnGetStartedCardArgs {
  isTOSA: boolean;
}
export function useIsOnGetStartedCard({ isTOSA }: UseIsOnGetStartedCardArgs) {
  const { pathname, search } = useLocation();

  if (!isTOSA) {
    return false;
  }

  return (
    pathname.includes(PATH_SIGNUP_ME) &&
    search.toLowerCase().includes('getstarted')
  );
}
