import desktopLifestyleBowlJpg from './lifestyle-bowl-desktop.jpg';
import desktopLifestyleBowlWebp from './lifestyle-bowl-desktop.webp';

import mobileLifestyleBowlJpg from './lifestyle-bowl-mobile.jpg';
import mobileLifestyleBowlWebp from './lifestyle-bowl-mobile.webp';

export const mobileLifestyleBowl = {
  webP: mobileLifestyleBowlWebp,
  jpg: mobileLifestyleBowlJpg,
};

export const desktopLifestyleBowl = {
  webP: desktopLifestyleBowlWebp,
  jpg: desktopLifestyleBowlJpg,
};
