import { useState } from 'react';

import slides from './slides';
import firstSlide, { SLIDE_NAME as FIRST_SLIDE } from './RootDecision';

const getCurrentSlideIndex = (currentSlide, renderSlides) => {
  const slideNames = renderSlides.map(slide => slide.name);
  return Math.max(0, slideNames.lastIndexOf(currentSlide));
};

function useSlides() {
  const [renderSlides, setRenderSlides] = useState([
    { name: FIRST_SLIDE, slide: firstSlide },
  ]);
  const [previousSlides, setPreviousSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(FIRST_SLIDE);

  const currentSlideIndex = getCurrentSlideIndex(currentSlide, renderSlides);
  const viewedSlides = [...previousSlides, currentSlide];

  const updateSlides = nextSlide => {
    setRenderSlides([
      ...renderSlides,
      { name: nextSlide, slide: slides[nextSlide] },
    ]);
    setCurrentSlide(nextSlide);
    setPreviousSlides(viewedSlides);
  };

  const goToPrevSlide = () => {
    setCurrentSlide(previousSlides[previousSlides.length - 1]);
    setPreviousSlides(previousSlides.slice(0, -1));
    setRenderSlides(renderSlides.slice(0, -1));
  };

  return {
    renderSlides,
    previousSlides,
    currentSlide,
    currentSlideIndex,
    goToPrevSlide,
    updateSlides,
  };
}

export default useSlides;
