// age
export enum BirthdayAccuracy {
  Months = 'months',
  Weeks = 'weeks',
  Years = 'years',
}

export const YEAR_IN_WEEKS = 52;
export const MONTH_IN_WEEKS = 4;

export const MIN_AGE_WEEKS = 8;
export const MAX_AGE_WEEKS = 30 * YEAR_IN_WEEKS;

// weight in grams
export const PET_MAX_WEIGHT = 158757;
export const PET_MIN_WEIGHT = 44.0564;

export const MAX_NUMBER_OF_PETS = 8;

export const PHONE_REGEXP =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export const ZIP_REGEXP = /^\d{5}$/;

export const GENERIC_ERROR_MESSAGE = 'An error occurred.';

export enum FreshFoodConfidenceLevels {
  dontBelieve = 'don’t believe',
  alreadyBelieve = 'already believe',
  notSure = 'am not yet sure',
}

export enum BodyCondition {
  Rounded = 'rounded',
  Chunky = 'chunky',
  JustRight = 'just right',
  TooSkinny = 'too skinny',
}

export enum ActivityLevel {
  NotVeryActive = 'not very active',
  Active = 'active',
  VeryActive = 'very active',
  ProAthlete = 'pro athlete',
}

export enum EatingStyle {
  VeryPicky = 'very picky',
  CanBePicky = 'can be picky',
  GoodEater = 'good eater',
  WillEatAnything = 'will eat anything',
}

export enum TreatsQuantity {
  Some = 'some',
  No = 'no',
  ALotOf = 'a lot of',
}

// includes all enabled and disabled values from the db; needs to be updated when static data changes
export enum PrescriptionDietIssues {
  LowFat = 'Low Fat',
  JointSupport = 'Joint Support',
  WeightManagement = 'Weight Management',
  WeightLoss = 'Weight Loss',
  RenalDisease = 'Renal Disease',
  Diabetes = 'Diabetes',
  LiverSupport = 'Liver Support',
  NeoplasiaDiet = 'Neoplasia Diet',
  SkinSupport = 'Skin Support',
  HydrolyzedProtein = 'Hydrolyzed Protein',
  GiPancreatitisDiet = 'GI/Pancreatitis Diet',
  Other = 'Other',
  GiDiet = 'GI diet',
  PancreatitisDiet = 'Pancreatitis diet',
}

// includes all enabled and disabled values from the db; needs to be updated when static data changes
export enum HealthIssue {
  Anxiety = 'Anxiety',
  BadBreath = 'Bad breath',
  Cancer = 'Cancer',
  ChewsPaws = 'Chews paws',
  Constipation = 'Constipation',
  Diarrhea = 'Diarrhea',
  EarInfections = 'Ear infections',
  EnvironmentalAllergies = 'Environmental allergies',
  FoodAllergies = 'Food allergies',
  GlutenSensitive = 'Gluten sensitive',
  GrainSensitive = 'Grain sensitive',
  Hyperactive = 'Hyperactive',
  HyperlipidemiaOrHypertriglyceridemia = 'Hyperlipidemia / Hypertriglyceridemia',
  PancreatitisHistory = 'History of Pancreatitis',
  Itchiness = 'Itchiness',
  Joints = 'Joints',
  Lethargy = 'Lethargy',
  PassesGas = 'Passes Gas',
  Seizures = 'Seizures',
  SkinAndCoat = 'Skin and Coat',
  TearStains = 'Tear stains',
  UrinaryTract = 'Urinary tract',
  Vomiting = 'Vomiting',
}
