import { DateSelect } from '../DateSelect';

type RescheduleDatesProps = {
  /** List of dates */
  dates: string[];
  /** The currently scheduled date for the next order */
  currentNextDate: string;
  /** The date the user is rescheduling. */
  rescheduledDate?: string;
  /** Which one of them should appear as "unavailable" (e.g. is too early). */
  unavailableDate?: string;
  /** Fired when the user selects a date. */
  onChange: (rescheduledDate: string) => void;
  /** has user selected a valid different date at least once? */
  didUserSelectADate: boolean;
};

/**
 * Render a list of dates to pick, grouped by month.
 */
export function RescheduleDates({
  dates,
  rescheduledDate,
  currentNextDate,
  unavailableDate,
  onChange,
  didUserSelectADate,
}: RescheduleDatesProps) {
  return (
    <DateSelect
      dates={dates}
      selectedDate={rescheduledDate}
      currentNextDate={currentNextDate}
      unavailableDate={unavailableDate}
      onChange={onChange}
      didUserSelectADate={didUserSelectADate}
    />
  );
}
