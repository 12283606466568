import { Button, ContentLoader, useViewport } from '@farmersdog/corgi-x';
import { useEffect, useState } from 'react';
import { ClinicSearch } from '../ClinicSearch';
import styles from './ClinicSearchAndSavedClinics.module.css';
import { CustomerClinics, SavedClinics } from '../SavedClinics/SavedClinics';
import { useGetClinics } from '../../network/useGetClinics';
import { customScroll } from '../../utils';

interface ClinicSearchAndSavedClinicsProps {
  shouldShowVetClinicSearch: boolean;
  parentId: string;
  setHideComponentIfSearchOffAndNoClinics: (arg: boolean) => void;
}
export function ClinicSearchAndSavedClinics({
  shouldShowVetClinicSearch,
  parentId,
  setHideComponentIfSearchOffAndNoClinics,
}: ClinicSearchAndSavedClinicsProps) {
  const { medium } = useViewport();
  const loadingHeight = medium ? 120 : 241;
  const [displayVetClinicSearchIfSaved, setDisplayVetClinicSearchIfSaved] =
    useState(false);

  const [recentlySavedClinicId, setRecentlySavedClinicId] = useState('');
  const {
    data: savedClinicsResponse,
    loading: getClinicsLoading,
    error,
    refetch,
  } = useGetClinics();

  let savedClinics;
  if (error) {
    savedClinics = [];
  }

  savedClinics =
    (savedClinicsResponse?.petHealth?.clinics as CustomerClinics) || [];

  useEffect(() => {
    if (
      !getClinicsLoading &&
      !savedClinics?.length &&
      !shouldShowVetClinicSearch
    ) {
      setHideComponentIfSearchOffAndNoClinics(true);
    }
  }, [
    getClinicsLoading,
    setHideComponentIfSearchOffAndNoClinics,
    savedClinics,
    shouldShowVetClinicSearch,
  ]);

  const displayVetClinicSearch =
    shouldShowVetClinicSearch &&
    ((savedClinics?.length && displayVetClinicSearchIfSaved) ||
      !savedClinics?.length ||
      !savedClinics);

  const displaySavedClinics =
    !displayVetClinicSearch && savedClinics?.length > 0;

  const displayAddAnotherVetButton =
    shouldShowVetClinicSearch &&
    !displayVetClinicSearch &&
    savedClinics?.length;

  const handleAddAnotherVetClinic = () => {
    setDisplayVetClinicSearchIfSaved(prev => !prev);
    customScroll({ id: parentId, offset: -200 });
  };
  return (
    <section aria-label="ClinicSearchAndSavedClinics">
      <ContentLoader
        loading={getClinicsLoading}
        height={loadingHeight}
        mode={'dark'}
      >
        {displayVetClinicSearch && (
          <ClinicSearch
            setDisplayVetClinicSearchIfSaved={setDisplayVetClinicSearchIfSaved}
            refetchSavedClinics={refetch}
            setRecentlySavedClinicId={setRecentlySavedClinicId}
            showBackButton={savedClinics?.length > 0}
            parentId={parentId}
          />
        )}
        {displaySavedClinics && (
          <SavedClinics
            clinics={savedClinics}
            recentlySavedClinicId={recentlySavedClinicId}
            refetchSavedClinics={refetch}
          />
        )}
        {displayAddAnotherVetButton && (
          <div className={styles.addVetClinic}>
            <Button variant="secondary" onClick={handleAddAnotherVetClinic}>
              Add Another Vet Clinic
            </Button>
          </div>
        )}
      </ContentLoader>
    </section>
  );
}
