import { ExperimentAttributes } from '../../../../../app/lib/Split/features/OnbHomepagePersonalizationPicky';

export function isEligibleForExperiment({
  hasOverweightPet,
  hasPickyEater,
}: Pick<ExperimentAttributes, 'hasOverweightPet' | 'hasPickyEater'>): boolean {
  const eligibilityChecks = [
    // No overweight pets
    hasOverweightPet === false,
    // Picky eaters only
    hasPickyEater === true,
  ];

  // All checks must pass
  return eligibilityChecks.every(Boolean);
}
