import { reporter } from '../../../../../../../errors/services';
import type { SlotEligibility } from '../../../../../../../slots/types';
import { useFetchHomepageData } from '../../../../../../network';
import { useTreatsNudgeStorage } from '../useTreatsNudgeStorage';

/**
 * Checks if the user is eligible to see the Treats Nudge
 */
export function useEligibility(): SlotEligibility {
  const request = useFetchHomepageData({
    onError: error => {
      reporter.warn('Error loading Treats Nudge Data', {
        sourceError: error,
      });
    },
  });

  const { isTreatsNudgeDismissed } = useTreatsNudgeStorage(window.localStorage);

  const shouldSuggestTreatsNudge = !request?.data?.customer?.hasTreatsInOrders;

  if (request.loading || request.error) {
    return {
      isReady: false,
      isCandidate: false,
    };
  }

  return {
    isReady: true,
    isCandidate: !isTreatsNudgeDismissed && shouldSuggestTreatsNudge,
  };
}
