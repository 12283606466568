import PropTypes from 'prop-types';
import {
  Link as ReactRouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Link, Text } from '@farmersdog/corgi';
import { Logo } from '@farmersdog/corgi-x';
import {
  PATH_HOME,
  PATH_SIGNUP_SUCCESS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_PLANS,
  PATH_WEBSITE_HOMEPAGE,
} from '@farmersdog/constants/paths';
import Sticky from 'src/components/Sticky';
import { Viewport } from 'src/screen';
import Flex from 'src/components/_depreciated/Flex';

import HeaderProgress from './HeaderProgress';
import DiscountBadge from './DiscountBadge';
import useSteps from './steps';
import useProgress from 'src/pages/Signup/hooks/useProgress';
import { isFreshSignup } from '@farmersdog/lead-browser-storage';
import { getReferrerCookie } from 'src/utils/cookies';
import config from 'src/config';

import classNames from 'classnames';

import { BackButton } from './BackButton';
import { PreviousLinkNavigationLabel } from '@farmersdog/tosa/src/hooks/usePreviousLinkNavigation';

import styles from './SignupHeader.module.scss';

function CollapsedView({
  homeLink,
  steps,
  showNavigation,
  showDiscountBadge,
  homeLinkAccessibleText,
}) {
  const activeStepIndex = steps.findIndex(step => step.active);
  const { path, label } = steps[activeStepIndex] || {};

  return (
    <header className={styles.header} style={{ padding: '5px 10px' }}>
      <Flex alignItems="center" style={{ position: 'relative' }} wrap="wrap">
        <Flex style={{ width: 60, marginRight: 'auto' }}>
          <ReactRouterLink
            to={homeLink}
            aria-label={homeLinkAccessibleText}
            data-name="logo-link"
          >
            <Logo variant="product" size={50} style={{ marginBottom: 10 }} />
          </ReactRouterLink>
        </Flex>
        {path && showNavigation && (
          <Flex grow={1} wrap="wrap">
            <nav role="navigation" className={styles.nav}>
              <div className={classNames(styles.links, styles.mobileLinks)}>
                <Text variant="heading-21" className={styles.linkText}>
                  <Link
                    to={path}
                    className={styles.link}
                    activeClassName={styles.active}
                    variant="secondary"
                    mode="dark"
                    isActive={() => true}
                    onClick={e => e.preventDefault()}
                  >
                    {label} ({activeStepIndex + 1}/{steps.length})
                  </Link>
                </Text>
              </div>
            </nav>
          </Flex>
        )}
        <Flex style={{ width: 60 }}>
          {showDiscountBadge ? (
            <DiscountBadge steps={steps} data-testid="discount-badge" />
          ) : (
            <span>&nbsp;</span>
          )}
        </Flex>
        {showNavigation && (
          <HeaderProgress
            steps={steps}
            style={{ position: 'absolute', top: 66, margin: '0 auto' }}
          />
        )}
      </Flex>
    </header>
  );
}

CollapsedView.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      completed: PropTypes.bool,
      active: PropTypes.bool,
      percentage: PropTypes.number,
    })
  ),
  showNavigation: PropTypes.bool.isRequired,
  showDiscountBadge: PropTypes.bool.isRequired,
  homeLink: PropTypes.string,
  homeLinkAccessibleText: PropTypes.string,
};

function WideView({ steps, showNavigation, showDiscountBadge, homeLink }) {
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);

  const headerClass = classNames(styles.header, {
    [styles.moveHigher]: isRecipesPage || isPlansPage,
  });

  return (
    <Sticky
      as="header"
      className={headerClass}
      style={{ top: 0, left: 0, padding: '0 50px' }}
    >
      <Flex
        style={{ position: 'relative', height: '100%' }}
        alignItems="center"
        wrap="wrap"
      >
        <Flex style={{ marginRight: 'auto' }}>
          <ReactRouterLink
            to={homeLink}
            aria-label={PreviousLinkNavigationLabel.homePage}
            data-name="logo-link"
            className={styles.desktopLogo}
          >
            <Logo variant="product" size={50} />
          </ReactRouterLink>
        </Flex>
        <Flex>{showNavigation && <BackButton />}</Flex>
        <Flex
          grow={1}
          direction="column"
          alignSelf="end"
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '800px' }}
        >
          {showNavigation && (
            <nav className={styles.nav} role="navigation">
              <div className={styles.links}>
                {steps.map((step, i) => {
                  const { path, label, active } = step;
                  const disabled = !steps
                    .slice(0, i + 1)
                    .every(s => s.completed);

                  return (
                    <Text
                      key={path}
                      variant="heading-21"
                      className={styles.linkText}
                    >
                      <Link
                        to={path}
                        variant="secondary"
                        mode="dark"
                        activeClassName={styles.active}
                        className={classNames(styles.link, {
                          [styles.disabled]: disabled,
                        })}
                        disabled={disabled}
                        isActive={() => active}
                        onClick={e => {
                          if (e && disabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {label}
                      </Link>
                    </Text>
                  );
                })}
              </div>
              <Flex
                style={{
                  padding: '0 12%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <HeaderProgress steps={steps} style={{ width: '100%' }} />
              </Flex>
            </nav>
          )}
        </Flex>
        <Flex style={{ width: 180, marginRight: 'auto' }}>
          {showDiscountBadge ? (
            <DiscountBadge steps={steps} data-testid="discount-badge" />
          ) : (
            <span>&nbsp;</span>
          )}
        </Flex>
      </Flex>
    </Sticky>
  );
}

WideView.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      completed: PropTypes.bool,
      active: PropTypes.bool,
      percentage: PropTypes.number,
    })
  ),
  showNavigation: PropTypes.bool.isRequired,
  showDiscountBadge: PropTypes.bool.isRequired,
  homeLink: PropTypes.string,
};

function SignupHeader() {
  const location = useLocation();
  const progress = useProgress();
  const steps = useSteps(progress, location.pathname);
  const lastCompletedStep = steps
    .filter(step => step.completed && !step.active)
    .slice(-1)[0];
  const isSuccessPage = useRouteMatch(PATH_SIGNUP_SUCCESS);

  const referrerCookie = getReferrerCookie();
  const referrerCookieDiscountCode = referrerCookie?.referrerCode;
  const browserExtensionCouponCodes = config(
    'features.browserExtensionCouponCodes'
  );
  const hasBrowserExtensionCouponInCookie =
    referrerCookieDiscountCode &&
    browserExtensionCouponCodes &&
    browserExtensionCouponCodes.includes(referrerCookieDiscountCode);

  const showDiscountBadge =
    hasBrowserExtensionCouponInCookie || isSuccessPage
      ? false
      : isFreshSignup();

  // TODO: remove 'homeLink' and 'homeLinkAccessibleText' after implementing mobile back button https://app.shortcut.com/farmersdog/story/95979/add-back-button-for-signup-flow-outside-of-tosa-in-mobile
  const homeLink = isSuccessPage
    ? PATH_WEBSITE_HOMEPAGE
    : lastCompletedStep?.path || PATH_HOME;
  const homeLinkAccessibleText =
    homeLink === PATH_HOME || homeLink === PATH_WEBSITE_HOMEPAGE
      ? PreviousLinkNavigationLabel.homePage
      : PreviousLinkNavigationLabel.previousSignupPage;

  return (
    <Viewport
      steps={steps}
      homeLink={homeLink}
      showNavigation={!isSuccessPage}
      showDiscountBadge={showDiscountBadge}
      homeLinkAccessibleText={homeLinkAccessibleText}
      xs={CollapsedView}
      lg={WideView}
    />
  );
}

export default SignupHeader;
