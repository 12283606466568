import { formatCadenceInWeeks } from 'src/utils/formatters';

export interface GetPretaxWeeklyAmountArgs {
  currentCadence: number;
  pretaxAmount: number;
}

export function calculatePretaxWeeklyAmount({
  currentCadence,
  pretaxAmount,
}: GetPretaxWeeklyAmountArgs): number {
  const cadenceInWeeks = formatCadenceInWeeks(currentCadence);
  return pretaxAmount / cadenceInWeeks;
}
