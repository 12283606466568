/** Swaps two array items in place, returning the mutated array.
 * If either index is out of bounds, returns array without swapping */
export function swap(array: unknown[], a: number, b: number) {
  if (isOutOfBounds(a, array.length) || isOutOfBounds(b, array.length)) {
    return array;
  }
  const temp = array[a];
  array[a] = array[b];
  array[b] = temp;
  return array;
}

export function isOutOfBounds(index: number, arrayLength: number) {
  return index < 0 || index >= arrayLength;
}
