import { useLocation } from 'react-router';

import { PATH_OFFER_TERMS } from '@farmersdog/constants/paths';
import type { TextProps } from '@farmersdog/corgi-x';
import { useCoupon, DiscountType } from '@farmersdog/coupons';

import config from '../../../../config';
import { Experiments } from '../../../../types';
import { FeatureName } from '../../../../utils';

import styles from './DiscountBanner.module.css';
import { DiscountBannerInner } from './DiscountBannerInner';
import { HigherDiscountBanner } from './HigherDiscount/HigherDiscountBanner';
import { isEligibleForHigherDiscountBanner } from './HigherDiscount/hooks/isEligibleForHigherDiscountBanner';

export interface BannerOverride {
  bannerCopy?: string;
  bannerLinkCopy?: string;
  bannerLinkPath?: string;
}

export const discountAmountToken = '~~discountAmount~~';

const SURPRISE_DISCOUNT_PCODE = 'surprisediscount100cxvso';

interface FreshDiscountBannerProps {
  experiments: Experiments;
}

export function FreshDiscountBanner({ experiments }: FreshDiscountBannerProps) {
  const { url, referrerRoute } = config.get('tosa.app.coreApi');
  const { pathname } = useLocation();
  const isRecipesPage = pathname.includes('/recipes');

  const { discountAmount, loading, couponCode } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  });

  const {
    config: experimentConfig,
    isReady,
    treatment,
  } = experiments[FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE] || {};

  if (loading || !isReady) {
    return <DiscountBannerInner />;
  }

  const isGlobalDiscountOverrideOn =
    treatment !== 'off' && treatment !== 'control';

  const overrides = isGlobalDiscountOverrideOn
    ? (experimentConfig as BannerOverride | undefined)
    : undefined;

  const bannerCopy = (
    overrides?.bannerCopy ||
    `Special offer: ${discountAmountToken}% off your first box!`
  ).replace(discountAmountToken, String(discountAmount));

  const linkPath = overrides?.bannerLinkPath;
  const linkCopy = overrides?.bannerLinkCopy;

  const {
    surpriseDiscountBannerCopy,
    surpriseDiscountBannerTextColor,
    surpriseDiscountClassName,
    surpriseDiscountLinkCopy,
  } = getSurpriseDiscountBannerProps({
    isRecipesPage,
    discountAmount,
    bannerCopy,
  });

  if (couponCode === SURPRISE_DISCOUNT_PCODE) {
    return (
      <DiscountBannerInner
        copy={surpriseDiscountBannerCopy}
        linkPath={PATH_OFFER_TERMS}
        linkCopy={surpriseDiscountLinkCopy}
        className={surpriseDiscountClassName}
        color={surpriseDiscountBannerTextColor}
      />
    );
  }

  if (isEligibleForHigherDiscountBanner({ couponCode, discountAmount })) {
    return (
      <HigherDiscountBanner
        isRecipesPage={isRecipesPage}
        discountAmount={discountAmount}
      />
    );
  }

  return (
    <DiscountBannerInner
      copy={bannerCopy}
      linkPath={linkPath}
      linkCopy={linkCopy}
    />
  );
}

function getSurpriseDiscountBannerProps({
  isRecipesPage,
  discountAmount,
  bannerCopy,
}: {
  isRecipesPage: boolean;
  discountAmount: number;
  bannerCopy: string;
}) {
  const shouldUseSurpriseDiscountRecipePageBannerStyles =
    isRecipesPage && discountAmount === 100;

  const surpriseDiscountClassName =
    shouldUseSurpriseDiscountRecipePageBannerStyles
      ? styles.surpriseDiscountBanner
      : '';

  const surpriseDiscountBannerTextColor: TextProps['color'] =
    shouldUseSurpriseDiscountRecipePageBannerStyles ? 'Kale3' : 'White';

  const surpriseDiscountBannerCopy =
    discountAmount === 100
      ? 'Surprise! 🎉 Get a free first box, while supplies last. '
      : bannerCopy;

  const surpriseDiscountLinkCopy = discountAmount === 100 ? 'Offer Terms' : '';

  return {
    surpriseDiscountClassName,
    surpriseDiscountBannerTextColor,
    surpriseDiscountBannerCopy,
    surpriseDiscountLinkCopy,
  };
}
