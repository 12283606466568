import { NodeNames } from '@farmersdog/constants';

import { normalizePhone, normalizeZipCode } from './normalizers';

interface NormalizeTextInputValueProps {
  name: string;
  value: string;
}
export function normalizeTextInputValue({
  name,
  value,
}: NormalizeTextInputValueProps) {
  switch (name) {
    case NodeNames.Phone: {
      return normalizePhone(value);
    }
    case NodeNames.Zip: {
      return normalizeZipCode(value);
    }
    default:
      return value;
  }
}
