export enum AddDogState {
  Name = 'Name',
  Essential = 'Essential',
  BodyCondition = 'BodyCondition',
  Weight = 'Weight',
  ActivityLevel = 'ActivityLevel',
  EatingStyle = 'EatingStyle',
  HealthIssues = 'HealthIssues',
  PetDietIssues = 'PetDiet',
  Recipes = 'Recipes',
  Review = 'Review',
  Success = 'Success',
  CloseAddDog = 'CloseAddDog',
}

type FormStep = {
  step: number;
  subSteps: AddDogState[];
};

export type AddDogFormStepsType = FormStep[];

export const AddDogFormSteps = [
  {
    step: 1,
    subSteps: [AddDogState.Name],
  },
  {
    step: 2,
    subSteps: [AddDogState.Essential],
  },
  {
    step: 3,
    subSteps: [
      AddDogState.BodyCondition,
      AddDogState.Weight,
      AddDogState.ActivityLevel,
      AddDogState.EatingStyle,
    ],
  },
  {
    step: 4,
    subSteps: [AddDogState.HealthIssues, AddDogState.PetDietIssues],
  },
  {
    step: 5,
    subSteps: [
      AddDogState.Review,
      AddDogState.Recipes,
      AddDogState.Success,
      AddDogState.CloseAddDog,
    ],
  },
];
