import { Button, Picture, Section, Text } from '@farmersdog/corgi-x';
import bowl from '../assets/bowl.png';
import styles from './RecipeCard.module.css';
import { useId } from 'react';

interface RecipeCardProps {
  title: string;
  description: string;
  callToAction: () => void;
}

export const RecipeCard = (props: RecipeCardProps) => {
  const { title, description, callToAction } = props;
  const handleCallToActionClick = () => {
    callToAction();
  };
  const headingId = useId();
  return (
    <Section
      variant="card"
      vSpacing="md"
      className={styles.container}
      aria-labelledby={headingId}
    >
      <Picture sources={[bowl]} className={styles.img} alt="Bowl Image" />
      <Section as="div" leftSpacing="md" className={styles.content}>
        <div>
          <Text
            as="h4"
            variant="heading-22"
            topSpacing="none"
            bottomSpacing="xs"
            bold
            aria-label="Recipe title"
            id={headingId}
          >
            {title}
          </Text>
          <Text
            as="p"
            variant="article-12"
            topSpacing="none"
            bottomSpacing="sm"
            aria-label="Recipe description"
          >
            {description}
          </Text>
        </div>
        <Button
          type="button"
          variant="plain-text"
          onClick={handleCallToActionClick}
        >
          <Text variant="heading-16" color="carrot-2">
            See Details
          </Text>
        </Button>
      </Section>
    </Section>
  );
};
