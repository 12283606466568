import { getCurrentSignupSubscriptionType } from '@farmersdog/lead-browser-storage';
import { ga4Mounter } from '@farmersdog/pixels';

export function useHandlePetsCompleted() {
  const handlePetsCompleted = () => {
    ga4Mounter.petsSuccess({
      data: {
        productLine: getCurrentSignupSubscriptionType() ?? '',
      },
    });
  };

  return handlePetsCompleted;
}
