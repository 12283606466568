/* CH#49502: Used by CVR */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { paths } from '@farmersdog/constants';
import { Link, Logo, PageWidth } from '@farmersdog/corgi-x';

import { FullFooter } from 'src/components/Footer';
import Page from 'src/components/Page';

import './ContentPage.scss';

function ContentPage({ title, children, className }) {
  return (
    <Page className="ContentPage" title={title}>
      <header className="ContentPage-header">
        <Link to={paths.PATH_HOME}>
          <Logo />
        </Link>
      </header>
      <section className={classNames('ContentPage-body', className)}>
        <PageWidth>{children}</PageWidth>
      </section>
      <FullFooter />
    </Page>
  );
}

ContentPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentPage;
