import { ChangeEvent, ReactElement } from 'react';
import { ListSelect } from '@farmersdog/corgi';
import { DateSelectOption, DateSelectOptionProps } from './DateSelectOption';
import { isSameDeliveryWeek } from '../../utils';

export interface DateSelectProps {
  /** List to dates to render as options. */
  dates: string[];
  /** Which one of them should appear as selected. */
  selectedDate?: string;
  /** Which one of them should appear as scheduled. */
  currentNextDate?: string;
  /** The recommended next date for the order */
  recommendedNextDate?: string;
  /** Which one of them should appear as "unavailable" (e.g. is too early). */
  unavailableDate?: string;
  /** Fired when the user selects a date. */
  onChange: (selectedDate: string) => void;
  /** Has user selected a different valid date at least once? */
  didUserSelectADate: boolean;
}

/**
 * A select to pick from a list of dates, with an heading. In the current
 * implementation, this is a list of dates grouped by months.
 */
export function DateSelect({
  dates,
  selectedDate,
  currentNextDate,
  recommendedNextDate,
  unavailableDate,
  onChange,
  didUserSelectADate,
}: DateSelectProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  const options = dates.map<ReactElement<DateSelectOptionProps>>(
    (date: string) => {
      const isScheduled = Boolean(
        currentNextDate && isSameDeliveryWeek(date, currentNextDate)
      );
      const isUnavailable = Boolean(
        unavailableDate && isSameDeliveryWeek(date, unavailableDate)
      );
      const isRecommended = Boolean(
        recommendedNextDate && isSameDeliveryWeek(date, recommendedNextDate)
      );

      return (
        <DateSelectOption
          key={date}
          value={date}
          isScheduled={isScheduled}
          isUnavailable={isUnavailable}
          isRecommended={isRecommended}
          invalid={false}
          withCheckbox={false}
          withRadio={true}
          description=""
          didUserSelectADate={didUserSelectADate}
        />
      );
    }
  );

  return (
    <ListSelect
      label="Choose an available date"
      name=""
      multi={false}
      onChange={handleChange}
      value={selectedDate}
    >
      {options}
    </ListSelect>
  );
}
