// We are temporarily supporting two checkout event names while we transition ad campaigns
const TIKTOK_LEGACY_CHECKOUT_EVENT = 'Checkout';
const TIKTOK_CHECKOUT_EVENT = 'CompletePayment';
const TIKTOK_INITIATE_CHECKOUT_EVENT = 'InitiateCheckout';
const TIKTOK_REGISTRATION_EVENT = 'CompleteRegistration';
const TIKTOK_ADDTOCART_EVENT = 'AddToCart';
const TIKTOK_PAGE_VIEW_EVENT = 'ViewContent';

export {
  TIKTOK_LEGACY_CHECKOUT_EVENT,
  TIKTOK_CHECKOUT_EVENT,
  TIKTOK_INITIATE_CHECKOUT_EVENT,
  TIKTOK_REGISTRATION_EVENT,
  TIKTOK_ADDTOCART_EVENT,
  TIKTOK_PAGE_VIEW_EVENT,
};
