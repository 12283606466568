import { PATH_PROFILE } from '@farmersdog/constants/paths';
import { Button, Section, Text } from '@farmersdog/corgi-x';
import { trackUpdatePaymentMethodClicked } from '../../../../../analytics';
import { reporter } from '../../../../../errors/services';
import { useFetchHomepageData } from '../../../../../homepage/network';
import { createSlottableComponent } from '../../../../../slots';
import { formatDateRange } from '../../../../../utils';
import { ResponsiveEdgeWrapper } from '../../../ResponsiveEdgeWrapper';
import { useEligibility } from './hooks';
import styles from './PaymentFailedHero.module.css';

/**
 * Display Payment Failed Hero.
 */
function PaymentFailed() {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving homepage data', { sourceError: error });
    },
  });

  const order = request.data?.customer.nextOrderToBeDelivered;

  if (!order) {
    if (!request.loading) {
      reporter.warn('No order found for Payment Failed Hero');
    }

    return null;
  }

  const formattedDeliveryWindow = formatDateRange(
    order.deliveryWindow.earliestDesiredArrivalDate,
    order.deliveryWindow.latestDesiredArrivalDate
  );

  return (
    <ResponsiveEdgeWrapper>
      <Section
        aria-label="Payment Update Needed"
        variant="card"
        className={styles.card}
        bottomSpacing="lg"
      >
        <Text as="h2" variant="heading-28" color="white" bold vSpacing="none">
          Payment Update Needed
        </Text>
        <Text
          as="p"
          variant="article-16"
          color="white"
          topSpacing="sm"
          bottomSpacing="md"
        >
          {`Our attempt to charge the card on file was unsuccessful. Help us avoid delays to your delivery scheduled for
          ${formattedDeliveryWindow}!`}
        </Text>
        <Button
          type="link"
          to={`${PATH_PROFILE}#billing`}
          onClick={() =>
            trackUpdatePaymentMethodClicked({ path: location.pathname })
          }
        >
          Update Payment Method
        </Button>
      </Section>
    </ResponsiveEdgeWrapper>
  );
}

export const PaymentFailedHero = createSlottableComponent(
  PaymentFailed,
  useEligibility
);
