export enum Path {
  Mets = '/mets',
  Dogpeople = '/dogpeople',
  Healthyhabits = '/healthyhabits',
  Secrets = '/secrets',
  Fitish = '/fitish',
  Daddies = '/daddies',
  Spout = '/spout',
  Fueled = '/fueled',
  Irrational = '/irrational',
  Weeklydose = '/weeklydose',
  News = '/news',
  Lol = '/lol',
  Cnn = '/cnn',
  Bloomberg = '/bloomberg',
  Comedy = '/comedy',
  Games = '/games',
  Hln = '/hln',
  Kiss = '/kiss',
  Msnbc = '/msnbc',
  Radioandy = '/radioandy',
  Sirius = '/sirius',
  Sxm = '/sxm',
  Todaysxm = '/todaysxm',
  Stars = '/stars',
  Debatable = '/debatable',
  Faction = '/faction',
  Disney = '/disney',
  Realfood = '/realfood',
  Rangers = '/rangers',
  Mariners = '/mariners',
  Newheights = '/newheights',
  Pmt = '/pmt',
  Football = '/football',
  Toast = '/toast',
  Skinny = '/skinny',
  Mel = '/mel',
  Elvis = '/elvis',
  Elvisduran = '/elvisduran',
  Jomboy = '/jomboy',
  Bible = '/bible',
  SXM50 = '/sxm50',
  Bobby = '/bobby',
  Jetblue = '/jetblue',
  Time = '/time',
  Timeqr30 = '/timeqr30',
  Yanks = '/yanks',
  Talkin = '/talkin',
  Today = '/today',
  MorningMeeting = '/morningmeeting',
  Rose = '/rose',
  Rogan = '/rogan',
  MSNBC50 = '/msnbc50',
  CNN50 = '/cnn50',
  COCO = '/coco',
  Sirius50 = '/sirius50',
  MLB = '/mlb',
  AvmaTechs2024 = '/avmatechs2024',
  VetKnowsBest = '/vetknowsbest',
  AvmaGuest2024 = '/avmaguest2024',
  Nightcap = '/nightcap',
  Shayshay = '/shayshay',
  CallHerDaddy = '/daddy',
  Han = '/han',
  Leo = '/leo',
  Bogart = '/bogart',
  Love = '/love',
  Try = '/try',
}

const secondaryRedirects = {
  '/talkinyanks': Path.Yanks,
  '/talkingyanks': Path.Yanks,

  '/talking': Path.Talkin,
  '/talkingbaseball': Path.Talkin,
  '/talkinbaseball': Path.Talkin,

  '/baseballtoday': Path.Today,

  '/meeting': Path.MorningMeeting,
  '/morninmeeting': Path.MorningMeeting,

  '/roserotation': Path.Rose,
  '/rotation': Path.Rose,

  '/chd': Path.CallHerDaddy,
  '/daddygang': Path.CallHerDaddy,
} as const;

type SecondaryPath = keyof typeof secondaryRedirects;

export const offlineCouponPaths = [
  ...Object.values(Path),
  ...(Object.keys(secondaryRedirects) as SecondaryPath[]),
] as const;

type PathToRedirect = (typeof offlineCouponPaths)[number];

export function isValidPath(path: string): path is PathToRedirect {
  return offlineCouponPaths.includes(path as PathToRedirect);
}

function isPrimaryPath(path: PathToRedirect): path is Path {
  return Object.values(Path).includes(path as Path);
}

function getPrimaryPathFromSecondaryPath(secondaryPath: SecondaryPath): Path {
  return secondaryRedirects[secondaryPath];
}

export function getRedirectPath(path: PathToRedirect): Path {
  return isPrimaryPath(path) ? path : getPrimaryPathFromSecondaryPath(path);
}
