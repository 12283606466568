import logo from './assets/logo.png';
import ogImageJpg from './assets/og-image.jpg';
import config from './config';

export default {
  description:
    'A smarter, healthier dog food: 100% human-grade food, pre-portioned and delivered to your door. Better for them & easier for you. Create your plan today!',
  image: `${config.get('app.publicUrl')}${logo}`,
  ogImage: `${config.get('app.publicUrl')}${ogImageJpg}`,
  siteName: 'The Farmer’s Dog',
  defaultTitle: 'Human-Grade Fresh Dog Food Delivery | The Farmer’s Dog',
  titleTemplate: '%s | The Farmer’s Dog',
};
