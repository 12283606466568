import {
  getIsOnDoNotSellShareList,
  isDiySignup,
} from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';
import { getTimeInSeconds } from '@farmersdog/utils';
import qs from 'qs';

import { reporter } from 'src/services/reporter';
import browserOnly from 'src/utils/browserOnly';
import { getUserId } from '@farmersdog/analytics';

/** Vendor attributes that are used to create a pixel tracker */
export interface VendorAttributes {
  /** A vendor name used for debugging purposes */
  vendorName: string;
  /** The base url for the pixel vendor */
  baseUrl: string;
  /**
   * Stringification options passed to to qs
   *
   * For more details please see the `qs` package documentation
   * https://github.com/ljharb/qs#stringifying
   */
  stringifyOptions?: Parameters<typeof qs.stringify>[1];
}
declare global {
  interface Window {
    Image: typeof Image;
  }
}

/**
 * Create a pixel tracking object.
 *
 * Since we have a single page web app we don't need to use "img" pixels in
 * order to create network requests. It is much simpler to simply make a fetch
 * to the desired url.
 *
 * The following example is a pixel request given some sample html that we
 * should "paste into our header.php file". Converting it to use `Pixel` class
 * allows us to test our analytics and add logging for QA purposes.
 *
 * Given the following html
 *
 * ```html
 * <img
 *   src="http://myprovider.com?order=my-order"
 *   width="1"
 *   height="1"
 *   style="display:none;"
 * />
 * ```
 * We could rewrite it using our pixel class and define the expected parameters
 * for the track call
 *
 * ```ts
 * interface EventAttributes {
 *   order: string
 * };
 *
 * const providerPixel = new Pixel<EventAttributes>({
 *   baseUrl: 'http://myprovider.com',
 *   vendorName: 'My Provider'
 * });
 *
 * providerPixel.track({
 *   order: 'my-order'
 * });
 *
 * providerPixel.track({
 *  order: 'my-order' // typescript error!
 * })
 * ```
 */
export class Pixel<T extends Record<string, unknown>> {
  vendorName: VendorAttributes['vendorName'];
  baseUrl: VendorAttributes['baseUrl'];
  stringifyOptions: VendorAttributes['stringifyOptions'];
  log: Logger;

  static defaultStringifyOptions = {
    addQueryPrefix: true,
  };

  constructor({ vendorName, baseUrl, stringifyOptions }: VendorAttributes) {
    this.vendorName = vendorName;
    this.baseUrl = baseUrl;
    this.stringifyOptions = stringifyOptions ?? Pixel.defaultStringifyOptions;
    this.log = new Logger(`app:vendors:${vendorName}`);
    this.log.debug('Creating pixel tracker', { vendorName, baseUrl });
  }

  track(attributes: T): void {
    if (getIsOnDoNotSellShareList()) {
      this.log.warn(
        'Blocking pixel event because user is on do not sell share list',
        {
          vendorName: this.vendorName,
          userId: getUserId(),
        }
      );
      return;
    }

    if (isDiySignup()) {
      return;
    }

    const enhancedAttributes = {
      ord: getTimeInSeconds(),
      ...attributes,
    };
    const query = qs.stringify(enhancedAttributes, this.stringifyOptions);
    const url = query ? `${this.baseUrl}${query}` : this.baseUrl;

    this.log.debug('Calling pixel track event', {
      baseUrl: this.baseUrl,
      attributes: enhancedAttributes,
    });

    browserOnly((window: Window) => {
      const img = new window.Image();
      img.src = url;
      img.onerror = () => {
        reporter.error(`Error loading pixel: ${url}`);
      };
    });
  }
}
