import { useRef } from 'react';

import { BranchNode as BranchNodeType } from '../../blueprint/types';
import { TOSAComponentInput } from '../../types';
import { validateRecipesSubmission } from '../../utils/validateRecipesSubmission';
import { BranchNode } from '../schema/BranchNode';

export function RecipesForm(props: TOSAComponentInput<BranchNodeType>) {
  const { formMethods } = props;

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const validatedData = validateRecipesSubmission(formData);

    if (props.onSubmit) {
      await props.onSubmit(validatedData, e);
    }
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
      <BranchNode {...props} />
    </form>
  );
}
