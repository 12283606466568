import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextV2,
  Input,
  SelectorGroup,
  LabeledSelector,
  Button,
} from '@farmersdog/corgi';
import { Helmet } from 'react-helmet-async';

import { selectPetNames } from 'src/reducers/signup/user';
import petNameOrYourDogs from 'src/utils/petNameOrYourDogs';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import ActionButtons from './ActionButtons';
import useAnalytics from './useAnalytics';

import { SLIDE_NAME as VEGETABLES } from './Vegetables';
import { SLIDE_NAME as PROTEIN_BLENDS } from './ProteinBlends';
import newProteinOptions, {
  FISH,
  FISH_MEAT_BLEND,
  MEAT_MEAT_BLEND,
  OTHER_NEW_PROTEIN,
} from './newProteinOptions';
import { TITLE } from './copy';

import styles from './NewProteins.module.scss';

export const SLIDE_NAME = 'NEW_PROTEINS';

function NewProteins({
  goToPrevSlide,
  setCurrentSlide,
  onAnswerEvent,
  newProteins,
  customNewProtein,
}) {
  const { trackAnswerSelected } = useAnalytics();

  const petNames = useSelector(selectPetNames);

  const isOtherProteinSelected = useMemo(() => {
    return newProteins.includes(OTHER_NEW_PROTEIN.value);
  }, [newProteins]);

  const goToNextSlide = () => {
    const proteinBlendValues = [FISH, FISH_MEAT_BLEND, MEAT_MEAT_BLEND].map(
      option => option.value
    );

    if (
      proteinBlendValues.some(proteinBlendValue =>
        newProteins.includes(proteinBlendValue)
      )
    ) {
      setCurrentSlide(PROTEIN_BLENDS);
      return;
    }

    setCurrentSlide(VEGETABLES);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE}>
        <TextV2 as="div" variant="heading-21">
          Which proteins can {petNameOrYourDogs(petNames)} eat?
        </TextV2>
        <TextV2 as="p" variant="body-16" color="charcoal-1">
          Select all proteins that work for {petNameOrYourDogs(petNames)}!
        </TextV2>
        <SelectorGroup
          type="checkbox"
          name="newProteins"
          legend="Which recipes"
          onChange={onAnswerEvent}
          value={newProteins}
          inputsClassName={styles.optionsContainer}
        >
          {newProteinOptions.map(protein => (
            <LabeledSelector
              key={protein.value}
              value={protein.value}
              className={styles.option}
            >
              {protein.label}
            </LabeledSelector>
          ))}
        </SelectorGroup>
        {isOtherProteinSelected && (
          <Input
            label="What other protein(s) would you prefer?"
            name="customNewProtein"
            type="text"
            className={styles.otherProteinField}
            onChange={e => onAnswerEvent(e, { track: false })}
            value={customNewProtein}
          />
        )}
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button
            variant="primary"
            disabled={newProteins.length === 0}
            onClick={() => {
              trackAnswerSelected('customNewProtein', customNewProtein);
              goToNextSlide();
            }}
          >
            Continue
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

NewProteins.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  onAnswerEvent: PropTypes.func.isRequired,
  newProteins: PropTypes.arrayOf(PropTypes.string).isRequired,
  customNewProtein: PropTypes.string.isRequired,
};

export default NewProteins;
