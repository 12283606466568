import classNames from 'classnames';

import { Arrow } from '@farmersdog/corgi';

import styles from './DropdownArrow.module.css';

interface DropdownArrowProps {
  isOpen: boolean;
}

export function DropdownArrow({ isOpen }: DropdownArrowProps) {
  const arrowClassName = classNames(styles.arrow, {
    [styles.arrowOpen]: isOpen,
  });

  return <Arrow className={arrowClassName} size={12} />;
}
