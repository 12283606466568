// TODO: Customer Portal Cleanup: Remove unnecessary exports
// https://app.shortcut.com/farmersdog/story/92897/customer-portal-cleanup-remove-unnecessary-exports
export { App as Paw } from './src/app/App';
export type { AppProps as PawProps } from './src/app/App.types';
export { AuthenticatedRoutes } from './src/routes/AuthenticatedRoutes';
export type { UserTreatment } from './src/app/lib/Split';
export { ApplicationError, ErrorPage, ValidationError } from './src/errors';
export { EmptyTreats, InactiveEmptyTreats } from './src/treats';
export { trackCancelSubscriptionFlowSave } from './src/cancellation-reasons';
export type {
  CancellationResponsesEnumType,
  CancelSubscriptionFlowSavePayload,
} from './src/cancellation-reasons';
export { TemporaryPause } from './src/temporary-pause';
export { Loading } from './src/components';
export { GladlySidekickScript } from './src/cx';
export type { BaseTreatment } from './src/app/lib/Split';
export { getHomepagePath, getDefaultAccountPath } from './src/routes/utils';
export { Homepage, RedoBadge, PaymentWarningBadge, useGetPaymentFailedStatus } from './src/homepage';
export { PrismicPreviewToolbarScript } from './src/content';
export { VetClinic } from './src/vet-clinic';
export { NotificationPreferences } from './src/notification-preferences';
export { MyDogs } from './src/my-dogs';
export { PetFreshFood } from './src/pet-fresh-food';
export { useExperimentDocuments } from './src/content/useExperimentDocuments';
export {
  formatDateRange,
  formatDateToMonthAndDay,
  formatUtcDateTime,
} from './src/utils';
// TODO: remove this export once the migration is complete
// NOTE: https://app.shortcut.com/farmersdog/story/112322/change-order-size-price-consistency-port-changefrequency-to-paw
export { ChangeFrequency } from './src/change-frequency';
export { ExperimentProvider } from './src/experiments';
