import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH_HOME } from '@farmersdog/constants/paths';

import { mountRadioHomePageView } from './mountRadioHomePageView';

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a Radio page view event.
 */
export function useMountRadioPageView() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === PATH_HOME) {
      mountRadioHomePageView();
    }
  }, [pathname]);
}
