import { tosaErrorCodes as errorCodes } from '@farmersdog/constants';

// list of error codes from auth/register that we want to handle with an inline error
const managedAuthRegisterErrorCodes = new Set([
  errorCodes.EMAIL_ALREADY_IN_USE as string,
  errorCodes.PHONE_NUMBER_ALREADY_IN_USE as string,
]);

export function isManagedAuthRegisterError(errorMessage: string) {
  return managedAuthRegisterErrorCodes.has(errorMessage);
}
