import { Button } from '@farmersdog/corgi-x';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import styles from './CTA.module.scss';

import type { ReactNode } from 'react';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import classNames from 'classnames';

export interface CTAProps {
  children: ReactNode;
}

export function CTA({ children }: CTAProps) {
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <Button
      type="link"
      variant="primary"
      to={PATH_SIGNUP_ME}
      className={classNames([
        styles.cta,
        accessibilityThemeStyles.responsiveCta,
      ])}
    >
      {children}
    </Button>
  );
}
