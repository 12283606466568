import desktopMeatAndVeggiesJpg from './meat-and-veggies-background-desktop.jpg';
import desktopMeatAndVeggiesWebp from './meat-and-veggies-background-desktop.webp';

import mobileMeatAndVeggiesJpg from './meat-and-veggies-background-mobile.jpg';
import mobileMeatAndVeggiesWebp from './meat-and-veggies-background-mobile.webp';

export const mobileMeatAndVeggies = {
  webP: mobileMeatAndVeggiesWebp,
  jpg: mobileMeatAndVeggiesJpg,
};

export const desktopMeatAndVeggies = {
  webP: desktopMeatAndVeggiesWebp,
  jpg: desktopMeatAndVeggiesJpg,
};
