import ActivityLevelActiveNew from './activity-level-active-new.png';
import ActivityLevelActiveNewWebp from './activity-level-active-new.webp';
import ActivityLevelActive from './activity-level-active.png';
import ActivityLevelNotActiveNew from './activity-level-not-active-new.png';
import ActivityLevelNotActiveNewWebp from './activity-level-not-active-new.webp';
import ActivityLevelNotActive from './activity-level-not-active.png';
import ActivityLevelProAthleteNew from './activity-level-pro-athlete-new.png';
import ActivityLevelProAthleteNewWebp from './activity-level-pro-athlete-new.webp';
import ActivityLevelProAthlete from './activity-level-pro-athlete.png';
import ActivityLevelVeryActiveNew from './activity-level-very-active-new.png';
import ActivityLevelVeryActiveNewWebp from './activity-level-very-active-new.webp';
import ActivityLevelVeryActive from './activity-level-very-active.png';

export enum ActivityLevelAssets {
  NotActive,
  Active,
  VeryActive,
  ProAthlete,
}

export const activityLevelAssets = {
  large: [
    [ActivityLevelNotActive],
    [ActivityLevelActive],
    [ActivityLevelVeryActive],
    [ActivityLevelProAthlete],
  ],
};

export const activityLevelNewAssets = {
  large: [
    [ActivityLevelNotActiveNewWebp, ActivityLevelNotActiveNew],
    [ActivityLevelActiveNewWebp, ActivityLevelActiveNew],
    [ActivityLevelVeryActiveNewWebp, ActivityLevelVeryActiveNew],
    [ActivityLevelProAthleteNewWebp, ActivityLevelProAthleteNew],
  ],
};
