import { ctaLabel } from '../PickyEaterCta.copy';
import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

export function trackPickyEaterNudge() {
  track(eventNames.homepage_hero_clicked, {
    hero_feature: 'picky eaters nudge',
    call_to_action: ctaLabel,
  });
}
