import classNames from 'classnames';

import Picture from 'src/components/Picture';
import { useCorgiViewport } from 'src/screen';

import { waitingDogs } from './assets';
import styles from './DogImage.module.scss';

function DogImage() {
  const responsiveClassName = useCorgiViewport({
    sm: styles.sm,
    md: styles.md,
    lg: styles.lg,
  });

  return (
    <Picture
      sources={waitingDogs}
      alt="Dogs waiting for The Farmer’s Dog food"
      loading="lazy"
      className={classNames(styles.image, responsiveClassName)}
    />
  );
}

export default DogImage;
