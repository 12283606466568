import classNames from 'classnames';
import Picture from 'src/components/Picture';
import { useViewport } from 'src/screen';
import { Text, Mask, Nowrap } from '@farmersdog/corgi-x';
import styles from './OurTale.module.scss';
import { jadaSources } from './assets';

function OurTale() {
  const isMobile = useViewport({ xs: true, lg: false });
  return (
    <>
      <div className={classNames(styles.container, { [styles.lg]: !isMobile })}>
        <Text
          variant={isMobile ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Our tale...
        </Text>
        <Mask shape="circle" size="xl">
          <Picture
            className={styles.jadaPuppy}
            sources={jadaSources}
            alt="about-us-jada-puppy"
          />
        </Mask>
        <Text
          variant={isMobile ? 'heading-22' : 'heading-28'}
          color="kale-3"
          as="h2"
          bold
        >
          It began with Jada
        </Text>
        <Text variant="article-20" color="kale-3" as="p">
          Jada was the most beautiful puppy, but suffered from severe digestive
          issues the first two years of her life. Her dad (aka our{' '}
          <Nowrap>co-founder</Nowrap>), Brett, tried every food on the market.{' '}
          <Nowrap>Nothing helped.</Nowrap>
        </Text>
      </div>
    </>
  );
}

export default OurTale;
