import { paths } from '@farmersdog/constants';

import { BranchNode } from '../blueprint/types';
import { getNodeNameAndPosition } from '../blueprint/utils';

interface IsContinueToNextPet {
  formSteps: BranchNode[];
  currentBranchName: string;
}

export function isContinueToNextPet({
  formSteps,
  currentBranchName,
}: IsContinueToNextPet) {
  const currentBranchIndex = formSteps.findIndex(
    formStep => formStep.name === currentBranchName
  );

  const currentBranch = formSteps[currentBranchIndex];
  if (!currentBranch || currentBranch.route !== paths.PATH_SIGNUP_PETS) {
    return false;
  }

  const nextBranch = formSteps[currentBranchIndex + 1];
  if (!nextBranch || nextBranch.route !== paths.PATH_SIGNUP_PETS) {
    return false;
  }

  const { position: currentPetPosition } =
    getNodeNameAndPosition(currentBranchName);
  const { position: nextPetPosition } = getNodeNameAndPosition(nextBranch.name);

  if (currentPetPosition === nextPetPosition) {
    return false;
  }

  return true;
}
