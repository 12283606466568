import { formatCommaSeparatedList } from '../../../../utils/formatCommaSeparatedList';
import { pluralizeString } from '../../../../utils/pluralizeString';
import { RecommendedRecipe } from '../../types';
import { getGenderObjectPronoun } from '../getGenderObjectPronoun';
import { getGenderPossessivePronoun } from '../getGenderPossessivePronoun';

export interface GetRecommendedRecipeCopyArgs {
  petName: string;
  lpfRecipeDisplayNames?: string[];
  isOnlyLpfRecipeRecommended: boolean;
  isWeightLossPersonalization?: boolean;
  showBodyConditionPersonalization?: boolean;
  recommendedRecipes: RecommendedRecipe[];
  petGender: string;
}

export function getRecommendedRecipeCopy({
  petName,
  petGender,
  lpfRecipeDisplayNames = [],
  isOnlyLpfRecipeRecommended,
  isWeightLossPersonalization,
  showBodyConditionPersonalization,
  recommendedRecipes,
}: GetRecommendedRecipeCopyArgs): JSX.Element {
  const recommendedRecipeDisplayNames = recommendedRecipes.map(
    ({ displayName }) => displayName.toLowerCase()
  );
  const recommendedRecipesString = getRecipesString(
    recommendedRecipeDisplayNames
  );

  const lpfRecipesString = getRecipesString(lpfRecipeDisplayNames);

  if (showBodyConditionPersonalization) {
    if (isOnlyLpfRecipeRecommended) {
      return (
        <>
          Based on your responses, we recommend our {lpfRecipesString}. Made
          with highly digestible meat, grains, and fresh vegetables, this recipe
          is a great option for dogs like {petName}
          {isWeightLossPersonalization && (
            <>
              —and we’ll send them pre-portioned for{' '}
              {getGenderPossessivePronoun(petGender)} weight goals
            </>
          )}
          .
        </>
      );
    }

    if (isWeightLossPersonalization) {
      return (
        <>
          Based on your responses, we recommend our {recommendedRecipesString}.
          They’re all made to human-grade standards, from fresh, highly
          digestible meat and vegetables. They’re ideal for{' '}
          {getGenderPossessivePronoun(petGender)} nutritional needs—and we’ll
          send them pre-portioned for {getGenderPossessivePronoun(petGender)}{' '}
          weight goals.
        </>
      );
    }

    return (
      <>
        Based on your responses, we recommend our {recommendedRecipesString}.
        They’re ideal for {getGenderPossessivePronoun(petGender)} nutritional
        needs, made to human-grade standards from fresh, highly digestible meat
        and vegetables, and will arrive pre-portioned for{' '}
        {getGenderObjectPronoun(petGender)}.
      </>
    );
  }

  if (isOnlyLpfRecipeRecommended) {
    return (
      <>
        Based on your responses, we recommend our {lpfRecipesString}. Made with
        highly digestible human grade meat, grains, and fresh vegetables, this
        recipe is a great option for dogs like {petName}.
      </>
    );
  }

  return (
    <>
      Based on your responses, we recommend these fresh, balanced recipes for{' '}
      {petName}.&nbsp;They’re all made with highly digestible human grade meat
      and fresh vegetables and are ideal for {petName}’s nutritional&nbsp;needs.
    </>
  );
}

function getRecipesString(recipes: string[]) {
  return `${formatCommaSeparatedList(
    recipes
  )} ${pluralizeString(recipes.length, 'recipe', 'recipes')}`;
}
