import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextV2 } from '@farmersdog/corgi';
import { Button } from '@farmersdog/corgi-x';
import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { selectPetNames } from 'src/reducers/signup/user';
import petNameOrYourDogs from 'src/utils/petNameOrYourDogs';

import ActionButtons from './ActionButtons';
import PreferenceSelector from './PreferenceSelector';

import { vegetableOptions } from './vegetableOptions';
import { SLIDE_NAME as ADDITIONAL_INGREDIENTS } from './AdditionalIngredients';
import { TITLE } from './copy';

import styles from './Vegetables.module.scss';

export const SLIDE_NAME = 'VEGETABLES';

function Vegetables({
  goToPrevSlide,
  setCurrentSlide,
  onAnswerChange,
  vegetables,
}) {
  const petNames = useSelector(selectPetNames);

  const handleOptionChange = e => {
    const targetVegetable = e.target.name;
    const newVegetablePreference = e.target.value;

    onAnswerChange('vegetables', {
      ...vegetables,
      [targetVegetable]: newVegetablePreference,
    });
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE}>
        <div className={styles.container}>
          <TextV2 as="div" variant="heading-21">
            Which vegetables can {petNameOrYourDogs(petNames)} eat?
          </TextV2>
          <TextV2
            as="p"
            variant="body-16"
            color="charcoal-1"
            className={styles.supportText}
          >
            Select your requirements for whether to include each of these
            vegetables.
          </TextV2>
          <div className={styles.questions}>
            {vegetableOptions.map(vegetable => (
              <PreferenceSelector
                item={vegetable}
                key={vegetable.value}
                onOptionChange={handleOptionChange}
                value={vegetables}
              />
            ))}
          </div>
        </div>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={() => setCurrentSlide(ADDITIONAL_INGREDIENTS)}
          >
            Continue
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

Vegetables.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  vegetables: PropTypes.object.isRequired,
};

export default Vegetables;
