import { ApolloLink } from '@apollo/client';
import { getTokenFromCookie } from '../../../utils';

export interface RequestContext {
  headers?: Record<string, string>;
}

/**
 * Authorization link handles setting authorization tokens in outgoing network
 * requests.
 */
export function setupAuthLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    operation.setContext(
      ({ headers: existingHeaders, ...context }: RequestContext) => {
        const token = getTokenFromCookie();
        const headers = token
          ? { authorization: `Bearer ${token}` }
          : undefined;

        return {
          ...context,
          headers: {
            ...existingHeaders,
            ...headers,
          },
        };
      }
    );

    return forward(operation);
  });
}
