import { NodeNames } from '@farmersdog/constants';

import {
  CompiledBlueprint,
  NodeRegistry,
  Position,
  Node,
} from '../../blueprint/types';
import { Experiments, SetFormStepsType } from '../../types';
import { fragmentConfig } from '../fragmentConfig';

import { updateSchemaWithFragments } from './updateSchemaWithFragments';

import type { UseFormReturn } from 'react-hook-form';

interface UpdatePetCountArgs {
  value: number;
  schema: CompiledBlueprint;
  setFormSteps: SetFormStepsType;
  nodeName: string;
  registry: NodeRegistry;
  unregister: UseFormReturn['unregister'];
  experiments: Experiments;
}

export function updatePetCount({
  value,
  schema,
  setFormSteps,
  nodeName,
  registry,
  unregister,
}: UpdatePetCountArgs): void {
  const currentPetNameNodes = Object.values(registry).filter(currentNode =>
    currentNode.name.startsWith(NodeNames.Name)
  );

  const { fragments, getFragmentCount } = fragmentConfig[nodeName];
  // unmount input
  const clonedSchema = updateSchemaWithFragments({
    fragmentCount: getFragmentCount(value),
    fragments,
    schema,
  });

  setFormSteps(clonedSchema);

  //  unregister state AFTER the input has been unmounted
  const petsCountDifference = currentPetNameNodes.length - value;

  if (petsCountDifference > 0) {
    removePetNamesFromState({
      currentPetNameNodes,
      unregister,
      updatedPetCount: value,
    });
  }
}

interface RemovePetNamesFromStateArgs {
  currentPetNameNodes: Array<Node>;
  updatedPetCount: number;
  unregister: UseFormReturn['unregister'];
}

function removePetNamesFromState({
  currentPetNameNodes,
  updatedPetCount,
  unregister,
}: RemovePetNamesFromStateArgs) {
  for (let i = currentPetNameNodes.length; i > updatedPetCount; i--) {
    const petPosition = String(i) as Position;
    const nodeNameToRemove = `${NodeNames.Name}-${petPosition}` as const;
    unregister(nodeNameToRemove);
  }
}
