import { Request } from 'express';

type GeolocationProperties = 'dma' | 'city' | 'state' | 'zip' | 'country';

type GeolocationHeaderMap = {
  [k in GeolocationProperties]: string;
};

export const geoLocationHeaderMap: GeolocationHeaderMap = {
  dma: 'cf-metro-code',
  city: 'cf-ipcity',
  state: 'cf-region-code',
  zip: 'cf-postal-code',
  country: 'cf-ipcountry',
};

export type GeolocationData = {
  [k in GeolocationProperties]?: string;
};

export const geolocationWindowProperty = '__geolocationData';

export function getGeolocationDataFromHeaders(req: Request): GeolocationData {
  const geoKeysToHeadersList = Object.entries(geoLocationHeaderMap);
  return geoKeysToHeadersList.reduce((geoValues, [key, header]) => {
    const value = req.get(header);
    return value ? { ...geoValues, [key]: value } : geoValues;
  }, {});
}
