import classNames from 'classnames';

import Picture from 'src/components/Picture';
import { useCorgiViewport } from 'src/screen';

import brettAndJonathanJpg from './assets/brett-and-jonathan-resized.jpg';
import brettAndJonathanWebP from './assets/brett-and-jonathan-resized.webp';

import bestPlaceToWorkJpg from './assets/best-places-to-work-resized.jpg';
import bestPlaceToWorkWebp from './assets/best-places-to-work-resized.webp';

import gobiLaptopJpg from './assets/gobi-laptop-resized.jpg';
import gobiLaptopWebP from './assets/gobi-laptop-resized.webp';

import zoomPhotoJpg from './assets/zoom-photo-resized.jpg';
import zoomPhotoWebP from './assets/zoom-photo-resized.webp';

import philBocksJpg from './assets/phil-bocks-resized.jpg';
import philBocksWebP from './assets/phil-bocks-resized.webp';

import tunnelJpg from './assets/tunnel-resized.jpg';
import tunnelWebP from './assets/tunnel-resized.webp';

import styles from './TileHero.module.scss';

const tiles = [
  {
    sources: [brettAndJonathanWebP, brettAndJonathanJpg],
    alt: 'Brett and Jonathan',
  },
  {
    sources: [bestPlaceToWorkWebp, bestPlaceToWorkJpg],
    alt: 'Best Place to Work',
  },
  {
    sources: [gobiLaptopWebP, gobiLaptopJpg],
    alt: 'Gobi with laptop',
  },
  {
    sources: [zoomPhotoWebP, zoomPhotoJpg],
    alt: "The Farmer's Dog zoom",
  },
  {
    sources: [philBocksWebP, philBocksJpg],
    alt: "The Farmers's Dog office",
  },
  {
    sources: [tunnelWebP, tunnelJpg],
    alt: 'Tunnel',
  },
];

export function TileHero() {
  return (
    <div
      className={classNames(
        styles.container,
        useCorgiViewport({
          xs: styles.xs,
          lg: styles.lg,
        })
      )}
    >
      {tiles.map(image => {
        return (
          <div className={styles.imageContainer} key={image.alt}>
            <Picture
              className={styles.image}
              alt={image.alt}
              sources={image.sources}
            />
          </div>
        );
      })}
    </div>
  );
}
