import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { TextV2, Link } from '@farmersdog/corgi';
import { Button, Nowrap } from '@farmersdog/corgi-x';

import { PATH_SIGNUP_PLANS } from '@farmersdog/constants/paths';

import ActionButtons from './ActionButtons';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { TITLE } from './copy';

function PricingOptions({ submit, goToPrevSlide }) {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 variant="body-16" as="p">
          Good news!
        </TextV2>
        <TextV2 variant="body-16" as="p">
          We have few different meal plans that offer a lower{' '}
          <Nowrap>average weekly price</Nowrap>.
        </TextV2>
        <Link
          variant="primary"
          asButton
          to={`${PATH_SIGNUP_PLANS}?topper`}
          onClick={() => {
            submit();
          }}
        >
          Change Meals Size
        </Link>
        <ActionButtons>
          <Button variant="secondary" kind="plain-text" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button variant="primary" type="submit">
            Close
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

PricingOptions.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export const SLIDE_NAME = 'PRICE_OPTIONS';

export default PricingOptions;
