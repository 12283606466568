import { addViewThroughConversionData } from './addViewThroughConversionData';
import { NextDoorEvent } from './nextDoorTypes';
import { trackNextDoorEvent } from './trackNextDoorEvent';

/**
 * Track a next door conversion event
 */
export async function trackNextDoorConversionEvent(userEmail?: string) {
  await addViewThroughConversionData(userEmail);
  trackNextDoorEvent(NextDoorEvent.CONVERSION);
}
