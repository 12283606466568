import { Button, Text, Input, FormControl, Link } from '@farmersdog/corgi-x';
import { ApolloError } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormSchema } from './schema';
import styles from './LoginForm.module.css';
import { getRecoverPasswordUrl } from '../../../utils';
import { LoginInput } from '../../../graphql/types.core-api';

interface FormValues {
  email: string;
  password: string;
}

export interface LoginFormProps {
  login: (loginInput: LoginInput) => void;
  loading: boolean;
  error: ApolloError | undefined;
}

export function LoginForm(props: LoginFormProps) {
  const form = useForm<FormValues>({
    resolver: yupResolver(LoginFormSchema),
  });

  const emailValue = form.watch('email');
  const passwordValue = form.watch('password');

  let emailErrorMessage = form.formState.errors.email?.message;
  let passwordErrorMessage = form.formState.errors.password?.message;

  const validationErrorMessage =
    props.error &&
    /status code 400/.exec(props.error?.message) &&
    'Sorry, but the email or password you entered was incorrect.';

  // This could be an invalid email, password, or both.
  // Make sure both are truthy so their input state will be invalid,
  // but set the validation error message in password to display at the bottom of the form.
  if (validationErrorMessage) {
    // don't overwrite formState error message if email is invalid,
    // we still need to disable submit and display invalid email message
    emailErrorMessage = emailErrorMessage || ' ';
    passwordErrorMessage = validationErrorMessage;
  }

  const recoverPasswordLink = getRecoverPasswordUrl(passwordValue);

  return (
    <form
      aria-label="Login"
      name="login"
      className={styles.form}
      onSubmit={form.handleSubmit(props.login)}
      data-testid="loginFormPaw"
    >
      <FormControl
        message={emailErrorMessage}
        invalid={Boolean(emailErrorMessage)}
        aria-live="assertive"
      >
        <Input
          {...form.register('email')}
          label="Email"
          autoComplete="email"
          className={styles.email}
          autoFocus={Boolean(emailErrorMessage)}
        />
      </FormControl>
      <FormControl
        message={passwordErrorMessage}
        invalid={Boolean(passwordErrorMessage)}
        aria-live="assertive"
      >
        <Input
          {...form.register('password')}
          label="Password"
          type="password"
          className={styles.password}
          autoComplete="current-password"
          autoFocus={Boolean(passwordErrorMessage)}
          withRevealButton
        />
      </FormControl>
      <Button
        type="submit"
        name="submit"
        aria-label={props.loading ? 'Loading' : 'Log in'}
        className={styles.submitButton}
        loading={props.loading}
        aria-disabled={
          !emailValue || !passwordValue || Boolean(form.formState.errors.email)
        }
      >
        Log In
      </Button>
      <Link className={styles.resetPassword} to={recoverPasswordLink}>
        <Text variant="heading-16">Forgot Password?</Text>
      </Link>
    </form>
  );
}
