import { Button, PaddedCard, Picture, Text, Wave } from '@farmersdog/corgi-x';
import { EmptyTreats } from '../../../treats';
import { formatDateToMonthAndDay } from '../../../utils';
import { leafSources, orderSources } from './assets';
import { PetSummary } from './PetSummary';

import styles from './ReactivationSuccess.module.css';

export interface PetOrderDetails {
  id: number;
  name: string;
  totalPacks: number;
  portion: number;
  dailyCalories: number;
}

interface ReactivationSuccessProps {
  nextOrderToBeDelivered: {
    id: number;
    pets: PetOrderDetails[];
    pricePerDay: number;
    deliveryWindow: {
      earliestDesiredArrivalDate: string;
      latestDesiredArrivalDate: string;
    };
  };
  shouldShowShopTreats: boolean;
  onExit: () => void;
}

export function ReactivationSuccess(props: ReactivationSuccessProps) {
  const earliestDesiredDate = formatDateToMonthAndDay(
    props.nextOrderToBeDelivered.deliveryWindow.earliestDesiredArrivalDate
  );
  const latestDesiredDate = formatDateToMonthAndDay(
    props.nextOrderToBeDelivered.deliveryWindow.latestDesiredArrivalDate
  );

  return (
    <section className={styles.section}>
      <Text vSpacing="none" as="p" variant="article-20">
        Your box will arrive between
      </Text>
      <Text vSpacing="xs" as="p" variant="heading-22" color="kale-3" bold>
        {earliestDesiredDate} - {latestDesiredDate}
      </Text>
      <Text as="p" variant="article-16" color="charcoal-3">
        It will include an updated feeding guide to reflect your new meal plan.
      </Text>
      <Wave withTop withBottom className={styles.wave}>
        <Picture
          sources={leafSources}
          alt="leaf"
          className={styles.leafPicture}
        />
        <div className={styles.cardsContainer}>
          <PaddedCard className={styles.paddedCard}>
            <Picture
              sources={orderSources}
              alt="box of meals"
              className={styles.orderPicture}
            />
            <Text
              as="h2"
              variant="heading-28"
              color="kale-3"
              bold
              vSpacing="none"
            >
              In Your Order
            </Text>
            {props.nextOrderToBeDelivered.pets.map(pet => {
              return <PetSummary key={pet.name} pet={pet} />;
            })}
          </PaddedCard>
          {props.shouldShowShopTreats && (
            <EmptyTreats className={styles.emptyTreats} />
          )}
        </div>
      </Wave>
      <Button
        type="button"
        variant="secondary"
        onClick={props.onExit}
        className={styles.goToAccountButton}
      >
        Go to Account
      </Button>
    </section>
  );
}
