import { Grid, Picture, Slideshow, Text } from '@farmersdog/corgi-x';
import styles from './Testimonials.module.scss';
import VETS_DATA from '../../vetsData';
import { HomepageTitle1 } from '../../../HomepageTitle';
import { useInterval } from 'src/hooks/useInterval/useInterval';
import { useState } from 'react';

interface TestimonialsProps {
  isMobile: boolean;
}

const SECONDS_PER_SLIDE = 8;
export const Testimonials = ({ isMobile }: TestimonialsProps) => {
  const { reset: resetInterval } = useInterval({
    durationSeconds: SECONDS_PER_SLIDE,
    onTimeLapsed: () => {
      setSlide(slide => {
        if (slide == VETS_DATA.length - 1) return 0;
        return slide + 1;
      });
    },
  });
  const [slide, setSlide] = useState(0);

  const getTestimonials = () => {
    return VETS_DATA.map(vet => {
      return (
        <Grid key={vet.name} className={styles.testimonialsContainer}>
          <Text
            variant="article-20"
            align="left"
            bottomSpacing="md"
            className={styles.quote}
          >
            &#8220;{vet.quote}&#8221;
          </Text>

          <Grid
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            bottomSpacing="md"
          >
            <Picture
              sources={vet.sources}
              alt={vet.name}
              loading="lazy"
              className={styles.photo}
            />
            <Text
              variant="heading-16"
              bold
              bottomSpacing="sm"
              className={styles.name}
            >
              {vet.name}
            </Text>
          </Grid>
        </Grid>
      );
    });
  };

  const onSlideChange = (index: number) => {
    setSlide(index);
    resetInterval();
  };

  return (
    <Grid
      alignItems="flex-start"
      flexDirection="column"
      className={styles.testimonials}
    >
      <HomepageTitle1
        center={false}
        bottomSpacing="sm"
        className={styles.title}
      >
        Trusted by vets.
      </HomepageTitle1>
      <Text variant="article-16" bottomSpacing={isMobile ? 'md' : 'lg'}>
        Thousands of veterinary professionals recommend our food—and feed it to
        their own dogs.
      </Text>
      <div className={styles.slideShow}>
        <Slideshow
          label="vet testimonials gallery"
          withDots
          scrollBehavior="smooth"
          withLoop
          activeIndex={slide}
          onSlideChange={onSlideChange}
        >
          {getTestimonials()}
        </Slideshow>
      </div>
    </Grid>
  );
};
