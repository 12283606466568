import { getSharedISpotParams } from './getSharedISpotParams';
import { iSpot, ISpotEventName } from './iSpot';

interface MountISpotPageViewArgs {
  utmSource: string | undefined;
}

/**
 * Mount a iSpot page view
 */
export function mountISpotPageView({
  utmSource,
}: MountISpotPageViewArgs): void {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { app, channel, customData } = getSharedISpotParams({ utmSource });

  iSpot.mount({
    app,
    channel,
    customData,
    type: ISpotEventName.PAGE_VIEW,
    ord: cacheBuster,
  });
}
