// Mapped from https://github.com/VIISON/ShopwareStripePayment/blob/master/Snippets/frontend/plugins/payment/stripe_payment/card.ini
// (couldn't find a reference for such codes)
export const ERROR_CODES = {
  cc_card_holder: 'cc_card_holder',
  incomplete_cvc: 'cc_cvc',
  incomplete_expiry: 'cc_expiry',
  incomplete_number: 'cc_number',
  incorrect_cvc: 'cc_cvc',
  incorrect_number: 'cc_number',
  invalid_card_holder: 'cc_card_holder',
  invalid_cvc: 'cc_cvc',
  invalid_expiry_month: 'cc_expiry',
  invalid_expiry_month_past: 'cc_expiry',
  invalid_expiry_year: 'cc_expiry',
  invalid_expiry_year_past: 'cc_expiry',
  invalid_number: 'cc_number',
};

/**
 * Incomplete list of valid Stripe.js element types. We only are including the
 * types that we need to support our current checkout process
 * https://stripe.com/docs/stripe-js/reference#element-types
 */
export const CARD_NUMBER = 'cardNumber';
export const CARD_EXPIRY = 'cardExpiry';
export const CARD_CVC = 'cardCvc';
export const PAYMENT_REQUEST_BUTTON = 'paymentRequestButton';

/**
 * Card brand key names from stripe response objects
 * https://stripe.com/docs/stripe-js/reference#element-on
 */
export const STRIPE_AMEX = 'amex';
export const STRIPE_DINERS = 'diners';
export const STRIPE_DISCOVER = 'discover';
export const STRIPE_JCB = 'jcb';
export const STRIPE_MAESTRO = 'maestro';
export const STRIPE_MASTERCARD = 'mastercard';
export const STRIPE_UNKNOWN_CARD = 'unknown';
export const STRIPE_VISA = 'visa';
