import classNames from 'classnames';

import CBSNewsLogoSvg from '../assets/logos/cbs-news.svg';
import FastCompanyLogoSvg from '../assets/logos/fast-company.svg';
import TodayShowLogoSvg from '../assets/logos/today-show.svg';
import VogueLogoSvg from '../assets/logos/vogue.svg';
import { LegalDetails } from '../LegalDetails';

import styles from './Footer.module.css';

interface FooterProps {
  className?: string;
}

export function Footer({ className }: FooterProps) {
  return (
    // Note that the element cannot be a `<footer>` element, or have a
    // "contentinfo" role. This child belongs to the `<main>` landmark in Tosa
    // and a <footer> must be outside it.
    <div
      data-testid="footer"
      className={classNames(styles.container, className)}
    >
      <div className={styles.logoContainer}>
        <TodayShowLogoSvg
          className={styles.logo}
          aria-label="Today Show Logo"
        />
        <FastCompanyLogoSvg
          className={styles.logo}
          aria-label="Fast Company Logo"
        />
        <VogueLogoSvg className={styles.logo} aria-label="Vogue Logo" />
        <CBSNewsLogoSvg className={styles.logo} aria-label="CBS News Logo" />
      </div>
      <LegalDetails />
    </div>
  );
}
