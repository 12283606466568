import { useMutation } from '@apollo/client';
import { SuspendFreshSubscriptionInput } from '../../../graphql/types.cgs';
import {
  CancelFreshSubscriptionMutation,
  CancelFreshSubscriptionMutationVariables,
} from './cancelFreshSubscription.cgs.generated-types';

import cancelFreshSubscriptionMutation from './cancelFreshSubscription.cgs.graphql';

interface Props {
  onCompleted: () => void;
  onError: () => void;
}

export interface SubmitParams {
  cancellationReasons: SuspendFreshSubscriptionInput['cancellationReasons'];
  prescriptionDiet?: SuspendFreshSubscriptionInput['prescriptionDiet'];
  replacementFoodBrand?: SuspendFreshSubscriptionInput['replacementFoodBrand'];
  replacementFoodType?: SuspendFreshSubscriptionInput['replacementFoodType'];
  cookAtHomeReplacementRecipe?: SuspendFreshSubscriptionInput['cookAtHomeReplacementRecipe'];
  freeResponse?: SuspendFreshSubscriptionInput['freeResponse'];
}

export type SubmitSuspendFreshSubscription = (params: SubmitParams) => void;
interface UseCancelFreshSubscription {
  submit: SubmitSuspendFreshSubscription;
  isSubmitting: boolean;
}

export function useCancelFreshSubscription(
  props: Props
): UseCancelFreshSubscription {
  const [callCancel, cancelState] = useMutation<
    CancelFreshSubscriptionMutation,
    CancelFreshSubscriptionMutationVariables
  >(cancelFreshSubscriptionMutation, {
    context: {
      endpoint: 'cgs',
    },
    onCompleted: props.onCompleted,
    onError: props.onError,
  });

  const submit: SubmitSuspendFreshSubscription = ({
    cancellationReasons,
    replacementFoodBrand,
    prescriptionDiet,
    replacementFoodType,
    cookAtHomeReplacementRecipe,
    freeResponse,
  }) => {
    void callCancel({
      variables: {
        input: {
          cancellationReasons,
          replacementFoodBrand,
          replacementFoodType,
          prescriptionDiet,
          cookAtHomeReplacementRecipe,
          freeResponse,
        },
      },
    });
  };

  return {
    submit,
    isSubmitting: cancelState.loading,
  };
}
