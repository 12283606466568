import { signupState } from '@farmersdog/lead-browser-storage';

import { ProductLineInput } from '../../../../graphql/types';

export function getProductLineInput(): ProductLineInput | undefined {
  const { subscriptionTypes } = signupState.get();

  return {
    // @ts-expect-error Need to consolidate these enums
    current: subscriptionTypes.current,
    // @ts-expect-error Need to consolidate these enums
    eligible: subscriptionTypes.eligible,
  };
}
