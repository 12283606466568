import flatMap from 'lodash/flatMap';
import startCase from 'lodash/startCase';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import { QueryParameter } from '@farmersdog/constants';
import { useSignupPetLocation } from 'src/hooks/usePetLocation';
import usePreviousEffect from 'src/hooks/usePreviousEffect';
import useProgress from 'src/pages/Signup/hooks/useProgress';
import { selectIsLoggedIn } from 'src/reducers/auth';
import { selectPets } from 'src/reducers/signup/user';
import { showPlanPageCookie } from 'src/utils/cookies';
import { encodePetUri } from 'src/utils/petUri';
import { isFreshSignup } from '@farmersdog/lead-browser-storage';
import { getRedirectPath } from './utils/getRedirectPath';

/**
 * The output of the useSignupRouter hook
 * @typedef {Object} Router
 * @property {RouteData?} previous The previous path in the signup flow
 * @property {RouteData} current The current path in the signup flow
 * @property {RouteData?} next The next path in the signup flow
 * @property {String?} redirect The path to redirect to
 * @property {RouteData[]} routesData
 * @property {Function} goToNext Programmatically navigate the user to the next signup flow
 * @property {Function} goToPrevious Programmatically navigate the user to the previous signup flow
 */

/**
 * The meta data associated with a route
 * @typedef {Object} RouteData
 * @property {String} path The path to this route
 * @property {Boolean} active Is the user currently on this route
 * @property {Number} progress The progress of this route's form
 * @property {Boolean} completed Has the user completed this route's form
 * @property {Boolean=} me Is the route a part of /me
 * @property {Boolean=} pets Is the route a part of /pets
 * @property {Boolean=} recipes Is the route a part of /recipes
 * @property {Boolean=} plans Is the route a part of /plans
 * @property {String=} title Pet name
 * @property {Boolean=} skip Should we skip this route
 * @property {Boolean=} checkout Is the route a part of /checkout
 */

export class SignupRouter {
  constructor() {
    this.visited = [];
    // Order of the signup flow
    this.pathSegments = [
      'me',
      'pets',
      'recipes',
      'plans',
      'checkout',
      'success',
    ];
  }

  /**
   * Determine if the user has been to this path
   *
   * @param {String} path
   * @returns {Boolean}
   */
  getHasVisited(path) {
    return this.visited.indexOf(path) > -1;
  }

  /**
   * Set that a user has been to this path
   *
   * @param {String} path
   * @returns {Boolean}
   */
  setHasVisited(path) {
    if (path && !this.getHasVisited(path)) {
      this.visited.push(path);
    }
  }

  /**
   * Check if a path contains the segment
   *
   * @param {String} pathname the target pathname to check
   * @param  {String} segment the segment arguments to check for
   *
   * @return {Boolean} does the pathname contains any of the paths
   */
  pathContains(pathname = '', segment) {
    return pathname.search(`${PATH_SIGNUP}/${segment}`) > -1;
  }

  /**
   * Loop through the pets array and calculates route data per pet
   *
   * @param {String} pathname
   * @param {String} route
   * @param {Array} pets
   * @param {Object} progress
   * @param {Number} currentPetId
   * @param {String} title
   *
   * @returns {...RouteData} Route data for each pet
   */
  getPetRoutesData(pathname, segment, pets = [], petsProgress, currentPetId) {
    return pets.map((pet, petIndex) => {
      const queryString = `?${QueryParameter.PetName}=${encodePetUri(
        pet.name
      )}`;
      const path = `${PATH_SIGNUP}/${segment}${queryString}`;
      const progress = petsProgress[petIndex];
      const active =
        this.pathContains(pathname, segment) && currentPetId === pet.id;
      if (active) this.setHasVisited(path);
      const visited = this.getHasVisited(path);

      return {
        path,
        active,
        visited,
        progress,
        title: pet.name,
      };
    });
  }

  /**
   * Loop through the pets array calculates pets route data per pet
   *
   * @param {String} pathname
   * @param {Array} pets
   * @param {Object} progress
   * @param {Number} currentPetId
   *
   * @returns {...RouteData} Route data for each pet
   */
  getPetsData(pathname, pets, overallProgress, currentPetId) {
    return this.getPetRoutesData(
      pathname,
      'pets',
      pets,
      overallProgress.singlePets,
      currentPetId
    ).map(petRoute => ({ ...petRoute, pets: true }));
  }

  /**
   * Loop through the pets array calculates recipes route data per pet
   *
   * @param {String} pathname
   * @param {Array} pets
   * @param {Object} progress
   * @param {Number} currentPetId
   *
   * @returns {...RouteData} Route data for each pet
   */
  getRecipesData(pathname, pets, overallProgress, currentPetId) {
    return this.getPetRoutesData(
      pathname,
      'recipes',
      pets,
      overallProgress.singlePlans,
      currentPetId
    ).map(petRoute => ({
      ...petRoute,
      recipes: true,
    }));
  }

  /**
   * Loop though all routes and generate data for each possible path in the
   * signup flow
   *
   * @param {String} pathname
   * @param {Object} progress
   * @param {Array} allPets
   * @param {Number} petId
   * @returns {RouteData[]}
   */
  getRoutesData(pathname, overallProgress, allPets, petId, isTOSA) {
    const getProgress = pathSegment => {
      if (pathSegment === 'recipes' && !isFreshSignup()) {
        return overallProgress.plans;
      }

      return pathSegment in overallProgress ? overallProgress[pathSegment] : 1;
    };

    const routesData = flatMap(this.pathSegments, pathSegment => {
      const path = `${PATH_SIGNUP}/${pathSegment}`;
      const progress = getProgress(pathSegment);
      const active = this.pathContains(pathname, pathSegment);

      if (pathSegment === 'pets' && !isTOSA) {
        return this.getPetsData(pathname, allPets, overallProgress, petId);
      }

      if (pathSegment === 'recipes' && isFreshSignup()) {
        return this.getRecipesData(pathname, allPets, overallProgress, petId);
      }

      if (active) {
        this.setHasVisited(path);
      }

      const shouldSkipPlansPage = !showPlanPageCookie() || !isFreshSignup();

      return {
        [pathSegment]: true,
        title: startCase(pathSegment),
        path,
        active,
        progress,
        skip: pathSegment === 'plans' && shouldSkipPlansPage,
        visited: this.getHasVisited(path),
      };
    });

    return this.getRoutesDataCompleted(routesData);
  }

  /**
   * Sets the completed property for the routesData
   *
   * @param {...RouteData} routesData
   * @returns {...RouteData}
   */
  getRoutesDataCompleted(routesData = []) {
    return routesData.map((routeData, routeIndex) => {
      const { progress, active } = routeData;

      if (routeIndex === 0) {
        routeData.completed = progress === 1 && !active;
      } else {
        const allPreviousComplete = routesData
          .slice(0, routeIndex)
          .every(data => data.completed);
        routeData.completed = progress === 1 && !active && allPreviousComplete;
      }

      return routeData;
    });
  }

  /**
   * Calculates the Router to be returned in the useSignupRouter hook
   *
   * @param {String} pathname
   * @param {Object} history
   * @param {Object} progress
   * @param {Array} allPets
   * @param {Number} petId
   * @returns {Router}
   */
  getRouter(
    pathname,
    history,
    overallProgress,
    allPets,
    petId,
    pathChanged,
    isLoggedIn,
    isTOSA
  ) {
    const routesData = this.getRoutesData(
      pathname,
      overallProgress,
      allPets,
      petId,
      isTOSA
    );
    const firstIncompleteRouteIndex = routesData.findIndex(
      data => !data.completed
    );
    const currentIndex = routesData.findIndex(data => data.active);

    const previous = routesData[currentIndex - 1];
    const current = routesData[currentIndex];
    const nextIndex = routesData.findIndex(
      (route, index) => index > currentIndex && !route.skip
    );
    const next = routesData[nextIndex];

    const goToNext = () => {
      if (next) {
        history.push(next.path);
      }
    };

    const goToPrevious = () => {
      if (previous) {
        history.push(previous.path);
      }
    };

    // Only redirect if user has gone ahead and they are not at the success page
    const isCurrentIndexValid = currentIndex !== -1;
    const isSuccessPage = currentIndex === routesData.length - 1;
    const isPreviousPageIncomplete =
      firstIncompleteRouteIndex !== -1 &&
      firstIncompleteRouteIndex < currentIndex;

    const redirect = getRedirectPath({
      pathChanged,
      isCurrentIndexValid,
      isSuccessPage,
      isPreviousPageIncomplete,
      isLoggedIn,
      currentIndex,
      firstIncompleteRouteIndex,
      routesData,
    });

    const signupRouter = {
      previous,
      current,
      next,
      redirect,

      routesData,

      goToNext,
      goToPrevious,
    };

    return signupRouter;
  }
}

const signupRouter = new SignupRouter();

/**
 * Hook to determine next, previous, and redirect path for the sign up flow
 *
 * @returns {Router} Calculated signup data based on current route data
 */
function useSignupRouter(args) {
  const { isTOSA } = args ?? {};
  const { pathname } = useLocation();
  const history = useHistory();
  const petId = useSignupPetLocation();
  const allPets = useSelector(selectPets);
  const { overall: overallProgress } = useProgress();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [pathChanged, setPathChanged] = useState(true);

  usePreviousEffect((previous, next) => {
    setPathChanged(previous !== next);
  }, pathname);

  return useMemo(
    () =>
      signupRouter.getRouter(
        pathname,
        history,
        overallProgress,
        allPets,
        petId,
        pathChanged,
        isLoggedIn,
        isTOSA
      ),
    [
      allPets,
      history,
      isLoggedIn,
      pathChanged,
      pathname,
      petId,
      overallProgress,
      isTOSA,
    ]
  );
}

export default useSignupRouter;
