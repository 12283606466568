/**
 * Used for Yup validation testing.  Strips out non digit characters and compares against a list of valid lengths.
 *
 * @param validLengths - An array containing valid lengths the input can be.
 * @returns A function that takes in an input, and returns true if it matches one of the valid lengths, false otherwise.
 */
export function isInputNumberLength(validLengths: number[]) {
  return (input = '') => {
    if (input.length === 0) {
      return true;
    }
    const sanitizedInput = input.replace(/[^0-9]/g, ''); // Remove non digit characters
    return validLengths.includes(sanitizedInput.length);
  };
}
