interface ComputeButtonTextArgs {
  hasHealthIssueWarning: boolean;
  hasPrescriptions: boolean;
}

export function computeButtonText({
  hasHealthIssueWarning,
  hasPrescriptions,
}: ComputeButtonTextArgs) {
  const defaultText = 'Continue';

  if (hasPrescriptions) {
    return 'Continue without Rx';
  }

  if (hasHealthIssueWarning) {
    return 'Continue Anyway';
  }

  return defaultText;
}
