import get from 'lodash/get';

/**
 * Get a configuration by key.
 * Server-side, use the config found in config/config.js
 * Client-side, use the config in the global window variable.
 *
 * @export
 * @param {string?} key
 * @returns {any}
 */
export default function config(key) {
  if (!process.env.BROWSER) {
    // eslint-disable-next-line global-require
    const convictSchema = require('../config/config').default;
    /* eslint-disable global-require, no-console, import/no-dynamic-require */
    const convict = require('convict');
    const convictConfig = convict(convictSchema);

    // Load environment dependent configuration
    const appEnv = convictConfig.get('app.env');
    convictConfig.load(require(`./${appEnv}.json`));

    if (process.env.SECRETS_JSON_PATH && process.env.SECRETS_JSON) {
      throw 'SECRETS_JSON_PATH and SECRETS_JSON are mutually exclusive';
    }

    // SECRETS_JSON_PATH will be set for containers running on k8s.
    if (process.env.SECRETS_JSON_PATH) {
      convictConfig.loadFile(process.env.SECRETS_JSON_PATH);
    }

    // SECRETS_JSON will be set for containers running on ECS. the
    // conditional below can be removed once this app has been migrated to
    // k8s.
    if (process.env.SECRETS_JSON) {
      convictConfig.load(JSON.parse(process.env.SECRETS_JSON));
    }

    convictConfig.validate({ allowed: 'strict' });

    return convictConfig.get(key);
  }
  // eslint-disable-next-line no-undef, no-underscore-dangle
  return get(window.__APP_CONFIG__, key);
}

/**
 * Return the configuration for the client side. Print the content returned
 * by this function in the server-side rendered HTML document.
 *
 * @export
 * @returns
 */
export function getHtmlConfig() {
  if (process.env.BROWSER) {
    throw new Error('This function doesn’t work in this enviroment.');
  } else {
    const allConfig = config();
    delete allConfig.basicAuth;
    delete allConfig.server;
    return `window.__APP_CONFIG__=${JSON.stringify(allConfig)}`;
  }
}
