import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Return an object that updates based on a deep equality reference rather then
 * a shallow one.
 *
 * This is useful when you need to pass parameters into 3rd party objects and
 * you want to avoid needlessly destroying and creating those objects inside of
 * a useEffect hook.
 *
 * @param next - Any object that you want to update based on a deep equality comparison
 */
export function useDeepMemo<T>(next: T): T {
  const [current, setCurrent] = useState<T>(next);

  useEffect(() => {
    setCurrent(previous => {
      if (!isEqual(previous, next)) {
        return next;
      }

      return previous;
    });
  }, [next]);

  return current;
}
