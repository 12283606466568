import { reporter } from 'src/services/reporter';
import { getFacebookPixelAttributes } from 'src/vendors/facebook';

import events from '../eventNames';
import track from '../track';

import { SubscriptionType } from 'src/graphql/types';
import getPreTaxTotal from '../getPreTaxTotal';

interface TrackDiyRecipesSuccessArgs {
  user?: Reducer.User;
  subtotalAmount: number;
  configuration?: Record<string, unknown>;
  requestAttributes?: Record<string, unknown>;
}

/**
 * A tracking event that is used to mark a customer successfully
 * clicking the continue button on the DIY recipes screen.
 *
 * @param user - A TFD user object
 * @param subtotalAmount - The subtotal amount of the DIY quote.
 * @param configuration - The configuration object stored in redux.
 * @param requestAttributes - Freeform attributes to submit to segment.
 */
export function trackDiyRecipesSuccess({
  user,
  subtotalAmount,
  configuration,
  requestAttributes,
}: TrackDiyRecipesSuccessArgs) {
  if (!user) {
    reporter.warn(
      `Segment event "${events.recipes_success}" has missing parameter "user"`
    );
  }

  const totalAmount = getPreTaxTotal(subtotalAmount);

  track(events.recipes_success, {
    totalAmount,
    // @ts-expect-error TODO: Update this consumer to use typescript
    ...getFacebookPixelAttributes(user, configuration),
    ...requestAttributes,
    product_line: SubscriptionType.Diy,
    label: SubscriptionType.Diy,
  });
}
