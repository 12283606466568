/* eslint-disable @typescript-eslint/no-empty-object-type */
import { isValidElement } from 'react';

/**
 * Check to see if a renderable variable is a fragment or a function.
 *
 * @param renderable - a react node, function, component, or primitive type
 * @returns whether not the renderable is a component or is directly renderable
 */
function isRenderable(renderable: {} | null | undefined): boolean {
  const type = typeof renderable;

  switch (type) {
    case 'function':
      return false;
    case 'object':
      return isValidElement(renderable) || renderable === null;
    default:
      return true;
  }
}

export default isRenderable;
