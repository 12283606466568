import { useMemo } from 'react';
import { useFetchChangeFreshOrderSizeQuotes } from './fetchChangeFreshOrderSizeQuotes';

export function useChangeFrequencyData() {
  const { data, loading, error } = useFetchChangeFreshOrderSizeQuotes();

  if (error) {
    throw error;
  }

  const currentFrequency = useMemo(() => {
    return data?.customer.changeFreshOrderSizeQuotes.current.frequency;
  }, [data]);

  const suggestedFrequencies = useMemo(() => {
    return data?.customer.changeFreshOrderSizeQuotes;
  }, [data]);

  const currentNextDate = useMemo(() => {
    return data?.customer.freshSubscription.nextDate;
  }, [data]);

  const journeyStatus = useMemo(() => {
    return data?.customer.journeyStatus;
  }, [data]);

  return {
    currentFrequency,
    suggestedFrequencies,
    currentNextDate,
    loading,
    journeyStatus,
  };
}
