import { useCallback, useEffect, useMemo, useState } from 'react';

import { NodeNames, RecipeNames } from '@farmersdog/constants';
import { ContentLoader } from '@farmersdog/corgi-x';
import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';

import {
  trackRecipesClickBackToRecommendedPlan,
  trackRecipesClickContinueWithRecommendation,
  trackRecipesClickSelectYourOwnPlan,
} from '../../analytics';
import { Position } from '../../blueprint/types';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import config from '../../config';
import { FetchQuoteQuery } from '../../graphql/types';
import {
  useLeadState,
  useSurpriseHigherDiscountExperiment,
  useWeightLossPersonalizationExperiment,
} from '../../hooks';
import { useHandleRecipesCompleted } from '../../hooks/useHandleRecipesCompleted';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import {
  FormFieldsType,
  PetRecipes,
  TOSAComponentInput,
  TOSALeafNode,
} from '../../types';
import {
  cvr_price_value_modal,
  PriceValueModalTreatments,
  scroller,
} from '../../utils';
import { MultiRecipeModal } from '../RecipeModal/MultiRecipeModal';

import { BodyConditionPersonalizationWrapper } from './BodyConditionPersonalizationWrapper';
import { FRESH_RECOMMENDATION_STATE_NAME } from './constants';
import {
  useClearCustomizedSelection,
  useControlFreshSelection,
  useFetchPetsRecommendedRecipes,
  useRemoveUnavailableRecipes,
  useShouldLandOnCustomization,
} from './hooks';
import { PetNamesAndRequestedCaloriesProvider } from './hooks/usePetNamesAndRequestedCalories';
import { useSurpriseHigherDiscountRecipesPage } from './hooks/useSurpriseHigherDiscountRecipesPage';
import styles from './RecipesPage.module.css';
import { RecipesSelection } from './RecipesSelection/RecipesSelection';
import { RecommendedPlan } from './RecommendedPlan';
import { PersonalizeRecommendedPlan } from './RecommendedPlan/PersonalizeRecommendedPlan';
import { RecommendedPlanWrapper } from './RecommendedPlanWrapper';
import { checkLpfRecipeStatus } from './utils/checkLpfRecipeStatus';
import { getPetInfo } from './utils/getPetInfo';
import { getRecommendedRecipeCopy } from './utils/getRecommendedRecipeCopy';
import { getSelectedAvailableRecipes } from './utils/getSelectedAvailableRecipes';

function RecipesPage_(props: TOSAComponentInput<TOSALeafNode>) {
  const {
    node,
    formMethods,
    petRecipes,
    setPetRecipes,
    formSubmitRequest,
    formValidationSchema,
    useFeature,
  } = props;

  const { isRecipesPageEnabled, code } = useSurpriseHigherDiscountExperiment({
    useFeature,
  });

  useSurpriseHigherDiscountRecipesPage({
    shouldReplaceDiscount: isRecipesPageEnabled,
    code,
  });

  const { position } = getNodeNameAndPosition(node.name);
  const formValues = formMethods.getValues();
  const currentSelection =
    formValues[`${NodeNames.FreshSelection}-${position}`];

  const petName = getPetInfo({ formValues, petPosition: position }).name;
  const petGender = getPetInfo({ formValues, petPosition: position }).gender;
  const bodyCondition = getPetInfo({
    formValues,
    petPosition: position,
  }).bodyCondition;

  const [isCustomizingRecipes, setIsCustomizingRecipes] = useState<
    null | boolean
  >(null);

  const {
    petRecipesQueryState,
    currentPetRecipes,
    recommendedRecipes,
    recommendedRecipesNames,
  } = useFetchPetsRecommendedRecipes({
    currentPetName: petName,
    petRecipes,
    setPetRecipes,
  });

  const formattedRecommendedRecipes = useMemo(
    () =>
      recommendedRecipes.map(recipe => ({
        name: recipe.name as RecipeNames,
        displayName: recipe.content.displayName,
        recommended: recipe.recommended,
      })),
    [recommendedRecipes]
  );

  const { lpfRecipeEligible, lpfRecipeRecommended, onlyLpfRecipeRecommended } =
    checkLpfRecipeStatus(currentPetRecipes || []);

  const availableSelectedRecipesNames = getSelectedAvailableRecipes({
    currentPetRecipes,
    currentSelection,
  });

  const { defaultToCustomization } = useShouldLandOnCustomization({
    availableSelectedRecipesNames,
    recommendedRecipesNames,
    setIsCustomizingRecipes,
    isCustomizingRecipes,
  });

  const clearCustomizedSelection = useCallback(() => {
    formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, []);
  }, [formMethods, position]);

  const setCustomizedSelection = useCallback(
    (recipes: string[]) => {
      formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, recipes);
    },
    [formMethods, position]
  );

  useRemoveUnavailableRecipes({
    setCustomizedSelection,
    currentSelectedRecipeNames: currentSelection,
    availableSelectedRecipesNames,
  });

  const { setHasToggledSelection } = useClearCustomizedSelection({
    defaultToCustomization,
    clearCustomizedSelection,
  });

  const { freshSelectionControlledField, handleSelectionChange } =
    useControlFreshSelection({
      control: formMethods.control,
      petPosition: position,
      currentSelection: currentSelection ?? [],
      toggleSelection: () => setHasToggledSelection(true),
    });

  useEffect(() => {
    // Ensure page scrolls to top when transitioning between recipes
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [isCustomizingRecipes]);

  const onRecipesCompleted = useHandleRecipesCompleted();
  const isLastPet = formValues.numPets === Number(position);

  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const userId = lead?.corePostgresUserId
    ? String(lead.corePostgresUserId)
    : undefined;

  let lpfRecipeNames = null;
  if (currentPetRecipes) {
    lpfRecipeNames = currentPetRecipes
      .filter(recipe => recipe.content.productLine === 'lpf')
      .map(recipe => recipe.name);
  }

  useSetLpfProductEligibility({
    shouldPersistLpfEligibility: lpfRecipeEligible,
    email: lead?.email ?? null,
    lpfRecipeNames,
  });

  const { treatment: priceValueModalTreatment } = useFeature(
    cvr_price_value_modal,
    {},
    userId
  );

  const {
    treatment: bodyConditionPersonalizationTreatment,
    showBodyConditionPersonalization,
    isWeightLossTreatment,
  } = useWeightLossPersonalizationExperiment({
    useFeature,
    bodyCondition,
  });

  const recommendedRecipeCopy = getRecommendedRecipeCopy({
    petName,
    petGender,
    lpfRecipeDisplayNames: currentPetRecipes
      ?.filter(({ content: { productLine } }) => productLine === 'lpf')
      .map(({ content: { displayName } }) => displayName),
    isOnlyLpfRecipeRecommended: onlyLpfRecipeRecommended,
    showBodyConditionPersonalization,
    isWeightLossPersonalization: isWeightLossTreatment,
    recommendedRecipes: formattedRecommendedRecipes,
  });

  const handleRecommendedPlanSubmit = (
    quote: FetchQuoteQuery['fetchQuote'] | null
  ) => {
    formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, []);
    petMixingPlanRatiosState.resetAllPetRatios();
    trackRecipesClickContinueWithRecommendation({
      recommendedRecipes,
    });
    onRecipesCompleted({
      lead,
      isLastPet,
      quote,
    });
  };

  const handleCustomizeRecipesSubmit = (
    quote: FetchQuoteQuery['fetchQuote'] | null
  ) => {
    formMethods.setValue(`${FRESH_RECOMMENDATION_STATE_NAME}-${position}`, []);
    petMixingPlanRatiosState.resetAllPetRatios();
    onRecipesCompleted({
      lead,
      isLastPet,
      quote,
    });
  };

  const handleReturnToRecommendedPlan = () => {
    setIsCustomizingRecipes(false);
    trackRecipesClickBackToRecommendedPlan();
  };

  const handleCustomizePlan = () => {
    setIsCustomizingRecipes(true);
    trackRecipesClickSelectYourOwnPlan();
  };

  const loading =
    isCustomizingRecipes === null ||
    petRecipesQueryState.loading ||
    !currentPetRecipes;

  const logger = useMemo(() => {
    return new Logger('tosa:frontend:RecipesPage');
  }, []);

  if (config.get('features.tosaLogging')) {
    if (!loading) {
      logger.info('recipes page loading completed');
    } else {
      logger.info('recipes page is loading', {
        isCustomizingRecipes,
        'petRecipesQueryState.loading': petRecipesQueryState.loading,
        currentPetRecipes,
      });
    }
  }

  return (
    <ContentLoader loading={loading} height="100vh">
      <MultiRecipeModal recipes={recommendedRecipes} petName={petName} />
      {isCustomizingRecipes ? (
        <>
          <RecipesSelection
            recipes={currentPetRecipes ?? []}
            returnToRecommendedPlan={handleReturnToRecommendedPlan}
            currentSelection={currentSelection ?? []}
            onSubmit={handleCustomizeRecipesSubmit}
            formSubmitting={formSubmitRequest.loading}
            freshSelectionControlledField={freshSelectionControlledField}
            handleSelectionChange={handleSelectionChange}
            petName={petName}
            formValidationSchema={formValidationSchema}
            petRecipes={petRecipes}
            formValues={formValues}
            selectionIsValid={
              hasValidSelection({ formValues, petPosition: position }) &&
              hasValidRecommendation({
                formValues,
                petRecipes,
              })
            }
            recommendationIsValid={hasValidRecommendation({
              formValues,
              petRecipes,
            })}
            useFeature={useFeature}
            userId={userId}
          />
        </>
      ) : (
        <>
          {showBodyConditionPersonalization ? (
            <BodyConditionPersonalizationWrapper
              petName={petName}
              treatment={bodyConditionPersonalizationTreatment}
              useFeature={useFeature}
              recommendedRecipes={formattedRecommendedRecipes}
              bodyCondition={bodyCondition}
              petGender={petGender}
            >
              <PersonalizeRecommendedPlan
                useFeature={useFeature}
                hasNonRecommendedLpfRecipes={
                  lpfRecipeEligible && !lpfRecipeRecommended
                }
                lpfRecipeRecommended={lpfRecipeRecommended}
                recommendedRecipes={formattedRecommendedRecipes}
                customizePlan={handleCustomizePlan}
                registerProps={formMethods.register(
                  `${FRESH_RECOMMENDATION_STATE_NAME}-${position}`
                )}
                onSubmit={handleRecommendedPlanSubmit}
                formSubmitting={formSubmitRequest.loading}
                petName={petName}
                formValidationSchema={formValidationSchema}
                petRecipes={petRecipes}
                formValues={formValues}
                recommendationIsValid={hasValidRecommendation({
                  formValues,
                  petRecipes,
                })}
                recommendedRecipeCopy={recommendedRecipeCopy}
              />
            </BodyConditionPersonalizationWrapper>
          ) : (
            <RecommendedPlanWrapper petName={petName}>
              <RecommendedPlan
                className={styles.recommendedPlan}
                useFeature={useFeature}
                hasNonRecommendedLpfRecipes={
                  lpfRecipeEligible && !lpfRecipeRecommended
                }
                lpfRecipeRecommended={lpfRecipeRecommended}
                recommendedRecipes={formattedRecommendedRecipes}
                customizePlan={handleCustomizePlan}
                registerProps={formMethods.register(
                  `${FRESH_RECOMMENDATION_STATE_NAME}-${position}`
                )}
                onSubmit={handleRecommendedPlanSubmit}
                formSubmitting={formSubmitRequest.loading}
                petName={petName}
                formValidationSchema={formValidationSchema}
                petRecipes={petRecipes}
                formValues={formValues}
                recommendationIsValid={hasValidRecommendation({
                  formValues,
                  petRecipes,
                })}
                priceValueModalTreatment={
                  priceValueModalTreatment as PriceValueModalTreatments
                }
                recommendedRecipeCopy={recommendedRecipeCopy}
              />
            </RecommendedPlanWrapper>
          )}
        </>
      )}
    </ContentLoader>
  );
}

interface HasValidRecommendationArgs {
  formValues: FormFieldsType;
  petRecipes: PetRecipes;
}
export function hasValidRecommendation({
  formValues,
  petRecipes,
}: HasValidRecommendationArgs) {
  const petsNames = getPetsNames(formValues);

  for (let i = 0; i < petsNames.length; i++) {
    const recipes = petRecipes[petsNames[i]];
    const hasRecommendation = recipes && recipes.length > 0;
    if (!hasRecommendation) {
      return false;
    }
  }

  return true;
}

interface HasValidSelectionArgs {
  formValues: FormFieldsType;
  petPosition: Position;
}
export function hasValidSelection({
  formValues,
  petPosition,
}: HasValidSelectionArgs) {
  const selection = formValues[`${NodeNames.FreshSelection}-${petPosition}`];
  return Boolean(selection && selection.length > 0);
}

function getPetsNames(formValues: FormFieldsType) {
  return Object.entries(formValues)
    .filter(([key]) => key.startsWith(`${NodeNames.Name}`))
    .map(([, val]) => val) as string[];
}

export function RecipesPage(props: TOSAComponentInput<TOSALeafNode>) {
  return (
    <PetNamesAndRequestedCaloriesProvider>
      <RecipesPage_ {...props} />
    </PetNamesAndRequestedCaloriesProvider>
  );
}
