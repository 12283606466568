import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import type { Ga4Configuration } from './Ga4Script';

export enum Ga4StandardEvent {
  PageView = 'page_view',
  Purchase = 'purchase',
  AddToCart = 'add_to_cart',
  GenerateLead = 'generate_lead',
}

export enum Ga4CustomEvent {
  MeSuccess = 'me_success',
  PetsSuccess = 'pets_success',
  RecipesSuccess = 'recipes_success',
  CheckoutSuccess = 'checkout_success',
}

export enum Ga4VetPortalEvent {
  SignupSuccess = 'vet_portal_signup_success',
}

export type Ga4Event = Ga4StandardEvent | Ga4CustomEvent | Ga4VetPortalEvent;

type Ga4EventProperties = Record<string, unknown>;

export type MountGa4EventArgs = {
  name: Ga4Event;
  config: Ga4Configuration;
  properties?: Ga4EventProperties;
};

function _mountGa4Event(
  w: Window,
  { name, config, properties = {} }: MountGa4EventArgs
) {
  if (!config.enabled) {
    return;
  }

  w.gtag?.(
    'event',
    name,
    buildProperties({ eventProperties: properties, config })
  );
}

export const mountGa4Event = mountVendorWindowEvent(
  'Ga4 Mount',
  _mountGa4Event
);

interface BuildPropertiesArgs {
  eventProperties: MountGa4EventArgs['properties'];
  config: Ga4Configuration;
}

function buildProperties({ eventProperties, config }: BuildPropertiesArgs) {
  const debugProperty = config.debugView ? { debug_mode: true } : undefined;

  return {
    ...eventProperties,
    ...debugProperty,
    send_to: config.measurementId,
  };
}
