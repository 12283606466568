import { useRef, useState } from 'react';

import Slider from 'src/components/Slider';
import Picture from 'src/components/Picture';

import { Grid, PageWidth, Text } from '@farmersdog/corgi-x';
import { Viewport } from 'src/screen';

import { HomepageTitle1 } from '../HomepageTitle';
import HomepageText from '../HomepageText';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import VETS_DATA from './vetsData';

import styles from './SectionQuotes.module.scss';
import classNames from 'classnames';
import { useOnEnterView } from 'src/hooks/useOnEnterView';
import { trackHomeVetSeen } from 'src/analytics/events/trackHomeVetSeen';

function SectionQuotes() {
  const [activeQuote, setActiveQuote] = useState(0);
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();
  const ref = useRef(null);
  useOnEnterView({
    ref,
    onEnter: trackHomeVetSeen,
  });

  return (
    <section
      className={classNames(styles.section, {
        [styles.accessibilityTheme]: isAccessibilityThemeOn,
      })}
    >
      <PageWidth>
        <HomepageTitle1
          color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
          center
        >
          Formulated by nutritionists,
          <br />
          praised by vets.
        </HomepageTitle1>
      </PageWidth>
      <div style={{ margin: '0 auto' }} ref={ref}>
        <Slider currentSlide={activeQuote}>
          {VETS_DATA.map(vet => (
            <Slider.Slide key={`vet-slide-${vet.name}`}>
              <figure>
                <HomepageText
                  color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                  as="blockquote"
                >
                  “{vet.quote}”
                </HomepageText>
                <Text
                  as="cite"
                  variant="article-16"
                  color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                  className={styles.name}
                >
                  {vet.name}
                </Text>
              </figure>
            </Slider.Slide>
          ))}
        </Slider>
        <Grid justifyContent="center" style={{ marginBottom: 40 }}>
          {VETS_DATA.map((vet, i) => {
            const isCurrent = activeQuote === i;

            return (
              <button
                key={`vet-button-${vet.name}`}
                type="button"
                aria-pressed={isCurrent}
                data-active={isCurrent}
                className={styles.button}
                onClick={() => setActiveQuote(i)}
              >
                <Viewport
                  xs={
                    <Picture
                      sources={vet.sources}
                      height={64}
                      width={64}
                      alt={vet.name}
                    />
                  }
                  md={
                    <Picture
                      sources={vet.sources}
                      height={84}
                      width={84}
                      alt={vet.name}
                    />
                  }
                />
              </button>
            );
          })}
        </Grid>
      </div>
    </section>
  );
}

export default SectionQuotes;
