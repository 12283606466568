import { TreatSize } from 'src/graphql/tosa.types';
import events from '../eventNames';
import track from '../track';

type TrackSignupTreatAddTreatToCartArgs = {
  name: string;
  size: TreatSize;
};

/** A Segment tracking event for when a user adds a treat to their cart in the checkout page. */

export function trackSignupFreeTreatAddTreatToCart({
  name,
  size,
}: TrackSignupTreatAddTreatToCartArgs) {
  track(events.signup_free_treats_add_treat_to_cart, {
    name,
    size,
  });
}
