import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Arrow } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

import styles from './NavSelect.module.scss';

function NavSelect({ onChange, children }) {
  const [value, setValue] = useState(0);

  const handleChange = e => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Text
      as="span"
      variant="heading-22"
      color="kale-3"
      bold
      className={classNames(styles.container, useCorgiViewport(styles))}
    >
      <select
        value={value}
        onBlur={handleChange}
        onChange={handleChange}
        className={styles.select}
      >
        {value === 0 && (
          <option value={0} disabled aria-hidden>
            Jump to a benefit
          </option>
        )}
        {children}
      </select>
      <span className={styles.arrow}>
        <Arrow height={15} fill="charcoal-1" />
      </span>
    </Text>
  );
}

NavSelect.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
};

export default NavSelect;
