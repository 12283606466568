import { SlotEligibility } from '../../../../../../slots';
import { useOrderSizeCtaData } from './useOrderSizeCtaData';

export function useEligibility(): SlotEligibility {
  // Use data gatherer so logic will not separate between hooks
  const { isReady, eligibleByCriteria, isDismissed } = useOrderSizeCtaData();

  if (!isReady) {
    return {
      isReady,
      isCandidate: false,
    };
  }

  return {
    isReady,
    isCandidate: !isDismissed && eligibleByCriteria,
  };
}
