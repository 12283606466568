import track from '../track';
import events from '../eventNames';

export type TrackDiyDownsellSuccessInput = {
  userId: number;
  cadence: number;
  quantity: number;
};

export function trackDiyDownsellSuccess({
  userId,
  cadence,
  quantity,
}: TrackDiyDownsellSuccessInput) {
  track(events.diy_downsell_success, {
    userId,
    cadence,
    quantity,
  });
}
