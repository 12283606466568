import { GraphQLFormattedError } from 'graphql';

export function doesGraphqlErrorPathIncludeFields(
  errorPathFields: string[],
  error: GraphQLFormattedError | undefined
): boolean {
  return Boolean(
    errorPathFields.find(pathField => error?.path?.includes(pathField))
  );
}
