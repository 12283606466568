import { tosaToken } from '@farmersdog/lead-browser-storage';

import { tokenUtils } from '../../utils';

export function getEmailFromCache() {
  const token = tosaToken.get();

  if (!token) {
    return '';
  }

  const payload = tokenUtils.decodeToken(token);

  if (!payload) {
    return '';
  }

  return payload.email;
}
