import { Helmet } from 'react-helmet-async';

import { AmazonTagEvent } from './trackAmazonTagEvent';

import { AMAZON_TAG_REGION, AMAZON_TAG_ID } from './constants';

export enum AmazonTagMethod {
  TrackEvent = 'trackEvent',
}

export type AmazonTagMounter = (
  method: AmazonTagMethod,
  eventName: AmazonTagEvent,
  properties?: Record<string, unknown>
) => void;

export function AmazonTagScript() {
  return (
    <Helmet>
      <script>
        {`!function(w,d,s,t,a){if(w.amzn)return;w.amzn=a=function(){w.amzn.q.push([arguments,(new Date).getTime()])};a.q=[];a.version="0.0";s=d.createElement("script");s.src="https://c.amazon-adsystem.com/aat/amzn.js";s.id="amzn-pixel";s.async=true;t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);

          amzn("setRegion", "${AMAZON_TAG_REGION}");
          amzn("addTag", "${AMAZON_TAG_ID}");
       `}
      </script>
    </Helmet>
  );
}
