import { getUserId } from '@farmersdog/analytics';
import { getIsOnDoNotSellShareList } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

/*
  This is a utility function that should be used in tandem with all third party vendor events
  that fire based on a window object method.
  This utility serves two primary purposes:
  1. It automatically checks the do not sell share status from browser storage and prevents the call 
  if the status is `true`, unless the `isBlockedByDoNotSellShare` option is set to `false`.
  2. It provides a consistent way to handle the window object method call, ensuring that the method
  is only called in the browser environment using the `browserOnly` utility.
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MountFunction<Args extends any[]> = (
  window: Window,
  ...args: Args
) => void | Promise<void>;

export interface MountOptions {
  isBlockedByDoNotSellShare?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mountVendorWindowEvent<Args extends any[]>(
  vendorName: string,
  mount: MountFunction<Args>,
  { isBlockedByDoNotSellShare = true }: MountOptions = {}
): (...args: Args) => void {
  const logger = new Logger('app:vendors:mountVendorWindowEvent');

  return (...args: Args) => {
    browserOnly((window: Window) => {
      if (isBlockedByDoNotSellShare && getIsOnDoNotSellShareList()) {
        logger.warn(
          'Blocking vendor window event because user is on do not sell share list',
          { vendorName, userId: getUserId() }
        );
        return;
      }

      void mount(window, ...args);
    });
  };
}
