/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import { LogoProps } from './Logos.types';

import TodayShowPng from 'src/assets/images/logos/today-show.png';
import TodayShowWebP from 'src/assets/images/logos/today-show.webp';

export function TodayShowLogo(props: LogoProps) {
  return (
    <Picture
      sources={[TodayShowWebP, TodayShowPng]}
      alt="Today Show Logo"
      {...props}
    />
  );
}
