import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

interface TrackRecipesViewedRecipeDetailsArgs {
  recipeName: string;
}

export function trackRecipesViewedRecipeDetails({
  recipeName,
}: TrackRecipesViewedRecipeDetailsArgs) {
  track(segmentTrackEvents.recipes_viewed_recipe_details, {
    recipeName,
  });
}
