/**
 * Generate the properties for a Ga4 PageView event
 */

import { redactEmailsFromPath } from '@farmersdog/utils';

type Ga4PageViewLocation = Pick<Location, 'pathname' | 'search' | 'hash'> & {
  origin: string;
};

export interface GetGa4PageViewArgs {
  location: Ga4PageViewLocation;
}

export function getGa4PageViewProperties({ location }: GetGa4PageViewArgs) {
  const { pathname, search, hash, origin } = location;
  const cleanedPath = redactEmailsFromPath(pathname);

  const properties = {
    page_location: `${origin}${cleanedPath}${search}${hash}`,
    page_path: cleanedPath,
  };

  return properties;
}
