import {
  ContentLoader,
  Divider,
  Grid,
  GridItem,
  Link,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import { useId } from 'react';
import { FeatureDocuments, useExperimentDocuments } from '../../../content';
import { reporter } from '../../../errors/services';
import { trackHomepageEssentialsLinkClicked } from '../../analytics';
import {
  HomepageEssentialsDocument,
  useFetchHomepageData,
} from '../../network';
import { fallbackContent } from './FallbackContent';
import styles from './HomepageEssentialsPrismic.module.css';
import { useGetHomepageTreatment } from './hooks';

export function HomepageEssentialsPrismic() {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving prismic data', { sourceError: error });
    },
  });

  const homepageTreatment = useGetHomepageTreatment({
    journeyStatus: request.data?.customer.journeyStatus,
    hasPickyEater: request.data?.customer.userCharacteristics.hasPickyEater,
  });

  const { content, loading } =
    // This type should be pulled from types.generated.ts when its working
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useExperimentDocuments<HomepageEssentialsDocument>({
      featureName: FeatureDocuments.tsx_essentials_homepage_guides,
      treatmentName: homepageTreatment,
      fallbackContent: fallbackContent,
    });

  const cardHeadingId = useId();

  return (
    <Section
      variant="card"
      className={styles.card}
      aria-labelledby={cardHeadingId}
      bottomSpacing="lg"
    >
      <ContentLoader loading={loading} height="500" mode="dark">
        {content?.section_heading?.[0].text && (
          <Text
            id={cardHeadingId}
            as="h3"
            variant="heading-28"
            color="kale-3"
            bold
            vSpacing="none"
          >
            {content.section_heading[0].text}
          </Text>
        )}
        <Divider variant="red-squiggly" align="start" spacing={24} />
        {content?.section_subheading?.[0]?.text && (
          <Text as="p" variant="article-16" vSpacing="xs">
            {content.section_subheading[0].text}
          </Text>
        )}
        <Grid topSpacing="sm" columnGap="md">
          {content?.guides?.map((guide, i) => (
            <GridItem key={i} md={6}>
              <div className={styles.guideContainer}>
                {guide.guide_image?.url && (
                  <Picture
                    sources={[guide.guide_image.url]}
                    alt={guide.guide_image.alt ?? ''}
                    className={styles.guidePicture}
                  />
                )}
                {guide.guide_heading && (
                  <Text
                    as="h5"
                    variant="heading-22"
                    color="kale-3"
                    bold
                    topSpacing="sm"
                    bottomSpacing="none"
                  >
                    {guide.guide_heading[0].text}
                  </Text>
                )}
                {guide.guide_subheading && (
                  <Text as="p" variant="article-16" vSpacing="xxs">
                    {guide.guide_subheading[0].text}
                  </Text>
                )}
                {guide.guide_cta_link?.url && guide.guide_cta_text && (
                  <Link
                    to={guide.guide_cta_link.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      trackHomepageEssentialsLinkClicked({
                        url: guide.guide_cta_link?.url ?? '',
                        label: guide.guide_cta_text?.[0].text ?? '',
                      })
                    }
                  >
                    <Text
                      variant="heading-16"
                      className={styles.guideLinkText}
                      as="p"
                      topSpacing="xxs"
                      bottomSpacing="none"
                    >
                      {guide.guide_cta_text[0].text}
                    </Text>
                  </Link>
                )}
              </div>
            </GridItem>
          ))}
        </Grid>
      </ContentLoader>
    </Section>
  );
}
