import { RedirectStatesEnumType, getRedirectStates } from './redirectStates';
import { TerminalStatesEnumType } from './terminalStates';
import { CancellationResponsesEnumType } from './cancellationResponses';
import { InitialStatesEnumType } from './initialStates';
import { ReplacementFoodType } from './replacementFoodStates';

export type StatesUnion =
  | CancellationResponsesEnumType
  | TerminalStatesEnumType
  | InitialStatesEnumType
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RedirectStatesEnumType
  | ReplacementFoodType;

export function isRedirectStateKey(
  key: unknown
): key is keyof typeof redirectStates {
  const redirectStates = getRedirectStates();
  return Object.hasOwnProperty.call(redirectStates, String(key));
}
