import { Text } from '@farmersdog/corgi-x';

/**
 * Given an error, it returns the message that can be safely displayed in the
 * User Interface. For example, we want to show the message for
 * `ValidationError` and `UserError`, but not the technical message of a
 * `RequestError`.
 * `
 * @param {Error} error
 * @return Element
 */
function getDisplayMessage(error) {
  if (error.displayMessage) {
    if (typeof error.displayMessage === 'string') {
      return (
        <Text as="p" variant="heading-16">
          {error.displayMessage}
        </Text>
      );
    }
    return error.displayMessage;
  }

  switch (error.name) {
    case 'ValidationError':
    case 'UserError':
      // These errors have message that can be shown to the user
      return (
        <Text as="p" variant="heading-16">
          {error.message}
        </Text>
      );
    case 'NetworkError':
      return (
        <Text as="p" variant="heading-16">
          Something did not work when connecting to our server. Please verify
          you are online or contact our customer support.
        </Text>
      );
    case 'ApplicationError':
    default:
      return (
        <Text as="p" variant="heading-16">
          We couldn’t process your request. While we are cleaning the mess, try
          to reload this page or contact our customer support.
        </Text>
      );
  }
}

export default getDisplayMessage;
