import {
  CustomerClinicSearchTreatments,
  UserFeatureKeys,
} from '../../app/lib/Split';
import { useExperimentContext } from '../../experiments';

/**
 * Determines
 * 1. Whether a user should see the Vet Clinic Card.
 * 2. When a user sees the Vet Clinic Card, whether he can see the Vet Clinic Search Box
 */
export function useVetClinic() {
  const { useFeature: getFeature } = useExperimentContext();
  const treatment = getFeature?.(
    UserFeatureKeys.HEALTH_CUSTOMER_CLINIC_SEARCH,
    {}
  ).treatment;
  const shouldShowVetClinic =
    treatment === CustomerClinicSearchTreatments.on ||
    treatment === CustomerClinicSearchTreatments.hide_search;
  const shouldShowVetClinicSearch =
    treatment === CustomerClinicSearchTreatments.on;

  return {
    shouldShowVetClinic,
    shouldShowVetClinicSearch,
  };
}
