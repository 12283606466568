export const newPasswordValidationRules = {
  valueMissing: {
    value: true,
    message: 'Please enter a password for your account.',
  },
};

export const newPasswordConfirmationValidationRules = {
  valueMissing: {
    value: true,
    message: 'Please enter a repeat password for your account.',
  },
};
