import cookie from 'js-cookie';

const DO_NOT_SELL_SHARE_KEY = 'tfd_do-not-sell-share';

export function getIsOnDoNotSellShareList(): boolean | undefined {
  const jsonValue = cookie.get(DO_NOT_SELL_SHARE_KEY);
  if (!jsonValue) {
    return undefined;
  }

  let parsedValue: unknown;
  try {
    parsedValue = JSON.parse(jsonValue);
  } catch {
    /* empty */
  }

  if (typeof parsedValue !== 'boolean') {
    throw new Error('Invalid value in doNotSellShare cookie');
  }

  return parsedValue;
}

export function setIsOnDoNotSellShareList(value: boolean): void {
  const jsonString = JSON.stringify(value);
  cookie.set(DO_NOT_SELL_SHARE_KEY, jsonString, { expires: 1 });
}

export function removeIsOnDoNotSellShareList(): void {
  cookie.remove(DO_NOT_SELL_SHARE_KEY);
}
