import { useTosaUserFeature } from '../../../hooks/useTosaUserFeature';
import {
  CvrPriorFoodTypeProgressionTreatments,
  FeatureName,
} from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface usePriorFoodTypeProgressionProps {
  useFeature: UseFeatureHook;
}

export function usePriorFoodTypeProgression({
  useFeature,
}: usePriorFoodTypeProgressionProps) {
  const { treatment, isReady } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_PRIOR_FOOD_TYPE_PROGRESSION,
  });

  const showSingleQuestionProgression =
    treatment === CvrPriorFoodTypeProgressionTreatments.on;

  return {
    isReady,
    showSingleQuestionProgression,
  };
}
