import { useCallback, useState } from 'react';

import {
  AsyncFunction,
  AsyncFunctionOptions,
  UseAsyncFunction,
} from './useAsyncFunction.types';

/**
 * Given an async function will return a state wrapped
 * callback for loading, called, error, and data.
 *
 * @example
 * ```
 * // Traditional await
 * const [call] = useAsyncFunction(someFunction)
 * try {
 *  showSpinner();
 *  const data = await call(param1, param2)
 *  applyData(data)
 * } finally {
 *  hideSpinner();
 * }
 * ```
 * ```
 * // State await
 * const [call, {loading, error, data, called}] = useAsyncFunction(someFunction)
 *
 * useEffect(() => {
 *  if (loading) { showSpinner() }
 *  else { hideSpinner() }
 * }, [loading])
 *
 * useEffect(() => {
 *  if (error) { showErrorMessage(error) }
 * }, [error])
 *
 * useEffect(() => {
 *  if (data) { applyData(data) }
 * }, [data])
 *
 * useEffect(() => {
 *  if (called && error) { retry(param1, param2) }
 * }, [called, error])
 *
 * call(param1, param2)
 * ```
 */
export function useAsyncFunction<
  ReturnType,
  ArgsType extends unknown[],
  ErrorType = Error,
>(
  asyncFunction: AsyncFunction<ReturnType, ArgsType>,
  { onCompleted, onError }: AsyncFunctionOptions<ReturnType, ErrorType> = {}
): UseAsyncFunction<ReturnType, ArgsType, ErrorType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [called, setCalled] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>();
  const [data, setData] = useState<ReturnType | undefined>();
  const [success, setSuccess] = useState(false);

  const call = useCallback(
    async (...params: ArgsType): Promise<ReturnType | undefined> => {
      let response;
      setLoading(true);
      setSuccess(false);
      setCalled(true);
      setError(undefined);

      try {
        response = await asyncFunction(...params);
        setSuccess(true);
        if (onCompleted) {
          onCompleted(response);
        }
      } catch (e) {
        // @ts-expect-error validate error
        setError(e);
        if (onError) {
          // @ts-expect-error validate error
          onError(e);
        }
      } finally {
        setData(response);
        setLoading(false);
      }

      return response;
    },
    [asyncFunction, onCompleted, onError]
  );

  return [call, { loading, called, error, data, success }];
}
