import cookie from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {
  AUTH_CLEAR_USER_TOKEN,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESHING_TOKEN,
  AUTH_REFRESH_TOKEN,
  SIGNUP_REGISTER,
  SIGNUP_REGISTER_EMAIL,
} from 'src/constants/actionTypes';
import { TOKEN_COOKIE } from 'src/constants/cookie';

export const initialState = {
  token: undefined,
  email: undefined,
  refreshing: false,
};

function getTokenExpiration(token) {
  const decoded = jwtDecode(token);
  let expires = 180;
  if (decoded.exp) {
    expires = new Date(decoded.exp * 1000);
  }

  return expires;
}

function getTokenId(token) {
  const decoded = jwtDecode(token);
  return decoded.id;
}

/**
 * Set the token cookie using the expiration date from the token.
 * @param {String} token
 */
function setTokenCookie(token) {
  const expires = getTokenExpiration(token);
  cookie.set(TOKEN_COOKIE, token, { expires });
}
/**
 * Remove the token cookie.
 */
function removeTokenCookie() {
  cookie.remove(TOKEN_COOKIE);
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN: {
      const { token, email: emailFromTokenLogin } = action.payload.data.login;
      const email = emailFromTokenLogin ?? action?.meta?.email;
      setTokenCookie(token);
      return { ...state, token, email };
    }
    case AUTH_REFRESH_TOKEN:
    case SIGNUP_REGISTER:
    case SIGNUP_REGISTER_EMAIL: {
      const user = action.payload.data;
      const { token, email } = user;
      setTokenCookie(token);
      return { ...state, token, email };
    }
    case AUTH_CLEAR_USER_TOKEN:
    case AUTH_LOGOUT: {
      removeTokenCookie();
      return initialState;
    }
    case AUTH_REFRESHING_TOKEN: {
      return { ...state, refreshing: action.payload };
    }
    default:
      return state;
  }
}

export function selectToken(state) {
  return state.auth.token;
}

export function selectTokenExpiration(state) {
  const token = state.auth.token;
  return token ? getTokenExpiration(token) : undefined;
}

export function selectTokenId(state) {
  const token = state.auth.token;
  return token ? getTokenId(token) : undefined;
}

export function selectIsLoggedIn(state) {
  return !!selectToken(state);
}

export function selectEmail(state) {
  return state.auth.email;
}

export function selectRefreshingToken(state) {
  return state.auth.refreshing;
}
