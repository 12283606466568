import { LightboxId } from './lightboxIds';

export interface LightboxAction {
  type: LightboxActionType;
  payload: LightboxActionPayload;
}

export interface LightboxActionPayload {
  id: LightboxId;
  forceOpen?: boolean;
  suppressed?: boolean;
}

export enum LightboxActionType {
  Show = 'SHOW',
  Hide = 'HIDE',
  HideAll = 'HIDE_ALL',
  Suppress = 'SUPPRESS',
}

export interface ShowLightboxByIdArgs {
  /* id of the lightbox to open */
  id: LightboxId;
  /* should this lightbox open and shut others even if other lightboxes are currently open */
  forceOpen?: boolean;
}

/**
 * Show a lightbox by id. By default this action will have no effect if there is
 * another lightbox open. Passing the forceOpen parameter will change this
 * behavior so that the specified lightbox always opens and closes all others.
 *
 */
export function showLightboxById({
  id,
  forceOpen = false,
}: ShowLightboxByIdArgs) {
  return {
    type: LightboxActionType.Show,
    payload: {
      id,
      forceOpen,
    },
  };
}

/**
 * Return a redux action to hide a specific lightbox by id.
 *
 * @param id - The ID of the lightbox to hide.
 */
export function hideLightboxById(id: LightboxId) {
  return {
    type: LightboxActionType.Hide,
    payload: {
      id,
    },
  };
}

/**
 * Return a redux action to hide all lightboxes.
 */
export function hideAllLightboxes() {
  return {
    type: LightboxActionType.HideAll,
  };
}
interface SetSuppressLightboxByIdProps {
  /* the ID of the lightbox to show */
  id: LightboxId;
  /* whether to completely suppress opening of the lightbox */
  suppressed: boolean;
}

/**
 * Set the `suppressed` property of a lightbox by id. If the
 * property is `true` then the lightbox will not show under any circumstance.
 *
 */
export function setSuppressLightboxById({
  id,
  suppressed,
}: SetSuppressLightboxByIdProps) {
  return {
    type: LightboxActionType.Suppress,
    payload: {
      id,
      suppressed,
    },
  };
}
