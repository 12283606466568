import moment from 'moment';
import { WeekdayNumber } from '../constants';
import { getEstUtcOffSet } from './getEstUtcOffSet';

/**
 * Return true if today's (in New York) is in the range between Thursday 10AM
 * and Friday 23:59:59 – between these times we need to show the unavailable
 * date.
 */
export function isTodayBetweenThuFri(): boolean {
  const estUtcOffset = getEstUtcOffSet();

  const thursday = moment()
    .utcOffset(estUtcOffset)
    .day(WeekdayNumber.Thursday)
    .hour(10)
    .startOf('hour');

  const friday = moment()
    .utcOffset(estUtcOffset)
    .day(WeekdayNumber.Friday)
    .hour(23)
    .endOf('hour');

  const isInRange = moment().isBetween(thursday, friday, 'minute', '[]');
  return isInRange;
}
