import type { datadogRum as dataDogRumType } from '@datadog/browser-rum';
import noop from 'lodash/noop';

export type DatadogRum = typeof dataDogRumType;

interface DatadogRUMUser {
  id: number;
}

interface DatadogService {
  identify(user?: DatadogRUMUser): void;
}

/**
 * Wrap datadog client so it is usable in all contexts. This allows the client
 * to be freely used without errors related to undefined `window`
 */

export const datadogRum = new Proxy({} as DatadogRum, {
  get<K extends keyof DatadogRum>(_: unknown, key: K) {
    if (
      typeof window !== 'undefined' &&
      window.DD_RUM &&
      typeof window.DD_RUM[key] === 'function'
    ) {
      return window.DD_RUM[key];
    }

    return noop;
  },
});

export const datadog: DatadogService = {
  /**
   * Identify a user in Datadog RUM traces
   *
   * @param user - The user to identify
   */
  identify(user?: DatadogRUMUser) {
    if (!user) {
      return;
    }

    datadogRum.setUser({
      id: String(user.id),
    });
  },
};
