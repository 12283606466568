import pick from 'lodash/pick';
import { identify } from 'src/analytics';

import {
  AUTH_LOGOUT,
  PET_DELETE,
  SUBSCRIPTION_UPDATE,
  USER_CREATE,
  USER_FETCH,
  USER_FETCH_STATUS,
  USER_REPLACE_PETS,
  USER_UPDATE,
  USER_UPDATE_REFERRER,
} from 'src/constants/actionTypes';

const initialUser = {};

export default function userReducer(user = initialUser, action) {
  switch (action.type) {
    case USER_FETCH: {
      const nextUser = action.payload.data;
      try {
        identify(nextUser);
      } catch {
        // ignore identify errors
      }
      return nextUser;
    }
    case USER_FETCH_STATUS: {
      const { me } = action.payload.data;

      return {
        ...user,
        status: me.status,
        subscription: {
          ...((user && user.subscription) || {}),
          status: me.subscription.status,
        },
      };
    }
    case USER_UPDATE:
    case USER_CREATE: {
      return action.payload.data;
    }
    case USER_UPDATE_REFERRER: {
      return {
        ...user,
        ...pick(action.payload.data, [
          'referrerId',
          'referralCode',
          'referrerType',
          'referrerAssociatedAt',
        ]),
      };
    }
    case AUTH_LOGOUT: {
      return initialUser;
    }
    case PET_DELETE: {
      // this is used also in signup so pets may or may not be on the user object
      if (Array.isArray(user.pets)) {
        const { id } = action.meta;
        return {
          ...user,
          pets: user.pets.filter(pet => pet.id !== id),
        };
      }

      return user;
    }
    case USER_REPLACE_PETS: {
      return {
        ...user,
        pets: action.payload.pets,
      };
    }
    case SUBSCRIPTION_UPDATE: {
      return {
        ...user,
        subscription: {
          ...user.subscription,
          ...action.payload.data,
        },
      };
    }
    default:
      return user;
  }
}

/**
 * Gets the current logged in user
 *
 * @param {Object} state - the redux state
 *
 * @returns {Reducer.User}
 */
export function selectUser(state) {
  return state.customerAccount.user;
}

/**
 * Get the full name of the logged in user
 *
 * @returns {string}
 */
export function selectUserFullName(state) {
  const user = selectUser(state);

  const firstAndLastName =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : undefined;

  return user.fullName || firstAndLastName;
}

export function selectUserId(state) {
  const user = selectUser(state);
  return user.id;
}

/**
 * Select all pets for the user
 *
 * @param {Object} state - The redux state object
 *
 * @returns {Reducer.Pet[]}
 */
export function selectPets(state) {
  return selectUser(state).pets;
}

/**
 * Select a pet by their id
 *
 * @param {Object} state - The redux state object
 * @param {number} id - The pet's id
 *
 * @returns {Reducer.Pet}
 */
export function selectPetById(state, id) {
  return selectPets(state).find(pet => pet.id === id) ?? {};
}

/**
 * Select a user's subscription
 *
 * @param {Object} state - The redux state object
 *
 * @returns {Reducer.Subscription}
 */
export function selectSubscription(state) {
  return selectUser(state).subscription;
}

/**
 * Select the food plans associated with all of the pets from the user object.
 *
 * @param {Object} state the redux state object
 *
 * @return {Reducer.FoodPlan[]} an array of plan objects
 */
export function selectFoodPlans(state) {
  const subscription = selectSubscription(state);

  return (subscription && subscription.foodPlans) || [];
}

/**
 * Select the plan associated with the pet from the user object.
 *
 * @param {Object} state the redux state object
 * @param {Number} id the id of the pet
 *
 * @return {Reducer.FoodPlan | null} the plan object
 */
export function selectFoodPlanByPetId(state, id) {
  return selectFoodPlans(state).find(foodPlan => foodPlan.petId === id) || null;
}

/**
 * Select the food plan by a food plan id.
 *
 * @param {Object} state the redux state object
 * @param {Number} id the id of the pet
 *
 * @return {Object} the plan object
 */
export function selectFoodPlanById(state, id) {
  return selectFoodPlans(state).find(foodPlan => foodPlan.id === id) || null;
}

/**
 * Select the orders in the user's subscription
 *
 * @param {Object} state the redux state object
 *
 * @returns {Reducer.Order[]}
 */
export function selectOrders(state) {
  const user = selectUser(state);
  if (!user.subscription) {
    return [];
  }
  return user.subscription.orders;
}

/**
 * Selects the current order according to its `states` field.
 *
 * @param {Object} state the redux state object
 * @returns {Reducer.Order | undefined}
 */
export function selectCurrentOrder(state) {
  const orders = selectOrders(state);
  if (!orders) {
    return undefined;
  }
  return orders.find(order => order.states.indexOf('current') > -1);
}

/**
 * Selects the next order according to its `states` field.
 *
 * @param {Object} state the redux state object
 * @returns {Reducer.Order | undefined}
 */
export function selectNextOrder(state) {
  const orders = selectOrders(state);
  if (!orders) {
    return undefined;
  }
  return orders.find(order => order.states.indexOf('next') > -1);
}

/**
 * Select all pets that currently have a foodPlan with the boolean field active
 * set to true.
 *
 * @returns {Reducer.Pet[]}
 */
export function selectActivePets(state) {
  return selectPets(state).filter(pet => {
    const foodPlan = selectFoodPlanByPetId(state, pet.id);
    return foodPlan && foodPlan.active;
  });
}

/**
 * Select the index of the first active pet in the pets array
 *
 * @param {Object} state The global redux state.
 *
 * @returns {Number} Index of first active pet in pets array
 */
export function selectIndexOfFirstActivePet(state) {
  const activePets = selectActivePets(state);
  const pets = selectPets(state);

  const firstActivePet = activePets[0];

  return Math.max(
    pets.findIndex(
      pet => (pet && pet.id) === (firstActivePet && firstActivePet.id)
    ),
    0
  );
}

export function selectSubscriptionStatus(state) {
  const subscription = selectSubscription(state);
  return subscription?.status;
}

/**
 * Select a boolean value indicating whether the mixing plan option can be
 * shown. The food plans associated with the pet must meet certain business
 * criteria in order to qualify for a mixing plan.
 *
 * @param {Object} state the redux state object
 *
 * @return {Boolean} the boolean value indicating if mixing plans can be used
 */
export function selectAllowChangeMealSize(state) {
  return selectFoodPlans(state).some(
    foodPlan =>
      foodPlan.active &&
      foodPlan.calories * foodPlan.portion * foodPlan.ratio > 201
  );
}
