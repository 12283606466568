import { GetClinicsQuery } from '../../network/GetClinics.cgs.generated-types';
import { SavedClinicCard } from '../SavedClinicCard/SavedClinicCard';
import styles from './SavedClinics.module.css';
import { useRemoveClinic } from '../../network';
import { CLINIC_REMOVAL_ERROR } from '../../constants';
import { useToast } from '@farmersdog/corgi';
import { ApolloQueryResult } from '@apollo/client';
import { trackClickedRemoveClinic } from '../../analytics';

export type CustomerClinics = GetClinicsQuery['petHealth']['clinics'];
interface SavedClinicsProps {
  clinics: CustomerClinics;
  refetchSavedClinics: () => Promise<ApolloQueryResult<GetClinicsQuery>>;
  recentlySavedClinicId: string | null;
}

export const SavedClinics = ({
  clinics,
  recentlySavedClinicId,
  refetchSavedClinics,
}: SavedClinicsProps) => {
  const [removeClinicMutation, { loading: removeClinicLoading }] =
    useRemoveClinic();
  const dispatchToast = useToast();

  const removeClinic = async (clinicId: string) => {
    try {
      await removeClinicMutation({
        variables: {
          input: {
            clinicId,
          },
        },
      });

      trackClickedRemoveClinic({ clinicId });
      await refetchSavedClinics();
    } catch {
      dispatchToast({
        variant: 'negative',
        children: CLINIC_REMOVAL_ERROR,
      });
    }
  };

  return (
    <section aria-label="SavedClinics">
      <div className={styles.container}>
        {clinics?.map(({ vetName, clinicEmail, clinicPhone, clinic }) => (
          <SavedClinicCard
            key={clinic.name + clinic.streetAddress1}
            clinic={clinic}
            vetName={vetName}
            clinicEmail={clinicEmail}
            clinicPhone={clinicPhone}
            onRemove={() => removeClinic(clinic.id!)}
            isRemoveLoading={removeClinicLoading}
            recentlySaved={clinic.id === recentlySavedClinicId}
          />
        ))}
      </div>
    </section>
  );
};
