import { Grid, Text } from '@farmersdog/corgi-x';
import { Check } from '@farmersdog/corgi-x/icons';

interface SubCopyLineItemProps {
  copy: string;
}

/**
 * Render a line item of sub copy with a check icon.
 */
export function SubCopyLineItem({ copy }: SubCopyLineItemProps) {
  return (
    <Grid flexDirection="row" columnGap="xs">
      <Text as="div" variant="heading-16" color="charcoal-3" key={copy}>
        {copy}
      </Text>
      <div>
        <Check baseColor="Carrot2" height="10px" />
      </div>
    </Grid>
  );
}
