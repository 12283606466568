import {
  mobileLovedByHumans,
  desktopLovedByHumans,
} from '../../../../../AssetPreloader/assets/loved-by-humans';

import {
  mobileLifestyleBowl,
  desktopLifestyleBowl,
} from '../../../../../AssetPreloader/assets/lifestyle-bowl';
import {
  desktopMeatAndVeggies,
  mobileMeatAndVeggies,
} from '../../../../../AssetPreloader/assets/meat-and-veggies';
import { MobileAndDesktopSources } from '../../../../../AssetPreloader/assets/shared';

import styles from './HeroContent.module.css';

interface HeroConfigReturn {
  title: JSX.Element;
  subtitle: JSX.Element;
  assets: MobileAndDesktopSources;
  alt: string;
  lowerBackground: boolean;
  cta: string;
}

const lovedByHumansTitleCtaHeaderEnabled = (
  <>
    Real food.
    <br />
    Made fresh.
    <br />
    Delivered.
  </>
);

const lovedByHumansTitleCtaHeaderDisabled = (
  <>
    Supported by vets.
    <br />
    Loved by dog people.
  </>
);

const lovedByHumansSubtitleCtaHeaderEnabled = (
  <>
    Recommended by vets and formulated
    <br />
    by board-certified nutritionists.
  </>
);

const lovedByHumansSubtitleCtaHeaderDisabled = (
  <>
    Fresh food formulated by
    <br />
    board-certified nutritionists.
  </>
);

const lovedByHumansCopy = (isHomepageCtaHeaderCopyEnabled: boolean) =>
  isHomepageCtaHeaderCopyEnabled
    ? {
        title: lovedByHumansTitleCtaHeaderEnabled,
        subtitle: lovedByHumansSubtitleCtaHeaderEnabled,
      }
    : {
        title: lovedByHumansTitleCtaHeaderDisabled,
        subtitle: lovedByHumansSubtitleCtaHeaderDisabled,
      };

export const LovedByHumansConfig = (
  isHomepageCtaHeaderCopyEnabled: boolean
): HeroConfigReturn => {
  const copy = lovedByHumansCopy(isHomepageCtaHeaderCopyEnabled);

  return {
    title: copy.title,
    subtitle: copy.subtitle,
    assets: {
      mobileSources: mobileLovedByHumans,
      desktopSources: desktopLovedByHumans,
    },
    alt: 'Dog looking at a bowl of food',
    lowerBackground: true,
    cta: 'Shop Fresh',
  };
};

// ########################################################

const lifestyleBowlTitle = (
  <>
    Real food.
    <br />
    Made fresh.
    <br />
    Delivered.
  </>
);

const lifestyleBowlSubtitle = (
  <>
    Vet-approved fresh food made
    <br />
    with real meat and veggies.
  </>
);

export const LifestyleBowlConfig = (): HeroConfigReturn => ({
  title: lifestyleBowlTitle,
  subtitle: lifestyleBowlSubtitle,
  assets: {
    mobileSources: mobileLifestyleBowl,
    desktopSources: desktopLifestyleBowl,
  },
  alt: 'Bowl of food',
  lowerBackground: false,
  cta: 'Shop Fresh',
});

const meatAndVeggiesTitle = (
  <>
    Meat and
    <br className={styles.mobileBreakline} /> veggies
    <br className={styles.desktopBreakline} /> you
    <br className={styles.mobileBreakline} /> can see
  </>
);

const meatAndVeggiesSubtitle = (
  <>
    Dog food should be food.
    <br />
    Not burnt brown balls.
  </>
);

export const MeatAndVeggiesConfig = (): HeroConfigReturn => ({
  title: meatAndVeggiesTitle,
  subtitle: meatAndVeggiesSubtitle,
  assets: {
    mobileSources: mobileMeatAndVeggies,
    desktopSources: desktopMeatAndVeggies,
  },
  alt: 'Dogs snout close to a bowl of food',
  lowerBackground: false,
  cta: 'Build your plan',
});
