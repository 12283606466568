import { useRef, useEffect } from 'react';

/**
 * Hook to save a reference to a previous value and compare it to the next
 * value. This is useful for running effects on simple value comparisons that
 * are in deeply nested objects. Since the nested object changes frequently it
 * can be difficult to use referential equality to run the effect at the correct
 * time.
 *
 * @example
 * ```
 * usePreviousEffect((lastComplexObject, nextComplexObject) => {
 *   if (lastComplexObject.nested.value !== nextComplexObject.nested.value) {
 *     performSomeSideEffects();
 *   }
 * }, complexObject)
 * ```
 */
function usePreviousEffect<T>(
  /** A function of the previous value and the next value. */
  effect: (lastValue: T, nextValue: T) => void,
  /** The value that you would like to use for comparison */
  nextValue: T
) {
  const lastValue = useRef(nextValue);

  // perform effect with access to last value
  useEffect(() => {
    effect(lastValue.current, nextValue);
    lastValue.current = nextValue;
  }, [lastValue, effect, nextValue]);
}

export default usePreviousEffect;
