import { useMemo } from 'react';
import { selectEatingStyles } from './selectors';
import { usePetConfiguration } from './usePetConfiguration';

/**
 * Fetch the eating style data that is required for modifying details about a
 * pet.
 */
export function useEatingStyles() {
  const query = usePetConfiguration();
  const eatingStyles = useMemo(
    () => selectEatingStyles(query.data),
    [query.data]
  );

  return {
    ...query,
    eatingStyles,
  };
}
