import { BranchNode } from '../../../../blueprint/types';
import { LeadView } from '../../../../exports';
import { useLeadState } from '../../../../hooks/useLeadState';
import { useThrowToErrorBoundary } from '../../../../hooks/useThrowToErrorBoundary';
import {
  Experiments,
  FormFieldsType,
  FormValidationSchema,
  UseForm,
  UseSchemaReturn,
} from '../../../../types';
import { restoreSignupFlow } from '../../../../utils';
import { reconcileCouponCookieAndLeadDiscount } from '../../../../utils/reconcileCouponCookieAndLeadDiscount';

export interface UseRecoverLeadProgressProps {
  reset: UseForm['reset'];
  setFormSteps: UseSchemaReturn['setFormSteps'];
  formValidationSchema: FormValidationSchema;
  blueprint: UseSchemaReturn['schema'];
  experiments: Experiments;
}

export interface RecoverLeadProgressState {
  loading: boolean;
}
export type RecoverLeadProgressHandler = (
  data: FormFieldsType
) => Promise<BranchNode[] | void>;

export type UseRecoverLeadProgressReturn = [
  handler: RecoverLeadProgressHandler,
  state: RecoverLeadProgressState,
];

export function useRecoverLeadProgress({
  reset,
  setFormSteps,
  formValidationSchema,
  blueprint,
  experiments,
}: UseRecoverLeadProgressProps): UseRecoverLeadProgressReturn {
  const {
    convertFormStateToLeadInput,
    convertLeadToFormState,
    recoverLead,
    recoverLeadState,
  } = useLeadState();
  const throwToErrorBoundary = useThrowToErrorBoundary();

  async function recoverLeadProgress(data: FormFieldsType) {
    try {
      const leadInput = convertFormStateToLeadInput({
        formValidationSchema,
        formData: data,
      });

      const recoveredLead = await recoverLead({
        lead: leadInput,
      });

      if (!recoveredLead) {
        return throwToErrorBoundary(
          new Error('Unexpected error in TOSA recovery response')
        );
      }

      reconcileCouponCookieAndLeadDiscount({
        leadDiscount: recoveredLead.discount,
        temporaryGrainsDiscount: recoveredLead?.temporaryGrainsDiscount,
      });

      return restoreSignupFlow({
        data: convertLeadToFormState(recoveredLead as LeadView),
        blueprint,
        reset,
        resetOptions: {
          keepDefaultValues: true,
          keepDirty: true,
        },
        setFormSteps,
        experiments,
      });
    } catch (error) {
      return throwToErrorBoundary(error);
    }
  }

  return [recoverLeadProgress, { loading: recoverLeadState.loading }];
}
