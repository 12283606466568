import { events, track } from 'src/analytics';

interface trackTreatsSkipFromModalProps {
  path: string;
}

export function trackTreatsSkipFromModal({
  path,
}: trackTreatsSkipFromModalProps) {
  return track(events.treats_skip_from_modal, {
    path,
  });
}
