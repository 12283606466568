import { SIGNUP_SET_CHECK_EXISTING } from 'src/constants/actionTypes';

const initialState = {
  // Used to save the settings when user is registering with an existing
  // account
  checkExisting: true,
};

export default function signupMeReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_SET_CHECK_EXISTING:
      return { ...state, checkExisting: action.payload };
    default:
      return state;
  }
}

export function selectCheckExisting(state) {
  return state.signup.me.checkExisting;
}
