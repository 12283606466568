export enum ValidUtmParam {
  Source = 'utm_source',
  Medium = 'utm_medium',
  Campaign = 'utm_campaign',
  Term = 'utm_term',
  Content = 'utm_content',
}

type UtmRequestAttributes = Record<ValidUtmParam, string>;

export type RequestAttributes = Partial<UtmRequestAttributes> & {
  referrer_url?: string;
};
