import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';
import * as Path from '@farmersdog/constants/paths';

import { trackAmazonTagPageView } from './trackAmazonTagPageView';

import { amazonTagPageViewRoutes } from './constants';

import { shouldRunPageViewFromWebsite } from '../utils';

interface UseTrackAmazonTagPageViewArgs {
  isRecipesPage: boolean;
  showTosaRecipesPage: boolean;
}
/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for an amazon tag page view event.
 */
export function useTrackAmazonTagPageView({
  isRecipesPage,
  showTosaRecipesPage,
}: UseTrackAmazonTagPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  const { previous } = useSignupRouter();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isFirstPetsPage = pathname === Path.PATH_SIGNUP_PETS && previous?.me;

  const shouldRunFromWebsite = shouldRunPageViewFromWebsite({
    pathname,
    isRecipesPage,
    showTosaRecipesPage,
  });

  useEffect(() => {
    if (shouldRunFromWebsite) {
      if (amazonTagPageViewRoutes.includes(pathname) || isFirstPetsPage) {
        trackAmazonTagPageView();
      }
    }
  }, [pathname, isFirstPetsPage, shouldRunFromWebsite]);
}
