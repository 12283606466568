import PropTypes from 'prop-types';
import { Text } from '@farmersdog/corgi-x';
import { CHARCOAL_3 } from '@farmersdog/corgi/constants';

import Flex from 'src/components/_depreciated/Flex';

import Picture from 'src/components/Picture';

import InstagramIcon from 'src/assets/icons/instagram.svg';
import HeartIcon from 'src/assets/icons/heart.svg';
import PaperPlaneIcon from 'src/assets/icons/paper-plane.svg';
import ChatBubbleIcon from 'src/assets/icons/chat-bubble.svg';

import './Instagram.scss';

const iconStyle = {
  width: 24,
  height: 24,
  margin: '0 0.5rem',
};

function Instagram({ screenName, quote, image }) {
  return (
    <div className="Instagram">
      <div className="Instagram-ratio">
        <Picture alt={screenName} loading="lazy" sources={image.sources} />
        <InstagramIcon
          style={{
            width: 32,
            height: 32,
            top: 12,
            right: 12,
            position: 'absolute',
            fill: CHARCOAL_3,
          }}
          aria-label="Instagram Icon"
        />
      </div>
      <div className="Instagram-content">
        <Flex style={{ margin: '1rem 0' }}>
          <HeartIcon
            style={{ ...iconStyle, marginLeft: 0, fill: '#ed4956' }}
            aria-label="Instagram favorite"
          />
          <ChatBubbleIcon style={iconStyle} aria-label="Comments" />
          <PaperPlaneIcon style={iconStyle} aria-label="Share post" />
        </Flex>
        <Text variant="heading-16" as="article" color="kale-3">
          <Text
            variant="heading-16"
            as="h3"
            bold
            color="kale-3"
            className="Instagram-userName"
          >
            {screenName}
          </Text>
          {' ' + quote}
        </Text>
      </div>
    </div>
  );
}

Instagram.propTypes = {
  screenName: PropTypes.string,
  quote: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Instagram;
