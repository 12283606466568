import { Text } from '@farmersdog/corgi-x';

import { TestimonialImage } from './TestimonialImage';
import { weightLossTestimonials } from './testimonials';
import styles from './WeightLossStories.module.css';

export function WeightLossStories() {
  return (
    <div className={styles.container}>
      {weightLossTestimonials.map(({ petName, before, after, description }) => (
        <div key={petName} className={styles.caseContainer}>
          <div className={styles.imagesContainer}>
            <TestimonialImage
              className={styles.firstImage}
              description="Before"
              sources={before.source}
              alt={before.alt}
            />
            <TestimonialImage
              description="After"
              sources={after.source}
              alt={after.alt}
            />
          </div>
          <Text variant="heading-16" color="kale-3">
            <b>{petName}:</b> {description}
          </Text>
        </div>
      ))}
    </div>
  );
}
