import { Grid, GridItem, Nowrap, Text } from '@farmersdog/corgi-x';

import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import {
  SkipGetStartedActiveTreatment,
  determineSkipGetStarted,
  SkipGetStartedTreatments,
} from '../../../utils';

import styles from './GetStartedCopyExperiment.module.css';

type TreatmentToElement = {
  [key in SkipGetStartedActiveTreatment]: {
    title: JSX.Element | undefined;
    subtitle: JSX.Element | undefined;
  };
};

const treatmentToElement: TreatmentToElement = {
  [SkipGetStartedTreatments.no_copy]: {
    title: undefined,
    subtitle: undefined,
  },
  [SkipGetStartedTreatments.same_copy]: {
    title: (
      <>
        Better food <Nowrap>starts here.</Nowrap>
      </>
    ),
    subtitle: (
      <>
        Let’s determine your meal plan, recommended recipes, and daily price!
        <br />
        This will only take ~3 minutes per dog.
      </>
    ),
  },
  [SkipGetStartedTreatments.new_copy]: {
    title: (
      <>
        Better care <Nowrap>starts here.</Nowrap>
      </>
    ),
    subtitle: (
      <>
        Our personalized fresh food plans are the easiest path to peace of mind
        about your dog’s health. Let’s get started!
      </>
    ),
  },
};

export function GetStartedCopyExperiment({
  experiments,
}: TOSAComponentInput<TOSALeafNode>) {
  const { skipGetStartedTreatment, shouldSkipGetStarted } =
    determineSkipGetStarted({
      experiments,
    });

  if (
    !shouldSkipGetStarted ||
    skipGetStartedTreatment === SkipGetStartedTreatments.no_copy
  ) {
    return null;
  }

  const { title, subtitle } = treatmentToElement[skipGetStartedTreatment];

  return (
    <Grid
      className={styles.container}
      rowGap={{ xs: 'sm', lg: 'md' }}
      topSpacing={{ xs: 'sm', lg: 'xxl' }}
      bottomSpacing={{ xs: 'none', lg: 'xl' }}
      justifyContent="center"
    >
      <GridItem>
        <Text
          className={styles.title}
          variant="heading-28"
          color="kale-3"
          bold
          as="h1"
          vSpacing="none"
        >
          {title}
        </Text>
      </GridItem>
      <GridItem>
        <Text
          variant="article-16"
          color="charcoal-3"
          fontStyle="italic"
          as="h2"
          vSpacing="none"
        >
          {subtitle}
        </Text>
      </GridItem>
    </Grid>
  );
}
