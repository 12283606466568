export const ACCOUNT_PASSWORD_RESET = 'ACCOUNT_PASSWORD_RESET';
export const ADDRESS_LOOKUP = 'ADDRESS_LOOKUP';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEAR_USER_TOKEN = 'AUTH_CLEAR_USER_TOKEN';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_REFRESHING_TOKEN = 'AUTH_REFRESHING_TOKEN';
export const CARDS_FETCH = 'CARDS_FETCH';
export const CARD_CREATE = 'CARD_CREATE';
export const CARD_UPDATE_DETAILS = 'CARD_UPDATE_DETAILS';
export const STATIC_PAGE_FETCH = 'STATIC_PAGE_FETCH';
export const HELP_CREATE_TICKET = 'HELP_CREATE_TICKET';
export const INSTAGRAM_FETCH = 'INSTAGRAM_FETCH';
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS';
export const REFERRER_FETCH = 'REFERRER_FETCH';
export const REFERRER_IS_FETCHING = 'REFERRER_IS_FETCHING';
export const REFERRER_HAS_CALLED_FETCH = 'REFERRER_HAS_CALLED_FETCH';
export const PET_CONFIGURATION_FETCH = 'PET_CONFIGURATION_FETCH';
export const PET_DELETE = 'PET_DELETE';
export const PET_OPTIONS = 'PET_OPTIONS';
export const PLANS_RECOMMEND = 'PLANS_RECOMMEND';
export const PRESCRIPTION_DIETS_FETCH = 'PRESCRIPTION_DIETS_FETCH';
export const PRODUCT_COMBINATIONS_FETCH = 'PRODUCT_COMBINATIONS_FETCH';
export const REFERRAL_STATS_FETCH = 'REFERRAL_STATS_FETCH';
export const SIGNUP_REGISTER = 'SIGNUP_REGISTER';
export const SIGNUP_REGISTER_EMAIL = 'SIGNUP_REGISTER_EMAIL';
export const SIGNUP_REPLACE_USER = 'SIGNUP_REPLACE_USER';
export const SIGNUP_UPDATE_FOOD_PLAN = 'SIGNUP_UPDATE_FOOD_PLAN';
export const SIGNUP_USER_FIELD_UPDATE = 'SIGNUP_USER_FIELD_UPDATE';
export const SIGNUP_PET_UPDATE = 'SIGNUP_PET_UPDATE';
export const SIGNUP_FETCH_QUOTE = 'SIGNUP_FETCH_QUOTE';
export const SIGNUP_FETCH_DIY_QUOTE = 'SIGNUP_FETCH_DIY_QUOTE';
export const SIGNUP_MIXING_PLAN_FETCH_FULL_PLAN_QUOTE =
  'SIGNUP_MIXING_PLAN_FETCH_FULL_PLAN_QUOTE';
export const SIGNUP_MIXING_PLAN_FETCH_MIXING_PLAN_QUOTE =
  'SIGNUP_MIXING_PLAN_FETCH_MIXING_PLAN_QUOTE';
export const SIGNUP_MIXING_PLAN_FETCH_BOOSTER_PLAN_QUOTE =
  'SIGNUP_MIXING_PLAN_FETCH_BOOSTER_PLAN_QUOTE';
export const SIGNUP_MIXING_PLAN_SET_HAS_SEEN_ERROR =
  'SIGNUP_MIXING_PLAN_SET_HAS_SEEN_ERROR';
export const SIGNUP_MIXING_PLAN_SET_MIXING_PLAN_OPTIONS_ARE_LOADING =
  'SIGNUP_MIXING_PLAN_SET_MIXING_PLAN_OPTIONS_ARE_LOADING';
export const SIGNUP_FETCH_PLANS = 'SIGNUP_FETCH_PLANS';
export const SIGNUP_FETCH_PREVIOUS_USER = 'SIGNUP_FETCH_PREVIOUS_USER';
export const SIGNUP_PROGRESS = 'SIGNUP_PROGRESS';
export const SIGNUP_PROGRESS_RESET = 'SIGNUP_PROGRESS_RESET';
export const SIGNUP_SET_USER_REFERRER = 'SIGNUP_SET_USER_REFERRER';
export const SIGNUP_SET_CHECK_EXISTING = 'SIGNUP_SET_CHECK_EXISTING';
export const SIGNUP_SET_CARD_HOLDER = 'SIGNUP_SET_CARD_HOLDER';
export const SIGNUP_SET_BILLING_AS_SHIPPING = 'SIGNUP_SET_BILLING_AS_SHIPPING';
export const SIGNUP_SET_VALIDATION_ERROR = 'SIGNUP_SET_VALIDATION_ERROR';
export const SIGNUP_SET_RECIPES_SUBMITTING = 'SIGNUP_SET_RECIPES_SUBMITTING';
export const SIGNUP_SET_CHECKOUT_SUBMITTING = 'SIGNUP_SET_CHECKOUT_SUBMITTING';
export const SIGNUP_SET_CHANGED_START_DATE = 'SIGNUP_SET_CHANGED_START_DATE';
export const SIGNUP_SET_DIY_SUBSCRIPTION = 'SIGNUP_SET_DIY_SUBSCRIPTION';
export const SIGNUP_RESET = 'SIGNUP_RESET';
export const SIGNUP_SET_RECOMMENDED_FOOD_PLAN =
  'SIGNUP_SET_RECOMMENDED_FOOD_PLAN';

export const STOCK_PREDICTED_DATES_FETCH = 'STOCK_PREDICTED_DATES_FETCH';
export const SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES =
  'SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES';
export const SUBSCRIPTION_FETCH_START_DATES = 'SUBSCRIPTION_FETCH_START_DATES';
export const SUBSCRIPTION_FETCH_FREQUENCIES = 'SUBSCRIPTION_FETCH_FREQUENCIES';
export const SUBSCRIPTION_FETCH_QUOTE = 'SUBSCRIPTION_FETCH_QUOTE';
export const SUBSCRIPTION_FETCH_DIY_QUOTE = 'SUBSCRIPTION_FETCH_DIY_QUOTE';
export const SUBSCRIPTION_FETCH_USER_QUOTE = 'SUBSCRIPTION_FETCH_USER_QUOTE';
export const SUBSCRIPTION_RUSH_DELAY = 'SUBSCRIPTION_RUSH_DELAY';
export const SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE';
export const SUBSCRIPTION_SET_CURRENT_NEXT_DATE =
  'SUBSCRIPTION_SET_CURRENT_NEXT_DATE';

export const UI_ERROR_MODAL_HIDE = 'UI_ERROR_MODAL_HIDE';
export const UI_ERROR_MODAL_SHOW = 'UI_ERROR_MODAL_SHOW';
export const UI_LIGHTBOX_SET = 'UI_LIGHTBOX_SET';
export const UI_LIGHTBOX_SHOW = 'UI_LIGHTBOX_SHOW';
export const UI_LIGHTBOX_HIDE = 'UI_LIGHTBOX_HIDE';
export const UI_LIGHTBOX_HIDE_ALL = 'UI_LIGHTBOX_HIDE_ALL';

export const USER_CREATE = 'USER_CREATE';
export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_STATUS = 'USER_FETCH_STATUS';
export const USER_PASSWORD_CHANGE = 'USER_PASSWORD_CHANGE';
export const USER_REPLACE_PETS = 'USER_REPLACE_PETS';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_REFERRER = 'USER_UPDATE_REFERRER';
