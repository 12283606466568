import type { ReactNode } from 'react';
import { Button, Link } from '@farmersdog/corgi-x';

const DEFAULT_EMAIL = 'help@thefarmersdog.com';

interface EmailSupportButtonProps {
  asLink?: boolean;
  subject: string;
  email?: string;
  className?: string;
  children: ReactNode;
}

export function EmailSupportButton({
  asLink,
  subject,
  children,
  email,
  className,
}: EmailSupportButtonProps) {
  const _email = email ?? DEFAULT_EMAIL;
  const href = `mailto:${_email}?subject=${subject}`;
  const Component = asLink ? Link : Button;
  return (
    <Component
      type="anchor"
      target="_blank"
      href={href}
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </Component>
  );
}
