import { KeyIngredients } from './KeyIngredients';
import RecipeBowlPicture from './RecipeBowlPicture';
import styles from './RecipeDiagram.module.css';

interface RecipeDiagram {
  recipeName: string;
  ingredients: string[];
}

export function RecipeDiagram({ recipeName, ingredients }: RecipeDiagram) {
  return (
    <div className={styles.diagramContainer}>
      <div className={styles.diagramBackground}>
        <RecipeBowlPicture
          recipeName={recipeName}
          className={styles.diagramImage}
        />
        <KeyIngredients ingredients={ingredients} />
      </div>
    </div>
  );
}
