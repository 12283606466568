import { track } from '@farmersdog/analytics';

export const SHOW_ERROR_MESSAGE = 'Show error message';

/**
 * Track that an error was displayed to the customer.
 *
 * @example
 * ```tsx
 *  export function ErrorPage({ error }) {
 *    useEffect(() => {
 *      trackShowErrorMessage(error);
 *    }, [error])
 *
 *    return (
 *      ...
 *  }
 * ```
 *
 * @param error - The error to track. The track call captures the `name` and `message`.
 */
export function trackShowErrorMessage(error: Error) {
  track(SHOW_ERROR_MESSAGE, {
    name: error.name,
    message: error.message,
  });
}
