import { CgsActivityLevel } from '../../graphql/types.cgs';

interface ActivityLevel {
  name: string;
  description: string;
}

const activityLevelMap: { [k in CgsActivityLevel]: ActivityLevel } = {
  [CgsActivityLevel.NotVeryActive]: {
    name: 'Not Very Active',
    description: 'Only moves when I come home.',
  },
  [CgsActivityLevel.Active]: {
    name: 'Active',
    description: 'Likes to play.',
  },
  [CgsActivityLevel.VeryActive]: {
    name: 'Very Active',
    description: 'Daily dog park trips and long walks.',
  },
  [CgsActivityLevel.ProAthlete]: {
    name: 'Pro Athlete',
    description: 'Daily jogs or 2 hour walks.',
  },
};

export const getActivityLevel = (
  activityLevel: CgsActivityLevel | null
): ActivityLevel | undefined => {
  return activityLevel ? activityLevelMap[activityLevel] : undefined;
};
