import classNames from 'classnames';
import { useCallback } from 'react';

import { Link } from '@farmersdog/corgi';
import { useViewport, Text } from '@farmersdog/corgi-x';

import { TOSAComponentInput, TOSALeafNode } from '../../../types';
import { getSignupRoute } from '../../../utils/getSignupRoute';
import { Picture } from '../../shared';

import { mixedBowl } from './assets/mixedBowl/mixedBowl';
import { Benefits } from './Benefits';
import { Footer } from './Footer';
import styles from './GetStarted.module.css';

export function GetStarted(props: TOSAComponentInput<TOSALeafNode>) {
  const { registry, formNavigation, formMethods, formSteps } = props;
  const { setNext, findNext } = formNavigation;
  const { getValues } = formMethods;

  const handleNavigateNext = useCallback(() => {
    setNext({ formSteps });
  }, [formSteps, setNext]);

  const nextSignupRoute = getSignupRoute({
    registry,
    current: findNext({ formSteps }),
    getValues,
  });

  const { md, xs } = useViewport();
  const isMobile = xs && !md;

  const subtitleClassnames = classNames(styles.subtitle, {
    [styles.subtitleMobile]: isMobile,
  });

  return (
    <>
      {!isMobile && (
        <Picture
          sources={mixedBowl}
          alt="Bowl of The Farmer’s Dog Dog Food"
          className={styles.bowl}
        />
      )}
      <div className={styles.textContainer}>
        <Text
          as="h1"
          variant="heading-40"
          color="kale-3"
          bold
          className={styles.title}
          bottomSpacing={isMobile ? 'md' : 'none'}
          topSpacing={{
            xs: 'sm',
            md: 'xxl',
          }}
        >
          Better food starts here.
        </Text>
        <Text
          as="h2"
          variant={isMobile ? 'article-16' : 'article-20'}
          color="charcoal-3"
          topSpacing={{
            xs: isMobile ? 'none' : 'sm',
            md: 'sm',
          }}
          bottomSpacing={isMobile ? 'xs' : 'xl'}
          className={subtitleClassnames}
        >
          Let’s determine your meal plan, recommended recipes, and daily price!
        </Text>
        <Text
          variant={isMobile ? 'article-12' : 'article-16'}
          fontStyle="italic"
          color={isMobile ? 'charcoal-2' : 'charcoal-3'}
          as="p"
          className={styles.time}
        >
          This will only take ~3 minutes per dog.
        </Text>
      </div>
      {/*
        NOTE: normally we place the submit/continue button on the form component, but because of the unique
        placement on this card and the fact that no data is submitted, we have it here instead.
      */}
      <div className={styles.buttonContainer}>
        <div className={styles.buttonBackground}>
          <Link
            asButton
            asPill
            to={nextSignupRoute}
            onClick={handleNavigateNext}
            aria-label="Continue to signup"
          >
            Continue
          </Link>
        </div>
      </div>
      <Benefits isMobileGetStartedCopyOn={isMobile} />
      <Footer />
    </>
  );
}
