import * as Apollo from '@apollo/client';

import { useMutation } from '../../services/apollo';
import {
  UpdateUserProductEligibilityMutation,
  UpdateUserProductEligibilityMutationVariables,
} from '../types';

import updateUserProductEligibility from './updateUserProductEligibility.graphql';

type Options = Apollo.MutationHookOptions<
  UpdateUserProductEligibilityMutation,
  UpdateUserProductEligibilityMutationVariables
>;

export function useUpdateUserProductEligibility(options?: Options) {
  return useMutation<
    UpdateUserProductEligibilityMutation,
    UpdateUserProductEligibilityMutationVariables
  >(updateUserProductEligibility, options);
}
