import { useCorgiViewport } from 'src/screen';
import Picture, { LoadingOption } from 'src/components/Picture';

import styles from './GallerySlide.module.scss';
import { GallerySectionSlide } from './slides';

export type GallerySlideProps = GallerySectionSlide;

/**
 * Render a gallery slide containing image content
 */
export function GallerySlide({ responsiveSources, alt }: GallerySlideProps) {
  const sources = useCorgiViewport(responsiveSources);

  return (
    <Picture
      sources={sources}
      alt={alt}
      loading={LoadingOption.lazy}
      rootMargin="50%"
      className={styles.image}
    />
  );
}
