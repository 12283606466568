import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSafeOptions } from '@farmersdog/corgi';

import { selectRequestAttributes } from 'src/reducers/referrer';

interface UseAttributes {
  /** Merged object of both request attributes and provided feature attributes */
  attributes: SplitIO.Attributes;
  /** True if all feature attributes have a value */
  attributesReady: boolean;
}

/**
 * Merges custom feature attributes with required request attributes.  Will
 * validate if the feature attributes are ready.
 *
 * @param featureAttributes - Custom attributes to send to split
 */
export const useAttributes = <AttributesType extends SplitIO.Attributes>(
  featureAttributes?: AttributesType
): UseAttributes => {
  const requestAttributes = useSelector(selectRequestAttributes);
  const safeFeatureAttributes = useSafeOptions(featureAttributes);

  return useMemo(() => {
    const attributesReady = safeFeatureAttributes
      ? Object.values(safeFeatureAttributes).every(value => value !== undefined)
      : true;

    return {
      attributesReady,
      attributes: {
        ...requestAttributes,
        ...safeFeatureAttributes,
        current_date: Date.now(),
      },
    };
  }, [safeFeatureAttributes, requestAttributes]);
};
