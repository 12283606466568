import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Input, Link } from '@farmersdog/corgi';
import { Button, Text } from '@farmersdog/corgi-x';

import { PATH_LOGIN } from '@farmersdog/constants/paths';
import SuccessCheckmark from 'src/components/SuccessCheckmark';
import Slider from 'src/components/Slider';
import Form from 'src/components/Form';
import parseQueryString from 'src/utils/parseQueryString';
import { showError } from 'src/actions/ui';
import {
  FinishCheckoutModal,
  FINISH_CHECKOUT_MODAL_ID,
} from 'src/components/FinishCheckoutModal';
import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import { useRequestPasswordReset } from 'src/graphql/mutations/useRequestPasswordReset';

import AuthPage, { USER_NOT_FOUND_ERROR } from 'src/components/AuthPage';
import { getDeprecatedErrors } from 'src/graphql/utils/getDeprecatedErrors';
import { ValidationError } from 'src/errors';

import { BACK_TO_LOGIN_BUTTON_COPY, SUCCESS_TEXT } from './ResetPassword.copy';

import styles from './ResetPassword.module.scss';
import { QueryParameter } from '@farmersdog/constants';

function ResetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState(
    parseQueryString(location.search, 'email') || ''
  );
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const emailField = useRef();

  const { open: openFinishCheckoutModal } = useGlobalLightbox({
    id: FINISH_CHECKOUT_MODAL_ID,
  });

  const handleError = err => {
    const validationErrors = getDeprecatedErrors(err.networkError);

    if (!validationErrors) {
      dispatch(showError(err));
    }

    const message = validationErrors[0]?.message;
    const params = validationErrors[0]?.params;
    setError(new ValidationError(message, params));

    if (message === USER_NOT_FOUND_ERROR) {
      openFinishCheckoutModal();
      return;
    }

    if (params === 'email') {
      emailField.current.focus();
    } else {
      dispatch(showError(err));
    }
  };

  const [callResetPassword, { loading }] = useRequestPasswordReset({
    onError: err => handleError(err),
    onCompleted: () => setSuccess(true),
  });

  const handleSubmit = () => {
    callResetPassword({ email });
  };

  useEffect(() => {
    emailField.current.focus();
  }, []);

  const backToLoginLink = success
    ? `${PATH_LOGIN}?${QueryParameter.Email}=${encodeURIComponent(email)}`
    : PATH_LOGIN;

  const validationError = error?.validation ?? {};

  return (
    <AuthPage
      title={success ? 'Recovery link sent' : 'Set new password'}
      header="Set new password"
      subHeader="Trouble logging in? Enter your email address to set a fresh password."
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Slider className={styles.slider} currentSlide={success ? 1 : 0}>
        <Slider.Slide>
          <Form
            aria-label="Reset Password"
            className={styles.form}
            onSubmit={handleSubmit}
            enableSubmit={!loading}
          >
            <Input
              ref={emailField}
              data-name="email"
              name="Email"
              label="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={e => {
                setError();
                setEmail(e.target.value);
              }}
              aria-label="Email Address"
            />
            {validationError.email && (
              <Text as="div" className={styles.error} variant="heading-16">
                {validationError.email}
              </Text>
            )}
            <Button
              className={styles.submitButton}
              type="submit"
              loading={loading}
              disabled={!email}
            >
              Submit
            </Button>
          </Form>
        </Slider.Slide>
        <Slider.Slide>
          {success && (
            <div className={styles.success}>
              <SuccessCheckmark />
              <Text as="p" variant="heading-16" color="kale-3">
                {SUCCESS_TEXT}
              </Text>
              <Text
                as="p"
                variant="heading-16"
                color="kale-3"
                className={styles.lastLine}
              >
                Check {email} for the link to change the password.
              </Text>
            </div>
          )}
        </Slider.Slide>
      </Slider>
      <Link className={styles.loginLink} to={backToLoginLink}>
        <Text as="span" variant="heading-16">
          {BACK_TO_LOGIN_BUTTON_COPY}
        </Text>
      </Link>
      <FinishCheckoutModal email={email} />
    </AuthPage>
  );
}

export default ResetPassword;
