import omit from 'lodash/omit';

import { PetNodes } from '@farmersdog/constants';

import { Position } from '../blueprint/types';
import { getNodeNameAndPosition } from '../blueprint/utils';
import { FormFieldsType } from '../types';

import { makeIndexedName } from './makeIndexedName';

interface RemovePetByPositionArgs {
  position: Position;
  formValues: FormFieldsType;
}

interface ShiftPetPositionsArgs {
  removedPosition: Position;
  formValues: FormFieldsType;
}

type RemovePetByPositionReturn = [
  formValues: FormFieldsType,
  fieldsToRemove: (keyof FormFieldsType)[],
];

function shiftPetPositions({
  removedPosition,
  formValues,
}: ShiftPetPositionsArgs): FormFieldsType {
  return Object.fromEntries(
    Object.entries(formValues).map(([currentName, currentValue]) => {
      const { name, position } = getNodeNameAndPosition(currentName);
      if (position && position > removedPosition) {
        return [makeIndexedName(name, Number(position) - 1), currentValue];
      }

      return [currentName, currentValue];
    })
  );
}

export function removePetByPosition({
  position,
  formValues,
}: RemovePetByPositionArgs): RemovePetByPositionReturn {
  const fieldsToRemove = Object.values(PetNodes)
    .map(nodeName => makeIndexedName(nodeName, position))
    .filter(fieldName =>
      Object.prototype.hasOwnProperty.call(formValues, fieldName)
    );

  const formValuesWithRemovedPet = omit(formValues, fieldsToRemove);

  const nextFormValues = shiftPetPositions({
    formValues: formValuesWithRemovedPet,
    removedPosition: position,
  });

  if (
    fieldsToRemove.length &&
    nextFormValues.numPets !== undefined &&
    Number.isFinite(nextFormValues.numPets)
  ) {
    nextFormValues.numPets--;
  }

  return [nextFormValues, fieldsToRemove];
}
