import { DiyRecipe, PrimaryProtein } from '../types';
import chickenAndRiceWebp from '../assets/chicken-and-rice.webp';

export const chickenAndRice: DiyRecipe = {
  name: 'Chicken and Rice',
  primaryProtein: PrimaryProtein.Chicken,
  ingredients: [
    {
      name: '93% lean ground chicken',
      quantity: 1361,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 325,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'pumpkin puree',
      quantity: 270,
      unit: 'g',
      alternativeQuantity: 1,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 270,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 9, unit: 'cup' },
        { name: 'frozen', quantity: 1.75, unit: 'cup' },
      ],
    },
    {
      name: 'sunflower oil',
      quantity: 23,
      unit: 'g',
      alternativeQuantity: 5,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'salmon oil',
      quantity: 7,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientSummary:
    'Ground Chicken, White Rice, Pumpkin Puree, Spinach, Sunflower Oil, DIY Nutrient Mix, Salmon Oil',
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  id: 'chicken-and-rice',
  yieldWeight: 5,
  cookTime: '40 mins',
  calories: 2430,
  popularity: 2,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: chickenAndRiceWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 3',
      description:
        'Add the ground chicken to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 4',
      description:
        'Add the pumpkin puree, cooked rice, and spinach. Cook until the spinach has wilted.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the salmon oil and stir to evenly distribute. If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  videoId: '1c6j1p8suy',
};
