import { Checkbox } from '@farmersdog/corgi';

import { TOSAComponentInput, TOSALeafNode } from '../../../types';

import styles from './CheckboxInput.module.css';

export const CheckboxInput = (props: TOSAComponentInput<TOSALeafNode>) => {
  const { node, formMethods } = props;
  const registerProps = formMethods.register(node.name);

  return (
    <Checkbox
      label={props.node.input?.label}
      labelWrap={true}
      className={styles.checkbox}
      id={node.__self__}
      {...registerProps}
      name={node.name}
      color="charcoal-1"
    />
  );
};
