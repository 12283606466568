export function removeTypenamesFromGraphQLResponse<T>(item: T): T {
  if (Array.isArray(item)) {
    return item.map(removeTypenamesFromGraphQLResponse) as T;
  }

  if (typeof item === 'object' && item !== null) {
    const cleaned: Record<string, unknown> = {};
    Object.entries(item).forEach(([key, value]) => {
      if (key !== '__typename') {
        cleaned[key] = removeTypenamesFromGraphQLResponse(value);
      }
    });

    return cleaned as T;
  }

  return item;
}
