import { PaddedCard, Text, Divider } from '@farmersdog/corgi-x';
import { useId, useState } from 'react';
import { VET_CLINIC_FEATURE_DESC } from './constants';
import { useVetClinic } from './hooks';
import { ClinicSearchAndSavedClinics } from './features/ClinicSearchAndSavedClinics/ClinicSearchAndSavedClinics';

export function VetClinic() {
  const id = useId();
  const { shouldShowVetClinic, shouldShowVetClinicSearch } = useVetClinic();
  const [
    hideComponentIfSearchOffAndNoClinics,
    setHideComponentIfSearchOffAndNoClinics,
  ] = useState(false);

  return (
    <>
      {shouldShowVetClinic && !hideComponentIfSearchOffAndNoClinics && (
        <section id="vet-clinic">
          <PaddedCard aria-labelledby={id}>
            <Text id={id} as="h2" variant="heading-28" color="kale-3" bold>
              Vet Clinic
            </Text>
            <Text as="p" variant="article-16" color="charcoal-3">
              {VET_CLINIC_FEATURE_DESC}
            </Text>
            <Divider width={1} color="Charcoal0" spacing={24} />
            <ClinicSearchAndSavedClinics
              shouldShowVetClinicSearch={shouldShowVetClinicSearch}
              parentId={id}
              setHideComponentIfSearchOffAndNoClinics={
                setHideComponentIfSearchOffAndNoClinics
              }
            />
          </PaddedCard>
        </section>
      )}
    </>
  );
}
