import { FeatureName, FreshFoodConfidenceTreatmentEnum } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface UseFreshFoodEducationArgs {
  useFeature: UseFeatureHook;
  freshFoodConfidence: string;
}
type UseFreshFoodConfidenceReturn =
  | {
      shouldShowPersonalizedEducation: true;
      freshFoodEducationTreatment: FreshFoodConfidenceTreatmentEnum;
    }
  | {
      shouldShowPersonalizedEducation: false;
      freshFoodEducationTreatment: FreshFoodConfidenceTreatmentEnum.off;
    };

export function useFreshFoodEducation({
  useFeature,
  freshFoodConfidence,
}: UseFreshFoodEducationArgs): UseFreshFoodConfidenceReturn {
  const { treatment: freshFoodEducationTreatment } = useFeature(
    FeatureName.CVR_FRESH_FOOD_CONFIDENCE_EDUCATION,
    {
      attributes: {
        freshFoodConfidence: freshFoodConfidence ?? '',
      },
    }
  );

  const shouldShowPersonalizedEducation =
    freshFoodEducationTreatment !== FreshFoodConfidenceTreatmentEnum.off &&
    freshFoodEducationTreatment !== FreshFoodConfidenceTreatmentEnum.control &&
    freshFoodEducationTreatment in FreshFoodConfidenceTreatmentEnum;

  return {
    ...(shouldShowPersonalizedEducation
      ? {
          shouldShowPersonalizedEducation,
          freshFoodEducationTreatment:
            freshFoodEducationTreatment as FreshFoodConfidenceTreatmentEnum,
        }
      : {
          shouldShowPersonalizedEducation,
          freshFoodEducationTreatment: FreshFoodConfidenceTreatmentEnum.off,
        }),
  };
}
