import fishRecipeImageWebP from './fish-recipe-image.webp';
import fishRecipeImageJpg from './fish-recipe-image.jpg';
import meatRecipeImageWebP from './meat-recipe-image.webp';
import meatRecipeImageJpg from './meat-recipe-image.jpg';
import poultryRecipeImageWebP from './poultry-recipe-image.webp';
import poultryRecipeImageJpg from './poultry-recipe-image.jpg';

export {
  fishRecipeImageJpg,
  fishRecipeImageWebP,
  meatRecipeImageJpg,
  meatRecipeImageWebP,
  poultryRecipeImageJpg,
  poultryRecipeImageWebP,
};
