import * as Apollo from '@apollo/client';

import { useMutation } from '../../services/apollo';
import { RecoverLeadMutationVariables, RecoverLeadMutation } from '../types';

import recoverLead from './recoverLead.graphql';

type Options = Apollo.MutationHookOptions<
  RecoverLeadMutation,
  RecoverLeadMutationVariables
>;

export function useRecoverLead(options?: Options) {
  return useMutation<RecoverLeadMutation, RecoverLeadMutationVariables>(
    recoverLead,
    options
  );
}
