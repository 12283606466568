import { Button, Grid, GridItem, Page, Text } from '@farmersdog/corgi-x';

import { CheckCircle } from '@farmersdog/corgi-x/icons';
import styles from './LpfSuccess.module.css';
interface LpfSuccessProps {
  onClose: () => void;
  recipeDisplayName: string;
}

/**
 * Success page shown after a customer has accepted the LPF downsell
 */
export function LpfSuccess(props: LpfSuccessProps) {
  return (
    <Page layout="base-centered" onCloseClick={props.onClose}>
      <Grid
        className={styles.content}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        topSpacing="lg"
        gap="sm"
      >
        <GridItem>
          <CheckCircle baseColor="Carrot2" height={64} />
        </GridItem>
        <GridItem>
          <Text as="h1" variant="heading-40" bold color="kale-3">
            You are all set!
          </Text>
        </GridItem>
        <GridItem>
          <Text variant="heading-22" color="charcoal-3">
            Your dog’s recipes have been updated to {props.recipeDisplayName}.
            You can review and edit your recipe selection on the ‘My Dog’ page
            of your account.
          </Text>
        </GridItem>
        <Grid topSpacing="md">
          <GridItem>
            <Button
              className={styles.cta}
              type="button"
              variant="primary"
              onClick={props.onClose}
            >
              Close
            </Button>
          </GridItem>
        </Grid>
      </Grid>
    </Page>
  );
}
