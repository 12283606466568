import { Nowrap } from '@farmersdog/corgi-x';

import woofImOtisSources from './assets/woof.im.otis';
import woofImOtisIcon from './assets/woof.im.otis_icon.jpg';
import ottozurichSources from './assets/ottozurich';
import ottozurichIcon from './assets/ottozurich_icon.jpg';
import pawsofsimbaSources from './assets/pawsofsimba';
import pawsofsimbaIcon from './assets/pawsofsimba_icon.jpg';

export default [
  {
    url: 'https://www.instagram.com/p/BwciBvonxpI/',
    screenName: 'woof.im.otis',
    quote: (
      <span>
        <span role="img" aria-label="speaker high volume">
          🔊
        </span>
        Made dad film and edit my morning @thefarmersdog. Now watch{' '}
        <Nowrap>
          me eat!{' '}
          <span role="img" aria-label="speaker high volume">
            🔊
          </span>
        </Nowrap>
      </span>
    ),
    icon: woofImOtisIcon,
    sources: woofImOtisSources,
  },
  {
    url: 'https://www.instagram.com/p/BgeZstah2vG/',
    screenName: 'ottozurich',
    quote: (
      <span>
        Are you ready for it??? #imafarmersdog Otto is tired of looking cute and
        just wants to eat!!! Finally found a food he actually wants and
        tolerates!!!{' '}
        <Nowrap>
          <span role="img" aria-label="raising hands">
            🙌🙌🙌🙌
          </span>{' '}
          @thefarmersdog
        </Nowrap>
      </span>
    ),
    icon: ottozurichIcon,
    sources: ottozurichSources,
  },
  {
    url: 'https://www.instagram.com/p/BfrTkB2l3F1/',
    screenName: 'pawsofsimba',
    quote: (
      <span>
        It’s hard work supervising in the kitchen but someone has to do it!
        Can’t wait to dig into my @thefarmersdog meal{' '}
        <span role="img" aria-label="pot of food">
          🍲
        </span>{' '}
        <Nowrap>#imafarmersdog #realfood</Nowrap>
      </span>
    ),
    icon: pawsofsimbaIcon,
    sources: pawsofsimbaSources,
  },
];
