/** We are unable to obtain shipping rates from our shipping service */
export const SHIPPING_SERVICE_UNAVAILABLE = 'SHIPPING_SERVICE_UNAVAILABLE';
/** The address cannot be shipped to */
export const CANNOT_SHIP_TO_LOCATION = 'CANNOT_SHIP_TO_LOCATION';
/** Generic non specific address validation error */
export const ADDRESS_VALIDATION_ERROR = `ADDRESS_VALIDATION_ERROR`;
/** The state is valid but TFD cannot deliver to it */
export const ADDRESS_INACCESSIBLE_STATE_ERROR = `ADDRESS_INACCESSIBLE_STATE_ERROR`;
/** The state is not valid state */
export const ADDRESS_INVALID_STATE_ERROR = `ADDRESS_INVALID_STATE_ERROR`;
/** The zip code is not valid */
export const ADDRESS_INVALID_ZIP_ERROR = `ADDRESS_INVALID_ZIP_ERROR`;
/** The address is a PO box and is not deliverable by TFD */
export const ADDRESS_PO_BOX_ERROR = `ADDRESS_PO_BOX_ERROR`;
/** There is already an account with this address */
export const ADDRESS_DUPLICATE_ERROR = `ADDRESS_DUPLICATE_ERROR`;
/** The address contains incorrect data that could be automatically corrected */
export const ADDRESS_INCORRECT_DATA_ERROR = `ADDRESS_INCORRECT_DATA_ERROR`;
/** The address contains incorrect data that cannot be automatically corrected */
export const ADDRESS_POTENTIAL_BAD_DATA_ERROR = `ADDRESS_POTENTIAL_BAD_DATA_ERROR`;
/** The address contains data that is valid separately but not in combination */
export const ADDRESS_DATA_MISMATCH_ERROR = `ADDRESS_DATA_MISMATCH_ERROR`;
/** The address cannot be found in the validation dataset */
export const ADDRESS_NOT_FOUND_ERROR = `ADDRESS_NOT_FOUND_ERROR`;
/** The address is not precise enough to be deliverable */
export const ADDRESS_AMBIGUOUS_ERROR = `ADDRESS_AMBIGUOUS_ERROR`;
/** Address is invalid for an unspecified reason */
export const ADDRESS_INVALID_ERROR = `ADDRESS_INVALID_ERROR`;
/** The address validation service is experiencing downtime */
export const ADDRESS_SERVICE_UNAVAILABLE_ERROR = `ADDRESS_SERVICE_UNAVAILABLE_ERROR`;
/** The zip code does not exist */
export const ADDRESS_NONEXISTENT_ZIPCODE_ERROR = `ADDRESS_NONEXISTENT_ZIPCODE_ERROR`;
/** ValidationError returned from CGS */
export const CGS_VALIDATION_ERROR = 'CGS_VALIDATION_ERROR';
/** ApplicationError returned from CGS */
export const CGS_APPLICATION_ERROR = 'CGS_APPLICATION_ERROR';
/** @deprecated Schema validation error returned from CGS */
export const CORE_API_VALIDATION_ERROR = 'CORE_API_VALIDATION_ERROR';
/** Validation error for API <-> CGS schema mismatch */
export const CORE_API_DATA_VALIDATION_ERROR = 'CORE_API_DATA_VALIDATION_ERROR';
