import { createContext } from 'react';
import { ID, FeatureMap } from '../types';

export interface CompleteSplitContextValue<
  T extends object,
  U extends object,
  G,
> {
  anonymousId: ID;
  anonymousFeatures: FeatureMap<T>;
  userId?: ID;
  userFeatures: FeatureMap<U>;
  globalAttributes?: G;
  allowOverride: boolean;
}

export interface InitialSplitContextValue {
  anonymousFeatures: undefined;
  userFeatures: undefined;
  userId: undefined;
  anonymousId: undefined;
  globalAttributes: undefined;
  allowOverride: false;
}

export type SplitContextValue<
  T extends object = object,
  U extends object = object,
  G = Record<string, unknown>,
> = CompleteSplitContextValue<T, U, G> | null;

export const SplitContext = createContext<SplitContextValue>(null);
