export function normalizeZipCode(value: string) {
  if (!value) {
    return value;
  }
  const normalizedZipCode = value.replace(/[^\d]/g, '');
  if (normalizedZipCode.length <= 5) {
    return normalizedZipCode;
  }

  return `${normalizedZipCode.slice(0, 5)}-${normalizedZipCode.slice(5, 9)}`;
}
