export const TITLE = 'We’d love to help';
export const ROOT_DECISION_COPY =
  'We want to create the perfect plan for you. What can we improve?';
export const ROOT_DECISION_LEGEND = 'What can we improve?';

export const RECIPES_DECISION_LABEL = 'I need a different recipe';
export const MAX_PRICE_LABEL = 'I need a lower price';
export const OTHER_LABEL = 'Other';
export const SEE_PRICING_LABEL = 'I want to see the price';
export const SEE_RECIPES_LABEL = 'I want to see the recipes';
export const MORE_INFO_LABEL = 'I need more information';
export const MORE_TIME_LABEL = 'I just need more time';
export const PET_INFO_LABEL = "I don't know my pet's information";
