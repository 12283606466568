interface HandlePhoneBackspaceChange {
  phoneInput: string;
  denormalizedPhone: string;
}
export const handlePhoneBackspaceChange = ({
  phoneInput,
  denormalizedPhone,
}: HandlePhoneBackspaceChange) => {
  const denormalizedLength = denormalizedPhone.length;

  if (phoneInput.endsWith('-')) {
    return denormalizedPhone;
  }

  if (denormalizedLength === 3 || denormalizedLength === 6) {
    return denormalizedPhone.slice(0, denormalizedLength - 1);
  }

  return denormalizedPhone;
};
