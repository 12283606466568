import { ApplicationError, ValidationError } from 'src/errors';
import { useCallback, useMemo, useState } from 'react';

import { showError } from 'src/actions/ui';
import { useDispatch } from 'react-redux';

const MODAL_TITLE = `Sorry to be picky…`;

// https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
const VALIDITY_TO_PROP = {
  badInput: 'type',
  patternMismatch: 'pattern',
  rangeOverflow: 'max',
  rangeUnderflow: 'min',
  stepMismatch: 'step',
  tooLong: 'maxLength',
  tooShort: 'minLength',
  typeMismatch: 'type',
  valueMissing: 'required',
};

/**
 * Loops though all validationMessage keys and checks if the input has any failed
 * validity.
 *
 * @param {Event} e Input event
 * @param {Object} validationMessages Validation messages input expects to handle
 *
 * @returns {String | undefined}
 */
export function getFailedValidity(e, validationMessages) {
  const validityToCheck = Object.keys(validationMessages);
  const failedValidity = validityToCheck.find(validity => {
    if (e.target.validity[validity]) {
      return validity;
    }
  });

  return failedValidity;
}

/**
 * Loops through all customValidations to find a failed test.  Returns the validation's message.
 *
 * @param {Event} e Input event
 * @param {CustomValidation[]} customValidations Custom validations to test against
 */
export function getFailedCustom(e, customValidations) {
  const failedCustom = customValidations.find(customValidation =>
    customValidation.test(e.target.value)
  );

  e.target.setCustomValidity(failedCustom?.message ?? '');
  return failedCustom?.message;
}

export function getValidationError(e, validityMessages, customValidations) {
  const failedValidity = getFailedValidity(e, validityMessages);

  if (failedValidity) {
    return validityMessages[failedValidity];
  }

  const failedCustom = getFailedCustom(e, customValidations);

  return failedCustom;
}

/**
 * @typedef UseInputValidation
 *
 * @property {(e: ChangeEvent) => Boolean} checkValidity Get the input validity after an event
 * @property {String | undefined} validationError Last found error message
 * @property {Object} formControlProps Props to be passed to a FormControl element
 * @property {Object} inputProps Props to be passed to a input element
 */

/**
 * @typedef Validation
 *
 * @property {ValidationConfig} [badInput]
 * @property {ValidationConfig} [patternMismatch]
 * @property {ValidationConfig} [rangeOverflow]
 * @property {ValidationConfig} [rangeUnderflow]
 * @property {ValidationConfig} [stepMismatch]
 * @property {ValidationConfig} [tooLong]
 * @property {ValidationConfig} [tooShort]
 * @property {ValidationConfig} [typeMismatch]
 * @property {ValidationConfig} [valueMissing]
 */

/**
 * @typedef ValidationConfig
 *
 * @property {*} value Value of the associated validation prop
 * @property {String} message Message to display when in valid
 */

/**
 * @typedef CustomValidation
 *
 * @property {(value) => Boolean} test Function to run a validation test against,
 * return true for invalid.
 * @property {String} message Message to display when in valid
 */

/**
 * Hook to assist with client side validation.
 *
 * @param {Validation} validation Object who's keys are a ValidationState
 * and values are a ValidationConfig.
 * @param {...CustomValidation} customValidations  All remaining parameters
 * should be custom validation configs
 *
 * @returns {UseInputValidation}
 */
function useInputValidation(validation, ...customValidations) {
  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState();

  const { inputProps, validityMessages } = useMemo(() => {
    const props = {
      'aria-required': validation.valueMissing?.value ? true : undefined,
      onInvalid: e => {
        const inputElement = e.target;
        const newValidationError = getValidationError(
          e,
          validityMessages,
          customValidations
        );
        setValidationError(newValidationError);
        const error = new ValidationError(newValidationError, e.target.name);

        if (!newValidationError) {
          const validationConfigError = new ApplicationError(
            'Unhandled validation detected',
            {
              name: e.target.name,
            }
          );

          return dispatch(showError(validationConfigError));
        }

        dispatch(
          showError(error, {
            title: MODAL_TITLE,
            onHide: () => {
              inputElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            },
          })
        );
      },
    };
    const messages = {};

    Object.entries(validation).forEach(([property, config]) => {
      const propForValidity = VALIDITY_TO_PROP[property];

      props[propForValidity] = config.value;
      messages[property] = config.message;
    });

    return { inputProps: props, validityMessages: messages };
  }, [customValidations, dispatch, validation]);

  const checkValidity = useCallback(
    e => {
      const newValidationError = getValidationError(
        e,
        validityMessages,
        customValidations
      );
      setValidationError(newValidationError);

      return !newValidationError;
    },
    [customValidations, validityMessages]
  );

  const formControlProps = {
    invalid: Boolean(validationError),
  };

  if (validationError) {
    formControlProps.message = validationError;
  }

  return {
    checkValidity,
    validationError,
    inputProps,
    formControlProps,
  };
}

export default useInputValidation;
