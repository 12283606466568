import { parse } from 'qs';

import { safeJsonParse } from './safeJsonParse';
import {
  GetTreatmentConfigType,
  GetTreatmentType,
  FeatureMap,
  GetConfigType,
} from '../types';

export interface GetTreatmentFromQueryStringArgs<
  T extends object,
  K extends keyof FeatureMap<T>,
> {
  treatmentName: K;
  queryString: string;
}

/**
 * Attempts to get treatment data from query parameters
 *
 * @param name - Feature name
 * @param queryString - Query string from location
 */
export function getTreatmentFromQueryString<
  T extends object,
  K extends keyof FeatureMap<T>,
>({
  treatmentName,
  queryString,
}: GetTreatmentFromQueryStringArgs<T, K>):
  | GetTreatmentConfigType<T, K>
  | undefined {
  const parsed = parse(queryString, {
    ignoreQueryPrefix: true,
  });

  if (isValidQueryTreatment<T, K>(treatmentName, parsed)) {
    const treatment = parsed[treatmentName];
    const unparsedQueryConfig = parsed.config;

    return {
      treatment,
      config: unparsedQueryConfig
        ? // @ts-expect-error Config strings may not match expected types
          safeJsonParse(decodeURIComponent(unparsedQueryConfig))
        : {},
    };
  }

  return undefined;
}

type UnparsedTreatmentQuery<T extends object, K extends keyof FeatureMap<T>> = {
  [P in K]: GetTreatmentType<T, K>;
} & { config: GetConfigType<T, K> };

function isValidQueryTreatment<T extends object, K extends keyof FeatureMap<T>>(
  treatmentName: K,
  value: unknown
): value is UnparsedTreatmentQuery<T, K> {
  return Object.prototype.hasOwnProperty.call(value, treatmentName);
}
