import { ApolloError, useMutation } from '@apollo/client';
import acceptFrequencyUpdateSubscription from './acceptFrequencyUpdateSubscription.core-api.graphql';
import { AcceptFrequencyUpdateSubscriptionMutationVariables } from './acceptFrequencyUpdateSubscription.core-api.generated-types';
interface UseSuggestOrderSizeMutation {
  onCompleted: (
    response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
  ) => void;
  onError: (error: ApolloError) => void;
}

export type ValidAcceptFrequencyUpdateSubscriptionMutationResponse = {
  updateMySubscription: {
    subscription: {
      frequency: number;
      nextOrderToBeDelivered: {
        earliestDesiredArrivalDate: string;
        latestDesiredArrivalDate: string;
      };
    };
  };
};

export function isValidAcceptFrequencyUpdateSubscriptionMutationResponse(
  dataObj: unknown
): dataObj is ValidAcceptFrequencyUpdateSubscriptionMutationResponse {
  return (
    (dataObj as ValidAcceptFrequencyUpdateSubscriptionMutationResponse)
      .updateMySubscription.subscription.frequency !== undefined &&
    (dataObj as ValidAcceptFrequencyUpdateSubscriptionMutationResponse)
      .updateMySubscription.subscription.nextOrderToBeDelivered
      ?.earliestDesiredArrivalDate !== undefined &&
    (dataObj as ValidAcceptFrequencyUpdateSubscriptionMutationResponse)
      .updateMySubscription.subscription.nextOrderToBeDelivered
      ?.latestDesiredArrivalDate !== undefined
  );
}

export const useSuggestOrderSizeMutation = (
  args: UseSuggestOrderSizeMutation
) => {
  const [submit, { loading }] = useMutation(acceptFrequencyUpdateSubscription, {
    refetchQueries: [],
    onCompleted: (
      response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
    ) => {
      if (!isValidAcceptFrequencyUpdateSubscriptionMutationResponse(response)) {
        throw new Error(
          'Incomplete data in SuggestOrderSize mutation response'
        );
      }
      args.onCompleted(response);
    },
    onError: args.onError,
  });

  const onSubmit = (
    variables: AcceptFrequencyUpdateSubscriptionMutationVariables
  ) => {
    void submit({ variables: { ...variables } });
  };

  return {
    onSubmit,
    loading,
  };
};
