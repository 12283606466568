import { local } from 'src/utils/browserStorage';
import cookie from 'js-cookie';
import { SEGMENT_USER_ID_COOKIE } from 'src/constants/cookie';

export const SEGMENT_USER_ID_LOCAL_STORAGE_KEY = SEGMENT_USER_ID_COOKIE;
export const DEFAULT_USER_ID = 'null';

function isValidId(id: string | null | undefined) {
  if (!id) {
    return true;
  }
  const numericId = Number(id);
  return Boolean(
    id === 'null' || id === 'undefined' || Number.isFinite(numericId)
  );
}

export function clearInvalidUserId() {
  const localStorageUserId = local.getItem(SEGMENT_USER_ID_LOCAL_STORAGE_KEY);
  if (!isValidId(localStorageUserId)) {
    local.setItem(SEGMENT_USER_ID_LOCAL_STORAGE_KEY, DEFAULT_USER_ID);
  }

  const cookieUserId = cookie.get(SEGMENT_USER_ID_COOKIE);
  if (!isValidId(cookieUserId)) {
    cookie.set(SEGMENT_USER_ID_COOKIE, DEFAULT_USER_ID);
  }
}
