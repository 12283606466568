import { MutationResult } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { Logger } from '@farmersdog/logger';

import { useUpdateUserProductEligibility } from '../graphql/mutations/useUpdateUserProductEligibility';
import {
  UpdateUserProductEligibilityMutation,
  UserProductEligibilityProductLine,
} from '../graphql/types';

interface UseSetLpfProductEligibilityArgs {
  email: string | null;
  shouldPersistLpfEligibility: boolean;
  lpfRecipeNames?: string[] | null;
}

/*
 * This hook is used to persist a user's product line eligibility for LPF
 */
export function useSetLpfProductEligibility({
  shouldPersistLpfEligibility,
  email,
  lpfRecipeNames = null,
}: UseSetLpfProductEligibilityArgs): MutationResult<UpdateUserProductEligibilityMutation> {
  const logger = useMemo(() => {
    return new Logger('tosa:frontend:useSetLpfProductEligibility');
  }, []);

  const [updateUserProductEligibility, updateUserProductEligibilityQueryState] =
    useUpdateUserProductEligibility();

  useEffect(() => {
    if (
      shouldPersistLpfEligibility &&
      email &&
      !updateUserProductEligibilityQueryState.called
    ) {
      try {
        void updateUserProductEligibility({
          variables: {
            email,
            productLine: UserProductEligibilityProductLine.Lpf,
            recipeNames: lpfRecipeNames,
          },
        });
      } catch (error) {
        logger.error('Failed to persist LPF eligibility', { error });
      }
    }
  }, [
    shouldPersistLpfEligibility,
    email,
    logger,
    updateUserProductEligibility,
    lpfRecipeNames,
    updateUserProductEligibilityQueryState.called,
  ]);

  return updateUserProductEligibilityQueryState;
}
