import useMutation from 'src/graphql/mutations/useMutation';
import { ApolloError } from '@apollo/client';
import updateDiySignupSubscriptionMutation from '../graphql/mutations/UpdateDiySignupSubscription.graphql';

import {
  UpdateDiySignupSubscriptionMutation,
  UpdateDiySignupSubscriptionMutationVariables,
} from 'src/graphql/types';

export interface UseUpdateDiySubscription {
  onCompleted?: (data?: UpdateDiySignupSubscriptionMutation | null) => void;
  onError?: (error: ApolloError) => void;
}

export function useUpdateDiySubscription({
  onCompleted,
  onError,
}: UseUpdateDiySubscription) {
  return useMutation<
    UpdateDiySignupSubscriptionMutation,
    UpdateDiySignupSubscriptionMutationVariables
  >(updateDiySignupSubscriptionMutation, {
    onCompleted,
    onError,
  });
}
