import { SkipGetStartedTreatments, FeatureName } from '../..';
import { Experiments } from '../../../types';

const ACTIVE_TREATMENTS = [
  SkipGetStartedTreatments.same_copy,
  SkipGetStartedTreatments.new_copy,
  SkipGetStartedTreatments.no_copy,
] as const;

export type SkipGetStartedActiveTreatment = (typeof ACTIVE_TREATMENTS)[number];

const isSkipGetStartedActiveTreatment = (
  treatment: SkipGetStartedTreatments
): treatment is SkipGetStartedActiveTreatment =>
  ACTIVE_TREATMENTS.includes(treatment as SkipGetStartedActiveTreatment);

type DetermineSkipGetStartedReturn =
  | {
      shouldSkipGetStarted: true;
      skipGetStartedTreatment: SkipGetStartedActiveTreatment;
    }
  | {
      shouldSkipGetStarted: false;
      skipGetStartedTreatment: SkipGetStartedTreatments.off;
    };

interface DetermineSkipGetStartedArgs {
  experiments: Experiments;
}

export function determineSkipGetStarted({
  experiments,
}: DetermineSkipGetStartedArgs): DetermineSkipGetStartedReturn {
  const skipGetStartedExperiment =
    experiments[FeatureName.CVR_SKIP_GET_STARTED];
  const skipGetStartedTreatment =
    (skipGetStartedExperiment?.treatment as
      | SkipGetStartedTreatments
      | undefined) || SkipGetStartedTreatments.off;

  const shouldSkipGetStarted = isSkipGetStartedActiveTreatment(
    skipGetStartedTreatment
  );

  return shouldSkipGetStarted
    ? {
        shouldSkipGetStarted,
        skipGetStartedTreatment,
      }
    : {
        shouldSkipGetStarted: false,
        skipGetStartedTreatment: SkipGetStartedTreatments.off,
      };
}
