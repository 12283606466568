/**
 * States in the "Suggest Order Size Up" flow
 * "Close" and "ContinueToPlan" don't have associated UIs
 */
export enum SuggestOrderSizeUpState {
  Suggest = 'Suggest',
  AcceptConfirmation = 'AcceptConfirmation',
  RejectConfirmation = 'RejectConfirmation',
  ContinueToPlan = 'ContinueToPlan',
  Close = 'Close',
}
