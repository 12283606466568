import { SuggestOrderSizeMachineContext } from './suggestOrderSizeMachine';

/**
 * Events in the Suggest Order Size workflow
 */
export enum SuggestOrderSizeEvent {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  Close = 'CLOSE',
  ContinueToPlan = 'CONTINUE_TO_PLAN',
}

export type SuggestOrderSizeEventMachineEvent = {
  type: SuggestOrderSizeEvent;
} & SuggestOrderSizeMachineContext;
