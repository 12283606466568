import { Card, ContentReveal } from '@farmersdog/corgi-x';

import { useErrorMessage } from '../../../../hooks/useErrorMessage';
import { TOSAComponentInput, TOSALeafNode } from '../../../../types';
import { getErrorMessage } from '../../../../validation';

export function SpeechBubble(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const {
    formState: { errors },
  } = formMethods;

  if (!node.input || !node.input.errorMap) {
    throw new Error('The error component needs an error map definition');
  }

  if (!node.input.excludeFromProgress) {
    throw new Error('The error component needs to be excluded from progress');
  }

  const { errorMap } = node.input;

  const errorCodeToDisplay = Object.values(errors)
    .map(error => getErrorMessage(error))
    .find(message => message && message in errorMap);

  const errorMessage = useErrorMessage({
    nodeName: node.name,
    getValues: formMethods.getValues,
    errorCode: errorCodeToDisplay,
    errorMap,
  });

  if (!errorMessage) return null;

  return (
    <ContentReveal in={!!errorMessage} inAnimation="fade">
      <div>
        <Card variant="speech-bubble" color="white" aria-label="Speech bubble">
          {errorMessage}
        </Card>
      </div>
    </ContentReveal>
  );
}
