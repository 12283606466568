import planBoxIllustration1 from './Mobile_Plan_Box_Illustration@1x.png';
import planBoxIllustration2 from './Mobile_Plan_Box_Illustration@2x.png';
import maybeLaterBuddyJadaIllustration from './maybe-later-buddy-jada.png';

export const planBox = {
  sources: [planBoxIllustration2, planBoxIllustration1],
  altText: 'Smaller Order Size',
};

export const maybeLaterBuddyJada = {
  sources: [maybeLaterBuddyJadaIllustration],
  altText: 'buddy and jada dogs',
};
