import { DiscountType, DiscountTypeAndAmount } from '../types';
import {
  REFERRAL_NOT_FOUND_RESPONSE_DATA,
  ReferrerResponse,
} from '../utils/getCoupon';
import { isUserReferrerResponse, isCouponResponse } from './discount';

export interface GetCouponBannerCopyArgs {
  coupon?: ReferrerResponse;
  discountAmount: number;
  discountType: DiscountType;
}

export function getCouponBannerCopy({
  coupon,
  discountAmount,
  discountType,
}: GetCouponBannerCopyArgs) {
  const discountCopy = getDiscountAmountCopy({
    discountType,
    discountAmount,
  });

  if (!coupon) {
    return `You’ve been gifted ${discountCopy} your first purchase!`;
  }

  if (coupon === REFERRAL_NOT_FOUND_RESPONSE_DATA || coupon.invalid === true) {
    return `Uh-oh, looks like your referral link is expired but here’s ${discountCopy} because dogs.`;
  }

  if (discountAmount === 0) {
    return `Discover your dog’s vet-recommended meal plan`;
  }

  if (isCouponResponse(coupon) && coupon.bannerCopy) {
    return coupon.bannerCopy;
  }

  if (
    discountAmount === 100 &&
    discountType === DiscountType.percentage &&
    isUserReferrerResponse(coupon)
  ) {
    return `${coupon.name} has given you a free trial!`;
  }

  if (
    discountAmount === 100 &&
    discountType === DiscountType.percentage &&
    isCouponResponse(coupon)
  ) {
    return `You’ve been given a free trial!`;
  }

  if (isUserReferrerResponse(coupon)) {
    return `${coupon.name} has gifted you ${discountCopy} your first purchase!`;
  }

  return `You’ve been gifted ${discountCopy} your first purchase!`;
}

export function getBannerCtaCopy({ discountAmount }: DiscountTypeAndAmount) {
  return discountAmount === 0 ? 'START NOW' : 'REDEEM NOW';
}

/**
 * Get the copy for the discount amount ('20% off' or '$30 off')
 */
export function getDiscountAmountCopy({
  discountAmount,
  discountType,
}: DiscountTypeAndAmount): string {
  return discountType === DiscountType.fixed
    ? `$${discountAmount} off`
    : `${discountAmount}% off`;
}
