import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

export interface LoginFrontendSuccessArgs {
  method: 'password' | 'token';
}

export function trackLoginFrontendSuccess({
  method,
}: LoginFrontendSuccessArgs) {
  track(eventNames.login_frontend_success, {
    method,
  });
}
