import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SmallFooter } from 'src/components/Footer';

import ActivityIndicator from 'src/components/_depreciated/ActivityIndicator';

import { fetchPreviousUser, getDecodedEmail } from 'src/actions/signup';
import { fetchPetConfiguration } from 'src/actions/configuration';

import { selectRefreshingToken, selectToken } from 'src/reducers/auth';
import { selectIsConfigurationFetched } from 'src/reducers/configuration';

import { selectUser } from 'src/reducers/signup/user';

import SignupHeader from './components/SignupHeader';

import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';

import { cookie, isFreshSignup } from '@farmersdog/lead-browser-storage';

import { scrollToTop } from 'src/utils/dom';

import { useHtmlElement } from '@farmersdog/corgi';

import useSignupParams from './hooks/useSignupParams';
import { PetSelectorV4 } from './components/PetSelector';
import useReferral from 'src/hooks/useReferral';
import { setGoogleTagManagerVar } from 'src/vendors/googleTagManager';
import { sendGa4UserId } from 'src/vendors/ga4';
import { getFacebookPixelAttributes } from 'src/vendors/facebook';
import useAsyncFunction from 'src/utils/useAsyncFunction';
import { useAsyncModule } from 'src/utils/useAsyncModule';

import { useRouteMatch } from 'react-router-dom';
import { PATH_SIGNUP_RECIPES } from '@farmersdog/constants/paths';

import {
  useEatingStyles,
  useBodyConditions,
} from '../../graphql/queries/usePetConfiguration';

import './Signup.scss';

import { reporter } from 'src/services/reporter';
import { datadog } from 'src/vendors/datadog';
import { useCreateLead } from 'src/graphql/tosa/mutations/useCreateLead';
import { useSyncLeadWithUser } from 'src/graphql/tosa/mutations/useSyncLeadWithUser';
import ErrorBoundary from 'src/errors/ErrorBoundary';
import { useThrowToErrorBoundary } from 'src/hooks/useThrowToErrorBoundary';
import Exit from './components/Exit';
import { SignupProviders } from './SignupProviders';

function Signup() {
  const dispatch = useDispatch();
  const refreshingToken = useSelector(selectRefreshingToken);
  const token = useSelector(selectToken);
  const { email } = useSignupParams();
  const user = useSelector(selectUser);
  const { id: userId } = user;
  const { eatingStyles } = useEatingStyles();
  const { bodyConditions } = useBodyConditions();
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const [isFetchingData, setIsFetchingData] = useState(true);

  const isConfigurationFetched = useSelector(selectIsConfigurationFetched);
  const [fetchConfig, fetchConfigState] = useAsyncFunction(() =>
    dispatch(fetchPetConfiguration())
  );

  const { current } = useSignupRouter();
  const signupSwitchModule = useAsyncModule(() => import('./SignupSwitch'), {
    onError: err => {
      reporter.warn(`Error loading SignupSwitch asynchronously - ${err}`);
      throw new Error(err);
    },
  });

  useReferral();
  const [fadeEl, fadeElRef] = useHtmlElement();

  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);

  const [syncLeadWithUserMutation, syncLeadWithUserMutationState] =
    useSyncLeadWithUser();
  const [createLead, createLeadRequest] = useCreateLead();

  // componentDidMount
  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingData(true);
      // Sync lead with user if email is present
      const decodedEmail = getDecodedEmail(email);
      if (decodedEmail && !createLeadRequest.called) {
        let leadResult;

        try {
          leadResult = await createLead({
            variables: {
              email: decodedEmail,
              daisyModeEnabled: false,
            },
          });
        } catch (err) {
          if (email) {
            datadog.identify({ email });
          }
          reporter.error(new Error('Failed to create lead'), { err });
          throwToErrorBoundary(err);
          return;
        }

        if (
          leadResult?.data?.createLead?.recoverable &&
          !syncLeadWithUserMutationState.called
        ) {
          try {
            await syncLeadWithUserMutation({
              variables: {
                email: decodedEmail,
                skipSyncIfNoRecipeSelection: true,
              },
            });
          } catch (err) {
            // We don't want to fail loudly here because right now there's an
            // unknown issue with a small portion of these requests. Because
            // the success of this request is not strictly necessary for the
            // user to checkout (there's just a potential they'll be checking
            // out with stale data), we're going to swallow this error for now
            // until we figure out the issue.
            if (email) {
              datadog.identify({ email });
            }
            reporter.error(new Error('Failed to sync lead with user'), { err });
          }
        }
      }

      try {
        await dispatch(fetchPreviousUser(email));
      } catch (err) {
        if (email) {
          datadog.identify({ email });
          reporter.error(new Error('Failed to fetch previous user with email'));
        }
      }
      setIsFetchingData(false);
      scrollToTop();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isConfigurationFetched ||
      fetchConfigState.loading ||
      fetchConfigState.called ||
      !token
    ) {
      return;
    }

    fetchConfig();
  }, [fetchConfig, fetchConfigState, isConfigurationFetched, token]);

  useEffect(() => {
    const pixelAttributes = getFacebookPixelAttributes(user, {
      eatingStyles,
      bodyConditions,
    });

    Object.entries(pixelAttributes).forEach(([attribute, value]) => {
      setGoogleTagManagerVar(attribute, value);
    });
  }, [bodyConditions, eatingStyles, user]);

  useEffect(() => {
    if (userId) {
      cookie.setCorePostGresUserId(String(userId));
    }
    sendGa4UserId({ userId });
  }, [userId]);

  if (isFetchingData || refreshingToken || signupSwitchModule.loading) {
    return (
      <div className="Signup">
        <SignupHeader />
        <div className="Signup-loading">
          <ActivityIndicator />
        </div>
        <SmallFooter />
      </div>
    );
  }

  const showFooter = current && (current.me || current.recipes);
  const SignupSwitch = signupSwitchModule.data.default;

  const fadeElClass = `Signup-fadeEl ${
    isFreshSignup() ? 'Signup-fadeEl-fresh' : 'Signup-fadeEl-diy'
  }`;

  return (
    <SignupProviders>
      <div className="Signup">
        <div>
          <SignupHeader />
          <PetSelectorV4 fadeEl={fadeEl} />
          <div className="Signup-Content">
            {isRecipesPage && (
              <div className={fadeElClass}>
                <div ref={fadeElRef} />
              </div>
            )}
            <ErrorBoundary>
              <SignupSwitch />
            </ErrorBoundary>
          </div>
        </div>
        {showFooter && <SmallFooter isRecipesPage={isRecipesPage} />}
      </div>
      <Exit isTOSA={false} />
    </SignupProviders>
  );
}

export default Signup;
