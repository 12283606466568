import { useState } from 'react';

/**
 * A hook to manage the state of the MaybeLaterModal
 */
export function useMaybeLaterModal() {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return {
    isOpen,
    openModal,
    closeModal,
  };
}
