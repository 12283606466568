interface ProgressItem {
  number: number | React.ReactElement;
  title: string;
  subtitle: string;
  descriptionItems: string[];
}

export const progressItems: ProgressItem[] = [
  {
    number: 1,
    title: 'Intro Box',
    subtitle: 'Features',
    descriptionItems: [
      'Smaller order to start',
      'Personalized portions',
      'Transition instructions',
      'Your chosen recipes',
    ],
  },
  {
    number: 2,
    title: 'Second Box',
    subtitle: 'First chance to',
    descriptionItems: [
      'Swap recipes',
      'Adjust portions',
      'Change order size',
      'Add treats',
    ],
  },
  {
    number: (
      <>
        3<small>+</small>
      </>
    ),
    title: 'Recurring Boxes',
    subtitle: 'Ongoing benefits',
    descriptionItems: [
      'No more store runs, ever',
      '24/7 human customer support',
      'Make changes anytime',
    ],
  },
];
