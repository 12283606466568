import PropTypes from 'prop-types';
import Button from '../_depreciated/Button';

import './CircleButton.scss';

/**
 * Render a circular button. Works well with an icon as child!
 */
export default function CircleButton({ children, ...props }) {
  return (
    <Button {...props} kind="default-alternate" className="CircleButton">
      {children}
    </Button>
  );
}

CircleButton.propTypes = {
  children: PropTypes.node.isRequired,
};
