export const ADD_DOG_PAGE_HEADING = 'Add a Dog';
export const ADD_DOG_PAGE_SUBHEADING =
  "Helping more dogs eat fresh, healthy food is what we're here for! Use the button below to email us with your details and we'll be in touch to set up your new pup's plan.";

export const ADD_DOG_EMAIL_SUBJECT =
  "Adding a Dog to my Plan at The Farmer's Dog";
export const ADD_DOG_EMAIL_BODY =
  'Hi,\n\n' +
  'I want to add another dog to my account!\n\n' +
  "Here are the details for my pup's new profile:\n\n" +
  'Name: \n' +
  'Age (specify weeks, months, or years): \n' +
  'Sex:\n' +
  'Breed:\n' +
  'Neutered/spayed:\n' +
  'Weight:\n' +
  'Body condition (too skinny, just right, rounded, chunky):\n' +
  'Activity level (not very active, active, very active, pro athlete):\n' +
  'Eating style (very picky, can be picky, good eater, will eat anything):\n' +
  'History of pancreatitis/hyperlipidemia/hypertriglyceridemia or prescribed a therapeutic low-fat diet by a vet? (yes/no):\n' +
  'Up to 3 desired recipes (pork, turkey, chicken, and/or beef):\n' +
  'Email address associated with my account:\n' +
  'First and last name associated with my account:\n\n' +
  'Thank you!\n\n' +
  '*If you want to add multiple dogs, just let us know the details for each dog!*';

export const ADD_DOG_EMAIL_ADDRESS = 'help@thefarmersdog.com';

export const ADD_DOG_CTA_TEXT = 'Contact Us';
