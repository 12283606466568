export const freshFoodOptions = [
  {
    value: 0,
    label: 'already believe',
  },
  {
    value: 1,
    label: 'am not yet sure',
  },
  {
    value: 2,
    label: 'don’t believe',
  },
];
