import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';

interface SelectedSearchResultPayload {
  clinicId: string;
}

export function trackSelectedSearchResult({
  clinicId,
}: SelectedSearchResultPayload) {
  track(eventNames.selected_search_result, {
    id: clinicId,
  });
}
