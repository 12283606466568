import { useLocation } from 'react-router-dom';
import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { useFullFooterContact } from '../hooks/useFullFooterContact';
import { freshCxPhone } from 'src/constants/phoneNumbers';
import styles from './FooterLinks.module.scss';
import { PATH_DIY } from '@farmersdog/constants/paths';

export const FooterSupportLinks = () => {
  const location = useLocation();
  const { showPhone, showEmail, supportEmail } = useFullFooterContact();

  const isDIY = location.pathname === PATH_DIY;

  return (
    <>
      <Text as="h4" variant="heading-16" bold>
        Get Support{' '}
        {!isDIY && (
          <Text
            className={styles.availability}
            color="Kale1"
            fontStyle="italic"
            variant="article-12"
          >
            Available 24/7
          </Text>
        )}
      </Text>
      <ul className={styles.list} aria-label="Get Support">
        {showEmail && (
          <li>
            <Link variant="secondary" href={`mailto:${supportEmail}`}>
              <Text variant="heading-16">{supportEmail}</Text>
            </Link>
          </li>
        )}
        {showPhone && (
          <li>
            <Link variant="secondary" href={`tel:${freshCxPhone.link}`}>
              <Text variant="heading-16">
                {freshCxPhone.display} - Call or Text
              </Text>
            </Link>
          </li>
        )}
      </ul>
    </>
  );
};
