import { useMachine } from '@xstate/react';
import { ReactivateEvent, ReactivateState } from '../constants';
import { reactivateMachine } from './reactivateMachine';

export interface UseReactivateMachine {
  isState: (state: ReactivateState) => boolean;
  onEdit: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export function useReactivateMachine() {
  const [state, send] = useMachine(reactivateMachine);

  const isState = (stateInput: ReactivateState) => {
    return state.matches(stateInput);
  };

  const onEdit = () => {
    send({ type: ReactivateEvent.Edit });
  };

  const onPrevious = () => {
    send({ type: ReactivateEvent.Previous });
  };

  const onNext = () => {
    send({ type: ReactivateEvent.Next });
  };

  return {
    isState,
    onEdit,
    onPrevious,
    onNext,
  };
}
