import {
  pinterest,
  PinterestEventAttributes,
  PinterestCheckoutEventData,
} from './pinterest';
import { sharedPinterestAttributes } from './constants';

/** The attributes required for an Pinterest checkout conversion event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'checkout',
};

export interface TrackPinterestConversionProps {
  userId: number;
  email: string;
}

/**
 * Track a Pinterest checkout conversion event.
 */

export async function trackPinterestCheckoutEvent({
  userId,
  email,
}: TrackPinterestConversionProps) {
  const eventData: PinterestCheckoutEventData = {
    value: '100',
    currency: 'USD',
    order_id: String(userId),
  };
  await pinterest.setEnhancedMatchAttributes(email);
  pinterest.track({
    ...attributes,
    ed: eventData,
  });
}
