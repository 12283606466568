import { track } from '@farmersdog/analytics';
import { Section, Text, VideoSection } from '@farmersdog/corgi-x';

import { eventNames } from '../../../../analytics/eventNames';
import { ResponsiveEdgeWrapper } from '../../../ResponsiveEdgeWrapper';
import { createSlottableComponent } from '../../../../../slots';
import { useEligibility } from './hooks';

import styles from './FoundersVideo.module.css';

const HEADING_TEXT = 'Watch Our Story';
const VIDEO_ID = 'yihnuivjtw';

/**
 * Displays a Founder's Video card powered by Wistia.
 */
function FoundersVideoComponent() {
  const WISTIA_OPTIONS = {
    popover: true,
  };

  const heading = (
    <Text variant="heading-28" as="h1" color="white" bold>
      {HEADING_TEXT}
    </Text>
  );

  const onWistiaEvent = (eventName: string) => {
    return () => {
      track(eventName);
    };
  };

  return (
    <ResponsiveEdgeWrapper>
      <Section
        aria-label={HEADING_TEXT}
        variant="card"
        className={styles.card}
        bottomSpacing="lg"
      >
        <VideoSection
          wistiaId={VIDEO_ID}
          wistiaOptions={WISTIA_OPTIONS}
          onPlay={onWistiaEvent(eventNames.homepage_brand_love_video_played)}
          onPause={onWistiaEvent(eventNames.homepage_brand_love_video_paused)}
          onEnd={onWistiaEvent(eventNames.homepage_brand_love_video_ended)}
          overlayContent={heading}
          className={styles.video}
        />
      </Section>
    </ResponsiveEdgeWrapper>
  );
}

export const FoundersVideo = createSlottableComponent(
  FoundersVideoComponent,
  useEligibility
);
