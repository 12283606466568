import { artsAi, ArtsAiEventName, PIXEL_ID } from './artsAi';

/**
 * Track an ArtsAI page view event
 */
export function trackArtsAiPageView(): void {
  artsAi.track({
    pixid: PIXEL_ID,
    action: ArtsAiEventName.PAGE_VIEW,
    ad_log: 'referer',
  });
}
