import pick from 'lodash/pick';

import {
  CARDS_FETCH,
  CARD_CREATE,
  CARD_UPDATE_DETAILS,
} from 'src/constants/actionTypes';

const initialCard = null;

function cardReducer(card = initialCard, action) {
  switch (action.type) {
    case CARDS_FETCH: {
      return action.payload.data[0] || initialCard;
    }
    case CARD_UPDATE_DETAILS:
    case CARD_CREATE: {
      return action.payload.data;
    }
    default: {
      return card;
    }
  }
}

export default cardReducer;

/**
 * Select the card in the customer account
 *
 * @param {Object} state the redux state object
 *
 * @returns {Reducer.Card}
 */
export function selectCard(state) {
  return pick(state.customerAccount.card, [
    'brand',
    'exp_month',
    'exp_year',
    'id',
    'last4',
    'name',
  ]);
}
