import {
  USER_CREATE,
  USER_FETCH,
  USER_FETCH_STATUS,
  USER_UPDATE,
  USER_PASSWORD_CHANGE,
  USER_REPLACE_PETS,
} from 'src/constants/actionTypes';
import { get, post, put, graphql } from 'src/actions/actionCreators';

import changePasswordMutation from './changePassword.graphql';
import userStatusQuery from './userStatus.graphql';

/**
 * Fetch the user data
 * @returns {Promise<{ data: Reducer.User }>}
 */
export const fetchUser = () => get(USER_FETCH, '/user');

export const tryFetchUser = () =>
  get(USER_FETCH, '/user', { ignoreFailure: true });
export const createUser = data => post(USER_CREATE, '/auth/register', data);

/**
 *
 * @param {Object} data - new user data to update;
 * @returns {Promise<{ data: Reducer.User }>}
 */
export const updateUser = data => put(USER_UPDATE, '/user', data);

/**
 * Replace the user's pets in the store with new ones.
 *
 * @export
 * @param {array?} pets
 * @returns
 */
export const replacePets = pets => ({
  type: USER_REPLACE_PETS,
  payload: { pets },
});

/**
 * Update a user's password
 *
 * @returns {Promise<void>} The request promise
 */
export const changePassword = input =>
  graphql(USER_PASSWORD_CHANGE, changePasswordMutation, { input });

export const fetchUserStatus = () =>
  graphql(USER_FETCH_STATUS, userStatusQuery);
