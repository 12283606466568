import { useMutation } from '@apollo/client';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';
import {
  SyncLeadWithUserMutation,
  SyncLeadWithUserMutationVariables,
} from 'src/graphql/tosa.types';
import syncLeadWithUserMutation from './syncLeadWithUserMutation.graphql';

/*
 * Hook to sync a lead with a user.
 * Its used to prevent users recovering later in the signup flow outside of TOSA from having out of sync data.
 */
export function useSyncLeadWithUser() {
  return useMutation<
    SyncLeadWithUserMutation,
    SyncLeadWithUserMutationVariables
  >(syncLeadWithUserMutation, {
    context: {
      endpoint: GraphQLEndpoints.TOSA,
    },
  });
}
