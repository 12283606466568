import { trackDiyRecipesSuccess } from 'src/analytics';

import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/signup/user';
import { selectRequestAttributes } from 'src/reducers/referrer';

import {
  useBodyConditions,
  useEatingStyles,
} from 'src/graphql/queries/usePetConfiguration';
import { useDiyCheckoutQuote } from 'src/pages/SignupCheckoutPage/hooks/useDiyCheckoutQuote';

export function useTrackDiyRecipesSubmit() {
  const user = useSelector(selectUser);
  const { subtotalAmount } = useDiyCheckoutQuote();
  const { bodyConditions } = useBodyConditions();
  const { eatingStyles } = useEatingStyles();
  const requestAttributes = useSelector(selectRequestAttributes);

  const trackDiyRecipesSubmit = () => {
    trackDiyRecipesSuccess({
      user,
      subtotalAmount,
      configuration: { bodyConditions, eatingStyles },
      requestAttributes,
    });
  };

  return { trackDiyRecipesSubmit };
}
