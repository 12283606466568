import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

export interface RecipeChange {
  originalPrice: number | undefined;
  originalFrequency: number | undefined;
  originalRecipes: string[];
  updatedPrice: number | undefined;
  updatedFrequency: number | undefined;
  updatedRecipes: string[];
}

export function trackSavedSelections(recipeChange: RecipeChange) {
  track(eventNames.edit_fresh_recipes_saved_selections, {
    recipeChange: recipeChange,
  });
}
