import { NodeNames } from '@farmersdog/constants';
import {
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

import { NodeRegistry } from '../blueprint/types';
import { getNodeById, getNodeNameAndPosition } from '../blueprint/utils';
import { UseForm } from '../types';

export enum SearchParams {
  Card = 'card',
  Pet = 'pet',
}

const PER_PET_PATHS = [PATH_SIGNUP_PETS, PATH_SIGNUP_RECIPES];

interface getSignupRouteArgs {
  registry: NodeRegistry | undefined;
  current: string | undefined;
  getValues: UseForm['getValues'];
}
export function getSignupRoute({
  registry,
  current,
  getValues,
}: getSignupRouteArgs) {
  if (registry && current) {
    const search = new URLSearchParams();

    let pathname = '';
    const node = getNodeById(current, registry);
    if (node?.route) {
      pathname = node.route;
      const { name, position } = getNodeNameAndPosition(node.name);

      if (PER_PET_PATHS.includes(pathname)) {
        const petNameField = `${NodeNames.Name}-${position}` as const;
        const petName = getValues(petNameField);

        search.append(SearchParams.Pet, petName ?? '');
      }

      search.append(SearchParams.Card, name);
    }

    return `${pathname}?${search.toString()}`;
  }

  return;
}
