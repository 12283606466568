import { session } from '@farmersdog/utils';

export const HAS_CHECKED_OUT = 'hasCheckedOut';

export function getHasCheckedOut(): boolean {
  const sessionValue = session.getItem(HAS_CHECKED_OUT);
  let parsedValue: boolean = false;

  if (sessionValue) {
    parsedValue = JSON.parse(sessionValue) as boolean;
  }
  return typeof parsedValue === 'boolean' ? parsedValue : false;
}

export function setHasCheckedOut(): void {
  session.setItem(HAS_CHECKED_OUT, JSON.stringify(true));
}
