import shouldBeFoodJpg from './should-be-food.jpg';
import shouldBeFoodWebP from './should-be-food.webp';

import dentalDiseaseGraphPng from './dental-disease-graph.png';
import dentalDiseaseGraphWebP from './dental-disease-graph.webp';
import dentalDiseaseGraphJp2 from './dental-disease-graph.jp2';

export const shouldBeFoodSources = [shouldBeFoodWebP, shouldBeFoodJpg];

export const dentalDiseaseSources = [
  dentalDiseaseGraphWebP,
  dentalDiseaseGraphJp2,
  dentalDiseaseGraphPng,
];
