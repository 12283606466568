import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

interface TrackRecipesSurpriseHigherDiscountArgs {
  oldCode: string;
  newCode: string;
}

export function trackRecipesSurpriseHigherDiscount({
  oldCode,
  newCode,
}: TrackRecipesSurpriseHigherDiscountArgs) {
  track(segmentTrackEvents.signup_surprise_higher_discount, {
    oldCode,
    newCode,
    page: 'recipes',
  });
}
