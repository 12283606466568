import { Text } from '@farmersdog/corgi-x';
import { Lock } from '@farmersdog/corgi-x/icons';

export function SecurePaymentMessage() {
  return (
    <Text variant="heading-12" as="p" vSpacing="none">
      <Lock size="1em" style={{ verticalAlign: 'middle' }} /> Payments are
      securely handled through Stripe.
    </Text>
  );
}
