import { useTosaUserFeature } from '../../../hooks';
import { FeatureName } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface UseGrainsQuestionExperimentArgs {
  useFeature: UseFeatureHook;
}

export const grainsQuestionLabel =
  '%pronoun-subject% gets %input% treats or scraps.';

export function useGrainsQuestionExperiment({
  useFeature,
}: UseGrainsQuestionExperimentArgs) {
  const { treatment: grainsQuestionTreatment, isReady } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_GRAINS_QUESTION,
  });

  const shouldShowGrainsQuestion = grainsQuestionTreatment === 'on';

  return {
    shouldShowGrainsQuestion,
    isReady,
  };
}
