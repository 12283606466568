import { Fragment } from 'react';

import { paths } from '@farmersdog/constants';

import { BranchNode } from '../../blueprint/types';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import { PetNameNavbar } from '../../components/shared';
import { TOSAComponentInput } from '../../types';
import { getPetNamesFromFormEntries } from '../../utils';
import { Node } from '../schema/Node';

/*
  This form wrapper is used to wrap all branches within the /pets flow.
  This can be used for any styling that is unique to/consistent across the /pets flow.
*/

export function PetFormWrapper(props: TOSAComponentInput<BranchNode>) {
  const { formNavigation, node } = props;

  const firstPetCard = getFirstPetCardName(props.formSteps);

  const currentNodeName = node.name;
  const formValues = props.formMethods.getValues();
  const { currentPetName, petNames } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName,
  });

  const petNavbarData = petNames.map((name, index) => {
    const firstPetCardId = getFirstPerPetCardId(props.formSteps, index);
    const previousBranchIncomplete =
      !props.progress.getPreviousBranchComplete(firstPetCardId);
    const previousBranchBlocked =
      props.progress.getPreviousBranchBlocked(firstPetCardId);

    const disabled = previousBranchIncomplete || previousBranchBlocked;

    return {
      name,
      disabled,
    };
  });

  return (
    <>
      {node.children.map(childNode => {
        return childNode.__self__ === formNavigation.current ? (
          <Fragment key={'pet-' + childNode.__self__}>
            <PetNameNavbar
              petNavbarData={petNavbarData}
              firstPetCard={firstPetCard}
              currentPetName={currentPetName}
            />
            <Node {...props} node={childNode} key={childNode.__self__} />
          </Fragment>
        ) : null;
      })}
    </>
  );
}

function getFirstPetCardName(formSteps: BranchNode[]): string {
  const firstPetStep = formSteps.find(formStep => {
    return formStep.route === paths.PATH_SIGNUP_PETS;
  });

  const nameAndPosition = firstPetStep
    ? getNodeNameAndPosition(firstPetStep.name)
    : null;

  return nameAndPosition?.name ?? '';
}

function getFirstPerPetCardId(
  formSteps: BranchNode[],
  petIndex: number
): string {
  const firstPerPetStep = [...formSteps].find(formStep => {
    const { position } = getNodeNameAndPosition(formStep.name);

    return (
      formStep.route === paths.PATH_SIGNUP_PETS &&
      position === String(petIndex + 1)
    );
  });

  return firstPerPetStep?.__self__ ?? '';
}
