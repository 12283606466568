import { useQuery } from '@apollo/client';
import { PaymentRetryOrderDataQuery } from './PaymentRetryOrderData.cgs.generated-types';
import PaymentRetryOrderData from './PaymentRetryOrderData.cgs.graphql';

/**
 * Retrieve Payment Retry data based on next Order.
 */
export function usePaymentRetryOrderData() {
  return useQuery<PaymentRetryOrderDataQuery>(PaymentRetryOrderData, {
    context: { endpoint: 'cgs' },
  });
}
