import { events, track } from 'src/analytics';

export enum TrackDiyCadenceChangeContext {
  SignupRecipes = 'signup_recipes',
  SignupCheckout = 'signup_checkout',
  AccountMeals = 'account_meals',
  AccountRecommendation = 'account_recommendation',
}

type TrackDiyCadenceChangeSuccessProperties = {
  old_cadence: number;
  new_cadence: number;
  context: TrackDiyCadenceChangeContext;
};

type TrackDiyCadenceChangeSuccessInput = {
  old_cadence: number;
  new_cadence: number;
  context: TrackDiyCadenceChangeContext;
};

export function trackDiyCadenceChangeSuccess(
  input: TrackDiyCadenceChangeSuccessInput
) {
  const properties: TrackDiyCadenceChangeSuccessProperties = {
    old_cadence: input.old_cadence,
    new_cadence: input.new_cadence,
    context: input.context,
  };

  return track(events.diy_cadence_change_success, properties);
}
