import { useEffect, useState } from 'react';
import { logSplitClientMismatches } from 'src/abTesting/logSplitClientMismatches';
import { useAnonymousIdPerformanceLogs } from '../useAnonymousIdPerformanceLogs';

export function useObserveSplitClientCache() {
  const [didLog, setDidLog] = useState(false);
  const { cachedSplitClients, isLoggingEnabled } =
    useAnonymousIdPerformanceLogs();

  useEffect(() => {
    if (isLoggingEnabled && !didLog) {
      logSplitClientMismatches(cachedSplitClients);
      setDidLog(true);
    }
  }, [isLoggingEnabled, cachedSplitClients, didLog]);
}
