import classNames from 'classnames';
import { ReactNode } from 'react';

import { GridContainer } from '@farmersdog/corgi-x';

import { useSignupSpacingExperiment } from '../../hooks';
import { UseFeatureHook } from '../../types';

import styles from './TosaLayout.module.css';

export interface TosaLayoutProps {
  children: ReactNode;
  useFeature: UseFeatureHook;
}

export function TosaLayout({ useFeature, children }: TosaLayoutProps) {
  const { isTreatmentOn: isSignupSpacingExperimentOn } =
    useSignupSpacingExperiment({ useFeature });

  return (
    <main>
      <GridContainer>
        <div
          className={classNames(styles.section, {
            [styles.extraMargin]: isSignupSpacingExperimentOn,
          })}
        >
          {children}
        </div>
      </GridContainer>
    </main>
  );
}
