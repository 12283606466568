/* CH#49502: Used by CVR */
import { useSelector } from 'react-redux';
import { Nowrap } from '@farmersdog/corgi-x';

import { selectReferrer } from 'src/reducers/referrer';
import { DiscountType } from 'src/graphql/enums';
import {
  getDiscountAmountCopy,
  getDiscountTypeAndAmount,
} from 'src/utils/referral';
import { Referrer } from 'src/hooks/useReferrer';

export function DiscountCTAText() {
  const referrer = useSelector(selectReferrer) as Referrer;

  const { discountAmount, discountType } = getDiscountTypeAndAmount(referrer);
  const discountAmountCopy = getDiscountAmountCopy({
    discountAmount,
    discountType,
  });

  if (discountAmount === 0) {
    return <span>Create your plan</span>;
  }

  if (discountType === DiscountType.percentage && discountAmount === 100) {
    return (
      <span>
        Redeem <Nowrap>free trial</Nowrap>
      </span>
    );
  }

  return (
    <span>
      Redeem <Nowrap>{discountAmountCopy}</Nowrap>
    </span>
  );
}
