import { CgsActivityLevel } from '../../graphql/types.cgs';
import activePNG from './active.png';

import notVeryActivePNG from './not-very-active.png';

import proAthletePNG from './pro-athlete.png';

import veryActivePNG from './very-active.png';

export const activityLevelResource = {
  [CgsActivityLevel.Active]: [activePNG],
  [CgsActivityLevel.NotVeryActive]: [notVeryActivePNG],
  [CgsActivityLevel.ProAthlete]: [proAthletePNG],
  [CgsActivityLevel.VeryActive]: [veryActivePNG],
};
