import * as Apollo from '@apollo/client';

import { useMutation } from '../../services/apollo';
import { RegisterLeadMutationVariables, RegisterLeadMutation } from '../types';

import registerLead from './registerLead.graphql';

type Options = Apollo.MutationHookOptions<
  RegisterLeadMutation,
  RegisterLeadMutationVariables
>;

export function useRegisterLead(options?: Options) {
  return useMutation<RegisterLeadMutation, RegisterLeadMutationVariables>(
    registerLead,
    options
  );
}
