import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { browserOnly } from './browserOnly';

import type { Opaque } from 'type-fest';

interface UseUserEngagementHandlerArgs {
  passiveSupported?: boolean;
  onUserEngagement: (version: EngagementTrackerVersion) => void;
}

export type EngagementTrackerVersion = Opaque<number>;
export const ENGAGEMENT_TRACKER_VERSION = 1 as EngagementTrackerVersion;

/**
 * Any changes to the DOM event listeners in this hook require two additional steps:
 *
 * 1. Increment the value of ENGAGEMENT_TRACKER_VERSION by 1
 * 2. Update https://farmersdog.slab.com/posts/segment-sessions-engagement-tracker-ydu33wyr with the new version and changes you are making
 */

export function useUserEngagementHandler({
  passiveSupported,
  onUserEngagement,
}: UseUserEngagementHandlerArgs) {
  useEffect(() => {
    const handleUserEngagement = debounce(
      () => onUserEngagement(ENGAGEMENT_TRACKER_VERSION),
      500
    );

    browserOnly((window, document) => {
      const listenerOptions = passiveSupported ? { passive: true } : false;

      window.addEventListener('load', handleUserEngagement);
      window.addEventListener('scroll', handleUserEngagement, listenerOptions);
      document.body.addEventListener(
        'mousemove',
        handleUserEngagement,
        listenerOptions
      );
      document.body.addEventListener(
        'mousedown',
        handleUserEngagement,
        listenerOptions
      );
      document.body.addEventListener(
        'keydown',
        handleUserEngagement,
        listenerOptions
      );
    });

    return () => {
      window.removeEventListener('load', handleUserEngagement);
      window.removeEventListener('scroll', handleUserEngagement);
      document.body.removeEventListener('mousemove', handleUserEngagement);
      document.body.removeEventListener('mousedown', handleUserEngagement);
      document.body.removeEventListener('keydown', handleUserEngagement);
    };
  }, [onUserEngagement, passiveSupported]);
}
