import PropTypes from 'prop-types';

import classNames from 'classnames';
import './ActivityIndicator.scss';

/**
 * Render a spinner as activity indicator.
 */
export default function ActivityIndicator({
  inverted = false,
  block = false,
  size = 18,
}) {
  const className = classNames('ActivityIndicator', { inverted, block });
  const bounceStyle = {
    width: size,
    height: size,
  };
  return (
    <div className={className} style={{ height: size }}>
      <div className="ActivityIndicator-bounce1" style={bounceStyle} />
      <div className="ActivityIndicator-bounce2" style={bounceStyle} />
      <div className="ActivityIndicator-bounce3" style={bounceStyle} />
    </div>
  );
}

ActivityIndicator.propTypes = {
  /** Change the color to white */
  inverted: PropTypes.bool,
  /** Change the height of the spinner */
  size: PropTypes.number,
  /** Display as block element */
  block: PropTypes.bool,
};
