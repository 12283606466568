import EatingStyleCanBePickyExpanded from './eating-style-can-be-picky-expanded.png';
import EatingStyleGoodEaterExpanded from './eating-style-good-eater-expanded.png';
import EatingStyleVeryPickyExpanded from './eating-style-very-picky-expanded.png';
import EatingStyleWillEatAnythingExpanded from './eating-style-will-eat-anything-expanded.png';

export enum EatingStyle {
  VeryPicky = 'VeryPicky',
  CanBePicky = 'CanBePicky',
  GoodEater = 'GoodEater',
  WillEatAnything = 'WillEatAnything',
}

export const eatingStyleResources = {
  [EatingStyle.VeryPicky]: [EatingStyleVeryPickyExpanded],
  [EatingStyle.CanBePicky]: [EatingStyleCanBePickyExpanded],
  [EatingStyle.GoodEater]: [EatingStyleGoodEaterExpanded],
  [EatingStyle.WillEatAnything]: [EatingStyleWillEatAnythingExpanded],
};
