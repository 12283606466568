import { Script } from 'src/components/Script';

export enum X_Events {
  Lead = 'tw-o28pe-odnpi',
  Add_To_Cart = 'tw-o28pe-odnpn',
  Purchase = 'tw-o28pe-odnog',
}

const PIXEL_ID = 'o28pe';

export type XSdk = (
  command: 'event',
  eventName: X_Events,
  properties?: Record<string, unknown>
) => void;

declare global {
  interface Window {
    // @ts-expect-error this is because this enum is not equal to the pixel package enum in the dumb eyes of TS.
    // this all needs a real refactor
    twq?: XSdk;
  }
}

// X (Twitter)
export function XScript() {
  return (
    <Script type="text/javascript">
      {`<!-- Twitter conversion tracking base code -->
      !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      twq('config','${PIXEL_ID}');
      <!-- End Twitter conversion tracking base code -->`}
    </Script>
  );
}
