import { RecipeNames } from '@farmersdog/constants';

import packageBeefPng from './package-beef.png';
import packageBeefWebP from './package-beef.webp';
import packageChickenGreensPng from './package-chicken-greens.png';
import packageChickenGreensWebP from './package-chicken-greens.webp';
import packageChickenOatsPng from './package-chicken-oats.png';
import packageChickenOatsWebP from './package-chicken-oats.webp';
import packagePorkOatsPng from './package-pork-oats.png';
import packagePorkOatsWebP from './package-pork-oats.webp';
import packagePorkPng from './package-pork.png';
import packagePorkWebP from './package-pork.webp';
import packageTurkeyPng from './package-turkey.png';
import packageTurkeyWebP from './package-turkey.webp';

export type RecipeImagesType = {
  [key in RecipeNames | 'NO_RECIPE']: string[];
};

export const recipeImages: RecipeImagesType = {
  [RecipeNames.PORK]: [packagePorkWebP, packagePorkPng],
  [RecipeNames.TURKEY]: [packageTurkeyWebP, packageTurkeyPng],
  [RecipeNames.BEEF]: [packageBeefWebP, packageBeefPng],
  [RecipeNames.BEEF_MLENTILS]: [packageBeefWebP, packageBeefPng],
  [RecipeNames.CHICKEN_AND_GREENS]: [
    packageChickenGreensWebP,
    packageChickenGreensPng,
  ],
  [RecipeNames.CHICKEN_CHIA]: [
    packageChickenGreensWebP,
    packageChickenGreensPng,
  ],
  [RecipeNames.CHICKENL]: [packageChickenGreensWebP, packageChickenGreensPng],
  [RecipeNames.CHICKEN_OATS]: [packageChickenOatsWebP, packageChickenOatsPng],
  [RecipeNames.CHICKEN_OATS_COLLARDS]: [
    packageChickenOatsWebP,
    packageChickenOatsPng,
  ],
  [RecipeNames.PORK_OATS]: [packagePorkOatsWebP, packagePorkOatsPng],
  NO_RECIPE: [packageTurkeyWebP, packageTurkeyPng],
};
