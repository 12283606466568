export const CHICKEN = {
  label: 'Chicken',
  value: 'chicken',
};

export const DUCK = {
  label: 'Duck',
  value: 'duck',
};

export const LAMB = {
  label: 'Lamb',
  value: 'lamb',
};

export const SHEEP = {
  label: 'Sheep',
  value: 'sheep',
};

export const GOAT = {
  label: 'Goat',
  value: 'goat',
};

export const BISON = {
  label: 'Bison',
  value: 'bison',
};

export const FISH = {
  label: 'Fish',
  value: 'fish',
};

export const FISH_MEAT_BLEND = {
  label: 'Fish and meat blend (e.g. salmon and turkey)',
  value: 'fish-meat-blend',
};

export const MEAT_MEAT_BLEND = {
  label: 'Meat and meat blend (e.g. beef and chicken)',
  value: 'meat-meat-blend',
};

export const OTHER_NEW_PROTEIN = {
  label: 'Other',
  value: 'other',
};

const NEW_PROTEIN_OPTIONS = [
  CHICKEN,
  DUCK,
  LAMB,
  SHEEP,
  GOAT,
  BISON,
  FISH,
  FISH_MEAT_BLEND,
  MEAT_MEAT_BLEND,
  OTHER_NEW_PROTEIN,
];

export default NEW_PROTEIN_OPTIONS;
