import { createMachine } from 'xstate';
import { AddDogState } from './AddDogState';
import { AddDogEvent } from './AddDogEvent';

export const addDogMachine = createMachine({
  id: 'add-dog',
  initial: AddDogState.Name,
  states: {
    [AddDogState.Name]: {
      on: {
        [AddDogEvent.Next]: { target: AddDogState.Essential },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.Essential]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.Name },
        [AddDogEvent.Next]: { target: AddDogState.BodyCondition },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.BodyCondition]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.Essential },
        [AddDogEvent.Next]: { target: AddDogState.Weight },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.Weight]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.BodyCondition },
        [AddDogEvent.Next]: { target: AddDogState.ActivityLevel },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.ActivityLevel]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.Weight },
        [AddDogEvent.Next]: { target: AddDogState.EatingStyle },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.EatingStyle]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.ActivityLevel },
        [AddDogEvent.Next]: { target: AddDogState.HealthIssues },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.HealthIssues]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.EatingStyle },
        [AddDogEvent.Next]: { target: AddDogState.PetDietIssues },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.PetDietIssues]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.HealthIssues },
        [AddDogEvent.Next]: { target: AddDogState.Review },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    [AddDogState.Review]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.PetDietIssues },
        [AddDogEvent.Next]: { target: AddDogState.Success },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
        [AddDogEvent.SelectRecipes]: { target: AddDogState.Recipes },
      },
    },
    [AddDogState.Recipes]: {
      on: {
        [AddDogEvent.Previous]: { target: AddDogState.Review },
        [AddDogEvent.Next]: { target: AddDogState.Review },
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },

    [AddDogState.Success]: {
      on: {
        [AddDogEvent.Close]: { target: AddDogState.CloseAddDog },
      },
    },
    /**
     * We want to properly close the machine when exiting the workflow
     * The current designs do not have a closing UI
     */
    [AddDogState.CloseAddDog]: {
      type: 'final',
    },
  },
});
