import { PageWidth } from '@farmersdog/corgi-x';
import { useContext, ReactNode, createContext } from 'react';
import { useFeature } from 'src/abTesting';
import { CVR_CTA_ACCESSIBILITY_HOMEPAGE } from 'src/abTesting/features';
import styles from './accessibleStyles.module.css';

interface ContextProviderProps {
  children: ReactNode;
}

interface AccessibilityThemeContextInterface {
  isAccessibilityThemeOn: boolean;
  accessibilityThemeStyles: {
    [className: string]: string;
  };
  KaleBackgroundWrapper: ({
    children,
    addBackgroundPadding,
  }: BackgroundWrapperProps) => JSX.Element;
  OysterBackgroundWrapper: ({
    children,
    addBackgroundPadding,
  }: BackgroundWrapperProps) => JSX.Element;
}

interface BackgroundWrapperProps {
  children: React.ReactNode;
  addBackgroundPadding?: boolean;
}

interface BackgroundWrapperBaseProps extends BackgroundWrapperProps {
  color: 'kale' | 'oyster';
}

const BackgroundWrapper = ({
  children,
  color,
  addBackgroundPadding = true,
}: BackgroundWrapperBaseProps) => {
  return (
    <section
      className={
        color === 'oyster' ? styles.oysterBackground : styles.kaleBackground
      }
    >
      <PageWidth>
        <div className={addBackgroundPadding ? styles.backgroundPadding : ''}>
          {children}
        </div>
      </PageWidth>
    </section>
  );
};
const KaleBackgroundWrapper = (props: BackgroundWrapperProps) => (
  <BackgroundWrapper color="kale" {...props} />
);
const OysterBackgroundWrapper = (props: BackgroundWrapperProps) => (
  <BackgroundWrapper color="oyster" {...props} />
);

const AccessibilityThemeContext =
  createContext<AccessibilityThemeContextInterface>({
    isAccessibilityThemeOn: false,
    accessibilityThemeStyles: {},
    KaleBackgroundWrapper,
    OysterBackgroundWrapper,
  });

export const AccessibilityThemeContextProvider = ({
  children,
}: ContextProviderProps) => {
  const isAccessibilityExperimentOn =
    useFeature(CVR_CTA_ACCESSIBILITY_HOMEPAGE).treatment === 'on';

  return (
    <AccessibilityThemeContext.Provider
      value={{
        isAccessibilityThemeOn: isAccessibilityExperimentOn,
        accessibilityThemeStyles: isAccessibilityExperimentOn ? styles : {},
        KaleBackgroundWrapper,
        OysterBackgroundWrapper,
      }}
    >
      {children}
    </AccessibilityThemeContext.Provider>
  );
};

export function useAccessibilityThemeContext() {
  return useContext(AccessibilityThemeContext);
}
