import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_HOME, PATH_SIGNUP_CHECKOUT } from '@farmersdog/constants/paths';
import { trackAudacyPageView } from './trackAudacyPageView';

export const trackAudacyPageViewRoutes = [PATH_HOME, PATH_SIGNUP_CHECKOUT];

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for an audacy page view event.
 */
export function useTrackAudacyPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (trackAudacyPageViewRoutes.includes(pathname)) {
      trackAudacyPageView();
    }
  }, [pathname]);
}
