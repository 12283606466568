import {
  useState,
  useContext,
  ReactNode,
  createContext,
  useCallback,
} from 'react';
import noop from 'lodash/noop';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { StripeUserInfo } from './useSubmit';

export type ExpressPayAttemptInfo = {
  userInfo: StripeUserInfo;
  tokenId: PaymentRequestTokenEvent['token']['id'];
  walletName: PaymentRequestTokenEvent['walletName'];
};

type ExpressPayAttemptValue = (
  | {
      expressPayAttemptInfo: undefined;
      isExpressPayAttempt: false;
    }
  | {
      expressPayAttemptInfo: ExpressPayAttemptInfo;
      isExpressPayAttempt: true;
    }
) & {
  createExpressPayAttempt: (info: ExpressPayAttemptInfo | undefined) => void;
  closeExpressPayAttempt: () => void;
};

const ExpressPayAttemptContext = createContext<ExpressPayAttemptValue>({
  expressPayAttemptInfo: undefined,
  isExpressPayAttempt: false,
  createExpressPayAttempt: noop,
  closeExpressPayAttempt: noop,
});

interface ExpressPayAttemptProviderProps {
  children: ReactNode;
}

export function ExpressPayAttemptProvider({
  children,
}: ExpressPayAttemptProviderProps) {
  const [expressPayAttemptInfo, setExpressPayAttemptInfo] = useState<
    ExpressPayAttemptInfo | undefined
  >();

  const closeExpressPayAttempt = useCallback(() => {
    setExpressPayAttemptInfo(undefined);
  }, []);

  return (
    <ExpressPayAttemptContext.Provider
      value={{
        // Defining each parameter separately here based on expressPayAttemptInfo
        // makes TS fail and would force us to use a cast
        ...(expressPayAttemptInfo
          ? { expressPayAttemptInfo, isExpressPayAttempt: true }
          : { expressPayAttemptInfo, isExpressPayAttempt: false }),
        createExpressPayAttempt: setExpressPayAttemptInfo,
        closeExpressPayAttempt,
      }}
    >
      {children}
    </ExpressPayAttemptContext.Provider>
  );
}

export function useExpressPayAttempt() {
  return useContext(ExpressPayAttemptContext);
}
