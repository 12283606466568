import { useEffect, useState } from 'react';

import { browserOnly } from '@farmersdog/utils';

import { useGlobalLightbox, LightboxId } from '../../../hooks';

export function useHotKeys() {
  const { open, isOpen } = useGlobalLightbox({
    id: LightboxId.TosaDevTools,
  });
  const [opening, setOpening] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  useEffect(() => {
    browserOnly(window => {
      window.document.addEventListener('keydown', e => {
        const keyName = e.key;

        if (e.isComposing || keyName === 'Control') {
          // do not alert when only Control key is pressed.
          return;
        }

        if (e.ctrlKey && e.altKey && e.code === 'KeyS' && !opening) {
          e.preventDefault();
          setOpening(true);
        }
      });
    });
    // We should remove the event listener but there seems to be a bug, the
    // effect adds then immediately removes the event listener so we never
    // listen.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This hook ensures that if a user holds down the hotkey we don't call open
    // multiple times resulting in the modal rapidly opening and closing
    const openModal = async () => {
      // TODO: This type needs to fixed in CORGI
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await open();
      setOpening(false);
      setHasBeenOpened(true);
    };

    if (opening && !isOpen) {
      void openModal();
    }
  }, [isOpen, open, opening]);

  return { hasBeenOpened };
}
