import { browserOnly } from '../browserOnly';

/**
 * In order to set a cookie that can be read across all subdomains, we need to explicitly set the primary domain
 *
 * There is no reliable regex that I'm aware of, and we only have 3 environments, so we are choosing to use an allowlist approach
 * There is a third-party source with an SDK that we could leverage but that felt quite heavy
 */

export function getCookieDomain(): string {
  const prodFallback = 'thefarmersdog.com';
  const tfdDomains = ['localhost', 'tfd.engineering', 'thefarmersdog.com'];

  return browserOnly(
    window => {
      const hostname = window.location.hostname;

      for (let i = 0; i < tfdDomains.length; i++) {
        if (hostname.includes(tfdDomains[i])) {
          return tfdDomains[i];
        }
      }

      return prodFallback;
    },
    () => prodFallback
  );
}
