import { ChangeEvent } from 'react';
import { Checkbox, Fieldset, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import styles from './EmailSmsCheckbox.module.css';

interface EmailSmsCheckboxProps {
  content: { title: string; copy: string };
  emailCheck: boolean;
  smsCheck: boolean;
  onChange: (e: ChangeEvent) => void;
  fieldName: string;
}

export function EmailSmsCheckbox(props: EmailSmsCheckboxProps) {
  return (
    <Fieldset legend={props.content.title}>
      <Text variant="article-16">{props.content.copy}</Text>
      <Grid className={styles.checkboxContainer}>
        <GridItem></GridItem>
        <GridItem lg={6} sm={6} xs={6}>
          <Checkbox
            label="Email"
            disabled={true}
            checked={props.emailCheck}
            readOnly={true}
            data-checked={props.emailCheck}
          />
        </GridItem>
        <GridItem lg={6} sm={6} xs={6}>
          <Checkbox
            label="Text"
            checked={props.smsCheck}
            onChange={props.onChange}
            name={props.fieldName}
            data-checked={props.smsCheck}
          />
        </GridItem>
      </Grid>
    </Fieldset>
  );
}
