import { events, track } from 'src/analytics';

export enum TrackCalibrateRescheduleAction {
  Accept = 'accept',
  Reject = 'reject',
}

export function trackCalibrateReschedule(
  action: TrackCalibrateRescheduleAction
) {
  track(events.calibration_reschedule, { action });
}
