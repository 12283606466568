import { BranchNode } from '../blueprint/types';
import { UseForm } from '../types';
import { getPetPositionByPetName } from '../utils/getPetPositionByPetName';

interface GetFormStepFromSearchParamsProps {
  formSteps: BranchNode[];
  cardName: string;
  petName: string | null;
  getValues: UseForm['getValues'];
}

export function getFormStepFromSearchParams({
  formSteps,
  cardName,
  petName,
  getValues,
}: GetFormStepFromSearchParamsProps) {
  const petIndex = petName
    ? getPetPositionByPetName({ formValues: getValues(), petName })
    : '';

  const nameToFind = petIndex ? `${cardName}-${petIndex}` : cardName;
  return formSteps.find(step => {
    return step.name === nameToFind;
  });
}
