import {
  AccessibleContent,
  Button,
  Grid,
  GridItem,
  Strikeout,
  Text,
} from '@farmersdog/corgi-x';

import styles from './SubmitButton.css';

interface SubmitButtonProps {
  hasSelectedRecipes: boolean;
  onClick: () => void;
  formSubmitting: boolean;
  petDailySubtotal: string;
  petDailyDiscountedSubtotal: string;
  discountPercentage: number | null;
}

export function SubmitButton(props: SubmitButtonProps) {
  const {
    hasSelectedRecipes,
    onClick,
    formSubmitting,
    petDailySubtotal,
    petDailyDiscountedSubtotal,
    discountPercentage,
  } = props;

  const accesibleText = `Subtotal was ${petDailySubtotal} per day, it’s now ${petDailyDiscountedSubtotal} per day.`;

  return (
    <div className={styles.innerContainer}>
      <Grid>
        <GridItem justifyContent="center">
          {hasSelectedRecipes && petDailyDiscountedSubtotal && (
            <AccessibleContent
              accessibleText={accesibleText}
              visualContent={
                <Text
                  variant="heading-16"
                  color="charcoal-3"
                  bold
                  className={styles.priceText}
                >
                  First Box:{' '}
                  <>
                    <Strikeout className={styles.pricePerDay}>
                      <Text variant="heading-16">{petDailySubtotal}/day</Text>
                    </Strikeout>{' '}
                  </>
                  <Text variant="heading-16" as="span" color="kale-2" bold>
                    {petDailyDiscountedSubtotal}
                    /day{' '}
                  </Text>
                  <Text
                    variant="heading-16"
                    as="span"
                    color="kale-2"
                    bold
                    className={styles.pricePerDay}
                    id="recipes-select-plan-discount"
                  >
                    ({discountPercentage ?? ''}% OFF)
                  </Text>
                </Text>
              }
            />
          )}
          {!hasSelectedRecipes && (
            <Text
              as="span"
              variant="article-16"
              color="charcoal-3"
              fontStyle="italic"
            >
              Select Recipes to Begin Building Plan
            </Text>
          )}
        </GridItem>
        <GridItem justifyContent="center">
          <Button
            className={styles.button}
            type="submit"
            aria-label={
              !hasSelectedRecipes
                ? 'Select Recipes to Begin Building Plan'
                : 'Continue'
            }
            aria-disabled={!hasSelectedRecipes || formSubmitting}
            disabled={formSubmitting}
            loading={formSubmitting}
            onClick={onClick}
          >
            Continue
          </Button>
        </GridItem>
      </Grid>
    </div>
  );
}
