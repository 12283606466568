import moment from 'moment';
import { WeekdayNumber } from 'src/constants/time';

/**
 * Get the estimated arrival window given a ship day.
 */
export function getDeliveryWindow(
  shipDay: moment.MomentInput
): [moment.Moment, moment.Moment] {
  const earliest = moment(shipDay).day(WeekdayNumber.Sunday);
  const latest = moment(shipDay).day(WeekdayNumber.Saturday);
  return [earliest, latest];
}
