import { track } from '@farmersdog/analytics';

import { ProductLineType } from '../../graphql/types';
import { segmentTrackEvents } from '../segmentTrackEventNames';

export function trackToggleProductLineClick(productLine: ProductLineType) {
  track(segmentTrackEvents.toggle_product_line_click, {
    productLine,
  });
}
