import { browserOnly } from '@farmersdog/utils';

import { fastdom } from '../fastdom';

export interface ScrollFn {
  (): void;
}

export class Scroller {
  mutation?: ScrollFn;

  private clearNextScroll() {
    if (this.mutation) {
      fastdom.clear(this.mutation);
      this.mutation = undefined;
    }
  }

  private queueNextScroll(fn: ScrollFn) {
    if (this.mutation) {
      this.clearNextScroll();
    }

    this.mutation = fastdom.mutate(() => {
      fn();
      this.clearNextScroll();
    });
  }

  scrollTo(options?: ScrollToOptions) {
    this.queueNextScroll(() => {
      browserOnly(window => {
        window.scrollTo(options);
      });
    });
  }

  scrollBy(options?: ScrollToOptions) {
    this.queueNextScroll(() => {
      browserOnly(window => {
        window.scrollBy(options);
      });
    });
  }
}
