import { Helmet } from 'react-helmet-async';
import { PATH_HOME } from '@farmersdog/constants/paths';
import config from '../../config/config';
import helmet from '../../config/helmet';

const publicUrl = config.get('app.publicUrl');
const facebookAppId = config.get('facebook.appId');

export function Head() {
  return (
    <>
      <Helmet
        titleTemplate={helmet.titleTemplate}
        defaultTitle={helmet.defaultTitle}
      >
        <link rel="canonical" href={`${publicUrl}${PATH_HOME}`} />
        <meta itemProp="name" content={helmet.siteName} />
        <meta itemProp="description" content={helmet.description} />
        <meta itemProp="image" content={helmet.image} />
        <meta itemProp="thumbnailUrl" content={helmet.image} />
        <meta itemProp="url" content={publicUrl} />
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={helmet.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={helmet.description} />
        <meta name="twitter:image" content={helmet.image} />
        <meta name="twitter:title" content={helmet.defaultTitle} />
        <meta name="twitter:url" content={publicUrl} />
        <meta property="og:description" content={helmet.description} />
        <meta property="og:image" content={helmet.ogImage} />
        <meta property="og:site_name" content={helmet.siteName} />
        <meta property="og:title" content={helmet.defaultTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={publicUrl} />
        <meta property="fb:app_id" content={facebookAppId} />
      </Helmet>
      {/* 
      TODO: Migrate Html Scripts
      https://app.shortcut.com/farmersdog/story/79410/paw-migrate-html-scripts
      <SegmentScript />
      <DatadogScript /> 
      */}
    </>
  );
}
