import { errorCopy } from '../../errors';
import { Text } from '@farmersdog/corgi-x';

export interface AddressValidationErrorProps {
  copy1?: string;
  copy2?: string;
}

/**
 * Render an error for an address that fails to meet our validation standards.
 *
 * @param copy1 - customer-facing error copy rendered in a Text <p> component
 * @param copy2 - customer-facing error copy rendered in a Text <p> component
 */
export function AddressValidationError({
  copy1 = errorCopy.AMBIGUOUS_ADDRESS_COPY_1,
  copy2 = errorCopy.AMBIGUOUS_ADDRESS_COPY_2,
}: AddressValidationErrorProps) {
  return (
    <>
      <Text as="p" variant="heading-16" color="charcoal-3">
        {copy1}
      </Text>
      <Text as="p" variant="heading-16" color="charcoal-1">
        {copy2}
      </Text>
    </>
  );
}
