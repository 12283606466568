import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectPets,
  selectIndexOfFirstActivePet,
} from 'src/reducers/customerAccount/user';

import getPetId from './getPetId';

/**
 * Get the petId based on the current url and pets in the customer account reducer
 */
function useAccountPetLocation() {
  const { petName } = useParams();
  const activeIndex = useSelector(selectIndexOfFirstActivePet);
  const pets = useSelector(selectPets);
  return getPetId(petName, pets, activeIndex);
}

export default useAccountPetLocation;
