import { redactEmailsFromPath } from '@farmersdog/utils';
import { trackGa4Event, Ga4StandardEvent } from './trackGa4Event';

/**
 * track a GA4 page view event
 */

type TrackGa4PageViewLocation = Pick<
  Location,
  'pathname' | 'search' | 'hash'
> & { origin: string };

export function trackGa4PageView(location: TrackGa4PageViewLocation): void {
  const { pathname, search, hash, origin } = location;
  const cleanedPath = redactEmailsFromPath(pathname);

  const properties = {
    page_location: `${origin}${cleanedPath}${search}${hash}`,
    page_path: cleanedPath,
  };

  trackGa4Event({ name: Ga4StandardEvent.PageView, properties });
}
