import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';

import { NodeRegistry, BranchNode } from '../blueprint/types';
import {
  Experiments,
  UseForm,
  UseFormNavigateReturn,
  UseSchemaReturn,
} from '../types';
import { getFormStepFromSearchParams } from '../utils/getFormStepFromSearchParams';
import { getSignupRoute, SearchParams } from '../utils/getSignupRoute';

import { useCachedLead } from './useCachedLead';

import type { Location as HistoryLocation } from 'history';

interface UseRouteArgs {
  registry: NodeRegistry | undefined;
  current: string | undefined;
  blueprint: UseSchemaReturn['schema'];
  setFormSteps: UseSchemaReturn['setFormSteps'];
  formNavigation: UseFormNavigateReturn;
  formMethods: UseForm;
  formSteps: BranchNode[];
  experiments: Experiments;
}

export function useRoute({
  registry,
  current,
  blueprint,
  setFormSteps,
  formNavigation,
  formMethods,
  formSteps,
  experiments,
}: UseRouteArgs) {
  const history = useHistory();
  const isSignup = useRouteMatch(PATH_SIGNUP);
  const restoreCachedLead = useCachedLead();

  useEffect(() => {
    const unsubscribe = history.listen(location => {
      const { cardName, petName } = getCardAndPetNameFromLocation(location);
      const formStep = cardName
        ? getFormStepFromSearchParams({
            formSteps,
            cardName,
            petName,
            getValues: formMethods.getValues,
          })
        : undefined;

      const formStepId = formStep?.__self__;

      if (formStepId === current || !formStepId) {
        return;
      }

      // Since history listeners run before our fake formNavigation.subscribe
      // listener we can not propagate the formNavigation listeners when we are
      // syncing step state back to the browser state
      formNavigation.goToStep(formStepId, { propagate: false });

      restoreCachedLead({
        reset: formMethods.reset,
        blueprint,
        setFormSteps,
        experiments,
      });
    });

    return unsubscribe;
  }, [
    current,
    formNavigation,
    formSteps,
    formMethods,
    history,
    restoreCachedLead,
    blueprint,
    setFormSteps,
    experiments,
  ]);

  useEffect(() => {
    const unsubscribe = formNavigation.subscribe(
      (nextCurrent, { routingAction = 'push' } = {}) => {
        if (!isSignup) {
          return;
        }

        const updatedRoute = getSignupRoute({
          registry,
          current: nextCurrent,
          getValues: formMethods.getValues,
        });

        const currentRoute = getRouteFromLocation(window.location);

        if (updatedRoute && updatedRoute !== currentRoute) {
          history[routingAction](updatedRoute);
        }
      }
    );

    return unsubscribe;
  }, [formMethods, formNavigation, history, isSignup, registry, current]);
}

function getRouteFromLocation(location: Location): string {
  return `${location.pathname}${location.search}`;
}

interface CardAndPetName {
  cardName: string | null;
  petName: string | null;
}

function getCardAndPetNameFromLocation(
  location: HistoryLocation
): CardAndPetName {
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const cardName = searchParams.get(SearchParams.Card);
  const petName = searchParams.get(SearchParams.Pet);

  return {
    cardName,
    petName,
  };
}
