import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Logger } from '@farmersdog/logger';

import { trackReferralCode } from 'src/analytics/events/trackReferralCode';

import { selectUser } from 'src/reducers/signup/user';
import { reporter } from 'src/services/reporter';
import {
  fetchReferrer,
  setIsFetchingReferrer,
  hasCalledFetchReferrer,
  updateUserReferrer,
} from 'src/actions/referrer';
import { tryFetchUser } from 'src/actions/user';
import { couponCookie } from '@farmersdog/coupons';
import { useFeature } from 'src/abTesting';
import { CVR_DISABLE_REFERRER_LOOKUP } from 'src/abTesting/features';

import {
  REFERRER_TYPE_PARTNER,
  REFERRER_TYPE_USER,
} from 'src/constants/referrer';
import { useDefaultDiscountExperiment } from '../useDefaultDiscountExperiment/useDefaultDiscountExperiment';
import { getReferrerProperties } from './getReferrerProperties';

const log = new Logger('app:hooks:useReferral');

/**
 * Handles fetching and setting referrer info.
 *
 */
export default function useReferral() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const [fellBackToDefaultDiscount, setFellBackToDefaultDiscount] =
    useState(false);
  const {
    isDefaultDiscountExperimentOn,
    getDefaultDiscount,
    setCouponCookieWithDefaultExperimentCode,
    defaultDiscountExperimentErrorMessage,
  } = useDefaultDiscountExperiment();

  const disableReferrerLookupFeature = useFeature(CVR_DISABLE_REFERRER_LOOKUP);
  const isReferrerLookupDisabled =
    !disableReferrerLookupFeature.isReady ||
    disableReferrerLookupFeature.treatment === 'on';

  const defaultDiscount = getDefaultDiscount();

  useEffect(() => {
    const attemptFetchReferrer = async () => {
      const referrerCookie = couponCookie.get();

      // Fetch user to check if associated with referrer.
      let user;
      try {
        const userResponse = await dispatch(tryFetchUser());
        if (userResponse) {
          user = userResponse.data;
        }
      } catch (err) {
        // Unauthorized errors expected on first visit.
      }

      const { referrerType, referrerId, referrerCode } = getReferrerProperties(
        user,
        referrerCookie,
        {
          referrerCode: defaultDiscount.referrerCode,
          referrerType: defaultDiscount.referrerType,
          referrerAssociatedAt: new Date(),
        },
        isDefaultDiscountExperimentOn
      );

      if (!referrerCode && !referrerId) {
        dispatch(setIsFetchingReferrer(false));
        dispatch(hasCalledFetchReferrer());
        return;
      }

      let refResponse;
      async function handleInvalidCoupon() {
        setFellBackToDefaultDiscount(true);
        // delete invalid referrer cookie
        couponCookie.remove();

        // Fallback to experimental default discount if coupon is expired or disabled (invalid)
        if (isDefaultDiscountExperimentOn) {
          try {
            refResponse = await dispatch(
              fetchReferrer(defaultDiscount.referrerType, {
                code: defaultDiscount.referrerCode,
              })
            );
            setCouponCookieWithDefaultExperimentCode();
          } catch (error) {
            reporter.error(defaultDiscountExperimentErrorMessage, error);
          }
        }
      }

      if (referrerType && (referrerCode || referrerId)) {
        dispatch(setIsFetchingReferrer(true));
        log.debug('Fetching referrer', {
          referrerType,
          referrerCode,
          referrerId,
        });
        try {
          refResponse = await dispatch(
            fetchReferrer(referrerType, {
              id: referrerId,
              code: referrerCode,
            })
          );

          if (refResponse.data?.type === REFERRER_TYPE_USER) {
            trackReferralCode({ referralCode: referrerCode });
          }

          if (refResponse.data?.invalid === true) {
            await handleInvalidCoupon();
          }
        } catch (err) {
          // Not found partners are ok, we track just other errors
          if (err.name === 'RequestError' && err.response.status !== 404) {
            reporter.error(err);
          }

          if (err.response.status == 404) {
            await handleInvalidCoupon();
          }
        } finally {
          dispatch(hasCalledFetchReferrer());
        }

        if (refResponse && user) {
          // Update user with new referrer data.
          const fetchedRefType =
            refResponse.data.type === REFERRER_TYPE_USER
              ? REFERRER_TYPE_USER
              : REFERRER_TYPE_PARTNER;
          if (
            refResponse.data.id !== user.referrerId ||
            fetchedRefType !== user.referrerType
          ) {
            log.debug('Updating user with new referral data');
            try {
              await dispatch(
                updateUserReferrer({
                  referrerId: refResponse.data.id,
                  referrerType: fetchedRefType,
                })
              );
            } catch (err) {
              // Unauthorized errors allowed to fail without tracking.
              if (err.response && err.response.status !== 401) {
                reporter.error(err);
              }
            }
          }
        }
      }
      dispatch(setIsFetchingReferrer(false));
    };

    if (!isReferrerLookupDisabled) {
      attemptFetchReferrer();
    }
  }, [
    currentUser.id,
    currentUser.referrerId,
    currentUser.referrerType,
    currentUser.referrerAssociatedAt,
    dispatch,
    defaultDiscount.referrerCode,
    defaultDiscount.referrerType,
    isReferrerLookupDisabled,
    isDefaultDiscountExperimentOn,
    setCouponCookieWithDefaultExperimentCode,
    defaultDiscountExperimentErrorMessage,
  ]);

  return { fellBackToDefaultDiscount };
}
