import { Text, Link, Arrow } from '@farmersdog/corgi';

import styles from './BackButton.module.css';
import { useBackButtonNavigation } from './hooks';

export function BackButton() {
  const { navigatePreviousLinkLabel, previousSignupRoute } =
    useBackButtonNavigation();

  return (
    <div className={styles.container}>
      <Link
        to={previousSignupRoute}
        variant="secondary"
        mode="dark"
        aria-label={navigatePreviousLinkLabel}
      >
        <Arrow size={12} className={styles.arrow} />
        <Text variant="heading-16">back</Text>
      </Link>
    </div>
  );
}
