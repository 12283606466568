import { browserOnly } from '@farmersdog/utils';

interface CustomScrollArgs {
  id: string;
  offset: number;
}

/**
 * Only use this function when
 * we have to do custom scroll position because scrollIntoView is not precise enough
 */
export function customScroll({ id, offset }: CustomScrollArgs) {
  browserOnly((window, document) => {
    const topElement = document.getElementById(id);
    if (topElement) {
      const yScroll =
        topElement.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({ top: yScroll });
    }
  });
}
