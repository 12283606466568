import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

interface TrackHomepageEssentialsLinkClickedPayload {
  label: string;
  url: string;
}

export function trackHomepageEssentialsLinkClicked({
  label,
  url,
}: TrackHomepageEssentialsLinkClickedPayload) {
  track(eventNames.homepage_essentials_link_clicked, {
    label,
    url,
  });
}
