/**
 * Attempt to format a phone number string as xxx-xxx-xxxx.  If the phone
 * number is too short will format up to the length of digits.
 *
 */
export function formatPhoneNumber(value: string, previousValue?: string) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/\D/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}-`;
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
}
