import eventNames from './eventNames';
import { track } from '@farmersdog/analytics';

interface TrackOnCloseArgs {
  currentStep: string;
}

export function trackOnClose(args: TrackOnCloseArgs) {
  track(eventNames.onClose, {
    currentStep: args.currentStep,
  });
}
