import { Grid, GridItem } from '@farmersdog/corgi-x';

import { WeightLossPersonalizationActiveTreatment } from '../../../hooks';
import { UseFeatureHook } from '../../../types';
import { CvrWeightlossPersonalizationTreatments } from '../../../utils';
import { Footer } from '../../creative/GetStarted/Footer';
import { FAQ } from '../FAQ';
import { HowItWorks } from '../HowItWorks';
import { RecipesStack } from '../RecipesStack';
import { SuccessStories } from '../SuccessStories';
import { RecommendedRecipe } from '../types';
import { WeightLoss } from '../WeightLoss';

import styles from './BodyConditionPersonalizationWrapper.module.css';
import { Separator } from './Separator';

interface BodyConditionPersonalizationWrapperProps {
  children: React.ReactNode;
  petName: string;
  recommendedRecipes: RecommendedRecipe[];
  treatment: WeightLossPersonalizationActiveTreatment;
  useFeature: UseFeatureHook;
  bodyCondition: string;
  petGender: string;
}

export const BodyConditionPersonalizationWrapper = ({
  children,
  petName,
  bodyCondition,
  petGender,
  recommendedRecipes,
  useFeature,
  treatment,
}: BodyConditionPersonalizationWrapperProps) => {
  const isGeneric =
    treatment === CvrWeightlossPersonalizationTreatments.generic;
  const isWeightLoss =
    treatment === CvrWeightlossPersonalizationTreatments.weight_loss;

  return (
    <div className={styles.absolute}>
      <section className={styles.wrapper}>
        <Grid justifyContent="center">
          <GridItem
            lg={6}
            flexDirection="column"
            className={styles.left}
            alignItems="center"
          >
            <div className={styles.sticky}>{children}</div>
          </GridItem>
          <GridItem flexDirection="column" lg={6}>
            <section className={styles.recipeBlock}>
              <RecipesStack
                petName={petName}
                foodPlanRecipes={recommendedRecipes}
                useFeature={useFeature}
              />
            </section>

            {isWeightLoss && (
              <>
                <WeightLoss
                  className={styles.sectionContent}
                  petName={petName}
                  bodyCondition={bodyCondition}
                  petGender={petGender}
                />

                <Separator />
              </>
            )}

            <HowItWorks
              className={styles.sectionContent}
              petName={petName}
              petGender={petGender}
              isGraySection={isGeneric}
            />

            {isGeneric && <Separator />}

            <SuccessStories
              className={styles.sectionContent}
              petName={petName}
              treatment={treatment}
            />

            <FAQ treatment={treatment} />
          </GridItem>
        </Grid>
      </section>
      <Footer className={styles.footer} />
    </div>
  );
};
