import events from '../eventNames';
import track from '../track';
import { reporter } from 'src/services/reporter';

/**
 * A segment tracking event that is used to mark a customer successfully
 * checking out.
 *
 * @param {import('src/graphql/types').User} user A TFD user object
 * @param {import('src/graphql/types').SubscriptionQuote} quote A TFD quote object
 * @param {Object} answers An object containing answers from the cancel subscription flow
 * @param {string} [answers.selectedReason.value] Selected reason for cancellation
 * @param {string} [answers.customReason] Free text reason for cancellation if selected 'Other'
 * @param {string} [answers.freeText] More detailed reason for cancellation
 * @param {string} [answers.selectedFood.value] Type of diet to replace TFD with (dry, wet, raw, etc)
 * @param {string} [answers.selectedBrandName] Name of food brand to replace TFD
 * @param {string} [answers.selectedDiet] Prescription diet needed that TFD doesn't provide
 * @param {string} [answers.suggestedPrice] Max price they would pay per week
 */

export function trackCancelSubscription({ user, quote, answers }) {
  if (!user) {
    reporter.warn(
      `Segment event "${events.cancel_subscription_subscription_canceled}" has missing parameter "user"`
    );
  }

  if (!quote) {
    reporter.warn(
      `Segment event "${events.cancel_subscription_subscription_canceled}" has missing parameter "quote"`
    );
  }

  track(events.cancel_subscription_subscription_canceled, {
    user_id: user?.id,
    email: user?.email,
    cancellation_reason:
      answers?.selectedReason && answers.selectedReason.value,
    other_reason: answers?.customReason,
    free_text: answers?.freeText,
    new_food: answers?.selectedFood && answers.selectedFood.value,
    new_brand: answers?.selectedBrandName,
    prescription_diet: answers?.selectedDiet,
    desired_weekly_price: answers?.suggestedPrice,
    current_weekly_price: quote
      ? String(quote.shipment.regular.pricePerWeek / 100)
      : '',
  });
}
