import { useRef, useEffect } from 'react';
import { loadGoogleApi } from './loadGoogleApi';

export interface GoogleMapsScriptProps {
  onLoad?: (google: typeof window.google) => void;
  onError?: (error: Error) => void;
}

function GoogleMapsScript({ onLoad, onError }: GoogleMapsScriptProps) {
  const onLoadRef = useRef(onLoad);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onLoadRef.current = onLoad;
    onErrorRef.current = onError;
  }, [onLoad, onLoadRef, onErrorRef, onError]);

  useEffect(() => {
    void loadGoogleApi()
      .then(google => {
        if (google && onLoadRef.current) {
          onLoadRef.current(google);
        }
      })
      .catch(error => {
        if (onErrorRef.current) {
          onErrorRef.current(error as Error);
        }
      });
  }, [onLoadRef, onErrorRef]);

  return null;
}

export default GoogleMapsScript;
