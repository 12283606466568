import { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloProvider as Provider,
  NormalizedCacheObject,
} from '@apollo/client';
import { createClient } from './createClient';

export const defaultClient = createClient();

interface ApolloProviderProps {
  children: ReactNode;
  /** Use Website's Apollo Client when rendering App in Website */
  client?: ApolloClient<NormalizedCacheObject>;
}

export function ApolloProvider({ children, client }: ApolloProviderProps) {
  return <Provider client={client || defaultClient}>{children}</Provider>;
}
