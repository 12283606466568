import { OrderDeliveryStatus } from '../../graphql/types.cgs';

import preparingGif from './preparing.gif';
import inTransitGif from './in-transit.gif';

export const orderDeliveryGifs: Record<
  OrderDeliveryStatus,
  string[] | undefined
> = {
  [OrderDeliveryStatus.Preparing]: [preparingGif],
  [OrderDeliveryStatus.Transit]: [inTransitGif],
  [OrderDeliveryStatus.Delivered]: undefined,
  [OrderDeliveryStatus.Failed]: undefined,
  [OrderDeliveryStatus.Scheduled]: undefined,
  [OrderDeliveryStatus.PaymentFailed]: undefined,
};
