import { Warning } from '@farmersdog/corgi-x/icons';
import { Text } from '@farmersdog/corgi-x';

import styles from './PaymentWarningBadge.module.css';

export const PAYMENT_UPDATE_NEEDED_TEXT = 'Payment Update Needed';

export function PaymentWarningBadge() {
  return (
    <div className={styles.root}>
      <Warning />
      <Text color="error-red" variant="heading-16" bold>
        {PAYMENT_UPDATE_NEEDED_TEXT}
      </Text>
    </div>
  );
}
