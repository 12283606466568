import { useQuery } from '@apollo/client';
import preCancelDataQuery from './preCancelData.cgs.graphql';
import { PreCancelDataQuery } from './preCancelData.cgs.generated-types';

export function usePreCancelData() {
  return useQuery<PreCancelDataQuery>(preCancelDataQuery, {
    context: {
      endpoint: 'cgs',
    },
  });
}
