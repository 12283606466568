import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_HOME } from '@farmersdog/constants/paths';
import { trackIHeartRadioPageView } from './trackIHeartRadioPageView';

export const iHeartRadioPageViewRoutes = [PATH_HOME];

export function useTrackIHeartRadioPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (iHeartRadioPageViewRoutes.includes(pathname)) {
      trackIHeartRadioPageView();
    }
  }, [pathname]);
}
