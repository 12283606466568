import { useId } from 'react';
import classNames from 'classnames';
import { useViewport } from 'src/screen';
import { Text, PageWidth, Nowrap, VideoSection } from '@farmersdog/corgi-x';

import { FOREVER_AD } from 'src/constants/wistiaVideoIds';

import styles from './OurMission.module.scss';

import events from 'src/analytics/eventNames';
import { track } from 'src/analytics';

const WISTIA_OPTIONS = {
  popover: true,
};

function OurMission() {
  const isMobile = useViewport({ xs: true, lg: false });
  const overlayId = useId();

  return (
    <div className={classNames(styles.container, { [styles.lg]: !isMobile })}>
      <div className={styles.column}>
        <Text
          variant={isMobile ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Our mission
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          <p>
            We’re turning our unconditional love for dogs into uncomplicated
            care. And that starts with what you put in their bowl.
          </p>
          <p>
            We were founded by two dog lovers who were fed up with
            highly-processed, burnt brown balls being marketed as “natural” and{' '}
            <Nowrap id="ad-forever">“healthy”</Nowrap> — so they decided to
            reimagine pet food from the <Nowrap>ground up</Nowrap>.
          </p>
          <p>
            Watch our love letter to dogs from this year’s big game, “Forever,”
            voted #1 on USA Today Ad Meter.
          </p>
        </Text>
      </div>
      <PageWidth enabled={!isMobile}>
        <h2 className={styles.videoHeading} aria-hidden="true" id={overlayId}>
          Ad made by The Farmer’s Dog for the superbowl in 2021 which was voted
          #1 on USA Today Ad Meter
        </h2>
        <VideoSection
          wistiaId={FOREVER_AD}
          wistiaOptions={WISTIA_OPTIONS}
          className={styles.video}
          aria-labelledby={overlayId}
          onPlay={() => track(events.forever_video_play)}
          onPause={() => track(events.forever_video_pause)}
          onEnd={() => track(events.forever_video_end)}
        />
      </PageWidth>
    </div>
  );
}

export default OurMission;
