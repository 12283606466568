import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { browserOnly, navigateTo } from '@farmersdog/utils';

import { isValidPath, getRedirectPath } from './config';
import { buildUtms } from './utils/build-utms';

function generateOfflineCouponsError(pathname: string) {
  return `OfflineCoupons matched path ${pathname} but provided no handler for the route`;
}

export function OfflineCoupons() {
  const { pathname } = useLocation();

  useEffect(() => {
    browserOnly(
      () => {
        const lowerCasePathname = pathname.toLowerCase();

        if (isValidPath(lowerCasePathname)) {
          const redirectPath = getRedirectPath(lowerCasePathname);
          const utms = buildUtms({
            path: redirectPath,
          });
          navigateTo(`/p${redirectPath}?${utms}`);
          return;
        }

        throw new Error(generateOfflineCouponsError(pathname));
      },
      () => null
    );
  }, [pathname]);

  return null;
}
