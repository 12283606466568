import {
  trackGa4Event,
  Ga4CustomEvent,
  Ga4StandardEvent,
} from './trackGa4Event';

import { SubscriptionType } from 'src/graphql/types';

import type { Ga4Item } from './utils';

interface CheckoutSuccessArgs {
  userId?: number;
  items?: Ga4Item[];
  productLine: SubscriptionType;
  value: string;
}

export function trackGa4CheckoutSuccess(data: CheckoutSuccessArgs) {
  const userIdProperty = data.userId ? { user_id: data.userId } : undefined;

  trackGa4Event({
    name: Ga4CustomEvent.CheckoutSuccess,
    properties: {
      productLine: data.productLine,
      value: data.value,
      ...userIdProperty,
    },
  });
  if (isValidPurchasePayload(data)) {
    trackGa4Event({
      name: Ga4StandardEvent.Purchase,
      properties: {
        currency: 'USD',
        value: data.value,
        transaction_id: data.userId,
        items: data.items,
        ...userIdProperty,
      },
    });
  }
}

function isValidPurchasePayload(data: CheckoutSuccessArgs): boolean {
  return Boolean(
    Number.isFinite(data?.userId) &&
      Array.isArray(data.items) &&
      data.items.length
  );
}
