import { combineReducers } from 'redux';
import { AUTH_LOGOUT, SIGNUP_RESET } from 'src/constants/actionTypes';

import user from './user';
import me from './me';
import progress, { initialState as progressInitialState } from './progress';
import submitting from './submitting';
import plans from './plans';
import pets from './pets';
import quote from './quote';
import mixingPlanExperiment from './mixingPlanExperiment';
import diy from './diy';
import checkout from './checkout';

import localStorageUser from '../../utils/localStorageUser';

const reducers = combineReducers({
  user,
  me,
  pets,
  plans,
  quote,
  mixingPlanExperiment,
  diy,
  checkout,
  submitting,
  progress: (state = progressInitialState) => state,
});

export default function signupReducer(state, action) {
  switch (action.type) {
    case AUTH_LOGOUT:
    case SIGNUP_RESET:
      localStorageUser.clear();
      return reducers(undefined, action); // Reset state
    default:
      return progress(reducers(state, action), action);
  }
}
