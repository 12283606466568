import track from '../track';
import events from '../eventNames';
import { TreatSku } from 'src/data/treats';

export enum ViewScreens {
  SNAP_STICKS = 'Snap Sticks',
  MEALS = 'Meals',
}

interface TrackAddedTreatsArgs {
  sku: TreatSku;
  quantity: number;
  viewScreen: ViewScreens;
}

export function trackAddedTreats(addedTreatsInfo: TrackAddedTreatsArgs): void {
  track(events.treats_add_to_plan, { addedTreatsInfo });
}
