import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type * as History from 'history';

import { browserOnly } from '@farmersdog/utils';

export function useScrollRestore() {
  const previousLocation = useRef<History.Location>();
  const location = useLocation();

  useEffect(() => {
    const previousPathname = previousLocation?.current?.pathname;
    const { hash, pathname } = location;

    browserOnly(window => {
      if (previousPathname === pathname && !hash) {
        return;
      }

      if (!hash) {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        return;
      }

      const node = window.document.querySelector(location.hash);

      if (!node) {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        return;
      }

      node.scrollIntoView({ behavior: 'auto', block: 'start' });
    });
  }, [location, previousLocation]);

  useEffect(() => {
    previousLocation.current = location;
  }, [location, previousLocation]);
}

export default useScrollRestore;
