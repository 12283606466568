import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_HOME } from '@farmersdog/constants/paths';
import { trackISpotPageView } from './trackISpotPageView';
import qs from 'qs';
import { QueryParameter } from '@farmersdog/constants';

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a iSpot page view event.
 */
export function useTrackISpotPageView() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (pathname === PATH_HOME) {
      const query = qs.parse(search, { ignoreQueryPrefix: true });
      const utmSource =
        typeof query?.[QueryParameter.UtmSource] === 'string'
          ? query[QueryParameter.UtmSource]
          : undefined;
      trackISpotPageView({ pathname, utmSource });
    }
  }, [pathname, search]);
}
