import { NodeNames, tosaErrorCodes as errorCodes } from '@farmersdog/constants';

import {
  Blueprint,
  NodeType,
  LayoutType,
  ComponentName,
} from '../blueprint/types';
import { ProductLineType } from '../graphql/types';
import {
  BirthdayAccuracy,
  FreshFoodConfidenceLevels,
} from '../validation/constants';

const PATH_SIGNUP_ME = '/signup/me';
const PATH_SIGNUP_PETS = '/signup/pets';
const PATH_SIGNUP_RECIPES = '/signup/recipes';

const nbsp = '\u00A0'; // non breaking space unicode character

export const standardBlueprint: Blueprint = {
  version: '0.0.0',
  root: {
    name: 'Root',
    type: NodeType.branch,
    layout: LayoutType.none,
    children: [
      {
        name: 'Me',
        type: NodeType.branch,
        layout: LayoutType.none,
        component: ComponentName.FormWrapper,
        children: [
          {
            name: ComponentName.GetStarted,
            type: NodeType.branch,
            route: PATH_SIGNUP_ME,
            component: ComponentName.GetStartedWrapper,
            children: [
              {
                name: 'GetStartedLayout',
                type: NodeType.leaf,
                component: ComponentName.GetStarted,
                layout: LayoutType.block,
                input: {
                  excludeFromProgress: true,
                },
              },
            ],
          },
          {
            name: 'Me1',
            type: NodeType.branch,
            route: PATH_SIGNUP_ME,
            component: ComponentName.StandardForm,
            children: [
              {
                name: 'getStartedCopyExperiment',
                type: NodeType.leaf,
                component: ComponentName.GetStartedCopyExperiment,
                layout: LayoutType.block,
                input: {
                  excludeFromProgress: true,
                },
              },
              {
                name: 'numPetsImage',
                type: NodeType.leaf,
                component: ComponentName.NumberOfPetsImage,
                layout: LayoutType.block,
                input: {
                  excludeFromProgress: true,
                },
              },
              {
                name: NodeNames.NumPets,
                type: NodeType.leaf,
                validation: NodeNames.NumPets,
                component: ComponentName.NumPetInput,
                layout: LayoutType.inline,
                input: {
                  label: 'I have %input% dog%plural%',
                  accessibleLabel: 'Number of pets',
                  placeholder: '0',
                },
              },
              {
                name: 'Pet Names',
                type: NodeType.branch,
                children: [],
                layout: LayoutType.none,
              },
              {
                name: 'me1Error',
                type: NodeType.leaf,
                component: ComponentName.InlineError,
                input: {
                  errorMap: {
                    [errorCodes.NUM_PETS_TOO_MANY]: `Apologies, our accounts aren’t used to handling so many dogs at once! Our team can help you finish signing up %signup-support-button%`,
                    [errorCodes.PET_NAMES_NOT_UNIQUE]: `Every dog is unique – please make sure they don’t have the same name. %signup-support-button%`,
                  },
                  excludeFromProgress: true,
                },
              },
            ],
          },
          {
            name: 'Me2',
            type: NodeType.branch,
            route: PATH_SIGNUP_ME,
            layout: LayoutType.none,
            component: ComponentName.StandardForm,
            children: [
              {
                name: 'me2Whitespace',
                type: NodeType.leaf,
                layout: LayoutType.block,
                component: ComponentName.MeWhitespaceNode,
                input: {
                  excludeFromProgress: true,
                },
              },
              {
                name: NodeNames.FirstName,
                type: NodeType.leaf,
                validation: NodeNames.FirstName,
                layout: LayoutType.block,
                component: ComponentName.BasicInput,
                input: {
                  label: 'My first name is %input%',
                  accessibleLabel: 'First name',
                  placeholder: 'enter name',
                },
              },
              {
                name: NodeNames.FreshFoodConfidence,
                type: NodeType.leaf,
                validation: NodeNames.FreshFoodConfidence,
                layout: LayoutType.block,
                component: ComponentName.FreshFoodConfidenceInput,
                input: {
                  label: `and I %input% that fresh food is the healthiest for${nbsp}%petNames%`,
                  accessibleLabel: 'Confidence in fresh food',
                  options: [
                    {
                      name: FreshFoodConfidenceLevels.alreadyBelieve,
                      value: FreshFoodConfidenceLevels.alreadyBelieve,
                    },
                    {
                      name: FreshFoodConfidenceLevels.notSure,
                      value: FreshFoodConfidenceLevels.notSure,
                    },
                    {
                      name: FreshFoodConfidenceLevels.dontBelieve,
                      value: FreshFoodConfidenceLevels.dontBelieve,
                    },
                  ],
                },
              },
            ],
          },
          {
            name: 'Me3',
            type: NodeType.branch,
            route: PATH_SIGNUP_ME,
            layout: LayoutType.none,
            component: ComponentName.ContactInfoForm,
            children: [
              {
                name: 'me3Whitespace',
                type: NodeType.leaf,
                layout: LayoutType.block,
                component: ComponentName.MeWhitespaceNode,
                input: {
                  excludeFromProgress: true,
                },
              },
              {
                name: 'zipSentence',
                type: NodeType.branch,
                layout: LayoutType.block,
                input: {
                  excludeFromProgress: true,
                },
                children: [
                  {
                    name: NodeNames.Zip,
                    type: NodeType.leaf,
                    validation: NodeNames.Zip,
                    layout: LayoutType.block,
                    component: ComponentName.ZipInput,
                    input: {
                      label: 'My zip code is %input%',
                      accessibleLabel: 'Zip code',
                      placeholder: 'enter zip code',
                    },
                  },
                  {
                    name: 'zipError',
                    type: NodeType.leaf,
                    layout: LayoutType.block,
                    component: ComponentName.ZipEmailErrorWrapper,
                    input: {
                      errorMap: {
                        [errorCodes.ZIP_INVALID]:
                          'Zip code must be exactly 5 digits.',
                        [errorCodes.ZIP_NOT_SERVICEABLE]:
                          'We only ship to mainland US, excluding Hawaii and Alaska.',
                      },
                      label: NodeNames.Zip,
                      excludeFromProgress: true,
                    },
                  },
                ],
              },
              {
                name: 'emailSentence',
                type: NodeType.branch,
                layout: LayoutType.block,
                input: {
                  excludeFromProgress: true,
                },
                children: [
                  {
                    name: NodeNames.Email,
                    type: NodeType.leaf,
                    validation: NodeNames.Email,
                    layout: LayoutType.block,
                    component: ComponentName.EmailInput,
                    input: {
                      label: 'My email address is %input%',
                      accessibleLabel: 'Email address',
                      placeholder: 'enter email',
                    },
                  },
                  {
                    name: 'emailError',
                    type: NodeType.leaf,
                    layout: LayoutType.block,
                    component: ComponentName.ZipEmailErrorWrapper,
                    input: {
                      errorMap: {
                        [errorCodes.EMAIL_INVALID]:
                          'This email doesn’t look right, please correct it.',
                      },
                      label: NodeNames.Email,
                      excludeFromProgress: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Pets',
        type: NodeType.branch,
        layout: LayoutType.none,
        children: [],
      },
      {
        name: 'Recipes',
        type: NodeType.branch,
        layout: LayoutType.none,
        productLines: [ProductLineType.Fresh],
        children: [],
      },
    ],
  },
  fragments: [
    {
      name: 'Pet Name',
      type: NodeType.branch,
      layout: LayoutType.none,
      children: [
        {
          name: NodeNames.Name,
          type: NodeType.leaf,
          validation: NodeNames.Name,
          layout: LayoutType.inline,
          component: ComponentName.PetNameInput,
          input: {
            label: ' %custom% %input%',
            accessibleLabel: `Pet's name`,
            inline: true,
            placeholder: 'enter pet’s name',
          },
        },
      ],
    },

    {
      name: 'Pet',
      type: NodeType.branch,
      layout: LayoutType.none,
      component: ComponentName.PetFormWrapper,
      children: [
        {
          name: 'PetAge',
          type: NodeType.branch,
          route: PATH_SIGNUP_PETS,
          layout: LayoutType.none,
          component: ComponentName.StandardForm,
          children: [
            {
              name: 'petDetailsImage',
              type: NodeType.leaf,
              component: ComponentName.PetDetailsImage,
              layout: LayoutType.block,
              input: {
                excludeFromProgress: true,
              },
            },
            {
              name: 'petGenderSentence',
              type: NodeType.branch,
              layout: LayoutType.block,
              children: [
                {
                  name: NodeNames.Gender,
                  type: NodeType.leaf,
                  layout: LayoutType.inline,
                  validation: NodeNames.Gender,
                  component: ComponentName.DropdownInput,
                  input: {
                    label: '%input% is',
                    accessibleLabel: 'Dog gender',
                    options: [
                      { name: 'She', value: 'female' },
                      { name: 'He', value: 'male' },
                    ],
                    default: 'female',
                  },
                },
                {
                  name: NodeNames.BirthdayAmount,
                  type: NodeType.leaf,
                  layout: LayoutType.inline,
                  validation: NodeNames.BirthdayAmount,
                  component: ComponentName.BasicInput,
                  input: {
                    label: '%input%',
                    accessibleLabel: 'Dog age',
                    placeholder: '0',
                  },
                },
                {
                  name: NodeNames.BirthdayUnit,
                  type: NodeType.leaf,
                  validation: NodeNames.BirthdayUnit,
                  layout: LayoutType.inline,
                  component: ComponentName.BirthdayUnitInput,
                  input: {
                    label: '%input%',
                    accessibleLabel: 'Age units',
                    options: [
                      {
                        name: 'year%plural%',
                        value: BirthdayAccuracy.Years,
                      },
                      {
                        name: 'month%plural%',
                        value: BirthdayAccuracy.Months,
                      },
                      {
                        name: 'week%plural%',
                        value: BirthdayAccuracy.Weeks,
                      },
                    ],
                    default: BirthdayAccuracy.Years,
                  },
                },
                {
                  name: 'petAgeError',
                  type: NodeType.leaf,
                  component: ComponentName.InlineError,
                  input: {
                    errorMap: {
                      [errorCodes.PET_TOO_OLD]:
                        'Are you sure? That seems quite old!',
                      [errorCodes.PET_TOO_YOUNG]:
                        'Come back when your puppy is 8 weeks old!',
                    },
                    excludeFromProgress: true,
                  },
                },
                {
                  name: NodeNames.PetAgeSkipButton,
                  type: NodeType.leaf,
                  layout: LayoutType.block,
                  component: ComponentName.PetAgeSkipButton,
                  input: {
                    label: 'Skip %petName%',
                    excludeFromProgress: true,
                  },
                },
              ],
            },
            {
              name: 'petNeuteredSentence',
              type: NodeType.branch,
              layout: LayoutType.block,
              children: [
                {
                  name: NodeNames.Neutered,
                  type: NodeType.leaf,
                  validation: NodeNames.Neutered,
                  layout: LayoutType.inline,
                  component: ComponentName.DropdownInput,
                  input: {
                    label: '%pronoun-subject% %input% %sterilized%',
                    accessibleLabel: 'Neutered status',
                    options: [
                      { name: 'is', value: true },
                      { name: 'is not', value: false },
                    ],
                    default: true,
                  },
                },
                {
                  name: NodeNames.Nature,
                  type: NodeType.leaf,
                  layout: LayoutType.inline,
                  validation: NodeNames.Nature,
                  component: ComponentName.DropdownInput,
                  input: {
                    label: ' and is the %input%',
                    accessibleLabel: `Your dog's nature`,
                    options: 'seed:PersonalityType',
                    default: 'cutest',
                  },
                },
                {
                  name: NodeNames.Breeds,
                  type: NodeType.leaf,
                  layout: LayoutType.inline,
                  validation: NodeNames.Breeds,
                  component: ComponentName.BreedsInput,
                  input: {
                    label: '%input% ever.',
                    accessibleLabel: `Dog breed`,
                    options: 'seed:Breed',
                    placeholder: 'enter breed',
                  },
                },
                {
                  name: 'pet breeds whitespace',
                  type: NodeType.leaf,
                  layout: LayoutType.block,
                  component: ComponentName.PetWhitespaceNode,
                  input: {
                    excludeFromProgress: true,
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'PetWeight',
          type: NodeType.branch,
          layout: LayoutType.none,
          route: PATH_SIGNUP_PETS,
          component: ComponentName.PetWeightForm,
          children: [
            {
              name: 'petWeightSentence',
              type: NodeType.branch,
              layout: LayoutType.block,
              input: {
                excludeFromProgress: true,
              },
              children: [
                {
                  name: NodeNames.Weight,
                  type: NodeType.leaf,
                  layout: LayoutType.block,
                  validation: NodeNames.Weight,
                  component: ComponentName.PetWeightInput,
                  input: {
                    label: '%petName% weighs %input% lbs.',
                    accessibleLabel: `Dog's weight in pounds`,
                    placeholder: '0',
                    hint: 'If you’re unsure, you can always adjust this info in your account later.',
                  },
                },
                {
                  name: 'petWeightError',
                  type: NodeType.leaf,
                  layout: LayoutType.block,
                  component: ComponentName.InlineError,
                  input: {
                    errorMap: {
                      [errorCodes.WEIGHT_TOO_LIGHT]:
                        'Are you sure? That seems quite light!',
                      [errorCodes.WEIGHT_TOO_HEAVY]:
                        'Are you sure? That seems quite heavy!',
                      [errorCodes.BREED_WEIGHT_TOO_HEAVY]: `Your dog is a little larger for its breed than our system is used to! If this is their correct weight, please reach out to our team for help finishing signup! %fraud-support-button%`,
                    },
                    excludeFromProgress: true,
                  },
                },
              ],
            },
            {
              name: NodeNames.BodyCondition,
              type: NodeType.leaf,
              layout: LayoutType.inline,
              validation: NodeNames.BodyCondition,
              component: ComponentName.BodyConditionInput,
              input: {
                label: `and %pronoun-possessive% body condition is currently... %input%`,
                options: 'seed:BodyCondition',
              },
            },
            {
              name: 'Ideal Weight',
              type: NodeType.branch,
              layout: LayoutType.block,
              children: [],
            },
          ],
        },
        {
          name: 'PetActivity',
          type: NodeType.branch,
          route: PATH_SIGNUP_PETS,
          layout: LayoutType.none,
          component: ComponentName.StandardForm,
          children: [
            {
              name: 'Pet activity Whitespace',
              type: NodeType.leaf,
              layout: LayoutType.none,
              component: ComponentName.PetWhitespaceNode,
              input: {
                excludeFromProgress: true,
              },
            },
            {
              name: NodeNames.ActivityLevel,
              type: NodeType.leaf,
              validation: NodeNames.ActivityLevel,
              layout: LayoutType.block,
              component: ComponentName.ActivityLevelInput,
              input: {
                label: '%petName%’s activity level is... %input%',
                options: 'seed:ActivityLevel',
              },
            },
          ],
        },
        {
          name: 'PetPicky',
          type: NodeType.branch,
          route: PATH_SIGNUP_PETS,
          layout: LayoutType.none,
          component: ComponentName.StandardForm,
          children: [
            {
              name: 'Pet eating style Whitespace',
              type: NodeType.leaf,
              layout: LayoutType.none,
              component: ComponentName.PetWhitespaceNode,
              input: {
                excludeFromProgress: true,
              },
            },
            {
              name: NodeNames.EatingStyle,
              type: NodeType.leaf,
              layout: LayoutType.block,
              validation: NodeNames.EatingStyle,
              component: ComponentName.EatingStyleInput,
              input: {
                label: 'At mealtimes, %petName% is... %input%',
                options: 'seed:EatingStyle',
              },
            },
          ],
        },
        {
          name: 'PetCurrentFood',
          type: NodeType.branch,
          route: PATH_SIGNUP_PETS,
          layout: LayoutType.none,
          component: ComponentName.PetCurrentFoodForm,
          children: [
            {
              name: 'Pet current food whitespace',
              type: NodeType.leaf,
              layout: LayoutType.block,
              component: ComponentName.PetWhitespaceNode,
              input: {
                excludeFromProgress: true,
              },
            },
            {
              name: 'currentFoodSentence',
              type: NodeType.branch,
              layout: LayoutType.block,
              children: [
                {
                  name: NodeNames.FoodType,
                  type: NodeType.leaf,
                  validation: NodeNames.FoodType,
                  component: ComponentName.FoodTypeInput,
                  input: {
                    label: '%pronoun-subject% mainly eats %input%',
                    accessibleLabel: `Current food type`,
                    options: 'seed:FoodType',
                    inline: true,
                  },
                },
                {
                  name: 'Food Brand',
                  type: NodeType.branch,
                  layout: LayoutType.inline,
                  children: [
                    {
                      name: NodeNames.FoodBrand,
                      type: NodeType.leaf,
                      validation: NodeNames.FoodBrand,
                      component: ComponentName.FoodBrandInput,
                      input: {
                        label: ' by %input%',
                        accessibleLabel: `Current food brand`,
                        placeholder: 'enter brand',
                      },
                    },
                  ],
                },
                {
                  name: NodeNames.GrainsPreference,
                  type: NodeType.branch,
                  layout: LayoutType.inline,
                  children: [
                    {
                      name: NodeNames.GrainsPreference,
                      type: NodeType.leaf,
                      validation: NodeNames.GrainsPreference,
                      component: ComponentName.GrainsPreferenceInput,
                      input: {
                        label:
                          ' and I %input% grains in %pronoun-possessive% food.',
                        accessibleLabel: `Grains preference`,
                        options: [
                          {
                            name: 'have no preference on',
                            value: 'have no preference on',
                          },
                          {
                            name: 'prefer to have',
                            value: 'prefer to have',
                          },
                          {
                            name: 'prefer to avoid',
                            value: 'prefer to avoid',
                          },
                        ],
                        default: 'have no preference on',
                        inline: true,
                      },
                    },
                  ],
                },
                {
                  name: NodeNames.TreatsQuantity,
                  type: NodeType.leaf,
                  validation: NodeNames.TreatsQuantity,
                  component: ComponentName.TreatsQuantityInput,
                  input: {
                    label: `and gets %input% treats or${nbsp}scraps.`,
                    accessibleLabel: 'Treats allowance',
                    options: 'seed:TreatUsageBucket',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'PetHealth',
          type: NodeType.branch,
          layout: LayoutType.none,
          route: PATH_SIGNUP_PETS,
          component: ComponentName.HealthForm,
          children: [
            {
              name: 'health issues whitespace',
              type: NodeType.leaf,
              layout: LayoutType.block,
              component: ComponentName.PetWhitespaceNode,
              input: {
                excludeFromProgress: true,
              },
            },
            {
              name: NodeNames.Healthy,
              type: NodeType.leaf,
              layout: LayoutType.block,
              validation: NodeNames.Healthy,
              component: ComponentName.PetHealthInput,
              input: {
                label: '%petName% %input% health issues',
                accessibleLabel: 'Health issues',
                options: [
                  { name: 'doesn’t have', value: false },
                  { name: 'has', value: true },
                ],
                default: false,
              },
            },
            {
              name: 'Health Issues',
              type: NodeType.branch,
              children: [],
            },
            {
              name: NodeNames.PrescriptionDiet,
              type: NodeType.leaf,
              layout: LayoutType.block,
              validation: NodeNames.PrescriptionDiet,
              component: ComponentName.PetHealthInput,
              input: {
                label: `and %input% require a prescription${nbsp}diet.`,
                accessibleLabel: 'Prescription diet',
                default: false,
                options: [
                  { name: 'doesn’t', value: false },
                  { name: 'does', value: true },
                ],
              },
            },
            {
              name: 'Prescription Diets',
              type: NodeType.branch,
              children: [],
            },
          ],
        },
      ],
    },
    {
      name: 'Pet Ideal Weight',
      type: NodeType.branch,
      layout: LayoutType.none,
      children: [
        {
          name: 'Pet Ideal Weight Whitespace',
          type: NodeType.leaf,
          layout: LayoutType.block,
          component: ComponentName.PetWhitespaceNode,
          input: {
            excludeFromProgress: true,
          },
        },
        {
          name: NodeNames.TargetWeight,
          type: NodeType.leaf,
          layout: LayoutType.block,
          validation: NodeNames.TargetWeight,
          component: ComponentName.PetWeightInput,
          input: {
            label: '%petName%’s ideal weight is %input% lbs',
            accessibleLabel: `Dog's ideal weight`,
            placeholder: '0',
            hint: 'Your dog’s ideal weight is used to calculate their recommended daily calories.',
          },
        },
        {
          name: 'petWeightError',
          type: NodeType.leaf,
          component: ComponentName.InlineError,
          input: {
            errorMap: {
              [errorCodes.TARGET_WEIGHT_TOO_LIGHT]: `Are you sure? That seems quite light!`,
              [errorCodes.TARGET_WEIGHT_TOO_HEAVY]: `Are you sure? That seems quite heavy!`,
              [errorCodes.OVERWEIGHT_PET_TARGET_WEIGHT]: `If your dog’s body condition is %bodyCondition%, we recommend targeting a lower weight than their current one.`,
              [errorCodes.UNDERWEIGHT_PET_TARGET_WEIGHT]: `If your dog’s body condition is %bodyCondition%, we recommend targeting a higher weight than their current one.`,
            },
            excludeFromProgress: true,
          },
        },
      ],
    },
    {
      name: 'Pet Food Brand',
      type: NodeType.branch,
      layout: LayoutType.none,
      children: [
        {
          name: NodeNames.FoodBrand,
          type: NodeType.leaf,
          validation: NodeNames.FoodBrand,
          component: ComponentName.BasicInput,
          input: {
            label: ' by %input%',
            placeholder: 'enter brand',
          },
        },
      ],
    },
    {
      name: 'Pet Health Issues',
      type: NodeType.branch,
      children: [
        {
          name: NodeNames.Issues,
          type: NodeType.leaf,
          validation: NodeNames.Issues,
          component: ComponentName.PetHealthCheckboxes,
          input: {
            label: 'Tap all that apply %input%',
            options: 'seed:HealthIssue',
          },
        },
      ],
    },
    {
      name: 'Pet Prescription Diets',
      type: NodeType.branch,
      children: [
        {
          name: NodeNames.PrescriptionDiets,
          type: NodeType.leaf,
          validation: NodeNames.PrescriptionDiets,
          component: ComponentName.PetHealthCheckboxes,
          input: {
            label:
              'What kind of prescription diet does %petName% need? %input%',
            options: 'seed:PrescriptionDiet',
          },
        },
      ],
    },
    {
      name: 'Recipes Form',
      type: NodeType.branch,
      component: ComponentName.RecipesFormWrapper,
      children: [
        {
          name: 'RecipeSelection',
          type: NodeType.branch,
          layout: LayoutType.none,
          component: ComponentName.RecipesForm,
          route: PATH_SIGNUP_RECIPES,
          children: [
            {
              name: NodeNames.FreshSelection,
              type: NodeType.leaf,
              component: ComponentName.RecipesPage,
              layout: LayoutType.block,
            },
          ],
        },
      ],
    },
  ],
};
