import createScreen from './createScreen';

import { corgiConfig } from './config/corgi';
import { legacyConfig } from './config/legacy';
import { corgiV2Config } from './config/corgiV2';
import { ViewportProps } from './types';

/** @deprecated Prefer `useViewport` via `@farmersdog/corgi-x` */
const {
  Viewport: CorgiViewport,
  ViewportDebug: CorgiViewportDebug,
  Orientation: CorgiOrientation,
  useViewport: useCorgiViewport,
  ScreenProvider: CorgiScreenProvider,
} = createScreen(corgiConfig);

/** @deprecated Prefer `useViewport` via `@farmersdog/corgi-x` */
const { Viewport, ViewportDebug, Orientation, useViewport, ScreenProvider } =
  createScreen(legacyConfig);

/** @deprecated Prefer `useViewport` via `@farmersdog/corgi-x` */
const {
  Viewport: CorgiV2Viewport,
  ViewportDebug: CorgiV2ViewportDebug,
  Orientation: CorgiV2Orientation,
  useViewport: useCorgiV2Viewport,
  ScreenProvider: CorgiV2ScreenProvider,
} = createScreen(corgiV2Config);

export {
  Viewport,
  ViewportDebug,
  Orientation,
  useViewport,
  ScreenProvider,
  CorgiViewport,
  CorgiViewportDebug,
  CorgiOrientation,
  useCorgiViewport,
  CorgiScreenProvider,
  CorgiV2Viewport,
  CorgiV2ViewportDebug,
  CorgiV2Orientation,
  useCorgiV2Viewport,
  CorgiV2ScreenProvider,
  ViewportProps,
};
