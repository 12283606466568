import * as Yup from 'yup';

export enum ClinicFormFieldName {
  VetName = 'clinic-vetName',
}
export interface ClinicFormProps {
  [ClinicFormFieldName.VetName]: string;
}

export const clinicFormInitialValues = {
  [ClinicFormFieldName.VetName]: '',
};

export const clinicFormSchema = Yup.object({
  [ClinicFormFieldName.VetName]: Yup.string(),
});
