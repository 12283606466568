import { Nowrap } from '@farmersdog/corgi-x';

import catriona from './assets/catriona';
import alex from './assets/alex';
import jonathan from './assets/jonathan';

export default [
  {
    name: 'Dr. Catriona Love',
    quote: (
      <>
        One of the most exciting innovations in pet food, The Farmer’s Dog is a
        safe alternative to <Nowrap>conventional brands.</Nowrap>
      </>
    ),
    iconSources: catriona,
  },
  {
    name: 'Dr. Alex Schechter',
    quote: (
      <>
        My patients love it! It’s tough to find a trustworthy home-cooked pet
        food brand. The Farmer’s Dog <Nowrap>delivers 100%.</Nowrap>
      </>
    ),
    iconSources: alex,
  },
  {
    name: 'Dr. Jonathan Block',
    quote: (
      <>
        I recommend Farmer’s Dog to our patients looking for a more healthful
        pet food. My own dogs love <Nowrap>it too!</Nowrap>
      </>
    ),
    iconSources: jonathan,
  },
];
