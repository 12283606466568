import { Link } from '@farmersdog/corgi';
import classNames from 'classnames';

import FacebookNewSvg from 'src/assets/icons/facebook-new.svg';
import InstagramNewSvg from 'src/assets/icons/instagram-new.svg';
import Tiktok from 'src/assets/icons/tiktok.svg';

import { links } from '@farmersdog/constants';
import styles from './FooterLinks.module.scss';

const socials = [
  {
    href: links.LINK_IG,
    icon: InstagramNewSvg,
    target: '_blank',
    rel: 'noopener noreferrer',
    'aria-label': 'Instagram',
  },
  {
    href: links.LINK_FB,
    icon: FacebookNewSvg,
    target: '_blank',
    rel: 'noopener noreferrer',
    'aria-label': 'Facebook',
  },
  {
    href: links.LINK_TK,
    icon: Tiktok,
    target: '_blank',
    rel: 'noopener noreferrer',
    'aria-label': 'TikTok',
  },
];

export const FooterSocialLinks = () => {
  return (
    <ul
      className={classNames(styles.list, styles.socialList)}
      aria-label="Social"
    >
      {socials.map(link => {
        if (!link) {
          return null;
        }

        const { icon: Icon, ...linkProps } = link;
        return (
          <li className={styles.item} key={link.href}>
            <Link
              variant="secondary"
              mode="light"
              {...linkProps}
              className={styles.contactLink}
            >
              {Icon && <Icon className={styles.icon} />}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
