/* CH#49502: Used by RET and CVR */
import './SuccessCheckmark.scss';

// https://codepen.io/haniotis/pen/KwvYLO

/** Render an animated SVG that can be used to display a success message. */
export default function SuccessCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      role="img"
      className="SuccessCheckmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      aria-hidden
      {...props}
    >
      <circle
        className="SuccessCheckmark-circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="SuccessCheckmark-path"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
}
