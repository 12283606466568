import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useFeature, ToggleTreatment } from 'src/abTesting';
import { browserOnly, navigateTo } from '@farmersdog/utils';

import { Path, Destination } from './config';
import { buildUtms } from './utils/build-utms';

export const cityTakeoverPaths = Object.values(Path);

function isValidPath(path: string): path is Path {
  return (Object.values(Path) as unknown as string).includes(path);
}

export function CityTakeover() {
  const { pathname } = useLocation();
  const { treatment, isReady } = useFeature<ToggleTreatment>(
    'cvr_citytakeover_redirects'
  );

  const redirectTo =
    treatment === 'on' ? Destination.Homepage : Destination.Instapage;

  useEffect(() => {
    browserOnly(
      window => {
        if (!isReady) {
          return null;
        }

        const lowerCasePathname = pathname.toLowerCase();

        if (isValidPath(lowerCasePathname)) {
          switch (redirectTo) {
            case Destination.Instapage: {
              const utms = buildUtms({
                path: lowerCasePathname,
                destination: Destination.Instapage,
              });
              window.location.replace(
                `https://discover.thefarmersdog.com${lowerCasePathname}?${utms}`
              );
              break;
            }
            case Destination.Homepage: {
              const utms = buildUtms({
                path: lowerCasePathname,
                destination: Destination.Homepage,
              });
              navigateTo(`/p${lowerCasePathname}?${utms}`);
              break;
            }
          }

          return null;
        }

        throw new Error(
          `CityTakeover matched path ${pathname} but provided no handler for the route`
        );
      },
      () => null
    );
  }, [pathname, redirectTo, treatment, isReady]);

  return null;
}
