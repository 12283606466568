import isNumber from 'lodash/isNumber';

import { cookie } from '@farmersdog/lead-browser-storage';

import { pixelRevenue } from '../../types';

import { configureGa4, ConfigureGa4Args } from './configureGa4';
import { Ga4Configuration } from './Ga4Script';
import {
  getGa4PageViewProperties,
  GetGa4PageViewArgs,
} from './getGa4PageViewProperties';
import {
  Ga4StandardEvent,
  Ga4CustomEvent,
  MountGa4EventArgs,
  mountGa4Event,
} from './mountGa4Event';

import type { Ga4Item } from './utils';

type MountEventArgs = Pick<MountGa4EventArgs, 'name' | 'properties'>;
type AddConfigArgs = Pick<ConfigureGa4Args, 'properties'>;

interface MeSuccessData {
  productLine: string;
}

interface MeSuccessArgs {
  data: MeSuccessData;
}

interface PetsSuccessData {
  productLine: string;
}

interface PetsSuccessArgs {
  data: PetsSuccessData;
}

interface RecipesSuccessData {
  items?: Ga4Item[];
}

interface RecipesSuccessArgs {
  data: RecipesSuccessData;
  isLastPet: boolean;
}

interface SendUserIdArgs {
  userId: number | undefined | null;
}

interface MeSuccessData {
  productLine: string;
}

interface MeSuccessArgs {
  data: MeSuccessData;
}

interface PetsSuccessData {
  productLine: string;
}

interface PetsSuccessArgs {
  data: PetsSuccessData;
}

interface RecipesSuccessData {
  items?: Ga4Item[];
}

interface RecipesSuccessArgs {
  data: RecipesSuccessData;
  isLastPet: boolean;
}

class Ga4Mounter {
  enabled = false;
  debugView = false;
  measurementId = '';
  loadSdk = false;

  configure(config: Ga4Configuration) {
    this.enabled = config.enabled;
    this.debugView = config.debugView;
    this.measurementId = config.measurementId;
    this.loadSdk = config.loadSdk;
  }

  get config() {
    return {
      enabled: this.enabled,
      debugView: this.debugView,
      measurementId: this.measurementId,
      loadSdk: this.loadSdk,
    };
  }

  get userId() {
    return cookie.getCorePostGresUserId();
  }

  pageView({ location }: GetGa4PageViewArgs) {
    const properties = getGa4PageViewProperties({ location });
    this.mountEvent({ name: Ga4StandardEvent.PageView, properties });
  }

  meSuccess({ data }: MeSuccessArgs) {
    this.mountEvent({
      name: Ga4CustomEvent.MeSuccess,
      properties: {
        product_line: data.productLine,
      },
    });
    this.mountEvent({
      name: Ga4StandardEvent.GenerateLead,
      properties: {
        currency: 'USD',
        value: pixelRevenue,
      },
    });
  }

  petsSuccess({ data }: PetsSuccessArgs) {
    this.mountEvent({
      name: Ga4CustomEvent.PetsSuccess,
      properties: {
        product_line: data.productLine,
      },
    });
  }

  recipesSuccess({ data, isLastPet }: RecipesSuccessArgs) {
    this.mountEvent({
      name: Ga4CustomEvent.RecipesSuccess,
    });
    if (isLastPet && data?.items?.length) {
      this.mountEvent({
        name: Ga4StandardEvent.AddToCart,
        properties: {
          currency: 'USD',
          value: pixelRevenue,
          items: data.items,
        },
      });
    }
  }

  sendUserId({ userId }: SendUserIdArgs) {
    if (userId) {
      this.addConfig({ properties: { user_id: userId } });
    }
  }

  addConfig({ properties }: AddConfigArgs) {
    configureGa4({ properties, config: this.config });
  }

  mountEvent({ name, properties }: MountEventArgs) {
    const userId = isNumber(properties?.user_id)
      ? String(properties?.user_id)
      : this.userId;
    const userIdProperty = userId ? { user_id: userId } : undefined;

    const eventProperties =
      properties || userIdProperty
        ? {
            ...properties,
            ...userIdProperty,
          }
        : undefined;

    mountGa4Event({ name, config: this.config, properties: eventProperties });
  }
}

const ga4Mounter = new Ga4Mounter();

export { ga4Mounter };
