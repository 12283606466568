import { Select } from '@farmersdog/corgi-x';
import { AddDogFormChangeHandler } from '../../../hooks';

interface SterilizationInputProps {
  value: boolean;
  onChange: AddDogFormChangeHandler;
}

export const SterilizationInput = (props: SterilizationInputProps) => {
  const { onChange, value } = props;
  const handleNeuteredStatusChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = e => {
    onChange('sterilization', e.target.value === 'Yes');
  };
  return (
    <Select
      name="sterilization"
      onChange={handleNeuteredStatusChange}
      label="Spayed or neutured status"
      value={value ? 'Yes' : 'No'}
      aria-required
    >
      <option value="No">No</option>
      <option value="Yes">Yes</option>
    </Select>
  );
};
