import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

import type { AnonymousExperimentFlags } from '../../middlewares/experimentsMiddleware';

const initialContext = {} as const;
const SSRAnonymousExperimentsContext = createContext<
  AnonymousExperimentFlags | typeof initialContext
>(initialContext);

export function SSRAnonymousExperimentsProvider({
  experiments,
  children,
}: {
  experiments: AnonymousExperimentFlags | undefined;
  children: ReactNode;
}) {
  const value = experiments ?? initialContext;
  return (
    <SSRAnonymousExperimentsContext.Provider value={value}>
      {children}
    </SSRAnonymousExperimentsContext.Provider>
  );
}

// We should change the name of this function to useAnonymousExperiments
// if we eventually decide to evaluate all of our anonymous experiments on the server
export function useSSRAnonymousExperiments() {
  return useContext(SSRAnonymousExperimentsContext);
}
