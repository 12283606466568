import PropTypes from 'prop-types';

/**
 * Convenience API for inline flex styling
 */
function Flex({
  as,
  className,
  children,
  inline,
  style,
  basis,
  grow,
  shrink,
  direction,
  wrap,
  justifyContent,
  alignContent,
  alignItems,
  alignSelf,
  ...props
}) {
  const HTMLElement = as;

  const computedStyle = {
    display: inline ? 'inline-flex' : 'flex',
    justifyContent,
    alignItems,
    flexDirection: direction,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
    flexWrap: wrap,
    alignContent,
    alignSelf,
    ...style,
  };

  return (
    <HTMLElement className={className} style={computedStyle} {...props}>
      {children}
    </HTMLElement>
  );
}

Flex.propTypes = {
  /** HTMLElement to render */
  as: PropTypes.string,
  /** @ignore React child nodes */
  children: PropTypes.node,
  /** @ignore CSS class name */
  className: PropTypes.string,
  /** Toggle inline behavior by using `display: inline-flex` instead of `flex */
  inline: PropTypes.bool,
  /** Shorthand for `flex-direction` property */
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
    'inherit',
    'initial',
    'unset',
  ]),
  /** Shorthand for `flex-basis` property */
  basis: PropTypes.oneOfType([
    PropTypes.oneOf([
      'auto',
      'fill',
      'content',
      'max-content',
      'min-content',
      'fit-content',
      'inherit',
      'initial',
      'unset',
    ]),
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Shorthand for `flex-grow` property */
  grow: PropTypes.oneOfType([
    PropTypes.oneOf(['inherit', 'initial', 'unset']),
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Shorthand for `flex-shrink` property */
  shrink: PropTypes.oneOfType([
    PropTypes.oneOf(['inherit', 'initial', 'unset']),
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Shorthand for `flex-wrap` property */
  wrap: PropTypes.oneOf([
    'wrap',
    'nowrap',
    'wrap-reverse',
    'inherit',
    'initial',
    'unset',
  ]),
  /** Native css property `justify-content` */
  justifyContent: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'center',
    'left',
    'right',
    'space-between',
    'space-around',
    'space-evenly',
    'stretch',
    'inherit',
    'initial',
    'unset',
  ]),
  /** Native css property `align-content` */
  alignContent: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'center',
    'baseline',
    'stretch',
    'inherit',
    'initial',
    'unset',
  ]),
  /** Native css property `align-items` */
  alignItems: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'center',
    'baseline',
    'stretch',
    'inherit',
    'initial',
    'unset',
  ]),
  /** Native css property `align-self` */
  alignSelf: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'center',
    'baseline',
    'stretch',
    'inherit',
    'initial',
    'unset',
  ]),
  /** @ignore Inline style prop */
  style: PropTypes.object,
};

Flex.defaultProps = {
  as: 'div',
};

export default Flex;
