import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, PageWidth } from '@farmersdog/corgi-x';

import { useCorgiViewport } from 'src/screen';

import styles from './Benefit.module.scss';

function Benefit({ icon, title, quote, author, picture }) {
  return (
    <div className={classNames(styles.container, useCorgiViewport(styles))}>
      <PageWidth>
        <figure className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <Text
            variant="heading-28"
            color="kale-3"
            bold
            as="figcaption"
            className={styles.title}
          >
            {title}
          </Text>
          <Text
            as="blockquote"
            color="charcoal-3"
            variant="article-20"
            className={styles.quote}
          >
            “{quote}”
          </Text>
          {author}
        </figure>
      </PageWidth>
      {picture && <div className={styles.picture}>{picture}</div>}
    </div>
  );
}

Benefit.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  quote: PropTypes.node.isRequired,
  author: PropTypes.node.isRequired,
  video: PropTypes.node,
  picture: PropTypes.node,
};

export default Benefit;
