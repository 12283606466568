import { useQuery } from '@apollo/client';
import {
  GetEligibleDiscountsQuery,
  GetEligibleDiscountsQueryVariables,
} from './getEligibleDiscounts.cgs.generated-types';
import getEligibleDiscounts from './getEligibleDiscounts.cgs.graphql';

export function useGetEligibleDiscounts() {
  return useQuery<
    GetEligibleDiscountsQuery,
    GetEligibleDiscountsQueryVariables
  >(getEligibleDiscounts, {
    context: { endpoint: 'cgs' },
  });
}
