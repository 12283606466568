import { RecipeType } from './types';

import {
  meatRecipeImageWebP,
  meatRecipeImageJpg,
  poultryRecipeImageWebP,
  poultryRecipeImageJpg,
} from './assets';

export const staticContent = {
  [RecipeType.Meat]: {
    petName: 'Redd',
    sources: [meatRecipeImageWebP, meatRecipeImageJpg],
  },
  [RecipeType.Poultry]: {
    petName: 'Bailey',
    sources: [poultryRecipeImageWebP, poultryRecipeImageJpg],
  },
};
