import { PageSectionProps } from '../../DIY';

import { Link } from '@farmersdog/corgi';
import { Text, GridContainer, Nowrap } from '@farmersdog/corgi-x';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { useCorgiV2Viewport } from 'src/screen';

import styles from './FreshBenefits.module.scss';
import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';

function FreshInstead() {
  return (
    <div className={styles.freshInstead}>
      <GridContainer className={styles.container}>
        <div className={styles.copy}>
          <Text variant="heading-22" color="white" bold as="h3">
            Want us to cook for you?
          </Text>
          <Text variant="heading-16" color="white">
            Get fresh, pre-portioned meals delivered
          </Text>
        </div>
        <Link
          to={getLinkToFreshSignup(PATH_SIGNUP_ME)}
          asButton
          className={styles.freshInsteadCta}
        >
          Build Your Fresh Plan
        </Link>
      </GridContainer>
    </div>
  );
}

function DogsThrive({ className }: PageSectionProps) {
  return (
    <div className={className}>
      <GridContainer>
        <Text
          variant="heading-40"
          color="kale-3"
          bold
          as="h2"
          className={styles.heading}
        >
          <Nowrap>Fresh food helps</Nowrap> dogs thrive.
        </Text>
        <Text variant="article-20" as="p" className={styles.copy}>
          Every dog should enjoy the life-changing benefits of a fresh diet. If
          you’re cooking fresh homemade dog food, adding our DIY Nutrient Mix
          ensures your meals are complete and balanced and safe for daily
          feeding.
        </Text>
      </GridContainer>
    </div>
  );
}

export function FreshBenefits({ className }: PageSectionProps) {
  const responsiveClassName = useCorgiV2Viewport<string>({
    xs: styles.xs,
    lg: '',
  });

  return (
    <section className={responsiveClassName}>
      <FreshInstead />
      <DogsThrive className={className} />
    </section>
  );
}
