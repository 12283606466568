import { useCallback } from 'react';
import { track, events } from 'src/analytics';
import { RouteData } from '../../hooks/useSignupRouter';
import { getCurrentStepFromRoute } from '../../hooks/utils';

interface UseAnalyticsArgs {
  answers?: {
    rootDecision: string;
    otherRootDecision: string;
    maxPrice: string;
    newProteins: string[];
    customNewProtein: string;
    avoidFishMeatBlendProteins: string[];
    avoidOtherFishMeatBlendMeatProtein: string;
    avoidOtherFishMeatBlendFishProtein: string;
    avoidFishProteins: string[];
    avoidOtherFishProtein: string;
    avoidMeatBlendProteins: string[];
    avoidOtherMeatBlendProtein: string;
    vegetables: Record<string, string>;
    additionalIngredients: Record<string, string>;
  };
  previousSlides?: string[];
  currentSlide?: string;
  currentRoute?: RouteData;
}

const useAnalytics = ({
  answers,
  previousSlides,
  currentSlide,
  currentRoute,
}: UseAnalyticsArgs = {}) => {
  const trackAnswerSelected = (name: string, value: string) => {
    const signupStep = currentRoute && getCurrentStepFromRoute(currentRoute);
    track(events.exit_intent_selected, {
      name,
      value,
      signup_step: signupStep,
    });
  };

  const trackAnswersSubmitted = useCallback(() => {
    const signupStep = currentRoute && getCurrentStepFromRoute(currentRoute);
    track(events.exit_intent_completed, {
      ...answers,
      exitIntentBranch: [...(previousSlides || []), currentSlide],
      signup_step: signupStep,
    });
  }, [answers, currentSlide, previousSlides, currentRoute]);

  return {
    trackAnswerSelected,
    trackAnswersSubmitted,
  };
};

export default useAnalytics;
