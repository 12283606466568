export interface Step {
  id: number;
  title: string;
  description: string;
}

export const STEPS: Array<Step> = [
  {
    id: 1,
    title: 'Build Your Plan',
    description:
      'Simply tell us a little bit about your dog, and we’ll create a plan with personalized portions to meet their unique nutritional and caloric needs.',
  },
  {
    id: 2,
    title: 'Try it Out',
    description:
      'To start, you’ll get your first box with up to two weeks of freshly made food – perfect for your dog to get a taste.',
  },
  {
    id: 3,
    title: 'Fall in Love',
    description:
      'If you and your dog love it – we think you will! – you’re all set. You’ll start receiving boxes of food automatically whenever you’re running low.',
  },
];
