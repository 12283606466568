import { InMemoryCacheConfig } from '@apollo/client';

export const cacheConfig: InMemoryCacheConfig = {
  // Use this configuration to change cache for specific typename
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
  typePolicies: {
    MyUserView: {
      keyFields: ['id'],
    },
    MySubscriptionView: {
      keyFields: ['id'],
    },
    MyPetView: {
      keyFields: ['id'],
    },
    // Disable caching of PlanRecipes. With cache Apollo assumes all foodPlan
    // recipes are the same since they have the same id and will use the
    // same recipe's ratio for all foodPlans.
    PlanRecipe: {
      keyFields: false,
    },
    MyOrderViewConnection: {
      keyFields: false,
    },
    MyOrderViewEdge: {
      keyFields: ['node', ['id']],
    },
    OrderMetadataPlanStats: {
      keyFields: false,
    },
    LineItemProductMetadata: {
      keyFields: false,
    },
    LineItemPetMetadata: {
      keyFields: false,
    },
    LineItemFoodPlanMetadata: {
      keyFields: false,
    },
    LineItemPlanProductMetadata: {
      keyFields: false,
    },
    LineItemSubscriptionAddOnMetadata: {
      keyFields: false,
    },
    LineItemStatsRecipeMetadata: {
      keyFields: false,
    },
    LeadView: {
      keyFields: ['email'],
    },
    CgsOrderDetailsOrderMetadataStatsPlan: {
      keyFields: false,
    },
  },
};
