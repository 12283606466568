/* CH#49502: Used by RET and CVR */
import { Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ENTER } from 'src/constants/keycodes';

import './Menu.scss';

function Menu({ children, ...props }) {
  const className = classNames('Menu', props.className);
  return (
    <ul {...props} className={className}>
      {children}
    </ul>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function Item({ children, selected = false, onClick, ...props }) {
  let tabIndex;
  let handleKeyUp;

  if (onClick) {
    tabIndex = 0;
    handleKeyUp = e => {
      e.preventDefault();
      e.stopPropagation();
      if (e.keyCode === ENTER) {
        onClick(e);
      }
    };
  }

  return (
    <li
      className={selected ? 'selected' : undefined}
      tabIndex={tabIndex}
      onKeyUp={handleKeyUp}
      onClick={onClick}
      {...props}
    >
      <Text role="link" variant="heading-16" color="charcoal-3" bold>
        {children}
      </Text>
    </li>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

Menu.Item = Item;
export default Menu;
