/**
 * Returns the value associated with query string key
 *
 * @example
 * ```ts
 * getPathQueryValue(
 *  "?key=value&anotherKey=anotherValue",
 *  "anotherKey"
 * )
 * ```
 *
 * Outputs: "anotherValue"
 *
 * @param queryPath - URL query path
 * @param queryKey - String to search query path keys
 */
export function getPathQueryValue(
  queryPath: string,
  queryKey: string
): string | undefined {
  // can't have a query path with only 2 characters
  if (queryPath.length <= 2) return undefined;

  const queryKeyValuePairs = new URLSearchParams(queryPath);

  let queryValue: string | undefined;

  queryKeyValuePairs.forEach((value, key) => {
    if (key.toLowerCase() === queryKey.toLowerCase()) {
      queryValue = value.toLowerCase();
    }
  });

  return queryValue;
}
