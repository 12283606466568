import { PATH_PROFILE } from '@farmersdog/constants/paths';
import { Button } from '@farmersdog/corgi-x';

import styles from './UpdateBillingErrorPage.module.css';

export interface UpdateBillingErrorPageProps {
  onClose: () => void;
}

export function UpdateBillingErrorPage({
  onClose,
}: UpdateBillingErrorPageProps): JSX.Element {
  return (
    <div className={styles.container}>
      <Button type="link" to={PATH_PROFILE} className={styles.update}>
        Update
      </Button>
      <Button
        type="button"
        variant="plain-text"
        className={styles.close}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
}
