import { PATH_HOME } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi';
import { Logo } from '@farmersdog/corgi-x';

import {
  PreviousLinkNavigationLabel,
  UsePreviousLinkNavigationReturn,
} from '../../../../hooks/usePreviousLinkNavigation';
import { BackButton } from '../BackButton';
import { StepData } from '../hooks/useSteps';

import styles from './LargeNavigation.module.css';
import { Steps } from './Steps';

interface LargeNavigationProps extends UsePreviousLinkNavigationReturn {
  stepData: StepData;
}

export function LargeNavigation(props: LargeNavigationProps) {
  const { stepData } = props;

  return (
    <nav className={styles.container} data-testid="header-nav-desktop">
      <div className={styles.logoContainer}>
        <Link
          to={PATH_HOME}
          aria-label={PreviousLinkNavigationLabel.homePage}
          data-testid="header-logo-desktop"
        >
          <Logo variant="product" size={50} className={styles.logo} />
        </Link>
        <BackButton {...props} />
      </div>
      <Steps stepData={stepData} />
    </nav>
  );
}
