import * as actionType from 'src/constants/actionTypes';

import calculateProgress from './utils/calculateProgress';

export const initialState = {
  overall: {
    me: 0,
    pets: 0,
    plans: 0,
    checkout: 0,
  },
  me: {
    email: false,
    firstName: false,
    petsCount: false,
    petsNames: false,
    zip: false,
    freshFoodConfidence: false,
  },
  pets: [],
  plans: [],
};

export default function signupProgressReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.SIGNUP_REGISTER:
    case actionType.SIGNUP_FETCH_PREVIOUS_USER:
    case actionType.SIGNUP_REPLACE_USER:
    case actionType.SIGNUP_UPDATE_FOOD_PLAN:
    case actionType.SIGNUP_SET_DIY_SUBSCRIPTION:
    case actionType.SIGNUP_PROGRESS_RESET: {
      return Object.assign({}, state, { progress: calculateProgress(state) });
    }
    default:
      return state;
  }
}

export function selectProgress(state) {
  return state.signup.progress;
}
