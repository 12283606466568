import classNames from 'classnames';
import { useCorgiViewport } from 'src/screen';

import { PageWidth } from '@farmersdog/corgi-x';

import SectionDogContent from './SectionDogContent';
import DogImage from './DogImage';

import styles from './SectionDog.module.scss';

function XSView() {
  return (
    <section>
      <div className={styles.container}>
        <PageWidth>
          <SectionDogContent />
          <DogImage />
        </PageWidth>
      </div>
    </section>
  );
}

function LGView() {
  return (
    <section>
      <PageWidth>
        <div
          className={classNames(
            styles.container,
            useCorgiViewport({ lg: styles.lg })
          )}
        >
          <div className={styles.content}>
            <SectionDogContent />
          </div>
          <DogImage />
        </div>
      </PageWidth>
    </section>
  );
}

export default function SectionDog() {
  return useCorgiViewport({
    xs: <XSView />,
    lg: <LGView />,
  });
}
