import { useId } from 'react';
import { Button, Text } from '@farmersdog/corgi-x';
import { formatPhoneNumber } from '../../../utils';

import styles from './ReviewAccountInformation.module.css';

interface Address {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  stateAbbreviation: string;
  zip: string;
}

export interface ReviewAccountInformationProps {
  accountInformation: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  shippingAddress: Address;
  billingAddress: Address;
  creditCard: {
    last4Digits: string;
  };
  onEdit: () => void;
}

export function ReviewAccountInformation(props: ReviewAccountInformationProps) {
  const id = useId();
  const shippingId = `shipping-${String(id)}`;
  const accountId = `account-${String(id)}`;
  const billingId = `billing-${String(id)}`;

  return (
    <section>
      <section className={styles.subsection} aria-labelledby={accountId}>
        <Text
          bold
          variant="heading-22"
          as="h4"
          id={accountId}
          className={styles.subheading}
        >
          User Account
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.accountInformation.firstName}{' '}
          {props.accountInformation.lastName}
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.accountInformation.email}
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          {formatPhoneNumber(props.accountInformation.phone)}
        </Text>
      </section>
      <section className={styles.subsection} aria-labelledby={shippingId}>
        <Text
          bold
          variant="heading-22"
          as="h4"
          id={shippingId}
          className={styles.subheading}
        >
          Shipping Address
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.shippingAddress.addressLine1}
        </Text>
        {props.shippingAddress.addressLine2 && (
          <Text variant="heading-16" as="p" className={styles.content}>
            {props.shippingAddress.addressLine2}
          </Text>
        )}
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.shippingAddress.city},{' '}
          {props.shippingAddress.stateAbbreviation} {props.shippingAddress.zip}
        </Text>
      </section>
      <section aria-labelledby={billingId}>
        <Text
          bold
          variant="heading-22"
          as="h4"
          id={billingId}
          className={styles.subheading}
        >
          Credit Card
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          **** **** **** {props.creditCard.last4Digits}
        </Text>
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.billingAddress.addressLine1}
        </Text>
        {props.billingAddress.addressLine2 && (
          <Text variant="heading-16" as="p" className={styles.content}>
            {props.billingAddress.addressLine2}
          </Text>
        )}
        <Text variant="heading-16" as="p" className={styles.content}>
          {props.billingAddress.city}, {props.billingAddress.stateAbbreviation}{' '}
          {props.billingAddress.zip}
        </Text>
      </section>
      <Button
        onClick={props.onEdit}
        className={styles.editAccountLink}
        variant="plain-text"
      >
        <Text variant="heading-16" as="span" bold>
          Edit Account Details
        </Text>
      </Button>
    </section>
  );
}
