import { Grid, GridItem, Page } from '@farmersdog/corgi-x';
import { BufferSummary } from '../buffer';
import { reporter } from '../errors/services';
import { FreshFoodConfidence, JourneyStatus } from '../graphql/types.cgs';
import { PacksAndPortions } from '../packs-and-portions';
import { StockOverview } from '../stock-overview';
import {
  HomepageEssentialsPrismic,
  HomepageOrdersSection,
  HomepageSlot,
  WelcomeMessage,
} from './features';
import { useHomepagePersonalization } from './features/HomepagePersonalization';
import { PickyEaterTips } from './features/PickyEaterTips/PickyEaterTips';
import { PlanProgression } from './features/PlanProgression/PlanProgression';
import { HomepageSlotId } from './Homepage.slots';
import { useFetchHomepageData } from './network';

export interface HomepageProps {
  justInTimeEnabled: boolean;
}

export type CustomerAttributes = {
  journeyStatus?: JourneyStatus;
  hasPickyEater?: boolean;
  freshFoodConfidence?: FreshFoodConfidence;
  daysSinceCheckout?: number;
  isMultiPet?: boolean;
};

export function Homepage(props: HomepageProps) {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving homepage data', { sourceError: error });
    },
  });

  const { showPickyTips, showPlanProgression, updateWelcomeMessageCopy } =
    useHomepagePersonalization();

  return (
    <Page>
      <HomepageSlot slotId={HomepageSlotId.Hero} />

      <WelcomeMessage
        firstName={request.data?.customer.accountInformation.firstName}
        pets={request.data?.customer.pets}
        journeyStatus={request.data?.customer.journeyStatus}
        loading={request.loading}
        showExperimentPickyCopy={updateWelcomeMessageCopy}
      />
      <HomepageOrdersSection
        nextOrderToBeDelivered={request.data?.customer.nextOrderToBeDelivered}
        loading={request.loading}
      />

      {showPlanProgression && (
        <PlanProgression
          pets={request.data?.customer.pets}
          journeyStatus={request.data?.customer.journeyStatus}
          regularOrdersDeliveredCount={
            request.data?.customer.freshSubscription.regularOrdersDeliveredCount
          }
          orders={request.data?.customer.orders}
          hasTransitionOrder={
            request.data?.customer.userCharacteristics.hasTransitionOrder
          }
          frequency={request.data?.customer.freshSubscription.frequency}
        />
      )}

      {showPickyTips && <PickyEaterTips />}

      {(request.data?.customer.journeyStatus ===
        JourneyStatus.AwaitingFirstBox ||
        request.data?.customer.journeyStatus ===
          JourneyStatus.ReceivedFirstBox) && <HomepageEssentialsPrismic />}
      {request.data?.customer.journeyStatus ===
        JourneyStatus.ReceivedSecondBox && (
        <AfterSecondBoxComponents justInTimeEnabled={props.justInTimeEnabled} />
      )}
    </Page>
  );
}

function AfterSecondBoxComponents({
  justInTimeEnabled,
}: {
  justInTimeEnabled: boolean;
}) {
  return (
    <>
      {justInTimeEnabled ? (
        <Grid columnGap="md" rowGap="md">
          <GridItem lg={6}>
            <StockOverview />
          </GridItem>
          <GridItem lg={6}>
            <BufferSummary />
          </GridItem>
        </Grid>
      ) : (
        <StockOverview />
      )}
      <PacksAndPortions />
    </>
  );
}
