// TODO: PAW Config: Convert get-properties to TS
// https://app.shortcut.com/farmersdog/story/83450/paw-config-convert-get-properties-to-ts
/* eslint-disable no-undef */
/**
 * If evaluate is true, this is being consumed by Webpack's val-loader,
 * and will return a strinigified module with our config properties evaluated.
 * If evaluate is false, returns config properties as a pojo.
 */

/* global window */
let isRegistered = false;

module.exports = function ({ evaluate = false } = {}) {
  // For server usage
  if (!process.env.PAW_IS_LIBRARY) {
    if (!isRegistered) {
      const { register } = require('@swc-node/register/register');
      register();

      isRegistered = true;
    }

    const config = require('./convictConfig').default;

    // For PAW development mode
    if (evaluate) {
      const serverProperties = JSON.stringify(config.getProperties());

      return {
        code: `module.exports = () => { return ${serverProperties} };`,
        cacheable: false,
      };
    }

    return config.getProperties();
  }

  if (process.env.PAW_IS_LIBRARY) {
    // When bundled as website server
    if (!process.env.BROWSER) {
      const convictSchema =
        // @ts-expect-error we're reaching out of this package to the website but the tsconfig doesn't like that
        require('../../../website/src/config/config').default;
      const convict = require('convict');
      const convictConfig = convict(convictSchema);

      convictConfig.validate({ allowed: 'strict' });

      return convictConfig.getProperties();
    }

    // When bundled with website
    return window.__APP_CONFIG__;
  }
};
