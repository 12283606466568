import {
  Button,
  Link,
  Text,
  Divider,
  Page,
  Picture,
} from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';
import { useHistory } from 'react-router';

import { formatPetNames } from '../../../utils/formatPetNames';

import { trackCancelSubscriptionDiyDownsellCta } from '../../analytics';

import styles from './DiyDownsell.module.css';
import illustration from './assets/pot-and-whisk.png';
import image from './assets/diy-meal.jpg';

export interface PetWithName {
  name: string;
}

export interface DiyDownsellProps {
  onClose: () => void;
  onPrevious: () => void;
  pets: PetWithName[];
}

export function DiyDownsell({ onClose, onPrevious, pets }: DiyDownsellProps) {
  const history = useHistory();

  const petNameCopy = formatPetNames(
    pets.map(pet => pet.name),
    'your dogs',
    { possessive: true }
  );

  const handleCtaClick = () => {
    trackCancelSubscriptionDiyDownsellCta();
    history.push(paths.PATH_DIY_DOWNSELL);
  };

  return (
    <Page
      className={styles.page}
      layout="base"
      onBackClick={onPrevious}
      onCloseClick={onClose}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      <div className={styles.container}>
        <Text
          variant="article-20"
          as="p"
          color="charcoal-3"
          topSpacing="sm"
          bottomSpacing="lg"
        >
          You’ll no longer receive deliveries of {petNameCopy} fresh, healthy
          food.
        </Text>
        <div className={styles.divider}>
          <Divider
            orientation="horizontal"
            style="dotted"
            color="Chickpea2"
            width={4}
          />
          <Picture
            sources={[illustration]}
            alt="illustration of pot and whisk"
            className={styles.illustration}
          />
        </div>
        <Text variant="heading-40" bold color="kale-3" as="h2">
          Planning to cook at home?
        </Text>
        <Text variant="article-20" as="p" vSpacing="sm">
          The most critical part of making dog food is ensuring it’s complete &
          balanced. 95% of recipes lack key nutrients – but our DIY Nutrient Mix
          makes it easy.
        </Text>
        <Picture sources={[image]} alt="diy meal" className={styles.image} />
        <section className={styles.list}>
          <Text variant="heading-22" bold color="kale-3" as="p" vSpacing="sm">
            Want to try our DIY Nutrient Mix?
          </Text>
          <ul>
            <Text as="li" variant="article-20">
              An easy supplement to balance homemade meals
            </Text>
            <Text as="li" variant="article-20">
              Includes 20+ simple, vet-developed recipes
            </Text>
            <Text as="li" variant="article-20">
              Starts at just $1 per week
            </Text>
          </ul>
          <div className={styles.buttons}>
            <Button type="button" onClick={handleCtaClick}>
              See DIY Recipes
            </Button>
            <Text variant="heading-16" as="p">
              <Link to={paths.PATH_CUSTOMER_ACCOUNT}>
                No thanks, I have an approved balanced recipe
              </Link>
            </Text>
          </div>
        </section>
      </div>
    </Page>
  );
}
