import { ReactNode } from 'react';
import UserError from './UserError';
import { Extra, Severity } from './ApplicationError';

type ValidationErrorArgs =
  | [ReactNode, Extra, Severity]
  | [ReactNode, Extra]
  | [ReactNode, Severity]
  | [ReactNode]
  | [];

export interface Validation {
  [field: string]: string;
}

/**
 * Use a `ValidationError` to create a validation error.
 *
 * - Validation error messages are meant to appear in the UI.
 * - The optional `paramName` can be used to tell which param was not valid.
 * - Validation errors have always severity set as `info`
 *
 * @example
 *
 * ```ts
 *  new ValidationError('Something went wrong with an unknown param');
 *  new ValidationError('First name is not valid', 'firstName');
 *  new ValidationError('Address does not exists', 'shippingAddress.addressLine1');
 * ```
 */
class ValidationError extends UserError {
  validation: Validation;

  /** Overload for a simple constructor with just a message */
  constructor(message: string);

  /** Overload for a constructor with a message and param */
  constructor(message: string, param: string);

  /** Overload for a constructor with a message, param and display message */
  constructor(message: string, param: string, displayMessage: ReactNode);

  /**
   * Overload for a constructor with a message, param, display message, and
   * extra
   */
  constructor(
    message: string,
    param: string,
    displayMessage: ReactNode,
    extra: Extra
  );

  /**
   * Overload for a constructor with a message, param, display message and
   * severity
   */
  constructor(
    message: string,
    param: string,
    displayMessage: ReactNode,
    severity: Severity
  );

  /**
   * Overload for a constructor with a message, param, display message, extra
   * and severity
   */
  constructor(
    message: string,
    param: string,
    displayMessage: ReactNode,
    extra: Extra,
    severity: Severity
  );

  /**
   * Initialize a new instance of a validation error.
   *
   * TODO: Revise these errors to be less confusing
   * https://app.clubhouse.io/farmersdog/story/35490/chore-create-an-architecture-proposal-for-better-client-error-handling
   *
   * @param message - A simple error message
   * @param displayMessage - A message that supports react
   * @param extra - Extra data to add to the event
   * @param severity - The event's severity level
   */
  constructor(
    message: string,
    param = 'unknown',
    ...args: ValidationErrorArgs
  ) {
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/28010
    super(message, ...args);
    this.name = 'ValidationError';
    this.validation = {
      [param]: message,
    };
  }
}

export default ValidationError;
