const food = 'food';
const plan = 'creating-your-plan';
const subscription = 'subscription';
const shipping = 'shipping';

const foodTitle = 'Our Food';
const planTitle = 'Creating Your Plan';
const shippingTitle = 'Packaging and Shipping';
const subscriptionTitle = 'Your Subscription';

export const scrollAnchorNames = {
  food,
  plan,
  subscription,
  shipping,
};

export const titles = {
  foodTitle,
  planTitle,
  shippingTitle,
  subscriptionTitle,
};

export const menuItems = [
  {
    name: food,
    label: foodTitle,
  },
  {
    name: plan,
    label: planTitle,
  },
  {
    name: shipping,
    label: shippingTitle,
  },
  {
    name: subscription,
    label: subscriptionTitle,
  },
];
