import * as Path from '@farmersdog/constants/paths';

export const AMAZON_TAG_REGION = 'NA';
export const AMAZON_TAG_ID = 'acb4d62f-3a66-486f-a297-53cc3d873776';

export const amazonTagPageViewRoutes = [
  Path.PATH_HOME,
  Path.PATH_SIGNUP_ME,
  Path.PATH_SIGNUP_RECIPES,
  Path.PATH_SIGNUP_CHECKOUT,
  Path.PATH_SIGNUP_SUCCESS,
];
