import {
  PATH_CUSTOMER_ACCOUNT,
  PAW_PATH_ACCOUNT,
} from '@farmersdog/constants/paths';
import { useRouteMatch } from 'react-router';

const CUSTOMER_PORTAL_PATHS = [PATH_CUSTOMER_ACCOUNT, PAW_PATH_ACCOUNT];

/** Determines if the provided pathname is a customer portal path (excludes login)  */
export function isAuthenticatedPath(pathname: string) {
  return CUSTOMER_PORTAL_PATHS.some(p => pathname.match(p));
}

/** A React hook that determines via React Router if the customer is visiting a
 * customer portal path (excludes login)  */
export function useIsAuthenticatedPath() {
  const match = useRouteMatch(CUSTOMER_PORTAL_PATHS);
  return Boolean(match);
}
