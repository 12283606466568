import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import fastdom from 'fastdom';
import { browserOnly } from '@farmersdog/corgi';
import { PATH_DIY, PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { DIY_FAQ } from './components/FAQ/constants/ids';

import Page from 'src/components/Page';
import {
  Hero,
  FreshBenefits,
  VitalMix,
  HowTo,
  FreshUpsell,
  FAQ,
  Recipes,
} from './components';
import { useTrackHomepageSuccess } from '../Signup/hooks/useTrackHomepageSuccess';

import { useSetSignupFlow, useSetSignupFlowByUrl } from '../Signup/DIY/hooks';
import { getLinkToDiySignup } from '../Signup/DIY/utils';

import { FullFooter } from 'src/components/Footer';

import styles from './DIY.module.scss';

import { useCorgiV2Viewport } from 'src/screen';
import classNames from 'classnames';

import { Navigation } from 'src/components/Navigation';
import { Banner, Text } from '@farmersdog/corgi-x';

export interface PageSectionProps {
  className?: string;
}

const valueProps = [
  'Gently cooked in the U.S.',
  'Pre-portioned packs',
  'Convenient deliveries',
];

export function DIY() {
  const { unlockDiySignupFlow } = useSetSignupFlow();
  useSetSignupFlowByUrl();
  useTrackHomepageSuccess();
  const location = useLocation();

  useEffect(() => {
    unlockDiySignupFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobileClassName = useCorgiV2Viewport<string>({
    xs: styles.xs,
    lg: '',
  });

  const pageSectionClassName = classNames(styles.pageSection, mobileClassName);

  const CTA = { text: 'Get Started', to: getLinkToDiySignup(PATH_SIGNUP_ME) };
  const ctaReveal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.pathname === PATH_DIY && location.hash === `#${DIY_FAQ}`) {
      browserOnly((window, document) => {
        fastdom.measure(() => {
          const el = document.getElementById(DIY_FAQ);

          if (el) {
            // chrome doesn't work with smooth scrollIntoView in this component
            const y = el.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: y });
          }
        });
      });
    }
    // depends on location.key so that clicking on the faq link when current hash is already #diy-faq will still trigger a scroll
  }, [location.hash, location.pathname, location.key]);

  return (
    <Page
      title="DIY Nutrient Mix for Homemade Dog Food"
      description="Make your homemade dog food complete and balanced with The Farmer’s Dog’s DIY nutrient mix. 
                   Check out homemade dog food recipes for your home cooked dog food."
    >
      <Navigation
        ctaTrigger={ctaReveal}
        banner={
          <Banner>
            <Text variant="heading-16" color="white">
              Free shipping on DIY orders. Because dogs.
            </Text>
          </Banner>
        }
        ctaDetails={CTA}
      />
      <Hero />
      <div ref={ctaReveal}>
        <FreshBenefits className={pageSectionClassName} />
        <VitalMix className={pageSectionClassName} />
        <HowTo />
        <Recipes />
        <FreshUpsell
          headingText="Or we can cook (and deliver) it for you!"
          valueProps={valueProps}
          ctaText="Try Our Fresh Meals"
        />
        <FAQ className={pageSectionClassName} />
      </div>
      <FullFooter />
    </Page>
  );
}
