import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Input, TextV2 } from '@farmersdog/corgi';
import { Button } from '@farmersdog/corgi-x';

import ActionButtons from './ActionButtons';

import { useFreshCheckoutQuote } from 'src/pages/SignupCheckoutPage/hooks/useFreshCheckoutQuote';

import { formatCurrency } from 'src/utils/formatters';

import { SLIDE_NAME as PRICING_OPTIONS } from './PricingOptions';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { TITLE } from './copy';
function MaxPrice({ maxPrice, onAnswerEvent, goToPrevSlide, setCurrentSlide }) {
  const { regularPricePerWeek } = useFreshCheckoutQuote();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 variant="body-16" as="p">
          Your subscription is {formatCurrency(regularPricePerWeek)} per week.
        </TextV2>
        <TextV2 variant="body-16" as="p">
          What is the most you would pay for a fresh diet per week?
        </TextV2>
        <Input
          name="maxPrice"
          label=" "
          type="number"
          onChange={onAnswerEvent}
          value={maxPrice}
        />
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={() => setCurrentSlide(PRICING_OPTIONS)}
          >
            Continue
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

MaxPrice.propTypes = {
  maxPrice: PropTypes.string.isRequired,
  onAnswerEvent: PropTypes.func.isRequired,
  goToPrevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
};

export const SLIDE_NAME = 'MAX_PRICE';

export default MaxPrice;
