import {
  Feature as OnbHomepagePersonalizationPickyFeature,
  SplitKey as OnbHomepagePersonalizationPickySplitKey,
} from './features/OnbHomepagePersonalizationPicky';
import {
  Feature as OnbHomepagePlanProgressionFeature,
  SplitKey as OnbHomepagePlanProgressionSplitKey,
} from './features/OnbHomepagePlanProgression';

export enum CustomerClinicSearchTreatments {
  off = 'off',
  on = 'on',
  hide_search = 'hide_search',
}

export const userFeatures = {
  cancel_inflight_warning: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  customer_cx_phone_number: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: {
        footer: {
          email: true,
          phone: true,
        },
        faq: {
          email: true,
          phone: true,
        },
        diySupport: {
          phone: true,
          email: true,
        },
      },
    },
  },
  ret_holidaycontent_toggle: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  ret_holidayfaqs_toggle: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  ret_pet_calibration_toggle: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  ret_addpet_toggle: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  ssp_schedule_card: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  [OnbHomepagePersonalizationPickySplitKey]:
    OnbHomepagePersonalizationPickyFeature,
  [OnbHomepagePlanProgressionSplitKey]: OnbHomepagePlanProgressionFeature,
  HEALTH_CUSTOMER_CLINIC_SEARCH: {
    treatments: Object.values(CustomerClinicSearchTreatments),
    track: true,
    mock: {
      treatment: CustomerClinicSearchTreatments.on,
      config: null,
    },
  },
  adj_add_dog_form: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  ssp_free_first_treat_enabled: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'off',
      config: null,
    },
  },
  adj_pre_grace_period: {
    treatments: ['on', 'off'],
    track: true,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
};

export const anonymousFeatures = {
  prospective_cx_phone_number: {
    treatments: ['on', 'off'],
    track: false,
    mock: {
      treatment: 'on',
      config: {
        footer: {
          phone: true,
          email: true,
        },
        diySupport: {
          phone: false,
          email: true,
        },
      },
    },
  },
};
