import { UseFeatureHook } from '../../types';

export const defaultFeatureData = {
  treatment: 'control',
  config: {} as Record<string, unknown>,
};

export const useFeaturePlaceholder: UseFeatureHook = () => {
  const getFeatureData = () => defaultFeatureData;

  return {
    isReady: true,
    treatment: defaultFeatureData.treatment,
    config: defaultFeatureData.config,
    getFeatureData,
  };
};
