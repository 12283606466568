import { GridItem, Picture, Text } from '@farmersdog/corgi-x';
import styles from './PictureWithHeadingItem.module.css';

interface PictureWithHeadingProps {
  sources: string[];
  alt: string;
  value: string;
  variant?: 'profile' | 'recipe';
}

export const PictureWithHeadingItem = (props: PictureWithHeadingProps) => {
  const { sources, alt, value, variant = 'profile' } = props;
  return (
    <GridItem
      justifyContent="flex-start"
      flexDirection="column"
      alignItems="center"
      xs={4}
      className={styles.gridItem}
    >
      <Picture
        sources={sources}
        alt={alt}
        className={
          variant === 'profile' ? styles.profileImage : styles.recipeImage
        }
      />
      <Text
        as="h3"
        variant="heading-16"
        bold
        bottomSpacing="none"
        className={styles.heading}
      >
        {value}
      </Text>
    </GridItem>
  );
};
