import { CgsBodyCondition } from '../../graphql/types.cgs';

interface BodyCondition {
  name: string;
  description: string;
}

const bodyConditionMap: { [k in CgsBodyCondition]: BodyCondition } = {
  [CgsBodyCondition.TooSkinny]: {
    name: 'Too Skinny',
    description: 'Visible rib cage, possible loss of muscle mass.',
  },
  [CgsBodyCondition.JustRight]: {
    name: 'Just Right',
    description:
      'Clear waistline and tucked in belly. Last ribs may be visible.',
  },
  [CgsBodyCondition.Rounded]: {
    name: 'Rounded',
    description:
      'Waistline is disappearing, difficult to feel ribs. Broad back.',
  },
  [CgsBodyCondition.Chunky]: {
    name: 'Chunky',
    description: 'Waistline and ribs are lost. Weight is a serious concern.',
  },
};

export const getBodyCondition = (
  bodyCondition: CgsBodyCondition | null
): BodyCondition | undefined => {
  return bodyCondition ? bodyConditionMap[bodyCondition] : undefined;
};
