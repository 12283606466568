import events from '../eventNames';
import track from '../track';

interface TrackRescheduleDateSceneClickContinueProperties {
  rescheduledDate: string | undefined;
}
/**
 * Track clicks to the "Continue" button in the customer portal Reschedule flow
 * date selector scene
 * */
export function trackRescheduleDateSceneClickContinue({
  rescheduledDate,
}: TrackRescheduleDateSceneClickContinueProperties) {
  return track(events.reschedule_date_scene_click_continue, {
    rescheduledDate,
  });
}
