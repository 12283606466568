import isEmpty from 'lodash/isEmpty';

export interface RequestAttributes {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  utm_content?: string;
  referrer_url?: string;
}

export interface LoadRequestAttributesFromWindowCacheArgs {
  initialState: Record<string, unknown> & {
    referrer: {
      requestAttributes: RequestAttributes | undefined;
    };
  };
}

export function loadRequestAttributesFromWindowCache({
  initialState,
}: LoadRequestAttributesFromWindowCacheArgs) {
  const cachedRequestAttributes = window?.__requestAttributes;
  if (!cachedRequestAttributes || isEmpty(cachedRequestAttributes)) {
    return initialState;
  }

  return {
    ...initialState,
    referrer: {
      ...initialState.referrer,
      requestAttributes: cachedRequestAttributes,
    },
  };
}
