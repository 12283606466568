import { useState, useEffect } from 'react';

import { trackRecipesPlanDetails } from '../analytics';
import { usePetNamesAndRequestedCalories } from '../components/RecipesPage/hooks';
import { addRequestedCaloriesToPets } from '../components/RecipesPage/utils/addRequestedCaloriesToPets';
import { useQuote } from '../graphql/queries/useQuote';
import { FetchQuoteQuery, PetInputForQuote } from '../graphql/types';
import { getQuoteDiscountType } from '../utils/getQuoteDiscountType/getQuoteDiscountType';

import { getDiscountInput } from './useLeadState/utils/convertFormStateToLeadInput/getDiscountInput';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';

interface UseFetchQuoteArgs {
  currentPetName: string;
  shouldFetchQuote: boolean;
  petsInput: Omit<PetInputForQuote, 'requestedCalories'>[] | null;
  isRecommendedPlan?: boolean;
}

export const useFetchQuote = ({
  currentPetName,
  shouldFetchQuote,
  petsInput,
  isRecommendedPlan,
}: UseFetchQuoteArgs) => {
  const discount = getDiscountInput();
  const { petNamesAndRequestedCalories } = usePetNamesAndRequestedCalories();

  const [quote, setQuote] = useState<FetchQuoteQuery['fetchQuote'] | null>(
    null
  );
  const [quoteQuery, quoteQueryState] = useQuote({
    fetchPolicy: 'network-only', // Ensure new quotes are always fetched
  });

  const throwToErrorBoundary = useThrowToErrorBoundary();
  useEffect(() => {
    const shouldRunEffect =
      petsInput &&
      petNamesAndRequestedCalories &&
      !quoteQueryState.loading &&
      (shouldFetchQuote || !quoteQueryState.called);

    if (!shouldRunEffect) return;

    const petsWithRecommendedCalories = addRequestedCaloriesToPets(
      petsInput,
      petNamesAndRequestedCalories
    );
    const discountType =
      (discount?.type && getQuoteDiscountType(discount.type)) || null;

    quoteQuery({
      variables: {
        pets: petsWithRecommendedCalories,
        shippingZip: null,
        discountCode: discount?.code || null,
        discountType,
      },
    })
      .then(response => {
        if (response.error) return throwToErrorBoundary(response.error);
        const data = response.data?.fetchQuote;
        if (data) {
          setQuote(data);
          trackRecipesPlanDetails({
            currentPetName,
            petsInput,
            quote: data,
            isRecommendedPlan,
          });
        }
      })
      .catch(error => {
        return throwToErrorBoundary(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldFetchQuote,
    discount?.code,
    discount?.type,
    quoteQuery,
    throwToErrorBoundary,
    currentPetName,
    petsInput,
    quoteQueryState.called,
    petNamesAndRequestedCalories,
  ]);

  return { quote, quoteQueryState };
};
