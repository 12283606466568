interface ShouldTrackArgs {
  path: string;
  blacklist: string[];
}

export function shouldTrack({ path, blacklist }: ShouldTrackArgs) {
  if (!blacklist.length) {
    return true;
  }

  return !blacklist.includes(path);
}
