import type { BranchNode } from '../../../../blueprint/types';
import type { UseFormNavigateReturn } from '../../../../types';

interface GetStepPercentageCompleteArgs {
  formSteps: BranchNode[];
  path: string;
  current: UseFormNavigateReturn['current'];
}

export const excludedNodeNames = ['GetStarted'];

export function getStepPercentageComplete({
  formSteps,
  path,
  current,
}: GetStepPercentageCompleteArgs) {
  const excludedNodeNamesSet = new Set<string>(excludedNodeNames);
  const pageSteps = formSteps.filter(
    formStep =>
      formStep.route === path && !excludedNodeNamesSet.has(formStep.name)
  );
  const currentStepIndex = pageSteps.findIndex(
    pageStep => pageStep.__self__ === current
  );

  if (pageSteps.length && currentStepIndex > 0) {
    return (currentStepIndex / pageSteps.length) * 100;
  }

  return 0;
}
