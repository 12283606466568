import { session } from '@farmersdog/utils';

export const RECOVERY_MODAL_KEY = 'hasViewedSignupRecoveryOption';

function get(): string | null {
  return session.getItem(RECOVERY_MODAL_KEY);
}

function set(token: 'true'): void {
  session.setItem(RECOVERY_MODAL_KEY, token);
}

function remove(): void {
  session.removeItem(RECOVERY_MODAL_KEY);
}

export const signupRecoveryModalState = {
  get,
  set,
  remove,
};
