import classNames from 'classnames';

import { Picture } from '@farmersdog/corgi-x';

import DesktopGIF from '../assets/kibbleVsFreshDesktop.gif';
import MobileGIF from '../assets/kibbleVsFreshMobile.gif';
import responsiveStyles from '../PlanCalculationWithLoader.module.css';

import styles from './KibbleOrFresh.module.css';

interface ResponsiveKibbleOrFresh {
  isMobile?: boolean;
}

function ResponsiveKibbleOrFresh({ isMobile }: ResponsiveKibbleOrFresh) {
  return (
    <Picture
      className={classNames(
        styles.image,
        isMobile ? responsiveStyles.mobile : responsiveStyles.desktop
      )}
      alt='A piece of kibble accompanied by the description "A Highly Processed Pellet" and a bit of The Farmer’s Dog food accompanied by the description "Fresh, Healthy Meat & Veggies"'
      sources={isMobile ? [MobileGIF] : [DesktopGIF]}
    />
  );
}

export const KibbleOrFresh = () => {
  return (
    <>
      <ResponsiveKibbleOrFresh isMobile />
      <ResponsiveKibbleOrFresh />
    </>
  );
};
