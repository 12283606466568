import { useMutation } from '@apollo/client';
import {
  AddClinicMutation,
  AddClinicMutationVariables,
} from './AddClinic.cgs.generated-types';
import AddClinic from './AddClinic.cgs.graphql';

export const useAddClinic = () => {
  return useMutation<AddClinicMutation, AddClinicMutationVariables>(AddClinic, {
    context: { endpoint: 'cgs' },
  });
};
