import { PAW_SUGGEST_ORDER_SIZE } from '@farmersdog/constants/paths';
import { reporter } from '../../../../../errors/services';
import { createSlottableComponent } from '../../../../../slots';
import { useFetchHomepageData } from '../../../../network';
import { MaybeLaterModal, useMaybeLaterModal } from '../../../MaybeLaterModal';
import { Nudge } from '../../../Nudge';
import {
  trackOrderSizeNudgeMaybeLater,
  trackOrderSizeNudgeSeePlanOptions,
} from './analytics';
import { useOrderSizeNudgeStorage, useEligibility } from './hooks';
import * as assets from './assets';
import * as copy from './OrderSizeDismissibleCta.copy';

function OrderSizeDismissibleCtaComponent() {
  const { data } = useFetchHomepageData({
    onError: (err: unknown) => {
      reporter.warn('Error retrieving data for the Order Size CTA', {
        sourceError: err,
      });
    },
  });

  const { isOpen, openModal, closeModal } = useMaybeLaterModal();
  const { getOrderSizeNudgeDismissed, setOrderSizeNudgeDismissed } =
    useOrderSizeNudgeStorage(window.localStorage);

  const isDismissed = getOrderSizeNudgeDismissed();

  const nudgeBody = copy.templates.body({
    subscriptionFrequency: data?.customer.freshSubscription.frequency ?? 0,
  });

  return (
    <>
      {/* Dismiss Confirm */}
      <MaybeLaterModal
        // Functionality
        isOpen={isOpen}
        onClose={closeModal}
        // Images
        imageSources={assets.maybeLaterBuddyJada.sources}
        imageAltText={assets.maybeLaterBuddyJada.altText}
        // Copy
        headlineText={copy.maybeLaterHeadline}
        bodyText={copy.maybeLaterBody}
        closeCtaText={copy.maybeLaterCloseCta}
        headlineColor={copy.colors.maybeLaterHeadline}
        bodyTextColor={copy.colors.maybeLaterBodyText}
      />
      {/* Dismissible CTA */}
      {!isDismissed && (
        <Nudge
          // Functionality
          onMaybeLaterClick={openModal}
          setNudgeDismissed={setOrderSizeNudgeDismissed}
          ctaOnClick={trackOrderSizeNudgeSeePlanOptions}
          trackDismissCta={trackOrderSizeNudgeMaybeLater}
          ctaLinkDestination={PAW_SUGGEST_ORDER_SIZE}
          // Images
          imageSources={assets.planBox.sources}
          imageAltText={assets.planBox.altText}
          // Copy
          body={nudgeBody}
          headlineText={copy.headline}
          subheadText={copy.subhead}
          ctaText={copy.cta}
          componentTitle={copy.componentTitle}
          dismissCtaText={copy.dismissCta}
        />
      )}
    </>
  );
}

export const OrderSizeDownDismissibleCta = createSlottableComponent(
  OrderSizeDismissibleCtaComponent,
  useEligibility
);
