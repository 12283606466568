import {
  Lazy,
  Schema,
  addMethod,
  array,
  lazy,
  mixed,
  object,
  string,
} from 'yup';

import { Blueprint, Fragment } from '../types';

addMethod(mixed, 'oneOfSchemas', function (schemas: Schema[]) {
  return this.test(
    'one-of-schemas',
    '${path} does not match at least one of the allowed schemas',
    item => schemas.some(schema => schema.isValidSync(item, { strict: true }))
  );
});

export const leafFragmentSchema = object()
  .shape({
    name: string().required(),
    type: string().matches(/leaf/, { excludeEmptyString: true }).required(),
    route: string(),
    validation: string(),
    component: string().required(),
    input: object(),
  })
  .noUnknown()
  .label('Leaf Fragment');

export const branchFragmentSchema = object()
  .shape({
    name: string().required(),
    type: string()
      .matches(/branch/, { excludeEmptyString: true })
      .required(),
    route: string(),
    validation: string(),
    component: string(),
    input: object(),
    children: array()
      .of(
        // @ts-expect-error Recursion does not play well in TS
        lazy<Fragment>(() => fragmentSchema.default(undefined) as Lazy<unknown>)
      )
      .required(),
  })
  .noUnknown()
  .label('Branch Fragment');

export const fragmentSchema = (
  mixed() as unknown as { oneOfSchemas: (x: Schema[]) => Schema }
)
  .oneOfSchemas([leafFragmentSchema, branchFragmentSchema])
  .label('Fragment');

export const blueprintSchema = object()
  .shape({
    version: string().required().label('version'),
    root: (fragmentSchema.required() as Schema).label('root'),
    fragments: array().of(fragmentSchema).label('fragments'),
  })
  .label('Blueprint');

export function validateBlueprint(blueprint: unknown): blueprint is Blueprint {
  return !!blueprintSchema.validateSync(blueprint, { strict: true });
}
