import desktopLovedByHumansJpg from './loved-by-humans-background-desktop.jpg';
import desktopLovedByHumansWebp from './loved-by-humans-background-desktop.webp';

import mobileLovedByHumansJpg from './loved-by-humans-background-mobile.jpg';
import mobileLovedByHumansWebp from './loved-by-humans-background-mobile.webp';

export const mobileLovedByHumans = {
  webP: mobileLovedByHumansWebp,
  jpg: mobileLovedByHumansJpg,
};

export const desktopLovedByHumans = {
  webP: desktopLovedByHumansWebp,
  jpg: desktopLovedByHumansJpg,
};
