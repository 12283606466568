import { Text, Page, Grid, GridItem, Divider } from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import styles from './PaymentRetry.module.css';
import { PATH_PROFILE } from '@farmersdog/constants/paths';
import { GladlyService } from '../../cx';
import { StickyButton } from './StickyButton';

import { trackPaymentRetryLiveChatCtaClicked } from '../analytics';
export function PaymentRetryFailure() {
  const history = useHistory();
  const handleOnClose = () => {
    history.push(PATH_PROFILE);
  };
  const heading =
    'We were unable to process payment with the card on file. Please contact us for additional support.';
  const handleOnCTAClick = () => {
    trackPaymentRetryLiveChatCtaClicked();
    GladlyService.show();
  };
  const ctaButtonDataTestId = 'payment_retry_failure_cta';
  const ctaText = 'Live Chat Support';

  return (
    <Page layout="base-centered" onCloseClick={handleOnClose}>
      <Grid
        className={styles.grid}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <GridItem>
          <Text as="h1" variant="heading-40" color="kale-3" bold vSpacing="md">
            Payment Failed
          </Text>
        </GridItem>
        <GridItem>
          <Text
            variant="article-20"
            color="charcoal-3"
            className={styles.headingSection}
          >
            {heading}
          </Text>
        </GridItem>
        <Divider borderStyle="none" />

        <StickyButton
          handleOnCTAClick={handleOnCTAClick}
          buttonVariant="secondary"
          buttonDataTestId={ctaButtonDataTestId}
          ctaText={ctaText}
        />
      </Grid>
    </Page>
  );
}
