export function formatCommaSeparatedList(words: string[]) {
  if (words.length <= 1) {
    return words.join();
  }

  if (words.length === 2) {
    return words.join(' and ');
  }

  const lastElement = words.pop();
  const joinedWords = words.join(', ');
  return `${joinedWords}, and ${lastElement}`;
}
