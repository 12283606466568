import events from '../eventNames';
import track from '../track';

interface Args {
  selectedStartDate: string;
  previousStartDate: string;
}

/**
 * Send a tracking event for when a user changes their start date using the UI
 * on the checkout page.
 *
 * @param previousStartDate - The start date that was changed
 * @param selectedStartDate - The start date that was selected using the UI
 */
export function trackCheckoutChangedStartDate({
  previousStartDate,
  selectedStartDate,
}: Args): void {
  track(events.checkout_changed_start_date, {
    previousStartDate,
    selectedStartDate,
  });
}
