/* eslint-disable @typescript-eslint/no-unsafe-return */
import { browserOnly } from './browserOnly';

let memoryStorage: Record<string, string> = {};

// TODO: Update this polyfill to use the correct API
// https://app.clubhouse.io/farmersdog/story/51577/chore-update-browserstorage-polyfill-to-match-spec
interface StorageStub {
  getItem(key: string): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
  clear(): void;
}

type StorageType = 'localStorage' | 'sessionStorage';

const stub = {
  setItem: (key: string, value: string) => {
    memoryStorage[key] = value;
  },
  getItem: (key: string): string | null => {
    return memoryStorage[key] || null;
  },
  clear: () => {
    memoryStorage = {};
  },
  removeItem: (key: string) => {
    delete memoryStorage[key];
  },
};

interface UntestedWindow {
  localStorage?: Storage;
  sessionStorage?: Storage;
  DOMException?: DOMException;
}

/**
 * Return if a value is an instance of DOMException
 *
 * @param value - The unknown value to test
 */
function isDOMException(value: unknown): value is DOMException {
  if (typeof DOMException === 'undefined') {
    return false;
  }

  return value instanceof DOMException;
}

/**
 * This is the recommended way of testing for a
 * storage from MDN, slightly modified to work on
 * node.
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 */
function getStorage(type: StorageType): StorageStub {
  return browserOnly(
    (window: UntestedWindow) => {
      let storage;
      try {
        storage = window[type];
        const x = '__storage_test__';
        // @ts-expect-error Accessing a possibly undefined method is the test
        storage.setItem(x, x);
        // @ts-expect-error Accessing a possibly undefined method is the test
        storage.removeItem(x);
        return window[type] as Window[StorageType];
      } catch (e) {
        if (
          isDOMException(e) &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        ) {
          return window[type] as Window[StorageType];
        }

        return stub;
      }
    },
    () => stub
  );
}

export const local = getStorage('localStorage');
export const session = getStorage('sessionStorage');
