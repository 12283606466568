import { local } from '@farmersdog/utils';
import type { SessionData } from '../utils';

import { isValidSessionData } from '../utils';

export const STORAGE_KEY = 'tfd-segment-session-data';

export type LocalStorageSessionDataReturn = SessionData | null;

/**
 * Retrieve the session id from local storage
 */
function get(): LocalStorageSessionDataReturn {
  try {
    const sessionDataString = local.getItem(STORAGE_KEY);
    if (!sessionDataString) {
      return null;
    }

    const parsedSessionData = JSON.parse(sessionDataString) as unknown;
    return isValidSessionData(parsedSessionData) ? parsedSessionData : null;
  } catch {
    return null;
  }
}

/**
 * Write the session id to local storage
 */
function set(sessionData: SessionData) {
  const sessionDataString = JSON.stringify(sessionData);
  local.setItem(STORAGE_KEY, sessionDataString);
}

export const localStorageSessionData = {
  get,
  set,
};
