import {
  addEligibleSubscriptionType,
  formatEligibleSubscriptionTypes,
  getEligibleSubscriptionTypes,
  setCurrentSignupSubscriptionType,
} from '@farmersdog/lead-browser-storage';

import { SubscriptionType } from 'src/graphql/types';
import { useUpdateMySignupSubscriptionType } from 'src/graphql/mutations/useUpdateMySignupSubscriptionType/useUpdateMySignupSubscriptionType';

export function useSetSignupFlow() {
  const [updateMySignupSubscriptionType] = useUpdateMySignupSubscriptionType({
    onError: () => console.error,
  });

  const setActiveSignupFlow = (activeSubscriptionType: SubscriptionType) => {
    setCurrentSignupSubscriptionType(activeSubscriptionType);
    void updateMySignupSubscriptionType({
      currentSignupSubscriptionType: activeSubscriptionType,
    });
  };

  const enableDiySignupFlow = () => {
    setActiveSignupFlow(SubscriptionType.Diy);
  };

  const enableFreshSignupFlow = () => {
    setActiveSignupFlow(SubscriptionType.Fresh);
  };

  const unlockDiySignupFlow = () => {
    addEligibleSubscriptionType(SubscriptionType.Diy);

    const eligibleTypes = getEligibleSubscriptionTypes();

    void updateMySignupSubscriptionType({
      eligibleSignupSubscriptionTypes:
        formatEligibleSubscriptionTypes(eligibleTypes),
    });
  };

  return {
    setActiveSignupFlow,
    enableFreshSignupFlow,
    enableDiySignupFlow,
    unlockDiySignupFlow,
  };
}
