import { AxiosRequestConfig } from 'axios';
import { UnknownAction } from 'redux';

export interface RequestAction extends UnknownAction {
  meta: Record<string, unknown>;
  payload: {
    request: AxiosRequestConfig;
  };
}

/**
 * Return `true` if an action is a request action (i.e. it should perform a request using axios)
 *
 * @param {Object} action The action being dispatched
 * @return {Boolean}
 */
export function isRequestAction(action: unknown): action is RequestAction {
  return Boolean(
    typeof action === 'object' &&
      action !== null &&
      'payload' in action &&
      typeof action.payload === 'object' &&
      action.payload &&
      'request' in action.payload &&
      typeof action.payload.request === 'object' &&
      action.payload.request &&
      'url' in action.payload.request &&
      action.payload.request.url
  );
}
