import { Link, Arrow } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { UsePreviousLinkNavigationReturn } from '../../../../hooks/usePreviousLinkNavigation';

import styles from './BackButton.module.css';

export function BackButton(props: UsePreviousLinkNavigationReturn) {
  const {
    navigatePreviousLinkLabel,
    handleNavigatePrevious,
    previousSignupRoute,
  } = props;

  return (
    <div className={styles.container}>
      <Link
        to={previousSignupRoute}
        variant="secondary"
        mode="dark"
        onClick={handleNavigatePrevious}
        aria-label={navigatePreviousLinkLabel}
      >
        <Arrow size={12} className={styles.arrow} />
        <Text variant="heading-16">back</Text>
      </Link>
    </div>
  );
}
