import { QueryParameter } from '@farmersdog/constants';
import { PATH_LOGIN, PATH_PASSWORD_RESET } from '@farmersdog/constants/paths';
import { Modal, Link, Divider } from '@farmersdog/corgi';
import { MARGIN_MD, OYSTER_0 } from '@farmersdog/corgi/build/constants';
import { Text } from '@farmersdog/corgi-x';

import { useGlobalLightbox, LightboxId } from '../../../hooks';

import styles from './HasAccountModal.module.css';

interface HasAccountModalProps {
  email: string;
  handleCloseModal: () => void;
}
export function HasAccountModal({
  email,
  handleCloseModal,
}: HasAccountModalProps) {
  const lightboxControl = useGlobalLightbox({
    id: LightboxId.HasAccountModal,
  });

  return (
    <Modal {...lightboxControl} disableInteractions={true}>
      <Text
        as="h2"
        variant="heading-40"
        color="kale-3"
        bold
        className={styles.title}
        {...lightboxControl.labelProps}
      >
        Hi again!
      </Text>
      <Text variant="article-16" as="p" {...lightboxControl.descriptionProps}>
        Looks like you’re already signed up.
      </Text>
      <Divider margin={MARGIN_MD} color={OYSTER_0} orientation="horizontal" />
      <div className={styles.buttons}>
        <div className={styles.login}>
          <Link
            asButton
            to={`${PATH_LOGIN}?${QueryParameter.Email}=${email}`}
            aria-label="Login Page"
            onClick={handleCloseModal}
          >
            <Text variant="heading-16" bold>
              Login now
            </Text>
          </Link>
        </div>
        <div className={styles.resetLink}>
          <Link
            to={`${PATH_PASSWORD_RESET}?${QueryParameter.Email}=${email}`}
            aria-label="Password Recovery Page"
            onClick={handleCloseModal}
          >
            <Text variant="heading-16" bold>
              Recover password
            </Text>
          </Link>
        </div>
      </div>
    </Modal>
  );
}
