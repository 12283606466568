import { ApolloError, useQuery } from '@apollo/client';
import {
  OrderType,
  SubscriptionStatus,
  SubscriptionType,
} from '../../../graphql/types.core-api';
import { determineSubscription } from '../../../utils';
import { customerAccountQuery } from '../graphql';
import { CustomerAccountPetFragment } from '../graphql/fragments/CustomerAccountPet.core-api.generated-types';
import { CustomerAccountReferralStatsFragment } from '../graphql/fragments/CustomerAccountReferralStats.core-api.generated-types';
import { CustomerAccountSubscriptionFragment } from '../graphql/fragments/CustomerAccountSubscription.core-api.generated-types';
import { CustomerAccountQuery } from '../graphql/queries/CustomerAccount.core-api.generated-types';

export interface CustomerAccountData {
  userId: number;
  firstName: string | undefined;
  email: string;
  subscription: CustomerAccountSubscriptionFragment | undefined;
  activePets: CustomerAccountPetFragment[] | undefined;
  referralStats: CustomerAccountReferralStatsFragment | undefined;
  withRegularOrder: boolean;
  isSubscriptionActive: boolean;
  isSubscriptionPending: boolean;
  isSubscriptionSuspended: boolean;
  isSubscriptionFresh: boolean;
  isCustomerFlaggedForFraud: boolean;
}

export type UseCustomerAccountQuery = {
  loading: boolean;
  error?: ApolloError;
  data?: CustomerAccountData;
};

export function useCustomerAccountQuery(): UseCustomerAccountQuery {
  const { data, loading, error } =
    useQuery<CustomerAccountQuery>(customerAccountQuery);

  if (!data) {
    return { loading, error };
  }

  const subscriptions = data.me.subscriptions;
  const activePets = data.me.pets ?? undefined;
  const referralStats = data.me.referralStats ?? undefined;

  const subscription = determineSubscription(subscriptions);

  const withRegularOrder =
    subscription.ordersConnection.edges?.some(
      edge => edge.node.type === OrderType.Regular
    ) ?? false;

  const isSubscriptionActive =
    subscription.status === SubscriptionStatus.Active;

  const isSubscriptionPending =
    subscription.status === SubscriptionStatus.Pending;

  const isSubscriptionSuspended =
    subscription.status === SubscriptionStatus.Suspended;

  const isSubscriptionFresh = subscription.type === SubscriptionType.Fresh;

  const isCustomerFlaggedForFraud = data.me.status === 'disabled';

  const userId = data.me.id;
  const firstName = data.me.firstName ?? undefined;
  const email = data.me.email;

  return {
    data: {
      userId,
      firstName,
      email,
      activePets,
      subscription,
      referralStats,
      withRegularOrder,
      isSubscriptionActive,
      isSubscriptionPending,
      isSubscriptionSuspended,
      isSubscriptionFresh,
      isCustomerFlaggedForFraud,
    },
    loading,
    error,
  };
}
