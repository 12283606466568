import { Script } from '../../Script';

import { NEXTDOOR_PIXEL_ID } from './mountNextDoorEvent';

export function NextDoorScript() {
  return (
    <Script type="text/javascript">
      {`(function(win, doc, sdk_url){
            if(win.ndp) return;
            var tr=win.ndp=function(){
            tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
          };
            tr.queue = [];
            var s='script';
            var new_script_section=doc.createElement(s);
            new_script_section.async=!0;
            new_script_section.src=sdk_url;
            var insert_pos=doc.getElementsByTagName(s)[0];
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
          })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
            ndp('init','${NEXTDOOR_PIXEL_ID}', {});`}
    </Script>
  );
}
