import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLightboxControl } from '@farmersdog/corgi';

import usePreviousEffect from 'src/hooks/usePreviousEffect';
import { hideError } from 'src/actions/ui';
import { selectError, selectOptions } from 'src/reducers/ui';

import ErrorModal from './ErrorModal';

/**
 * This component is used by Application to detect when an error is dispatched
 * by `showError` and display the modal.
 */
function ShowErrorModal() {
  const location = useLocation();
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const options = useSelector(selectOptions);

  const handleHide = () => {
    dispatch(hideError());
    options?.onHide?.();
  };

  const lightboxControl = useLightboxControl({
    onClose: handleHide,
  });

  usePreviousEffect((previousLocation, currentLocation) => {
    if (previousLocation.pathname !== currentLocation.pathname) {
      handleHide();
    }
  }, location);

  useEffect(() => {
    if (error && !lightboxControl.isOpen) {
      lightboxControl.open();
    }
  }, [error, lightboxControl]);

  if (!error) {
    return null;
  }

  return (
    <ErrorModal
      title={options?.title}
      code={options?.errorCode}
      captureException={options?.captureException}
      onHide={handleHide}
      error={error}
      {...lightboxControl}
    />
  );
}

export default ShowErrorModal;
