import * as Apollo from '@apollo/client';

import { useQuery } from '../../services/apollo';
import {
  DoesPhoneNumberExistQueryVariables,
  DoesPhoneNumberExistQuery,
} from '../types';

import doesPhoneNumberExist from './doesPhoneNumberExist.graphql';

type Options = Apollo.QueryHookOptions<
  DoesPhoneNumberExistQuery,
  DoesPhoneNumberExistQueryVariables
>;

export function useDoesPhoneNumberExist(options?: Options) {
  return useQuery<
    DoesPhoneNumberExistQuery,
    DoesPhoneNumberExistQueryVariables
  >(doesPhoneNumberExist, options);
}
