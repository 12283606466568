import { Pixel } from '../../Pixel';

/* 
  NOTE: This is a unique implementation where we have two separate pixels using the same setup.
*/

// iHeartRadio

export const I_HEART_RADIO_PIXEL_ID = 23456;

export enum IHeartRadioEventName {
  PAGE_VIEW = 'homepage_visits',
  LEAD_CONVERSION = 'leads_petspage',
  CHECKOUT_SUCCESS = 'signups',
}

export enum IHeartRadioEventId {
  PAGE_VIEW = 56816,
  LEAD_CONVERSION = 56817,
  CHECKOUT_SUCCESS = 56818,
}

// Audacy

export const AUDACY_PIXEL_ID = 21865;

export enum AudacyEventName {
  PAGE_VIEW = 'sitewide',
  CHECKOUT_SUCCESS = 'signup',
}

export enum AudacyEventId {
  PAGE_VIEW = 50830,
  CHECKOUT_SUCCESS = 50831,
}

// Pixel

type ClaritasAttributes = {
  ppt: number;
  ord: number;
};

type AudacyAttributes = ClaritasAttributes & {
  g: AudacyEventName;
  gid: AudacyEventId;
};

type IHeartRadioAttributes = ClaritasAttributes & {
  g: IHeartRadioEventName;
  gid: IHeartRadioEventId;
};

export const claritas = new Pixel<AudacyAttributes | IHeartRadioAttributes>({
  vendorName: 'Claritas',
  baseUrl: 'https://trkn.us/pixel/conv/',
  stringifyOptions: {
    delimiter: ';',
  },
});
