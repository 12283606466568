import { useState, useEffect } from 'react';

import { Nowrap } from '@farmersdog/corgi-x';

const NUMBER_OF_DOTS = 3;

interface AnimatedEllipsisProps {
  time: number;
}

export function AnimatedEllipsis({ time }: AnimatedEllipsisProps) {
  const [ticks, setTicks] = useState(0);
  const transitionTime = time / NUMBER_OF_DOTS;
  const transition = `opacity ${transitionTime}ms ease-in-out`;

  useEffect(() => {
    const interval = setInterval(() => {
      setTicks(currentTicks => {
        if (currentTicks > NUMBER_OF_DOTS) return 0;
        return currentTicks + 1;
      });
    }, transitionTime);

    return () => {
      clearInterval(interval);
    };
  }, [transitionTime]);

  return (
    <Nowrap>
      {[...Array.from({ length: NUMBER_OF_DOTS })].map((_, index) => (
        <span
          key={index}
          style={{ opacity: ticks >= index + 1 ? 1 : 0, transition }}
        >
          .
        </span>
      ))}
    </Nowrap>
  );
}
