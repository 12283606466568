import { Grid, Text } from '@farmersdog/corgi-x';
import { formatCurrency } from '../../../../utils';
import { SubCopyLineItem } from './SubCopyLineItem';

interface OrderSizeDescriptionProps {
  daysOfFood: number;
  dailyConsumptionPrice: number;
  orderTotal: number;
  subCopy: string[];
}

/**
 * Render a description of an order size, with sub copy and price.
 */
export function OrderSizeDescription(props: OrderSizeDescriptionProps) {
  return (
    <Grid rowGap="sm" flexDirection="column">
      <Text variant="heading-22" color="kale-3" as="h2" bold>
        {`${props.daysOfFood} Days of Food`}
      </Text>

      {props.subCopy.map(copy => {
        return <SubCopyLineItem copy={copy} key={copy} />;
      })}

      <Text variant="heading-16" color="charcoal-3" bold>
        {`${formatCurrency(props.orderTotal)} `}
        {`(${formatCurrency(props.dailyConsumptionPrice)}/day)*`}
      </Text>
    </Grid>
  );
}
