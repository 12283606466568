import { ContentWidth } from '@farmersdog/corgi';
import { Wave, PageWidth } from '@farmersdog/corgi-x';
import { Text, type TextProps } from '@farmersdog/corgi-x';

import BenefitsListCheckmarkSvg from './assets/benefits-list-checkmark.svg';

import { useCorgiV2Viewport, useCorgiViewport } from 'src/screen';
import styles from './Benefits.module.scss';

import classNames from 'classnames';

type BenefitsListProps = Pick<ListProps, 'valueProps'>;
export interface ListProps {
  valueProps: string[];
}

function BenefitsList({ valueProps }: BenefitsListProps) {
  const responsiveClassName = useCorgiV2Viewport<string>({
    xs: '',
    lg: styles.lg,
  });

  const listVariant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-12',
    lg: 'heading-16',
  });

  return (
    <ul className={classNames(styles.benefitsContainer, responsiveClassName)}>
      {valueProps.map(valueProp => {
        return (
          <li key={valueProp}>
            <BenefitsListCheckmarkSvg className={styles.icon} />
            <Text variant={listVariant} color="kale-3" bold>
              {valueProp}
            </Text>
          </li>
        );
      })}
    </ul>
  );
}

export function Benefits({ valueProps }: ListProps) {
  const titleVariant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-22',
    lg: 'heading-28',
  });

  return (
    <section>
      <Wave padding="margin-lg" withBottom withTop>
        <PageWidth>
          <ContentWidth fr={3}>
            <Text
              className={styles.underline}
              variant={titleVariant}
              bold
              as="h3"
            >
              Benefits
            </Text>
            <BenefitsList valueProps={valueProps} />
          </ContentWidth>
        </PageWidth>
      </Wave>
    </section>
  );
}
