import classNames from 'classnames';
import { useEffect, useId, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import {
  getNodeNameAndPosition,
  getPositionedProperty,
} from '../../../blueprint/utils';
import { useWeightLossPersonalizationExperiment } from '../../../hooks';
import { useLabel } from '../../../hooks/useLabel';
import {
  ExtendedTextInputChangeEvent,
  TOSAComponentInput,
  TOSALeafNode,
} from '../../../types';
import {
  getInputAttributes,
  normalizeWeight,
  scrollOncePerSession,
} from '../../../utils';
import { InlineInput, FormHint } from '../base';

import styles from './PetWeightInput.module.css';

export function PetWeightInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature } = props;
  const { getValues, register, setValue, trigger } = formMethods;
  const registerProps = register(node.name);
  const { frontLabel, backLabel } = useLabel({
    node,
    getValues,
  });
  const { name: nodeName, position } = getNodeNameAndPosition(node.name);

  const bodyCondition = getPositionedProperty({
    formValues: getValues(),
    nodeName: node.name,
    property: 'bodyCondition',
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { showBodyConditionPersonalization } =
    useWeightLossPersonalizationExperiment({
      useFeature,
      bodyCondition: bodyCondition as string,
    });

  const isTargetWeightNode = nodeName === NodeNames.TargetWeight;

  const shouldScrollToWrapper =
    isTargetWeightNode && showBodyConditionPersonalization;

  useEffect(() => {
    if (shouldScrollToWrapper) {
      scrollOncePerSession({ ref: wrapperRef, id: node.name });
    }
  }, [shouldScrollToWrapper, node.name]);

  const formHintClass = classNames({
    [styles.weightFormHint]: nodeName === NodeNames.Weight,
    [styles.targetWeightFormHint]: isTargetWeightNode,
  });

  const formHint = node?.input?.hint;
  const hintId = useId();

  const inputAttributes = getInputAttributes(node);

  const handleChange = async (event: ExtendedTextInputChangeEvent) => {
    const { value } = event.target;
    await registerProps.onChange(event);
    const normalizedWeight = normalizeWeight(value);

    setValue(node.name, normalizedWeight, { shouldValidate: true });

    if (node.name === `weight-${position}`) {
      await trigger(`targetWeight-${position}`);
    }
  };

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      {formHint && (
        <FormHint id={hintId} className={formHintClass}>
          {formHint}
        </FormHint>
      )}
      <div className={styles.frontLabel}>{frontLabel}</div>
      <div className={styles.inputContainer}>
        <InlineInput
          className={styles.input}
          {...inputAttributes}
          {...registerProps}
          aria-describedby={formHint && hintId}
          onChange={handleChange}
        />
        {backLabel}
      </div>
    </div>
  );
}
