import { UserFeatureKeys } from '../../../app/lib/Split';
import { useExperimentContext } from '../../../experiments';

/**
 * Determines whether a user should see the schedule card.
 */
export function useShouldShowScheduleCard() {
  const { useFeature: getFeature } = useExperimentContext();

  return getFeature?.(UserFeatureKeys.ssp_schedule_card, {}).treatment === 'on';
}
