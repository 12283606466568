export const PREPAID_CARD = `We can’t accept prepaid cards. Please enter a valid credit or debit card.`;
export const UNKNOWN_STRIPE = `Uh oh something went wrong.`;
export const CREDIT_CARD_TITLE = `Credit Card not accepted`;
export const SHIPPING_SERVICE_UNAVAILABLE = `We are having trouble verifying your address from our shipping partners. Please try submitting again in a few moments or contact our customer support.`;
export const SHIPPING_SERVICE_UNAVAILABLE_TITLE = `Shipping address not found`;
export const VERIFY_ADDRESS = `Apologies but we’re having trouble verifying the address entered — mind confirming we’ve got the right place? More information such as an apartment or suite number may be missing or incorrect.`;
export const VERIFY_ADDRESS_TITLE = `Please verify address`;
export const CANNOT_SHIP_TITLE = `We cannot ship to this address`;
export const AMBIGUOUS_ADDRESS_COPY_1 = `Please check that your address is correct.`;
export const AMBIGUOUS_ADDRESS_COPY_2 = `We currently deliver to non-P.O. Box addresses in the 48 contiguous states. Please be on the look-out for updates!`;
