import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Star } from '@farmersdog/corgi';

import styles from './FiveStars.module.scss';

/**
 * Five star icons in a line
 */
function FiveStars({ color, className }) {
  return (
    <div
      className={classNames(styles.container, className)}
      aria-label="Rating 5 out of 5"
      role="img"
    >
      <Star height={24} width={24} fill={color} aria-hidden />
      <Star height={24} width={24} fill={color} aria-hidden />
      <Star height={24} width={24} fill={color} aria-hidden />
      <Star height={24} width={24} fill={color} aria-hidden />
      <Star height={24} width={24} fill={color} aria-hidden />
    </div>
  );
}

FiveStars.propTypes = {
  /** Color of all the starts */
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FiveStars;
