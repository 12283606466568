import mergeWith from 'lodash/mergeWith';

const customizer = (objValue, srcValue) => {
  return Array.isArray(objValue) ? srcValue : undefined;
};

/**
 * Works as lodash's merge but it doesn't merge arrays.
 *
 * @param  {...any} args
 * @return {any}
 */
export default function mergeObjects(...args) {
  return mergeWith(...args, customizer);
}
