import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { browserOnly } from '@farmersdog/utils';

type ScriptProps = JSX.IntrinsicElements['script'];
export function Script({ children, ...props }: ScriptProps) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    browserOnly((window, document) => {
      if (document.readyState === 'complete') {
        setLoaded(true);
        return;
      }

      const handleLoad = () => {
        window.removeEventListener('load', handleLoad);
        setLoaded(true);
      };

      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    });
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <Helmet>
      <script {...props}>{children}</script>
    </Helmet>
  );
}
