// import { StatesUnion } from './types';

/**
 * A "redirect state" is an intermediate state with no accompanying
 * questions, responses, or UI. Its only purpose is to redirect the
 * user to a different URL or state.
 *
 * It is associated with a cancellation reason (i.e. the option the
 * user just selected).
 */

// interface RedirectState {
//   redirect: StatesUnion;
// }

const redirectStatesEnum = {};

export type RedirectStatesEnumType = keyof typeof redirectStatesEnum;

export const getRedirectStates = () => redirectStatesEnum;
