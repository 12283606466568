/** Use this email for redirecting sign-up support e-mails. */
export const EMAIL_HELP_SIGNUP = 'signup@thefarmersdog.com';
/** Use this email for redirecting support e-mails. */
export const EMAIL_HELP = 'help@thefarmersdog.com';
/** Use this email for redirecting diy support e-mails. */
export const EMAIL_DIY_HELP = 'diysupport@thefarmersdog.com';
/** Use this email to redirect press enquiries. */
export const EMAIL_PRESS = 'press@thefarmersdog.com';
/** Use this email for careers enquiries. */
export const EMAIL_CAREERS = 'careers@thefarmersdog.com';
/** Use this email for redirecting generic emails. */
export const EMAIL_INFO = 'info@thefarmersdog.com';
/** Use this email to contact our vet relationship department **/
export const EMAIL_VET = 'vetpros@thefarmersdog.com';
/** Use this email for privacy request emails. */
export const EMAIL_PRIVACY_REQUESTS = 'privacy-requests@thefarmersdog.com';