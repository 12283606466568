import cookie from 'js-cookie';
import { v1 as uuidv1 } from 'uuid';

import { local, getCookieDomain } from '@farmersdog/utils';

export const SEGMENT_ANONYMOUS_ID_COOKIE_KEY = 'ajs_anonymous_id';
export const SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY = 'ajs_anonymous_id';

export const ANONYMOUS_ID_MISSING_VALUE = 'no-anonymous-id-set';

export const cookieOptions: cookie.CookieAttributes = {
  expires: 365,
  path: '/',
  sameSite: 'Lax',
  secure: false,
  domain: getCookieDomain(),
};

/**
 * Retrieve the current anonymous ID **from the cookie**
 *
 * @returns - An anonymous ID string or "no-anonymous-id-set" if non-existent
 */
function get(): string {
  return (
    cookie.get(SEGMENT_ANONYMOUS_ID_COOKIE_KEY) ?? ANONYMOUS_ID_MISSING_VALUE
  );
}

function getFromLocalStorage(): string | null {
  return local.getItem(SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY);
}

/**
 * Set the anonymous ID
 */
function set(anonymousId: string): void {
  // Segment stores these in both cookie and local storage, so writing to both seems prudent
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#storage-priority
  local.setItem(SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY, anonymousId);
  // mimicking Segment cookie settings
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#cookie-settings
  cookie.set(SEGMENT_ANONYMOUS_ID_COOKIE_KEY, anonymousId, cookieOptions);
}

function setInLocalStorage(anonymousId: string): void {
  local.setItem(SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY, anonymousId);
}

/**
 * Set the anonymous ID if it doesn't already exist
 * @returns Whether a new anonymous ID was created
 */

function createIfNotExists(): boolean {
  const currentAnonymousId = get();
  if (currentAnonymousId === ANONYMOUS_ID_MISSING_VALUE) {
    const nextAnonymousId = createAnonymousId();
    set(nextAnonymousId);
    return true;
  }

  return false;
}

function syncCookieWithLocalStorage(): void {
  const currentAnonymousId = get();
  if (currentAnonymousId !== ANONYMOUS_ID_MISSING_VALUE) {
    setInLocalStorage(currentAnonymousId);
  }
}

/**
 * Create a new anonymous ID
 *
 * @returns - An anonymous ID string
 */
function createAnonymousId() {
  return uuidv1();
}

export const anonymousId = {
  get,
  set,
  createIfNotExists,
  createAnonymousId,
  getFromLocalStorage,
  setInLocalStorage,
  syncCookieWithLocalStorage,
};
