import classNames from 'classnames';
import { useViewport } from 'src/screen';
import { Card } from '@farmersdog/corgi';
import { Nowrap, Text } from '@farmersdog/corgi-x';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './Quotes.module.scss';

function Quotes() {
  const isMobile = useViewport({ xs: true, lg: false });
  const {
    isAccessibilityThemeOn,
    KaleBackgroundWrapper,
    accessibilityThemeStyles,
  } = useAccessibilityThemeContext();

  const sectionContent = (
    <>
      <Card
        className={classNames(styles.quotesCard, accessibilityThemeStyles.card)}
      >
        <Text variant="heading-22" color="kale-3" bold as="blockquote">
          “You don’t need to guess what’s in our food. You can literally see it,
          smell it, and even taste it. <Nowrap>Trust me.</Nowrap>{' '}
          <Nowrap>I have.</Nowrap> <Nowrap>A lot.</Nowrap>
        </Text>
        <Text variant="article-16" color="charcoal-3" as="figcaption">
          Unlike kibble or canned food, our fresh recipes are made with
          ingredients that are safe for humans to eat and in kitchens where only
          human food is produced. This is the food we’re feeding our own dogs;
          we’re always going to hold ourselves to the{' '}
          <Nowrap>highest standard.”</Nowrap>
        </Text>
        <Text variant="heading-16" color="charcoal-3" as="cite">
          — Brett
        </Text>
      </Card>
      <Card
        className={classNames(
          styles.quotesCard,
          accessibilityThemeStyles.card,
          { [styles.cardMargin]: isAccessibilityThemeOn && isMobile }
        )}
      >
        <Text variant="heading-22" bold color="kale-3" as="blockquote">
          “Feeding our food isn’t about spoiling your dog or being fancy; it’s
          about recognizing that no natural digestive system — human, dog, or
          otherwise — is suited to highly processed food.
        </Text>
        <Text variant="article-16" color="charcoal-3" as="figcaption">
          At the end of the day, dogs aren’t humans, and do need to eat
          different things than us — but they’re still living beings that need{' '}
          <Nowrap>real food</Nowrap>. And we’re making it really easy to give
          that to them.”
        </Text>
        <Text variant="heading-16" color="charcoal-3" as="cite">
          — Jonathan
        </Text>
      </Card>
    </>
  );
  return isAccessibilityThemeOn ? (
    <KaleBackgroundWrapper>
      <div
        className={
          isAccessibilityThemeOn && !isMobile
            ? styles.accessibleDesktopContainer
            : ''
        }
      >
        {sectionContent}
      </div>
    </KaleBackgroundWrapper>
  ) : (
    <div className={classNames(styles.container, { [styles.lg]: !isMobile })}>
      {sectionContent}
    </div>
  );
}

export default Quotes;
