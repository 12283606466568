import classNames from 'classnames';
import { useViewport } from 'src/screen';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { Link } from '@farmersdog/corgi';
import { Nowrap, Text, PageWidth } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';
import UnderlineDecorator from 'src/components/UnderlineDecorator';
import ReviewsSlider from 'src/components/ReviewsSlider';

import HomepageText from '../HomepageText';
import { HomepageTitle1 } from '../HomepageTitle';
import HeadlineList from '../HeadlineList';
import CounterTitle from './CounterTitle';

import { pawsWithPacks } from './assets';

import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './SectionReviews.module.scss';
import { useVetSection } from '../../hooks/useVetSection';

function SectionReviews() {
  const columns = useViewport({ xs: 2, sm: 3, md: 5 });
  const isMobile = useViewport({ xs: true, sm: true, lg: false });
  const { shouldShowVetSection } = useVetSection();

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  const containerClassNames = classNames(styles.section, useViewport(styles), {
    [styles.accessibilityTheme]: isAccessibilityThemeOn,
    [styles.whiteBackground]: shouldShowVetSection,
  });

  return (
    <section className={containerClassNames}>
      {isMobile && (
        <Picture
          loading="lazy"
          sources={pawsWithPacks}
          className={styles.backgroundImage}
          rootMargin="100%"
          alt="Reviews Background Image"
        />
      )}
      <PageWidth>
        <CounterTitle />
        <UnderlineDecorator center>
          <Text as="h3" variant="heading-22" color="kale-3" bold>
            What Customers Are Saying
          </Text>
        </UnderlineDecorator>
        <HeadlineList columns={columns} align="center">
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Increased
            <br />
            Vitality
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            <Nowrap>
              Clear Skin &<br />
              Shiny Coat
            </Nowrap>
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Less
            <br />
            <Nowrap>“Dog Odor”</Nowrap>
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Better
            <br />
            #2
          </Text>
        </HeadlineList>
      </PageWidth>
      <ReviewsSlider />
      <PageWidth enabled={isMobile} className={styles.recommendedSection}>
        <div
          className={classNames(styles.box, {
            [styles.accessibilityTheme]: isAccessibilityThemeOn,
          })}
        >
          <HomepageTitle1>See your dog’s recommended plan!</HomepageTitle1>
          <HomepageText>
            Get real, healthy food conveniently delivered with our personalized
            meal plans.
          </HomepageText>
          <Link
            asButton
            variant="primary"
            to={getLinkToFreshSignup(PATH_SIGNUP_ME)}
            className={classNames(styles.button, {
              [styles.accessiblilityThemeCta]: isAccessibilityThemeOn,
            })}
          >
            Create Your Plan
          </Link>
        </div>
      </PageWidth>
    </section>
  );
}
export default SectionReviews;
