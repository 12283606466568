import cloneDeep from 'lodash/cloneDeep';

import { Blueprint, CompiledBlueprint } from '../types';

import { blueprintTraverseApply } from './blueprintTraverseApply';
import { generateNodeId } from './generateNodeId';

export function compileBlueprint(
  input: Blueprint | CompiledBlueprint
): CompiledBlueprint {
  const blueprint = cloneDeep(input);
  blueprintTraverseApply(blueprint.root, node => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    node.__self__ = node.__self__ ?? generateNodeId();
  });

  return blueprint as CompiledBlueprint;
}
