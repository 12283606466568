import { EnableLpf } from './EnableLpf';

export enum EnablementId {
  Lpf = '55091',
}

export function Enable({ id }: { id: EnablementId }) {
  if (id === EnablementId.Lpf) {
    return <EnableLpf />;
  }

  return null;
}
