import Picture, { LoadingOption } from 'src/components/Picture';

import kibbleSources from './assets';
import styles from './Kibble.module.scss';

/**
 * Render a kibble graphic
 */
export function Kibble() {
  return (
    <Picture
      sources={kibbleSources}
      className={styles.image}
      loading={LoadingOption.lazy}
      alt="Kibble"
    />
  );
}
