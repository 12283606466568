import Testimonial from './Testimonial';
import Instagram from './Instagram';
import instagramData from './instagramData';
import testimonialData from './testimonialData';

const instagramFragments = instagramData.map(data => ({
  data,
  component: Instagram,
}));

const testimonialFragments = testimonialData.map(data => ({
  data,
  component: Testimonial,
}));

function interleave(...arrays) {
  const interleaved = [];

  const longestArray = arrays.reduce(
    (prev, next) => (prev.length > next.length ? prev : next),
    []
  );

  longestArray.forEach((_, j) => {
    arrays.forEach((__, i) => {
      const item = arrays[i][j];

      if (item) {
        interleaved.push(item);
      }
    });
  });

  return interleaved;
}

function getSlides(content) {
  let instagramSlides = [];
  let testimonialSlides = [];

  if (content.includes('instagram')) {
    instagramSlides.push(...instagramFragments);
  }

  if (content.includes('testimonial')) {
    testimonialSlides.push(...testimonialFragments);
  }

  return interleave(instagramSlides, testimonialSlides);
}

export default getSlides;
