import { useId } from 'react';
import {
  ContentLoader,
  Divider,
  PaddedCard,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { reporter } from '../errors/services';
import { useFetchStockOverview } from './network';
import { StockMeter, StockStatusMessage } from './features';

import styles from './StockOverview.module.css';

export function StockOverview() {
  const request = useFetchStockOverview({
    onError: error => {
      reporter.warn('Error loading StockOverview', {
        sourceError: error,
      });
    },
  });
  const { medium } = useViewport();
  const stockOverviewId = useId();

  if (request.error) return null;

  const loadingHeight = medium ? 120 : 241;

  return (
    <section aria-labelledby={stockOverviewId}>
      <PaddedCard className={styles.stockOverviewCard}>
        <div className={styles.headings}>
          <Text
            as="h2"
            id={stockOverviewId}
            variant="heading-28"
            color="kale-3"
            bold
          >
            Packs at Home
          </Text>
          <Text variant="article-16">
            Your current stock helps us plan for your next order date.
          </Text>
        </div>
        <ContentLoader
          mode="dark"
          height={loadingHeight}
          loading={request.loading}
        >
          {request.data && (
            <div className={styles.content}>
              <StockMeter
                petNames={request.data.petNames ?? []}
                currentPackCount={request.data.currentStockAsPacks}
                previousOrderQuantity={request.data.previousOrderQuantity}
              />
              {request.data.nextOrder && (
                <>
                  <Divider borderStyle="dotted" color="Chickpea2" />
                  <StockStatusMessage
                    projectedStockAtDate={request.data.projectedStockAtDate}
                    canRescheduleNextOrder={request.data.canRescheduleNextOrder}
                    stockStatus={request.data.stockStatusAtNextDelivery}
                    isNextOrderScheduledForRecommendation={
                      request.data.isNextOrderScheduledForRecommendation
                    }
                  />
                </>
              )}
            </div>
          )}
        </ContentLoader>
      </PaddedCard>
    </section>
  );
}
