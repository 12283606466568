import cloneDeep from 'lodash/cloneDeep';

import {
  CompiledBlueprint,
  Node,
  NodeRegistry,
  Fragment,
  BranchNode,
  LeafNode,
} from '../blueprint/types';
import {
  isBranchNode,
  isLeafNode,
  blueprintTraverseApply,
  generateNodeId,
} from '../blueprint/utils';
import { TOSATreeIndex } from '../types';
interface BlueprintGetNodeByIndexOptions {
  closest: boolean;
}
export function BlueprintGetNodeByIndex(
  schema: CompiledBlueprint,
  index: TOSATreeIndex = [],
  { closest }: BlueprintGetNodeByIndexOptions = { closest: false }
): { node: BranchNode | LeafNode | undefined; index: TOSATreeIndex } {
  let current: Node = schema.root;
  const realIndex = [];

  for (const idx of index) {
    if (isLeafNode(current)) {
      if (closest) {
        return { node: current, index: realIndex };
      }
      return { node: undefined, index: [] };
    } else if (isBranchNode(current)) {
      // @ts-expect-error typescript bug? current is always of type never, which doesn't make sense
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (idx >= current.children.length) {
        if (closest) {
          return { node: current, index: realIndex };
        } else {
          return { node: undefined, index: [] };
        }
      } else {
        // @ts-expect-error typescript bug? current is always of type never, which doesn't make sense
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        current = current.children[idx];
      }
    } else {
      throw new Error('Invalid node');
    }

    realIndex.push(idx);
  }

  return { node: current, index: realIndex };
}

export function getNodeByName(name: string, registry: NodeRegistry) {
  return registry[name];
}
interface FragmentOperationProps {
  nodeTarget: BranchNode;
  difference: number;
}
interface AppendChildrenOperationProps extends FragmentOperationProps {
  fragmentSource: Fragment;
  index: number | undefined;
}

/* Performs an update operation, converting a Fragment to a Node */
export function fragmentTreeToNodeTree(
  fragment: Fragment,
  updateOperation: (fragment: Fragment) => void
  // @ts-expect-error this is a transition point from Fragment to Node types
): asserts fragment is Node {
  blueprintTraverseApply(fragment, updateOperation);
}

export function appendChildren({
  fragmentSource,
  nodeTarget,
  difference,
  index,
}: AppendChildrenOperationProps) {
  const clonedNodeTarget = cloneDeep(nodeTarget);
  const currentTargetCount = clonedNodeTarget.children.length;
  // add the same fragment the number of times specified
  const newChildren: Node[] = [...clonedNodeTarget.children];
  const startIndex = index ?? currentTargetCount + 1;
  const endIndex = startIndex + difference;

  for (let i = startIndex; i < endIndex; i++) {
    const clonedFragment = cloneDeep(fragmentSource);
    // recursively update name
    fragmentTreeToNodeTree(clonedFragment, (frag: Fragment) => {
      frag.__self__ = generateNodeId();
      frag.name = `${frag.name}-${i}`;
    });
    newChildren.push(clonedFragment);
  }
  clonedNodeTarget.children = newChildren;
  return clonedNodeTarget;
}

export function truncateChildren({
  nodeTarget,
  difference,
}: FragmentOperationProps) {
  const clonedNodeTarget = cloneDeep(nodeTarget);
  clonedNodeTarget.children = clonedNodeTarget.children.slice(
    0,
    clonedNodeTarget.children.length + difference
  );
  return clonedNodeTarget;
}

export function findBranchNodeByName({
  name,
  node,
}: {
  name: string;
  node: BranchNode;
}): BranchNode | undefined {
  if (node.name === name) {
    return node;
  }

  if (isBranchNode(node)) {
    for (const n of node.children) {
      if (isBranchNode(n)) {
        const found = findBranchNodeByName({ name, node: n });
        if (found) return found;
      }
    }
  }

  return;
}

export function nodeIsLastChild(thisNode: BranchNode, thatNode: Node): boolean {
  const lastChild = thisNode.children[thisNode.children.length - 1];
  return lastChild ? lastChild.__self__ === thatNode.__self__ : false;
}
