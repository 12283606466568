interface SortObjectPropertiesByKeyArgs {
  object: Record<string, unknown>;
  map: {
    [key: string]: number;
  };
}

type KeyValuePair = [key: string, val: unknown];

const sortByMap = (sort1: number, sort2: number) => {
  if (sort1 === sort2) {
    return 0;
  }

  return sort1 > sort2 ? 1 : -1;
};

export function sortObjectPropertiesByKey({
  object,
  map,
}: SortObjectPropertiesByKeyArgs) {
  const maxPosition = Math.max(...Object.values(map));
  return Object.fromEntries(
    Object.entries(object).sort(
      ([key1]: KeyValuePair, [key2]: KeyValuePair) => {
        const sort1 = map[key1] ?? maxPosition + 1;
        const sort2 = map[key2] ?? maxPosition + 1;
        return sortByMap(sort1, sort2);
      }
    )
  );
}
