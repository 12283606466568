import {
  DynamicCalculatingScreenTreatments,
  FeatureName,
} from '../../../utils';
import { useLeadState } from '../../useLeadState';
import { useTosaUserFeature } from '../../useTosaUserFeature';

import type { UseFeatureHook } from '../../../types';

interface UseDynamicCalculatingScreenArgs {
  useFeature: UseFeatureHook;
}
export type CalculatingScreenTreatment = Exclude<
  DynamicCalculatingScreenTreatments,
  DynamicCalculatingScreenTreatments.off
>;

type UseDynamicCalculatingScreenReturn =
  | {
      shouldShowDynamicCalculatingScreen: true;
      dynamicCalculatingScreenTreatment: CalculatingScreenTreatment;
    }
  | {
      shouldShowDynamicCalculatingScreen: false;
      dynamicCalculatingScreenTreatment: DynamicCalculatingScreenTreatments.off;
    };

const calculatingScreenTreatments: CalculatingScreenTreatment[] = [
  DynamicCalculatingScreenTreatments.vet_static,
  DynamicCalculatingScreenTreatments.kibble_or_fresh,
  DynamicCalculatingScreenTreatments.fresh_benefit_weight_static,
  DynamicCalculatingScreenTreatments.fresh_benefit_weight_animation,
  DynamicCalculatingScreenTreatments.fresh_benefit_weight_static,
  DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_animation,
  DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_static,
  DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_animation,
];

export function useDynamicCalculatingScreen({
  useFeature,
}: UseDynamicCalculatingScreenArgs): UseDynamicCalculatingScreenReturn {
  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const bodyConditions = lead?.pets?.map(pet => pet.bodyCondition) ?? [];
  const isDry = Boolean(lead?.pets?.some(pet => pet.foodType === 'dry food'));

  const { treatment: dynamicCalculatingScreenTreatment } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_DYNAMIC_CALCULATING_SCREEN,
    attributes: {
      isDry,
      bodyConditions,
      freshFoodConfidence: lead?.freshFoodConfidence,
    },
  });

  const shouldShowDynamicCalculatingScreen =
    calculatingScreenTreatments.includes(
      dynamicCalculatingScreenTreatment as CalculatingScreenTreatment
    );

  return {
    ...(shouldShowDynamicCalculatingScreen
      ? {
          shouldShowDynamicCalculatingScreen,
          dynamicCalculatingScreenTreatment:
            dynamicCalculatingScreenTreatment as CalculatingScreenTreatment,
        }
      : {
          shouldShowDynamicCalculatingScreen,
          dynamicCalculatingScreenTreatment:
            DynamicCalculatingScreenTreatments.off,
        }),
  };
}
