import { useEffect, useRef } from 'react';
import { ContentWidth, Link, browserOnly } from '@farmersdog/corgi';

import { MARGIN_XL_VALUE } from '@farmersdog/corgi/constants';
import { useLocation } from 'react-router-dom';
import fastdom from 'fastdom';

import Page from 'src/components/Page';
import { FullFooter } from 'src/components/Footer';
import { Navigation } from 'src/components/Navigation';
import { useCorgiViewport } from 'src/screen';

import { TileHero, JobsList, Benefits } from './components';
import copy from './copy';
import { PATH_CAREERS } from '@farmersdog/constants/paths';
import { EMAIL_RECRUITING } from '../../constants/emails';

import styles from './Careers.module.scss';
import classNames from 'classnames';
import { Divider, PageWidth, Text, type TextProps } from '@farmersdog/corgi-x';

import pdf from './assets/Recruitment-Fraud-Notice.pdf';

const OPEN_POSITIONS = 'open-positions';

const valueProps = [
  'Dog-friendly offices',
  'Market-competitive compensation and equity packages',
  'Comprehensive Healthcare, Dental, and Vision insurance',
  'Competitive 401k plan with company match',
  '12-week paid parental leave',
  'Flexible PTO and work-from-home policy',
  'Discounted fresh food for your pup',
  'Your pet interrupting video calls and in-person meetings is now a feature, not a bug',
];

function Careers() {
  const location = useLocation();
  const titleVariant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-40',
    lg: 'heading-52',
  });

  const titleDescriptionVariant = useCorgiViewport<TextProps['variant']>({
    xs: 'article-16',
    lg: 'article-20',
  });

  const ctaReveal = useRef(null);

  useEffect(() => {
    if (
      location.pathname === PATH_CAREERS &&
      location.hash === `#${OPEN_POSITIONS}`
    ) {
      browserOnly((window, document) => {
        fastdom.measure(() => {
          const el = document.getElementById(OPEN_POSITIONS);
          if (el) {
            el.scrollIntoView();
          }
        });
      });
    }
    // depends on location.key so that clicking on the view open positions link when current hash is already #open-postitions will still trigger a scroll
  }, [location.hash, location.pathname, location.key]);

  return (
    <Page
      title="Careers"
      description="Join our team and help us change the way people take care of their pets. Must love dogs!"
    >
      <Navigation ctaTrigger={ctaReveal} />
      <section>
        <PageWidth className={styles.main}>
          <Text
            as="h1"
            variant={titleVariant}
            color="kale-3"
            className={styles.center}
            bold
          >
            {copy.pageTitle}
          </Text>

          <Text
            as="p"
            variant={titleDescriptionVariant}
            color="charcoal-3"
            className={classNames(styles.center, styles.pageTitleDescription)}
          >
            {copy.pageTitleDescription}
          </Text>
          <div className={styles.linkContainer}>
            <Link
              asButton
              variant="primary"
              asMini
              to={`${PATH_CAREERS}#${OPEN_POSITIONS}`}
            >
              View Open Positions
            </Link>
          </div>
          <TileHero />
        </PageWidth>
      </section>
      <div ref={ctaReveal}>
        <section>
          <ContentWidth fr={2} alignSelf="center" className={styles.center}>
            <Text
              as="h3"
              variant="heading-28"
              color="kale-3"
              className={styles.subHeader}
              bold
            >
              {copy.pageSubtitle}
            </Text>
            <Text as="div" variant="article-16">
              {copy.pageText}
            </Text>
            <Divider
              orientation="horizontal"
              color="Oyster0"
              margin={MARGIN_XL_VALUE}
            />
            <Text
              as="div"
              variant="heading-12"
              fontStyle="italic"
              className={styles.fraudWarning}
              color="charcoal-3"
            >
              Please be aware that some members of the public have recently
              received communication from individuals not associated with The
              Farmer’s Dog relating to both real and falsified job
              opportunities. Note that The Farmer’s Dog will{' '}
              <Text as="span" variant="heading-12" fontStyle="italic" bold>
                never ask you to install software during our interview process
              </Text>
              . If you are in doubt of the legitimacy of any communication that
              appears to be from The Farmer’s Dog, especially if you are
              receiving a message about a role you did not apply to, please
              reach out to{' '}
              <Link href={`mailto:${String(EMAIL_RECRUITING)}`}>
                {EMAIL_RECRUITING}
              </Link>{' '}
              and refer to our{' '}
              <Link href={pdf} target="_blank" rel="noopener noreferrer">
                Notice of Recruitment Fraud.
              </Link>
            </Text>
          </ContentWidth>
        </section>
        <Benefits valueProps={valueProps} />
        <section>
          <ContentWidth
            fr={2}
            alignSelf="center"
            className={classNames(styles.center, styles.positionsContainer)}
          >
            <Text as="h3" variant="heading-28" color="kale-3" bold>
              {copy.deiHeading}
            </Text>
            <Text
              as="div"
              variant="article-16"
              className={styles.deiAndEmploymentCopy}
            >
              {copy.deiCopy}
            </Text>
            <Text as="h3" variant="heading-28" color="kale-3" bold>
              {copy.equalEmploymentHeading}
            </Text>
            <Text
              as="div"
              variant="article-16"
              className={styles.deiAndEmploymentCopy}
            >
              {copy.equalEmploymentCopy}
            </Text>
            <Divider
              orientation="horizontal"
              color="Oyster0"
              margin={MARGIN_XL_VALUE}
            />
            <Text
              id={OPEN_POSITIONS}
              as="h3"
              variant="heading-28"
              color="kale-3"
              bold
            >
              Open Positions at The Farmer’s Dog
            </Text>
            <JobsList />
          </ContentWidth>
        </section>
      </div>
      <FullFooter />
    </Page>
  );
}

export default Careers;
