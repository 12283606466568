import { CHARCOAL_3 } from '@farmersdog/corgi/constants';
import { Text, Picture } from '@farmersdog/corgi-x';

import ChatBubbleIcon from '../assets/icons/chat-bubble.svg';
import HeartIcon from '../assets/icons/heart.svg';
import InstagramIcon from '../assets/icons/instagram.svg';
import PaperPlaneIcon from '../assets/icons/paper-plane.svg';

import styles from './Instagram.css';

const iconStyle = {
  width: 24,
  height: 24,
  margin: '0 0.5rem',
};

export interface InstagramProps {
  screenName: string;
  quote: string;
  image: {
    sources: string[];
  };
}

export function Instagram({ screenName, quote, image }: InstagramProps) {
  return (
    <div className={styles.instagram}>
      <div className={styles.imageRatio}>
        <Picture alt={screenName} loading="lazy" sources={image.sources} />
        <InstagramIcon
          style={{
            width: 32,
            height: 32,
            top: 12,
            right: 12,
            position: 'absolute',
            fill: CHARCOAL_3,
          }}
          aria-label="Instagram Logo"
        />
      </div>
      <div className={styles.content}>
        <div style={{ display: 'flex', margin: '1rem 0' }}>
          <HeartIcon
            style={{ ...iconStyle, marginLeft: 0, fill: '#ed4956' }}
            aria-label="Instagram favorite"
          />
          <ChatBubbleIcon style={iconStyle} aria-label="Comments" />
          <PaperPlaneIcon style={iconStyle} aria-label="Share post" />
        </div>
        <Text variant="article-16" as="article" color="charcoal-3">
          <Text
            variant="article-16"
            as="h3"
            bold
            color="charcoal-3"
            className={styles.userName}
          >
            {screenName}
          </Text>
          {' ' + quote}
        </Text>
      </div>
    </div>
  );
}
