import { FEMALE } from 'src/constants/gender';

// The default pet must be used when creating a new pet
// Put here the settings that should be pre-compiled in the form
const defaultPet = {
  name: '',
  gender: FEMALE,
  neutered: true,
  nature: 1,
  specialDiet: false,
};

/**
 * Return a list of distinct breed names for an array of pets.
 *
 * @param {Array<Pet>} pets
 */
export function getUniqueBreedNamesFromPets(pets) {
  if (!pets) {
    return undefined;
  }
  const breeds = new Set();
  pets.forEach(pet => {
    if (pet.breeds) {
      pet.breeds
        .map(breed => breed.name)
        .filter(Boolean)
        .forEach(breed => breeds.add(breed));
    }
  });
  return Array.from(breeds);
}

/**
 * Return a list of distinct issue names for an array of pets.
 *
 * @param {Array<Pet>} pets
 */
export function getUniqueHealthIssueNamesFromPets(pets) {
  if (!pets) {
    return undefined;
  }
  const issues = new Set();
  pets.forEach(pet => {
    if (pet.issues) {
      pet.issues
        .map(issue => issue.name)
        .filter(Boolean)
        .forEach(issue => issues.add(issue));
    }
  });
  return Array.from(issues);
}

/**
 * Return the default pet object used for pre-filling the form.
 *
 * @example
 *  const newPet = {
 *    ...getDefaultPet(),
 *    name: 'Stina'
 *  }
 *
 * @return {Object}
 */
export function getDefaultPet() {
  return { ...defaultPet };
}

/**
 * Reset pets array to default state while preserving name
 *
 * @param {?Array} pets an array of pets
 * @return {Array}
 *
 * @example
 *  const userData = {
 *    ...staleData,
 *    pets: this.resetPets(staleData.pets)
 *  }
 */
export function resetPets(pets = []) {
  return pets.map(pet => ({
    ...getDefaultPet(),
    name: pet.name,
  }));
}
