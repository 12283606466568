import { ApolloError, useQuery } from '@apollo/client';
import { GetLpfDownsellEligibilityQuery } from './getLPFDownsellEligibility.cgs.generated-types';
import getLPFDownsellEligibility from './getLPFDownsellEligibility.cgs.graphql';

interface LpfDownsellEligibility {
  eligibleForLPF: boolean;
  eligibleForLPFForReasonDownsell: boolean;
  isMultiPet: boolean;
}

interface UseGetLpfDownsellEligibility {
  data: LpfDownsellEligibility | undefined;
  error?: ApolloError;
  loading: boolean;
}

export function useGetLpfDownsellEligibility(): UseGetLpfDownsellEligibility {
  const response = useQuery<GetLpfDownsellEligibilityQuery>(
    getLPFDownsellEligibility,
    {
      context: { endpoint: 'cgs' },
    }
  );

  return {
    loading: response.loading,
    error: response.error,
    data: response.data && {
      eligibleForLPF: response.data.customer.eligibleForProductLine.lpf,
      eligibleForLPFForReasonDownsell:
        response.data.customer.eligibleForDownsell.lpf,
      isMultiPet: response.data.customer.userCharacteristics.isMultiPet,
    },
  };
}
