import { MutationHookOptions, useMutation } from '@apollo/client';
import { LoginMutation, LoginMutationVariables } from 'src/graphql/types';
import { loginMutation } from '../../graphql';

export type UseLoginSubmit = (email: string, password: string) => void;

export function useLoginSubmit(
  options: MutationHookOptions<LoginMutation, LoginMutationVariables>
): UseLoginSubmit {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(
    loginMutation,
    options
  );

  const callLogin = (email: string, password: string) =>
    login({ variables: { input: { email, password } } });

  return callLogin;
}
