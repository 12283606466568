import { Text } from '@farmersdog/corgi-x';

import { CvrWeightlossPersonalizationTreatments } from '../../../utils';
import { Section } from '../Section';

import styles from './FAQ.module.css';
import { Question } from './Question';
import { getQuestions } from './questions';

interface FrequentlyAskedQuestionsProps {
  treatment: CvrWeightlossPersonalizationTreatments;
}

export const FAQ = ({ treatment }: FrequentlyAskedQuestionsProps) => {
  const questions = getQuestions(treatment);

  return (
    <Section>
      <Text
        bold
        as="h3"
        vSpacing="none"
        color="charcoal-3"
        variant="heading-12"
        className={styles.subheading}
      >
        Answers to
      </Text>
      <Text
        bold
        as="h2"
        color="kale-3"
        topSpacing={{ xs: 'sm', lg: 'xs' }}
        bottomSpacing="md"
        variant="heading-40"
        className={styles.heading}
      >
        Frequently <br className={styles.mobileBreakline} /> Asked{' '}
        <br className={styles.breakline} /> Questions
      </Text>
      {questions.map(question => (
        <div key={question.title} className={styles.question}>
          <Question title={question.title}>
            <Text
              as="p"
              vSpacing="xs"
              color="charcoal-3"
              variant="article-16"
              className={styles.content}
            >
              {question.content}
            </Text>
          </Question>
        </div>
      ))}
    </Section>
  );
};
