export function formatBreeds(breeds: string[] | null) {
  if (!breeds) {
    return '';
  }
  const mixedBreedIndex = breeds.findIndex(
    breed => breed.search(/unknown/gi) > -1
  );
  const isMixed = mixedBreedIndex > -1 || breeds.length > 1;
  const realBreeds = breeds.filter(breed => breed.search(/unknown/gi) === -1);
  if (realBreeds.length === 0) {
    return 'mixed breed';
  }
  return `${realBreeds[0]}${isMixed ? ' mix' : ''}`;
}
