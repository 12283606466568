import { ValidationError } from 'yup';

import { PetNodes, tosaErrorCodes as errorCodes } from '@farmersdog/constants';

import { Position } from '../blueprint/types';

import { BirthdayAccuracy, YEAR_IN_WEEKS, MONTH_IN_WEEKS } from './constants';

interface GetAgeInWeeksProps {
  unit: string;
  amount: number;
  position: Position;
}

export function getAgeInWeeks({ unit, amount, position }: GetAgeInWeeksProps) {
  switch (unit) {
    case BirthdayAccuracy.Years: {
      return amount * YEAR_IN_WEEKS;
    }
    case BirthdayAccuracy.Months: {
      return amount * MONTH_IN_WEEKS;
    }
    case BirthdayAccuracy.Weeks: {
      return amount;
    }
    default:
      throw new ValidationError(
        errorCodes.UNEXPECTED_ERROR,
        unit,
        `${PetNodes.BirthdayUnit}-${position}`
      );
  }
}
