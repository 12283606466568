const cache = { pathname: '', search: '' };

export interface Location {
  pathname: string;
  search: string;
}

export function isDuplicatePage({ pathname, search }: Location) {
  return pathname === cache.pathname && search === cache.search;
}

export function setPageCache({ pathname, search }: Location) {
  cache.pathname = pathname;
  cache.search = search;
}
