import classNames from 'classnames';

import styles from './Section.module.css';

interface SectionProps {
  backgroundColor?: 'white' | 'gray' | 'green';
  children: React.ReactNode;
}

export function Section({ children, backgroundColor = 'white' }: SectionProps) {
  return (
    <section
      className={classNames(styles.section, {
        [styles.white]: backgroundColor === 'white',
        [styles.gray]: backgroundColor === 'gray',
        [styles.green]: backgroundColor === 'green',
      })}
    >
      <div className={styles.sectionContent}>{children}</div>
    </section>
  );
}
