export const BLUEBERRIES = {
  label: 'Blueberries',
  value: 'blueberries',
};

export const CRANBERRIES = {
  label: 'Cranberries',
  value: 'cranberries',
};

export const RICE = {
  label: 'Rice',
  value: 'rice',
};

export const QUINOA = {
  label: 'Quinoa',
  value: 'quinoa',
};

export const OATS = {
  label: 'Oats',
  value: 'oats',
};

export const GINGER = {
  label: 'Ginger',
  value: 'ginger',
};

export const TURMERIC = {
  label: 'Turmeric',
  value: 'turmeric',
};

export const CHIA_SEEDS = {
  label: 'Chia seeds',
  value: 'chiaSeeds',
};

export const FLAX_SEED = {
  label: 'Flax seed',
  value: 'flaxSeed',
};

export const SUNFLOWER_SEEDS = {
  label: 'Sunflower seeds',
  value: 'sunflowerSeeds',
};

export const SALMON_OIL = {
  label: 'Salmon oil',
  value: 'salmonOil',
};

export const additionalIngredientOptions = [
  BLUEBERRIES,
  CRANBERRIES,
  RICE,
  QUINOA,
  OATS,
  GINGER,
  TURMERIC,
  CHIA_SEEDS,
  FLAX_SEED,
  SUNFLOWER_SEEDS,
  SALMON_OIL,
];
