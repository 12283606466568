import { useId } from 'react';
import {
  ContentLoader,
  PaddedCard,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { PreferencesForm } from './features';
import { useFetchNotificationPreferences } from './network';
import { reporter } from '../errors/services';

export function NotificationPreferences() {
  const request = useFetchNotificationPreferences();
  const { medium } = useViewport();
  const id = useId();

  const loadingHeight = medium ? 120 : 241;

  if (request.error) {
    reporter.error(new Error('Error loading NotificationPreferences'), {
      sourceError: request.error,
    });
    return null;
  }

  return (
    <PaddedCard aria-labelledby={id}>
      <Text id={id} as="h2" variant="heading-28" color="kale-3" bold>
        Notification Preferences
      </Text>
      <ContentLoader loading={request.loading} height={loadingHeight}>
        {request.data && (
          <PreferencesForm
            marketingNotifications={
              request.data.customer.notificationPreferences
                .marketingNotifications
            }
            orderReminders={
              request.data.customer.notificationPreferences.orderReminders
            }
            orderUpdates={
              request.data.customer.notificationPreferences.orderUpdates
            }
          />
        )}
      </ContentLoader>
    </PaddedCard>
  );
}
