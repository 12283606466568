import { NodeNames } from '@farmersdog/constants';

import { TOSALeafNode } from '../types';
import {
  isValidationSchemaField,
  ValidationSchemaType,
} from '../validation/yupValidation';

type InputModeType = JSX.IntrinsicElements['input']['inputMode'];

interface GetInputModeProps {
  node: TOSALeafNode;
  validationSchema: ValidationSchemaType;
}
export function getInputMode({
  node,
  validationSchema,
}: GetInputModeProps): InputModeType {
  if (node.name === NodeNames.Email) {
    return 'email';
  }
  if (node.name === NodeNames.Phone) {
    return 'tel';
  }

  if (node.name === NodeNames.Zip) {
    return 'numeric';
  }

  const validation = node.validation;

  if (isValidationSchemaField(validationSchema, validation)) {
    const type = validationSchema[validation].type;

    if (type === 'number') {
      return 'numeric';
    }
  }

  return 'text';
}
