import { Logger } from '@farmersdog/logger';
import omit from 'lodash/omit';

import {
  SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES,
  SUBSCRIPTION_FETCH_START_DATES,
  SUBSCRIPTION_FETCH_FREQUENCIES,
  SUBSCRIPTION_FETCH_QUOTE,
  SUBSCRIPTION_FETCH_DIY_QUOTE,
  SUBSCRIPTION_FETCH_USER_QUOTE,
  SUBSCRIPTION_UPDATE,
  SUBSCRIPTION_SET_CURRENT_NEXT_DATE,
} from 'src/constants/actionTypes';

import config from 'src/config';
import { put, post, get, graphql } from 'src/actions/actionCreators';

import fetchAvailableNextDatesQuery from './fetchAvailableNextDates.graphql';
import fetchAvailableNextDatesPlanStockQuery from './fetchAvailableNextDatesPlanStock.graphql';
import { QueryParameter } from '@farmersdog/constants';

import { SubscriptionType } from 'src/graphql/types';

const log = new Logger('app:actions:subscription');

export const updateSubscription = data =>
  put(SUBSCRIPTION_UPDATE, '/subscription', data);
export const fetchStartDates = () =>
  get(SUBSCRIPTION_FETCH_START_DATES, `/subscription/start-dates`);
export const fetchFrequencies = () =>
  get(SUBSCRIPTION_FETCH_FREQUENCIES, `/subscription/frequencies`);

/**
 * Fetch the subscription quote for signup in suggested mode. Suggested mode
 * means that the frequency will be inferred by the API.
 *
 * @param {FetchQuoteUser} user the user object.
 *
 * @param {FetchQuoteOpt} options the options associated with this quote request
 *
 * @return {Promise<Api.Response<import('src/graphql/types').SubscriptionQuote>>} A promise that resolves to the API response
 */
export const fetchQuote = (
  {
    shippingAddress,
    subscription,
    pets = [],
    referrerId,
    referrerCode,
    referrerType,
    referrerAssociatedAt,
  } = {},
  defaultOptions = {}
) => {
  const options = {
    mode: 'default',
    ...defaultOptions,
  };

  log.debug('Fetching prices...');

  let address;
  if (shippingAddress) {
    address = {
      ...shippingAddress,
      state: shippingAddress.state ? shippingAddress.state.id : undefined,
    };
    log.debug('Using shipping address to get taxes', address);
  } else {
    log.debug('No shipping address available to get taxes');
  }

  const data = {
    address,
    referrerId,
    referrerCode,
    referrerType,
    referrerAssociatedAt,
    subscription: {
      ...subscription,
      trialEnabled:
        (subscription && subscription.trialEnabled) || config('features.trial'),
      frequency: subscription && subscription.frequency,
      priceVersionId: subscription && subscription.priceVersionId,
      taxPercentage: subscription && subscription.taxPercentage,
      discountPercentage: subscription && subscription.discountPercentage,
    },
    pets: pets.map(pet => omit(pet, ['plan'])),
  };

  log.debug('Fetching price with data...', data);
  const route = `/subscription/quote?${QueryParameter.QuoteMode}=${options.mode}`;
  const url = options.baseUrl ? `${options.baseUrl}${route}` : route;

  return post(SUBSCRIPTION_FETCH_QUOTE, url, data);
};

export const fetchDiyQuote = ({ shippingAddress, pets, subscription }) => {
  let address;
  if (shippingAddress) {
    address = {
      ...shippingAddress,
      state: shippingAddress.state ? shippingAddress.state.id : undefined,
    };
    log.debug('Using shipping address to get taxes', address);
  } else {
    log.debug('No shipping address available to get taxes');
  }

  const data = {
    address,
    subscription: {
      ...subscription,
      type: SubscriptionType.Diy,
    },
    pets: pets.map(pet => omit(pet, ['plan'])),
  };

  log.debug('Fetching price with data...', data);

  return post(SUBSCRIPTION_FETCH_DIY_QUOTE, '/subscription/quote', data);
};

/**
 * Fetch the subscription quote for a user.
 * This action will save the quote in the `subscription` store.
 *
 * @export
 * @param {Object} [user]
 * @returns {import('./subscription.types').FetchQuoteUser}
 */
export const fetchUserQuote = user => {
  const action = fetchQuote(user);
  if (action.error) {
    action.type = `${SUBSCRIPTION_FETCH_USER_QUOTE}_FAILURE`;
  } else {
    action.type = SUBSCRIPTION_FETCH_USER_QUOTE;
  }
  return action;
};

export function fetchAvailableNextDates(planStockStates) {
  if (planStockStates) {
    return graphql(
      SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES,
      fetchAvailableNextDatesPlanStockQuery,
      { planStockStates }
    );
  }
  return graphql(
    SUBSCRIPTION_FETCH_AVAILABLE_NEXT_DATES,
    fetchAvailableNextDatesQuery,
    { planStockStates }
  );
}

export function setCurrentNextDate(payload) {
  return {
    type: SUBSCRIPTION_SET_CURRENT_NEXT_DATE,
    payload,
  };
}
