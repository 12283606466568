import classNames from 'classnames';
import { Button } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { RecipeType } from './types';

import styles from './NavigationTab.module.scss';

import { staticContent } from './static-content';

interface NavigationTabProps {
  currentRecipeType: RecipeType;
  setCurrentRecipeType: (current: RecipeType) => void;
}

export function NavigationTab({
  currentRecipeType,
  setCurrentRecipeType,
}: NavigationTabProps) {
  const handleRecipeChange = (recipe: RecipeType) => {
    setCurrentRecipeType(recipe);
  };

  return (
    <div className={styles.container}>
      <ul className={styles.tabs}>
        {Object.values(RecipeType).map(recipe => {
          return (
            <li
              key={recipe}
              className={classNames(styles.tabContainer, {
                [styles.active]: currentRecipeType === recipe,
              })}
            >
              <Button
                asLink
                onClick={() => {
                  handleRecipeChange(recipe);
                }}
              >
                <Text
                  variant="heading-22"
                  color="kale-3"
                  bold
                  className={styles.tab}
                >
                  {staticContent[recipe].petName}
                </Text>
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
