import get from 'lodash/get';
import { PATH_LOGIN } from '@farmersdog/constants/paths';

interface RouteData {
  title: string;
  path: string;
  active: boolean;
  progress: number;
  skip: boolean;
  visited: boolean;
  completed?: boolean;
  me?: boolean;
  pets?: boolean;
  recipes?: boolean;
  plans?: boolean;
}

export const getRedirectPath = (args: {
  pathChanged: boolean;
  isCurrentIndexValid: boolean;
  isSuccessPage: boolean;
  isPreviousPageIncomplete: boolean;
  isLoggedIn: boolean;
  currentIndex: number;
  firstIncompleteRouteIndex: number;
  routesData: RouteData[];
}) => {
  const {
    pathChanged,
    isCurrentIndexValid,
    isSuccessPage,
    isPreviousPageIncomplete,
    isLoggedIn,
    currentIndex,
    firstIncompleteRouteIndex,
    routesData,
  } = args;
  let redirect = undefined;
  if (pathChanged) {
    if (!isCurrentIndexValid) {
      redirect = get(routesData, '0.path');
    } else if (isPreviousPageIncomplete && !isSuccessPage) {
      redirect = get(routesData, `${firstIncompleteRouteIndex}.path`);
    }
  } else if (!isLoggedIn) {
    if (isSuccessPage) {
      redirect = PATH_LOGIN;
    } else if (currentIndex !== 0) {
      redirect = get(routesData, '0.path');
    }
  }
  return redirect;
};
