export * as eatingStyles from './eatingStyles';
export * as emails from './emails';
export * as links from './links';
export * as paths from './paths';
export * as phoneNumbers from './phoneNumbers';
export * as regexPatterns from './regexPatterns';
export * from './bodyConditionName';
export * from './queryParameter';
export * from './recipeNames';
export * from './tosaRouteWhitelist';
export * from './tosaNodeNames';
export * from './tosaErrorCodes';
