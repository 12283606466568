export enum tosaErrorCodes {
  NUM_PETS_INVALID = 'NUM_PETS_INVALID',
  NUM_PETS_TOO_MANY = 'NUM_PETS_TOO_MANY',
  PET_NAMES_NOT_UNIQUE = 'PET_NAMES_NOT_UNIQUE',
  ZIP_INVALID = 'ZIP_INVALID',
  ZIP_NOT_SERVICEABLE = 'ZIP_NOT_SERVICEABLE',
  EMAIL_INVALID = 'EMAIL_INVALID',
  PHONE_INVALID = 'PHONE_INVALID',
  PHONE_DUPLICATE = 'PHONE_DUPLICATE',
  TARGET_WEIGHT_TOO_LIGHT = 'TARGET_WEIGHT_TOO_LIGHT',
  TARGET_WEIGHT_TOO_HEAVY = 'TARGET_WEIGHT_TOO_HEAVY',
  OVERWEIGHT_PET_TARGET_WEIGHT = 'OVERWEIGHT_PET_TARGET_WEIGHT',
  UNDERWEIGHT_PET_TARGET_WEIGHT = 'UNDERWEIGHT_PET_TARGET_WEIGHT',
  PET_TOO_OLD = 'PET_TOO_OLD',
  PET_TOO_YOUNG = 'PET_TOO_YOUNG',
  WEIGHT_TOO_HEAVY = 'WEIGHT_TOO_HEAVY',
  WEIGHT_TOO_LIGHT = 'WEIGHT_TOO_LIGHT',
  BREED_WEIGHT_TOO_HEAVY = 'BREED_WEIGHT_TOO_HEAVY',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  PHONE_NUMBER_ALREADY_IN_USE = 'PHONE_NUMBER_ALREADY_IN_USE',
  /** When yup cannot validate because of an exception */
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}
