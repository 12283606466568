import { CancellationResponsesEnumType } from '../../../../flow-state-management';
import { PetFieldsFragment } from '../../../../network';
import { LpfDownsell } from './LpfDownsell';
import {
  LpfDownsellState,
  useLpfDownsellProgress,
} from './useLpfDownsellProgress';

interface LpfDownsellWrapperProps {
  initialState: LpfDownsellState;
  cancellationReasonsHistory: CancellationResponsesEnumType[];
  onBackToCancellationReasons: () => void;
  onContinueToCancel: () => void;

  currentWeeklyPrice: number;
  onClose: () => void;
  pet: PetFieldsFragment;
}

export function LpfDownsellWrapper(props: LpfDownsellWrapperProps) {
  const lpfDownsellFlow = useLpfDownsellProgress({
    initialState: props.initialState,
    onBackToCancellationReasons: props.onBackToCancellationReasons,
    onContinueToCancel: props.onContinueToCancel,
    cancellationReasonsHistory: props.cancellationReasonsHistory,
  });

  return (
    <LpfDownsell
      {...lpfDownsellFlow}
      onClose={props.onClose}
      currentWeeklyPrice={props.currentWeeklyPrice}
      pet={props.pet}
    />
  );
}
