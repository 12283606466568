import cloneDeep from 'lodash/cloneDeep';

import { isDiySignup } from '@farmersdog/lead-browser-storage';

import { ProductLineType } from '../../graphql/types';
import { Blueprint, CompiledBlueprint } from '../types';
import { blueprintTraverseApply } from '../utils';

const DEFAULT_PRODUCT_LINES = [ProductLineType.Fresh, ProductLineType.Diy];

export interface FilterBlueprintForProductLineArgs<
  B extends Blueprint | CompiledBlueprint,
> {
  blueprint: B;
}

export function filterBlueprintForProductLine<
  B extends Blueprint | CompiledBlueprint,
>({ blueprint }: FilterBlueprintForProductLineArgs<B>): B {
  const currentProductLine = isDiySignup()
    ? ProductLineType.Diy
    : ProductLineType.Fresh;

  const clonedBlueprint = cloneDeep(blueprint);

  blueprintTraverseApply(clonedBlueprint.root, (node, parent) => {
    const productLines = node.productLines || DEFAULT_PRODUCT_LINES;

    if (!productLines.includes(currentProductLine)) {
      const index = parent?.children.findIndex(childNode => {
        return childNode.name === node.name;
      });
      if (parent && typeof index === 'number' && index > -1) {
        parent?.children.splice(index, 1);
      }
    }
  });

  return clonedBlueprint;
}
