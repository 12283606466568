import { PriceValueModalTreatments } from '../../../../utils/features';

export interface UsePriceValueExperimentReturn {
  shouldShowPriceValue: boolean;
  shouldShowKibbleBashContent: boolean;
  shouldShowProFreshContent: boolean;
}

export const usePriceValueModalExperiment = (
  treatment: PriceValueModalTreatments
): UsePriceValueExperimentReturn => {
  const shouldShowPriceValue = [
    PriceValueModalTreatments.kibble_bash,
    PriceValueModalTreatments.pro_fresh,
  ].includes(treatment);

  const shouldShowKibbleBashContent =
    treatment === PriceValueModalTreatments.kibble_bash;

  const shouldShowProFreshContent =
    treatment === PriceValueModalTreatments.pro_fresh;

  return {
    shouldShowPriceValue,
    shouldShowKibbleBashContent,
    shouldShowProFreshContent,
  };
};
