import styles from './ResponsiveEdgeWrapper.module.css';

interface ResponsiveEdgeWrapperProps {
  children: React.ReactNode;
}

/**
 * ResponsiveEdgeWrapper is a container component designed to adapt its
 * margins based on the viewport size. It provides an edge-to-edge layout
 * on mobile devices while maintaining standard margins on desktop screens.
 *
 * @example
 * <ResponsiveEdgeWrapper>
 *   <YourComponent />
 * </ResponsiveEdgeWrapper>
 *
 * @returns {JSX.Element} A div element that wraps its children with responsive styling.
 */
export function ResponsiveEdgeWrapper({
  children,
}: ResponsiveEdgeWrapperProps) {
  return <div className={styles.container}>{children}</div>;
}
