import { events, track } from 'src/analytics';
import { SubscriptionType } from 'src/graphql/types';

export interface CancelSubscriptionFlowStartPayload {
  subscriptionType?: SubscriptionType | null;
  cancellationVersion: 1 | 2;
}

export function trackCancelSubscriptionFlowStart({
  subscriptionType,
  cancellationVersion,
}: CancelSubscriptionFlowStartPayload) {
  track(events.cancel_subscription_flow_start, {
    cancellationVersion,
    subscriptionType,
  });
}
