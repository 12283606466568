import { UseForm } from '../../../types';
import { RecommendedRecipe } from '../types';

import styles from './HiddenPreSelectedRecommendation.module.css';

interface HiddenPreSelectedRecommendationArgs {
  recipe: RecommendedRecipe;
  registerProps: ReturnType<UseForm['register']>;
}
export const HiddenPreSelectedRecommendation = ({
  recipe,
  registerProps,
}: HiddenPreSelectedRecommendationArgs) => {
  return (
    <input
      key={recipe.name}
      type="checkbox"
      value={recipe.name}
      checked={true}
      {...registerProps}
      className={styles.hidden}
    />
  );
};
