import * as Apollo from '@apollo/client';

import { useMutation } from '../../services/apollo';
import { UpdateLeadMutationVariables, UpdateLeadMutation } from '../types';

import updateLead from './updateLead.graphql';

type Options = Apollo.MutationHookOptions<
  UpdateLeadMutation,
  UpdateLeadMutationVariables
>;

export function useUpdateLead(options?: Options) {
  return useMutation<UpdateLeadMutation, UpdateLeadMutationVariables>(
    updateLead,
    options
  );
}
