import { Input, CreditCardInput } from '@farmersdog/corgi-x';

import classNames from 'classnames';
import { UseCreditCardForm } from '../hooks/useCreditCardForm';
import styles from './CreditCardDetails.module.css';

export interface CreditCardNumberProps {
  className: string;
  last4Digits: string;
  isEditing: boolean;
  onCreditCardNumberFocus: UseCreditCardForm['handleCreditCardDetailsFocus'];
  onCreditCardNumberChange: UseCreditCardForm['handleCreditCardDetailsChange'];
}

/**
 * Shows placeholder for credit card number (only last 4 digits) until
 * `props.isEditing` is `true`, which exposes the Corgi/Stripe-integrated
 * component and hides the placeholder.
 */
export function CreditCardNumber(props: CreditCardNumberProps) {
  return (
    <div className={classNames(styles.parent, props.className)}>
      {props.isEditing ? (
        <CreditCardInput
          type="cardNumber"
          className={styles.cardNumber}
          label="Card Number"
          onChange={e =>
            props.onCreditCardNumberChange({ cardNumber: e.complete })
          }
        />
      ) : (
        <Input
          className={styles.placeholderElement}
          label="Card Number"
          value={`**** **** **** ${props.last4Digits}`}
          onFocus={props.onCreditCardNumberFocus}
        />
      )}
    </div>
  );
}
