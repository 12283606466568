import { trackGoogleAdsEvent } from './trackGoogleAdsEvent';

import { purchaseActionId } from './actionIds';

interface CheckoutSuccessArgs {
  userId?: number;
  value: string;
}

export function trackGoogleAdsCheckoutSuccess(data: CheckoutSuccessArgs) {
  trackGoogleAdsEvent({
    actionId: purchaseActionId,
    properties: {
      transaction_id: data.userId,
      currency: 'USD',
      value: data.value,
    },
  });
}
