import { createContext, useContext, type ReactNode } from 'react';
import { type AppProps } from '../app';

type ToggleTreatment = 'on' | 'off' | 'control';

export interface UseFeatureOptions<AttributesType extends SplitIO.Attributes> {
  /** Optional feature attributes to pass to split */
  attributes?: AttributesType;
  /** When true useFeature will not request treatment data from split until
   * getFeatureData is called. */
  lazy?: boolean;
}

export interface UseFeatureReturn<TreatmentType, ConfigType> {
  isReady: boolean;
  treatment: string;
  config: Record<string, unknown>;
  getFeatureData: () => {
    treatment: TreatmentType | 'control';
    config: ConfigType | object;
  };
}

export type UseFeature<
  TreatmentType = ToggleTreatment,
  ConfigType extends object = object,
  AttributesType extends SplitIO.Attributes = Record<
    string,
    SplitIO.AttributeType
  >,
> = (
  name: string,
  options: UseFeatureOptions<AttributesType>,
  splitKeyOverride?: string
) => UseFeatureReturn<TreatmentType, ConfigType>;

export interface ExperimentContextProps {
  useFeature?: UseFeature;
}

const initialExperimentsContext: ExperimentContextProps = {
  useFeature: undefined,
};

const ExperimentContext = createContext<ExperimentContextProps>(
  initialExperimentsContext
);

interface ExperimentProviderProps {
  children: ReactNode;
  useFeature?: AppProps['useFeature'];
}

/**
 * `ExperimentProvider` is used to distribute the `useFeature` hook to child
 * components without having to pass it down as props within PAW, since adding
 * a `SplitProvider` causes issues with Split in PAW. This helps to prevent droopy
 * props, and create easy access to said props. For more context (no pun intended)
 * as to why we don't have a `SplitProvider` in PAW, see [the State of PAW Q1 2023
 * (Section: No access to Split) doc]
 * (https://www.notion.so/thefarmersdog/State-of-PAW-Q1-2023-7defb3ff7b074916aba319e5eae8b135?pvs=4#7b44ee3a061b467aaf0b143108d5f23e)
 *
 * @param children - ReactNode
 * @param useFeature - Function used to obtain feature flag treatments
 * Split Provider.
 */
export function ExperimentProvider({
  children,
  useFeature,
}: ExperimentProviderProps) {
  return (
    <ExperimentContext.Provider value={{ useFeature }}>
      {children}
    </ExperimentContext.Provider>
  );
}

export function useExperimentContext() {
  const experimentContext = useContext(ExperimentContext);
  return experimentContext;
}
