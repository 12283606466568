import { ApolloLink } from '@apollo/client';

import { anonymousId } from '@farmersdog/lead-browser-storage';
import { getSessionProperties } from '@farmersdog/sessions';
import { ENGAGEMENT_TRACKER_VERSION } from '@farmersdog/utils';

import { setOperationHeaders } from '../utils/setOperationHeaders';

export const anonymousIdHeaderKey = 'tfd-anonymous-id';

/**
 * Global header middleware
 *
 * Automatically handles adding global headers to requests, meaning headers that
 * should apply to all requests (excluding auth token).
 */
export function createGlobalHeadersMiddleware(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    // Get headers from context
    const contextHeaders =
      (operation.getContext().Headers as Record<string, string>) || {};
    const headers: Record<string, string | number> = {
      ...contextHeaders,
    };

    // Add anonymousId header
    headers[anonymousIdHeaderKey] = anonymousId.get();

    headers['x-requester-path'] = window.location.pathname;

    // Add session headers
    const sessionData = getSessionProperties(ENGAGEMENT_TRACKER_VERSION);
    if (sessionData) {
      headers['Session-ID'] = sessionData.sessionId;
      headers['Session-Version'] = sessionData.sessionVersion;
    }

    setOperationHeaders({
      operation,
      headers,
    });

    return forward(operation);
  });
}
