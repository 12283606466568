import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TextV2, SelectorGroup, LabeledSelector } from '@farmersdog/corgi';
import { Nowrap } from '@farmersdog/corgi-x';

import { useViewport } from 'src/screen';

import { preferenceOptions } from './preferenceOptions';

import styles from './PreferenceSelector.module.scss';

function PreferenceSelector({ value, item, onOptionChange }) {
  return (
    <div
      key={item.value}
      className={classNames(styles.question, useViewport(styles))}
    >
      <TextV2 as="p" variant="body-16" className={styles.label}>
        {item.label}
      </TextV2>
      <SelectorGroup
        type="radio"
        name={item.value}
        legend={item.label}
        value={value[item.value]}
        onChange={onOptionChange}
        inputsClassName={styles.options}
        className={styles.optionsContainer}
      >
        {preferenceOptions.map(prefOption => (
          <LabeledSelector
            id={`${item.value}-${prefOption.value}`}
            key={`${item.value}-${prefOption.value}`}
            value={prefOption.value}
            className={styles.option}
          >
            <Nowrap>{prefOption.label}</Nowrap>
          </LabeledSelector>
        ))}
      </SelectorGroup>
    </div>
  );
}

PreferenceSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default PreferenceSelector;
