import { Picture } from '@farmersdog/corgi-x';

import { benefitsSrc } from './assets';
import styles from './Separator.module.css';

export function Separator() {
  return (
    <div className={styles.container}>
      <Picture
        className={styles.image}
        sources={benefitsSrc}
        alt=""
        aria-hidden="true"
      />
    </div>
  );
}
