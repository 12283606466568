import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

export function trackPickyEaterSectionArticleClicked(
  buttonText: string,
  articleLink: string
) {
  track(eventNames.homepage_picky_eater_section_clicked, {
    call_to_action: buttonText,
    article: articleLink,
  });
}
