import { SIGNUP_FETCH_DIY_QUOTE } from 'src/constants/actionTypes';

const initialState = {
  quote: null,
  subscription: null,
};

export default function signupDiyReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FETCH_DIY_QUOTE:
      return { ...state, quote: action.payload.data };
    case `${SIGNUP_FETCH_DIY_QUOTE}_FAILURE`:
      return { ...state, quote: initialState.quote };
    default:
      return state;
  }
}

export function selectDiyQuote(state) {
  return state.signup.diy.quote;
}
