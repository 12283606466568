import { NudgeCopy } from './TreatsDismissibleCta';

export const getNudgeCopy = (
  freeFirstTreatEnabled: boolean,
  petsName: string
): NudgeCopy => {
  return freeFirstTreatEnabled
    ? {
        body: `Your first regular-sized bag of Snap Sticks is FREE. They're healthy, tasty, and pair perfectly with ${petsName} plan!`,
        alt: 'Dog with snap sticks',
        headline: 'LIMITED TIME OFFER',
        subhead: 'Get a Free Bag of Treats',
        ctaText: 'Add Free Treats',
        title: 'Free First Treat Nudge',
        dismiss: 'Maybe Later',
      }
    : {
        body: `Our limited ingredient, human-grade dog treats are designed for your pup’s
  meal plan and deliver in your existing orders!`,
        alt: 'Dog with snap sticks',
        headline: 'MAKE TREATING A SNAP',
        subhead: 'Smart. Healthy. Treats.',
        ctaText: 'Shop Treats Now',
        title: 'Treats Nudge',
        dismiss: 'Maybe Later',
      };
};
