import { ApolloError, useQuery } from '@apollo/client';
import { ApplicationError } from '../../../errors';
import { StockStatus } from '../../../graphql/types.core-api';
import { StockOverviewOrderFragment } from '../fragments/StockOverviewOrder.core-api.generated-types';
import { StockOverviewSubscriptionFragment } from '../fragments/StockOverviewSubscription.core-api.generated-types';
import { FetchStockOverviewQuery } from './FetchStockOverview.core-api.generated-types';
import fetchStockOverviewQuery from './FetchStockOverview.core-api.graphql';
import floor from 'lodash/floor';
import { StockOverviewFoodPlanFragment } from '../fragments/StockOverviewFoodPlan.core-api.generated-types';

export interface StockOverviewData {
  currentStockAsPacks: number;
  isNextOrderScheduledForRecommendation: boolean;
  nextOrder: StockOverviewOrderFragment | null;
  previousOrderQuantity: number;
  stockStatusAtNextDelivery: StockStatus;
  canRescheduleNextOrder: boolean;
  projectedStockAtDate: number | undefined;
  petNames: string[];
}

export interface UseFetchStockOverview {
  loading: boolean;
  error?: ApolloError | ApplicationError;
  data?: StockOverviewData;
}

interface UseFetchStockOverviewProps {
  onError: (error: unknown) => void;
}

export function useFetchStockOverview(
  props: UseFetchStockOverviewProps
): UseFetchStockOverview {
  const request = useQuery<FetchStockOverviewQuery>(fetchStockOverviewQuery, {
    context: { endpoint: 'core-api' },
    onError: props.onError,
  });

  if (request.loading) {
    return { loading: request.loading };
  }

  if (request.error) {
    return { loading: false, error: request.error };
  }

  if (
    !request.data ||
    !request.data.me.pets ||
    !request.data.me.subscriptions[0]
  ) {
    return {
      loading: false,
      error: new ApplicationError(
        'Unknown exception occurred while loading data'
      ),
    };
  }

  // filtered to active/fresh via graphql field input
  const subscription = request.data.me.subscriptions[0];

  const currentStockAsPacks = getCurrentStockAsPacks(
    subscription.foodPlans || []
  );

  const isNextOrderScheduledForRecommendation =
    getisNextOrderScheduledForRecommendation(subscription);

  const previousOrderQuantity =
    subscription.mostRecentlyDeliveredOrder?.quantity ?? 0;

  const stockStatusAtNextDelivery =
    subscription.stockStatusAtNextDelivery ?? StockStatus.Ok;

  const canRescheduleNextOrder = subscription.canRescheduleNextOrder ?? false;

  const projectedStockAtDate = subscription.foodPlans
    ? getProjectedStockAtDate(subscription.foodPlans)
    : undefined;

  const petNames = request.data.me.pets.map(p => p.name);

  return {
    loading: false,
    error: request.error,
    data: {
      currentStockAsPacks,
      isNextOrderScheduledForRecommendation,
      nextOrder: subscription.nextOrderToBeDelivered,
      previousOrderQuantity,
      stockStatusAtNextDelivery,
      canRescheduleNextOrder,
      projectedStockAtDate,
      petNames,
    },
  };
}

/**
 * Gets the total stock as packs for all active food plans. Returns the total
 * rounded down to the nearest integer.
 */
function getCurrentStockAsPacks(
  foodPlans: StockOverviewFoodPlanFragment[]
): number {
  const activeFoodPlans = foodPlans.filter(foodPlan => foodPlan.active);
  const activePlansStockAsPacks = activeFoodPlans.map(
    foodPlan => foodPlan.stockAsPacks ?? 0
  );
  const totalStockAsPacks = activePlansStockAsPacks.reduce(
    (sum, packs) => sum + packs,
    0
  );

  return floor(totalStockAsPacks);
}

/**
 * Gets the total projected stock at next delivery for all active food plans.
 * Returns the total rounded down to the nearest integer.
 */
function getProjectedStockAtDate(foodPlans: StockOverviewFoodPlanFragment[]) {
  const projectedStockAtDate = foodPlans
    .filter(foodPlan => foodPlan.active)
    .map(foodPlan => foodPlan.projectedStockAtNextDelivery)
    .reduce((a, b) => a + b);

  return floor(projectedStockAtDate);
}

/**
 * Determines if the subscription's next date is already the earliest
 * recommended next date.
 */
function getisNextOrderScheduledForRecommendation(
  subscription: StockOverviewSubscriptionFragment | undefined
): boolean {
  return subscription?.nextDate === subscription?.recommendedNextDate;
}
