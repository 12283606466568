import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, Mask } from '@farmersdog/corgi-x';
import Slider from 'src/components/Slider';

import styles from './VetsSlider.module.scss';
import Picture from '../../../../components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function VetsSlider({ vetsData }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {vetsData.map(({ iconSources, name }, index) => (
          <button
            key={index}
            type="button"
            onClick={() => setCurrentSlide(index)}
            className={classNames(styles.button, {
              [styles.active]: currentSlide === index,
            })}
            aria-label={`show ${name} quote`}
          >
            <Mask shape="circle" size="md">
              <Picture sources={iconSources} alt={name} />
            </Mask>
          </button>
        ))}
      </div>
      <Slider currentSlide={currentSlide}>
        {vetsData.map(({ name, quote }, index) => (
          <Slider.Slide key={index}>
            <figure className={styles.slide}>
              <Text
                variant="heading-22"
                color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                as="figcaption"
                bold
                className={styles.name}
              >
                {name}
              </Text>
              <Text
                as="blockquote"
                variant="article-20"
                color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                className={styles.quote}
              >
                “{quote}”
              </Text>
            </figure>
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  );
}

VetsSlider.propTypes = {
  vetsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quote: PropTypes.node.isRequired,
      iconSources: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

export default VetsSlider;
