import { useContext, useMemo } from 'react';
import has from 'lodash/has';

import { ScreenContext } from './createScreenContext';
import { ViewportProps } from './types';

/**
 * Hook to return a memoized set of arbitrary data based on matched screens
 */
const createUseViewport = (Context: ScreenContext) => {
  function useViewport<T>(props: ViewportProps<T>) {
    const { matchedViewports } = useContext(Context);

    // @ts-expect-error TODO: Update these typings
    return useMemo((): T => {
      const matchedViewportName = matchedViewports.find(screenName =>
        has(props, screenName)
      );

      if (matchedViewportName) {
        return props[matchedViewportName as keyof typeof props];
      }
    }, [props, matchedViewports]);
  }

  return useViewport;
};

export default createUseViewport;
