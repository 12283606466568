import { TypedUseSelectorHook, useSelector } from 'react-redux';

// this matches the previous behavior of react-redux
// if we want to continue using it we should fully type this
// or else we should refactor / delete all of our redux code at some point
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface RootState {}

// create a typed selector otherwise the state is of type "unknown"
// https://redux.js.org/tutorials/typescript-quick-start#define-typed-hooks
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
