export const MAX_COOKIE_EXPIRATION = new Date('Tue, 19 Jan 2038 04:14:07 GMT');

export const TOKEN_COOKIE = 'website-token';
export const REFERRAL_COOKIE = 'referral';
export const IMPACT_CLICK_ID_COOKIE = 'impact-click-id';
export const PHONE_SALES_AGENT_COOKIE = 'phone-sales-agent';

export const SIGNUP_PLAN_PAGE_COOKIE = 'signup-plan-page';

export const SIGNUP_EXIT_INTENT_CHECKOUT_COOKIE = 'signup-exit-intent-checkout';
export const SIGNUP_EXIT_INTENT_ME_COOKIE = 'signup-exit-intent-me';
export const SIGNUP_EXIT_INTENT_PETS_COOKIE = 'signup-exit-intent-pets';
export const SIGNUP_EXIT_INTENT_PLANS_COOKIE = 'signup-exit-intent-plans';
export const SIGNUP_EXIT_INTENT_RECIPES_COOKIE = 'signup-exit-intent-recipes';
export const TERMS_CHANGES_NOTICE_DISMISSED_COOKIE =
  'tfd-terms-changes-notice-dismissed';

// Used by `src/components/Details` component
export const DETAILS_COOKIE = 'details';

export const SEGMENT_USER_ID_COOKIE = 'ajs_user_id';
