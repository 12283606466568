export interface WelcomeMessageCopyInputs {
  petNames: string;
  friendOrFriends: string;
}

export const beforeBoxTwoSubHeading = ({
  petNames,
  friendOrFriends,
}: WelcomeMessageCopyInputs) =>
  `You’ve just made the best decision for your best ${friendOrFriends}. We're so excited for ${petNames} to start eating a fresh, healthy diet with bowlfuls of benefits (for them and you).`;

export const beforeBoxTwoSubHeadingExperimentPicky = ({
  petNames,
  friendOrFriends,
}: WelcomeMessageCopyInputs) =>
  `You’ve just made the best decision for your best ${friendOrFriends}. Our recommended plan is a starting point—we can help make any adjustments you or ${petNames} need, and we’ll email you before starting future orders.`;

export const afterBoxTwoSubHeading = ({ petNames }: WelcomeMessageCopyInputs) =>
  `You're building a great, healthy habit for ${petNames}—so give them a rub on the belly and yourself a pat on the back. We're here to help every step of the way.`;
