import { radio } from './radio';

/** The attributes required for a Radio.com checkout conversion event */
export const attributes = {
  ppt: '7767',
  g: 'purchase',
  gid: '26797',
};

export interface MountRadioConversionProps {
  userId: number;
}

/**
 * Mount a Radio.com checkout conversion event.
 */
export function mountRadioCheckoutConversion({
  userId,
}: MountRadioConversionProps): void {
  radio.mount({
    ...attributes,
    cv1: String(userId),
  });
}
