import { useFormik } from 'formik';
import noop from 'lodash/noop';
import { PetsFieldsFragment } from '../../network';

type UseSelectRecipesFormProps = {
  id: number;
  displayName: string;
  name: string;
  selected: boolean;
  mainIngredients: string;
};

export function useSelectRecipesForm(
  currentFreshFoodProducts: PetsFieldsFragment['foodRecipes'],
  availableFreshFoodProducts: PetsFieldsFragment['availableFreshFoodProducts']
) {
  // Recipe.displayName represents shared labels between fresh food products
  const currentDisplayNames = currentFreshFoodProducts.map(
    product => product.displayName
  );

  const initialValues: UseSelectRecipesFormProps[] =
    availableFreshFoodProducts.map(product => ({
      ...product,
      selected: currentDisplayNames.includes(product.displayName),
    }));

  return useFormik<UseSelectRecipesFormProps[]>({
    initialValues,
    onSubmit: noop,
  });
}
