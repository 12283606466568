import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCorgiViewport } from 'src/screen';
import { Text, type TextProps } from '@farmersdog/corgi-x';

import styles from './HomepageTitle.module.scss';

interface HomepageTitle1Props extends Omit<TextProps, 'variant'> {
  center: boolean;
}

export function HomepageTitle1({
  children,
  center,
  className,
  ...props
}: HomepageTitle1Props) {
  const variant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-28',
    sm: 'heading-40',
  });

  return (
    <Text
      as="h1"
      color="kale-3"
      variant={variant}
      className={classNames(styles.margin, className, {
        [styles.center]: center,
      })}
      bold
      {...props}
    >
      {children}
    </Text>
  );
}

HomepageTitle1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  center: PropTypes.bool,
};

interface HomepageTitle2Props extends Omit<TextProps, 'variant'> {
  center: boolean;
}

export function HomepageTitle2({
  children,
  center,
  className,
  ...props
}: HomepageTitle2Props) {
  return (
    <Text
      as="h2"
      variant="heading-28"
      color="kale-3"
      className={classNames(styles.margin, className, {
        [styles.center]: center,
      })}
      bold
      {...props}
    >
      {children}
    </Text>
  );
}

HomepageTitle2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  center: PropTypes.bool,
};
