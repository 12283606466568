import {
  handlePluralPlaceholders,
  handlePronounPlaceholders,
  handlePetNamePlaceholders,
  handlePetNamesPlaceholders,
  handlePetSterilizedPlaceholders,
  handleBodyConditionPlaceholders,
} from '../utils';

interface HandleBodyConditionArgs {
  bodyCondition: string;
  applyStartCasing?: boolean;
}
export class Placeholder {
  public modified: string;

  constructor(input: string) {
    this.modified = input;
  }

  handlePlural(isPlural: boolean) {
    this.modified = handlePluralPlaceholders({
      input: this.modified,
      isPlural,
    });
    return this;
  }

  handlePronoun(gender: string) {
    this.modified = handlePronounPlaceholders({ input: this.modified, gender });
    return this;
  }

  handlePetName(petName: string) {
    this.modified = handlePetNamePlaceholders({
      input: this.modified,
      petName,
    });
    return this;
  }

  handlePetNames(petNames: string[]) {
    this.modified = handlePetNamesPlaceholders({
      input: this.modified,
      petNames,
    });
    return this;
  }
  handlePetSterilized(gender: string) {
    this.modified = handlePetSterilizedPlaceholders({
      input: this.modified,
      gender,
    });
    return this;
  }

  handleBodyCondition({
    bodyCondition,
    applyStartCasing,
  }: HandleBodyConditionArgs) {
    this.modified = handleBodyConditionPlaceholders({
      input: this.modified,
      bodyCondition,
      applyStartCasing,
    });
    return this;
  }
}
