import PoopSvg from '../assets/poop.svg';
import { DidYouKnowBox } from '../DidYouKnowBox';
import responsiveStyles from '../PlanCalculationWithLoader.module.css';

interface ResponsiveHealthierPoopStatic {
  isMobile?: boolean;
}

function ResponsiveHealthierPoopStatic({
  isMobile,
}: ResponsiveHealthierPoopStatic) {
  return (
    <DidYouKnowBox
      className={isMobile ? responsiveStyles.mobile : responsiveStyles.desktop}
      Svg={PoopSvg}
    >
      Our fresh recipes are more {isMobile && <br />}digestible than highly{' '}
      {isMobile && <br />}
      processed kibble, resulting {isMobile && <br />}in smaller, healthier
      poops.
    </DidYouKnowBox>
  );
}

export const HealthierPoopStatic = () => {
  return (
    <>
      <ResponsiveHealthierPoopStatic />
      <ResponsiveHealthierPoopStatic isMobile />
    </>
  );
};
