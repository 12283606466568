import analytics from './analytics';
import { getSegmentOpts } from './helpers/getSegmentOpts';

function identify(
  userId: string | undefined,
  traits?: Record<string, unknown>,
  callbackIfMethodIsUndefined?: () => void
) {
  // Since we've typed userId as `string | undefined`, this is necessary to
  // play nice with the TS overload for Segment's identify method.
  const lastTwoIdentifyArguments = [
    traits,
    getSegmentOpts({ eventType: 'identify' }),
  ];

  if (userId) {
    analytics(callbackIfMethodIsUndefined).identify(
      userId,
      ...lastTwoIdentifyArguments
    );
  } else {
    analytics(callbackIfMethodIsUndefined).identify(
      ...lastTwoIdentifyArguments
    );
  }
}

export default identify;
