import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { browserOnly } from '@farmersdog/utils';

import { trackGa4PageView } from './trackGa4PageView';

import { shouldRunPageViewFromWebsite } from '../utils';

interface UseTrackGa4PageViewArgs {
  isRecipesPage: boolean;
  showTosaRecipesPage: boolean;
}
/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for a Ga4 page view event.
 */
export function useTrackGa4PageView({
  isRecipesPage,
  showTosaRecipesPage,
}: UseTrackGa4PageViewArgs) {
  const location = useLocation();
  const { pathname, search, hash } = location;

  const origin = browserOnly(
    (window: Window) => window.location.origin,
    () => ''
  );

  const shouldRunFromWebsite = shouldRunPageViewFromWebsite({
    pathname,
    isRecipesPage,
    showTosaRecipesPage,
  });

  useEffect(() => {
    if (shouldRunFromWebsite) {
      trackGa4PageView({ pathname, search, hash, origin });
    }
  }, [pathname, hash, search, origin, shouldRunFromWebsite]);
}
