import PropTypes from 'prop-types';
import { TextV2 } from '@farmersdog/corgi';
import { Button, Nowrap } from '@farmersdog/corgi-x';

import { Helmet } from 'react-helmet-async';

import { TitleWithCopy } from 'src/layouts/TitleWithCopy';
import { TITLE } from './copy';

import ActionButtons from './ActionButtons';

function SeePricing({ goToPrevSlide }) {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TitleWithCopy title={TITLE} align="center">
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          Prices range from $2-$12/day depending on dog profile and your{' '}
          <Nowrap>own preferences</Nowrap>.
        </TextV2>
        <TextV2 as="p" variant="body-16" color="charcoal-3">
          You will see your meal plans and price options -{' '}
          <Nowrap>before checkout</Nowrap>.
        </TextV2>
        <ActionButtons>
          <Button variant="secondary" onClick={goToPrevSlide}>
            Back
          </Button>
          <Button variant="primary" type="submit">
            Close
          </Button>
        </ActionButtons>
      </TitleWithCopy>
    </>
  );
}

SeePricing.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
};

export const SLIDE_NAME = 'SEE_PRICING';

export default SeePricing;
