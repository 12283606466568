import { useFetchHomepageData } from '../../../../../network';
import { reporter } from '../../../../../../errors/services';
import { SlotEligibility } from '../../../../../../slots';

/**
 * Checks if the user is eligible to see the Founders video
 */
export function useEligibility(): SlotEligibility {
  const request = useFetchHomepageData({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving data for Founders Video eligibility', {
        sourceError: error,
      });
    },
  });

  const isReady = !request.loading && !request.error;
  if (!isReady) {
    return {
      isReady,
      isCandidate: false,
    };
  }

  if (
    request?.data?.customer?.freshSubscription?.regularOrdersDeliveredCount ==
    undefined
  ) {
    throw new Error('Error retrieving data for Founders Video eligibility');
  }

  const isCandidate =
    isReady &&
    request.data.customer.freshSubscription.regularOrdersDeliveredCount < 2;

  return {
    isReady,
    isCandidate,
  };
}
