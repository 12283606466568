import { useEffect } from 'react';
import {
  OrderDeliveryWindowDisplayedPayload,
  trackOrderDeliveryWindowDisplayed,
} from './trackOrderDeliveryWindowDisplayed';
import isNil from 'lodash/isNil';

export const useTrackOrderDeliveryWindowDisplayed = (
  payload: OrderDeliveryWindowDisplayedPayload
) => {
  useEffect(() => {
    if (!isNil(payload.fromDate) && !isNil(payload.toDate)) {
      trackOrderDeliveryWindowDisplayed(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.orderId, payload.fromDate, payload.toDate]);
};
