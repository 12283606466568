import { APP_TYPE } from './iSpot';

interface GetISpotParmsArgs {
  utmSource?: unknown;
}

interface GetISpotParamsReturn {
  app: string;
  customData: string;
  channel: string;
}

export function getSharedISpotParams({
  utmSource,
}: GetISpotParmsArgs): GetISpotParamsReturn {
  // utm source is defaulted to null
  const utmSourceStr = typeof utmSource === 'string' ? utmSource : 'null';
  const customData = `channel_${utmSourceStr}`;

  return {
    app: APP_TYPE,
    customData,
    channel: utmSourceStr,
  };
}
