export const phoneCouponCodes = new Set([
  'phone50',
  'phone60',
  'phone70',
  'phone100',
]);

export function isPhoneSalesCoupon(couponCode?: string) {
  if (!couponCode) {
    return false;
  }

  return phoneCouponCodes.has(couponCode);
}
