import { Picture } from '@farmersdog/corgi-x';
import { freshFoodPictureSources } from '../../../assets';

interface RecipeBowlPictureProps {
  recipeName: string;
  className?: string;
}

export default function RecipeBowlPicture({
  recipeName,
  className,
}: RecipeBowlPictureProps) {
  return freshFoodPictureSources[recipeName] ? (
    <Picture
      sources={freshFoodPictureSources[recipeName]}
      alt={recipeName}
      className={className}
    />
  ) : (
    <></>
  );
}
