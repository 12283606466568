import { PATH_ADJUST_DAILY_CALORIES } from '@farmersdog/constants/paths';
import noop from 'lodash/noop';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { SubscriptionType } from '../../../../../graphql/types.core-api';
import {
  CancelSubscriptionFlowSavePayload,
  trackCancelSubscriptionFlowSave,
} from '../../../../analytics';
import { CancellationResponsesEnumType } from '../../../../flow-state-management';

export enum LpfDownsellState {
  CostAssessment = 'CostAssessment',
  LpfEducation = 'LpfEducation',
  LpfSuccess = 'LpfSuccess',
  IntroMixingPlan = 'IntroMixingPlan',
  MixingPlanEducation = 'MixingPlanEducation',
}

interface LpfDownsellControls {
  onPrevious: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
}

type LpfDownsellNavigation = Record<LpfDownsellState, LpfDownsellControls>;

interface UseLpfDownsellProgressProps {
  initialState: LpfDownsellState;
  cancellationReasonsHistory: CancellationResponsesEnumType[];
  onBackToCancellationReasons: () => void;
  onContinueToCancel: () => void;
}

/**
 * Handles navigation logic for the LPF downsell flow.
 */
export function useLpfDownsellProgress(props: UseLpfDownsellProgressProps) {
  const { initialState } = props;

  const [currentState, setCurrentState] = useState(initialState);
  const history = useHistory<{
    cancelSubscriptionFlowSavePayload: CancelSubscriptionFlowSavePayload;
  }>();

  const onLpfDownsellPrimaryClick = useCallback(() => {
    trackCancelSubscriptionFlowSave({
      saveReason: 'lpf-downsell',
      subscriptionType: SubscriptionType.Fresh,
      cancellationReasonsHistory: props.cancellationReasonsHistory,
    });

    setCurrentState(LpfDownsellState.LpfSuccess);
  }, [props.cancellationReasonsHistory]);

  const onMixingPlanEducationPrimaryClick = useCallback(() => {
    history.push(
      PATH_ADJUST_DAILY_CALORIES,
      // Save tracking payload in router state to be fired on change calories completion
      {
        cancelSubscriptionFlowSavePayload: {
          saveReason: 'change-meals-size-lpf-flow',
          subscriptionType: SubscriptionType.Fresh,
          cancellationReasonsHistory: props.cancellationReasonsHistory,
        },
      }
    );
  }, [history, props.cancellationReasonsHistory]);

  const navigation: LpfDownsellNavigation = useMemo(
    () => ({
      [LpfDownsellState.CostAssessment]: {
        onPrevious: () => props.onBackToCancellationReasons(),
        onPrimaryClick: () => setCurrentState(LpfDownsellState.LpfEducation),
        onSecondaryClick: noop,
      },
      [LpfDownsellState.LpfEducation]: {
        onPrevious: () =>
          initialState === LpfDownsellState.CostAssessment
            ? setCurrentState(LpfDownsellState.CostAssessment)
            : props.onBackToCancellationReasons(),
        onPrimaryClick: () => onLpfDownsellPrimaryClick(),
        onSecondaryClick: () =>
          setCurrentState(LpfDownsellState.IntroMixingPlan),
      },
      [LpfDownsellState.LpfSuccess]: {
        onPrevious: noop,
        onPrimaryClick: noop,
        onSecondaryClick: noop,
      },
      [LpfDownsellState.IntroMixingPlan]: {
        onPrevious: () => setCurrentState(LpfDownsellState.LpfEducation),
        onPrimaryClick: () => props.onContinueToCancel(),
        onSecondaryClick: () =>
          setCurrentState(LpfDownsellState.MixingPlanEducation),
      },
      [LpfDownsellState.MixingPlanEducation]: {
        onPrevious: () => setCurrentState(LpfDownsellState.IntroMixingPlan),
        onPrimaryClick: () => onMixingPlanEducationPrimaryClick(),
        onSecondaryClick: noop,
      },
    }),
    [
      props,
      initialState,
      onLpfDownsellPrimaryClick,
      onMixingPlanEducationPrimaryClick,
    ]
  );

  return {
    currentState,
    ...navigation[currentState],
  };
}
