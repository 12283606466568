import PropTypes from 'prop-types';
import { Divider } from '@farmersdog/corgi-x';
import { MARGIN_MD } from '@farmersdog/corgi/build/constants';

import styles from './ActionButtons.module.scss';

function ActionButtons({ children }) {
  return (
    <>
      <Divider margin={MARGIN_MD} color="charcoal-0" orientation="horizontal" />
      <div className={styles.buttons}>{children}</div>
    </>
  );
}

ActionButtons.propTypes = {
  children: PropTypes.node,
};

export default ActionButtons;
