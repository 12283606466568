import * as Apollo from '@apollo/client';

import { useQuery } from '../../services/apollo';
import { ValidateZipcodeQueryVariables, ValidateZipcodeQuery } from '../types';

import validateZipcode from './validateZipcode.graphql';

type Options = Apollo.QueryHookOptions<
  ValidateZipcodeQuery,
  ValidateZipcodeQueryVariables
>;

export function useValidateZipcode(options?: Options) {
  return useQuery<ValidateZipcodeQuery, ValidateZipcodeQueryVariables>(
    validateZipcode,
    options
  );
}
