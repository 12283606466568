import { artsAi, ArtsAiEventName, PIXEL_ID } from './artsAi';

/**
 * Track an ArtsAI checkout conversion
 */
export function trackArtsAiCheckoutConversion(): void {
  artsAi.track({
    pixid: PIXEL_ID,
    action: ArtsAiEventName.CHECKOUT_SUCCESS,
    ad_log: 'referer',
  });
}
