/**
 * Converts a json string into JSON.  If an error occurs
 * default to object as split can not send an array.
 *
 * @param jsonString - JSON string to parse
 */
export function safeJsonParse(jsonString: string | null | undefined): object {
  if (!jsonString) {
    return {};
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(jsonString);
  } catch {
    return {};
  }
}
