import { useToastDialog } from '@farmersdog/corgi';
import noop from 'lodash/noop';
import { Loading } from '../components';
import {
  CancellationReasons,
  CancellationSuccess,
  DelaySavePrompt,
  DiyDownsell,
  FreeResponse,
} from './features';
import { PriceDownsell } from './features/PriceDownsell/PriceDownsell';
import {
  CancellationResponsesEnum,
  CancellationResponsesEnumType,
  InitialStatesEnum,
  TerminalStatesEnum,
  getChangeFreeResponseTextStates,
  getDelaySaveStates,
  getFreeResponseStates,
  getMixingPlanSaveStates,
  getPrescriptionDiets,
  getReplacementFoodTypeStates,
  useCancellationProgress,
} from './flow-state-management';
import {
  useCancelFreshSubscription,
  useFetchCancellationReasonsData,
} from './network';
import { getFilteredCancellationReasons } from './utils';

/**
 * This is the primary Cancellation Flow for Fresh users, and handles
 * each step of the process.  For the DIY cancellation flow, see the module
 * `CancelSubscription` found in `website/website`.
 *
 * @see file://./../../../website/src/workflows/CancelSubscription/CancelSubscription.tsx
 */
export function CancellationFlow() {
  const request = useFetchCancellationReasonsData();
  const dispatchToast = useToastDialog();
  const flow = useCancellationProgress({
    initialState: InitialStatesEnum.Primary,
  });
  const cancelSubmit = useCancelFreshSubscription({
    onCompleted: () => {
      flow.onCancellationSuccess();
    },
    onError: () => {
      dispatchToast({
        variant: 'negative',
        message: 'There was an error submitting your cancellation request.',
        buttonLabel: 'Close',
        close: noop,
      });
    },
  });

  /**
   * A wrapper for our mutation's submit function. If called during a "question"
   * state, the caller needs to append the `finalResponse` (currently selected
   * response) to the history
   */
  const cancelSubmitFreshSubscription = (
    finalResponse?: CancellationResponsesEnumType
  ) => {
    const allCancellationReasons = [...flow.reasonsHistory];
    if (finalResponse) {
      allCancellationReasons.push(finalResponse);
    }

    cancelSubmit.submit({
      cancellationReasons: getFilteredCancellationReasons(
        allCancellationReasons
      ),
      replacementFoodBrand: flow.selectedFoodBrand,
      prescriptionDiet: getPrescriptionDiet(allCancellationReasons),
      replacementFoodType: getReplacementFoodType(allCancellationReasons),
      cookAtHomeReplacementRecipe: flow.selectedCookAtHomeReplacementRecipe,
      freeResponse: flow.freeResponse,
    });
  };

  if (request.error) {
    throw request.error;
  }

  if (request.loading || cancelSubmit.isSubmitting) {
    return <Loading />;
  }

  if (!request.data) {
    throw new Error('Unknown exception occurred while loading data');
  }

  if (flow.isState(TerminalStatesEnum.CancelSuccess)) {
    if (flow.selectedReason === CancellationResponsesEnum.IllCookAtHome) {
      return (
        <DiyDownsell
          onPrevious={flow.onPrevious}
          onClose={flow.onClose}
          pets={request.data.pets}
        />
      );
    }
    return (
      <CancellationSuccess
        isUnlocked={request.data.nextOrderToBeDelivered.isUnlocked || false}
        cancellationReasons={[
          ...flow.reasonsHistory,
          ...(flow.selectedReason ? [flow.selectedReason] : []),
        ]}
        deliveryWindow={request.data.nextOrderToBeDelivered.deliveryWindow}
        onExit={flow.onClose}
      />
    );
  }

  const delaySaveStates = getDelaySaveStates();
  if (delaySaveStates.some(flow.isState)) {
    return (
      <DelaySavePrompt
        nextOrder={request.data.nextOrderToBeDelivered}
        onConfirmCancellation={() => cancelSubmitFreshSubscription()}
        onClose={flow.onClose}
        onPrevious={flow.onPrevious}
        cancellationReasonsHistory={flow.reasonsHistory}
      />
    );
  }

  const mixingPlanSaveStates = getMixingPlanSaveStates();
  if (mixingPlanSaveStates.some(s => flow.isState(s))) {
    return (
      <PriceDownsell
        reasonsHistory={flow.reasonsHistory}
        currentWeeklyPrice={
          request.data.freshSubscription.lastQuotedPrice.shipmentPricePerWeek
        }
        onPrevious={flow.onPrevious}
        onContinueToCancel={flow.transitionToReplacementFoodState}
        onClose={flow.onClose}
        pets={request.data.pets}
      />
    );
  }

  const freeResponseStates = getFreeResponseStates();
  if (freeResponseStates.some(flow.isState)) {
    const changeFreeResponseCopyStates = getChangeFreeResponseTextStates();

    return (
      <FreeResponse
        onNext={flow.transitionToReplacementFoodState}
        onPrevious={flow.onPrevious}
        onClose={flow.onClose}
        freeResponse={flow.freeResponse}
        setFreeResponse={flow.setFreeResponse}
        showSpecifyRecipeText={changeFreeResponseCopyStates.some(flow.isState)}
      />
    );
  }

  // TODO: Integrate DiyDownsell with FLOW
  // https://app.shortcut.com/farmersdog/story/96660/spike-investigate-how-to-display-diy-downsell-cancellation-success-pathway
  // if (flow.isState('INSERT DIY STATE')) {
  //   return (
  //     <DiyDownsell
  //       onPrevious={flow.onPrevious}
  //       onClose={flow.onClose}
  //       pets={[{ name: 'Uno' }]}
  //       pets={request.pets}
  //     />
  //   );
  // }

  return (
    <CancellationReasons
      question={flow.question ?? ''}
      reasons={flow.reasons}
      selectedReason={flow.selectedReason}
      onReasonChange={flow.onReasonChange}
      onNext={flow.onNext}
      onPrevious={flow.onPrevious}
      onClose={flow.onClose}
      isState={flow.isState}
      onSubmit={cancelSubmitFreshSubscription}
      pets={request.data.pets}
      foodBrands={request.data.foodBrands}
      selectedFoodBrand={flow.selectedFoodBrand}
      onFoodBrandChange={flow.onFoodBrandChange}
      selectedCookAtHomeReplacementRecipe={
        flow.selectedCookAtHomeReplacementRecipe
      }
      onCookAtHomeReplacementRecipeChange={
        flow.onCookAtHomeReplacementRecipeChange
      }
    />
  );
}

function getPrescriptionDiet(
  reasonsHistory: CancellationResponsesEnumType[]
): CancellationResponsesEnumType | undefined {
  const prescriptionDiets = getPrescriptionDiets();

  return reasonsHistory.find(reason => {
    return prescriptionDiets.includes(reason);
  });
}

function getReplacementFoodType(
  reasonsHistory: CancellationResponsesEnumType[]
): CancellationResponsesEnumType | undefined {
  const replacementFoodTypes = getReplacementFoodTypeStates();

  return reasonsHistory.find(reason => {
    return replacementFoodTypes.includes(reason);
  });
}
