import { Picture, Text } from '@farmersdog/corgi-x';
import { QueryParameter } from '@farmersdog/constants';
import {
  PATH_CALIBRATE_PET_EDIT,
  PAW_PATH_MY_DOGS,
} from '@farmersdog/constants/paths';
import {
  setPathQueries,
  setPathParams,
  formatPetDescription,
} from '../../../utils';
import { CgsActivityLevel, CgsBodyCondition } from '../../../graphql/types.cgs';
import { ActionHeader } from '../ActionHeader';
import {
  bodyConditionResourcesSmall,
  activityLevelResource,
} from '../../../assets';
import styles from './PetProfile.module.css';
import { getActivityLevel } from '../../utils/getActivityLevel';
import { getBodyCondition } from '../../utils/getBodyCondition';

interface PetProfileProps {
  name: string;
  breedsForDisplay: string[];
  bodyCondition: CgsBodyCondition | null;
  ageInWeeks: number;
  weightInPounds: number;
  activityLevel: CgsActivityLevel | null;
}

export function PetProfile(props: PetProfileProps) {
  const petDescription = formatPetDescription({
    breeds: props.breedsForDisplay,
    weightInPounds: props.weightInPounds,
    ageInWeeks: props.ageInWeeks,
  });

  const activityLevel = getActivityLevel(props.activityLevel);
  const bodyCondition = getBodyCondition(props.bodyCondition);

  return (
    <section>
      <ActionHeader
        headerText="Profile"
        actionText="Edit"
        actionAriaLabel={`Edit ${props.name} profile`}
        actionTo={setPathQueries(
          setPathParams(PATH_CALIBRATE_PET_EDIT, { petName: props.name }),
          {
            [QueryParameter.PrevPath]: PAW_PATH_MY_DOGS,
          }
        )}
      />
      <Text variant="heading-16" as="p" color="charcoal-3" vSpacing="xxs">
        {petDescription}
      </Text>
      {bodyCondition && (
        <div className={styles['section-container']}>
          {props.bodyCondition && (
            <Picture
              sources={bodyConditionResourcesSmall[props.bodyCondition]}
              alt=""
              className={styles.picture}
            />
          )}
          <div className={styles['sub-section-container']}>
            <Text variant="heading-16" bold>
              {bodyCondition.name}
            </Text>
            <Text variant="heading-16">{bodyCondition.description}</Text>
          </div>
        </div>
      )}
      {activityLevel && (
        <div className={styles['section-container']}>
          {props.activityLevel && (
            <Picture
              sources={activityLevelResource[props.activityLevel]}
              alt=""
              className={styles.picture}
            />
          )}
          <div className={styles['sub-section-container']}>
            <Text variant="heading-16" bold>
              {activityLevel.name}
            </Text>
            <Text variant="heading-16">{activityLevel.description}</Text>
          </div>
        </div>
      )}
    </section>
  );
}
