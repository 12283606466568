import { getSharedISpotParams } from './getSharedISpotParams';
import {
  iSpot,
  Premion,
  ISpotEventName,
  PremionEventName,
  PREMION_REF_ID,
} from './iSpot';

/**
 * Mount the iSpot recipes conversion event
 */

export function mountISpotRecipesConversion({
  utmSource,
}: {
  utmSource: unknown;
}) {
  const cacheBuster = Math.round(new Date().getTime() / 1000);

  const { customData, channel, app } = getSharedISpotParams({
    utmSource,
  });

  iSpot.mount({
    app,
    type: ISpotEventName.ADD_TO_CART,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });

  Premion.mount({
    app,
    type: PremionEventName.ADD_TO_CART,
    refid: PREMION_REF_ID,
    url: window.location.pathname,
    customdata: customData,
    channel,
    ord: cacheBuster,
  });
}
