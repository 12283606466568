import { useMutation } from '@apollo/client';
import {
  RemoveClinicMutation,
  RemoveClinicMutationVariables,
} from './RemoveClinic.cgs.generated-types';
import RemoveClinic from './RemoveClinic.cgs.graphql';

export const useRemoveClinic = () => {
  return useMutation<RemoveClinicMutation, RemoveClinicMutationVariables>(
    RemoveClinic,
    {
      context: { endpoint: 'cgs' },
    }
  );
};
