import omit from 'lodash/omit';

import { PetNodes } from '@farmersdog/constants';

import { FormFieldsType } from '../types';

import { getPossiblePetFields } from './getPossiblePetFields';

export interface RemovePetDataFromStateArgs {
  state: FormFieldsType;
}
export function getStateWithoutPetData({
  state,
}: RemovePetDataFromStateArgs): [FormFieldsType, string[]] {
  const fieldsNames = Object.values(PetNodes).filter(
    nodeName => nodeName !== PetNodes.Name
  );
  const fieldsToRemove = getPossiblePetFields({ petFields: fieldsNames });

  return [omit(state, fieldsToRemove), fieldsToRemove];
}
