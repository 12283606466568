import { reporter } from '../../../../../../errors/services';
import { useFetchHomepageData } from '../../../../../network';
import { useOrderSizeNudgeStorage } from '../../OrderSizeDismissibleCta/hooks/useOrderSizeNudgeStorage';
import { isEligibleForOrderSizeUpCtaByCriteria } from '../utils/isEligibleForOrderSizeUpCta';

export function useOrderSizeUpCtaData() {
  const { loading, data, error } = useFetchHomepageData({
    onError: (err: unknown) => {
      reporter.warn('Error retrieving data for the Order Size CTA', {
        sourceError: err,
      });
    },
  });

  // share local storage with the order size down nudge
  // ex: we want to avoid user seeing nudge up, increasing order size, and then seeing nudge down
  const { getOrderSizeNudgeDismissed } = useOrderSizeNudgeStorage(
    window.localStorage
  );

  const frequency = data?.customer.freshSubscription.frequency ?? 0;
  const regularOrdersDeliveredCount =
    data?.customer.freshSubscription.regularOrdersDeliveredCount ?? 0;
  const nextOrderIsUnlocked =
    data?.customer.nextOrderToBeDelivered?.isUnlocked ?? false;
  const signedUpWithFrequencyCapDiscountCode =
    !!data?.customer.experimentEligibility
      .hasOrderSizeUpNudgeEligibleCouponCode;

  const criteria = {
    frequency,
    regularOrdersDeliveredCount,
    nextOrderIsUnlocked,
    signedUpWithFrequencyCapDiscountCode,
  };

  const eligibleByCriteria = isEligibleForOrderSizeUpCtaByCriteria({
    criteria,
  });
  const noData = !data;
  const isReady = !loading && !error && !noData;

  const isDismissed = getOrderSizeNudgeDismissed();

  return {
    isReady,
    eligibleByCriteria,
    isDismissed,
    nextOrderDeliveryWindow:
      data?.customer.nextOrderToBeDelivered.deliveryWindow,
  };
}
