import { NO_PREFERENCE } from '../preferenceOptions';
import { vegetableOptions } from '../vegetableOptions';
import { additionalIngredientOptions } from '../additionalIngredientOptions';

export interface IngredientOption {
  value: string;
  label: string;
}

export function getIngredientsInitialState(ingredients: IngredientOption[]) {
  return ingredients.reduce(
    (prevState, option) => {
      prevState[option.value] = NO_PREFERENCE.value;
      return prevState;
    },
    {} as Record<string, string>
  );
}

export function getVegetablesInitialState() {
  return getIngredientsInitialState(vegetableOptions);
}

export function getAdditionalIngredientsInitialState() {
  return getIngredientsInitialState(additionalIngredientOptions);
}
