import { CvrWeightlossPersonalizationTreatments } from '../../../utils';

export const genericQuestions = [
  {
    title: 'Why should I feed my dog fresh food?',
    content:
      'Fresh is not dry, it’s not wet, or raw. Fresh is gently cooked just enough to get rid of dangerous pathogens while maintaining whole food integrity. We believe there’s a big difference between surviving and thriving. As pet obesity, cancer, and diabetes rise at alarming rates, there is a growing body of scientific evidence that points to the health benefits of a fresh food diet, and to the dangers of many commercial pet food manufacturing processes. And whether your dog is a picky eater grappling with a particular health issue or perfectly healthy and willing to eat just about anything, we’ve seen first-hand the positive impact of feeding fresh to our own pups and our customers’.',
  },
  {
    title: 'How are your meals made?',
    content:
      'Our meals are prepared in human food facilities, where each recipe is gently cooked at low temperatures according to human-grade standards. They’re then quickly frozen (never deep frozen!) for safe shipping and your storage convenience. Since we package and deliver your meals on a convenient subscription, your food is truly made fresh and never contains preservatives which allow kibble to sit on a shelf for years at a time. Sometimes things just work out to be a win-win.',
  },
  {
    title: 'Do I need to refrigerate the food?',
    content:
      'Yes! Since our food is truly made fresh and we donʼt use any preservatives, itʼs essential that you treat it like real food (because it is!). Keep the food at a safe temperature in a refrigerator or freezer. Your box will also include an easy guide on how to store and feed the food properly (it’s very simple!).',
  },
  {
    title: 'Do I need to cook the food?',
    content:
      'No need, our food is pre-portioned and ready-to-serve! You can serve it directly from the fridge or add a touch of hot water if your dog prefers warm food.',
  },
  {
    title: 'How do shipments work? Do you ship nationwide?',
    content:
      'We currently ship to the 48 contiguous states. With our meal plans, customers receive deliveries of freshly prepared food on a recurring and flexible basis. Plans are customized to fit your dog’s needs and come in convenient serving packs to make feeding easy. Your pup’s food arrives in refrigerated and environmentally friendly boxes that keep the food safe. We perfectly time deliveries to make sure you never run out of food and you will have the ability to adjust deliveries at any time online.',
  },
];

export const weightLossQuestions = [
  {
    title: 'How do you know what kind of food my dog needs?',
    content:
      'We work closely with vets to figure out the optimal meal plans for different combinations of breed, age, activity level, and ideal weight. That way, we can ask you the right questions to create a plan that’s truly customized to your dogs’ characteristics and needs. After you start, our trusty team is here every step of the way to help monitor your dog’s progress and make adjustments to your plan.',
  },
  {
    title: 'How do you determine my dogʼs portions?',
    content:
      'We work closely with our vet nutritionists to figure out the optimal calorie needs for different combinations of breed, age, activity level, and ideal weight. Certain dogs can metabolize faster than others, so some plans may need further adjusting.',
  },
  ...genericQuestions,
];

export const getQuestions = (
  treatment: CvrWeightlossPersonalizationTreatments
) =>
  treatment === CvrWeightlossPersonalizationTreatments.generic
    ? genericQuestions
    : weightLossQuestions;
