import { Redirect, Switch, Route } from 'react-router-dom';
import { useAuthContext } from '../app';
import { AccountRoutes } from './AccountRoutes';
import {
  PAW_PATH_ACCOUNT,
  PAW_PATH_CUSTOMER_ACCOUNT,
  PAW_PATH_HOME,
  PAW_PATH_LOGIN,
} from '@farmersdog/constants/paths';
import { LoginPage } from '../login';
import NotFound from '../components/NotFound';
import { CustomerAccountData } from './network';

export function RootRoutes() {
  const { isLoggedIn } = useAuthContext();

  return (
    <Switch>
      <Route
        exact
        path={PAW_PATH_HOME}
        render={() => <Redirect to={PAW_PATH_ACCOUNT} />}
      />

      <Route
        path={PAW_PATH_ACCOUNT}
        render={() => {
          if (!isLoggedIn) {
            return <Redirect to={PAW_PATH_LOGIN} />;
          }
          return (
            <AccountRoutes
              // TODO: This is just to satisfy TS since this RootRoutes is not in use.
              customerAccountData={{} as CustomerAccountData}
            />
          );
        }}
      />

      <Route
        path={PAW_PATH_LOGIN}
        render={props => {
          if (isLoggedIn) {
            const searchParams = new URLSearchParams(props.location.search);
            const nextUrl =
              searchParams.get('next') ?? PAW_PATH_CUSTOMER_ACCOUNT;
            return <Redirect to={nextUrl} />;
          }
          return <LoginPage />;
        }}
      />

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
