import { HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';

import config from '../../../config';

export function createHttpMiddleware() {
  const uri = new URL(
    config.get('tosa.app.api.graphQLRoute'),
    config.get('tosa.app.api.url')
  ).toString();

  return new HttpLink({
    uri,
    fetch,
  });
}
