import { reporter } from '../errors/services';
import { useFetchPacksAndPortions } from './network';
import { PacksAndPortionsSection } from './PacksAndPortionsSection';

export function PacksAndPortions() {
  const request = useFetchPacksAndPortions({
    onError: (error: unknown) => {
      reporter.warn('Error retrieving PacksAndPortions data', {
        sourceError: error,
      });
    },
  });

  if (request.error) return null;

  if (!request.loading && !request.data) {
    reporter.error(new Error('Invalid PacksAndPortions data'));
    return null;
  }

  return (
    <PacksAndPortionsSection
      loading={request.loading}
      pets={request.data?.me.pets}
      foodPlans={request.data?.me.subscription.foodPlans}
      feedingGuides={request.data?.me.subscription.feedingGuides}
    />
  );
}
