import { createElement, forwardRef, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Bolt } from '@farmersdog/corgi';
import { Mask, PageWidth, VideoSection, Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';
import Picture from 'src/components/Picture';

import useImperativeScroll from 'src/hooks/useImperativeScroll';

import { BENEFITS_HEALTH } from 'src/constants/wistiaVideoIds';

import Benefit from '../Benefit';
import BenefitCarousel from '../BenefitCarousel';
import CallToAction from '../CallToAction';

import QUOTES from './quotes';
import mainAssets from './assets/main';
import styles from './BenefitHealth.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

const WISTIA_OPTIONS = {
  popover: true,
};

function BenefitHealth({ innerRef }) {
  const containerRef = useImperativeScroll(innerRef);

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  const maxWidth = useCorgiViewport({ xs: false, lg: true });

  const overlayId = useId();

  return (
    <div
      id="benefit-health"
      ref={containerRef}
      className={classNames(styles.container, useCorgiViewport(styles), {
        [styles.oysterBackground]: isAccessibilityThemeOn,
      })}
    >
      <div className={styles.content}>
        <Benefit
          icon={<Bolt height={24} fill="kale-3" />}
          title="Boosting Health & Wellness"
          quote={
            <>
              Bruno has never looked or felt so good. He no longer sheds and his
              breath is fresh and clean. His coat absolutely gleams with health.
              His eyes are bright and his energy level is through the roof!
              Thank you for making quality food at a good price point. If anyone
              is on the fence about trying this or making the switch, don’t be!
            </>
          }
          picture={
            <Mask shape="circle" size="xl">
              <Picture
                sources={mainAssets}
                alt="boosting health and wellness dog named bruno"
              />
            </Mask>
          }
        />
      </div>
      <BenefitCarousel quotes={QUOTES} />
      <PageWidth enabled={maxWidth}>
        <VideoSection
          wistiaId={BENEFITS_HEALTH}
          wistiaOptions={WISTIA_OPTIONS}
          className={styles.video}
          aria-labelledby={overlayId}
          overlayContent={
            <figure className={styles.videoQuote}>
              <Text
                as="blockquote"
                variant="heading-28"
                color="white"
                className={styles.quote}
                id={overlayId}
              >
                “If I can invest in her health and be proactive, then I think
                it’s worth it.”
              </Text>
              <Text variant="heading-16" color="white" bold>
                – Tyene’s dad
              </Text>
            </figure>
          }
        />
      </PageWidth>
      <div
        className={classNames(useCorgiViewport(styles), {
          [styles.ctaPadding]: isAccessibilityThemeOn,
        })}
      >
        <CallToAction>Try Fresh Today</CallToAction>
      </div>
    </div>
  );
}

BenefitHealth.propTypes = {
  innerRef: PropTypes.object,
};

// eslint-disable-next-line react/display-name
export default forwardRef((props, innerRef) =>
  createElement(BenefitHealth, { ...props, innerRef })
);
