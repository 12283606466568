export enum Path {
  SeattleBillboard = '/sea',
  SeattleDirectMail = '/seattle',
  SeattleStreetTeam = '/hellosea',
  MinneapolisBillboard = '/minn',
  MinneapolisDirectMail = '/minneapolis',
  MinneapolisStreetTeam = '/hellominn',
}

export enum Destination {
  Instapage = 'instapage',
  Homepage = 'homepage',
}
