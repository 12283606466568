import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../../analytics';
import { BranchNode } from '../../blueprint/types';
import { LeadView } from '../../exports';
import { Progress } from '../../hooks/useProgress';
import { Experiments, UseFormNavigateReturn } from '../../types';
import { determineSkipGetStarted } from '../experiments';

interface RedirectIfNodeRemovedArgs {
  current: string | undefined;
  formSteps: BranchNode[];
  formNavigation: UseFormNavigateReturn;
  getNextIncompleteBranch: Progress['getNextIncompleteBranch'];
  experiments: Experiments;
  lead: LeadView | null;
}

export function redirectIfNodeRemoved({
  current,
  formSteps,
  formNavigation,
  getNextIncompleteBranch,
  experiments,
  lead,
}: RedirectIfNodeRemovedArgs) {
  if (current && formSteps.length > 0) {
    const currentIndex = formNavigation.findCurrentIndex({ formSteps });
    if (currentIndex === -1) {
      if (determineSkipGetStarted({ experiments }).shouldSkipGetStarted) {
        const nextIncompleteBranch = getNextIncompleteBranch();
        formNavigation.goToStep(nextIncompleteBranch.id, {
          routingAction: 'replace',
        });
      } else {
        track(segmentTrackEvents.redirect_if_node_removed, {
          anonymousId: lead?.anonymousId,
          corePostgresUserId: lead?.corePostgresUserId,
          email: lead?.email,
        });
      }
    }
  }
}
