import { Nowrap } from '@farmersdog/corgi-x';

import jack from './assets/jack';
import liam from './assets/liam';
import lola from './assets/lola';

export default [
  {
    name: 'Jack’s mom',
    quote: (
      <span>
        Jack was dropping weight so quickly. I found TFD and he has already put
        weight back on and devours <Nowrap>the food!</Nowrap>
      </span>
    ),
    sources: jack,
  },
  {
    name: 'Lola’s mom',
    quote: (
      <span>
        Lola has put on a lot of weight in her old age, but since switching to
        TFD, she’s lost 10lbs and is playing again! It’s nothing short{' '}
        <Nowrap>of miraculous.</Nowrap>
      </span>
    ),
    sources: lola,
  },
  {
    name: 'Liam’s mom',
    quote: (
      <span>
        I changed up Liam’s food and he has his puppy energy back! Thank you so
        much for helping me whip this fat boy back <Nowrap>in shape!</Nowrap>
      </span>
    ),
    sources: liam,
  },
];
