import { browserOnly } from '@farmersdog/utils';

interface LoadGoogleApiArgs {
  googleMapsApiKey: string;
}

/*
 * api key must be passed as an arg from the config value
 */

export function loadGoogleApi({
  googleMapsApiKey,
}: LoadGoogleApiArgs): Promise<typeof google> {
  const attributes = {
    async: true,
    defer: true,
    src: `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    type: 'text/javascript',
  };
  return new Promise((resolve, reject) => {
    return browserOnly(
      (window, document) => {
        if (window.google) {
          resolve(window.google);
        }

        let el: HTMLScriptElement | null = document.querySelector(
          `script[src="${attributes.src}"]`
        );

        if (!el) {
          el = document.createElement('script');

          Object.entries(attributes).forEach(([name, value]) => {
            if (!el) {
              return reject(
                new Error(
                  'Unable to create a new script element to load Google API'
                )
              );
            }

            el.setAttribute(name, String(value));
          });

          document.head.appendChild(el);
        }

        const handleLoad = () => {
          if (window.google) {
            resolve(window.google);
          } else {
            reject(new Error('Google loaded but no API object available'));
          }
        };

        const handleError = () => {
          reject(new Error('Cannot load Google API'));
        };

        el.addEventListener('load', handleLoad);
        el.addEventListener('error', handleError);
      },
      () =>
        reject(
          new Error('The google API cannot be loaded in the node environment')
        )
    );
  });
}
