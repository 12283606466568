import classNames from 'classnames';

import StethoscopeSvg from '../assets/stethoscope.svg';
import { DidYouKnowBox } from '../DidYouKnowBox';
import responsiveStyles from '../PlanCalculationWithLoader.module.css';

import styles from './VetStatic.module.css';

interface ResponsiveVetStatic {
  isMobile?: boolean;
}

function ResponsiveVetStatic({ isMobile }: ResponsiveVetStatic) {
  return (
    <DidYouKnowBox
      className={classNames(
        styles.container,
        isMobile ? responsiveStyles.mobile : responsiveStyles.desktop
      )}
      Svg={StethoscopeSvg}
    >
      All of our recipes are {isMobile && <br />}formulated by our in-house{' '}
      {isMobile && <br />}
      veterinary nutritionists!
    </DidYouKnowBox>
  );
}

export function VetStatic() {
  return (
    <>
      <ResponsiveVetStatic />
      <ResponsiveVetStatic isMobile />
    </>
  );
}
