import {
  claritas,
  AudacyEventId,
  AudacyEventName,
  AUDACY_PIXEL_ID,
} from '../claritas';
import { getCacheBuster } from '../../utils';

/**
 * Track an audacy checkout conversion
 */
export function trackAudacyCheckoutConversion(): void {
  const ord = getCacheBuster();
  claritas.track({
    ord,
    ppt: AUDACY_PIXEL_ID,
    g: AudacyEventName.CHECKOUT_SUCCESS,
    gid: AudacyEventId.CHECKOUT_SUCCESS,
  });
}
