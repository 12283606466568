import track from '../track';
import eventNames from '../eventNames';

interface TrackRecipesViewedRecipeDetailsArgs {
  recipeName: string;
}

export function trackRecipesViewedRecipeDetails({
  recipeName,
}: TrackRecipesViewedRecipeDetailsArgs) {
  track(eventNames.recipes_viewed_recipe_details, {
    recipeName,
  });
}
