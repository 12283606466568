import PropTypes from 'prop-types';

import { Nowrap } from '@farmersdog/corgi-x';

const NOWRAP_WORD_COUNT = 2;
const SPACE = ' ';

function AutoNowrap({ children }) {
  if (typeof children !== 'string') {
    return children;
  }

  const words = children.split(SPACE);

  return (
    <>
      {words.slice(0, -NOWRAP_WORD_COUNT).join(SPACE)}{' '}
      <Nowrap>{words.slice(-NOWRAP_WORD_COUNT).join(SPACE)}</Nowrap>
    </>
  );
}

AutoNowrap.propTypes = {
  children: PropTypes.node,
};

export default AutoNowrap;
