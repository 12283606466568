import { FormFieldsType } from '../../types';

import { getNodeNameAndPosition } from './getNodeNameAndPosition';

interface getPositionedPropertyProps {
  formValues: FormFieldsType;
  nodeName: string;
  property: string;
}

export const getPositionedProperty = ({
  formValues,
  nodeName,
  property,
}: getPositionedPropertyProps) => {
  const { position } = getNodeNameAndPosition(nodeName);
  const indexProperty = `${property}-${position}`;
  return formValues[indexProperty as keyof typeof formValues];
};
