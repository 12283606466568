/**
 * These constants are the query parameters used across multiple pages in the app.
 */
export enum QueryParameter {
  /**
   * The previous path before entering a workflow.  Primarily used to redirect
   * a user back to a page after workflow is complete.
   */
  PrevPath = 'prevPath',
  /**
   * The path to send a user to after a workflow as completed.  Unlike previous path
   * a user may not be coming from this path.
   */
  NextPath = 'next',
  /**
   * When updating a credit card, set as the default card
   */
  UseCardAsDefaultSource = 'useCardAsDefaultSource',
  /**
   * The type of referrer code used in the REST /referrer endpoint
   */
  ReferrerType = 'type',
  /**
   * The referrer code used in the REST /referrer endpoint
   */
  ReferrerCode = 'code',
  /**
   * A referral code
   */
  ReferralCode = 'ref',
  /**
   * An email subject
   */
  Subject = 'subject',
  /**
   * The type of quote being requested from the REST /quote endpoint
   */
  QuoteMode = 'mode',
  /**
   * The name of a pet
   */
  PetName = 'pet',
  /**
   * An email address
   */
  Email = 'email',
  /**
   * Boolean to indicate that a food plan is a mixing plan
   */
  IsMixing = 'isMixing',
  /**
   * A boolean to indicate enable the topper options in a signup mixing plan
   */
  Topper = 'topper',
  /**
   * A user's id
   */
  UserId = 'userId',
  /**
   * Identify the advertiser, site, publication, etc. that is sending traffic to your property
   * https://support.google.com/analytics/answer/1033863
   */
  UtmSource = 'utm_source',
  /**
   * The advertising or marketing medium
   * https://support.google.com/analytics/answer/1033863
   */
  UtmMedium = 'utm_medium',
  /**
   * The individual campaign name, slogan, promo code, etc. for a product
   * https://support.google.com/analytics/answer/1033863
   */
  UtmCampaign = 'utm_campaign',
  /**
   * The parameter used to toggle between DIY and fresh during Signup
   */
  ProductLine = 'productLine',
  /**
   * The parameter used to indicate how many pets a user is signing up for on me page.
   */
  PetCount = 'petCount',
  /**
   * Indicates the entry point for a workflow. Not used for redirects; only analytics.
   */
  EntryPoint = 'entryPoint',
}
