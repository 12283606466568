import events from '../eventNames';
import track from '../track';

/**
 * A segment tracking event that is used to track when a user opens the learn more section
 *  in the free treats section during checkout.
 */

export function trackSignupFreeTreatsOpenLearnMore() {
  track(events.signup_free_treats_open_learn_more);
}
